import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { PaymentRecipientForSelect } from '@treasury/domain/channel/types/ach/api';
import { FdlFieldDefinitions, FieldType, Record } from '@treasury/FDL';
import fieldTypes from '@treasury/policy/primitives';

const notOnUsTransactionTypeAbbreviation: any = {
    'Credit Only': 'CR',
    'Debit Only': 'DR',
    'Debits and Credits': 'both',
};

function showRecipientBasedOnTransactionType(
    recipientTransactionType: any,
    companyNotOnUsTransactionType: any
): boolean {
    return (
        recipientTransactionType ===
            notOnUsTransactionTypeAbbreviation[companyNotOnUsTransactionType] ||
        notOnUsTransactionTypeAbbreviation[companyNotOnUsTransactionType] === 'both'
    );
}

export const fetchedRecipientFields = (
    paymentHeaderRecord: Record<PaymentHeader>
): FdlFieldDefinitions<PaymentRecipientForSelect> => ({
    selected: fieldTypes.boolean,
    name: fieldTypes.string.thatIs.visibleWhen((record: any) =>
        showRecipientBasedOnTransactionType(
            record.getField('transactionType'),
            paymentHeaderRecord.getField('achCompany').notOnUsTransactionTypes
        )
    ) as FieldType<PaymentRecipientForSelect['name']>,
    recipientIdNumber: new FieldType() as FieldType<PaymentRecipientForSelect['recipientIdNumber']>,
    accountNumber: fieldTypes.string as FieldType<PaymentRecipientForSelect['accountNumber']>,
    accountType: fieldTypes.string as FieldType<PaymentRecipientForSelect['accountType']>,
    transactionType: fieldTypes.string as FieldType<PaymentRecipientForSelect['transactionType']>,
    amount: fieldTypes.amount as FieldType<PaymentRecipientForSelect['amount']>,
    status: fieldTypes.string as FieldType<PaymentRecipientForSelect['status']>,
    routingNumber: fieldTypes.string as FieldType<PaymentRecipientForSelect['routingNumber']>,
    id: fieldTypes.number as FieldType<PaymentRecipientForSelect['id']>,
});

export const getAchRecipientsColumns = () => [
    { type: 'checkbox', label: '', field: 'selected' },
    {
        label: 'Recipient Name',
        field: 'name',
        type: 'read-only',
    },
    {
        label: 'ID Number',
        field: 'recipientIdNumber',
        type: 'read-only',
    },
    {
        label: 'Account Number',
        field: 'accountNumber',
        type: 'read-only',
    },
    {
        label: 'Account Type',
        field: 'accountType',
        type: 'read-only',
    },
    {
        label: 'Routing Number',
        field: 'routingNumber',
        type: 'read-only',
    },
    {
        label: 'Transaction Type',
        field: 'transactionType',
        type: 'read-only',
    },
    {
        label: 'Default Amount',
        field: 'amount',
        type: 'read-only',
    },
    {
        label: 'Status',
        field: 'status',
        type: 'read-only',
    },
];

export const convertSelectedToPaymentRecipients = (
    selectedPaymentRecipients: Array<Record<PaymentRecipientForSelect>>
): Array<PaymentRecipient> =>
    selectedPaymentRecipients.map(paymentRecipient => {
        const {
            prenote = false,
            hold = false,
            name,
            recipientIdNumber,
            accountNumber,
            accountType,
            amount,
            addenda,
            errors,
            transactionType,
            step,
            routingNumber,
            status,
            // Selected is not returned during an API request
            selected,
        } = paymentRecipient.values;

        return {
            prenote,
            hold,
            name,
            idNumber: recipientIdNumber,
            accountNumber,
            accountType,
            bank: {
                bankId: routingNumber,
            },
            amount,
            addenda,
            errors,
            transactionType: transactionType === 'Credit' ? 'CR' : 'DR',
            step,
            routingNumber,
            status,
            selected,
            editable: false,
            fromMasterList: true,
        };
    });
