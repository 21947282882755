export enum FrequencyType {
    OneTime = 'OneTime',
    Weekly = 'Weekly',
    EveryTwoWeeks = 'EveryTwoWeeks',
    TwiceAMonth = 'TwiceAMonth',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    EverySixMonths = 'EverySixMonths',
    Yearly = 'Yearly',
}

export const FrequencyTypeLabels = {
    [FrequencyType.OneTime]: 'One Time',
    [FrequencyType.Weekly]: 'Weekly',
    [FrequencyType.EveryTwoWeeks]: 'Every Two Weeks',
    [FrequencyType.TwiceAMonth]: 'Twice a Month',
    [FrequencyType.Monthly]: 'Monthly',
    [FrequencyType.Quarterly]: 'Quarterly',
    [FrequencyType.EverySixMonths]: 'Every Six Months',
    [FrequencyType.Yearly]: 'Yearly',
} as const;

export type FrequencyForSelect = {
    name: (typeof FrequencyTypeLabels)[keyof typeof FrequencyTypeLabels];
    value: FrequencyType;
};

export interface FrequencyBase<T> {
    type: T;
    startOn: string | null;
    endOn: string | null;
    noEndDate: boolean;
}

export interface WeeklyFrequencyBase<T> extends FrequencyBase<T> {
    repeatOn: string | null;
}

export interface MonthlyFrequencyBase<T> extends FrequencyBase<T> {
    repeatOnLastBusinessDay: boolean;
}

export interface OneTimeFrequency {
    type: FrequencyType.OneTime;
    valueDate: string | null;
}

export type WeeklyFrequency = WeeklyFrequencyBase<FrequencyType.Weekly>;

export type EveryTwoWeeksFrequency = WeeklyFrequencyBase<FrequencyType.EveryTwoWeeks>;

export interface TwiceAMonthFrequency extends MonthlyFrequencyBase<FrequencyType.TwiceAMonth> {
    repeatOn: number[] | null;
}

export interface MonthlyFrequency extends MonthlyFrequencyBase<FrequencyType.Monthly> {
    repeatOn: number | null;
}

export type QuarterlyFrequency = FrequencyBase<FrequencyType.Quarterly>;

export type EverySixMonthsFrequency = FrequencyBase<FrequencyType.EverySixMonths>;

export type YearlyFrequency = FrequencyBase<FrequencyType.Yearly>;

export type Frequency =
    | null
    | OneTimeFrequency
    | WeeklyFrequency
    | EveryTwoWeeksFrequency
    | TwiceAMonthFrequency
    | MonthlyFrequency
    | QuarterlyFrequency
    | EverySixMonthsFrequency
    | YearlyFrequency;
