import { MasterListRecipient } from '@treasury/domain/channel/types/ach';
import { Record, Recordset, RecordsetEvent } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-table.js';
import { AlertMixin } from '@treasury/omega/mixins';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../../components/blocking-loader.js';
import {
    importedRecipientColumns,
    importedRecipientFields,
} from '../../data/ach-import-recipient-fields';
import { AchRecipientsAddendaDialog } from '../../dialogs';

const tag = 'ach-import-recipients-dialog';
const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));
@customElement(tag)
export class AchImportRecipientsDialog extends AlertingListeningElement {
    @property({ type: Array })
    importedRecipients = [];

    @state()
    loading = false;

    @state()
    private columns = importedRecipientColumns;

    @state()
    recordset!: Recordset<any>;

    @property({ type: Boolean })
    open = false;

    @property({ type: Function })
    setSelectedRecord = (record: Record<MasterListRecipient>) => null;

    @state()
    selectedRecord!: Record<MasterListRecipient>;

    @state()
    private saveIsDisabled = true;

    addListeners = () => {
        this.listenTo(this.recordset, RecordsetEvent.Updated, () => {
            this.setSaveButtonDisabled();
        });
    };

    async firstUpdated() {
        this.loading = true;
        this.recordset = new Recordset(importedRecipientFields, this.importedRecipients);
        await this.recordset.requestUpdate();
        this.loading = false;
        this.setSaveButtonDisabled();
        this.addListeners();
    }

    openAddendaDialog(record: Record<MasterListRecipient>) {
        this.selectedRecord = record;
        this.selectedRecord.setField('editable', true);
        this.setSelectedRecord(record);
        const container =
            document.querySelector('ach-recipients-container') ?? document.createElement('div');
        AchRecipientsAddendaDialog.open(container);
    }

    setSaveButtonDisabled() {
        const recordsetData = this.recordset?.getData();

        const emptyRecipientNames = recordsetData?.filter(record => record.name.length === 0);

        const emptyAccountNumbers = recordsetData?.filter(
            record => record.accountNumber.length === 0
        );

        const emptyAccountTypes = recordsetData?.filter(
            record => record.accountType === null || record.accountType === undefined
        );

        const zeroValAmounts = recordsetData?.filter(record => record.amount === 0);

        const isSaveDisabled =
            emptyRecipientNames?.length > 0 ||
            emptyAccountNumbers?.length > 0 ||
            emptyAccountTypes?.length > 0 ||
            zeroValAmounts?.length > 0;

        this.saveIsDisabled = isSaveDisabled;
    }

    render() {
        if (this.loading) return html`<blocking-loader></blocking-loader>`;
        return html` <omega-dialog
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><div slot="content">
                <omega-table
                    .recordset=${this.recordset}
                    .columnDefinitions=${this.columns}
                    @action=${(e: CustomEvent) => {
                        if (e.detail.action === 'openAddendaDialog')
                            this.openAddendaDialog(e.detail.record);
                    }}
                ></omega-table>
            </div>
            <div slot="actions">
                <omega-button
                    type="primary"
                    @click=${() => {
                        this.dispatchEvent(
                            new CustomEvent('save', { detail: this.recordset.getData() })
                        );
                        this.open = false;
                        this.dispatchEvent(new CustomEvent('close'));
                    }}
                    .disabled=${this.saveIsDisabled}
                    >Save</omega-button
                ><omega-button
                    type="link"
                    @click=${() => {
                        this.open = false;
                        this.dispatchEvent(new CustomEvent('close'));
                    }}
                    >Cancel</omega-button
                >
            </div></omega-dialog
        >`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --omega-dialog-min-width: 70%;
            }
        `;
    }
}
