import AchReturnActivityServices from '@treasury/domain/channel/services/ach/ach-return-activity-services.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';

export class AchReturnActivityClient {
    async search(...args) {
        return AchReturnActivityServices.search(...args);
    }

    async searchV2(...args) {
        return AchReturnActivityServices.searchV2(...args);
    }

    async userActivity(...args) {
        return UserActivityService.print(...args);
    }

    async saveUserActivity(...args) {
        return UserActivityService.saveUserActivity(...args);
    }

    async getCompanies() {
        return AchReturnActivityServices.getCompanies();
    }

    async getDetails(...args) {
        return AchReturnActivityServices.getDetails(...args);
    }

    async downloadReturn(...args) {
        return AchReturnActivityServices.downloadReturn(...args);
    }

    async downloadReturnV2(...args) {
        return AchReturnActivityServices.downloadReturnV2(...args);
    }

    async downloadReturnReport(...args) {
        return AchReturnActivityServices.downloadReturnReport(...args);
    }
}
