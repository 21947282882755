RdcSsoController.$inject = [
    'remoteDepositCaptureSsoService',
    '$sce',
    'modalService',
    'remoteDepositTypes',
    '$timeout',
    'moment',
    '$rootScope',
    '$scope',
];

export default function RdcSsoController(
    remoteDepositCaptureSsoService,
    $sce,
    modalService,
    remoteDepositTypes,
    $timeout,
    moment,
    $rootScope,
    $scope
) {
    const vm = this;

    vm.fields = [];
    vm.actionUrl = null;
    vm.url = null;
    vm.getRemoteDepositTypeTemplateName = getRemoteDepositTypeTemplateName;
    vm.launchSmartPay = launchSmartPay;
    vm.displaymethod = null;
    $scope.useIFrame = useIFrame;
    $scope.useNewWindow = useNewWindow;
    $scope.handoffMessage = 'Your Remote Deposit Service will open in a new window.';
    $scope.openNewWindow = openNewWindow;
    $scope.counter = 0;
    $scope.isWausauDeposit = isWausauDeposit;

    init();
    getAdditionalSettings();

    function getRemoteDepositTypeTemplateName() {
        if (remoteDepositTypes.properties.hasOwnProperty(vm.remoteDepositTypeId)) {
            return `${remoteDepositTypes.properties[
                vm.remoteDepositTypeId
            ].name.toLowerCase()}.html`;
        }

        return null;
    }

    function init() {
        remoteDepositCaptureSsoService.getRemoteDepositCaptureSsoLogin().then(response => {
            if (response) {
                vm.remoteDepositTypeId = response.remoteDepositProductTypeId;

                const url = $sce.trustAsResourceUrl(response.ssoLoginUrl);

                if (vm.remoteDepositTypeId === remoteDepositTypes.SMARTPAY) {
                    vm.fields = [
                        { name: 'Referring Application', value: 'Treasury' },
                        { name: 'NetTellerID', value: response.customerId },
                        { name: 'CMID', value: response.userId },
                        {
                            name: 'TimeStamp',
                            value: moment
                                .parseZone(response.timestamp)
                                .format('MM/DD/YYYY hh:mm:ss A'),
                        },
                        { name: 'Hash', value: response.hash.toUpperCase() },
                        { name: 'HASHALG', value: ('hashAlgorithmName' in response && response.hashAlgorithmName !== null) ? response.hashAlgorithmName : 'SHA1'},
                    ];
                }

                vm.url = url;
            } else {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText:
                            'We were unable to complete your request at this time. Please contact your financial institution for additional information.',
                    }
                );
            }
        });
    }

    function launchSmartPay() {
        angular.element('#remoteDepositForm').submit();
    }

    function getAdditionalSettings() {
        remoteDepositCaptureSsoService.getAdditionalRemoteDepositSettings().then(response => {
            if (response) {
                vm.displaymethod = response.displayMethod;
            } else {
                vm.displaymethod = 'iframe';
            }
        });
    }

    function isWausauDeposit() {
        return vm.remoteDepositTypeId === remoteDepositTypes.WAUSAU;
    }

    function useIFrame() {
        return isWausauDeposit() && vm.displaymethod === 'iframe';
    }

    function useNewWindow() {
        return isWausauDeposit() && vm.displaymethod === 'newwindow';
    }

    function openNewWindow(url) {
        if ($scope.counter === 0) {
            window.open(url, '_blank');
            $scope.counter += 1;
        }
    }
}
