/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { NotificationConfigurationModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class NotificationConfigurationClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags NotificationConfiguration
     * @name NotificationConfigurationGet
     * @summary Gets the Notification Configuration.
     * @request GET:/notificationConfiguration
     */
    notificationConfigurationGet = (params: RequestParams = {}) =>
        this.http.request<NotificationConfigurationModelDto, any>({
            path: `/notificationConfiguration`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags NotificationConfiguration
     * @name NotificationConfigurationPut
     * @summary Saves the Notification Configuration.
     * @request PUT:/notificationConfiguration
     */
    notificationConfigurationPut = (
        notificationConfiguration: NotificationConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<NotificationConfigurationModelDto, any>({
            path: `/notificationConfiguration`,
            method: 'PUT',
            body: notificationConfiguration,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
