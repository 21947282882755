/* eslint-disable class-methods-use-this */
import { html, nothing } from 'lit';
import './actions-dropdown.js';
import StandardColumn from './standard-column.js';

export default class ActionsColumn extends StandardColumn {
    constructor(...args) {
        super(...args);
        const [{ actions, sortable }] = args;
        this.actions = actions;
        this.sortable = sortable || false;
        this.minWidth = 100;
        this.targetWidth = 100;
        this.maxWidth = 100;
    }

    cellClasses() {
        return [...super.cellClasses(), 'omega-no-print'];
    }

    isSortable() {
        return this.sortable;
    }

    cellClicked(record, rowIndex, e) {
        if (!this.disableCommand) {
            e.target.dispatchEvent(
                new CustomEvent('action', {
                    bubbles: true,
                    composed: true,
                    detail: { record, rowIndex, field: this.field, action: this.actions[0] },
                })
            );
        }
    }

    renderThContents() {
        return this.label;
    }

    renderTdContents(record, rowIndex) {
        if (!record) return super.renderContentLoading();
        if (this.field) {
            return html`
                <span
                    aria-role="button"
                    class="action-handler link-column"
                    @click=${e => this.cellClicked(record, rowIndex, e)}
                >
                    ${record.print(this.field)}
                </span>
            `;
        }
        return html`<actions-dropdown
            .label=${this.label}
            .record=${record}
            .rowIndex=${rowIndex}
            .actions=${this.actions}
        ></actions-dropdown>`;
    }

    renderSummaryContents(/* visibleRecords */) {
        return nothing;
    }
}
