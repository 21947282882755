/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

/**
 * Requests for the wire upload formats feature
 */
export class WireFormatRequests {
    static async getFormats() {
        const http = await TmHttpClient.getInstance();
        const data = await http.request(`wires/fileformat`, { method: 'GET' });
        return { data, totalCount: data.length };
    }

    /**
     *
     * @param {int} params format id
     * @param {array} fields all fields available for the formatter to compare against saved tiles in the format
     */
    static async getFormat(params) {
        return (await TmHttpClient.getInstance()).request(`wires/fileformat/${params}`, {
            method: 'GET',
        });
    }

    static async saveFormat(format) {
        if (format.id) {
            return (await TmHttpClient.getInstance()).request(`wires/fileformat/${format.id}`, {
                method: 'PUT',
                body: format,
            });
        }
        return (await TmHttpClient.getInstance()).request(`wires/fileformat`, {
            method: 'POST',
            body: format,
        });
    }

    /**
     *
     * @param {*} params id
     */
    static async deleteFormat(params) {
        return (await TmHttpClient.getInstance()).request(`wires/fileformat/${params}`, {
            method: 'DELETE',
        });
    }

    static async getFormatFields() {
        return (await TmHttpClient.getInstance()).request(`wires/fileformatfield`, {
            method: 'GET',
        });
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default WireFormatRequests;
