import DownloadRequests from '../../requests/download/download.js';

/**
 * @typedef {Object} DownloadFilterModel
 * @property {string} page
 */

export default class DownloadService {
    /**
     * @template T
     * @param {string} confirmationId
     * @param {DownloadType} outputType
     * @param {string} pageId
     * @param {DownloadFilterModel & T} filterModel
     * @param {DownloadType[]} downloadFormats
     * @returns { Promise<void> } Promise representing the finished download operation.
     */
    static async download(
        confirmationId,
        outputType,
        pageId,
        filterModel,
        downloadFormats = ['CSV', 'NACHA', 'PDF']
    ) {
        return DownloadRequests.download(
            confirmationId,
            outputType,
            pageId,
            filterModel,
            downloadFormats
        );
    }
}
