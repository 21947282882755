// cspell:ignore pageview
import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    getLocalDateTime,
    getTimezone,
    getTimezoneAbbreviated,
    getTimezoneOffset,
} from '@treasury/utils';
import mixpanel from 'mixpanel-browser';
import { BuildType, ConfigurationService } from '../config';
import {
    AnalyticsEvent,
    AnalyticsEventPayloads,
    IAnalyticsService,
    UserIdentificationData,
} from './analytics.types';

@Injectable()
export class AnalyticsService implements IAnalyticsService {
    // eslint-disable-next-line no-empty-function
    constructor(private configService: ConfigurationService) {}

    private initialized = false;

    public init() {
        if (this.initialized) {
            return;
        }

        if (!this.configService.analyticsToken) {
            throw new Error('Could not initialize AnalyticsService. No token present.');
        }

        mixpanel.init(this.configService.analyticsToken, {
            debug: this.configService.buildType !== BuildType.Production,
            persistence: 'localStorage',
        });

        this.initialized = true;
    }

    /**
     * Associate a unique user with the current analytics session.
     */
    public identify(userData: UserIdentificationData) {
        const { userId, isSuperUser, isAdmin, companyId } = userData;
        const fiId = this.configService.institutionId.toLowerCase();
        const product = this.configService.app;
        const userPayload = {
            'fi-id': fiId,
            'is-admin': isAdmin,
            'is-super-user': isSuperUser,
            'company-id': companyId,
        };
        /**
         * [Super properties](https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#super-properties)
         * sent with every event.
         */
        const superProperties = {
            ...userPayload,
            product,
            'local-date-time': getLocalDateTime(),
            timezone: getTimezone(),
            'timezone-id': getTimezoneAbbreviated(),
            'gmt-offset': getTimezoneOffset(),
        };

        this.guardInit(() => {
            mixpanel.identify(userId.toString());
            mixpanel.register(superProperties);
            mixpanel.people.set(userPayload);
        });
    }

    /**
     * Forget any user associated with the current analytics session.
     */
    public reset() {
        this.guardInit(() => mixpanel.reset());
        this.initialized = false;
    }

    public track<Event extends AnalyticsEvent>(
        eventName: Event,
        ...payload: AnalyticsEventPayloads[Event] extends object
            ? [AnalyticsEventPayloads[Event]]
            : [never?]
    ) {
        this.guardInit(() => mixpanel.track(eventName, payload));
    }

    public trackPageView(route: string) {
        const fiId = this.configService.institutionId.toLowerCase();
        this.guardInit(() =>
            mixpanel.track_pageview({
                route,
                'fi-id': fiId,
            })
        );
    }

    /**
     * Invokes the provided function only if the service has been initialized.
     */
    private guardInit(fn: () => void) {
        if (!this.initialized) {
            console.warn('Cannot use analytics service. It has not been initialized.');
            return;
        }

        fn();
    }
}
