import { LitElement, html, css, nothing } from 'lit';
import './omega-field.js';

class OmegaDetailRowContent extends LitElement {
    static get properties() {
        return {
            contentColumns: Array,
            record: Object,
        };
    }

    // STUB: not relevant to storybook
    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-button--button',
        };
    }

    renderDetailsForColumn(columns) {
        return columns.map(column => {
            const { field } = column;
            return html`<omega-field field="${field}" .record=${this.record}></omega-field>`;
        });
    }

    renderColumns(contentColumnArray) {
        return contentColumnArray.map(
            contentColumn => html` <div class="form-column">
                ${this.renderDetailsForColumn(contentColumn)}
            </div>`
        );
    }

    render() {
        if (!this.contentColumns || !this.record) return nothing;
        return html`<div class="omega-flex-form">${this.renderColumns(this.contentColumns)}</div>`;
    }

    static get styles() {
        return css`
            .omega-flex-form {
                display: flex;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }
            .form-column:not(:last-of-type) {
                padding-right: 10px;
            }
            .form-column.with-border:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }
            .omega-flex-form omega-field {
                margin: 10px;
                min-height: 32px;
                --omega-label-color: #999999;
                --omega-label: 13px;
            }
            .border-style {
                border-top: 3px solid #d3d6d9;
            }
        `;
    }
}

customElements.define('omega-detail-row-content', OmegaDetailRowContent);
export default OmegaDetailRowContent;
