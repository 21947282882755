import { html, nothing, unsafeCSS } from 'lit';
import Column from './column.js';

export default class IconColumn extends Column {
    constructor(...args) {
        super(...args);
        const [
            {
                label,
                icon,
                iconLabel,
                iconStyle,
                action,
                tooltip,
                conditional, // function that should return an icon or null
                visibleWhen, // function that should return a boolean`
            },
        ] = args;
        this.label = label;
        this.icon = icon;
        this.iconLabel = iconLabel ?? icon;
        this.iconStyle = iconStyle;
        this.action = action;
        this.tooltip = tooltip;
        this.conditional = conditional ?? (() => null);
        this.visibleWhen = visibleWhen ?? (() => true);
        this.minWidth = 50;
        this.targetWidth = 75;
        this.maxWidth = 100;
    }

    cellClasses() {
        return [...super.cellClasses(), 'icon-column', 'omega-no-print'];
    }

    iconClicked(record, rowIndex, e, action) {
        e.target.dispatchEvent(
            new CustomEvent('action', {
                bubbles: true,
                composed: true,
                detail: {
                    record,
                    rowIndex,
                    action: action ?? this.action,
                },
            })
        );
    }

    renderThContents() {
        return this.label;
    }

    renderButton(record, rowIndex) {
        return html`
            <omega-button
                class="default-icon"
                type="icon"
                ?disabled=${!record}
                .icon=${this.icon}
                hideLabel="always"
                @click=${e => this.iconClicked(record, rowIndex, e)}
            >
                ${this.iconLabel}
            </omega-button>
        `;
    }

    renderConditionalButton(record, rowIndex) {
        const conditionalIcon = this.conditional(record);
        const { iconStyle } = conditionalIcon;
        return html` <style>
                omega-button.conditional-icon.i${rowIndex}::part(icon) {
                    ${unsafeCSS(iconStyle)}
                }
            </style>
            <omega-button
                class="conditional-icon i${rowIndex}"
                type="icon"
                ?disabled=${!record}
                .icon=${conditionalIcon.icon}
                hideLabel="always"
                @click=${e => {
                    if (conditionalIcon.action)
                        this.iconClicked(record, rowIndex, e, conditionalIcon.action);
                }}
            >
                ${this.iconLabel}
            </omega-button>`;
    }

    renderConditionalTooltip(record, rowIndex) {
        const conditionalIcon = this.conditional(record);
        if (typeof conditionalIcon.tooltip === 'string') {
            return html` <omega-tooltip light message=${conditionalIcon.tooltip}>
                ${this.renderConditionalButton(record, rowIndex)}
            </omega-tooltip>`;
        }

        return html` <omega-tooltip light>
            ${this.renderConditionalButton(record, rowIndex)}
            <div slot="content">${conditionalIcon.tooltip}</div>
        </omega-tooltip>`;
    }

    renderTdContents(record, rowIndex) {
        if (!this.visibleWhen(record)) {
            return nothing;
        }
        const conditionalIcon = this.conditional(record);
        if (conditionalIcon) {
            if (conditionalIcon.tooltip) {
                return this.renderConditionalTooltip(record, rowIndex);
            }
            return this.renderConditionalButton(record, rowIndex);
        }
        if (this.tooltip) {
            return html` <omega-tooltip light message=${this.tooltip}>
                ${this.renderButton(record, rowIndex)}
            </omega-tooltip>`;
        }
        return this.renderButton(record, rowIndex);
    }

    renderContentLoading() {
        return html`
            <omega-button type="icon" disabled .icon=${this.icon} hideLabel="always">
                ${this.iconLabel}
            </omega-button>
        `;
    }
}
