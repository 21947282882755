import { HttpResponse } from '../types';

/**
 * @returns `true` if the provided object is one of several low-level types that
 * could come from a fetch response.
 */
export function isHttpResponse(obj: unknown): obj is HttpResponse {
    return (
        obj instanceof Blob ||
        obj instanceof ArrayBuffer ||
        obj instanceof Response ||
        typeof obj === 'string'
    );
}
