export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                selectionList: '=',
                columnName: '@',
                controlId: '@',
                placeholder: '@',
                ngRequired: '=',
                includeTemplate: '=',
                transaction: '=?',
                type: '@',
                index: '=?',
                ngInvalid: '=?',
                ngDisabled: '=',
            },
            template: require('./typeAheadTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.internalSelectionList = $scope.selectionList;

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue) {
                                $scope.internalSelectionList = $scope.selectionList;
                            }
                        },
                        true
                    );
                },
            ],
        };
    },
];
