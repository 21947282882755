CreateArpController.$inject = [
    '$scope',
    'arpService',
    '$uibModal',
    '$state',
    '$filter',
    'modalService',
    'activityService',
    'resourceType',
    'auditCode',
    'dates',
    'navigationService',
    'entitlementsService',
];

export default function CreateArpController(
    $scope,
    arpService,
    $uibModal,
    $state,
    $filter,
    modalService,
    activityService,
    resourceType,
    auditCode,
    dates,
    navigationService,
    entitlementsService
) {
    let selectedFileId = null;
    let uploadedItems = [];
    let fileErrors = null;
    let isMultiAccountFormat = false;
    let areManagedItemsValid = false;
    let activeForm = {};
    let isIssuedItemsActivityEnabled = false;

    const ctrl = this;
    ctrl.managedItems = [];
    ctrl.validItems = [];
    ctrl.wizardController = {};
    ctrl.tableController = {};
    ctrl.accountDisplayField = {};
    ctrl.savedFormats = {};
    ctrl.companyAccounts = [];
    ctrl.filterObj = {};
    ctrl.datePickerOptions = { format: 'MM/dd/yyyy', max: dates.MaxValue };
    ctrl.arpData = {
        isUpload: true,
        isUploadSelection: true,
        arpAccount: null,
        issuedItems: null,
        savedFormat: null,
        file: null,
        fileName: null,
    };
    ctrl.titles = {
        UploadIssuedChecks: 'Create Issued Items',
        EnterIssuedChecks: 'Create Issued Items',
    };
    ctrl.controls = {
        incrementCheckNumber: false,
    };

    ctrl.setForm = setForm;
    ctrl.cancel = cancel;
    ctrl.isUpload = isUpload;
    ctrl.onSelectFile = onSelectFile;
    ctrl.onRemoveFile = onRemoveFile;
    ctrl.isObject = isObject;
    ctrl.findAccount = findAccount;
    ctrl.setAccount = setAccount;
    ctrl.isManageStep = isManageStep;
    ctrl.isReviewStep = isReviewStep;
    ctrl.isConfirmStep = isConfirmStep;
    ctrl.canUpload = canUpload;
    ctrl.undo = undo;
    ctrl.addManagedItem = addManagedItem;
    ctrl.deleteManagedItem = deleteManagedItem;
    ctrl.hasFileErrors = hasFileErrors;
    ctrl.confirmUndo = confirmUndo;
    ctrl.saveIssuedItems = saveIssuedItems;
    ctrl.canReview = canReview;
    ctrl.filterRows = filterRows;
    ctrl.upload = upload;
    ctrl.disableFilter = disableFilter;
    ctrl.goToNextStep = goToNextStep;
    ctrl.goToPreviousStep = goToPreviousStep;
    ctrl.showFileErrors = showFileErrors;
    ctrl.showAddButton = showAddButton;
    ctrl.getTitle = getTitle;
    ctrl.confirmToggle = confirmToggle;
    ctrl.reloadPage = reloadPage;
    ctrl.getAmountTotal = getAmountTotal;
    ctrl.updateIsMultiAccountFormat = updateIsMultiAccountFormat;
    ctrl.accountControlShouldBeDisabled = accountControlShouldBeDisabled;
    ctrl.setTableController = setTableController;
    ctrl.getAccountSelectInformationText = getAccountSelectInformationText;
    ctrl.isAccountInvalid = isAccountInvalid;
    ctrl.setDirty = setDirty;
    ctrl.allowFifteenThousandFileRecords = allowFifteenThousandFileRecords;

    $scope.$watch(() => ctrl.managedItems, validateManagedItems, true);

    function getTitle() {
        if (ctrl.arpData.isUpload) {
            return ctrl.titles.UploadIssuedChecks;
        }
        return ctrl.titles.EnterIssuedChecks;
    }

    // function truncateCheckNumbers() {
    //     if (ctrl.managedItems != null) {
    //         const managedItemsCopy = ctrl.managedItems;
    //         managedItemsCopy.forEach((item, index) => {
    //             managedItemsCopy[index].checkNumber = parseInt(item.checkNumber).toString();
    //         });
    //         ctrl.managedItems = managedItemsCopy;
    //     }
    // }
    function truncateCheckNumbers() {
        ctrl.managedItems = ctrl.managedItems?.map(item => ({
            ...item,
            checkNumber: parseInt(item.checkNumber).toString(),
        }));
    }

    function goToNextStep() {
        if (ctrl.isManageStep) {
            truncateCheckNumbers();
        }
        ctrl.filterObj.text = null;
        ctrl.wizardController.goToNextStep();
    }

    function goToPreviousStep() {
        ctrl.filterObj.text = null;
        ctrl.wizardController.goToPreviousStep();
    }

    function showFileErrors() {
        $uibModal.open({
            template: require('../views/arpFileErrorsModalView.html'),
            controller: 'ArpFileErrorsController',
            backdrop: 'static',
            resolve: {
                fileErrors() {
                    return fileErrors;
                },
            },
        });
    }

    function showAddButton(isLastOnPage) {
        const numPages = ctrl.tableController.pagination.numberOfPages;
        const isLastPage = ctrl.tableController.pagination.currentPage === numPages;
        return isLastOnPage && isLastPage;
    }

    function disableFilter() {
        const isActiveFormInvalid = !activeForm || activeForm.$invalid;
        const noIssuedItems = !ctrl.managedItems || ctrl.managedItems.length <= 0;
        return isActiveFormInvalid || noIssuedItems;
    }

    function filterRows(row) {
        return Boolean(
            !ctrl.filterObj.text ||
                ctrl.filterObj.text.trim() === '' ||
                !ctrl.managedItems ||
                ctrl.managedItems.length <= 0 ||
                (row.checkNumber &&
                    row.checkNumber.toLowerCase().indexOf(ctrl.filterObj.text.toLowerCase()) !==
                        -1) ||
                (row.payee &&
                    row.payee.toLowerCase().indexOf(ctrl.filterObj.text.toLowerCase()) !== -1) ||
                (row.dateIssued &&
                    $filter('date')(row.dateIssued, 'MM/dd/yyyy').indexOf(ctrl.filterObj.text) !==
                        -1) ||
                (row.checkAmount &&
                    $filter('currency')(row.checkAmount, '$').indexOf(ctrl.filterObj.text) !==
                        -1) ||
                (row.type &&
                    row.type.toLowerCase().indexOf(ctrl.filterObj.text.toLowerCase()) !== -1)
        );
    }

    function canReview() {
        return ctrl.managedItems && ctrl.managedItems.length > 0 && areManagedItemsValid;
    }

    function reloadPage() {
        $state.go($state.current, {}, { reload: true });
    }

    function getAmountTotal() {
        if (ctrl.validItems && ctrl.validItems.length > 0) {
            let total = 0;
            for (let i = 0; i < ctrl.validItems.length; i++) {
                const check = ctrl.validItems[i];
                if (check && check.checkAmount) {
                    total += check.checkAmount;
                }
            }
            return total;
        }

        return 0;
    }

    function hasFileErrors() {
        return Boolean(fileErrors && fileErrors.length > 0);
    }

    function getNextIndex() {
        if (!ctrl.managedItems || ctrl.managedItems.length <= 0) {
            return 0;
        }
        return (
            Math.max.apply(
                null,
                ctrl.managedItems.map(o => o.index)
            ) + 1
        );
    }

    function addManagedItem() {
        const lastIssueItem = ctrl.managedItems[ctrl.managedItems.length - 1];
        const newIssueItem = {
            index: getNextIndex(),
            account: null,
            checkNumber: null,
            checkAmount: null,
            issuedDate: null,
            payee: null,
            type: null,
        };

        if (lastIssueItem != null) {
            incrementCheckNumber(newIssueItem, lastIssueItem.checkNumber);
            newIssueItem.account = lastIssueItem.account;
        }

        if (ctrl.tableController.pagination) {
            ctrl.tableController.pagination.focusedItem = ctrl.managedItems.length + 1;
        }

        ctrl.managedItems.push(newIssueItem);
    }

    function incrementCheckNumber(issueItem, lastCheckNumber) {
        if (!ctrl.controls.incrementCheckNumber || !/^[0-9]+$/.test(lastCheckNumber)) {
            return;
        }

        issueItem.checkNumber = parseInt(lastCheckNumber) + 1;
    }

    function canUpload() {
        return (
            ctrl.arpData.file &&
            ctrl.arpData.savedFormat &&
            (ctrl.arpData.arpAccount || isMultiAccountFormat)
        );
    }

    function updateIsMultiAccountFormat(savedFormat) {
        isMultiAccountFormat =
            isDelimitedFileMultiAccountFormat(savedFormat) ||
            isFixedFileMultiAccountFormat(savedFormat);
        if (isMultiAccountFormat) {
            ctrl.arpData.arpAccount = null;
        }
    }

    function accountControlShouldBeDisabled() {
        return (
            ctrl.companyAccounts == null || ctrl.arpData.savedFormat == null || isMultiAccountFormat
        );
    }

    function getAccountSelectInformationText() {
        if (accountControlShouldBeDisabled()) {
            return 'If the selected Upload Format includes an account number, selection of an account is not required.';
        }
        return 'If your format does not include an account number, please select an account.';
    }

    function isDelimitedFileMultiAccountFormat(savedFormat) {
        return (
            savedFormat.type === 'Delimited' &&
            savedFormat.accountNumberOrder &&
            savedFormat.accountNumberOrder > 0
        );
    }

    function isFixedFileMultiAccountFormat(savedFormat) {
        return (
            savedFormat.type === 'FixedPosition' &&
            savedFormat.accountNumberBegin &&
            savedFormat.accountNumberBegin > 0 &&
            savedFormat.accountNumberEnd &&
            savedFormat.accountNumberEnd > 0
        );
    }

    function isObject(value) {
        return value != null && typeof value === 'object';
    }

    function onSelectFile(selectFileEvent) {
        const selectedFile = selectFileEvent.files[0];
        ctrl.arpData.file = selectedFile.rawFile;
        ctrl.arpData.fileName = selectedFile.name;
        selectedFileId = selectedFile.uid;
        $scope.$apply();
    }

    function onRemoveFile(removeFileEvent) {
        const fileRemoved = removeFileEvent.files[0];
        if (fileRemoved.uid === selectedFileId) {
            ctrl.arpData.file = null;
            ctrl.arpData.fileName = null;
            selectedFileId = null;
            $scope.$apply();
        }
    }

    function getAccounts() {
        arpService.getArpAccounts('UploadCreateArpFile').then(response => {
            ctrl.companyAccounts = response;
            if (response.length > 0 && response[0] != null) {
                ctrl.companyAccounts.forEach(account => {
                    account.customAccountNumberLabel = `${account.number} (${account.type})`;
                });
            }
        });
    }

    function getSavedFormats() {
        arpService.getSavedFormats().then(response => {
            ctrl.savedFormats = response;
        });
    }

    function init() {
        determineIfIssuedItemsActivityIsEnabled();
        getAccounts();
        getSavedFormats();
    }

    function setForm(form) {
        activeForm = form;
    }

    function isUpload() {
        return ctrl.arpData.isUpload;
    }

    function isManageStep() {
        return ctrl.wizardController.currentStep().name === 'Manage Issued Items';
    }

    function isReviewStep() {
        return ctrl.wizardController.currentStep().name === 'Review';
    }

    function isConfirmStep() {
        return ctrl.wizardController.currentStep().name === 'Confirmation';
    }

    function confirmToggle() {
        if (
            (activeForm && activeForm.$dirty) ||
            (ctrl.managedItems && ctrl.managedItems.length > 0)
        ) {
            const modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'OK',
                headerText: 'Undo Changes',
                bodyText: 'Are you sure you want to undo all of your changes?',
                submit() {
                    $modalInstance.close();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(
                () => {
                    toggle();
                },
                () => {
                    ctrl.arpData.isUploadSelection = ctrl.arpData.isUpload;
                }
            );
        } else {
            ctrl.arpData.isUpload = ctrl.arpData.isUploadSelection;
            if (ctrl.arpData.isUpload === false) {
                addManagedItem();
            }
        }
    }

    function toggle() {
        ctrl.arpData = {
            isUpload: ctrl.arpData.isUploadSelection,
            isUploadSelection: ctrl.arpData.isUploadSelection,
            arpAccount: null,
            issuedItems: null,
            savedFormat: null,
            file: null,
            fileName: null,
        };
        ctrl.managedItems = [];
        if (ctrl.arpData.isUpload === false) {
            ctrl.controls.incrementCheckNumber = false;
            addManagedItem();
        }
        isMultiAccountFormat = false;
    }

    function undo() {
        if (!ctrl.arpData.isUpload) {
            ctrl.arpData = {
                isUpload: ctrl.arpData.isUploadSelection,
                isUploadSelection: ctrl.arpData.isUploadSelection,
                arpAccount: null,
                issuedItems: null,
                savedFormat: null,
                file: null,
                fileName: null,
            };
        }
        ctrl.managedItems = getManagedItems(uploadedItems, true);

        if (ctrl.managedItems.length < 1) {
            addManagedItem();
        }

        activeForm.$setPristine(true);
    }

    function confirmUndo() {
        if ((activeForm && activeForm.$dirty) || ctrl.managedItems.length > 0) {
            const modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'OK',
                headerText: 'Undo Changes',
                bodyText: 'Are you sure you want to undo all of your changes?',
                submit() {
                    $modalInstance.close();
                    undo();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        }
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit() {
                $modalInstance.close();
                onCancelNavigation();
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function cancel() {
        if (
            (activeForm && activeForm.$dirty) ||
            (ctrl.managedItems.length > 0 && (isManageStep() || isReviewStep()))
        ) {
            confirmCancel();
        } else {
            onCancelNavigation();
        }
    }

    function onCancelNavigation() {
        $state.go(
            isIssuedItemsActivityEnabled ? 'payables.arp.activity' : 'payables.arp.check-exceptions'
        );
    }

    function findAccount() {
        const modalInstance = getAccountModalInstance();
        modalInstance.result.then(selectedAccount => {
            activeForm.$dirty = true;
            ctrl.arpData.arpAccount = angular.copy(selectedAccount);
        });
    }

    function setAccount(managedItem) {
        const modalInstance = getAccountModalInstance();
        modalInstance.result.then(selectedAccount => {
            activeForm.$dirty = true;
            managedItem.account = angular.copy(selectedAccount);
        });
    }

    function getAccountModalInstance() {
        return $uibModal.open({
            template: require('../views/arpAccountModalView.html'),
            size: 'md',
            controller: 'ArpAccountsController',
            backdrop: 'static',
            resolve: {
                companyAccounts() {
                    return ctrl.companyAccounts;
                },
            },
        });
    }

    function upload() {
        activityService.userInteractionAudit(
            resourceType.ArpResources,
            auditCode.ConfirmUploadIssuedItems
        );
        arpService
            .parseIssuedItems(ctrl.arpData.savedFormat.id, ctrl.arpData.file)
            .then(response => {
                if (response.fileErrors) {
                    fileErrors = response.fileErrors;
                } else {
                    uploadedItems = response.issuedItems;
                    ctrl.managedItems = getManagedItems(uploadedItems, true);
                    ctrl.wizardController.goToNextStep();
                }
            });
    }

    function getManagedItems(items, isUploaded) {
        const managedItems = angular.copy(items);
        managedItems.forEach(managedItem => {
            managedItem.isUploaded = isUploaded;
            if (isUploaded && !isMultiAccountFormat) {
                // Required to ignore the uploaded account if it is not a multi-account upload format - for backward compatibility.
                managedItem.account = ctrl.arpData.arpAccount;
            } else {
                managedItem.account = ctrl.companyAccounts.find(
                    account => account.id === managedItem.account.id
                );
            }
        });
        return managedItems;
    }

    function saveIssuedItems() {
        activityService.userInteractionAudit(
            resourceType.ArpResources,
            auditCode.ConfirmCreateIssuedItems
        );
        arpService.saveIssuedItems(ctrl.managedItems, ctrl.arpData.fileName).then(items => {
            ctrl.managedItems = getManagedItems(items, false);
            ctrl.wizardController.goToNextStep();
            ctrl.successMessage = createSuccessMessage(items);
            ctrl.failureMessage = createFailureMessage(items);
        });
    }

    function createSuccessMessage(items) {
        const count = items.filter(item => !item.errorSummary).length;
        if (count === 0) return '';
        return `Successfully processed ${count} Positive Pay ${count > 1 ? 'items' : 'item'}`;
    }

    function createFailureMessage(items) {
        const count = items.filter(item => Boolean(item.errorSummary)).length;
        if (count === 0) return '';
        return `Failed ${count} Positive Pay ${count > 1 ? 'items' : 'item'}`;
    }

    function deleteManagedItem(item, index) {
        if (ctrl.tableController.pagination) {
            const deletedItemPosition = ctrl.tableController.pageStart + index;
            const focusedItem = Math.min(deletedItemPosition, ctrl.managedItems.length - 1);
            ctrl.tableController.pagination.focusedItem = focusedItem;
        }

        ctrl.managedItems = ctrl.managedItems.filter(el => el.$$hashKey !== item.$$hashKey);

        if (ctrl.managedItems.length < 1) {
            addManagedItem();
            activeForm.$setPristine(true);
        }
    }

    function setTableController(tableController) {
        ctrl.tableController = tableController;
    }

    function isAccountInvalid(account, controlName) {
        const validAccount = isObject(account);
        const control = activeForm[controlName];
        const isDirty = control && control.$dirty;
        return isDirty && !validAccount;
    }

    function setDirty(controlName) {
        const control = activeForm[controlName];
        if (control) {
            control.$setDirty();
        }
    }

    function validateManagedItems() {
        // Summary now depends on valid items so all items have to be validated
        const newValidItems = [];
        let hasInvalidRow = false;
        if (ctrl.managedItems != null) {
            ctrl.managedItems.forEach(item => {
                const hasAccount = ctrl.isObject(item.account) === true;
                const hasCheckNumber = item.checkNumber != null;
                const hasType = item.type != null;
                const hasValidCheckAmount =
                    item.checkAmount != null || (hasType && item.type === 'Void');
                const thisRowValid = hasAccount && hasCheckNumber && hasType && hasValidCheckAmount;
                if (thisRowValid) {
                    newValidItems.push(item);
                }
                hasInvalidRow = hasInvalidRow || !thisRowValid;
            });
        }
        ctrl.validItems = newValidItems;
        areManagedItemsValid = !hasInvalidRow;
    }

    function determineIfIssuedItemsActivityIsEnabled() {
        navigationService.getNavigation().then(response => {
            for (let i = 0; i < response.length; i++) {
                if (foundIssuedItemsActivityMenu(response[i])) {
                    isIssuedItemsActivityEnabled = true;
                    break;
                }
            }
        });
    }

    function foundIssuedItemsActivityMenu(navigationMenu) {
        if (navigationMenu.key === 'arpActivity') {
            return true;
        }
        if (navigationMenu.subMenus && navigationMenu.subMenus.length) {
            for (let i = 0; i < navigationMenu.subMenus.length; i++) {
                if (foundIssuedItemsActivityMenu(navigationMenu.subMenus[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    function allowFifteenThousandFileRecords() {
        return entitlementsService.hasEntitlement('Feature.Arp.AllowFifteenThousandFileRecords');
    }

    init();
}
