import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

export const mapAchFileActivityFieldsToParameters = ({ parameters }) => {
    const { achCompanyList, achSourceList, uploadDate, achFileName } = parameters;

    const dateType = DateFormatters.getDateType(uploadDate);
    const dateValues = DateFormatters.parseDate(uploadDate)?.value;

    const mapped = {
        achCompanyList: achCompanyList === 'all' ? [] : achCompanyList,
        achSourceList: achSourceList === 'all' ? [] : achSourceList,
        achFileName,
    };

    if (dateType === 'dateRange') {
        mapped.uploadDateType = 'Range';
        mapped.uploadDateStart = dateValues.start;
        mapped.uploadDateEnd = dateValues.end;
    } else {
        mapped.uploadDateType = 'Specific Date';
        if (dateValues) {
            mapped.uploadDate = dateValues;
            mapped.uploadSpecificDate = dateValues;
        } else {
            mapped.uploadDate = '';
            mapped.uploadSpecificDate = '';
        }
    }
    return mapped;
};
