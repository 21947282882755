ChangeWireStatusDialogController.$inject = ['$scope', '$modalInstance', 'type', 'paymentType'];

export default function ChangeWireStatusDialogController(
    $scope,
    $modalInstance,
    type,
    paymentType
) {
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.type = type;
    $scope.paymentType = paymentType;
    $scope.comments = { value: '' };

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        $modalInstance.close($scope.comments.value);
    }

    function setForm(form) {
        $scope.form = form;
    }
}
