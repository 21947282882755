import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';
import { createUniqueId } from '@treasury/utils';

/**
 * @typedef { 'pdt' | 'pdb' | 'drt' | 'drb' |  'cdb' | 'cdt' } ReportType
 */

/**
 * @param { ng.resource.IResourceService } $resource
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
export function GenerateReportService($resource, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}generatereport/?type=:type`;
    return {
        generateReport,
    };

    /**
     *
     * @param { ReportType } type
     * @param { import('@treasury/api/channel').FilterBaseModelDto } filterMessage
     * @returns
     */
    function generateReport(type, filterMessage) {
        const resource = $resource(resourceUrl, { type });
        /**
         * @type { Promise<import('@treasury/api/channel').ReportResultBaseModelDto>}
         */
        const p = resource.save(filterMessage).$promise.then(response => {
            // use to determine if report value has changed with $scope.watch()
            response.clientId = createUniqueId();
            return response;
        });

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([response, analytics]) => {
            const { dateType, totalRecords: count } = response;
            const eventType = reportTypeToEvent(type);

            if (!eventType) {
                return;
            }

            analytics.track(eventType, {
                dateType,
                count: count ?? 0,
            });
        });

        return p;
    }
}

GenerateReportService.$inject = ['$resource', 'TmDi'];

/**
 * @param { ReportType } reportType
 */
function reportTypeToEvent(reportType) {
    switch (reportType) {
        case 'drb':
        case 'cdb':
        case 'pdb':
            return AnalyticsEvent.BalanceReport;
        case 'drt':
        case 'cdt':  
        case 'pdt':
            return AnalyticsEvent.TransactionReport;
        default:
            return undefined;
    }
}
