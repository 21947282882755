import { AchFilterServices } from '@treasury/domain/channel/services/ach';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';

export default class AchFilterClient {
    async search(...args) {
        return AchFilterServices.search(...args);
    }

    async download(...args) {
        return AchFilterServices.download(...args);
    }

    /**
     * @param { import('@treasury/api/channel').SaveAchFiltersRequestModelDto} dto
     */
    async saveFilter(dto) {
        return AchFilterServices.saveFilter(dto, 'create');
    }

    /**
     * @param { import('@treasury/api/channel').SaveAchFiltersRequestModelDto} dto
     */
    async editFilter(dto) {
        return AchFilterServices.saveFilter(dto, 'edit');
    }

    async getAuditLog(...args) {
        return AchFilterServices.getAuditLog(...args);
    }

    getAccountSettings() {
        return AchFilterServices.getAccountSettings();
    }

    async getAccountsAll(accountSettings) {
        return AchFilterServices.getAccountsAll(accountSettings);
    }

    async getAccountsCreate() {
        return AchFilterServices.getAccountsCreate();
    }

    async getAccountInfo(...args) {
        return AchFilterServices.getAccountInfo(...args);
    }

    async getAccountUniqueIdById(...args) {
        return AchFilterServices.getAccountUniqueIdById(...args);
    }

    async getAccountMasterSettings(...args) {
        return AchFilterServices.getAccountMasterSettings(...args);
    }

    async lookupException(...args) {
        return AchFilterServices.lookupException(...args);
    }

    async mapException(...args) {
        return AchFilterServices.mapException(...args);
    }

    async approveFilter(...args) {
        return AchFilterServices.approveFilter(...args);
    }

    async deleteFilter(...args) {
        return AchFilterServices.deleteFilter(...args);
    }

    async rejectFilter(...args) {
        return AchFilterServices.rejectFilter(...args);
    }

    async canCreateAchFilter() {
        return EntitlementsService.hasEntitlement('Create ACH Filter');
    }

    async print(...args) {
        return UserActivityService.print(...args);
    }
}
