// cSpell:words securityquestions verifysecurityquestions
import { TmHttpClient, TmHttpClientCached, TmHttpResponseType } from '@treasury/core/http';

/**
 * Account related requests for Treasury
 * */
class AccountRequests {
    static async getCurrentUserAccountSettings() {
        return (await TmHttpClient.getInstance()).request(`account/`, {
            method: 'GET',
            maxAgeInSeconds: 30,
        });
    }

    static async getCurrentUser() {
        return (await TmHttpClient.getInstance()).request(`account/`, {
            method: 'GET',
            maxAgeInSeconds: 60000,
        });
    }

    static async updateCurrentUserAccountSettings(body) {
        return (await TmHttpClient.getInstance()).request(`account/`, { method: 'POST', body });
    }

    static async resetCurrentUserAccountCache() {
        const http = await TmHttpClient.getInstance();
        if (!(http instanceof TmHttpClientCached)) {
            throw new Error('Cannot reset HTTP cache. The client does not implement caching.');
        }

        return http.resetCachedValue(`account`, {
            method: 'GET',
        });
    }

    static async getSettings() {
        return (await TmHttpClient.getInstance()).request(`account/settings`, { method: 'GET' });
    }

    static async login(body) {
        return (await TmHttpClient.getInstance()).request(`account/login`, {
            method: 'POST',
            body,
        });
    }

    static async logout(body) {
        return (await TmHttpClient.getInstance()).request(`account/logoutWithUrl`, {
            method: 'POST',
            body,
            responseType: TmHttpResponseType.Text,
        });
    }

    static async saveSecurityQuestions(body) {
        return (await TmHttpClient.getInstance()).request(`account/securityquestions`, {
            method: 'POST',
            body,
        });
    }

    static async verifySecurityQuestions(body) {
        return (await TmHttpClient.getInstance()).request(`account/verifysecurityquestions`, {
            method: 'PUT',
            body,
        });
    }

    static async registerSecureToken({ credentialId, token, pin }) {
        return (await TmHttpClient.getInstance()).request(
            `security/complete-secure-token-config?actionType=3&credentialId=${encodeURIComponent(
                credentialId
            )}&token=${encodeURIComponent(token)}&pin=${encodeURIComponent(pin)}`,
            { method: 'GET' }
        );
    }

    static async forgotPassword(body) {
        return (await TmHttpClient.getInstance()).request('account/forgotpassword', {
            method: 'POST',
            body,
        });
    }

    static async verifyUser(body) {
        return (await TmHttpClient.getInstance()).request(`account/verifyuser`, {
            method: 'PUT',
            body: { ...body, userName: body.loginId },
        });
    }

    static async getPasswordRequirements(institution) {
        return (await TmHttpClient.getInstance()).request(
            `account/passwordrequirements?fiId=${institution}`,
            {
                method: 'GET',
            }
        );
    }

    static async updatePassword(body) {
        return (await TmHttpClient.getInstance()).request(`account/updatePassword`, {
            method: 'PUT',
            body,
        });
    }

    static async changePassword(body) {
        return (await TmHttpClient.getInstance()).request(`account/changePassword`, {
            method: 'POST',
            body,
        });
    }

    static async checkLoginCompletion() {
        return (await TmHttpClient.getInstance()).request(`account/checkLoginCompletion`, {
            method: 'GET',
        });
    }

    static async sendOneTimePassword(phoneNumber, method, useForBoth) {
        return (await TmHttpClient.getInstance()).request(
            `security/verifyMethod?useForBoth=${!!useForBoth}`,
            {
                method: 'POST',
                body: {
                    phoneNumber,
                    method,
                },
            }
        );
    }

    static async validateOneTimePassword(oneTimePassword) {
        return (await TmHttpClient.getInstance()).request(`security/validateOneTimePassword`, {
            method: 'POST',
            body: {
                oneTimePassword,
            },
        });
    }

    static async validateSecureToken(token) {
        return (await TmHttpClient.getInstance()).request('security/validateSecureToken', {
            method: 'POST',
            body: {
                token,
            },
        });
    }

    static async setOutOfBandPreferredMethod(method) {
        return (await TmHttpClient.getInstance()).request(
            `security/setPreferredMethod?methodType=${encodeURIComponent(method)}`,
            {
                method: 'GET',
            }
        );
    }

    static async oobRemindMeLater() {
        return (await TmHttpClient.getInstance()).request(
            `security/complete-oob-config?action=Remind`,
            {
                method: 'GET',
            }
        );
    }

    static async fetchOutOfBandConfig() {
        return (await TmHttpClient.getInstance()).request(`security/config`, {
            method: 'GET',
        });
    }

    static async removeVerificationMethod(type) {
        return (await TmHttpClient.getInstance()).request(
            `security/removeMethod?methodType=${encodeURIComponent(type)}`,
            {
                method: 'DELETE',
            }
        );
    }

    static async completeOutOfBandConfig() {
        return (await TmHttpClient.getInstance()).request(
            `security/complete-oob-config?action=Done`,
            {
                method: 'GET',
            }
        );
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default AccountRequests;
