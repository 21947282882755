import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-select';
import { html, LitElement } from 'lit';

import {
    createFieldTypes,
    getDefaultValues,
    getValues,
    mapToRequestBody,
} from '../data/security-questions-data.js';

export default class SecurityQuestions extends ListeningElementMixin(LitElement) {
    static get properties() {
        return {
            readOnly: { type: Boolean, attribute: 'readonly' },
            securityQuestions: Array,
            numberOfSecurityQuestions: Number,
            record: Object,
            isValid: Boolean,
            loading: Boolean,
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.record = new Record(
            createFieldTypes(this.numberOfSecurityQuestions, this.securityQuestions, this.readOnly),
            this.readOnly
                ? getValues(this.securityQuestions)
                : getDefaultValues(this.numberOfSecurityQuestions)
        );
        this.listenTo(this.record, 'change', () => this.formIsValid());
    }

    submit() {
        const result = mapToRequestBody(this.record.values);
        this.dispatchEvent(new CustomEvent('submit', { detail: { value: result } }));
    }

    formIsValid() {
        this.isValid = this.record.isValid() && this.record.hasRequiredValues();
    }

    render() {
        const securityQuestions = Array(this.numberOfSecurityQuestions).fill();
        return html`
            <style>
                :host {
                    display: block;
                }
                .content {
                    margin: 15px;
                }
                .footer {
                    padding: 4px 15px;
                    border-top: 1px solid #ccc;
                }
                .info-box {
                    border: 1px solid #4384b5;
                    border-left: 4px solid #4384b5;
                    padding: 15px;
                    margin-bottom: 15px;
                    display: flex;
                }
                omega-icon {
                    margin-right: 16px;
                    color: #4384b5;
                    font-size: 24px;
                    position: relative;
                    top: -4px;
                }
                omega-field {
                    margin-bottom: 12px;
                    --omega-field-label-width: 100px;
                    --omega-field-control-width: 350px;
                    max-width: 480px;
                }
                .q-a-set {
                    margin-bottom: 32px;
                }
            </style>
            <div class="content">
                <div class="info-box">
                    <omega-icon icon="info-circle"></omega-icon>
                    For additional authentication purposes, please select
                    ${this.numberOfSecurityQuestions} security questions and provide an answer for
                    each question. You may be prompted to answer security questions at login.
                </div>
                ${securityQuestions.map(
                    (val, idx) => html`<div class="q-a-set">
                        <omega-field
                            label="Question: ${idx + 1}"
                            .record=${this.record}
                            field="question_${idx}"
                        ></omega-field>
                        <omega-field
                            label="Answer:"
                            .record=${this.record}
                            field="answer_${idx}"
                        ></omega-field>
                    </div>`
                )}
            </div>
            <div class="footer">
                <omega-button
                    type="primary"
                    @click="${this.submit}"
                    .loading=${this.loading}
                    ?disabled=${!this.isValid}
                    >Submit
                </omega-button>
            </div>
        `;
    }
}

window.customElements.define('security-questions', SecurityQuestions);
