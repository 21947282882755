import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-dialog';
import { paymentCanBeEdited } from '@treasury/policy/transfers';
import { deepEquals } from '@treasury/utils';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';

class EditTransferDetails extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            isEditing: { type: Boolean, reflect: true },
            record: Object,
            config: Object,
            recordset: Object,
            clonedRecord: Object,
            recordWasEdited: Boolean,
            isViewOnly: Boolean,
            showDiscardEditsDialog: Boolean,
            hasRequiredValues: Boolean,
            canApproveOrReject: Boolean,
            selectedTransferRecords: Array,
            setActionDialogProperties: Function,
        };
    }

    constructor() {
        super();
        this.isEditing = false;
        this.recordWasEdited = false;
        this.isViewOnly = true;
        this.showDiscardEditsDialog = false;
        this.canApproveOrReject = false;
    }

    firstUpdated() {
        this.clonedRecord = this.cloneRecord();
        this.clonedRecord.values = { ...this.record.values };
        this.isViewOnly = this.getViewOnlyStatus(this.clonedRecord.values);
        const clonedInitialValues = this.getEditableFieldValues(this.clonedRecord.initialValues);
        this.listenTo(this.clonedRecord, 'change', () => {
            this.recordWasEdited = !deepEquals(
                clonedInitialValues,
                this.getEditableFieldValues(this.clonedRecord.values)
            );
            this.hasRequiredValues = this.clonedRecord.hasRequiredValues();
            this.renderActionButtons();
        });
        this.canApproveOrReject = this.setApproveOrRejectStatus();
        this.selectedTransferRecords = [this.clonedRecord];
    }

    cloneRecord() {
        return this.record.clone();
    }

    getViewOnlyStatus({ status, transferDate, permissions }) {
        return !paymentCanBeEdited(status, transferDate, permissions, this.config);
    }

    getEditableFieldValues({ amount, fromAccount, toAccount, transferDate, memo }) {
        return {
            amount,
            fromAccount,
            toAccount,
            transferDate,
            memo: memo ?? '',
        };
    }

    checkEffectiveDate(date) {
        const today = new Date();
        return new Date(date).setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
    }

    updateEditingState() {
        this.isEditing = !this.isEditing;
        this.clonedRecord.setField('editable', !this.clonedRecord.getField('editable'));
        if (this.checkEffectiveDate(this.clonedRecord.getField('transferDate'))) {
            this.clonedRecord.setField('transferDate', null);
        }
    }

    confirmCancelEdit() {
        this.showDiscardEditsDialog = false;
        this.updateEditingState();
        this.dispatchEvent(new CustomEvent('close'));
    }

    closeEditMode() {
        if (this.recordWasEdited) {
            this.showDiscardEditsDialog = true;
        } else {
            this.dispatchEvent(new CustomEvent('close'));
        }
    }

    setApproveOrRejectStatus() {
        return (
            this.record.getField('status') === 'PendingApproval' &&
            this.record.getField('permissions').some(e => e.permission === 'Approve')
        );
    }

    renderEditSaveButton() {
        if (this.isViewOnly) return nothing;
        if (!this.isEditing) {
            return html` <omega-button
                type="primary"
                .disabled=${this.record.values.parentFrequencyModel !== null}
                @click=${() => {
                    this.updateEditingState();
                }}
                >Edit Transfer</omega-button
            >`;
        }
        return html` <omega-button
            type="primary"
            @click=${() => this.setActionDialogProperties('resubmit', this.selectedTransferRecords)}
            .disabled=${!this.recordWasEdited || !this.clonedRecord.hasRequiredValues()}
            >Apply Changes</omega-button
        >`;
    }

    renderApproveRejectButtons() {
        if (!this.canApproveOrReject) return nothing;
        return html`<div>
            <omega-button
                type="approve"
                @click=${() =>
                    this.setActionDialogProperties('approve', this.selectedTransferRecords)}
                >Approve</omega-button
            >
            <omega-button
                type="reject"
                @click=${() =>
                    this.setActionDialogProperties('reject', this.selectedTransferRecords)}
                >Reject</omega-button
            >
        </div>`;
    }

    renderActionButtons() {
        const buttonStyle = this.isViewOnly && !this.canApproveOrReject ? 'secondary' : 'link';
        return html`
            ${this.renderApproveRejectButtons()} ${this.renderEditSaveButton()}
            <omega-button type=${buttonStyle} @click=${() => this.closeEditMode()}
                >Close</omega-button
            >
        `;
    }

    renderDiscardEditsDialog() {
        if (this.showDiscardEditsDialog) {
            return html`<omega-dialog
                id="cancel-edits-dialog"
                open
                .dialogTitle=${'Discard changes?'}
                @close=${() => {
                    this.showDiscardEditsDialog = false;
                }}
            >
                <div slot="content" style="padding: 0 15px 15px">
                    Are you sure you want to discard the changes to this transfer?
                </div>
                <omega-button
                    slot="actions"
                    type="reject"
                    @click=${() => {
                        this.confirmCancelEdit();
                    }}
                    }
                    >Discard Changes</omega-button
                >
                <omega-button
                    slot="actions"
                    @click=${() => {
                        this.showDiscardEditsDialog = false;
                    }}
                    >Cancel</omega-button
                >
            </omega-dialog>`;
        }
        return nothing;
    }

    render() {
        if (this.clonedRecord) {
            return html`${this.renderDiscardEditsDialog()}
                <div class="details-header">
                    <span class="title">
                        <h3>${this.clonedRecord.getField('transactionId')}</h3>
                        <b>-</b>
                        <h3>${this.clonedRecord.getField('status')}</h3>
                    </span>
                    <omega-button
                        type="icon"
                        icon="close"
                        hideLabel="always"
                        @click=${() => this.closeEditMode()}
                    ></omega-button>
                </div>
                <div class="omega-flex-form">
                    <div class="form-column">
                        <omega-field
                            field="transactionId"
                            label="Transaction ID"
                            .record=${this.clonedRecord}
                        ></omega-field>
                        <omega-field
                            field="fromAccount"
                            label="From Account"
                            .record=${this.clonedRecord}
                        ></omega-field>
                        <omega-field
                            field="toAccount"
                            label="To Account"
                            .record=${this.clonedRecord}
                        ></omega-field>
                        <omega-field
                            field="amount"
                            label="Amount"
                            .record=${this.clonedRecord}
                        ></omega-field>
                        <omega-field
                            field="transferDate"
                            label="Transfer Date"
                            .record=${this.clonedRecord}
                        ></omega-field>
                    </div>
                    <div class="form-column">
                        <omega-field
                            field="memo"
                            label="Memo"
                            .record=${this.clonedRecord}
                        ></omega-field>
                        <omega-field
                            field="audit"
                            label="Audit"
                            .record=${this.clonedRecord}
                        ></omega-field>
                    </div>
                </div>
                <omega-button-bar alignment="left">
                    ${this.renderActionButtons()}
                </omega-button-bar> `;
        }
        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .title {
                align-self: center;
            }
            .title h3 {
                margin: 5px;
                display: inline-block;
            }
            .details-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid var(--omega-secondary-lighten-300);
                padding: 0 10px;
            }
            .omega-flex-form {
                display: flex;
            }
            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }
            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }
            .omega-flex-form omega-field {
                --omega-field-control-width: 120px;
                margin: 10px;
                min-height: 32px;
            }
            omega-button-bar {
                border-top: 1px solid var(--omega-secondary-lighten-300);
            }
        `;
    }
}

customElements.define('edit-transfer-details', EditTransferDetails);
