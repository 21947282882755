/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { CompanyUserAccountSettingsModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class CompanyUserAccountSettingsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags CompanyUserAccountSettings
     * @name CompanyUserAccountSettingsGet
     * @request GET:/companyUserAccountSettings
     */
    companyUserAccountSettingsGet = (params: RequestParams = {}) =>
        this.http.request<CompanyUserAccountSettingsModelDto, any>({
            path: `/companyUserAccountSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUserAccountSettings
     * @name CompanyUserAccountSettingsPut
     * @request PUT:/companyUserAccountSettings
     */
    companyUserAccountSettingsPut = (
        companyUserAccountSettings: CompanyUserAccountSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyUserAccountSettingsModelDto, any>({
            path: `/companyUserAccountSettings`,
            method: 'PUT',
            body: companyUserAccountSettings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
