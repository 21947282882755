import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-table';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import ChannelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import ReportingDashboardClient from '../clients/reporting-dashboard-client.js';
import { columns, fields, filters } from '../data/report-dashboard.js';

class ReportingDashboardContainer extends mix(LitElement).with(
    ListeningElementMixin,
    ChannelAlertMixin
) {
    static get properties() {
        return {
            recordset: Object,
            loading: Boolean,
            alert: Object,
            fields: Object,
            filters: Array,
            columns: Array,
        };
    }

    constructor() {
        super();
        // this.tileType = 'report';
        // this.tileContainers = [];
        // this.reportTiles = [];
        this.client = new ReportingDashboardClient();
        this.fields = fields;
        this.columns = columns;
        this.filters = filters;
        this.actions = {
            routeToReport: async record => {
                const type = record.getField('type');
                const id = record.getField('id');
                const report = record.values;
                const navService = (await DiContainer.getInstance()).get(NavigationService);
                navService.navigate(`reports-${type.toLowerCase()}`, { type, id, report });
            },
        };
        this.rowsPerPage = 25;
    }

    async firstUpdated() {
        this.loading = true;
        try {
            this.recordset = new Recordset(this.fields, this.client.getReports);
            this.recordset.pageSize = 25;
            await this.recordset.requestUpdate();
            this.listenTo(this.recordset, 'updated', ({ detail }) =>
                this.favoriteReport(detail.record)
            );
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    promptForDeletion(record) {
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'warning',
            title: 'Confirm Deletion',
            posture: 'assertive',
            message: 'Are you sure you want to delete this report?',
            actions: html`<omega-button type="reject" @click=${() => this.deleteReport(record)}
                    >Delete</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert = { ...this.alert, visible: false, posture: 'polite', type: '' };
                    }}
                    >Cancel</omega-button
                >`,
        };
    }

    async deleteReport(record) {
        const reportId = record.getField('id');
        try {
            await this.client.deleteReport(reportId);
            this.recordset.requestUpdate();
        } catch (e) {
            this.setAlertFromError(e);
        }
    }

    async favoriteReport(record) {
        const reportId = record.getField('id');
        const isFavorite = record.getField('isFavoriteReport');
        try {
            await this.client.favoriteReport({ reportId, isFavorite });
        } catch (e) {
            this.setAlertFromError(e);
        }
    }

    handleAction({ action, record, rowIndex }) {
        const actionFunction = this.actions[action];
        if (actionFunction) actionFunction(record, rowIndex);
    }

    renderTable() {
        if (!this.recordset) return nothing;
        return html`<omega-filter-bar
                .filters=${this.filters}
                .recordset=${this.recordset}
                @change=${e => {
                    this.tableFilters = e.detail;
                }}
            ></omega-filter-bar>
            <omega-table
                .loading=${this.loading}
                .rowsPerPage=${this.rowsPerPage}
                .recordset=${this.recordset}
                .filters=${this.filters}
                .columnDefinitions=${this.columns}
                @action=${({ detail }) => this.handleAction(detail)}
            ></omega-table>`;
    }

    render() {
        return html`
            ${this.renderAlert()}
            <div id="reporting-dashboard-container">
                <h2 id="reporting-header">Reporting</h2>
                ${this.renderTable()}
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #reporting-dashboard-container {
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                padding: 0 5px;
                box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
            }
            #reporting-header {
                border-bottom: 1px solid #eeededfd;
            }
        `;
    }
}

customElements.define('reporting-dashboard-container', ReportingDashboardContainer);
