/* eslint-disable import/no-extraneous-dependencies */
// cspell:words currentcolor
import { css, html, LitElement } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';

class OmegaIcon extends LitElement {
    static get properties() {
        return {
            icon: { type: String },
            description: { type: String },
        };
    }

    render() {
        return html`<i
            class="fa fa-${this.icon} icon"
            role="img"
            aria-label="${this.description}"
        ></i>`;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                .fa.icon {
                    font-size: var(--omega-icon-font-size, 1em);
                    color: var(--omega-icon-color, currentcolor);
                }

                .fa.icon.danger {
                    color: #ee3a3b;
                }
            `,
        ];
    }
}

customElements.define('omega-icon', OmegaIcon);
export default OmegaIcon;
