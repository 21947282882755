import { HolidayCalendarModelDto, ModelBaseDto } from '@treasury/api/channel';

export const defaultModelBaseDto: ModelBaseDto = {
    id: 0,
    updatedDate: '',
};

export const defaultHolidayCalendarModelDto: HolidayCalendarModelDto = {
    ...defaultModelBaseDto,
    name: '',
    date: '',
    isPastDue: false,
};
