/* eslint-disable max-classes-per-file */
import { Injectable } from '@jack-henry/frontend-utils/di';
import { IIssuedItem, IssuedItemPendingStatus } from '@treasury/domain/channel/mappings/arp';
import { VoidSelectionState } from '../templates';
import { IssuedItemMatchVm } from '../view-models';

interface VoidMatchState {
    matches: IssuedItemMatchVm[];
    uiState?: VoidSelectionState;
}

/**
 * State store for voidable matches for an incomplete `IIssuedItem`.
 * Used to communicates across `<void-matches-detail-row>` components.
 */
@Injectable()
export class VoidMatchesDatastore {
    /**
     * A mapping of potential check matches found in the system for each void from the previous step, keyed on the original item.
     */
    private voidResults = new Map<IIssuedItem, VoidMatchState>();

    /**
     * A map of an `IIssuedItem` to its corresponding match view model.
     *
     * This exists primarily because putting `IssuedItemMatchVm` instances
     * directly into a `Recordset` for display in Omega table is difficult
     * due to lack of support for displaying nested objects in rows. Items
     * coming from the recordset are used to find their corresponding view model.
     */
    private itemMappings = new WeakMap<IIssuedItem, IssuedItemMatchVm>();

    /**
     * Remove matches and UI state for a partial issued item.
     */
    public removeData(item: IIssuedItem) {
        this.getMatches(item).forEach(match => this.itemMappings.delete(match));
        this.voidResults.delete(item);
    }

    /**
     * Remove all matches for all items from the datastore.
     */
    public clear() {
        this.voidResults.clear();
        this.itemMappings = new WeakMap();
    }

    /**
     * Associate matches with a partial issued item.
     */
    public addMatches(original: IIssuedItem, matches: IIssuedItem[]) {
        const viewModels = matches.map(
            match => this.getViewModel(match) || new IssuedItemMatchVm(match)
        );

        this.voidResults.set(original, {
            matches: viewModels,
        });

        viewModels.forEach(vm => {
            this.itemMappings.set(vm.item, vm);
        });
    }

    public getMatches(original: IIssuedItem) {
        return this.voidResults.get(original)?.matches.map(match => match.item) || [];
    }

    /**
     * Replace a partial, original issued item with one or more exact matches,
     * update existing match lists,
     * and clear the original match results from the datastore.
     */
    public replaceWithSelected(original: IIssuedItem, selected: IIssuedItem[]) {
        this.removeData(original);
        selected.forEach(item => {
            item.pendingStatus = IssuedItemPendingStatus.SelectedFromMatches;
            item.itemEntryType = original.itemEntryType;
        });

        Array.from(this.voidResults)
            .map(([, result]) => result.matches)
            .forEach(matches => {
                // update match lists to account for newly-selected items
                const duplicates = matches
                    .map(m => m.item)
                    .filter(match => selected.some(s => s.compare(match)));

                duplicates.forEach(d => {
                    d.pendingStatus = IssuedItemPendingStatus.PreviouslySelected;
                });
            });
    }

    public setUiState(item: IIssuedItem, state: VoidSelectionState) {
        const results = this.voidResults.get(item);
        if (!results) {
            return;
        }

        results.uiState = state;

        this.voidResults.set(item, results);
    }

    public getUiState(item: IIssuedItem) {
        return this.voidResults.get(item)?.uiState;
    }

    /**
     * Get the view model associated with an issued item found in a match list.
     *
     * This exists primarily because the relevant `Recordset` contains `IIssuedItem`
     * instances rather than view model objects for ease of displaying in a table.
     */
    public getViewModel(item: IIssuedItem) {
        return this.itemMappings.get(item);
    }
}
