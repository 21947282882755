import { v4 as uuid } from 'uuid';

/**
 * Generate a pseudo-unique identifier.
 * Suitable for client-side unique identifiers like hash keys.
 * Should not be used in applications where true universal uniqueness is required.
 */
export function createUniqueId() {
    return uuid();
}
