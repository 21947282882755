ValidationGroup.$inject = [];

export default function ValidationGroup() {
    const controllers = {};

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };

    function link(scope, element, attrs, ctrl) {
        const groupName = attrs['validation-group'];

        if (!controllers[groupName]) {
            controllers[groupName] = [];
        }

        const controllerGroup = controllers[groupName];

        controllerGroup.push(ctrl);

        scope.$watch(
            () => ctrl.$modelValue,
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    angular.forEach(controllerGroup, target => {
                        if (ctrl !== target) {
                            target.$validate();
                        }
                    });
                }
            }
        );

        scope.$on('$destroy', () => {
            const group = controllers[groupName];

            if (group) {
                const index = group.indexOf(ctrl);

                if (index >= 0) {
                    group.splice(index, 1);
                }

                if (group.length <= 0) {
                    delete controllers[groupName];
                }
            }
        });
    }
}
