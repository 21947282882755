// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping API calls associated with ACH Templates.
 */
class AchTaxTemplateRequests {
    static async fetchAchTaxTemplates(body) {
        return (await TmHttpClient.getInstance()).request(`achBatches/searchTaxTemplates`, {
            method: 'POST',
            body,
        });
    }

    static async initiateTaxPaymentsByIds(body) {
        const dynamicEndpoint = body.length > 1 ? 'Tax' : '';
        return (await TmHttpClient.getInstance()).request(`achBatches/initiate${dynamicEndpoint}`, {
            method: 'POST',
            body,
        });
    }

    static async deleteTemplateById(id) {
        return (await TmHttpClient.getInstance()).request(`achBatches/delete`, {
            method: 'DELETE',
            body: {
                achBatchId: id,
                securityMessage: {
                    actionType: 'Delete Batch',
                    errorCode: null,
                    hasAlternate: false,
                    message: null,
                    methodUsed: null,
                    oneTimePassword: null,
                    status: null,
                },
            },
        });
    }

    static async updateTemplateStatus(actionType, action, { templateId, comments }) {
        return (await TmHttpClient.getInstance()).request(`achBatches/${templateId}/${action}`, {
            method: 'POST',
            body: {
                id: templateId,
                securityMessage: {
                    actionType,
                    status: null,
                    methodUsed: null,
                    hasAlternate: false,
                    errorCode: null,
                    message: null,
                    oneTimePassword: null,
                },
                value: comments,
            },
        });
    }
}

export default AchTaxTemplateRequests;
