PosPayFileMappingController.$inject = ['$scope', 'arpService', '$state', '$stateParams', 'toaster'];

export default function PosPayFileMappingController(
    $scope,
    arpService,
    $state,
    $stateParams,
    toaster
) {
    let dataLoaded = false;
    let formatId = 0;
    const ctrl = this;
    let selectedFormatType = 'Delimited';
    let formInstance = null;
    const fixedPositionText =
        'To add or edit a Fixed Position file upload format, select the position of the columns in your file.';
    const delimitedText =
        'To add or edit a Delimited file upload format, select the order of the columns in your file.';
    const accountTypeIndicatorErrorText = 'Indicator must be unique';

    const positionErrorCodes = {
        min: 'Value must be greater than 0.  ',
        fixedPositionSequenceError: 'End value cannot be less than the begin value.  ',
        fixedPositionFieldsOverlapping: 'Overlapping position values.  ',
        delimitedInvalid: 'All values must be unique.  ',
    };

    const positionFieldNames = [
        'issueDate',
        'itemAmount',
        'itemNumber',
        'accountNumber',
        'accountType',
        'payee',
        'voidDate',
        'voidIndicator',
    ];

    ctrl.dateFormatOptions = [
        'dddyy',
        'dddyyyy',
        'ddmmyy',
        'ddyymm',
        'ddmmyyyy',
        'mmddyy',
        'mmddyyyy',
        'mmyydd',
        'yyddd',
        'yyddmm',
        'yymmdd',
        'yyyyddd',
        'yyyyddmm',
        'yyyymmdd',
        'mm/dd/yyyy',
        'mm/dd/yy',
    ];

    ctrl.voidDateFormatOptions = ['', ...ctrl.dateFormatOptions];

    ctrl.editedFormat = {
        itemNumberOrder: null,
        itemNumberBegin: null,
        itemNumberEnd: null,
        itemAmountOrder: null,
        itemAmountBegin: null,
        itemAmountEnd: null,
        itemAmountFormat: 'DecimalIncluded',
        itemAmountDecimal: null,
        issueDateOrder: null,
        issueDateBegin: null,
        issueDateEnd: null,
        issueDateFormat: 'mm/dd/yyyy',
        payeeOrder: null,
        payeeBegin: null,
        payeeEnd: null,
        accountNumberOrder: null,
        accountNumberBegin: null,
        accountNumberEnd: null,
        accountTypeOrder: null,
        accountTypeBegin: null,
        accountTypeEnd: null,
        voidDateOrder: null,
        voidDateBegin: null,
        voidDateEnd: null,
        voidDateFormat: '',
        voidIndicatorOrder: null,
        voidIndicatorBegin: null,
        voidIndicatorEnd: null,
        textQualifier: "'",
        fieldDelimiter: ',',
        headerRows: 0,
        footerRows: 0,
    };

    ctrl.errorIndicators = {
        issueDate: '',
        itemAmount: '',
        itemNumber: '',
        accountNumber: '',
        accountType: '',
        payee: '',
        voidDate: '',
        voidIndicator: '',
        accountTypeIndicators: '',
    };

    ctrl.cancel = cancel;
    ctrl.save = save;
    ctrl.setForm = setForm;
    ctrl.itemAmountType = 'DecimalIncluded';
    ctrl.isTabSelected = isTabSelected;
    ctrl.setTypeToDelimited = setTypeToDelimited;
    ctrl.setTypeToFixedPosition = setTypeToFixedPosition;
    ctrl.changeItemAmountType = changeItemAmountType;
    ctrl.getFormatTypeInfoText = getFormatTypeInfoText;
    ctrl.headerRowText =
        'If your issued item file contains a header record or records, please enter the number of rows to be excluded';
    ctrl.footerRowText =
        'If your issued item file contains a footer record or records, please enter the number of rows to be excluded';

    $scope.$watch(() => ctrl.editedFormat, validateFormat, true);

    function getFormatTypeInfoText() {
        if (selectedFormatType === 'FixedPosition') {
            return fixedPositionText;
        }
        if (selectedFormatType === 'Delimited') {
            return delimitedText;
        }
        return '';
    }

    function validateFormat() {
        if (selectedFormatType === 'Delimited') {
            validateDelimitedFormat();
        } else {
            validateFixedPositionFormat();
        }

        validateCheckingAndSavingsIndicator();
        ctrl.itemAmountType = getItemAmountType();
    }

    function validateCheckingAndSavingsIndicator() {
        const form = formInstance;
        if (form != null) {
            if (ctrl.editedFormat) {
                if (
                    !(
                        ctrl.editedFormat.accountTypeCheckingEquals ||
                        ctrl.editedFormat.accountTypeSavingsEquals
                    )
                ) {
                    return;
                }
                if (
                    ctrl.editedFormat.accountTypeCheckingEquals ===
                    ctrl.editedFormat.accountTypeSavingsEquals
                ) {
                    markInvalid(form.checkingIndicatorInput);
                    markInvalid(form.savingsIndicatorInput);
                    ctrl.errorIndicators.accountTypeIndicators = accountTypeIndicatorErrorText;
                } else {
                    markValid(form.checkingIndicatorInput);
                    markValid(form.savingsIndicatorInput);
                    ctrl.errorIndicators.accountTypeIndicators = '';
                }
            }
        }
    }

    function validateFixedPositionFormat() {
        if (formInstance && ctrl.editedFormat) {
            const controlNames = [];
            const positionValues = [];

            // reset the error state
            for (let q = 0; q < positionFieldNames.length; q++) {
                const fieldName = positionFieldNames[q];
                const beginControlName = `${fieldName}Begin`;
                const endControlName = `${fieldName}End`;
                controlNames.push({ begin: beginControlName, end: endControlName });
                positionValues.push({
                    begin: ctrl.editedFormat[beginControlName],
                    end: ctrl.editedFormat[endControlName],
                });
                if (formInstance[beginControlName]) {
                    markValid(formInstance[beginControlName]);
                }
                if (formInstance[endControlName]) {
                    markValid(formInstance[endControlName]);
                }
                ctrl.errorIndicators[fieldName] = '';
            }

            // validations
            for (let i = 0; i < positionFieldNames.length; i++) {
                const iBeginValue = positionValues[i].begin;
                const iEndValue = positionValues[i].end;
                const iBeginControl = formInstance[controlNames[i].begin];
                const iEndControl = formInstance[controlNames[i].end];
                const iFieldName = positionFieldNames[i];
                let iOverlaps = false;
                let lessThanMin = false;

                // Test minimum value criterion
                // When min is set on the control, it returns a value of undefined if the input text is < min.
                // When required is set on the control, it returns undefined if the input text is deleted.
                if (iBeginValue === undefined || (iBeginValue !== null && iBeginValue <= 0)) {
                    markInvalid(iBeginControl);
                    lessThanMin = true;
                }

                if (iEndValue === undefined || (iEndValue !== null && iEndValue <= 0)) {
                    markInvalid(iEndControl);
                    lessThanMin = true;
                }

                if (lessThanMin) {
                    ctrl.errorIndicators[iFieldName] = positionErrorCodes.min;
                }

                // Test sequence of values for given field
                if (iBeginValue && iEndValue && iBeginValue > iEndValue) {
                    markInvalid(iEndControl);
                    addOrAppendErrorIndicator(
                        iFieldName,
                        positionErrorCodes.fixedPositionSequenceError
                    );
                }

                // Test for overlapping ranges between fields
                for (let j = 0; j < positionFieldNames.length; j++) {
                    if (i !== j) {
                        const jBeginValue = positionValues[j].begin;
                        const jEndValue = positionValues[j].end;

                        if (
                            iBeginValue &&
                            iBeginControl &&
                            ((jBeginValue != null &&
                                (iBeginValue === jBeginValue ||
                                    (iBeginValue > jBeginValue && iBeginValue <= jEndValue))) ||
                                (jEndValue != null &&
                                    (iBeginValue === jEndValue ||
                                        (iBeginValue > jEndValue && iBeginValue <= jBeginValue))))
                        ) {
                            markInvalid(iBeginControl);
                            iOverlaps = true;
                        }

                        if (
                            iEndValue &&
                            iEndControl &&
                            ((jBeginValue != null &&
                                (iEndValue === jBeginValue ||
                                    (iEndValue > jBeginValue && iEndValue <= jEndValue))) ||
                                (jEndValue != null &&
                                    (iEndValue === jEndValue ||
                                        (iEndValue > jEndValue && iEndValue <= jBeginValue))))
                        ) {
                            markInvalid(iEndControl);
                            iOverlaps = true;
                        }
                    }
                }
                if (iOverlaps) {
                    addOrAppendErrorIndicator(
                        iFieldName,
                        positionErrorCodes.fixedPositionFieldsOverlapping
                    );
                }
            }
        }
    }

    function validateDelimitedFormat() {
        if (formInstance) {
            if (ctrl.editedFormat) {
                const controlNames = [];
                const orderValues = [];

                for (let q = 0; q < positionFieldNames.length; q++) {
                    const fieldName = positionFieldNames[q];
                    const controlName = `${fieldName}Order`;
                    controlNames.push(controlName);
                    orderValues.push(ctrl.editedFormat[controlName]);
                    if (formInstance[controlName]) {
                        markValid(formInstance[controlName]);
                    }
                    ctrl.errorIndicators[fieldName] = '';
                }

                const invalidOrderText = positionErrorCodes.delimitedInvalid;

                for (let i = 0; i < positionFieldNames.length; i++) {
                    const iValue = orderValues[i];
                    const iControlName = controlNames[i];
                    // Test minimum value.
                    // When min is set on the control, it returns a value of undefined if the input text is < min.
                    // When required is set on the control, it returns undefined if the input text is deleted.
                    if (
                        formInstance[iControlName] &&
                        (iValue === undefined || (iValue !== null && iValue <= 0))
                    ) {
                        markInvalid(formInstance[iControlName]);
                        ctrl.errorIndicators[positionFieldNames[i]] = positionErrorCodes.min;
                    }
                    // Test duplicate order values
                    //  validation is symmetrical so do a triangular index to avoid redundant comparisons.
                    for (let j = i + 1; j < positionFieldNames.length; j++) {
                        const jValue = orderValues[j];
                        if (iValue && jValue && iValue === jValue) {
                            const jControlName = controlNames[j];
                            if (formInstance[iControlName]) {
                                markInvalid(formInstance[iControlName]);
                            }
                            if (formInstance[jControlName]) {
                                markInvalid(formInstance[jControlName]);
                            }
                            ctrl.errorIndicators[positionFieldNames[i]] = invalidOrderText;
                            ctrl.errorIndicators[positionFieldNames[j]] = invalidOrderText;
                            break; // If error is found, more comparisons in this sub-loop are redundant.
                        }
                    }
                }
            }
        }
    }

    function addOrAppendErrorIndicator(fieldName, errorMessage) {
        if (ctrl.errorIndicators[fieldName]) {
            ctrl.errorIndicators[fieldName] = `${ctrl.errorIndicators[fieldName]}\n${errorMessage}`;
        } else ctrl.errorIndicators[fieldName] = errorMessage;
    }

    function markInvalid(control) {
        if (control) {
            control.$setValidity('input', false);
            control.$setDirty();
        }
    }

    function markValid(control) {
        if (control) {
            control.$setValidity('input', true);
        }
    }

    function save() {
        ctrl.editedFormat.type = selectedFormatType;
        arpService.updateSavedFormat(ctrl.editedFormat).then(response => {
            if (response) {
                toaster.save('Positive Pay Upload Format');
                $state.go('payables.arp.arp-upload-format-list');
            }
        });
    }

    function cancel() {
        $state.go('payables.arp.arp-upload-format-list');
    }

    function loadSavedFormat(id) {
        if (!dataLoaded) {
            dataLoaded = true;
            arpService.getSavedFormat(id).then(response => {
                ctrl.editedFormat = response;
                if (ctrl.editedFormat && ctrl.editedFormat.type) {
                    selectedFormatType = ctrl.editedFormat.type;
                }
            });
        }
    }

    function getItemAmountType() {
        if (ctrl.editedFormat.itemAmountFormat) {
            if (ctrl.editedFormat.itemAmountFormat === 'DecimalNotIncluded') {
                if (ctrl.editedFormat.itemAmountDecimal === true) {
                    return 'WholeDollar';
                }
                return 'ImpliedDecimal';
            }
        }
        return 'DecimalIncluded';
    }

    function changeItemAmountType(type) {
        if (type === 'WholeDollar') {
            ctrl.editedFormat.itemAmountDecimal = true;
            ctrl.editedFormat.itemAmountFormat = 'DecimalNotIncluded';
        } else if (type === 'ImpliedDecimal') {
            ctrl.editedFormat.itemAmountDecimal = false;
            ctrl.editedFormat.itemAmountFormat = 'DecimalNotIncluded';
        } else {
            ctrl.editedFormat.itemAmountDecimal = null;
            ctrl.editedFormat.itemAmountFormat = 'DecimalIncluded';
        }
    }

    function setTypeToDelimited() {
        selectedFormatType = 'Delimited';
        validateDelimitedFormat();
    }

    function setTypeToFixedPosition() {
        selectedFormatType = 'FixedPosition';
        validateFixedPositionFormat();
    }

    function isTabSelected(tabName) {
        return tabName === selectedFormatType;
    }

    function setForm(form) {
        if (form) {
            formInstance = form;
        }
    }

    function init() {
        if ($stateParams && $stateParams.id) {
            formatId = $stateParams.id;
            loadSavedFormat(formatId);
        }
    }

    init();
}
