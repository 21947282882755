TransferAccountSelectionController.$inject = [
    '$scope',
    '$modalInstance',
    '$filter',
    'accountsList',
    'showCurrentBalanceForLoanAccounts',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function TransferAccountSelectionController(
    $scope,
    $modalInstance,
    $filter,
    accountsList,
    showCurrentBalanceForLoanAccounts
) {
    $scope.accountsList = accountsList;
    $scope.accountSearch = '';

    $scope.getBalanceDisplay = getBalanceDisplay;
    $scope.isNegativeBalance = isNegativeBalance;
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.filterAccounts = filterAccounts;

    function init() {
        $scope.orderByField = 'number';
        $scope.reverseSort = false;
        filterAccounts();
    }

    function getBalanceDisplay(account) {
        return $filter('negativeParentheses')(
            $filter('currency')(getAccountBalanceForDisplay(account))
        );
    }

    function getAccountBalanceForDisplay(account) {
        if (showCurrentBalanceForLoanAccounts && account.type === 'Loan') {
            return account.currentBalance;
        }

        return account.availableBalance;
    }

    function isNegativeBalance(account) {
        return getAccountBalanceForDisplay(account) < 0;
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function save(account) {
        $modalInstance.close(account);
    }

    function setForm(form) {
        $scope.form = form;
    }

    function filterAccounts() {
        const query = $scope.accountSearch.toLowerCase().trim();
        const columns = ['name', 'number'];

        $scope.filteredAccounts = $scope.accountsList.filter(account =>
            columns.some(column => account[column].toLowerCase().indexOf(query) !== -1)
        );
    }

    init();
}
