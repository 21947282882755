import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { Record } from '@treasury/FDL';
import fieldTypes from '@treasury/policy/primitives';

export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                id: '@',
                reportType: '=',
                generatedReport: '=',
                sidebarContainerController: '=',
                exportFilter: '=',
                searchOnLoad: '=?',
            },
            templateUrl: '/app/directives/components/reportFilter/reportFilterTemplate.html',
            controller: [
                '$scope',
                '$rootScope',
                '$stateParams',
                'informationReportsService',
                'companyAccountsService',
                '$state',
                'toaster',
                '$modal',
                'generateReportService',
                '$anchorScroll',
                '$location',
                'tranGroupCodeService',
                'dateFilterService',
                'DatePeriod',
                function (
                    $scope,
                    $rootScope,
                    $stateParams,
                    informationReportsService,
                    companyAccountsService,
                    $state,
                    toaster,
                    $modal,
                    generateReportService,
                    $anchorScroll,
                    $location,
                    tranGroupCodeService,
                    dateFilterService,
                    DatePeriod
                ) {
                    $scope.reportId = $stateParams.id;
                    $scope.filterHeight = 400;
                    $scope.showSubFilter = false;
                    $scope.dateDisabled = false;
                    $scope.savedReport = {};
                    $scope.tempSavedReport = {};
                    $scope.loanSelected = false;
                    $scope.searchOnLoad = angular.isDefined($scope.searchOnLoad)
                        ? $scope.searchOnLoad
                        : true;

                    $scope.disableRunReport = function () {
                        return (
                            !$scope.savedReport.accounts ||
                            $scope.savedReport.accounts.length === 0 ||
                            $scope.savedReport.accountTypes.length === 0 ||
                            ($scope.savedReport.dateType === '# of Prior Days' &&
                                $scope.filterObject.isDateVisible &&
                                ($scope.determineFinite($scope.savedReport.priorDays) === false ||
                                    Number($scope.savedReport.priorDays) === 0))
                        );
                    };

                    $scope.isWidthSmall = function (widthValue) {
                        return widthValue;
                    };

                    $scope.generate = async function () {
                        const type = reportType();
                        sanitizeDataForDatabase();
                        if (
                            $scope.savedReport.name === 'Date Range Balance' ||
                            $scope.savedReport.name === 'Date Range Transaction'
                        ) {
                            const period = dateFilterService.getPeriodDates(
                                $scope.savedReport.dateType,
                                $scope.savedReport.fromDate,
                                $scope.savedReport.toDate
                            );
                            if ($scope.savedReport.dateType === DatePeriod.TODAY) {
                                $scope.savedReport.specificDate = period.formattedToDate;
                            } else {
                                $scope.savedReport.toDate = period.formattedToDate;
                                $scope.savedReport.fromDate = period.formattedFromDate;
                            }
                        }
                        if ($scope.savedReport.type === 'Transaction') {
                            $scope.savedReport.type = 'TransactionFilter';
                        }
                        if ($scope.savedReport.type === 'Balance') {
                            $scope.savedReport.type = 'BalanceFilter';
                        }

                        if ($scope.loanSelected) {
                            $scope.savedReport.paymentTypes = [];
                            angular.forEach($scope.paymentTypesLst, paymentType => {
                                if (paymentType.isChecked)
                                    $scope.savedReport.paymentTypes.push({
                                        type: paymentType.name,
                                    });
                            });
                        }

                        generateReportService
                            .generateReport(type, prepareFilter($scope.savedReport))
                            .then(response => {
                                if (
                                    response.reportType === 'Transaction' &&
                                    response.dateType !== 'Date Range'
                                ) {
                                    let transactions;
                                    let summary;
                                    let checkingTransactions;
                                    let savingsTransactions;
                                    let loanTransactions;
                                    let showChecking;
                                    let showSavings;
                                    let showLoan;
                                    let showTotal;

                                    showChecking =
                                        $scope.savedReport.accountTypes.indexOf('Checking') !== -1;
                                    showSavings =
                                        $scope.savedReport.accountTypes.indexOf('Savings') !== -1;
                                    showLoan =
                                        $scope.savedReport.accountTypes.indexOf('Loan') !== -1;
                                    showTotal = showChecking && showSavings;

                                    transactions = response.data;

                                    checkingTransactions = transactions.filter(
                                        transaction => transaction.accountType === 'Checking'
                                    );
                                    savingsTransactions = transactions.filter(
                                        transaction => transaction.accountType === 'Savings'
                                    );
                                    loanTransactions = transactions.filter(
                                        transaction => transaction.accountType === 'Loan'
                                    );
                                    summary = {
                                        checking: {
                                            creditCount: 0,
                                            creditTotal: 0,
                                            debitCount: 0,
                                            debitTotal: 0,
                                            show: showChecking,
                                        },
                                        savings: {
                                            creditCount: 0,
                                            creditTotal: 0,
                                            debitCount: 0,
                                            debitTotal: 0,
                                            show: showSavings,
                                        },
                                        loan: {
                                            creditCount: 0,
                                            creditTotal: 0,
                                            debitCount: 0,
                                            debitTotal: 0,
                                            show: showLoan,
                                        },
                                        total: {
                                            creditCount: 0,
                                            creditTotal: 0,
                                            debitCount: 0,
                                            debitTotal: 0,
                                            show: showTotal,
                                        },
                                    };

                                    angular.forEach(
                                        checkingTransactions,
                                        getUpdateFn(summary.checking)
                                    );
                                    angular.forEach(
                                        savingsTransactions,
                                        getUpdateFn(summary.savings)
                                    );
                                    angular.forEach(loanTransactions, getUpdateFn(summary.loan));

                                    function getUpdateFn(summaryBranch) {
                                        return function (transaction) {
                                            summaryBranch.creditTotal += transaction.creditAmount;
                                            summary.total.creditTotal += transaction.creditAmount;
                                            summaryBranch.debitTotal += transaction.debitAmount;
                                            summary.total.debitTotal += transaction.debitAmount;
                                            if (transaction.creditAmount) {
                                                summaryBranch.creditCount += 1;
                                                summary.total.creditCount += 1;
                                            }
                                            if (transaction.debitAmount) {
                                                summaryBranch.debitCount += 1;
                                                summary.total.debitCount += 1;
                                            }
                                        };
                                    }

                                    response.summary = summary;
                                }

                                window.angular.copy(response, $scope.generatedReport);
                                $scope.savedReport.isAscendingSort =
                                    $scope.generatedReport.isAscending;
                                $scope.savedReport.sortBy = $scope.generatedReport.sortBy;
                                $scope.$emit('reportUpdated', {
                                    isAscending: $scope.generatedReport.isAscending,
                                    sortBy: $scope.generatedReport.sortBy,
                                });
                            });
                    };

                    $scope.scrollLocation = function (element) {
                        $location.hash(element);
                        $anchorScroll();
                    };

                    $scope.$watch(
                        'savedReport',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                if ($scope.savedReport.type === 'Transaction') {
                                    $scope.savedReport.type = 'TransactionFilter';
                                }
                                if ($scope.savedReport.type === 'Balance') {
                                    $scope.savedReport.type = 'BalanceFilter';
                                }
                                $scope.exportFilter.model = newValue;
                            }
                        },
                        true
                    );

                    $scope.$watch(
                        'savedReport.accountTypes',
                        newValue => {
                            if (
                                newValue !== null &&
                                newValue !== undefined &&
                                $scope.savedReport.accountTypes instanceof Array === true
                            ) {
                                $scope.$broadcast('accountTypes', newValue);
                            }
                        },
                        true
                    );

                    $scope.isLoanSelected = function () {
                        if (typeof $scope.savedReport.accountTypes === 'string') {
                            $scope.loanSelected =
                                $scope.savedReport.type === 'TransactionFilter' &&
                                $scope.savedReport.accountTypes === 'Loan';
                        } else {
                            $scope.loanSelected =
                                $scope.savedReport.type === 'TransactionFilter' &&
                                $scope.savedReport.accountTypes.reduce(
                                    (acc, val) => (val.name === 'Loan' && val.isChecked) || acc,
                                    false
                                );
                        }
                        return $scope.loanSelected;
                    };

                    $scope.filterObject = {
                        isReportTypeVisible: true,
                        isReportTypeDisabled: false,
                        isDateVisible: true,
                        isDateDisabled: false,
                        isAccountTypeVisible: true,
                        isAccountTypeDisabled: false,
                        isAccountVisible: true,
                        isAccountDisabled: false,
                        isCheckNumberVisible: true,
                        isPaymentIdTypeDisabled: false,
                        isAmountTypeVisible: true,
                        isAmountTypeDisabled: false,
                        isPaymentTypeVisible: true,
                        isPaymentTypeDisabled: false,
                        isTransactionTypeVisible: true,
                        isTransactionTypeDisabled: false,
                        isTransactionDirectionVisible: true,
                        isisTransactionDirectionDisabled: false,
                        isSortByVisible: true,
                        isSortByDisabled: false,
                        isAscendingDescendingVisible: true,
                        isAscendingDescendingDisabled: false,
                    };

                    $scope.sidebarContainerController.setCollapsed(true);

                    $scope.reportTypeList = [];
                    $scope.dateTypeList = [];
                    $scope.accountTypeList = [];
                    $scope.allAccountsList = [];
                    $scope.sortByList = [];
                    $scope.checkNumberList = [];
                    $scope.amountOperatorList = [];
                    $scope.paymentTypesLst = [];
                    $scope.transactionDirectionList = [];

                    $scope.processReportGeneration = function (report) {
                        $scope.savedReport.selectedLabel = report.name;
                        if (report.id !== $scope.savedReport.id) {
                            $scope.runReport(report);
                        }
                    };

                    $scope.runReport = function (report) {
                        informationReportsService.runReport(report);
                    };

                    function scrubData() {
                        $scope.savedReport.isOpen = false;

                        // Convert accountTypes string to array.
                        if (
                            $scope.savedReport.accountTypes !== null &&
                            $scope.savedReport.accountTypes !== undefined &&
                            $scope.savedReport.accountTypes instanceof Array === false
                        ) {
                            const split = $scope.savedReport.accountTypes.split(',');
                            $scope.savedReport.accountTypes = [];
                            angular.forEach($scope.accountTypeList, accountType => {
                                angular.forEach(split, item => {
                                    if (accountType.name === item.trim()) {
                                        if (split.length >= 1) {
                                            if (accountType.name != 'Loan') {
                                                accountType.isChecked = true;
                                            } else {
                                                accountType.isChecked = false;
                                            }
                                        }
                                        if (split.length === 1) {
                                            accountType.isChecked = true;
                                        }
                                        $scope.savedReport.accountTypes.push(accountType);
                                    }
                                });
                            });
                        }
                        $scope.savedReport.selectedLabel = $scope.savedReport.name;
                    }

                    function copyFilterRecordToSavedReport() {
                        const { values } = $scope.filterRecord;
                        const { savedReport } = $scope;

                        if (!(savedReport && savedReport.accounts)) return;

                        savedReport.accounts.length = 0;
                        $scope.allAccountsList.forEach(account => {
                            account.isChecked = values.accounts.indexOf(account.id) !== -1;
                            if (account.isChecked) {
                                savedReport.accounts.push(account);
                            }
                        });

                        savedReport.accountTypes = values.accountTypes.toString();
                    }

                    function setupFilterRecord(accountTypes, accountIds, accountDisplayField) {
                        $scope.filterRecord = new Record(
                            {
                                accounts: fieldTypes.string.with
                                    .options({
                                        fetch: record =>
                                            Promise.resolve(
                                                $scope.allAccountsList.filter(
                                                    account =>
                                                        record
                                                            .getField('accountTypes')
                                                            .split(',')
                                                            .indexOf(account.type) > -1
                                                )
                                            ),
                                        text: accountDisplayField,
                                        value(account) {
                                            return account.account.id;
                                        },
                                        fields: ['accountTypes'],
                                    })
                                    .thatHas.label('Account')
                                    .with.filtering()
                                    .and.multipleValues(),

                                accountTypes: fieldTypes.string.with
                                    .options([
                                        { text: 'Checking', value: 'Checking' },
                                        { text: 'Savings', value: 'Savings' },
                                        {
                                            text: 'Checking and Savings',
                                            value: 'Checking,Savings',
                                        },
                                        { text: 'Loan', value: 'Loan' },
                                    ])
                                    .thatHas.label('Account Type'),
                            },
                            {
                                accounts: accountIds,
                                accountTypes,
                            }
                        );

                        copyFilterRecordToSavedReport();
                        $scope.filterRecord.onChange(copyFilterRecordToSavedReport);
                    }

                    function loadReportFilter(isReset) {
                        informationReportsService.getFilter($scope.reportId).then(response => {
                            if (
                                response.fromDate !== null &&
                                response.fromDate !== undefined &&
                                response.fromDate.length > 0
                            ) {
                                response.fromDate = formatDate(response.fromDate);
                            }
                            if (
                                response.toDate !== null &&
                                response.toDate !== undefined &&
                                response.toDate.length > 0
                            ) {
                                response.toDate = formatDate(response.toDate);
                            }
                            $scope.savedReport = angular.copy(response);

                            loadAllAccountsList(isReset)
                                .then(response => {
                                    if (
                                        response.length > 0 &&
                                        response[0] !== null &&
                                        typeof response[0] !== 'undefined'
                                    ) {
                                        $scope.accountDisplayField = response[0].showAccountNickName
                                            ? 'name'
                                            : 'number';
                                    }

                                    return afterLoadingAccounts(response, isReset);
                                })
                                .then(response => {
                                    const options = response.sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    );
                                    return afterLoadingPaymentTypes(options, isReset);
                                })
                                .then(() => {
                                    if (!isReset && $scope.searchOnLoad) {
                                        $scope.generate();
                                    }
                                });

                            // we dont collapse date range reports
                            if (
                                $scope.reportType === 'date-range-transaction' ||
                                $scope.reportType === 'date-range-balance'
                            ) {
                                $scope.sidebarContainerController.setCollapsed(false);
                            }

                            determineFilterHeight();
                            $scope.tempSavedReport = angular.copy($scope.savedReport);
                        });
                    }

                    function determineFilterHeight() {
                        if (!$scope.savedReport.isStandardReport) $scope.filterHeight = 486;
                        else $scope.filterHeight = 398;

                        $scope.filterHeight -= 20;
                    }

                    function sanitizeDataForDatabase() {
                        $scope.savedReport.isOpen = false;
                        let tmpAccountTypes = '';
                        if ($scope.savedReport.accountTypes instanceof Array) {
                            angular.forEach($scope.savedReport.accountTypes, accountType => {
                                if (accountType.isChecked) {
                                    tmpAccountTypes += `${accountType.name},`;
                                }
                            });
                            tmpAccountTypes = tmpAccountTypes.substring(
                                0,
                                tmpAccountTypes.length - 1
                            );
                            $scope.savedReport.accountTypes = tmpAccountTypes;
                        }
                    }

                    $scope.save = function (blnSaveAs) {
                        sanitizeDataForDatabase();
                        if ($scope.savedReport.type === 'Transaction') {
                            $scope.savedReport.type = 'TransactionFilter';
                        }
                        if ($scope.savedReport.type === 'Balance') {
                            $scope.savedReport.type = 'BalanceFilter';
                        }

                        // if isStandardReport, must save
                        if ($scope.savedReport.isStandardReport || blnSaveAs) {
                            const modalInstance = $modal.open({
                                templateUrl:
                                    '/app/directives/components/reportFilter/saveReport/saveReportModalView.html',
                                size: 'md',
                                controller: 'SaveReportController',
                                backdrop: 'static',
                                resolve: {
                                    selectedReport() {
                                        return window.angular.copy($scope.savedReport);
                                    },
                                    blnSaveAs() {
                                        return blnSaveAs;
                                    },
                                },
                            });
                            modalInstance.result.then(addedReport => {
                                if (
                                    addedReport.fromDate !== null &&
                                    addedReport.fromDate !== undefined
                                ) {
                                    addedReport.fromDate = formatDate(addedReport.fromDate);
                                }
                                if (
                                    addedReport.toDate !== null &&
                                    addedReport.toDate !== undefined
                                ) {
                                    addedReport.toDate = formatDate(addedReport.toDate);
                                }
                                if (
                                    addedReport.priorDays !== null &&
                                    addedReport.priorDays !== undefined
                                ) {
                                    addedReport.priorDays = formatDate(addedReport.priorDays);
                                }
                                if (
                                    addedReport.specificDate !== null &&
                                    addedReport.specificDate !== undefined
                                ) {
                                    addedReport.specificDate = formatDate(addedReport.specificDate);
                                }
                                $scope.savedReport = addedReport;
                                determineFilterHeight();

                                // add new report to the list.
                                $scope.currentUserSavedReports.push(addedReport);
                                scrubData();
                                toaster.save('Report');
                                $scope.addedReport = addedReport;
                                //$scope.generate();
                                $scope.form.$invalid = false;
                                $scope.tempSavedReport = angular.copy($scope.savedReport);
                                const saveReportId = $scope.savedReport.id;
                                $scope.savedReport.id = 0; //reset savedReport id here to force runReport()
                                $scope.processReportGeneration($scope.tempSavedReport);
                                $scope.savedReport.id = saveReportId;
                            });
                        } else {
                            // update report
                            updateReport();
                            $scope.form.$invalid = false;

                            $scope.generate();
                        }
                    };

                    // used to set the form instance.
                    $scope.setForm = function (form) {
                        $scope.form = form;
                    };

                    function updateReport() {
                        $scope.savedReport.paymentTypes = [];
                        angular.forEach($scope.paymentTypesLst, paymentType => {
                            if (paymentType.isChecked)
                                $scope.savedReport.paymentTypes.push(paymentType);
                        });

                        informationReportsService
                            .updateFilter($scope.savedReport)
                            .then(response => {
                                toaster.save('Report');
                                $scope.savedReport = angular.copy(response);
                                scrubData();
                                $scope.savedReport.selectedLabel = $scope.savedReport.name;
                                $scope.form.$invalid = false;
                                $scope.tempSavedReport = angular.copy($scope.savedReport);
                            });
                    }

                    function loadStandardReports(
                        loadMyReportsCallback,
                        filterReportsCallback,
                        isReset
                    ) {
                        informationReportsService.getReportsOfType('standard').then(response => {
                            $scope.standardReportsList = response;
                            if (loadMyReportsCallback) {
                                loadMyReportsCallback(filterReportsCallback, isReset);
                            }
                        });
                    }

                    function loadMyReports(filterReportsCallback, isReset) {
                        informationReportsService.getReportsOfType('myreport').then(response => {
                            $scope.currentUserSavedReports = response;
                            if (filterReportsCallback) {
                                filterReportsCallback(isReset);
                            }
                        });
                    }

                    async function init() {
                        loadStandardReports(loadMyReports, loadReportFilter, false);
                        $scope.currentDate = moment().format('MM/DD/YYYY');
                        $scope.hasSortByTransactionTypeInDateRangeReport =
                            await FeatureFlagService.isEnabled(
                                'Feature.Report.SortByTransactionTypeInDateRangeReport.467082'
                            );
                    }

                    function formatDate(dt) {
                        return moment(new Date(dt)).format('MM/DD/YYYY');
                    }

                    $scope.reset = function () {
                        loadReportFilter(true);
                    };

                    function loadTransactionDirectionList() {
                        $scope.transactionDirectionList = [
                            {
                                name: 'Inbound',
                            },
                            {
                                name: 'Outbound',
                            },
                            {
                                name: 'Both',
                            },
                        ];
                    }

                    $scope.resetValues = function (type) {
                        if (type === 'amount') {
                            $scope.savedReport.fromAmount = null;
                            $scope.savedReport.toAmount = null;
                        }
                        if (type === 'date') {
                            $scope.savedReport.fromDate = null;
                            $scope.savedReport.toDate = null;
                            $scope.savedReport.priorDays = null;
                            $scope.savedReport.specificDate = null;
                        }
                    };

                    $scope.determineFinite = function (value) {
                        let result = true;
                        if (isFinite(value) === false) {
                            result = false;
                        }
                        return result;
                    };

                    function loadAmountOperatorList() {
                        $scope.amountOperatorList = [
                            {
                                name: 'Specific Amount',
                            },
                            {
                                name: 'Between',
                            },
                            {
                                name: 'Less Than',
                            },
                            {
                                name: 'Greater Than',
                            },
                        ];
                    }

                    function loadCheckNumberList() {
                        $scope.checkNumberList = [
                            {
                                name: 'Specific Check Number',
                            },
                            {
                                name: 'Check Number Range',
                            },
                        ];
                    }

                    function setVisibleAndDisabledAndControllersState() {
                        if (
                            $scope.reportType === 'day-balance' ||
                            $scope.reportType === 'date-range-balance'
                        ) {
                            $scope.filterObject.isCheckNumberVisible = false;
                            $scope.filterObject.isAmountTypeVisible = false;
                            $scope.filterObject.isCheckNumberVisible = false;
                            $scope.filterObject.isPaymentTypeVisible = false;
                            $scope.filterObject.isTransactionTypeVisible = false;
                            $scope.filterObject.isTransactionDirectionVisible = false;
                            if ($scope.savedReport.isStandardReport) {
                                // default is no accounts selected (because selecting all accounts creates performance issues)
                                angular.forEach($scope.allAccountsList, account => {
                                    account.isChecked = false;
                                });
                            }

                            // determine sort by types that are visible.
                            angular.forEach($scope.sortByList, sortBy => {
                                if ($scope.reportType === 'date-range-balance') {
                                    if (
                                        sortBy.name !== 'Date' &&
                                        sortBy.name !== 'Opening Balance' &&
                                        sortBy.name !== 'Collected Balance' &&
                                        sortBy.name !== 'Debits' &&
                                        sortBy.name !== 'Credits'
                                    ) {
                                        sortBy.isVisible = false;
                                    }
                                }
                                if ($scope.reportType === 'day-balance') {
                                    if (
                                        sortBy.name !== 'Debits' &&
                                        sortBy.name !== 'Credits' &&
                                        sortBy.name !== 'Available Balance' &&
                                        sortBy.name !== 'Current Balance' &&
                                        sortBy.name !== 'Collected Balance' &&
                                        sortBy.name !== 'Account Type' &&
                                        sortBy.name !== 'Account Name' &&
                                        sortBy.name !== 'Account Number'
                                    ) {
                                        sortBy.isVisible = false;
                                    }
                                }
                            });
                            $scope.showSubFilter = false;
                        }

                        if (
                            ($scope.reportType === 'date-range-transaction' ||
                                $scope.reportType === 'day-transaction') &&
                            $scope.savedReport.isStandardReport
                        ) {
                            // reset default values for transaction reports
                            $scope.savedReport.checkNumberOperator = null;
                            $scope.savedReport.specificPaymentId = null;
                            $scope.savedReport.fromPaymentId = null;
                            $scope.savedReport.toPaymentId = null;
                            $scope.savedReport.amountOperator = null;
                            $scope.savedReport.fromAmount = null;
                            $scope.savedReport.toAmount = null;

                            // default Report Type to Transaction
                            $scope.savedReport.type = 'Transaction';

                            // default is no accounts selected for performance reasons
                            angular.forEach($scope.allAccountsList, account => {
                                account.isChecked = false;
                            });

                            // default transaction direction is Both
                            $scope.savedReport.transactionDirection = 'Both';

                            // default transaction type is Both
                            $scope.savedReport.transactionType = 'Both';

                            // select all payment types
                            angular.forEach($scope.paymentTypesLst, paymentType => {
                                paymentType.isChecked = true;
                            });
                        }

                        if (
                            $scope.reportType === 'date-range-transaction' ||
                            $scope.reportType === 'day-transaction'
                        ) {
                            // disable Report Type State
                            $scope.filterObject.isReportTypeDisabled = true;
                            $scope.showSubFilter = true;
                            if (
                                $scope.savedReport.checkNumberOperator === null ||
                                $scope.savedReport.checkNumberOperator === undefined
                            ) {
                                $scope.savedReport.checkNumberOperator =
                                    $scope.checkNumberList[0].name;
                            }
                            if (
                                $scope.savedReport.amountOperator === null ||
                                $scope.savedReport.amountOperator === undefined
                            ) {
                                $scope.savedReport.amountOperator =
                                    $scope.amountOperatorList[0].name;
                            }
                        }

                        if ($scope.reportType === 'day-transaction') {
                            // disable Date field.
                            $scope.filterObject.isDateDisabled = true;

                            // determine sort by types that are visible.
                            angular.forEach($scope.sortByList, sortBy => {
                                if (
                                    sortBy.name !== 'Debits' &&
                                    sortBy.name !== 'Credits' &&
                                    sortBy.name !== 'Account Number' &&
                                    sortBy.name !== 'Account Name' &&
                                    sortBy.name !== 'Account Type' &&
                                    sortBy.name !== 'Transaction Type'
                                ) {
                                    sortBy.isVisible = false;
                                }
                            });

                            if ($scope.savedReport.isStandardReport) {
                                // default sort order
                                $scope.savedReport.sortBy = 'AccountNumber';
                            }
                        }

                        if (
                            $scope.reportType === 'date-range-balance' ||
                            $scope.reportType === 'day-balance'
                        ) {
                            $scope.filterObject.isReportTypeDisabled = true;
                        }

                        if (
                            $scope.reportType === 'day-balance' &&
                            reportType() === 'cdb' &&
                            $scope.savedReport.isStandardReport
                        ) {
                            // set the default Date Value to Date Range
                            $scope.savedReport.dateType = 'Current Day';
                            $scope.filterObject.isDateDisabled = true;
                        }

                        if (
                            $scope.reportType === 'day-balance' &&
                            reportType() === 'pdb' &&
                            $scope.savedReport.isStandardReport
                        ) {
                            // set the default Date Value to Date Range
                            $scope.savedReport.dateType = 'Prior Day';
                            $scope.filterObject.isDateDisabled = true;
                        }

                        if (
                            $scope.reportType === 'day-transaction' &&
                            reportType() === 'cdt' &&
                            $scope.savedReport.isStandardReport
                        ) {
                            // set the default Date Value to Date Range
                            $scope.savedReport.dateType = 'Current Day';
                        } else if (
                            $scope.reportType === 'day-transaction' &&
                            reportType() === 'pdt' &&
                            $scope.savedReport.isStandardReport
                        ) {
                            // set the default Date Value to Date Range
                            $scope.savedReport.dateType = 'Prior Day';
                        } else if (
                            $scope.reportType === 'date-range-transaction' ||
                            $scope.reportType === 'date-range-balance'
                        ) {
                            // determine date types that are visible.
                            angular.forEach($scope.dateTypeList, dateType => {
                                if (
                                    dateType.name === 'Current Day' ||
                                    dateType.name === 'Prior Day'
                                ) {
                                    dateType.isVisible = false;
                                }
                            });

                            if ($scope.savedReport.isStandardReport) {
                                $scope.savedReport.dateType = 'Today';

                                // default select no accounts for performance reasons
                                angular.forEach($scope.allAccountsList, account => {
                                    account.isChecked = false;
                                });
                            }

                            // determine sort by types that are visible.
                            angular.forEach($scope.sortByList, sortBy => {
                                if ($scope.reportType === 'date-range-transaction') {
                                    if (
                                        sortBy.name !== 'Debits' &&
                                        sortBy.name !== 'Credits' &&
                                        sortBy.name !== 'Date'
                                    ) {
                                        sortBy.isVisible = false;
                                    }
                                    if (
                                        sortBy.name === 'Transaction Type' &&
                                        $scope.hasSortByTransactionTypeInDateRangeReport
                                    ) {
                                        sortBy.isVisible = true;
                                    }
                                }
                                if ($scope.reportType === 'date-range-balance') {
                                    if (
                                        sortBy.name !== 'Debits' &&
                                        sortBy.name !== 'Credits' &&
                                        sortBy.name !== 'Opening Balance' &&
                                        sortBy.name !== 'Date' &&
                                        sortBy.name !== 'Collected Balance'
                                    ) {
                                        sortBy.isVisible = false;
                                    }
                                }
                            });
                            if ($scope.savedReport.isStandardReport) {
                                $scope.savedReport.sortBy = 'Date';
                            }
                        }

                        if (
                            ($scope.reportType === 'date-range-transaction' ||
                                $scope.reportType === 'date-range-balance') &&
                            $scope.savedReport.isStandardReport
                        ) {
                            $scope.savedReport.isAscendingSort = true;
                        }
                    }

                    function reportType() {
                        let result = '';
                        if ($scope.savedReport.reportType === 'Transaction') {
                            if ($scope.savedReport.dateType === 'Current Day') {
                                result = 'cdt';
                            } else if ($scope.savedReport.dateType === 'Prior Day') {
                                result = 'pdt';
                            } else {
                                result = 'drt';
                            }
                        } else if ($scope.savedReport.reportType === 'Balance') {
                            if ($scope.savedReport.dateType === 'Current Day') {
                                result = 'cdb';
                            } else if ($scope.savedReport.dateType === 'Prior Day') {
                                result = 'pdb';
                            } else {
                                result = 'drb';
                            }
                        }
                        return result;
                    }

                    $scope.changeEdit = function (item) {
                        if (item.isEdit === undefined || item.isEdit === false) {
                            item.isEdit = true;
                        } else item.isEdit = !item.isEdit;
                    };

                    function loadSortByList() {
                        $scope.sortByList = [
                            {
                                name: 'Account Number',
                                key: 'AccountNumber',
                                isVisible: true,
                            },
                            {
                                name: 'Account Name',
                                key: 'AccountName',
                                isVisible: true,
                            },
                            {
                                name: 'Account Type',
                                key: 'AccountType',
                                isVisible: true,
                            },
                            {
                                name: 'Transaction Type',
                                key: 'transactionCodeDescription',
                                isVisible: true,
                            },
                            {
                                name: 'Current Balance',
                                key: 'CurrentBalance',
                                isVisible: true,
                            },
                            {
                                name: 'Available Balance',
                                key: 'AvailableBalance',
                                isVisible: true,
                            },
                            {
                                name: 'Opening Balance',
                                key: 'OpeningBalance',
                                isVisible: true,
                            },
                            {
                                name: 'Collected Balance',
                                key: 'CollectedBalance',
                                isVisible: true,
                            },
                            {
                                name: 'Debits',
                                key: 'Debits',
                                isVisible: true,
                            },
                            {
                                name: 'Credits',
                                key: 'Credits',
                                isVisible: true,
                            },
                            {
                                name: 'Date',
                                key: 'Date',
                                isVisible: true,
                            },
                        ];
                    }

                    $scope.filterDropdown = function (array) {
                        const tmpArray = [];
                        angular.forEach(array, item => {
                            if (item.isVisible) {
                                tmpArray.push(item);
                            }
                        });
                        return tmpArray;
                    };

                    function loadReportTypeList() {
                        $scope.reportTypeList = [
                            {
                                name: 'Balance',
                            },
                            {
                                name: 'Transaction',
                            },
                            {
                                name: 'ACH',
                            },
                            {
                                name: 'Wire',
                            },
                            {
                                name: 'Transfer',
                            },
                            {
                                name: 'LockBox',
                            },
                            {
                                name: 'EDI',
                            },
                        ];
                    }

                    function loadDateTypeList() {
                        $scope.dateTypeList = [
                            {
                                name: 'Current Day',
                                isVisible: true,
                            },
                            {
                                name: 'Prior Day',
                                isVisible: true,
                            },
                            {
                                name: 'Today',
                                isVisible: true,
                            },
                            {
                                name: 'Specific Date',
                                isVisible: true,
                            },
                            {
                                name: 'Date Range',
                                isVisible: true,
                            },
                            {
                                name: 'Week-To-Date',
                                isVisible: true,
                            },
                            {
                                name: 'Month-To-Date',
                                isVisible: true,
                            },
                            {
                                name: 'Year-To-Date',
                                isVisible: true,
                            },
                        ];
                    }

                    function loadAccountTypeList() {
                        $scope.accountTypeList = [
                            {
                                name: 'Checking',
                            },
                            {
                                name: 'Savings',
                            },
                            {
                                name: 'Loan',
                            },
                        ];
                    }

                    function loadAllAccountsList(isReset) {
                        return companyAccountsService.getCompanyAccountsForProductFeature(
                            '',
                            'InformationReporting',
                            reportType()
                        );
                    }

                    function afterLoadingPaymentTypes(paymentTypes, isReset) {
                        if (!$scope.savedReport.paymentTypes) {
                            return;
                        }

                        $scope.paymentTypesLst = [];
                        angular.forEach(paymentTypes, item => {
                            $scope.paymentTypesLst.push({ type: item.name });
                        });
                        angular.forEach($scope.paymentTypesLst, item => {
                            // if not a standard report, we must set isChecked for the accounts that already exist in the collection
                            if ($scope.savedReport.isStandardReport === false) {
                                let alreadyExists = false;
                                angular.forEach($scope.savedReport.paymentTypes, paymentType => {
                                    if (paymentType.type === item.type) {
                                        alreadyExists = true;
                                    }
                                });
                                item.isChecked = alreadyExists;
                            } else {
                                $scope.savedReport.paymentTypes.push(item);
                                item.isChecked = false;
                            }
                        });
                    }

                    function afterLoadingAccounts(accounts, isReset) {
                        // must transform the data
                        angular.forEach(accounts, item => {
                            const tmpItem = angular.copy(item);
                            item.account = tmpItem;
                            if (tmpItem.account) {
                                item.account.type = tmpItem.account.type;
                                item.type = tmpItem.account.type;
                            }

                            // if not a standard report, we must set isChecked for the accounts that already exist in the collection
                            if ($scope.savedReport.isStandardReport === false) {
                                let alreadyExists = false;
                                angular.forEach($scope.savedReport.accounts, account => {
                                    if (account.account.id === item.id) {
                                        alreadyExists = true;
                                    }
                                });
                                item.isChecked = alreadyExists;
                            }
                        });

                        if (isReset === false) {
                            $scope.allAccountsList = accounts;

                            const accountIds = $scope.savedReport.accounts.map(
                                account => account.account.id
                            );

                            // accountTypes is a string like "Checking,Savings,Loan". Split into an array (e.g. ["Checking", "Savings", "Loan"])
                            const accountTypesArray = $scope.savedReport.accountTypes.split(',');

                            // Loan cannot be mixed with either Checking or Savings. If it's not the only item, remove it.
                            const indexOfLoan = accountTypesArray.indexOf('Loan');
                            if (accountTypesArray.length > 1 && indexOfLoan > -1) {
                                accountTypesArray.splice(indexOfLoan, 1);
                            }

                            // Now convert back to a string, after sorting, just in case the API sends the types in a random order
                            const accountTypesString = accountTypesArray.sort().join(',');

                            setupFilterRecord(
                                accountTypesString,
                                accountIds,
                                $scope.accountDisplayField
                            );
                        }
                        loadReportTypeList();
                        loadDateTypeList();
                        loadAccountTypeList();
                        loadSortByList();
                        loadCheckNumberList();
                        loadAmountOperatorList();
                        loadTransactionDirectionList();
                        scrubData();
                        setVisibleAndDisabledAndControllersState();

                        return loadPaymentTypesList();
                    }

                    function loadPaymentTypesList() {
                        return tranGroupCodeService.get();
                    }

                    init();

                    function prepareFilter(filterObject) {
                        const filterCopy = angular.copy(filterObject);
                        const field = 'fromAmount';
                        const fieldType = 'amountOperator';

                        if (
                            !filterCopy[field] &&
                            (!filterCopy[fieldType] ||
                                filterCopy[fieldType].indexOf('Specific') !== -1)
                        ) {
                            delete filterCopy[fieldType];
                        }
                        if (filterCopy.specificPaymentId) {
                            filterCopy.fromPaymentId = filterCopy.specificPaymentId;
                            filterCopy.toPaymentId = filterCopy.specificPaymentId;
                        }

                        return filterCopy;
                    }
                },
            ],
        };
    },
];
