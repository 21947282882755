import { html, nothing } from 'lit';

export function renderDownloadDialog(isDownloading: boolean, onClose: () => void) {
    if (!isDownloading) {
        return nothing;
    }

    return html`<omega-dialog
        id="download-dialog"
        open
        .dialogTitle=${'Downloading Issued Items Activity'}
        @close=${onClose}
    >
        <omega-progress card></omega-progress>
    </omega-dialog>`;
}
