import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-download-bar';

import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { css, html, LitElement, nothing } from 'lit';
import '../../components/ach-save-as-template-dialog.js';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration.ts';
import '../parts/ach-international-payment-header.js';
import '../parts/ach-international-payment-recipients-table.js';

class AchInternationalConfirmStep extends LitElement {
    static get properties() {
        return {
            loading: Boolean,
            paymentHeaderRecord: Object,
            fields: Array,
            activeStep: Number,
            recipientsRecordset: Object,
            recipients: Array,
            template: Boolean,
            entitlements: Array,
            isFromPayment: Boolean,
            openSaveAsTemplate: Boolean,
            openApplyUpdatesToTemplate: Boolean,
            validateTemplateName: Function,
            workflowType: String,
            paymentCreationType: String,
            savedAsTemplate: Boolean,
            downloadOptions: Array,
            updateTemplateFromPayment: Function,
            templateUpdated: Boolean,
        };
    }

    constructor() {
        super();
        this.loading = false;
        this.savedAsTemplate = false;
        this.openApplyUpdatesToTemplate = false;
        this.template = window.location.pathname.split('/')[4] === 'template';
        this.templateUpdated = false;
    }

    get downloadOptions() {
        return CONTAINER_CONFIGURATION[this.workflowType].downloadOptions ?? [];
    }

    createNew() {
        this.dispatchEvent(
            new CustomEvent('goToStep', {
                detail: {
                    stepNumber: 0,
                    createNew: true,
                },
            })
        );
    }

    goToFirstStep() {
        this.dispatchEvent(new CustomEvent('goToStep', { detail: { stepNumber: 0 } }));
    }

    async goToNewWorkflow(creationType, workflowType) {
        const destination = `payables.ach.payments.${creationType}-international-ach-${workflowType}`;
        const di = await DiContainer.getInstance();

        return di.get(NavigationService).navigate(destination);
    }

    goToAchActivity() {
        this.dispatchEvent(new CustomEvent('goToAchActivity'));
    }

    goToAchTemplatesList() {
        this.dispatchEvent(new CustomEvent('goToAchTemplatesList'));
    }

    initiatePaymentFromTemplate() {
        this.dispatchEvent(new CustomEvent('initiatePaymentFromTemplate'));
    }

    hasFeatureFlag() {
        return this.entitlements?.some(
            e => e.permission === 'Feature.Ach.InternationalPaymentTemplates'
        );
    }

    hasTemplatePermission() {
        return this.entitlements?.some(e => e.permission === 'Create International Ach Template');
    }

    renderTemplateDialog() {
        return html`<ach-save-as-template-dialog
            .paymentHeaderRecord=${this.paymentHeaderRecord}
            .open=${this.openSaveAsTemplate}
            .validateTemplateName=${this.validateTemplateName}
            @close=${() => {
                this.openSaveAsTemplate = false;
            }}
            @save=${() => {
                this.dispatchEvent(new CustomEvent('saveAsTemplate'));
            }}
        ></ach-save-as-template-dialog>`;
    }

    renderUpdateTemplateDialog() {
        return html`<omega-alert
            class="apply-updates-to-template-dialog"
            type="warning"
            posture="assertive"
            .isVisible=${this.openApplyUpdatesToTemplate}
            .closeable=${true}
            title="Are you sure?"
            @close=${() => {
                this.openApplyUpdatesToTemplate = false;
            }}
        >
            <p>Are you sure you want to apply updates to the template?</p>
            <omega-button
                class="apply-updates"
                slot="actions"
                type="primary"
                @click=${async () => {
                    this.openApplyUpdatesToTemplate = false;
                    const successLevel = await this.updateTemplateFromPayment();
                    this.templateUpdated = successLevel === 1;
                }}
                >Apply Updates</omega-button
            >
            <omega-button
                class="cancel"
                slot="actions"
                type="secondary"
                @click=${() => {
                    this.openApplyUpdatesToTemplate = false;
                }}
                >Cancel</omega-button
            >
        </omega-alert>`;
    }

    renderTitle() {
        return this.template ? 'Template Confirmation' : 'Payment Confirmation';
    }

    renderApplyUpdatesToTemplateButton() {
        if (
            this.paymentCreationType === 'initiate' &&
            this.hasTemplatePermission() &&
            !this.isFromPayment &&
            !this.templateUpdated
        ) {
            return html`<omega-button
                class="apply-updates-to-template"
                @click=${() => {
                    this.openApplyUpdatesToTemplate = true;
                }}
                >Apply Updates to Template</omega-button
            >`;
        }
        return nothing;
    }

    renderInitiateButton() {
        const isTemplate = this.workflowType === 'template';
        const canInitiate = this.paymentHeaderRecord.getField('status') === 'Ready';
        if (isTemplate && canInitiate) {
            return html`<omega-button @click=${this.initiatePaymentFromTemplate}>
                Initiate Payment from Template
            </omega-button> `;
        }
        return nothing;
    }

    renderCreateNewButton() {
        return html`<omega-button @click=${this.createNew}
            >Create New ${this.template ? 'Template' : 'Payment'}</omega-button
        >`;
    }

    renderCreateTemplateButton() {
        if (!this.hasFeatureFlag()) return nothing;
        if (
            this.paymentCreationType === 'initiate' ||
            this.paymentCreationType === 'edit' ||
            this.workflowType === 'template'
        )
            return nothing;
        if (!this.hasTemplatePermission() || this.savedAsTemplate) return nothing;
        return html`<omega-button
            @click=${() => {
                this.openSaveAsTemplate = true;
            }}
            >Save as Template</omega-button
        >`;
    }

    renderGoToPaymentActivity() {
        if (this.workflowType === 'template') {
            return html`<omega-button
                class="ach-templates"
                type="link"
                @click=${this.goToAchTemplatesList}
                >International ACH Templates</omega-button
            >`;
        }
        return html`<omega-button type="link" @click=${this.goToAchActivity}>
            ACH Payment Activity
        </omega-button>`;
    }

    renderActionButtons() {
        return html`${this.renderCreateNewButton()}${this.renderCreateTemplateButton()}
        ${this.renderApplyUpdatesToTemplateButton()} ${this.renderInitiateButton()}
        ${this.renderGoToPaymentActivity()}`;
    }

    render() {
        if (this.activeStep === 2) {
            return html` ${this.renderTemplateDialog()}${this.renderUpdateTemplateDialog()}
                <div class="omega-form-header">
                    <h2 class="step-title">${this.renderTitle()}</h2>
                    <omega-download-bar
                        pageTitle=""
                        .actions=${['download', 'print']}
                        .downloadOptions=${this.downloadOptions}
                    ></omega-download-bar>
                </div>
                <ach-international-payment-header
                    expanded
                    .fields=${this.fields}
                    .paymentHeaderRecord=${this.paymentHeaderRecord}
                    .recipientsRecordset=${this.recipientsRecordset}
                    .activeStep=${this.activeStep}
                    .template=${this.template}
                ></ach-international-payment-header>
                <ach-international-payment-recipients-table
                    .recipients=${this.recipients}
                    .recipientsRecordset=${this.recipientsRecordset}
                    display-toggle-all
                    .editable=${false}
                ></ach-international-payment-recipients-table>
                <omega-button-bar position="bottom" alignment="left">
                    ${this.renderActionButtons()}
                </omega-button-bar>`;
        }
        return nothing;
    }

    static get styles() {
        return css`
            .omega-form-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .step-title {
                margin: 0;
            }
        `;
    }
}

customElements.define('ach-international-confirm-step', AchInternationalConfirmStep);
