// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

function resolve(obj, path, defValue) {
    const rv = path.split('.').reduce((o, p) => o && o[p], obj);
    return rv || defValue;
}

filtersModule.filter(
    'orderByNumeric',
    () =>
        function (array, fieldName, desc) {
            if (!angular.isArray(array)) {
                return array;
            }
            if (!angular.isString(fieldName)) {
                return array;
            }

            // Using angular.copy(array) was causing issues with watchCollections
            // So emulated line 19335 of angular.js (1.4.4)
            const sorted = array.map(item => item);

            sorted.sort((a, b) => {
                const resolveA = resolve(a, fieldName, '0');
                const resolveB = resolve(b, fieldName, '0');
                const x = !angular.isString(resolveA) ? String(resolveA) : resolveA;
                const y = !angular.isString(resolveB) ? String(resolveB) : resolveB;
                // https://stackoverflow.com/a/45278933/4322226
                if (desc) {
                    return y.localeCompare(x, undefined, { numeric: true, sensitivity: 'base' });
                }
                return x.localeCompare(y, undefined, { numeric: true, sensitivity: 'base' });
            });
            return sorted;
        }
);