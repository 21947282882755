/* eslint-disable @treasury/filename-match-export */
import { AbstractConstructor, Constructor } from '@treasury/utils/types';
import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import '../components/omega-alert';
import { OmegaAlertConfig } from '../services/omega-alert';

export function AlertMixin<Class extends Constructor<object> | AbstractConstructor>(
    BaseClass: Class
) {
    class OmegaAlerter extends BaseClass {
        @property({ type: Object })
        protected alert: OmegaAlertConfig = {
            title: '',
            message: '',
            visible: false,
            code: '',
            time: '',
            type: 'success',
            posture: 'polite',
            actions: '',
            onClose: () => null,
        };

        public get isVisible() {
            return this.alert.visible;
        }

        /**
         * @deprecated Use the `OmegaAlertService`.
         */
        protected renderAlert() {
            const { code, time, message, type, title, actions, posture, visible, onClose } =
                this.alert;
            const renderedCode = code ? html`${code}: ` : nothing;
            const renderedTime = time ? html`<br />Time: ${time.toString()}` : nothing;
            if (!visible) return nothing;
            return html`
                <omega-alert
                    type=${type}
                    title=${title}
                    posture=${posture}
                    ?isVisible=${visible}
                    @close=${() => {
                        this.alert = { ...this.alert, visible: false };
                        if (onClose) onClose();
                    }}
                >
                    ${renderedCode} ${message} ${renderedTime}
                    <div slot="actions">${actions}</div>
                </omega-alert>
            `;
        }
    }

    return OmegaAlerter;
}
