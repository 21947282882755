/* eslint-disable class-methods-use-this */
import { html, nothing } from 'lit';
import '../omega-icon.js';
import RadioColumn from './radio-column.js';

export default class RadioIconColumn extends RadioColumn {
    constructor(...args) {
        super(...args);
        const [{ field, value }, recordset, cursor] = args;
        this.field = field;
        this.fieldType = recordset.getFieldType(field);
        this.value = value;
        this.recordset = recordset;
        this.minWidth = 50;
        this.targetWidth = 55;
        this.maxWidth = 60;
        this.cursor = cursor;
    }

    columnToggle(field) {
        const allRecords =
            this.recordset.allRecords ?? /* for backwards compatibility */ this.cursor.allRecords;
        allRecords.forEach(record => {
            if (record.getField('protected') !== 'Y') {
                record.setField(field, this.value);
            }
        });
    }

    renderTdContents(record, rowIndex) {
        const radioButton = document.createElement('input');
        radioButton.type = 'radio';
        radioButton.name = `${rowIndex}_${this.field}`;
        radioButton.value = this.value;
        radioButton.checked = this.value === record.getField(this.field);
        this.listenTo(radioButton, 'click', () => record.setField(this.field, this.value));
        this.listenTo(record, 'change', () => {
            radioButton.checked = this.value === record.getField(this.field);
        });

        if (record.values?.protected === 'Y' && this.value === record.getField(this.field)) {
            return html`<omega-icon icon="lock" description="protected"></omega-icon>`;
        }
        if (record.values?.protected === 'Y' && this.value !== record.getField(this.field)) {
            return nothing;
        }
        return radioButton;
    }
}
