import UserActivityRequests from '@treasury/domain/channel/requests/user-activity/user-activity';
import AchConfigurationServices from '@treasury/domain/channel/services/ach/ach-configuration-services.js';
import { AchInternationalServices } from '@treasury/domain/channel/services/ach/ach-international-services';
import AchPaymentsServices from '@treasury/domain/channel/services/ach/ach-payments-services.js';
import DownloadService from '@treasury/domain/channel/services/download/download-service.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity/user-activity-service.js';
import { UsersService } from '@treasury/domain/channel/services/users/users-service.ts';

/**
 * this is the consolidated InternationalAchPaymentClient,
 * we dont need multiple clients with repeated service calls
 * for each view of the same data
 *
 * to be deprecated:
 * create-international-payment-client
 * international-ach-payment-detail-client
 * */

export default class InternationalAchPaymentClient {
    async getPayment(...args) {
        return AchInternationalServices.getPaymentById(...args);
    }

    async downloadPayment(...args) {
        return DownloadService.download(...args);
    }

    async updateUserPrintActivity(...args) {
        return UserActivityService.print(...args);
    }

    async achConfiguration(...args) {
        const configuration = await AchConfigurationServices.getConfiguration(...args);
        const entitlements = await EntitlementsService.getEntitlements();
        /*
        Same day ach is not exposed bc you can not send an SDA IAT payment
        */
        return {
            companyMatchType: configuration.companyMatchType,
            cutoffTimes: configuration.cutoffTimes,
            holidays: configuration.holidays,
            achSettings: configuration.achSettings,
            entitlements,
        };
    }

    async getEntitlements(...args) {
        return EntitlementsService.getEntitlements(...args);
    }

    async hasEntitlement(...args) {
        return EntitlementsService.hasEntitlement(...args);
    }

    async currentUser(...args) {
        return UsersService.fetchCurrentUser(...args);
    }

    async approvers(...args) {
        return UsersService.fetchApprovers(...args);
    }

    async updatePaymentStatus(...args) {
        return AchPaymentsServices.updateAchPaymentStatus(...args);
    }

    async getCountries(...args) {
        return AchInternationalServices.getCountries(...args);
    }

    async getCountriesForPayment(...args) {
        return AchInternationalServices.getCountriesForPayment(...args);
    }

    async getPaymentById(...args) {
        return AchInternationalServices.getPaymentById(...args);
    }

    async getStates(...args) {
        return AchInternationalServices.getStates(...args);
    }

    async getAchCompanies(...args) {
        return AchInternationalServices.getAchCompanies(...args);
    }

    async createPayment(...args) {
        return AchInternationalServices.createPayment(...args);
    }

    async updatePayment(...args) {
        return AchInternationalServices.updateInternationalAchPayment(...args);
    }

    async uploadNachaFile(...args) {
        return AchInternationalServices.uploadNachaFile(...args);
    }

    async savePaymentAsTemplate(...args) {
        return AchInternationalServices.savePaymentAsTemplate(...args);
    }

    async download(...args) {
        return DownloadService.download(...args);
    }

    async print(...args) {
        return UserActivityRequests.print(...args);
    }

    async getUserAccountsToOffsetBalancedCompany(...args) {
        return AchInternationalServices.getUserAccountsToOffsetBalancedCompany(...args);
    }

    async getOffsetAccounts(...args) {
        return AchInternationalServices.getOffsetAccounts(...args);
    }

    async getDestinationCountry(...args) {
        return AchInternationalServices.getDestinationCountry(...args);
    }

    async getInternationalTransactionTypes(...args) {
        return AchInternationalServices.getInternationalTransactionTypes(...args);
    }

    async createTemplate(...args) {
        return AchInternationalServices.createInternationalAchTemplate(...args);
    }

    async getTemplates(...args) {
        return AchInternationalServices.getTemplates(...args);
    }

    async getTemplateById(...args) {
        return AchInternationalServices.getTemplateById(...args);
    }

    async updateTemplate(...args) {
        return AchInternationalServices.updateTemplate(...args);
    }

    async deleteTemplate(id) {
        return AchInternationalServices.deleteTemplate(id);
    }

    async isUniqueTemplateName(name) {
        return AchInternationalServices.isUniqueTemplateName(name);
    }

    async downloadTemplate(filterModel, outputType) {
        return AchInternationalServices.downloadTemplate(filterModel, outputType);
    }

    async downloadTemplateList(filters, type) {
        return AchInternationalServices.downloadTemplateList({ parameters: filters }, type);
    }

    async getIatAchOptions() {
        return AchInternationalServices.getIatAchOptions();
    }

    async getTemplateApprovers(id) {
        return AchInternationalServices.getTemplateApprovers(id);
    }

    async approveTemplate(id, comments) {
        return AchInternationalServices.approveTemplate(id, comments);
    }

    async rejectTemplate(id, comments) {
        return AchInternationalServices.rejectTemplate(id, comments);
    }
}
