import FieldType from '@treasury/FDL/field-type';
import nachaAlphanumeric from './lib/validators/nacha/nacha-alphanumeric.validator.js';

export const nachaStreetAddress = new FieldType().with.validator(nachaAlphanumeric);
export const nachaCity = new FieldType().with.validator(nachaAlphanumeric);
export const nachaRecipientName = new FieldType().with
    .validator(nachaAlphanumeric)
    .and.maxLength(35);
export const nachaCompanyName = new FieldType().with.validator(nachaAlphanumeric);
export const nachaBankName = new FieldType().with.validator(nachaAlphanumeric);
export const nachaZipCode = new FieldType().with.validator(nachaAlphanumeric);
export const nachaCountryCode = new FieldType().with.validator(nachaAlphanumeric);
export const nachaBankIdType = new FieldType();
