import { getChannelAPIPath } from '@treasury/core/http';

DashboardService.$inject = ['$resource'];

export default function DashboardService($resource) {
    const resourceUrl = `${getChannelAPIPath()}dashboard/`;

    return {
        getInformationCenter,
        getResources,
        getReportsOfType,
        getPaymentsPendingApproval,

        getAccountGroups,
        createAccountGroup,
        updateGroupName,
        deleteGroup,
        getAccounts,
        addAccounts,
        removeAccount,
        resequenceGroup,
        resequenceAccount,
        getSummaryInfo,
        getTransactionDetail,
        getDepositTransactionDetail,
        getTimeDepositTransactionDetail,
        getLoanTransactionDetail,
        getEntitlements,
        postBatch,
        getConfiguredWidgets,
        setConfiguredWidgets,
        getAvailableWidgets,
        getFavouriteReports,
        getStopRequests,
        postStpBatch,
        getWorkExceptions,
        getAvailableWidgetProducts,
        postArpExceptions,
        postAchExceptions,
        getProductStatus,
        auditUserIteration,
    };

    function postBatch(type, batchPayload) {
        return $resource(`${resourceUrl}batch`, { type }, { save: { method: 'POST' } }).save(
            batchPayload
        ).$promise;
    }

    function postArpExceptions(batchPayload) {
        return $resource(`${resourceUrl}arpExceptionsBatch`).save(batchPayload).$promise;
    }

    function postAchExceptions(batchPayload) {
        return $resource(`${resourceUrl}channelAchExceptionsBatch`).save(batchPayload).$promise;
    }

    function getWorkExceptions(type) {
        return $resource(`${resourceUrl}channelWorkExceptions?type=:type`, { type }).query()
            .$promise;
    }

    function batchApproveOrReject(batch, action) {
        return $resource(`${resourceUrl}:id/batch?action=:action`, { id: 0, action }).save(batch)
            .$promise;
    }

    function getEntitlements(accountId) {
        return $resource(`${resourceUrl}entitlements/`).query().$promise;
    }
    function getLoanTransactionDetail(accountId) {
        return $resource(`${resourceUrl}loantransactiondetail/?accountId=:id`, {
            id: accountId,
        }).get().$promise;
    }

    function getTimeDepositTransactionDetail(accountId) {
        return $resource(`${resourceUrl}timedeposittransactiondetail/?accountId=:id`, {
            id: accountId,
        }).get().$promise;
    }

    function getDepositTransactionDetail(accountId) {
        return $resource(`${resourceUrl}depositTransactionDetail/?accountId=:id`, {
            id: accountId,
        }).get().$promise;
    }

    function postStpBatch(list) {
        return $resource(`${resourceUrl}stpBatch`, { save: { method: 'POST' } }).save(list)
            .$promise;
    }

    function getTransactionDetail(accountId, accountType) {
        return $resource(
            `${resourceUrl}transactiondetail/?accountId=:id&accountType=:accountType`,
            { id: accountId, accountType },
            { query: { method: 'GET', isArray: false } }
        ).query().$promise;
    }

    function getSummaryInfo() {
        return $resource(`${resourceUrl}summary`).get().$promise;
    }

    function getAccountGroups() {
        return $resource(`${resourceUrl}myaccounts`).query().$promise;
    }

    function createAccountGroup(group) {
        return $resource(`${resourceUrl}myaccounts/groups`).save(group).$promise;
    }

    function updateGroupName(group) {
        const resource = $resource(
            `${resourceUrl}myaccounts/groups`,
            { groupId: group.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(group).$promise;
    }

    function deleteGroup(groupId) {
        return $resource(`${resourceUrl}myaccounts/groups`, { groupId }).delete().$promise;
    }

    function getAccounts() {
        return $resource(`${resourceUrl}myaccounts/accounts`).query().$promise;
    }

    function addAccounts(accounts, groupId) {
        return $resource(`${resourceUrl}myaccounts/groups/accounts`, { groupId }).save(accounts)
            .$promise;
    }

    function removeAccount(groupId, accountId) {
        return $resource(`${resourceUrl}myaccounts/groups/accounts`, {
            groupId,
            accountId,
        }).delete().$promise;
    }

    function resequenceGroup(groupId, newIndex) {
        const resource = $resource(
            `${resourceUrl}myaccounts/groups/resequence`,
            { groupId, newIndex },
            { update: { method: 'PUT' } }
        );
        return resource.update().$promise;
    }

    function resequenceAccount(groupId, accountId, newIndex) {
        const resource = $resource(
            `${resourceUrl}myaccounts/groups/accounts/resequence`,
            { groupId, accountId, newIndex },
            { update: { method: 'PUT' } }
        );
        return resource.update().$promise;
    }

    function getReportsOfType(reportType) {
        return $resource(`${resourceUrl}informationreportsreports?type=:type`, {
            type: reportType,
        }).query().$promise;
    }

    function getPaymentsPendingApproval(type) {
        return $resource(`${resourceUrl}paymentPendingApproval?type=:type`, { type }).query()
            .$promise;
    }

    // function getStopPaymentsPendingApproval()

    function getResources() {
        return $resource(`${resourceUrl}resourcedetail`).query().$promise;
    }

    function getFavouriteReports() {
        return $resource(`${resourceUrl}favouriteReports`).query().$promise;
    }

    function getInformationCenter() {
        return $resource(`${resourceUrl}informationcenterdetail`).query().$promise;
    }

    function getConfiguredWidgets() {
        return $resource(`${resourceUrl}configuredWidgets`).query().$promise;
    }

    function setConfiguredWidgets(widgets) {
        const resource = $resource(`${resourceUrl}configuredWidgets`, null, {
            update: { method: 'PUT', isArray: true },
        });
        return resource.update(widgets).$promise;
    }

    function getAvailableWidgets() {
        return $resource(`${resourceUrl}availableWidgets`).query().$promise;
    }

    function getStopRequests() {
        return $resource(`${resourceUrl}stopRequests`).query().$promise;
    }

    function getAvailableWidgetProducts(widgetType) {
        return $resource(`${resourceUrl}WidgetProducts?type=:type`, { type: widgetType }).query()
            .$promise;
    }

    function getProductStatus() {
        return $resource(`${resourceUrl}getProducts`).query().$promise;
    }

    function auditUserIteration(interaction) {
        const request = $resource(`${resourceUrl}auditUserInteraction/${interaction}`);
        return request.save().$promise;
    }
}
