/* eslint-disable no-use-before-define */
import { TmApiError } from '@treasury/domain/shared';
import '@treasury/omega/components/omega-alert.js';
import { html, nothing } from 'lit';

export default function channelAlertMixin(superClass) {
    class ChannelAlert extends superClass {
        constructor() {
            super();
            /**
             * @typedef alert
             * @type {object}
             * @property {string} title
             * @property {string|TemplateResult<1>} message
             * @property {boolean} visible
             * @property {string} code
             * @property {string} time
             * @property {string} type
             * @property {string} posture
             * @property {string|TemplateResult<1>} actions
             */

            /** @type {alert} */
            this.alert = {
                title: '',
                message: '',
                visible: false,
                code: '',
                time: '',
                type: '',
                posture: 'polite',
                actions: '',
                alignment: '',
            };
        }

        /**
         * @param { TmApiError | unknown} err
         * @param { string } [defaultMessage]
         */
        setAlertFromError(err, defaultMessage) {
            this.alert = {
                ...this.alert,
                ...createErrorAlert(err, defaultMessage),
            };
        }

        renderAlert() {
            const { code, time, message, type, title, actions, posture, visible } = this.alert;
            const renderedCode = code ? html`${code}: ` : nothing;
            const renderedTime = time ? html`<br />Time: ${time}` : nothing;

            if (!visible) return nothing;

            return html`
                <omega-alert
                    type=${type}
                    title=${title}
                    posture=${posture}
                    ?isVisible=${visible}
                    .alignment=${this.alignment}
                    @close=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                >
                    ${renderedCode} ${message} ${renderedTime}
                    <div slot="actions">${actions}</div>
                </omega-alert>
            `;
        }
    }
    return ChannelAlert;
}

/**
 * @param { TmApiError | Error | unknown} err
 * @param { string } [defaultMessage]
 */
function createErrorAlert(err, defaultMessage) {
    const baseConfig = {
        visible: true,
        type: 'error',
        posture: 'default',
    };
    if (err instanceof TmApiError) {
        const { message, errorCode: code, timestamp: time } = err;
        return {
            ...baseConfig,
            message,
            code,
            time,
        };
    }

    if (err instanceof Error) {
        return {
            ...baseConfig,
            message: err.message,
        };
    }

    return {
        ...baseConfig,
        message: defaultMessage ?? 'An error occurred. Please try again.',
    };
}
