import { Nullable } from '@treasury/utils/types';
import { AccountDto } from './accounts.dto';
import { SecCodeDto } from './sec-code.dto';

export interface AchExceptionsQueryDto {
    /* The optional variable declarations for amount and postedDate fields was a pragmatic choice.
    When amountType is 'Specific Amount', the API doesn't need amountEnd and amountStart. Likewise,
    when amountType is 'Amount Range', the amount field can be omitted. The same principle applies
    to the postedDate fields. This was easier than handling all the variations in map-search-params.ts. */
    amount?: number | string | null;
    amountEnd?: number | string | null;
    amountStart?: number | string | null;
    amountType: string | null;
    decisionTaken: string;
    description: string;
    page: string | null;
    postedDate?: string | null;
    postedDateEnd?: string | null;
    postedDateStart?: string | null;
    postedDateType: string | null;
    searchType: string;
    type: string;
    accounts: AccountDto[];
    secCodes: SecCodeDto[];
}

export const nullAchExceptionsFilterBody: Nullable<AchExceptionsQueryDto> = {
    amount: '',
    amountEnd: '',
    amountStart: '',
    amountType: '',
    decisionTaken: '',
    description: '',
    page: '',
    postedDate: '',
    postedDateEnd: '',
    postedDateStart: '',
    postedDateType: '',
    searchType: '',
    type: '',
    accounts: [],
    secCodes: [],
};
