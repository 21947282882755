import { SecCodeDto } from '@treasury/domain/channel/types';
import { OmegaListItem } from '@treasury/omega/view-models';

export type SecCodeListItem = OmegaListItem<SecCodeDto>;
export function createSecCodeListItem(dto: SecCodeDto): SecCodeListItem {
    return {
        value: dto,
        text: dto.code,
    };
}
