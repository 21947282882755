import { AuthInterceptorServiceFactory } from '@treasury/domain/shared/interceptors';
import ActivityService from './activityService.js';
import AlertsManager from './alertSrvc.js';
import CacheService from './cacheSrvc.js';
import DownloadService from './downloadSrvc.js';
import ModalService from './modalSrvc.js';
import PagePushService from './pagePushSrvc.js';
import ScopeService from './scopeSrvc.js';
import SpinnerService from './spinnerSrvc.js';

export const servicesCommonModule = angular
    .module('channel.services.common', [])
    .factory('activityService', ActivityService)
    .factory('alertsManager', AlertsManager)
    .factory('cacheService', CacheService)
    .factory('authInterceptorService', AuthInterceptorServiceFactory)
    .factory('downloadService', DownloadService)
    .service('modalService', ModalService)
    .factory('pagePushService', PagePushService)
    .factory('spinnerService', SpinnerService)
    .factory('scopeService', ScopeService);
