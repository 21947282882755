import { FieldType } from '@treasury/FDL';
import { SecCodeDto, WorkAchExceptionDto } from '@treasury/domain/channel/types/arp';
import { OmegaReportFilter } from '@treasury/omega/types';
import FieldTypes from '@treasury/policy/primitives';
import { WorkAchExceptionListItem, createWorkAchExceptionListItem } from '../models';
import { SecCodeListItem, createSecCodeListItem } from '../models/sec-code-list-item';

// eslint-disable-next-line @treasury/filename-match-export
export default function createOmegaReportFilters(
    secCodes: SecCodeDto[],
    accounts: WorkAchExceptionDto[]
): OmegaReportFilter<any, any>[] {
    const secCodeListItems = secCodes.map(code => createSecCodeListItem(code));
    return [
        {
            field: 'accounts',
            fieldType: new FieldType<WorkAchExceptionListItem>().with
                .defaultValue(null)
                .and.options({
                    data: accounts.map(a => createWorkAchExceptionListItem(a)),
                })
                .with.placeholder('Select an account')
                .with.label('Accounts')
                .with.multipleValues()
                .with.filtering()
                .thatHas.hashFunction<WorkAchExceptionListItem['value']>(v => v.accountDisplayLabel)
                .as.tag('omega-select'),
            value: accounts,
        },
        {
            field: 'amount',
            fieldType: FieldTypes.amountRange.with.label('Amount').as.tag('omega-range'),
            value: ['specific', null],
        },
        {
            field: 'postedDate',
            fieldType: FieldTypes.dateRangePickerNoDefault.and
                .label('Posted Date')
                .as.tag('omega-datepicker'),
            value: '',
        },
        {
            field: 'secCodes',
            fieldType: new FieldType<SecCodeListItem>().with
                .defaultValue(null)
                .and.options({
                    data: secCodeListItems,
                })
                .with.placeholder('Select SEC Codes')
                .with.label('SEC Code')
                .with.multipleValues()
                .thatHas.hashFunction<SecCodeListItem['value']>(v => v.code)
                .as.tag('omega-select'),
            value: secCodeListItems.map(item => item.value),
        },
        {
            field: 'description',
            fieldType: FieldTypes.string.with.label('Entry Description').as.tag('omega-input'),
            value: '',
        },
    ];
}
