const isValidDate = (date: any) => {
    if (!date) {
        return false;
    }

    if ((typeof date === 'string' && date.length) || typeof date === 'number') {
        return true;
    }

    // date from datepicker should have getDate method
    return typeof date === 'object' && typeof date.getDate === 'function';
};

export default isValidDate;
