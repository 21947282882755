import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';

/**
 * @typedef { import('@treasury/api/channel').UserInformationModelDto } UserInformationModelDto
 */

/**
 *
 * @param { ng.resource.IResourceService } $resource
 * @param { ng.IHttpService } $http
 * @param { ng.ILogService } $log
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
export function UsersService($resource, $http, $log, $q, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}users/`;

    return {
        searchUsers,
        getUserHeader,
        getUserProductFeatureSummary,
        submitUser,
        discardChanges,
        unlock,
        approve,
        reject,
        changeUserActiveStatus,
        deleteUser,
        manualResetPassword,
        resetPassword,
        getUserInformation,
        saveUserInformation,
        getUserAccountAccess,
        updateUserAccountAccess,
        getProductLimit,
        getProductEntitlements,
        getAchEntitlements,
        updateProductLimit,
        updateProductEntitlements,
        updateAchProductEntitlements,
        getAchLimits,
        updateAchLimits,
        updateIpRestrictions,
        getIpRestrictions,
        getUserTimeAccess,
        saveUserTimeAccess,
        getApprovers,
        getUserSsoPosPaySettings,
        updateUserSsoPosPaySettings,
        getUserReceivablesSettings,
        updateUserReceivablesSettings,
        getAccountsAccessibleToUser,
        buildUpdateProductEntitlementsRequest,
    };

    function searchUsers(filter) {
        return $resource(
            `${resourceUrl}search`,
            {},
            { search: { method: 'POST', isArray: true } }
        ).search(filter).$promise;
    }

    function getUserHeader(id) {
        return $resource(`${resourceUrl}:id/header`, { id }).get().$promise;
    }

    function getUserProductFeatureSummary(id) {
        return $resource(`${resourceUrl}:id/productfeaturesummary`, { id }).get().$promise;
    }

    /**
     * @param { number } id
     */
    function submitUser(id) {
        const resource = $resource(
            `${resourceUrl}:id/submit`,
            { id },
            { update: { method: 'PUT' } }
        );
        return $q
            .all([resource.update().$promise, TmDi.getAsync(AnalyticsService)])
            .then(([response, analytics]) => {
                analytics.track(AnalyticsEvent.UserSubmitted, {
                    id,
                });
                return response;
            });
    }

    function discardChanges(id, comments) {
        return $resource(
            `${resourceUrl}:id/discard`,
            { id, comments },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function unlock(id) {
        return $resource(`${resourceUrl}:id/unlock`, { id }, { update: { method: 'PUT' } }).update()
            .$promise;
    }

    /**
     *
     * @param { number } id
     * @param { string } message
     * @returns
     */
    function approve(id, message) {
        const resource = $resource(
            `${resourceUrl}:id/approve`,
            { id, comments: message },
            { update: { method: 'PUT' } }
        );

        return $q
            .all([resource.update().$promise, TmDi.getAsync(AnalyticsService)])
            .then(([response, analytics]) => {
                analytics.track(AnalyticsEvent.UserApproved, {
                    id: id.toString(),
                });
                return response;
            });
    }

    function reject(id, message) {
        return $resource(
            `${resourceUrl}:id/reject`,
            { id, comments: message },
            { update: { method: 'PUT' } }
        ).update().$promise;
    }

    function resetPassword(id) {
        return $resource(`${resourceUrl}:id/ResetPassword`, { id }).save().$promise;
    }

    function manualResetPassword(id, resetPasswordPayload) {
        return $resource(`${resourceUrl}:id/ManualResetPassword`, { id }).save({
            key: resetPasswordPayload.tempPassword,
            value: resetPasswordPayload.tempPassword,
        }).$promise;
    }

    function deleteUser(deleteUserModel) {
        return $http({
            method: 'DELETE',
            url: resourceUrl,
            headers: {
                'content-type': 'application/json',
            },
            data: deleteUserModel,
        }).then(response => response.data);
    }

    function getUserInformation(id, previousVersion) {
        return $resource(`${resourceUrl}:id/userinformation`, {
            id,
            previousVersion,
        }).get().$promise;
    }

    /**
     *
     * @param { number } id
     * @param { UserInformationModelDto} user
     */
    function saveUserInformation(id, user) {
        /**
         * @type { ng.resource.IResourceClass<ng.resource.IResource<UserInformationModelDto>>}
         */
        const resource = $resource(`${resourceUrl}:id/userinformation`, { id });
        return $q
            .all([resource.save(user).$promise, TmDi.getAsync(AnalyticsService)])
            .then(([response, analytics]) => {
                analytics.track(AnalyticsEvent.UserCreated, {
                    id: response.userId.toString(),
                });
                return response;
            });
    }

    function getUserTimeAccess(id) {
        return $resource(`${resourceUrl}:id/usertimeaccess`, { id }).get().$promise;
    }

    function saveUserTimeAccess(id, userTimeAccess) {
        return $resource(`${resourceUrl}:id/usertimeaccess`, { id }).save(userTimeAccess).$promise;
    }

    function getUserAccountAccess(id) {
        return $resource(`${resourceUrl}:id/accountaccess`, { id }).get().$promise;
    }

    function updateUserAccountAccess(id, userAccountAccess) {
        return $resource(
            `${resourceUrl}:id/accountaccess`,
            { id },
            { update: { method: 'PUT' } }
        ).update(userAccountAccess).$promise;
    }

    function getProductLimit(id, productFeatureType) {
        return $resource(`${resourceUrl}:id/productlimits`, {
            id,
            productFeatureType,
        }).get().$promise;
    }

    function getProductEntitlements(id, productFeatureType) {
        return $resource(`${resourceUrl}:id/productentitlements`, {
            id,
            productFeatureType,
        }).get().$promise;
    }

    function getAchEntitlements(id) {
        return $resource(`${resourceUrl}:id/achproductentitlements`, { id }).get().$promise;
    }

    function getAchLimits(id) {
        return $resource(`${resourceUrl}:id/achlimits`, { id }).get().$promise;
    }

    function updateProductLimit(id, productFeatureConfig) {
        return $resource(
            `${resourceUrl}:id/productlimits`,
            { id },
            { update: { method: 'PUT' } }
        ).update(productFeatureConfig).$promise;
    }

    function updateAchLimits(id, limits) {
        return $resource(
            `${resourceUrl}:id/achlimits`,
            { id },
            { update: { method: 'PUT' } }
        ).update(limits).$promise;
    }

    function updateAchProductEntitlements(id, productFeatureConfig) {
        return $resource(
            `${resourceUrl}:id/achproductentitlements`,
            { id },
            { update: { method: 'PUT' } }
        ).update(productFeatureConfig).$promise;
    }

    function buildUpdateProductEntitlementsRequest(entitlements, entitlementsPristine) {
        const requestBody = {
            addedUserClaims: [],
            addedUserClaimAccounts: {},
            removedUserClaims: [],
            removedUserClaimAccounts: {},
            securityMessage: null,
        };

        appendUserClaimsToProductEntitlementsRequest(
            requestBody,
            entitlements.userProductClaims,
            entitlementsPristine.userProductClaims
        );

        appendAccountClaimsToProductEntitlementsRequest(
            requestBody,
            entitlements.accounts,
            entitlementsPristine.accounts
        );

        return requestBody;
    }

    function updateProductEntitlements(id, productFeatureConfig) {
        return $resource(
            `${resourceUrl}:id/productentitlements`,
            { id },
            { update: { method: 'PUT' } }
        ).update(productFeatureConfig).$promise;
    }

    function changeUserActiveStatus(userId, isActive, comments) {
        const resource = $resource(
            `${resourceUrl}:id/setActive?isActive=:isActive&comments=:comments`,
            { id: userId, isActive, comments },
            { update: { method: 'PUT' } }
        );
        return resource.update().$promise;
    }

    function getIpRestrictions(id) {
        return $resource(`${resourceUrl}:id/ipRestrictions`, { id }).get().$promise;
    }

    function updateIpRestrictions(id, restrict, ipRestrictions) {
        return $resource(
            `${resourceUrl}:id/ipRestrictions`,
            { id, restrict },
            { update: { method: 'PUT' } }
        ).update(ipRestrictions).$promise;
    }

    function getApprovers(request) {
        return $resource(
            `${resourceUrl}getApprovers`,
            {},
            { get: { method: 'POST', isArray: false } }
        ).get(request).$promise;
    }

    function getUserSsoPosPaySettings(id) {
        return $resource(`${resourceUrl}:id/ssopospayusersettings`, { id }).get().$promise;
    }

    function updateUserSsoPosPaySettings(id, userSsoPosPaySettings) {
        return $resource(
            `${resourceUrl}:id/ssopospayusersettings`,
            { id },
            { update: { method: 'PUT' } }
        ).update(userSsoPosPaySettings).$promise;
    }

    function getUserReceivablesSettings(id) {
        return $resource(`${resourceUrl}:id/receivablessettings`, { id }).get().$promise;
    }

    function updateUserReceivablesSettings(id, userReceivablesSettings) {
        return $resource(
            `${resourceUrl}:id/receivablessettings`,
            { id },
            { update: { method: 'PUT' } }
        ).update(userReceivablesSettings).$promise;
    }

    function getAccountsAccessibleToUser() {
        return $resource(
            `${resourceUrl}accessibleaccounts`,
            {},
            { get: { method: 'GET', isArray: true } }
        ).get().$promise;
    }

    function appendUserClaimsToProductEntitlementsRequest(
        requestBody,
        userProductClaims,
        userProductClaimsPristine
    ) {
        if (userProductClaims == null || userProductClaimsPristine == null) {
            return;
        }

        for (let claimIndex = 0; claimIndex < userProductClaims.length; ++claimIndex) {
            const userProductClaim = userProductClaims[claimIndex];
            const userProductClaimPristine = userProductClaimsPristine[claimIndex];

            if (userProductClaim.claimId !== userProductClaimPristine.claimId) {
                $log.info('userProductClaim', userProductClaim);
                $log.info('userProductClaimPristine', userProductClaimPristine);
                throw new Error('ClaimId does not match pristine ClaimId');
            }

            if (
                userProductClaim.hasPendingUserClaim &&
                !userProductClaimPristine.hasPendingUserClaim
            ) {
                requestBody.addedUserClaims.push(userProductClaim.claimId);
            } else if (
                !userProductClaim.hasPendingUserClaim &&
                userProductClaimPristine.hasPendingUserClaim
            ) {
                requestBody.removedUserClaims.push(userProductClaim.claimId);
            }
        }
    }

    function appendAccountClaimsToProductEntitlementsRequest(
        requestBody,
        accounts,
        accountsPristine
    ) {
        if (accounts == null || accountsPristine == null) {
            return;
        }

        for (let accountIndex = 0; accountIndex < accounts.length; ++accountIndex) {
            const account = accounts[accountIndex];
            const pristineAccount = accountsPristine[accountIndex];

            for (let claimIndex = 0; claimIndex < account.userAccountClaims.length; ++claimIndex) {
                const accountClaim = account.userAccountClaims[claimIndex];
                const pristineAccountClaim = pristineAccount.userAccountClaims[claimIndex];

                if (accountClaim.claimId !== pristineAccountClaim.claimId) {
                    $log.info('accountClaim', accountClaim);
                    $log.info('pristineAccountClaim', pristineAccountClaim);
                    throw new Error('ClaimIds do not match');
                }

                if (accountClaim.hasPendingUserClaim && !pristineAccountClaim.hasPendingUserClaim) {
                    if (requestBody.addedUserClaimAccounts[accountClaim.claimId] === undefined) {
                        requestBody.addedUserClaimAccounts[accountClaim.claimId] = [];
                    }
                    requestBody.addedUserClaimAccounts[accountClaim.claimId].push(account.id);
                } else if (
                    !accountClaim.hasPendingUserClaim &&
                    pristineAccountClaim.hasPendingUserClaim
                ) {
                    if (requestBody.removedUserClaimAccounts[accountClaim.claimId] === undefined) {
                        requestBody.removedUserClaimAccounts[accountClaim.claimId] = [];
                    }
                    requestBody.removedUserClaimAccounts[accountClaim.claimId].push(account.id);
                }
            }
        }
    }
}

UsersService.$inject = ['$resource', '$http', '$log', '$q', 'TmDi'];
