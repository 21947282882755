import { getChannelAPIPath } from '@treasury/core/http';

BankService.$inject = ['$resource', '$q'];

export default function BankService($resource, $q) {
    const resourceUrl = `${getChannelAPIPath()}banks/`;
    let banks = null;

    return {
        getAllBanks,
    };

    function getAllBanks() {
        const deferred = $q.defer();

        if (banks) {
            deferred.resolve(banks);
        } else {
            $resource(`${resourceUrl}?silent=1`)
                .query()
                .$promise.then(response => {
                    banks = response;
                    deferred.resolve(banks);
                });
        }
        return deferred.promise;
    }
}
