/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-use-before-define */
// cSpell:words pgbank, nginclude

import { AuthenticationService } from '@treasury/domain/services/authentication';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { StateObject, StateService, TransitionService } from '@uirouter/angularjs';
import { NavigationRouteMap } from './app.constants';

type AugmentedRootScope = ng.IRootScopeService & {
    _institution: string;
    showFdicDigitalSign: boolean;
    showNavigationMenu: boolean;
    useBrandTheme: boolean;
    optionalViewClasses: string[];
    bodyLayout: string;
    previousState: StateObject;
    currentState: StateObject;
};

export function registerRunBlock(module: ng.IModule) {
    return module.run([
        '$rootScope',
        'stateChangeService',
        '$templateCache',
        '$injector',
        '$state',
        'navigationRouteMap',
        'navigationService',
        'reportMenuOptions',
        'entitlementsService',
        function (
            $rootScope: AugmentedRootScope,
            stateChangeService: any,
            $templateCache: ng.ITemplateCacheService,
            $injector: ng.auto.IInjectorService,
            $state: StateService,
            navigationRouteMap: NavigationRouteMap,
            navigationService: any,
            reportMenuOptions: any,
            entitlementsService: any
        ) {
            if (document.documentElement.dataset.institution) {
                $rootScope._institution = document.documentElement.dataset.institution;
            }

            cacheTemplates($templateCache);

            if ($injector.has('$transitions')) {
                const $transitions = $injector.get('$transitions') as TransitionService;
                $transitions.onStart({}, transition =>
                    handleStateChangeStart(transition.$to(), transition.$from())
                );
                $transitions.onSuccess({}, transition =>
                    handleStateChangeSuccess(transition.$to())
                );
            } else {
                $rootScope.$on(
                    '$stateChangeStart',
                    (event, toState, toParams, fromState, fromParams) =>
                        handleStateChangeStart(toState, fromState)
                );
                $rootScope.$on(
                    '$stateChangeSuccess',
                    (event, toState, toParams, fromState, fromParams) =>
                        handleStateChangeSuccess(toState)
                );
            }

            (async () => {
                $rootScope.showFdicDigitalSign =
                    await FeatureFlagService.isEnabled('FdicDigitalSign');
            })();

            async function handleStateChangeStart(
                toState: StateObject & Record<string, any>,
                fromState: StateObject
            ) {
                stateChangeService.resetAll();

                $rootScope.showNavigationMenu = !(
                    toState.allowAnonymous || toState.hideNavigationMenu
                );
                if ($rootScope.showNavigationMenu) {
                    $rootScope.bodyLayout = toState.bodyLayout || 'page-container';
                } else {
                    $rootScope.bodyLayout = 'login';
                }

                $rootScope.optionalViewClasses = toState.optionalViewClasses;

                $rootScope.previousState = fromState;
                $rootScope.currentState = toState;

                const authService = await AuthenticationService.getInstance();
                if (!authService.authenticated && !toState.allowAnonymous) {
                    // hack; navigating to a route with a valid auth token circumvents login flow so
                    // that is not a sure way to determine if authentication ocurred
                    // if we successfully retrieved the entitlements, we know authentication has ocurred
                    return (entitlementsService.getEntitlements() as Promise<void>)
                        .then(() => {
                            authService.authenticate();
                        })
                        .catch(() => $state.target('login'));
                }

                return true;
            }

            function handleStateChangeSuccess(toState: StateObject & Record<string, any>) {
                const performNavigationAudit = !(
                    toState.allowAnonymous || toState.preventNavigationAudit
                );
                if (performNavigationAudit) {
                    const destinationNavigation = findNavigationFromRoute(
                        toState,
                        navigationRouteMap
                    );

                    if (
                        destinationNavigation &&
                        !isMenuExcludedFromUserActivity(destinationNavigation)
                    ) {
                        navigationService.userActivityAudit(destinationNavigation);
                    }
                }
                if (toState.fullPageUI) {
                    document.body.classList.add('full-page-ui');
                } else {
                    document.body.classList.remove('full-page-ui');
                }
            }

            function isMenuExcludedFromUserActivity(option: string) {
                return isReportsMenu(option) || option === 'createUser';
            }

            function isReportsMenu(option: string) {
                return reportMenuOptions.indexOf(option) !== -1;
            }

            function findNavigationFromRoute(
                destinationState: StateObject,
                navigationRouteMap: NavigationRouteMap
            ) {
                const properties = Object.getOwnPropertyNames(
                    navigationRouteMap
                ) as (keyof NavigationRouteMap)[];
                for (let i = 0; i < properties.length; ++i) {
                    const navigation = properties[i];
                    const route = navigationRouteMap[navigation];

                    if (route === destinationState.name) {
                        return navigation;
                    }
                }
            }
        },
    ]);
}

function cacheTemplates($templateCache: ng.ITemplateCacheService) {
    // Add templates referenced in the index.html page to the template cache
    $templateCache.put('app/shared/header/header.html', require('./shared/header/header.html'));
    $templateCache.put('app/shared/footer/footer.html', require('./shared/footer/footer.html'));
    // Add templates reference in JS snippets, not from .html files that are picked up by the nginclude-loader
    $templateCache.put(
        '/app/directives/components/sortableTable/sortableTableSortControl.html',
        require('./directives/components/sortableTable/sortableTableSortControl.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/accountsWidget.html',
        require('./dashboard/widgets/views/accountsWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/arpWidget.html',
        require('./dashboard/widgets/views/arpWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/arpWidgetNew.html',
        require('./dashboard/widgets/views/arpWidgetNew.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/favoriteReportsWidget.html',
        require('./dashboard/widgets/views/favoriteReportsWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/informationCenterWidget.html',
        require('./dashboard/widgets/views/informationCenterWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/paymentsPendingApprovalWidget.html',
        require('./dashboard/widgets/views/paymentsPendingApprovalWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/quickTransferWidget.html',
        require('./dashboard/widgets/views/quickTransferWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/quickLoanPaymentWidget.html',
        require('./dashboard/widgets/views/quickLoanPaymentWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/resourcesWidget.html',
        require('./dashboard/widgets/views/resourcesWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/stopRequestWidget.html',
        require('./dashboard/widgets/views/stopRequestWidget.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/accountsLeftControls.html',
        require('./dashboard/widgets/views/accountsLeftControls.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/accountsFooter.html',
        require('./dashboard/widgets/views/accountsFooter.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/accountsRightControls.html',
        require('./dashboard/widgets/views/accountsRightControls.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/quickTransferFooter.html',
        require('./dashboard/widgets/views/quickTransferFooter.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/stopRequestFooter.html',
        require('./dashboard/widgets/views/stopRequestFooter.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/workArpExceptionsOptions.html',
        require('./dashboard/widgets/views/workArpExceptionsOptions.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/arpWidgetFooter.html',
        require('./dashboard/widgets/views/arpWidgetFooter.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/paymentPendingApprovalOptions.html',
        require('./dashboard/widgets/views/paymentPendingApprovalOptions.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/pendingPaymentsFooter.html',
        require('./dashboard/widgets/views/pendingPaymentsFooter.html')
    );
    $templateCache.put(
        'app/dashboard/widgets/views/quickLoanPaymentFooter.html',
        require('./dashboard/widgets/views/quickLoanPaymentFooter.html')
    );
    $templateCache.put(
        '/app/payables/internalTransfer/create/views/internalTransferTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferTemplate.html')
    );

    $templateCache.put(
        'app/payables/internalTransfer/edit/views/internalTransferTemplate.html',
        require('./payables/internalTransfer/edit/views/internalTransferTemplate.html')
    );
    $templateCache.put(
        '/app/payables/internalTransfer/create/views/internalTransferOneToOneTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferOneToOneTemplate.html')
    );

    $templateCache.put(
        '/app/payables/internalTransfer/create/views/internalTransferManyToOneTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferManyToOneTemplate.html')
    );

    $templateCache.put(
        '/app/payables/internalTransfer/create/views/internalTransferOneToManyTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferOneToManyTemplate.html')
    );

    $templateCache.put(
        'app/payables/internalTransfer/create/views/internalTransferManyToOneTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferManyToOneTemplate.html')
    );

    $templateCache.put(
        'app/payables/internalTransfer/create/views/internalTransferManyToManyTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferManyToManyTemplate.html')
    );

    $templateCache.put(
        'app/payables/internalTransfer/edit/views/internalTransferConfirmationTemplate.html',
        require('./payables/internalTransfer/edit/views/internalTransferConfirmationTemplate.html')
    );

    $templateCache.put(
        '/app/payables/internalTransfer/create/views/internalTransferConfirmationTemplate.html',
        require('./payables/internalTransfer/create/views/internalTransferConfirmationTemplate.html')
    );

    $templateCache.put(
        '/app/payables/internalTransfer/create/views/loanTransferTemplate.html',
        require('./payables/internalTransfer/create/views/loanTransferTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/freeFormBeneficiaryView.html',
        require('./payables/wire/wires/views/freeFormBeneficiaryView.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/createWireEditTemplate.html',
        require('./payables/wire/wires/views/createWireEditTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/createMultipleWireEditTemplate.html',
        require('./payables/wire/wires/views/createMultipleWireEditTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/wireTemplateEditTemplate.html',
        require('./payables/wire/wires/views/wireTemplateEditTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/wires/views/wireEditTemplate.html',
        require('./payables/wire/wires/views/wireEditTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/wires/views/createWireReviewTemplate.html',
        require('./payables/wire/wires/views/createWireReviewTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/wires/views/wireReviewTemplate.html',
        require('./payables/wire/wires/views/wireReviewTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/createMultipleWireReviewTemplate.html',
        require('./payables/wire/wires/views/createMultipleWireReviewTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/wires/views/createWireTemplateReviewTemplate.html',
        require('./payables/wire/wires/views/createWireTemplateReviewTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/wireTemplateReviewTemplate.html',
        require('./payables/wire/wires/views/wireTemplateReviewTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/wires/views/createWireTemplateEditTemplate.html',
        require('./payables/wire/wires/views/createWireTemplateEditTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/beneficiaries/views/createEditBeneficiaryTemplate.html',
        require('./payables/wire/beneficiaries/views/createEditBeneficiaryTemplate.html')
    );

    $templateCache.put(
        'app/payables/wire/beneficiaries/views/intermediaryEditForSilverLakeUsdFxBeneficiary.html',
        require('./payables/wire/beneficiaries/views/intermediaryEditForSilverLakeUsdFxBeneficiary.html')
    );

    $templateCache.put(
        'app/payables/wire/beneficiaries/views/createEditBeneficiaryReviewTemplate.html',
        require('./payables/wire/beneficiaries/views/createEditBeneficiaryReviewTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/beneficiaries/views/beneficiaryEditTemplate.html',
        require('./payables/wire/beneficiaries/views/beneficiaryEditTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/beneficiaries/views/beneficiaryReviewTemplate.html',
        require('./payables/wire/beneficiaries/views/beneficiaryReviewTemplate.html')
    );
    $templateCache.put(
        'app/payables/wire/fx/views/fxWireWizardCreate.html',
        require('./payables/wire/fx/views/fxWireWizardCreate.html')
    );

    $templateCache.put(
        'app/payables/wire/fx/views/fxWireWizardConfirmation.html',
        require('./payables/wire/fx/views/fxWireWizardConfirmation.html')
    );
    $templateCache.put(
        'app/payables/wire/fx/views/fxWireForm.html',
        require('./payables/wire/fx/views/fxWireForm.html')
    );
    $templateCache.put(
        'app/payables/wire/fx/views/fxWireBeneficiaryDetails.html',
        require('./payables/wire/fx/views/fxWireBeneficiaryDetails.html')
    );

    $templateCache.put(
        'app/payables/wire/fx/views/fxWireWizardReview.html',
        require('./payables/wire/fx/views/fxWireWizardReview.html')
    );
    $templateCache.put(
        'app/payables/wire/fx/views/fxWireDetails.html',
        require('./payables/wire/fx/views/fxWireDetails.html')
    );
    $templateCache.put(
        '/app/payables/ach/payments/views/createPaymentSingleBatchTemplate.html',
        require('./payables/ach/payments/views/createPaymentSingleBatchTemplate.html')
    );
    $templateCache.put(
        'app/payables/ach/batch/views/batchHeaderInfoTemplate.html',
        require('./payables/ach/batch/views/batchHeaderInfoTemplate.html')
    );

    $templateCache.put(
        'app/payables/ach/batch/views/multipleAchBatchDetail.html',
        require('./payables/ach/batch/views/multipleAchBatchDetail.html')
    );

    $templateCache.put(
        'app/payables/ach/batch/views/manageRecipientsTemplate.html',
        require('./payables/ach/batch/views/manageRecipientsTemplate.html')
    );
    $templateCache.put(
        'app/payables/ach/batch/views/reviewBatchTemplate.html',
        require('./payables/ach/batch/views/reviewBatchTemplate.html')
    );
    $templateCache.put(
        'app/payables/ach/batch/views/batchHeaderSummaryTemplate.html',
        require('./payables/ach/batch/views/batchHeaderSummaryTemplate.html')
    );
    $templateCache.put(
        '/app/payables/ach/batch/directives/recipientsTable/recipientsTableTemplate.html',
        require('./payables/ach/batch/directives/recipientsTable/recipientsTableTemplate.html')
    );
    $templateCache.put(
        '/app/payables/ach/batch/directives/recipientsTable/recipientsTableReadOnlyTemplate.html',
        require('./payables/ach/batch/directives/recipientsTable/recipientsTableReadOnlyTemplate.html')
    );
    $templateCache.put(
        '/app/payables/ach/payments/views/createTaxPaymentSingle.html',
        require('./payables/ach/payments/views/createTaxPaymentSingle.html')
    );

    $templateCache.put(
        '/app/payables/ach/payments/views/createPaymentMultipleTaxTemplates.html',
        require('./payables/ach/payments/views/createPaymentMultipleTaxTemplates.html')
    );
    $templateCache.put(
        '/app/payables/ach/batchList/views/taxTemplateListView.html',
        require('./payables/ach/batchList/views/taxTemplateListView.html')
    );

    $templateCache.put(
        '/app/payables/ach/batchList/views/batchListView.html',
        require('./payables/ach/batchList/views/batchListView.html')
    );

    $templateCache.put(
        '/app/payables/ach/payments/views/createPaymentMultipleBatchesTemplate.html',
        require('./payables/ach/payments/views/createPaymentMultipleBatchesTemplate.html')
    );

    $templateCache.put(
        'app/payables/ach/payments/views/createPaymentMultipleStateTax.html',
        require('./payables/ach/payments/views/createPaymentMultipleStateTax.html')
    );

    $templateCache.put(
        'app/payables/ach/payments/views/reviewPaymentMultipleFederalTax.html',
        require('./payables/ach/payments/views/reviewPaymentMultipleFederalTax.html')
    );

    $templateCache.put(
        'app/payables/ach/payments/views/createPaymentMultipleFederalTax.html',
        require('./payables/ach/payments/views/createPaymentMultipleFederalTax.html')
    );

    $templateCache.put(
        'app/payables/ach/payments/views/reviewPaymentMultipleStateTax.html',
        require('./payables/ach/payments/views/reviewPaymentMultipleStateTax.html')
    );

    $templateCache.put(
        'app/payables/ach/batch/views/stateTaxTemplateDetailView.html',
        require('./payables/ach/batch/views/stateTaxTemplateDetailView.html')
    );

    $templateCache.put(
        'app/payables/ach/taxes/views/createTaxPaymentForm.html',
        require('./payables/ach/taxes/views/createTaxPaymentForm.html')
    );
    $templateCache.put(
        'app/payables/ach/taxes/views/createFederalTaxPaymentForm.html',
        require('./payables/ach/taxes/views/createFederalTaxPaymentForm.html')
    );
    $templateCache.put(
        'app/payables/ach/taxes/views/reviewTaxPayment.html',
        require('./payables/ach/taxes/views/reviewTaxPayment.html')
    );
    $templateCache.put(
        'app/payables/ach/taxes/views/reviewFederalTaxSummary.html',
        require('./payables/ach/taxes/views/reviewFederalTaxSummary.html')
    );
    $templateCache.put(
        'app/payables/ach/taxes/views/createStateTaxPaymentForm.html',
        require('./payables/ach/taxes/views/createStateTaxPaymentForm.html')
    );
    $templateCache.put(
        'app/payables/ach/taxes/views/reviewStateTaxSummary.html',
        require('./payables/ach/taxes/views/reviewStateTaxSummary.html')
    );
    $templateCache.put(
        'app/payables/ach/batch/views/federalTaxTemplateDetailView.html',
        require('./payables/ach/batch/views/federalTaxTemplateDetailView.html')
    );
    $templateCache.put(
        'app/payables/arp/views/uploadIssuedItemsTemplate.html',
        require('./payables/arp/views/uploadIssuedItemsTemplate.html')
    );

    $templateCache.put(
        'app/payables/arp/views/manageIssuedItemsTemplate.html',
        require('./payables/arp/views/manageIssuedItemsTemplate.html')
    );

    $templateCache.put(
        'app/payables/arp/views/posPayMappingDelimitedColumnOrderView.html',
        require('./payables/arp/views/posPayMappingDelimitedColumnOrderView.html')
    );

    $templateCache.put(
        'app/payables/arp/views/posPayMappingFixedPositionOrderView.html',
        require('./payables/arp/views/posPayMappingFixedPositionOrderView.html')
    );

    $templateCache.put(
        '/app/payables/stp/views/createStpTemplate.html',
        require('./payables/stp/views/createStpTemplate.html')
    );
    $templateCache.put(
        '/app/payables/stp/views/reviewStpTemplate.html',
        require('./payables/stp/views/reviewStpTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/userInformationTemplate.html',
        require('./admin/users/views/userInformationTemplate.html')
    );

    $templateCache.put(
        'app/admin/users/views/timeAccessTemplate.html',
        require('./admin/users/views/timeAccessTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/accountAccessTemplate.html',
        require('./admin/users/views/accountAccessTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/ipRestrictTemplate.html',
        require('./admin/users/views/ipRestrictTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/timeAccessTemplate.html',
        require('./admin/users/views/timeAccessTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/achUserEntitlements.html',
        require('./admin/users/views/achUserEntitlements.html')
    );
    $templateCache.put(
        'app/admin/users/views/achUserLimits.html',
        require('./admin/users/views/achUserLimits.html')
    );
    $templateCache.put(
        'app/admin/users/views/arpUserEntitlementsTemplate.html',
        require('./admin/users/views/arpUserEntitlementsTemplate.html')
    );

    $templateCache.put(
        'app/admin/users/views/ssoPosPayUserSettings.html',
        require('./admin/users/views/ssoPosPayUserSettings.html')
    );

    $templateCache.put(
        'app/admin/users/views/arpUserEntitlements.html',
        require('./admin/users/views/arpUserEntitlements.html')
    );
    $templateCache.put(
        'app/admin/users/views/informationReportingEntitlementsTemplate.html',
        require('./admin/users/views/informationReportingEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/accountReconciliationReportingEntitlementsTemplate.html',
        require('./admin/users/views/accountReconciliationReportingEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/internalTransferEntitlementsTemplate.html',
        require('./admin/users/views/internalTransferEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/internalTransferUserLimits.html',
        require('./admin/users/views/internalTransferUserLimits.html')
    );
    $templateCache.put(
        'app/admin/users/views/stpEntitlementsTemplate.html',
        require('./admin/users/views/stpEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/wireTransfersEntitlementsTemplate.html',
        require('./admin/users/views/wireTransfersEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/wireTransferUserLimits.html',
        require('./admin/users/views/wireTransferUserLimits.html')
    );
    $templateCache.put(
        'app/admin/users/views/wireTransfersDliEntitlementsTemplate.html',
        require('./admin/users/views/wireTransfersDliEntitlementsTemplate.html')
    );
    $templateCache.put(
        'app/admin/users/views/wireTransferEntitlementsForm.html',
        require('./admin/users/views/wireTransferEntitlementsForm.html')
    );
    $templateCache.put(
        'app/admin/users/views/wireTransferDliUserLimits.html',
        require('./admin/users/views/wireTransferDliUserLimits.html')
    );
    $templateCache.put(
        'app/admin/users/views/receivablesUserSettings.html',
        require('./admin/users/views/receivablesUserSettings.html')
    );
    $templateCache.put(
        'app/admin/users/views/integratedServicesEntitlementsTemplate.html',
        require('./admin/users/views/integratedServicesEntitlementsTemplate.html')
    );

    $templateCache.put(
        '/app/directives/components/reportFilter/reportFilterTemplate.html',
        require('./directives/components/reportFilter/reportFilterTemplate.html')
    );

    $templateCache.put(
        '/app/directives/components/reportFilter/saveReport/saveReportModalView.html',
        require('./directives/components/reportFilter/saveReport/saveReportModalView.html')
    );

    $templateCache.put(
        '/app/directives/controls/approvalStatus/approvalsPopover.html',
        require('./directives/controls/approvalStatus/approvalsPopover.html')
    );

    $templateCache.put(
        '/app/directives/components/dateSearch/dateSearchTemplate.html',
        require('./directives/components/dateSearch/dateSearchTemplate.html')
    );

    $templateCache.put(
        '/app/directives/controls/typeAheadAccount/typeaheadAccountTemplate.html',
        require('./directives/controls/typeAheadAccount/typeaheadAccountTemplate.html')
    );

    $templateCache.put(
        '/app/directives/components/amountSearch/amountSearchTemplate.html',
        require('./directives/components/amountSearch/amountSearchTemplate.html')
    );
}
