import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

const DEFAULT_LABELS = Object.freeze({
    active: 'Active',
    inactive: 'Inactive',
});

class OmegaToggle extends LitElement {
    static get properties() {
        return {
            field: Object,
            label: String,
            disabled: { type: Boolean, reflect: true },
            record: Object,
            checked: Boolean,
            value: String,
            activeText: String,
            inactiveText: String,
            hideLabel: { type: Boolean, reflect: true },
            hideText: { type: Boolean, reflect: true },
        };
    }

    constructor() {
        super();

        this.checked = false;
        this.hideText = false;
        this.activeText = DEFAULT_LABELS.active;
        this.inactiveText = DEFAULT_LABELS.inactive;
        this.value = '';
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-toggle--toggle',
        };
    }

    get toggleText() {
        return this.checked ? this.activeText : this.inactiveText;
    }

    onChange() {
        this.dispatchEvent(
            new CustomEvent('change', { detail: { checked: this.checked, value: this.value } })
        );
    }

    onClick() {
        if (this.disabled) return;
        this.checked = !this.checked;
        this.onChange();
    }

    renderLabel() {
        if (this.hideLabel) return nothing;
        return html`<label for=${this.field}>${this.label}</label>`;
    }

    renderText() {
        return this.hideText
            ? nothing
            : html`<span class="omega-toggle-text">${this.toggleText}</span>`;
    }

    render() {
        const dynamicClasses = {
            active: this.checked,
            inactive: !this.checked,
        };

        return html`
            ${this.renderLabel()}
            <input
                id=${this.field}
                type="checkbox"
                ?checked=${this.checked}
                class="omega-toggle-checkbox visually-hidden"
                .disabled=${this.disabled}
                .value=${this.value}
                @change=${this.onChange}
            />
            <div
                class="omega-toggle ${classMap(dynamicClasses)}"
                aria-hidden="true"
                @click=${this.onClick}
                tabindex="-1"
                role="presentation"
            >
                <div class="omega-toggle-indicator"></div>
                ${this.renderText()}
            </div>
        `;
    }

    static get styles() {
        const indicatorSize = css`24px`;

        return css`
            :host {
                display: inline-block;
                margin: 5px;
            }

            label {
                font-size: var(--omega-label);
            }

            .value-label {
                font-size: var(--omega-hint);
                line-height: 24px;
            }

            #positive-label {
                color: var(--omega-white);
            }

            .omega-toggle {
                padding: 6px 6px;
                width: 80px;
                height: 18px;
                border-radius: 14px;
                position: relative;
            }

            .omega-toggle.inactive {
                background-color: #686868;
                text-align: right;
            }

            .omega-toggle.active {
                background-color: var(--omega-primary);
                text-align: left;
            }

            .omega-toggle-text {
                color: #fff;
                position: relative;
                font-size: var(--omega-label);
            }

            .omega-toggle-indicator {
                position: absolute;
                width: ${indicatorSize};
                height: ${indicatorSize};
                background-color: white;
                border-radius: 12px;
                transition: transform 0.2s;
                transform: translate(0, -3px);
            }

            .omega-toggle:not(:disabled):hover {
                cursor: pointer;
            }

            .omega-toggle:focus {
                outline: none;
            }

            .omega-toggle-checkbox:focus + .omega-toggle,
            .omega-toggle:focus {
                box-shadow: 0 1px 4px 0;
                outline: none;
            }

            .omega-toggle-checkbox:focus + .omega-toggle > .omega-toggle-indicator {
                box-shadow: 0 1px 4px 0;
            }

            .omega-toggle-checkbox:disabled + .omega-toggle {
                opacity: 0.6;
                pointer-events: none;
            }

            .omega-toggle.active > .omega-toggle-indicator {
                transform: translate(58px, -3px);
            }

            .visually-hidden {
                position: absolute !important;
                overflow: hidden;
                width: 1px;
                height: 1px;
                clip: rect(1px, 1px, 1px, 1px);
            }
        `;
    }
}

window.customElements.define('omega-toggle', OmegaToggle);
export default OmegaToggle;
