import { getChannelAPIPath } from '@treasury/core/http';

ImportLayoutService.$inject = ['$resource', '$http'];

export default function ImportLayoutService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}achImportLayout/`;

    return {
        getImportLayoutValues,
        save,
    };

    function getImportLayoutValues() {
        return $resource(resourceUrl).get().$promise;
    }

    // this needs to be in place until such a time as a delimiter drop-down is implemented
    function setDelimiter(importLayout) {
        if (importLayout.uploadFormat == 'delimited') {
            importLayout.delimiter = 'comma';
        } else {
            importLayout.delimiter = null;
        }
    }

    function save(importLayout) {
        setDelimiter(importLayout);
        return $http.put(resourceUrl, importLayout).then(response => response.data);
    }
}
