CreateTaxPaymentController.$inject = ['$rootScope', '$scope', '$state', 'achBankService'];

export default function CreateTaxPaymentController($rootScope, $scope, $state, achBankService) {
    init();
    function init() {
        if ($state.params.paymentList) {
            $scope.setSelectedBatches($state.params.paymentList);
        }

        achBankService.getAllBanks().then(response => {
            $rootScope.banks = response;
        });
    }
}
