import { css } from 'lit';

export const exceptionWidgetStyles = css`
    :host {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
    }
    .outside-cutoff-time {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .outside-cutoff-time .message {
        font-size: 15px;
    }
    .alert-wrapper {
        padding: 1px 8px;
    }
`;
