/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ChallengeSettingsModelDto,
    CompanyUserTimeAccessDto,
    GetUserAccountAccessModelDto,
    GetUserAchEntitlementsModelDto,
    GetUserAchLimitsModelDto,
    GetUserProductEntitlementsModelDto,
    GetUserProductLimitsResponseModelDto,
    IpRestrictionAddressModelDto,
    IpRestrictionModelDto,
    LookupModelDto,
    ReceivablesUserSettingsModelDto,
    SaveUserAccountAccessModelDto,
    SaveUserAchProductEntitlementsModelDto,
    SaveUserProductEntitlementsModelDto,
    SearchCompanyUserModelDto,
    SsoPosPayUserSettingsModelDto,
    UserInformationModelDto,
    UserInformationResponseModelDto,
    UserProductFeatureSummaryModelDto,
    UserStateModelDto,
    UserSummaryModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class CompanyuserClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserResetPassword
     * @summary Reset company user password.
     * @request POST:/companyuser/{companyId}/users/{userId}/resetpassword
     */
    companyUserResetPassword = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/resetpassword`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSearch
     * @summary Searches for users.
     * @request POST:/companyuser/users/search
     */
    companyUserSearch = (
        searchUserModel: SearchCompanyUserModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<UserSummaryModelDto, any>({
            path: `/companyuser/users/search`,
            method: 'POST',
            body: searchUserModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserManualResetPassword
     * @summary Manually reset company user password.
     * @request POST:/companyuser/{companyId}/users/{userId}/manualresetpassword
     */
    companyUserManualResetPassword = (
        companyId: number,
        userId: number,
        password: LookupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/manualresetpassword`,
            method: 'POST',
            body: password,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserDeleteUser
     * @summary Delete user.
     * @request DELETE:/companyuser/{companyId}/users/{userId}
     */
    companyUserDeleteUser = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSetActive
     * @summary Sets as user as active or inactive
     * @request PUT:/companyuser/{companyId}/users/{userId}/setactive
     */
    companyUserSetActive = (
        companyId: number,
        userId: number,
        query: {
            isActive: boolean;
            comments: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<UserSummaryModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/setactive`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserUnlock
     * @summary Unlocks a user
     * @request PUT:/companyuser/{companyId}/users/{userId}/unlock
     */
    companyUserUnlock = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<UserSummaryModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/unlock`,
            method: 'PUT',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserState
     * @summary Gets the header information when editing a user
     * @request GET:/companyuser/{companyId}/users/{userId}/header
     */
    companyUserGetUserState = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<UserStateModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/header`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetProductFeatureSummary
     * @summary Gets the product feature summary when editing a user
     * @request GET:/companyuser/{companyId}/users/{userId}/productfeaturesummary
     */
    companyUserGetProductFeatureSummary = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<UserProductFeatureSummaryModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/productfeaturesummary`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSubmit
     * @summary Submits new user information
     * @request PUT:/companyuser/{companyId}/users/{userId}/submit
     */
    companyUserSubmit = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/submit`,
            method: 'PUT',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserDiscard
     * @summary Discards user information that is pending submission
     * @request PUT:/companyuser/{companyId}/users/{userId}/discard
     */
    companyUserDiscard = (
        companyId: number,
        userId: number,
        query: {
            comments: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/discard`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserApprove
     * @summary Approves user information that has been submitted
     * @request PUT:/companyuser/{companyId}/users/{userId}/approve
     */
    companyUserApprove = (
        companyId: number,
        userId: number,
        query: {
            comments: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/approve`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserReject
     * @summary Rejects user information that has been submitted
     * @request PUT:/companyuser/{companyId}/users/{userId}/reject
     */
    companyUserReject = (
        companyId: number,
        userId: number,
        query: {
            comments: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/reject`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserInformation
     * @summary Creates a new user using User Information
     * @request POST:/companyuser/{companyId}/users/{userId}/userinformation
     */
    companyUserSaveUserInformation = (
        companyId: number,
        userId: number,
        userInformation: UserInformationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<UserInformationModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/userinformation`,
            method: 'POST',
            body: userInformation,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserInformation
     * @summary Gets a user's User Information
     * @request GET:/companyuser/{companyId}/users/{userId}/userinformation
     */
    companyUserGetUserInformation = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<UserInformationResponseModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/userinformation`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserChallengeDetail
     * @summary Returns user challenge details.
     * @request GET:/companyuser/{companyId}/users/{userId}/userchallengedetail
     */
    companyUserGetUserChallengeDetail = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<ChallengeSettingsModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/userchallengedetail`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetAccountAccess
     * @summary Gets a user's Account Access
     * @request GET:/companyuser/{companyId}/users/{userId}/accountaccess
     */
    companyUserGetAccountAccess = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<GetUserAccountAccessModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/accountaccess`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserAccountAccess
     * @summary Updates a user's Account Access
     * @request PUT:/companyuser/{companyId}/users/{userId}/accountaccess
     */
    companyUserSaveUserAccountAccess = (
        companyId: number,
        userId: number,
        accountAccess: SaveUserAccountAccessModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/accountaccess`,
            method: 'PUT',
            body: accountAccess,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserProductEntitlements
     * @summary Gets a user's Product Entitlements
     * @request GET:/companyuser/{companyId}/users/{userId}/productentitlements
     */
    companyUserGetUserProductEntitlements = (
        companyId: number,
        userId: number,
        query: {
            productFeatureType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<GetUserProductEntitlementsModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/productentitlements`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserProductEntitlements
     * @summary Updates a user's Product Entitlements
     * @request PUT:/companyuser/{companyId}/users/{userId}/productentitlements
     */
    companyUserSaveUserProductEntitlements = (
        companyId: number,
        userId: number,
        saveUserProductEntitlementsModel: SaveUserProductEntitlementsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/productentitlements`,
            method: 'PUT',
            body: saveUserProductEntitlementsModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserAchEntitlements
     * @summary Gets a user's Ach Entitlements
     * @request GET:/companyuser/{companyId}/users/{userId}/achproductentitlements
     */
    companyUserGetUserAchEntitlements = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<GetUserAchEntitlementsModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/achproductentitlements`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserAchProductEntitlements
     * @summary Updates a user's Product Entitlements Config
     * @request PUT:/companyuser/{companyId}/users/{userId}/achproductentitlements
     */
    companyUserSaveUserAchProductEntitlements = (
        companyId: number,
        userId: number,
        saveUserProductEntitlementsModel: SaveUserAchProductEntitlementsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/achproductentitlements`,
            method: 'PUT',
            body: saveUserProductEntitlementsModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserProductLimits
     * @summary Gets a user's Product Limits
     * @request GET:/companyuser/{companyId}/users/{userId}/productlimits
     */
    companyUserGetUserProductLimits = (
        companyId: number,
        userId: number,
        query: {
            productFeatureType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<GetUserProductLimitsResponseModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/productlimits`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserProductLimits
     * @summary Updates a User's Product Limits Config
     * @request PUT:/companyuser/{companyId}/users/{userId}/productlimits
     */
    companyUserSaveUserProductLimits = (
        companyId: number,
        userId: number,
        productLimitsConfig: GetUserProductLimitsResponseModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/productlimits`,
            method: 'PUT',
            body: productLimitsConfig,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserAchLimits
     * @summary Gets a user's Ach limits
     * @request GET:/companyuser/{companyId}/users/{userId}/achlimits
     */
    companyUserGetUserAchLimits = (companyId: number, userId: number, params: RequestParams = {}) =>
        this.http.request<GetUserAchLimitsModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/achlimits`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserAchLimits
     * @summary Updates a User's ACH Limits
     * @request PUT:/companyuser/{companyId}/users/{userId}/achlimits
     */
    companyUserSaveUserAchLimits = (
        companyId: number,
        userId: number,
        achLimits: GetUserAchLimitsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/achlimits`,
            method: 'PUT',
            body: achLimits,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserUnlockUserChallenge
     * @summary Unlocks User Challenge points
     * @request PUT:/companyuser/{id}/users/{actionId}/unlockUserChallenge
     */
    companyUserUnlockUserChallenge = (
        id: number,
        actionId: number,
        query: {
            recollect: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${id}/users/${actionId}/unlockUserChallenge`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserReCollectUserChallenge
     * @summary Recollect User Challenge Authentication Information
     * @request PUT:/companyuser/{id}/users/{actionId}/reCollectUserChallenge
     */
    companyUserReCollectUserChallenge = (
        id: number,
        actionId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${id}/users/${actionId}/reCollectUserChallenge`,
            method: 'PUT',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserTimeAccess
     * @summary Gets a User's Time Access
     * @request GET:/companyuser/{id}/users/{actionId}/usertimeaccess
     */
    companyUserGetUserTimeAccess = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<CompanyUserTimeAccessDto, any>({
            path: `/companyuser/${id}/users/${actionId}/usertimeaccess`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserSaveUserTimeAccess
     * @summary Updates a User's Time Access
     * @request POST:/companyuser/{id}/users/{actionId}/usertimeaccess
     */
    companyUserSaveUserTimeAccess = (
        id: number,
        actionId: number,
        userTimeAccess: CompanyUserTimeAccessDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${id}/users/${actionId}/usertimeaccess`,
            method: 'POST',
            body: userTimeAccess,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetIpRestrictions
     * @summary Gets IP restrictions.
     * @request GET:/companyuser/{companyId}/users/{userId}/iprestrictions
     */
    companyUserGetIpRestrictions = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<IpRestrictionModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/iprestrictions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserPutIpRestrictions
     * @summary Update IP restrictions.
     * @request PUT:/companyuser/{companyId}/users/{userId}/iprestrictions
     */
    companyUserPutIpRestrictions = (
        companyId: number,
        userId: number,
        query: {
            /** True or False. */
            restricted: boolean;
        },
        models: IpRestrictionAddressModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/iprestrictions`,
            method: 'PUT',
            query: query,
            body: models,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserSsoPosPaySettings
     * @summary Gets SSO positive pay settings.
     * @request GET:/companyuser/{companyId}/users/{userId}/ssopospaysettings
     */
    companyUserGetUserSsoPosPaySettings = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<SsoPosPayUserSettingsModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/ssopospaysettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserPutUserSsoPosPaySettings
     * @summary Update SSO positive pay settings.
     * @request PUT:/companyuser/{companyId}/users/{userId}/ssopospaysettings
     */
    companyUserPutUserSsoPosPaySettings = (
        companyId: number,
        userId: number,
        model: SsoPosPayUserSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/ssopospaysettings`,
            method: 'PUT',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGetUserReceivablesSettings
     * @summary Gets user receivable settings.
     * @request GET:/companyuser/{companyId}/users/{userId}/receivablessettings
     */
    companyUserGetUserReceivablesSettings = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<ReceivablesUserSettingsModelDto, any>({
            path: `/companyuser/${companyId}/users/${userId}/receivablessettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserPutUserReceivablesSettings
     * @summary Updates user receivable settings.
     * @request PUT:/companyuser/{companyId}/users/{userId}/receivablessettings
     */
    companyUserPutUserReceivablesSettings = (
        companyId: number,
        userId: number,
        model: ReceivablesUserSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/receivablessettings`,
            method: 'PUT',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUser
     * @name CompanyUserGenerateEnrollmentEmail
     * @summary Generates an enrollment email and sends it to the user's registered email.
     * @request POST:/companyuser/{companyId}/users/{userId}/generateenrollmentemail
     */
    companyUserGenerateEnrollmentEmail = (
        companyId: number,
        userId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/companyuser/${companyId}/users/${userId}/generateenrollmentemail`,
            method: 'POST',
            format: 'json',
            ...params,
        });
}
