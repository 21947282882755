/* eslint-disable import/no-extraneous-dependencies */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import isInternationalAchCompany from '../../../shared/utilities/is-international-ach-company.js';
import sortByAchCompanyName from '../../../shared/utilities/sort-by-ach-company-name.js';
import { downloadBlob, getFileExtension } from '../../../utilities/file-handling';
import {
    internationalTransactionTypes,
    mapDestinationCountry,
    mapPaymentHeaderAndRecipients,
    mapTemplateFilters,
    mapTemplateRequestDataForWorkflow,
    mapWorkflowDataForTemplateApi,
    parsePaymentResponse,
} from '../../mappings/ach/ach-international-payments';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchDomesticRequests from '../../requests/ach/ach-domestic-requests.js';
import AchInternationalRequests from '../../requests/ach/ach-international-requests.js';
import AchInternationalTemplateRequests from '../../requests/ach/ach-international-template-requests.js';
import StateRequests from '../../requests/states/state-requests.js';
import { UserRequests } from '../../requests/users/users.js';
import {
    InternationalAchTemplateFiltersDto,
    InternationalAchTemplateHeader,
    InternationalAchTemplateOptions,
    InternationalAchTemplateRecipients,
} from '../../types/ach/international';

let countriesInEurope: any;
export class AchInternationalServices {
    static async getCountries() {
        const response = await AchInternationalRequests.getInternationalAchCountries();
        countriesInEurope = response.internationalAchCountries.filter(
            (country: { receivingBankCountry: string }) => country.receivingBankCountry === 'Europe'
        );
        return response.internationalAchCountries;
    }

    static async getCountriesForPayment() {
        const countries = await this.getCountries();
        return countries.filter((country: any) => country.isActive);
    }

    static async getPaymentById(paymentId: string) {
        const response = await AchInternationalRequests.getInternationalAchPayment(paymentId);

        return parsePaymentResponse(response, this.getCountries);
    }

    static async getStates() {
        return StateRequests.getStates();
    }

    static async getAchCompanies() {
        const companies = await AchCompanyRequests.getAchCompanies();
        const internationalCompanies = companies.filter(isInternationalAchCompany);
        return sortByAchCompanyName(internationalCompanies);
    }

    static async getUserAccountsToOffsetBalancedCompany() {
        /*
        Offset accounts for balanced companies are actually
        the accounts the user has access too. Dont hit an offset specific endpoint
        */
        return UserRequests.getAccounts();
    }

    static async getOffsetAccounts(achCompanyId: string) {
        return AchCompanyRequests.getAchCompanyOffsetAccounts(achCompanyId);
    }

    static async getDestinationCountry(destinationCountry: {
        receivingBankCountry: any;
        countryCode: any;
        countryName: any;
    }) {
        return mapDestinationCountry(destinationCountry, countriesInEurope);
    }

    static async getInternationalTransactionTypes() {
        return internationalTransactionTypes();
    }

    static async createPayment(paymentHeaderRecord: object, recipientsRecordset: any[]) {
        const body = mapPaymentHeaderAndRecipients(paymentHeaderRecord, recipientsRecordset);

        return AchInternationalRequests.createInternationalAchPayment(body);
    }

    static async uploadNachaFile(file: Blob) {
        const formData = new FormData();
        formData.append('file', file);
        return AchInternationalRequests.uploadInternationalNachaFile(formData);
    }

    static async updateInternationalAchPayment(
        paymentHeaderRecord: object,
        recipientsRecordset: any[],
        paymentId: string
    ) {
        const body = mapPaymentHeaderAndRecipients(paymentHeaderRecord, recipientsRecordset);
        return AchInternationalRequests.updateInternationalAchPayment(body, paymentId);
    }

    static async savePaymentAsTemplate(
        paymentHeader: { values: InternationalAchTemplateHeader },
        recipientRecordset: { allRecords: { InternationalAchTemplateRecipients: any; map: any } }
    ) {
        const options = await AchInternationalTemplateRequests.getOptions();

        const body = mapWorkflowDataForTemplateApi(
            paymentHeader.values,
            recipientRecordset.allRecords.map((r: any[]) => r.values),
            options,
            'create'
        );
        return AchInternationalTemplateRequests.createInternationalAchTemplate(body);
    }

    static async createInternationalAchTemplate(
        paymentHeader: InternationalAchTemplateHeader,
        recipientRecordset: InternationalAchTemplateRecipients[],
        paymentId: string,
        iatAchOptions: InternationalAchTemplateOptions
    ) {
        // to do - remove line below and pass the options data in from container via client
        const options = iatAchOptions || (await AchInternationalTemplateRequests.getOptions());

        const body = mapWorkflowDataForTemplateApi(
            paymentHeader,
            recipientRecordset,
            options,
            'create'
        );

        return AchInternationalTemplateRequests.createInternationalAchTemplate(body);
    }

    static async isUniqueTemplateName(name: string) {
        return AchDomesticRequests.isTemplateNameValid(name);
    }

    static async getTemplates(filter: InternationalAchTemplateFiltersDto) {
        const body = mapTemplateFilters(filter);
        const response = await AchInternationalTemplateRequests.getTemplates(body);
        return {
            data: response.templates ?? [],
            totalCount: response.templates?.length ?? 0,
        };
    }

    static async getTemplateById(
        id: string,
        iatAchOptions: InternationalAchTemplateOptions | undefined
    ) {
        let options;
        if (!iatAchOptions) options = await AchInternationalTemplateRequests.getOptions();
        const response = await AchInternationalTemplateRequests.getTemplateById(id);
        return mapTemplateRequestDataForWorkflow(response, iatAchOptions || options);
    }

    static async deleteTemplate(id: string) {
        return AchInternationalTemplateRequests.deleteTemplate(id);
    }

    static async downloadTemplate(filterModel: string, outputType: string) {
        const blob = await AchInternationalTemplateRequests.downloadTemplate({
            filterModel,
            outputType,
        });
        const fileExtension = getFileExtension(outputType);
        const filename = `International Ach Template.${fileExtension}`;
        return downloadBlob(blob, filename);
    }

    static async downloadTemplateList(
        filter: InternationalAchTemplateFiltersDto,
        outputType: string
    ) {
        const body = { outputType, filterModel: mapTemplateFilters(filter) };
        const blob = await AchInternationalTemplateRequests.downloadTemplateList(body);
        const fileExtension = getFileExtension(outputType);
        const filename = `International Ach Templates.${fileExtension}`;
        return downloadBlob(blob, filename);
    }

    static async getIatAchOptions() {
        return AchInternationalTemplateRequests.getOptions();
    }

    static async updateTemplate(
        paymentHeader: InternationalAchTemplateHeader,
        recipientRecordset: InternationalAchTemplateRecipients[],
        paymentId: string | any,
        iatAchOptions: InternationalAchTemplateOptions
    ) {
        const body = mapWorkflowDataForTemplateApi(
            paymentHeader,
            recipientRecordset,
            iatAchOptions,
            'edit'
        );
        body.id = paymentId;
        return AchInternationalTemplateRequests.updateTemplate(body);
    }

    static async getTemplateApprovers(id: string) {
        return AchInternationalTemplateRequests.getApprovers(id);
    }

    static async approveTemplate(id: string, comments: string) {
        const dto = { id, comments };
        const response = AchInternationalTemplateRequests.approve(dto);
        const di = await DiContainer.getInstance();
        const analytics = await di.getAsync(AnalyticsService);

        analytics.track(AnalyticsEvent.AchTemplateApproved, {
            templateId: id,
        });

        return response;
    }

    static async rejectTemplate(id: string, comments: string) {
        const body = { id, comments };
        return AchInternationalTemplateRequests.reject(body);
    }
}
