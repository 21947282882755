/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ErrorHttpContentDto,
    SaveAccountReconIssuedItemsRequestDto,
    SaveAccountReconIssuedItemsResponseDto,
    SearchAccountReconIssuedItemsRequestDto,
    SearchAccountReconIssuedItemsResponseDto,
    SearchIssuedItemsVoidsRequestDto,
    SearchIssuedItemsVoidsResponseDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class IssuedItemsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags IssuedItems
     * @name IssuedItemsSearchIssuedItems
     * @summary Gets Issued Items based on search criteria.
     * @request POST:/issuedItems/searchIssuedItems
     */
    issuedItemsSearchIssuedItems = (
        searchAccountReconIssuedItemsRequest: SearchAccountReconIssuedItemsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SearchAccountReconIssuedItemsResponseDto, ErrorHttpContentDto>({
            path: `/issuedItems/searchIssuedItems`,
            method: 'POST',
            body: searchAccountReconIssuedItemsRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IssuedItems
     * @name IssuedItemsSearchIssuedItems2
     * @summary Searches Treasury Management for matches on supplied voids.
     * @request POST:/issuedItems/searchIssuedItemsVoids
     */
    issuedItemsSearchIssuedItems2 = (
        searchIssuedItemsVoidsRequest: SearchIssuedItemsVoidsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SearchIssuedItemsVoidsResponseDto, ErrorHttpContentDto>({
            path: `/issuedItems/searchIssuedItemsVoids`,
            method: 'POST',
            body: searchIssuedItemsVoidsRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IssuedItems
     * @name IssuedItemsSaveIssuedItems
     * @summary Saves Account Recon Issued Item(s)
     * @request POST:/issuedItems/saveIssuedItems
     */
    issuedItemsSaveIssuedItems = (
        saveAccountReconIssuedItemsRequest: SaveAccountReconIssuedItemsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SaveAccountReconIssuedItemsResponseDto, ErrorHttpContentDto>({
            path: `/issuedItems/saveIssuedItems`,
            method: 'POST',
            body: saveAccountReconIssuedItemsRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
