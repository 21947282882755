MessageCenterComposeModalController.$inject = [
    '$scope',
    '$modalInstance',
    'modalService',
    'messagingCenterService',
    'uiMessages',
    'entitlementsService',
];

export default function MessageCenterComposeModalController(
    $scope,
    $modalInstance,
    modalService,
    messagingCenterService,
    uiMessages,
    entitlementsService
) {
    $scope.selectedUser = {
        object: null,
    };
    $scope.message = {
        files: [],
    };
    $scope.clicked = false;
    $scope.allowedExtensions = entitlementsService.hasEntitlement(
        'Feature.MessageCenter.CreateSaveMessage'
    )
        ? [
              '.csv',
              '.doc',
              '.docx',
              '.gif',
              '.jfif',
              '.jif',
              '.jpg',
              '.jpeg',
              '.jpe',
              '.pdf',
              '.png',
              '.txt',
              '.xls',
              '.xlsx',
          ]
        : ['.csv', '.doc', '.docx', '.gif', '.jpg', '.jpeg', '.pdf', '.png', '.txt'];

    $scope.validationOptions = {
        maxFileSize: 3145728,
        allowedExtensions: $scope.allowedExtensions,
    };

    $scope.$watch('message.companyId', () => {
        if ($scope.message.companyId) {
            getCompanyUsers();
        } else {
            $scope.message.userId = null;
            $scope.users = [];
        }
    });

    $scope.onSelectFile = function (e) {
        if (e.files) {
            angular.forEach(e.files, file => {
                if ($.inArray(file.extension, $scope.validationOptions.allowedExtensions) === -1) {
                    modalService.showModal(
                        {},
                        {
                            bodyTitle: 'File Error',
                            alertType: 'Error',
                            isAlert: true,
                            summaryText: uiMessages.FileWrongExtension,
                        }
                    );
                    e.preventDefault();
                }
                // File size is not available in all browsers
                else if (file.size > $scope.validationOptions.maxFileSize) {
                    modalService.showModal(
                        {},
                        {
                            bodyTitle: 'File Error',
                            alertType: 'Error',
                            isAlert: true,
                            summaryText: uiMessages.DocExceedsMaxSize,
                        }
                    );
                    e.preventDefault();
                }
            });

            $scope.form.$setDirty();
            angular.forEach(e.files, file => {
                $scope.message.files.push({ uid: file.uid, rawFile: file.rawFile });
            });
            $scope.$apply();
        }
    };

    $scope.onRemoveFile = function (e) {
        if (e.files) {
            $scope.form.$setDirty();
            angular.forEach(e.files, file => {
                $scope.message.files = $scope.message.files.filter(el => el.uid !== file.uid);
            });
            $scope.$apply();
        }
    };

    $scope.cancel = function () {
        if ($scope.form.$dirty) {
            var confirmCancel = modalService.showModal(
                {},
                {
                    closeButtonText: 'Continue Editing',
                    actionButtonText: 'OK',
                    headerText: 'Cancel',
                    bodyText: "You haven't sent a message. Are you sure you want to cancel?",
                    submit(result) {
                        confirmCancel.close();
                        $modalInstance.close();
                    },
                }
            );
        } else {
            $modalInstance.close();
        }
    };

    $scope.send = function () {
        $scope.clicked = true;
        messagingCenterService.saveMessage($scope.message).then(response => {
            $scope.message = response;
            $modalInstance.close($scope.message);
            $scope.clicked = false;
        });
    };

    function init() {
        getMessageCategories();
    }

    function getMessageCategories() {
        messagingCenterService.getMessageCategories().then(response => {
            $scope.categories = angular.copy(response);
        });
    }

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    init();
}
