import { css, html, LitElement } from 'lit';
import '../../../omega-select.js';
import { formStyles } from '../../form-styles.js';
import './start-and-end.js';

export class OmegaFrequencyWeekRecurring extends LitElement {
    static get properties() {
        return {
            frequency: { type: Object },
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
            isValidStartDate: { type: Function },
            isValidEndDate: { type: Function },
            isStartDateInvalid: { type: Function },
            isEndDateInvalid: { type: Function },
        };
    }

    repeatOnChange(e) {
        this.frequency = { ...this.frequency, repeatOn: e.target.value };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    setFrequency(e) {
        this.frequency = e.detail;
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    get validRepeatOn() {
        return !!this.frequency?.repeatOn;
    }

    render() {
        const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map(day => ({
            text: day,
            value: day,
        }));
        return html`
            <div class="row">
                <label
                    >Repeat On:
                    ${!this.readOnly ? html`<span class="required"> *</span>` : null}</label
                >
                ${this.readOnly
                    ? html`<div class="control">
                          ${this.frequency?.repeatOn ?? '(not selected)'}
                      </div>`
                    : html`<omega-select
                          class="control"
                          .items=${dayOptions}
                          .value=${this.frequency?.repeatOn}
                          @change=${this.repeatOnChange}
                          .valid=${this.validRepeatOn}
                          ?disabled=${this.disabled}
                      ></omega-select>`}
            </div>
            <omega-frequency-start-and-end
                .frequency=${this.frequency}
                .isStartDateInvalid=${this.isStartDateInvalid}
                .isEndDateInvalid=${this.isEndDateInvalid}
                ?disabled=${this.disabled}
                ?readonly=${this.readOnly}
                @change=${this.setFrequency}
            ></omega-frequency-start-and-end>
        `;
    }

    static get styles() {
        return [
            formStyles,
            css`
                :host {
                    display: block;
                }
            `,
        ];
    }
}

window.customElements.define('omega-frequency-week-recurring', OmegaFrequencyWeekRecurring);
export default OmegaFrequencyWeekRecurring;
