/* eslint-disable lines-between-class-members */
import { WorkflowActions, WorkflowTypes } from '@treasury/domain/channel/types/ach';
import { Record, Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-radio-group.js';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { css, html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { Entitlement } from '../../../utilities/entitlements.js';
import '../../components/ach-payment-recipients-table.js';
import {
    createChildSupportPaymentColumns,
    defaultRecipient,
    uploadNachaFileRecipientsColumns,
} from '../data/recipient-recordset.js';
import '../parts/child-support-payment-header';
import { recipientDetails } from '../parts/child-support-payment-recipient-details';

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));
export class ManageRecipientsStep extends AlertingListeningElement {
    @property({ type: Number })
    activeStep!: number;
    @property({ type: Boolean })
    isFromFile!: boolean;
    @property({ type: Object })
    paymentHeaderRecord!: Record;
    @property({ type: Boolean })
    loading = false;
    @property({ type: Object })
    recipientsRecordset!: Recordset<unknown>;
    @property({ type: Array })
    recipients: Array<any> = [{ ...defaultRecipient }];
    @property({ type: String })
    action!: WorkflowActions;
    @property({ type: String })
    type!: WorkflowTypes;
    @property({ type: Array })
    entitlements!: Array<Entitlement>;
    @property({ type: Array })
    holidays!: Array<any>;
    @property({ type: Object })
    alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: 'Are you sure you want to cancel?',
        type: 'warning',
        posture: 'assertive' as const,
        actions: html`<div slot="actions">
            <omega-button class="ok" type="primary" @click=${() => this.dialogCancel()}
                >Ok</omega-button
            >
            <omega-button class="continue-editing" @click=${() => this.dialogContinueEditing()}
                >Continue Editing</omega-button
            >
        </div>`,
    };
    @property({ type: Boolean })
    canContinue = false;
    editable!: boolean;
    dialogCancel() {
        this.dispatchEvent(new CustomEvent('cancel'));
    }
    dialogContinueEditing() {
        this.alert = { ...this.alert, visible: false };
    }
    isReadonly() {
        return (
            this.hasNoTemplateEditEntitlement(this.entitlements, this.action, this.type) ||
            this.hasNoPaymentEditEntitlement(this.entitlements, this.action, this.type) ||
            this.hasPartialTemplateEditEntitlement(this.entitlements, this.action, this.type) ||
            this.hasPartialPaymentEditEntitlement(this.entitlements, this.action, this.type)
        );
    }

    get paymentHeaderRecipients() {
        if (this.isFromFile) return this.recipientsRecordset;
        return undefined;
    }

    get columns() {
        if (this.isFromFile) return uploadNachaFileRecipientsColumns(false);
        this.editable = !this.isReadonly();
        return createChildSupportPaymentColumns(this.editable);
    }

    isPayment(type: WorkflowTypes) {
        return type ? type.toLowerCase() === WorkflowTypes.Payment : false;
    }

    hasNoPaymentEditEntitlement(
        entitlements: Array<Entitlement>,
        action: WorkflowActions,
        type: WorkflowTypes
    ) {
        return (
            !entitlements.some(e => e.permission === 'ACH, Payment, Partial Edit') &&
            !entitlements.some(e => e.permission === 'ACH, Payment, Full Edit') &&
            this.isPayment(type) &&
            action !== WorkflowActions.Create
        );
    }

    hasNoTemplateEditEntitlement(
        entitlements: Array<Entitlement>,
        action: WorkflowActions,
        type: WorkflowTypes
    ) {
        return (
            !entitlements.some(e => e.permission.toLowerCase() === 'partial edit ach template') &&
            !entitlements.some(e => e.permission.toLowerCase() === 'full edit ach template') &&
            !this.isPayment(type) &&
            action !== WorkflowActions.Create
        );
    }

    hasPartialPaymentEditEntitlement(
        entitlements: Array<Entitlement>,
        action: WorkflowActions,
        type: WorkflowTypes
    ) {
        return (
            entitlements.some(e => e.permission === 'ACH, Payment, Partial Edit') &&
            !entitlements.some(e => e.permission === 'ACH, Payment, Full Edit') &&
            this.isPayment(type) &&
            action !== WorkflowActions.Create
        );
    }

    hasPartialTemplateEditEntitlement(
        entitlements: Array<Entitlement>,
        action: WorkflowActions,
        type: WorkflowTypes
    ) {
        return (
            entitlements.some(e => e.permission.toLowerCase() === 'partial edit ach template') &&
            !entitlements.some(e => e.permission.toLowerCase() === 'full edit ach template') &&
            !this.isPayment(type) &&
            action !== WorkflowActions.Create
        );
    }

    get recipientDetailsFunction() {
        if (this.isFromFile) return null;
        return recipientDetails;
    }

    next() {
        this.dispatchEvent(new CustomEvent('next'));
    }

    previous() {
        this.dispatchEvent(new CustomEvent('previous'));
    }

    canContinueToNextStep() {
        this.canContinue =
            this.recipientsRecordset.isValid() &&
            this.paymentHeaderRecord.hasRequiredValues() &&
            this.paymentHeaderRecord.isValid();
    }

    renderReviewButton() {
        const recipientErrorCount = this.recipientsRecordset.invalidRecordCount();
        if (recipientErrorCount > 0) {
            const recipientsHave = recipientErrorCount > 1 ? 'recipients have' : 'recipient has';

            return html`<omega-tooltip
                light
                message="${recipientErrorCount} ${recipientsHave} one or more invalid fields."
            >
                <omega-button
                    @click=${() => this.next()}
                    type="primary"
                    .disabled=${!this.canContinue}
                    icon="warning"
                    class="review"
                    >Review</omega-button
                ></omega-tooltip
            >`;
        }

        return html`<omega-button
            @click=${() => this.next()}
            class="review"
            type="primary"
            .disabled=${!this.canContinue}
            >Review</omega-button
        >`;
    }

    render() {
        const title = this.isFromFile ? 'Payment Details' : 'Manage Recipients';
        return html` ${this.renderAlert()}
            <div class="omega-form-header">
                <h2 class="step-title">${title}</h2>
                <span><span class="red">*</span> indicates Required field</span>
            </div>
            <child-support-payment-header
                .isFromFile=${this.isFromFile}
                .paymentHeaderRecord=${this.paymentHeaderRecord}
                .recipientsRecordset=${this.paymentHeaderRecipients}
                .activeStep=${this.activeStep}
                .expanded=${this.isFromFile}
            ></child-support-payment-header>
            <ach-payment-recipients-table
                .columns=${this.columns}
                .recipients=${this.recipients}
                .recipientsRecordset=${this.recipientsRecordset}
                .holidays=${this.holidays}
                .detailFunction=${this.recipientDetailsFunction}
                display-toggle-all
                @updated=${this.canContinueToNextStep}
                @changed=${this.canContinueToNextStep}
            ></ach-payment-recipients-table>
            <omega-button-bar position="bottom" alignment="left">
                ${this.renderReviewButton()}
                <omega-button class="back" type="secondary" @click=${() => this.previous()}
                    >Back</omega-button
                >
                <omega-button
                    class="cancel"
                    type="secondary"
                    @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                    }
                    >Cancel</omega-button
                ></omega-button-bar
            >`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-form-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .omega-form-header span.red {
                color: var(--omega-text-error);
            }
            .step-title {
                color: var(--omega-text-header);
                font-size: 24px;
                font-weight: 500;
                margin: 0;
            }
        `;
    }
}

customElements.define('manage-recipients-step', ManageRecipientsStep);
