/* eslint-disable sonarjs/cognitive-complexity */
import { Time } from './time';

const daysInWeek = 7;
const secondsInDay = 24 * 60 * 60;
const secondsInWeek = daysInWeek * secondsInDay;
const millisecondsInSecond = 1000;

function parseTimeNumber(time: number): Time {
    // requires the units of input time to be in seconds
    if (Number.isFinite(time)) {
        const seconds = Math.trunc(time);
        if (Number.isSafeInteger(seconds)) {
            let timeOfWeekTicks = seconds % secondsInWeek;
            // convert negative to equivalent positive in the weekly seconds ring
            if (timeOfWeekTicks < 0) {
                timeOfWeekTicks += secondsInWeek;
            }
            // handle possible negative zero
            timeOfWeekTicks = Math.abs(timeOfWeekTicks);

            const dayOfWeek = Math.trunc(timeOfWeekTicks / secondsInDay);
            const tickCount = timeOfWeekTicks % secondsInDay;
            return new Time(tickCount, dayOfWeek);
        }
    }

    throw new Error(`Invalid time: ${time}`);
}

function parseTimeString(time: string) {
    let dayOfWeek = new Date(time).getDay() || 0;

    const [hours, minutes, seconds] = time
        .replace(/\d\d\d\d-\d\d-\d\dT/, '')
        .split(':')
        .map(part => parseInt(part));

    const secondsInHalfDay = 12 * 60 * 60;

    const textInputTickCount = hours * 60 * 60 + minutes * 60 + (seconds || 0);
    let tickCount = textInputTickCount % secondsInDay;
    dayOfWeek += Math.trunc(textInputTickCount / secondsInDay);

    // patch the tick count computation for midnight hour
    if (time.toLocaleLowerCase().includes('a') && hours === 12) {
        tickCount -= secondsInHalfDay;
    }

    // patch the tick count computation for after noon
    if (time.toLocaleLowerCase().includes('p') && hours !== 12) {
        tickCount += secondsInHalfDay;
    }

    return new Time(tickCount, dayOfWeek);
}

export function parseTime(time: number | string | Time): Time {
    if (time instanceof Time) {
        return time;
    }

    if (typeof time === 'number') {
        return parseTimeNumber(time);
    }

    if (typeof time === 'string') {
        return parseTimeString(time);
    }

    throw new Error(`Time argument must be a number or a string: ${time}`);
}

export class AlarmClock extends EventTarget {
    public timeZone = '';

    // eslint-disable-next-line no-use-before-define
    private static instance?: AlarmClock;

    private readyPromise: Promise<boolean> | null = null;

    private tickCount = -1;

    private alarmEvents = new Map<string, number>();

    private _dayOfWeek = 0;

    private get hasStarted() {
        return this.tickCount >= 0;
    }

    private lastTickTimestamp: number = Math.round(performance.now() / 1000);

    /**
     * An `AlarmClock` is a singleton available to both AngularJS and Lit components. It will be configured with
     * the bank's local time and cutoff times once the services are loaded
     */
    public static getInstance() {
        if (!AlarmClock.instance) {
            AlarmClock.instance = new AlarmClock();
        }

        return AlarmClock.instance;
    }

    public static start(clock: AlarmClock) {
        setInterval(clock.tick.bind(clock), millisecondsInSecond);
    }

    get seconds() {
        return this.time.seconds;
    }

    get minutes() {
        return this.time.minutes;
    }

    get hours() {
        return this.time.hours;
    }

    get dayOfWeekIndex() {
        return this._dayOfWeek;
    }

    get dayOfWeek() {
        return this.time.toDayOfWeekString();
    }

    get timeFormatted() {
        return this.time.toString();
    }

    get time(): Time {
        return new Time(this.tickCount, this._dayOfWeek);
    }

    set time(time: string | number | Time) {
        const parsedTime = parseTime(time);
        if (parsedTime.tickCount >= 0) {
            this.tickCount = parsedTime.tickCount;
            this._dayOfWeek = parsedTime.dayOfWeek;
            this.lastTickTimestamp = Math.round(performance.now() / 1000);
            AlarmClock.start(this);
        }
    }

    checkReadiness() {
        if (!this.readyPromise) {
            this.readyPromise = new Promise(resolve => {
                if (this.hasStarted) {
                    resolve(true);
                } else {
                    const checkInterval = setInterval(() => {
                        if (this.hasStarted) {
                            clearInterval(checkInterval);
                            this.readyPromise = null;
                            resolve(true);
                        }
                    }, 300);
                }
            });
        }
        return this.readyPromise;
    }

    // Sets a daily alarm
    setAlarm(event: string, time: string | Time) {
        if (!event) return;
        if (!time) return;
        const parsedTime = parseTime(time);
        if (parsedTime.tickCount >= 0) {
            this.alarmEvents.set(event, parsedTime.tickCount);
        }
    }

    getAlarm(event: string): Time {
        const alarmTickCount = this.alarmEvents.get(event);
        if (!alarmTickCount) throw new Error(`Event not found: ${event}`);
        return new Time(alarmTickCount, this._dayOfWeek);
    }

    removeAlarm(event: string) {
        return this.alarmEvents.delete(event);
    }

    async dispatchAlarms(startTick: number, endTick: number) {
        const alarmsToDispatch = [...this.alarmEvents.entries()].filter(
            ([, time]) => time > startTick && time <= endTick
        );
        alarmsToDispatch.forEach(([id]) => this.dispatchEvent(new CustomEvent(id)));
    }

    tick() {
        const currentTickTimestamp = Math.round(performance.now() / 1000);
        const secondsDiff = currentTickTimestamp - this.lastTickTimestamp;

        this.lastTickTimestamp = currentTickTimestamp;
        const prevTickCount = this.tickCount;
        this.tickCount += secondsDiff;

        if (secondsDiff <= 0) return;

        //If it's a new day then...
        if (this.tickCount >= secondsInDay) {
            this.tickCount = this.tickCount - secondsInDay;
            this._dayOfWeek = (this._dayOfWeek + 1) % daysInWeek;
            // Dispatch any alarms through end of previous day
            this.dispatchAlarms(prevTickCount, secondsInDay);
            // Dispatch any alarms from midnight to current tick
            this.dispatchAlarms(0, this.tickCount);
        } else {
            this.dispatchAlarms(prevTickCount, this.tickCount);
        }
    }

    isBefore(event: string) {
        if (!this.hasStarted) {
            throw new Error('Time not set');
        }
        return this.alarmEvents.has(event) && this.tickCount < this.getAlarm(event).tickCount;
    }

    isAfter(event: string) {
        if (!this.hasStarted) {
            throw new Error('Time not set');
        }
        return this.alarmEvents.has(event) && !this.isBefore(event);
    }

    isBetween(start: string, end: string) {
        if (!this.hasStarted) {
            throw new Error('Time not set');
        }
        return this.isAfter(start) && this.isBefore(end);
    }
}
