// eslint-disable-next-line import/extensions
import { DefaultFrequency } from '@treasury/domain/channel/types/frequency';

export const paymentFrequencyFormatter = value => {
    const valueDate = value.valueDate ?? value.effectiveDate;
    if (value) {
        const frequency =
            typeof value === 'string'
                ? {
                      ...DefaultFrequency,
                      valueDate: value,
                      type: 'OneTime',
                  }
                : {
                      ...DefaultFrequency,
                      ...value,
                      valueDate,
                      type: value.type ?? 'OneTime',
                  };
        if (frequency.type === 'Twice a Month') {
            frequency.type = 'Twice A Month';
        }
        frequency.type = frequency.type.replace(/ /g, '');
        return frequency;
    }
    return { ...DefaultFrequency };
};
