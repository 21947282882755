import {
    NachaErrors,
    NachaUploadBatchData,
    NachaUploadData,
    NachaUploadPaymentDto,
    PaymentRecipientDto,
} from '../../types/ach';
import { DefaultFrequency } from '../../types/frequency';

const mapRecipientResponse = (
    recipients: PaymentRecipientDto[],
    /** to do: add bank data */
    // banks: AchBank[]
    step: number
): PaymentRecipientDto[] =>
    recipients.map(recipient => {
        let { routingNumber } = recipient;
        if (routingNumber.length === 8) {
            routingNumber = `0${recipient.routingNumber}`;
        }
        const {
            prenote,
            hold,
            name,
            idNumber,
            accountNumber,
            accountType,
            bank,
            amount,
            errors,
            transactionType,
            addenda,
        } = recipient;
        return {
            ...recipient,
            prenote,
            hold,
            name,
            idNumber,
            accountNumber,
            accountType,
            bank,
            amount,
            errors,
            transactionType,
            addenda: addenda[0]?.value,
            routingNumber,
            step,
        };
    });

export const mapNachaUploadResponseToPaymentValues = (
    response: NachaUploadPaymentDto
): NachaUploadData => {
    const step = 0;
    const {
        id,
        name,
        achCompany,
        achCompanyId,
        achPaymentDraftId,
        secCode,
        entryDescription,
        discretionaryData,
        restricted,
        debitAmount,
        creditAmount,
        offsetAccount,
        audit,
        parentId,
        reversalAchPaymentId,
        recipients,
        errorSummary,
        fileArchiveId,
        hasAccess,
    } = response;
    const payment = {
        id: id ?? '',
        name: name ?? '',
        achCompany,
        achPaymentDraftId,
        achCompanyId: achCompanyId ? Number.parseInt(achCompanyId) : 0,
        secCode: secCode ?? { description: '', code: '' },
        entryDescription: entryDescription ?? '',
        discretionaryData: discretionaryData ?? '',
        restricted: restricted ?? '',
        debitAmount,
        creditAmount,
        frequency: DefaultFrequency,
        parentId,
        offsetAccount,
        audit,
        reversalAchPaymentId,
        step,
        nachaFileUpload: true,
        fileArchiveId,
        hasAccess,
    };

    return {
        payment,
        recipients: mapRecipientResponse(recipients, step),
        errorSummary,
    };
};

const getErrorSummary = (payment: NachaUploadPaymentDto): NachaErrors => ({
    hasErrors: !!payment.errorSummary?.summaryMessageList.length,
    errorMessages: payment.errorSummary?.summaryMessageList.length
        ? payment.errorSummary?.summaryMessageList
        : [],
});

export const mapNachaBatchUploadResponseToPaymentValues = (
    data: NachaUploadPaymentDto
): NachaUploadBatchData => {
    const balancedCount = data.totalBalancedBatches ?? 0;
    const unbalancedCount = data.totalUnBalancedBatches ?? 0;

    if (balancedCount + unbalancedCount > 1 && data.achBatchSummaries) {
        const batchPayments = data.achBatchSummaries.map(mapNachaUploadResponseToPaymentValues);
        return {
            multiplePayments: true,
            payments: batchPayments,
            summary: data,
            errorSummary: getErrorSummary(data),
        };
    }

    const payments = [mapNachaUploadResponseToPaymentValues(data)];
    return {
        multiplePayments: false,
        payments,
        summary: data,
        errorSummary: getErrorSummary(data),
    };
};
