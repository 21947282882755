export default () => ({
    restrict: 'EA',
    replace: true,
    scope: {
        ngDisabled: '=',
        ngModel: '=',
        click: '&?',
        confirmationCallback: '&?',
        form: '=',
        controlId: '@',
        type: '@',
        positiveLabel: '@',
        negativeLabel: '@',
        positiveValue: '=',
        negativeValue: '=',
    },
    template: require('./toggleTemplate.html'),
    controller: ToggleController,
});

ToggleController.$inject = ['$scope', '$timeout'];

function ToggleController($scope, $timeout) {
    $scope.animate = false;

    $scope.toggleValue = toggleValue;

    $scope.$watch('ngModel', onNgModelChanged);

    function toggleValue() {
        if (!$scope.ngDisabled) {
            if ($scope.confirmationCallback) {
                $scope.confirmationCallback().then(() => {
                    completeToggle();
                });
            } else if ($scope.click) {
                $scope.click();
                completeToggle(true);
            } else {
                completeToggle();
            }
        }
    }

    function onNgModelChanged(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== null && oldValue !== undefined) {
            $scope.animate = true;
            $timeout(() => {
                $scope.animate = false;
            }, 200);
        }
    }

    //
    // Private

    function completeToggle(readOnlyModel) {
        $scope.touched = true;
        if ($scope.form !== undefined) {
            $scope.form.$setDirty(true);
        }
        if (!readOnlyModel) {
            if ($scope.ngModel === $scope.positiveValue) {
                $scope.ngModel = $scope.negativeValue;
            } else {
                $scope.ngModel = $scope.positiveValue;
            }
        }
    }
}
