import InformationReportingRequests from '../../requests/reporting/information-reporting-requests.js';

export class ReportsDashboardService {
    static async getReports() {
        const response = await InformationReportingRequests.fetchReports();
        return {
            data: response,
            totalCount: response.length,
        };
    }

    static async favoriteReport(params) {
        return InformationReportingRequests.favoriteReport(params);
    }

    static async deleteReport(id) {
        return InformationReportingRequests.deleteReport(id);
    }
}
