import { AchExceptionDetailDataDto } from '@treasury/api/channel';

export const defaultAchExceptionModelDto: AchExceptionDetailDataDto = {
    accountId: 0,
    achExceptionDecisionStatusId: 0,
    achExceptionDetailId: 0,
    auditHistory: false,
    companyId: 0,
    createdBy: 0,
    createdDate: '',
    institutionId: 0,
    updatedBy: 0,
    updatedDate: '',
};
