export default {
    name: 'noPunctuation',
    validate(modelValue, viewValue /* record, options */) {
        const value = viewValue || modelValue;
        if (value === '') return true;
        if (!value) return false;
        if (Array.isArray(value)) return value.every(val => /^[\d A-Za-z]+$/.test(val));

        return /^[\d A-Za-z]+$/.test(value);
    },
};
