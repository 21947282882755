import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';

@Injectable()
export class NavigationsService {
    async fetchNavigationOptions() {
        return (await TmHttpClient.getInstance()).request('navigations', {
            method: 'GET',
        });
    }
}
