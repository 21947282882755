export class Time {
    constructor(tickCount: number, dayOfWeek: number) {
        this.tickCount = tickCount;
        this.dayOfWeek = dayOfWeek;
    }

    tickCount: number;

    dayOfWeek: number;

    get seconds() {
        return this.tickCount % 60;
    }

    get minutes() {
        return ((this.tickCount - this.seconds) / 60) % 60;
    }

    get hours() {
        return ((this.tickCount - this.minutes * 60 - this.seconds) / 60 / 60) % 24;
    }

    subtractMinutes(minutes: number) {
        this.tickCount -= minutes * 60;
    }

    toString(): string {
        const { hours } = this;
        const minutes = this.minutes.toString().padStart(2, '0');
        if (hours === 0) return `12:${minutes} AM`;
        if (hours < 12) return `${hours}:${minutes} AM`;
        if (hours === 12) return `12:${minutes} PM`;
        return `${hours - 12}:${minutes} PM`;
    }

    toTwentyFourHourString(): string {
        const hours = this.hours.toString().padStart(2, '0'); // padding is Important!
        const minutes = this.minutes.toString().padStart(2, '0');
        const seconds = this.seconds.toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    toDayOfWeekString(): string {
        switch (this.dayOfWeek) {
            case 0:
                return 'Sunday';
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            default:
                return 'error';
        }
    }
}
