/* eslint-disable no-use-before-define */
import '@treasury/omega/components/omega-toggle';
import '@treasury/omega/components/omega-tooltip';
import '../../shared/templates/report-top.template';

import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record as FdlRecord, Recordset, RecordsetEvent } from '@treasury/FDL';
import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { ListeningElement } from '@treasury/omega/components';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { OmegaToggleEvent } from '@treasury/omega/types';
import { Action } from '@treasury/utils/types';
import { columns } from '../data';

interface CreationStepConfig {
    recordset: Recordset<IIssuedItem>;
    onNext: Action;
    onCancel: Action;
}

export function renderCreationStep(config: CreationStepConfig) {
    const { recordset, onNext, onCancel } = config;

    return html`<issued-items-creation-create-step
        .recordset=${recordset}
        .onNext=${onNext}
        .onCancel=${onCancel}
    ></issued-items-creation-create-step>`;
}

@customElement('issued-items-creation-create-step')
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class IssuedItemsCreationStep extends ListeningElement {
    protected readonly verifiedPropNames: (keyof this)[] = ['recordset', 'onCancel', 'onNext'];

    @property({
        type: Object,
    })
    public readonly recordset!: Recordset<IIssuedItem>;

    @property({
        attribute: false,
    })
    public readonly onNext!: Action;

    @property({
        attribute: false,
    })
    public readonly onCancel!: Action;

    @state()
    private isValid = false;

    @state()
    private autoIncrementCheckNumber = false;

    @InjectProperty()
    private declare dialogService: OmegaDialogService;

    private readonly autoIncrementTooltipMessage =
        'If you would like to sequentially increment check numbers when selecting add row, please select to activate.';

    protected firstUpdated(changedProperties: Map<string | number | symbol, unknown>) {
        super.firstUpdated(changedProperties);

        this.isValid = !this.recordset.hasErrors;
        this.listenToMulti(
            this.recordset,
            [RecordsetEvent.Updated, RecordsetEvent.CountsChanged],
            () => {
                this.isValid = !this.recordset.hasErrors;
            }
        );
        this.listenToRecordset(this.recordset, RecordsetEvent.RecordAdded, e => {
            const { record, index } = e.detail;
            this.onRecordAdded(record as FdlRecord<IIssuedItem>, index);
        });
    }

    private async undoChanges() {
        const { reason } = await this.dialogService.open(
            'Are you sure you want to undo all of your changes?',
            'Undo Changes'
        ).closed;

        if (reason === OmegaDialogExitReason.Confirm) {
            this.recordset.reset(true);
        }
    }

    private onRecordAdded(record: FdlRecord<IIssuedItem>, index: number) {
        if (index < 1) {
            throw new Error('Could not retrieve previous record. Invalid recordset index.');
        }

        const previousRecord: FdlRecord<IIssuedItem> = this.recordset.getRecordAtIndex(index - 1);

        if (!previousRecord) {
            return;
        }

        // use the previous account, if it exists
        const previousAccount = previousRecord.getField('account');
        record.setField('account', previousAccount);

        this.incrementCheckNumber(previousRecord, record);
    }

    /**
     * Use the check number from the previous as record as the
     * basis for the check number in sequence for the next record.
     */
    private incrementCheckNumber(
        previousRecord: FdlRecord<IIssuedItem>,
        nextRecord: FdlRecord<IIssuedItem>
    ) {
        if (!this.autoIncrementCheckNumber) {
            return;
        }

        const previousCheckNumber = previousRecord.getField('checkNumber');

        if (typeof previousCheckNumber !== 'string') {
            return;
        }

        const parsedCheckNumber = parseInt(previousCheckNumber, 10);
        if (Number.isNaN(parsedCheckNumber)) {
            return;
        }

        nextRecord.setField('checkNumber', (parsedCheckNumber + 1).toString());
    }

    private onIncrementToggleChange(e: OmegaToggleEvent) {
        const { checked } = e.detail;
        this.autoIncrementCheckNumber = checked;
    }

    protected render() {
        return html` <div class="align-middle">
                <strong>Increment Check Numbers</strong>
                <omega-toggle
                    hideText
                    @change=${(e: OmegaToggleEvent) => this.onIncrementToggleChange(e)}
                    .checked=${this.autoIncrementCheckNumber}
                ></omega-toggle>
                <omega-tooltip
                    light
                    icon="info-circle"
                    message=${this.autoIncrementTooltipMessage}
                ></omega-tooltip>
            </div>
            <omega-table .recordset=${this.recordset} .columnDefinitions=${columns}></omega-table>
            <omega-button-bar position="bottom" alignment="left">
                <omega-button @click=${this.onNext} type="primary" ?disabled=${!this.isValid}
                    >Review</omega-button
                >
                <omega-button @click=${this.undoChanges} type="link">Undo Changes</omega-button>
                <omega-button @click=${this.onCancel} type="link">Cancel</omega-button>
            </omega-button-bar>`;
    }

    static styles = css`
        .align-middle > * {
            vertical-align: middle;
        }

        omega-table::part(pagination) {
            bottom: -15px;
        }
    `;
}
