import { AchBankDto, PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import { RecordsetValidator } from '@treasury/FDL/recordset';

export const creditOnlyTransactionTypeValidator = (
    record: Record<PaymentHeader>,
    onUsAchBanks: Array<AchBankDto>
): RecordsetValidator<PaymentRecipient> => ({
    name: 'Transaction type must be set to "Credit" on all recipients.',
    validate: (recipients: Array<Record<PaymentRecipient>>) => {
        const { batchBalanceRequirements, notOnUsTransactionTypes } = record.getField('achCompany');

        const isCreditOnly =
            batchBalanceRequirements === 'Unbalanced - Full Offset' &&
            recipients[0].values.transactionType === 'CR';

        if (notOnUsTransactionTypes === 'Credit Only' || isCreditOnly) {
            const notOnUsRecipients = recipients.filter(
                recipient =>
                    !onUsAchBanks.some(
                        (bank: AchBankDto) => bank.bankId === recipient.getField('bank')?.bankId
                    )
            );
            return notOnUsRecipients.every((recipientRecord: Record<PaymentRecipient>) => {
                const recipient = recipientRecord.values;
                return recipient.transactionType === 'CR';
            });
        }
        return true;
    },
});

export const debitOnlyTransactionTypeValidator = (
    record: Record<PaymentHeader>,
    onUsAchBanks: Array<AchBankDto>
): RecordsetValidator<PaymentRecipient> => ({
    name: 'Transaction type must be set to "Debit" on all recipients.',
    validate: (recipients: Array<Record<PaymentRecipient>>) => {
        const { batchBalanceRequirements, notOnUsTransactionTypes } = record.getField('achCompany');

        const isDebitOnly =
            batchBalanceRequirements === 'Unbalanced - Full Offset' &&
            recipients[0].values.transactionType === 'DR';

        if (notOnUsTransactionTypes === 'Debit Only' || isDebitOnly) {
            const notOnUsRecipients = recipients.filter(
                recipient =>
                    !onUsAchBanks.some(
                        (bank: AchBankDto) => bank.bankId === recipient.getField('bank')?.bankId
                    )
            );
            return notOnUsRecipients.every((recipientRecord: Record<PaymentRecipient>) => {
                const recipient = recipientRecord.values;
                return recipient.transactionType === 'DR';
            });
        }
        return true;
    },
});
