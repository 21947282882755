import { DiContainer } from '@jack-henry/frontend-utils/di';
import { ConfigurationService } from '@treasury/core/config';
import { LegacyNavigationService, NavigationService } from '@treasury/core/navigation';
import { createStates } from './app.routes.states';
import { channelDiModule } from './constants/di.constants';

export const statesModule = angular.module('channel.states', [channelDiModule.name]).config([
    'TmDi',
    async function configureRoutes(TmDi: DiContainer) {
        const configService = TmDi.get(ConfigurationService);
        const navService = TmDi.get(NavigationService) as LegacyNavigationService;
        const { institutionId } = configService;

        if (!institutionId) {
            throw new Error('Unable to determine institution ID');
        }

        const states = createStates(institutionId);
        navService.addStates(states, () => {
            window.location.replace(`/${institutionId}/not-found`);
        });
    },
]);
