export interface AuthPayload {
    isAdmin: boolean;
}

/**
 * Status emitted at the end of an authentication flow.
 * Useful for consumer apps to determine where to route the user to.
 */
export enum AuthFlowEvent {
    TermsAndConditionsAccept,
    Done,
}
