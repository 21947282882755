import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { NewUserAccountDto } from '@treasury/domain/channel/types';
import { TmApiError } from '@treasury/domain/shared';
import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/progress/omega-progress';
import { AlertMixin } from '@treasury/omega/mixins/alert.mixin';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserProfileClient } from './clients/user-profile-client';
import { createUserAccountFields } from './data/create-user-account-record';
import './parts/copy-user-dialog';

export const CreateUserTagName = 'create-user-container';

const mixin = AlertMixin(ListeningElementMixin(LitElement));
@customElement(CreateUserTagName)
export class CreateUserContainer extends mixin {
    @property({
        type: Object,
    })
    @InjectProperty()
    public declare client: UserProfileClient;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({
        type: Object,
    })
    public createUserAccountRecord: Record<NewUserAccountDto> = new Record(
        createUserAccountFields,
        {} as NewUserAccountDto
    );

    @state()
    private isLoading = true;

    @state()
    private isUpdating = false;

    @state()
    private isDirty = false;

    @state()
    public copyUserDialogOpen = false;

    public async firstUpdated() {
        try {
            const userAccountData = {
                name: '',
                loginId: '',
                department: '',
                email: '',
                phone: '',
                extension: null,
                fax: null,
                isSuperUser: false,
                isAdmin: false,
            } as NewUserAccountDto;
            this.createUserAccountRecord = new Record(createUserAccountFields, userAccountData);
            this.initAccountListening();
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code,
                    time,
                };
            } else {
                const message = 'An unknown error occurred. Please try again.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }
    }

    private initAccountListening() {
        this.listenTo(this.createUserAccountRecord, 'change', (detail: CustomEvent) => {
            const targetRecord = detail.target as Record;
            this.isDirty =
                targetRecord.hasChanged &&
                targetRecord.isValid() &&
                targetRecord.hasRequiredValues();
        });
    }

    public copyUser(user: any) {
        const userValues = user.record.values;
        const userAccountData = {
            name: userValues.userName,
            loginId: userValues.userId,
            department: null,
            email: '',
            phone: '',
            extension: null,
            fax: null,
            isSuperUser: userValues.isSuperUser,
            isAdmin: userValues.isAdmin,
        } as NewUserAccountDto;
        this.createUserAccountRecord = new Record(createUserAccountFields, userAccountData);
        this.isDirty = false;
        this.initAccountListening();
    }

    public goToUserList() {
        return this.navService.navigate('admin.user-list');
    }

    public async saveNewUser() {
        const defaultErrMsg = 'Could not save user profile changes. Please try again.';
        try {
            this.isUpdating = true;
            const { success } = await this.client.createUser(this.createUserAccountRecord.values);

            if (success === false) {
                // in practice, getting here should never happen since HTTP 200
                // responses of certain shapes will result in TmHttpClient throwing
                throw new Error(defaultErrMsg);
            }

            this.alert = {
                ...this.alert,
                visible: true,
                posture: 'polite',
                actions: '',
                title: '',
                type: 'success',
                message: 'New User Created!',
            };
            this.isDirty = false;
            this.createUserAccountRecord = new Record(
                createUserAccountFields,
                this.createUserAccountRecord.values
            );
            this.initAccountListening();
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message,
                    code,
                    visible: true,
                    posture: 'polite',
                    title: '',
                    actions: '',
                    type: 'error',
                };
            } else {
                console.error(err);
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message: defaultErrMsg,
                };
            }
        } finally {
            this.isUpdating = false;
        }
    }

    public reset() {
        // Definitely not the best way to do this
        window.location.reload();
    }

    cancelUserCreation() {
        if (this.createUserAccountRecord.hasChanged) {
            this.promptForCancellation();
        }
    }

    promptForCancellation() {
        this.alert = {
            ...this.alert,
            type: 'warning',
            posture: 'assertive',
            visible: true,
            title: 'Unsaved Changes',
            message: 'Are you sure you want to reset this user?',
            actions: html`<omega-button type="primary" id="cancel-changes" @click=${this.reset}
                    >OK</omega-button
                ><omega-button id="continue-editing" @click=${this.closeCancellationPrompt}
                    >Continue Editing</omega-button
                >`,
        };
        this.requestUpdate();
    }

    closeCancellationPrompt() {
        this.alert = { ...this.alert, visible: false };
        this.requestUpdate();
    }

    renderBlockingLoader() {
        if (this.isUpdating) {
            return html`<div class="background">
                <omega-progress card class="light-loader"></omega-progress>
            </div>`;
        }
        return nothing;
    }

    render() {
        const copyUserFunction = this.copyUser.bind(this);
        return html`${this.renderAlert()}${this.renderBlockingLoader()}
            <user-copy-user-dialog
                .copyUser=${copyUserFunction}
                .client=${this.client}
                .open=${this.copyUserDialogOpen}
                @close=${() => {
                    this.copyUserDialogOpen = false;
                }}
            ></user-copy-user-dialog>
            <div class="user-profile-container">
                <omega-button
                    id="go-to-user-list"
                    type="icon"
                    @click="${() => {
                        this.goToUserList();
                    }}"
                    >Back to User List</omega-button
                >
                <h2 class="profile-header">Create New User</h2>

                <div class="profile-content">
                    <span class="required-field-explanation">
                        <omega-button
                            id="copy-user"
                            type="secondary"
                            @click="${() => {
                                this.copyUserDialogOpen = true;
                            }}"
                            >Copy User</omega-button
                        ></span
                    >
                    <div class="user-information">
                        <h2 class="section-title">User Information</h2>
                        <hr />

                        <omega-field
                            field="name"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="loginId"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="department"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="email"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                        <div class="phone-ext-container">
                            <omega-field
                                field="phone"
                                class="phone"
                                .record=${this.createUserAccountRecord}
                            ></omega-field>
                            <omega-field
                                field="extension"
                                .record=${this.createUserAccountRecord}
                            ></omega-field>
                        </div>
                        <omega-field
                            field="fax"
                            class="phone"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="isSuperUser"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="isAdmin"
                            .record=${this.createUserAccountRecord}
                        ></omega-field>
                    </div>
                    <hr />
                    <omega-button
                        id="save-profile-updates"
                        type="primary"
                        ?disabled=${!this.isDirty}
                        @click="${this.saveNewUser}"
                        >Save Changes</omega-button
                    >
                    <omega-button
                        id="cancel-profile-updates"
                        type="secondary"
                        @click=${this.cancelUserCreation}
                        >Reset</omega-button
                    >
                </div>
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            .profile-header {
                font-size: 24px;
            }

            span.required-field-explanation {
                font-style: italic;
                float: right;
            }
            span.red {
                color: var(--omega-text-error);
            }

            .profile-content {
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                box-shadow: 0px 0px 2px 2px rgb(150 150 150 / 75%);
                padding: var(--user-profile-content-padding, 10px);
                margin-bottom: 55px;
                flex: 1 1 auto;
            }

            .section-title {
                margin: 0;
            }

            .user-information omega-field {
                --omega-field-label-max-width: 95px;
                --omega-field-control-font-weight: 600;
            }

            .user-information hr {
                margin-top: 15px;
            }

            omega-button {
                margin: 0px;
            }

            omega-field[field='name'] {
                --omega-field-control-max-width: 400px;
            }

            omega-field[field='loginId'] {
                --omega-field-control-max-width: 200px;
            }

            omega-field[field='email'] {
                --omega-field-control-max-width: 400px;
            }

            omega-field[field='department'] {
                --omega-field-control-max-width: 400px;
            }

            .phone-ext-container {
                display: flex;
            }

            .phone-ext-container omega-field {
                flex: 1 1;
            }

            omega-field[field='phone'] {
                max-width: 220px;
            }

            omega-field[field='extension'] {
                --omega-field-label-max-width: 30px;
                --omega-field-control-max-width: 60px;
            }

            omega-field.phone {
                --omega-field-control-max-width: 124px;
            }

            omega-field {
                margin: 10px;
            }
        `;
    }
}
