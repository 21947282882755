/* eslint-disable no-use-before-define */
import type { SecurityMessage } from '@treasury/domain/channel/mappings/security';
import {
    ChallengeType,
    ErrorType,
    StatusType,
} from '@treasury/domain/channel/mappings/security';
import {
    coerceSecurityMessage,
    extractSecurityPayload,
    isLockedOrSuccessful,
} from '@treasury/domain/shared';
import type { IdentityDialogOtpRequest } from '@treasury/domain/shared/fetch-with-auth';
import { exists, ObservationSource } from '@treasury/utils';
import { LitPropertyVerifier } from '@treasury/utils/lit-helpers';
import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/omega-button.js';
import '../../components/omega-dialog.js';
import '../../components/omega-input';
import '../../components/progress/omega-progress';
import { OmegaDialogExitReason, OmegaDialogResult } from '../../services';
import { createDialogStrategy, IdentityDialogEvent, IdentityDialogResultInternal } from './types';
import { OobOtp } from './types/oob-otp';
import { SecureToken } from './types/secure-token';

@customElement('identity-verification-dialog')
export class IdentityVerificationDialog extends LitPropertyVerifier {
    @property({
        type: Object,
    })
    public securityMessage!: SecurityMessage;

    @property({
        type: Boolean,
    })
    public forLogin = false;

    /**
     * Perform an MFA request without any user interaction.
     * If this is `true`, no UI is presented to the user.
     */
    @property({
        type: Boolean,
    })
    public withoutUserInteraction = false;

    @property({
        type: Boolean,
    })
    public disableClose = false;

    /**
     * Function used to make an API request with password from this dialog.
     * Provided by fetch code.
     */
    @property({
        type: Function,
    })
    public otpRequestFn?: IdentityDialogOtpRequest;

    protected verifiedPropNames: (keyof this)[] = ['securityMessage'];

    @state()
    public verifying = false;

    @state()
    private secureTokenStrategy = createDialogStrategy(this);

    private readonly verificationStream = new ObservationSource<SecurityMessage>();

    /**
     * Promise that completes the first time the dialog is opened
     * and automatic OOB verification has ocurred.
     */
    public readonly automaticVerification = this.verificationStream.toObservable().toPromise(true);

    private get passwordElement() {
        if (
            this.secureTokenStrategy instanceof OobOtp ||
            this.secureTokenStrategy instanceof SecureToken
        ) {
            return this.secureTokenStrategy.passwordRef.value;
        }

        return undefined;
    }

    private get errorMessage() {
        const { errorCode, message } = this.securityMessage;
        if (!errorCode || !message || errorCode === ErrorType.Verify) {
            return undefined;
        }

        return message;
    }

    private get isSuccessful() {
        return this.securityMessage.status === StatusType.Success;
    }

    private set isSuccessful(val) {
        if (val) {
            this.securityMessage.status = StatusType.Success;
        } else {
            this.securityMessage.status = StatusType.Failure;
            this.securityMessage.message = 'Verification was unsuccessful.';
        }

        this.requestUpdate('isSuccessful');
    }

    public get password() {
        return this.passwordElement ? this.passwordElement.value : '';
    }

    public set password(val) {
        if (!this.passwordElement) {
            return;
        }

        this.passwordElement.value = val;
    }

    /**
     * The UI is waiting for user interaction.
     */
    private get waitingForUser() {
        return !this.verifying && this.securityMessage.status !== StatusType.Locked;
    }

    protected firstUpdated(changedProps: PropertyValueMap<typeof this>) {
        super.firstUpdated(changedProps);
        this.onPropsChange(changedProps);

        if (this.withoutUserInteraction) {
            this.verifyViaOob();
        }
    }

    protected updated(changedProps: PropertyValueMap<typeof this>) {
        super.updated(changedProps);
        this.onPropsChange(changedProps);
    }

    private onPropsChange(changedProps: PropertyValueMap<typeof this>) {
        if (changedProps.has('securityMessage') && exists(this.securityMessage)) {
            this.secureTokenStrategy = createDialogStrategy(this, this.securityMessage);
        }
    }

    public cancel() {
        this.verifying = false;
        this.password = '';
        this.close({
            reason: OmegaDialogExitReason.Cancel,
            data: {
                message: this.securityMessage,
            },
        });
    }

    /**
     * Verify user identity using the challenge type and provided credentials (if any).
     * @returns `true` if the verification was successful.
     */
    public async verify() {
        if (this.forLogin) {
            if (await this.verifyAccountPassword(this.password)) {
                this.close({
                    reason: OmegaDialogExitReason.Confirm,
                    data: {
                        message: this.securityMessage,
                    },
                });
                return true;
            }
            return false;
        }

        // eslint-disable-next-line default-case
        switch (this.securityMessage.challengeMethodTypeId) {
            case ChallengeType.SecureToken:
            case ChallengeType.OutOfBandOTP: {
                this.securityMessage.oneTimePassword = this.password;
                return this.performPasswordRequest();
            }
            case ChallengeType.OutOfBand: {
                if (!this.otpRequestFn) {
                    throw new Error(
                        'Could not validate identity using OOB. No request function provided.'
                    );
                }

                const response = await this.otpRequestFn(this.securityMessage);
                const securityPayload = await extractSecurityPayload(response);

                if (!securityPayload) {
                    throw new Error(
                        'Could not validate identity using OOB. Response contained no security payload.'
                    );
                }

                this.securityMessage = coerceSecurityMessage(securityPayload);

                if (isLockedOrSuccessful(this.securityMessage)) {
                    this.close({
                        reason: OmegaDialogExitReason.Confirm,
                        data: {
                            response,
                            message: this.securityMessage,
                        },
                    });

                    return true;
                }

                return false;
            }
        }

        return false;
    }

    /**
     * Inform consumers that the original request that results in an OTP
     * code being sent to the user should be invoked again.
     */
    public async reverify(securityMessage: SecurityMessage) {
        if (!this.otpRequestFn) {
            throw new Error('Could not re-verify. No request function provided.');
        }

        this.verifying = true;
        const response = await this.otpRequestFn({
            ...this.securityMessage,
            ...securityMessage,
        });
        const securityPayload = await extractSecurityPayload(response);
        if (!securityPayload) {
            throw new Error('Could not re-verify. No security payload in response');
        }

        this.securityMessage = coerceSecurityMessage(securityPayload);
        this.verifying = false;
    }

    private async verifyAccountPassword(password: string) {
        if (password.length === 0) {
            return false;
        }

        this.verifying = true;

        const { success, result } =
            await this.secureTokenStrategy.validateAccountPassword(password);

        if (!success) {
            const challengeMethodTypeId =
                result?.challengeMethodTypeId ?? this.securityMessage.challengeMethodTypeId;

            this.securityMessage = {
                ...this.securityMessage,
                ...result,
                challengeMethodTypeId,
                errorCode: ErrorType.Failure,
                message: result?.message ?? 'Verification was unsuccessful.',
            };

            this.password = '';
        }

        this.isSuccessful = success;
        this.verifying = false;
        return success;
    }

    /**
     * Make a request using the user-provided OTP.
     */
    private async performPasswordRequest() {
        if (!this.otpRequestFn) {
            throw new Error(
                'Cannot perform OTP request. No request function provided to identity dialog.'
            );
        }

        this.verifying = true;
        const response = await this.otpRequestFn({
            ...this.securityMessage,
            oneTimePassword: this.password,
        });
        const securityPayload = await extractSecurityPayload(response);
        if (!securityPayload) {
            this.isSuccessful = false;
            return false;
        }

        const securityMessage = coerceSecurityMessage(securityPayload);
        const { status, errorCode } = securityMessage;
        this.password = '';
        this.verifying = false;

        if (status !== StatusType.Success && errorCode !== ErrorType.Locked) {
            // API  zeroes out failures; preserve value from original message
            if (securityMessage.challengeMethodTypeId === 0) {
                securityMessage.challengeMethodTypeId = this.securityMessage.challengeMethodTypeId;
            }

            this.isSuccessful = false;
            this.securityMessage = securityMessage;
            return false;
        }

        this.isSuccessful = true;
        this.close({
            reason: OmegaDialogExitReason.Confirm,
            data: {
                message: securityMessage,
                response,
            },
        });

        return true;
    }

    private async verifyViaOob() {
        if (!this.otpRequestFn) {
            throw new Error(
                'Cannot perform request-without-user-interaction. No request function provided.'
            );
        }

        this.verifying = true;

        const response = await this.otpRequestFn(this.securityMessage);
        const securityPayload = await extractSecurityPayload(response);

        if (!securityPayload) {
            throw new Error('Could extract a security payload from the response.');
        }

        const securityMessage = coerceSecurityMessage(securityPayload);
        this.securityMessage = securityMessage;

        if (isLockedOrSuccessful(this.securityMessage)) {
            this.close({
                reason: OmegaDialogExitReason.Confirm,
                data: {
                    message: this.securityMessage,
                    response,
                },
            });
        }

        this.verifying = false;
        this.verificationStream.emit(securityMessage);
        this.verificationStream.complete();
    }

    private close(dialogResult: OmegaDialogResult<IdentityDialogResultInternal>) {
        this.dispatchEvent(
            new CustomEvent(IdentityDialogEvent.Close, {
                detail: dialogResult,
                bubbles: true
            })
        );
    }

    private renderContent() {
        if (!this.waitingForUser) {
            return nothing;
        }

        return this.secureTokenStrategy.renderContent();
    }

    private renderActions() {
        if (!this.waitingForUser) {
            return nothing;
        }

        return this.secureTokenStrategy.renderActions();
    }

    private renderError() {
        if (!this.errorMessage) {
            return nothing;
        }

        return html`<div class="alert-wrapper">
            <omega-alert type="error" isVisible .closeable=${false}>
                <div class="error">${this.errorMessage}</div>
            </omega-alert>
            <div name="actions">
                <omega-button type="link" @click=${this.cancel}>Close</omega-button>
            </div>
        </div>`;
    }

    private renderProgress() {
        if (!this.verifying) {
            return nothing;
        }

        return html`<omega-progress card></omega-progress>`;
    }

    public render() {
        if (this.isSuccessful) {
            return html`<div class="success-message">You authentication was successful</div>`;
        }

        if (this.verifying) {
            return this.renderProgress();
        }

        return html` ${this.renderError()} ${this.renderContent()} ${this.renderActions()} `;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return css`
            .caption {
                padding: 10px;
            }
            .caption p:first-of-type {
                margin-top: 0;
            }
            .caption p:last-of-type {
                margin-bottom: 0;
            }
            .error {
                font-size: 8pt;
                font-weight: bold;
                padding: 0;
                padding-top: 1px;
            }
            .input-wrapper {
                margin: 20px;
            }
            .alert-wrapper {
                padding: 0 8px;
            }
            input {
                width: 100%;
                height: 32px;
                line-height: 34px;
                border: 1px solid var(--omega-input-default-border);
                padding: 0px 10px;
                border-radius: var(--omega-input-border-radius);
                font-size: 16px;
                box-sizing: border-box;
            }
            omega-dialog {
                width: 25%;
                z-index: 1026;
            }
            omega-progress {
                margin: 25px auto;
            }
            .success-message {
                padding: 10px;
                text-align: center;
            }
        `;
    }
}
