ChangePasswordController.$inject = [
    '$scope',
    '$state',
    'accountService',
    '$stateParams',
    'pagePushService',
    '$location',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function ChangePasswordController(
    $scope,
    $state,
    accountService,
    $stateParams,
    pagePushService,
    $location
) {
    $scope.reason = $stateParams.reason;
    $scope.passwordObj = {
        password: null,
        confirmPassword: null,
        institution: document.documentElement.dataset.institution,
    };

    $scope.message = '';

    $scope.changePassword = changePassword;
    $scope.reset = reset;
    $scope.displayDaysUntilPasswordExpires = displayDaysUntilPasswordExpires;
    $scope.goToDashboard = goToDashboard;
    $scope.isPasswordExpired = isPasswordExpired;
    $scope.willPasswordExpireSoon = willPasswordExpireSoon;

    function displayDaysUntilPasswordExpires() {
        if ($stateParams.daysUntilPasswordExpires === 1) {
            return `${$stateParams.daysUntilPasswordExpires} day`;
        }
        return `${$stateParams.daysUntilPasswordExpires} days`;
    }

    function willPasswordExpireSoon() {
        return !isPasswordExpired($scope.reason) && $stateParams.daysUntilPasswordExpires != null;
    }

    function isPasswordExpired(reason) {
        return reason === 'expired';
    }

    function changePassword() {
        if (
            $scope.passwordObj.password == null ||
            $scope.passwordObj.confirmPassword == null ||
            $scope.passwordObj.password !== $scope.passwordObj.confirmPassword
        )
            return false;

        accountService.updatePassword($scope.passwordObj).then(response => {
            $scope.message = accountService.checkForError(response);
            if (!$scope.message) {
                goToNextStep();
            }
        });
    }

    function goToNextStep() {
        if ($stateParams.nextStep === 'createSecurityQuestions') {
            $state.go('create-security-questions');
        } else {
            accountService.goToNextStep();
        }
    }

    function goToDashboard() {
        $state.go('dashboard');
    }

    function reset() {
        $scope.passwordObj.password = null;
        $scope.passwordObj.confirmPassword = null;
        $scope.form.$setPristine();
    }

    function init() {
        if (!$stateParams.reason) {
            $state.go('login');
        }
        const user = pagePushService.getUser();
        $scope.passwordObj.loginId = user.loginId;
        $scope.passwordObj.companyId = user.companyId;
        $scope.passwordObj.code = user.code;
        const fiId = pagePushService.getUser().institution;
        accountService.getPasswordRequirements(fiId).then(response => {
            $scope.passwordRequirementsList = response;
        });
    }

    init();
}
