NotificationListController.$inject = [
    '$rootScope',
    '$scope',
    '$filter',
    'modalService',
    'notificationsService',
];

export default function NotificationListController(
    $rootScope,
    $scope,
    $filter,
    modalService,
    notificationsService
) {
    $scope.allSelected = { value: false };
    $scope.filterText = { value: null };
    $scope.filteredNotifications = null;
    $scope.notifications = null;
    $scope.notificationsByCategory = {};
    $scope.selectedFilter = 'All';
    $scope.productFilter = {
        ach: false,
        arp: false,
        wire: false,
        admin: false,
        login: false,
        accounts: false,
        transfer: false,
        stoppayment: false,
    };

    $scope.areAnyNotificationsSelected = areAnyNotificationsSelected;
    $scope.deleteSelected = deleteSelected;
    $scope.getNumberInFilter = getNumberInFilter;
    $scope.getTime = getTime;
    $scope.isFilterSelected = isFilterSelected;
    $scope.keyPressReadNotification = keyPressReadNotification;
    $scope.markSelectedAsRead = markSelectedAsRead;
    $scope.markSelectedAsUnread = markSelectedAsUnread;
    $scope.readNotification = readNotification;
    $scope.selectFilter = selectFilter;
    $scope.selectOrDeselectAllNotifications = selectOrDeselectAllNotifications;

    $scope.$watch('filterText.value', onFilterTextChange);
    $scope.$watch('notifications', onNotificationsChange);
    $scope.$watch('notifications', onNotificationsPropertyChange, true);
    $scope.$watch('productFilter', onProductFilterChange, true);

    init();

    function init() {
        getNotifications();
        getProductTypes();
    }

    function isFilterSelected(filter) {
        return $scope.selectedFilter === filter;
    }

    function getNumberInFilter(filter) {
        if (filter && $scope.notificationsByCategory[filter]) {
            return $scope.notificationsByCategory[filter].length;
        }
        return 0;
    }

    function selectFilter(filter) {
        $scope.selectedFilter = filter;
        filterNotifications(filter);
    }

    function getTime(notification) {
        const timestamp = moment(notification.createdOn);
        return timestamp.format('hh:mmA MMM D, YYYY');
    }

    function selectOrDeselectAllNotifications(select) {
        if (!$scope.filteredNotifications) {
            return;
        }

        // Remove selection for all notifications.
        $scope.notifications.forEach(filteredNotification => {
            filteredNotification.isSelected = false;
        });

        // Set new value for the filtered notifications.
        $scope.filteredNotifications.forEach(filteredNotification => {
            filteredNotification.isSelected = select;
        });
    }

    function areAnyNotificationsSelected() {
        return getSelectedNotificationIds().length > 0;
    }

    function markSelectedAsRead() {
        const selectedNotificationIds = getSelectedNotificationIds();
        notificationsService.setRead(selectedNotificationIds, true).then(() => {
            getNotifications();
            $rootScope.$broadcast('removeNotifications', selectedNotificationIds);
        });
    }

    function markSelectedAsUnread() {
        const selectedNotificationIds = getSelectedNotificationIds();
        notificationsService.setRead(selectedNotificationIds, false).then(() => {
            getNotifications();
            $rootScope.$broadcast('addNotifications', selectedNotificationIds);
        });
    }

    function deleteSelected() {
        let selectedNotificationIds;
        let modalInstance;
        let modalOptions;

        selectedNotificationIds = getSelectedNotificationIds();

        modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete',
            actionButtonClass: 'btn-danger',
            headerText: 'Confirm Deletion',
            bodyText: `Are you sure you want to delete ${
                selectedNotificationIds.length === 1 ? 'this notification?' : 'these notifications?'
            }`,
            submit(result) {
                notificationsService.remove(selectedNotificationIds).then(() => {
                    getNotifications();
                    $rootScope.$broadcast('removeNotifications', selectedNotificationIds);
                });
                modalInstance.close();
            },
        };
        modalInstance = modalService.showModal({}, modalOptions);
    }

    function readNotification(targetNotification) {
        $scope.notifications.forEach(notification => {
            if (notification !== targetNotification) {
                notification.isReading = false;
            }
        });
        targetNotification.isReading = !targetNotification.isReading;

        if (!targetNotification.isRead) {
            notificationsService.setRead([targetNotification.id], true);
            targetNotification.isRead = true;
        }
    }

    function keyPressReadNotification(event, targetNotification) {
        if (event.which === 13) {
            readNotification(targetNotification);
        }
    }

    //
    // Events

    function onNotificationsChange(notifications) {
        filterNotifications($scope.selectedFilter);
    }

    function onNotificationsPropertyChange() {
        setValueForSelectAll($scope.selectedFilter);
    }

    function onFilterTextChange() {
        filterNotifications($scope.selectedFilter);
    }

    function onProductFilterChange() {
        filterNotifications($scope.selectedFilter);
    }

    //
    // Private

    function getNotifications() {
        notificationsService.getAll().then(response => {
            $scope.notifications = response;
        });
    }

    function getProductTypes() {
        notificationsService.getProductTypes().then(response => {
            $scope.productTypes = response;
        });
    }

    function filterNotifications(filter) {
        if (!$scope.notifications) {
            return;
        }

        $scope.notificationsByCategory.All = $scope.notifications;

        $scope.notificationsByCategory.Unread = $filter('filter')(
            $scope.notifications,
            notification => !notification.isRead
        );

        $scope.notificationsByCategory.Read = $filter('filter')(
            $scope.notifications,
            notification => notification.isRead
        );

        $scope.notificationsByCategory['High-Priority'] = $filter('filter')(
            $scope.notifications,
            notification => notification.isImportant
        );

        $scope.filteredNotifications = $filter('filter')(
            $scope.notificationsByCategory[filter],
            (notification, index) => {
                let matchesFilterText;
                let noProductSelection;
                let matchesProductSelection;

                matchesFilterText =
                    !$scope.filterText.value ||
                    notification.subject
                        .toLowerCase()
                        .indexOf($scope.filterText.value.toLowerCase()) !== -1 ||
                    notification.detail
                        .toLowerCase()
                        .indexOf($scope.filterText.value.toLowerCase()) !== -1 ||
                    notification.functionality
                        .toLowerCase()
                        .indexOf($scope.filterText.value.toLowerCase()) !== -1;

                noProductSelection =
                    !$scope.productFilter.ach &&
                    !$scope.productFilter.arp &&
                    !$scope.productFilter.wire &&
                    !$scope.productFilter.login &&
                    !$scope.productFilter.admin &&
                    !$scope.productFilter.accounts &&
                    !$scope.productFilter.transfer &&
                    !$scope.productFilter.stoppayment;

                matchesProductSelection =
                    noProductSelection ||
                    ($scope.productFilter.ach && notification.functionality === 'ACH') ||
                    ($scope.productFilter.arp && notification.functionality === 'Positive Pay') ||
                    ($scope.productFilter.stoppayment &&
                        notification.functionality === 'Stop Payment') ||
                    ($scope.productFilter.wire && notification.functionality === 'Wire') ||
                    ($scope.productFilter.login && notification.functionality === 'Login') ||
                    ($scope.productFilter.admin && notification.functionality === 'Admin') ||
                    ($scope.productFilter.accounts && notification.functionality === 'Accounts') ||
                    ($scope.productFilter.transfer &&
                        notification.functionality === 'Transfer/Loan Payment');

                return matchesFilterText && matchesProductSelection;
            }
        );
        setValueForSelectAll();
    }

    function setValueForSelectAll() {
        let allSelected;

        if (!$scope.filteredNotifications) {
            return;
        }

        allSelected = true;
        $scope.filteredNotifications.forEach(filteredNotification => {
            if (!filteredNotification.isSelected) {
                allSelected = false;
            }
        });
        $scope.allSelected.value = allSelected;
    }

    function getSelectedNotificationIds() {
        const ids = [];

        if (!$scope.filteredNotifications) {
            return ids;
        }

        $scope.filteredNotifications.forEach(filteredNotification => {
            if (filteredNotification.isSelected) {
                ids.push(filteredNotification.id);
            }
        });
        return ids;
    }
}
