ApproveRejectController.$inject = ['$scope', '$modalInstance', 'type'];

export default function ApproveRejectController($scope, $modalInstance, type) {
    $scope.type = type;
    // this will dismiss the window.

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will remove the beneficiary
    $scope.save = function () {
        $modalInstance.close($scope.comments);
    };
}
