import { html } from 'lit';

export function renderWiresInfo(title: string) {
    return html`<div style="width: 335px">
        <p>
            <strong>${title}</strong> - Beneficiary and bank information can be saved as a template.
            Templates can be created from this page, or when you are sending a wire.
        </p>
    </div>`;
}
