/**
 *
 * @returns Returns a string representation of the current environment
 */
export const getEnvironment = (): string => window.location.host.split('.')[0];

/**
 * Checks if the current environment is production.
 * @returns Returns a boolean value based on if the current environment is production
 */
export const isProduction = (): boolean => {
    const nonProdEnvironments = [
        /^mock$/g,
        /^dev$/g,
        /^qa$/g,
        /^kind-forest-[0-9a-f]{9}-[0-9a-f]{4}$/g,
        /^calm-dune-[0-9a-f]{9}-[0-9a-f]{4}$/g,
    ];
    const isLowerEnvironment = nonProdEnvironments.some(env => getEnvironment().match(env));
    return !isLowerEnvironment;
};
