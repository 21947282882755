TransferTemplateDetailsController.$inject = [
    '$scope',
    '$state',
    'toaster',
    '$timeout',
    '$modal',
    '$stateParams',
    '$location',
    'modalService',
    'entitlementsService',
    'downloadPageId',
    '$filter',
    'companyAccountsService',
    'internalTransfersService',
];

export default function TransferTemplateDetailsController(
    $scope,
    $state,
    toaster,
    $timeout,
    $modal,
    $stateParams,
    $location,
    modalService,
    entitlementsService,
    downloadPageId,
    $filter,
    companyAccountsService,
    internalTransfersService
) {
    $scope.id = $stateParams.id;
    $scope.type = $stateParams.type;
    $scope.close = close;
    $scope.filterSearch = filterSearch;
    $scope.showAccountGrid = showAccountGrid;
    $scope.searchObj = { text: null };
    $scope.editTemplate = editTemplate;
    $scope.canEditInternalTransferTemplate = entitlementsService.hasEntitlement('Edit Template');
    $scope.canApproveOrReject = canApproveOrReject;
    $scope.total = 0;
    $scope.isConfirmation = false;
    $scope.isEditing = false;
    $scope.approveOrRejectTemplate = approveOrRejectTemplate;
    $scope.hasAccessPermissions = hasAccessPermissions;

    init();

    function close() {
        $state.go('payables.transferTemplatesList');
    }

    $scope.changeEdit = function (item) {
        if (item.isEdit === undefined || item.isEdit === false) {
            item.isEdit = true;
        } else {
            item.isEdit = !item.isEdit;
        }
    };

    function editTemplate() {
        $state.go('payables.edit-transfer-template', { id: $scope.id, type: 'Edit' });
    }

    function canApproveOrReject() {
        return (
            !!$scope.transferTemplate &&
            $scope.transferTemplate.status === 'Pending Approval' &&
            !$scope.isEditing &&
            !$scope.isConfirmation
        );
    }

    function approveOrRejectTemplate(template, type) {
        const modalInstance = $modal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeTransferStatusDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                paymentType() {
                    return 'Template';
                },
            },
        });
        modalInstance.result.then(comments => {
            internalTransfersService.approveOrRejectTransferTemplate(type, template.id).then(() => {
                $state.go('payables.transferTemplatesList');
            });
        });
    }

    function showAccountGrid(transferTemplate) {
        return (
            $scope.filteredtransferTemplateDetails !== undefined &&
            $scope.filteredtransferTemplateDetails.length > 0
        );
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredtransferTemplateDetails = $filter('filter')(
            $scope.transferTemplateDetails,
            filterRows
        );
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.transferTemplateDetails ||
            $scope.transferTemplateDetails.length <= 0
        ) {
            return true;
        }

        return (
            (!!row.number &&
                row.number.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.transferAmount &&
                $filter('currency')(row.transferAmount, '$').indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.transferMemo &&
                !!row.transferMemo &&
                row.transferMemo.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function init() {
        internalTransfersService.getTransferTemplate($scope.id).then(response => {
            $scope.transferTemplate = angular.copy(response);
            if (response.transferType !== 'onetoone') {
                $scope.transferTemplateDetails = response.templateTransferInfo;
                $scope.searchObj.text = null;
                $scope.filterSearch($scope.searchText);
                $scope.total = calculateTotal($scope.transferTemplateDetails);
            }
            if ($scope.type === 'Edit') {
                $scope.isEditing = true;
            }
        });
    }

    function hasAccessPermissions(permissionType) {
        if ($scope.transferTemplate !== null && $scope.transferTemplate !== undefined) {
            let result = false;
            angular.forEach($scope.transferTemplate.permissions, permission => {
                if (permission.permission === permissionType) {
                    result = true;
                }
            });
            return result;
        }
    }

    function calculateTotal(details) {
        let total = 0;
        angular.forEach(details, (value, index) => {
            total += parseFloat(value.transferAmount);
        });
        return total.toFixed(2);
    }
}
