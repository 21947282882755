AccountNickNamesController.$inject = [
    '$scope',
    'companyAccountsService',
    'modalService',
    '$filter',
];

export default function AccountNickNamesController(
    $scope,
    companyAccountsService,
    modalService,
    $filter
) {
    $scope.edit = edit;
    $scope.setForm = setForm;
    $scope.saveAccount = saveAccount;
    $scope.bulkSave = bulkSave;
    $scope.saveAccount = saveAccount;
    $scope.bulkEdit = bulkEdit;
    $scope.cancelAccount = cancelAccount;
    $scope.bulkCancel = bulkCancel;
    $scope.filteredAccounts = [];
    $scope.search = { searchAccountNickName: '' };

    function init() {
        companyAccountsService.getCompanyAccountsNickNames().then(response => {
            $scope.accounts = response;
            $scope.filteredAccounts = $scope.accounts;
        });
    }

    function edit(account) {
        $scope.isEditing = true;
        $scope.copyAccount = angular.copy(account);
        if ($scope.form.$dirty) {
            $scope.form.$setPristine();
        }
        angular.forEach($scope.accounts, account => {
            account.isEditing = false;
        });

        $scope.filteredAccounts[$scope.filteredAccounts.indexOf(account)].isEditing = true;
    }

    function setForm(form) {
        $scope.form = form;
    }

    function saveAccount(account) {
        companyAccountsService.saveNickNameAccount(account).then(response => {
            $scope.filteredAccounts[$scope.filteredAccounts.indexOf(account)] = response;
            $scope.copyAccount = null;
            $scope.isEditing = false;
        });
    }

    function cancelAccount(account) {
        $scope.filteredAccounts[$scope.filteredAccounts.indexOf(account)].isEditing = false;
        $scope.isEditing = false;
        $scope.accounts[$scope.accounts.indexOf(account)] = angular.copy($scope.copyAccount);
        $scope.copyAccount = null;
    }

    function bulkEdit() {
        $scope.bulkEditing = true;
        $scope.copyAccounts = angular.copy($scope.accounts);
        angular.forEach($scope.accounts, account => {
            account.isEditing = true;
        });
    }

    function bulkSave() {
        companyAccountsService.saveBulkAccountNickNames($scope.accounts).then(response => {
            $scope.accounts = response;
            let dupliacateNamePresent;
            $scope.filteredAccounts = angular.copy($scope.accounts);
            angular.forEach($scope.filteredAccounts, account => {
                if (account.isDuplicated) {
                    dupliacateNamePresent = true;
                }
                angular.forEach($scope.filteredAccounts, account => {
                    account.isEditing = true;
                });
            });
            if (!dupliacateNamePresent) {
                angular.forEach($scope.filteredAccounts, account => {
                    account.isEditing = false;
                });

                $scope.bulkEditing = false;
                $scope.isEditing = false;
            }
        });
    }

    $scope.$watch(
        'search.searchAccountNickName',
        () => {
            const expression = function (value, index) {
                const fields = ['number', 'type', 'name'];
                for (const i in fields) {
                    const field = fields[i];
                    const query = {};
                    query[field] = $scope.search.searchAccountNickName;
                    const r = $filter('filter')([value], query);
                    if (r.length > 0) return true;
                }
                return false;
            };
            $scope.filteredAccounts = $filter('filter')($scope.accounts, expression);
        },
        true
    );

    function bulkCancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    $modalInstance.close();
                    init();
                    angular.forEach($scope.accounts, account => {
                        account.isEditing = false;
                    });
                    $scope.bulkEditing = false;
                    $scope.isEditing = false;
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            init();
            angular.forEach($scope.accounts, account => {
                account.isEditing = false;
            });
            $scope.bulkEditing = false;
            $scope.isEditing = false;
        }
    }
    init();
}
