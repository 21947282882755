/* eslint-disable import/extensions */
import { FiDate } from '../../../dates';
import { mapAchFileActivityFieldsToParameters } from '../../mappings/ach/ach-file-activity.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchFileActivityRequests from '../../requests/ach/ach-file-activity-requests.js';
import DownloadService from '../download/download-service.js';

export default class AchFileActivityService {
    static async fetchAchFileActivity(body) {
        const parameters = mapAchFileActivityFieldsToParameters(body);
        return AchFileActivityRequests.fetchFileActivity(parameters);
    }

    static async fetchFileSources() {
        return AchFileActivityRequests.fetchFileSources();
    }

    static async fetchCompanies() {
        return AchCompanyRequests.getAchCompanies();
    }

    static async downloadAchFileActivity(outputType, filterModel) {
        const mappedDownloadRequestFilters = mapAchFileActivityFieldsToParameters({
            parameters: filterModel,
        });
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        const pageId = 'ACHFileActivityList';

        return DownloadService.download(
            fileSuffix,
            outputType,
            pageId,
            { ...mappedDownloadRequestFilters, page: pageId },
            ['CSV', 'PDF']
        );
    }
}
