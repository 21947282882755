import { RecordsetEvent } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import { formatUsd } from '@treasury/policy/lib/formatters';
import { css, html, LitElement } from 'lit';
import { mix } from 'mixwith';

class AchReportTop extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            recordset: Object,
            countLabel: String,
            amountSum: Number,
            itemCount: Number,
        };
    }

    constructor() {
        super();
        this.amountSum = 0;
        this.itemCount = 0;
        this.countLabel = 'Count';
    }

    async firstUpdated() {
        this.listenToMulti(
            this.recordset,
            [RecordsetEvent.Updated, RecordsetEvent.CountsChanged],
            () => this.setSummary()
        );
        this.setSummary();
    }

    generateSummary(recordset) {
        const { allRecords } = recordset;
        const checkSum = this.recordset.allRecords
            .map(record => record.getField('creditAmount'))
            .reduce((sum, current) => sum + current, 0);

        const itemCount = allRecords.length;

        return {
            checkSum,
            itemCount,
        };
    }

    setSummary() {
        const { checkSum, itemCount } = this.generateSummary(this.recordset);
        this.amountSum = checkSum;
        this.itemCount = itemCount;
    }

    renderFilterBar() {
        return html`<omega-filter-bar .recordset=${this.recordset}></omega-filter-bar>`;
    }

    render() {
        return html`
            <div class="row padded">
                ${this.renderFilterBar()}
                <div class="text-right">
                    <span>
                        <strong>${this.countLabel}: </strong>
                        ${this.itemCount}
                    </span>
                    <br />
                    <span>
                        <strong>Total Amount: </strong>
                        ${formatUsd(this.amountSum)}
                    </span>
                </div>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .padded {
                padding: 10px;
            }
            .row {
                display: flex;
                flex-direction: row;
            }
            .row > * {
                flex: auto;
            }
            .text-right {
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        `;
    }
}

customElements.define('ach-report-top', AchReportTop);
