import { Message } from './types/message.type';

export const transformMessage = (message: Message) => {
    const fd = new FormData();
    fd.append('CompanyId', message.companyId);
    fd.append('UserId', message.toUserId?.toString());
    fd.append('CategoryId', message.categoryId?.toString());
    fd.append('Body', message.body);
    fd.append('ParentMessageId', message.parentMessageId?.toString());
    fd.append('AssignedToUserId', message.assignedToUserId?.toString());
    message.files?.forEach(async (file: File) => {
        fd.append('file', file);
    });
    return fd;
};
