import { getChannelAPIPath } from '@treasury/core/http';
import { getChannelModule } from '../app.module';

const channelModule = getChannelModule();

channelModule.factory('bdiEStatementsService', bdiEStatementsService);

bdiEStatementsService.$inject = ['$resource', '$http'];

function bdiEStatementsService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}`;

    return {
        getInformation,
    };

    function getInformation(productTypeId) {
        return $resource(
            `${getChannelAPIPath()}SsoProductType/` + `:productTypeId`,
            {
                productTypeId,
            },
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }
}
