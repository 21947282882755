AchFileActivityListController.$inject = [
    '$scope',
    'secCodesService',
    '$modal',
    'achBatchService',
    'achFileActivityService',
    'achCompaniesService',
    'entitlementsService',
    'searchFilterService',
    '$state',
    'toaster',
    'securityService',
    '$filter',
    'regexConstants',
    'dateConstants',
    'holidaysService',
    'modalService',
    'FileSaver',
];

export default function AchFileActivityListController(
    $scope,
    secCodesService,
    $modal,
    achBatchService,
    achFileActivityService,
    achCompaniesService,
    entitlementsService,
    searchFilterService,
    $state,
    toaster,
    securityService,
    $filter,
    regexConstants,
    dateConstants,
    holidaysService,
    modalService,
    FileSaver
) {
    $scope.filterObject = {};
    $scope.filteredFileActivityList = [];
    $scope.allItemsAreSelected = {};
    $scope.selectionList = [];
    $scope.list = [];
    $scope.searchObj = { text: null };
    $scope.search = search;
    $scope.resetSearch = resetSearch;
    $scope.setForm = setForm;
    $scope.createPayment = createPayment;
    $scope.selectOrDeselectAllPayments = selectOrDeselectAllPayments;
    $scope.updateSelectionList = updateSelectionList;
    $scope.calculateTotals = calculateTotals;
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.goToRecurringPaymentList = goToRecurringPaymentList;
    $scope.goToACHFileActivityList = goToACHFileActivityList;
    $scope.goToPaymentList = goToPaymentList;
    $scope.fileNameRegex = regexConstants.AlphaNumericPlusSpecialCharsRegex;
    $scope.reviewFileActivity = reviewFileActivity;
    $scope.reviewSingleFileActivity = reviewSingleFileActivity;
    $scope.currentDate = moment().format('MM/DD/YYYY');
    $scope.endOnDate = moment().format('MM/DD/YYYY');
    $scope.hideReview = hideReview;
    $scope.viewFile = viewFile;
    $scope.processFile = processFile;
    $scope.deleteFile = deleteFile;
    $scope.showErrorList = showErrorList;
    $scope.isDataLoaded = false;
    $scope.disableDownload = true;

    $scope.dpOptions = {
        disableDates(date) {
            if (Date.parse(date) < Date.parse($scope.endOnDate)) {
                return true;
            }
            if (Date.parse(date) > Date.parse($scope.currentDate)) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function setApprovalAttributes() {
        $scope.fileActivityList.map(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'achPayment',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
        });
    }

    // fired when user clicks review button at bottom after selecting multiple records
    function reviewFileActivity() {
        const selected_fileActivity_id_list = [];
        angular.forEach($scope.selectionList, file => {
            if (file.isSelected) {
                selected_fileActivity_id_list.push(file.fileArchiveId);
            }
        });
        $state.go('payables.ach.payment-list', {
            fileArchiveIds: selected_fileActivity_id_list,
        });
    }

    // fired when user clicks on a record's review link
    function reviewSingleFileActivity(id) {
        $state.go('payables.ach.payment-list', { fileArchiveIds: [id] });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredFileActivityList = $filter('filter')($scope.fileActivityList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.fileActivityList ||
            $scope.fileActivityList.length <= 0
        ) {
            return true;
        }
        return (
            (!!row.fileName &&
                row.fileName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.source &&
                row.source.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.fileSize &&
                $filter('fileSizeFormatter')(row.fileSize).indexOf(
                    $scope.searchObj.text.toLowerCase()
                ) !== -1) ||
            (!!row.uploadDate &&
                $filter('date')(row.uploadDate, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.totalBatches &&
                $filter('number')(row.totalBatches).indexOf($scope.searchObj.text) !== -1) ||
            (!!row.approvedCount &&
                $filter('number')(row.approvedCount).indexOf($scope.searchObj.text) !== -1) ||
            (!!row.pendingApprovalCount &&
                $filter('number')(row.pendingApprovalCount).indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.rejectedCount &&
                $filter('number')(row.rejectedCount).indexOf($scope.searchObj.text) !== -1) ||
            (!!row.expiredCount &&
                $filter('number')(row.expiredCount).indexOf($scope.searchObj.text) !== -1) ||
            (!!row.debitAmount &&
                $filter('currency')(row.debitAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.creditAmount &&
                $filter('currency')(row.creditAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function disableFilter() {
        return $scope.fileActivityList ? $scope.fileActivityList.length <= 0 : true;
    }

    function goToRecurringPaymentList() {
        $state.go('payables.ach.recurring-payment-list');
    }

    function goToACHFileActivityList() {
        $state.go('payables.ach.fileactivity-list');
    }

    function goToPaymentList() {
        $state.go('payables.ach.payment-list');
    }

    function init() {
        $scope.approvePaymentEntitlement = 'Approve Ach Payment';
        $scope.dateOptions = [
            'Specific Date',
            'Range',
            'Last-one-Week',
            'Last-one-Month',
            'Last-one-Year',
        ];
        $scope.amountOptions = ['Specific Amount', 'Range'];
        $scope.statusOptions = [
            'All',
            'Pending Approval',
            'Approval Rejected',
            'Initiated',
            'UnInitiated',
            'Cancelled',
            'Failed',
            'Expired',
            'Scheduled',
        ];

        resetSearch();
        searchFilterService.restoreFilter($scope.filterObject);

        const filters = prepareFilter($scope.filterObject);
        if ($state.params.id) filters.fileArchiveId = parseInt($state.params.id);

        achFileActivityService.search(filters).then(response => {
            $scope.fileActivityList = response;
            setApprovalAttributes();
            $scope.filterSearch($scope.searchText);
            if ($scope.fileActivityList.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.disableDownload = false;
            }
            $scope.isDataLoaded = true;
        });
        getHolidays();
        $scope.endOnDate = moment().add(-18, 'M').format('MM/DD/YYYY'); // to address requirement that System should allow user to search file Activity for last 18 months
    }

    function resetSearch() {
        if ($scope.form) {
            $scope.form.$setPristine();
        }

        $scope.filterObject = {};
        $scope.filterObject.achFileName = '';
        $scope.filterObject.uploadDate = '';
        getAchSourceList();
        getAchCompanies();
        $scope.filterObject.uploadDateType = $scope.dateOptions[0];
    }

    // gets data for Source dropdown under fly out search
    function getAchSourceList() {
        achFileActivityService.getsourcelist().then(response => {
            $scope.achSourceList = response;
            angular.forEach($scope.achSourceList, achSource => {
                achSource.isChecked = true;
            });
        });
    }

    // gets data for ACH Company Name dropdown under fly out search
    function getAchCompanies() {
        achCompaniesService.getAll().then(data => {
            $scope.achCompanyList = data;
            angular.forEach($scope.achCompanyList, achCompany => {
                achCompany.isChecked = true;
            });
        });
    }

    function search() {
        searchFilterService.saveFilter($scope.filterObject, ['payables.ach.fileactivity-list']);
        achFileActivityService.search(prepareFilter($scope.filterObject)).then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.searchObj.text = null;
            $scope.fileActivityList = response;
            setApprovalAttributes();
            $scope.filterSearch($scope.searchText);

            if ($scope.fileActivityList.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
        });
    }

    function createPayment() {
        $state.go('payables.ach.payments.create');
    }

    function setForm(form) {
        $scope.form = form;
    }

    function hasPermission(permission) {
        return entitlementsService.hasEntitlement(permission);
    }

    function selectOrDeselectAllPayments() {
        angular.forEach($scope.fileActivityList, file => {
            if (hideReview(file) !== true) {
                file.isSelected = $scope.allItemsAreSelected.value;
                if ($scope.allItemsAreSelected.value === true) {
                    $scope.selectionList.push(file);
                } else {
                    $scope.selectionList = [];
                }
            } else {
                file.isSelected = false;
            }
        });
    }

    function updateSelectionList(file) {
        if (file.isSelected === true) {
            $scope.selectionList.push(file);
        } else if (file.isSelected === false) {
            $scope.selectionList.splice($scope.selectionList.indexOf(file), 1);
        }
    }

    function calculateTotals(data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    }

    function prepareFilter(filterObject) {
        const obj = angular.copy(filterObject);
        const fields = ['debitAmount', 'creditAmount', 'effectiveDate', 'uploadDate'];
        angular.forEach(fields, field => {
            const fieldType = `${field}Type`;
            if (!obj[field] && (!obj[fieldType] || obj[fieldType].indexOf('Specific') !== -1)) {
                delete obj[field];
                delete obj[fieldType];
            }
        });
        return obj;
    }

    function hideReview(file) {
        if (
            file.approvedCount === 0 &&
            file.pendingApprovalCount === 0 &&
            file.expiredCount === 0 &&
            file.rejectedCount === 0
        ) {
            return true;
        }
        return false;
    }

    function showErrorList(errorList) {
        $modal.open({
            template: require('../views/nachaFileValidationErrorList.html'),
            windowClass: 'inspect-batches-modal-window',
            controller: 'nachaFileValidationErrorListController',
            backdrop: 'static',
            resolve: {
                validationErrorList() {
                    return errorList;
                },
            },
        });
    }

    function viewFile(file) {
        achFileActivityService.downloadNachaFile(file.fileArchiveId).then(data => {
            const filename = file.fileName;
            const blob = new Blob([data], { type: 'text/csv' });
            FileSaver.saveAs(blob, `${filename}.txt`);
        });
    }

    function deleteFile(file) {
        const modalOptions = {
            headerText: `Delete ${file.fileName}?`,
            closeButtonText: 'No',
            actionButtonText: 'Yes',
            bodyText: 'File and payments will be removed from the system.',
            submit(result) {
                modalInstance.close(result);
            },
        };
        var modalInstance = modalService.showModal({}, modalOptions);
        modalInstance.result.then(() => {
            achFileActivityService.deleteFile(file.fileId).then(() => {
                modalInstance.close();
                $state.reload();
            });
        });
    }

    function processFile(file) {
        achFileActivityService.processNachaFile(file.fileId).then(response => {
            const filename = file.fileName;
            upload(response, file);
        });
    }

    function upload(response, file) {
        if (response.errorSummary) {
            file.validationError = response.errorSummary.summaryMessageList;
        } else {
            $scope.achPaymentImport = response;
            if (response.achBatchSummaries && response.achBatchSummaries.length > 1) {
                response.achBatchSummaries = response.achBatchSummaries.map(item => {
                    item.status = null;
                    if (!item.frequency) {
                        item.frequency = {
                            type: 'One Time',
                        };
                    }
                    return item;
                });

                $scope.setSelectedBatches(response);
                $state.go('payables.ach.payments.createFromNachaUpload', {
                    upload: true,
                });
            } else {
                response.status = null;
                if (!response.frequency) {
                    response.frequency = {
                        type: 'One Time',
                    };
                }
                $scope.setSelectedBatches(response);
                $state.go('payables.ach.payment-detail', {
                    id: 0,
                    type: 'import',
                });
            }
        }
    }

    init();
}
