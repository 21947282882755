export const outputTypes = {
    pdf: 1,
    csv: 2,
    nacha: 7,
};

export const fileExtensions = {
    pdf: 'pdf',
    csv: 'csv',
    nacha: 'txt',
};

export function outputTypeId(type: string) {
    const typeFormatted = type.toLowerCase();
    let typeId: number = -1;
    if (typeFormatted in outputTypes) {
        typeId = outputTypes[typeFormatted as keyof typeof outputTypes];
    }

    let fileExtension: string | false = false;
    if (typeFormatted in fileExtensions) {
        fileExtension = fileExtensions[typeFormatted as keyof typeof fileExtensions];
    }
    return { typeId, fileExtension };
}
