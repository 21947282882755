import { IpRestrictionAddressModelDto, IpRestrictionModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';

export class UserIpRestrictions extends DomainEntity<IpRestrictionModelDto> {
    constructor(dto: IpRestrictionModelDto) {
        super(dto);
    }

    public createDefault() {
        return new UserIpRestrictions({} as IpRestrictionModelDto) as this;
    }

    get ipRestrictions() {
        return this.dto.ipRestrictions?.map(
            (ipRestriction: IpRestrictionAddressModelDto, index: number) => ({
                ...ipRestriction,
                edited: Object.entries(ipRestriction).some(
                    ([key, value]: [string, number | string]) =>
                        // @ts-ignore
                        this.dto.ipRestrictionsPending[index][key] !== value
                ),
            })
        );
    }

    get restricted() {
        return this.dto.restricted;
    }
}
