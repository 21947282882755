/* eslint-disable no-use-before-define */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-return-assign */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record as FdlRecord, Recordset } from '@treasury/FDL';
import { IIssuedItem, IssuedItemUiState } from '@treasury/domain/channel/mappings/arp';
import { ArpService } from '@treasury/domain/channel/services/arp';
import { Action, LitPropertyVerifier } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../shared/templates/report-top.template';
import { reviewStepColumns } from '../data';
import { getIssuedItemColorAndIcon } from '../helpers';
import { VoidMatchesDatastore } from '../services';
import './void-matches-detail-row.template';
import { IssuedItemsVoidsSelectionEvent } from './void-matches-detail-row.template';

interface ReviewStepConfig {
    recordset: Recordset<IIssuedItem>;
    onNext: Action;
    onBack: Action;
    onCancel: Action;
}

export function renderReviewStep(config: ReviewStepConfig) {
    const { recordset, onNext, onCancel, onBack } = config;

    return html`<issued-items-creation-review-step
        .recordset=${recordset}
        .onNext=${onNext}
        .onCancel=${onCancel}
        .onBack=${onBack}
    ></issued-items-creation-review-step>`;
}

@customElement('issued-items-creation-review-step')
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class IssuedItemsCreationReviewStep extends LitPropertyVerifier {
    @property({
        type: Object,
    })
    public readonly recordset!: Recordset<IIssuedItem>;

    @property({
        attribute: false,
    })
    public readonly onNext!: Action;

    @property({
        attribute: false,
    })
    public readonly onBack!: Action;

    @property({
        attribute: false,
    })
    public readonly onCancel!: Action;

    @state()
    private isLoading = true;

    protected readonly verifiedPropNames: (keyof this)[] = [
        'recordset',
        'onNext',
        'onBack',
        'onCancel',
    ];

    @InjectProperty()
    private declare arpService: ArpService;

    @InjectProperty()
    private declare matchesDatastore: VoidMatchesDatastore;

    protected async firstUpdated(changedProperties: Map<string | number | symbol, unknown>) {
        super.firstUpdated(changedProperties);
        const voids = this.recordset.backingValues.filter(item => item.type === 'Void');

        this.recordset.setSortComparator((a, b) => voidMatchesSortPredicate(a.values, b.values));

        this.isLoading = true;
        await this.loadMatches(voids);
        this.recordset.sortAndFilterRecords();
        this.isLoading = false;
    }

    private async loadMatches(items: IIssuedItem[]) {
        this.matchesDatastore.clear();
        if (items.length < 1) {
            return;
        }

        const results = await this.arpService.getMatchesForVoids(items);
        results.forEach(result => {
            const { matches, original } = result;
            if (matches.length < 1) {
                return;
            }

            this.matchesDatastore.addMatches(original, matches);
        });
    }

    private onVoidsSelected(e: IssuedItemsVoidsSelectionEvent) {
        const { voids, original } = e.detail;
        const { backingValues } = this.recordset;

        this.recordset.delete(original);
        this.matchesDatastore.replaceWithSelected(original, voids);

        voids
            // prune duplicates or equivalent items
            .filter(v => !backingValues.some(item => v.compare(item)))
            .forEach(v => {
                this.recordset.insert(v);
            });

        this.requestUpdate();
    }

    private hasEligibleVoids() {
        const { backingValues } = this.recordset;
        return backingValues.some(item => item.voidEligible);
    }

    public getRowBorderColor(item: IIssuedItem) {
        return getIssuedItemColorAndIcon(item)?.rowColor;
    }

    private renderDetailRow(item: IIssuedItem, closeFn: Action) {
        const matches = this.matchesDatastore.getMatches(item);
        return html`<issued-items-void-matches
            .original=${item}
            .voidMatches=${matches}
            .closeRow=${closeFn}
            @voids-changed=${(e: IssuedItemsVoidsSelectionEvent) => this.onVoidsSelected(e)}
        ></issued-items-void-matches>`;
    }

    private renderLoader() {
        return html`<blocking-loader></blocking-loader>`;
    }

    protected render() {
        if (this.isLoading) {
            return this.renderLoader();
        }

        return html`<omega-table
                .recordset=${this.recordset}
                .columnDefinitions=${reviewStepColumns}
                .autoExpandDetailRows=${true}
                .borderColorFn=${(record: FdlRecord<IIssuedItem>) =>
                    this.getRowBorderColor(record.values)}
                .detailRowTemplateFunction=${(record: FdlRecord<IIssuedItem>, close: Action) =>
                    this.renderDetailRow(record.values, close)}
            ></omega-table>
            <omega-button-bar position="bottom" alignment="left">
                <omega-button
                    @click=${this.onNext}
                    type="primary"
                    .disabled=${this.recordset.hasErrors || !this.hasEligibleVoids()}
                    >Confirm</omega-button
                >
                <omega-button @click=${this.onBack} type="link">Back</omega-button>
                <omega-button @click=${this.onCancel} type="link">Cancel</omega-button>
            </omega-button-bar>`;
    }

    public static styles = css`
        .warning-row {
            border: 1px solid;
            border-color: var(--omega-warning);
        }
    `;
}

/**
 * Bubble items that are invalid and blocking confirm button to the top.
 * Items requiring attention come next.
 * Existing sort order is preserved otherwise.
 */
export function voidMatchesSortPredicate(a: IIssuedItem, b: IIssuedItem) {
    const bothInvalid =
        a.errorState === IssuedItemUiState.Error && b.errorState === IssuedItemUiState.Error;
    const bothIneligible =
        a.errorState === IssuedItemUiState.Warning && b.errorState === IssuedItemUiState.Warning;

    if (bothInvalid || bothIneligible) {
        return 0;
    }

    if (a.errorState === IssuedItemUiState.Error) {
        return -1;
    }

    if (b.errorState === IssuedItemUiState.Error) {
        return 1;
    }

    if (a.errorState === IssuedItemUiState.Warning) {
        return -1;
    }

    if (b.errorState === IssuedItemUiState.Warning) {
        return 1;
    }

    // consider all other cases equal to maintain pre-existing sort order
    return 0;
}
