import { PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import { RecordsetValidator } from '@treasury/FDL/recordset';

export const recipientAmountValidator = (): RecordsetValidator<PaymentRecipient> => ({
    name: 'Recipient amounts may not be zero',
    validate: (recipients: Array<Record<PaymentRecipient>>) =>
        !recipients.some(
            (recipientRecord: Record<PaymentRecipient>) =>
                recipientRecord.values.amount === 0 && !recipientRecord.values.prenote
        ),
});
