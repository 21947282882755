import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import { RecordsetValidator } from '@treasury/FDL/recordset';

export const unbalancedPartialOffsetValidator = (
    record: Record<PaymentHeader>
): RecordsetValidator<PaymentRecipient> => ({
    name: 'Debit and Credit amounts must be greater than zero and different from one another.',
    validate: (recipients: Array<Record<PaymentRecipient>>) => {
        const achCompany = record.getField('achCompany');
        if (achCompany.batchBalanceRequirements !== 'Unbalanced - Partial Offset') return true;
        const credit = record.getField('creditAmount');
        const debit = record.getField('debitAmount');
        return credit !== debit && credit > 0 && debit > 0;
    },
});
