import { AchExceptionSaveResultDto } from '../../types/arp/ach-exception-save-result.dto';

export class AchExceptionSaveResult {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto: AchExceptionSaveResultDto) {}

    public get confirmedDecisionCount() {
        return this.dto.confirmedDecisionCount;
    }

    public set confirmedDecisionCount(value) {
        this.dto.confirmedDecisionCount = value;
    }

    public get failedAchExceptions() {
        return this.dto.failedAchExceptions;
    }

    public set failedAchExceptions(value) {
        this.dto.failedAchExceptions = value;
    }

    public get failedDecisionsCount() {
        return this.dto.failedDecisionsCount;
    }

    public set failedDecisionsCount(value) {
        this.dto.failedDecisionsCount = value;
    }

    public get totalDecisionsCount() {
        return this.dto.totalDecisionsCount;
    }

    public set totalDecisionsCount(value) {
        this.dto.totalDecisionsCount = value;
    }
}
