import { LitElement, html, css, nothing } from 'lit';

import { Record } from '@treasury/FDL/record';
import { transferComments } from '@treasury/policy/transfers';

import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-textarea';
import '@treasury/omega/components/progress/omega-progress.js';

class TransferActionDialog extends LitElement {
    static get properties() {
        return {
            showActionDialog: Boolean,
            dialogAction: Function,
            dialogTitle: String,
            actionTaken: String,
            transferRecords: Array,
            statusChangeComments: String,
            loading: Boolean,
        };
    }

    constructor() {
        super();
        this.rowCount = 5;
        this.showActionDialog = true;
        this.actionTaken = '';
        this.loading = false;
        this.step = 'prompt';
        this.statusChangeComments = null;
    }

    connectedCallback() {
        super.connectedCallback();
        this.statusCommentRecord = new Record({ comments: transferComments }, { comments: '' });
    }

    get disableDialogAction() {
        return typeof this.dialogAction !== 'function';
    }

    async handleDialogAction() {
        let response = { type: 'error', message: 'An error has occurred.' };
        this.loading = true;
        if (this.dialogAction) {
            response = await this.dialogAction(this.transferRecords, this.statusChangeComments);
        }
        this.loading = false;
        this.step = response.type;
        this.message = response.message;
        this.dispatchEvent(new CustomEvent(this.actionTaken, { detail: response }));
    }

    handleDialogClose() {
        this.showActionDialog = false;
        this.dispatchEvent(new CustomEvent('close'));
        this.step = 'prompt';
        this.message = '';
        this.statusCommentRecord.setField('comments', '');
    }

    capitalizeAction(action) {
        return action[0].toUpperCase() + action.substring(1).toLowerCase();
    }

    pluralizeTransfers() {
        return this.transferRecords.length > 1 ? 'Transfers' : 'Transfer';
    }

    setDialogTitle() {
        return this.actionTaken
            ? `${this.capitalizeAction(this.actionTaken)} ${this.pluralizeTransfers()}?`
            : 'Confirm Transfer Action';
    }

    getDialogTitle() {
        return this.dialogTitle ?? this.setDialogTitle();
    }

    getTransactionIds() {
        return this.transferRecords.map(record => record.getField('transactionId')).join(', ');
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<omega-progress card class="large-loader"></omega-progress>`;
    }

    renderActionButton() {
        const actionType = this.actionTaken === 'approve' ? 'approve' : 'reject';
        return html`<omega-button
            slot="actions"
            type=${actionType}
            id="transferDialogActionButton"
            ?disabled=${this.disableDialogAction}
            @click=${this.handleDialogAction}
            >${this.capitalizeAction(this.actionTaken)} ${this.pluralizeTransfers()}</omega-button
        >`;
    }

    renderContent() {
        if (this.step === 'prompt') {
            const content = html`<p class="dialogContent">
                Are you sure you want to ${this.actionTaken} ${this.getTransactionIds()}?
            </p>`;
            return html`<div slot="content" id="modalContent">
                    ${content}
                    <br />
                    Comments:
                    <omega-textarea
                        .rows=${this.rowCount}
                        .field=${this.statusCommentRecord.field('comments')}
                        @change=${({ detail }) => {
                            this.statusChangeComments = detail;
                        }}
                    ></omega-textarea>
                </div>
                ${this.renderActionButton()}
                <omega-button
                    slot="actions"
                    @click=${this.handleDialogClose}
                    id="closeTransferDialogActionButton"
                    >Cancel</omega-button
                >`;
        }
        if (this.step === 'success' || this.step === 'error') {
            return html`<div slot="content" id="modalContent">
                    <p class="dialogContent">${this.message}</p>
                    <br />
                </div>
                <omega-button
                    slot="actions"
                    @click=${this.handleDialogClose}
                    id="closeTransferDialogActionButton"
                    >Close</omega-button
                >`;
        }
        return html`<div slot="content" id="modalContent">
                <p class="dialogContent">An error occurred while processing your request.</p>
                <br />
            </div>
            <omega-button
                slot="actions"
                @click=${this.handleDialogClose}
                id="closeTransferDialogActionButton"
                >Close</omega-button
            >`;
    }

    renderDialogBody() {
        if (this.loading) return this.renderLoader();
        return this.renderContent();
    }

    render() {
        if (this.showActionDialog) {
            return html`<omega-dialog
                id="payment-action-dialog"
                open
                .dialogTitle=${this.getDialogTitle()}
                @close=${this.handleDialogClose}
            >
                ${this.renderDialogBody()}
            </omega-dialog>`;
        }
        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #modalContent {
                padding: 0 15px 15px;
                line-height: 1em;
            }
            .dialogContent {
                margin-top: 0;
                line-height: 1.5em;
            }
        `;
    }
}

customElements.define('transfer-action-dialog', TransferActionDialog);
