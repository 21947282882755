/*
    NOTE:
    Eventually we might want to fill the auditCode enumeration from the API,
    instead of having it implemented both there and here.
    Even then, some precaution will have to be taken such that
    changes to the API keys won't break presentation code.
*/
export enum ResourceType {
    AccountResources = 0,
    AuthenticationResources,
    OdiResources,
    PaymentResources,
    ReportingResources,
    NotificationResources,
    WireResources,
    SchedulerResources,
    ValidationResources,
    AchResources,
    ArpResources,
    SecurityResources,
    SessionResources,
}
