import AchChildSupportServices from '@treasury/domain/channel/services/ach/ach-child-support-services.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';

export default class ChildSupportTemplatesClient {
    constructor() {
        this.entitlementsService = new EntitlementsService();
    }

    async fetchPaymentTemplates(...args) {
        return AchChildSupportServices.getPaymentTemplates(...args);
    }

    async deletePaymentTemplate(guid) {
        return AchChildSupportServices.deleteTemplate(guid);
    }

    async getPaymentTemplate(guid) {
        return AchChildSupportServices.getPaymentTemplate(guid);
    }

    async downloadChildSupportTemplates(filterModel, outputType) {
        return AchChildSupportServices.downloadChildSupportTemplates(filterModel, outputType);
    }

    async hasEntitlement(entitlement) {
        return this.entitlementsService.hasEntitlement(entitlement);
    }

    /**
     * @param entitlements: Array<string>
     */
    async hasSomeEntitlements(entitlements) {
        const entitlementList = await this.entitlementsService.getEntitlements();
        return entitlements.reduce(
            (prev, curr) =>
                !!entitlementList.find(entitlement => entitlement.permission === curr) || prev,
            false
        );
    }

    /**
     * @param entitlements: Array<string>
     */
    async hasAllEntitlements(entitlements) {
        const entitlementList = await this.entitlementsService.getEntitlements();
        return entitlements.reduce(
            (prev, curr) =>
                !!entitlementList.find(entitlement => entitlement.permission === curr) && prev,
            true
        );
    }
}
