// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

filtersModule.filter(
    'transferStatus',
    () =>
        function (input) {
            input = input || '';
            switch (input) {
                case 'ApprovalRejected':
                    return 'Approval Rejected';
                case 'PendingApproval':
                    return 'Pending Approval';
            }
            return input;
        }
);
