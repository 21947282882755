export class TimeParsers {
    static formatTimeFilter(minutesSinceMidnight) {
        // if (minutesSinceMidnight === undefined) return;
        const hours = this.totalHours(minutesSinceMidnight);
        const minutes = this.totalMinutesAfterHourFormatting(minutesSinceMidnight);
        const suffix = this.timeSuffix(hours);
        return this.hoursAndMinutes(hours, minutes, suffix);
    }

    static totalHours(minutes) {
        return Math.floor(minutes / 60);
    }

    static totalMinutesAfterHourFormatting(minutes) {
        return minutes % 60;
    }

    static timeSuffix(hours) {
        return hours < 12 ? 'AM' : 'PM';
    }

    static hoursAndMinutes(hours, minutes, suffix) {
        let hourFormat = hours;
        let minuteFormat = minutes;
        const suffixFormat = suffix;
        if (hours < 1) {
            hourFormat = 12;
        }
        if (hours > 12) {
            hourFormat = hours - 12;
        }
        if (minutes < 10) {
            minuteFormat = `0${minutes}`;
        }

        return `${hourFormat.toString()}:${minuteFormat.toString()} ${suffixFormat.toString()}`;
    }
}

export default TimeParsers;
