/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ErrorHttpContentDto,
    FeatureFlagDataPageDto,
    GetAllFeatureFlagsRequestDto,
    GetAllInstitutionsRequestDto,
    InstitutionCollectionDto,
    SaveFeatureFlagsRequestDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class FeatureFlagsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags FeatureFlags
     * @name FeatureFlagsGetAllInstitutions
     * @summary Gets all the institutions.
     * @request POST:/FeatureFlags/AllInstitutions
     */
    featureFlagsGetAllInstitutions = (
        request: GetAllInstitutionsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InstitutionCollectionDto, ErrorHttpContentDto>({
            path: `/FeatureFlags/AllInstitutions`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags FeatureFlags
     * @name FeatureFlagsGetAllFeatureFlags
     * @summary Gets all the feature flags.
     * @request POST:/FeatureFlags/AllFeatureFlags
     */
    featureFlagsGetAllFeatureFlags = (
        request: GetAllFeatureFlagsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<FeatureFlagDataPageDto, ErrorHttpContentDto>({
            path: `/FeatureFlags/AllFeatureFlags`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags FeatureFlags
     * @name FeatureFlagsSaveFeatureFlags
     * @summary Saves the feature flags.
     * @request POST:/FeatureFlags/SaveFeatureFlags
     */
    featureFlagsSaveFeatureFlags = (
        request: SaveFeatureFlagsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/FeatureFlags/SaveFeatureFlags`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
