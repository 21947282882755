ErrorModalController.$inject = ['$scope', '$modalInstance', 'message', '$sce'];

export default function ErrorModalController($scope, $modalInstance, message, $sce) {
    $scope.message = message;
    $scope.errorMessage = $sce.trustAsHtml($scope.message);

    $scope.close = close;
    // this will dismiss the window.

    function close() {
        $modalInstance.close();
    }
}
