import { CompanyAccountModelDto } from '@treasury/api/channel';

export const nullDebitAccount: CompanyAccountModelDto = {
    showAccountNickName: false,
    name: '',
    number: '',
    account: {
        accountUniqueId: '',
        id: 0,
        number: '',
        type: 'Checking',
        coreStatus: '',
        accountDisplayLabel: '',
        name: '',
    },
    accountDisplayLabel: '',
    accountUniqueId: '',
    allowLoanPaymentPastDue: false,
    availableBalance: 0,
    cifNumber: '',
    collectedBalance: 0,
    currentBalance: 0,
    dailyLimit: 0,
    id: 0,
    isDeleted: false,
    loanPaymentAmountDue: 0,
    loanPaymentDueDate: '',
    perTransactionLimit: 0,
    status: '',
    transferAmount: 0,
    transferMemo: '',
    type: 'Checking',
    updatedBy: '',
    updatedDate: '',
};
