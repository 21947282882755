/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-use-before-define */
/*
    The Time Access duration function is common to Channel and Back Office logout logic.
    userTimeAccessDuration() encapsulates the complex business logic that determines how long a user is allowed to have access
        based on the current FI time now and on the user's time access settings.
    Returns remaining access duration in milliseconds.
*/
import { Time } from '@treasury/alarm-clock/time';
import {
    DayOfWeekDto,
    TimeAccessDto,
    TimeAccessTypeDto,
    UserTimeAccessDayDto,
} from '@treasury/api/channel';
import { createDefaultTimeAccess } from '../../backoffice/mappings/time-access';

export const userTimeAccessDuration = (fiCurrentTime: Time, timeAccessSettings: TimeAccessDto) =>
    timeAccessStartingToday(fiCurrentTime, timeAccessSettings);

export const NEXT_MIDNIGHT = '24:00:00';
export const DEFAULT_BEGIN_TIME = '08:00';
export const DEFAULT_END_TIME = '17:00';

export const timeAccessStartingToday = (fiCurrentTime: Time, timeAccessSettings: TimeAccessDto) => {
    const defaultAccess = 3600000; // 1 hour, in case something goes wrong in reading the user settings, so user isn't totally crippled if they were able to log in to begin with.
    const unrestrictedAccess = 129600000; // 36 hours, not likely to go this long without idle sign-out.
    const timeNow = fiCurrentTime?.toTwentyFourHourString() ?? '00:00:00';
    const currentDayOfWeek: number = fiCurrentTime?.dayOfWeek ?? 0;
    const tomorrowDayOfWeek: number = (currentDayOfWeek + 1) % 7;

    if (!timeAccessSettings?.timeRestricted) return unrestrictedAccess;

    const currentDayTimeAccess = getTimeAccessForDay(timeAccessSettings, currentDayOfWeek);
    const { timeAccessType } = currentDayTimeAccess;
    switch (timeAccessType) {
        case TimeAccessTypeDto.RestrictAllDay:
            return 0; // This will only be reached if the user setting is changed while logged in.
        case TimeAccessTypeDto.TimeRange:
            return getDurationFromDifference(timeNow, currentDayTimeAccess.endTime || '');
        case TimeAccessTypeDto.AllowAllDay:
            return timeAccessIntoTomorrow(
                timeNow,
                timeAccessSettings,
                tomorrowDayOfWeek,
                defaultAccess
            );
        default:
            return defaultAccess;
    }
};

export const timeAccessIntoTomorrow = (
    timeNow: string,
    timeAccessSettings: TimeAccessDto,
    tomorrowDayOfWeek: DayOfWeekDto,
    defaultAccess: number
) => {
    const tomorrowTimeAccess = getTimeAccessForDay(timeAccessSettings, tomorrowDayOfWeek);
    const { timeAccessType } = tomorrowTimeAccess;
    switch (timeAccessType) {
        case TimeAccessTypeDto.RestrictAllDay:
            return getDurationFromDifference(timeNow, NEXT_MIDNIGHT);
        case TimeAccessTypeDto.RestrictAllDay:
            if (tomorrowTimeAccess.beginTime?.startsWith('00:00') && tomorrowTimeAccess.endTime) {
                return getDurationFromDifference(
                    timeNow,
                    nextDayEndTime(tomorrowTimeAccess.endTime)
                );
            }
            return getDurationFromDifference(timeNow, NEXT_MIDNIGHT);
        case TimeAccessTypeDto.AllowAllDay:
            return getDurationFromDifference(timeNow, nextDayEndTime(NEXT_MIDNIGHT));
        default:
            return defaultAccess;
    }
};

export const getDurationFromDifference = (
    currentFiTimeOfDay: string,
    accessExpirationTime: string
) => {
    const endHours = parseHours(accessExpirationTime);
    const endMinutes = parseMinutes(accessExpirationTime) + endHours * 60;
    const endSeconds = endMinutes * 60;
    const beginHours = parseHours(currentFiTimeOfDay);
    const beginMinutes = parseMinutes(currentFiTimeOfDay) + beginHours * 60;
    const beginSeconds = parseSeconds(currentFiTimeOfDay) + beginMinutes * 60;
    if (endSeconds < beginSeconds) return 0;
    return (endSeconds - beginSeconds) * 1000;
};

export const cleanTimeAccessSettings = (settingsForDay: UserTimeAccessDayDto | undefined) => {
    if (!settingsForDay) {
        const noSettings = createDefaultTimeAccess();
        noSettings.timeAccessType = TimeAccessTypeDto.AllowAllDay;
        return noSettings;
    }
    let { beginTime } = settingsForDay;
    if (!beginTime) beginTime = DEFAULT_BEGIN_TIME;
    let { endTime } = settingsForDay;
    if (!endTime) endTime = DEFAULT_END_TIME;
    return { ...settingsForDay, beginTime, endTime };
};

export const getTimeAccessForDay = (timeAccessSettings: TimeAccessDto, dayOfWeek: DayOfWeekDto) => {
    const userDailySettings = timeAccessSettings.userTimeAccessDays || [];
    const settingsForDay =
        userDailySettings.find(item => item.dayOfWeek === dayOfWeek) ?? createDefaultTimeAccess();
    return cleanTimeAccessSettings(settingsForDay);
};

export const parseHours = (timeOfDay: string) => Number.parseInt(timeOfDay.slice(0, 2));

export const parseMinutes = (timeOfDay: string) => Number.parseInt(timeOfDay.slice(3, 5));

export const parseSeconds = (timeOfDay: string) => Number.parseInt(timeOfDay.slice(6, 8));

export const formatTimeUnit = (unit: number) => String(unit).padStart(2, '0');

export const nextDayEndTime = (endTimeOfDay: string) => {
    const endHours = formatTimeUnit(parseHours(endTimeOfDay) + 24);
    const endMinutes = formatTimeUnit(parseMinutes(endTimeOfDay));
    return `${endHours}:${endMinutes}`;
};
