/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

/**
 * Calls associated with the wire => 'Upload Wires' workflow
 */
export class WireUploadRequests {
    /**
     * * This is clearly a call being used elsewhere as common data and doesn't apply
     * * specifically to wire file uploads
     */
    static async getCompanies(nonDli = true) {
        return (await TmHttpClient.getInstance()).request(`wireCompanies?nonDli=${nonDli}`, {
            method: 'GET',
        });
    }

    static async getFileFormats() {
        return (await TmHttpClient.getInstance()).request(`wires/fileFormat`, {
            method: 'GET',
        });
    }

    static async getWireConfiguration() {
        return (await TmHttpClient.getInstance()).request(`wireCompanies/wireConfiguration`, {
            method: 'GET',
        });
    }

    static async getAccountConfiguration() {
        return (await TmHttpClient.getInstance()).request(`account`, {
            method: 'GET',
        });
    }

    static async parseWireFile(file, companyId, formatId) {
        const fd = new FormData();
        const http = await TmHttpClient.getInstance();

        fd.append('wireData', file, file.name);
        fd.append('wireCompanyId', companyId);
        fd.append('formatId', formatId);

        return http.request('wires/parseWireFile', {
            method: 'POST',
            body: fd,
            disableContentType: true,
        });
    }

    static async processWireFile(wireFileId) {
        return (await TmHttpClient.getInstance()).request(`wires/processWireFile/${wireFileId}`, {
            method: 'POST',
        });
    }

    static async submitWires(body) {
        return (await TmHttpClient.getInstance()).request(`wires/initiateBulk`, {
            method: 'POST',
            body,
        });
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default WireUploadRequests;
