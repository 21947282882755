import { getAllAchBanks } from '@treasury/domain/channel/requests/ach/bank-service.js';
import { UsersService } from '@treasury/domain/channel/services/ach';
import { AchBank } from '@treasury/domain/channel/types/ach';
import { MasterListRecipient } from '@treasury/domain/channel/types/ach/api/masterListRecipient.dto';
import { boolean, FdlFieldDefinitions, FieldType, Record } from '@treasury/FDL';
import { Primitives as fieldTypes } from '@treasury/policy';
import {
    achAccountNumber,
    achAccountType,
    achIdNumber,
    achPaymentRecipientName,
} from '@treasury/policy/ach';
import { html } from 'lit';
import '../../../components/channel-status-badge.js';

const isEditable = (record: Record<MasterListRecipient>) => record?.getField('editable');

const isReadonly = (record: Record<MasterListRecipient>) => !isEditable(record);

export const getAchRecipientsColumns = (
    canApprove: boolean,
    canEdit: boolean,
    canDelete: boolean
) => [
    {
        type: 'checkbox',
        label: '',
        field: 'selected',
    },
    {
        label: 'Recipient Name',
        field: 'recipientName',
        type: 'command',
        action: 'selectRecipient',
    },
    {
        label: 'ID Number',
        field: 'recipientIdNumber',
    },
    {
        label: 'Account Number',
        field: 'accountNumber',
    },
    {
        label: 'Account Type',
        field: 'accountTypeDescription',
    },
    {
        label: 'Routing Number',
        field: 'routingNumber',
    },
    {
        label: 'Transaction Type',
        field: 'transactionType',
    },
    {
        label: 'Default Amount',
        field: 'defaultAmount',
    },
    {
        label: '',
        type: 'actions',
        actions: [
            {
                label: 'Addenda',
                action: 'openAddendaDialog',
            },
        ],
    },
    {
        label: 'Status',
        field: 'achMasterRecipientStatusTypeDescription',
        type: 'read-only',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions: [
            {
                label: 'Edit',
                action: 'edit',
                visibleWhen: (record: Record<MasterListRecipient>) => {
                    if (!canEdit) return false;
                    const recipientStatusTypeId = record.getField('achMasterRecipientStatusTypeId');
                    if (recipientStatusTypeId === 1) return false;
                    return !record.getField('editable');
                },
            },
            {
                label: 'Approve',
                action: 'approve',
                visibleWhen: (record: Record<MasterListRecipient>) => {
                    const recipientStatusTypeId = record.getField('achMasterRecipientStatusTypeId');

                    const recipientCanApproveReject = record.getField('canApproveReject');
                    if (recipientStatusTypeId === 1) {
                        return (
                            recipientCanApproveReject && canApprove && !record.getField('editable')
                        );
                    }

                    return false;
                },
            },
            {
                label: 'Reject',
                action: 'reject',
                visibleWhen: (record: Record<MasterListRecipient>) => {
                    const recipientStatusTypeId = record.getField('achMasterRecipientStatusTypeId');

                    const recipientCanApproveReject = record.getField('canApproveReject');
                    if (recipientStatusTypeId === 1) {
                        return (
                            recipientCanApproveReject && canApprove && !record.getField('editable')
                        );
                    }

                    return false;
                },
            },
            {
                label: 'Delete',
                action: 'delete',
                visibleWhen: (record: Record<MasterListRecipient>) => {
                    if (!canDelete) return false;
                    return !record.getField('editable');
                },
            },
            {
                label: 'Save',
                action: 'save',
                visibleWhen: (record: Record<MasterListRecipient>) => {
                    const editable = record.getField('editable');
                    const hasRequiredFields = !record.hasValidationErrors;
                    return editable && hasRequiredFields;
                },
            },
            {
                label: 'Cancel',
                action: 'cancel',
                visibleWhen: (record: Record<MasterListRecipient>) => record.getField('editable'),
            },
        ],
    },
];

const transactionType = new FieldType<string>().with
    .options([
        { text: 'CR', value: 'CR' },
        { text: 'DR', value: 'DR' },
    ])
    .and.defaultValue('CR')
    .and.tag('omega-select')
    .thatIs.readOnlyWhen(isReadonly);

export const achBank = () =>
    new FieldType<number | string>().with
        .options<AchBank>({
            fetch: async (): Promise<AchBank[]> => getAllAchBanks(),
            text: 'bankId',
            value: (record: AchBank) => record.bankId,
        })
        .with.filtering()
        .and.search({
            title: 'Search ACH Companies',
            columns: [
                {
                    label: 'Routing Number',
                    field: 'bankId',
                },
                {
                    label: 'Bank Name',
                    field: 'name',
                },
                {
                    label: 'Bank Address',
                    field: 'city',
                },
            ],
        })
        .with.minColumnWidth(200);

const routingNumber: any = achBank().as.tag('omega-select').thatIs.readOnlyWhen(isReadonly);

export const recipientFields = (): FdlFieldDefinitions<MasterListRecipient> => ({
    selected: boolean.thatIs.disabledWhen((record: any) => record.getField('editable')),
    recipientName: achPaymentRecipientName.as
        .tag('omega-input')
        .thatIs.readOnlyWhen(isReadonly)
        .thatHas.label('Name')
        .thatIs.required(),
    recipientIdNumber: achIdNumber.as.tag('omega-input').thatIs.readOnlyWhen(isReadonly),
    accountNumber: achAccountNumber.as
        .tag('omega-input')
        .thatIs.readOnlyWhen(isReadonly)
        .thatIs.required(),
    accountTypeDescription: achAccountType.as
        .tag('omega-select')
        .with.options([
            { text: 'Checking', value: 'Checking' },
            { text: 'Savings', value: 'Savings' },
            { text: 'General Ledger', value: 'General Ledger' },
            { text: 'Loan', value: 'Loan' },
        ])
        .thatHas.defaultValue('Checking')
        .thatIs.readOnlyWhen(isReadonly)
        .thatIs.required(),
    routingNumber: routingNumber.thatIs.required(),
    transactionType: transactionType.thatIs.required(),
    defaultAmount: fieldTypes.money.as.tag('omega-input').thatIs.readOnlyWhen(isReadonly),
    addenda: fieldTypes.string.as.tag('omega-input').thatIs.readOnlyWhen(isReadonly) as any,
    achMasterRecipientStatusTypeDescription: fieldTypes.string.thatIs
        .readOnly()
        .with.template((status, record) => {
            const component = html`<channel-status-badge
                style="max-width: 350px;"
                .fetchApproversFunction=${async () =>
                    UsersService.fetchApprovers({
                        approvalEntity: 'AchMasterRecipient',
                        productId: record.getField('id'),
                        updatedBy: record.getField('updatedBy'),
                    })}
                .status=${status}
                .approvalsNeeded=${record.getField('requiredApprovals')}
                .approvalsCount=${record.getField('approvalCount')}
            ></channel-status-badge>`;
            component.toString = () => status;
            return component;
        }) as any,
    editable: fieldTypes.boolean.thatIs.visibleWhen(() => false),
});
