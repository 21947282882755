import { html, nothing } from 'lit';
import StandardColumn from './standard-column.js';

// usage: <omega-table-command-column field="accountNumber" label="AccountNumber" action="showAccountBalance"></omega-table-command-column>
// TODO: For some existing designs in TM Channel an optional pop-in comment will be needed to explain what the action does.
export default class CommandColumn extends StandardColumn {
    static get properties() {
        return {
            disableCommand: Boolean,
        };
    }

    constructor(...args) {
        super(...args);
        this.action = args[0].action;
        this.disableCommand = false;
    }

    // eslint-disable-next-line class-methods-use-this
    cellClicked(record, rowIndex, e) {
        if (!this.disableCommand) {
            e.target.dispatchEvent(
                new CustomEvent('action', {
                    bubbles: true,
                    composed: true,
                    detail: { record, rowIndex, field: this.field, action: this.action },
                })
            );
        }
    }

    handleKeyUp(event, record, rowIndex) {
        if (event.key === 'Enter') {
            this.cellClicked(record, rowIndex, event);
        }
    }

    cellClasses() {
        if (this.disableCommand) {
            return super.cellClasses();
        }
        return [...super.cellClasses(), 'link'];
    }

    renderTdContents(record, rowIndex) {
        if (!record) return super.renderContentLoading();
        if (!record.fieldTypeForField(this.field).visible(record)) return nothing;
        if (record.fieldTypeForField(this.field).isDisabled(record)) {
            return html`<span>${record.print(this.field)}</span>`;
        }
        if (!record.fieldTypeForField(this.field).readonly(record)) {
            return html`<omega-field
                .field=${this.field}
                .record=${record}
                hideLabel
            ></omega-field>`;
        }
        return html`
            <span
                class="link-column"
                role="button"
                @click=${e => this.cellClicked(record, rowIndex, e)}
                @keyup=${e => this.handleKeyup(e)}
            >
                ${record.print(this.field)}
            </span>
        `;
    }
}
