export default () => ({
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {
        entityId: '=',
        approvals: '=?',
        availableApprovers: '=?',
        maxApprovals: '=',
        popoverDirection: '=',
        approvalAttributes: '=',
        preloaded: '=',
    },
    template: require('./approvalStatusTemplate.html'),
    controller: ApprovalStatusController,
});

ApprovalStatusController.$inject = [
    '$scope',
    '$timeout',
    '$element',
    'stateChangeService',
    'usersService',
];

function ApprovalStatusController($scope, $timeout, $element, stateChangeService, usersService) {
    $scope.segments = ['inactive', 'inactive', 'inactive'];

    $scope.$watch('approvalAttributes', onApprovalsChange);
    $scope.$watch('maxApprovals', onMaxApprovalsChange);

    $scope.isActive = isActive;
    $scope.isNew = isNew;
    $scope.popoverDirection = $scope.popoverDirection || 'top';
    $scope.getApprovers = getApprovers;

    function isActive(stage) {
        return stage === 'active' || stage === 'new';
    }

    function isNew(stage) {
        return stage === 'new';
    }

    //
    // Events

    function onApprovalsChange() {
        displaySegments();
    }

    function onMaxApprovalsChange() {
        displaySegments();
    }

    if ($scope.preloaded) {
        displaySegments();
    }

    function getApprovers() {
        if (!$scope.approvalsLoading && !$scope.approvalsLoaded) {
            $scope.approvalsLoading = true;
            const request = {
                approvalEntity: $scope.approvalAttributes.approvalEntity,
                amount: $scope.approvalAttributes.amount,
                createdBy: $scope.approvalAttributes.createdBy,
                updatedBy: $scope.approvalAttributes.updatedBy,
                productId: $scope.approvalAttributes.productId,
            };
            usersService.getApprovers(request).then(
                response => {
                    $scope.approvals = response.completedApprovals;
                    $scope.availableApprovers = response.availableApprovers;
                    $scope.approvalsLoaded = true;
                },
                error => {
                    $scope.approvalsLoaded = true;
                }
            );
        }
    }

    //
    // Private

    function displaySegments() {
        if ($scope.preloaded && !$scope.approvalsLoaded) {
            $scope.approvalAttributes = { approvalCount: $scope.approvals.length };
            $scope.approvalsLoaded = true;
        }
        let i;

        if ($scope.maxApprovals === undefined) {
            return;
        }

        if ($scope.approvalAttributes && $scope.approvalAttributes.approvalCount !== undefined) {
            $scope.segments = [];

            for (i = 0; i < $scope.maxApprovals; i += 1) {
                $scope.segments.push('inactive');
            }

            for (i = 0; i < $scope.approvalAttributes.approvalCount; i += 1) {
                $scope.segments[i] = 'active';
            }
        }
    }
}
