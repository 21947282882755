import BalanceReportsService from '@treasury/domain/channel/services/reporting/balance-reports-service.js';

export default class BalanceReportsClient {
    async fetchAccounts() {
        return BalanceReportsService.fetchAccounts();
    }

    async fetchTransactionCodeGroups() {
        return BalanceReportsService.fetchTransactionCodeGroups();
    }

    async fetchReportData(...args) {
        return BalanceReportsService.fetchReportData(...args);
    }

    async fetchReportFilters(...args) {
        return BalanceReportsService.fetchReportFilters(...args);
    }
}
