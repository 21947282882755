import { svg } from 'lit';

export const warningIcon = svg`
<svg viewBox="0 0 23 20"fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="warning-a" maskUnits="userSpaceOnUse" x="0" y="0">
    <path d="M13 1l9.5 16.2c.7 1.2-.3 2.8-1.7 2.8H1.9a1.9 1.9 0 01-1.7-2.8L9.7.9A1.8 1.8 0 0113 1zm-1.4 1.2H11l-9 15.5c0 .2 0 .4.2.4h18.1c.2 0 .3-.2.2-.4l-9-15.5zm-.2 11.1c.9 0 1.6.8 1.6 1.7 0 .9-.7 1.6-1.6 1.6-1 0-1.7-.7-1.7-1.6 0-1 .8-1.7 1.7-1.7zm1-5.8c.3 0 .5.2.5.5l-.3 4.3a.5.5 0 01-.4.5h-1.7a.5.5 0 01-.4-.5L9.8 8c0-.3.2-.5.5-.5h2.2z" fill="#fff"/>
  </mask>
  <g mask="url(#warning-a)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3-4h28v28H-3V-4z" fill="#EC9241"/>
  </g>
</svg>`;
