const totalHours = minutes => Math.floor(minutes / 60);
const totalMinutesAfterHourFormatting = minutes => minutes % 60;
const timeSuffix = hours => (hours < 12 ? 'AM' : 'PM');
const dateFormatted = (hours, minutes, suffix) => {
    let hourFormat = hours;
    let minuteFormat = minutes;
    const suffixFormat = suffix;
    if (hours < 1) {
        hourFormat = 12;
    }
    if (hours > 12) {
        hourFormat = hours - 12;
    }
    if (minutes < 10) {
        minuteFormat = `0${minutes}`;
    }

    return `${hourFormat.toString()}:${minuteFormat.toString()} ${suffixFormat.toString()}`;
};

export const timeIntervals = intervalInMinutes => {
    const intervals = [
        {
            text: '12:00 AM',
            value: {
                formattedTime: '12:00 AM',
                interval: 0,
            },
        },
    ];
    for (let t = intervalInMinutes; t < 24 * 60; t += intervalInMinutes) {
        const hours = totalHours(t);
        const minutes = totalMinutesAfterHourFormatting(t);
        const suffix = timeSuffix(hours);
        intervals.push({
            text: dateFormatted(hours, minutes, suffix),
            value: {
                formattedTime: dateFormatted(hours, minutes, suffix),
                interval: t,
            },
        });
    }
    return intervals;
};
