import { MasterListRecipient } from '@treasury/domain/channel/types/ach/api/masterListRecipient.dto';
import { FdlFieldDefinitions } from '@treasury/FDL';
import { achAccountNumber, achPaymentRecipientName } from '@treasury/policy/ach';
import fieldTypes from '@treasury/policy/primitives';

export const achSelectedRecipientsColumns = () => [
    { type: 'checkbox', label: '', field: 'selected' },
    {
        label: 'Recipient Name',
        field: 'recipientName',
        type: 'read-only',
    },
    {
        label: 'Account Number',
        field: 'accountNumber',
        type: 'read-only',
    },
];

export const achSelectedRecipientsFields = (): FdlFieldDefinitions<MasterListRecipient> => ({
    selected: fieldTypes.boolean,
    recipientName: achPaymentRecipientName.as.tag('omega-input').thatIs.readOnlyWhen(() => true),
    accountNumber: achAccountNumber.as.tag('omega-input').thatIs.readOnlyWhen(() => true),
});
