export enum AnalyticsEvent {
    AchPayment = 'ach-payment',
    AchPaymentApproved = 'ach-payment-creation-approval',
    AchTaxPayment = 'ach-tax-payment',
    AchExceptionDecision = 'ach-exception-decision',
    AchFilterCreated = 'ach-filter-created',
    AchFilterDeleted = 'ach-filter-deleted',
    AchFilterEdited = 'ach-filter-edited',
    AchRecipientsCreated = 'ach-recipients-created',
    AchRecipientsApproved = 'ach-recipients-approved',
    AchTemplateCreated = 'ach-templated-created',
    AchTemplateApproved = 'ach-template-approved',
    BalanceReport = 'balance-report',
    BillPayment = 'bill-payment',
    CheckExceptionReview = 'check-exception-review',
    GetElectronicDocs = 'get-electronic-documents',
    InternationalWireCreated = 'international-wire-created',
    IssuedItemsCreated = 'issued-item-created',
    LogIn = 'log-in',
    LogOut = 'log-out',
    LoanPayment = 'loan-payment',
    LoanApproved = 'loan-approved',
    StopPaymentCreated = 'stop-payment-created',
    StopPaymentApproved = 'stop-payment-approved',
    TransactionReport = 'transaction-report',
    TransferCreated = 'transfer-created',
    TransferApproved = 'transfer-approval',
    TransferTemplateCreated = 'transfer-template-created',
    TransferTemplateApproved = 'transfer-template-approved',
    TransferTemplateRejected = 'transfer-template-rejected',
    UserCreated = 'user-created',
    UserSubmitted = 'user-submitted',
    UserApproved = 'user-approved',
    WireCreated = 'wire-payment',
    WireApproved = 'wire-approved',
    WireBeneficiaryCreated = 'wire-beneficiary-created',
    WireBeneficiaryApproved = 'wire-beneficiary-approved',
    WireTemplateCreated = 'wire-template-created',
    WireTemplateApproved = 'wire-template-approved',
}

type IrDateType = 'Current Day' | 'Prior Day';
type SecCode =
    | 'ARC'
    | 'BOC'
    | 'CCD'
    | 'CIE'
    | 'CTX'
    | 'IAT'
    | 'POP'
    | 'POS'
    | 'PPD'
    | 'RCK'
    | 'TEL'
    | 'WEB'
    | 'XCK';

export type AnalyticsEventPayloads = {
    [AnalyticsEvent.LogIn]: undefined;
    [AnalyticsEvent.LogOut]: undefined;
    [AnalyticsEvent.AchTemplateCreated]: {
        type: 'state' | 'federal';
        secCode: SecCode;
        debitAmount?: number;
        creditAmount?: number;
    };
    [AnalyticsEvent.AchTemplateApproved]: {
        templateId: string;
    };
    [AnalyticsEvent.AchRecipientsCreated]: {
        recipientIds: string[];
    };
    [AnalyticsEvent.AchRecipientsApproved]: {
        recipientIds: string[];
    };
    [AnalyticsEvent.BalanceReport]: {
        dateType: IrDateType;
        count: number;
    };
    [AnalyticsEvent.TransactionReport]: AnalyticsEventPayloads[AnalyticsEvent.BalanceReport];
    [AnalyticsEvent.TransferCreated]: {
        amount: number;
        transactionId: string;
        status: string;
        startDate?: string;
        endDate?: string;
        frequency?: string;
    };
    [AnalyticsEvent.TransferApproved]: {
        id: string;
    };
    [AnalyticsEvent.TransferTemplateCreated]: {
        type: string;
        amount: number;
        frequencyType: string;
    };
    [AnalyticsEvent.TransferTemplateApproved]: {
        id: string;
    };
    [AnalyticsEvent.TransferTemplateRejected]: {
        id: string;
    };
    [AnalyticsEvent.AchExceptionDecision]: {
        amount: number;
        decision: 'pay' | 'return' | 'none';
        exceptionType: 'ACH Debit' | 'ACH Credit';
        secCode: SecCode;
        accountType: 'Checking' | 'Savings';
    };
    [AnalyticsEvent.AchFilterCreated]: {
        fromAmount?: number;
        toAmount?: number;
        isRange: boolean;
        secCode?: SecCode;
        transactionType: 'Credit' | 'Debit';
        allowType: 'Allow' | 'Disallow';
    };
    [AnalyticsEvent.AchFilterEdited]: AnalyticsEventPayloads[AnalyticsEvent.AchFilterCreated];
    [AnalyticsEvent.AchFilterDeleted]: {
        filterId: string;
    };
    [AnalyticsEvent.AchPayment]: {
        companyId: string;
        transactionId: string;
        creditAmount: number;
        debitAmount: number;
        status: string;
    };
    [AnalyticsEvent.AchPaymentApproved]: {
        paymentId: string;
    };
    [AnalyticsEvent.AchTaxPayment]: {
        taxType: 'state' | 'federal';
        amount: number;
        amountType: number;
    };
    [AnalyticsEvent.BillPayment]: undefined;
    [AnalyticsEvent.LoanPayment]: {
        amount: number;
        type: 'regular' | 'principal';
    };
    [AnalyticsEvent.LoanApproved]: {
        id: string;
    };
    [AnalyticsEvent.CheckExceptionReview]: {
        itemCount: number;
        totalAmount: number;
    };
    [AnalyticsEvent.IssuedItemsCreated]: {
        itemCount: number;
        totalAmount: number;
    };
    [AnalyticsEvent.GetElectronicDocs]: {
        product: 'Wausau' | 'Esi' | 'PrintMail' | 'none';
        report: string;
    };
    [AnalyticsEvent.InternationalWireCreated]: AnalyticsEventPayloads[AnalyticsEvent.WireCreated];
    [AnalyticsEvent.StopPaymentApproved]: {
        id: string;
    };
    [AnalyticsEvent.StopPaymentCreated]: {
        id: string;
        accountId: string;
        status: string;
        checkAmount: number;
        approvalsRequiredCount: number;
    };
    [AnalyticsEvent.UserCreated]: {
        id: string;
    };
    [AnalyticsEvent.UserSubmitted]: AnalyticsEventPayloads[AnalyticsEvent.UserCreated];
    [AnalyticsEvent.UserApproved]: AnalyticsEventPayloads[AnalyticsEvent.UserCreated];
    [AnalyticsEvent.WireCreated]: {
        amount: number;
        isDli?: boolean;
        currency: string;
        frequency: string;
    };
    [AnalyticsEvent.WireApproved]: {
        id: string;
    };
    [AnalyticsEvent.WireBeneficiaryCreated]: {
        bankId?: string;
        country?: string;
        isInternational: boolean;
        currencyType?: string;
    };
    [AnalyticsEvent.WireBeneficiaryApproved]: {
        id: string;
    };
    [AnalyticsEvent.WireTemplateCreated]: {
        numberOfApprovalsNeeded: number;
        isInternational: boolean;
        wireCompanyId: string;
    };
    [AnalyticsEvent.WireTemplateApproved]: {
        id: string;
    };
};

export interface UserIdentificationData {
    userId: number;
    isAdmin: boolean;
    isSuperUser: boolean;
    companyId: string;
}

export interface IAnalyticsService {
    init(): void;
    identify(data: UserIdentificationData): void;
    reset(): void;
    track<Event extends AnalyticsEvent>(
        eventName: Event,
        // have to use spread syntax to support the optional parameter on conditional types
        // https://stackoverflow.com/a/52318137/574930
        ...payload: AnalyticsEventPayloads[Event] extends object
            ? [AnalyticsEventPayloads[Event]]
            : [never?]
    ): void;
    trackPageView(route: string): void;
}
