import UserActivityRequests from '../../requests/user-activity/user-activity.js';

export class UserActivityService {
    static async print(activityId, additionalInformation) {
        return UserActivityRequests.print(activityId, additionalInformation);
    }

    static navigationAudit(toState, fromState) {
        return UserActivityRequests.navigationAudit(toState, fromState);
    }

    static async userInteractionAudit(resourceType, auditCode) {
        return UserActivityRequests.userInteractionAudit(resourceType, auditCode);
    }

    static async saveUserActivity(
        userActivityType,
        activityMessage,
        relatedId = null,
        activityDate = null
    ) {
        let activityDateFormatted = activityDate;
        if (activityDate === null) {
            const currentDate = new Date();
            activityDateFormatted = currentDate.toISOString();
        }
        return UserActivityRequests.saveUserActivity(
            userActivityType,
            activityMessage,
            relatedId,
            activityDateFormatted
        );
    }
}
