export default () => ({
    restrict: 'A',
    replace: true,
    scope: {
        rowData: '=',
        isDataLoaded: '=',
        loadingStateText: '@',
        emptyStateText: '@',
    },
    template: require('./emptyStateTemplate.html'),
    controller: EmptyStateController,
});

EmptyStateController.$inject = ['$scope'];

function EmptyStateController($scope) {
    init();

    function init() {
        if (!$scope.emptyStateText) {
            $scope.emptyStateText = 'No records found.';
        }

        if (!$scope.loadingStateText) {
            $scope.loadingStateText = 'Loading Records';
        }
    }
}
