AchExceptionsOpenItemsController.$inject = [
    '$filter',
    '$q',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'achExceptionDecisionStatusTypes',
    'achExceptionListTypes',
    'arpService',
    'companyAccountsService',
    'downloadPageId',
    'secCodesService',
    'toaster',
    'modalService',
    'entitlementsService',
    '$scope',
];

export default function AchExceptionsOpenItemsController(
    $filter,
    $q,
    $state,
    $stateParams,
    $timeout,
    $uibModal,
    achExceptionDecisionStatusTypes,
    achExceptionListTypes,
    arpService,
    companyAccountsService,
    downloadPageId,
    secCodesService,
    toaster,
    modalService,
    entitlementsService,
    $scope
) {
    let canDisplayCurrentData = false;
    const openItems = 'OpenItems';

    const vm = this;
    vm.downloadPageId = downloadPageId.AchExceptionsList;
    vm.maxDate = new Date();
    vm.searchParams = {
        searchType: openItems,
        decisionTaken: 'both',
    };
    vm.downloadOptions = {
        downloadFormats: ['PDF', 'CSV'],
        pageId: vm.downloadPageId,
        filterModel: vm.searchParams,
    };

    vm.exportOptions = { canPrint: true };
    vm.accounts = [];
    vm.secCodes = [];
    vm.amountOptions = ['Specific Amount', 'Amount Range'];
    vm.dateOptions = ['Specific Date', 'Date Range'];
    vm.searchText = '';
    vm.allowEdit = false;
    vm.accountDisplayField = '';
    vm.achExceptionsPristine = {};
    vm.achExceptionsWorking = {};
    vm.filteredExceptions = [];
    vm.isDataLoaded = false;
    vm.alertMessage = null;
    vm.hasEtlRunSuccessfully = null;
    vm.endOfDay = null;
    vm.listType = achExceptionListTypes.openItems;
    vm.achExceptionsCutOffTimes = {};

    vm.search = search;
    vm.filterSearch = filterSearch;
    vm.review = review;

    vm.getDecision = getDecision;
    vm.toggleExpanded = toggleExpanded;
    vm.closeExpanded = closeExpanded;
    vm.showCreateFilterModal = showCreateFilterModal;
    vm.toggleSidebarClosed = toggleSidebarClosed;
    vm.setListToOpenItems = setListToOpenItems;
    vm.goToActivityItems = goToActivityItems;
    vm.goToFilterRules = goToFilterRules;
    vm.hasACHCreateFilter = hasACHCreateFilter;
    vm.reset = reset;
    vm.undoChanges = undoChanges;
    vm.disableDetailDecisionButton = disableDetailDecisionButton;
    vm.isReviewButtonDisabled = isReviewButtonDisabled;
    vm.setDecisionToNoDecision = setDecisionToNoDecision;
    vm.setAllPay = setAllPay;
    vm.setAllReturn = setAllReturn;
    vm.resetAmount = resetAmount;
    vm.resetPostedDate = resetPostedDate;
    vm.allowDataDisplay = allowDataDisplay;
    vm.displayAlertMessage = displayAlertMessage;
    vm.displayEmptyStateDiv = displayEmptyStateDiv;
    vm.tableController = {};
    vm.isACHFilterEnabled = isACHFilterEnabled;

    function getDecision(exception) {
        if (exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.noDecision)
            return 'No Decision';

        if (exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.pay)
            return 'Pay';

        if (exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.return)
            return 'Return';

        return '';
    }

    function toggleExpanded(exception) {
        exception.expanded = !exception.expanded;
    }

    function closeExpanded(exception) {
        exception.expanded = false;
    }

    function isACHFilterEnabled() {
        return entitlementsService.hasEntitlement('Feature.PositivePay.ACHFilterManagement');
    }

    function hasACHCreateFilter() {
        return isACHFilterEnabled() && entitlementsService.hasEntitlement('Create ACH Filter');
    }

    function showCreateFilterModal(exception) {
        const modalOptions = {
            headerText: 'Create a New ACH Filter',
            bodyText: 'Click continue to create a new ACH Filter.',
            actionButtonText: 'Continue',
            closeButtonText: 'Cancel',
            submit(result) {
                $modalInstance.close(result);
                $state.go('payables.arp.ach-filter-workflow-create-from-exception', {
                    exceptionJson: exception,
                    id: exception.id,
                    referringPage: 'payables.arp.ach-exceptions-list',
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function toggleSidebarClosed() {
        vm.sidebarClosed = !vm.sidebarClosed;
    }

    function setListToOpenItems() {
        vm.listType = achExceptionListTypes.openItems;
    }

    function goToActivityItems() {
        $state.go('payables.arp.ach-exceptions-activity-list');
    }

    function goToFilterRules() {
        $state.go('payables.arp.ach-filters');
    }

    function reset() {
        initReset();
    }

    function undoChanges() {
        vm.achExceptionsWorking = angular.copy(vm.achExceptionsPristine);
        filterSearch(vm.searchText);
    }

    function disableDetailDecisionButton() {
        return !vm.allowEdit;
    }

    function isReviewButtonDisabled() {
        return (
            !vm.filteredExceptions.some(exception => exception.achExceptionDecisionStatusId > 0) ||
            !vm.allowEdit
        );
    }

    function setDecisionToNoDecision(exception) {
        exception.achExceptionDecisionStatusId = achExceptionDecisionStatusTypes.noDecision;
        filterSearch(vm.searchText);
    }

    function setAllPay() {
        vm.filteredExceptions.forEach(exception => {
            exception.achExceptionDecisionStatusId = achExceptionDecisionStatusTypes.pay;
        });
        filterSearch(vm.searchText);
    }

    function setAllReturn() {
        vm.filteredExceptions.forEach(exception => {
            exception.achExceptionDecisionStatusId = achExceptionDecisionStatusTypes.return;
        });
        filterSearch(vm.searchText);
    }

    function resetAmount() {
        if (vm.searchParams) {
            vm.searchParams.amount = '';
            vm.searchParams.amountEnd = '';
            vm.searchParams.amountStart = '';
        }
    }

    function resetPostedDate() {
        if (vm.searchParams) {
            vm.searchParams.postedDate = '';
            vm.searchParams.postedDateEnd = '';
            vm.searchParams.postedDateStart = '';
        }
    }

    function allowDataDisplay() {
        const anyData = vm.filteredExceptions.length != null && vm.filteredExceptions.length > 0;
        return anyData && canDisplayCurrentData;
    }

    function displayAlertMessage() {
        return vm.alertMessage;
    }

    function displayEmptyStateDiv() {
        return vm.hasEtlRunSuccessfully;
    }

    function loadCutoffTimes() {
        return companyAccountsService.getCutoffTimesByProductType('AchException').then(response => {
            const timeZone = response.fiTimeZone;
            const timeUntilStart = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.startTime
                }`
            ).diff(response.currentFiTime);
            const timeUntilCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);

            const thirtyMinuteTimeSpan = 1800000;
            const timeUntilThirtyMinutesBeforeCutoff = timeUntilCutoff - thirtyMinuteTimeSpan;
            const startTime = convertTime(response.processingCutoff.startTime);
            const cutoffTime = convertTime(response.processingCutoff.cutoffTime);

            const startAlertMessage = `Exception items are not available at this time.  Please try again after ${startTime} (${timeZone}).`;
            const cutoffAlertMessage = `Exception items will be disabled for decisioning at ${cutoffTime} Cut-Off time (${timeZone}).`;
            const pastCutoffMessage = `Exception items are disabled because the current time is past the ${cutoffTime} Cut-Off time (${timeZone}).`;

            vm.alertMessage = null;
            canDisplayCurrentData = timeUntilStart <= 0;
            vm.allowEdit = timeUntilStart <= 0 && timeUntilCutoff > 0;

            if (timeUntilStart > 0) {
                // If before Start Time:
                vm.alertMessage = startAlertMessage;
                //  set a timed event to enable edits and alert, when the start time has passed.
                $timeout(() => {
                    if (vm.hasEtlRunSuccessfully) {
                        canDisplayCurrentData = true;
                        vm.allowEdit = true;
                        vm.alertMessage = null;
                        toaster.alert(
                            'Past Start Time',
                            'You can now edit decisions for the listed Exceptions.'
                        );
                    }
                }, timeUntilStart);
            }

            if (timeUntilCutoff > 0) {
                // If before Cut-Off time
                //  set a timed event to disable edits and alert, when the Cut-Off Time has passed.
                $timeout(() => {
                    vm.allowEdit = false;
                    vm.alertMessage = pastCutoffMessage;
                    toaster.alert('Past Cut-Off Time', pastCutoffMessage);
                }, timeUntilCutoff);
            } else {
                // If after Cut-Off time
                vm.alertMessage = pastCutoffMessage;
                vm.allowEdit = false;
            }

            if (timeUntilCutoff > 0 && timeUntilCutoff < thirtyMinuteTimeSpan) {
                vm.alertMessage = cutoffAlertMessage;
            } else if (timeUntilThirtyMinutesBeforeCutoff > 0) {
                $timeout(() => {
                    vm.alertMessage = cutoffAlertMessage;
                }, timeUntilThirtyMinutesBeforeCutoff);
            }
        });
    }

    function accountsSelected() {
        return vm.searchParams.accounts && vm.searchParams.accounts.length;
    }

    function search(filteredObject) {
        if (!accountsSelected()) return;

        filteredObject.type = openItems;

        arpService.findAchExceptions(filteredObject).then(response => {
            vm.sidebarClosed = !(
                response.achExceptionDetails == null || response.achExceptionDetails.length === 0
            );

            vm.hasEtlRunSuccessfully = response.hasEtlRunSuccessfully;
            vm.endOfDay = response.isEndOfDayRunning;
            vm.achExceptionsPristine = response;
            vm.achExceptionsWorking = angular.copy(response);
            vm.isDataLoaded = true;

            if (
                vm.achExceptionsWorking &&
                vm.achExceptionsWorking.achExceptionDetails &&
                vm.achExceptionsWorking.achExceptionDetails.length === 1
            ) {
                vm.achExceptionsWorking.achExceptionDetails[0].expanded = true;
            }

            filterSearch(vm.searchText);
            displayAlertMessageWhenEodIsRunningOrSearchReturnsErrors();
            $scope.$apply();
        });
    }

    function displayAlertMessageWhenEodIsRunningOrSearchReturnsErrors() {
        // The function is a place holder. It does not match the requirements
        if ((vm.listType = achExceptionListTypes.openItems)) {
            if (!vm.endOfDay) {
                vm.alertMessage =
                    'Exception items are currently not available for review, please try again later';
                vm.allowEdit = false;
            }
        }
    }

    function initReset() {
        vm.accounts.forEach(account => {
            account.isChecked = true;
        });

        vm.secCodes.forEach(secCode => {
            secCode.isChecked = true;
        });

        vm.searchParams.amountType = null;
        vm.resetAmount();

        vm.searchParams.postedDateType = null;
        vm.resetPostedDate();

        vm.searchParams.description = '';
        vm.searchParams.decisionTaken = 'both';
    }

    function review() {
        const modalInstance = $uibModal.open({
            template: require('../views/achExceptionOpenItemsReviewModalView.html'),
            controller: 'AchExceptionOpenItemsReviewModalController',
            backdrop: 'static',
            resolve: {
                achExceptions() {
                    return vm.filteredExceptions.filter(
                        exception => exception.achExceptionDecisionStatusId > 0
                    );
                },
                achExceptionDecisionStatusTypes() {
                    return achExceptionDecisionStatusTypes;
                },
            },
        });
        modalInstance.result.then(achExceptions => {
            const confirmedDecisions = [];
            achExceptions.forEach(exception => {
                if (
                    exception.achExceptionDecisionStatusId !==
                    achExceptionDecisionStatusTypes.noDecision
                ) {
                    confirmedDecisions.push(exception);
                }
            });
            if (confirmedDecisions.length > 0) {
                const modalOptions = {
                    closeButtonText: 'No',
                    actionButtonText: 'Yes',
                    headerText: 'Confirm Decisions',
                    bodyHeader:
                        'You are about to decision ACH Exception items that can only be decisioned one time.',
                    bodyText: 'Are you sure you would like to proceed?',
                    submit() {
                        $modalInstance.close();

                        arpService.saveAchExceptions(confirmedDecisions).then(response => {
                            $uibModal.open({
                                template: require('../views/achExceptionOpenItemsSaveConfirmationModal.html'),
                                size: 'sm',
                                controller: 'AchExceptionOpenItemsSaveConfirmationModalController',
                                backdrop: 'static',
                                resolve: {
                                    failedDecisionsCount() {
                                        return response.failedDecisionsCount;
                                    },
                                    confirmedDecisionCount() {
                                        return confirmedDecisions.length;
                                    },
                                },
                            });

                            vm.search(vm.searchParams);
                            $state.go('payables.arp.ach-exceptions-activity-list');
                        });
                    },
                };

                var $modalInstance = modalService.showModal({}, modalOptions);
            }
        });
    }

    function loadAccounts() {
        return arpService.getArpAccounts('WorkAchException').then(response => {
            if (response.length === 0) {
                $uibModal
                    .open({
                        template: require('../views/missingAccountsModal.html'),
                        controller: 'MissingAccountsModalController',
                        backdrop: 'static',
                        resolve: {
                            contextSpecificMessage() {
                                return "You don't have any accounts associated with ACH exceptions.";
                            },
                        },
                    })
                    .result.then(() => {
                        $state.go('dashboard');
                    });
                return;
            }

            vm.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            vm.accounts = response;

            if (!$stateParams.accountNumber) {
                vm.accounts.forEach(account => {
                    account.isChecked = true;
                });
                vm.searchParams.accounts = vm.accounts;
            } else if ($stateParams.accountNumber) {
                vm.searchParams.accounts = [];
                vm.accounts.forEach(account => {
                    if (
                        (account.showAccountNickName &&
                            account.name === $stateParams.accountNumber) ||
                        (!account.showAccountNickName &&
                            account.number === $stateParams.accountNumber)
                    ) {
                        account.isChecked = true;
                    } else {
                        account.isChecked = false;
                    }
                });
            }
        });
    }

    function filterSearch(searchText) {
        vm.filteredExceptions =
            $filter('filter')(vm.achExceptionsWorking.achExceptionDetails, searchText) || [];
    }

    function loadSecCodes() {
        return secCodesService.getAll().then(response => {
            if (!(response && response.length)) return;

            vm.secCodes = response.map(secCode => ({
                code: secCode,
                isChecked: true,
            }));

            vm.searchParams.secCodes = vm.secCodes;
        });
    }

    function getAchExceptionsCutOffTimes() {
        return arpService.getAchExceptionsCutOffTimes().then(response => {
            if (!response) return;

            validateEtlSuccess(
                response.currentBusinessDayStartTime,
                response.currentBusinessDayCutoff,
                response.fiLocalTime,
                response.hasEtlRunSuccessfully
            );
        });
    }

    function validateEtlSuccess(
        currentDayStartTime,
        currentDayCutoff,
        fiLocalTime,
        hasEtlRunSuccessfully
    ) {
        if (currentDayStartTime > fiLocalTime || currentDayCutoff < fiLocalTime) {
            return;
        }

        if (!hasEtlRunSuccessfully) {
            vm.alertMessage =
                'Exception items are currently not available for review, please try again later';
            vm.allowEdit = false;
        }
    }

    function convertTime(time) {
        return moment(`${moment().format('l')} ${time}`).format('LT');
    }

    function init() {
        initReset();

        $q.all([
            loadAccounts(),
            loadSecCodes(),
            loadCutoffTimes(),
            getAchExceptionsCutOffTimes(),
        ]).then(() => {
            search(vm.searchParams);
        });
    }

    init();
}
