/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { TmApiError } from '@treasury/domain/shared';
import { number } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import { reportIcon } from '@treasury/omega/css/icons';
import '@treasury/omega/layouts/omega-form';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import {
    AccountReconMessage,
    schema,
    steps,
} from '../data/ach-account-reconciliation-report-workflow.js';

class AchAccountReconciliationReportWorkflow extends mix(LitElement).with(
    ListeningElementMixin,
    channelAlertMixin
) {
    static get properties() {
        return {
            steps: { type: Array },
            schema: { type: Object },
            loading: { type: Boolean },
            alert: { type: Object },
            id: { type: String },
            reportValues: { type: Object },
            form: { type: Object },
            reportTypes: { type: Array },
            displayedReportType: { type: Object },
        };
    }

    async firstUpdated() {
        const { params } = (await getNavService()).getRouteData();

        this.loading = true;
        this.reportValues = params?.reportValues;
        this.createSteps();
        this.schema = schema(this.reportValues);
        this.reportTypes = await AchAccountReconciliationService.fetchReportTypes();
        this.loading = false;
        this.hideRunButton = false;
    }

    createSteps() {
        this.steps = steps(this);
    }

    promptCancellation() {
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'warning',
            message: 'Are you sure you want to cancel? Current progress will be lost.',
            title: 'Cancel',
            posture: 'assertive',
            actions: html`<omega-button @click=${() => this.cancel()}>Ok</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert = {
                            ...this.alert,
                            visible: false,
                        };
                    }}
                    >Continue Editing</omega-button
                >`,
        };
    }

    async cancel() {
        return (await getNavService()).navigate('ir.accountReconciliation');
    }

    shouldShowRunButton() {
        return !this.hideRunButton;
    }

    async submitReport(reportRecord, runReport) {
        this.loading = true;
        const reportValues = reportRecord.values;
        try {
            const saveResponse = await AchAccountReconciliationService.submitReport(reportValues);
            const saveSuccessful = saveResponse.success === true;
            const displayContent = saveSuccessful
                ? {
                      result: 'Saved',
                      message: saveResponse?.id
                          ? AccountReconMessage.SaveMessage
                          : 'Your new custom report was saved successfully.',
                  }
                : {
                      result: 'Not saved',
                      message: saveResponse.errorMessage,
                  };
            if (saveSuccessful && !runReport) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'success',
                    message: displayContent.message,
                    title: displayContent.result,
                    posture: 'polite',
                };
            }
            if (!saveSuccessful) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message: displayContent.message,
                    title: displayContent.result,
                    posture: 'polite',
                };
            }
            reportRecord.addField('id', number, saveResponse.id);
            if (saveSuccessful) {
                if (runReport) {
                    this.createSteps();
                    this.hideRunButton = true;
                    this.runReport(reportRecord.values, true);
                } else {
                    this.form = this.shadowRoot.querySelector('omega-form');
                    this.form.activeStep = 2;
                }
            }
        } catch (e) {
            const message =
                e instanceof TmApiError ? e.message : 'An error occurred. Please try again.';
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
                posture: 'polite',
                title: 'Not Saved',
            };
        } finally {
            this.loading = false;
        }
    }

    async runReport(reportValues, isAfterSave) {
        const reportId = reportValues.id;
        this.loading = true;
        try {
            await AchAccountReconciliationService.runReport(reportId);
            const displayContent = {
                result: 'Submitted',
                message: AccountReconMessage.SaveAndRunMessage,
            };

            this.alert = {
                type: 'success',
                title: displayContent.result,
                message: displayContent.message,
                code: '',
                time: '',
                posture: 'polite',
                visible: true,
            };
            AchAccountReconciliationService.fetchReportFiles();
            if (isAfterSave) {
                this.form = this.shadowRoot.querySelector('omega-form');
                this.form.activeStep = 2;
            }
        } catch (e) {
            if (e instanceof TmApiError) {
                const { message, timestamp: time, errorCode: code } = e;
                this.alert = {
                    type: 'error',
                    title: message,
                    message,
                    code,
                    time,
                    posture: 'polite',
                    visible: true,
                };
            } else {
                this.alert = {
                    type: 'error',
                    title: 'Error',
                    message: 'An error occurred. Please try again.',
                    posture: 'polite',
                    visible: true,
                };
            }
        } finally {
            this.loading = false;
        }
    }

    async viewReportLibrary() {
        (await getNavService()).navigate('ir.accountReconciliation-library');
    }

    async viewReportTemplates() {
        (await getNavService()).navigate('ir.accountReconciliation');
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    renderDisplayReportType() {
        if (!this.displayedReportType) return nothing;
        const referenceId = this.displayedReportType.referenceId
            ? `(${this.displayedReportType.referenceId})`
            : nothing;
        return html`
            <div class="glossary-term">
                <div>${reportIcon}</div>
                <div>
                    <span class="report-type-name">
                        ${this.displayedReportType.displayName || this.displayedReportType.name}
                        ${referenceId}
                    </span>
                    <p>${this.displayedReportType.description}</p>
                </div>
            </div>
        `;
    }

    renderReportDictionary() {
        if (!this.reportTypes) return nothing;
        this.reportTypeOptions = this.reportTypes
            .map(item => ({
                value: item.id,
                text: item.displayName,
            }))
            .sort((a, b) => (a.text > b.text) - (a.text < b.text));
        this.displayedReportType = this.displayedReportType || this.reportTypeOptions[0].id;

        return html`
            <div id="report-dictionary">
                <h4>Account Reconciliation Report Glossary</h4>
                <omega-select
                    .items=${this.reportTypeOptions}
                    .value=${this.displayedReportType?.id}
                    @change=${evt => {
                        this.displayedReportType = this.reportTypes.find(
                            item => item.id === evt.target.value
                        );
                    }}
                ></omega-select>
                ${this.renderDisplayReportType()}
            </div>
        `;
    }

    renderWorkflow() {
        if (!this.schema || !this.steps) return nothing;
        return html`<div id="workflow-container">
            <omega-form .steps=${this.steps} .schema=${this.schema}></omega-form>
        </div>`;
    }

    render() {
        return html`${this.renderAlert()} ${this.renderBlockingLoader()}
            <div id="workflow-wrapper">
                ${this.renderWorkflow()}${this.renderReportDictionary()}
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --omega-field-control-max-width: 350px;
                --omega-field-label-max-width: 200px;
                margin: 0 10%;
            }
            #workflow-wrapper {
                display: flex;
                justify-content: space-between;
            }
            #report-dictionary {
                background-color: #fff;
                box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
                margin-left: 15px;
                padding: 10px;
                width: 330px;
            }
            #workflow-container {
                position: relative;
                flex-grow: 1;
                background-color: #fff;
                box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
            }
            .glossary-term {
                display: flex;
                margin: 20px 5px;
            }

            .glossary-term p {
                margin: 5px 0;
            }
            h4,
            .report-name,
            .report-type-name {
                display: block;
                font-size: 14px;
                color: #646464;
                font-weight: 500;
            }
            omega-workflow {
                height: 100%;
            }
        `;
    }
}

customElements.define(
    'ach-account-reconciliation-report-workflow',
    AchAccountReconciliationReportWorkflow
);

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
