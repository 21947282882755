/* eslint-disable no-multi-assign */
// cspell:ignore uirouter
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-tooltip';
import '@treasury/omega/layouts/omega-report';
import '../shared/templates/report-top.template';

import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { ArpAccountsService, ArpService } from '@treasury/domain/channel/services/arp';
import { DownloadEvent } from '@treasury/domain/channel/services/download/';
import { AccountDto } from '@treasury/domain/channel/types/arp';
import { TmApiError } from '@treasury/domain/shared';
import { Recordset } from '@treasury/FDL';
import { RecordsetEvent, RecordsetFetchArgs } from '@treasury/FDL/recordset';
import { AlertMixin } from '@treasury/omega/mixins';
import {
    LocalFilterEvent,
    OmegaReportDownloadFormat,
    OmegaReportFilter,
    OmegaReportLink,
} from '@treasury/omega/types';
import { columns, createFilters, defaultSearchParams, fields } from './data';
import { IssuedItemsSearchParams, mapIssuedItemsSearchParams } from './models';
import { renderBlockingLoader, renderDownloadDialog, renderReportInfo } from './templates';

type IssuedItemsFilterEvent = LocalFilterEvent<IssuedItemsSearchParams>;
type IssuedItemsDownloadEvent = DownloadEvent<IssuedItemsSearchParams>;

export const IssuedItemsActivityTagName = 'issued-items-activity-container';

@customElement(IssuedItemsActivityTagName)
export class IssuedItemsActivityContainer extends AlertMixin(LitElement) {
    public readonly title = 'Issued Items Activity';

    private readonly reportInfoElement = renderReportInfo(this.title);

    private readonly callToAction = 'Create Issued Items';

    @property({
        type: String,
    })
    public institution!: string;

    private readonly downloadOptions: OmegaReportDownloadFormat[] = ['CSV', 'PDF'];

    private readonly toolbarOptions = ['download', 'print'];

    @state()
    private filters: OmegaReportFilter<IIssuedItem>[] = [];

    private readonly columns = [...columns];

    @state({
        hasChanged: (newValue: Recordset<IIssuedItem>, oldValue: Recordset<IIssuedItem>) => {
            if (!oldValue) {
                return true;
            }

            return newValue.allRecords !== oldValue.allRecords;
        },
    })
    private readonly recordset = new Recordset<IIssuedItem, IssuedItemsSearchParams>(fields, args =>
        this.fetchRecords(args)
    );

    private subs = [
        this.recordset.onChange(() => this.requestUpdate()),
        this.recordset.listen(RecordsetEvent.Error, ({ detail }) => this.onError(detail.error)),
    ];

    private localFilters: IssuedItemsFilterEvent['detail'] = [];

    /**
     * By design, the first fetch uses default search parameters
     * which differ from the initial filter values.
     */
    private isFirstFetch = true;

    @state()
    private isLoading = false;

    @state()
    private isDownloading = false;

    @state()
    private hasError = false;

    @InjectProperty()
    private declare arpService: ArpService;

    @InjectProperty()
    private declare accountsService: ArpAccountsService;

    @InjectProperty()
    private declare navService: NavigationService;

    /**
     * Account number specified via query string parameters for
     * the purposes of auto-populating the filter and search results
     * when navigation to this view.
     *
     * Not certain if this is used in practice,
     * but it's been included in the Lit conversion for parity.
     */
    private async getDefaultAccountNumber() {
        const { params } = await this.navService.getRouteData<{
            accountNumber: string | undefined;
        }>();

        return params.accountNumber;
    }

    /**
     * This is a getter since `institution` is an element property
     * and will be `undefined` at instantiation.
     */
    private get reportLinks() {
        const links: OmegaReportLink[] = [
            {
                title: 'Check Exceptions',
                url: `/${this.institution}/payables/arp/check-exceptions`,
            },
            {
                title: 'Check Exceptions - Decision Activity',
                url: `/${this.institution}/payables/arp/check-exceptions/decision-activity`,
            },
        ];

        return links;
    }

    public async firstUpdated() {
        if (!this.institution) {
            throw new Error(
                `The institution attribute of <${IssuedItemsActivityTagName}> is required.`
            );
        }

        const accounts = await this.accountsService.getArpAccounts('UploadCreateArpFile');
        this.filters = await createFilters(accounts);
        this.setDefaultAccount(accounts);
    }

    public disconnectedCallback() {
        // eslint-disable-next-line wc/guard-super-call
        super.disconnectedCallback();
        this.subs.forEach(sub => sub.unsubscribe());
    }

    /**
     * @param searchParams issued item search params specific by filters
     * @param isFirstSearch Flag indicating if this is the first search. Query will use default parameters if set to `true`.
     * @returns A collection of `IssuedItem` objects.
     */
    private async searchItems(searchParams: IssuedItemsSearchParams, isFirstSearch: boolean) {
        if (isFirstSearch) {
            searchParams = {
                ...searchParams,
                ...defaultSearchParams,
            };
        }

        this.isLoading = true;

        const query = mapIssuedItemsSearchParams(searchParams);
        const issuedItems = await this.arpService.getIssuedItems(query);

        this.isLoading = false;

        return issuedItems;
    }

    private async setDefaultAccount(accounts: AccountDto[]) {
        const defaultAccountNumber = await this.getDefaultAccountNumber();
        if (!defaultAccountNumber) {
            return;
        }

        const accountFilter = this.filters.find(f =>
            f.multiple ? f.fields.includes('accountName') : f.field === 'accountName'
        ) as OmegaReportFilter<IIssuedItem, AccountDto> | undefined;
        if (!accountFilter) {
            return;
        }

        const account = accounts.find(a => a.number === defaultAccountNumber);
        if (!account) {
            return;
        }

        accountFilter.value = [account];
    }

    private goToCreateIssuedItems() {
        this.navService.navigate('payables.arp.create');
    }

    private async fetchRecords(
        fetchArgs: RecordsetFetchArgs<IIssuedItem, IssuedItemsSearchParams>
    ) {
        this.hasError = this.alert.visible = false;
        const { parameters, isFirstFetch } = fetchArgs;
        const issuedItems = await this.searchItems(parameters, isFirstFetch);
        this.isFirstFetch = isFirstFetch;

        return {
            data: issuedItems,
            totalCount: issuedItems.length,
        };
    }

    private async handleDownload({ detail }: IssuedItemsDownloadEvent) {
        const { type, filter } = detail;
        const searchParams = this.isFirstFetch
            ? {
                  ...filter,
                  ...defaultSearchParams,
              }
            : filter;
        const searchDto = mapIssuedItemsSearchParams(searchParams);

        this.isDownloading = true;
        try {
            await this.arpService.downloadIssuedItemsReport(type, searchDto);
        } catch (e) {
            if (e instanceof Error) {
                this.onError(e);
            } else {
                this.alert.message = 'Download failed. Please try again.';
                this.alert.visible = true;
            }
        } finally {
            this.isDownloading = false;
        }
    }

    private onFilter(event: IssuedItemsFilterEvent) {
        const { detail } = event;
        this.localFilters = detail;
    }

    private onError(e: Error) {
        const { alert } = this;
        this.isLoading = false;

        alert.visible = this.hasError = true;
        alert.title = 'Error';
        alert.type = 'error';
        alert.message = e.message;

        if (e instanceof TmApiError) {
            alert.code = e.errorCode.toString();
        }
    }

    private renderReportTop() {
        if (this.hasError) {
            return nothing;
        }

        return html` <issued-items-report-top
            .recordset=${this.recordset}
            .onFilter=${(e: CustomEvent) => this.onFilter(e)}
        ></issued-items-report-top>`;
    }

    public render() {
        // don't render the UI until the accounts request comes back and filters can be constructed
        if (this.filters.length < 1) {
            return nothing;
        }

        return html`
            ${renderBlockingLoader(this.isLoading)}
            ${renderDownloadDialog(this.isDownloading, () => {
                this.isDownloading = false;
            })}

            <omega-report
                flyout
                autostart
                .persistState=${true}
                .reportInformation=${this.reportInfoElement}
                .callToAction=${this.callToAction}
                @callToAction=${() => this.goToCreateIssuedItems()}
                .title=${this.title}
                .columns=${this.columns}
                .recordset=${this.recordset}
                .localFilters=${this.localFilters}
                .options=${this.toolbarOptions}
                .downloadOptions=${this.downloadOptions}
                .downloadFunction=${this.handleDownload}
                @reportDownload=${(event: IssuedItemsDownloadEvent) => this.handleDownload(event)}
                .reportLinks=${this.reportLinks}
                .filters=${this.filters}
            >
                <div id="top-of-table" slot="above-table">
                    ${this.renderAlert()} ${this.renderReportTop()}
                </div>
            </omega-report>
        `;
    }

    static styles = css`
        omega-alert {
            padding: 5px;
        }

        .padded {
            padding: 10px;
        }

        .row {
            display: flex;
            flex-direction: row;
        }
        .row > * {
            flex: auto;
        }
        .full-width {
            width: 100%;
        }
        .right {
            text-align: right;
        }
        .text-center {
            text-align: center;
        }
        .text-right {
            text-align: right;
        }
        .dialog-body {
            padding: 20px;
        }
    `;
}
