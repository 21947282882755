export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                selectionList: '=',
                columnName: '@',
                id: '@',
                placeholder: '@',
                ngRequired: '=',
                includeTemplate: '=',
                type: '@',
                index: '=?',
                ngInvalid: '=?',
                ngDisabled: '=',
            },
            template: require('./typeAheadAchOffsetAccountTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.internalSelectionList = [];

                    $scope.$watch('columnName', newValue => {
                        if (newValue !== null && newValue !== undefined) {
                            $scope.query = `item as item.${$scope.columnName} for item in internalSelectionList`;
                        }
                    });

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.internalSelectionList = angular.copy($scope.selectionList);
                            }
                        },
                        true
                    );

                    $scope.$watch('ngModel', newValue => {
                        if (newValue) {
                            angular.forEach($scope.internalSelectionList, item => {
                                if (
                                    (item.showAccountNickName && item.name === newValue.name) ||
                                    (!item.showAccountNickName && item.number === newValue.number)
                                ) {
                                    $scope.ngModel = item;
                                }
                            });
                        }
                    });
                },
            ],
        };
    },
];
