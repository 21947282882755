import { AccountDto, AchExceptionsQueryDto, SecCodeDto } from '@treasury/domain/channel/types';
import { DateFormatters } from '@treasury/domain/shared/utilities/date-formatters';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import { OmegaNumericValue } from '@treasury/omega/types';

export interface AchExceptionsSearchParams {
    accounts: AccountDto[];
    amount: OmegaNumericValue;
    description: string;
    postedDate: OmegaDate;
    postedDateType: string;
    secCodes: SecCodeDto[];
}

function setAmountProperties(searchParams: AchExceptionsSearchParams) {
    const amounts: OmegaNumericValue = searchParams.amount;
    if (amounts.length === 2) {
        return { amountType: null, amount: '', amountStart: '', amountEnd: '' };
    }
    const amountType = amounts[0] === 'range' ? 'Amount Range' : 'Specific Amount';
    if (amountType === 'Specific Amount') {
        if (amounts[1] === '0' && amounts[2] === 0) {
            // The current amount control doesn't present a no-selection option. So when zero is detected,
            // mimic the no-selection-made behavior from original interface.
            return { amountType: null, amount: '', amountStart: '', amountEnd: '' };
        }
        return { amountType, amount: +amounts[1], amountStart: '', amountEnd: '' }; // Use + to coerce amount to number.
    }
    return { amountType, amount: '', amountStart: +amounts[1], amountEnd: +amounts[2] };
}

function setDateProperties({ postedDate }: AchExceptionsSearchParams) {
    if (postedDate && postedDate.id !== 'no-date-selected') {
        // TODO: remove cast when date formatters are converted to TS
        const parsedDate = DateFormatters.parseDate(postedDate as unknown as Date).value;
        const dateId = postedDate.id;

        if (dateId === 'today' || dateId === 'specific') {
            return {
                postedDateType: 'Specific Date',
                postedDate: parsedDate,
                postedDateStart: '',
                postedDateEnd: '',
            };
        }

        if (postedDate.isRange) {
            return {
                postedDateType: 'Date Range',
                postedDate: '',
                postedDateStart: parsedDate.start,
                postedDateEnd: parsedDate.end,
            };
        }
    }

    return { postedDateType: null, postedDate: '', postedDateStart: '', postedDateEnd: '' };
}

export function mapSearchParams(searchParams: AchExceptionsSearchParams) {
    const { amountType, amount, amountStart, amountEnd } = setAmountProperties(searchParams);
    const { postedDateType, postedDate, postedDateStart, postedDateEnd } =
        setDateProperties(searchParams);
    const body: AchExceptionsQueryDto = {
        amount,
        amountStart,
        amountEnd,
        amountType,
        decisionTaken: 'both',
        description: searchParams.description,
        postedDate,
        postedDateEnd,
        postedDateStart,
        postedDateType,
        searchType: 'OpenItems',
        type: 'OpenItems',
        accounts: searchParams.accounts,
        secCodes: searchParams.secCodes,
        page: 'AchExceptionsList',
    };
    return body;
}
