TaxCodesController.$inject = ['$scope', '$modalInstance', 'taxCodes'];

export default function TaxCodesController($scope, $modalInstance, taxCodes) {
    $scope.searchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.taxCodes = taxCodes;
    $scope.selectTaxCode = selectTaxCode;
    $scope.filterTaxCodes = filterTaxCodes;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function selectTaxCode(tc) {
        $modalInstance.close(tc);
    }

    function filterTaxCodes(tc) {
        return (
            (tc.taxCode !== null &&
                tc.taxCode !== undefined &&
                tc.taxCode.toString().indexOf($scope.searchText) !== -1) ||
            (tc.description !== null &&
                tc.description !== undefined &&
                tc.description.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1)
        );
    }
}
