export default function localeTimeString(value) {
    const date = new Date(value);

    if (!date.getTime() || Number.isNaN(date.getTime())) return 'Invalid Date';

    const result = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    // Standardize whitespace
    return decodeURIComponent(encodeURIComponent(result).replace(/%E2%80%AF/g, '%20'));
}
