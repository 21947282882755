import { ListeningElementMixin } from '@treasury/omega/components';
import { css, html, LitElement, nothing } from 'lit';
import '../parts/ach-international-payment-header.js';
import '../parts/ach-international-payment-recipients-table.js';

const ListeningElement = ListeningElementMixin(LitElement);

class AchInternationalReviewStep extends ListeningElement {
    static get properties() {
        return {
            loading: Boolean,
            paymentHeaderRecord: Object,
            activeStep: Number,
            fields: Array,
            recipientsRecordset: Object,
            recipients: Array,
            canContinue: Boolean,
            template: Boolean,
        };
    }

    constructor() {
        super();
        this.loading = false;
    }

    // review updated usage
    updated() {
        if (this.paymentHeaderRecord) {
            this.listenTo(this.paymentHeaderRecord, 'change', () => {
                this.canContinueToNextStep();
            });
        }
    }

    canContinueToNextStep() {
        if (this.paymentHeaderRecord) {
            this.canContinue =
                this.paymentHeaderRecord.isValid() && this.paymentHeaderRecord.hasRequiredValues();
        }
    }

    next() {
        this.dispatchEvent(
            new CustomEvent('next', {
                detail: {
                    paymentHeader: this.paymentHeaderRecord,
                    recipients: this.recipientsRecordset,
                },
            })
        );
    }

    previous() {
        this.dispatchEvent(new CustomEvent('previous'));
    }

    cancel() {
        window.history.go(-1);
    }

    renderTitle() {
        return this.template ? 'Review Template' : 'Review Payment';
    }

    render() {
        if (this.activeStep === 1) {
            return html`
                <div class="omega-form-header">
                    <h2 class="step-title">${this.renderTitle()}</h2>
                </div>
                <ach-international-payment-header
                    expanded
                    .fields=${this.fields}
                    .paymentHeaderRecord=${this.paymentHeaderRecord}
                    .recipientsRecordset=${this.recipientsRecordset}
                    .activeStep=${this.activeStep}
                    .template=${this.template}
                ></ach-international-payment-header>
                <ach-international-payment-recipients-table
                    .recipients=${this.recipients}
                    .editable=${false}
                    .recipientsRecordset=${this.recipientsRecordset}
                ></ach-international-payment-recipients-table>
                <omega-button-bar position="bottom" alignment="left">
                    <omega-button @click=${this.next} .disabled=${!this.canContinue} type="primary"
                        >Confirm</omega-button
                    >
                    <omega-button type="secondary" @click=${this.previous}>Back</omega-button>
                    <omega-button
                        type="secondary"
                        @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                        >Cancel</omega-button
                    >
                </omega-button-bar>
            `;
        }
        return nothing;
    }

    static get styles() {
        return css`
            .omega-form-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .step-title {
                margin: 0;
            }
        `;
    }
}

customElements.define('ach-international-review-step', AchInternationalReviewStep);
