import { css, html, LitElement } from 'lit';

export class OmegaIconError extends LitElement {
    render() {
        return html` <svg
            width="30px"
            height="30px"
            viewBox="0 0 30 30"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <title>icons/failure/outline/normal</title>
            <defs>
                <path
                    d="M10,0 C15.5224597,0 20,4.47915323 20,10 C20,15.5240726 15.5224597,20 10,20 C4.47754032,20 0,15.5240726 0,10 C0,4.47915323 4.47754032,0 10,0 Z M10,2.00573066 C5.58519699,2.00573066 2.00573066,5.58648639 2.00573066,10 C2.00573066,14.4160924 5.58519699,17.9942693 10,17.9942693 C14.414803,17.9942693 17.9942693,14.4160924 17.9942693,10 C17.9942693,5.58648639 14.414803,2.00573066 10,2.00573066 Z M9.9713467,12.0916905 C9.02186371,12.0916905 8.252149,12.8614053 8.252149,13.8108883 C8.252149,14.7603712 9.02186371,15.530086 9.9713467,15.530086 C10.9208297,15.530086 11.6905444,14.7603712 11.6905444,13.8108883 C11.6905444,12.8614053 10.9208297,12.0916905 9.9713467,12.0916905 Z M8.63351204,10.7769513 C8.64739366,11.0322407 8.88335282,11.2320917 9.13830252,11.2320917 L10.7852794,11.2320917 C11.0402291,11.2320917 11.2891438,11.0322407 11.3030254,10.7769513 L11.553378,5.3213639 C11.5683797,5.04561605 11.3512058,4.81375358 11.0758137,4.81375358 L8.87079714,4.81375358 C8.59540507,4.81375358 8.35953935,5.04561605 8.3745411,5.3213639 L8.63351204,10.7769513 Z"
                    id="path-1"
                ></path>
            </defs>
            <g
                id="icons/failure/outline/normal"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g id="Color" transform="translate(5.000000, 5.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <use
                        id="warning_icon"
                        fill="#BF0000"
                        fill-rule="nonzero"
                        xlink:href="#path-1"
                    ></use>
                    <rect
                        fill="#BF0000"
                        mask="url(#mask-2)"
                        x="-4"
                        y="-4"
                        width="28"
                        height="28"
                    ></rect>
                </g>
            </g>
        </svg>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}

customElements.define('omega-icon-error', OmegaIconError);
