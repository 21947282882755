import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';

/**
 * View model representing a selectable issued item in a match list.
 */
export class IssuedItemMatchVm {
    // eslint-disable-next-line no-useless-constructor
    constructor(public readonly item: IIssuedItem) {}

    public selected = false;
}
