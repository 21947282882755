/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import { FiDate } from '../../../dates';
import {
    mapTransferActivityParameters,
    mapUpdateTransferParamsToRequest
} from '../../mappings/transfers/map-transfer-request-params.js';
import AchConfigurationServices from '../../services/ach/ach-configuration-services.js';
import DownloadService from '../../services/download/download-service.js';

/**
 *
 * Static class grouping calls associated with the transfers => 'Transfer Activity'
 */
export class TransferActivityRequests {
    /**
     * This report doesn't currently support pagination so we'll supplement with the comparator and faux pagination
     */
    static async fetchTransferActivity({ parameters }) {
        const body = mapTransferActivityParameters(parameters);
        const http = await TmHttpClient.getInstance();
        const data = await http.request(`internalTransfers/FilterTransfers`, {
            method: 'POST',
            body,
        });

        return {
            data,
            totalCount: data.length,
        };
    }

    static async fetchTransferAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `companyaccounts/GetWithProductFeature?type=To&productfeaturetype=InternalTransfer`,
            {
                method: 'GET',
                maxAgeInSeconds: 600000,
            }
        );
    }

    static async fetchAchConfig(...args) {
        const configuration = await AchConfigurationServices.getConfiguration(...args);
        return {
            cutoffTimes: configuration.cutoffTimes,
            holidays: configuration.holidays,
            allowSameDayPayments: configuration.isSameDayAchEnabled,
        };
    }

    /**
     * @template T The shape of the fetched data.
     * @param {'cancel' | 'approve' | 'reject' | 'resubmit'} action: Action named for the request.
     * The strings `'cancel'`, `'approve'`, `'reject'`, and `'resubmit'` are valid values.
     * @param {{comments:string, internalTransferId:string}} config
     * @returns {Promise<FetchWithAuthentication<T>>}
     */
    static async updateTransferStatus(action, parameters) {
        const { securityActionType, endpoint, data } = mapUpdateTransferParamsToRequest(
            action,
            parameters
        );

        const method = action === 'resubmit' ? 'PUT' : 'POST';
        const http = await TmHttpClient.getInstance();
        return http.request(`internalTransfers/${endpoint}`, {
            method,
            body: {
                ...data,
                securityMessage: {
                    actionType: `${securityActionType}Transfer_ByName`,
                    errorCode: null,
                    hasAlternate: false,
                    message: null,
                    methodUsed: null,
                    oneTimePassword: null,
                    status: null,
                },
            },
        });
    }

    static async downloadTransfers(outputType, page, filterModel) {
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        const mappedDownloadRequestFilters = mapTransferActivityParameters(filterModel);
        const downloadFormats = [outputType];
        return DownloadService.download(
            fileSuffix,
            outputType,
            page,
            { ...mappedDownloadRequestFilters, page },
            downloadFormats
        );
    }
}
