import { LitElement, html, css } from 'lit';

class OmegaIconWarningOutline extends LitElement {
    render() {
        return html`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
                <path d="M15 18c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1s1 .4 1 1v5c0 .6-.4 1-1 1z" />
                <circle cx="15" cy="20" r="1" />
                <path
                    d="M15 5c.3 0 .6.2.7.5l8.7 17.4c.3.5-.1 1.2-.7 1.2H6.3c-.6 0-1-.6-.7-1.2l8.7-17.4c.1-.3.4-.5.7-.5m0-2c-1.1 0-2 .6-2.5 1.6L3.8 21.9c-.4.9-.4 1.9.1 2.7.5.9 1.4 1.4 2.4 1.4h17.4c1 0 1.9-.5 2.4-1.3s.6-1.9.1-2.7L17.5 4.6C17 3.6 16.1 3 15 3z"
                />
            </svg>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}
customElements.define('omega-icon-warning-outline', OmegaIconWarningOutline);
