ImportRecipientsModalController.$inject = [
    '$scope',
    '$modal',
    '$modalInstance',
    'modalService',
    'achRecipientsService',
];

/**
 * @typedef { ReturnType<import('../../../../services/achRecipientsSrvc').AchRecipientsService>} AchRecipientsService
 */

/**
 *
 * @param { ng.IScope} $scope
 * @param { AchRecipientsService } achRecipientsService
 */
export default function ImportRecipientsModalController(
    $scope,
    $modal,
    $modalInstance,
    modalService,
    achRecipientsService
) {
    $scope.recipientData = null;
    $scope.fileUid = null;
    $scope.format = 'delimited';

    $scope.canUpload = canUpload;
    $scope.cancel = cancel;
    $scope.onRemoveFile = onRemoveFile;
    $scope.onSelectFile = onSelectFile;
    $scope.setFormat = setFormat;
    $scope.upload = upload;

    init();

    function init() {
        $scope.recipientData = {
            file: null,
        };
    }

    function setFormat(format) {
        $scope.format = format;
    }

    function canUpload() {
        return $scope.recipientData.file;
    }

    function upload() {
        achRecipientsService.create($scope.recipientData, $scope.format).then(response => {
            if (response.errorMessage) {
                loadErrorModal(response.errorMessage);
            } else {
                $modalInstance.close(response);
            }
        });
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel import recipient?',
                submit(result) {
                    $modalInstance1.dismiss();
                    $modalInstance.dismiss();
                    $scope.recipientData = {
                        file: null,
                    };
                },
            };
            var $modalInstance1 = modalService.showModal({}, modalOptions);
        } else {
            $scope.recipientData = {
                file: null,
            };

            $modalInstance.dismiss();
        }
    }

    //
    // Events

    function onSelectFile(e) {
        if (e.files) {
            $scope.recipientData.file = e.files[0].rawFile;
            $scope.fileUid = e.files[0].uid;
            $scope.form.$dirty = true;
            $scope.$apply();
        }
    }

    function onRemoveFile(e) {
        if ($scope.fileUid === e.files[0].uid) {
            $scope.recipientData.file = null;
            $scope.fileUid = null;
            $scope.$apply();
        }
    }

    function loadErrorModal(errorMessage) {
        const modalInstance = $modal.open({
            template: require('../views/batchErrorModalTemplate.html'),
            size: 'lg',
            controller: 'ErrorModalController',
            backdrop: 'static',
            resolve: {
                message() {
                    return errorMessage;
                },
            },
        });

        modalInstance.result.then(() => {
            $scope.recipientData.file = null;
            $scope.fileUid = null;
        });
    }
}
