import { svg } from 'lit';

export const successIcon = svg`
<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icons/success/outline</title>
    <defs>
        <path d="M14,3 C7.92485081,3 3,7.92485081 3,14 C3,20.0751492 7.92485081,25 14,25 C20.0751492,25 25,20.0751492 25,14 C25,7.92485081 20.0751492,3 14,3 Z M14,5.12903226 C18.902629,5.12903226 22.8709677,9.09661694 22.8709677,14 C22.8709677,18.902629 18.9033831,22.8709677 14,22.8709677 C9.09737097,22.8709677 5.12903226,18.9033831 5.12903226,14 C5.12903226,9.09737097 9.09661694,5.12903226 14,5.12903226 M20.2187258,10.907004 L19.2191452,9.89935081 C19.0121411,9.69066129 18.6751331,9.68928629 18.4664435,9.89633468 L12.1967984,16.1155927 L9.54473387,13.4420161 C9.33772984,13.2333266 9.00072177,13.2319516 8.79203226,13.4389556 L7.78433468,14.4385363 C7.57564516,14.6455403 7.57427016,14.9825484 7.78131855,15.1912823 L11.8078952,19.2504597 C12.0148992,19.4591492 12.3519073,19.4605242 12.5605968,19.2534758 L20.215754,11.65975 C20.4243992,11.4527016 20.4257298,11.1156935 20.2187258,10.907004 L20.2187258,10.907004 Z" id="success-path-1"></path>
    </defs>
    <g id="Colors-/-Styles-/-Typography-/-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons" transform="translate(-85.000000, -84.000000)">
            <g id="Group" transform="translate(81.000000, 80.000000)">
                <g id="Color" transform="translate(1.000000, 1.000000)">
                    <mask id="success-mask-2" fill="white">
                        <use xlink:href="#success-path-1"></use>
                    </mask>
                    <use id="success_icon" fill="#02A12E" fill-rule="nonzero" xlink:href="#success-path-1"></use>
                    <rect fill="#34AA44" mask="url(#success-mask-2)" x="0" y="0" width="28" height="28"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>`;
