import { Record } from '@treasury/FDL';
import '@treasury/FDL/form-element';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { ListeningElementMixin } from '../components';
import './form-components.index';
import './omega-tooltip.js';

const omegaField2TagName = 'omega-field-2';
const ListeningElement = ListeningElementMixin(LitElement);

class OmegaField2<T> extends ListeningElement {
    @property({ type: String })
    public field!: keyof T;

    @property({ type: Object })
    public record!: Record<T>;

    renderInfoIcon() {
        const iconMessageSettings = this.record.fieldTypeForField(this.field).iconMessage();
        if (typeof iconMessageSettings === 'string') {
            return html`<omega-tooltip
                icon="info-circle"
                message=${iconMessageSettings}
                direction="top-right"
            ></omega-tooltip>`;
        }
        if (iconMessageSettings) {
            return html`<omega-tooltip
                icon="info-circle"
                direction=${iconMessageSettings.direction ?? 'top-right'}
                ?light=${false}
            >
                <div slot="content">${iconMessageSettings.message}</div>
            </omega-tooltip>`;
        }
        return nothing;
    }

    render() {
        return html`<form-element .field=${this.field} .record=${this.record}>
            <span slot="label">${this.renderInfoIcon()}</span>
        </form-element>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define(omegaField2TagName, OmegaField2);
export default OmegaField2;
