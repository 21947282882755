export const isValidDate = dateModel => {
    const today = new Date();
    const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
    checkDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (dateModel.isWeekend()) return true;
    if (checkDate > today) return true;
    return false;
};

export const isFutureDate = dateModel => {
    const today = new Date();
    const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
    checkDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (checkDate > today) return true;
    return false;
};

export const isPastDate = dateModel => {
    const today = new Date();
    const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
    checkDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (checkDate < today) return true;
    return false;
};

export const isCurrentCentury = dateModel => {
    const today = new Date();
    const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
    checkDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (checkDate < today) return true;
    if (checkDate.getFullYear() >= today.getFullYear() + 100) return true;
    return false;
};

export const disableWeekendsAndFutureDates = {
    dateDisabledFunction: isValidDate,
};

export const disableFutureDates = {
    dateDisabledFunction: isFutureDate,
};

export const disablePastDates = {
    dateDisabledFunction: isPastDate,
};

export const disablePastDatesAndFutureCenturies = {
    dateDisabledFunction: isCurrentCentury,
};
