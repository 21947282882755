import { getChannelAPIPath } from '@treasury/core/http';

NotificationsService.$inject = ['$resource', '$http'];

export default function NotificationsService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}notifications/`;

    return {
        getRecent,
        getAll,
        getProductTypes,
        setRead,
        archive,
        remove,
    };

    function getRecent() {
        return $resource(
            `${resourceUrl}recent?html=true`,
            {},
            { get: { method: 'GET', isArray: false } }
        ).get(true).$promise;
    }

    function getAll() {
        return $resource(`${resourceUrl}?html=true`).query().$promise;
    }

    function getProductTypes() {
        return $resource(`${resourceUrl}productTypes`).query().$promise;
    }

    function setRead(ids, isRead) {
        let lookups;
        let resource;
        lookups = getLookupList(ids);
        resource = $resource(
            `${resourceUrl}setRead?value=:value`,
            { value: isRead },
            { update: { method: 'PUT' } }
        );
        return resource.update(lookups).$promise;
    }

    function archive(ids) {
        let lookups;
        let resource;
        lookups = getLookupList(ids);
        resource = $resource(`${resourceUrl}archive`, null, { update: { method: 'PUT' } });
        return resource.update(lookups).$promise;
    }

    function remove(ids) {
        const lookups = getLookupList(ids);
        return $http({
            url: resourceUrl,
            method: 'DELETE',
            data: lookups,
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
        });
    }

    //
    // Private

    function getLookupList(ids) {
        const lookups = [];
        ids.forEach(id => {
            lookups.push({ id });
        });
        return lookups;
    }
}
