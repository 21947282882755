/**
 * @typedef { ReturnType<import('../../../../services/achRecipientsSrvc').AchRecipientsService>} AchRecipientsService
 */

/**
 *
 * @param { ng.IScope } $scope
 * @param { AchRecipientsService } achRecipientsService
 * @param { ng.IFilterService } $filter
 */

export default function RecipientsActivityListController(
    $scope,
    achRecipientsService,
    $state,
    $filter,
    regexConstants
) {
    $scope.dateOptions = null;
    $scope.filterObject = {};
    $scope.amountOptions = null;
    $scope.limitedBatches = false;
    $scope.limitedPayments = false;
    $scope.recipients = null;
    $scope.resetSearch = resetSearch;
    $scope.search = search;
    $scope.goToDetails = goToDetails;

    $scope.filteredRecipients = {};
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.searchObj = { text: null };
    $scope.recipientNameRegex = regexConstants.AlphaNumericPlusSpecialCharsRegex;
    $scope.message = '';
    $scope.$watch('sidebarContainerController', checkExpandFilter);

    $scope.hasInputValue = function () {
        return (
            $scope.filterObject.recipientName.length > 0 ||
            $scope.filterObject.amount ||
            $scope.filterObject.amountMin ||
            $scope.filterObject.amountMax ||
            $scope.filterObject.preNote ||
            $scope.filterObject.hold ||
            $scope.filterObject.reversal
        );
    };
    function checkExpandFilter() {
        if ($scope.sidebarContainerController) {
            $scope.sidebarContainerController.setCollapsed(false);
        }
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredRecipients = $filter('filter')($scope.recipients, $scope.searchObj.text);
    }

    function disableFilter() {
        return $scope.recipients == null || $scope.recipients.length === 0;
    }

    function init() {
        $scope.amountOptions = ['Specific Amount', 'Range'];
        resetSearch();
        $scope.message =
            'Find specific recipients by entering your search criteria in the filters, then click search.';
    }

    function resetSearch() {
        if ($scope.form) {
            $scope.form.$setPristine();
        }

        $scope.filterObject = {};
        $scope.filterObject.recordType = 'Both';
        $scope.filterObject.amountType = $scope.amountOptions[0];
        $scope.filterObject.recipientName = '';
    }

    function search() {
        achRecipientsService.search(prepareFilter($scope.filterObject)).then(response => {
            if (response.achRecipientRecords.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.recipients = response.achRecipientRecords;
            $scope.limitedBatches = response.limitedBatches;
            $scope.limitedPayments = response.limitedPayments;
            $scope.searchObj.text = null;
            $scope.filterSearch($scope.searchObj.text);
        });
    }

    function goToDetails(recipient) {
        if (recipient.recordType === 'Batch') {
            $scope.setUploadedBatch(null);
            $state.go('payables.ach.batch-detail', {
                id: recipient.recordId,
                type: 'recipient',
            });
        } else if (recipient.recordType === 'Payment') {
            $state.go('payables.ach.payment-detail', {
                id: recipient.recordId,
                type: 'recipient',
            });
        }
    }

    function prepareFilter(filterObject) {
        const obj = Object.assign(filterObject);
        const fields = ['amount'];
        fields.forEach(field => {
            const fieldType = `${field}Type`;
            if (!obj[field] && (!obj[fieldType] || obj[fieldType].indexOf('Specific') !== -1)) {
                delete obj[field];
                delete obj[fieldType];
            }
        });

        return obj;
    }

    init();
}

RecipientsActivityListController.$inject = [
    '$scope',
    'achRecipientsService',
    '$state',
    '$filter',
    'regexConstants',
];
