import { Injectable } from '@jack-henry/frontend-utils/di';
import TermsAndConditionsRequests from '../../requests/account/terms-and-conditions.js';

@Injectable()
export default class TermsAndConditionsService {
    static getTermsAndConditions() {
        return TermsAndConditionsRequests.get(false);
    }

    getTermsAndConditions() {
        return TermsAndConditionsRequests.get(false);
    }

    static checkTermsAndConditions() {
        return TermsAndConditionsRequests.get(true);
    }

    static acceptTermsAndConditions() {
        return TermsAndConditionsRequests.accept();
    }
}
