import { html } from 'lit';

export default {
    title: 'Verify Phone Number',

    renderBody(context) {
        return html`
            <style>
                p {
                    margin: 0px 0px 18px;
                }
                input {
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    width: calc(100% - 20px);
                    margin-bottom: 18px;
                    padding: 8px;
                }
            </style>
            <p>
                ${context.verificationMethod.aMessageWasSentToYourDevice} We need to verify your
                identity for the security of the account. Please enter your One Time Password below.
            </p>
            <div>
                <input
                    type="text"
                    aria-label="one time password"
                    @input=${e => {
                        context.oneTimePassword = e.target.value;
                    }}
                />
            </div>

            <div>Phone number: ${context.phoneNumber}</div>
        `;
    },

    renderActions(context) {
        return html`<omega-button
                type="primary"
                @click=${context.verify}
                .loading=${context.isLoading}
                .disabled=${!context.isOneTimePasswordValid}
                >Verify Number</omega-button
            >
            <omega-button type="link" @click=${context.close}>Cancel</omega-button>`;
    },
};
