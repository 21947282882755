ArpAccountsController.$inject = ['$scope', '$modalInstance', 'companyAccounts', '$timeout'];

export default function ArpAccountsController($scope, $modalInstance, companyAccounts, $timeout) {
    $scope.accountSearchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.companyAccounts = companyAccounts;
    $scope.selectAccount = selectAccount;
    $scope.filterAccounts = filterAccounts;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function selectAccount(account) {
        $modalInstance.close(account);
    }

    function filterAccounts(account) {
        if (account.availableBalance !== undefined && account.availableBalance != null)
            return (
                account.number.toString().indexOf($scope.accountSearchText) !== -1 ||
                account.name
                    .toString()
                    .toLowerCase()
                    .indexOf($scope.accountSearchText.toString().toLowerCase()) !== -1 ||
                account.availableBalance.toString().indexOf($scope.accountSearchText) !== -1
            );
        return (
            account.number.toString().indexOf($scope.accountSearchText) !== -1 ||
            account.name
                .toString()
                .toLowerCase()
                .indexOf($scope.accountSearchText.toString().toLowerCase()) !== -1
        );
    }
}
