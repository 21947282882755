export default [
    '$filter',
    '$locale',
    '$window',
    function ($filter, $locale, $window) {
        return {
            require: 'ngModel',
            scope: {
                min: '=min',
                max: '=max',
                defaultValue: '@',
                noDecimalPoints: '=?',
                ngRequired: '=ngRequired',
                noCurrencySymbol: '=?',
                noComma: '=?',
                roundValue: '=?',
            },
            link(scope, element, attrs, ngModel) {
                if (scope.defaultValue !== '') scope.defaultValue = scope.defaultValue || '$0.00';

                if (scope.noDecimalPoints === null || scope.noDecimalPoints === undefined)
                    scope.noDecimalPoints = false;
                if (scope.noCurrencySymbol === null || scope.noCurrencySymbol === undefined)
                    scope.noCurrencySymbol = false;
                if (scope.noComma === null || scope.noComma === undefined) scope.noComma = false;
                if (scope.roundValue === null || scope.roundValue === undefined)
                    scope.roundValue = false;
                element.bind('click', function () {
                    if (!$window.getSelection().toString()) {
                        this.setSelectionRange(0, this.value.length);
                    }
                });

                function decimalRex(dChar) {
                    return RegExp(`\\d|\\${dChar}`, 'g');
                }

                function clearRex(dChar) {
                    return RegExp(`((\\${dChar})|([0-9]{1,}\\${dChar}?))&?[0-9]{0,2}`, 'g');
                }

                function decimalSepRex(dChar) {
                    return RegExp(`\\${dChar}`, 'g');
                }

                function clearValue(value) {
                    // if the first character is a decimal period we prepend 0.
                    if (value !== null && value !== undefined && value.substring(0, 1) === '.')
                        value = `0${value}`;

                    value = String(value);
                    const dSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                    let clear;

                    if (value.match(decimalSepRex(dSeparator))) {
                        clear = value
                            .match(decimalRex(dSeparator))
                            .join('')
                            .match(clearRex(dSeparator));
                        clear = clear ? clear[0].replace(dSeparator, '.') : null;
                    } else if (value.match(decimalSepRex('.'))) {
                        clear = value.match(decimalRex('.')).join('').match(clearRex('.'));
                        clear = clear ? clear[0] : null;
                    } else {
                        clear = value.match(/\d/g);
                        clear = clear ? clear.join('') : null;
                    }

                    return clear;
                }

                ngModel.$parsers.push(viewValue => {
                    if (viewValue == undefined) return '';
                    const transformedInput = viewValue.replace(/[^0-9,.]/g, '');
                    if (transformedInput !== viewValue) {
                        ngModel.$setViewValue(transformedInput);
                        ngModel.$render();
                    }
                    return transformedInput;
                });

                ngModel.$parsers.push(viewValue => {
                    let cVal = clearValue(viewValue);
                    if (scope.noDecimalPoints && cVal != null) {
                        const splitResult = cVal.split('.');
                        if (splitResult.length > 0) {
                            cVal = splitResult[0];
                        }
                    }
                    return parseFloat(cVal) > scope.max ? scope.max : parseFloat(cVal);
                });

                element.on('blur', () => {
                    element.val(getFormattedValue(ngModel.$modelValue));
                });

                ngModel.$formatters.unshift(value => getFormattedValue(value));

                function getFormattedValue(value) {
                    if (value === null || isNaN(value) || value === undefined) {
                        if (scope.defaultValue !== '' && isNaN(scope.defaultValue)) {
                            scope.defaultValue = '0.00';
                        }
                        value = scope.defaultValue;
                    }

                    if (value === '') return '';

                    if (scope.roundValue && !scope.noDecimalPoints) {
                        value = Math.round(value);
                    }
                    let currencyValue = $filter('currency')(value);
                    if (scope.noDecimalPoints) {
                        const splitResult = currencyValue.split('.');
                        currencyValue = splitResult[0];
                    }
                    if (scope.noCurrencySymbol) {
                        currencyValue = currencyValue.substring(1, currencyValue.length);
                    }
                    if (scope.noComma) {
                        currencyValue = currencyValue.split(',').join('');
                    }
                    return currencyValue;
                }

                scope.$watch(
                    () => ngModel.$modelValue,
                    newValue => {
                        runValidations(newValue);
                    }
                );

                scope.$watch(
                    () => scope.min,
                    newValue => {
                        if (newValue !== null && newValue !== undefined)
                            runValidations(ngModel.$modelValue);
                    }
                );

                function runValidations(cVal) {
                    if (!scope.ngRequired && isNaN(cVal)) {
                        return;
                    }
                    if (scope.min != null) {
                        const min = parseFloat(scope.min);
                        ngModel.$setValidity('min', cVal >= min);
                    }
                    if (scope.max != null) {
                        const max = parseFloat(scope.max);
                        ngModel.$setValidity('max', cVal <= max);
                    }
                }
            },
        };
    },
];
