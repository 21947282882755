/**
 * Recipients are shared between the template creation workflow and the payment creation workflow
 * in ACH International. We will refactor that table here to use the same table across 3 steps in 2 workflows.
 * we'll need to account for the variations on the table that exist between the steps and 2 workflows via props
 */

import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/omega-filter-bar';
import './ach-international-addenda-dialog.js';
import { boolean } from '@treasury/policy/primitives';
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import { ListeningElementMixin } from '@treasury/omega/components';
import { recipientDetails } from './ach-international-payment-recipient-details.js';
import { columns } from '../data/recipient-recordset.js';
import '../../components/ach-payment-recipients-table.js';

const ListeningElement = ListeningElementMixin(LitElement);
class AchInternationalPaymentRecipientsTable extends ListeningElement {
    static get properties() {
        return {
            recipientsRecordset: Object,
            recipients: Array,
            editable: { type: Boolean, reflect: true },
            tableFilters: Array,
            holidays: Array,
            selectedRecord: Object,
            hasListeners: Boolean,
        };
    }

    constructor() {
        super();
        this.editable = true;
        this.recipients = null;
        this.filters = [
            {
                field: 'prenote',
                fieldType: boolean.with.label('Prenote Only'),
                value: false,
            },
            {
                field: 'hold',
                fieldType: boolean.with.label('Hold Only'),
                value: false,
            },
            {
                field: '#invalid',
                fieldType: boolean.with.label('Errors'),
                value: false,
            },
        ];
        this.hasListeners = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this, 'openAddenda', ({ detail }) => {
            this.selectedRecord = detail;
        });
        this.columnDefinitions = columns(this.editable);
    }

    firstUpdated() {
        this.addEventListeners();
    }

    updated(changedProperties) {
        if (changedProperties.has('recipientRecordset')) {
            this.addEventListeners();
        }
    }

    addEventListeners() {
        if (this.recipientsRecordset) {
            // page-changed occurs when recordset update occurs or record is deleted or inserted
            this.listenTo(this.recipientsRecordset, 'page-changed', () => {
                this.recipientsRecordset.allRecords[
                    this.recipientsRecordset.allRecords.length - 1
                ].isExpanded = true;
                this.dispatchEvent(
                    new CustomEvent('changed', {
                        composed: true,
                        bubbles: true,
                        detail: this.recipientsRecordset,
                    })
                );
            });
            // updated occurs when record is added or copied at recordset
            this.listenTo(this.recipientsRecordset, 'updated', () => {
                this.dispatchEvent(
                    new CustomEvent('updated', {
                        composed: true,
                        bubbles: true,
                        detail: this.recipientsRecordset,
                    })
                );
            });
            this.hasListeners = true;
        }
    }

    renderAddendaDialog() {
        const addenda = this.selectedRecord?.getField('addenda');
        const editable = this.editable || this.selectedRecord?.getField('nachaUpload');

        return html`<ach-international-addenda-dialog
            .selectedRecord=${this.selectedRecord}
            .addenda=${addenda}
            .editable=${editable}
            @close=${() => {
                this.selectedRecord = null;
            }}
        ></ach-international-addenda-dialog>`;
    }

    renderRecipientDetails(record, close) {
        return recipientDetails(record, close, this.editable);
    }

    render() {
        if (this.recipientsRecordset) {
            if (!this.hasListeners) this.addEventListeners();
            return html`
                ${this.renderAddendaDialog()}
                <omega-filter-bar
                    .recordset=${this.recipientsRecordset}
                    .filters=${this.filters}
                    @change=${e => {
                        this.tableFilters = e.detail;
                    }}
                ></omega-filter-bar>
                <omega-table
                    .recordset=${this.recipientsRecordset}
                    .columnDefinitions=${this.columnDefinitions}
                    .filters=${this.tableFilters}
                    @load=${e => {
                        e.detail.records.forEach(record => {
                            if (record.hasErrors() || record.getField('step') === 0)
                                record.isExpanded = true;
                        });
                    }}
                    .detailRowTemplateFunction=${(record, close) =>
                        this.renderRecipientDetails(record, close)}
                >
                </omega-table>
            `;
        }
        return nothing;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                    --bottom-offset: -15px;
                }
            `,
        ];
    }
}

customElements.define(
    'ach-international-payment-recipients-table',
    AchInternationalPaymentRecipientsTable
);
