IpRestrictController.$inject = ['$scope', 'usersService', 'toaster', '$window', 'securityService'];

export default function IpRestrictController(
    $scope,
    usersService,
    toaster,
    $window,
    securityService
) {
    $scope.ipRestrictions = null;
    $scope.ipRestrictionsCurrent = null;
    $scope.ipRestrictionsPending = null;
    $scope.ipRestrictionsPristine = null;
    $scope.restricted = { value: false };
    $scope.restrictedPristine = {};
    $scope.restrictedCurrent = {};
    $scope.restrictedPending = {};

    $scope.form = null;
    $scope.validation = {
        octetRegExp: /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])$/,
        optionalOctetRegExp: /^$|^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])$/,
        cidrRegExp: /^$|^([0-9]|[1-2][0-9]|3[0-2])$/,
    };

    $scope.setForm = setForm;
    $scope.canAddRow = canAddRow;
    $scope.isLastRow = isLastRow;
    $scope.canDeleteRow = canDeleteRow;
    $scope.deleteRow = deleteRow;
    $scope.addRow = addRow;
    $scope.save = save;
    $scope.reset = reset;
    $scope.toggleIpRestrictions = toggleIpRestrictions;
    $scope.getRestrictedLabel = getRestrictedLabel;

    $scope.$watch('ipRestrictionsCurrent', updateFormModel);
    $scope.$watch('ipRestrictionsPending', updateFormModel);
    $scope.$watch('compareMode', updateFormModel);
    $scope.$watch('editMode', updateFormModel);
    $scope.$watch('userHeader', updateFormModel);

    init();

    function init() {
        usersService.getIpRestrictions($scope.id).then(response => {
            if (response) {
                $scope.ipRestrictionsCurrent = response.ipRestrictions;
                if ($scope.ipRestrictionsCurrent.length <= 0) {
                    $scope.ipRestrictionsCurrent.push({
                        octet1: null,
                        octet2: null,
                        octet3: null,
                        octet4: null,
                        name: null,
                    });
                }
                $scope.ipRestrictionsPending = response.ipRestrictionsPending;
                $scope.restrictedCurrent = response.restricted;
                $scope.restrictedPending = response.restrictedPending;
                $scope.ipRestrictionsPristine = $window.angular.copy(
                    $scope.ipRestrictionsPending || $scope.ipRestrictionsCurrent
                );
                $scope.restrictedPristine = $window.angular.copy(
                    $scope.restrictedPending || $scope.restrictedCurrent
                );
            }
        });
    }

    function getRestrictedLabel(showCurrent) {
        let result;
        if (showCurrent === true) {
            result = $scope.restrictedCurrent ? 'Restricted' : 'Unrestricted';
        } else {
            result = $scope.restricted.value ? 'Restricted' : 'Unrestricted';
        }
        return result;
    }

    function toggleIpRestrictions() {
        $scope.ipRestrictions.enabled = !$scope.ipRestrictions.enabled;
        $scope.form.$setDirty(true);
    }

    function canDeleteRow() {
        return $scope.editMode && $scope.restricted.value;
    }

    function canAddRow(index) {
        return $scope.editMode && $scope.form.$valid && isLastRow(index) && $scope.restricted.value;
    }

    function addRow() {
        $scope.ipRestrictions.push({
            octet1: null,
            octet2: null,
            octet3: null,
            octet4: null,
            name: null,
        });
    }

    function deleteRow(index) {
        $scope.form.$setDirty(true);
        $scope.ipRestrictions.splice(index, 1);
        if ($scope.ipRestrictions.length <= 0) {
            $scope.ipRestrictions.push({
                octet1: null,
                octet2: null,
                octet3: null,
                octet4: null,
                name: null,
            });
        }
    }

    function isLastRow(index) {
        return $scope.ipRestrictions.length - 1 === index;
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        const sentRestrictions = angular.copy($scope.ipRestrictions);
        const lastRow = sentRestrictions.slice(-1)[0];
        if (lastRow.octet1 == null && lastRow.octet2 == null) {
            sentRestrictions.pop();
        }

        const restrictionsPayload = {
            ipRestrictions: sentRestrictions,
            securityMessage: null,
        };

        securityService
            .verifyUser('Edit User', restrictionsPayload, () =>
                usersService.updateIpRestrictions(
                    $scope.id,
                    $scope.restricted.value,
                    restrictionsPayload
                )
            )
            .then(response => {
                if (response.success) {
                    $scope.ipRestrictionsPristine = window.angular.copy($scope.ipRestrictions);
                    $scope.ipRestrictionsPending = window.angular.copy($scope.ipRestrictions);
                    $scope.restrictedPristine = window.angular.copy($scope.restricted.value);
                    $scope.restrictedPending = window.angular.copy($scope.restricted.value);
                    $scope.$parent.notifySave();
                    $scope.form.$setPristine();
                    toaster.save('User IP Access');
                }
            });
    }

    function reset() {
        $scope.ipRestrictions = angular.copy($scope.ipRestrictionsPristine);
        $scope.restricted.value = angular.copy($scope.restrictedPristine);
        if ($scope.ipRestrictions.length <= 0) {
            $scope.ipRestrictions.push({
                octet1: null,
                octet2: null,
                octet3: null,
                octet4: null,
                name: null,
            });
        }
        $scope.form.$setPristine();
    }

    function updateFormModel() {
        if ($scope.ipRestrictionsCurrent && $scope.ipRestrictionsPending) {
            if ($scope.showPending) {
                $scope.restricted.value = angular.copy($scope.restrictedPending);
                $scope.ipRestrictions = angular.copy($scope.ipRestrictionsPending);
            } else {
                $scope.restricted.value = angular.copy($scope.restrictedCurrent);
                $scope.ipRestrictions = angular.copy($scope.ipRestrictionsCurrent);
            }
            if ($scope.ipRestrictions.length <= 0) {
                $scope.ipRestrictions.push({
                    octet1: null,
                    octet2: null,
                    octet3: null,
                    octet4: null,
                    name: null,
                });
            }
        }
    }
}
