VerifySecurityMethodModalController.$inject = [
    '$scope',
    '$timeout',
    '$modalInstance',
    'securityService',
    'spinnerService',
    'method',
    'methodType',
    'existingPinNumber',
    'otherVerificationStatus',
    'usePin',
    'challengeMethodType',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function VerifySecurityMethodModalController(
    $scope,
    $timeout,
    $modalInstance,
    securityService,
    spinnerService,
    method,
    methodType,
    existingPinNumber,
    otherVerificationStatus,
    usePin,
    challengeMethodType
) {
    $scope.bodyText = null;
    $scope.checkboxText = '';
    $scope.existingPinNumber = existingPinNumber;
    $scope.otherVerificationStatus = otherVerificationStatus;
    $scope.headerText = null;
    $scope.method = method || {};
    $scope.methodType = methodType;
    $scope.state = 'Edit';
    $scope.tempData = { useForBoth: false, preferredMethodType: methodType };
    $scope.usePin = usePin && challengeMethodType !== '3';

    $scope.addOrEdit = addOrEdit;
    $scope.addOrEditOther = addOrEditOther;
    $scope.cancel = cancel;
    $scope.close = close;
    $scope.hasBothMethods = hasBothMethods;
    $scope.hasExistingPinNumber = hasExistingPinNumber;
    $scope.isEditable = isEditable;
    $scope.isMethodType = isMethodType;
    $scope.verify = verify;
    $scope.challengeMethodType = challengeMethodType;
    $scope.oneTimePassword = null;
    $scope.resend = resend;

    $scope.$watch('state', onStateChange);

    init();

    function init() {
        let phoneNumber;
        let phoneNumberPieces;

        if ($scope.method.phoneNumber) {
            phoneNumber = $scope.method.phoneNumber;
            phoneNumberPieces = [
                phoneNumber.slice(0, 3),
                phoneNumber.slice(3, 6),
                phoneNumber.slice(6, 10),
            ];
            $scope.tempData.phone1 = phoneNumberPieces.length > 0 ? phoneNumberPieces[0] : null;
            $scope.tempData.phone2 = phoneNumberPieces.length > 1 ? phoneNumberPieces[1] : null;
            $scope.tempData.phone3 = phoneNumberPieces.length > 2 ? phoneNumberPieces[2] : null;
        }

        if ($scope.existingPinNumber) {
            $scope.tempData.pinNumber = $scope.existingPinNumber;
        }

        $scope.$watch('tempData.phone1', onPhone1Change);
        $scope.$watch('tempData.phone2', onPhone2Change);
    }

    function verify() {
        $scope.method.method = $scope.methodType;
        $scope.method.phoneNumber =
            $scope.tempData.phone1 + $scope.tempData.phone2 + $scope.tempData.phone3;
        $scope.method.pinNumber = $scope.tempData.pinNumber;

        $scope.state = 'Verify';
        const onResponse = function (response) {
            const isVerifying =
                $scope.challengeMethodType === '3' && !$scope.tempData.oneTimePassword;
            if (response.status === 'Success') {
                $scope.state = isVerifying ? 'Verifying' : 'Success';
            } else if (response.status === 'Error') {
                $scope.state = 'Error';
            }
            $scope.responseMessage = response.message;
        };
        if ($scope.challengeMethodType === '3' && !!$scope.tempData.oneTimePassword) {
            securityService
                .validateOneTimePassword($scope.tempData.oneTimePassword.trim())
                .then(onResponse);
        } else {
            securityService
                .verifyMethod($scope.method, $scope.tempData.useForBoth)
                .then(onResponse);
        }
    }

    function close(showNext) {
        if (hasBothMethods()) {
            securityService
                .setPreferredMethod($scope.tempData.preferredMethodType)
                .then(response => {
                    $modalInstance.close({
                        pinNumber: $scope.method.pinNumber,
                        showNext,
                    });
                });
        } else {
            $modalInstance.close({ pinNumber: $scope.method.pinNumber, showNext });
        }
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function hasExistingPinNumber() {
        return !!$scope.existingPinNumber;
    }

    function isEditable() {
        return $scope.state === 'Edit' || $scope.state === 'Error';
    }

    function isMethodType(methodType) {
        return methodType === $scope.methodType;
    }

    function hasBothMethods() {
        return $scope.otherVerificationStatus === 'Verified' || $scope.tempData.useForBoth;
    }

    function addOrEdit() {
        return $scope.method.phoneNumber === undefined ? 'Add' : 'Edit';
    }

    function addOrEditOther() {
        return $scope.otherVerificationStatus === 'DoesNotExist' ? 'Add' : 'Edit';
    }

    function resend() {
        $scope.tempData.oneTimePassword = null;
        this.verify();
    }

    //
    // Events

    function onStateChange(newValue) {
        switch (newValue) {
            case 'Edit':
                $scope.headerText = `${addOrEdit()} Phone Number`;
                if ($scope.methodType === 'Text') {
                    $scope.bodyText =
                        'Receive a text message (SMS) and reply to verify your identity.';
                    $scope.checkboxText = 'Use same number for automated phone calls.';
                } else if ($scope.methodType === 'Call') {
                    $scope.bodyText = 'Receive an automated phone call to verify your identity.';
                    $scope.checkboxText = 'Use same number for text message (SMS).';
                }
                break;

            case 'Verify':
                $scope.headerText = 'Verify Phone Number';
                if ($scope.methodType === 'Text') {
                    $scope.bodyText = 'A text message (SMS) was sent to your phone.';
                } else if ($scope.methodType === 'Call') {
                    $scope.bodyText = 'A phone call was made to the number provided below.';
                }
                if ($scope.challengeMethodType === '3') {
                    $scope.bodyText +=
                        ' We need to verify your identity. For the security of the account, please enter your One-Time Passcode below.';
                } else {
                    $scope.bodyText +=
                        ' Reply to the prompt to continue. You must reply within 60 seconds before the verification expires.';
                }
                break;

            case 'Success':
            case 'Error':
                $scope.headerText = 'Verify Phone Number';
                $scope.bodyText = null;
                break;
            default:
                break;
        }
    }

    function onPhone1Change(newValue, oldValue) {
        if (newValue === oldValue) {
            return;
        }
        $scope.tempData.phone1Valid = newValue && newValue.length === 3;
    }

    function onPhone2Change(newValue, oldValue) {
        if (newValue === oldValue) {
            return;
        }
        $scope.tempData.phone2Valid = newValue && newValue.length === 3;
    }
}
