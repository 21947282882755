export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                exportOptions: '=',
                downloadOptions: '=?',
                includeCreateNewTransfer: '=?',
                canCreateNew: '=?',
                includeRefresh: '=?',
                onRefreshClick: '&',
                additionalPrintAuditInformation: '&?',
            },
            template: require('./exportOptionBarTemplate.html'),
            controller: [
                '$scope',
                '$modal',
                '$state',
                function ($scope, $modal, $state) {
                    $scope.gotoNewTransfer = function () {
                        $state.go('payables.transfers');
                    };
                    $scope.canDownload = function () {
                        return (
                            $scope.downloadOptions != null &&
                            $scope.downloadOptions.downloadFormats != null &&
                            $scope.downloadOptions.downloadFormats.length > 0 &&
                            !$scope.downloadOptions.disabled
                        );
                    };

                    $scope.hideDownload = function () {
                        return !!$scope.downloadOptions.hideDownload;
                    };

                    $scope.$watch('downloadOptions', $scope.canDownload());

                    $scope.canPrint = function () {
                        return (
                            !!$scope.exportOptions &&
                            !!$scope.exportOptions.canPrint &&
                            !$scope.downloadOptions.disabled
                        );
                    };

                    $scope.refreshClicked = function () {
                        $scope.onRefreshClick();
                    };

                    $scope.$watch(
                        'exportOptions',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.exportOptions = newValue;
                                if ($scope.includeCreateNewTransfer)
                                    $scope.exportOptions.downloadOptions = ['CSV', 'PDF'];
                                else if (
                                    $scope.downloadOptions === null ||
                                    $scope.downloadOptions === undefined
                                ) {
                                    $scope.exportOptions.downloadOptions = [
                                        'CSV',
                                        'PDF',
                                        'BAI',
                                        'TIFF',
                                        'Word',
                                        'Quicken (QFX)',
                                        'Quickbooks (QBO)',
                                    ];
                                } else
                                    $scope.exportOptions.downloadOptions = $scope.downloadOptions;

                                $scope.exportOptions.isOpen = false;
                            }
                        },
                        true
                    );
                },
            ],
        };
    },
];
