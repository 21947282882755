/* eslint-disable no-use-before-define */
import { ErrorType } from '@treasury/domain/channel/mappings/security';
import { html, nothing } from 'lit';
import { IdentityDialogStrategy } from '.';
import { IdentityVerificationDialog } from '../identity-verification-dialog';

export class Oob implements IdentityDialogStrategy {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly context: IdentityVerificationDialog) {}

    public async validateAccountPassword(password: string) {
        throw new Error('Validating account password is not valid for legacy OOB authentication.');
        return {
            success: false,
        };
    }

    public renderContent() {
        const { securityMessage } = this.context;
        if (!securityMessage) {
            return nothing;
        }

        const locked = securityMessage?.errorCode === ErrorType.Locked;
        const isVerify =
            !securityMessage?.errorCode || securityMessage?.errorCode === ErrorType.Verify;
        const { sendMessage } = securityMessage;

        // eslint-disable-next-line no-negated-condition
        return !locked
            ? html`<div class="caption">
                  <p><b>Please check your phone and reply to the prompt</b></p>
                  ${sendMessage ? html`<p>${sendMessage}</p>` : nothing}
                  <p>
                      We need to ensure your identity for the security of your account. Please check
                      your phone and reply to the prompt.
                  </p>
                  ${isVerify
                      ? html`<omega-progress card class="large-loader"></omega-progress>`
                      : nothing}
              </div>`
            : nothing;
    }

    public renderActions() {
        const { securityMessage, verifying, verify, cancel } = this.context;
        const errorCode = securityMessage?.errorCode;

        if (!errorCode || errorCode === ErrorType.Verify) {
            return nothing;
        }

        const boundVerify = verify.bind(this.context);
        const canRetry = securityMessage?.errorCode !== ErrorType.Locked;
        const securityPrompt =
            !securityMessage.methodUsed || securityMessage.methodUsed === 'Text'
                ? 'Call me instead'
                : 'Text me instead';

        return html`<div name="actions">
            ${canRetry
                ? html`
                      <omega-button
                          type="secondary"
                          .loading=${verifying}
                          .disabled=${verifying}
                          @click=${verify}
                      >
                          ${!securityMessage.methodUsed || securityMessage.methodUsed === 'Text'
                              ? 'Resend text message'
                              : 'Call again'}
                      </omega-button>
                      ${securityMessage.hasAlternate
                          ? html` <omega-button
                                type="secondary"
                                .loading=${verifying}
                                .disabled=${verifying}
                                @click=${boundVerify}
                            >
                                ${securityPrompt}
                            </omega-button>`
                          : nothing}
                  `
                : nothing}
            ${!this.context.disableClose
                ? html`<omega-button type="link" @click=${cancel}>
                      ${canRetry ? 'Cancel' : 'Close'}
                  </omega-button>`
                : nothing}
        </div>`;
    }
}
