/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping calls associated with and shared in the Reporting Module.
 */
class InformationReportingRequests {
    // fetch all reports
    static async fetchReports() {
        return (await TmHttpClient.getInstance()).request(`informationreports`, { method: 'GET' });
    }

    // favorite report
    static async favoriteReport({ reportId, isFavorite }) {
        (await TmHttpClient.getInstance()).request(
            `informationreports/${reportId}/favorite?value=${isFavorite}`,
            {
                method: 'GET',
            }
        );
    }

    // delete report
    static async deleteReport(id) {
        (await TmHttpClient.getInstance()).request(`informationreports/${id}`, {
            method: 'DELETE',
        });
    }

    // report filters
    static async fetchReportFilters(id) {
        return (await TmHttpClient.getInstance()).request(`informationreports/${id}`, {
            method: 'GET',
        });
    }

    // standard reports
    static async fetchStandardReports() {
        return (await TmHttpClient.getInstance()).request(`informationreports/standard`, {
            method: 'GET',
        });
    }

    // custom saved reports
    static async fetchSavedReports() {
        return (await TmHttpClient.getInstance()).request(`informationreports/myreport`, {
            method: 'GET',
        });
    }
}

export default InformationReportingRequests;
