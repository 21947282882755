/* eslint-disable max-classes-per-file */
import { PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import '@treasury/omega/components/omega-dialog';
import { DialogUtility } from '@treasury/omega/util/dialog-utility';
import { css, html, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';

const tag = 'addenda-dialog';

const util = new DialogUtility(tag);

export class AddendaDialog extends LitElement {
    @property({ type: Object })
    record!: Record<PaymentRecipient>;

    @property({ type: Boolean })
    readonly!: boolean;

    @state()
    previousAddenda = '';

    static open(context: Element) {
        util.openFromParentContainer(context);
    }

    close() {
        util.close(this);
    }

    handleOpen() {
        if (!this.record) return;
        const addenda = this.record.getField('addenda');
        this.previousAddenda = addenda ?? '';
    }

    cancel() {
        this.record.setField('addenda', this.previousAddenda);
        this.close();
    }

    get displayAddenda() {
        if (!this.record) return false;
        const addenda = this.record.getField('addenda');
        const hasValue = !!addenda && addenda.toString().trim().length > 0;
        const editable = !this.readonly;

        return editable || hasValue;
    }

    renderAddendaActions() {
        if (this.readonly) {
            return html`<omega-button slot="actions" type="primary" @click=${() => this.cancel()}
                >Close</omega-button
            >`;
        }
        return html` <omega-button slot="actions" type="primary" @click=${() => this.close()}
                >Save</omega-button
            >
            <omega-button slot="actions" type="link" @click=${() => this.cancel()}
                >Cancel</omega-button
            >`;
    }

    renderAddenda() {
        if (!this.displayAddenda) {
            return html`<h3>There is no addenda to display</h3>`;
        }
        return html`<omega-field
            id="addenda"
            field="addenda"
            .readOnly=${this.readonly}
            .record=${this.record}
        ></omega-field>`;
    }

    render() {
        return html`
            <omega-dialog
                id=${util.dialogId}
                dialogTitle="Addenda"
                @open=${() => this.handleOpen()}
                @close=${() => this.close()}
            >
                <div slot="content">
                    <div class="dialog-content">${this.renderAddenda()}</div>
                    <hr />
                </div>
                ${this.renderAddendaActions()}
            </omega-dialog>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
customElements.define(tag, AddendaDialog);
