import { AchExceptionDetailDataDto, AchExceptionDetailModelDto } from '@treasury/api/channel';
import { PayOrReturnChoice } from '../channel/mappings/ach';
import { DomainEntity } from '../shared';
import { ExceptionDecisionStatus, defaultAchExceptionModelDto } from './data';

export class AchException extends DomainEntity<AchExceptionDetailDataDto> {
    public originalDecisionStatus: string | undefined;

    constructor(dto: AchExceptionDetailDataDto) {
        super(dto);
        this.dto.decisionStatus = this.getDecisionStatusFromString(this.dto.decisionStatus);
        this.originalDecisionStatus = this.getDecisionStatusFromString(this.dto.decisionStatus);
    }

    public createDefault(): this {
        return new AchException(defaultAchExceptionModelDto) as this;
    }

    public getDecisionStatusFromString(str?: string) {
        switch (str?.toLocaleLowerCase()) {
            case 'pay':
                return ExceptionDecisionStatus.Pay;
            case 'return':
                return ExceptionDecisionStatus.Return;
            default:
                return ExceptionDecisionStatus.NA;
        }
    }

    get decisionStatus() {
        return this.dto.decisionStatus;
    }

    get entryDescription() {
        return this.dto.entryDescription;
    }

    get achExceptionDetailId() {
        return this.dto.achExceptionDetailId;
    }

    get accountId() {
        return this.dto.accountId;
    }

    get accountName() {
        return this.dto.accountName;
    }

    get accountNumber() {
        return this.dto.accountNumber;
    }

    get amount() {
        return this.dto.amount;
    }

    get achCompanyName() {
        return this.dto.achCompanyName;
    }

    get decisionStatusId() {
        return this.dto.achExceptionDecisionStatusId;
    }

    set decisionStatusId(val: PayOrReturnChoice) {
        this.dto.achExceptionDecisionStatusId = val;
    }

    get secCode() {
        return this.dto.secCode;
    }

    public get decisionChoice(): PayOrReturnChoice {
        return this.decisionStatusId ? this.decisionStatusId : PayOrReturnChoice.Unselected;
    }

    public toAchExceptionDetailModelDto(): AchExceptionDetailModelDto {
        return {
            ...this.dto,
            id: this.achExceptionDetailId,
            updatedBy: undefined,
            accountId: this.accountId.toString(),
            amount: this.amount ?? 0,
        };
    }

    public pay() {
        this.dto.decisionStatus = ExceptionDecisionStatus.Pay;
        this.decisionStatusId = PayOrReturnChoice.Pay;
    }

    public return() {
        this.dto.decisionStatus = ExceptionDecisionStatus.Return;
        this.decisionStatusId = PayOrReturnChoice.Return;
    }

    public clearDecision() {
        this.dto.decisionStatus = ExceptionDecisionStatus.NA;
        this.decisionStatusId = PayOrReturnChoice.Unselected;
    }

    get decisionModified() {
        return this.originalDecisionStatus !== this.dto.decisionStatus;
    }

    public get originalApprovalStatus() {
        return this.originalDecisionStatus === ExceptionDecisionStatus.Pay
            ? 'Approved'
            : 'Rejected';
    }
}
