/* eslint-disable no-param-reassign */
import { Recordset } from '@treasury/FDL';
import {
    boolean,
    yesNoBoolean,
    string,
    socialSecurityNumber,
    number,
} from '@treasury/policy/primitives';
import {
    achPaymentRecipientName,
    achAccountNumber,
    achIdNumber,
    achFipsCode,
    achPaymentDate,
    achAccountType,
    achBank,
} from '@treasury/policy/ach';

import {
    childSupportCaseIdentifier,
    childSupportPaymentAmount,
    nonCustodialParentLastName,
    nonCustodialParentFirstName,
} from '@treasury/policy/ach';
import { OmegaColumnAction, OmegaColumnType } from '@treasury/omega/components/table';
import ChildSupportWorkflowClient from '../clients/child-support-workflow-client.ts';

/**
 *
 * Business Rule
 */
const isPayment = type => type !== 'template';
const hasPartialPaymentEditEntitlement = (entitlements, action, type) =>
    entitlements.some(e => e.permission === 'ACH, Payment, Partial Edit') &&
    !entitlements.some(e => e.permission === 'ACH, Payment, Full Edit') &&
    isPayment(type) &&
    action !== 'create';
const hasPartialTemplateEditEntitlement = (entitlements, action, type) =>
    entitlements.some(e => e.permission.toLowerCase() === 'partial edit ach template') &&
    !entitlements.some(e => e.permission.toLowerCase() === 'full edit ach template') &&
    !isPayment(type) &&
    action !== 'create';
const hasNoPaymentEditEntitlement = (entitlements, action, type) =>
    !entitlements.some(e => e.permission === 'ACH, Payment, Partial Edit') &&
    !entitlements.some(e => e.permission === 'ACH, Payment, Full Edit') &&
    isPayment(type) &&
    action !== 'create';
const hasNoTemplateEditEntitlement = (entitlements, action, type) =>
    !entitlements.some(e => e.permission.toLowerCase() === 'partial edit ach template') &&
    !entitlements.some(e => e.permission.toLowerCase() === 'full edit ach template') &&
    !isPayment(type) &&
    action !== 'create';
const editableStep = record => record.getField('step') === 0 || record.getField('step') === 1;
const isDetailView = action => !!action === false;
const isReadOnlyStates = (record, entitlements, action, type) => {
    const partialPayment = hasPartialPaymentEditEntitlement(entitlements, action, type);
    const partialTemplate = hasPartialTemplateEditEntitlement(entitlements, action, type);
    const notEditableStep = !editableStep(record);
    const detailView = isDetailView(action);
    const noTemplateEdit = hasNoTemplateEditEntitlement(entitlements, action, type);
    const noPaymentEdit = hasNoPaymentEditEntitlement(entitlements, action, type);
    return (
        // eslint-disable-next-line @treasury/max-boolean-operators
        partialPayment ||
        partialTemplate ||
        notEditableStep ||
        detailView ||
        noTemplateEdit ||
        noPaymentEdit
    );
};

/**
 * Columns:
 */
const columns = [
    {
        field: 'name',
        label: 'Recipient Name',
    },
    {
        field: 'idNumber',
        label: 'ID Number',
    },
    {
        field: 'accountNumber',
        label: 'Account Number',
    },
    {
        field: 'accountType',
        label: 'Account Type',
    },
    {
        field: 'bank',
        label: 'Routing Number',
    },
    {
        field: 'amount',
        label: 'Amount',
    },
];

export const createChildSupportPaymentColumns = editable => {
    if (editable) {
        return [
            ...columns.map(column => ({ ...column })),
            {
                field: 'prenote',
                label: 'Prenote',
                type: OmegaColumnType.CheckBox,
                sortable: true,
            },
            {
                field: 'hold',
                label: 'Hold',
                type: OmegaColumnType.CheckBox,
                sortable: true,
            },
            {
                field: '',
                label: '',
                type: OmegaColumnType.EditRow,
                options: [
                    {
                        action: OmegaColumnAction.Add,
                    },
                    {
                        action: OmegaColumnAction.Clone,
                        tooltip:
                            'This feature may be utilized to copy the Recipient Name, ID Number, Account Number, Account Type and Routing Number.',
                        fieldsToClone: ['name', 'idNumber', 'accountNumber', 'accountType', 'bank'],
                    },
                    {
                        action: OmegaColumnAction.Delete,
                    },
                ],
            },
            {
                field: '',
                label: '',
                'display-toggle-all': true,
                'display-chevron': true,
                type: OmegaColumnType.Detail,
            },
        ];
    }
    return [
        ...columns.map(column => ({ ...column })),
        {
            field: 'prenote',
            label: 'Prenote',
        },
        {
            field: 'hold',
            label: 'Hold',
        },
        {
            field: '',
            label: '',
            'display-toggle-all': true,
            'display-chevron': true,
            type: OmegaColumnType.Detail,
        },
    ];
};

export const uploadNachaFileRecipientsColumns = editable => [
    ...columns.map(column => ({ ...column, editable })),
    {
        field: 'prenote',
        label: 'Prenote',
        type: editable ? OmegaColumnType.CheckBox : null,
    },
    {
        field: 'hold',
        label: 'Hold',
        type: editable ? OmegaColumnType.CheckBox : null,
    },
    {
        field: 'addenda',
        label: 'Addenda',
        type: OmegaColumnType.Actions,
        actions: [{ label: 'Addenda', action: 'Addenda', visibleWhen: () => true }],
    },
];

/**
 *
 * Fields:
 */
/* we have to define separate fields for the
    nacha upload workflow which uses a different table. this would not be the case if
    the nacha parse did the same thing as the GET request which parses the addenda (detail) fields
*/
const recipientsFields = (isNacha, type, config, client, fdlClient) => {
    const { entitlements, action } = config;
    const fields = {
        id: string.thatIs.visibleWhen(() => false),
        recipientId: string.thatIs.visibleWhen(() => false),
        addendaId: string.thatIs.visibleWhen(() => false),
        name: achPaymentRecipientName.thatIs
            .required()
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type))
            .as.tag('omega-input'),
        idNumber: achIdNumber.thatIs.readOnlyWhen(record =>
            isReadOnlyStates(record, entitlements, action, type)
        ),
        accountNumber: achAccountNumber.thatIs
            .required()
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type))
            .as.tag('omega-input'),
        accountType: achAccountType.thatIs
            .required()
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        bank: achBank(fdlClient)
            .thatIs.required()
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type))
            .thatHas.hashFunction(bank => bank?.bankId),
        amount: childSupportPaymentAmount.thatIs
            .required()
            .thatIs.readOnlyWhen(
                record =>
                    !editableStep(record) ||
                    isDetailView(action) ||
                    hasNoTemplateEditEntitlement(entitlements, action, type) ||
                    hasNoPaymentEditEntitlement(entitlements, action, type)
            )
            .and.disabledWhen(record => record.getField('prenote')),
        prenote: yesNoBoolean.with
            .cellClass('count')
            .and.defaultValue(false)
            .thatIs.readOnlyWhen(
                record =>
                    !editableStep(record) ||
                    isDetailView(action) ||
                    hasNoTemplateEditEntitlement(entitlements, action, type) ||
                    hasNoPaymentEditEntitlement(entitlements, action, type)
            ),
        hold: yesNoBoolean.with
            .cellClass('count')
            .and.defaultValue(false)
            .thatIs.readOnlyWhen(
                record =>
                    !editableStep(record) ||
                    isDetailView(action) ||
                    hasNoTemplateEditEntitlement(entitlements, action, type) ||
                    hasNoPaymentEditEntitlement(entitlements, action, type)
            ),
        errors: boolean,
        step: number.thatIs.visibleWhen(() => false),
    };
    if (isNacha) {
        return {
            ...fields,
            addenda: string,
        };
    }
    return {
        ...fields,
        nonCustodialParentSsn: socialSecurityNumber.thatIs
            .required()
            .with.label('Non-Custodial Parent SSN')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        nonCustodialParentFirstName: nonCustodialParentFirstName.with
            .label('Non-Custodial Parent First Name')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        nonCustodialParentLastName: nonCustodialParentLastName.with
            .label('Non-Custodial Parent Last Name')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        caseIdentifier: childSupportCaseIdentifier.thatIs
            .required()
            .with.label('Case Identifier')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        fipsCode: achFipsCode.with
            .label('FIPS Code')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        payDate: achPaymentDate.thatIs
            .requiredWhen(() => isPayment(type))
            .with.label('Pay Date')
            .thatIs.visibleWhen(() => isPayment(type))
            .thatIs.readOnlyWhen(
                record =>
                    !editableStep(record) ||
                    isDetailView(action) ||
                    hasNoTemplateEditEntitlement(entitlements, action) ||
                    hasNoPaymentEditEntitlement(entitlements, action)
            ),
        employmentTermination: yesNoBoolean.with
            .label('Employment Termination')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
        medicalSupport: yesNoBoolean.with
            .label('Medical Support')
            .thatIs.readOnlyWhen(record => isReadOnlyStates(record, entitlements, action, type)),
    };
};

export const defaultRecipient = isNacha => {
    const fields = {
        name: '',
        idNumber: '',
        accountNumber: '',
        accountType: 'Checking',
        bank: {},
        amount: 0,
        prenote: false,
        hold: false,
        errors: false,
    };
    if (isNacha) {
        return { ...fields, addenda: string };
    }
    return {
        ...fields,
        nonCustodialParentSsn: '',
        nonCustodialParentFirstName: '',
        nonCustodialParentLastName: '',
        caseIdentifier: '',
        fipsCode: '',
        payDate: '',
        employmentTermination: false,
        medicalSupport: false,
        step: 0,
    };
};

/* istanbul ignore next */
const recipients = (isNacha, type, config, data, client, fdlClient) => {
    data.map(recipient => {
        Object.keys(defaultRecipient(isNacha)).forEach(key => {
            if (!recipient[key]) {
                recipient[key] = recipientsFields(isNacha, type, config, client, fdlClient)[
                    key
                ].defaultValue();
            }
        });
        return recipient;
    });
    return data;
};

export const recipientRecordset = (
    data,
    isNacha,
    type,
    config,
    client = new ChildSupportWorkflowClient(),
    fdlClient = null
) =>
    new Recordset(recipientsFields(isNacha, type, config, client, fdlClient), () =>
        recipients(isNacha, type, config, data, client, fdlClient)
    );

export default recipientRecordset;
