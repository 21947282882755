export enum DatePeriod {
    TODAY = 'Today',
    SPECIFIC_DATE = 'Specific Date',
    DATE_RANGE = 'Date Range',
    WEEK_TO_DATE = 'Week-To-Date',
    MONTH_TO_DATE = 'Month-To-Date',
    YEAR_TO_DATE = 'Year-To-Date',
    NUMBER_OF_PRIOR_DAYS = '# of Prior Days',
    CURRENT_DAY = 'Current Day',
    PRIOR_DAY = 'Prior Day',
}

export type DateFormat =
    | 'dddyy'
    | 'dddyyyy'
    | 'ddmmyy'
    | 'ddyymm'
    | 'ddmmyyyy'
    | 'mmddyy'
    | 'mmddyyyy'
    | 'mmyydd'
    | 'yyddd'
    | 'yyddmm'
    | 'yymmdd'
    | 'yyyyddd'
    | 'yyyyddmm'
    | 'yyyymmdd'
    | 'mmddyy';
