interface Config {
    title: string;
    key: string;
    downloadKey: string;
    downloadClientService: string;
    post: string;
    put: string;
    delete: string;
    actions: string[];
    downloadOptions: string[];
    creationOptions: { label: string; value: string }[];
    permissionsKey: string;
    permissions: string[];
    statusField: string;
    prenoteField: string;
    holdField: string;
    reportUrl: string;
}

interface ContainerConfig {
    template: Config;
    payment: Config;
}

export const CONTAINER_CONFIGURATION: ContainerConfig = {
    template: {
        title: 'Template',
        key: 'template',
        downloadKey: 'ACHTemplateDetail',
        downloadClientService: 'downloadChildSupportTemplate',
        post: 'createTemplate',
        put: 'updateTemplate',
        delete: '',
        actions: ['edit', 'delete'],
        downloadOptions: ['CSV', 'PDF'],
        creationOptions: [],
        permissionsKey: 'userPermissions',
        permissions: ['EditTemplate', 'DeleteTemplate', 'CreatePayment', 'CreateTemplate'],
        statusField: 'statusDescription',
        prenoteField: 'isPrenote',
        holdField: 'holdTransaction',
        reportUrl: 'payables.ach.payments.child-support-templates',
    },
    payment: {
        title: 'Payment',
        key: 'payment',
        post: 'submitPayment',
        put: 'updatePayment',
        delete: '',
        downloadKey: 'ACHPaymentDetail',
        downloadClientService: 'downloadChildSupportPayment',
        actions: ['edit', 'approve', 'reject'],
        downloadOptions: ['CSV', 'NACHA', 'PDF'],
        creationOptions: [
            { label: 'Manual', value: 'manual' },
            { label: 'From Template', value: 'template' },
        ],
        permissionsKey: 'userPermissions',
        permissions: ['Approve', 'RejectPayment', 'EditPayment'],
        statusField: 'status',
        prenoteField: 'prenote',
        holdField: 'hold',
        reportUrl: 'payables.ach.payment-list',
    },
};
