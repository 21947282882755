import { getChannelAPIPath } from '@treasury/core/http';
import { getAllAchCompanies } from '@treasury/domain/channel/requests/ach/bank-service.js';

AchCompaniesService.$inject = ['$resource', '$q', '$http'];

export default function AchCompaniesService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}achCompanies`;

    return {
        getAll,
        getSecCodes,
        getOffsetAccounts,
        getCCDAchCompanies,
        getNonIATAchCompanies,
    };

    function getAll() {
        return getAllAchCompanies();
        // return $resource(resourceUrl).query().$promise;
    }

    function getSecCodes(id) {
        return $resource(`${resourceUrl}/:id/secCodes`, { id }).query().$promise;
    }

    function getOffsetAccounts(id) {
        return $resource(`${resourceUrl}/:id/offsetAccounts`, {
            id,
            enableAccountNicknameSetting: false,
        }).query().$promise;
    }

    function getCCDAchCompanies(achCompanies) {
        if (!(achCompanies && achCompanies.length)) return [];

        return achCompanies.filter(
            item =>
                item.secCodes &&
                item.secCodes.length &&
                item.secCodes.some(sec => sec.code === 'CCD')
        );
    }

    function getNonIATAchCompanies(achCompanies) {
        if (!(achCompanies && achCompanies.length)) return [];

        return achCompanies.filter(
            item =>
                item.secCodes &&
                item.secCodes.length &&
                item.secCodes.some(sec => sec.code !== 'IAT')
        );
    }
}
