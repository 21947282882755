import { FiDate } from '@treasury/domain/dates';

AchPaymentListController.$inject = [
    '$scope',
    'secCodesService',
    'achPaymentsService',
    'entitlementsService',
    'searchFilterService',
    '$state',
    'toaster',
    'securityService',
    '$filter',
    'modalService',
    'achCompaniesService',
    '$uibModal',
    'achSettingsService',
    '$timeout',
];

export default function AchPaymentListController(
    $scope,
    secCodesService,
    achPaymentsService,
    entitlementsService,
    searchFilterService,
    $state,
    toaster,
    securityService,
    $filter,
    modalService,
    achCompaniesService,
    $uibModal,
    achSettingsService,
    $timeout
) {
    $scope.filterObject = {};
    $scope.paymentList = [];
    $scope.filteredPayments = [];
    $scope.allItemsAreSelected = {};
    $scope.selectionList = [];
    $scope.list = [];
    $scope.selected_fileActivity_id_List = [];
    $scope.selected_payment_List = [];
    $scope.searchObj = { text: null };
    $scope.achCompanies = [];

    $scope.search = search;
    $scope.resetSearch = resetSearch;
    $scope.setForm = setForm;
    $scope.cancel = cancel;
    $scope.canCancelPayment = canCancelPayment;
    $scope.hasPermission = hasPermission;
    $scope.createPayment = createPayment;
    $scope.selectOrDeselctAllPayments = selectOrDeselectAllPayments;
    $scope.updateSelectionList = updateSelectionList;
    $scope.calculateTotals = calculateTotals;
    $scope.batchUpdate = batchUpdate;
    $scope.getParentFrequencySummary = getParentFrequencySummary;
    $scope.frequencyTypeToFriendly = frequencyTypeToFriendly;
    $scope.goToPaymentDetail = goToPaymentDetail;
    $scope.goToRecurringPaymentDetail = goToRecurringPaymentDetail;
    $scope.checkEnability = checkEnability;
    $scope.unInitiate = unInitiate;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.goToRecurringPaymentList = goToRecurringPaymentList;
    $scope.goToACHFileActivityList = goToACHFileActivityList;
    $scope.isDataLoaded = false;
    $scope.hasChildSupportEntitlement = hasChildSupportEntitlement;
    $scope.disableDownload = true;

    function hasChildSupportEntitlement() {
        return entitlementsService.hasEntitlement('Feature.ACH.AllowChildSupportPayments');
    }

    function setApprovalAttributes() {
        $scope.paymentList.map(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'achPayment',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredPayments = $filter('filter')($scope.paymentList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.paymentList ||
            $scope.paymentList.length <= 0
        ) {
            return true;
        }
        return (
            (!!row.name &&
                row.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.achCompanyName &&
                row.achCompanyName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.fileName &&
                row.fileName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.transactionId &&
                row.transactionId.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.secCode &&
                row.secCode.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.frequency.initiatedDate &&
                $filter('date')(row.frequency.initiatedDate, 'MM/dd/yyyy').indexOf(
                    $scope.searchObj.text
                ) !== -1) ||
            (!!row.frequency.effectiveDate &&
                $filter('date')(row.frequency.effectiveDate, 'MM/dd/yyyy').indexOf(
                    $scope.searchObj.text
                ) !== -1) ||
            (!!row.debitAmount &&
                $filter('currency')(row.debitAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.creditAmount &&
                $filter('currency')(row.creditAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!$scope.frequencyTypeToFriendly(row.parentFrequencyType) &&
                $scope
                    .frequencyTypeToFriendly(row.parentFrequencyType)
                    .toLowerCase()
                    .indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function disableFilter() {
        return $scope.paymentList ? $scope.paymentList.length <= 0 : true;
    }

    function goToRecurringPaymentList() {
        $state.go('payables.ach.recurring-payment-list');
    }

    function goToACHFileActivityList(fileArchiveId) {
        if (fileArchiveId) {
            $state.go('payables.ach.fileactivity-list-param', { id: fileArchiveId });
        } else {
            $state.go('payables.ach.fileactivity-list');
        }
    }

    async function init() {
        $scope.approvePaymentEntitlement = 'Approve Ach Payment';
        getSecCodes();
        $scope.dateOptions = ['Specific Date', 'Range'];
        $scope.amountOptions = ['Specific Amount', 'Range'];
        $scope.filterObject.achType = 'All';
        $scope.achTypeOptions = [
            { text: 'All', value: 'All' },
            { text: 'Reversal', value: 'Reversal' },
            { text: 'Tax Payment', value: 'TaxPayment' },
            { text: 'Child Support', value: 'ChildSupportPayment' },
            { text: 'International ACH Payment', value: 'InternationalPayment' },
        ];
        $scope.statusOptions = [
            'All',
            'Pending Approval',
            'Approval Rejected',
            'Initiated',
            'UnInitiated',
            'Cancelled',
            'Failed',
            'Expired',
            'Scheduled',
        ];
        $scope.currentDate = moment().format('MM/DD/YYYY');

        achSettingsService.get().then(settings => {
            $scope.areTaxPaymentsEnabled = settings.allowTaxPayments;
        });

        achCompaniesService.getAll().then(data => {
            $scope.filterObject.achCompanyList = $scope.achCompanies = data;
            $scope.filterObject.achCompanyList.sort((a, b) =>
                a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase())
            );
            angular.forEach($scope.achCompanies, achCompany => {
                achCompany.isChecked = true;
            });
            resetSearch();
            searchFilterService.restoreFilter($scope.filterObject);
            const filters = prepareFilter($scope.filterObject);
            if ($state.params.fileArchiveIds) filters.fileArchiveIds = [parseInt($state.params.id)];
            else if ($state.params.fileArchiveIds !== null)
                filters.fileArchiveIds = $state.params.list;
            searchAndSortPayments(filters).then(sortedPayments => {
                $scope.paymentList = sortedPayments;
                setApprovalAttributes();
                $scope.filterSearch($scope.searchText);
                if ($scope.paymentList.length === 0) {
                    $scope.sidebarContainerController.setCollapsed(false);
                } else {
                    $scope.disableDownload = false;
                }
                $scope.isDataLoaded = true;
                $timeout(() => {
                    $scope.$apply();
                }, 100);
            });
        });
    }

    function findStatus(payments, status) {
        return payments.filter(
            payment => payment.status.toLowerCase().trim() === status.toLowerCase().trim()
        );
    }

    function sortByEffectiveDate(a, b) {
        const result = new Date(a.frequency.effectiveDate) - new Date(b.frequency.effectiveDate);
        if (result > 0) return -1;
        if (result < 0) return 1;
        return 0;
    }

    async function searchAndSortPayments(filters) {
        const pendingStatus = 'pending approval';
        const scheduledStatus = 'scheduled';
        const filteredPayments = filters;
        const lastWeek = new Date().setDate(new Date().getDate() - 7);
        const futureTime = new Date().setDate(new Date().getDate() + 7);
        filteredPayments.effectiveDateEnd = new FiDate(futureTime).toString();
        filteredPayments.effectiveDateStart = new FiDate(lastWeek).toString();
        filteredPayments.effectiveDateType = 'Range';
        const payments = await achPaymentsService.search(filteredPayments, 'One Time');
        const pendingApproval = findStatus(payments, pendingStatus).sort(sortByEffectiveDate);
        const scheduled = findStatus(payments, scheduledStatus).sort(sortByEffectiveDate);
        const allOthers = payments
            .filter(payment => payment.status.toLowerCase().trim() !== scheduledStatus)
            .filter(payment => payment.status.toLowerCase().trim() !== pendingStatus)
            .sort(sortByEffectiveDate);
        return pendingApproval.concat(scheduled).concat(allOthers);
    }

    function resetSearch() {
        if ($scope.form) {
            $scope.form.$setPristine();
        }

        $scope.filterObject = { achType: 'All' };
        $scope.filterObject.achCompanyList = [];
        angular.forEach($scope.achCompanies, achCompany => {
            achCompany.isChecked = true;
            $scope.filterObject.achCompanyList.push(achCompany);
        });

        $scope.filterObject.batchName = '';
        $scope.filterObject.debitAmountType = $scope.amountOptions[0];
        $scope.filterObject.creditAmountType = $scope.amountOptions[0];
        $scope.filterObject.initiatedDateType = $scope.dateOptions[0];
        $scope.filterObject.effectiveDateType = $scope.dateOptions[0];
        $scope.filterObject.status = 'All';
        $scope.filterObject.secCode = 'All';
    }

    function hasAccessPermissions(payment, permissionType) {
        let result = false;
        angular.forEach(payment.permissions, permission => {
            if (permission.permission == permissionType) {
                result = true;
            }
        });
        return result;
    }

    function search() {
        searchFilterService.saveFilter($scope.filterObject, ['payables.ach.payment-detail']);
        achPaymentsService.search(prepareFilter($scope.filterObject), 'One Time').then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.searchObj.text = null;
            $scope.paymentList = response;
            setApprovalAttributes();
            $scope.filterSearch($scope.searchText);

            if ($scope.paymentList.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
        });
    }

    function createPayment() {
        $state.go('payables.ach.payments.create');
    }

    function getSecCodes() {
        secCodesService.getConfigcSecCodes().then(response => {
            $scope.secCodes = response;
            $scope.secCodes.unshift('All');
        });
    }

    function frequencyTypeToFriendly(frequencyType) {
        return achPaymentsService.frequencyTypeToFriendly(frequencyType);
    }

    function setForm(form) {
        $scope.form = form;
    }

    function hasPermission(permission) {
        return entitlementsService.hasEntitlement(permission);
    }

    function selectOrDeselectAllPayments() {
        angular.forEach($scope.paymentList, payment => {
            if (payment.status === 'Pending Approval' && hasAccessPermissions(payment, 'Approve')) {
                payment.isSelected = $scope.allItemsAreSelected.value;
                if ($scope.allItemsAreSelected.value === true) {
                    $scope.selectionList.push(payment);
                } else {
                    $scope.selectionList = [];
                }
            }
        });
    }

    function getParentFrequencySummary(parentFrequency) {
        return achPaymentsService.summarizeFrequency(parentFrequency);
    }

    function updateSelectionList(payment) {
        let allSelected = true;

        if (payment.isSelected === true) {
            $scope.selectionList.push(payment);
        } else if (payment.isSelected === false) {
            $scope.selectionList.splice($scope.selectionList.indexOf(payment), 1);
        }

        angular.forEach($scope.paymentList, payment => {
            if (payment.status === 'Pending Approval') {
                if (!payment.isSelected) {
                    allSelected = false;
                }
            }
        });

        if (allSelected) {
            $scope.allItemsAreSelected.value = true;
        } else {
            $scope.allItemsAreSelected.value = false;
        }
    }

    function goToPaymentDetail(payment) {
        if (achPaymentsService.isChildSupportPayment(payment)) {
            $state.go('payables.ach.child-support-details', { id: payment.id, type: 'payment' });
        } else if (achPaymentsService.isInternationalAchPayment(payment)) {
            $state.go('payables.ach.international-ach-payment-details', { id: payment.id });
        } else if (achPaymentsService.isTaxPayment(payment)) {
            $state.go('payables.ach.taxPayment-detail', { id: payment.id });
        } else {
            $state.go('payables.ach.payment-detail', {
                id: payment.id,
                type: 'view',
                list: 'payment-list',
            });
        }
    }

    function calculateTotals(data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    }

    function checkEnability() {
        if ($scope.selectionList.length > 0) {
            return false;
        }
        return true;
    }

    function batchUpdate(type) {
        const modalInstance = $uibModal.open({
            template: require('../views/bulkApproveOrRejectDialog.html'),
            size: 'md',
            controller: 'BulkApproveOrRejectDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            const batchPayload = {
                lookups: [],
            };
            angular.forEach($scope.paymentList, payment => {
                if (payment.isSelected) {
                    batchPayload.lookups.push({ key: payment.id, value: comments });
                }
            });
            let actionType;
            if (type === 'Approve') {
                actionType = 'Approve ACH Payment';
            } else if (type === 'Reject') {
                actionType = 'Reject ACH Payment';
            }
            securityService
                .verifyUser(actionType, batchPayload, () =>
                    achPaymentsService.batchApproveOrReject(batchPayload, type)
                )
                .then(
                    response => {
                        $scope.allItemsAreSelected.value = false;
                        selectOrDeselectAllPayments();

                        if (updatePayments(response)) {
                            if (type === 'Approve') {
                                toaster.approve('Payments');
                            } else if (type === 'Reject') {
                                toaster.reject('Payments');
                            }
                        }
                    },
                    () => {
                        $scope.allItemsAreSelected.value = false;
                        selectOrDeselectAllPayments();
                        search();
                    }
                );
        });
    }

    function updatePayments(response) {
        let noErrors = true;
        if (!!response && !!response.achPayments && response.achPayments.length > 0) {
            $scope.paymentList = $scope.paymentList.map(payment => {
                const updatedPayment = response.achPayments.filter(item => {
                    if (item.errorSummary) {
                        noErrors = false;
                    }
                    if (item.id === payment.id) {
                        // For payments that were created using upload flow, fileName is not being returned from BSL
                        // to avoid performance issues.
                        item.fileName = payment.fileName;
                        // This is needed in case the updated payments are displayed in a sorted table directive
                        // and one needs to maintain the same order after the update
                        if (payment.sequence) {
                            item.sequence = payment.sequence;
                        }
                        return item;
                    }
                });
                return !!updatedPayment && updatedPayment.length === 1
                    ? updatedPayment[0]
                    : payment;
            });
            setApprovalAttributes();
            $scope.filterSearch($scope.searchText);
        }
        return noErrors;
    }

    function canCancelPayment(payment) {
        const cancelStatuses = [
            'Pending Approval',
            'Approval Rejected',
            'Uninitiated',
            'Expired',
            'Scheduled',
        ];
        const isInternational = payment.transactionId.slice(-2) === '-I';
        if (cancelStatuses.includes(payment.status)) {
            if (isInternational && hasPermission('Delete International ACH Payment')) {
                return true;
            }
            if (!isInternational && hasPermission('Delete Ach Payment')) {
                return true;
            }
        }
        return false;
    }

    function cancel(payment) {
        const modalInstance = $uibModal.open({
            template: require('../views/cancelPaymentDialogView.html'),
            size: 'lg',
            controller: 'CancelPaymentController',
            backdrop: 'static',
            resolve: {
                type() {
                    return 'paymentList';
                },
            },
        });
        modalInstance.result.then(comments => {
            const cancelAchPaymentModel = {
                achPaymentId: payment.id,
                comments,
            };
            securityService
                .verifyUser('Delete ACH Payment', cancelAchPaymentModel, () =>
                    achPaymentsService.cancel(cancelAchPaymentModel)
                )
                .then(response => {
                    search();
                });
        });
    }

    function unInitiate(payment) {
        const modalInstance = $uibModal.open({
            template: require('../views/unInitiatePaymentDialog.html'),
            size: 'lg',
            controller: 'UnInitiatePaymentController',
            backdrop: 'static',
            resolve: {
                type() {
                    return 'paymentList';
                },
                isReversal() {
                    return !!payment.reversalAchPaymentId;
                },
            },
        });
        modalInstance.result.then(comments => {
            const achUninitiateModel = {
                achPaymentId: payment.id,
                comments,
            };
            securityService
                .verifyUser('Delete ACH Payment', achUninitiateModel, () =>
                    achPaymentsService.uninitiate(achUninitiateModel)
                )
                .then(
                    response => {
                        if (response.isUninitiated) {
                            toaster.save('Payment Uninitiated');
                            search();
                        } else {
                            removePermission(payment, 'DeletePayment');
                            const modalOptions = {
                                headerText: 'Uninitiate Request Failed',
                                bodyTitle:
                                    'Unable to uninitiate payment. Please contact your financial institution for assistance.',
                                actionButtonText: 'Close',
                                isAlert: true,
                            };
                            modalService.showModal({}, modalOptions).result.then(null, search);
                        }
                    },
                    error => {
                        search();
                    }
                );
        });
    }

    function removePermission(payment, permissionName) {
        const index = payment.permissions.indexOf(permissionName);
        if (index !== -1) payment.permissions.splice(index, 1);
    }

    function goToRecurringPaymentDetail(payment) {
        $state.go('payables.ach.payment-detail', {
            id: payment.parentId,
            type: 'view',
            list: 'recurringList',
        });
    }

    function prepareFilter(filterObject) {
        const obj = angular.copy(filterObject);
        const fields = ['debitAmount', 'creditAmount', 'effectiveDate', 'initiatedDate'];
        angular.forEach(fields, field => {
            const fieldType = `${field}Type`;
            if (!obj[field] && (!obj[fieldType] || obj[fieldType].indexOf('Specific') !== -1)) {
                delete obj[field];
                delete obj[fieldType];
            }
        });
        return obj;
    }

    init();
}
