UnInitiatePaymentController.$inject = ['$scope', '$modalInstance', 'type', 'isReversal'];

export default function UnInitiatePaymentController($scope, $modalInstance, type, isReversal) {
    $scope.type = type;
    $scope.isReversal = isReversal;
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.unInitiatePayment = function () {
        $modalInstance.close($scope.comments);
    };
}
