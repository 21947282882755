IntegratedServiceEntitlementsController.$inject = [
    '$scope',
    '$window',
    'usersService',
    'toaster',
    'securityService',
];

export default function IntegratedServiceEntitlementsController(
    $scope,
    $window,
    usersService,
    toaster,
    securityService
) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.entitlements = null;
    $scope.entitlementsPristine = null;
    $scope.setForm = setForm;
    $scope.reset = reset;
    $scope.save = save;

    function init() {
        loadEntitlements();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        const updates = usersService.buildUpdateProductEntitlementsRequest(
            $scope.entitlements,
            $scope.entitlementsPristine
        );

        securityService
            .verifyUser('Edit User', updates, () =>
                usersService.updateProductEntitlements($scope.id, updates)
            )
            .then(response => {
                if (response.success) {
                    $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
                    $scope.$parent.notifySave();
                    $scope.form.$setPristine();
                    toaster.save('Integrated Service Entitlements');
                }
            });
    }
    function reset() {
        $scope.entitlements = angular.copy($scope.entitlementsPristine);
        $scope.form.$setPristine();
    }

    // Private
    function loadEntitlements() {
        usersService.getProductEntitlements($scope.id, 'IntegratedServices').then(response => {
            $scope.entitlements = response;
            if (response.accountProductClaims) {
                for (let i = 0; i < response.accountProductClaims.length; i++) {
                    $scope.columnValues.push(false);
                }
            }
            $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
        });
    }
    init();
}
