/* eslint-disable class-methods-use-this */

import { exists } from '@treasury/utils';
import { html, nothing } from 'lit';
// eslint-disable-next-line import/extensions
import '../progress/omega-skeleton-loader';

const SORT_STATES = ['UNSORTED', 'ascending', 'descending'];

export default class Column {
    constructor({ field }, recordset) {
        this.fieldType = recordset.getFieldType(field);
        this.minWidth = this.fieldType.properties?.minColumnWidth;
        this.maxWidth = this.fieldType.properties?.maxColumnWidth;
        this.targetWidth = this.fieldType.properties?.targetColumnWidth;
        this.textAlign = recordset.getFieldType(field).textAlign();
        // eslint-disable-next-line prefer-destructuring
        this.sort = SORT_STATES[0];
        this.listeningTo = [];
    }

    listenTo(target, event, fn) {
        target.addEventListener(event, fn);
        this.listeningTo.push({ target, event, fn });
    }

    dispose() {
        for (const { target, event, fn } of this.listeningTo) {
            target.removeEventListener(event, fn);
        }
        this.listeningTo = [];
    }

    cellClasses() {
        const classes = [];
        if (this.isSortable()) {
            classes.push('sortable');
        }
        if (this.noPrint) {
            classes.push('no-print');
        }
        return classes;
    }

    isSortable() {
        return false;
    }

    nextSortState(cursor, event) {
        if (event.target.tagName.toLowerCase() === 'omega-checkbox') return;
        const currentSortIndex = SORT_STATES.indexOf(this.sort);
        this.sort = SORT_STATES[(currentSortIndex + 1) % SORT_STATES.length];

        cursor.sort(this);
    }

    getStyleString() {
        const styleKvPairs = {
            'text-align': this.textAlign,
            'min-width': this.minWidth,
            'max-width': this.maxWidth,
            width: this.targetWidth,
        };

        return Object.keys(styleKvPairs).reduce((styleString, styleName) => {
            const styleValue = styleKvPairs[styleName];
            if (exists(styleValue)) {
                const unit = typeof styleValue === 'number' ? 'px' : '';
                styleString += `${styleName}: ${styleValue}${unit}; `;
            }

            return styleString;
        }, '');
    }

    renderSortableTh(cursor) {
        const sortDirection = cursor.getColumnSortDirection(this);
        const classes = this.cellClasses().join(' ');
        const styles = this.getStyleString();

        return html`<!-- @ts-ignore -->
            <style>
                @media print {
                    .no-print {
                        display: none;
                    }
                }
            </style>
            <th
                class="${classes}"
                aria-sort="${sortDirection}"
                @click=${e => this.nextSortState(cursor, e)}
            >
                <div style="${styles}">
                    <span>${this.renderThContents(cursor)}</span>

                    <svg
                        class="unsorted-icon no-print"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 75 40"
                    >
                        <path
                            d="M50 34H14a1 1 0 00-1 2l18 23a1 1 0 002 0l18-23a1 1 0 00-1-2zm-36-4h36a1 1 0 001-2L33 5a1 1 0 00-2 0L13 28a1 1 0 001 2z"
                            data-name="29"
                        />
                    </svg>

                    <svg
                        class="sort-icon no-print"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        viewBox="0 0 684 742.5"
                    >
                        <path d="M0 0l342 594L684 0H0z" />
                    </svg>
                </div>
            </th>`;
    }

    renderThContents(/* cursor */) {
        return nothing;
    }

    renderTdContents(/* record */) {
        return nothing;
    }

    renderSummaryContents(/* visibleRecords */) {
        return nothing;
    }

    renderCol() {
        const colClasses = [];
        if (this.noPrint) {
            colClasses.push('no-print');
        }
        return html`<col class="${colClasses.join(' ')}" />`;
    }

    renderGroupTh(definition) {
        if (definition.columns) {
            return html`<th id="grouped-column-header" colspan=${definition.columns.length}>
                ${definition.label}
            </th>`;
        }
        return html`<th></th>`;
    }

    renderTh(cursor) {
        if (this.isSortable()) {
            return this.renderSortableTh(cursor);
        }

        const styleString = this.getStyleString();
        const classes = this.cellClasses().join(' ');

        return html`<th class=${classes}>
            <div style="${styleString}">${this.renderThContents(cursor)}</div>
        </th>`;
    }

    renderTd(record, rowIndex) {
        const classes = this.cellClasses().join(' ');
        const styleString = this.getStyleString();
        const contents = record
            ? this.renderTdContents(record, rowIndex)
            : this.renderContentLoading();
        return html`<td class="${classes}">
            <div style=${styleString}>${contents}</div>
        </td>`;
    }

    renderSummary(visibleRecords) {
        const summary = visibleRecords
            ? this.renderSummaryContents(visibleRecords)
            : this.renderContentLoading();
        return html`<th class="${this.cellClasses().join(' ')}">${summary}</th>`;
    }

    renderContentLoading() {
        /**
         * TODO: refactor to component under progress -> <omega-progress-shimmer></omega-progress-shimmer>
         */
        return html`<omega-skeleton-loader></omega-skeleton-loader>`;
    }
}
