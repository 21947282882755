import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { boolean, date, number, string } from '@treasury/policy/primitives';
import { html } from 'lit';

export const JobStatusType = {
    Undefined: 0,
    Pending: 5,
    Started: 6,
    Completed: 7,
    Failed: 8,
    Cancelled: 9,
    Retry: 10,
    Hold: 11,
};

export const fields = () => ({
    id: number.thatIs.readOnly(),
    reportName: string.thatIs
        .readOnly()
        .andIs.disabledWhen(record => record.getField('jobStatusType') !== JobStatusType.Completed),
    reportType: string.thatIs.readOnly(),
    accountName: string.thatIs.readOnly(),
    runDate: date.thatIs.readOnly(),
    createdBy: string.thatIs.readOnly(),
    selected: boolean.as.tag('omega-checkbox').and.sortable(false).and.maxColumnWidth(30),
    detail: string.thatIs.readOnly(),
    jobStatusType: string.thatIs
        .readOnly()
        .with.template((value, record) => {
            const label = Object.keys(JobStatusType).find(k => JobStatusType[k] === value);
            if (value === JobStatusType.Failed) {
                return html`<div style="display: flex; justify-content: flex-start;">
                    ${label}<omega-tooltip
                        light
                        .icon=${'exclamation-triangle'}
                        .message=${record.getField('detail')}
                    ></omega-tooltip>
                </div>`;
            }
            return label;
        })
        .and.compareFunction((a, b) => {
            const labelA = Object.keys(JobStatusType).find(k => JobStatusType[k] === a);
            const labelB = Object.keys(JobStatusType).find(k => JobStatusType[k] === b);
            return (labelA > labelB) - (labelA < labelB);
        }),
});

const actions = [
    {
        label: 'Delete Report',
        action: 'delete',
    },
    {
        label: 'Download Report',
        action: 'download',
        visibleWhen: record => record.getField('jobStatusType') === JobStatusType.Completed,
    },
];

export const columns = [
    {
        type: 'checkbox',
        field: 'selected',
    },
    {
        field: 'reportName',
        label: 'Report Name',
        type: 'command',
        action: 'clickReportName',
    },
    {
        field: 'reportType',
        label: 'Report Type',
    },
    {
        field: 'accountName',
        label: 'Account',
    },
    {
        field: 'runDate',
        label: 'Run Date',
    },
    {
        field: 'jobStatusType',
        label: 'Job Status',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions,
    },
];

export const filters = () => [
    {
        field: 'reportType',
        fieldType: number.with
            .label('Report Type')
            .and.options({
                fetch: async () => {
                    const options = await AchAccountReconciliationService.fetchReportTypes();
                    return options.sort(
                        (a, b) => (a.displayName > b.displayName) - (a.displayName < b.displayName)
                    );
                },
                text: 'displayName',
                value: 'id',
            })
            .and.placeholder('Select Report Type')
            .and.multipleValues()
            .as.tag('omega-select'),
        value: [],
    },
    {
        field: 'accountIds',
        fieldType: string.with
            .label('Accounts')
            .and.options({
                fetch: async () => AchAccountReconciliationService.fetchAccounts(),
                text: item => `${item.name} - ${item.number}`,
                value: 'id',
            })
            .and.multipleValues()
            .as.tag('omega-select'),
        value: [],
    },
    {
        field: 'jobStatusTypeIds',
        fieldType: string.with
            .label('Job Status')
            .and.options(
                Object.keys(JobStatusType).map(key => ({
                    text: key.toString(),
                    value: JobStatusType[key],
                }))
            )
            .and.multipleValues()
            .as.tag('omega-select'),
        value: [],
    },
];
