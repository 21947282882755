import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';

/**
 * Shared column definitions used throughout the issued item creation workflow.
 */
export const issuedItemCreationColumns: OmegaColumnDefinition<IIssuedItem>[] = [
    {
        field: 'type',
        label: 'Type',
    },
    {
        field: 'account',
        label: 'Account Number (Type)',
    },
    {
        field: 'checkNumber',
        label: 'Check Number',
    },
    {
        field: 'dateIssued',
        label: 'Date Issued',
    },
    {
        field: 'checkAmount',
        label: 'Check Amount',
    },
    {
        field: 'payee',
        label: 'Payee',
    },
];
