import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-table.js';
import '@treasury/omega/components/progress/omega-progress.js';
import '@treasury/omega/layouts/omegaSection.js';
import buildFilter from '@treasury/omega/models/build-filter.js';
import primitives from '@treasury/policy/primitives';
import { css, html, LitElement } from 'lit';

class DarkContainer extends LitElement {
    static get properties() {
        return {
            institution: {
                type: String,
            },
            featureFlags: Array,
            tableFilters: Array,
        };
    }

    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
        this.featureFlags = [];
        this.fetchFlags();
    }

    updated(changedProps) {
        if (changedProps.has('tableFilters')) {
            this.featureFlagRecordset.filter = buildFilter(this.tableFilters);
            this.featureFlagRecordset.requestSoftUpdate();
        }
    }

    get institution() {
        return this._institution;
    }

    set institution(value) {
        this._institution = value;
        this.views = {
            /**
             * ACH ROUTES
             */
            ach: {
                group: 'ACH',
                pages: [
                    {
                        name: 'ACH Domestic Payments',
                        route: 'payables.ach.payments.create-dark',
                    },
                    {
                        name: 'ACH Payment Activity',
                        url: `${this.institution}/ach/payment-list`,
                    },
                    {
                        name: 'ACH Templates',
                        route: 'payables.ach.payments.templates',
                    },
                    {
                        name: 'ACH Tax Templates',
                        route: 'payables.ach.payments.tax-templates',
                    },
                    {
                        name: 'ACH File Activity',
                        route: 'payables.ach.fileactivity-list',
                    },
                    {
                        name: 'Account Recon Report Library v2',
                        route: 'ir.accountReconciliation-library-container',
                    },
                    {
                        name: 'ACH Recipients',
                        url: `${this.institution}/dark/recipients`,
                    },
                ],
            },

            /**
             * TRANSFER ROUTES
             */
            transfers: {
                group: 'Transfers',
                pages: [
                    {
                        name: 'Transfer Activity Report',
                        route: 'payables.transferDark',
                    },
                ],
            },

            /**
             * WIRE ROUTES
             */
            wires: {
                group: 'Wires',
                pages: [
                    {
                        name: 'Create From Template',
                        route: 'payables.wire.create-from-template-lit',
                    },
                ],
            },

            /**
             * REPORTING
             */
            reporting: {
                group: 'Reporting',
                pages: [
                    {
                        name: 'Reports: Dashboard',
                        route: 'reports-dashboard',
                    },
                    {
                        name: 'Standard Reports: Current Day Balance',
                        route: 'reports-balance',
                        parameters: { id: 1 },
                    },
                    {
                        name: 'Standard Reports: Prior Day Balance',
                        route: 'reports-balance',
                        parameters: { id: 2 },
                    },
                    {
                        name: 'Standard Reports: Date Range Balance',
                        route: 'reports-balance',
                        parameters: { id: 5 },
                    },
                    {
                        name: 'Standard Reports: Current Day Transactions',
                        route: 'reports-transaction',
                        parameters: { id: 3 },
                    },
                    {
                        name: 'Standard Reports: Prior Day Transactions',
                        route: 'reports-transaction',
                        parameters: { id: 4 },
                    },
                    {
                        name: 'Standard Reports: Date Range Transactions',
                        route: 'reports-transaction',
                        parameters: { id: 6 },
                    },
                    {
                        name: 'Standard Reports: EDI',
                        route: 'reports-edi',
                        parameters: { id: 7 },
                    },
                ],
            },
        };
    }

    async fetchFlags() {
        const flags = await FeatureFlagService.getFeatureFlags();
        this.featureFlags = flags;
        this.featureFlagRecordset = new Recordset(
            { permission: primitives.string.thatIs.readOnly() },
            () => this.featureFlags
        );
        this.featureFlagRecordset.requestUpdate();
    }

    routeClick(e) {
        if (e.url) {
            window.location.href = e.url;
        } else if (e.route) {
            this.routeToLink(e.route, e.parameters);
        }
    }

    async routeToLink(route, parameters) {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        return navService.navigate(route, parameters);
    }

    renderPageButton(view) {
        return html`<omega-button type="primary" @click=${() => this.routeClick(view)}
            >${view.name}</omega-button
        >`;
    }

    renderPageList() {
        return Object.keys(this.views).map(
            key =>
                html`<h3>${this.views[key].group}</h3>
                    ${this.views[key].pages.map(view => this.renderPageButton(view))}`
        );
    }

    renderFeatureList() {
        const rowsPerPage = 100;
        if (this.featureFlagRecordset) {
            return html` <omega-filter-bar
                    .recordset=${this.featureFlagRecordset}
                    @change=${({ detail }) => {
                        this.tableFilters = detail;
                    }}
                ></omega-filter-bar>
                <omega-table
                    .recordset=${this.featureFlagRecordset}
                    .columnDefinitions=${[{ label: 'Flag (Permission)', field: 'permission' }]}
                    .rowsPerPage=${rowsPerPage}
                    .filters=${this.tableFilters}
                ></omega-table>`;
        }
        return html`<omega-progress card></omega-progress>`;
    }

    render() {
        return html`
            <h1>Demo Pages</h1>
            <div class="dark-display">${this.renderPageList()}</div>
            <h1>Feature Flags Enabled</h1>
            <div class="dark-display">${this.renderFeatureList()}</div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dark-display {
                flex-grow: 2;
            }
        `;
    }
}

export default DarkContainer;
window.customElements.define('dark-container', DarkContainer);
