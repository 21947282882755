import { Injectable } from '@jack-henry/frontend-utils/di';
import { SecurityQuestionModelDto, UserModelDto } from '@treasury/api/channel';
import { AccountService } from '@treasury/domain/channel/services';
import TermsAndConditionsService from '@treasury/domain/channel/services/account/terms-and-conditions-service';

@Injectable()
export class Client {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly accountService: AccountService) {}

    login(credentials: {
        institution: string;
        companyId: string;
        loginId: string;
        password: string;
    }) {
        return this.accountService.login(credentials);
    }

    checkLoginCompletion() {
        return this.accountService.checkLoginCompletion();
    }

    saveSecurityQuestions(securityQuestionsPayload: SecurityQuestionModelDto[]) {
        return this.accountService.saveSecurityQuestions(securityQuestionsPayload);
    }

    verifySecurityQuestions(securityQuestionsPayload: SecurityQuestionModelDto[]) {
        return this.accountService.verifySecurityQuestions(securityQuestionsPayload);
    }

    registerSecureToken(credentialId?: string, token?: string, pin?: string) {
        return this.accountService.registerSecureToken(credentialId, token, pin);
    }

    forgotPassword(user: UserModelDto) {
        return this.accountService.forgotPassword(user);
    }

    async sendOneTimePassword(phone: string, method: string, useForBoth: boolean) {
        return this.accountService.sendOneTimePassword(phone, method, useForBoth);
    }

    async validateOneTimePassword(oneTimePassword: string) {
        return this.accountService.validateOneTimePassword(oneTimePassword);
    }

    async setOutOfBandPreferredMethod(method: string) {
        return this.accountService.sendOutOfBandPreferredMethod(method);
    }

    async oobRemindMeLater() {
        return this.accountService.oobRemindMeLater();
    }

    async secureTokenRemindMeLater() {
        return this.accountService.secureTokenRemindMeLater();
    }

    verifyUser(user: UserModelDto) {
        return this.accountService.verifyUser(user);
    }

    passwordRequirements(institution: string) {
        return this.accountService.getPasswordRequirements(institution);
    }

    updatePassword(user: UserModelDto) {
        return this.accountService.updatePassword(user);
    }

    fetchOutOfBandConfig() {
        return this.accountService.getOutOfBandConfig();
    }

    async removeVerificationMethod(methodType: string) {
        return this.accountService.removeVerificationMethod(methodType);
    }

    completeOutOfBandConfig() {
        return this.accountService.completeOutOfBandConfig();
    }

    getTermsAndConditions() {
        return TermsAndConditionsService.getTermsAndConditions();
    }
}
