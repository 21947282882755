/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
import { css, html, LitElement } from 'lit';

class OmegaTimeInput extends LitElement {
    static get properties() {
        return {
            minuteStep: Number,
            value: { type: String, reflect: true }, // time, in HH:mm (24 hour format)
            disabled: { type: Boolean, reflect: true },
            legacyRendering: Boolean,
            timeObject: Object,
        };
    }

    constructor() {
        super();
        this._value = '00:00';
        this.minuteStep = 15;
        this.disabled = false;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-time-input--default-legacy-rendering',
        };
    }

    firstUpdated() {
        if (this.legacyRendering) {
            this.minuteStep = this.findNormalizedStep(this.minuteStep);
        }
    }

    get value() {
        return this._value;
    }

    set value(v) {
        this._value = v ?? '00:00';
        this.timeObject = this.findNormalizedTime(this._value);
    }

    findNormalizedTime(time) {
        const valid = typeof time === 'string' && time.length === 5;
        const twentyFourHourTime = valid ? this.parseTimeString(time) : { hours: 0, minutes: 0 };

        return this.mapTo12HourTime(twentyFourHourTime);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    parseTimeString(timeString) {
        const hourIndex = timeString[0] === '0' ? 1 : 0;
        let hours = parseInt(timeString.slice(hourIndex, 2));
        if (Number.isNaN(hours) || hours < 0) hours = 0;
        if (hours > 23) hours = 23;

        const minuteIndex = timeString[3] === '0' ? 4 : 3;
        let minutes = parseInt(timeString.slice(minuteIndex, 5));
        if (Number.isNaN(minutes) || minutes < 0) minutes = 0;
        if (minutes > 59) minutes = 59;

        return { hours, minutes };
    }

    mapTo12HourTime(twentyFourHourObject) {
        const period = twentyFourHourObject.hours >= 12 ? 'PM' : 'AM';
        // eslint-disable-next-line prefer-destructuring
        let hours = twentyFourHourObject.hours;
        if (hours > 12) hours -= 12;
        if (hours === 0) hours = 12;
        const hourString = String(hours).padStart(2, '0');
        const minuteString = String(twentyFourHourObject.minutes).padStart(2, '0');

        return { hours: hourString, minutes: minuteString, period };
    }

    convertTo24HourFormat(timeObject) {
        const hours = parseInt(timeObject?.hours) ?? 0;
        const hourOffset = timeObject?.period === 'PM' && hours < 12 ? 12 : 0;
        const normalizedHours =
            timeObject?.period === 'AM' && hours === 12 ? 0 : hours + hourOffset;
        const hourString = String(normalizedHours).padStart(2, '0');
        const minuteString = timeObject?.minutes.padStart(2, '0');
        return `${hourString}:${minuteString}`;
    }

    findNormalizedStep(step) {
        let normalizedStep = step == null ? 15 : step;
        normalizedStep = step <= 30 ? step : 30;
        normalizedStep = normalizedStep <= 1 ? 1 : Math.trunc(normalizedStep / 5) * 5;
        const valid = [1, 5, 10, 15, 30].some(x => x === normalizedStep);
        return valid ? normalizedStep : 15;
    }

    handleLegacyInputChangeCommitted() {
        this.value = this.convertTo24HourFormat(this.timeObject);
        this.dispatchEvent(new CustomEvent('changed', { detail: this.value }));
    }

    handleChange() {
        this.dispatchEvent(new CustomEvent('changed', { detail: this.value }));
    }

    handleHoursChanged(e) {
        const adjustedTime = this.normalizeHourInput(this.timeObject, e.target.valueAsNumber);
        const hourString = String(adjustedTime.hours).padStart(2, '0');
        e.target.value = hourString;
        this.timeObject.hours = hourString;
        this.timeObject.period = adjustedTime.period;
        this.handleLegacyInputChangeCommitted();
    }

    handleMinutesChanged(e) {
        const adjustedMinutes = this.clamp(e.target.valueAsNumber, 0, 59);
        const minutesString = String(adjustedMinutes).padStart(2, '0');
        e.target.value = minutesString;
        this.timeObject.minutes = minutesString;
        this.handleLegacyInputChangeCommitted();
    }

    normalizeHourInput(previousTime, updatedHours) {
        // The following is needed for the legacy implementation to make both text and up/down behave adequately
        // It can be greatly simplified if the design is changed to use fixed selection lists.
        const previousHours = parseInt(previousTime.hours);
        const previousPeriod = previousTime.period;
        // Ignore text input that is out of range (no change)
        if (Number.isNaN(updatedHours) || updatedHours < 0 || updatedHours > 13)
            return { hours: previousHours, period: previousPeriod };

        // Handle edge-case transitions starting in PM
        if (previousPeriod === 'PM') {
            // Transition from noon
            if (previousHours === 12) {
                if (updatedHours > 12) {
                    return { hours: 1, period: 'PM' };
                }
                if (updatedHours < 12 && updatedHours > 0) {
                    return { hours: updatedHours, period: 'AM' };
                }
                if (updatedHours === 0) {
                    return { hours: 12, period: 'AM' };
                }
            }
            // Otherwise, enforce maximum hours
            if (previousHours <= 11 && updatedHours > 11) {
                return { hours: 11, period: 'PM' };
            }
            // Transition from PM hour to noon
            if (previousHours < 12 && updatedHours < 1) {
                return { hours: 12, period: 'PM' };
            }
        }
        // Handle edge-case transitions starting in AM
        if (previousPeriod === 'AM') {
            // Transition midnight to AM hours
            if (previousHours === 12) {
                // enforce minimum hours
                if (updatedHours === 0 || updatedHours === 11) {
                    return { hours: 12, period: 'AM' };
                }
                if (updatedHours < 11 && updatedHours > 0) {
                    return { hours: updatedHours, period: 'AM' };
                }
                if (updatedHours > 12) {
                    return { hours: 1, period: 'AM' };
                }
            }
            // Transition AM to PM
            if (previousHours < 12 && updatedHours > 12) {
                return { hours: 1, period: 'PM' };
            }
            if (previousHours === 11 && updatedHours === 12) {
                return { hours: 12, period: 'PM' };
            }
            // Transition to midnight
            if (updatedHours < 1) {
                return { hours: 12, period: 'AM' };
            }
        }

        // Fall through, updated hours
        updatedHours = this.clamp(updatedHours, 1, 12);
        return { hours: updatedHours, period: previousPeriod };
    }

    handlePeriodClick() {
        let newPeriod = 'AM';
        if (this.timeObject?.period === 'AM') {
            newPeriod = 'PM';
        }
        this.timeObject = { ...this.timeObject, period: newPeriod };
        this.handleLegacyInputChangeCommitted();
    }

    clamp(val, min, max) {
        if (Number.isNaN(val) || Number.isNaN(min) || Number.isNaN(max)) return NaN;
        // eslint-disable-next-line no-nested-ternary
        return val > max ? max : val < min ? min : val;
    }

    renderLegacyUI() {
        return html`
            <div class="omega-legacy-time-input" name="timeInput">
                <input
                    name="hours"
                    type="number"
                    class="omega-legacy-time-input-box"
                    min="0"
                    max="13"
                    .value=${this.timeObject?.hours}
                    ?disabled=${this.disabled}
                    @change=${this.handleHoursChanged}
                />
                :
                <input
                    name="minutes"
                    type="number"
                    class="omega-legacy-time-input-box"
                    min="0"
                    max="59"
                    step="${this.minuteStep}"
                    .value=${this.timeObject?.minutes}
                    ?disabled=${this.disabled}
                    @change=${this.handleMinutesChanged}
                />
                <input
                    name="period"
                    type="button"
                    class="omega-period-input-box"
                    .value="${this.timeObject?.period}"
                    @click=${this.handlePeriodClick}
                    ?disabled=${this.disabled}
                />
            </div>
        `;
    }

    render() {
        if (this.legacyRendering) return this.renderLegacyUI();
        return html`
            <div class="omega-time-input">
                <input
                    name="timeInput"
                    type="time"
                    class="omega-time-input-box"
                    .value="${this.value}"
                    ?disabled="${this.disabled}"
                    @change=${this.handleChange}
                />
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: relative;
                color: var(--omega-ux-text-color, #000);
            }

            * {
                box-sizing: border-box;
            }

            input {
                height: 34px;
                text-align: middle;
                border: 1px solid var(--omega-input-default-border);
                border-radius: var(--omega-input-border-radius);
                font-size: var(--omega-input);
            }

            input:active {
                border-color: var(--omega-input-active-border);
            }

            input:hover {
                box-shadow: 0 0 8px 2px rgb(33 144 203 / 50%); /* #3390cb*  TODO Need to reconcile the hover box-shadows across all omega input components */
            }

            .omega-legacy-time-input {
                display: inline-block;
                width: 186px;
            }

            .omega-time-input {
                display: inline-block;
                width: 150px;
            }

            .omega-legacy-time-input-box {
                width: 48px;
                padding: 6px 0px 6px 14px;
            }

            .omega-time-input-box {
                width: 120px;
                padding: 6px 0px 6px 12px;
            }

            .omega-period-input-box {
                width: 42px;
                padding: 6px 6px 6px 6px;
                margin-left: 10px;
                background-color: white;
            }
        `;
    }
}

customElements.define('omega-time-input', OmegaTimeInput);
export default OmegaTimeInput;
