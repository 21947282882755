AccountDownloadController.$inject = [
    '$scope',
    '$modalInstance',
    'account',
    'collection',
    'downloadService',
    'FileSaver',
    'modalService',
];

export default function AccountDownloadController(
    $scope,
    $modalInstance,
    account,
    collection,
    downloadService,
    FileSaver,
    modalService
) {
    $scope.account = account;
    $scope.collection = collection;
    $scope.downloadOptions = {
        dateOperator: 'Last 7 Days',
        format: '',
        downloadAnotherAccount: false,
        fromDate: null,
        toDate: null,
    };
    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.downloadAnotherAccount = function () {
        $scope.downloadOptions.downloadAnotherAccount = true;
        $scope.accountList = collection;
    };

    // this will update the company
    $scope.save = function () {
        let fromDate = moment().format('MM-DD-YYYY');
        let toDate = moment().format('MM-DD-YYYY');
        switch ($scope.downloadOptions.dateOperator) {
            case 'Last 7 Days':
                fromDate = moment().add('d', -7).format('MM-DD-YYYY');
                toDate = moment().format('MM-DD-YYYY');
                break;
            case 'Last 15 Days':
                fromDate = moment().add('d', -15).format('MM-DD-YYYY');
                toDate = moment().format('MM-DD-YYYY');
                break;
            case 'Last 30 Days':
                fromDate = moment().add('d', -30).format('MM-DD-YYYY');
                toDate = moment().format('MM-DD-YYYY');
                break;
            case 'Date Range':
                fromDate = $scope.downloadOptions.fromDate;
                toDate = $scope.downloadOptions.toDate;
                break;
        }
        downloadService
            .download({
                downloadFormats: $scope.formatList,
                pageId: 'AccountTransactionDetail',
                outputType: $scope.downloadOptions.format,
                filterModel: {
                    fromDate,
                    toDate,
                    id: account.id,
                    type: account.type,
                    page: 'AccountTransactionDetail',
                },
            })
            .then(response => {
                const blob = new Blob([response], {
                    type: getMimeType($scope.downloadOptions.format),
                });
                const currentDate = new Date();
                if ($scope.downloadOptions.format === 'QFX' && blob.size < 3) {
                    $modalInstance.close();
                    const modalOptions = {
                        isAlert: true,
                        closeButtonText: '',
                        actionButtonText: 'OK',
                        headerText: 'No Transactions Available',
                        summaryText:
                            'There are no transactions available for download, please update your date range selection.',
                        time: `${currentDate.toDateString()}  ${currentDate.toTimeString()}`,
                        submit() {
                            cancelModal.dismiss();
                        },
                    };
                    const cancelModal = modalService.showModal({}, modalOptions);
                    return;
                }
                FileSaver.saveAs(
                    blob,
                    `AccountTransactionDetail.${$scope.downloadOptions.format.toLowerCase()}`
                );
                $modalInstance.close();
            });
    };

    function getMimeType(outputType) {
        switch (outputType.toLowerCase()) {
            case 'pdf':
                return 'application/pdf';
            case 'csv':
                return 'text/csv';
            case 'ofx':
                return 'application/x-ofx';
            case 'qfx':
                return 'application/x-qfx';
            default:
                return 'application/octet-stream';
        }
    }

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    function init() {
        $scope.dateOperatorList = ['Last 7 Days', 'Last 15 Days', 'Last 30 Days', 'Date Range'];
        $scope.formatList = ['PDF', 'OFX'];
        getQfxEnabledForCompanyFlag();
    }

    function getQfxEnabledForCompanyFlag() {
        downloadService.getQfxEnabledFlag().then(response => {
            if (response.enabledFlag) {
                $scope.formatList.push('QFX');
            }
        });
    }

    init();
}
