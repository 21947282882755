import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';

/**
 * @typedef { import('@treasury/api/channel').StpModelDto } StpModelDto
 */

/**
 * 
 * @param { ng.resource.IResourceService } $resource 
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi 
 * @returns 
 */
export function StpService($resource, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}stp/`;

    return {
        approve,
        batchApprove,
        batchReject,
        cancel,
        create,
        getAccounts,
        getFilterAccounts,
        getAll,
        reject,
        search,
    };

    /**
     * @param { StpModelDto} model
     */
    function create(model) {
        const resource = $resource(`${resourceUrl}create`);

        /**
         * @type { Promise<StpModelDto> } 
         */
        const p = resource.save(model).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
            const { id, account, status, checkAmount, totalApprovalsRequired } = model;
            analytics.track(AnalyticsEvent.StopPaymentCreated, {
                id: id.toString(),
                accountId: account.id.toString(),
                status,
                checkAmount: checkAmount || 0,
                approvalsRequiredCount: totalApprovalsRequired,
            });
        });

        return p;
    }

    function getAccounts() {
        return $resource(`${resourceUrl}getAccounts`).query().$promise;
    }

    function getFilterAccounts() {
        return $resource(`${resourceUrl}getFilterAccounts`).query().$promise;
    }

    function getAll() {
        return $resource(`${resourceUrl}getAll`).query().$promise;
    }

    /**
     * 
     * @param { import('@treasury/api/channel').LookupListModelDto} batch 
     * @returns 
     */
    function batchApprove(batch) {
        const resource =  $resource(
            `${resourceUrl}batchApprove`,
            {},
            { save: { method: 'POST', isArray: true } }
        );

        /**
         * @type { Promise<StpModelDto[]> }
         */
        const p = resource.save(batch).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([payments, analytics]) => {
            payments.forEach(({ id }) => {
                analytics.track(AnalyticsEvent.StopPaymentApproved, {
                    id: id.toString()
                })
            });
        });

        return p;
    }

    function batchReject(batch) {
        return $resource(
            `${resourceUrl}batchReject`,
            {},
            { save: { method: 'POST', isArray: true } }
        ).save(batch).$promise;
    }

    /**
     * 
     * @param { number } id 
     * @param { string } comments
     */
    function approve(id, comments) {
        const resource = $resource(`${resourceUrl}:id/approve`, { id });
        /**
         * @type { Promise<StpModelDto> }
         */
        const p = resource.save({ key: id, value: comments })
            .$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
            analytics.track(AnalyticsEvent.StopPaymentApproved, {
                id: id.toString()
            });
        });

        return p;
    }

    function reject(id, comments) {
        return $resource(`${resourceUrl}:id/reject`, { id }).save({ key: id, value: comments })
            .$promise;
    }

    function cancel(id, comments) {
        return $resource(`${resourceUrl}:id/cancel`, { id }).save({ key: id, value: comments })
            .$promise;
    }

    function search(model) {
        return $resource(
            `${resourceUrl}search`,
            {},
            { search: { method: 'POST', isArray: true } }
        ).search(model).$promise;
    }
}

StpService.$inject = ['$resource', 'TmDi'];