import { DiContainer } from '@jack-henry/frontend-utils/di';
import { OpenTimeAccessDialogFn } from '@treasury/domain/services/logout';
import { Observable, countdown, noOp } from '@treasury/utils';
import { observe } from '@treasury/utils/lit-helpers';
import { html } from 'lit';
import { OmegaDialogService } from '../../services/omega-dialog';

const title = 'Access Timeout';
const confirmButtonLabel = 'OK';

function createPromptTemplate(countdown$: Observable<number>) {
    return html`<p>
        Your allowed time access will expire in ${observe(countdown$)} seconds and you will be
        logged out. Click ${confirmButtonLabel} if you wish to continue working for the remaining
        time.
    </p>`;
}

export const openTimeAccessDialog: OpenTimeAccessDialogFn = async function openTimeAccessDialog(
    config
) {
    const { warningSeconds, logoSource } = config;
    const countdown$ = countdown(warningSeconds);
    const template = createPromptTemplate(countdown$);
    const di = await DiContainer.getInstance();
    const dialogService = di.get(OmegaDialogService);

    const { closed, close } = dialogService.open(template, title, {
        logoSource,
        buttons: {
            confirm: {
                label: confirmButtonLabel,
            },
            cancel: null,
        },
    });

    return {
        closed: closed.then(noOp),
        close: () => close(),
    };
};
