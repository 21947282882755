export const CHECK_EXCEPTION_START = 'checkExceptionsStart';
export const CHECK_EXCEPTION_CUTOFF = 'checkExceptionsCutoff';
export const CHECK_EXCEPTION_CUTOFF_MINUS_30 = 'checkExceptionsCutoff-30Minutes';
export const ACH_EXCEPTION_START = 'achExceptionStart';
export const ACH_EXCEPTION_CUTOFF = 'achExceptionCutoff';
export const ACH_EXCEPTION_CUTOFF_MINUS_30 = 'achExceptionCutoff-30Minutes';
export const EXCEPTION_REASONS = [
    'No Reason',
    'Account mismatch',
    'Amount mismatch',
    'Dormant item',
    'Duplicate item',
    'Maximum amount exceeded',
    'Paid item without issue (post all)',
    'Paid item without issue (post none)',
    'Payee mismatch',
    'Stale dated item',
    'Stop payment',
    'Void item',
    'Zero/Blank serial number',
];
export const RETURN_REASON_OPTION = 'PositivePay.CheckException.AllowReturnReason';
export const CORRECTION_REQUEST_OPTION = 'PositivePay.CheckException.AllowCorrectionRequest';
export const ACCEPTED_ATTACHMENT_EXTENSIONS = [
    '.pdf',
    '.jpg',
    '.jpeg',
    '.jpe',
    '.jfif',
    '.jif',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
];
