// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant(
    'achExceptionDecisionStatusTypes',
    Object.freeze({
        noDecision: 0,
        pay: 1,
        return: 2,
        all: 3,
    })
);
