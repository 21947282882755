import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/dialog.js';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-additional-info.js';

class AchInternationalAddendaDialog extends LitElement {
    static get properties() {
        return {
            selectedRecord: Object,
            addenda: Array,
            editable: Boolean,
        };
    }

    constructor() {
        super();
        Object.assign(this, {
            selectedRecord: null,
            addenda: [],
            editable: false,
        });
    }

    get isEditable() {
        return (
            this.selectedRecord.getField('step') === 0 ||
            (this.selectedRecord.getField('step') === 1 &&
                this.selectedRecord.getField('nachaUpload'))
        );
    }

    renderReadOnlyAddenda(addenda = []) {
        return addenda.map(
            (a, i) => html` <div class="display-addenda"><span>Addenda ${i + 1}:</span> ${a}</div> `
        );
    }

    render() {
        if (!this.selectedRecord) return nothing;
        const prevAddenda = this.selectedRecord.getField('addenda').slice();
        return html`<omega-dialog
            id="addenda-dialog"
            .dialogTitle=${'Addenda'}
            open
            @close=${() => {
                this.selectedRecord.setField('addenda', prevAddenda);
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <div slot="content">
                ${this.isEditable
                    ? html` <div id="addenda-information">
                              You can add up to 2 addenda references for each recipient
                          </div>
                          <omega-field
                              field="addenda"
                              label=""
                              .record=${this.selectedRecord}
                          ></omega-field>`
                    : this.renderReadOnlyAddenda(this.addenda)}
            </div>

            ${this.isEditable
                ? html` <omega-button
                      slot="actions"
                      type="primary"
                      @click=${() => {
                          this.dispatchEvent(new CustomEvent('close'));
                      }}
                      >Save</omega-button
                  >`
                : nothing}

            <omega-button
                slot="actions"
                @click=${() => {
                    this.selectedRecord.setField('addenda', prevAddenda);
                    this.dispatchEvent(new CustomEvent('close'));
                }}
                >Cancel</omega-button
            >
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            div[slot='content'] {
                padding: 16px;
            }
            .display-addenda {
                font-size: 13px;
                margin: 8px;
            }
            .display-addenda > span {
                font-weight: bold;
            }
            #addenda-information {
                position: absolute;
                top: 45px;
            }
        `;
    }
}

customElements.define('ach-international-addenda-dialog', AchInternationalAddendaDialog);
