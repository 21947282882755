/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpClientCached } from '@treasury/core/http';

const ACHBANKS_ENDPOINT = 'achbanks';
const ACHCOMPANIES_ENDPOINT = 'achCompanies';

async function resetBanks() {
    const http = await TmHttpClient.getInstance();
    if (!(http instanceof TmHttpClientCached)) {
        throw new Error('Cannot reset HTTP cache. The client does not implement caching.');
    }

    http.resetCachedValue(ACHBANKS_ENDPOINT, {
        method: 'GET',
    });
}

async function getAllAchBanks() {
    return (await TmHttpClient.getInstance()).request(ACHBANKS_ENDPOINT, {
        method: 'GET',
        maxAgeInSeconds: 600000000,
    });
}

async function getAllAchCompanies() {
    return (await TmHttpClient.getInstance()).request(ACHCOMPANIES_ENDPOINT, {
        method: 'GET',
        maxAgeInSeconds: 600,
    });
}

export { resetBanks, getAllAchBanks, getAllAchCompanies };
