import { AuthenticationService } from '@treasury/domain/services/authentication';

/**
 *
 * @param { ng.IScope } $scope
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
// eslint-disable-next-line @treasury/filename-match-export
export default function VerifySecurityMethodsController(
    $scope,
    $modal,
    $timeout,
    accountService,
    securityService,
    modalService,
    $stateParams,
    TmDi
) {
    $scope.config = null;
    $scope.pinNumber = null;
    $scope.isRecollection = false;
    $scope.textMethodSuccessful = false;
    $scope.callMethodSuccessful = false;

    $scope.finish = finish;
    $scope.formatPhoneNumber = formatPhoneNumber;
    $scope.remindMeLater = remindMeLater;
    $scope.removeMethod = removeMethod;
    $scope.skip = skip;
    $scope.successfulVerification = successfulVerification;
    $scope.isRemindMeLaterAvailable = isRemindMeLaterAvailable;
    $scope.verifyMethod = verifyMethod;
    $scope.showCallPin = false;
    $scope.showTextPin = false;
    $scope.challengeMethodType = $stateParams.challengeMethodType;
    $scope.isUisEnabled = false;

    init();

    async function init() {
        const authenticationService = await TmDi.getAsync(AuthenticationService);
        const uisResp = await authenticationService.uisMetadataPromise;
        $scope.isUisEnabled = uisResp.isUisEnabled;

        const response = await getSecurityConfig();
        $scope.allowActionBypass = response.allowActionBypass;
        if (response.textVerificationMethod || response.callVerificationMethod) {
            $scope.isRecollection = true;
        }
    }

    function verifyMethod(method, methodType) {
        const modalInstance = $modal.open({
            template: require('../views/verifySecurityMethodModal.html'),
            size: 'sm',
            controller: 'VerifySecurityMethodModalController',
            backdrop: 'static',
            resolve: {
                method() {
                    return window.angular.copy(method);
                },
                methodType() {
                    return methodType;
                },
                existingPinNumber() {
                    return $scope.pinNumber;
                },
                otherVerificationStatus() {
                    if ($scope.successfulVerification()) {
                        return 'Verified';
                    }
                    if (methodType === 'Text') {
                        return $scope.config.callVerificationMethod
                            ? 'ExistsButNotVerified'
                            : 'DoesNotExist';
                    }
                    if (methodType === 'Call') {
                        return $scope.config.callVerificationMethod
                            ? 'ExistsButNotVerified'
                            : 'DoesNotExist';
                    }
                },
                usePin() {
                    return $scope.config.usePin;
                },
                challengeMethodType() {
                    return $scope.challengeMethodType;
                },
            },
        });
        modalInstance.result.then(result => {
            $scope.pinNumber = result.pinNumber;
            if (methodType === 'Text') {
                $scope.textMethodSuccessful = true;
                if (result.showNext) {
                    $timeout(() => {
                        verifyNextMethod();
                    }, 250);
                }
            } else if (methodType === 'Call') {
                $scope.callMethodSuccessful = true;
                if (result.showNext) {
                    $timeout(() => {
                        verifyNextMethod();
                    }, 250);
                }
            }
            getSecurityConfig();
        });
    }

    function verifyNextMethod() {
        if ($scope.textMethodSuccessful && !$scope.callMethodSuccessful) {
            verifyMethod($scope.config.callVerificationMethod, 'Call');
        }

        if (!$scope.textMethodSuccessful && $scope.callMethodSuccessful) {
            verifyMethod($scope.config.textVerificationMethod, 'Text');
        }
    }

    function removeMethod(methodType) {
        let modalOptions;
        let modalInstance;

        modalOptions = {
            bodyText: 'Are you sure you want to remove this phone number?',
            headerText: 'Confirm Phone Number Removal',
            actionButtonText: 'Remove',
            closeButtonText: 'Cancel',
            submit(result) {
                finishRemoval(methodType);
                modalInstance.close(result);
            },
        };
        modalInstance = modalService.showModal({}, modalOptions);
    }

    function finish() {
        securityService.completeOobConfig('Done').then(async response => {
            await accountService.goToNextStep();
        });
    }

    function remindMeLater() {
        securityService.completeOobConfig('Remind').then(async response => {
            await accountService.goToNextStep();
        });
    }

    function skip() {
        securityService.completeOobConfig('Skip').then(async response => {
            await accountService.goToNextStep();
        });
    }

    function successfulVerification() {
        return $scope.textMethodSuccessful || $scope.callMethodSuccessful;
    }

    function isRemindMeLaterAvailable() {
        if ($scope.isRecollection) return false;
        if (successfulVerification()) return false;
        return $scope.allowActionBypass;
    }

    function formatPhoneNumber(phoneNumber) {
        return [phoneNumber.slice(0, 3), phoneNumber.slice(3, 6), phoneNumber.slice(6, 10)].join(
            '-'
        );
    }

    $scope.toggleShowCallPin = function () {
        $scope.showCallPin = !$scope.showCallPin;
    };

    $scope.toggleShowTextPin = function () {
        $scope.showTextPin = !$scope.showTextPin;
    };

    //
    // Private

    function getSecurityConfig() {
        return securityService.getConfig().then(response => {
            $scope.config = response;
            return response;
        });
    }

    function finishRemoval(methodType) {
        securityService.removeMethod(methodType).then(() => {
            if (methodType === 'Text') {
                $scope.config.textVerificationMethod = null;
            } else if (methodType === 'Call') {
                $scope.config.callVerificationMethod = null;
            }
        });
    }
}

VerifySecurityMethodsController.$inject = [
    '$scope',
    '$modal',
    '$timeout',
    'accountService',
    'securityService',
    'modalService',
    '$stateParams',
    'TmDi',
];
