import { string, boolean, number } from '@treasury/policy/primitives';

export const fields = {
    name: string.thatIs.readOnly(),
    isCustomReport: boolean.with.formatter(value =>
        value?.toLowerCase() === 'yes' ? 'CUSTOM' : 'STANDARD'
    ),
    isFavoriteReport: boolean,
    id: number,
    type: string,
};
export const columns = [
    {
        type: 'command',
        label: 'Report Name',
        field: 'name',
        action: 'routeToReport',
    },
    {
        label: 'Type',
        type: 'read-only',
        field: 'isCustomReport',
    },
    {
        type: 'checkbox',
        label: 'Favorite',
        field: 'isFavoriteReport',
    },
    {
        label: 'Delete Report',
        type: 'icon',
        action: 'deleteReport',
        conditional: record => {
            if (record.getField('isCustomReport')) {
                return { icon: 'trash', label: 'Delete Report', action: 'deleteReport' };
            }
            return false;
        },
    },
];
export const filters = [
    {
        field: 'isFavoriteReport',
        fieldType: boolean.with.label('Favorite'),
        value: false,
    },
    {
        field: 'isCustomReport',
        fieldType: boolean.with.label('Custom'),
        value: false,
    },
];
