import { getChannelAPIPath } from '@treasury/core/http';

InformationReportsService.$inject = ['$resource', '$state'];

export default function InformationReportsService($resource, $state) {
    const resourceUrl = `${getChannelAPIPath()}informationreports/`;
    return {
        getReportsOfType,
        getReports,
        getFilter,
        favoriteFilter,
        saveFilter,
        updateFilter,
        deleteFilter,
        updateSequence,
        runReport,
    };

    function updateSequence(reportIds) {
        return $resource(
            `${resourceUrl}sequence`,
            {},
            { update: { method: 'POST', isArray: true } }
        ).update(reportIds).$promise;
    }

    function getReports() {
        return $resource(resourceUrl).query().$promise;
    }

    function getReportsOfType(type) {
        return $resource(`${resourceUrl}:type`, { type }).query().$promise;
    }

    function getFilter(reportId) {
        return $resource(`${resourceUrl}:id`, { id: reportId }).get().$promise;
    }

    function favoriteFilter(reportId, value) {
        return $resource(`${resourceUrl}:id/favorite?value=:value`, {
            id: reportId,
            value,
        }).get().$promise;
    }

    function saveFilter(filterMessage) {
        return $resource(resourceUrl).save(filterMessage).$promise;
    }

    function updateFilter(filterMessage) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: filterMessage.id !== 0 ? filterMessage.id : 10 },
            { update: { method: 'PUT' } }
        );
        return resource.update(filterMessage).$promise;
    }

    function deleteFilter(reportId) {
        return $resource(`${resourceUrl}:id`, { id: reportId }).delete().$promise;
    }

    function runReport(report) {
        if (report.reportType === 'Transaction') {
            if (report.dateType === 'Current Day' || report.dateType === 'Prior Day') {
                $state.go('ir.dt', { id: report.id });
            } else {
                $state.go('ir.drt', { id: report.id });
            }
        } else if (report.reportType === 'Balance') {
            if (report.dateType === 'Current Day' || report.dateType === 'Prior Day') {
                $state.go('ir.db', { id: report.id });
            } else {
                $state.go('ir.drb', { id: report.id });
            }
        } else if (report.reportType === 'EDI') {
            $state.go('ir.edi', { id: report.id });
        }
    }
}
