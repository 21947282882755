/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import { TmHttpClient, TmHttpClientCached } from '@treasury/core/http';
import { buildQuery } from '@treasury/utils';
import { UserRequests } from '../users/users.js';

/**
 * entitlements for features and users across Treasury
 */
export class EntitlementsRequests {
    static async getEntitlements() {
        return (await TmHttpClient.getInstance()).request(`entitlements`, {
            method: 'GET',
            maxAgeInSeconds: 3600,
        });
    }

    static async resetEntitlementsCache() {
        const http = await TmHttpClient.getInstance();
        if (!(http instanceof TmHttpClientCached)) {
            throw new Error('Cannot reset HTTP cache. The client does not implement caching.');
        }

        return http.resetCachedValue(`entitlements`, {
            method: 'GET',
        });
    }

    /**
     *
     * @param {*} permission : the string permission name from the user's entitlements list
     */
    static async fetchEntitlement(permission) {
        const entitlements = await this.getEntitlements();
        return entitlements.some(e => e.permission === permission);
    }

    static async fetchEntitlementByFeatureType(productFeatureType) {
        const id = await UserRequests.fetchCurrentUserId();
        const query = buildQuery({ productFeatureType });
        return (await TmHttpClient.getInstance()).request(
            `users/${id}/productEntitlements?${query}`,
            {
                method: 'GET',
            }
        );
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default EntitlementsRequests;
