EdiController.$inject = [
    '$scope',
    '$rootScope',
    '$stateParams',
    'informationReportsService',
    'companyAccountsService',
    '$state',
    'toaster',
    '$modal',
    'generateReportService',
    '$anchorScroll',
    '$location',
    'dateFilterService',
    'DatePeriod',
];

export default function EdiController(
    $scope,
    $rootScope,
    $stateParams,
    informationReportsService,
    companyAccountsService,
    $state,
    toaster,
    $modal,
    generateReportService,
    $anchorScroll,
    $location,
    dateFilterService,
    DatePeriod
) {
    $scope.savedReport = {
        postedTransactions: true,
    };
    $scope.allAccountsList = [];
    $scope.isCollapsed = false;
    $scope.ediReport = null;
    $scope.filterHeight = 400;
    $scope.hasReportBeenGenerated = false;
    $scope.filterOptions = {
        orderByField: '',
        reverseSort: false,
    };
    $scope.reportId = $stateParams.id;
    $scope.currentDate = moment().format('MM/DD/YYYY');
    $scope.tempSavedReport = {};
    $scope.$watch('sidebarContainerController', checkExpandFilter);

    init();

    function init() {
        loadDateTypeList();
        loadStandardReports(loadMyReports, loadReportFilter, false);
        loadAllAccountsList();
        loadAmountOperatorList();
        determineFilterHeight();
    }

    $scope.processReportGeneration = function (report) {
        $scope.savedReport.selectedLabel = report.name;
        if (report.id !== $scope.savedReport.id) {
            $scope.runReport(report);
        }
    };

    $scope.runReport = function (report) {
        informationReportsService.runReport(report);
    };

    $scope.getRowSpan = function (ediData) {
        if (!!ediData.ediAddenda && ediData.ediAddenda.length > 0) {
            return ediData.ediAddenda.length;
        }
        return 1;
    };

    $scope.getNestedRowClass = function (index) {
        if (index % 2 === 0) {
            return 'odd';
        }
        return 'even';
    };

    function loadAllAccountsList() {
        companyAccountsService
            .getCompanyAccountsForProductFeature('', 'InformationReporting', 'edi')
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                $scope.allAccountsList = response;
                angular.forEach($scope.allAccountsList, item => {
                    const tmpItem = angular.copy(item);
                    item.account = tmpItem;
                    item.displayValue = item.showAccountNickName ? item.name : item.number;
                });
            });
    }

    $scope.reset = function () {
        loadReportFilter(true);
    };

    $scope.generate = function () {
        if ($scope.savedReport.postedTransactions) {
            const period = dateFilterService.getPeriodDates(
                $scope.savedReport.dateType,
                $scope.savedReport.fromDate,
                $scope.savedReport.toDate
            );
            if ($scope.savedReport.dateType === DatePeriod.TODAY) {
                $scope.savedReport.specificDate = period.formattedToDate;
            } else {
                $scope.savedReport.toDate = period.formattedToDate;
                $scope.savedReport.fromDate = period.formattedFromDate;
            }
        } else {
            $scope.savedReport.fromDate = $scope.currentDate;
        }
        generateReportService.generateReport('edi', $scope.savedReport).then(response => {
            $scope.ediReport = response;
        });
    };

    function loadReportFilter(isReset) {
        informationReportsService.getFilter($scope.reportId).then(response => {
            if (
                response.fromDate !== null &&
                response.fromDate !== undefined &&
                response.fromDate.length > 0
            ) {
                response.fromDate = formatDate(response.fromDate);
            }
            if (
                response.toDate !== null &&
                response.toDate !== undefined &&
                response.toDate.length > 0
            ) {
                response.toDate = formatDate(response.toDate);
            }
            $scope.savedReport = angular.copy(response);
            if (
                $scope.savedReport &&
                $scope.savedReport.accounts &&
                $scope.savedReport.accounts.length === 1 &&
                $scope.savedReport.accounts[0].account
            ) {
                $scope.savedReport.account = $scope.savedReport.accounts[0].account;
            }
            $scope.savedReport.selectedLabel = response.name;
            determineFilterHeight();
            $scope.tempSavedReport = angular.copy($scope.savedReport);
        });
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function loadStandardReports(loadMyReportsCallback, filterReportsCallback, isReset) {
        informationReportsService.getReportsOfType('standard').then(response => {
            $scope.standardReportsList = response;
            if (loadMyReportsCallback) {
                loadMyReportsCallback(filterReportsCallback, isReset);
            }
        });
    }

    function loadMyReports(filterReportsCallback, isReset) {
        informationReportsService.getReportsOfType('myreport').then(response => {
            $scope.currentUserSavedReports = response;
            if (filterReportsCallback) {
                filterReportsCallback(isReset);
            }
        });
    }

    function loadAmountOperatorList() {
        $scope.amountOperatorList = [
            {
                name: '--Select Amount Type--',
            },
            {
                name: 'Specific Amount',
            },
            {
                name: 'Between',
            },
            {
                name: 'Less Than',
            },
            {
                name: 'Greater Than',
            },
        ];
    }

    function loadDateTypeList() {
        $scope.dateTypes = dateFilterService.getDefaultDateTypes();
    }

    $scope.isWidthSmall = function (widthValue) {
        return widthValue;
    };
    $scope.scrollLocation = function (element) {
        $location.hash(element);
        $anchorScroll();
    };

    function determineFilterHeight() {
        if (!$scope.savedReport.isStandardReport) $scope.filterHeight = 486;
        else $scope.filterHeight = 398;

        $scope.filterHeight -= 20;
    }

    $scope.calculateTotals = function (data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    };

    $scope.parse = function (value) {
        return parseFloat(value);
    };

    $scope.$on('reportUpdated', (event, response) => {
        $scope.filterOptions.orderByField = angular.copy(response.sortBy);
        if (
            $scope.filterOptions.orderByField !== null &&
            $scope.filterOptions.orderByField !== undefined
        ) {
            $scope.filterOptions.orderByField =
                $scope.filterOptions.orderByField.substring(0, 1).toLowerCase() +
                $scope.filterOptions.orderByField.substring(
                    1,
                    $scope.filterOptions.orderByField.length
                );
        }
        if (response.isAscending) {
            $scope.filterOptions.reverseSort = false;
        } else {
            $scope.filterOptions.reverseSort = true;
        }
    });

    $scope.$watch(
        'ediReport',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                if (angular.isDefined(newValue.data)) {
                    if (newValue.data.length === 0) {
                        $scope.sidebarContainerController.setCollapsed(false);
                    } else {
                        $scope.sidebarContainerController.setCollapsed(true);
                    }
                    $scope.hasReportBeenGenerated = true;
                    $scope.ediReport = newValue;

                    // we must set debits/credits amount to 0
                    angular.forEach($scope.ediReport.data, item => {
                        if (item.credits === null || item.credits === undefined) {
                            item.credits = 0;
                        }
                        if (item.debits === null || item.debits === undefined) {
                            item.debits = 0;
                        }
                    });
                }
            }

            if (!!$scope.ediReport && !!$scope.ediReport.data && $scope.ediReport.data.length > 0) {
                $scope.sidebarContainerController.setCollapsed(true);
            } else {
                $scope.sidebarContainerController.setCollapsed(false);
            }
        },
        true
    );

    $scope.save = function (blnSaveAs) {
        // if isStandardReport, must save

        if ($scope.savedReport.isStandardReport || blnSaveAs) {
            const modalInstance = $modal.open({
                template: require('../../../directives/components/reportFilter/saveReport/saveReportModalView.html'),
                size: 'md',
                controller: 'SaveReportController',
                backdrop: 'static',
                resolve: {
                    selectedReport() {
                        return window.angular.copy($scope.savedReport);
                    },
                    blnSaveAs() {
                        return blnSaveAs;
                    },
                },
            });
            modalInstance.result.then(addedReport => {
                if (addedReport.fromDate !== null && addedReport.fromDate !== undefined) {
                    addedReport.fromDate = formatDate(addedReport.fromDate);
                }
                if (addedReport.toDate !== null && addedReport.toDate !== undefined) {
                    addedReport.toDate = formatDate(addedReport.toDate);
                }
                if (addedReport.specificDate !== null && addedReport.specificDate !== undefined) {
                    addedReport.specificDate = formatDate(addedReport.specificDate);
                }
                $scope.savedReport = addedReport;
                determineFilterHeight();

                // add new report to the list.
                $scope.currentUserSavedReports.push(addedReport);
                toaster.save('Report');
                $scope.addedReport = addedReport;
                $scope.generate();
                $scope.form.$invalid = false;
                $scope.tempSavedReport = angular.copy($scope.savedReport);
            });
        } else {
            updateReport();
            $scope.form.$invalid = false;
        }
    };

    $scope.changePostedPending = function (posted) {
        $scope.savedReport.postedTransactions = posted;
        $scope.resetValues('date');
    };

    $scope.resetValues = function (type) {
        if (type === 'amount') {
            $scope.savedReport.fromAmount = null;
            $scope.savedReport.toAmount = null;
        } else if (type === 'date') {
            $scope.savedReport.fromDate = null;
            $scope.savedReport.toDate = null;
            $scope.savedReport.specificDate = null;
        }
    };

    function updateReport() {
        informationReportsService.updateFilter($scope.savedReport).then(response => {
            toaster.save('Report');
            $scope.savedReport = angular.copy(response);
            $scope.savedReport.selectedLabel = $scope.savedReport.name;
            $scope.form.$invalid = false;
            $scope.tempSavedReport = angular.copy($scope.savedReport);
        });
    }

    function checkExpandFilter() {
        if ($scope.sidebarContainerController) {
            $scope.sidebarContainerController.setCollapsed(false);
        }
    }
}
