import { checkAfterCutoff } from '@treasury/domain/channel/types/ach';
import { Permission } from '@treasury/domain/channel/types/permission.dto';
import { FiDate } from '@treasury/domain/dates';

export function paymentCanBeEdited(
    status: string,
    transferDate: string,
    permissions: Permission[],
    config?: { cutoffTimes: any; allowSameDayPayments: boolean }
) {
    const today = new FiDate().toIsoDate();
    const dateOfTransfer = new FiDate(transferDate).toIsoDate();

    if (!permissions.some(e => e.permission === 'Edit')) return false;
    if (
        ['ApprovalRejected', 'Approval Rejected', 'Failed', 'Expired', 'Scheduled'].includes(status)
    )
        return true;
    if (status === 'Submitted') {
        if (today < dateOfTransfer) return true;

        if (
            config &&
            today === dateOfTransfer &&
            !checkAfterCutoff(config.cutoffTimes, 'Transfer') &&
            config.allowSameDayPayments === true
        ) {
            return true;
        }
    }
    return false;
}
