SpinnerDirective.$inject = ['$rootScope', 'securityService', 'spinnerService'];

export default function SpinnerDirective($rootScope, securityService, spinnerService) {
    return {
        link(scope, element, attrs) {
            scope.isVisible = false;
            scope.retain = false;

            $rootScope.$on('loader_show', () => {
                setVisibility(true);
            });
            $rootScope.$on('loader_hide', () => {
                setVisibility(false);
            });
            $rootScope.$on('retain_loader', () => {
                scope.retain = true;
                setVisibility(scope.isVisible);
            });
            $rootScope.$on('release_loader', () => {
                scope.retain = false;
                setVisibility(scope.isVisible);
            });

            function setVisibility(isVisible) {
                scope.isVisible = isVisible && !securityService.isModalOpen();
                if (scope.isVisible || scope.retain) {
                    element.show();
                } else {
                    element.hide();
                }
            }
        },
    };
}
