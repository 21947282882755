export default () => ({
    restrict: 'A',
    replace: true,
    scope: {
        ngDisabled: '=',
        ngModel: '=',
        confirmationCallback: '&?',
        click: '&',
        form: '=',
        id: '@',
        type: '@',
    },
    template: require('./switchTemplate.html'),
    controller: SwitchController,
});

SwitchController.$inject = ['$scope'];

function SwitchController($scope) {
    $scope.hasSwitched = false;
    $scope.toggle = toggle;
    $scope.type = $scope.type === undefined ? 'name' : $scope.type;

    function toggle() {
        if (!$scope.ngDisabled) {
            if ($scope.confirmationCallback) {
                $scope.confirmationCallback().then(() => {
                    completeToggle();
                });
            } else if ($scope.click) {
                $scope.click();
                completeToggle();
            } else {
                completeToggle();
            }
        }
    }

    //
    // Private

    function completeToggle() {
        $scope.touched = true;
        $scope.hasSwitched = true;
        if ($scope.form !== undefined) {
            $scope.form.$dirty = true;
        }
        $scope.ngModel = !$scope.ngModel;
    }
}
