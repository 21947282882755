/* eslint-disable no-use-before-define */
import {
    clone,
    createUniqueId,
    deepEquals,
    exists,
    getKeys,
    getKeysInstance
} from '@treasury/utils';
import { nothing, TemplateResult } from 'lit';
import FieldType from '../field-type.js';
import Field from '../field.js';
import { dispatchRecordEvent, FdlRecordEvent } from './record.events';
import { FdlFieldDefinitions, FieldCollection } from './record.types';

export default class Record<T = unknown> extends EventTarget {
    constructor(
        public fieldTypes: FdlFieldDefinitions<T>,
        /**
         * The backing object used to hydrate the record and act as the source of truth.
         * Each property corresponds to a field.
         */
        public values: T
    ) {
        super();
    }

    /**
     * View model property used in some components.
     */
    public isExpanded = false;

    public readonly id = createUniqueId();

    public initialValues = clone(this.values);

    private cachedFields: FieldCollection<T> = {};

    public get hasChanged() {
        return !deepEquals(this.initialValues, this.values);
    }

    public get validationErrors() {
        const keys = getKeysInstance(this.values as T & object);

        return keys.map(key =>
            this.fieldTypeForField(key).validate(key, this.values[key], null, this)
        );
    }

    public get validationErrorCount() {
        return this.validationErrors.flat().length;
    }

    public get hasValidationErrors() {
        return this.validationErrorCount > 0;
    }

    public getFormattedField(fieldName: keyof T, context: any): string {
        const fieldType = this.fieldTypeForField(fieldName);
        const field = this.getField(fieldName);

        return fieldType.format(field, this, context);
    }

    public print(
        fieldName: keyof T,
        index?: number
    ): string | number | boolean | TemplateResult | Array<TemplateResult> | typeof nothing {
        const value = this.getField(fieldName);
        const printedValue = exists(index) && Array.isArray(value) ? value[0] : value;

        return this.fieldTypeForField(fieldName).print(printedValue, this);
    }

    public parse<FieldName extends keyof T>(fieldName: FieldName, value: T[FieldName]) {
        return this.fieldTypeForField(fieldName).parse(value);
    }

    /**
     * @deprecated - use `this.listenTo(record,'change',callback)` instead
     */
    public onChange(callback: (event: CustomEvent<any>) => void) {
        this.addEventListener(FdlRecordEvent.Change, callback as EventListener);
    }

    public announceChange(fieldName: keyof T) {
        dispatchRecordEvent(this, FdlRecordEvent.Change, { field: fieldName });
    }

    public announceBlur(fieldName: keyof T) {
        dispatchRecordEvent(this, FdlRecordEvent.Blur, { field: fieldName });
    }

    /**
     * @deprecated use `validationErrors` property.
     */
    public errors() {
        return this.validationErrors;
    }
    /**
     * @deprecated use `validationErrorCount` property.
     */

    public errorCount() {
        return this.validationErrorCount;
    }
    /**
     *
     * @deprecated use `hasValidationErrors` property.
     */

    public hasErrors() {
        return this.hasValidationErrors;
    }

    public readableRecordErrors() {
        const fieldValidationErrors = getKeysInstance(this.values as T & object).map(key =>
            this.fieldTypeForField(key)
                .validate(key, this.values[key], null, this)
                .map(v => ({ ...v, label: this.fieldTypeForField(key).label(this) }))
        );

        return fieldValidationErrors
            .map(field => field.map(error => `${error.label}: ${error.name}`))
            .flat();
    }

    public readableFieldErrors(fieldName: keyof T): string[] {
        return this.fieldTypeForField(fieldName)
            .validate(fieldName, this.values[fieldName], null, this)
            .map(v => ({ ...v, label: this.fieldTypeForField(fieldName).label(this) }))
            .map(error => `${error.label} ${error.name}`)
            .flat();
    }

    /**
     * Checks the validity of a field based on the fields validators.
     *
     * @returns Returns `true` if the field is valid.
     */
    public isValid<FieldName extends keyof T>(fieldName?: FieldName, value?: T[FieldName]) {
        if (exists(fieldName)) {
            if (!exists(value)) {
                value = this.values[fieldName];
            }

            return (
                this.fieldTypeForField(fieldName).validate<T>(fieldName, value, null, this)
                    .length === 0
            );
        }

        return !this.hasValidationErrors;
    }

    public get invalidValues() {
        const invalidValues: (keyof T)[] = [];
        getKeys(this.fieldTypes).forEach(key => {
            if (!this.isValid(key)) {
                invalidValues.push(key);
            }
        });
        return invalidValues;
    }

    /**
     * Checks the record for required fields that have non-zero, non-empty values (e.g., `null`, `undefined`, or empty string).
     * @returns `true` if all required values are present
     */
    public hasRequiredValues() {
        const hasMissingValue = getKeys(this.fieldTypes).some(fieldName => {
            const value = this.values[fieldName];
            const fieldTypeForField = this.fieldTypes[fieldName];
            const isRequiredField = fieldTypeForField?.required(this) ?? false;
            const isEmptyString = typeof value === 'string' && value === '';
            const isEmptyArray = Array.isArray(value) && value.length === 0;
            return isRequiredField ? !exists(value) || isEmptyString || isEmptyArray : false;
        });

        return !hasMissingValue;
    }

    public fieldTypeForField<FieldName extends keyof T>(
        fieldName: FieldName
    ): FieldType<T[FieldName]> {
        if (isPropertyPath(fieldName as string)) {
            const [parent] = (fieldName as string).split('.');

            return this.fieldTypeForField(parent as FieldName);
        }

        const fieldType = this.fieldTypes[fieldName];

        // use instanceof here in case fieldName is actually a
        // native object property and not a key specified in the dict
        // TODO: convert this.fieldTypes to a Map for safety
        return fieldType instanceof FieldType ? fieldType : new FieldType<T[FieldName]>();
    }

    public fieldTypeForFieldNested(propertyPath: string) {
        return this.fieldTypeForField(propertyPath as keyof T);
    }

    public addField<FieldName extends keyof T>(
        field: FieldName,
        fieldType: FieldType<T[FieldName]>,
        value: T[FieldName]
    ) {
        this.fieldTypes = { ...this.fieldTypes, [field]: fieldType };
        this.values = { ...this.values, [field]: value };
        this.initialValues = { ...this.initialValues, [field]: value };
        this.announceChange(field);
    }

    public setField<FieldName extends keyof T>(fieldName: FieldName, value: T[FieldName]) {
        const transformed = this.fieldTypeForField(fieldName).transform(value);
        if (isPropertyPath(fieldName as string)) {
            this.setNestedField(fieldName as string, transformed);
        }

        this.values[fieldName] = transformed;
        this.announceChange(fieldName);
    }

    /**
     * Set one or more fields on the underlying object.
     * @param fields A dictionary containing key:value pairs of field names to values.
     */
    public setFields(fields: Partial<T>) {
        getKeys(fields).forEach(fieldName => {
            this.setField(fieldName, fields[fieldName] as T[keyof T]);
        });
    }

    public parseAndSetField<FieldName extends keyof T>(
        fieldName: FieldName,
        formattedValue: T[FieldName]
    ) {
        const parsed = this.fieldTypeForField(fieldName).parse(formattedValue);
        this.setField(fieldName, parsed);
    }

    /**
     * Gets the value of a field.
     */
    public getField<K extends keyof T>(fieldName: K): T[K] {
        return isPropertyPath(fieldName as string)
            ? this.getNestedField(fieldName as string)
            : this.values[fieldName];
    }

    public hasField(fieldName: keyof T) {
        return exists(this.getField(fieldName));
    }

    public reset() {
        this.values = clone(this.initialValues);
        getKeysInstance(this.values as object & T).forEach(field => this.announceChange(field));
        dispatchRecordEvent(this, FdlRecordEvent.Reset, undefined);
    }

    public clear() {
        // re-constitute backing data using provided field definition default value
        const defaultValues = getKeys(this.fieldTypes).reduce((values, key) => {
            const fieldType = this.fieldTypes[key];
            // eslint-disable-next-line no-param-reassign
            values[key] = fieldType?.defaultValue();

            return values;
        }, {} as T);

        this.values = defaultValues;
        getKeysInstance(this.values as T & object).forEach(field => this.announceChange(field));

        return this;
    }

    public allowInputChar(fieldName: keyof T, char: string) {
        return this.fieldTypeForField(fieldName).allowInputChar(char);
    }

    public field(name: keyof T) {
        if (!exists(this.cachedFields[name])) {
            this.cachedFields[name] = new Field(this, name as string);
        }

        return this.cachedFields[name];
    }

    public clone(fields?: (keyof T)[]) {
        let clonedValues: T;
        const proto = Object.getPrototypeOf(this.values);
        // treat POJOs as simple objects
        if (proto.constructor === Object) {
            const fieldsToClone = fields ?? getKeys(this.fieldTypes);
            const entries = getKeysInstance(this.values as T & object).map(fieldName => {
                let value = this.values[fieldName];
                if (!fieldsToClone.includes(fieldName)) {
                    value = this.fieldTypeForField(fieldName).defaultValue();
                }

                return [fieldName, value];
            });
            clonedValues = Object.fromEntries(entries);
        }
        // support class instances stored in records
        else {
            clonedValues = clone(this.values);
        }
        return new Record(this.fieldTypes, clonedValues);
    }

    public hashOfFields(fields?: (keyof T)[]) {
        const tuples = Object.entries(this.values as object).filter(
            ([key]) => !fields || fields.includes(key as keyof T)
        );

        return JSON.stringify(tuples);
    }

    /**
     * Set a nested property on the backing object.
     *
     * @param propertyPath A string formatted with dot syntax describing the path to a nested property.
     * @param value A value to set at the nested property.
     */
    private setNestedField(propertyPath: string, value: any) {
        const parts = propertyPath.split('.');
        if (parts.length < 1) {
            throw new Error(
                `Cannot set nested field on record at: '${propertyPath}'. The provided property path is invalid.`
            );
        }

        let part = parts.shift() as string;

        if (part === '__proto__' || part === 'constructor' || part === 'prototype') {
            return;
        }

        let target: any = this.values[part as keyof T];

        // dereference objects until reaching the end of the property path
        while (parts.length > 1) {
            part = parts.shift() as string;
            if (part === '__proto__' || part === 'constructor' || part === 'prototype') {
                return;
            }
            target = target[part];
        }

        part = parts.shift() as string;

        target[part] = value;
        this.announceChange(propertyPath as any);
    }

    /**
     * Get the value of a field nested within the backing object.
     *
     * @param propertyPath A dot-separated property path to the value.
     */
    private getNestedField(propertyPath: string) {
        const parts = propertyPath.split('.');
        if (parts.length < 1) {
            throw new Error(
                `Cannot get nested field on record at: '${propertyPath}'. The provided property path is invalid.`
            );
        }

        let target: any = this.values;

        // traverse the property path until arriving at the final one
        while (parts.length > 1) {
            const propName = parts.shift() as string;
            target = target[propName];
        }

        return target[parts.shift() as string];
    }
}

function isPropertyPath(str: string) {
    return str.split('.').length > 1;
}
