import { CustomEventCallback } from '@treasury/utils';
import { Recordset } from '.';
import { Record } from '../record';

/**
 * Enumeration of possible events raised by a `Recordset` instance.
 */
export enum RecordsetEvent {
    Loading = 'loading',
    Error = 'error',
    Updated = 'updated',
    /** @deprecated Use `Updated` instead. */
    Changed = 'change',
    CountsChanged = 'counts-changed',
    PageChanged = 'page-changed',
    RecordAdded = 'record-added',
    RecordDeleted = 'record-deleted',
    RecordCloned = 'record-cloned',
    ColumnChanged = 'column-changed',
}

interface RecordsetLoadPayload {
    loading: boolean;
}

interface RecordAddedPayload<T> {
    record: Record<T>;
    index: number;
}

interface RecordDeletedPayload<T> {
    record: Record<T>;
    index: number;
}

interface RecordClonedPayload<T> {
    record: Record<T>;
    index: number;
}

interface RecordsetUpdatedPayload<T> {
    field: keyof T;
    record: Record<T>;
}

interface RecordsetErrorPayload {
    error: Error;
}

type RecordsetEventMap<T> = {
    [RecordsetEvent.Loading]: RecordsetLoadPayload;
    [RecordsetEvent.RecordAdded]: RecordAddedPayload<T>;
    [RecordsetEvent.Error]: RecordsetErrorPayload;
    [RecordsetEvent.Updated]: RecordsetUpdatedPayload<T>;
    [RecordsetEvent.Changed]: undefined;
    [RecordsetEvent.CountsChanged]: undefined;
    [RecordsetEvent.PageChanged]: undefined;
    [RecordsetEvent.ColumnChanged]: undefined;
    [RecordsetEvent.RecordDeleted]: RecordDeletedPayload<T>;
    [RecordsetEvent.RecordCloned]: RecordClonedPayload<T>;
};

export function dispatchRecordsetEvent<E extends RecordsetEvent, T = unknown>(
    recordset: Recordset<T, any>,
    eventName: E,
    payload: RecordsetEventMap<T>[E]
) {
    const event = new CustomEvent(eventName, {
        detail: payload,
    });

    recordset.dispatchEvent(event);
}

export type RecordsetEventCallback<E extends RecordsetEvent, T> = CustomEventCallback<
    RecordsetEventMap<T>[E]
>;

export function listenForRecordsetEvent<E extends RecordsetEvent, T, P>(
    recordset: Recordset<T, P>,
    eventName: E,
    fn: RecordsetEventCallback<E, T>
) {
    recordset.addEventListener(eventName, fn as EventListener);

    return {
        unsubscribe: () => recordset.removeEventListener(eventName, fn as EventListener),
    };
}
