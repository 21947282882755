export enum ChallengeType {
    /**
     * Legacy out-of-band provider.
     */
    OutOfBand = 1,
    /**
     * Secure token authentication via Symantec.
     */
    SecureToken,
    /**
     * Current out-of-band provider using call or text.
     */
    OutOfBandOTP,
}
