/* eslint-disable no-param-reassign */

/**
 * String comparison function in the style used by array sort predicates.
 *
 * @param {string|null} a
 * @param {string|null} b
 * @returns {-1|0|1} Returns `0` if strings are lexically equivalent,
 * `-1` if `a` is lexically less than `b`,
 * and `1` `a` is lexically greater than `b`.
 */
export default function compareStrings(a, b) {
    if (a === null) {
        a = '';
    }

    if (b === null) {
        b = '';
    }

    if (typeof a !== 'string') {
        throw new Error(`Expected first argument to be a string. Got: ${JSON.stringify(a)}`);
    }
    if (typeof b !== 'string') {
        throw new Error(`Expected second argument to be a string. Got: ${JSON.stringify(b)}`);
    }

    const aValue = a.toLowerCase().replace(/\W/g, '');
    const bValue = b.toLowerCase().replace(/\W/g, '');

    if (aValue === bValue) {
        return 0;
    }

    return aValue > bValue ? 1 : -1;
}
