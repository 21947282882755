import dateFormatter from '../../formatters/date.formatter.js';

const DEFAULT_OPTIONS = [
    {
        id: 'today',
        label: 'Today',
        dates: (() => [new Date()])(),
        legacyId: '$TODAY',
    },
    {
        id: 'specific',
        label: 'Specific Date',
        exact: true,
    },
    {
        id: 'range',
        label: 'Date Range',
        exact: true,
        isRange: true,
    },
    {
        id: 'week-to-date',
        label: 'Week to Date',
        dates: (() => {
            const today = new Date();
            return [
                new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()),
                today,
            ];
        })(),
        legacyId: '$WTD',
        isRange: true,
    },
    {
        id: 'month-to-date',
        label: 'Month to Date',
        dates: (() => {
            const today = new Date();
            return [new Date(today.getFullYear(), today.getMonth(), 1), today];
        })(),
        legacyId: '$MTD',
        isRange: true,
    },
    {
        id: 'year-to-date',
        label: 'Year to Date',
        dates: (() => {
            const today = new Date();
            return [new Date(today.getFullYear(), 0, 1), today];
        })(),
        legacyId: '$YTD',
        isRange: true,
    },
];

const DEFAULT_ORDER = DEFAULT_OPTIONS.map(item => item.id);

const ADDITIONAL_OPTIONS = [
    {
        id: 'as-of',
        label: 'As Of',
        exact: true,
    },
    {
        id: 'prior-to',
        label: 'Prior To',
        exact: true,
    },
    {
        id: 'as-of-today',
        label: 'As Of Today',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [today, null];
        })(),
    },
    {
        id: 'prior-to-today',
        label: 'Prior To Today',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [null, today];
        })(),
    },
    {
        id: 'previous-week',
        label: 'Previous Week',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [
                new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() - 7
                ),
                new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() - 1
                ),
            ];
        })(),
    },
    {
        id: 'previous-month',
        label: 'Previous Month',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [
                new Date(today.getFullYear(), today.getMonth() - 1, 1),
                new Date(today.getFullYear(), today.getMonth(), 0),
            ];
        })(),
    },
    {
        id: 'previous-year',
        label: 'Previous Year',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [
                new Date(today.getFullYear() - 1, 0, 1),
                new Date(today.getFullYear() - 1, 11, 31),
            ];
        })(),
    },
];

const NO_SELECTION_OPTION = {
    id: 'no-date-selected',
    label: 'Select Date',
    formattedValue: '',
};

export const ANY_DATE_SELECTION_OPTION = {
    id: 'any-date',
    label: 'Any Date',
    formattedValue: '',
};

export const NONE_SELECTION_OPTION = {
    id: 'none',
    label: 'None',
    formattedValue: '',
};

export const NO_SELECTION_OPTIONS = [
    NONE_SELECTION_OPTION,
    ANY_DATE_SELECTION_OPTION,
    NO_SELECTION_OPTION,
];

export const isDate = dateObject =>
    dateObject && typeof dateObject.getTime === 'function' && !Number.isNaN(dateObject.getTime());

export const parseDate = date => {
    if (isDate(date)) return date;

    const dateObject = date && new Date(dateFormatter(date));

    return isDate(dateObject) ? dateObject : null;
};

export const findDateOption = optionId =>
    [...DEFAULT_OPTIONS, ...ADDITIONAL_OPTIONS].find(option =>
        [option?.id, option?.legacyId].includes(optionId)
    );

export const getDatesArray = dates => {
    if (!dates) return null;

    let datesArray;

    if (typeof dates === 'string') {
        datesArray = dates.split(' - ');
    }

    if (Array.isArray(dates)) {
        datesArray = dates;
    }

    if (isDate(dates)) {
        datesArray = [dates];
    }

    if (!datesArray) return null;

    return datesArray.map(date => parseDate(date)).filter(date => isDate(date));
};

export const getOptionFromDates = dates => {
    const datesArray = getDatesArray(dates);

    if (!datesArray) return '';

    const option = findDateOption(datesArray.length === 1 ? 'specific' : 'range');

    return { ...option, dates: datesArray };
};

export const dateOptionDateFormatter =
    (dateFormatterFunction = dateFormatter, joiner = '-') =>
    dateOption => {
        if (!dateOption) return '';

        let option = dateOption?.id ? dateOption : findDateOption(dateOption);

        if (!option) {
            option = getOptionFromDates(dateOption);
        }

        if (option?.formattedValue) {
            return option.formattedValue;
        }
        if (!(option?.dates && option.dates.length)) return '';

        return option.dates.map(date => dateFormatterFunction(date)).join(` ${joiner} `);
    };

class DateOptionsModel {
    constructor(formatter) {
        this.items = [
            ...DEFAULT_OPTIONS.map(o => ({ ...o })),
            ...ADDITIONAL_OPTIONS.map(o => ({ ...o })),
        ];
        this.formatter = formatter || dateFormatter;
        this.getDatesArray = getDatesArray;
        this.isDate = isDate;
        this.parseDate = parseDate;
    }

    filteredOptions(optionOrder, range, required) {
        let order = optionOrder?.length ? optionOrder : DEFAULT_ORDER;
        const optionOrderHasNoSelectionOption = NO_SELECTION_OPTIONS.some(option =>
            order.some(o => o === option.id)
        );
        const noSelectionOption =
            NO_SELECTION_OPTIONS.find(option => order.find(o => o === option.id)) ||
            NO_SELECTION_OPTION;

        this.items = [noSelectionOption, ...this.items];
        if (!required && !optionOrderHasNoSelectionOption) {
            order = [noSelectionOption.id, ...order];
        }
        let options = order.map(item => this.items.find(opt => opt.id === item));
        if (!range && !optionOrder?.length) {
            options = options.filter(item => !item.isRange);
        }
        return options;
    }

    getOption(optionId) {
        if (!optionId) return null;

        return this.items.find(option => [option?.id, option?.legacyId].includes(optionId));
    }

    setDates(optionId, dates) {
        const option = this.getOption(optionId);
        if (option?.exact && dates.length) {
            option.dates = dates;
        }
    }

    parseRelativeDate(optionId) {
        // Search unfiltered array of all date options
        const ALL_RELATIVE_OPTIONS = [...DEFAULT_OPTIONS, ...ADDITIONAL_OPTIONS];
        const relativeOption = ALL_RELATIVE_OPTIONS.find(option =>
            [option?.id, option?.legacyId].includes(optionId)
        );

        return relativeOption?.dates || null;
    }

    formatDates(optionId, formatter) {
        const option = this.getOption(optionId);
        const formatterFunction = formatter || dateFormatter;

        if (!option) return '';

        if (option.formattedValue) {
            return option.formattedValue;
        }

        if (!(option.dates && option.dates.length)) return '';

        if (option.exact && !option.isRange) {
            const singleDate = option.dates[0] ? option.dates[0] : option.dates[1];
            return formatterFunction(singleDate);
        }

        return option.dates.map(date => formatterFunction(date)).join(' - ');
    }
}

DateOptionsModel.defaultOptions = DEFAULT_OPTIONS;

DateOptionsModel.isDate = isDate;

DateOptionsModel.parseDate = parseDate;

DateOptionsModel.getOptionFromDates = getOptionFromDates;

DateOptionsModel.allOptions = [...DEFAULT_OPTIONS, ...ADDITIONAL_OPTIONS];

export default DateOptionsModel;
