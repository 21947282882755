import { getChannelAPIPath } from '@treasury/core/http';

SecurityService.$inject = ['$resource', '$q', '$modal', '$state', '$rootScope', '$timeout'];

export default function SecurityService($resource, $q, $modal, $state, $rootScope, $timeout) {
    const _resourceUrl = `${getChannelAPIPath()}security/`;
    let _modalInstance = null;
    let _securityMessage = {};

    return {
        getConfig,
        verifyMethod,
        setPreferredMethod,
        removeMethod,
        completeOobConfig,
        recentLogins,
        completeSecureTokenConfig,
        verifyUser,
        isModalOpen,
        getSecureTokenConfig,
        validateOneTimePassword,
        validateSecureToken,
    };

    function getConfig() {
        return $resource(`${_resourceUrl}config`).get().$promise;
    }

    function verifyMethod(method, useForBoth) {
        return $resource(`${_resourceUrl}verifyMethod?useForBoth=:useForBoth`, {
            useForBoth,
        }).save(method).$promise;
    }

    function validateOneTimePassword(oneTimePassword) {
        return $resource(`${_resourceUrl}validateOneTimePassword`).save({ oneTimePassword })
            .$promise;
    }

    function validateSecureToken(token) {
        return $resource(`${_resourceUrl}validateSecureToken`).save({ token }).$promise;
    }

    function setPreferredMethod(methodType) {
        return $resource(`${_resourceUrl}setPreferredMethod?methodType=:methodType`, {
            methodType,
        }).get().$promise;
    }

    function removeMethod(methodType) {
        return $resource(`${_resourceUrl}removeMethod?methodType=:methodType`, {
            methodType,
        }).delete().$promise;
    }

    function completeOobConfig(action) {
        return $resource(`${_resourceUrl}complete-oob-config?action=:action`, {
            action,
        }).get().$promise;
    }

    function recentLogins() {
        return $resource(`${_resourceUrl}recentLogins`).get().$promise;
    }

    function getSecureTokenConfig() {
        return $resource(`${_resourceUrl}get-secure-token-config`).get().$promise;
    }

 

    /**
     * Three ways to call this:
     * 
     * Without action - verifyUser(actionType)
     * Without action - verifyUser(actionType, isRoute)
     * With action    - verifyUser(actionType, entity, actionCallback)
     * 
     * @template T
     * @param { string} actionType
     * @param { (securityMessage: string) => Promise<T>} [param3]
     * @returns { Promise<T> }
     */
    function verifyUser(actionType, param2, param3) {
        if (!param3) {
            return verifyUserWithoutAction(actionType, param2);
        }
        return verifyUserWithAction(actionType, param2, param3);
    }

    function isModalOpen() {
        return !!_modalInstance;
    }

    //
    // Verify without action.  Used for non-threshold verifications.

    function verifyUserWithoutAction(actionType, isRoute) {
        const deferred = $q.defer();

        if (!_securityMessage.actionType) {
            newSecurityMessage(actionType);
        }

        verify();

        function verify() {
            $resource(`${_resourceUrl}checkSecurity/`)
                .save(_securityMessage)
                .$promise.then(
                    response => {
                        window.angular.copy(response, _securityMessage);
                        handleStatusChange(verify, success, failure, isRoute);
                    },
                    error => {
                        if (isModalOpen()) {
                            _modalInstance.dismiss();
                        }
                    }
                );
        }

        function success() {
            deferred.resolve(_securityMessage);
            _modalInstance = null;
            _securityMessage = {};
        }

        function failure() {
            if (_securityMessage) {
                deferred.reject({
                    errorMessage: _securityMessage.message,
                    errorCode: _securityMessage.errorCode,
                });
            } else {
                deferred.reject({ errorMessage: null, errorCode: null });
            }
            _modalInstance = null;
            _securityMessage = {};
        }

        return deferred.promise;
    }

    //
    // Verify with action.  Used for verifications that require a threshold and an actionable API call.

    function verifyUserWithAction(actionType, entity, actionCallback) {
        let deferred;
        let actionCallbackResponse;

        deferred = $q.defer();
        actionCallbackResponse = null;

        if (!_securityMessage.actionType) {
            newSecurityMessage(actionType);
        }

        verify();

        function verify() {
            if (entity) {
                entity.securityMessage = { ..._securityMessage };
                // Hack - We are adding a separate status so we can display loading indicators
                if (entity.securityMessage.status === 'Verifying') {
                    entity.securityMessage.status = 'Verify';
                }
            }

            actionCallback(_securityMessage).then(
                response => {
                    actionCallbackResponse = response;

                    if (response.securityMessage) {
                        window.angular.copy(response.securityMessage, _securityMessage);
                        handleStatusChange(verify, success, failure, false);
                    } else {
                        success();
                    }
                },
                error => {
                    failure();
                }
            );
        }

        function success() {
            deferred.resolve(actionCallbackResponse);
            _modalInstance = null;
            _securityMessage = {};
        }

        function failure() {
            if (_securityMessage) {
                deferred.reject({
                    errorMessage: _securityMessage.message,
                    errorCode: _securityMessage.errorCode,
                });
            } else {
                deferred.reject({ errorMessage: null, errorCode: null });
            }
            if (isModalOpen()) {
                _modalInstance.dismiss();
            }
            _modalInstance = null;
            _securityMessage = {};
        }

        return deferred.promise;
    }

    function completeSecureTokenConfig(credential) {
        return $resource(
            `${_resourceUrl}complete-secure-token-config?actionType=:actionType&credentialId=:credentialId&token=:token&pin=:pin`,
            {
                actionType: credential.actionType,
                credentialId: credential.credentialId,
                token: credential.token,
                pin: credential.pin,
            }
        ).save().$promise;
    }

    function handleStatusChange(reverifyCallback, successCallback, failureCallback, isRoute) {
        if (_securityMessage.status === 'Success') {
            if (_modalInstance) {
                $timeout(() => {
                    _modalInstance.close();
                }, 1000);
            } else {
                successCallback();
            }
        } else if (!isModalOpen()) {
            showModal(reverifyCallback, successCallback, failureCallback, isRoute);
        }
    }

    function showModal(reverifyCallback, successCallback, failureCallback, isRoute) {
        // For oob, start waiting for the servers pass/fail response.
        if (_securityMessage.challengeMethodTypeId === 1 && _securityMessage.status !== 'Locked') {
            reverifyCallback();
        }

        _modalInstance = $modal.open({
            template: require('../shared/security/views/verifyUserModal.html'),
            size: 'sm',
            backdrop: 'static',
            controller: 'VerifyUserModalController',
            resolve: {
                securityMessage() {
                    return _securityMessage;
                },
                reverifyCallback() {
                    return reverifyCallback;
                },
            },
        });

        _modalInstance.result.then(
            result => {
                successCallback();
            },
            error => {
                failureCallback();
                if (!$rootScope.previousState.name && isRoute) {
                    $state.go('login');
                }
            }
        );
    }

    function newSecurityMessage(actionType) {
        _securityMessage = {
            actionType,
            status: null,
            methodUsed: null,
            hasAlternate: false,
            errorCode: null,
            message: null,
            oneTimePassword: null,
        };
    }
}
