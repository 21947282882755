import FieldType from '@treasury/FDL/field-type';

export const question = new FieldType().thatIs
    .required()
    .and.placeholder('Please select an option');
export const answer = new FieldType().thatIs.required().and.placeholder('Answer').and.maxLength(25);

export const createFieldTypes = (numberOfSecurityQuestions, securityQuestions, readonly) => {
    const fieldTypes = {};
    // Here we filter out all selected questions options expect for the current question's selection
    const isValidQuestion = (value, index, record) => {
        const selectedValues = Object.entries(record.values)
            .filter(entry => !!entry[1] && entry[0] !== `question_${index}`)
            .map(entry => entry[1]?.id);

        return !selectedValues.includes(value.id);
    };

    for (let i = 0; i < numberOfSecurityQuestions; i++) {
        const questionWithOptions = question.with.options({
            data: securityQuestions,
            text: 'text',
            value: record => record,
            filter: (value, index, record) => isValidQuestion(value, i, record),
        });
        fieldTypes[`question_${i}`] = questionWithOptions.thatIs
            .readOnlyWhen(() => readonly)
            .with.template(value => value.text);
        fieldTypes[`answer_${i}`] = answer;
    }
    return fieldTypes;
};

export const getValues = securityQuestions => {
    const values = {};
    securityQuestions.forEach((sq, i) => {
        values[`question_${i}`] = sq;
        values[`answer_${i}`] = null;
    });
    return values;
};

export const getDefaultValues = numberOfSecurityQuestions => {
    const values = {};
    for (let i = 0; i < numberOfSecurityQuestions; i++) {
        values[`answer_${i}`] = null;
    }
    return values;
};

export const mapToRequestBody = securityQuestions => {
    const questions = Object.entries(securityQuestions).filter(sq => sq[0].includes('question'));
    questions.forEach((q, i) => {
        q[1].answer = securityQuestions[`answer_${i}`];
    });

    return questions.map(q => q[1]);
};
