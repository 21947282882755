import { OmegaDate } from '@treasury/omega/components/datepicker';
import { IssuedItemsSearchParams } from '../models';

const createdDate: OmegaDate = {
    label: 'default',
    // eslint-disable-next-line @treasury/no-date
    dates: [new Date()],
    id: 'today',
    legacyId: '$TODAY',
    isRange: false,
};

/**
 * Default search params to use that differ from the filter defaults.
 */
export const defaultSearchParams: Partial<IssuedItemsSearchParams> = {
    createdDate,
};
