// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from 'uuid';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

const actionType = paymentHeaderRecord =>
    paymentHeaderRecord.id && paymentHeaderRecord.action !== 'initiate'
        ? 'Edit ACH Payment'
        : 'Initiate Payment From Batch';

const mapAchCompany = paymentHeaderRecord => {
    const { type } = paymentHeaderRecord;
    const { action } = paymentHeaderRecord;
    if (action === 'initiate' || type === 'template') {
        return {
            achCompany: {
                id: paymentHeaderRecord.achCompany?.id ?? paymentHeaderRecord.achCompanyId,
                companyName: paymentHeaderRecord.achCompanyName,
                companyId: paymentHeaderRecord.companyIdentity,
            },
        };
    }
    return { achCompany: { ...paymentHeaderRecord.achCompany } };
};

const mapAchCompanyId = paymentHeaderRecord => {
    const { type, action } = paymentHeaderRecord;
    if (action === 'initiate' || type === 'template') {
        return {
            achCompanyId: paymentHeaderRecord.companyIdentity,
        };
    }
    return { achCompanyId: paymentHeaderRecord.achCompanyId };
};

const sharedMappings = (paymentHeaderRecord, recipientsRecordset) => ({
    ...paymentHeaderRecord,
    ...mapAchCompany(paymentHeaderRecord),
    ...mapAchCompanyId(paymentHeaderRecord),
    frequency: {
        ...paymentHeaderRecord.frequency,
        effectiveDate: DateFormatters.parseDate(paymentHeaderRecord.frequency.valueDate).value,
    },
    recipients: recipientsRecordset.map(recipient => ({
        ...recipient,
        prenote: !!recipient.prenote,
        hold: !!recipient.hold,
        payDate: DateFormatters.parseDate(recipient.payDate).value,
        id: 0,
        reversal: false,
        routingNumber: recipient.bank.bankId,
        isRoutingNumberValid: true,
        isRoutingNumberOnUs: false,
        transactionType: 'CR',
        nonCustodialParentSsn: null,
        addenda: [
            {
                id: 0,
                nonCustodialParentSsn: recipient.nonCustodialParentSsn.replace(/-/g, ''),
                nonCustodialParentFirstName: recipient.nonCustodialParentFirstName,
                nonCustodialParentLastName: recipient.nonCustodialParentLastName,
                caseIdentifier: recipient.caseIdentifier,
                fipsCode: recipient.fipsCode,
                payDate: DateFormatters.parseDate(recipient.payDate).value,
                employmentTermination: !!recipient.employmentTermination,
                medicalSupport: !!recipient.medicalSupport,
            },
        ],
    })),
    type: 'AchChildSupportPayment',
    restricted: !!paymentHeaderRecord.restricted,
    isTemplate: false,
    applyUpdatesToBatch: false,
    status: '',
    numberOfApprovalsNeeded: 0,
    completedApprovals: [],
    duplicatePreventionId: uuid(),
});

export const mapPaymentHeaderAndRecipients = (paymentHeaderRecord, recipientsRecordset) => ({
    ...sharedMappings(paymentHeaderRecord, recipientsRecordset),
    securityMessage: {
        actionType: actionType(paymentHeaderRecord),
        status: null,
        methodUsed: null,
        hasAlternate: false,
        errorCode: null,
        message: null,
        oneTimePassword: null,
    },
});

export const mapTemplateRecipients = (recipientsRecordset, paymentHeaderRecord) =>
    recipientsRecordset.map(recipient => ({
        addenda: {
            id: recipient.addendaId ?? recipient.addenda?.id,
            recipientId: recipient.recipientId,
            caseIdentifier: recipient.caseIdentifier,
            employmentTermination: !!recipient.employmentTermination,
            fipsCode: recipient.fipsCode,
            medicalSupport: !!recipient.medicalSupport,
            nonCustodialParentFirstName: recipient.nonCustodialParentFirstName,
            nonCustodialParentLastName: recipient.nonCustodialParentLastName,
            nonCustodialParentSsn: recipient.nonCustodialParentSsn.replace(/-/g, ''),
        },
        id: recipient.recipientId,
        name: recipient.name,
        idNumber: recipient.idNumber,
        routingNumber: recipient.bank.bankId,
        accountNumber: recipient.accountNumber,
        accountType: recipient.accountType,
        transactionType: 2,
        amount: paymentHeaderRecord.setToZero ? 0 : recipient.amount,
        isPrenote: !!recipient.prenote,
        holdTransaction: !!recipient.hold,
    }));

export const mapPaymentHeaderAndRecipientsForEdit = (paymentHeaderRecord, recipientsRecordset) => ({
    id: paymentHeaderRecord.id ?? null,
    name: paymentHeaderRecord.name,
    secCode: paymentHeaderRecord.secCode,
    entryDescription: paymentHeaderRecord.entryDescription,
    discretionaryData: paymentHeaderRecord.discretionaryData,
    achCompanyId: paymentHeaderRecord.achCompanyId,
    companyIdentity: paymentHeaderRecord.companyIdentity,
    achCompanyName: paymentHeaderRecord.achCompanyName,
    achCompanyUniqueId: paymentHeaderRecord.achCompanyUniqueId,
    restricted: !!paymentHeaderRecord.restricted,
    recipients: [...mapTemplateRecipients(recipientsRecordset, paymentHeaderRecord)],
});
export const mapPaymentHeaderAndRecipientsForCreate = (
    paymentHeaderRecord,
    recipientsRecordset
) => ({
    id: paymentHeaderRecord.id ?? null,
    name: paymentHeaderRecord.templateName,
    secCode: paymentHeaderRecord.secCode,
    entryDescription: paymentHeaderRecord.entryDescription,
    discretionaryData: paymentHeaderRecord.discretionaryData,
    achCompanyId: paymentHeaderRecord.achCompany.id,
    companyIdentity: paymentHeaderRecord.achCompanyId,
    achCompanyName: paymentHeaderRecord.achCompanyName,
    restricted: !!paymentHeaderRecord.restricted,
    recipients: [...mapTemplateRecipients(recipientsRecordset, paymentHeaderRecord)],
});

// TODO: conditional mappings for create vs. edit template
export const mapPaymentHeaderAndRecipientsRecordsetToTemplate = (
    paymentHeaderRecord,
    recipientsRecordset
) => {
    const { action, fromPayment } = paymentHeaderRecord;
    const initiatedFromTemplate = action === 'initiate' && !fromPayment;
    if (action === 'edit' || initiatedFromTemplate) {
        return {
            ...mapPaymentHeaderAndRecipientsForEdit(paymentHeaderRecord, recipientsRecordset),
        };
    }
    return { ...mapPaymentHeaderAndRecipientsForCreate(paymentHeaderRecord, recipientsRecordset) };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const mapFilterRecordToTemplatesRequest = filterValues => {
    const { status, achCompany, name, lastUpdated, amount } = filterValues.parameters;
    // eslint-disable-next-line no-param-reassign
    delete filterValues.parameters;
    return {
        // ...filterValues,//no pagination at this time
        status,
        achCompanyIdList: achCompany,
        templateName: name.length > 0 ? name : null,
        lastUpdatedDateType: DateFormatters.getDateType(lastUpdated) === 'specificDate' ? 1 : 2,
        lastUpdated:
            DateFormatters.parseDate(lastUpdated).value.start ??
            DateFormatters.parseDate(lastUpdated).value,
        lastUpdatedEnd: DateFormatters.parseDate(lastUpdated).value.end ?? null,
        creditAmountType: amount[0] === 'range' ? 2 : 1,
        creditAmount: amount[1] === 0 || !amount[1] ? null : amount[1],
        creditAmountMax: amount[2] === 0 ? null : amount[2],
    };
};

export const mapPaymentResponseDto = response => ({
    ...response,
    payment: {
        ...response.payment,
        recipients: response.payment.recipients.map(r => ({
            ...r,
            bank: { bankId: r.routingNumber },
        })),
    },
});

export const mapTemplateResponseDto = (response, company) => ({
    ...response,
    recipients: response.recipients.map(recipient => ({
        ...recipient,
        bank: { bankId: recipient.routingNumber },
        accountType: recipient.accountTypeName,
        prenote: recipient.isPrenote,
        hold: recipient.holdTransaction,
    })),
    status: response.statusDescription,
    achCompany: { ...company },
    offsetAccount: response.offsetAccount ?? company.offsetAccountNumber,
});
