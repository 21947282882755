import { PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { MasterListRecipient } from '@treasury/domain/channel/types/ach/api/masterListRecipient.dto';
import { Record } from '@treasury/FDL';

export const convertSelectedToPaymentRecipients = (
    selectedPaymentRecipients: Array<Record<MasterListRecipient>>
): Array<PaymentRecipient> =>
    selectedPaymentRecipients.map((paymentRecipient: any) => {
        const {
            recipientName,
            recipientIdNumber,
            accountNumber,
            accountTypeDescription,
            defaultAmount,
            addendaRaw,
            errors,
            transactionType,
            routingNumber,
            achMasterRecipientStatusTypeDescription,
            selected,
        } = paymentRecipient.values;

        return {
            prenote: false,
            hold: false,
            name: recipientName,
            idNumber: recipientIdNumber,
            accountNumber,
            accountType: accountTypeDescription,
            bank: {
                bankId: routingNumber,
            },
            amount: defaultAmount,
            addenda: addendaRaw,
            errors,
            transactionType,
            step: 0,
            routingNumber,
            status: achMasterRecipientStatusTypeDescription,
            selected,
            editable: false,
            fromMasterList: false,
        };
    });
