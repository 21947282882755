import FieldType from '@treasury/FDL/field-type.js';
import alphanumeric from '../lib/validators/alphanumeric.validator';
import nachaAlphanumeric from '../lib/validators/nacha/nacha-alphanumeric.validator.js';
import { nachaCompanyName, nachaZipCode } from '../nacha.js';

export const internationalAchOriginatorCompany = nachaCompanyName.with
    .maxLength(35)
    .and.validator(nachaAlphanumeric);

export const internationalAchOriginatorCompanyId = new FieldType().thatIs
    .readOnlyWhen(() => true)
    .and.maxLength(10)
    .and.validator({
        name: 'alphanumeric',
        validate: value => alphanumeric.validate(value.companyId),
    });

export const internationalAchOriginatorCountry = new FieldType().with
    .defaultValue('US')
    .thatIs.readOnly();

export const internationalAchOriginatorZipCode = nachaZipCode.with.maxLength(31);

export const internationalAchOriginatorCurrencyCode = new FieldType().with
    .defaultValue('USD')
    .thatIs.readOnly();

export const internationalAchOriginatorSecCode = new FieldType().with
    .defaultValue('IAT')
    .and.readOnly();

export const internationalAchOffsetAccount = new FieldType();

export const internationalForeignExchangeReference = new FieldType().with
    .maxLength(15)
    .and.validator(nachaAlphanumeric);

export const internationalForeignExchangeReferenceNumber = new FieldType();
