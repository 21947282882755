import { v4 as uuid } from 'uuid';

PaymentDetailController.$inject = [
    '$scope',
    '$rootScope',
    '$modal',
    '$state',
    '$window',
    '$timeout',
    'toaster',
    'modalService',
    'companyAccountsService',
    'utilityService',
    'entitlementsService',
    'achBankService',
    'achBatchService',
    'achCompaniesService',
    'achSettingsService',
    'achPaymentsService',
    'holidaysService',
    'securityService',
    'downloadPageId',
    'regexConstants',
    'dateConstants',
    'achConstants',
];
export default function PaymentDetailController(
    $scope,
    $rootScope,
    $modal,
    $state,
    $window,
    $timeout,
    toaster,
    modalService,
    companyAccountsService,
    utilityService,
    entitlementsService,
    achBankService,
    achBatchService,
    achCompaniesService,
    achSettingsService,
    achPaymentsService,
    holidaysService,
    securityService,
    downloadPageId,
    regexConstants,
    dateConstants,
    achConstants
) {
    if (parseInt($state.params.id, 10) === 0 && !$scope.payment) {
        $state.go('payables.ach.payments.create');
        return;
    }
    achBankService.getAllBanks().then(response => {
        $rootScope.banks = response;
    });
    $scope.listType = $state.params.list;
    $scope.batch = {};
    $scope.form = {};
    $scope.achCompanies = [];
    $scope.secCodes = [];
    $scope.isNameEditing = false;
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
        reversal: false,
    };
    $scope.alphanumericRegex = regexConstants.AlphaNumericRegex;
    $scope.alphanumericSplCharsRegex = regexConstants.AlphaNumericPlusSpecialCharsRegex;
    $scope.numericRegex = /^\d+$/;
    $scope.paymentId = parseInt($state.params.id, 10);
    $scope.nextAchBusinessDate = moment().format('MM/DD/YYYY');
    $scope.resetErrors = resetErrors;
    $scope.showAddendaModal = showAddendaModal;
    $scope.checkEditableStatus = checkEditableStatus;
    $scope.isChildPayment = isChildPayment;
    $scope.setForm = setForm;
    $scope.confirmCancel = confirmCancel;
    $scope.goToPaymentList = goToPaymentList;
    $scope.findAchCompany = findAchCompany;
    $scope.validateAchCompany = validateAchCompany;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.filterRecipients = filterRecipients;
    $scope.goToRecurringPaymentList = goToRecurringPaymentList;
    $scope.calculateAmounts = calculateAmounts;
    $scope.createBatch = createBatch;
    $scope.setReviewMode = setReviewMode;
    $scope.getRecipientErrors = getRecipientErrors;
    $scope.approveOrReject = approveOrReject;
    $scope.deletePayment = deletePayment;
    $scope.editBatch = editBatch;
    $scope.editName = editName;
    $scope.goBack = goBack;
    $scope.getEndOnDate = getEndOnDate;
    $scope.confirm = confirm;
    $scope.checkRepeatOnDays = checkRepeatOnDays;
    $scope.getMaximumDate = getMaximumDate;
    $scope.reviewImport = reviewImport;
    $scope.print = print;
    $scope.cancelImport = cancelImport;
    $scope.exitView = exitView;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.processingCutoff = {};
    $scope.disableToday = null;
    $scope.searchByText = searchByText;
    $scope.numbers = dateConstants.daysInMonth;
    $scope.weekDays = dateConstants.weekDays;
    $scope.payment = !$scope.payment ? new achBatchService.Batch() : $scope.payment;
    $scope.downloadPageId = downloadPageId.AchPaymentDetail;
    $scope.wizardStep = 2;
    $scope.counts = {
        prenote: 0,
        hold: 0,
        reversal: 0,
    };
    $scope.reverseCheckboxModel = {
        reverseSelected: false,
        reverseFullPayment: false,
    };
    $scope.createReversal = createReversal;
    $scope.enableReversalButton = enableReversalButton;
    $scope.removeRecipientsNotForReversal = removeRecipientsNotForReversal; // only in scope for unit testing purposes
    $scope.checkOffsetTransaction = checkOffsetTransaction; // only in scope for unit testing purposes
    $scope.assignOffsetTotal = assignOffsetTotal; // only in scope for unit testing
    $scope.isPaymentReversible = isPaymentReversible;
    $scope.updateReversalCounts = updateReversalCounts;
    $scope.resetValuesOnChange = resetValuesOnChange;
    $scope.resetValues = resetValues;
    $scope.recipientsImported = recipientsImported;
    $scope.setPendingApprovalPaymentMessage = setPendingApprovalPaymentMessage;
    $scope.setProcessingPaymentMessage = setProcessingPaymentMessage;
    $scope.setScheduledPaymentMessage = setScheduledPaymentMessage;
    $scope.setPaymentErrorMessage = setPaymentErrorMessage;
    $scope.isOneTimeImport = isOneTimeImport;
    $scope.displayValidationPromptWithoutEdit = displayValidationPromptWithoutEdit;
    $scope.shouldShowOffsetAccountReadOnly = shouldShowOffsetAccountReadOnly;
    $scope.shouldShowOffsetAccountInput = shouldShowOffsetAccountInput;
    $scope.shouldShowOffsetAccountInputEditMode = shouldShowOffsetAccountInputEditMode;
    $scope.shouldShowConfirmValid = shouldShowConfirmValid;
    $scope.shouldShowConfirmInvalid = shouldShowConfirmInvalid;
    $scope.shouldShowEditPayment = shouldShowEditPayment;
    $scope.isSaving = false;

    function getDateDifference(fromDate, toDate) {
        return Date.parse(toDate) - Date.parse(fromDate);
    }

    function isStartDateAfterEndDate(fromDate, toDate) {
        if (!fromDate || !toDate) return false;
        return getDateDifference(fromDate, toDate) < 0;
    }

    function isOneTimeImport() {
        return (
            $scope.payment.frequency != null &&
            $scope.payment.frequency.type === 'One Time' &&
            $scope.type === 'import' &&
            $scope.wizardStep === 2
        );
    }

    function displayValidationPromptWithoutEdit() {
        return (
            $scope.payment.frequency != null &&
            !$scope.isImporting &&
            $scope.payment.frequency.type === 'One Time' &&
            $scope.type === 'import' &&
            !$scope.isConfirmation
        );
    }

    function updateReversalCounts() {
        if (
            $scope.reverseCheckboxModel.reverseFullPayment ||
            !$scope.reverseCheckboxModel.reverseSelected
        ) {
            $scope.counts.reversal = 0;
        } else {
            achBatchService.updateFilterCounts($scope.counts, $scope.payment.recipients);
        }
    }
    function isPaymentReversible() {
        return (
            $scope.payment &&
            ($scope.payment.canReverseFull || $scope.payment.canReverseTransactions)
        );
    }
    function findOffsetTransaction(recipients) {
        // rules for finding offset transaction:
        // -- must be one CR and more than one DR   OR
        // -- must be one DR and more than one CR
        // -- pre-notes and holds don't count
        // -- if the one to many situation is found, the offset transaction is the "one"
        let creditCount = 0;
        let debitCount = 0;
        let creditRecipient;
        let debitRecipient;
        $scope.payment.recipients.forEach(recipient => {
            if (!recipient.prenote && !recipient.hold) {
                if (recipient.transactionType === 'DR') {
                    debitCount++;
                    debitRecipient = recipient;
                } else {
                    creditCount++;
                    creditRecipient = recipient;
                }
            }
        });
        if (creditCount === 1 && debitCount > 1) {
            return creditRecipient;
        }
        if (debitCount === 1 && creditCount > 1) {
            return debitRecipient;
        }
        return null;
    }
    function checkOffsetTransaction() {
        // if there is an offset transaction, mark it as such.
        // this is for purposes of reversals
        if ($scope.payment.achCompany.batchBalanceRequirements === 'Balanced') {
            const offsetTransaction = findOffsetTransaction($scope.payment.recipients);
            if (offsetTransaction) {
                offsetTransaction.isOffsetTransaction = true;
            }
        }
    }
    function enableReversalButton() {
        if (isPaymentReversible() && $scope.reverseCheckboxModel) {
            if ($scope.reverseCheckboxModel.reverseSelected) {
                if ($scope.payment.recipients && $scope.payment.recipients.length > 0) {
                    return $scope.payment.recipients.some(
                        recipient => recipient.selectedForReversal
                    );
                }
                return false;
            }
            return $scope.reverseCheckboxModel.reverseFullPayment;
        }
        return false;
    }
    function removeRecipientsNotForReversal(payment) {
        // only send the ones marked for reversal.
        // note that the function removes the non-relevant recipients from the payment.
        // the assumption is that if they somehow come back from a reversal call,
        // that the payment will just reload and start from scratch.
        // therefore, we don't have to remember which ones we removed.
        // a recipient is removed if
        // -- it is a prenote or
        // -- it is a hold or
        // -- we are doing transaction reversal and the recipient is not checked for reversal
        if ($scope.reverseCheckboxModel.reverseSelected) {
            for (let i = payment.recipients.length - 1; i >= 0; i--) {
                if (
                    payment.recipients[i].prenote ||
                    payment.recipients[i].hold ||
                    !payment.recipients[i].selectedForReversal
                ) {
                    payment.recipients.splice(i, 1);
                }
            }
        }
    }
    function assignOffsetTotal(payment) {
        // if there is an offset transaction, set the total of that one to be the sum of the other ones
        if (
            $scope.reverseCheckboxModel.reverseSelected &&
            $scope.payment.achCompany.batchBalanceRequirements === 'Balanced'
        ) {
            let offsetTransaction;
            let total = 0.0;
            for (let i = 0; i < payment.recipients.length; i++) {
                if (payment.recipients[i].isOffsetTransaction) {
                    offsetTransaction = payment.recipients[i];
                } else if (
                    !payment.recipients[i].prenote &&
                    !payment.recipients[i].hold &&
                    payment.recipients[i].selectedForReversal
                ) {
                    total += payment.recipients[i].amount;
                }
            }
            if (offsetTransaction) {
                offsetTransaction.amount = total;
            }
        }
    }
    function createReversal() {
        // this happens when you click the create reversal button
        // Create new guid for the reversal payment.
        $scope.payment.duplicatePreventionId = uuid();
        const isUnbalancedOrPrefunding =
            $scope.achCompany.allowUnbalancedPayments || $scope.achCompany.prefundingDays > 0;
        if (
            $scope.achCompany.offsetAccountNumber &&
            isUnbalancedOrPrefunding &&
            $scope.isAchPrefundingEntitled
        ) {
            $scope.payment.offsetAccountNumber = null;
            $scope.payment.offsetAccount = null;
        }
        removeRecipientsNotForReversal($scope.payment);
        assignOffsetTotal($scope.payment);
        $scope.setSelectedBatches($scope.payment);
        $state.go('payables.ach.payments.reverse', { reversalId: $scope.payment.id });
    }
    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }
    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('SameDayAch').then(response => {
            $scope.processingCutoff = response;
            const currentFiTime = moment(response.currentFiTime).format('L');
            const fiCutoffTime = `${currentFiTime} ${response.processingCutoff.cutoffTime}`;
            const fiCutoffTimeDiff = moment(fiCutoffTime).diff(response.currentFiTime);
            $scope.disableToday = response.processingCutoff.allowSameDay === false;
            const timeout = $scope.disableToday ? 0 : fiCutoffTimeDiff;
            $scope.cutoffPassed = fiCutoffTimeDiff <= 0;
            checkTimeout(timeout);
            getAchNextEffectiveDate();
        });
    }
    function checkTimeout(timeout) {
        $timeout(() => {
            if (timeout > 0) {
                $scope.cutoffPassed = true;
                toaster.alert('Cutoff Time Passed', 'Cannot create ACH for today.');
            }
            if ($scope.payment && $scope.payment.frequency) {
                if (
                    moment($scope.payment.frequency.effectiveDate).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.payment.frequency.effectiveDate = formatDate(response);
                        });
                }
                if (
                    moment($scope.payment.frequency.startOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.payment.frequency.startOn = formatDate(response);
                        });
                }
                if (
                    moment($scope.payment.frequency.endOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getAchNextBusinessDay(
                            $scope.holidayDates,
                            $scope.disableToday,
                            $scope.cutoffPassed
                        )
                        .then(response => {
                            $scope.payment.frequency.endOn = formatDate(response);
                        });
                }
            }
        }, timeout);
    }
    function resetErrors() {
        $scope.form.$setValidity('onUsTransactionsRequired', true);
        $scope.form.$setValidity('onUsAmountRequiredPerBatch', true);
    }
    function hasAccessPermissions(payment, permissionType) {
        let result = false;
        payment.permissions.forEach(permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }
    function confirm() {
        $scope.isSaving = true;
        // Was told that I should cache the ACH Company, Offset Account, Recipients, and SEC Code from $scope
        // so the BSL will not have to resend it on the response and impact performance.
        const achCompany = angular.copy($scope.payment.achCompany);
        const offsetAccount = angular.copy($scope.payment.offsetAccount);
        const { secCode } = $scope.payment;
        const recipients = angular.copy($scope.payment.recipients);
        $scope.errorMessages = null;
        if (parseInt($state.params.id) === 0) {
            const actionType =
                $scope.payment.frequency.type === 'One Time'
                    ? 'Create One time ACH Payment'
                    : 'Create Recurring ACH Payment';
            securityService
                .verifyUser(actionType, $scope.payment, () =>
                    achPaymentsService.create($scope.payment)
                )
                .then(response => {
                    $scope.payment = response.payment;
                    setAchCompanyAttributes(achCompany);
                    $scope.payment.offsetAccount = offsetAccount;
                    $scope.payment.secCode = secCode;
                    $scope.payment.recipients = recipients;
                    if (response.successMessage) {
                        $scope.successMessage = response.successMessage;
                    }
                    $scope.paymentId = response.payment.id;
                    setConfirmation(response);
                }).finally(() => $scope.isSaving = false);
        } else {
            securityService
                .verifyUser('Edit ACH Payment', $scope.payment, () =>
                    achPaymentsService.update($scope.paymentId, $scope.payment)
                )
                .then(response => {
                    $scope.payment = response.payment;
                    setAchCompanyAttributes(achCompany);
                    $scope.payment.offsetAccount = offsetAccount;
                    $scope.payment.secCode = secCode;
                    $scope.payment.recipients = recipients;
                    if (response.successMessage) {
                        $scope.successMessage = response.successMessage;
                    }
                    setConfirmation(response);
                }).finally(() => $scope.isSaving = false);
        }
    }
    function setConfirmation(response) {
        if (response.errorMessage) {
            $scope.errorMessages = response.errorMessage;
            if (response.payment.status === 'Failed') {
                $scope.isConfirmation = true;
                $scope.wizardStep = 4;
            }
        } else {
            $scope.approvalsNeeded = null;
            $scope.paymentStatus = response.payment.status;
            setConfirmationApprovalStatus(response);
            $scope.wizardStep = 4;
        }
    }
    function setConfirmationApprovalStatus(response) {
        $scope.isConfirmation = true;
        $scope.isReviewing = false;
        if (response.payment.numberOfApprovalsNeeded > 0) {
            $scope.approvalsNeeded = response.payment.numberOfApprovalsNeeded;
            $scope.paymentMessage = 'Pending Approval! ACH is in pending approval status';
        }
    }
    function deletePayment() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to delete this Payment?',
            submit(result) {
                // FIXME: This is a reference to a non existent service and function. Leaving here as this isn't an area I changed in this PR. DE
                achPaymentService.deletePayment($scope.paymentId).then(response => {
                    $modalInstance.close();
                    $state.go('payables.ach.payment-list');
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }
    function setPendingApprovalPaymentMessage() {
        return $scope.isConfirmation && !$scope.errorMessages && $scope.approvalsNeeded !== null;
    }
    function setProcessingPaymentMessage() {
        return (
            $scope.isConfirmation &&
            !$scope.errorMessages &&
            $scope.approvalsNeeded === null &&
            $scope.paymentStatus === 'Initiated'
        );
    }
    function setScheduledPaymentMessage() {
        return (
            $scope.isConfirmation &&
            !$scope.errorMessages &&
            $scope.approvalsNeeded === null &&
            $scope.paymentStatus === 'Scheduled'
        );
    }
    function setPaymentErrorMessage() {
        return $scope.errorMessages && $scope.errorMessages.length > 0;
    }
    $scope.$watch(
        () => $scope.payment.frequency,
        () => {
            if ($scope.payment && $scope.payment.frequency) {
                $scope.endOnDate = getEndOnDate(
                    $scope.payment.frequency.startOn,
                    $scope.payment.frequency.type
                );
                $timeout(() => {
                    $scope.$apply();
                });
            }
        },
        true
    );
    function recipientsImported() {
        $scope.form.$setDirty();
    }
    function getEndOnDate(date, frequencyType) {
        let endOnDate = moment().format('MM/DD/YYYY');
        if (isValidDate(date)) {
            const dt = new Date(date);
            if (frequencyType == 'Weekly') {
                endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if (frequencyType == 'Every Two Weeks') {
                endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (frequencyType == 'Twice a Month' || frequencyType == 'Monthly') {
                endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if (frequencyType == 'Quarterly') {
                endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if (frequencyType == 'Every Six Months') {
                endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if (frequencyType == 'Annually') {
                endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
        return endOnDate;
    }
    function setForm(form) {
        $scope.form = form;
    }
    function editName() {
        $scope.isNameEditing = true;
    }
    function checkEditableStatus(status) {
        if (parseInt($state.params.id, 10) === 0) {
            return true;
        }
        const editablePayments = [
            'Approval Rejected',
            'Failed',
            'Uninitiated',
            'Expired',
            'Scheduled',
        ];
        return editablePayments.indexOf(status) !== -1;
    }

    function isChildPayment(payment) {
        return payment.parentId !== 0;
    }
    function reviewImport() {
        $scope.isImporting = false;
        $scope.isEditing = false;
        $scope.isReviewing = true;
        $scope.wizardStep = 3;
    }
    function confirmCancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel Payment Information?',
                submit(result) {
                    $modalInstance.close();
                    $scope.batch = angular.copy($scope.copyBatch);
                    $scope.isEditing = false;
                    $state.go('payables.ach.payment-list');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $scope.isEditing = false;
            $state.go('payables.ach.payment-list');
        }
    }
    function editBatch() {
        $scope.isEditing = true;
        $scope.isNameEditing = false;
        if (!isChildPayment($scope.payment)) {
            $scope.payment.frequency.startOn = null;
        }
        $timeout(() => {
            angular.forEach($scope.batch.recipients, (item, i) => {
                angular.forEach(
                    ['name', 'idNumber', 'accountNumber', 'routingNumber', 'transactionType'],
                    key => {
                        $scope.form[`recipient-${i}`][key].$validate();
                    }
                );
            });
        }, 200);
    }
    function addMasterRecipients() {
        if ($state.params.masterRecipients) {
            $state.params.masterRecipients.forEach(masterRecipient => {
                $scope.payment.recipients.push(masterRecipient);
            });
            $timeout(() => {
                $scope.form.$setDirty(true);
            });
        }
    }
    function getAchCompanies() {
        achCompaniesService.getAll().then(data => {
            $scope.achCompanies = data;
        });
    }
    function goToPaymentList() {
        $state.go('payables.ach.payment-list');
    }
    function goToRecurringPaymentList() {
        $state.go('payables.ach.recurring-payment-list');
    }
    function exitView() {
        if ($scope.payment.frequency.type === 'One Time') {
            $window.history.back();
        } else {
            $state.go('payables.ach.recurring-payment-list', {}, { reload: true });
        }
    }
    function getAccountTypes() {
        $scope.accountTypes = achBatchService.getAccountTypes();
    }
    function getTransactionTypes() {
        $scope.transactionTypes = achBatchService.getTransactionTypes();
    }
    function getPaymentDetail(id) {
        if (parseInt(id, 10) === 0) {
            $scope.payment.status = 'Ready';
            getAchSecCodes($scope.payment.achCompany.id);
            $scope.filteredRecipients = filterRecipients();
            getOffsetAccounts();
            populateAchCompany();
        } else {
            achPaymentsService.getDetails(id).then(response => {
                $scope.payment = response.payment;
                checkOffsetTransaction();
                achBatchService.updateFilterCounts($scope.counts, $scope.payment.recipients);
                if ($scope.payment.frequency) {
                    $scope.payment.frequency.effectiveDate = moment(
                        response.payment.frequency.effectiveDate,
                        'YYYY-MM-DD'
                    ).format('MM/DD/YYYY');
                    $scope.payment.frequency.startOn = moment(
                        response.payment.frequency.startOn,
                        'YYYY-MM-DD'
                    ).format('MM/DD/YYYY');
                    $scope.endOnDate = getEndOnDate(
                        $scope.payment.frequency.startOn,
                        $scope.payment.frequency.type
                    );
                }
                getAchSecCodes($scope.payment.achCompany.id);
                $scope.filteredRecipients = filterRecipients();
                getOffsetAccounts();
                populateAchCompany();
            });
        }
    }
    $scope.onChange = function (e) {
        if (isValidDate($scope.payment.frequency.startOn)) {
            const dt = new Date($scope.batch.frequency.startOn);
            if ($scope.batch.frequency.type == 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type == 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.batch.frequency.type == 'Twice a Month' ||
                $scope.batch.frequency.type == 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type == 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type == 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.batch.frequency.type == 'Annually') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };
    function findAchCompany() {
        const modalInstance = $modal.open({
            template: require('../../batch/views/achCompaniesModalView.html'),
            size: 'md',
            controller: 'AchCompaniesModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return $scope.achCompanies;
                },
            },
        });
        modalInstance.result.then(data => {
            setAchCompanyAttributes(data);
        });
    }
    function getAchSecCodes(id) {
        achCompaniesService.getSecCodes(id).then(response => {
            $scope.secCodes = response;
        });
    }

    $scope.onChange = function (e) {
        if (isValidDate($scope.payment.frequency.startOn)) {
            const dt = new Date($scope.payment.frequency.startOn);
            if ($scope.payment.frequency.type == 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.payment.frequency.type == 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.payment.frequency.type == 'Twice a Month' ||
                $scope.payment.frequency.type == 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.payment.frequency.type == 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.payment.frequency.type == 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.payment.frequency.type == 'Annually') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };
    function getMaximumDate(startDate, frequencyType) {
        let date = moment(new Date(startDate));
        if (frequencyType === 'Every Six Months') {
            date = date.add(6, 'months');
        } else if (frequencyType === 'Monthly') {
            date = date.add(1, 'month');
        } else if (frequencyType === 'Quarterly') {
            date = date.add(3, 'months');
        } else if (frequencyType === 'Annually') {
            date = date.add(1, 'year');
        }
        return date.format('MM/DD/YYYY');
    }
    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date && $scope.holidayDates) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };
    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            getAchNextEffectiveDate();
        });
    }
    function getAchNextEffectiveDate() {
        if (
            $scope.disableToday == null ||
            $scope.cutoffPassed == null ||
            $scope.holidayDates == null
        ) {
            return;
        }
        utilityService
            .getAchNextEffectiveDate($scope.holidayDates, $scope.disableToday, $scope.cutoffPassed)
            .then(async nextAchEffectiveDate => {
                if ($scope.payment && $scope.payment.frequency) {
                    const allowSameDayPayments = $scope.processingCutoff.processingCutoff;
                    const holidays = $scope.holidayDates;
                    const sameDayAchSettings = $scope.achSettings;
                    const cutOffTimes = $scope.processingCutoff;

                    const incomingDate = new Date($scope.payment.frequency.effectiveDate);

                    const isIncomingEffectiveDateValid =
                        await achPaymentsService.isIncomingDateValid(
                            incomingDate,
                            allowSameDayPayments,
                            holidays,
                            sameDayAchSettings,
                            cutOffTimes
                        );

                    $scope.payment.frequency.effectiveDate = isIncomingEffectiveDateValid
                        ? formatDate($scope.payment.frequency.effectiveDate)
                        : formatDate(nextAchEffectiveDate);
                }
            });
    }
    function loadFrequencyOptions() {
        $scope.frequencies = [
            {
                name: 'One Time',
                key: 'One Time',
            },
            {
                name: 'Weekly',
                key: 'Weekly',
            },
            {
                name: 'Every Two Weeks',
                key: 'Every Two Weeks',
            },
            {
                name: 'Twice a Month',
                key: 'Twice a Month',
            },
            {
                name: 'Monthly',
                key: 'Monthly',
            },
            {
                name: 'Quarterly',
                key: 'Quarterly',
            },
            {
                name: 'Annually',
                key: 'Annually',
            },
            {
                name: 'Every Six Months',
                key: 'Every Six Months',
            },
        ];
        if ($scope.listType === 'recurringList') {
            $scope.frequencies.splice(0, 1);
        }
    }
    function checkRepeatOnDays() {
        if (!$scope.payment.frequency.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.payment.frequency.repeatOnDay1 < $scope.payment.frequency.repeatOnDay2 ||
                $scope.payment.frequency.repeatOnDay2 === '';
            const isValid2 =
                $scope.payment.frequency.repeatOnDay2 > $scope.payment.frequency.repeatOnDay1 ||
                $scope.payment.frequency.repeatOnDay1 === '';
            if (
                $scope.payment.frequency.repeatOnDay1 !== undefined &&
                $scope.payment.frequency.repeatOnDay2 !== undefined &&
                $scope.payment.frequency.repeatOnDay2 !== ''
            ) {
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', isValid1);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
    }
    function getFrequencySummary(payment) {
        if (payment && payment.frequency) {
            return achPaymentsService.summarizeFrequency(payment.frequency);
        }
    }
    function setReviewMode() {
        calculateAmounts();
        $scope.filteredRecipients = filterRecipients();
        $scope.isEditing = false;
        $scope.isReviewing = true;
    }
    function validateAchCompany() {
        return (
            $scope.achCompanies.filter(item => item.companyName === $scope.batch.achCompanyName)
                .length > 0
        );
    }
    function filterRecipients() {
        if ($scope.payment && $scope.payment.recipients) {
            let filteredArray = $scope.payment.recipients.filter(recipient => {
                if (recipient.isDeleted) {
                    return false;
                }
                return (
                    $scope.searchFilters.text === '' ||
                    (recipient.name &&
                        recipient.name
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    recipient.name
                        .toUpperCase()
                        .indexOf($scope.searchFilters.text.toUpperCase()) !== -1 ||
                    (recipient.idNumber &&
                        recipient.idNumber.toLowerCase().indexOf($scope.searchFilters.text) !==
                            -1) ||
                    (recipient.accountNumber &&
                        recipient.accountNumber.toLowerCase().indexOf($scope.searchFilters.text) !==
                            -1) ||
                    (recipient.amount &&
                        recipient.amount.toString().indexOf($scope.searchFilters.text) !== -1) ||
                    (recipient.routingNumber &&
                        recipient.routingNumber.indexOf($scope.searchFilters.text.toLowerCase()) !==
                            -1)
                );
            });
            if ($scope.searchFilters.prenote) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.prenote && recipient.prenote
                );
            }
            if ($scope.searchFilters.hold) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.hold && recipient.hold
                );
            }
            if ($scope.reverseCheckboxModel.reverseSelected && $scope.searchFilters.reversal) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.reversal && recipient.selectedForReversal
                );
            }
            return filteredArray;
        }
    }
    function searchByText() {
        $scope.filteredRecipients = filterRecipients();
    }
    function calculateAmounts() {
        $scope.payment.debitAmount = 0;
        $scope.payment.creditAmount = 0;
        $scope.payment.recipients.forEach(item => {
            if (!item.hold) {
                const key = item.transactionType === 'DR' ? 'debitAmount' : 'creditAmount';
                $scope.payment[key] += parseFloat(item.amount) || 0;
            }
        });
        $scope.isEditing = false;
        $scope.isReviewing = true;
    }

    function handleApproveState(response) {
        const hasError = parseIndividualErrors(response);
        if (hasError) return;
        if ($scope.listType === 'recurringList') {
            $state.go('payables.ach.recurring-payment-list');
        } else {
            $state.go('payables.ach.payment-list');
        }
    }

    function approveOrReject(type) {
        const modalInstance = $modal.open({
            template: require('../views/approveRejectPaymentModal.html'),
            size: 'lg',
            controller: 'ApproveRejectPaymentController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            const message = { id: $scope.paymentId, value: comments };
            if (type === 'approve') {
                securityService
                    .verifyUser('Approve ACH Payment', message, () =>
                        achPaymentsService.approve(message.id, message)
                    )
                    .then(handleApproveState);
            } else if (type === 'reject') {
                securityService
                    .verifyUser('Reject ACH Payment', message, () =>
                        achPaymentsService.reject(message.id, message)
                    )
                    .then(handleApproveState);
            }
        });
    }
    function parseIndividualErrors(transaction) {
        let hasErrors = false;
        if (transaction.errorSummary && transaction.errorSummary.summaryMessage) {
            $scope.errorDetails = [];
            hasErrors = true;
            $scope.errorMessage = transaction.errorSummary.summaryMessage;
            transaction.errorSummary.details.forEach(item => {
                if (item.message && item.messageList && item.messageList.length > 0) {
                    item.messageList.forEach(message => {
                        $scope.errorDetails.push(message.value);
                    });
                } else if (item.message) {
                    $scope.errorDetails.push(item.message);
                }
            });
        } else {
            clearErrors();
        }
        return hasErrors;
    }
    function clearErrors() {
        $scope.errorMessage = null;
        $scope.errorDetails = null;
    }
    function createBatch() {
        achBatchService.create($scope.batch).then(response => {
            $scope.batch = response;
            $scope.wizardController.goToNextStep();
        });
    }
    function goBack() {
        $scope.isReviewing = false;
        $scope.wizardStep = 2;
        if ($scope.type === 'import') {
            $scope.isImporting = true;
        } else {
            $scope.isEditing = true;
        }
        $scope.errorMessages = null;
    }
    function print() {
        $window.print();
    }
    function setAchCompanyAttributes(data) {
        $scope.payment.achCompanyName = data.companyName || '';
        $scope.payment.achCompanyId = data.companyId || '';
        $scope.payment.secCode =
            data.secCodes && data.secCodes.length > 0 ? data.secCodes[0].code : '';
        $scope.secCodes = data.secCodes || [];
        $scope.payment.entryDescription = data.entryDescription || '';
        $scope.payment.discretionaryData = data.discretionaryData || '';
        $scope.payment.achCompany = data;
    }

    function addRecipientError(existingErrors, newError) {
        if (existingErrors && newError && existingErrors.indexOf(newError) === -1) {
            existingErrors.push(newError);
        }
    }

    function isValidDate(dateString) {
        if (dateString == null) return false;
        const dateTime = Date.parse(dateString);
        if (isNaN(dateTime)) {
            return false;
        }
        return true;
    }

    function getRecipientErrors() {
        const errors = [];
        let invalidRecipients = 0;
        $scope.payment.recipients.forEach((item, i) => {
            if ($scope.form[`recipient-${i}`]?.$invalid) {
                invalidRecipients++;
            }
        });
        achBatchService.updateFilterCounts($scope.counts, $scope.payment.recipients);
        if (invalidRecipients === 1) {
            addRecipientError(
                errors,
                `${invalidRecipients} recipient has one or more invalid fields.`
            );
        }
        if (invalidRecipients > 1) {
            addRecipientError(
                errors,
                `${invalidRecipients} recipients have one or more invalid fields.`
            );
        }
        if ($scope.batch.entryDescription !== undefined && $scope.batch.entryDescription.$invalid) {
            addRecipientError(errors, achConstants.Errors.entry);
        }
        if (
            !$scope.payment.frequency.repeatOn &&
            ($scope.payment.frequency.type === 'Weekly' ||
                $scope.payment.frequency.type === 'Every Two Weeks')
        ) {
            addRecipientError(errors, achConstants.Errors.repeatOn);
        }
        if (
            !$scope.payment.frequency.repeatOnDay1 &&
            $scope.payment.frequency.type === 'Monthly' &&
            $scope.payment.frequency.repeatOnLastBusinessDay === false
        ) {
            addRecipientError(errors, achConstants.Errors.repeatOnDay1);
        }
        if (
            !$scope.payment.frequency.repeatOnDay1 &&
            $scope.payment.frequency.type === 'Twice a Month'
        ) {
            addRecipientError(errors, achConstants.Errors.repeatOnDay1);
        }
        if (
            !$scope.payment.frequency.repeatOnDay2 &&
            $scope.payment.frequency.type === 'Twice a Month' &&
            $scope.payment.frequency.repeatOnLastBusinessDay === false
        ) {
            addRecipientError(errors, achConstants.Errors.repeatOnDay2);
        }
        if (
            isStartDateAfterEndDate(
                $scope.payment.frequency.repeatOnDay1,
                $scope.payment.frequency.repeatOnDay2
            ) &&
            $scope.payment.frequency.type === 'Twice a Month' &&
            $scope.payment.frequency.repeatOnLastBusinessDay === false &&
            $scope.payment.frequency.repeatOnDay1 &&
            $scope.payment.frequency.repeatOnDay2
        ) {
            addRecipientError(errors, achConstants.Errors.repeatOnDay1Invalid);
        }
        if (
            $scope.payment.frequency.type !== 'One Time' &&
            !isValidDate($scope.payment.frequency.startOn)
        ) {
            addRecipientError(errors, achConstants.Errors.startOn);
        }
        if (
            $scope.payment.frequency.type !== 'One Time' &&
            $scope.payment.frequency.noEndDate === false &&
            !isValidDate($scope.payment.frequency.endOn)
        ) {
            addRecipientError(errors, achConstants.Errors.endOn);
        }

        if (
            $scope.isAchPrefundingEntitled &&
            $scope.isReviewing &&
            ($scope.payment.achCompany.prefundingDays <= 0 || isDebitTransaction()) &&
            !$scope.payment.offsetAccount
        ) {
            addRecipientError(errors, achConstants.Errors.offsetAccountRequired);
        }

        for (const type in $scope.form.$error) {
            switch (type) {
                case 'validDate':
                    addRecipientError(errors, achConstants.Errors.invalidDate);
                    break;
                case 'dateGreaterThan':
                    if (
                        isStartDateAfterEndDate(
                            $scope.payment.frequency.startOn,
                            $scope.payment.frequency.endOn
                        )
                    ) {
                        addRecipientError(errors, achConstants.Errors.startOnInvalid);
                    } else {
                        addRecipientError(errors, achConstants.Errors.startOn);
                    }
                    break;
                case 'onUsTransactionsRequired':
                    addRecipientError(
                        errors,
                        `At least ${$scope.payment.achCompany.onUsTransactionsRequired} recipients are required to have "On Us" routing numbers.`
                    );
                    break;
                case 'onUsAmountRequiredPerBatch':
                    addRecipientError(
                        errors,
                        `At least ${$scope.payment.achCompany.onUsAmountRequiredPerBatch}% of the batch amount must be allocated to "On Us" routing numbers.`
                    );
                    break;
                case 'batchBalanceRequirements':
                    if ($scope.payment.achCompany.batchBalanceRequirements === 'Balanced') {
                        addRecipientError(errors, achConstants.Errors.unbalanced);
                    } else if (
                        $scope.payment.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Full Offset'
                    ) {
                        addRecipientError(
                            errors,
                            `Transaction type must be set to "${$scope.payment.recipients[0].transactionType}" on all recipients.`
                        );
                    } else if (
                        $scope.payment.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Partial Offset'
                    ) {
                        addRecipientError(errors, achConstants.Errors.partialOffset);
                    }
                    break;
                default:
                    addRecipientError(errors, `Unknown error: ${type}`);
            }
        }
        return errors;
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../../batch/views/addendaModalView.html'),
            size: 'lg',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        recipient,
                        batch: $scope.payment,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });
        modalInstance.result.then(data => {});
    }
    function cancelImport() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel Payment Information?',
                submit(result) {
                    $modalInstance.close();
                    $state.go('payables.ach.payment-list');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $state.go('payables.ach.payment-list');
        }
    }
    function getOffsetAccounts() {
        achCompaniesService.getOffsetAccounts($scope.payment.achCompany.id).then(response => {
            $scope.accounts = response;
            if ($scope.payment.offsetAccount) {
                const account = $scope.accounts.reduce(
                    (acc, val) => (val.id === $scope.payment.offsetAccount.id ? val : acc),
                    null
                );
                if (account) {
                    $scope.payment.offsetAccount = account;
                } else {
                    $scope.payment.offsetAccount = null;
                }
            }
        });
    }
    function resetValuesOnChange() {
        $scope.payment.frequency.repeatOnDay1 = undefined;
        $scope.payment.frequency.repeatOnDay2 = undefined;
        $scope.payment.frequency.startOn = undefined;
        $scope.payment.frequency.repeatOn = undefined;
        $scope.payment.frequency.endOn = undefined;
        $scope.payment.frequency.noEndDate = false;
        $scope.payment.frequency.repeatOnLastBusinessDay = false;
    }
    function resetValues(type) {
        if (type === 'Monthly') {
            $scope.payment.frequency.repeatOnDay1 = null;
        } else if (type === 'Twice a Month') {
            $scope.payment.frequency.repeatOnDay2 = null;
        }
    }

    async function populateAchCompany() {
        const achCompanies = await achCompaniesService.getAll();
        const achCompany = achCompanies.find(
            company => company.id === $scope.payment.achCompany.id
        );
        $scope.payment.achCompany.prefundingDays = achCompany.prefundingDays;
        $scope.payment.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
        $scope.payment.achCompany.allowUnbalancedPayments = achCompany.allowUnbalancedPayments;
        $scope.achCompany = achCompany;
        $scope.$digest();
    }

    function isDebitTransaction() {
        const hasDebit = $scope.payment.recipients.some(
            recipient => recipient.transactionType === 'DR'
        );
        const hasCredit = $scope.payment.recipients.some(
            recipient => recipient.transactionType === 'CR'
        );
        return hasDebit && !hasCredit;
    }

    function getAchSettingsAllowUnbalancedPayments() {
        return $scope.achSettings?.allowUnbalancedPayments ?? true;
    }

    function shouldShowOffsetAccountReadOnly() {
        if ($scope.payment.achCompany.batchBalanceRequirements === 'Balanced') return false;

        if (isDebitTransaction() && $scope.isReviewing) return false;
        if ($scope.isImporting && !$scope.isConfirmation) return false;
        if (
            $scope.isAchPrefundingEntitled &&
            $scope.payment.achCompany.prefundingDays <= 0 &&
            !$scope.payment.achCompany.allowUnbalancedPayments &&
            (!$scope.payment.offsetAccount || $scope.isReviewing || $scope.isEditing)
        )
            return false;
        return true;
    }

    function shouldShowOffsetAccountInput() {
        if (
            $scope.payment.achCompany.allowUnbalancedPayments &&
            getAchSettingsAllowUnbalancedPayments() &&
            $scope.isReviewing
        )
            return false;
        if (
            $scope.isAchPrefundingEntitled &&
            $scope.isReviewing &&
            !$scope.isConfirmation &&
            $scope.payment.achCompany.batchBalanceRequirements !== 'Balanced' &&
            ($scope.payment.achCompany.prefundingDays <= 0 || isDebitTransaction())
        )
            return true;
    }

    function shouldShowOffsetAccountInputEditMode() {
        /* 

        Companies that are not balanced require an offset account. 
        The product today requires an unbalanced company to select an offset. 
        The prefunding initiative will change that and pull one from SilverLake. 
        Here we guard against whether the achPrefunding feature flag is on, 
        If not, ensure the company requires an offset account, we're importing (creating via nacha upload)
        and not in the review step. 

        If so, show the control as editable

        */
        if ($scope.isAchPrefundingEntitled) {
            if (!$scope.isReviewing && !$scope.isEditing) return false;
            return (
                ($scope.isImporting || $scope.isEditing) &&
                $scope.payment.achCompany.batchBalanceRequirements !== 'Balanced' &&
                $scope.payment.achCompany.prefundingDays <= 0 &&
                !$scope.payment.achCompany.allowUnbalancedPayments
            );
        }
        return (
            $scope.isImporting &&
            !$scope.isReviewing &&
            $scope.payment.achCompany.batchBalanceRequirements !== 'Balanced'
        );
    }

    function shouldShowConfirmValid() {
        return $scope.isReviewing && !$scope.isConfirmation && $scope.form.$valid;
    }

    function shouldShowConfirmInvalid() {
        return $scope.isReviewing && !$scope.isConfirmation && $scope.form.$invalid;
    }

    function shouldShowEditPayment() {
        return (
            checkEditableStatus($scope.payment.status) &&
            $scope.type !== 'import' &&
            !$scope.isEditing &&
            !$scope.isReviewing &&
            !$scope.isImporting &&
            $scope.editPaymentEntitlement &&
            !$scope.payment.reversalAchPaymentId &&
            !isChildPayment($scope.payment)
        );
    }

    (async function () {
        // init
        $scope.type = $state.params.type;
        $scope.editPaymentEntitlement =
            entitlementsService.hasEntitlement('ACH, Payment, Full Edit') ||
            entitlementsService.hasEntitlement('ACH, Payment, Partial Edit');
        $scope.partialEditPaymentEntitlement =
            entitlementsService.hasEntitlement('ACH, Payment, Partial Edit') &&
            !entitlementsService.hasEntitlement('ACH, Payment, Full Edit');
        $scope.approvePaymentEntitlement =
            entitlementsService.hasEntitlement('Approve Ach Payment');
        $scope.deletePaymentEntitlement = entitlementsService.hasEntitlement('Delete Ach Payment');
        $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
        $scope.isAchPrefundingEntitled = entitlementsService.hasEntitlement(
            'Feature.ACH.AllowUnbalancedPayments'
        );
        getAchCompanies();
        getAccountTypes();
        getTransactionTypes();
        loadFrequencyOptions();
        loadCutoffTimes();
        getHolidays();
        if ($state.params.payment) {
            $scope.payment = $state.params.payment;
            addMasterRecipients();
            getAchSecCodes($scope.payment.achCompany.id)
            getOffsetAccounts();
        } else {
            getPaymentDetail($state.params.id);
        }
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
        if ($scope.type === 'edit') {
            editBatch();
        } else if ($scope.type === 'import') {
            $scope.isImporting = true;
        }
        $scope.endOnDate = moment().format('MM/DD/YYYY');
    })();
}
