export enum StateName {
    LOGIN = 'login',
    CHANGE_PASSWORD = 'change-password',
    USER_VERIFICATION = 'user-verification',
    USER_VERIFICATION_RESET = 'user-verification-reset',
    CREATE_SECURITY_QUESTIONS = 'create-security-questions',
    ANSWER_SECURITY_QUESTIONS = 'answer-security-questions',
    AUTHENTICATE_SECURE_TOKEN = 'authenticate-secure-token',
    REGISTER_SECURE_TOKEN = 'register-secure-token',
    FORGOT_PASSWORD = 'forgot-password',
    LOCKED_OUT = 'locked-out',
    UNLOCK_USER = 'unlock-user',
}

export enum RsaStatus {
    NOT_SET = 'NotSet',
    ALLOW = 'Allow',
    BLOCK = 'Block',
    CHALLENGE = 'Challenge',
    LOCKED = 'Locked',
    REGISTER = 'Register',
    Undefined = 'Undefined',
}

export enum SecureTokenStatus {
    NOT_SET = 'NotSet',
    ALLOW = 'Allow',
    CHALLENGE = 'Challenge',
    LOCKED = 'Locked',
    REGISTER = 'Register',
}

export enum UserCredentialStatus {
    NOT_SET = 'NotSet',
    ALLOW = 'Allow',
    EXPIRED = 'Expired',
    INVALID = 'Invalid',
    TEMPORARY = 'Temporary',
}

export interface State {
    userCredentialStatus?: UserCredentialStatus[] | UserCredentialStatus;
    rsaStatus?: RsaStatus[] | RsaStatus;
    secureTokenStatus?: SecureTokenStatus[] | SecureTokenStatus;
    loginComplete?: boolean;
    nextStateName?: StateName;
    data?: unknown;
}

export interface Transition {
    currentStateName: StateName;
    nextStates: State[];
}

export const transitions: Transition[] = [
    {
        currentStateName: StateName.LOGIN,
        nextStates: [
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                rsaStatus: RsaStatus.ALLOW,
                secureTokenStatus: SecureTokenStatus.ALLOW,
                loginComplete: true,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.ALLOW,
                    UserCredentialStatus.TEMPORARY,
                    UserCredentialStatus.EXPIRED,
                ],
                rsaStatus: RsaStatus.CHALLENGE,
                nextStateName: StateName.ANSWER_SECURITY_QUESTIONS,
            },
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                rsaStatus: RsaStatus.REGISTER,
                secureTokenStatus: SecureTokenStatus.ALLOW,
                nextStateName: StateName.CREATE_SECURITY_QUESTIONS,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.TEMPORARY,
                    UserCredentialStatus.EXPIRED,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: SecureTokenStatus.ALLOW,
                nextStateName: StateName.CHANGE_PASSWORD,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.TEMPORARY,
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.ALLOW,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: [SecureTokenStatus.CHALLENGE, SecureTokenStatus.LOCKED],
                nextStateName: StateName.AUTHENTICATE_SECURE_TOKEN,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.TEMPORARY,
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.ALLOW,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: SecureTokenStatus.REGISTER,
                nextStateName: StateName.REGISTER_SECURE_TOKEN,
            },
        ],
    },
    {
        currentStateName: StateName.AUTHENTICATE_SECURE_TOKEN,
        nextStates: [
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.CHALLENGE],
                loginComplete: true,
            },
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                rsaStatus: RsaStatus.REGISTER,
                nextStateName: StateName.CREATE_SECURITY_QUESTIONS,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                nextStateName: StateName.CHANGE_PASSWORD,
            },
        ],
    },
    {
        currentStateName: StateName.REGISTER_SECURE_TOKEN,
        nextStates: [
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.CHALLENGE],
                loginComplete: true,
            },
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                rsaStatus: RsaStatus.REGISTER,
                nextStateName: StateName.CREATE_SECURITY_QUESTIONS,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                nextStateName: StateName.CHANGE_PASSWORD,
            },
        ],
    },
    {
        currentStateName: StateName.ANSWER_SECURITY_QUESTIONS,
        nextStates: [
            {
                userCredentialStatus: UserCredentialStatus.ALLOW,
                secureTokenStatus: SecureTokenStatus.ALLOW,
                loginComplete: true,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.ALLOW,
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                secureTokenStatus: [SecureTokenStatus.CHALLENGE, SecureTokenStatus.LOCKED],
                nextStateName: StateName.AUTHENTICATE_SECURE_TOKEN,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.ALLOW,
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                secureTokenStatus: SecureTokenStatus.REGISTER,
                nextStateName: StateName.REGISTER_SECURE_TOKEN,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                secureTokenStatus: SecureTokenStatus.ALLOW,
                nextStateName: StateName.CHANGE_PASSWORD,
            },
        ],
    },
    {
        currentStateName: StateName.CREATE_SECURITY_QUESTIONS,
        nextStates: [
            {
                loginComplete: true,
            },
        ],
    },
    {
        currentStateName: StateName.CHANGE_PASSWORD,
        nextStates: [
            {
                rsaStatus: RsaStatus.REGISTER,
                nextStateName: StateName.CREATE_SECURITY_QUESTIONS,
            },
            {
                loginComplete: true,
            },
        ],
    },
    {
        currentStateName: StateName.USER_VERIFICATION,
        nextStates: [
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                rsaStatus: RsaStatus.CHALLENGE,
                nextStateName: StateName.ANSWER_SECURITY_QUESTIONS,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: [SecureTokenStatus.CHALLENGE, SecureTokenStatus.LOCKED],
                nextStateName: StateName.AUTHENTICATE_SECURE_TOKEN,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: SecureTokenStatus.REGISTER,
                nextStateName: StateName.REGISTER_SECURE_TOKEN,
            },
            {
                userCredentialStatus: UserCredentialStatus.TEMPORARY,
                rsaStatus: [RsaStatus.REGISTER, RsaStatus.ALLOW],
                secureTokenStatus: SecureTokenStatus.ALLOW,
                nextStateName: StateName.CHANGE_PASSWORD,
            },
        ],
    },
    {
        currentStateName: StateName.USER_VERIFICATION_RESET,
        nextStates: [
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                rsaStatus: RsaStatus.CHALLENGE,
                nextStateName: StateName.ANSWER_SECURITY_QUESTIONS,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: [SecureTokenStatus.CHALLENGE, SecureTokenStatus.LOCKED],
                nextStateName: StateName.AUTHENTICATE_SECURE_TOKEN,
            },
            {
                userCredentialStatus: [
                    UserCredentialStatus.EXPIRED,
                    UserCredentialStatus.TEMPORARY,
                ],
                rsaStatus: [RsaStatus.ALLOW, RsaStatus.REGISTER],
                secureTokenStatus: SecureTokenStatus.REGISTER,
                nextStateName: StateName.REGISTER_SECURE_TOKEN,
            },
            {
                userCredentialStatus: UserCredentialStatus.TEMPORARY,
                rsaStatus: [RsaStatus.REGISTER, RsaStatus.ALLOW],
                secureTokenStatus: SecureTokenStatus.ALLOW,
                nextStateName: StateName.CHANGE_PASSWORD,
            },
        ],
    },
];
