BatchListController.$inject = [
    '$scope',
    'secCodesService',
    'achBatchService',
    'entitlementsService',
    '$state',
    'modalService',
    'securityService',
    '$filter',
    'achCompaniesService',
    'taxesService',
    'achSettingsService',
    'scopeService',
];

export default function BatchListController(
    $scope,
    secCodesService,
    achBatchService,
    entitlementsService,
    $state,
    modalService,
    securityService,
    $filter,
    achCompaniesService,
    taxesService,
    achSettingsService,
    scopeService
) {
    const federalTaxTemplateType = 1;
    const stateTaxTemplateType = 2;
    const bothTaxTemplateType = 3;
    const invalidTaxTemplateType = 0;

    const createFromBatchState = 'payables.ach.payments.createFromBatch';
    const createFromTaxTemplateState = 'payables.ach.payments.createFromTaxTemplate';
    const batchDetailState = 'payables.ach.batch-detail';
    const taxTemplateDetailState = 'payables.ach.batch-tax-detail';
    const cloneBatchState = 'payables.ach.batch.createClonedBatch';
    const cloneTaxTemplateState = 'payables.ach.batch.createClonedTaxTemplate';
    const createBatchState = 'payables.ach.batch.create';
    const createTaxTemplateState = 'payables.ach.batch.createTaxTemplate';
    const batchListState = 'payables.ach.batch-list';
    const taxTemplateListState = 'payables.ach.payments.tax-templates';
    const childSupportTemplatesState = 'payables.ach.payments.child-support-templates';
    const internationalPaymentTemplatesState = 'payables.ach.payments.international-ach-templates';

    $scope.filterObject = {};
    $scope.allItemsAreSelected = {};
    $scope.selectionList = [];
    $scope.list = [];
    $scope.achCompanies = [];

    $scope.search = search;
    $scope.resetSearch = resetSearch;
    $scope.setForm = setForm;
    $scope.selectOrDeselctAllBatches = selectOrDeselectAllBatches;
    $scope.updateSelectionList = updateSelectionList;
    $scope.calculateTotals = calculateTotals;
    $scope.bulkInitiate = bulkInitiate;
    $scope.checkEnability = checkEnability;
    $scope.goToBatchDetail = goToBatchDetail;
    $scope.clone = clone;
    $scope.createBatch = createBatch;
    $scope.deleteBatch = deleteBatch;
    $scope.initiate = initiate;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.setForm = setForm;
    $scope.isDataLoaded = false;
    $scope.isListView = isListView;

    $scope.filteredBatches = [];
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.searchObj = { text: null };
    $scope.goToTaxTemplateList = goToTaxTemplateList;
    $scope.getPaymentTypeName = getPaymentTypeName;
    $scope.allowTaxPayments = allowTaxPayments;
    $scope.hasApproveOrRejectPermission = hasApproveOrRejectPermission;
    $scope.goToChildSupportTemplates = goToChildSupportTemplates;
    $scope.hasChildSupportFeatureFlags = hasChildSupportFeatureFlags;
    $scope.goToInternationalAchTemplates = goToInternationalAchTemplates;
    $scope.hasInternationalAchTemplateFeatureFlagsAndEntitlements =
        hasInternationalAchTemplateFeatureFlagsAndEntitlements;
    $scope.disableDownload = true;

    function hasApproveOrRejectPermission(batch) {
        return batch.permissions.find(
            permission =>
                permission.permission === 'Approve' || permission.permission === 'RejectBatch'
        );
    }

    function allowTaxPayments() {
        if (!$scope.achSettings) return false;

        return (
            $scope.achSettings.allowFederalTaxPayments || $scope.achSettings.allowStateTaxPayments
        );
    }

    function getPaymentTypeName(name) {
        return taxesService.getPaymentTypeName(name);
    }

    function isListView() {
        return (
            $state.current.name === batchListState || $state.current.name === taxTemplateListState
        );
    }

    function isBatchSearch() {
        return (
            $state.current.name === createFromBatchState || $state.current.name === batchListState
        );
    }

    function isTaxTemplateSearch() {
        return (
            $state.current.name === createFromTaxTemplateState ||
            $state.current.name === taxTemplateListState
        );
    }

    function goToTaxTemplateList() {
        $state.go(taxTemplateListState);
    }

    function goToChildSupportTemplates() {
        $state.go(childSupportTemplatesState);
    }

    function goToInternationalAchTemplates() {
        $state.go(internationalPaymentTemplatesState);
    }

    function hasChildSupportFeatureFlags() {
        const hasFeatureFlags =
            entitlementsService.hasEntitlement('Feature.ACH.AllowChildSupportPayments') &&
            entitlementsService.hasEntitlement('Feature.Ach.ChildSupportPaymentTemplates');
        const hasCompanyEntitlementsSwitchedOn = $scope.achSettings?.allowChildSupportPayments;
        return hasFeatureFlags && hasCompanyEntitlementsSwitchedOn;
    }

    function hasInternationalAchTemplateFeatureFlagsAndEntitlements() {
        const hasIatFeatureFlag = entitlementsService.hasEntitlement(
            'Feature.ACH.AllowInternationalPayments'
        );
        const hasIatTemplateFeatureFlag = entitlementsService.hasEntitlement(
            'Feature.Ach.InternationalPaymentTemplates'
        );
        const hasUserIatTemplateEntitlement =
            entitlementsService.hasEntitlement('Full Edit International Ach Template') ||
            entitlementsService.hasEntitlement('Create International Ach Template') ||
            entitlementsService.hasEntitlement('Partial Edit International Ach Template') ||
            entitlementsService.hasEntitlement('Delete International Ach Template') ||
            entitlementsService.hasEntitlement('Approve International Ach Template') ||
            entitlementsService.hasEntitlement('File Upload Edit International ACH Template');

        const hasCompanyIatTemplateEntitlement = $scope.achSettings?.allowInternationalPayments;

        return (
            hasIatFeatureFlag &&
            hasIatTemplateFeatureFlag &&
            hasUserIatTemplateEntitlement &&
            hasCompanyIatTemplateEntitlement
        );
    }

    function setApprovalAttributes() {
        $scope.batchList.map(batch => {
            batch.approvalAttributes = {
                approvalEntity: 'achBatch',
                approvalCount: batch.completedApprovalCount,
                createdBy: batch.createdBy,
                updatedBy: batch.updatedByUserId,
                productId: batch.id,
            };
            return batch;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredBatches = $filter('filter')($scope.batchList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.batchList ||
            $scope.batchList.length <= 0
        ) {
            return true;
        }

        return (
            (!!row.name &&
                row.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.achCompanyName &&
                row.achCompanyName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.secCode &&
                row.secCode.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.lastUpdated &&
                $filter('date')(row.lastUpdated, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.debitAmount &&
                $filter('currency')(row.debitAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.creditAmount &&
                $filter('currency')(row.creditAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function disableFilter() {
        return $scope.batchList ? $scope.batchList.length <= 0 : true;
    }

    init();

    function init() {
        if ($state.params.paymentList) {
            $scope.setSelectedBatches($state.params.paymentList);
        }
        $scope.editBatchEntitlement =
            entitlementsService.hasEntitlement('Full Edit Ach Template') ||
            entitlementsService.hasEntitlement('Partial Edit Ach Template');
        $scope.approveBatchEntitlement = entitlementsService.hasEntitlement('Approve Ach Template');
        $scope.deleteBatchEntitlement = entitlementsService.hasEntitlement('Delete Ach Template');
        $scope.createPaymentEntitlement = entitlementsService.hasEntitlement(
            'ACH, Batch, CreatePayment'
        );
        $scope.createBatchEntitlement = entitlementsService.hasEntitlement('Create Ach Template');
        getSecCodes();
        $scope.dateOptions = ['Specific Date', 'Range'];
        $scope.amountOptions = ['Specific Amount', 'Range'];
        $scope.statusOptions = ['All', 'Pending Approval', 'Approval Rejected', 'Ready'];
        $scope.currentDate = moment().format('MM/DD/YYYY');

        achCompaniesService.getAll().then(data => {
            if (isTaxTemplateSearch()) {
                data = achCompaniesService.getCCDAchCompanies(data);
            }

            $scope.filterObject.achCompanyList = data;
            $scope.achCompanies = data;
            $scope.filterObject.achCompanyList.sort((a, b) =>
                a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase())
            );
            angular.forEach($scope.achCompanies, achCompany => {
                achCompany.isChecked = true;
            });

            whenDefined('achSettings', () => {
                resetSearch();
                searchTemplates(updateTemplateListOnInit);
            });
        });
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function searchTemplates(callback) {
        if (isBatchSearch()) achBatchService.search($scope.filterObject).then(callback);

        if (isTaxTemplateSearch())
            achBatchService.searchTaxTemplates($scope.filterObject).then(callback);
    }

    function updateTemplateListOnInit(response) {
        $scope.searchObj.text = null;
        $scope.batchList = response;
        setApprovalAttributes();
        filterSearch($scope.searchText);
        if ($scope.batchList.length === 0) {
            $scope.sidebarContainerController.setCollapsed(false);
            $scope.isDataLoaded = true;
        } else {
            $scope.disableDownload = false;
        }
    }

    function updateTemplateListOnClick(response) {
        if (response.length === 0) {
            $scope.sidebarContainerController.setCollapsed(false);
            $scope.disableDownload = true;
        } else {
            $scope.sidebarContainerController.setCollapsed(true);
            $scope.disableDownload = false;
        }
        $scope.searchObj.text = null;
        $scope.batchList = response;
        setApprovalAttributes();
        filterSearch($scope.searchText);
    }

    function deleteBatch(id) {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete',
            headerText: 'Delete Template',
            bodyText: 'Are you sure you want to delete this Template?',
            submit(result) {
                modalInstance.close(result);
            },
        };

        var modalInstance = modalService.showModal({}, modalOptions);

        modalInstance.result.then(result => {
            const batchToDelete = { AchBatchId: id };
            securityService
                .verifyUser('Delete Batch', batchToDelete, () =>
                    achBatchService.deleteBatch(batchToDelete).then(response => response.data)
                )
                .then(response => {
                    if (response) {
                        angular.forEach($scope.batchList, batch => {
                            if (batch.id === id) {
                                batch.status = 'Deleted';
                            }
                        });
                    }
                });
        });
    }

    function hasAccessPermissions(batch, permissionType) {
        let result = false;
        angular.forEach(batch.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    function whenDefined(expression, callback) {
        scopeService.whenDefined(expression, callback, $scope);
    }

    function resetTemplateType(filterObject) {
        configureTaxTemplateType($scope.achSettings, filterObject);
    }

    function configureTaxTemplateType(achSettings, filterObject) {
        if (achSettings) {
            if (achSettings.allowFederalTaxPayments && achSettings.allowStateTaxPayments) {
                filterObject.taxTemplateType = bothTaxTemplateType;
            } else if (achSettings.allowFederalTaxPayments) {
                filterObject.taxTemplateType = federalTaxTemplateType;
            } else if (achSettings.allowStateTaxPayments) {
                filterObject.taxTemplateType = stateTaxTemplateType;
            } else {
                filterObject.taxTemplateType = invalidTaxTemplateType;
            }
        }
    }

    function resetSearch() {
        if ($scope.form) {
            $scope.form.$setPristine();
        }
        $scope.filterObject = {};
        resetTemplateType($scope.filterObject);

        $scope.filterObject.achCompanyList = [];
        angular.forEach($scope.achCompanies, achCompany => {
            achCompany.isChecked = true;
            $scope.filterObject.achCompanyList.push(achCompany);
        });
        $scope.filterObject.batchName = '';
        $scope.filterObject.debitAmountType = $scope.amountOptions[0];
        $scope.filterObject.updatedDateType = $scope.dateOptions[0];
        $scope.filterObject.creditAmountType = $scope.amountOptions[0];
        $scope.filterObject.status = $scope.isListView ? 'All' : 'Ready';
        $scope.filterObject.secCode = 'All';
    }

    function initiate(batch) {
        const list = [];
        list.push({ key: batch.id, value: '' });
        achBatchService.bulkInitiate(list).then(response => {
            // $scope.setSelectedBatches is defined in ach controller
            $scope.setSelectedBatches(response);

            if ($scope.isListView) {
                createFromTemplate();
            }
        });
    }

    function search() {
        searchTemplates(updateTemplateListOnClick);
    }

    function createBatch() {
        if (isBatchSearch()) $state.go(createBatchState);
        if (isTaxTemplateSearch()) $state.go(createTaxTemplateState);
    }

    function getSecCodes() {
        secCodesService.getConfigcSecCodes().then(response => {
            $scope.secCodes = response;
            $scope.secCodes.unshift('All');
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function clone(id) {
        if (isBatchSearch()) cloneTemplate(id, cloneBatchState);
        if (isTaxTemplateSearch()) cloneTemplate(id, cloneTaxTemplateState);
    }

    function cloneTemplate(id, state) {
        $state.go(state, { cloneId: id });
    }

    function selectOrDeselectAllBatches() {
        angular.forEach($scope.batchList, batch => {
            if (batch.status === 'Ready' && filterRows(batch)) {
                // don't select it if the row has been filtered out
                batch.isSelected = $scope.allItemsAreSelected.value;
                if ($scope.allItemsAreSelected.value === true) {
                    $scope.selectionList.push(batch);
                } else {
                    $scope.selectionList = [];
                }
            }
        });
    }

    function goToBatchDetail(batch) {
        if (isBatchSearch()) goToDetail(batch, batchDetailState);
        if (isTaxTemplateSearch()) goToDetail(batch, taxTemplateDetailState);
    }

    function goToDetail(batch, state) {
        $scope.setUploadedBatch(null);
        $state.go(state, { id: batch.id, type: 'view' });
    }

    function updateSelectionList(batch) {
        let allSelected = true;

        if (batch.isSelected === true) {
            $scope.selectionList.push(batch);
        } else if (batch.isSelected === false) {
            $scope.selectionList.splice($scope.selectionList.indexOf(batch), 1);
        }

        angular.forEach($scope.batchList, batch => {
            if (batch.status === 'Ready') {
                if (!batch.isSelected) {
                    allSelected = false;
                }
            }
        });

        if (allSelected) {
            $scope.allItemsAreSelected.value = true;
        } else {
            $scope.allItemsAreSelected.value = false;
        }
    }

    function calculateTotals(data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    }

    function checkEnability() {
        return $scope.selectionList.length <= 0;
    }

    function convertTemplateToLookup(payment) {
        return { key: payment.achPaymentDraftId, value: '' };
    }

    function initiatePaymentFromTemplate(list) {
        $state.go(createFromBatchState);
    }

    function createFromTemplate() {
        if (isBatchSearch()) {
            initiatePaymentFromTemplate($scope.payment);
        }
        if (isTaxTemplateSearch()) $state.go(createFromTaxTemplateState);
    }

    function bulkInitiate() {
        const list = $scope.selectionList.map(item => ({ key: item.id, value: '' }));
        achBatchService.bulkInitiate(list).then(response => {
            // $scope.setSelectedBatches is defined in ach controller
            $scope.setSelectedBatches(response);

            if ($scope.isListView) {
                createFromTemplate();
            }
        });
    }
}
