// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

filtersModule.filter('negativeParentheses', negativeParentheses);

negativeParentheses.$inject = ['$filter', '$locale'];

function negativeParentheses($filter, $locale) {
    return function (value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return `(${value.substring(1)})`;
        }
        return value;
    };
}
