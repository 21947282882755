WorkArpExceptionsWidgetController.$inject = [
    '$scope',
    'toaster',
    'dashboardService',
    'modalService',
    '$state',
    'spinnerService',
    'companyAccountsService',
    '$timeout',
    'achExceptionDecisionStatusTypes',
    '$uibModal',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function WorkArpExceptionsWidgetController(
    $scope,
    toaster,
    dashboardService,
    modalService,
    $state,
    spinnerService,
    companyAccountsService,
    $timeout,
    achExceptionDecisionStatusTypes,
    $uibModal
) {
    $scope.entitlement = '';

    let canDisplayArp = false;
    let canDisplayAch = false;
    let areCutoffTimesLoaded = false;

    const vm = this;
    vm.arpExceptionsPristine = [];
    vm.arpExceptionList = [];
    vm.achExceptionsPristine = [];
    vm.achExceptionList = [];
    vm.successMessage = '';
    vm.errorMessage = '';
    vm.arpAlertMessage = '';
    vm.achAlertMessage = '';
    vm.isConfirmation = false;
    vm.isConfirmationComplete = false;
    vm.isReviewing = false;
    vm.activeProduct = {};
    vm.allowArpEdit = false;
    vm.allowAchEdit = false;
    vm.reviewList = [];
    vm.workExceptionProducts = {};

    vm.loadCompletionCallback = null;

    vm.goToArpList = function (exception) {
        $state.go('payables.arp.check-exceptions', { accountNumber: exception.accountNumber });
    };

    vm.goToAchList = function (exception) {
        $state.go('payables.arp.ach-exceptions-list', {
            accountNumber: exception.accountNumber,
        });
    };

    vm.setDecisionToExistingDecision = function (exception) {
        exception.pay = exception.existingDecision;
    };

    vm.setDecisionToNoDecision = function (exception) {
        exception.achExceptionDecisionStatusId = achExceptionDecisionStatusTypes.noDecision;
    };

    vm.setAllPay = function () {
        if (vm.ShowAchExceptions()) {
            setAllAchDecisionsToPay();
        } else if (vm.ShowCheckExceptions()) {
            setAllDecisions(true);
        }
    };

    vm.setAllReturn = function () {
        if (vm.ShowAchExceptions()) {
            setAllAchDecisionsToReturn();
        } else if (vm.ShowCheckExceptions()) {
            setAllDecisions(false);
        }
    };

    vm.ShowCheckExceptions = function () {
        return vm.activeProduct.type === 'arpExceptions' && canDisplayArp;
    };

    vm.ShowAchExceptions = function () {
        return vm.activeProduct.type === 'achExceptions' && canDisplayAch;
    };

    vm.canDecision = function (exception) {
        return exception != null && exception.protected !== 'Y';
    };

    vm.showPayLock = function (exception) {
        return vm.canDecision(exception) === false && !isReturnDecision(exception);
    };

    vm.showReturnLock = function (exception) {
        return vm.canDecision(exception) === false && !isPayDecision(exception);
    };

    vm.disableEdits = function () {
        if (vm.ShowAchExceptions()) {
            return !vm.allowAchEdit;
        }
        if (vm.ShowCheckExceptions()) {
            return !vm.allowArpEdit;
        }
        return true;
    };

    init();

    $scope.$watch(
        '$parent.dashboardEntitlements',
        newValue => {
            if (newValue !== null && typeof newValue === 'object') {
                newValue.forEach(item => {
                    if (item.widget === 'arp') {
                        $scope.entitlement = item.permission;
                    }
                });
            }
        },
        true
    );

    function isPayDecision(exception) {
        if (vm.ShowAchExceptions()) {
            return exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.pay;
        }
        if (vm.ShowCheckExceptions()) {
            return exception.pay === true;
        }

        return false;
    }

    function isReturnDecision(exception) {
        if (vm.ShowAchExceptions()) {
            return (
                exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.return
            );
        }
        if (vm.ShowCheckExceptions()) {
            return exception.pay === false;
        }

        return false;
    }

    function isReviewButtonDisabled() {
        if (vm.ShowAchExceptions()) {
            return (
                !vm.achExceptionList.some(
                    exception => exception.achExceptionDecisionStatusId > 0
                ) || vm.disableEdits()
            );
        }
        return !vm.allowArpEdit || vm.arpExceptionList.length === 0;
    }

    function setAllDecisions(payState) {
        getActiveList().forEach(exception => {
            if (exception.protected !== 'Y') {
                exception.pay = payState;
            }
        });
    }

    function setAllAchDecisionsToPay() {
        getActiveList().forEach(exception => {
            if (exception.protected !== 'Y') {
                exception.achExceptionDecisionStatusId = achExceptionDecisionStatusTypes.pay;
            }
        });
    }

    function setAllAchDecisionsToReturn() {
        getActiveList().forEach(exception => {
            if (exception.protected !== 'Y') {
                exception.achExceptionDecisionStatusId = achExceptionDecisionStatusTypes.return;
            }
        });
    }

    function getActiveList() {
        if (vm.ShowAchExceptions()) {
            return vm.achExceptionList;
        }
        if (vm.ShowCheckExceptions()) {
            return vm.arpExceptionList;
        }
        return [];
    }

    function getReviewList() {
        if (vm.ShowAchExceptions()) {
            return getActiveList().filter(exception => exception.achExceptionDecisionStatusId > 0);
        }
        if (vm.ShowCheckExceptions()) {
            return getActiveList();
        }
        return [];
    }

    function initActiveList() {
        if (vm.ShowAchExceptions()) {
            vm.achExceptionList = [];
        } else if (vm.ShowCheckExceptions()) {
            vm.arpExceptionList = [];
        } else {
            vm.arpExceptionList = [];
            vm.achExceptionList = [];
        }
    }

    function init() {
        spinnerService.configureGroup(
            'arpExceptionsWidget',
            [
                '/dashboard/channelWorkExceptions',
                '/dashboard/achExceptionsBatch',
                '/dashboard/arpExceptionsBatch',
                '/dashboard/WidgetProducts',
            ],
            ['arpWidget.html', 'arpWidgetFooter.html', 'workArpExceptionsOptions.html']
        );

        $scope.widget.leftControlsTemplate =
            'app/dashboard/widgets/views/workArpExceptionsOptions.html';

        $scope.widget.leftControlsController = {
            initializeProducts,
            getWorkExceptionProducts,
            loadProduct,
            isTabSelected,
            isReviewing,
        };

        $scope.widget.footerTemplate = 'app/dashboard/widgets/views/arpWidgetFooter.html';

        $scope.widget.footerController = {
            isEditingDecisions,
            isReviewing,
            isOnConfirmation,
            isOnConfirmationComplete,
            disableEdits: vm.disableEdits,
            isReviewButtonDisabled,
            review,
            reset,
            reload,
            confirm,
            cancel,
            setAllPay: vm.setAllPay,
            setAllReturn: vm.setAllReturn,
        };
    }

    function isTabSelected(product) {
        return vm.activeProduct === product;
    }

    function initializeProducts() {
        updateSpinner(spinnerService.startGroupRequest('arpExceptionsWidget'));
        dashboardService.getAvailableWidgetProducts('workArp').then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('arpExceptionsWidget'));
                vm.workExceptionProducts = response;
                for (let i = 0; i < vm.workExceptionProducts.length; i++) {
                    if (vm.workExceptionProducts[i].count > 0) {
                        vm.activeProduct = vm.workExceptionProducts[i];
                        break;
                    }
                }
                if (vm.activeProduct) {
                    loadTableData(vm.activeProduct.type);
                }
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('arpExceptionsWidget'));
            }
        );
    }

    function getWorkExceptionProducts() {
        return vm.workExceptionProducts;
    }

    function loadProduct(product) {
        if (noDecision()) {
            vm.activeProduct = product;
            loadTableData(vm.activeProduct.type);
        } else {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    vm.activeProduct = product;
                    loadTableData(vm.activeProduct.type);
                    $modalInstance1.dismiss();
                },
            };
            var $modalInstance1 = modalService.showModal({}, modalOptions);
        }
    }

    function reset() {
        vm.achExceptionList = angular.copy(vm.achExceptionsPristine);
        vm.arpExceptionList = angular.copy(vm.arpExceptionsPristine);
    }

    function convertTime(time) {
        return moment(`${moment().format('l')} ${time}`).format('LT');
    }

    function loadCutoffTimes() {
        // Load Arp Cutoff times
        if (!areCutoffTimesLoaded) {
            companyAccountsService.getCutoffTimesByProductType('ArpException').then(response => {
                const timeZone = response.fiTimeZone;
                const startTime = convertTime(response.processingCutoff.startTime);
                const cutoffTime = convertTime(response.processingCutoff.cutoffTime);

                const timeUntilStart = moment(
                    `${moment(response.currentFiTime).format('L')} ${
                        response.processingCutoff.startTime
                    }`
                ).diff(response.currentFiTime);
                const timeUntilCutoff = moment(
                    `${moment(response.currentFiTime).format('L')} ${
                        response.processingCutoff.cutoffTime
                    }`
                ).diff(response.currentFiTime);

                vm.allowArpEdit = timeUntilStart <= 0 && timeUntilCutoff > 0;
                canDisplayArp = timeUntilStart <= 0;

                if (timeUntilStart > 0) {
                    //  set a timed event to enable edits when the start time has passed.
                    $timeout(() => {
                        canDisplayArp = true;
                        vm.allowArpEdit = true;
                        toaster.alert(
                            'Past Check Exception Start Time',
                            'You can now edit decisions for the listed Check Exceptions.'
                        );
                        vm.arpAlertMessage='';
                        initializeProducts();
                    }, timeUntilStart);
                }
                if (timeUntilCutoff > 0) {
                    //  set a timed event to disable edits when the Cut-Off Time has passed.
                    $timeout(() => {
                        vm.allowArpEdit = false;
                        toaster.alert(
                            'Past Check Exception Cut-Off Time',
                            'Check Exception items are disabled because the current time is past the Cut-Off Time.'
                        );
                        vm.arpAlertMessage = `Check exception items are disabled because the current time is past the ${cutoffTime} ${timeZone} Cut-Off time`;
                    }, timeUntilCutoff);
                }

                if (timeUntilStart > 0) {
                    vm.arpAlertMessage = `Check exception items are not available at this time. Please try again after ${startTime} ${timeZone}`;
                } else if(timeUntilCutoff <= 0) {
                    vm.arpAlertMessage = `Check exception items are disabled because the current time is past the ${cutoffTime} ${timeZone} Cut-Off time`
                }
            });

            // Load ACH Cutoff times
            companyAccountsService.getCutoffTimesByProductType('AchException').then(response => {
                const timeZone = response.fiTimeZone;
                const startTime = convertTime(response.processingCutoff.startTime);
                const cutoffTime = convertTime(response.processingCutoff.cutoffTime);

                const timeUntilStart = moment(
                    `${moment(response.currentFiTime).format('L')} ${
                        response.processingCutoff.startTime
                    }`
                ).diff(response.currentFiTime);
                const timeUntilCutoff = moment(
                    `${moment(response.currentFiTime).format('L')} ${
                        response.processingCutoff.cutoffTime
                    }`
                ).diff(response.currentFiTime);

                vm.allowAchEdit = timeUntilStart <= 0 && timeUntilCutoff > 0;
                canDisplayAch = timeUntilStart <= 0;

                if (timeUntilStart > 0) {
                    //  set a timed event to enable edits when the start time has passed.
                    $timeout(() => {
                        canDisplayAch = true;
                        vm.allowAchEdit = true;
                        toaster.alert(
                            'Past ACH Exception Start Time',
                            'You can now edit decisions for the listed ACH Exceptions.'
                        );
                        initializeProducts();
                        vm.achAlertMessage = '';
                    }, timeUntilStart);
                }

                if (timeUntilCutoff > 0) {
                    //  set a timed event to disable edits when the Cut-Off Time has passed.
                    $timeout(() => {
                        vm.allowAchEdit = false;
                        toaster.alert(
                            'Past ACH Exception Cut-Off Time',
                            'ACH Exception items are disabled because the current time is past the Cut-Off Time.'
                        );
                        vm.achAlertMessage = `ACH exception items are disabled because the current time is past the ${cutoffTime} ${timeZone} Cut-Off time`
                    }, timeUntilCutoff);
                }

                if (timeUntilStart > 0) {
                    vm.achAlertMessage = `ACH exception items are not available at this time. Please try again after ${startTime} ${timeZone}`;
                } else if(timeUntilCutoff <= 0) {
                    vm.achAlertMessage = `ACH exception items are disabled because the current time is past the ${cutoffTime} ${timeZone} Cut-Off time`
                }

            });
            areCutoffTimesLoaded = true;
        }
    }

    function loadTableData(productType) {
        updateSpinner(spinnerService.startGroupRequest('arpExceptionsWidget'));
        loadCutoffTimes();
        dashboardService.getWorkExceptions(productType).then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('arpExceptionsWidget'));
                response.forEach(exception => {
                    exception.pay = getPayState(exception.decisionTaken);
                    exception.existingDecision = exception.pay;
                });

                let exceptionItem;
                if (productType === 'arpExceptions') {
                    vm.arpExceptionsPristine = response;
                    vm.arpExceptionList = angular.copy(vm.arpExceptionsPristine);
                    exceptionItem = vm.workExceptionProducts.filter(
                        item => item.type === 'arpExceptions'
                    );
                } else if (productType === 'achExceptions') {
                    vm.achExceptionsPristine = response;
                    vm.achExceptionList = angular.copy(vm.achExceptionsPristine);
                    exceptionItem = vm.workExceptionProducts.filter(
                        item => item.type === 'achExceptions'
                    );
                    if (exceptionItem != null && exceptionItem.length > 0) {
                        exceptionItem[0].count = vm.achExceptionList.length;
                    }
                }

                if (exceptionItem != null && exceptionItem.length > 0) {
                    vm.activeProduct = exceptionItem[0];
                }
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('arpExceptionsWidget'));
            }
        );
    }

    function getPayState(decisionCode) {
        if (decisionCode === 'Pay') {
            return true;
        }
        if (decisionCode === 'Return') {
            return false;
        }
        return null;
    }

    function getDecisionLabel(exception) {
        if (vm.ShowAchExceptions()) {
            return getAchDecisionLabel(exception);
        }
        if (vm.ShowCheckExceptions()) {
            return getCheckDecisionLabel(exception.pay);
        }

        return '';
    }

    function getCheckDecisionLabel(payState) {
        if (payState === true) {
            return 'Pay';
        }
        if (payState === false) {
            return 'Return';
        }
        return 'No Decision';
    }

    function getAchDecisionLabel(exception) {
        switch (exception.achExceptionDecisionStatusId) {
            case achExceptionDecisionStatusTypes.pay:
                return 'Pay';
            case achExceptionDecisionStatusTypes.return:
                return 'Return';
            case achExceptionDecisionStatusTypes.noDecision:
                return 'No Decision';
            default:
                return '';
        }
    }

    function hasNewDecision(exception) {
        if (vm.ShowAchExceptions()) {
            return (
                exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.pay ||
                exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.return
            );
        }
        if (vm.ShowCheckExceptions()) {
            return exception.pay != null && exception.pay !== exception.existingDecision;
            // Note: 'No Decision' (null) indicates the absence of a decision, not a state that can be saved for a decision.
        }
    }

    function noDecision() {
        const activeList = getActiveList();
        let hasNoNewDecision = true;
        if (activeList && activeList.length > 0) {
            for (let i = 0; i < activeList.length; i++) {
                if (hasNewDecision(activeList[i])) {
                    hasNoNewDecision = false;
                    break;
                }
            }
        }
        return hasNoNewDecision;
    }

    function isEditingDecisions() {
        return vm.isReviewing === false;
    }

    function isReviewing() {
        return vm.isReviewing === true && vm.isConfirmationComplete === false;
    }

    function isOnConfirmation() {
        return vm.isConfirmation === true;
    }

    function isOnConfirmationComplete() {
        return vm.isConfirmationComplete === true;
    }

    function reload() {
        vm.isConfirmationComplete = false;
        vm.isConfirmation = false;
        vm.isReviewing = false;
        initActiveList();
        $scope.$parent.flipWidget($scope.widget, false);
        loadTableData(vm.activeProduct.type);
    }

    function cancel() {
        $scope.$parent.flipWidget($scope.widget, false);
        vm.isReviewing = false;
    }

    function review() {
        vm.reviewList = [];
        getReviewList().forEach(exception => {
            exception.decisionReviewLabel = getDecisionLabel(exception);
            vm.reviewList.push(exception);
        });

        $scope.$parent.flipWidget($scope.widget, true);
        vm.isReviewing = true;
    }

    function confirm() {
        if (!vm.reviewList.length) {
            vm.isConfirmation = true;
            vm.isConfirmationComplete = true;
            return;
        }

        vm.successMessage = '';
        vm.errorMessage = '';

        const { type } = vm.activeProduct;
        if (type === 'arpExceptions') {
            confirmCheck();
        } else if (type === 'achExceptions') {
            confirmAch();
        }
    }

    function confirmAch() {
        const modalOptions = {
            closeButtonText: 'No',
            actionButtonText: 'Yes',
            headerText: 'Confirm Decisions',
            bodyHeader:
                'You are about to decision ACH Exception items that can only be decisioned one time.',
            bodyText: 'Are you sure you would like to proceed?',
            submit() {
                $modalInstance.close();

                vm.isConfirmationComplete = false;
                vm.isConfirmation = true;

                const confirmedDecisions = vm.reviewList.filter(
                    exception =>
                        exception.achExceptionDecisionStatusId !==
                        achExceptionDecisionStatusTypes.noDecision
                );

                const dashboardServiceFunction = dashboardService.postAchExceptions;
                updateSpinner(spinnerService.startGroupRequest('arpExceptionsWidget'));
                dashboardServiceFunction(confirmedDecisions).then(
                    response => {
                        closeSpinnerAfterConfirmation();
                        displayPostAchExceptionsResult(response, confirmedDecisions);
                    },
                    error => {
                        closeSpinnerAfterConfirmation();
                    }
                );
            },
        };

        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function displayPostAchExceptionsResult(response, confirmedDecisions) {
        const modalInstance = $uibModal.open({
            template: require('../../../payables/arp/views/achExceptionOpenItemsSaveConfirmationModal.html'),
            size: 'sm',
            controller: 'AchExceptionOpenItemsSaveConfirmationModalController',
            backdrop: 'static',
            resolve: {
                failedDecisionsCount() {
                    return response.failedDecisionsCount;
                },
                confirmedDecisionCount() {
                    return confirmedDecisions.length;
                },
            },
        });

        modalInstance.result.then(response => {
            if (response.successMessage) {
                vm.successMessage = response.successMessage;
            }
            if (response.errorMessage) {
                vm.errorMessage = response.errorMessage;
            }
        });
    }

    function confirmCheck() {
        vm.isConfirmationComplete = false;
        vm.isConfirmation = true;

        const dashboardServiceFunction = dashboardService.postArpExceptions;

        updateSpinner(spinnerService.startGroupRequest('arpExceptionsWidget'));
        dashboardServiceFunction(vm.reviewList).then(
            response => {
                closeSpinnerAfterConfirmation();

                if (response.successMessage) {
                    vm.successMessage = response.successMessage;
                }
                if (response.errorMessage) {
                    vm.errorMessage = response.errorMessage;
                }
                if (response && !response.successMessage && !response.errorMessage) {
                    vm.successMessage = 'Decisions saved successfully';
                }
            },
            error => {
                closeSpinnerAfterConfirmation();
            }
        );
    }

    function updateSpinner(numPendingRequests) {
        $scope.widget.isLoading = numPendingRequests > 0;
    }

    function closeSpinnerAfterConfirmation() {
        vm.isConfirmationComplete = true;
        updateSpinner(spinnerService.stopGroupRequest('arpExceptionsWidget'));
    }
}
