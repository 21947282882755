import { html } from 'lit';
import { map } from 'lit/directives/map.js';

interface apiResponse {
    successLevel: number;
    responseDetailCollection: {
        responseCode: number;
        responseMessage: string;
    }[];
}

const renderMessage = (i: string) => html`${i}<br />`;

export const handleApiError = (responseObject: apiResponse, title: string) => {
    const messages = responseObject.responseDetailCollection.map(
        ({ responseCode, responseMessage }) => `${responseCode} - ${responseMessage}`
    );
    return {
        message: html`${map(messages, renderMessage)}`,
        type: 'error',
        title,
        visible: true,
        posture: 'default',
    };
};
