RemoveWireTemplateController.$inject = ['$scope', '$modalInstance', 'initiatedRecurringWire'];

export default function RemoveWireTemplateController(
    $scope,
    $modalInstance,
    initiatedRecurringWire
) {
    $scope.initiatedRecurringWire = initiatedRecurringWire;
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.remove = function () {
        $modalInstance.close($scope.comments);
    };
}
