AchExceptionOpenItemsReviewModalController.$inject = [
    '$scope',
    '$modalInstance',
    'achExceptions',
    'achExceptionDecisionStatusTypes',
];

export default function AchExceptionOpenItemsReviewModalController(
    $scope,
    $modalInstance,
    achExceptions,
    achExceptionDecisionStatusTypes
) {
    // variables
    $scope.form = null;
    $scope.achExceptions = achExceptions;

    // functions
    $scope.getDecision = getDecision;
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.setForm = setForm;

    // public functions
    function getDecision(exception) {
        switch (exception.achExceptionDecisionStatusId) {
            case achExceptionDecisionStatusTypes.noDecision:
                return 'No Decision';
            case achExceptionDecisionStatusTypes.pay:
                return 'Pay';
            case achExceptionDecisionStatusTypes.return:
                return 'Return';
            default:
                return '';
        }
    }

    function save() {
        $modalInstance.close($scope.achExceptions);
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }
}
