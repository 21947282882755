// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

export default class AchExceptionsRequests {
    static async findAchExceptions(body) {
        return (await TmHttpClient.getInstance()).request('arp/findAchExceptions', {
            body,
            method: 'POST',
        });
    }

    static async findAchExceptionsDecisionsActivity(body) {
        return (await TmHttpClient.getInstance()).request(
            'arp/findAchExceptionsDecisionsActivity',
            {
                body,
                method: 'POST',
            }
        );
    }
}
