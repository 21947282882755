ImportLayoutController.$inject = ['$scope', 'importLayoutService', 'toaster'];

export default function ImportLayoutController($scope, importLayoutService, toaster) {
    $scope.delimitorOptions = ['comma'];

    $scope.setControl = setControl;
    $scope.checkInvalid = checkInvalid;
    $scope.setForm = setForm;
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.checkRange = checkRange;
    $scope.setFixedPositionForm = setFixedPositionForm;
    $scope.setUseTransactionCodeDelimited = setUseTransactionCodeDelimited;
    $scope.setUseTransactionCodeFixedPosition = setUseTransactionCodeFixedPosition;
    $scope.useTransactionCodeDelimited = true;
    $scope.useTransactionCodeFixedPosition = true;

    // events
    $scope.$watch('importLayout', checkInvalid, true);

    function setControl(type) {
        $scope.importLayout.uploadFormat = type;
    }

    function setUseTransactionCodeDelimited(value) {
        $scope.useTransactionCodeDelimited = value;
        clearHiddenControlsDelimited();
    }

    function setUseTransactionCodeFixedPosition(value) {
        $scope.useTransactionCodeFixedPosition = value;
        clearHiddenControlsFixedPosition();
    }

    function clearHiddenControlsDelimited() {
        if ($scope.importLayout) {
            if ($scope.useTransactionCodeDelimited) {
                $scope.importLayout.accountTypeColumn = null;
                $scope.importLayout.accountTypeCheckingDelimited = null;
                $scope.importLayout.accountTypeSavingsDelimited = null;
                $scope.importLayout.accountTypeLoanDelimited = null;
                $scope.importLayout.accountTypeGlDelimited = null;
                $scope.importLayout.transactionTypeColumn = null;
                $scope.importLayout.transactionTypeDebitDelimited = null;
                $scope.importLayout.transactionTypeCreditDelimited = null;
            } else {
                $scope.importLayout.transactionCodeColumn = null;
            }
        }
    }

    function clearHiddenControlsFixedPosition() {
        if ($scope.importLayout) {
            if ($scope.useTransactionCodeFixedPosition) {
                $scope.importLayout.accountTypeBegin = null;
                $scope.importLayout.accountTypeEnd = null;
                $scope.importLayout.accountTypeCheckingFixedPosition = null;
                $scope.importLayout.accountTypeSavingsFixedPosition = null;
                $scope.importLayout.accountTypeLoanFixedPosition = null;
                $scope.importLayout.accountTypeGlFixedPosition = null;
                $scope.importLayout.transactionTypeBegin = null;
                $scope.importLayout.transactionTypeEnd = null;
                $scope.importLayout.transactionTypeDebitFixedPosition = null;
                $scope.importLayout.transactionTypeCreditFixedPosition = null;
            } else {
                $scope.importLayout.transactionCodeBegin = null;
                $scope.importLayout.transactionCodeEnd = null;
            }
        }
    }

    function save() {
        importLayoutService.save($scope.importLayout).then(response => {
            toaster.save('Import Layout');
            $scope.importLayout = response;
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function setFixedPositionForm(form) {
        $scope.form1 = form;
    }

    function cancel(type, form) {
        importLayoutService.getImportLayoutValues().then(response => {
            $scope.importLayout = response;
            $scope.importLayout.uploadFormat = type;
            if (type === 'delimited') {
                $scope.form = form;
                $scope.form.$setPristine();
            } else if (type === 'fixedPosition') {
                $scope.form1 = form;
                $scope.form1.$setPristine();
            }
        });
    }

    function checkInvalid() {
        if (
            !$scope.form ||
            !$scope.form.nameColumn ||
            !$scope.form.idNumber ||
            !$scope.form.routingNumber ||
            !$scope.form.accountNumber ||
            !$scope.form.amount ||
            !$scope.form.transactionCode
        ) {
            return false;
        }
        const distinct = [];
        if ($scope.importLayout) {
            distinct.push({ key: 'nameColumn', value: $scope.importLayout.nameColumn });
            distinct.push({ key: 'idNumber', value: $scope.importLayout.idNumberColumn });
            distinct.push({
                key: 'routingNumber',
                value: $scope.importLayout.routingNumberColumn,
            });
            distinct.push({
                key: 'accountNumber',
                value: $scope.importLayout.accountNumberColumn,
            });
            distinct.push({ key: 'amount', value: $scope.importLayout.amountColumn });
            distinct.push({
                key: 'transactionCode',
                value: $scope.importLayout.transactionCodeColumn,
            });
            distinct.push({ key: 'accountType', value: $scope.importLayout.accountTypeColumn });
            distinct.push({
                key: 'transactionType',
                value: $scope.importLayout.transactionTypeColumn,
            });
            for (let q = 0; q < distinct.length; q++) {
                if ($scope.form[distinct[q].key])
                    $scope.form[distinct[q].key].$setValidity('duplicateColumns', true);
            }
            for (let i = 0; i < distinct.length; i++) {
                for (let j = 0; j < distinct.length; j++) {
                    if (
                        i !== j &&
                        distinct[i].value !== null &&
                        distinct[j].value !== null &&
                        distinct[i].value === distinct[j].value
                    ) {
                        $scope.form[distinct[i].key].$setValidity('duplicateColumns', false);
                        $scope.form[distinct[i].key].$setDirty();
                        $scope.form[distinct[j].key].$setValidity('duplicateColumns', false);
                        $scope.form[distinct[j].key].$setDirty();
                    }
                }
            }
        }
        return true;
    }

    function checkRange(beginValue, endValue, columnName, allowEquals) {
        if (!allowEquals) {
            allowEquals = false;
        }
        $scope.form1[columnName].$setValidity('less', true);
        if (allowEquals) {
            if (beginValue && beginValue > endValue) {
                $scope.form1[columnName].$setValidity('less', false);
                $scope.form1.$setDirty();
            }
        } else if (beginValue && beginValue >= endValue) {
            $scope.form1[columnName].$setValidity('less', false);
            $scope.form1.$setDirty();
        }
    }

    init();

    function init() {
        importLayoutService.getImportLayoutValues().then(response => {
            $scope.importLayout = response;
            setUseTransactionCodeDelimited(response.transactionCodeColumn);
            setUseTransactionCodeFixedPosition(response.transactionCodeBegin);
        });
    }
}
