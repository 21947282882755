SearchFilterService.$inject = ['$state', '$rootScope'];

export default function SearchFilterService($state, $rootScope) {
    let _searchPanels = {};
    const _previousState = null;
    const _currentState = null;

    return {
        saveFilter,
        restoreFilter,
        reset,
    };

    function saveFilter(filter, returnFromStateNames) {
        let currentStateName;
        let searchPanel;

        currentStateName = $rootScope.currentState.name;
        searchPanel = _searchPanels[currentStateName] = {};
        searchPanel.returnFromStateNames = returnFromStateNames;
        searchPanel.filter = window.angular.copy(filter);
    }

    function restoreFilter(filter) {
        let result;
        let currentStateName;
        let previousStateName;
        let searchPanel;

        currentStateName = $rootScope.currentState.name;
        previousStateName = $rootScope.previousState.name;
        searchPanel = _searchPanels[currentStateName];
        if (searchPanel) {
            window.angular.forEach(searchPanel.returnFromStateNames, returnFromStateName => {
                if (returnFromStateName === previousStateName) {
                    window.angular.copy(searchPanel.filter, filter);
                }
            });
        }
    }

    function reset() {
        _searchPanels = {};
    }
}
