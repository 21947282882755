import { LitElement, html, css, nothing } from 'lit';
import { Recordset } from '@treasury/FDL';
import { mix } from 'mixwith';
import { Record } from '@treasury/FDL/record';
import BalanceReportsClient from '../clients/balance-reports-client.js';
import '@treasury/omega/layouts/omega-report';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../../components/blocking-loader.js';
import { reportFields, columns, filters } from '../data/balance-reports-data.js';

class BalanceReportsContainer extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            institution: String,
            type: String,
            id: Number,
            recordset: Object,
            record: Object,
            loading: Boolean,
            alert: Object,
            reportMetaData: Object,
            localFilters: Array,
        };
    }

    constructor() {
        super();
        this.client = new BalanceReportsClient();
        this.columns = columns;
        this.filters = filters;
        this.reportFields = reportFields;
        this.options = ['download', 'print'];
        this.rowsPerPage = 25;
        this.loading = true;
    }

    async fetchReportMetaData() {
        this.reportMetaData = await this.client.fetchReportData({
            ...this.reportFilterValues,
            ...this.recordset.parameters,
        });
        this.record = new Record(this.reportFields, this.reportMetaData);
        this.downloadOptions = this.record.getField('downloadOptions');
        this.title = this.record.getField('title');
        return Promise.resolve({
            data: this.reportMetaData.data,
            totalCount: this.reportMetaData.totalRecords,
        });
    }

    async firstUpdated() {
        this.id = window.location.pathname.split('/').pop();
        this.reportFilterValues = await this.client.fetchReportFilters(this.id);
        this.filters = filters(this.reportFilterValues);
        this.recordset = new Recordset(this.reportFields.data, this.fetchReportMetaData.bind(this));
        this.loading = false;
    }

    renderSummary() {
        if (!this.reportMetaData) return nothing;
        return html`<div id="report-summary">
            <div class="summary-column">
                <h2>Total Checking Balance:</h2>
                <p>${this.record.print('totalCheckingBalance')}</p>
            </div>
            <div class="summary-column">
                <h2>Total Savings Balance:</h2>
                <p>${this.record.print('totalSavingsBalance')}</p>
            </div>
        </div>`;
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    render() {
        if (this.loading) return nothing;
        return html`
            ${this.renderBlockingLoader()}
            <omega-report
                flyout
                autostart
                .title=${this.title}
                .actions=${this.actions}
                .reportInformation=${this.reportInformation}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recordset}
                .columns=${this.columns}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .reportActions=${this.reportActions}
                .downloadOptions=${this.downloadOptions}
                .downloadFunction=${this.downloadAchTemplates}
                .options=${this.options}
                .callToAction=${this.callToActionText}
                .rowsPerPage=${this.rowsPerPage}
                @callToAction=${this.callToAction}
                @reportDownload=${this.downloadAchTemplates}
            >
                <div slot="above-table">
                    ${this.renderSummary()}
                    <omega-filter-bar
                        id="type-to-filter"
                        .recordset=${this.recordset}
                        @change=${({ detail }) => {
                            this.localFilters = detail;
                        }}
                    ></omega-filter-bar>
                </div>
            </omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            omega-filter-bar {
                margin-left: 10px;
            }
            #report-summary {
                display: flex;
                justify-content: space-around;
            }
        `;
    }
}

customElements.define('balance-reports-container', BalanceReportsContainer);
