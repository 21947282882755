import { Injectable } from '@jack-henry/frontend-utils/di';
import { PositivePayCompanyConfigurationClient } from '@treasury/api/channel/generated/PositivePayCompanyConfiguration';

@Injectable()
export class CompanyConfigService {
    constructor(private readonly client: PositivePayCompanyConfigurationClient) {
        // eslint-disable-next-line no-empty-function
    }

    async getPositivePayCompanyConfiguration() {
        return this.client.positivePayCompanyConfigurationGetAllOptions();
    }

    public async hasOption(optionName: string) {
        const options = await this.getPositivePayCompanyConfiguration();
        return options.data.some(o => o.name === optionName && o.value === '1');
    }
}
