import { html, LitElement } from 'lit';
import '@treasury/omega/components/omega-table';
import { ListeningElementMixin } from '@treasury/omega/components';
import { mix } from 'mixwith';

export class ErrorTable extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            errors: Object,
        };
    }

    constructor() {
        super();
        this.columnDefinitions = [
            {
                field: 'lineNumber',
                label: 'Line Number',
            },
            {
                field: 'errorText',
                label: 'Error',
            },
        ];
    }

    get errorCount() {
        return this.errors.totalCount;
    }

    firstUpdated() {
        this.listenTo(this.errors, 'page-changed', () => this.requestUpdate());
    }

    render() {
        return html`
            <div>
                Below are a list of ${this.errorCount} errors that need to be corrected in your file
                before moving forward. Please click back to re-upload your file.
            </div>
            <omega-table
                title="Error Table"
                .recordset=${this.errors}
                .columnDefinitions=${this.columnDefinitions}
            ></omega-table>
        `;
    }
}

customElements.define('error-table', ErrorTable);
export default ErrorTable;
