// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { format } from 'date-fns';

import { FieldType, boolean } from '@treasury/FDL';
import { FdlFieldDefinitions, Record as FdlRecord } from '@treasury/FDL/record';
import { AlarmClock } from '@treasury/alarm-clock';
import { UsersService } from '@treasury/domain/channel/services/ach';
import { Wire, WiresService } from '@treasury/domain/wires';
import compareDates from '@treasury/omega/helpers/compare-dates';
import FieldTypes from '@treasury/policy/primitives';
import { html, nothing } from 'lit';
import '../../../components/channel-status-badge.js';
import { hasAccessPermissions } from './column.definitions';

const renderFrequencyType = (wire: Wire) => {
    if (!wire.parentFrequency) {
        return nothing;
    }
    return html`
        <br />

        ${WiresService.frequencyTypeToFriendly(wire.parentFrequency.type)}&nbsp;<omega-tooltip
            light
            direction="top"
            icon="info-circle"
            message=${WiresService.summarizeFrequency(wire.parentFrequency)}
        ></omega-tooltip>
    `;
};

const ReadOnlyStringField = FieldTypes.string.thatIs.readOnly() as FieldType<string>;
const ReadonlyDateField = FieldTypes.date.thatIs.readOnly() as FieldType<Date>;
const BeneficiaryField = new FieldType<Wire['beneficiary']>().thatIs
    .readOnly()
    .with.template(beneficiary => beneficiary?.name || '');
const DebitAccountField = new FieldType<Wire['debitAccount']>().thatIs
    .readOnly()
    .with.template(account => account?.number || '');
const FrequencyField = new FieldType<Wire['frequency']>().thatIs
    .readOnly()
    .with.template((frequency, record) => {
        const wire = record.values as Wire;
        const effectiveDate = frequency?.valueDate ? new Date(frequency.valueDate) : new Date();
        const formattedDate = format(effectiveDate, 'MM/dd/yyyy');
        return html` ${formattedDate}${renderFrequencyType(wire)}`;
    });
const WireCompanyField = new FieldType<Wire['wireCompany']>().thatIs
    .readOnly()
    .with.template(company => company?.name || '');

function canSelectWire(wire: Wire, hasApproveInternational: boolean) {
    const effectiveDate = new Date(wire.frequency?.nextPaymentDate ?? '');
    const today = new Date();
    const comparedDates = compareDates(effectiveDate, today);
    if (comparedDates === -1) {
        return false;
    }
    if (comparedDates === 0 && AlarmClock.getInstance().isAfter('wireCutoff')) {
        return false;
    }
    // eslint-disable-next-line @treasury/no-mixed-boolean-operators
    const internationalCheck = !wire.isDliWire || (wire.isDliWire && hasApproveInternational);
    return (
        wire.status === 'Pending Approval' &&
        internationalCheck &&
        hasAccessPermissions(wire, 'Approve')
    );
}

export const createFields = (
    usersService: UsersService,
    hasApproveInternational: boolean
): FdlFieldDefinitions<Wire> => ({
    isChecked: boolean.with
        .maxColumnWidth(30)
        .thatIs.disabledWhen(
            (record: FdlRecord<Wire>) => !canSelectWire(record.values, hasApproveInternational)
        ),
    transactionId: ReadOnlyStringField as FieldType<Wire['transactionId']>,
    wireCompany: WireCompanyField,
    type: ReadOnlyStringField as FieldType<Wire['type']>,
    debitAccount: DebitAccountField,
    beneficiary: BeneficiaryField,
    destinationCurrency: ReadOnlyStringField as FieldType<Wire['destinationCurrency']>,
    amount: FieldTypes.currency.thatIs.readOnly() as FieldType<Wire['amount']>,
    frequency: FrequencyField,
    createdDate: ReadonlyDateField as FieldType<Wire['createdDate']>,
    confirmationNumber: ReadOnlyStringField as FieldType<Wire['confirmationNumber']>,
    status: ReadOnlyStringField.with.template((status, record) => {
        const component = html`<channel-status-badge
            .fetchApproversFunction=${async () =>
                usersService.getApprovers({
                    approvalEntity: 'wire',
                    productId: record.getField('wireId'),
                    amount: record.getField('amount'),
                    createdBy: record.getField('createdBy'),
                    updatedBy: record.getField('updatedBy'),
                })}
            .status=${status}
            .approvalsNeeded=${record.getField('numberOfApprovalsNeeded')}
            .approvalsCount=${record.getField('completedApprovalCount')}
        ></channel-status-badge>`;
        component.toString = () => status;
        return component;
    }) as FieldType<Wire['status']>,
    source: ReadOnlyStringField as FieldType<Wire['source']>,
});
