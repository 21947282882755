import {
    string,
    datePicker,
    number,
    boolean,
    list,
    date,
    money,
} from '@treasury/policy/primitives';
import AchFileActivityClient from '../clients/ach-file-activity-client.js';

const client = new AchFileActivityClient();

export const filters = [
    {
        field: 'achFileName',
        fieldType: string.with.label('File Name').as.tag('omega-input'),
        value: '',
    },
    {
        field: 'achSourceList',
        fieldType: string.with
            .label('Source')
            .and.multipleValues()
            .with.hashFunction(user => user.id)
            .and.options({
                fetch: client.fetchFileSources,
                text: 'name',
                value: record => record,
            })
            .and.filtering()
            .as.tag('omega-select'),
    },
    {
        label: 'ACH Company Name',
        field: 'achCompanyList',
        fieldType: string.with
            .label('ACH Company Name')
            .and.multipleValues()
            .with.hashFunction(company => company.id)
            .and.options({
                fetch: client.fetchCompanies,
                text: 'companyName',
                value: record => record,
            })
            .and.filtering()
            .as.tag('omega-select'),
    },
    {
        field: 'uploadDate',
        fieldType: datePicker.with
            .label('Date')
            .and.range()
            .and.parseDynamicRange()
            .and.options({
                data: [
                    'any-date',
                    'today',
                    'specific',
                    'range',
                    'previous-week',
                    'previous-month',
                    'year-to-date',
                ],
            })
            .thatIs.required()
            .as.tag('omega-datepicker')
            .with.defaultValue(null), // custom options: key: uploadDateType, values: last one week (Last-one-Week), last one month (Last-one-Month), last one year (Last-one-Year)
        /**
         * uploadDate: ""
            uploadDateEnd: "05/05/2021"
            uploadDateStart: "04/26/2021"
            uploadDateType: "Range"
            uploadSpecificDate: "05/04/2021"
         */
    },
];

export const columns = [
    {
        type: 'checkbox',
        field: 'selected',
    },
    {
        field: 'fileName',
        label: 'File Name',
    },
    {
        field: 'fileSize',
        label: 'Size',
    },
    {
        field: 'source',
        label: 'Source',
    },
    {
        field: 'uploadDate',
        label: 'Processed Date',
    },
    {
        field: 'totalBatches',
        label: 'Total Batches',
    },
    {
        label: 'S T A T U S',
        columns: [
            {
                field: 'approvedCount',
                label: 'Approved',
            },
            {
                field: 'pendingApprovalCount',
                label: 'Pending Approval',
            },
            {
                field: 'rejectedCount',
                label: 'Rejected',
            },
            {
                field: 'expiredCount',
                label: 'Expired',
            },
        ],
    },
    {
        field: 'debitAmount',
        label: 'Total Debits',
        summary: true,
    },
    {
        field: 'creditAmount',
        label: 'Total Credits',
        summary: true,
    },
    {
        label: 'Actions',
        type: 'actions',
        actions: [
            {
                label: 'Review',
                action: 'review',
                visibleWhen: record =>
                    (record.getField('approvedCount') === 0 &&
                        record.getField('pendingApprovalCount') === 0 &&
                        record.getField('expiredCount') === 0 &&
                        record.getField('rejectedCount') === 0) === false,
            },
        ],
    },
];

export const fields = {
    fileName: string.thatIs.readOnly(), // 'Intl Triple R Valid IAT NACHA.txt',
    fileId: number.thatIs.readOnly(), // 5074,
    fileArchiveId: number.thatIs.readOnly(), // 30190,
    validationError: string.thatIs.readOnly(),
    isActive: boolean.thatIs.readOnly(), // false,
    achCompanyName: string.thatIs.readOnly(), // null,
    fileSize: string.thatIs.readOnly(), // '2.44 KB',
    source: string.thatIs.readOnly(), // 'mani',
    uploadDate: date.thatIs.readOnly(), // '2021-05-03T22:02:34.44',
    totalBatches: number.thatIs.readOnly(), // 1,
    approvedCount: number.thatIs.readOnly(), // 0,
    pendingApprovalCount: number.thatIs.readOnly(), // 0,
    rejectedCount: number.thatIs.readOnly(), // 0,
    expiredCount: number.thatIs.readOnly(), // 0,
    debitAmount: money.with.reducer('sum').thatIs.readOnly(), // 0.0,
    creditAmount: money.with.reducer('sum').thatIs.readOnly(), // 0.0,
    status: string, // null,
    permissions: list, // [],
    id: number, // 0,
    updatedBy: string, // 'mani',
    updatedDate: date, // '2021-05-03T22:02:34.827',
    selected: boolean.thatIs
        .disabledWhen(
            record =>
                record.getField('approvedCount') === 0 &&
                record.getField('pendingApprovalCount') === 0 &&
                record.getField('expiredCount') === 0 &&
                record.getField('rejectedCount') === 0
        )
        .as.tag('omega-checkbox'),
};
