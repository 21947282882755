import { css, html, LitElement } from 'lit';

class OmegaRadioGroup extends LitElement {
    static get properties() {
        return {
            id: {
                type: String,
            },
            label: {
                type: String,
            },

            name: {
                type: String,
            },
            radios: {
                type: Array,
            },
            value: {
                type: String,
            },
            orientation: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.orientation = 'column';
    }

    _changed(e) {
        this.value = e.target.value;
        this.dispatchEvent(
            new CustomEvent('change', {
                detail: this.value,
            })
        );
    }

    renderRadios() {
        return this.radios.map(
            (radio, idx) =>
                html`<div class="omega-radio-wrapper ${radio.disabled ? 'disabled-option' : ''}">
                    <input
                        type="radio"
                        class="omega-radio"
                        id=${radio.id || idx}
                        .name=${this.name}
                        .value=${radio.value}
                        .checked=${this.value === radio.value}
                        .disabled=${!!radio.disabled}
                        aria-checked=${this.value === radio.value}
                        @change=${this._changed}
                    />
                    <label for=${radio.id || idx}>${radio.label ?? radio.text}</label>
                </div>`
        );
    }

    render() {
        return html` <label for=${this.id}>${this.label}</label>
            <div
                class="omega-radio-group omega-radio-group-${this.orientation}"
                role="radiogroup"
                id=${this.id}
            >
                ${this.renderRadios()}
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            label {
                font-size: var(--omega-label);
                color: var(--omega-text-secondary);
            }

            .omega-radio-group {
                display: flex;
                min-width: 225px;
                margin-top: 8px;
            }

            .omega-radio-group-column {
                flex-direction: var(--omega-radio-group-flex-direction, column);
            }

            .omega-radio-group-row {
                flex-direction: row;
            }

            .omega-radio-group-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 16px 8px;
            }

            .omega-radio-group-row .omega-radio-wrapper {
                margin-left: 10px;
            }

            .omega-radio-wrapper {
                display: inline-block;
                padding-bottom: 3px;
                margin-right: 10px;
            }

            .omega-radio-wrapper * {
                cursor: pointer;
            }

            .omega-radio-wrapper.disabled-option label {
                color: #ccc;
                cursor: not-allowed;
            }

            .omega-radio-wrapper [type='radio']:checked,
            .omega-radio-wrapper [type='radio']:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            .omega-radio-wrapper [type='radio']:checked + label,
            .omega-radio-wrapper [type='radio']:not(:checked) + label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
            }

            .omega-radio-wrapper [type='radio']:checked + label:before,
            .omega-radio-wrapper [type='radio']:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 18px;
                border: 1px solid var(--omega-secondary-lighten-200);
                border-radius: 100%;
                background: var(--omega-white);
            }

            .omega-radio-wrapper [type='radio']:checked + label:after,
            .omega-radio-wrapper [type='radio']:not(:checked) + label:after {
                content: '';
                width: 12px;
                height: 12px;
                position: absolute;
                top: 4px;
                left: 4px;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            .omega-radio-wrapper [type='radio']:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }

            .omega-radio-wrapper [type='radio']:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
                content: '';
                width: 12px;
                height: 12px;
                background: var(--omega-primary);
                position: absolute;
                top: 4px;
                left: 4px;
                border-radius: 100%;
                transition: all 0.2s ease;
            }
        `;
    }
}
customElements.define('omega-radio-group', OmegaRadioGroup);
export default OmegaRadioGroup;
