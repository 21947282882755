import { Record as FdlRecord } from '@treasury/FDL';
import { Holiday } from '@treasury/domain/channel/types';
import type {
    Frequency,
    FrequencyConstructorInput,
    FrequencyTypeKeys,
} from '@treasury/domain/channel/types/frequency';
import { FrequencyDataConstructor, FrequencyType } from '@treasury/domain/channel/types/frequency';
import { LitElement, css, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { html as staticHtml, unsafeStatic } from 'lit/static-html.js';
import { fontAwesome } from '../../css/icons/font-awesome.js';
import { toKebabCase } from '../../util/helpers';
import OmegaSelect from '../omega-select.js';
import { formStyles } from './form-styles.js';
import './sub-components/every-six-months.js';
import './sub-components/every-two-weeks.js';
import './sub-components/monthly.js';
import './sub-components/oneTime.js';
import './sub-components/quarterly.js';
import './sub-components/twice-a-month.js';
import './sub-components/weekly.js';
import './sub-components/yearly.js';
import { DefaultFrequenciesForSelect, FrequencyForSelect, FrequencyTypeLabels } from './types';

class OmegaFrequency extends LitElement {
    @property({ type: Object })
    frequency?: FrequencyConstructorInput;

    @property({ type: String })
    type?: FrequencyType | null | undefined;

    @property({ attribute: 'readonly', type: Boolean })
    readOnly?: boolean;

    @property({ attribute: 'readonlyexception', type: Boolean })
    readOnlyException?: boolean;

    @property({ type: Boolean })
    required?: boolean;

    @property({ type: Boolean })
    disabled?: boolean;

    @property({ type: Function })
    isDisabledStartDate?: () => boolean;

    @property({ type: Function })
    isDisabledEndDate?: () => boolean;

    @property({ type: Function })
    isStartDateInvalid?: () => boolean;

    @property({ type: Function })
    isEndDateInvalid?: () => boolean;

    @property({ type: Object })
    record?: FdlRecord;

    @property({ type: String })
    annotation?: string;

    @property({ reflect: true, type: Boolean })
    valid?: boolean;

    @property({ type: Array })
    holidays?: Array<Holiday>;

    @property({ type: Array })
    frequencyTypes?: Array<FrequencyForSelect>;

    public get _typeSelectValue(): FrequencyTypeKeys {
        return this._type?.replace(/ a /g, 'A').replace(/ /g, '') as FrequencyTypeKeys;
    }

    private get _type() {
        return this.frequency?.type;
    }

    private set _type(type: FrequencyType | undefined | null) {
        this.type = type;
        if (this.frequency?.type !== type) {
            this._frequency = { type };
        }
    }

    private get _frequency(): Frequency {
        return FrequencyDataConstructor(this.frequency ?? {});
    }

    private set _frequency(f: FrequencyConstructorInput) {
        this.frequency = FrequencyDataConstructor(f ?? {});
        if (this.frequency?.type !== this.type) {
            this._type = this.frequency.type;
        }
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    firstUpdated() {
        if (this.type || this.frequency?.type) {
            this._type = this.type ?? this._frequency.type;
        }
        if (this.frequency) this.frequency = FrequencyDataConstructor(this.frequency);
        if (!this.frequencyTypes || this.frequencyTypes.length === 0) {
            this.frequencyTypes = DefaultFrequenciesForSelect;
        }
    }

    getFrequencyType() {
        return this._type;
    }

    changeSelectedFrequency(e: Event) {
        const targetElement = e.target as OmegaSelect;
        const key = targetElement.value as FrequencyTypeKeys;
        if (!key) return;
        const newFrequencyType = FrequencyType[key];
        if (this.type === newFrequencyType) return;
        this._type = newFrequencyType;
    }

    setFrequency(event: CustomEvent<FrequencyConstructorInput>) {
        if (event.detail === null) return;
        const frequencyData = event?.detail ?? {};
        const newFrequencyData: FrequencyConstructorInput = {
            ...this._frequency,
            ...frequencyData,
        };
        const newFrequency: Frequency = FrequencyDataConstructor(newFrequencyData);
        this._frequency = newFrequency;
    }

    renderTypes() {
        let frequencySubType = 'one-time';
        if (this._type) {
            frequencySubType = toKebabCase(this._type).toLowerCase();
        }

        // https://lit.dev/docs/templates/expressions/#non-literal-statics
        const tag = `omega-frequency-${frequencySubType}`;
        return staticHtml`<${unsafeStatic(tag)}
                .frequency=${this._frequency}
                .isStartDateInvalid=${this.isStartDateInvalid}
                .isEndDateInvalid=${this.isEndDateInvalid}
                @change=${this.setFrequency}
                ?readonly=${this.readOnly && !this.readOnlyException}
                .disabled=${this.disabled}
            ></${unsafeStatic(tag)}>`;
    }

    renderFrequencyLabel() {
        if (this.required && !this.readOnly) return html`<span class="required"> *</span>`;
        return nothing;
    }

    renderControl() {
        return this.readOnly || this.frequencyTypes?.length === 1
            ? html`<div class="control">
                  ${FrequencyTypeLabels[this._type ?? FrequencyType.OneTime]}
              </div>`
            : html`<omega-select
                  class="control"
                  .items=${this.frequencyTypes}
                  .value=${this._typeSelectValue}
                  placeholder="Select a Frequency"
                  @change=${this.changeSelectedFrequency}
                  .disabled=${this.disabled}
              ></omega-select>`;
    }

    renderAnnotation() {
        if (!this.annotation) return nothing;
        return this.annotation;
    }

    render() {
        return html`
            <div class="row first">
                <label class="label">Frequency ${this.renderFrequencyLabel()} </label>
                ${this.renderControl()}
            </div>
            ${this.renderAnnotation()} ${this.renderTypes()}
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            formStyles,
            css`
                :host {
                    display: block;
                }
                .annotation {
                    display: flex;
                    margin-top: 10px;
                }
                .annotation i {
                    color: #2481b4;
                    margin-top: 1px;
                }
                .annotation span {
                    font-weight: bold;
                    margin-left: 5px;
                }
            `,
        ];
    }
}

window.customElements.define('omega-frequency', OmegaFrequency);
export default OmegaFrequency;
