PaymentPendingApprovalProductsService.$inject = ['$resource', '$http', '$q'];

export default function PaymentPendingApprovalProductsService($resource, $http, $q) {
    //  var resourceUrl = serviceBase + "notifications/";

    return {
        getAll,
    };

    function getAll() {
        const deferred = $q.defer();
        deferred.resolve([
            {
                productType: 'transfer',
                productName: 'Transfer',
                count: 5,
            },
            {
                productType: 'ach',
                productName: 'ACH',
                count: 8,
            },
            {
                productType: 'wire',
                productName: 'Wires',
                count: 10,
            },
        ]);
        return deferred.promise;
    }
}
