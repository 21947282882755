RateQuoteExpiredModalController.$inject = ['$scope', '$modalInstance', '$state'];

export default function RateQuoteExpiredModalController($scope, $modalInstance, $state) {
    $scope.cancel = cancel;
    $scope.getNewQuote = getNewQuote;

    function cancel() {
        $modalInstance.dismiss();
        $state.go('payables.wire.wire-list');
    }

    function getNewQuote() {
        $modalInstance.close();
    }
}
