import { getChannelAPIPath } from '@treasury/core/http';

NavigationService.$inject = ['$resource', '$http'];

export default function NavigationService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}navigations/`;

    return {
        getNavigation,
        userActivityAudit,
    };

    function getNavigation() {
        return $http.get(resourceUrl).then(response => response.data);
    }

    function userActivityAudit(navigation) {
        const request = $resource(`${resourceUrl}auditUserActivity/${navigation}`);
        return request.save().$promise;
    }
}
