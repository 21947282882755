import { WireCompanyModelDto } from '@treasury/api/channel';
import { OmegaListItem } from '@treasury/omega/view-models';

export type WireCompanyListItem = OmegaListItem<WireCompanyModelDto>;
export function createWireCompanyListItem(dto: WireCompanyModelDto): WireCompanyListItem {
    return {
        value: dto,
        text: dto.name,
    };
}
