import { Observable } from './observable';
import { ObservationSource } from './observation-source';

/**
 * Merge two `Observable` streams together to create a combined stream of values.
 */
export function combine<Type1, Type2>(o1: Observable<Type1>, o2: Observable<Type2>) {
    const source = new ObservationSource<Type1 | Type2>();
    const observables = [o1, o2];
    const subs = observables.map(o =>
        o.observe({
            onNext: v => source.emit(v),
            onError: e => source.setError(e),
        })
    );

    source.listenForCompletion(() => {
        subs.forEach(s => s.unsubscribe());
    });

    return source.toObservable();
}

export function combineMulti<T>(...observables: Observable<T>[]) {
    if (observables.length < 1) {
        throw new Error('Could not create a combined observable. The list was empty.');
    }

    if (observables.length === 1) {
        return observables[0];
    }

    const firstObservable = observables.shift();
    if (!firstObservable) {
        throw new Error('Encountered an observable list with empty elements.');
    }

    return observables.reduce((prev, current) => combine(prev, current), firstObservable);
}

/**
 * Create a new observable from a source that limits
 * the number of emissions to once per `duration`.
 *
 * @param duration Minimum amount of time (in milliseconds) that must pass
 * since the last emission for an observable to emit again.
 */
export function throttle<T>(o: Observable<T>, duration: number) {
    let lastEmit: Date;

    const filtered = o.filter(() => {
        if (!lastEmit) {
            return true;
        }

        return Date.now() - lastEmit.getTime() >= duration;
    });

    filtered.subscribe(
        () => {
            lastEmit = new Date();
        },
        true,
        true
    );

    return filtered;
}

export function fromArray<T>(values: T[], completeObservable = true) {
    const source = new ObservationSource<T>(({ emit, complete }) => {
        values.forEach(v => emit(v));
        if (completeObservable) {
            complete();
        }
    }, true);

    return source.toObservable();
}

export function fromPromise<T>(p: Promise<T> | (() => Promise<T>), completeObservable = true) {
    let source = new ObservationSource<T>(({ emit, complete }) => {
        // do this all in one line so Jest rejected promises tests don't freak out
        (p instanceof Promise ? p : p())
            .then(val => {
                emit(val);
                if (completeObservable) {
                    complete();
                }
            })
            .catch(err => {
                source.setError(err);
                if (completeObservable) {
                    complete();
                }
            })
            .finally(() => {
                // eslint-disable-next-line no-multi-assign
                (source as any) = (p as any) = null;
            });
    }, true);

    return source.toObservable();
}

export function fromEvent<EventType extends Event = Event>(e: EventTarget, eventName: string) {
    const source = new ObservationSource<EventType>();

    e.addEventListener(eventName, e => source.emit(e as EventType));

    return source.toObservable();
}

export function range(start: number, end: number) {
    if (start >= end) {
        throw new Error(
            `Invalid range. Start value ${start} is greater than or equal to end value ${end}.`
        );
    }

    return new ObservationSource<number>(({ emit, complete }) => {
        while (start < end) {
            emit(start);
            // eslint-disable-next-line no-param-reassign
            start++;
        }

        complete();
    }, true).toObservable();
}

/**
 * Create an `Observable` that emits at regular intervals.
 *
 * @param delay Time in milliseconds between Observable emission.
 */
export function interval(delay: number) {
    let accumulator = 0;
    return new ObservationSource<number>(({ emit }) => {
        window.setInterval(() => {
            emit(accumulator++);
        }, delay);
    }, true).toObservable();
}

/**
 * Create an `Observable` that emits once after a delay.
 *
 * @param delay Time in milliseconds until `Observable` emission.
 */
export function timer(delay: number, completeObservable = true) {
    return new ObservationSource<void>(({ emit, complete }) => {
        window.setTimeout(() => {
            emit();

            if (completeObservable) {
                complete();
            }
        }, delay);
    }, true).toObservable();
}

/**
 * Create a stream that emits every second until it reaches zero
 * starting with the specified number of seconds.
 *
 * @param start Amount of time in seconds to begin counting down.
 */
export function countdown(start: number) {
    const { setInterval, clearInterval } = window;

    return new ObservationSource<number>(({ emit, complete }) => {
        emit(start);
        const intervalId = setInterval(() => {
            emit(start--);

            if (start <= 0) {
                complete();
                clearInterval(intervalId);
            }
        }, 1000);
    }).toObservable();
}
