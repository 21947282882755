import { html, nothing } from 'lit';
import Column from './column.js';

export default class TooltipColumn extends Column {
    constructor(...args) {
        super(...args);
        const [{ label, icon, iconLabel, field, message, direction }, recordset] = args;
        this.label = label;
        this.field = field;
        this.message = message;
        this.icon = icon ?? 'info-circle';
        this.iconLabel = iconLabel ?? icon;
        this.minWidth = 50;
        this.targetWidth = 75;
        this.maxWidth = 100;
        this.direction = direction;
        this.recordset = recordset;
    }

    setDirection(rowIndex) {
        if (this.direction) return this.direction;
        if (rowIndex <= 4) return 'bottom';
        if (rowIndex >= this.recordset.pageSize - 5 && rowIndex <= this.recordset.pageSize)
            return 'top';
        return 'left';
    }

    cellClasses() {
        return [...super.cellClasses(), 'icon-column', 'omega-no-print'];
    }

    renderThContents() {
        return this.label;
    }

    renderTdContents(record, rowIndex) {
        this.message = record.print(this.field) ?? this.message;
        if (!this.message) return nothing;
        return html`
            <omega-tooltip
                .icon=${this.icon}
                light
                .message=${this.message}
                .direction=${this.setDirection(rowIndex)}
            ></omega-tooltip>
        `;
    }

    renderContentLoading() {
        return html`
            <omega-button type="icon" disabled .icon=${this.icon} hideLabel="always">
                ${this.iconLabel}
            </omega-button>
        `;
    }
}
