ArpExceptionReviewModalController.$inject = ['$scope', '$modalInstance', 'arpExceptions'];

export default function ArpExceptionReviewModalController($scope, $modalInstance, arpExceptions) {
    // variables
    $scope.form = null;
    $scope.arpExceptions = arpExceptions;

    // functions
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.setForm = setForm;

    // public functions
    function save() {
        $modalInstance.close($scope.arpExceptions);
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }

    // private functions
}
