import { html, css, LitElement } from 'lit';
import '@treasury/omega/components/progress/omega-progress';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-alert';
import '@treasury/omega/components/omega-icon';
import { string } from '@treasury/policy/primitives';
import { Record } from '@treasury/FDL/record';
import { mix } from 'mixwith';
import { ListeningElementMixin } from '@treasury/omega/components';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';

export class ForgotPassword extends mix(LitElement).with(ListeningElementMixin, channelAlertMixin) {
    static get properties() {
        return {
            alert: Object,
            forgotPasswordResponse: Boolean,
            institution: String,
            loading: Boolean,
            record: Object,
            formActionsDisabled: Boolean,
        };
    }

    constructor() {
        super();
        this.fields = {
            companyId: string.with.label('Company ID').thatIs.required(),
            email: string.with
                .label('Email')
                .thatIs.required()
                .thatHas.validator({
                    name: 'email-format',
                    validate: (value /* view, record, options */) => {
                        if (!value) return true;
                        const re =
                            /^[a-zA-Z0-9.!#$%&amp;’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
                        return re.test(value);
                    },
                }),
            institution: string,
            loginId: string.with.label('Login ID').thatIs.required(),
            password: string,
        };
        this.formActionsDisabled = true;
    }

    connectedCallback() {
        super.connectedCallback();
        this.record = new Record(this.fields, {
            companyId: null,
            email: null,
            loginId: null,
            institution: this.institution,
            password: null,
        });
        this.listenTo(this.record, 'change', () => {
            this.isFormValid();
        });
    }

    reset() {
        this.record.reset();
    }

    back() {
        this.dispatchEvent(new CustomEvent('back'));
    }

    isFormValid() {
        this.formActionsDisabled =
            !this.record.isValid() || !this.record.hasRequiredValues() || this.loading;
    }

    forgotPassword() {
        this.dispatchEvent(
            new CustomEvent('submit', {
                detail: { formValues: this.record.values },
            })
        );
    }

    renderSuccessMessage() {
        return html`
            <div class="success-container">
                <div class="success-heading">
                    <h1>Email Sent!</h1>
                </div>
                <div class="text-center">
                    <omega-icon icon="envelope"></omega-icon>
                    <div class="success-text">Success!</div>
                </div>
                <div>
                    <p>
                        Please check your email for further information about your password change.
                    </p>
                    <p>The instructions provided in the email will be valid for two hours.</p>
                </div>
            </div>
            <footer>
                <a href=${`/${this.institution}/login`}>
                    <omega-button> Return To Login </omega-button>
                </a>
            </footer>
        `;
    }

    renderForgotPasswordForm() {
        return html`
            <div class="forgot-password-container">
                <p>
                    If you have forgotten your password, you can reset it by verifying the
                    information below. You must be an active user and the credentials entered below
                    must match your user profile exactly. If the information is entered correctly,
                    an email will be sent to the address on record.
                </p>
                <strong>
                    You will have two hours from the time the email is sent to take the next steps.
                </strong>
                <div class="form-container">
                    <form>
                        <omega-field
                            .record=${this.record}
                            id="company-id"
                            field="companyId"
                            label="Company ID"
                        ></omega-field>

                        <omega-field
                            .record=${this.record}
                            id="login-id"
                            field="loginId"
                            label="Login ID"
                        ></omega-field>

                        <omega-field
                            .record=${this.record}
                            id="email"
                            field="email"
                            label="Email"
                        ></omega-field>
                    </form>
                </div>
            </div>
            <footer>
                <omega-button
                    id="log-in"
                    type="primary"
                    ?disabled=${this.formActionsDisabled}
                    ?loading=${this.loading}
                    @click=${this.forgotPassword}
                >
                    Submit
                </omega-button>
                <omega-button id="reset" ?disabled=${this.loading} @click=${this.reset}>
                    Reset
                </omega-button>
                <omega-button ?disabled=${this.loading} @click=${this.back}>Back</omega-button>
            </footer>
        `;
    }

    render() {
        if (this.forgotPasswordResponse) return this.renderSuccessMessage();
        return [this.renderAlert(), this.renderForgotPasswordForm()];
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            footer {
                padding: 4px 15px;
                border-top: 1px solid #ccc;
            }
            .forgot-password-container {
                margin: 15px;
            }
            .form-container {
                margin-top: 30px;
            }
            omega-field {
                --omega-field-label-width: 100px;
                --omega-field-control-width: 250px;
                max-width: 360px;
                margin-bottom: 15px;
            }
            omega-icon {
                margin-top: 16px;
                --omega-icon-font-size: 4em;
                --omega-icon-color: #2caa55;
            }
            .text-center {
                text-align: center;
            }
            .success-container {
                background-color: #fff;
                padding: 15px;
            }
            .success-text {
                font-size: 24px;
                color: #2caa55;
                font-weight: 600;
            }
            .success-heading {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 52px;
                background-color: #2caa55;
                display: flex;
                align-items: center;
                padding-left: 15px;
            }
            h1 {
                font-size: 20px;
                margin: 0;
            }
        `;
    }
}

export default ForgotPassword;
window.customElements.define('forgot-password', ForgotPassword);
