export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            require: 'ngModel',
            scope: {
                ngModel: '=',
                placeholder: '@',
                ngRequired: '=',
                ngInvalid: '=?',
                classes: '=?',
                ngDisabled: '=?',
                banks: '=',
                displayColumn: '@',
                returnColumn: '@',
                onSelectBank: '&',
                inputName: '@?',
            },
            link(scope, element, attr, ngModel) {
                ngModel.$formatters.push(model => {
                    if (!!model && !!scope.displayColumn && !!model[scope.displayColumn]) {
                        return model[scope.displayColumn];
                    }
                    return model;
                });
            },
            template: require('./typeAheadAchBanksTemplate.html'),
            controller: [
                '$scope',
                'achBankService',
                '$modal',
                function ($scope, achBankService, $modal) {
                    $scope.banksLoaded = banksLoaded;
                    $scope.label = label;
                    $scope.showBankDialog = showBankDialog;
                    $scope.onSelect = onSelect;

                    function label(item) {
                        if (!!item.city && !!item.state) {
                            return `${item.bankId} ${item.name} ${item.city},${item.state}`;
                        }
                        return `${item.bankId} ${item.name} ${item.city},${item.state}`;
                    }

                    function showBankDialog() {
                        if (!$scope.banksLoaded()) {
                            return;
                        }

                        const modalInstance = $modal.open({
                            template: require('../../../payables/ach/batch/views/bankDialogView.html'),
                            size: 'md',
                            controller: 'AchBankDialogController',
                            backdrop: 'static',
                        });

                        modalInstance.result.then(response => {
                            if ($scope.returnColumn) {
                                $scope.ngModel = response.bank[$scope.returnColumn];
                            } else {
                                $scope.ngModel = response.bank;
                            }
                            if ($scope.onSelectBank) {
                                $scope.onSelectBank({ bank: response.bank });
                            }
                        });
                    }

                    function onSelect($item, $model, $label) {
                        if ($scope.onSelectBank) {
                            $scope.onSelectBank({ bank: $item });
                        }
                    }

                    function banksLoaded() {
                        return Array.isArray($scope.banks);
                    }

                    function init() {
                        if ($scope.inputName == null) {
                            $scope.inputName = 'routingNumber';
                        }
                    }

                    init();
                },
            ],
        };
    },
];
