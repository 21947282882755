import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';
import { createUniqueId } from '@treasury/utils';

/**
 * @typedef { import('@treasury/api/channel').WireModelDto } WireModelDto
 * @typedef { import('@treasury/api/channel').WireTemplateModelDto } WireTemplateModelDto
 * @typedef { import('@treasury/api/channel').WireListModelDto } WireListModelDto
 * @typedef { import('@jack-henry/frontend-utils/di').DiContainer } DiContainer
 */

/**
 * @param { ng.resource.IResourceService} $resource
 * @param { ng.IHttpService } $http 
 * @param { ng.IFilterService } $filter
 * @param { DiContainer } TmDi 
 */
export function WireService($resource, $http, $filter, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}wires/`;
    let selectedTemplates = [];
    let createWireType = null;

    return {
        getAll,
        search,
        searchTemplates,
        create,
        initiateBulk,
        batchApproveOrReject,
        approveOrReject,
        remove,
        removeTemplate,
        summarizeFrequency,
        frequencyTypeToFriendly,
        getWire,
        update,
        getRateQuote,
        acceptRateQuote,
        getAllRecurring,
        cancel,
        getWireTemplate,
        validateTemplateName,
        updateWireTemplate,
        setSelectedTemplates,
        getSelectedTemplates,
        approveOrRejectWireTemplate,
        createWireTemplate,
        setCreateWireType,
        getCreateWireType,
        getInternationalWireCurrencies,
    };

    function getAll() {
        return $resource(resourceUrl).query().$promise;
    }

    function getAllRecurring(type) {
        return $resource(resourceUrl, { type: 'Recurring' }).query().$promise;
    }
    function search(message, type) {
        return $resource(
            `${resourceUrl}Search`,
            { type },
            { search: { method: 'POST', isArray: true } }
        )
            .search(message)
            .$promise.then(response => {
                response = response.map(wire => {
                    wire.frequency.valueDate = moment(
                        wire.frequency.valueDate,
                        'YYYY-MM-DD'
                    ).format();
                    return wire;
                });
                return response;
            });
    }

    function searchTemplates(filter) {
        return $resource(`${resourceUrl}wireTemplateSearch`, null, {
            search: { method: 'POST', isArray: true },
        })
            .search(filter)
            .$promise.then(response => response);
    }

    /**
     * @param { WireModelDto } wire
     */
    function create(wire) {
        populateDupPreventionId(wire);

        /**
         * @type { Promise<WireModelDto> }
         */
        const p = $resource(resourceUrl).save(wire).$promise;
        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
            trackPayment(wire, analytics)
        });

        return p;
    }

    /**
     *
     * @param { WireListModelDto } wireList
     */
    function initiateBulk(wireList) {
        const wires = wireList.wires || [];
        wires.forEach(populateDupPreventionId);

        const resource = $resource(`${resourceUrl}initiateBulk`);
        /**
         * @type { Promise<WireListModelDto> }
         */
        const p = resource.save(wireList).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
            wires.forEach(w => {
                const { amount, isDliWire: isDli, currency, frequency } = w;
                analytics.track(AnalyticsEvent.WireCreated, {
                    amount,
                    isDli,
                    currency,
                    frequency
                });
            });
        });

        return p;
    }

    function batchApproveOrReject(batch, type) {
        return $resource(`${resourceUrl}:id/batch?action=:action`, { id: 0, action: type }).save(
            batch
        ).$promise;
    }

    function remove(cancelWireModel) {
        return $http({
            method: 'DELETE',
            url: resourceUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            data: cancelWireModel,
        }).then(response => response.data);
    }

    function removeTemplate(template, comments) {
        return $resource(`${resourceUrl}template/:id`, {
            id: template.id,
            comments,
        }).delete().$promise;
    }

    function cancel(cancelWireModel) {
        const resource = $resource(`${resourceUrl}cancel`, {}, { update: { method: 'PUT' } });
        return resource.update(cancelWireModel).$promise;
    }

    function frequencyTypeToFriendly(frequencyType) {
        if (frequencyType === null) {
            return 'Undefined';
        }

        let result = frequencyType;

        switch (frequencyType) {
            case 'EveryTwoWeeks':
                result = 'Every Two Weeks';
                break;
            case 'TwiceAMonth':
                result = 'Twice a Month';
                break;
            case 'EverySixMonths':
                result = 'Every Six Months';
                break;
            case 'OneTime':
                result = 'One Time';
                break;
        }

        return result;
    }

    function summarizeFrequency(frequency) {
        if (frequency.type === 'One Time' || frequency.type === 'OneTime') {
            return 'One Time.';
        }

        let summary = 'Occurs ';
        switch (frequencyTypeToFriendly(frequency.type)) {
            case 'Weekly':
                summary += ` every ${getDayOfWeek(frequency)} ${getStartAndEnd(frequency)}`;
                break;
            case 'Every Two Weeks':
                summary += ` on ${getDayOfWeek(frequency)} every two weeks ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Twice a Month':
                summary += ` on ${getDaysOfMonth(frequency, true)} every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Monthly':
                summary += ` on ${getDaysOfMonth(frequency)} of every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Quarterly':
                summary += ` on the ${getDayForADate(
                    frequency.startOn
                )} every three months ${getStartAndEnd(frequency)}`;
                break;
            case 'Every Six Months':
                summary += ` on the ${getDayForADate(
                    frequency.startOn
                )} every six months ${getStartAndEnd(frequency)}`;
                break;
            case 'Yearly':
                summary += ` on ${getDayForAnnual(frequency.startOn)} every year ${getStartAndEnd(
                    frequency
                )}`;
                break;
        }
        summary += '.';
        return summary;
    }

    //
    // Private

    function getDayOfWeek(frequency) {
        return frequency.repeatOn;
    }

    function getRateQuote(wire) {
        const resource = $resource(
            `${resourceUrl}getNewWireQuote`,
            {},
            { payload: { method: 'POST', isArray: false } }
        );
        return resource.payload({
            amount: wire.amount,
            destinationCurrencyCode: wire.destinationCurrencyCode,
            isAmountUSD: wire.isAmountUSD,
        }).$promise;
    }

    function acceptRateQuote(wire) {
        const resource = $resource(
            `${resourceUrl}acceptNewWireQuote`,
            {},
            { payload: { method: 'POST', isArray: false } }
        );
        return resource.payload(wire).$promise;
    }

    function getDayForAnnual(date) {
        const tempdate = new Date(date);
        return $filter('date')(tempdate, 'MM/dd');
    }

    function getDayForADate(date) {
        return formatDay($filter('date')(new Date(date), 'd'));
    }

    function getDaysOfMonth(frequency, twoDays) {
        const day1 = frequency.repeatOnDay1;
        const day2 = frequency.repeatOnDay2;

        if (!twoDays) {
            if (frequency.repeatOnLastBusinessDay) {
                return 'the last business day';
            }
            return `the ${formatDay(day1)}`;
        }
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }

    function formatDay(day) {
        if (day === 1 || day === 21 || day === 31) {
            return `${day}st`;
        }
        if (day === 2 || day === 22) {
            return `${day}nd`;
        }
        if (day === 3 || day === 23) {
            return `${day}rd`;
        }
        return `${day}th`;
    }

    function getStartAndEnd(frequency) {
        if (frequency.endOn) {
            return `from ${formatDate(frequency.startOn)} to ${formatDate(frequency.endOn)}`;
        }
        return `starting on ${formatDate(frequency.startOn)}`;
    }

    function getWire(id) {
        return $resource(`${resourceUrl}:id`, { id }).get().$promise;
    }

    /**
     * 
     * @param { 'Approve' | 'Reject'} type 
     * @param { number } id 
     * @returns { Promise<import('@treasury/api/channel').WireModelDto>}
     */
    function approveOrReject(type, id, comments) {
        if (type === 'Approve') {
            const resource = $resource(`${resourceUrl}:id/approve`, { id });
            const p = resource.save(comments).$promise;

            Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
                analytics.track(AnalyticsEvent.WireApproved, {
                    id: id.toString()
                });
            });

            return p;
        }

        if (type === 'Reject') {
            return $resource(`${resourceUrl}:id/reject`, { id }).save(comments).$promise;
        }

        throw new Error(`Invalid wire action: ${type}`);
    }

    function formatDate(date) {
        return $filter('date')(date, 'MM/dd/yyyy');
    }

    function update(model) {
        const resource = $resource(
            `${resourceUrl}:id`,
            { id: model.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(model).$promise;
    }

    function setSelectedTemplates(templates) {
        selectedTemplates = [];
        if (Array.isArray(templates)) {
            selectedTemplates = templates;
        } else {
            selectedTemplates.push(templates);
        }
    }

    function setCreateWireType(type) {
        createWireType = type;
    }

    function getCreateWireType() {
        const result = createWireType;
        createWireType = null;
        return result;
    }

    function getWireTemplate(id) {
        return $resource(`${resourceUrl}getTemplate/:id`, { id }).get().$promise;
    }

    function validateTemplateName(name) {
        return $resource(`${resourceUrl}validateTemplateName/`, { templateName: name }).get()
            .$promise;
    }

    function getSelectedTemplates() {
        return selectedTemplates;
    }

    function updateWireTemplate(model) {
        const resource = $resource(
            `${resourceUrl}updateTemplate/:id`,
            { id: model.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(model).$promise;
    }

    /**
     * 
     * @param { 'Approve' | 'Reject'} type 
     * @param { number } id 
     * @returns { Promise<WireTemplateModelDto>}
     */
    function approveOrRejectWireTemplate(type, id, comments) {
        if (type === 'Approve') {
            const resource = $resource(`${resourceUrl}approveTemplate/:id`, { id });
            const p = resource.save(comments).$promise;

            Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
                analytics.track(AnalyticsEvent.WireTemplateApproved, {
                    id: id.toString()
                })
            });

            return p;
        }

        if (type === 'Reject') {
            return $resource(`${resourceUrl}rejectTemplate/:id`, { id }).save(comments).$promise;
        }

        throw new Error(`Invalid wire template action: ${type}`)
    }

    /**
     * @param { WireTemplateModelDto } model
     */
    function createWireTemplate(model) {
        const resource = $resource(`${resourceUrl}createTemplate/`);
        /**
         * @type { Promise<WireTemplateModelDto>}
         */
        const p = resource.save(model).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([_, analytics]) => {
            const { numberOfApprovalsNeeded, isInternational, wireCompany} = model;

            analytics.track(AnalyticsEvent.WireTemplateCreated, {
                numberOfApprovalsNeeded,
                isInternational,
                wireCompanyId: wireCompany.id.toString()
            })
        });

        return p;
    }

    function getInternationalWireCurrencies() {
        return $resource(`${resourceUrl}getInternationalWireCurrencies/`).query().$promise;
    }
}

WireService.$inject = ['$resource', '$http', '$filter', 'TmDi'];

/**
 * @param { WireModelDto } wire
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function populateDupPreventionId(wire) {
    if (!wire.duplicatePreventionId) {
        // eslint-disable-next-line no-param-reassign
        wire.duplicatePreventionId = createUniqueId();
    }

    return wire;
}

/**
 * @param { WireModelDto } wire
 * @param { AnalyticsService } analytics
 */
function trackPayment(wire, analytics) {
    const { amount, isInternational, isDliWire: isDli, destinationCurrency: currency } = wire;
    const { type: frequency } = wire.frequency;
    const event = isInternational ? AnalyticsEvent.InternationalWireCreated : AnalyticsEvent.WireCreated;

    analytics.track(event, {
        amount,
        isDli,
        currency,
        frequency,
    });
}
