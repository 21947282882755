// CSpell:ignore deformatter -- Should be dateFormatter?

import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import dateFormatter from '../formatters/date.formatter.js';
import compareDates from '../helpers/compare-dates.js';
import DateModel from './datepicker/DateModel.js';
import './omega-button.js';

const daysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
const daysShort = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
];

const YEAR_COUNT = 10;

class OmegaCalendar extends LitElement {
    static get properties() {
        return {
            monthFirstDay: Object,
            selectedDate: Object,
            secondaryDate: Object,
            dateDisabledFunction: Function,
            secondaryDateDisabledFunction: Function,
            range: { type: Boolean, reflect: true },
            selectedDateInvalid: Boolean,
            secondaryDateInvalid: Boolean,
            hoveredDate: Object,
            startDayOfWeek: Number,
            dateFormatter: Function,
            monthSelectorOpen: Boolean,
            yearSelectorOpen: Boolean,
        };
    }

    constructor() {
        super();
        this.monthSelectorOpen = false;
        this.yearSelectorOpen = false;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-calendar--calendar',
        };
    }

    firstUpdated() {
        this.setMonth();
    }

    setMonth() {
        const start =
            this.selectedDate && this.isValidDate(this.selectedDate)
                ? new Date(this.selectedDate)
                : new Date();
        let day = start;
        let isDayDisabled = this.disableDay(day);
        this.monthFirstDay = new DateModel();

        if (isDayDisabled) {
            while (isDayDisabled) {
                isDayDisabled = this.disableDay(day);
                if (!isDayDisabled) {
                    this.monthFirstDay.setDate(new Date(day.getFullYear(), day.getMonth(), 1));
                    return;
                }
                day = new Date(day.setDate(day.getDate() + 1));
            }
        } else {
            this.monthFirstDay.setDate(new Date(day.getFullYear(), day.getMonth(), 1));
        }
    }

    isValidDate(dateString) {
        return (
            dateString &&
            new Date(dateString).getTime() &&
            !Number.isNaN(new Date(dateString).getTime())
        );
    }

    onDateClicked(day) {
        const isSelectedDate = !this.range || !this.selectedDate || this.secondaryDate;
        const dates = isSelectedDate ? [day, null] : [day, this.selectedDate].sort(compareDates);

        [this.selectedDate, this.secondaryDate] = dates;
        this.selectedDateInvalid = false;
        this.secondaryDateInvalid = false;
        this.announceChange();
    }

    onMouseoverDate(day) {
        this.hoveredDate = this.range && this.selectedDate && !this.secondaryDate ? day : null;
    }

    datesMatch(day, day2) {
        return this.isValidDate(day) && this.isValidDate(day2) && compareDates(day, day2) === 0;
    }

    isInRange(day, day1, day2) {
        if (!(this.isValidDate(day1) && this.isValidDate(day2))) return false;

        const dates = [day1, day2].sort(compareDates);

        return compareDates(day, dates[0]) === 1 && compareDates(dates[1], day) === 1;
    }

    formattedDate(day) {
        return (this.dateFormatter || dateFormatter)(day);
    }

    outOfMonth(day) {
        return day.getMonth() !== this.monthFirstDay.getMonth();
    }

    disableDay(day) {
        if (this.selectedDate && this.secondaryDateDisabledFunction && !this.secondaryDate) {
            return this.secondaryDisabled(day);
        }
        return this.selectedDisabled(day);
    }

    selectedDisabled(day) {
        const dateModel = new DateModel(day);
        return this.dateDisabledFunction && this.dateDisabledFunction(dateModel);
    }

    secondaryDisabled(day) {
        const dateModel = new DateModel(day);
        const selectedDateModel = new DateModel(this.selectedDate);
        return (
            (this.secondaryDateDisabledFunction &&
                this.secondaryDateDisabledFunction(dateModel, selectedDateModel)) ||
            compareDates(this.selectedDate, day) === 1
        );
    }

    submitDisabled() {
        const selectedDateInvalid = !this.selectedDate || this.selectedDateInvalid;
        const invalidRange = this.range && (!this.secondaryDate || this.secondaryDateInvalid);
        return selectedDateInvalid || invalidRange;
    }

    dateFromFormattedString(dayString) {
        let date;
        if (this.deformatter) {
            date = this.deformatter(dayString);
        } else {
            date = new Date(dayString);
        }

        return Number.isNaN(date.getTime()) ? null : date;
    }

    onSelectedBlur(inputValue) {
        const inputDate = this.dateFromFormattedString(inputValue);

        if (!inputDate) return;

        if (this.selectedDisabled(inputDate)) {
            this.selectedDateInvalid = true;
            return;
        }

        this.selectedDateInvalid = false;
        this.selectedDate = inputDate;
        // Date might be in a different month, so the calendar should be updated
        this.setMonth();
        this.announceChange();
    }

    onSecondaryBlur(inputValue) {
        const inputDate = this.dateFromFormattedString(inputValue);

        if (!inputDate) return;

        if (this.secondaryDisabled(inputDate)) {
            this.secondaryDateInvalid = true;
            return;
        }

        this.secondaryDateInvalid = false;
        this.secondaryDate = inputDate;
        this.announceChange();
    }

    announceChange() {
        this.dispatchEvent(
            new CustomEvent('dateChange', {
                composed: true,
                bubbles: true,
                detail: { selectedDate: this.selectedDate, secondaryDate: this.secondaryDate },
            })
        );
    }

    onkeyup(e) {
        if (e.key === 'Enter') {
            this.onDateClicked(e.target.date);
        }
    }

    keyNavigation(key, day) {
        const days = this.monthFirstDay?.getMonthDays(this.startDayOfWeek);
        const index = days.findIndex(d => compareDates(d, day) === 0);

        const arrowKeys = {
            left: 37,
            up: 38,
            right: 39,
            down: 40,
        };

        const directionTarget = new Map([
            [arrowKeys.up, -7],
            [arrowKeys.down, 7],
            [arrowKeys.left, -1],
            [arrowKeys.right, 1],
        ]);

        const newItem = this.shadowRoot
            .querySelectorAll('td[date]')
            [index + directionTarget.get(key)]?.querySelector('button');

        if (newItem) {
            newItem.focus();
        }
    }

    blurOnEnter(evt) {
        if (evt.key === 'Enter' || evt.keyCode === 13) {
            evt.target.blur();
        }
    }

    stopSpecialCharacters(event) {
        // eslint-disable-next-line prefer-regex-literals
        const regex = new RegExp('^[a-zA-Z0-9/ ]+$');
        if (!regex.test(event.data)) {
            event.preventDefault();
        }
    }

    async showMonthList() {
        this.monthSelectorOpen = true;
        this.yearSelectorOpen = false;
        await this.updateComplete;
        const currentMonth = this.monthFirstDay.month;
        const list = this.shadowRoot.querySelector('div#month-selector');
        if (list) {
            const currentLi = this.shadowRoot.querySelector(`li#month-list-${currentMonth}`);
            currentLi.classList.add('is-selected');
            currentLi.scrollIntoView();
        }
    }

    async showYearList() {
        this.monthSelectorOpen = false;
        this.yearSelectorOpen = true;
        await this.updateComplete;
        const currentYear = this.monthFirstDay.year;
        const list = this.shadowRoot.querySelector('div#year-selector');
        if (list) {
            const currentLi = this.shadowRoot.querySelector(`li#year-list-${currentYear}`);
            currentLi.classList.add('is-selected');
            currentLi.scrollIntoView();
        }
    }

    renderHeader() {
        return html`
            <header>
                <div class="month-label">
                    <button
                        id="select-month"
                        aria-label="show month selector"
                        @click="${() => this.showMonthList()}"
                    >
                        ${this.monthFirstDay?.monthName()}
                    </button>
                    <button
                        id="select-year"
                        aria-label="show year selector"
                        class="year-label"
                        @click="${this.showYearList}"
                    >
                        ${this.monthFirstDay?.getFullYear()}
                    </button>
                </div>
                <div>
                    <button
                        id="previous-month"
                        aria-label="go to last month"
                        class="month-button"
                        type="button"
                        @click=${() => {
                            this.monthFirstDay.goBackOneMonth();
                            this.requestUpdate();
                        }}
                    ></button>
                    <button
                        id="next-month"
                        aria-label="go to next month"
                        class="month-button"
                        type="button"
                        @click=${() => {
                            this.monthFirstDay.goForwardOneMonth();
                            this.requestUpdate();
                        }}
                    ></button>
                </div>
            </header>
        `;
    }

    renderMonthItem(month, index) {
        return html`
            <li
                id="month-list-${index}"
                @click="${() => {
                    this.monthFirstDay.setMonth(index);
                    this.monthSelectorOpen = false;
                    this.requestUpdate();
                }}"
                @keyup=${e => this.onkeyup(e)}
            >
                ${month}
            </li>
        `;
    }

    renderMonthList() {
        if (!this.monthSelectorOpen) return nothing;

        return html`
            <div id="month-selector">
                <ul>
                    ${MONTHS.map((month, index) => this.renderMonthItem(month, index))}
                </ul>
            </div>
        `;
    }

    renderYearItem(year) {
        return html`
            <li
                id="year-list-${year}"
                @click="${() => {
                    this.monthFirstDay.setYear(year);
                    this.yearSelectorOpen = false;
                    this.requestUpdate();
                }}"
                @keyup=${e => this.onkeyup(e)}
            >
                ${year}
            </li>
        `;
    }

    renderYearList() {
        if (!this.yearSelectorOpen || !this.monthFirstDay) return nothing;
        const years = [];
        for (
            let year = this.monthFirstDay.getFullYear() - YEAR_COUNT;
            year <= this.monthFirstDay.getFullYear() + YEAR_COUNT;
            year++
        ) {
            years.push(this.renderYearItem(year));
        }
        return html`
            <div id="year-selector">
                <ul>
                    ${years}
                </ul>
            </div>
        `;
    }

    renderCalendar() {
        if (this.monthSelectorOpen || this.yearSelectorOpen) return nothing;
        const dayHeaders = daysShort.slice();

        if (this.startDayOfWeek) {
            for (let i = 0; i < this.startDayOfWeek; i++) {
                dayHeaders.push(dayHeaders.shift());
            }
        }

        const weeks = this.monthFirstDay
            ?.getMonthDays(this.startDayOfWeek)
            .reduce((accumulatedWeeks, day) => {
                if (
                    !accumulatedWeeks.length ||
                    accumulatedWeeks[accumulatedWeeks.length - 1].length === 7
                ) {
                    accumulatedWeeks.push([]);
                }
                accumulatedWeeks[accumulatedWeeks.length - 1].push(day);
                return accumulatedWeeks;
            }, []);

        if (!weeks?.length) return nothing;

        return html`
            <table>
                <thead>
                    ${dayHeaders.map(
                        (day, index) =>
                            html`<th scope="col" aria-label="${daysOfTheWeek[index]}">${day}</th>`
                    )}
                </thead>
                <tbody>
                    ${weeks.map(
                        week => html`
                            <tr>
                                ${week.map(day => this.renderDay(day))}
                            </tr>
                        `
                    )}
                </tbody>
            </table>
        `;
    }

    renderDay(day) {
        const dayLabel = `${daysOfTheWeek[day.getDay()]} ${dateFormatter(day)}`;
        return html`
            <td
                class=${classMap({
                    date: true,
                    selected: this.datesMatch(day, this.selectedDate),
                    'is-start':
                        (this.secondaryDate || this.hoveredDate) &&
                        this.datesMatch(day, this.selectedDate) &&
                        !this.datesMatch(day, this.hoveredDate),
                    secondary: this.datesMatch(day, this.secondaryDate),
                    hovered: this.datesMatch(day, this.hoveredDate),
                    'in-range': this.isInRange(
                        day,
                        this.selectedDate,
                        this.secondaryDate || this.hoveredDate
                    ),
                    'out-of-month': this.outOfMonth(day),
                })}
            >
                <button
                    class="day"
                    ?disabled=${this.disableDay(day)}
                    data-date="${dateFormatter(day)}"
                    aria-label="${dayLabel}"
                    type="button"
                    @click=${() => this.onDateClicked(day)}
                    @mouseover=${() => this.onMouseoverDate(day)}
                    @keydown=${evt => this.keyNavigation(evt.keyCode, day)}
                >
                    ${day.getDate()}
                </button>
            </td>
        `;
    }

    renderRangeInput() {
        if (!this.range) return nothing;
        const secondaryDateError = this.secondaryDateInvalid
            ? html`<span id="secondary-date-error" class="error">Invalid date</span>`
            : nothing;
        return html`
            <div>
                <input
                    id="secondary-date-input"
                    class="date-input"
                    .value=${this.formattedDate(this.secondaryDate)}
                    @blur=${evt => this.onSecondaryBlur(evt.target?.value)}
                    @keyup=${this.blurOnEnter}
                    @beforeinput=${this.stopSpecialCharacters}
                    required
                    type="text"
                    aria-label="Report Name"
                    placeholder="End Date"
                />
                ${secondaryDateError}
            </div>
        `;
    }

    renderInput() {
        const invalidDateSpan = html`<span id="selected-date-error" class="error"
            >Invalid date</span
        >`;
        return html`
            <div class="input-container">
                <div>
                    <input
                        id="selected-date-input"
                        class="date-input"
                        ?error=${this.selectedDateInvalid}
                        .value=${this.formattedDate(this.selectedDate)}
                        @blur=${evt => this.onSelectedBlur(evt.target?.value)}
                        @keyup=${this.blurOnEnter}
                        @beforeinput=${this.stopSpecialCharacters}
                        required
                        type="text"
                        aria-label="Report Name"
                        .placeholder=${this.range ? 'Start Date' : 'Date'}
                    />
                    ${this.selectedDateInvalid ? invalidDateSpan : nothing}
                </div>

                ${this.renderRangeInput()}
            </div>
        `;
    }

    render() {
        return html`
            ${this.renderInput()} ${this.renderHeader()} ${this.renderCalendar()}
            ${this.renderMonthList()} ${this.renderYearList()}
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: relative;
                color: var(--omega-text-default);
                max-width: 300px;
            }

            * {
                box-sizing: border-box;
            }

            table {
                font-size: 12px;
                border-collapse: collapse;
                position: relative;
                height: 100px;
                width: 100%;
            }

            .input-container {
                display: flex;
            }

            .input-container > div {
                flex: 2;
                width: 100%;
            }

            :host([range]) .input-container > div {
                width: calc(100% - 5px);
            }

            :host([range]) .input-container > div:first-child {
                margin-right: 10px;
            }

            .date-input {
                height: 36px;
                padding: 6px 10px;
                border: 1px solid #ccc;
                background-color: #fff;
                border-radius: 3px;
                min-width: 120px;
                box-sizing: border-box;
                width: 100%;
            }

            header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 12px 0 18px;
            }

            header .month-label {
                font-size: 18px;
                font-weight: 700;
                color: var(--omega-text-header);
            }

            header .year-label {
                font-weight: 400;
                color: var(--omega-primary);
                cursor: pointer;
                border: none;
                background-color: transparent;
                font-size: inherit;
            }

            .month-button {
                width: 20px;
                height: 20px;
                padding: 5px;
                border: none;
                appearance: none;
                background: var(--omega-dropdown-arrow) right 4px center no-repeat #fff;
                background-color: transparent;
                background-size: 12px 12px;
                margin-right: 10px;
                transform: rotate(90deg);
            }

            .month-button#next-month {
                transform: rotate(-90deg);
            }

            #select-month {
                cursor: pointer;
                border: none;
                background-color: transparent;
                font-size: inherit;
                font-weight: inherit;
            }

            #select-month:hover {
                color: var(--omega-primary);
            }

            th {
                font-size: 11px;
                font-weight: 500;
                text-align: center;
                padding-bottom: 4px;
            }

            tbody td {
                padding: 0;
                position: relative;
                text-align: center;
            }

            .day {
                font: inherit;
                color: inherit;
                width: 30px;
                width: var(--datepicker-day-button-size, 30px);
                height: var(--datepicker-day-button-size, 30px);
                border: none;
                appearance: none;
                background-color: transparent;
                line-height: var(--datepicker-day-button-size, 30px);
                text-align: center;
                padding: 0;
            }

            .day:hover {
                border: 1px solid var(--omega-primary);
                border-radius: 7px;
                line-height: calc(var(--datepicker-day-button-size, 30px) - 2px);
            }

            .day[disabled] {
                color: #d1d1d1 !important;
                position: relative;
            }

            .day[disabled]::after {
                content: '';
                position: absolute;
                border-bottom: 1px solid #d1d1d1;
                width: 22px;
                top: 14px;
                left: 50%;
                transform: translate(-50%) rotate(-30deg);
            }

            td.out-of-month .day {
                color: var(--omega-text-tertiary);
            }

            .day[disabled]:hover {
                border-color: transparent;
            }

            input[error] {
                border-color: var(--omega-text-error);
            }

            .error {
                font-size: 11px;
                color: var(--omega-text-error);
                font-weight: 500;
            }

            td.selected button,
            td.secondary button {
                background-color: var(--omega-primary);
                border-radius: 7px;
                color: var(--omega-primary-foreground) !important;
            }

            td.hovered:not([selected]),
            td.in-range {
                background-color: var(--omega-calendar-selection-color);
                /* background-color: #cddeeb; */
                color: var(--omega-primary);
            }

            td.is-start::before,
            td.secondary::before {
                position: absolute;
                z-index: -1;
                right: 0;
                display: block;
                width: 20px;
                height: 30px;
                background-color: var(--omega-calendar-selection-color);
                content: '';
            }

            td.selected.secondary::before {
                display: none;
            }

            td.is-start::before {
                right: 0;
            }

            td.secondary::before {
                left: 0;
            }

            #month-selector ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
            }

            #month-selector li {
                display: inline-block;
                width: 90px;
                height: 40px;
                list-style: none;
                border: 1px solid #ccc;
                border-radius: 3px;
                text-align: center;
                padding-top: 10px;
                margin: 5px;
                cursor: pointer;
            }

            #month-selector li.is-selected {
                background-color: var(--omega-primary);
                color: white;
            }

            #year-selector ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                height: 200px;
                overflow-y: scroll;
            }

            #year-selector li {
                display: inline-block;
                width: 100%;
                height: 40px;
                list-style: none;
                border: 1px solid #ccc;
                border-radius: 3px;
                text-align: center;
                padding-top: 10px;
                margin: 5px;
                cursor: pointer;
            }

            #year-selector li.is-selected {
                background-color: var(--omega-primary);
                color: white;
            }
        `;
    }
}

customElements.define('omega-calendar', OmegaCalendar);
export default OmegaCalendar;
