export default {
    template: require('../views/issuedItemSummaryView.html'),
    controller: IssuedItemSummaryController,
    bindings: {
        issuedItems: '<',
        totalCountLabel: '@',
    },
};

function IssuedItemSummaryController() {
    const $ctrl = this;
    $ctrl.totalCheckCount = 0;
    $ctrl.totalAmount = 0;
    $ctrl.accountSummaries = [];
    $ctrl.totalCountLabel = $ctrl.totalCountLabel || 'Total Checks';

    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if (changes.issuedItems) {
            validateInputs();
            calculateSummaries();
        }
    }

    function validateInputs() {
        if (!angular.isArray($ctrl.issuedItems)) {
            throw 'issue-items passed to account summary must be an array';
        }
    }

    function calculateSummaries() {
        $ctrl.accountSummaries = calculateAccountSummary();
        $ctrl.totalCheckCount = $ctrl.issuedItems.length;
        $ctrl.totalAmount = $ctrl.issuedItems.reduce(
            (totalAmount, issuedItem) => totalAmount + issuedItem.checkAmount,
            0
        );
    }

    function calculateAccountSummary() {
        const accountSummaries = {};

        $ctrl.issuedItems.forEach(issuedItem => {
            const { account } = issuedItem;
            if (account == null) return;

            const accountSummary = accountSummaries[account.id];
            if (accountSummary == null) {
                accountSummaries[account.id] = {
                    accountNumber: account.number,
                    accountType: account.type,
                    itemCount: 1,
                    amountTotal: issuedItem.checkAmount,
                };
            } else {
                accountSummary.itemCount += 1;
                accountSummary.amountTotal += issuedItem.checkAmount;
            }
        });

        const values = [];
        for (const index in accountSummaries) {
            values.push(accountSummaries[index]);
        }

        return values;
    }
}
