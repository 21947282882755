WireToTemplateDialogController.$inject = ['$scope', '$modalInstance', 'wireService'];

export default function WireToTemplateDialogController($scope, $modalInstance, wireService) {
    $scope.cancel = cancel;
    $scope.create = create;
    $scope.setForm = setForm;
    $scope.template = { name: null };
    $scope.disableCreate = disableCreate;

    function cancel() {
        $modalInstance.dismiss();
    }

    function create() {
        wireService.validateTemplateName($scope.template.name).then(response => {
            if (response.isValid) {
                $scope.form.wireTemplateNameDialog.$setValidity('unique', true);
                $modalInstance.close($scope.template.name);
            } else {
                $scope.form.wireTemplateNameDialog.$setValidity('unique', false);
            }
        });
    }

    function disableCreate() {
        return $scope.form.wireTemplateNameDialog.$error.required;
    }

    function setForm(form) {
        $scope.form = form;
    }
}
