import { Record } from '@treasury/FDL';
import { TemplateResult } from 'lit';

// eslint-disable-next-line no-shadow
export enum OmegaColumnAction {
    Add = 'add',
    Delete = 'delete',
    Clone = 'clone',
}

// eslint-disable-next-line no-shadow
export enum OmegaColumnType {
    /**
     * Displays the cell as checkbox
     *
     * Each cell is a checkbox that takes user input.
     * The column applies this input to the given field in the current record.
     * A checkbox in the head of the column displays the aggregate state of items
     * in the column and it sets the values of all cells in the column when changed.
     * @example
     * {
     *     type: ColumnTypes.Checkbox,
     *     field: 'myFieldName',
     *     label: 'My Field Label'
     * }
     */
    CheckBox = 'checkbox',
    /**
     * Provides buttons to add / clone / delete a column.
     * The list of buttons are provided in an array of options.
     * Each action consists of an action ("add" / "clone" / "delete") and an optional tooltip.
     * For clone, we can also pass an optional list of "fieldsToClone" (otherwise it clones the entire record.)
     * @example
     * {
     *     type: ColumnTypes.EditRow,
     *     field: '',
     *     label: '',
     *     options: [{ action: ColumnActions.Add }]
     * }
     */
    EditRow = 'edit-row',
    /**
     *  Implements the command pattern for table cells within a column.
     *  The command is executed when the table cell is clicked/tapped or changed.
     *  This will commonly be used for link fields that take the user to another view that is related to the cell content.
     *
     *  This can more generally be used for any kind of command that may be generated by a cell-level element.
     *  Clicking on a button in this column generates a command event that passes a detail argument with the field,
     *  the action name, the row record, and the row index.
     *
     *  Do not deprecate this in favor of the Actions column because this type has some disabled logic that Actions column
     *  does not.
     *
     *  @example
     *  {
     *      type: ColumnTypes.Command,
     *      field: 'transactionId',
     *      label: 'Command',
     *      // string that references the name of the action getting passed to the table
     *      action: 'showTransactionDetails'
     *  }
     */
    Command = 'command',
    /**
     * Takes an array of actions and labels that are formatted as a link.
     *
     * Actions are strings that reference the name of an action/function that gets passed into a table.
     * Clicking on the lick will execute the action.
     *
     * @example
     * {
     *     type: ColumnType.Actions,
     *     label: 'Actions',
     *     actions: [
     *         {
     *             label: 'Action 1',
     *             // string that references the name of the action getting passed to the table
     *             action: 'takeAction',
     *             visibleWhen: (record) => !!record.getField('selected')
     *         }
     *     ]
     * }
     */
    Actions = 'actions',
    /**
     *  Renders a detail column cell that when clicked, raises an event that an Omega Table recognizes to open/close the corresponding detail row on the table.
     *  Each cell displays the input trigger 'Details', formatted as a link.
     *
     * @example
     * {
     *     type: ColumnType.Detail,
     *     'display-toggle-all': true,
     *     'display-chevron': true,
     * }
     */
    Detail = 'detail',
    /**
     * Takes an icon that is displayed in the cell
     *
     * @example
     * {
     *     type: ColumnTypes.Icon,
     *     icon: 'trash',
     *     iconLabel: 'Delete Item' // for accessibility
     *     label: 'Delete',
     *     // string that references the name of the action getting passed to the table
     *     action: 'deleteItem'
     * }
     */
    Icon = 'icon',
    /**
     * Renders a column cell as a icon with a tooltip. The message for the tooltip can passed in
     * via field or static message prop.
     *
     * @example
     * {
     *     type: ColumnTypes.Tooltip,
     *     field: 'myMessage' // field dynamic message from record
     *     message: 'My message goes here' // allows you to pass in a static message
     *     icon: 'info-circle', // defaults to info circle if not defined
     *     iconLabel: 'My Info Label' // for accessibility
     *     label: 'Icon label',
     * }
     */
    Tooltip = 'tooltip',
    RadioIcon = 'radio-icon',
    CheckImage = 'check-image',
    Empty = 'empty',
}

interface ColumnDefinitionBase<T> {
    /**
     * Denotes the field from the dataset shape to populate the column.
     * */
    field?: keyof T;
    /**
     * Defines the column header text.
     * */
    label: string;
    /**
     * Informs the report about special column behavior.
     * */
    type?: OmegaColumnType;
    summary?: boolean;
    sortable?: boolean;
}

interface IconColumnDefinition<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.Icon;
    /**
     * Font Awesome icon ID.
     */
    icon: string;
    iconStyle?: string;
    tooltip?: string | TemplateResult;
    action?: string;
    /**
     * Conditional function that determines whether the a conditional, secondary icon is shown instead of the primary icon.
     * @param record
     * */
    conditional?: (record: Record<T>) => boolean | IconColumnDefinition<T>;
    /**
     * Conditional function that determines whether the icon is visible.
     * @param record
     * */
    visibleWhen?: (record: Record<T>) => boolean;
}
interface TooltipColumnDefinition<T>
    extends ColumnDefinitionBase<T>,
        Partial<Pick<IconColumnDefinition<T>, 'icon'>> {
    type: OmegaColumnType.Tooltip;
}

interface DetailColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.Detail;
    'display-toggle-all': boolean;
    'display-chevron'?: boolean;
}

interface OptionsApiBase {
    action: OmegaColumnAction;
    tooltip?: string;
}

interface CloneOptionsApi<T> extends OptionsApiBase {
    fieldsToClone: Array<keyof T>;
}

type OptionsApi<T> = OptionsApiBase | CloneOptionsApi<T>;

interface EditRowColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.EditRow;
    options: Array<OptionsApi<T>>;
    visible: boolean;
}

interface ActionsColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.Actions;
    actions?: Array<
        | {
              /**
               * Label for the button/action will be rendered
               */
              label: string;
              /**
               * Event handler for the action that will be executed or string reference to the event handler.
               */
              action: string | ((record: Record<T>, index: number) => void);
              /**
               * Visible when is a function that takes the record and returns a boolean.
               */
              visibleWhen?: (record: Record<T>) => boolean;
          }
        | string
    >;
}

interface CommandColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.Command;
    action: string;
}

interface RadioIconColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.RadioIcon;
    value: string | number | undefined;
}

interface CheckImageColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.CheckImage;
    fetchCheckImages: (record: Record<T>) => Promise<void>;
}

interface EmptyColumn<T> extends ColumnDefinitionBase<T> {
    type: OmegaColumnType.Empty;
}

export type OmegaColumnDefinition<T> =
    | ColumnDefinitionBase<T>
    | CommandColumn<T>
    | IconColumnDefinition<T>
    | TooltipColumnDefinition<T>
    | DetailColumn<T>
    | EditRowColumn<T>
    | ActionsColumn<T>
    | RadioIconColumn<T>
    | CheckImageColumn<T>
    | EmptyColumn<T>;
