import { Record } from '@treasury/FDL/record';
import { string, amountRange, textarea, boolean, number } from '@treasury/policy/primitives';
import { achCompanyName } from '@treasury/policy/ach';
import { secCode, filterDatePicker } from '@treasury/policy/ach';
import accountingNotation from '@treasury/omega/formatters/accountingNotation.formatter';
import usd from '@treasury/omega/formatters/usd.formatter';
import { disablePastDatesAndFutureCenturies } from '@treasury/policy/lib/utils/disable-date-functions.js';
import { maxAmountValidator } from '@treasury/policy/lib/validators/max-amount.validator.ts';
import { FieldType } from '@treasury/FDL';

const createOrEditFlow = record =>
    record.getField('mode') === 'create' ||
    record.getField('mode') === 'create-from-exception' ||
    record.getField('mode') === 'edit' ||
    record.getField('mode') === 'clone';

const createFromExceptionFlow = record =>
    record.getField('mode') === 'create-from-exception' && record.initialValues.achCompanyId;

const isReviewOrConfirmStep = record => record.getField('step') !== 0;

function getAmount(fromAmount, toAmount) {
    if (!toAmount) return ['range', 0, 0];
    if (fromAmount === toAmount) return ['specific', fromAmount];
    return ['range', fromAmount, toAmount];
}

function getSecCodeText(option) {
    if (!option.code) return option.name;
    return `${option.code} - ${option.name}`;
}

const maxAmount = maxAmountValidator(999999999);

export const createNewFilter = (values, mode, client) =>
    new Record(
        {
            account: new FieldType().with
                .options({
                    fetch: client.getAccountsCreate,
                    text: record => `${record.accountNumber} - ${record.accountType}`,
                    value: record => record,
                })
                .with.formatter(value => value.accountNumber)
                .and.filtering()
                .and.search({
                    title: 'Search Accounts',
                    columns: [
                        { label: 'Account Type', field: 'accountType' },
                        { label: 'Account Number', field: 'accountNumber' },
                    ],
                })
                .thatHas.hashFunction(item => item?.accountUniqueId)
                .and.label('Account')
                .thatIs.requiredWhen(createOrEditFlow)
                .andIs.readOnlyWhen(() => mode === 'edit')
                .andIs.readOnlyWhen(isReviewOrConfirmStep)
                .and.defaultValue(''),

            accountType: string.thatIs.visibleWhen(() => false).and.readOnly(),

            accountUniqueId: string.thatIs.visibleWhen(() => false).and.readOnly(),

            achCompanyId: string.thatIs
                .requiredWhen(createOrEditFlow)
                .with.label('Company ID')
                .and.maxLength(10)
                .andIs.readOnlyWhen(createFromExceptionFlow)
                .andIs.readOnlyWhen(isReviewOrConfirmStep),

            achCompanyName: achCompanyName.with
                .label('ACH Company Name')
                .with.iconMessage(
                    'The company name and the company name in the originating transaction must be an exact match'
                )
                .andIs.readOnlyWhen(isReviewOrConfirmStep),
            achFilterUniqueId: string.thatIs.visibleWhen(() => false),

            allowAllDebits: boolean.thatIs.visibleWhen(() => false).and.readOnly(),

            allowAllCredits: boolean.thatIs.visibleWhen(() => false).and.readOnly(),

            amount: amountRange.with
                .label('Amount')
                .with.validator(maxAmount)
                .and.template((value, record) => {
                    // eslint-disable-next-line prefer-const
                    let [fromAmountValue, toAmountValue] = record.getField('amount').slice(1);
                    // Ugly hack to get around the API returning NULL for the FromAmount when we have a valid range of 0 - something. Once Services updates the API (AB#314023)
                    if (fromAmountValue == null && toAmountValue) {
                        fromAmountValue = 0;
                    }
                    if (fromAmountValue != null && toAmountValue) {
                        return `
                            ${accountingNotation(usd(fromAmountValue))} - ${accountingNotation(
                            usd(toAmountValue)
                        )}
                        `;
                    }
                    if (fromAmountValue) {
                        return `${accountingNotation(usd(fromAmountValue))}`;
                    }
                    return '';
                })
                .thatIs.segmented()
                .andIs.readOnlyWhen(isReviewOrConfirmStep),

            expirationDate: filterDatePicker.with
                .label('Expiration Date')
                .andIs.readOnlyWhen(isReviewOrConfirmStep)
                .thatHas.selectionDisabledFunctions(disablePastDatesAndFutureCenturies),

            filterRuleName: string.thatIs
                .requiredWhen(createOrEditFlow)
                .with.label('Filter Rule Name')
                .andIs.readOnlyWhen(isReviewOrConfirmStep)
                .and.maxLength(30),

            mode: string.thatIs.visibleWhen(() => false).and.readOnly(),

            nickName: string.thatIs.visibleWhen(() => false).and.readOnly(),

            remarks: textarea.with.rowCount(5).with.label('Remarks'),

            secCode: secCode.with
                .label('SEC Code')
                .andIs.readOnlyWhen(isReviewOrConfirmStep)
                .with.options({
                    data: [
                        { code: '', name: 'Select None' },
                        { code: 'ARC', name: 'Accounts Receivable Entry' },
                        { code: 'BOC', name: 'Back Office Conversion' },
                        { code: 'CCD', name: 'Corporate Credit or Debit' },
                        { code: 'CIE', name: 'Customer-Initiated Entry' },
                        { code: 'CTX', name: 'Corporate Trade Exchange' },
                        { code: 'IAT', name: 'International ACH Transaction' },
                        { code: 'CTX', name: 'Corporate Trade Exchange' },
                        { code: 'POP', name: 'Point-of-Purchase Entry' },
                        { code: 'POS', name: 'Point-of-Sale' },
                        { code: 'PPD', name: 'Prearranged Payment and Deposit' },
                        { code: 'RCK', name: 'Re-presented Check Entry' },
                        { code: 'TEL', name: 'Telephone-Initiated Entry' },
                        { code: 'WEB', name: 'Internet-Initiated/ Mobile Entry' },
                        { code: 'XCK', name: 'Destroyed Check Entry' },
                    ],
                    text: option => getSecCodeText(option),
                    value: 'code',
                }),

            step: number.thatIs.visibleWhen(() => false).and.readOnly(),

            transactionType: string.thatIs
                .requiredWhen(createOrEditFlow)
                .with.options({
                    fetch: record => {
                        const creditsLabel = record.getField('allowAllCredits')
                            ? 'Credit Not Allowed'
                            : 'Credit Allowed';
                        const debitsLabel = record.getField('allowAllDebits')
                            ? 'Debit Not Allowed'
                            : 'Debit Allowed';
                        return new Promise(resolve =>
                            resolve([
                                { value: 'Credit', text: creditsLabel },
                                { value: 'Debit', text: debitsLabel },
                            ])
                        );
                    },
                })
                .thatIs.inline()
                .with.label('Transaction Type')
                .andIs.readOnlyWhen(isReviewOrConfirmStep),
        },
        {
            account: values?.account,
            accountType: values?.accountType ? values.accountType : null,
            accountUniqueId: values?.accountUniqueId ? values.accountUniqueId : null,
            achFilterUniqueId: values?.achFilterUniqueId ? values.achFilterUniqueId : 0,
            achCompanyId: values?.achCompanyId ? values.achCompanyId : null,
            achCompanyName: values?.achCompanyName ? values.achCompanyName : null,
            allowAllCredits: null,
            allowAllDebits: null,
            amount: values.amount ? values.amount : getAmount(values.fromAmount, values.toAmount),
            expirationDate: values?.expirationDate ? values.expirationDate : null,
            filterRuleName: values?.filterRuleName ? values.filterRuleName : null,
            mode,
            nickName: values?.nickName ? values.nickName : null,
            remarks: values?.remarks ? values.remarks : null,
            secCode: values?.secCode ? values.secCode : null,
            step: 0,
            transactionType: values?.transactionType ? values.transactionType : null,
        }
    );
