import { FieldType, Record, Recordset } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { Primitives as fieldTypes } from '@treasury/policy';
import { exists, getKeys } from '@treasury/utils';
import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ListeningElementMixin } from '../components';
import '../components/omega-accordion.js';
import '../components/omega-action-bar.js';
import '../components/omega-button';
import '../components/omega-button-bar.js';
import '../components/omega-download-bar.js';
import '../components/omega-field-2';
import '../components/omega-field.js';
import '../components/omega-filter-bar.js';
import '../components/omega-table.js';
import '../components/omega-validation-button';
import '../components/omega-workflow.js';
import { OmegaColumnDefinition } from '../components/table';
import { OmegaReportItemLabel } from '../types';

export interface StepBase {
    step: number;
}

const ListeningElement = ListeningElementMixin(LitElement);

type RecordsetFetch = () => Promise<Recordset<unknown>>;

type RecordsetVisibleWhen<T> = (record: Record<T>) => boolean;

type RecordsetValidWhen<T> = (record: Record<T>, recordset: Recordset<T>) => boolean;

export type ActionType =
    | 'primary'
    | 'secondary'
    | 'link'
    | 'approve'
    | 'reject'
    | 'icon'
    | 'status-active'
    | 'status-failed'
    | 'status-pending-approval'
    | 'status-inactive';
export interface Action<TRecord extends StepBase = StepBase> {
    label: string;
    type?: ActionType;
    action: (record: Record<TRecord>) => void;
    /** Set to true if the button for this action should a submit button that should submit form-data. */
    submit?: boolean;
    /** Set to true if the button for this action should be a validation/status button. */
    validation?: boolean;
    visibleWhen?: (record: Record<TRecord>) => boolean;
    disabledWhen?: (record: Record<TRecord>) => boolean;
}

interface Group<TRecord> {
    legend: string;
    fields: Array<keyof TRecord>;
}

export interface Column<TRecord> {
    groups?: Group<TRecord>[];
    fields: Array<keyof TRecord>;
    fieldset?: Array<{ legend: string; fields: Array<string> }>;
}

interface Section<TRecord> {
    header?: string;
    columns: Array<Column<TRecord>>;
    groups?: Array<Group<TRecord>>;
    fields?: Array<keyof TRecord>;
}

export interface Step<TRecord extends StepBase> extends StepBase {
    label: string;
    /** Actions that are displayed at the bottom of the form */
    actions?: Action<TRecord>[];
    /** Actions that are displayed in the filter bar above the table. */
    filterBarActions?: Action<TRecord>[];
    submit?: boolean;
}

export interface RecordsetFilter {
    field: string;
    fieldType: FieldType<any>;
    value: any;
}

type DownloadBarAction = 'print' | 'save' | 'download';
type DownloadOption = 'CSV' | 'NACHA' | 'PDF';

export interface DownloadBarOptions {
    actions: Array<DownloadBarAction>;
    downloadOptions: Array<DownloadOption>;
    hideActionLabels: boolean;
}

interface RecordsetConfiguration<TRecordset> {
    recordsetValidWhen?: RecordsetValidWhen<TRecordset>;
    recordsetColumns?:
        | Array<OmegaColumnDefinition<TRecordset>>
        | ((
              recordset: Recordset<TRecordset> | undefined
          ) => Array<OmegaColumnDefinition<TRecordset>>);

    recordset: Recordset<TRecordset>;
    recordsetVisibleWhen?: RecordsetVisibleWhen<TRecordset>;
    recordsetFilters?: RecordsetFilter[];
    recordsetDetailRowTemplateFunction?: (record: Record<TRecordset>) => TemplateResult;
    recordsetAction?: (record: Record<TRecordset>) => void;
    recordsetFields?: FdlFieldDefinitions<TRecordset>;
    recordsetFetch?: RecordsetFetch;
    autoExpandDetailRows?: boolean;
    tableShouldRender?: (record: Record<TRecordset>) => boolean;
}

export interface Schema<TRecord, TRecordset = TRecord> {
    fields?: FdlFieldDefinitions<TRecord>;
    recordsetConfiguration?: RecordsetConfiguration<TRecordset>;
    values?: Partial<TRecord>;
    record?: Record<TRecord>;
    sections?: Array<Section<TRecord>>;
}

export const OmegaFormTagName = 'omega-form';

const stepFieldType = fieldTypes.number.thatIs.visibleWhen(() => false) as FieldType<number>;
const defaultStep: StepBase = {
    step: 3,
};

class OmegaForm<T> extends ListeningElement {
    @property({ type: String })
    public formTitle!: string;

    @property({ type: Number })
    public activeStep = 0;

    @property({ type: Array })
    public steps: Step<T & StepBase>[] = [];

    @property({ type: Object })
    public schema!: Schema<T & StepBase>;

    @state()
    private record!: Record<T & StepBase>;

    @state()
    private recordset?: Recordset<T & StepBase>;

    @state()
    private recordsetFilters!: Array<RecordsetFilter>;

    @state()
    private valid!: boolean;

    @state()
    private recordsetErrors!: Array<string>;

    @property({ type: Array })
    public actions!: Action<T & StepBase>[];

    @property({ type: Object })
    public downloadBarOptions!: DownloadBarOptions;

    /*
        Override default content and render form body from slot.
    */
    @property({ type: Object, attribute: false })
    public overrideConfig?: { formValid: boolean; useFormBody: boolean };

    /*
        Shows or hides the workflow steps in the form
        (Defaults to true)
    */
    @property({ type: Boolean })
    public showWorkflowStepIndicator = true;

    @property({ type: Object })
    public itemLabel: OmegaReportItemLabel = { singular: 'item', plural: 'items' };

    private hasSteps() {
        return this.steps.length > 0;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-form--default',
        };
    }

    get currentStepDefinition() {
        return this.steps.find(step => step.step === this.activeStep);
    }

    get fields(): (keyof (T & StepBase))[] {
        if (this.schema.record) return getKeys(this.schema.record.fieldTypes);
        if (this.schema.fields) return getKeys(this.schema.fields);
        return [];
    }

    get recordsetVisible() {
        if (!this.schema?.recordsetConfiguration?.recordsetVisibleWhen) {
            return true;
        }

        return this.schema?.recordsetConfiguration?.recordsetVisibleWhen(this.record);
    }

    get recordsetValid() {
        if (!this.schema?.recordsetConfiguration?.recordsetValidWhen) return true;
        if (!this.recordset) {
            return true;
        }

        return this.schema?.recordsetConfiguration?.recordsetValidWhen(this.record, this.recordset);
    }

    protected async firstUpdated() {
        this.record = this.buildRecord();
        this.recordset = await this.buildRecordset();
        this.addRecordListeners();
        this.addRecordsetListeners();
        this.validate();
    }

    private buildRecord() {
        if (this.schema.record) {
            this.schema.record.addField('step', stepFieldType as any, 0 as any);
            return this.schema.record as unknown as Record<T & StepBase>;
        }

        const { values } = this.schema;
        const fieldDefinitions = {
            ...this.schema.fields,
            step: stepFieldType,
        } as unknown as FdlFieldDefinitions<T & StepBase>;

        if (exists(values)) {
            return new Record<T & StepBase>(fieldDefinitions, {
                ...values,
                step: this.activeStep,
            } as T & StepBase);
        }

        return new Record(fieldDefinitions, defaultStep as T & StepBase);
    }

    addRecordListeners() {
        this.listenTo(this.record, 'change', ({ detail }: any) => {
            const { field } = detail;
            this.activeStep = this.record.getField('step');
            this.validate();
            this.dispatchEvent(
                new CustomEvent('change', {
                    detail: this.record,
                })
            );
        });
        this.listenTo(this.record, 'fields-change', ({ detail }: any) => {
            this.activeStep = this.record.getField('step');
            this.validate();
        });
    }

    private async buildRecordset() {
        if (!this.schema) {
            return undefined;
        }

        const { recordsetConfiguration } = this.schema;
        if (recordsetConfiguration?.recordset) {
            const { recordset } = recordsetConfiguration;
            await recordset.requestUpdate();
            recordset.allRecords.forEach(r => r.setField('step', 0 as any));

            return recordset;
        }

        if (recordsetConfiguration?.recordsetFields) {
            const { recordsetFields } = recordsetConfiguration;
            const recordset = new Recordset<T & StepBase>(
                recordsetFields as unknown as FdlFieldDefinitions<T & StepBase>
            );
            await recordset.requestUpdate();

            return recordset;
        }

        return undefined;
    }

    addRecordsetListeners() {
        if (!this.recordset) {
            return;
        }

        this.listenTo(this.recordset, 'updated', ({ detail }: any) => {
            this.validate();
        });
    }

    validate() {
        if (this.recordset && this.recordsetVisible) {
            this.recordsetErrors = this.recordset.errors();
            this.valid =
                this.record.isValid() &&
                this.record.hasRequiredValues() &&
                this.recordset.isValid() &&
                this.recordsetValid;
        } else {
            this.valid = this.record.isValid() && this.record.hasRequiredValues();
        }
    }

    handleAction({ action, record, rowIndex }: any) {
        if (!this.schema.recordsetConfiguration?.recordsetColumns) return null;
        const triggeredFunction = typeof action === 'function' ? action : action.action;
        if (typeof triggeredFunction === 'function') return triggeredFunction(record, rowIndex);
        return () => false;
        // if actions are passed as string references to recordsetActions, call action from the reference
        // Either a TODO or Won't do. I think it depends on deciding if we're happy with the above API
        // const actions = this.schema.recordsetConfiguration.recordsetColumns
        //     .filter(col => col.actions)
        //     .map(c => c.actions);
        // const actionFn = actions.find(a => a.action === action);
        // return this.schema.recordsetConfiguration?.recordsetActions[actionFn](record, rowIndex);
    }

    getColumnDefinitions() {
        if (typeof this.schema.recordsetConfiguration?.recordsetColumns === 'function') {
            return this.schema.recordsetConfiguration?.recordsetColumns(this.recordset);
        }
        return this.schema.recordsetConfiguration?.recordsetColumns;
    }

    getValidationMessage() {
        let errorMessage = '';

        if (this.recordsetErrors && this.recordsetErrors.length >= 1) {
            const recordsetErrorMessage = `
            The form has the following errors: <ul>${this.recordsetErrors
                .map(re => `<li>${re}</li>`)
                .join('')}</ul>
            `;
            errorMessage += recordsetErrorMessage;
        }

        if (this.record.invalidValues && this.record.invalidValues.length >= 1) {
            const recordErrorMessage = `
            The following fields are missing or invalid: <ul>${this.record
                .readableRecordErrors()
                .map(val => `<li>${val}</li>`)
                .join('')}</ul>
            `;
            errorMessage += recordErrorMessage;
        }

        return errorMessage;
    }

    renderDownloadBar() {
        if (!this.downloadBarOptions) return nothing;
        return html`<omega-download-bar
            .actions=${this.downloadBarOptions.actions}
            .downloadOptions=${this.downloadBarOptions.downloadOptions}
            .hideActionLabels=${this.downloadBarOptions.hideActionLabels}
        ></omega-download-bar>`;
    }

    renderValidationButton(action: Action<T & StepBase>) {
        const disabled = action.submit ? !this.valid : false;
        return html`<omega-validation-button
            .message=${this.getValidationMessage()}
            .type=${action.type as string}
            .disabled=${disabled}
            @submit=${() => action.action(this.record)}
            >${action.label}</omega-validation-button
        >`;
    }

    renderActionButton(action: Action<T & StepBase>, slot: string | undefined = undefined) {
        if (action.visibleWhen && !action.visibleWhen(this.record)) {
            return nothing;
        }

        const validity = this.overrideConfig?.useFormBody
            ? this.overrideConfig.formValid
            : this.valid;
        const disabledSubmit = action.submit ? !validity : false;
        let isDisabled = false;
        if (action.disabledWhen) isDisabled = action.disabledWhen(this.record);
        const disabled = disabledSubmit || isDisabled;
        if (action.validation) return this.renderValidationButton(action);
        return html`<omega-button
            .type=${action.type as string}
            .disabled=${!!disabled}
            slot=${ifDefined(slot)}
            @click=${() => action.action(this.record)}
            >${action.label}</omega-button
        >`;
    }

    renderFormActions() {
        if (!this.actions) return nothing;
        const buttons = this.actions.map(action => this.renderActionButton(action));
        return html`<omega-button-bar .alignment=${'left'} .position=${'bottom'}
            >${buttons}</omega-button-bar
        >`;
    }

    renderWorkflowActions() {
        if (!this.hasSteps()) return nothing;
        const buttons = this.currentStepDefinition?.actions?.map(action =>
            this.renderActionButton(action)
        );
        return html`<omega-button-bar .alignment=${'left'} .position=${'bottom'}
            >${buttons}</omega-button-bar
        >`;
    }

    renderField(field: keyof (T & StepBase)) {
        if (!this.record || !field) return nothing;
        if (!this.record.fieldTypeForField(field).visible(this.record)) return nothing;
        return html`<omega-field .field=${field} .record=${this.record}></omega-field>`;
    }

    renderGroup(group: Group<T & StepBase>) {
        if (!group) return nothing;
        const fields = group.fields.map(field => this.renderField(field));
        return html`<fieldset>
            <legend>${group.legend}</legend>
            ${fields}
        </fieldset>`;
    }

    renderColumn(column: Column<T & StepBase>) {
        if (!column) return nothing;
        let fields;
        let groups;
        if (column.fields) {
            fields = column.fields.map(field => this.renderField(field));
        }
        if (column.groups) {
            groups = column.groups.map(group => this.renderGroup(group));
        }
        return html`<div class="form-column">${fields}${groups}</div>`;
    }

    renderFormSections(sections: Array<Section<T & StepBase>>) {
        return sections.map(section => {
            const sectionColumns = section.columns?.map((column: Column<T & StepBase>) =>
                this.renderColumn(column)
            );
            const sectionGroups = section.groups?.map(group => this.renderGroup(group));
            const sectionFields = section.fields?.map(field => this.renderField(field));
            return html`<div class="omega-flex-form">${sectionColumns}</div>
                <div class="form-fields">${sectionGroups}</div>
                <div class="form-fields">${sectionFields}</div>`;
        });
    }

    renderForm() {
        if (!this.schema || !this.record) return nothing;

        if (this.overrideConfig?.useFormBody) {
            return html`<slot name="form-body"></slot>`;
        }

        const { sections } = this.schema;

        if (!sections || sections.length < 1) {
            const formFields = this.fields.map(field => this.renderField(field));
            return html`${formFields}${this.renderTable()}`;
        }

        return html` ${this.renderFormSections(sections)}`;
    }

    renderFilterBarActions() {
        const filterBarActions = this.currentStepDefinition?.filterBarActions;
        if (!filterBarActions || filterBarActions.length < 1) return nothing;
        return html`
            <div slot="actions">
                ${filterBarActions.map(action => this.renderActionButton(action, 'filter-actions'))}
            </div>
        `;
    }

    renderTableControls() {
        return html`<slot name="table-controls" class="table-controls"></slot>`;
    }

    renderTable() {
        if (!this.schema.recordsetConfiguration) return nothing;
        if (!this.recordset) return nothing;
        if (this.overrideConfig?.useFormBody) return nothing;
        if (
            typeof this.schema.recordsetConfiguration.tableShouldRender === 'function' &&
            !!this.schema.recordsetConfiguration.tableShouldRender(this.record)
        )
            return nothing;
        const filters = this.schema.recordsetConfiguration.recordsetFilters
            ? html`<omega-filter-bar
                  .recordset=${this.recordset}
                  .itemLabel=${this.itemLabel}
                  .filters=${this.schema.recordsetConfiguration.recordsetFilters}
                  @change=${(e: any) => {
                      this.recordsetFilters = e.detail;
                  }}
              >
                  ${this.renderFilterBarActions()}
              </omega-filter-bar> `
            : nothing;
        const table = html`<omega-table
            .recordset=${this.recordset}
            .itemLabel=${this.itemLabel}
            .columnDefinitions=${this.getColumnDefinitions()}
            .filters=${this.recordsetFilters}
            .detailRowTemplateFunction=${this.schema.recordsetConfiguration
                ?.recordsetDetailRowTemplateFunction}
            .autoExpandDetailRows=${this.schema.recordsetConfiguration?.autoExpandDetailRows}
            @action=${({ detail }: CustomEvent) => this.handleAction(detail)}
        ></omega-table>`;
        const tableControls = this.renderTableControls();
        if (!this.schema.recordsetConfiguration.recordsetVisibleWhen)
            return [filters, tableControls, table];
        if (!this.schema.recordsetConfiguration.recordsetVisibleWhen(this.record)) return nothing;
        return [filters, tableControls, table];
    }

    renderWorkflow() {
        if (!this.hasSteps()) {
            return this.renderForm();
        }

        const stepLabels = this.steps.map(step => step.label);
        // This allows us to have various step indexes
        const startingIndex = Math.min(...this.steps.map(step => step.step));
        return html`<omega-workflow
            .showIndicator=${this.showWorkflowStepIndicator}
            .stepLabels=${stepLabels}
            .activeStep=${this.activeStep}
            .startingIndex=${startingIndex}
            ><div slot="step">
                <slot name="below-form-stepper"></slot>
                <div class="form-title-container">
                    <div class="title">
                        ${this.formTitle
                            ? html`<div class="form-title">${this.formTitle}</div>`
                            : html`<slot name="form-title"></slot>`}
                    </div>
                    <div class="toolbar">
                        <div class="content"><slot name="form-title-toolbar"></slot></div>
                        ${this.renderDownloadBar()}
                    </div>
                </div>
                <hr />
                ${this.renderForm()} ${this.renderTable()}
            </div>
        </omega-workflow>`;
    }

    render() {
        if (!this.record) return nothing;
        return html`${this.renderWorkflow()}
        ${this.renderFormActions()}${this.renderWorkflowActions()} `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-flex-form {
                display: flex;
            }
            omega-field {
                --omega-label: 14px;
                --omega-field-control-font-size: 14px;
                --omega-field-control-font-weight: 700;
                --omega-field-control-font-weight-editable: 400;
                --omega-field-control-width: 100px;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-fields {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid var(--omega-secondary-lighten-300);
            }
            omega-field {
                margin: 10px;
            }
            omega-field-2 {
                margin: 10px;
            }
            fieldset {
                background: var(--omega-primary--focus);
                border: 2px solid var(--omega-primary-darken-100);
                border-radius: 3px;
            }

            legend {
                padding: 5px;
                color: var(--omega-white);
                font-size: var(--omega-label);
                background: var(--omega-primary-darken-100);
                border: 2px solid var(--omega-primary-darken-100);
                border-radius: 3px;
            }

            .form-title-container {
                margin: 0;
                display: flex;
                align-items: center;
            }
            .form-title-container .title {
                flex: 1;
            }
            .form-title {
                font-size: 24px;
                color: var(--omega-text-header);
            }
            .form-title-container .toolbar {
                display: flex;
                align-items: center;
            }

            .form-title-container .toolbar .content {
                padding-right: 2px;
            }

            hr {
                border: none;
                border-top: var(--omega-default-border);
            }
        `;
    }
}

export default OmegaForm;

customElements.define(OmegaFormTagName, OmegaForm);
