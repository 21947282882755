// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

const TIMEOUT = 600000000;

/**
 * Calls associated with the ach account reconciliation reports:
 *  * search reports
 *  * search report files
 *  * search accounts
 *  * get report types
 *  * get report timePeriods
 *  * download report file
 *  * create/update report
 *  * run report
 *  * delete report
 *  * delete report file
 */
class AchAccountReconciliationRequests {
    static async searchReports(params) {
        const { parameters } = params;
        const queryData = {
            AccountIds: parameters.accountIds,
            ReportTypeIds: parameters.reportType,
            FrequencyTypeIds: parameters.frequencyType,
            CreatedUserIds: parameters.createBy,
            PagingParameters: {
                PageSize: 500,
                PageNumber: 1,
                OrderBys: [
                    {
                        Name: 'Id',
                        Descending: true,
                    },
                ],
            },
        };

        return (await TmHttpClient.getInstance()).request(`accountRecon/report/search`, {
            method: 'POST',
            body: queryData,
        });
    }

    static async searchReportFiles(params) {
        const { parameters } = params ?? { parameters: null };
        const queryData = {
            AccountIds: parameters?.accountIds ?? null,
            ReportTypeIds: parameters?.reportType ?? null,
            FrequencyTypeIds: parameters?.frequencyType ?? null,
            JobStatusTypeIds: parameters?.jobStatusTypeIds ?? null,
            CreatedUserIds: parameters?.createBy ?? null,
            PagingParameters: {
                PageSize: 500,
                PageNumber: 1,
                OrderBys: [
                    {
                        Name: 'CreatedDate',
                        Descending: true,
                    },
                ],
            },
        };
        return (await TmHttpClient.getInstance()).request(`accountRecon/report/file/search`, {
            method: 'POST',
            body: queryData,
        });
    }

    static async searchAccounts(queryData = {}) {
        return (await TmHttpClient.getInstance()).request(`accountRecon/accounts`, {
            method: 'POST',
            body: queryData,
        });
    }

    static async getReportTypes() {
        return (await TmHttpClient.getInstance()).request(`accountRecon/reportType`, {
            method: 'GET',
            maxAgeInSeconds: TIMEOUT,
        });
    }

    static async getTimePeriods() {
        return (await TmHttpClient.getInstance()).request(`accountRecon/timePeriodType`, {
            method: 'GET',
            maxAgeInSeconds: TIMEOUT,
        });
    }

    static async downloadReportFile(externalReportFileId, fileOutputType) {
        return (await TmHttpClient.getInstance()).request(`accountRecon/report/file/download/`, {
            method: 'POST',
            body: { externalReportFileId, fileOutputType },
        });
    }

    static async createUpdateReport(report) {
        return (await TmHttpClient.getInstance()).request('accountRecon/report', {
            method: 'POST',
            body: report,
        });
    }

    static async runReport(reportId) {
        return (await TmHttpClient.getInstance()).request(
            `accountRecon/report/execute/${reportId}`,
            {
                method: 'POST',
            }
        );
    }

    static async deleteReport(reportId) {
        return (await TmHttpClient.getInstance()).request(`accountRecon/report/${reportId}`, {
            method: 'DELETE',
        });
    }

    static async deleteReportFile(reportFileId) {
        return (await TmHttpClient.getInstance()).request(
            `accountRecon/report/file/${reportFileId}`,
            {
                method: 'DELETE',
            }
        );
    }
}

export default AchAccountReconciliationRequests;
