import { createFileDownloadName } from '@treasury/domain/shared/utilities/map-download-options-from-api.js';

export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                downloadFormats: '=?',
                pageId: '=',
                filterModel: '=?',
                confirmationId: '=?',
                disabled: '=ngDisabled',
            },
            template: require('./downloadTemplate.html'),
            controller: DownloadController,
        };
    },
];

DownloadController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$modal',
    'toaster',
    'downloadService',
    'FileSaver',
    'downloadPageId',
    'modalService',
];

function DownloadController(
    $scope,
    $state,
    $stateParams,
    $modal,
    toaster,
    downloadService,
    FileSaver,
    downloadPageId,
    modalService
) {
    $scope.options = {};

    $scope.isValidDownload = function () {
        return !!$scope.options.pageId || !!$scope.filterModel;
    };

    function init() {
        if ($scope.filterModel) {
            $scope.options.filterModel = $scope.filterModel;
        }
        $scope.options.downloadFormats = $scope.downloadFormats ? $scope.downloadFormats : ['PDF'];
        $scope.options.pageId = $scope.pageId;
        $scope.options.confirmationId = $scope.confirmationId;
    }
    function getMimeType(outputType) {
        switch (outputType.toLowerCase()) {
            case 'pdf':
            case 'pdfindetail':
                return 'application/pdf';
            case 'csv':
            case 'csvindetail':
                return 'text/csv';
            case 'ofx':
                return 'application/x-ofx';
            case 'qfx':
                return 'application/x-qfx';
            case 'bai':
            case 'nacha':
                return 'text/plain';
            default:
                return 'application/octet-stream';
        }
    }

    function getFileExtension(outputType) {
        switch (outputType.toLowerCase()) {
            case 'pdfindetail':
                return 'pdf';
            case 'csvindetail':
                return 'csv';
            case 'bai':
            case 'nacha':
                return 'txt';
            default:
                return outputType.toLowerCase();
        }
    }
    function sendDownload() {
        downloadService.download($scope.options).then(response => {
            const blob = new Blob([response], { type: getMimeType($scope.options.outputType) });
            const currentDate = new Date();
            if ($scope.options.outputType === 'QFX' && blob.size < 3) {
                const modalOptions = {
                    isAlert: true,
                    closeButtonText: '',
                    actionButtonText: 'OK',
                    headerText: 'No Transactions Available',
                    summaryText:
                        'There are no transactions available for download, please update your date range selection.',
                    time: `${currentDate.toDateString()}  ${currentDate.toTimeString()}`,
                    submit() {
                        cancelModal.dismiss();
                    },
                };
                const cancelModal = modalService.showModal({}, modalOptions);
                return;
            }
            const fileType = getFileExtension($scope.options.outputType);
            const fileDownloadName = createFileDownloadName(
                $scope.options.pageId,
                fileType,
                $scope.options?.filterModel?.dateType
            );
            FileSaver.saveAs(blob, `${fileDownloadName}`);
        });
    }

    $scope.download = function (option) {
        if ($scope.filterModel) {
            $scope.options.filterModel = $scope.filterModel;
            $scope.options.filterModel.page = $scope.options.pageId;
        }
        if (option.indexOf('PDF') >= 0) {
            $scope.options.downloadPdfTypeSelected = option;
            $scope.options.outputType = 'PDF';
        }
        if (option.indexOf('OFX') >= 0) {
            $scope.options.downloadPdfTypeSelected = option;
            $scope.options.outputType = 'OFX';
        }
        if (option.indexOf('CSV') >= 0) {
            $scope.options.outputType = option.indexOf('Detail') >= 0 ? 'CsvInDetail' : 'CSV';
            if (
                $scope.pageId === downloadPageId.MultipleAchPaymentConfirmation ||
                $scope.pageId === downloadPageId.MultipleAchTaxPaymentsConfirmation
            ) {
                $scope.options.outputType = 'CsvInDetail';
            }
        }
        if (option.indexOf('BAI') >= 0) {
            $scope.options.outputType = 'BAI';
        }
        if (option.indexOf('QBO') >= 0) {
            $scope.options.outputType = 'QBO';
        }
        if (option.indexOf('QFX') >= 0) {
            $scope.options.downloadPdfTypeSelected = option;
            $scope.options.outputType = 'QFX';
        }
        if (option.indexOf('NACHA') >= 0) {
            $scope.options.outputType = 'NACHA';
        }
        sendDownload();
    };

    init();
}
