/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    EnableMobileUiRequestDto,
    EnableMobileUiResponseDto,
    GetInstitutionAboutResponseDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class InstitutionClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionAbout
     * @summary Returns about information about the institution based on fi id.
     * @request GET:/institution/{fiId}/about
     */
    institutionAbout = (fiId: string, params: RequestParams = {}) =>
        this.http.request<GetInstitutionAboutResponseDto, any>({
            path: `/institution/${fiId}/about`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionEnableMobileUi
     * @summary Checks if the FI has the PWA mobile Ui enabled.
     * @request POST:/institution/pwamobileui
     */
    institutionEnableMobileUi = (
        enableMobileUiRequest: EnableMobileUiRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<EnableMobileUiResponseDto, any>({
            path: `/institution/pwamobileui`,
            method: 'POST',
            body: enableMobileUiRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
