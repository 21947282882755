import accountingNotation from './accountingNotation.formatter.js';

export default function amountRangeAccountingNotation(value) {
    if (!Array.isArray(value)) {
        return '';
    }
    const type = value[0];
    if (type === 'specific') {
        return accountingNotation(value[1]);
    }
    return `$${accountingNotation(value[1] ?? 0)} - $${accountingNotation(value[2])}`;
}
