import OmegaDialog from '@treasury/omega/components/omega-dialog.js';
import { css, html } from 'lit';

import enter from './oob-registration-dialog-states/enter.js';
import selectPreference from './oob-registration-dialog-states/select-preference.js';
import verify from './oob-registration-dialog-states/verify.js';

export default class OobRegistrationDialog extends OmegaDialog {
    static get properties() {
        return {
            ...super.properties,
            state: Object,
            verificationMethod: String,
            isLoading: Boolean,
            client: Object,
            phoneNumber: String,
            oneTimePassword: String,
        };
    }

    constructor() {
        super();
        this.state = enter;
        this.useForBoth = false;
        this.serverResponse = {};
        this.oneTimePassword = '';
    }

    firstUpdated() {
        this.preferredMethod = this.verificationMethod.id;
    }

    get title() {
        return this.state.title;
    }

    get isOneTimePasswordValid() {
        return this.oneTimePassword.length === this.verificationMethod.oneTimePasswordLength;
    }

    requestOtherContactMethod() {
        this.close('request-other-method');
    }

    async sendOneTimePassword() {
        this.isLoading = true;
        const response = await this.client.sendOneTimePassword(
            this.phoneNumber.digits,
            this.verificationMethod.id,
            this.useForBoth
        );
        if (response.status === 'Success') {
            this.state = verify;
        } else {
            this.state = enter;
            this.errorMessage = response.message;
        }
        this.isLoading = false;
    }

    async verify() {
        this.isLoading = true;
        const response = await this.client.validateOneTimePassword(this.oneTimePassword);

        if (response.status === 'Success') {
            this.state = selectPreference;
            this.verificationMethod.isVerified = true;
            this.verificationMethod.phoneNumber = this.phoneNumber.copy();
            if (this.useForBoth) {
                this.verificationMethod.other.isVerified = true;
                this.verificationMethod.other.phoneNumber = this.phoneNumber.copy();
            }
        } else {
            this.state = enter;
            this.errorMessage = response.message ?? 'Error!';
        }
        this.isLoading = false;
    }

    async finish() {
        this.isLoading = true;
        await this.client.setOutOfBandPreferredMethod(this.preferredMethod);
        this.isLoading = false;
        this.close('success');
    }

    renderHeader() {
        return html`
        <div class="dialog-header">
            <span class="dialog-header-title">${this.title}</span>
                <omega-button
                    class="omega-dialog-menu-button close-button"
                    type="icon"
                    icon="times"
                    hideLabel="always"
                    @click=${this.toggle}
                >
                    Close
                </omega-button>
            </span>
        </div>`;
    }

    renderBody() {
        return html`<div class="body">${this.state.renderBody(this)}</div> `;
    }

    renderActions() {
        return html` <footer>${this.state.renderActions(this)}</footer> `;
    }

    static get styles() {
        return [
            super.styles,
            css`
                :host {
                    --omega-primary: var(--brand-color);
                }
                .omega-dialog {
                    min-width: 520px;
                    border-radius: 0px;
                    padding: 0;
                }

                .dialog-header {
                    display: flex;
                    border-bottom: 1px solid #ccc;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;
                }
                .dialog-header-title {
                    font-size: 22px;
                    font-weight: 600;
                    color: var(--omega-text-default);
                }

                .body {
                    padding: 15px;
                }

                footer {
                    width: 100%;
                    border-top: 1px solid #ccc;
                    padding: 8px;
                }
            `,
        ];
    }
}

window.customElements.define('oob-registration-dialog', OobRegistrationDialog);
