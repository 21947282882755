import { html } from 'lit';

export function renderReportInfo(title: string) {
    return html`<div style="max-width: 225px">
        <p>
            <strong>${title}</strong> - Includes all recipients. Utilize the search feature to
            review recipients previously submitted.
        </p>
    </div>`;
}
