/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { FiCorpayProductModelDto, FiCorpayProductResponseModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class CorpayProductFeatureConfigurationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsGetCorpayProductFeature
     * @summary Provides the endpoint to get the corpay product feature configuration for the financial institution.
     * @request GET:/corpayProductFeatureConfigurations
     */
    productFeatureConfigurationsGetCorpayProductFeature = (params: RequestParams = {}) =>
        this.http.request<FiCorpayProductModelDto, any>({
            path: `/corpayProductFeatureConfigurations`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsSaveCorpayProductFeature
     * @summary Provides the endpoint to save the corpay product feature configuration for the financial institution.
     * @request POST:/corpayProductFeatureConfigurations
     */
    productFeatureConfigurationsSaveCorpayProductFeature = (
        fiCorpayProductModel: FiCorpayProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<FiCorpayProductResponseModelDto, any>({
            path: `/corpayProductFeatureConfigurations`,
            method: 'POST',
            body: fiCorpayProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
