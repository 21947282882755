import { v4 as uuid } from 'uuid';

export default () => ({
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {
        controller: '=',
        disableNavigation: '=',
        wizardStep: '=',
    },
    template: require('./wizardTemplate.html'),
    controller: WizardController,
});

WizardController.$inject = ['$scope', '$element', '$timeout', '$compile'];

function WizardController($scope, $element, $timeout, $compile) {
    $scope.goToStep = goToStep;
    $scope.step = null;
    $scope.steps = [];

    $scope.isNavigationEnabled = isNavigationEnabled;
    $scope.isPreviousStep = isPreviousStep;
    $scope.isSelected = isSelected;
    $scope.isSelectedIndex = isSelectedIndex;
    $scope.wasPreviouslySelected = wasPreviouslySelected;
    this.addStep = addStep;

    function addStep(stepScope) {
        $scope.steps.push(stepScope);

        // Select the first step. Hide the other steps.
        if ($scope.steps.length === 1) {
            stepScope.visible = true;
            $scope.step = stepScope;
        } else {
            stepScope.visible = false;
        }
    }

    //
    // Public interface

    if ($scope.controller) {
        $scope.controller.duplicatePreventionId = uuid();

        $scope.controller.goToNextStep = goToNextStep;
        $scope.controller.goToFirstStep = goToFirstStep;
        $scope.controller.goToPreviousStep = goToPreviousStep;
        $scope.controller.currentStep = currentStep;
        $scope.controller.goToStep = goToStep;
    }

    function currentStep() {
        return $scope.step;
    }

    function goToNextStep() {
        const index = $scope.steps.indexOf($scope.step);
        if (index < $scope.steps.length - 1) {
            goToStep(index + 1);
        }
    }

    function goToFirstStep() {
        goToStep(0);
    }

    function goToPreviousStep() {
        const index = $scope.steps.indexOf($scope.step);
        if (index > 0) {
            goToStep(index - 1);
        }
    }

    function isNavigationEnabled() {
        if (!$scope.disableNavigation) {
            return true;
        }

        return $scope.disableNavigation.indexOf($scope.step.name) === -1;
    }

    //
    // Template interface

    function goToStep(stepIndex, event) {
        if (event) {
            event.preventDefault();
        }

        if (event && !wasPreviouslySelected($scope.steps[stepIndex])) {
            return;
        }

        if (event && !isNavigationEnabled()) {
            return;
        }

        if (stepIndex < 0 || stepIndex > $scope.steps.length) {
            return;
        }

        window.angular.forEach($scope.steps, step => {
            step.visible = false;
        });
        $scope.step = $scope.steps[stepIndex];
        $scope.step.visible = true;
    }

    function isSelected(step) {
        if ($scope.controller) {
            return step === $scope.step;
        }
        const index = $scope.steps.indexOf(step);
        return ($scope.wizardStep ? $scope.wizardStep : 1) === index + 1;
    }

    function isSelectedIndex(stepIndex) {
        return $scope.steps[stepIndex] === $scope.step;
    }

    function wasPreviouslySelected(step) {
        return stepIsLessThan(step, $scope.step);
    }

    function isPreviousStep(index) {
        if ($scope.steps && index < $scope.steps.length - 1) {
            return isSelected($scope.steps[index + 1]);
        }
        return false;
    }

    //
    // Private

    function stepIsLessThan(step, referenceStep) {
        const index = $scope.steps.indexOf(step);
        const referenceIndex = $scope.steps.indexOf(referenceStep);
        return index < referenceIndex;
    }

    function stepIsGreaterThanOrEqualTo(step, referenceStep) {
        const index = $scope.steps.indexOf(step);
        const referenceIndex = $scope.steps.indexOf(referenceStep);
        return index > referenceIndex;
    }
}
