export interface Entitlement {
    id?: number;
    permission: string;
    updatedBy?: string | null;
    updatedDate?: string | null;
}

export const Entitlements = {
    Ach: {
        AllowChildSupportPayments: {
            permission: 'Feature.ACH.AllowChildSupportPayments',
        },
        AllowPrefunding: {
            permission: 'Feature.ACH.AllowPrefunding',
        },
        AllowUnbalancedPayments: {
            permission: 'Feature.ACH.AllowUnbalancedPayments',
        },
        PartialEditTemplate: { permission: 'partial edit ach template' },
        FullEditTemplate: { permission: 'full edit ach template' },
        PartialEditPayment: { permission: 'ACH, Payment, Partial Edit' },
        FullEditPayment: { permission: 'ACH, Payment, Full Edit' },
        RestrictPayment: { permission: 'Restricted Batch' },
        AllowOnUsAccessManagementFeature: { permission: 'Feature.ACH.AllowOnUsAccessManagement' },
    },
    Wire: {
        ApproveFxWirePayment: { permission: 'Approve DLI Wire Payment' },
    },
};

export default Entitlements;
