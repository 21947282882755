import { FieldType, string } from '@treasury/FDL';
import noPunctuation from './lib/validators/noPunctuation.validator.js';

export const companyId = string.with.label('Company ID');

export const loginId = string.with.label('Login ID');

export const password = string.with.label('Password').and.type('password');

export const name = string.with
    .minColumnWidth(300)
    .and.targetColumnWidth(320)
    .and.maxColumnWidth(400)
    .and.defaultValue('');

export const userName = string.with
    .cellClass('userName')
    .and.minColumnWidth(115)
    .and.targetColumnWidth(150)
    .and.maxColumnWidth(190)
    .and.defaultValue('');

export const userIdentification = new FieldType().with
    .cellClass('userIdentification')
    .and.validator(noPunctuation)
    .and.minColumnWidth(115)
    .and.targetColumnWidth(150)
    .and.maxColumnWidth(190);

export const userType = new FieldType().with
    .cellClass('userType')
    .and.validator(noPunctuation)
    .and.minColumnWidth(130)
    .and.targetColumnWidth(130)
    .and.maxColumnWidth(130);

export const userActivity = new FieldType().with
    .cellClass('userActivity')
    .and.minColumnWidth(50)
    .and.targetColumnWidth(150)
    .and.maxColumnWidth(250);
