import { css, html, LitElement, nothing } from 'lit';

export default class OmegaAdditionalInfoComplex extends LitElement {
    static get properties() {
        return {
            label: String,
            values: Array,
            activePropertyName: String,
            lastActiveItem: Number,
            invalidCount: Number,
            maxlength: Number,
        };
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-additional-info--complex',
        };
    }

    firstUpdated() {
        this.checkValidity(this.shadowRoot.querySelector('#default-row'));
    }

    updated(changedProperties) {
        if (changedProperties.has('values') && this.values.length === 0) {
            this.values = [
                {
                    institutionCheckExceptionReturnReasonUniqueId: '',
                    description: '',
                    isDeleted: false,
                    isDefault: true,
                },
                {
                    institutionCheckExceptionReturnReasonUniqueId: '',
                    description: '',
                    isDeleted: false,
                    isDefault: false,
                },
            ];
        }
    }

    addARow() {
        const newItem = { isDeleted: false, isDefault: false };
        newItem[this.activePropertyName] = '';
        this.values = [...this.values, newItem];
        this.dispatchChangeEvent();
    }

    deleteRow(index) {
        this.values[index].isDeleted = true;
        let count = 0;
        this.values = this.values.map(value => {
            if (this.shouldRenderAsAdditional(value)) {
                count++;
            }
            return value;
        });
        if (!count) {
            this.addARow();
        }
        this.dispatchChangeEvent();
    }

    updateRow(index, target) {
        this.values = this.values.map((v, i) => {
            if (i === index) {
                const newVal = {
                    ...v,
                };
                newVal[this.activePropertyName] = target.value;
                return newVal;
            }
            return v;
        });
        this.checkValidity(target);
        this.dispatchChangeEvent();
    }

    checkValidity(target) {
        const isValid = target.checkValidity();
        if (isValid === false) {
            target.setAttribute('aria-invalid', true);
        } else {
            target.removeAttribute('aria-invalid');
        }
        this.invalidCount = this.shadowRoot.querySelectorAll('input[aria-invalid]').length;
        this.dispatchChangeEvent();
    }

    getLastActiveItem() {
        this.values.forEach((value, index) => {
            if (this.shouldRenderAsAdditional(value)) {
                this.lastActiveItem = index;
            }
        });
    }

    shouldRenderAsDefault(value) {
        return value.isDefault && !value.isDeleted;
    }

    shouldRenderAsAdditional(value) {
        return !value.isDefault && !value.isDeleted;
    }

    dispatchChangeEvent() {
        this.dispatchEvent(
            new CustomEvent('change', {
                detail: { values: this.values, isValid: this.invalidCount === 0 },
            })
        );
    }

    renderDefaultRow(index) {
        return html`<li>
            <div class="input-cell">
                <input
                    id="default-row"
                    required
                    label="additional-info-item-${index + 1}"
                    maxlength=${this.maxlength}
                    .value=${this.values[index][this.activePropertyName]}
                    @input=${e => this.updateRow(index, e.target)}
                    @blur=${e => this.checkValidity(e.target)}
                ></input>
            </div>
        </li>`;
    }

    renderAdditionalRow(index) {
        const deleteButton =
            this.values.length === 1
                ? nothing
                : html`<omega-button
                      type="icon"
                      icon="trash"
                      x-small
                      @click=${() => this.deleteRow(index)}
                      hideLabel="always"
                      >Remove</omega-button
                  >`;
        const addButton =
            index === this.lastActiveItem
                ? html`<omega-button
                      type="icon"
                      icon="plus"
                      x-small
                      @click=${this.addARow}
                      hideLabel="always"
                      >Add</omega-button
                  >`
                : nothing;

        return html`<li>
            <div class="input-cell">
                <input
                    required
                    label="additional-info-item-${index + 1}"
                    maxlength=${this.maxlength}
                    .value=${this.values[index][this.activePropertyName]}
                    @input=${e => this.updateRow(index, e.target)}
                    @blur=${e => this.checkValidity(e.target)}
                />
            </div>
            <div>${addButton}</div>
            <div>${deleteButton}</div>
        </li>`;
    }

    renderDefaultRows() {
        return this.values.map((value, index) =>
            this.shouldRenderAsDefault(value) ? this.renderDefaultRow(index) : ''
        );
    }

    renderAdditionalRows() {
        this.getLastActiveItem();
        return this.values.map((value, index) =>
            this.shouldRenderAsAdditional(value) ? this.renderAdditionalRow(index) : ''
        );
    }

    render() {
        // if (!this.field) return html`<div>loading</div>`;
        return html`<div class="additional-info">
            <label class="label">Default <span class="required-indicator">&nbsp;*</span></label>
            <ul>
                ${this.renderDefaultRows()}
            </ul>
            <label class="label">Additional</label>
            <ul>
                ${this.renderAdditionalRows()}
            </ul>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
            }

            ul {
                display: flex;
                flex-direction: column;
                flex: 1;
                border-collapse: collapse;
                padding: 0;
                margin: 0;
                list-style-type: none;
                padding-bottom: 12px;
            }
            li {
                flex: 0;
                display: flex;
                align-items: center;
                padding: 0;
                margin: var(--omega-input-margin, 8px) 0;
            }
            li:first-of-type {
                margin-top: 0;
            }
            li:last-of-type {
                margin-bottom: 0;
            }

            li > div {
                flex: 0;
                margin: 0;
                padding: 0;
                display: flex;
            }

            .input-cell {
                flex-grow: 1;
                flex: 1;
            }
            .label {
                padding-bottom: 6px;
            }
            .additional-info {
                display: flex;
                flex-direction: column;
            }
            .required-indicator {
                color: var(--omega-error);
            }
            input {
                width: 100%;
                height: 32px;
                line-height: 34px;
                border: 1px solid var(--omega-input-default-border);
                padding: 0px 10px;
                border-radius: var(--omega-input-border-radius);
                font-size: var(--omega-input);
            }
            input:active {
                border-color: var(--omega-input-active-border);
            }
            input[aria-invalid='true'] {
                /* outline: 1px solid var(--omega-input-error-border); */
                border: 2px solid var(--omega-input-error-border);
                outline: none;
            }
            input:disabled {
                /* matching omega-select and datepicker */
                /* border-color: var(--omega-input-disabled-border); */
                /* background-color: var(--omega-grayed-out); */
                opacity: 0.6;
                background: var(--omega-white);
            }
        `;
    }
}

customElements.define('omega-additional-info-complex', OmegaAdditionalInfoComplex);
