const isValidRepeatDay1BeforeRepeatDay2 = (frequency: any) => {
    if (!frequency) return false;

    const { repeatOnDay1, repeatOnDay2 } = frequency;

    if (repeatOnDay2) {
        // if (logging) console.log(`repeat day 1 is before day 2: ${result}`);

        return Number.parseInt(repeatOnDay1) < Number.parseInt(repeatOnDay2);
    }

    return true;
};

export default isValidRepeatDay1BeforeRepeatDay2;
