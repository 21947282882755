export const rangeValidator = {
    name: 'validate-range',
    validate: v => {
        if (!Array.isArray(v)) return true;
        const [type, start, end] = v;
        if (!type?.toLowerCase().includes('range')) return true;
        if (!Number(start) && !Number(end)) return true;
        return Number(start) < Number(end);
    },
};
