// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant(
    'dashboardUserInteractions',
    Object.freeze({
        viewedInformationCenterMessage: 1,
        viewedResource: 2,
    })
);
