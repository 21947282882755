import { AchPaymentWithBatchesModelDto } from '@treasury/api/channel';
import { mapNachaBatchUploadResponseToPaymentValues } from '../../mappings/ach/ach-domestic-payment-mappers';
import {
    mapPaymentPostRequestDto,
    mapPaymentRecipients,
} from '../../mappings/ach/ach-domestic-payments.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchDomesticRequests from '../../requests/ach/ach-domestic-requests.js';
import {
    AchBatchDetail,
    AchBatchDraftRequestDto,
    AchBatchRequestDto,
    AchBatchSummary,
    AchCompany,
    AchDomesticPaymentCreateResponseDto,
    BatchDetails,
    NachaUploadBatchData,
    NachaUploadPaymentDto,
    OffsetAccount,
    PaymentHeader,
    PaymentRecipient,
} from '../../types/ach';
import { getBatchDetailFromRequest, mapBatchPostRequest } from './batch-mappers';

export class AchDomesticServices {
    static async getAchBanks() {
        return AchCompanyRequests.getAchBanks();
    }

    static async getOnUsAchBanks() {
        const banks = await AchDomesticServices.getAchBanks();
        return banks.filter((bank: any) => bank.onUs === true);
    }

    static async getAchCompanies() {
        const companies: AchCompany[] = await AchCompanyRequests.getAchCompanies();
        return companies.filter(
            (company: any) =>
                // company.batchBalanceRequirements === 'Unbalanced - Full Offset' &&
                company.secCodes[0].code !== 'IAT' // &&
            // company.notOnUsTransactionTypes === 'Credit Only'
        );
    }

    static async getAchPayment(paymentId: number) {
        const response = await AchDomesticRequests.getAchPayment(paymentId);
        const banks = await AchDomesticServices.getAchBanks();
        response.payment.recipients = mapPaymentRecipients(response.payment, banks);
        // Services is working on a feature to fix the missing offset account number for unbalanced prefunded ACH company payments(related to #534785)  Once fixed this needs to be removed so that the offset account at the time of the payment is used and not the current ACH company offset account number used below(and in the old angular view)
        const achCompanies = await AchCompanyRequests.getAchCompanies();
        const achCompany = achCompanies.find(
            (company: AchCompany) => company.id === response.payment.achCompany.id
        );
        if (achCompany) {
            response.payment.achCompany = {
                ...response.payment.achCompany,
                offsetAccountNumber: achCompany.offsetAccountNumber,
                prefundingDays: achCompany.prefundingDays,
                allowUnbalancedPayments: achCompany.allowUnbalancedPayments,
            };
        }

        // When an offset account is editable the payment response model for offset account does not match the model used by the record.  This retrieves the full model for populating the selection input.  This should not be necessary after the Services team updates the endpoint(related to #534785)
        const offsetAccounts = await AchCompanyRequests.getAchCompanyOffsetAccounts(
            response.payment.achCompany.id
        );
        const offsetAccount = offsetAccounts.find(
            (account: OffsetAccount) =>
                account.number ===
                (response.payment.offsetAccount?.value ??
                    response.payment.achCompany.offsetAccountNumber)
        );
        if (offsetAccount) {
            response.payment.offsetAccount = offsetAccount;
        }

        return response;
    }

    static async getPaymentDraft(draftId: number, batchName: string): Promise<[AchBatchDetail]> {
        const body: AchBatchDraftRequestDto = [{ key: draftId, value: batchName }];

        const response = (await AchDomesticRequests.getPaymentDraft(body)) as AchBatchRequestDto;

        return [getBatchDetailFromRequest(response)[0]];
    }

    static async getPaymentDrafts(
        data: Array<{ draftId: number; batchName: string }>
    ): Promise<AchBatchDetail[]> {
        const body = data.map(draft => ({ key: draft.draftId, value: draft.batchName }));

        const response = (await AchDomesticRequests.getPaymentDraft(body)) as AchBatchRequestDto;

        return getBatchDetailFromRequest(response);
    }

    static async createPayment(header: PaymentHeader, recipientsArray: Array<PaymentRecipient>) {
        const body = mapPaymentPostRequestDto(header, recipientsArray);
        return AchDomesticRequests.createPayment(body);
    }

    static async createBatch(
        summary: AchBatchSummary,
        batchDetails: Array<BatchDetails>
    ): Promise<AchDomesticPaymentCreateResponseDto> {
        const body = mapBatchPostRequest(summary, batchDetails);
        return AchDomesticRequests.createPayment(body);
    }

    static async editPayment(header: PaymentHeader, recipientsArray: Array<PaymentRecipient>) {
        const body = mapPaymentPostRequestDto(header, recipientsArray);
        return AchDomesticRequests.updatePayment(body);
    }

    static async isTemplateNameValid(name: string) {
        return AchDomesticRequests.isTemplateNameValid(name);
    }

    static async editTemplate(...args: any) {
        return Promise.resolve(true);
    }

    static async uploadNachaFile(nachaData: File): Promise<NachaUploadBatchData> {
        const response: NachaUploadPaymentDto =
            await AchDomesticRequests.uploadNachaFile(nachaData);

        // The response does not contain the current ACH Company data so it must be fetched separately and overwritten here. Working with Services to fix on there end and then can remove extra call here. DevOps #542062
        const achCompanies = await AchCompanyRequests.getAchCompanies();

        if (response.achBatchSummaries) {
            response.achBatchSummaries?.forEach((payment, i) => {
                const achCompany = achCompanies.find(
                    (company: AchCompany) => company.id === payment.achCompany.id
                );
                if (achCompany) {
                    response.achBatchSummaries![i].achCompany = {
                        ...response.achBatchSummaries![i].achCompany,
                        offsetAccountNumber: achCompany.offsetAccountNumber,
                        prefundingDays: achCompany.prefundingDays,
                        allowUnbalancedPayments: achCompany.allowUnbalancedPayments,
                        notOnUsTransactionTypes: achCompany.notOnUsTransactionTypes,
                    };
                }
            });
        }

        return mapNachaBatchUploadResponseToPaymentValues(response);
    }

    static async initiateFromTemplate(templates: Array<{ value: string; key: string }>) {
        const response: AchPaymentWithBatchesModelDto =
            await AchDomesticRequests.initiateFromTemplate(templates);

        // The response does not contain the current ACH Company data so it must be fetched separately and overwritten here. Working with Services to fix on there end and then can remove extra call here. DevOps #542062
        const achCompanies = await AchCompanyRequests.getAchCompanies();

        const achCompany = achCompanies.find(
            (company: AchCompany) => company.id === response.achCompany.id
        );
        if (achCompany) {
            response.achCompany = {
                ...response.achCompany,
                offsetAccountNumber: achCompany.offsetAccountNumber,
                prefundingDays: achCompany.prefundingDays,
                allowUnbalancedPayments: achCompany.allowUnbalancedPayments,
                notOnUsTransactionTypes: achCompany.notOnUsTransactionTypes,
            };
        }
        return response;
    }
}
