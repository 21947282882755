import { html, LitElement } from 'lit';

export class Quarterly extends LitElement {
    static get properties() {
        return {
            frequency: Object,
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
            isValidStartDate: Function,
            isValidEndDate: Function,
            isStartDateInvalid: Function,
            isEndDateInvalid: Function,
        };
    }

    setFrequency(e) {
        this.frequency = e.detail;
        this.dispatchEvent(new CustomEvent('change', { detail: e.detail, bubbles: false }));
    }

    render() {
        return html`
            <omega-frequency-start-and-end
                .frequency=${this.frequency}
                .isStartDateInvalid=${this.isStartDateInvalid}
                .isEndDateInvalid=${this.isEndDateInvalid}
                @change=${this.setFrequency}
                ?disabled=${this.disabled}
                ?readonly=${this.readOnly}
            ></omega-frequency-start-and-end>
            <em>Quarterly is defined as processing on the same day every 3 months</em>
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define('omega-frequency-quarterly', Quarterly);
export default Quarterly;
