/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-classes-per-file */
import '@treasury/omega/components/omega-alert';
import '@treasury/omega/components/omega-icon';
import '@treasury/omega/components/progress/omega-progress';
import '../../../../components/blocking-loader';
import '../../shared/templates/report-top.template';

import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { IssuedItemSaved } from '@treasury/domain/channel/mappings/arp';
import { Recordset, RecordsetEvent } from '@treasury/FDL';
import { OmegaTableDetailRowFunction } from '@treasury/omega/types';
import { LitPropertyVerifier } from '@treasury/utils/lit-helpers';
import { Action } from '@treasury/utils/types';

import { confirmStepColumns } from '../data';

interface ResultPayload {
    successMessage?: string;
    failureMessage?: string;
}
type ConfirmStepResultEvent = CustomEvent<ResultPayload>;

interface ConfirmationStepConfig {
    recordset: Recordset<IssuedItemSaved>;
    onRestart: Action;
    onResult: (results: ResultPayload) => void;
}

export function renderConfirmationStep(config: ConfirmationStepConfig) {
    const { recordset, onResult, onRestart } = config;

    return html`<issued-items-creation-confirm-step
        .recordset=${recordset}
        .onRestart=${onRestart}
        @result=${(e: ConfirmStepResultEvent) => onResult(e.detail)}
    ></issued-items-creation-confirm-step>`;
}

type DetailRowParams = Parameters<OmegaTableDetailRowFunction<IssuedItemSaved>>;
@customElement('issued-items-creation-confirm-step')
class IssuedItemsCreationConfirmationStep extends LitPropertyVerifier {
    @InjectProperty()
    private declare readonly analyticsService: AnalyticsService;

    @property({
        type: Object,
    })
    public recordset!: Recordset<IssuedItemSaved>;

    @property({
        attribute: false,
    })
    public onRestart!: Action;

    protected readonly verifiedPropNames: (keyof this)[] = ['recordset', 'onRestart'];

    @state()
    private loading = true;

    @state()
    private hasRequestError = false;

    private readonly unsubscribeHandles: Action[] = [];

    private get savedItems() {
        return this.recordset.allRecords.map(r => r.values);
    }

    private get failedItems() {
        return this.savedItems.filter(item => item.hasFailed);
    }

    private get submittedItems() {
        return this.savedItems.filter(item => !item.hasFailed);
    }

    protected async firstUpdated(changedProperties: Map<string | number | symbol, unknown>) {
        super.firstUpdated(changedProperties);

        this.loading = true;

        const handle = this.recordset.listen(RecordsetEvent.Error, () => {
            this.hasRequestError = true;
        });

        this.unsubscribeHandles.push(handle.unsubscribe);

        await this.recordset.update();
        // hack to open detail row for records with errors without having a dedicated column
        this.recordset.allRecords.forEach(r => {
            const item = r.values;
            r.isExpanded = item.hasFailed;
        });

        this.trackAnalytics();
        this.loading = false;
        this.dispatchEvent(
            new CustomEvent<ResultPayload>('result', {
                detail: {
                    successMessage: this.successMessage,
                    failureMessage: this.failureMessage,
                },
            })
        );
    }

    public disconnectedCallback() {
        // eslint-disable-next-line wc/guard-super-call
        super.disconnectedCallback();
        this.unsubscribeHandles.forEach(unsubscribe => unsubscribe());
    }

    private get successMessage() {
        const recordCount = this.submittedItems.length;
        if (recordCount < 1) {
            return undefined;
        }

        const itemVerbiage = recordCount > 1 ? 'items' : 'item';

        return `Successfully processed ${recordCount} Positive Pay ${itemVerbiage}.`;
    }

    private get failureMessage() {
        const recordCount = this.failedItems.length;
        if (recordCount < 1) {
            return undefined;
        }

        const itemVerbiage = recordCount > 1 ? 'items' : 'item';

        return `${recordCount} Positive Pay ${itemVerbiage} failed.`;
    }

    private renderDetailRow(record: DetailRowParams[0]) {
        const item = record.values;

        if (!item.error) {
            return nothing;
        }

        return html`<issued-items-confirmation-step-detail-row
            .message="${item.error.errorMessage}"
        ></issued-items-confirmation-step-detail-row>`;
    }

    private trackAnalytics() {
        const totalAmount = this.submittedItems.reduce(
            (total, item) => total + item.checkAmount,
            0
        );

        this.analyticsService.track(AnalyticsEvent.IssuedItemsCreated, {
            itemCount: this.submittedItems.length,
            totalAmount,
        });
    }

    public renderTable() {
        if (this.hasRequestError) {
            return nothing;
        }

        return html`<omega-table
            .recordset=${this.recordset}
            .columnDefinitions=${confirmStepColumns}
            .detailRowTemplateFunction=${(record =>
                this.renderDetailRow(record)) as OmegaTableDetailRowFunction<IssuedItemSaved>}
        ></omega-table>`;
    }

    protected render() {
        if (this.loading) {
            return html`<blocking-loader></blocking-loader>`;
        }

        return html`${this.renderTable()}
            <omega-button-bar position="bottom" alignment="left">
                <omega-button @click=${this.onRestart} type="link"
                    >Upload/Enter Additional Issued Items</omega-button
                >
            </omega-button-bar>`;
    }
}

/**
 * A class wouldn't ordinarily be used for something as lightweight as this,
 * but the detail row styles won't pierce through Omega table's shadow root
 * in the parent component, so they must be defined in their own component.
 */
@customElement('issued-items-confirmation-step-detail-row')
class IssuedItemsConfirmationStepDetailRow extends LitElement {
    @property({
        type: String,
    })
    public readonly message = '';

    public render() {
        return html`<omega-icon icon="warning"></omega-icon>${this.message}`;
    }

    static styles = css`
        :host {
            padding: 5px;
            display: block;
        }

        omega-icon {
            padding: 5px;
            color: var(--omega-error);
        }
    `;
}
