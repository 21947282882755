import { UserModelDto } from '@treasury/api/channel';
import { OmegaListItem } from '@treasury/omega/view-models';

export type UserModelListItem = OmegaListItem<UserModelDto>;
export function createUserModelListItem(dto: UserModelDto): UserModelListItem {
    return {
        value: dto,
        text: `${dto.name}: ${dto.loginID}` || '',
    };
}
