import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services/entitlements';
import { css, html, LitElement, nothing } from 'lit';
import '../../tables/wire-table.js';
import { workflowStyles } from '../workflow.styles.js';

export class WireUploadConfirmation extends LitElement {
    static get properties() {
        return {
            confirming: { type: Boolean, reflect: true },
            institution: Number,
            records: Array,
            accountConfiguration: Object,
            filters: Array,
            wireFileUniqueId: String,
        };
    }

    constructor() {
        super();
        this.isFeatureWireSftpEnabled = false;
        this.setFeatureFlags();
    }

    static get is() {
        return 'wire-upload-confirmation';
    }

    async setFeatureFlags() {
        this.isFeatureWireSftpEnabled = await EntitlementsService.instance.hasEntitlement(
            'Feature.Wire.SFTP.DataTransmissions'
        );
    }

    _routeToUploadStep() {
        this.dispatchEvent(
            new CustomEvent('stepChange', { bubbles: true, composed: true, detail: { value: 0 } })
        );
    }

    async _routeToWireFileActivity() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        navService.navigate('payables.wire.wire-file-activity');
    }

    async _routeToWireActivity() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        navService.navigate('payables.wire.wire-list');
    }

    renderUploadButton() {
        if (this.wireFileUniqueId) return nothing;
        return html`<omega-button type="secondary" @click=${this._routeToUploadStep}>
            Upload Wire File
        </omega-button>`;
    }

    render() {
        const wireActivityButtonText = this.isFeatureWireSftpEnabled
            ? 'Wire Payment Activity'
            : 'Wire Activity';
        return html`
            <slot></slot>
            <wire-table
                readonly
                .records=${this.records}
                .accountConfiguration=${this.accountConfiguration}
                .filters=${this.filters}
            ></wire-table>

            <omega-button-bar alignment="left" position="bottom">
                ${this.renderUploadButton()}
                <omega-button type="link" @click=${this._routeToWireFileActivity}>
                    Wire File Activity
                </omega-button>
                <omega-button type="link" @click=${this._routeToWireActivity}>
                    ${wireActivityButtonText}
                </omega-button>
            </omega-button-bar>
        `;
    }

    static get styles() {
        return [
            workflowStyles,
            css`
                :host {
                    display: block;
                    position: relative;
                    height: 100%;
                }
            `,
        ];
    }
}

window.customElements.define(WireUploadConfirmation.is, WireUploadConfirmation);
export default WireUploadConfirmation;
