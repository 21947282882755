import { Injectable } from '@jack-henry/frontend-utils/di';
import { exists, noOp, WindowService } from '@treasury/utils';
import { getAngularInjector } from '@treasury/utils/functions/angular.helpers';
import {
    Ng1StateDeclaration,
    RawParams,
    StateDeclaration,
    StateParams,
    StateService,
    TransitionOptions,
    TransitionService,
    UIRouter,
} from '@uirouter/angularjs';
import { NavigationService } from './navigation.service';

type OtherWiseHandler = (location: Location) => string | void;

/**
 * TM wrapper for [Angular UI Router](https://ui-router.github.io/ng1/docs/latest/classes/core.uirouter.html)
 * implementation fulfilling the `NavigationService` contract.
 */
@Injectable()
export class LegacyNavigationService extends NavigationService<TransitionOptions> {
    constructor(window: WindowService) {
        super(window);
        this.routeDelimiter = '.';
        this.prefixRoutes = false;

        // listen for route transitions to account for alternative methods like ui-sref links
        this.listenForTransitions();
    }

    private async getStateService() {
        const ngInjector = await getAngularInjector();

        return ngInjector.get<StateService>('$state');
    }

    private async getTransitionService() {
        const ngInjector = await getAngularInjector();

        return ngInjector.get<TransitionService>('$transitions');
    }

    private async getUiRouter() {
        const ngInjector = await getAngularInjector();

        return ngInjector.get<UIRouter>('$uiRouter');
    }

    private async getStateParams() {
        const ngInjector = await getAngularInjector();

        return ngInjector.get<StateParams>('$stateParams');
    }

    private async listenForTransitions() {
        const transitionService = await this.getTransitionService();

        transitionService.onSuccess({}, t => {
            t.promise.then((state: StateDeclaration) => {
                this.handleNavigationEvent();
            });
        });
    }

    protected async performNavigation<Params>(
        route: string,
        params?: Params | undefined,
        options?: TransitionOptions
    ) {
        const stateService = await this.getStateService();
        return stateService.go(route, params as RawParams, options).then(noOp);
    }

    protected async getActiveRoute() {
        const stateService = await this.getStateService();
        return stateService.$current.name;
    }

    protected async getUrlParams() {
        const params = await this.getStateParams();
        const normalizedParams: Record<string, any> = {};

        // prune null/empty params
        Object.keys(params).forEach(k => {
            if (exists(params[k])) {
                normalizedParams[k] = params[k];
            }
        });

        return normalizedParams;
    }

    public async reload() {
        const stateService = await this.getStateService();
        stateService.reload();
    }

    public async addStates(
        states: Record<string, Ng1StateDeclaration>,
        otherwise?: OtherWiseHandler | string
    ) {
        const { stateRegistry, urlService } = await this.getUiRouter();

        if (otherwise) {
            urlService.rules.otherwise(
                typeof otherwise === 'string' ? otherwise : () => otherwise(this.window.location)
            );
        }

        // assign name to state itself based on the dictionary key
        const stateList: Ng1StateDeclaration[] = Object.keys(states).map(name => ({
            ...states[name],
            name,
        }));

        stateList.forEach(state => {
            stateRegistry.register(state);
        });

        urlService.listen(true);
        urlService.sync();
    }
}
