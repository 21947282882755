import fieldTypes from '@treasury/policy/primitives';
import { FieldType } from '@treasury/FDL';
import { NewUserAccountDto } from '@treasury/domain/channel/types';
import { FdlFieldDefinitions } from '@treasury/FDL/record';

export const createUserAccountFields: FdlFieldDefinitions<NewUserAccountDto> = {
    name: fieldTypes.string.with
        .label('Name:')
        .thatHas.maxLength(35)
        .thatIs.required() as FieldType<string>,
    loginId: fieldTypes.string.with
        .label('Login ID:')
        .thatHas.maxLength(25)
        .thatIs.required() as FieldType<string>,
    department: fieldTypes.string.with.label('Department:') as FieldType<
        NewUserAccountDto['department']
    >,
    email: (fieldTypes.string as FieldType<string>).with
        .label('Email:')
        .thatIs.required()
        .thatHas.validator({
            name: 'email-format',
            validate: (value: string) => {
                if (!value) return true;
                const re =
                    /^[a-zA-Z0-9.!#$%&amp;’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
                return re.test(value);
            },
        }),
    phone: fieldTypes.phone.with.label('Phone:').thatIs.required() as FieldType<string>,
    extension: fieldTypes.string.with
        .label('Ext.')
        .thatHas.maxLength(10)
        .and.inputMask(/[0-9]/) as FieldType<NewUserAccountDto['extension']>,
    fax: fieldTypes.phone.with.label('Fax:') as FieldType<NewUserAccountDto['fax']>,
    isSuperUser: fieldTypes.boolean.with.label('Super User:').with.toggle().thatIs.inline(),
    isAdmin: fieldTypes.boolean.with.label('Admin:').with.toggle().thatIs.inline(),
};
