/* eslint-disable import/no-extraneous-dependencies */
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { fontAwesome } from '../css/icons/font-awesome.js';
import './progress/omega-progress.js';

class OmegaButton extends LitElement {
    static get properties() {
        return {
            type: { type: String, reflect: true },
            icon: { type: String, reflect: true },
            disabled: { type: Boolean, reflect: true },
            hideLabel: { type: String, reflect: true },
            posture: { type: String, reflect: true },
            block: { type: Boolean, reflect: true },
            loading: { type: Boolean, reflect: true },
            submit: { type: Boolean, reflect: true },
            noPrint: { type: Boolean },
            width: String,
        };
    }

    constructor() {
        super();
        this.type = 'secondary';
        this.disabled = false;
        this.addEventListener('click', event => this._handleClick(event));
        this.noPrint = true;
        /* this.hideLabel = 'always'; */
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-button--button',
        };
    }

    isLabelHidden() {
        return this.hideLabel === 'always';
    }

    _handleClick(event) {
        if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    }

    renderIcon() {
        return this.icon ? html`<i class="fa fa-${this.icon} icon" part="icon"></i>` : nothing;
    }

    renderButtonContents() {
        if (this.loading) return html`<omega-progress></omega-progress>`;
        return html`${this.renderIcon()}
            <slot name="icon"></slot>
            <span class=${classMap({ 'visually-hidden': this.isLabelHidden() })}>
                <slot></slot>
            </span>`;
    }

    render() {
        return html`
            <button
                class=${classMap({
                    small: this.posture === 'small',
                    labelled: !this.isLabelHidden(),
                    'no-print': this.noPrint,
                })}
                style=${styleMap({ width: this.width })}
                button-type=${this.type}
                ?disabled=${this.disabled || this.loading}
                ?block=${this.block}
                type=${ifDefined(this.submit ? 'submit' : undefined)}
            >
                ${this.renderButtonContents()}
            </button>
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: inline-block;
                    margin: var(--omega-button-margin, 8px);
                    --omega-button-min-width: 90px;
                }
                :host([block]) {
                    width: 100%;
                }
                button {
                    font-family: var(--omega-font);
                    min-width: var(--omega-button-min-width, 90px);
                    padding: var(--omega-button-padding, 8px 20px);
                    border: 1px solid var(--omega-page-background);
                    border-top-left-radius: var(--omega-button-border-radius-left, 3px);
                    border-top-right-radius: var(--omega-button-border-radius-right, 3px);
                    border-bottom-left-radius: var(--omega-button-border-radius-left, 3px);
                    border-bottom-right-radius: var(--omega-button-border-radius-right, 3px);
                    font-size: 13px;
                    font-weight: var(--omega-button-font-weight, 500);
                    text-decoration: var(--omega-button-text-decoration, none);
                    line-height: 16px;
                    cursor: var(--omega-button-cursor, pointer);
                    text-align: var(--omega-button-text-align, center);
                }
                button[block] {
                    width: 100%;
                }
                :host([loading]) {
                    opacity: 80%;
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[type='secondary'][disabled] {
                    background: #fff !important;
                    border-color: var(--omega-button-disabled-secondary-border-color) !important;
                    color: var(--omega-button-disabled-secondary-color);
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[type='primary'][disabled] {
                    background: #fff !important;
                    border-color: var(--omega-divider-color) !important;
                    color: var(--omega-text-header);
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[type='approve'][disabled] {
                    background: #fff !important;
                    border-color: var(--omega-divider-color) !important;
                    color: var(--omega-text-header);
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[type='reject'][disabled] {
                    background: #fff !important;
                    border-color: var(--omega-divider-color) !important;
                    color: var(--omega-text-header);
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[type='link'][disabled] {
                    background: transparent !important;
                    color: var(--omega-text-header);
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[type='icon'][disabled] {
                    background: #fff !important;
                    color: var(--omega-info);
                    cursor: not-allowed;
                }
                :host([loading]) button[type='link'] {
                    background: transparent !important;
                    border: 1px solid var(--omega-primary) !important;
                }
                button:enabled {
                    cursor: var(--omega-button-cursor, pointer);
                }
                button.small {
                    min-width: 0;
                    padding: 10px;
                    margin: 0 2px;
                    line-height: 8px;
                }
                button label {
                    text-align: center;
                }
                button[button-type='primary'] {
                    border: 1px var(--omega-primary-darken-100);
                    background-color: var(--omega-primary);
                    color: var(--omega-white);
                }
                button[button-type='primary']:hover:not([disabled]) {
                    border-color: var(--omega-primary-darken-100);
                    background-color: var(--omega-primary);
                    box-shadow: 0 0 4px 0 var(--omega-button-primary-box-shadow);
                }

                button[button-type='secondary'] {
                    border: 1px solid var(--omega-primary-lighten-100);
                    background-color: var(--omega-white);
                    color: var(--omega-primary);
                }
                button[button-type='secondary']:hover:not([disabled]) {
                    border-color: var(--omega-primary-lighten-100);
                    background-color: var(--omega-white);
                    box-shadow: 0 0 4px 0 var(--omega-button-secondary-box-shadow);
                }

                button[button-type='approve'] {
                    border: 1px var(--omega-success-darken-100);
                    background-color: var(--omega-success);
                    color: var(--omega-white);
                }
                button[button-type='approve']:hover:not([disabled]) {
                    border-color: var(--omega-success-darken-100);
                    background-color: var(--omega-success);
                    box-shadow: 0 0 4px 0 var(--omega-button-approve-box-shadow);
                }

                button[button-type='link'] {
                    color: var(--omega-primary);
                    background-color: transparent;
                    border: 1px solid transparent;
                }

                button[button-type='link']:hover:not([disabled]) {
                    color: var(--omega-primary-darken-100);
                }

                button[button-type='link']:disabled {
                    color: var(--omega-text-header);
                    cursor: not-allowed;
                }

                button[button-type='link']:focus {
                    border: none;
                    color: var(--omega-primary-darken-100);
                    outline: none;
                }

                button[button-type='link']:active {
                    background-color: var(--omega-primary-lighten-300);
                    outline: none;
                }

                button[button-type='reject'] {
                    border: 1px var(--omega-error-darken-100);
                    background-color: var(--omega-error);
                    color: var(--omega-white);
                }
                button[button-type='reject']:hover:not([disabled]) {
                    border-color: var(--omega-error-darken-100);
                    background-color: var(--omega-error);
                    box-shadow: 0 0 4px 0 var(--omega-button-reject-box-shadow);
                }
                button[button-type='primary'][disabled] {
                    border-color: var(--omega-primary-darken-100);
                    background-color: var(--omega-primary-lighten-100);
                    box-shadow: 0 0 4px 0 var(--omega-button-primary-box-shadow);
                    cursor: not-allowed;
                }
                button[button-type='secondary'][disabled] {
                    box-shadow: 0 0 4px 0 var(--omega-button-disabled-secondary-box-shadow);
                    border-color: var(--omega-button-disabled-secondary-border-color) !important;
                    color: var(--omega-button-disabled-secondary-color);
                    cursor: not-allowed;
                }

                button[button-type='approve'][disabled] {
                    background-color: var(--omega-success-lighten-100);
                    border-color: var(--omega-success-darken-100);
                    box-shadow: 0 0 4px 0 var(--omega-button-success-box-shadow);
                    cursor: not-allowed;
                }

                button[button-type='reject'][disabled] {
                    border-color: var(--omega-error-darken-100);
                    background-color: var(--omega-error-lighten-100);
                    box-shadow: 0 0 4px 0 var(--omega-button-reject-box-shadow);
                    cursor: not-allowed;
                }
                :host(:not([loading])) button[button-type='icon'][disabled] {
                    background: #fff !important;
                    color: var(--omega-info);
                    cursor: not-allowed;
                }

                button[button-type^='status-'] {
                    font-size: 11px;
                    font-weight: 600;
                    padding: 2px 0;
                    text-transform: uppercase;
                }
                button[button-type='status-active'] {
                    background-color: var(--omega-white);
                    border: 1px solid var(--omega-success-darken-100);
                    color: var(--omega-success-darken-100);
                }
                button[button-type='status-inactive'] {
                    background-color: var(--omega-info-lighten-300);
                    border: 1px solid var(--omega-info-lighten-100);
                    color: var(--omega-info-darken-100);
                }
                button[button-type='status-failed'] {
                    background-color: var(--omega-white);
                    border: 1px solid var(--omega-error);
                    color: var(--omega-error);
                }
                button[button-type='status-pending-approval'] {
                    background-color: var(--omega-white);
                    border: 1px solid var(--omega-warning);
                    color: var(--omega-warning-darken-100);
                    padding: 4px 0;
                    line-height: 10px;
                }
                button[button-type='icon'] {
                    padding: var(--omega-button-icon-padding, 6px 8px);
                    border: 1px solid var(--omega-button-icon-border);
                    background-color: transparent;
                    color: var(--omega-button-icon-color);
                }
                button[button-type='icon'] i {
                    line-height: 16px;
                    font-size: 18px;
                    vertical-align: middle;
                }
                button[button-type='icon'] i + .omega-button-label {
                    margin-left: 0.5ex;
                }
                button[button-type='icon'] span {
                    display: inline-block;
                    margin-left: 3px;
                }
                button[button-type='icon']:not(.labelled) {
                    min-width: 1px;
                    padding: var(--omega-button-icon-padding, 6px 8px);
                    margin-right: 0px;
                    margin-left: 0px;
                }
                button[button-type='icon']:hover:not(:disabled) {
                    background-color: var(--omega-button-icon-hover);
                }
                button[button-type='icon']:focus {
                    background-color: var(--omega-button-icon-darken-100);
                }
                button[button-type='icon'] > i.fa-exclamation-triangle {
                    color: var(--omega-button-exclamation-triangle-color);
                }
                button[button-type='icon']:disabled > i.fa-exclamation-triangle {
                    display: none;
                }
                :host([x-small]) {
                    margin: 0;
                }
                :host([x-small]) button {
                    padding: 7px 8px;
                    margin: 0;
                    border: 0;
                }

                .visually-hidden {
                    position: absolute !important;
                    overflow: hidden;
                    width: 1px;
                    height: 1px;
                    clip: rect(1px, 1px, 1px, 1px);
                }
                omega-progress {
                    height: 10px;
                    width: 10px;
                    border-width: 2px;
                    margin-bottom: -3px;
                }

                @media print {
                    .no-print {
                        display: none;
                    }
                }
            `,
        ];
    }
}

customElements.define('omega-button', OmegaButton);
export default OmegaButton;
