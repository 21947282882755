/* eslint-disable class-methods-use-this */

import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import '../icons/omegaIconInformation.js';
import '../icons/omegaIconMore.js';

export const OmegaContextMenuTagName = 'omega-context-menu';
export default class OmegaContextMenu extends LitElement {
    static get properties() {
        return {
            isOpen: {
                type: Boolean,
                reflect: true,
            },
        };
    }

    constructor() {
        super();
        this.isOpen = false;
        this.addEventListener('keydown', this.handleKeydown);
        this.boundClickDocument = this.onClickDocument.bind(this);
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-context-menu--any-content',
        };
    }

    handleKeydown(e) {
        // handle escape key
        if (e.keyCode === 27) {
            this.closeMenu();
        }
    }

    onClickDocument(e) {
        const shouldClose = !(e.composedPath() || []).includes(this);

        if (shouldClose) {
            e.preventDefault();
            this.closeMenu();
        }
    }

    closeMenu() {
        this.isOpen = false;

        const toggleButton = this.shadowRoot.querySelector('#toggle-button');
        toggleButton.focus();
        document.removeEventListener('click', this.boundClickDocument);
    }

    renderItems() {
        return html`
            <menu class=${classMap({ 'is-open': this.isOpen })}>
                <slot></slot>
            </menu>
        `;
    }

    renderToggleButton() {
        return html`
            <button
                id="toggle-button"
                aria-label="context menu"
                aria-expanded=${this.isOpen}
                @click=${() => {
                    this.isOpen = !this.isOpen;
                    document.addEventListener('click', this.boundClickDocument);
                }}
            >
                <slot name="button-contents">
                    <omega-icon-more></omega-icon-more>
                </slot>
            </button>
        `;
    }

    render() {
        return html`${this.renderToggleButton()}${this.renderItems()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: relative;
            }

            ::slotted(hr) {
                border: none;
                border-top: 1px solid var(--omega-divider-color);
            }

            #toggle-button {
                background: none;
                border: none;
                font-size: 16px;
            }

            omega-icon-more {
                width: 16px;
            }

            [role='menuitem'] {
                display: block;
            }

            menu:not(.is-open) {
                display: none;
            }

            menu {
                position: absolute;
                z-index: 1;
                background: white;
                border-radius: 8px;
                box-shadow: var(--omega-menu-shadow);
                right: var(--omega-context-menu-right);
                top: var(--omega-context-menu-top);
                max-width: 280px;
                min-width: var(--omega-context-menu-min-width, 200px);
                margin: 0;
                padding: 12px 0 0;
            }

            :host([left-side]) menu {
                right: 0;
            }
        `;
    }
}
// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define(OmegaContextMenuTagName, OmegaContextMenu);
