import { DEFAULT_OPTIONS, ADDITIONAL_OPTIONS } from '../utils/date-options-constants.js';
import DateModel from '../utils/date-model.js';

// eslint-disable-next-line @treasury/filename-match-export
export default function dateFormatter(value) {
    // Search unfiltered array of all date options
    const ALL_RELATIVE_OPTIONS = [...DEFAULT_OPTIONS, ...ADDITIONAL_OPTIONS];
    const relativeOption = ALL_RELATIVE_OPTIONS.find(option =>
        [option?.id, option?.legacyId].includes(value)
    );
    let dateArray = relativeOption?.dates || null;

    if (!dateArray) {
        // perform split in case string is a range
        dateArray = typeof value === 'string' ? value.split(' - ') : [value];
    }

    return dateArray
        .map(date => {
            try {
                const dateValue =
                    typeof date === 'string' ? date.slice(0, 10).replace(/-/g, '/') : date;
                const DateObj = new DateModel(dateValue);
                return DateObj.displayFormat();
            } catch (error) {
                return null;
            }
        })
        .join(' - ');
}

// TODO: refactor all references to this function
//       to call dateFormatter(value) instead.
export const localeDateString = value => dateFormatter(value);

// TODO: refactor all references to this function
//       to call dateFormatter(value) instead.
export const formatterFunction = value => dateFormatter(value);
