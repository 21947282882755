/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ApproveBeneficiaryModelDto,
    BeneficiaryModelDto,
    BeneficiarySearchCriteriaModelDto,
    DeleteBeneficiaryModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class BeneficiariesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesGetAll
     * @summary Gets all Beneficiaries.
     * @request GET:/beneficiaries
     */
    beneficiariesGetAll = (params: RequestParams = {}) =>
        this.http.request<BeneficiaryModelDto[], any>({
            path: `/beneficiaries`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesPost
     * @summary Creates a Beneficiary.
     * @request POST:/beneficiaries
     */
    beneficiariesPost = (beneficiary: BeneficiaryModelDto, params: RequestParams = {}) =>
        this.http.request<BeneficiaryModelDto, any>({
            path: `/beneficiaries`,
            method: 'POST',
            body: beneficiary,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesPut
     * @summary Updates a Beneficiary.
     * @request PUT:/beneficiaries
     */
    beneficiariesPut = (beneficiary: BeneficiaryModelDto, params: RequestParams = {}) =>
        this.http.request<BeneficiaryModelDto, any>({
            path: `/beneficiaries`,
            method: 'PUT',
            body: beneficiary,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesDelete
     * @summary Deletes a Beneficiary.
     * @request DELETE:/beneficiaries
     */
    beneficiariesDelete = (
        deleteBeneficiaryModel: DeleteBeneficiaryModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<DeleteBeneficiaryModelDto, any>({
            path: `/beneficiaries`,
            method: 'DELETE',
            body: deleteBeneficiaryModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesGetAll2
     * @summary Gets all Beneficiaries of particular type and id
     * @request GET:/Beneficiaries/{id}/{type}
     */
    beneficiariesGetAll2 = (type: boolean, id: number, params: RequestParams = {}) =>
        this.http.request<BeneficiaryModelDto[], any>({
            path: `/Beneficiaries/${id}/${type}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesGet
     * @summary Gets a Beneficiary.
     * @request GET:/Beneficiaries/{id}
     */
    beneficiariesGet = (id: number, params: RequestParams = {}) =>
        this.http.request<BeneficiaryModelDto, any>({
            path: `/Beneficiaries/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesSearch
     * @summary Searches for Beneficiaries.
     * @request POST:/beneficiaries/search
     */
    beneficiariesSearch = (
        beneficiarySearchCriteria: BeneficiarySearchCriteriaModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BeneficiaryModelDto[], any>({
            path: `/beneficiaries/search`,
            method: 'POST',
            body: beneficiarySearchCriteria,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesApprove
     * @summary Approves a Beneficiary.
     * @request POST:/beneficiaries/approve
     */
    beneficiariesApprove = (
        approveBeneficiaryModel: ApproveBeneficiaryModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<BeneficiaryModelDto, any>({
            path: `/beneficiaries/approve`,
            method: 'POST',
            body: approveBeneficiaryModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Beneficiaries
     * @name BeneficiariesReject
     * @summary Rejects a Beneficiary.
     * @request POST:/beneficiaries/reject
     */
    beneficiariesReject = (
        approveBeneficiaryModel: ApproveBeneficiaryModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<BeneficiaryModelDto, any>({
            path: `/beneficiaries/reject`,
            method: 'POST',
            body: approveBeneficiaryModel,
            format: 'json',
            ...params,
        });
}
