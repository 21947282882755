ReportsDashboardController.$inject = [
    '$scope',
    'informationReportsService',
    '$state',
    '$stateParams',
    'toaster',
    'modalService',
    '$filter',
    'navigationRouteMap',
    'navigationService',
    'informationReportingActivityCodes',
];

export default function ReportsDashboardController(
    $scope,
    informationReportsService,
    $state,
    $stateParams,
    toaster,
    modalService,
    $filter,
    navigationRouteMap,
    navigationService,
    informationReportingActivityCodes
) {
    $scope.reports = [];
    $scope.currentUserSavedReports = [];
    $scope.standardReportsList = [];
    $scope.isFormLoaded = false;
    $scope.filterObj = {
        name: '',
        isFavorite: undefined,
        isStandardReport: undefined,
    };

    $scope.placeholder = function (element) {
        return element.clone().addClass('drop-target').text('');
    };
    $scope.hint = function (element) {
        return element.clone();
    };

    $scope.sortableOptions = {
        disabled: '.disabled',
        end(e) {
            $scope.reports = $scope.reports.map(item => {
                if (item.sequence < e.oldIndex && item.sequence >= e.newIndex) {
                    item.sequence++;
                } else if (item.sequence > e.oldIndex && item.sequence <= e.newIndex) {
                    item.sequence--;
                } else if (item.sequence === e.oldIndex) {
                    item.sequence = e.newIndex;
                }
                return item;
            });
            const myReportIds = $filter('orderBy')($scope.reports, 'sequence', false).map(
                item => item.id
            );
            informationReportsService
                .updateSequence({ reportingFilterIds: myReportIds })
                .then(response => {
                    loadReports();
                    toaster.save('My Reports sequence has been');
                });
        },
    };

    function deleteMyReport(report) {
        informationReportsService.deleteFilter(report.id).then(response => {
            $scope.reports.splice($scope.reports.indexOf(report), 1);
            toaster.delete('My Report');
        });
    }

    $scope.delete = function (report) {
        const modalOptions = {
            headerText: 'Confirm Deletion',
            bodyText: 'Are you sure you want to delete this report?',
            actionButtonText: 'Delete Report',
            actionButtonClass: 'btn-danger',
            closeButtonText: 'Cancel',
            submit(result) {
                deleteMyReport(report);
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    $scope.runReport = function (report) {
        if (report.reportType === 'Transaction') {
            if (report.dateType === 'Current Day' || report.dateType === 'Prior Day') {
                $state.go('ir.dt', { id: report.id });
            } else {
                $state.go('ir.drt', { id: report.id });
            }
        } else if (report.reportType === 'Balance') {
            if (report.dateType === 'Current Day' || report.dateType === 'Prior Day') {
                $state.go('ir.db', { id: report.id });
            } else {
                $state.go('ir.drb', { id: report.id });
            }
        } else if (report.reportType === 'EDI') {
            $state.go('ir.edi', { id: report.id });
        }
    };

    $scope.toggleFavorite = function (report) {
        report.isFavorite = !report.isFavorite;

        informationReportsService.favoriteFilter(report.id, report.isFavorite).then(response => {
            toaster.save('Favorites');
        });
    };

    $scope.selectTab = function (tabName) {
        $scope.selectedTabName = tabName;
        switch (tabName) {
            case 'all':
                $scope.filterObj.isFavorite = undefined;
                $scope.filterObj.isStandardReport = undefined;
                break;

            case 'favorite':
                $scope.filterObj.isFavorite = true;
                $scope.filterObj.isStandardReport = undefined;
                break;

            case 'custom':
                $scope.filterObj.isFavorite = undefined;
                $scope.filterObj.isStandardReport = false;
                break;

            case 'standard':
                $scope.filterObj.isFavorite = undefined;
                $scope.filterObj.isStandardReport = true;
                break;
        }

        triggerUserActivity(getReportOptionName(tabName));
    };

    $scope.isTabSelected = function (tabName) {
        return tabName === $scope.selectedTabName;
    };

    function loadReports() {
        informationReportsService.getReports().then(response => {
            $scope.reports = response;
            $scope.isFormLoaded = true;
        });
    }

    function triggerUserActivity(destination) {
        navigationService.userActivityAudit(destination);
    }

    function getReportOptionName(optionPart) {
        const option = `ir${capitalize(optionPart)}`;
        const index = informationReportingActivityCodes.indexOf(option);
        return informationReportingActivityCodes[index] ?? '';
    }

    function capitalize(word) {
        if (typeof word !== 'string') return '';
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    function init() {
        $scope.selectTab($stateParams.tab ? $stateParams.tab : 'all');
        loadReports();
    }

    init();
}
