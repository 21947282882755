DateRangeBalanceController.$inject = ['$scope', 'entitlementsService'];

/* eslint-disable @treasury/filename-match-export */
export default function DateRangeBalanceController($scope, entitlementsService) {
    $scope.accountNameAccountNumberUpdates = entitlementsService.hasEntitlement(
        'Feature.Reporting.AccountNameAccountNumberUpdates.499511'
    );
    $scope.filterObject = {};
    $scope.dateRangeBalanceReport = {};
    $scope.hasReportBeenGenerated = false;
    $scope.sorting = [];

    $scope.calculateTotals = function (data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    };

    $scope.$on('reportUpdated', (event, response) => {
        setSort(response);
    });

    function setSort(response) {
        angular.forEach($scope.sorting, item => {
            item.sortBy = angular.copy(response.sortBy);
            item.sortBy =
                item.sortBy.substring(0, 1).toLowerCase() +
                item.sortBy.substring(1, item.sortBy.length);
            item.isAscending = response.isAscending;
        });
    }

    $scope.determineOrder = function (account) {
        let result = '';
        if ($scope.sorting.length > 0) {
            angular.forEach($scope.sorting, item => {
                if (item.accountNumber === account) {
                    result =
                        item.sortBy.substring(0, 1).toLowerCase() +
                        item.sortBy.substring(1, item.sortBy.length);
                }
            });
        }
        return result;
    };

    $scope.setOrder = function (account, value) {
        angular.forEach($scope.sorting, item => {
            if (item.accountNumber === account) {
                item.sortBy = value;
            }
        });
    };

    $scope.setSort = function (account) {
        angular.forEach($scope.sorting, item => {
            if (item.accountNumber === account) {
                item.isAscending = !item.isAscending;
            }
        });
    };

    $scope.determineSort = function (account) {
        let result = false;
        if ($scope.sorting.length > 0) {
            angular.forEach($scope.sorting, item => {
                if (item.accountNumber === account) {
                    result = !item.isAscending;
                }
            });
        }
        return result;
    };

    $scope.isWidthSmall = function (widthValue) {
        return widthValue;
    };

    $scope.parse = function (value) {
        return parseFloat(value);
    };

    $scope.$watch(
        'dateRangeBalanceReport',
        (newValue, oldValue) => {
            if (newValue !== null && newValue !== undefined) {
                if (angular.isDefined(newValue.data)) {
                    if (newValue.data.length === 0) {
                        $scope.sidebarContainerController.setCollapsed(false);
                    } else {
                        $scope.sidebarContainerController.setCollapsed(true);
                    }
                    $scope.dateRangeBalanceReport = newValue;
                    $scope.sorting = [];
                    angular.forEach($scope.dateRangeBalanceReport.data, item => {
                        $scope.sorting.push({
                            accountName: item.accountName,
                            accountNumber: item.accountNumber,
                            isAscending: $scope.dateRangeBalanceReport.isAscending,
                            sortBy: $scope.dateRangeBalanceReport.sortBy,
                        });
                    });
                    $scope.hasReportBeenGenerated = true;
                }
            }
        },
        true
    );
}
