import { TmHttpClient } from '@treasury/core/http';
import { Nullable } from '@treasury/utils/types';
import { IssuedItemDto, IssuedItemSavedError } from '../../types';
import { IssuedItemSaveDto } from '../../types/arp';

interface SaveIssuedItemsBody {
    issuedItems: Nullable<IssuedItemSaveDto>[];
    uploadFileName?: string;
}

interface SaveIssuedItemsResponse {
    issuedItemErrors: IssuedItemSavedError[];
    issuedItems: Nullable<IssuedItemDto>[];
}

export async function saveIssuedItems(body: SaveIssuedItemsBody) {
    const http = await TmHttpClient.getInstance();
    return http.request<SaveIssuedItemsResponse>('issuedItems/saveIssuedItems', {
        method: 'POST',
        body,
    });
}
