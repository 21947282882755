import { LitElement, html, css, nothing } from 'lit';
import { mix } from 'mixwith';
import { ListeningElementMixin } from '@treasury/omega/components';
import { boolean, date, Recordset, string } from '@treasury/FDL';
import { accountNumber } from '@treasury/policy/account';
import { amount } from '@treasury/policy/primitives';
import channelAlertMixin from '../../../../mix-ins/channel-alert-mixin.js';
import AchExceptionsClient from '../../../ach-exceptions/clients/ach-exceptions-client.js';
import '@treasury/omega/components/progress/omega-progress.js';

class ExceptionTable extends mix(LitElement).with(channelAlertMixin, ListeningElementMixin) {
    static get properties() {
        return {
            Recordset: Object,
            records: Object,
            tableLoading: Boolean,
            loading: Boolean,
        };
    }

    constructor() {
        super();
        this.columns = [
            { field: 'selected', label: '', allowOne: true, type: 'checkbox' },
            { field: 'decision', label: 'Decision' },
            { field: 'accountNumber', label: 'Account' },
            { field: 'achCompanyName', label: 'ACH Company' },
            { field: 'amount', label: 'Amount' },
            { field: 'postedDate', label: 'Posted Date' },
            { field: 'exceptionType', label: 'Type' },
            { field: 'secCode', label: 'SEC Code' },
            { field: 'entryDescription', label: 'Description' },
        ];
        this.fields = {
            selected: boolean,
            decision: string.thatIs.readOnly(),
            accountNumber: accountNumber.thatIs.readOnly(),
            achCompanyName: string.thatIs.readOnly(),
            amount: amount.thatIs.readOnly(),
            postedDate: date.thatIs.readOnly(),
            exceptionType: string.thatIs.readOnly(),
            secCode: string.thatIs.readOnly(),
            entryDescription: string.thatIs.readOnly(),
        };
        this.client = new AchExceptionsClient();
        this.loading = true;
        this.tableLoading = true;
    }

    async firstUpdated() {
        this.records = await this.client.findAllAchExceptions();
        this.recordset = new Recordset(this.fields, () => this.records);
        this.loading = false;
        this.listenTo(this.recordset, 'loading', ({ detail }) => {
            this.tableLoading = detail.loading;
        });
        this.listenTo(this.recordset, 'updated', ({ detail }) => {
            if (detail && detail.field === 'selected') {
                const event = new CustomEvent('recordSelected', { detail: detail.record.values });
                this.dispatchEvent(event);
            }
        });
        await this.recordset.requestUpdate();
    }

    renderLoader() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        return nothing;
    }

    renderTable() {
        if (!this.recordset) return nothing;
        return html`
            <omega-filter-bar
                .filters=${[]}
                .recordset=${this.recordset}
                @change=${({ detail }) => {
                    this.tableFilters = detail;
                }}
            ></omega-filter-bar>
            <omega-table
                .columnDefinitions=${this.columns}
                .recordset=${this.recordset}
                .loading=${this.tableLoading}
            >
            </omega-table>
        `;
    }

    render() {
        return [this.renderLoader(), this.renderTable()];
    }

    static get styles() {
        return css``;
    }
}

customElements.define('exception-table', ExceptionTable);
