/* eslint-disable lit-a11y/anchor-is-valid */
/* eslint-disable import/no-duplicates */
import '@treasury/omega/components/omega-alert';
import '@treasury/omega/components/progress/omega-progress';
import '@treasury/omega/layouts/omega-form';
import './initial-step-radio-group.template';

import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record } from '@treasury/FDL';
import { IIssuedItem, isIssuedItems } from '@treasury/domain/channel/mappings/arp';
import { ArpAccountsService, ArpService } from '@treasury/domain/channel/services';
import { UploadIssuedItemsError } from '@treasury/domain/channel/types/arp';
import { OmegaFileUploadEvent } from '@treasury/omega/types';
import { Action, Callback, LitPropertyVerifier, exists } from '@treasury/utils';
import { UploadFormChangeEvent, UploadFormData, createUploadFormSchema } from '../data';
import { renderUploadErrorDialog } from './upload-error-dialog.template';

interface UploadStepTransitionPayload {
    items: IIssuedItem[];
    fileName: string;
}

type UploadStepTransition = Callback<UploadStepTransitionPayload>;

export function renderUploadStep(onCancel: Action, onNextStep: UploadStepTransition) {
    return html`<issued-items-creation-upload-step
        .onCancel=${onCancel}
        .onNextStep=${onNextStep}
    ></issued-items-creation-upload-step>`;
}

@customElement('issued-items-creation-upload-step')
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class IssuedItemsCreationUploadStep extends LitPropertyVerifier {
    constructor() {
        super();
        this.schema = createUploadFormSchema(this.accountService, this.arpService);
    }

    protected readonly verifiedPropNames: (keyof this)[] = ['onCancel', 'onNextStep'];

    @property({
        reflect: false,
    })
    public onCancel!: Action;

    @property({
        reflect: false,
    })
    public onNextStep!: UploadStepTransition;

    @InjectProperty()
    private declare accountService: ArpAccountsService;

    @InjectProperty()
    private declare arpService: ArpService;

    private schema: ReturnType<typeof createUploadFormSchema>;

    @state()
    private uploadedFile?: File;

    @state()
    private record?: Record<UploadFormData>;

    @state()
    private errors: UploadIssuedItemsError[] = [];

    @state()
    private isShowingErrorDialog = false;

    @state()
    private isUploading = false;

    @state()
    private fileTooLarge = false;

    private readonly acceptedExtensions = ['.csv', '.txt', '.text', '.arp'];

    private get hasErrors() {
        if (this.fileTooLarge) {
            return true;
        }

        return this.errors && this.errors.length > 0;
    }

    private get canUpload() {
        return (
            !this.isUploading &&
            exists(this.record) &&
            this.record.isValid() &&
            exists(this.uploadedFile)
        );
    }

    private handleFileSelect(files: File[]) {
        this.resetErrorState();
        // eslint-disable-next-line prefer-destructuring
        this.uploadedFile = files[0];
    }

    private handleFormChange(e: UploadFormChangeEvent) {
        this.record = e.detail;
    }

    private resetErrorState() {
        this.errors = [];
        this.fileTooLarge = false;
    }

    private async upload() {
        if (!this.uploadedFile || !this.record || this.isUploading) {
            return;
        }

        this.isUploading = true;
        const format = this.record.getField('savedFormat');
        const account = this.record.getField('account');
        const itemsOrErrors = await this.arpService.parseIssuedItems(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            format.id!.toString(),
            this.uploadedFile,
            account
        );
        this.isUploading = false;

        if (isIssuedItems(itemsOrErrors)) {
            this.onNextStep({
                items: itemsOrErrors,
                fileName: this.uploadedFile.name,
            });
        } else {
            this.errors = itemsOrErrors;
        }
    }

    private renderErrorMessage() {
        if (!this.hasErrors) {
            return nothing;
        }

        const errorBody =
            this.errors.length > 0
                ? html` <p>
                      Encountered one or more errors during upload.
                      <a
                          href="javascript: void 0;"
                          @click=${() => {
                              this.isShowingErrorDialog = true;
                          }}
                          >See more...</a
                      >
                  </p>`
                : html`<p>The provided file is too large.</p>`;

        return html`<omega-alert
            .closeable=${false}
            .isVisible=${true}
            title="Upload Error"
            type="error"
            alignment="left"
            >${errorBody}
        </omega-alert>`;
    }

    private renderErrorDialog() {
        if (!this.isShowingErrorDialog) {
            return nothing;
        }

        return renderUploadErrorDialog(this.errors, () => {
            this.isShowingErrorDialog = false;
        });
    }

    protected render() {
        return html` ${this.renderErrorMessage()} ${this.renderErrorDialog()}
            <omega-form
                @change=${(e: UploadFormChangeEvent) => this.handleFormChange(e)}
                .schema=${this.schema}
            ></omega-form>
            <omega-file-upload
                .accepted=${this.acceptedExtensions}
                @filesUploaded=${(e: OmegaFileUploadEvent) => this.handleFileSelect(e.detail.files)}
                @invalidFileSize=${() => {
                    this.fileTooLarge = true;
                }}
            ></omega-file-upload>
            <br />
            <omega-button-bar position="bottom" alignment="left">
                <omega-button
                    @click=${() => this.upload()}
                    .disabled=${!this.canUpload}
                    type="primary"
                    >Upload</omega-button
                >
                <omega-button @click=${() => this.onCancel()} type="link">Cancel</omega-button>
            </omega-button-bar>`;
    }

    static styles = css`
        :host {
            display: block;
            width: 440px;
        }

        omega-form,
        omega-file-upload {
            max-width: 500px;
        }

        omega-progress {
            width: 100px;
            height: 100px;
        }

        input {
            display: block;
        }

        .center {
            text-align: center;
        }
    `;
}
