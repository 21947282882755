import { isValidDate } from './index';

const isValidEndOn = (frequency: any) => {
    if (!frequency) return false;

    // if (logging) console.log(`valid frequency end on date: ${result}`);

    return frequency.noEndDate || isValidDate(frequency.endOn);
};

export default isValidEndOn;
