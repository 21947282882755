/* eslint-disable @treasury/no-date */
/**
 * Get the timezone name as a [TZ identifier](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones).
 *
 * @param locale The locale code to get the abbreviated timezone for.
 * Omitting defaults to what is reported by the browser.
 */
export function getTimezone(locale?: Intl.LocalesArgument) {
    return Intl.DateTimeFormat(locale).resolvedOptions().timeZone;
}

/**
 * Get the [UTC offset](https://en.wikipedia.org/wiki/List_of_UTC_offsets) for the current locale.
 */
export function getTimezoneOffset() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const sign = offsetMinutes < 0 ? '+' : '-';
    const offsetHours = Math.abs(offsetMinutes) / 60;

    return `${sign}${offsetHours}`;
}

/**
 * Get the [abbreviated time zone code](https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations) for the specified timezone.
 *
 * @param locale The locale code to get the abbreviated timezone for.
 * Omitting defaults to what is reported by the browser.
 */
export function getTimezoneAbbreviated(locale?: Intl.LocalesArgument) {
    // passing undefined uses the browsers default locale
    const dtf = new Intl.DateTimeFormat(locale, { timeZoneName: 'short' });
    const parts = dtf.formatToParts(new Date());
    const value = parts.find(part => part.type === 'timeZoneName')?.value;

    if (!value) {
        throw new Error(`Could not get abbreviated timezone for locale: ${locale}`);
    }

    return value;
}

/**
 * Get the current timezone-aware date and time formatted as
 * `Thu Apr 18 2024, 1:03:27 PM` regardless of locale.
 */
export function getLocalDateTime(d = new Date()) {
    // don't use locale variant or the string format will change depending on country
    const date = d.toDateString();
    const secsFormatted = d.getSeconds().toString().padStart(2, '0');
    const minsFormatted = d.getMinutes().toString().padStart(2, '0');
    const hours24 = d.getHours();
    const hours = hours24 > 12 ? hours24 - 12 : hours24;
    const postFix = hours24 < 12 ? 'AM' : 'PM';

    return `${date}, ${hours}:${minsFormatted}:${secsFormatted} ${postFix}`;
}
