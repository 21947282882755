/* eslint-disable import/no-named-as-default */
import AchSettingsRequests from '../../requests/ach/ach-settings-requests.js';
import ProcessingCutoffRequests from '../../requests/cutoffs/processing-cutoff-times.js';
import DateRequests from '../../requests/holidays/dates.js';

export default class AchConfigurationServices {
    static async getConfiguration() {
        try {
            const achSettings = await AchSettingsRequests.getAchSettings();
            const sameDayAchSettings = await AchSettingsRequests.getSameDayAch();
            const isSameDayAchEnabled = await this.isSameDayAchEnabled();
            const holidays = await DateRequests.getHolidays();
            const cutoffTimes = await ProcessingCutoffRequests.getCutoffTimes();
            return {
                achSettings,
                sameDayAchSettings,
                isSameDayAchEnabled,
                holidays,
                cutoffTimes,
                companyMatchType: achSettings.achCompanyMatchType,
            };
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    static async isSameDayAchEnabled() {
        /* this should take a type of payment, since IAT would never be SDA allowed */
        const sda = await AchSettingsRequests.getSameDayAch();
        return sda.processingCutoff.allowSameDay;
    }
}
