export default () => ({
    require: ['?^wizard', '?^sidebarContainer'],
    restrict: 'A',
    replace: false,
    transclude: true,
    scope: {
        name: '@',
    },
    template: require('./panelTemplate.html'),
    link: panelLink,
});

function panelLink(scope, element, attrs, parentControllers) {
    scope.parentController = null;

    // Wizards
    if (parentControllers[0]) {
        parentControllers[0].addStep(scope);
        scope.parentController = parentControllers[0];
    }

    // Sidebar Container
    if (parentControllers[1]) {
        parentControllers[1].addPanel(scope);
        scope.parentController = parentControllers[1];
    }

    if (scope.visible === undefined) {
        scope.visible = true;
    }
}
