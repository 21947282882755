import { svg } from 'lit';

export const scheduleIcon = svg`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" fill="currentColor">
<defs>
  <path id="schedule-a" d="M14.99,5 C9.47,5 5,9.48 5,15 C5,20.52 9.47,25 14.99,25 C20.52,25 25,20.52 25,15 C25,9.48 20.52,5 14.99,5 L14.99,5 Z M15,7 C19.42,7 23,10.58 23,15 C23,19.42 19.42,23 15,23 C10.58,23 7,19.42 7,15 C7,10.58 10.58,7 15,7 Z M15.5,10 L14,10 L14,16 L19.25,19.15 L20,17.92 L15.5,15.25 L15.5,10 Z"/>
</defs>
<g fill-rule="evenodd">
  <mask id="schedule-b" fill="#fff">
    <use xlink:href="#schedule-a"/>
  </mask>
  <g  mask="url(#schedule-b)">
    <rect width="26" height="26" transform="translate(2 2)"/>
  </g>
</g>
</svg>`;
