import { isValidDate } from './index';

const isValidStartOn = (frequency: any) => {
    if (!frequency) return false;

    // if (logging) console.log(`valid frequency start on date: ${result}`);

    return isValidDate(frequency.startOn);
};

export default isValidStartOn;
