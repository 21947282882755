CreatePaymentDetailController.$inject = ['$scope', '$modal', '$modalInstance', 'payment'];

export default function CreatePaymentDetailController($scope, $modal, $modalInstance, payment) {
    $scope.payment = payment;
    $scope.showAddendaModal = showAddendaModal;
    $scope.filterRecipients = filterRecipients;
    $scope.searchByText = searchByText;
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };
    $scope.close = close;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../../batch/views/addendaModalView.html'),
            size: 'md',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.payment,
                        recipient,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });

        modalInstance.result.then(data => {
            batch.addendaType = data.addendaType;
            batch.addendaBody = data.addendaBody;
        });
    }

    function filterRecipients() {
        if ($scope.payment && $scope.payment.recipients) {
            let filteredArray = $scope.payment.recipients.filter(recipient => {
                if (recipient.isDeleted) {
                    return false;
                }

                return (
                    $scope.searchFilters.text === '' ||
                    (recipient.name &&
                        recipient.name
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    recipient.name
                        .toUpperCase()
                        .indexOf($scope.searchFilters.text.toUpperCase()) !== -1 ||
                    (recipient.idNumber &&
                        recipient.idNumber.toLowerCase().indexOf($scope.searchFilters.text) !==
                            -1) ||
                    (recipient.accountNumber &&
                        recipient.accountNumber.toLowerCase().indexOf($scope.searchFilters.text) !==
                            -1) ||
                    (recipient.amount &&
                        (recipient.amount.toString().indexOf($scope.searchFilters.text) !== -1 ||
                            `${recipient.amount.toString()}.00`.indexOf(
                                $scope.searchFilters.text
                            ) !== -1)) ||
                    (recipient.accountType &&
                        recipient.accountType
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    (recipient.transactionType &&
                        recipient.transactionType
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    (recipient.routingNumber &&
                        recipient.routingNumber.indexOf($scope.searchFilters.text.toLowerCase()) !==
                            -1)
                );
            });

            if ($scope.searchFilters.prenote) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.prenote && recipient.prenote
                );
            }

            if ($scope.searchFilters.hold) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.hold && recipient.hold
                );
            }
            return filteredArray;
        }
    }

    function searchByText() {
        $scope.filteredRecipients = filterRecipients();
    }

    (function () {
        // init
        $scope.filteredRecipients = filterRecipients();
    })();
}
