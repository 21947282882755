/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import {
    parseNachaFileResponseRecipients,
    parseNachaPaymentHeaderResponse,
} from '@treasury/domain/channel/mappings/ach/ach-international-payments.ts';
import { getDefaultValueDate, isIncomingDateValid } from '@treasury/domain/channel/types/ach';
import { DefaultFrequency } from '@treasury/domain/channel/types/frequency';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import isAchCompanyBalanced from '@treasury/domain/shared/utilities/is-ach-company-balanced';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-workflow.js';
import '@treasury/omega/components/progress/omega-progress';
import { LitElement, css, html, nothing } from 'lit';
import { map } from 'lit/directives/map.js';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import InternationalAchPaymentClient from '../clients/international-ach-payment-client.js';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration.ts';
import {
    convertPaymentDataToTemplate,
    convertTemplateToPayment,
} from '../data/convert-payment-template.ts';
import fillInInternationalAchPayment from '../data/fill-me-in-international-ach-payment.js';
import { setValueFromOptions } from '../data/get-value-from-options.ts';
import { handleApiError } from '../data/handle-api-error.ts';
import { paymentFields, paymentHeaderRecord } from '../data/payment-header-record.js';
import { recipientRecordset } from '../data/recipient-recordset.js';
import { paymentFieldsCopy, paymentHeaderRecordCopy } from '../data/template-header-record.js';
import { templateRecipientRecordset } from '../data/template-recipient-recordset.js';
import '../international-workflow-steps/ach-international-confirm-step.js';
import '../international-workflow-steps/ach-international-create-payment-step.js';
import '../international-workflow-steps/ach-international-review-step.js';

class InternationalAchWorkflowContainer extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            alert: Object,
            activeStep: Number,
            client: Object,
            institution: String,
            paymentHeaderRecord: Object,
            recipientsRecordset: Object,
            fields: Array,
            paymentFile: Object,
            paymentLoading: Boolean,
            isUploading: Boolean,
            isDownloading: Boolean,
            loading: Boolean,
            edit: Boolean,
            template: Boolean,
            configurationDetails: Object,
            templateValidationLoading: Boolean,
        };
    }

    constructor() {
        super();
        this.loading = true;
        this.activeStep = 0;
        this.recipientsRecordset = null;
        this.isUploading = false;
        this.isDownloading = false;
        this.recipientsPerPage = 25;
        this.edit = false;
        this.configurationDetails = {};
        this.template = false;
        this.templateNameIsValid = true;
        this.templateValidationLoading = false;
        this.workflowType = 'payment';
        this.creationType = 'create';
        this.id = undefined;
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new InternationalAchPaymentClient();
        }
        await this.init();

        const featureService = await FeatureFlagService.getInstance();
        this.paymentsPendingProcessFeatureFlag = await featureService.isEnabled(
            Feature.PaymentsPendingProcess
        );
    }

    async init() {
        await this.initContainerState();
        await this.initRecordState();
        this.loading = false;
    }

    async initContainerState() {
        const navService = await getNavService();
        const { params } = await navService.getRouteData();

        try {
            [this.workflowType, this.creationType, this.id] = window.location.pathname
                .split('/')
                .slice(4);
            this.paymentId = this.id;
            this.payment = params.paymentState?.payment;
            this.initiateFromTemplate = !!params.initiateFromTemplate;
            this.configurationDetails = await this.client.achConfiguration();
            this.edit =
                this.payment?.paymentCreationType === 'edit' ||
                this.creationType === 'edit' ||
                this.edit === 'true' ||
                this.edit;
            this.template =
                this.workflowType === 'template' || this.template === 'true' || this.template;
            this.configurationDetails.iatAchOptions = this.template
                ? await this.client.getIatAchOptions()
                : null;
            if (this.template && this.configurationDetails.iatAchOptions?.successLevel > 1) {
                throw new Error('This service is currently unavailable.');
            }
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        }
    }

    async initRecordState() {
        try {
            if (this.edit && !this.payment) {
                await this.getPaymentForEdit();
            }
            if (this.creationType === 'initiate' && !this.payment) {
                await this.getTemplateForInitiate();
            }
            await this.initPaymentHeaderRecord();
            await this.initRecipientsRecordset();
            this.initListening();
            this.paymentHeaderRecord.announceChange('achCompany');
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        }
    }

    async getTemplateForInitiate() {
        const paymentRequest = this.client.getTemplateById;
        const navService = await getNavService();
        const { params } = await navService.getRouteData();

        try {
            const templateData =
                params.paymentState ??
                (await paymentRequest(this.paymentId, this.configurationDetails.iatAchOptions));
            if (templateData.successLevel > 1) {
                throw new Error();
            }
            const companies = await this.client.getAchCompanies();
            const options =
                this.configurationDetails.iatAchOptions ?? (await this.client.getIatAchOptions());
            const { payment, recipients } = convertTemplateToPayment(
                templateData,
                companies,
                options
            );
            this.payment = payment;
            this.recipients = recipients;
        } catch (e) {
            if (e.message === 'user permission') {
                throw new Error(`Cannot edit ${this.workflowType} with id of ${this.paymentId}.`);
            }
            throw new Error('An error occurred with this payment request.');
        }
    }

    async getPaymentForEdit() {
        const permissionPartial = this.template
            ? 'Partial Edit International ACH Template'
            : 'Partial Edit International ACH Payment';
        const permissionFull = this.template
            ? 'Full Edit International ACH Template'
            : 'Full Edit International ACH Payment';
        const paymentRequest = this.template
            ? this.client.getTemplateById
            : this.client.getPaymentById;
        const matcher = this.template
            ? /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
            : /^[0-9]{6}$/;
        const editPermission = this.template ? 'EditTemplate' : 'EditPayment';
        const errorMessageRecordType = this.template ? 'template' : 'payment';
        try {
            const userCanPartialEditPayment = await this.client.hasEntitlement(permissionPartial);
            const userCanFullEditPayment = await this.client.hasEntitlement(permissionFull);
            // to do, set up correct edit permission handling
            const userCanEdit =
                userCanPartialEditPayment || userCanFullEditPayment || this.template;
            // if user has edit entitlement
            const validId = matcher.test(this.paymentId);
            if (userCanEdit && validId) {
                const payment = await paymentRequest(
                    this.paymentId,
                    this.configurationDetails.iatAchOptions
                );
                if (this.template && payment.successLevel > 1) {
                    // need to add response detail handling here
                    throw new Error();
                }
                const permissions = (payment?.payment?.permissions || []).map(p => p.permission);
                // to do, set up correct edit permission handling
                if (permissions.includes(editPermission) || this.template) {
                    this.payment = this.template ? payment : payment.payment;
                    this.recipients = this.payment.recipients;
                } else {
                    throw new Error('user permission');
                }
            }
        } catch (e) {
            if (e.message === 'user permission') {
                throw new Error(
                    `Cannot edit ${errorMessageRecordType} with id of ${this.paymentId}.`
                );
            }
            throw new Error('An error occurred with this payment request.');
        }
    }

    setEntryDescription(company) {
        const entryDescription = this.paymentHeaderRecord.getField('entryDescription');
        const isNachaUpload = this.paymentHeaderRecord.getField('nachaUpload');
        if (this.edit && this.template) return;
        if (isNachaUpload) {
            this.paymentHeaderRecord.setField(
                'entryDescription',
                entryDescription ?? company?.entryDescription
            );
        } else {
            this.paymentHeaderRecord.setField('entryDescription', company?.entryDescription);
        }
    }

    fieldChanged(field) {
        const changedField = field;
        if (changedField === 'achCompany') {
            /**
             * update values for fields tied to a property on another field
             */
            const company = this.paymentHeaderRecord.getField('achCompany');
            if (company) {
                this.paymentHeaderRecord.setField('achCompanyId', company?.companyId);
                this.paymentHeaderRecord.setField('secCode', 'IAT');
                this.setEntryDescription(company);
                this.paymentHeaderRecord.setField(
                    'discretionaryData',
                    company?.discretionaryData ?? ''
                );

                this.recipientsRecordset.allRecords.forEach(record => {
                    record.setField('achCompany', company);
                });
            }
        }

        if (changedField === 'destinationCountryCode') {
            const destinationCountryCode =
                this.paymentHeaderRecord.getField('destinationCountryCode');
            this.recipientsRecordset.allRecords.forEach(record => {
                record.setField('destination', destinationCountryCode);
            });
        }

        if (changedField === 'step') {
            const step = this.paymentHeaderRecord.getField('step');
            this.recipientsRecordset.allRecords.map(record => record.setField('step', step));
            this.requestUpdate();
        }
    }

    initListening() {
        this.listenTo(this.paymentHeaderRecord, 'change', ({ detail }) => {
            this.fieldChanged(detail.field);
        });

        this.listenTo(this.paymentHeaderRecord, 'blur', async ({ detail }) => {
            const { field } = detail;
            this.fieldChanged(field);
            if (field === 'name' && this.template) {
                await this.validateUniqueTemplateName();
            }
        });

        this.listenTo(this.recipientsRecordset, 'page-changed', () => {
            this.recipientsRecordset.allRecords[
                this.recipientsRecordset.allRecords.length - 1
            ].isExpanded = true;
            this.dispatchEvent(
                new CustomEvent('changed', {
                    composed: true,
                    bubbles: true,
                    detail: this.recipientsRecordset,
                })
            );
        });

        this.listenTo(this.recipientsRecordset, 'updated', () => {
            const data = this.recipientsRecordset.getData();
            let debits = 0;
            let credits = 0;
            if (data) {
                data.forEach(v => {
                    const calcCredit = v.transactionType === 'Credit' && !v.hold;
                    const calcDebit = v.transactionType === 'Debit' && !v.hold;
                    if (calcCredit) {
                        credits += v.amount;
                    }
                    if (calcDebit) {
                        debits += v.amount;
                    }
                    this.paymentHeaderRecord.setField('debitAmount', debits);
                    this.paymentHeaderRecord.setField('creditAmount', credits);
                    // if prenote is enabled, amount must be 0
                    // eslint-disable-next-line no-param-reassign
                    v.amount = v.prenote ? 0 : v.amount;
                    // copy destination and achCompany from paymentHeaderRecord
                    // eslint-disable-next-line no-param-reassign
                    v.destination = this.paymentHeaderRecord.getField('destinationCountryCode');
                    // eslint-disable-next-line no-param-reassign
                    v.achCompany = this.paymentHeaderRecord.getField('achCompany');
                });
            }
            const company = this.paymentHeaderRecord.getField('achCompany');
            if (company && isAchCompanyBalanced(company)) {
                if (!debits && !credits) return;
                if (debits) {
                    this.paymentHeaderRecord.setField('creditAmount', debits);
                }
                if (credits) {
                    this.paymentHeaderRecord.setField('debitAmount', credits);
                }
            }
            this.dispatchEvent(
                new CustomEvent('updated', {
                    composed: true,
                    bubbles: true,
                    detail: this.recipientsRecordset,
                })
            );
        });
    }

    async validateUniqueTemplateName() {
        const name = this.paymentHeaderRecord.getField('name');
        const initialName = this.paymentHeaderRecord.initialValues.name;
        if (this.templateValidationLoading) return; // debouncing with state
        if (name === initialName) {
            this.templateNameIsValid = true;
            return;
        }
        this.templateValidationLoading = true;
        this.loading = true;
        // button not re-rendering here
        this.templateNameIsValid = await this.client.isUniqueTemplateName(name);
        this.templateValidationLoading = false;
        this.loading = false;
        if (!this.templateNameIsValid) {
            this.alert = {
                ...this.alert,
                title: 'Template Name Error',
                posture: 'assertive',
                type: 'warning',
                visible: true,
                message: `Template name "${name}" already exists. Template names must be unique.`,
            };
        }
    }

    paymentIsEditableStatus() {
        const editableStatuses = new Set([
            'Approval Rejected',
            'Scheduled',
            'Uninitiated',
            'Failed',
            'Expired',
        ]);
        return editableStatuses.has(this.paymentHeaderRecord.getField('status'));
    }

    async initPaymentHeaderRecord() {
        const navService = await getNavService();

        let { payment } = this;
        if (!payment) {
            const { params } = await navService.getRouteData();
            payment = params.paymentState?.payment;
        }

        this.fields = this.template
            ? paymentFieldsCopy(
                  payment,
                  this.configurationDetails,
                  this.client,
                  this.edit,
                  this.template
              )
            : paymentFields(
                  payment,
                  this.configurationDetails,
                  this.client,
                  this.edit,
                  this.template,
                  this.creationType === 'initiate'
              );
        this.paymentHeaderRecord = this.template
            ? paymentHeaderRecordCopy(
                  payment,
                  this.configurationDetails,
                  this.client,
                  this.edit,
                  this.template
              )
            : paymentHeaderRecord(
                  payment,
                  this.configurationDetails,
                  this.client,
                  this.edit,
                  this.template,
                  this.creationType === 'initiate'
              );
        this.paymentHeaderRecord.setField('nachaUpload', false);

        if (this.edit) {
            if (this.template) {
                await this.setHeaderSelectValues();
            } else {
                // reset frequency's effective date in cases where the effective payment date has already passed
                const frequency = this.paymentHeaderRecord.getField('frequency');
                const effectiveDate = new Date(frequency.effectiveDate);
                const today = new Date();
                const isOneTime = frequency.type === 'OneTime' || frequency.type === 'One Time';
                if (isOneTime && effectiveDate < today) {
                    this.paymentHeaderRecord.setField('frequency', {
                        ...frequency,
                        effectiveDate: null,
                        valueDate: null,
                    });
                }
            }
        }
    }

    async setHeaderSelectValues() {
        const selectedValues = {};
        selectedValues.achCompany = await setValueFromOptions(
            'achCompany',
            this.paymentHeaderRecord,
            'companyGuidUniqueId',
            this.payment.achCompanyUniqueId
        );
        selectedValues.destinationCountryCode = await setValueFromOptions(
            'destinationCountryCode',
            this.paymentHeaderRecord,
            'abbreviation',
            this.payment.destinationCountryCode
        );
        selectedValues.foreignExchangeIndicator = await setValueFromOptions(
            'foreignExchangeIndicator',
            this.paymentHeaderRecord,
            '',
            this.payment.foreignExchangeIndicator
        );
        selectedValues.foreignExchangeReference = await setValueFromOptions(
            'foreignExchangeReferenceNumber',
            this.paymentHeaderRecord,
            'id',
            this.payment.foreignExchangeReferenceIndicatorId
        );
        selectedValues.destinationCurrencyCode = await setValueFromOptions(
            'destinationCurrencyCode',
            this.paymentHeaderRecord,
            'name',
            this.payment.destinationCurrencyCode
        );
        return selectedValues;
    }

    async initRecipientsRecordset() {
        const navService = await getNavService();
        const { params } = await navService.getRouteData();
        let { payment, recipients } = this;

        if (!payment) {
            payment = params.paymentState?.payment;
        }

        if (!recipients) {
            recipients = params.paymentState?.recipients;
        }

        if (recipients)
            this.recipients = recipients.map(recipient => ({
                ...recipient,
                step: 0,
                destination: this.paymentHeaderRecord.getField('destinationCountryCode'),
            }));
        this.recipientsRecordset = this.template
            ? await templateRecipientRecordset(
                  this.recipients,
                  payment,
                  this.configurationDetails,
                  this.client,
                  this.template,
                  this.creationType
              )
            : await recipientRecordset(
                  this.recipients,
                  payment,
                  this.configurationDetails,
                  this.client,
                  this.template
              );

        this.recipientsRecordset.setInitialPageSize(this.recipientsPerPage);
        await this.recipientsRecordset.requestUpdate();
        if (this.template && this.edit) {
            this.recipientsRecordset.allRecords.forEach(async (record, index) => {
                await setValueFromOptions(
                    'iatTransactionType',
                    record,
                    'id',
                    payment.recipients[index].iatTransactionTypeId
                );
                await setValueFromOptions(
                    'receivingBankCountry',
                    record,
                    'id',
                    payment.recipients[index].receivingBankCountryId
                );
            });
        }
    }

    async fillMeIn() {
        if (this.activeStep === 0) {
            await fillInInternationalAchPayment(this.paymentHeaderRecord, this.recipientsRecordset);
        }
    }

    async nextStep() {
        if (this.activeStep >= 2) {
            console.error('Max step count reached');
            this.activeStep = 2;
            return;
        }
        if (this.activeStep === 0 && this.template) {
            await this.validateUniqueTemplateName();
            if (!this.templateNameIsValid) {
                return;
            }
        }
        this.setScrollPosition(0, 0);
        this.activeStep++;
        this.paymentHeaderRecord.setField('step', this.activeStep);
    }

    previousStep() {
        this.setScrollPosition(0, 0);
        this.activeStep--;
        this.paymentHeaderRecord.setField('step', this.activeStep);
    }

    setScrollPosition(x, y) {
        document.querySelector('#sectionView').scrollTo(x, y);
    }

    promptForCancellation() {
        this.alert = {
            ...this.alert,
            type: 'warning',
            posture: 'assertive',
            visible: true,
            message: `Are you sure you want to cancel? Select 'Cancel' to return to ACH Payment Activity or Continue Editing.`,
            actions: html`<omega-button type="reject" @click=${this.goToAchPaymentActivity}
                    >Cancel</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                    >Continue Editing</omega-button
                >`,
        };
    }

    async goToAchPaymentActivity() {
        return this.template
            ? this.goToAchTemplatesList()
            : (await getNavService()).navigate('payables.ach.payment-list');
    }

    async goToAchTemplatesList() {
        return (await getNavService()).navigate(
            'payables.ach.payments.international-ach-templates'
        );
    }

    async uploadFile(e) {
        const file = e.detail;
        this.isUploading = true;
        try {
            this.paymentFile = await this.client.uploadNachaFile(file);
            if (
                this.paymentFile.errorSummary &&
                this.paymentFile.errorSummary.summaryMessageList.length
            ) {
                this.alert = {
                    ...this.alert,
                    message: this.paymentFile.errorSummary.summaryMessageList.map(
                        message => message
                    ),
                    type: 'error',
                    visible: true,
                };
            } else {
                if (!this.achConfiguration)
                    this.achConfiguration = await this.client.achConfiguration();
                this.paymentHeaderRecord = paymentHeaderRecord(
                    parseNachaPaymentHeaderResponse(this.paymentFile),
                    this.achConfiguration,
                    this.client,
                    false,
                    false
                );
                this.paymentHeaderRecord.setField('nachaUpload', true);

                const incomingEffectiveDateFromAPI = this?.paymentFile?.frequency?.effectiveDate;

                const incomingEffectiveDate = new Date(incomingEffectiveDateFromAPI);

                const { sameDayAchSettings, allowSameDayPayments, holidays, cutoffTimes } =
                    this.achConfiguration;

                const isIncomingEffectiveDateValid = isIncomingDateValid(
                    incomingEffectiveDate,
                    allowSameDayPayments,
                    holidays,
                    sameDayAchSettings,
                    cutoffTimes
                );

                const defaultValueDate = getDefaultValueDate(
                    allowSameDayPayments,
                    cutoffTimes,
                    holidays,
                    sameDayAchSettings
                );

                this.paymentHeaderRecord.setField('frequency', {
                    ...DefaultFrequency,
                    valueDate: isIncomingEffectiveDateValid
                        ? incomingEffectiveDateFromAPI
                        : defaultValueDate,
                });

                this.initListening();
                this.recipients = parseNachaFileResponseRecipients(this.paymentFile.recipients);
                this.recipientsRecordset = recipientRecordset(
                    this.recipients,
                    this.paymentFile,
                    this.achConfiguration,
                    this.client
                );
                this.alert = {
                    visible: false,
                };
                this.recipientsRecordset.allRecords.map(record =>
                    record.setField('nachaUpload', true)
                );
                await this.recipientsRecordset.requestUpdate();
                this.paymentHeaderRecord.announceChange('achCompany');
                this.paymentHeaderRecord.setField('step', 1);
                this.activeStep = 1;
            }
        } catch (err) {
            this.setAlertFromError(err);
        } finally {
            this.isUploading = false;
        }
    }

    async updateTemplateFromPayment() {
        this.loading = true;
        const options =
            this.configurationDetails?.iatAchOptions ?? (await this.client.getIatAchOptions());
        const { paymentHeaderValues, recipientsValues } = convertPaymentDataToTemplate(
            this.paymentHeaderRecord.values,
            this.recipientsRecordset.getData(),
            options
        );
        const updateResult = await this.client.updateTemplate(
            paymentHeaderValues,
            recipientsValues,
            this.id,
            options
        );
        this.loading = false;
        if (updateResult.successLevel > 1) {
            const errorAlert = handleApiError(updateResult, 'Error Submitting Template Update');
            this.alert = {
                ...this.alert,
                ...errorAlert,
            };
        } else {
            const linkRoute = async () => {
                const navService = await getNavService();
                return navService.navigate('payables.ach.international-ach-template-details', {
                    id: this.id,
                });
            };
            const message = `International ACH Template is Successfully Updated`;
            const linkText = 'View Updated International ACH Template';
            this.alert = {
                ...this.alert,
                title: 'Successfully Updated Template',
                message: html`${message}
                    <span
                        class="link"
                        @click=${linkRoute}
                        @keypress=${e => (e.code === 'Enter' ? linkRoute() : null)}
                    >
                        ${linkText}
                    </span>`,
                type: 'success',
                visible: true,
                posture: 'assertive',
            };
        }
        return updateResult.successLevel;
    }

    async submitPayment() {
        try {
            this.paymentLoading = true;
            if (this.edit) {
                if (this.template) {
                    this.payment = await this.client.updateTemplate(
                        this.paymentHeaderRecord.values,
                        this.recipientsRecordset.getData(),
                        this.paymentId,
                        this.configurationDetails.iatAchOptions ||
                            (await this.client.getIatAchOptions())
                    );
                } else {
                    this.payment = await this.client[
                        CONTAINER_CONFIGURATION[this.workflowType].put
                    ](
                        this.paymentHeaderRecord.values,
                        this.recipientsRecordset.getData(),
                        this.paymentId
                    );
                }
                this.payment.payment = this.payment.internationalAchPayment ?? this.payment.payment;
            } else {
                this.payment = await this.client[CONTAINER_CONFIGURATION[this.workflowType].post](
                    this.paymentHeaderRecord.values,
                    this.recipientsRecordset.getData(),
                    this.paymentId,
                    this.configurationDetails.iatAchOptions
                );
            }
            this.setScrollPosition(0, 0);
            /**
             * handle errors
             */
            // cancel trying to make a payment - no alert
            if (this.payment.securityMessage?.errorCode) {
                this.paymentLoading = false;
                return;
            }

            if (this.template && this.payment.successLevel > 1) {
                // to do - update to user-friendly error message
                const messages = this.payment.responseDetailCollection.map(
                    ({ responseCode, responseMessage }) => `${responseCode} - ${responseMessage}`
                );
                const renderMessage = i => html`${i}<br />`;
                this.alert = {
                    ...this.alert,
                    message: html`${map(messages, renderMessage)}`,
                    type: 'error',
                    title: 'Error Submitting Template',
                    visible: true,
                    posture: 'default',
                };
                this.paymentLoading = false;
                return;
            }

            /**
             * handle successfully created payment
             */
            if (this.template) {
                this.paymentHeaderRecord.setField('audit', this.payment.templateAudit);
                this.paymentHeaderRecord.setField('status', this.payment.templateStatus);
                this.paymentHeaderRecord.setField('id', this.payment.id);
                this.paymentId = this.payment.id;
            } else {
                this.paymentHeaderRecord.setField('audit', this.payment.payment?.audit);
                this.paymentHeaderRecord.setField(
                    'transactionId',
                    this.payment.payment?.transactionId
                );
                this.paymentHeaderRecord.setField('status', this.payment.payment?.status);
                this.paymentHeaderRecord.setField('id', this.payment.payment?.id);
                this.paymentId = this.payment.payment?.id;
            }
            if (this.activeStep < 2) this.nextStep();

            // two types of success alerts: pending and processing - will render alert based on the response here
            let message =
                this.paymentHeaderRecord.getField('status') === 'Pending Approval'
                    ? 'ACH payment is pending approval.'
                    : 'ACH payment is processing.';
            let linkText = 'Please review ACH Payment Activity';
            let linkRoute = () => this.goToAchPaymentActivity();
            if (this.template) {
                const actionWord = this.edit ? 'Edited' : 'Created';
                const pendingApproval =
                    this.paymentHeaderRecord.getField('status') === 'Pending Approval';
                message = pendingApproval
                    ? 'Pending Approval! International ACH Template is in pending approval status.'
                    : `International ACH Template is Successfully ${actionWord}`;
                linkText = 'Please review International ACH Payment Templates';
                linkRoute = () => this.goToAchTemplatesList();
            }

            const paymentStatus = this.paymentHeaderRecord.getField('status');
            const alertType =
                this.paymentsPendingProcessFeatureFlag && paymentStatus === 'Pending Process'
                    ? 'info'
                    : 'success';

            this.alert = {
                ...this.alert,
                title: '',
                message: html`${message}
                    <span
                        class="link"
                        @click=${linkRoute}
                        @keypress=${e => (e.code === 'Enter' ? linkRoute() : null)}
                    >
                        ${linkText}
                    </span>`,
                type: alertType,
                visible: true,
                posture: 'default',
            };
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        } finally {
            this.paymentLoading = false;
        }
    }

    async savePaymentAsTemplate() {
        this.loading = true;
        try {
            if (!this.configurationDetails.iatAchOptions) {
                this.configurationDetails.iatAchOptions = await this.client.getIatAchOptions();
            }
            const { paymentHeaderValues, recipientsValues } = convertPaymentDataToTemplate(
                this.paymentHeaderRecord.values,
                this.recipientsRecordset.getData(),
                this.configurationDetails.iatAchOptions
            );
            const response = await this.client[CONTAINER_CONFIGURATION.template.post](
                paymentHeaderValues,
                recipientsValues,
                null,
                this.configurationDetails.iatAchOptions
            );
            if (response.responseDetailCollection.length) {
                this.alert = {
                    ...this.alert,
                    message: response.responseDetailCollection[0].responseMessage,
                    type: 'error',
                    visible: true,
                };
            } else {
                this.savedAsTemplate = true;
                this.goToAchTemplatesList();
            }
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
            this.setScrollPosition(0, 0);
        }
    }

    async initiatePaymentFromTemplate() {
        try {
            this.loading = true;
            const templateId = this.paymentId;
            if (!templateId) throw new Error('Unable to identify template.');
            const templateData = await this.client.getTemplateById(templateId);
            const companies = await this.client.getAchCompanies();
            const options =
                this.configurationDetails.iatAchOptions ?? (await this.client.getIatAchOptions());
            const { payment, recipients } = convertTemplateToPayment(
                templateData,
                companies,
                options
            );
            this.loading = false;
            (await getNavService()).navigate(
                'payables.ach.payments.initiate-international-ach-payment',
                {
                    paymentState: {
                        payment,
                        recipients,
                    },
                    id: templateId,
                }
            );
        } catch (e) {
            this.loading = false;
            console.error(e);
            this.setAlertFromError(e);
        }
    }

    async print() {
        await this.client.print('internationalAchFullPage');
        window.print();
    }

    async download({ detail }) {
        this.isDownloading = true;
        try {
            if (this.template) {
                await this.client.downloadTemplate(this.paymentId, detail.downloadType);
            } else {
                await this.client.downloadPayment(
                    this.paymentId,
                    detail.downloadType,
                    'ACHPaymentDetail'
                );
            }
        } catch (err) {
            this.setAlertFromError(err);
        } finally {
            this.isDownloading = false;
        }
    }

    async handleGoToCreateNew() {
        if (this.creationType === 'create') {
            return this.resetForNewPayment();
        }

        const workflowRoute = `payables.ach.payments.create-international-ach-${this.creationType}`;
        return (await getNavService()).navigate(workflowRoute);
    }

    async resetForNewPayment() {
        this.loading = true;
        this.paymentHeaderRecord.setField('step', this.activeStep);
        this.payment = null;
        this.recipients = null;
        await this.initRecordState();
        this.setScrollPosition(0, 0);
        this.loading = false;
    }

    renderLoader() {
        if (this.loading) {
            return html`<omega-progress card></omega-progress>`;
        }
        return nothing;
    }

    renderBlockingLoader() {
        if (this.paymentLoading || this.isDownloading || this.loading) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    renderCreateOrEdit() {
        return this.edit ? 'Edit' : 'Create';
    }

    renderDataLabel() {
        return `${this.renderCreateOrEdit()} ${CONTAINER_CONFIGURATION[this.workflowType]?.title}`;
    }

    renderTitle() {
        return this.template
            ? `${this.renderCreateOrEdit()} International ACH Template`
            : 'International ACH Payment';
    }

    render() {
        const updateTemplateHandler = this.updateTemplateFromPayment.bind(this);
        return html`
            ${this.renderAlert()}${this.renderBlockingLoader()}
            <h2 class="workflow-header">${this.renderTitle()}</h2>
            <div class="create-international-ach-payment-container">
                <omega-workflow .activeStep=${this.activeStep}>
                    <div slot="step" data-label="${this.renderDataLabel()}">
                        <div slot="content">
                            <create-ach-international-payment-step
                                .institution=${this.institution}
                                .activeStep=${this.activeStep}
                                .entitlements=${this.configurationDetails.entitlements}
                                .workflowType=${this.workflowType}
                                .paymentHeaderRecord=${this.paymentHeaderRecord}
                                .recipientsRecordset=${this.recipientsRecordset}
                                .fields=${this.fields}
                                .isUploading=${this.isUploading}
                                .editingMode=${this.edit}
                                @fileUploaded=${this.uploadFile}
                                @next=${e => {
                                    const { paymentHeader, recipients } = e.detail;
                                    this.paymentHeaderRecord = paymentHeader;
                                    this.recipientsRecordset = recipients;
                                    this.nextStep();
                                }}
                                @cancel=${this.promptForCancellation}
                                .template=${this.template}
                                .continueLoading=${this.templateValidationLoading}
                                .continueBlocked=${!this.templateNameIsValid}
                                .templateNameIsValid=${this.templateNameIsValid}
                            ></create-ach-international-payment-step>
                            ${this.renderLoader()}
                        </div>
                    </div>
                    <div slot="step" data-label="Review">
                        <ach-international-review-step
                            .fields=${this.fields}
                            .activeStep=${this.activeStep}
                            .paymentHeaderRecord=${this.paymentHeaderRecord}
                            .recipientsRecordset=${this.recipientsRecordset}
                            @next=${e => {
                                const { paymentHeader, recipients } = e.detail;
                                this.paymentHeaderRecord = paymentHeader;
                                this.recipientsRecordset = recipients;
                                this.submitPayment();
                            }}
                            @previous=${this.previousStep}
                            @cancel=${this.promptForCancellation}
                            .template=${this.template}
                        ></ach-international-review-step>
                    </div>
                    <div slot="step" data-label="Confirmation">
                        <ach-international-confirm-step
                            .activeStep=${this.activeStep}
                            .fields=${this.fields}
                            .paymentHeaderRecord=${this.paymentHeaderRecord}
                            .recipientsRecordset=${this.recipientsRecordset}
                            .entitlements=${this.configurationDetails.entitlements}
                            .savedAsTemplate=${this.savedAsTemplate}
                            .workflowType=${this.workflowType}
                            .paymentCreationType=${this.creationType}
                            .isFromPayment=${!this.initiateFromTemplate}
                            .validateTemplateName=${this.client?.isUniqueTemplateName}
                            .updateTemplateFromPayment=${updateTemplateHandler}
                            @saveAsTemplate=${this.savePaymentAsTemplate}
                            @goToStep=${async ({ detail }) => {
                                this.alert = { ...this.alert, visible: false };
                                this.activeStep = detail.stepNumber;
                                if (this.activeStep === 0 && detail.createNew === true) {
                                    this.handleGoToCreateNew();
                                }
                            }}
                            @initiatePaymentFromTemplate=${this.initiatePaymentFromTemplate}
                            @goToAchActivity=${this.goToAchPaymentActivity}
                            @goToAchTemplatesList=${this.goToAchTemplatesList}
                            @print=${this.print}
                            @download=${this.download}
                        ></ach-international-confirm-step>
                    </div>
                </omega-workflow>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .create-international-ach-payment-container {
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
            }
            omega-workflow {
                height: 100%;
            }
            .workflow-header {
                font-size: 24px;
                margin-top: 0;
                font-weight: 400;
            }
            div[slot='step'] {
                height: 100%;
            }
            div[slot='content'] {
                height: 100%;
            }
            .link {
                color: var(--omega-primary);
                text-decoration: underline;
                cursor: pointer;
            }
        `;
    }
}

customElements.define('international-ach-workflow-container', InternationalAchWorkflowContainer);
export default InternationalAchWorkflowContainer;

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
