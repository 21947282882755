/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/layouts/omega-report';
import {
    fieldDelimiter,
    fileFormatDetails,
    fileFormatType,
    footerRows,
    headerRows,
    id,
    templateName,
    updatedBy,
} from '@treasury/policy/wires';
import FieldType from '@treasury/FDL/field-type.js';
import dateFormatter from '@treasury/policy/lib/formatters/date.formatter';
import { Recordset } from '@treasury/FDL';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';

class WireUploadTemplatesTable extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            entitlement: Array,
            fetchFormats: Function,
            formats: Array,
            columns: Array,
            formatsRecordset: Object,
            institution: String,
            alert: Object,
            records: Array,
            loading: Boolean,
            recordset: Object,
        };
    }

    constructor() {
        super();
        this.actions = {
            loadFormat: record => {
                this.clickFormatFileName(record.getField('id'));
            },
            promptFormatDeletion: record => {
                this.formatId = record.getField('id');
                this.formatName = record.getField('templateName');
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'warning',
                    posture: 'assertive',
                    title: 'Are You Sure?',
                    actions: html`<omega-button-bar slot="actions" alignment="right"
                        ><omega-button @click=${() => this.deleteSelectedFormat()} type="primary"
                            >Continue</omega-button
                        ><omega-button @click=${this.dismissDeleteAlert}
                            >Cancel</omega-button
                        ></omega-button-bar
                    >`,
                    message: `Are you sure you want to delete ${this.formatName}?`,
                };
            },
        };
        this.fields = {
            fieldDelimiter: fieldDelimiter.thatIs.readOnly(),
            fileFormatDetails: fileFormatDetails.thatIs.readOnly(),
            fileFormatType: fileFormatType.thatIs.readOnly(),
            footerRows: footerRows.thatIs.readOnly(),
            headerRows: headerRows.thatIs.readOnly(),
            id: id.thatIs.readOnly(),
            templateName: templateName.thatIs.readOnly(),
            updatedBy: updatedBy.thatIs.readOnly(),
            updatedDate: new FieldType().with.formatter(dateFormatter).thatIs.readOnly(),
        };
        this.pageTitle = 'Wire Upload Formats';
        this.alert = { visible: false };
        this.columns = [
            {
                type: 'command',
                label: 'Upload Formats',
                field: 'templateName',
                action: 'loadFormat',
            },
            {
                label: 'Type',
                field: 'fileFormatType',
            },
            {
                label: 'Created Date',
                field: 'updatedDate',
            },
            {
                label: 'Created By',
                field: 'updatedBy',
            },
        ];
    }

    firstUpdated() {
        if (this.entitlement)
            this.columns = [
                ...this.columns,
                {
                    label: 'Actions',
                    type: 'icon',
                    icon: 'trash',
                    iconLabel: 'Delete Format',
                    action: 'promptFormatDeletion',
                },
            ];

        this.recordset = new Recordset(this.fields, this.fetchFormats);
    }

    clickFormatFileName(formatId) {
        /**
         * * since we only handle api calls at the container level, we'll
         * * just emit an even here
         */
        this.dispatchEvent(
            new CustomEvent('selectFormat', { bubbles: true, detail: { formatId } })
        );
    }

    createNewFormat() {
        this.dispatchEvent(new CustomEvent('newFormat'));
    }

    deleteSelectedFormat() {
        this.alert = { ...this.alert, visible: false };
        /**
         * * since we only handle api calls at the container level, we'll
         * * just emit an even here
         */

        this.dispatchEvent(
            new CustomEvent('deleteFormat', {
                bubbles: true,
                detail: { formatId: this.formatId },
            })
        );
    }

    dismissDeleteAlert() {
        this.alert = { ...this.alert, visible: false };
    }

    handleDownload() {
        /**
         * TODO: this feature will not be implemented as part of the MVP; services will not be ready with an endpoint
         */
    }

    hasCreateFormatEntitlements() {
        return this.entitlement ? 'Create New Format' : null;
    }

    render() {
        if (!this.recordset) return nothing;
        return html`
            ${this.renderAlert()}
            <omega-report
                autostart
                .title=${this.pageTitle}
                .alert=${this.alert}
                flyout
                .loading=${this.loading}
                .recordsPerPage=${25}
                .actions=${this.actions}
                .recordset=${this.recordset}
                .columns=${this.columns}
                .options=${['print']}
                .callToAction=${this.hasCreateFormatEntitlements()}
                @callToAction=${this.createNewFormat}
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
        `;
    }
}

customElements.define('wire-upload-templates-table', WireUploadTemplatesTable);
export default WireUploadTemplatesTable;
