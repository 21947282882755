import { getChannelAPIPath } from '@treasury/core/http';

TermsAndConditionsService.$inject = ['$resource', '$http', '$q'];

export default function TermsAndConditionsService($resource, $http, $q) {
    const resourceUrl = `${getChannelAPIPath()}termsAndConditions/`;
    return {
        get,
        accept,
    };

    function get(blankIfUserAccepted) {
        return $resource(resourceUrl).get({ blankIfUserAccepted: blankIfUserAccepted || false })
            .$promise;
    }

    function accept() {
        return $resource(`${resourceUrl}accept`).get().$promise;
    }
}
