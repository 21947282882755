import {
    DEFAULT_OPTIONS,
    DEFAULT_ORDER,
    ADDITIONAL_OPTIONS,
    NO_SELECTION_OPTION,
    // ANY_DATE_SELECTION_OPTION,
    // NONE_SELECTION_OPTION,
    NO_SELECTION_OPTIONS,
} from './date-options-constants.js';
import dateFormatter from '../formatters/date.formatter.js';

export const isDate = dateObject =>
    !!dateObject && typeof dateObject.getTime === 'function' && !Number.isNaN(dateObject.getTime());

export const parseDate = date => {
    if (isDate(date)) return date;

    const dateObject = date && new Date(dateFormatter(date));

    return isDate(dateObject) ? dateObject : null;
};

export const findDateOption = optionId =>
    [...DEFAULT_OPTIONS, ...ADDITIONAL_OPTIONS].find(option =>
        [option?.id, option?.legacyId].includes(optionId)
    );

export const getDatesArray = dates => {
    if (!dates) return null;

    let datesArray;

    if (typeof dates === 'string') {
        datesArray = dates.split(' - ');
    }

    if (Array.isArray(dates)) {
        datesArray = dates;
    }

    if (isDate(dates)) {
        datesArray = [dates];
    }

    if (!datesArray) return null;

    return datesArray.map(date => parseDate(date)).filter(date => isDate(date));
};

export const getOptionFromDates = dates => {
    const datesArray = getDatesArray(dates);

    if (!datesArray) return '';

    const option = findDateOption(datesArray.length === 1 ? 'specific' : 'range');

    return { ...option, dates: datesArray };
};

export const dateOptionDateFormatter =
    (dateFormatterFunction = dateFormatter, joiner = '-') =>
    dateOption => {
        if (!dateOption) return '';

        let option = dateOption?.id ? dateOption : findDateOption(dateOption);

        if (!option) {
            option = getOptionFromDates(dateOption);
        }

        if (option?.formattedValue) {
            return option.formattedValue;
        }
        if (!(option?.dates && option.dates.length)) return '';

        return option.dates.map(date => dateFormatterFunction(date)).join(` ${joiner} `);
    };

class DateOptionsModel {
    constructor(formatter) {
        this.items = [
            // NO_SELECTION_OPTION,
            ...DEFAULT_OPTIONS.map(o => ({ ...o })),
            ...ADDITIONAL_OPTIONS.map(o => ({ ...o })),
        ];
        this.formatter = formatter || dateFormatter;
        this.getDatesArray = getDatesArray;
        this.isDate = isDate;
        this.parseDate = parseDate;
    }

    filteredOptions(optionOrder, range, required) {
        let order = optionOrder?.length ? optionOrder : DEFAULT_ORDER;
        const noSelectionOption =
            NO_SELECTION_OPTIONS.find(option => order.find(o => o === option.id)) ||
            NO_SELECTION_OPTION;

        this.items = [noSelectionOption, ...this.items];
        if (!required) {
            order = [noSelectionOption.id, ...order];
        }
        let options = order.map(item => this.items.find(opt => opt.id === item));
        if (!range && !optionOrder?.length) {
            options = options.filter(item => !item.isRange);
        }

        return options;
    }

    getOption(optionId) {
        if (!optionId) return null;

        return this.items.find(option => [option?.id, option?.legacyId].includes(optionId));
    }

    setDates(optionId, dates) {
        const option = this.getOption(optionId);
        if (option?.exact && dates.length) {
            option.dates = dates;
        }
    }

    formatDates(optionId, formatter) {
        const option = this.getOption(optionId);
        const formatterFunction = formatter || dateFormatter;

        if (!option) return '';

        if (option.formattedValue) {
            return option.formattedValue;
        }

        if (!(option.dates && option.dates.length)) return '';

        if (option.exact && !option.isRange) {
            const singleDate = option.dates[0] ? option.dates[0] : option.dates[1];
            return formatterFunction(singleDate);
        }

        return option.dates.map(date => formatterFunction(date)).join(' - ');
    }
}

DateOptionsModel.defaultOptions = DEFAULT_OPTIONS;

DateOptionsModel.isDate = isDate;

DateOptionsModel.parseDate = parseDate;

DateOptionsModel.getOptionFromDates = getOptionFromDates;

DateOptionsModel.allOptions = [...DEFAULT_OPTIONS, ...ADDITIONAL_OPTIONS];

export default DateOptionsModel;
