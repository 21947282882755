import { getChannelAPIPath } from '@treasury/core/http';

PrintService.$inject = ['$http'];

export default function PrintService($http) {
    const resourceUrl = `${getChannelAPIPath()}print/`;

    return {
        userActivityAudit,
    };

    function userActivityAudit(printId, additionalAuditInformation) {
        return $http
            .post(
                `${resourceUrl}useractivityaudit`,
                { printId, additionalAuditInformation },
                { suppressErrorMessage: true }
            )
            .then(
                response => response,
                err => {
                    // Per requirements, do not interrupt user experience if there is an error
                }
            );
    }
}
