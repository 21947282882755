import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { WorkflowActions } from '@treasury/domain/channel/types/ach/constants';
import '@treasury/omega/components/omega-radio-group.js';

import { css, html, LitElement, TemplateResult } from 'lit';
import { property, state } from 'lit/decorators.js';

interface RadioData {
    label: string | TemplateResult;
    value: string;
}

export class AchCreationToggle extends LitElement {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @state()
    id = '';

    @state()
    radios: RadioData[] = [
        {
            label: 'Manual Entry',
            value: WorkflowActions.Create,
        },
        {
            label: 'From Template',
            value: WorkflowActions.InitiateFromTemplate,
        },
        {
            label: 'Upload Nacha File',
            value: WorkflowActions.InitiateFromFile,
        },
    ];

    @property({ type: String })
    value: string = WorkflowActions.Create;

    handleChange(e: { detail: string }) {
        const { detail } = e;
        if (detail === WorkflowActions.InitiateFromTemplate) {
            this.navService.navigate('payables.ach.payments.templates');
        }
        this.dispatchEvent(new CustomEvent('change', e));
    }

    render() {
        return html`
            <omega-radio-group
                name="creation-toggle"
                orientation="row"
                .radios=${this.radios}
                .value=${this.value}
                @change=${this.handleChange}
            >
            </omega-radio-group>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}
window.customElements.define('ach-creation-toggle', AchCreationToggle);
