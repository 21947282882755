import { StatusCode } from '../../../src/login/data/login-phases.js';

UserVerificationController.$inject = [
    '$scope',
    '$state',
    'accountService',
    '$location',
    'pagePushService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function UserVerificationController(
    $scope,
    $state,
    accountService,
    $location,
    pagePushService
) {
    $scope.loginData = null;
    $scope.message = '';
    $scope.newUserCode = $location.search().code;
    $scope.user = {};
    $scope.reset = reset;

    $scope.login = login;

    init();

    function init() {
        $scope.loginData = {
            institution: document.documentElement.dataset.institution,
            companyId: '',
            loginId: '',
            password: '',
            useRefreshTokens: false,
            code: decodeURIComponent($location.search().code),
        };
    }

    function reset() {
        init();
    }

    function login() {
        pagePushService.setUser($scope.loginData);

        // Get login information.
        $scope.loginData.userName = $scope.loginData.loginId;
        accountService
            .validateUser($scope.loginData, true)
            .then(async response => {
                $scope.loginData.credential = response;
                $scope.message = accountService.checkForError(response);
                if (response.statusCode === StatusCode.Invalid) throw new Error(response.message);
                accountService.setAuth({
                    statusCode: response.statusCode,
                    daysUntilPasswordExpires: response.daysUntilPasswordExpires,
                });
                await accountService.goToNextStep();
            })
            .catch(response => {
                if (response.message) {
                    $scope.message = response.message;
                } else if (response.error_description) {
                    $scope.message = response.error_description;
                } else {
                    $scope.message = 'An error occurred during login. Please try again.';
                }
                $scope.$apply();
            });
    }
}
