import { svg } from 'lit';

export const trashcanIcon = svg`<svg width="14" height="16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
  <defs>
    <path d="M13.967 1.4h-3.75L9.923.816A.75.75 0 009.25.4H5.679a.741.741 0 00-.669.416l-.293.584H.967a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM2.129 14.994A1.5 1.5 0 003.626 16.4h7.681a1.5 1.5 0 001.497-1.406L13.467 4.4h-12l.662 10.594z" id="trashcan-a"/>
  </defs>
  <g transform="translate(-.467 -.4)" fill="none" fill-rule="evenodd">
    <mask id="trashcan-b" fill="#fff">
      <use xlink:href="#trashcan-a"/>
    </mask>
    <use fill="#000" fill-rule="nonzero" xlink:href="#trashcan-a"/>
    <path fill="currentColor" mask="url(#trashcan-b)" d="M-8-6h29.867v29.867H-8z"/>
  </g>
</svg>`;
