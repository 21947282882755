import { html, LitElement } from 'lit';
import '@treasury/omega/components/progress/omega-progress';

export class Login extends LitElement {
    static get properties() {
        return {
            record: Object,
            loading: Boolean,
        };
    }

    login(e) {
        this.dispatchEvent(new CustomEvent('login', { detail: { value: e } }));
    }

    forgotPassword() {
        this.dispatchEvent(new CustomEvent('forgot-password'));
    }

    render() {
        return html`
            <style>
                :host {
                    display: block;
                }
                .form {
                    margin: 15px;
                }
                .footer {
                    padding: 4px 15px;
                    border-top: 1px solid #ccc;
                }
                omega-field {
                    --omega-field-label-width: 100px;
                    --omega-field-control-width: 250px;
                    max-width: 360px;
                    margin-bottom: 15px;
                }
                omega-field:last-of-type {
                    margin-bottom: 0;
                }
            </style>
            <div class="form">
                <omega-field .record=${this.record} id="company-id" field="companyId"></omega-field>
                <omega-field .record=${this.record} id="login-id" field="loginId"></omega-field>
                <omega-field .record=${this.record} id="password" field="password"></omega-field>
            </div>
            <div class="footer">
                <omega-button
                    type="primary"
                    id="log-in"
                    ?loading=${this.loading}
                    @click=${this.login}
                    >Log In</omega-button
                >
                <omega-button id="forgot-password" type="link" @click=${this.forgotPassword}
                    >Forgot Password?</omega-button
                >
            </div>
        `;
    }
}

export default Login;
window.customElements.define('channel-login', Login);
