/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { LookupModelDto, NotificationListModelDto, NotificationModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class NotificationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsSetRead
     * @summary Sets the "read" state of a notification.
     * @request PUT:/notifications/setRead
     */
    notificationsSetRead = (
        query: {
            /** true / false */
            value: boolean;
        },
        notificationIds: LookupModelDto[],
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/notifications/setRead`,
            method: 'PUT',
            query: query,
            body: notificationIds,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsArchive
     * @summary Archives one or more notifications.
     * @request PUT:/notifications/archive
     */
    notificationsArchive = (notificationIds: LookupModelDto[], params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/notifications/archive`,
            method: 'PUT',
            body: notificationIds,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsDelete
     * @summary Deletes one or more notifications.
     * @request DELETE:/notifications
     */
    notificationsDelete = (notificationIds: LookupModelDto[], params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/notifications`,
            method: 'DELETE',
            body: notificationIds,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsGet
     * @summary Gets all notifications.
     * @request GET:/notifications
     */
    notificationsGet = (
        query?: {
            /** @default false */
            html?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<NotificationModelDto[], any>({
            path: `/notifications`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsGetRecent
     * @summary Gets recent notifications.
     * @request GET:/notifications/recent
     */
    notificationsGetRecent = (
        query?: {
            /** @default false */
            html?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<NotificationListModelDto, any>({
            path: `/notifications/recent`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsGetProductTypess
     * @summary Gets recent notifications.
     * @request GET:/notifications/productTypes
     */
    notificationsGetProductTypess = (params: RequestParams = {}) =>
        this.http.request<LookupModelDto[], any>({
            path: `/notifications/productTypes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
