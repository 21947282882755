import { Primitive, WidenLiterals } from '@treasury/utils';

export const isEdited = <T extends Primitive>(
    original?: WidenLiterals<T>,
    edited?: WidenLiterals<T>
) => {
    if (original === null) {
        return edited !== null;
    }

    if (original === undefined) {
        return edited !== undefined;
    }

    return original !== edited;
};
