// eslint-disable-next-line import/no-named-as-default
import DateFormatters from '../../../shared/utilities/date-formatters.js';
import AchAccountReconciliationRequests from '../../requests/ach/ach-account-reconciliation-requests.js';

const GENERAL_REPORT_PERIOD_OPTIONS = [
    { option: 'specific', id: 1 },
    { option: 'range', id: 1 },
    { option: 'previous-week', id: 3 },
    { option: 'previous-month', id: 5 },
    { option: 'previous-year', id: 7 },
    { option: 'week-to-date', id: 4 },
    { option: 'month-to-date', id: 6 },
    { option: 'year-to-date', id: 8 },
];

const PRIOR_TO_PERIOD_OPTIONS = [{ option: 'prior-to', id: 1 }];
const AS_OF_PERIOD_OPTIONS = [{ option: 'as-of', id: 1 }];

export default class AchAccountReconciliationService {
    static async fetchReports(parameters) {
        const reportsResponse = await AchAccountReconciliationRequests.searchReports(parameters);

        return reportsResponse?.items.map(report => ({
            externalReportTypeDisplayName: report.externalReportType?.displayName,
            selectedReportPeriodOption: this.getSelectedReportPeriodOption(report),
            ...report,
        }));
    }

    static async fetchReportFiles(parameters) {
        const reportFiles = await AchAccountReconciliationRequests.searchReportFiles(parameters);

        return reportFiles.items?.map(file => {
            const newFile = file;
            newFile.accountName =
                file.account?.accountDisplayLabel || file.account?.name || file.account?.number;
            newFile.createdBy = file.user?.name || file.user.loginID;
            const runDate = file.executionCompleteTime || file.executionStartTime || '';
            newFile.runDate = runDate.replace(/-/g, '/').slice(0, 10);
            newFile.reportType = file.externalReportType.displayName;
            return newFile;
        });
    }

    static async fetchReportTypes() {
        const reportTypes = await AchAccountReconciliationRequests.getReportTypes();
        return reportTypes?.map(reportType => ({
            reportPeriodOptions: this.getReportPeriodOptions(reportType.referenceId),
            ...reportType,
        }));
    }

    static async fetchAccounts() {
        const queryBody = {
            PagingParameters: {
                PageSize: 1000,
                PageNumber: 1,
                OrderBys: [
                    {
                        Name: 'Name',
                        Descending: false,
                    },
                ],
            },
        };
        const response = await AchAccountReconciliationRequests.searchAccounts(queryBody);
        return response.items;
    }

    static async fetchAvailableTimePeriods() {
        const timePeriodsResponse = await AchAccountReconciliationRequests.getTimePeriods();
        const timePeriodIds = timePeriodsResponse?.values.map(item => item.id);
        return GENERAL_REPORT_PERIOD_OPTIONS.filter(option => timePeriodIds.includes(option.id));
    }

    static async submitReport(report) {
        let mappedBody = {
            activityPeriodStart:
                DateFormatters.parseDate(report.activityPeriod)?.value?.start ??
                DateFormatters.parseDate(report.activityPeriod)?.value,
            activityPeriodEnd:
                DateFormatters.parseDate(report.activityPeriod)?.value?.end ??
                DateFormatters.parseDate(report.activityPeriod)?.value,
        };
        const selectedPeriodOption = this.getPeriodOptionsForType(report.type.referenceId).find(
            periodOption =>
                periodOption.option === report.activityPeriod.id ||
                periodOption.option === report.activityPeriod
        );
        if (selectedPeriodOption.id !== 1) {
            mappedBody.activityPeriodStart = null;
            mappedBody.activityPeriodEnd = null;
        }
        mappedBody = {
            ...mappedBody,
            reportName: report.reportName,
            accounts: report.accountIds.map(account => ({ id: account.id })),
            externalReportType: report.type,
            frequency: { id: 1 },
            timePeriod: { id: selectedPeriodOption.id },
            id: report.id,
        };
        return AchAccountReconciliationRequests.createUpdateReport(mappedBody);
    }

    static async runReport(reportId) {
        return AchAccountReconciliationRequests.runReport(reportId);
    }

    static async deleteReport(reportId) {
        return AchAccountReconciliationRequests.deleteReport(reportId);
    }

    static async deleteReportFiles(reportFileIds) {
        return Promise.all(
            reportFileIds.map(reportId =>
                AchAccountReconciliationRequests.deleteReportFile(reportId)
            )
        );
    }

    static async fetchReportFile(reportId, fileOutputType) {
        return AchAccountReconciliationRequests.downloadReportFile(reportId, fileOutputType);
    }

    //* Functions for mapping as-of and prior-to options - temporary until these are fully supported by the API
    // eslint-disable-next-line sonarjs/cognitive-complexity
    static getSelectedReportPeriodOption(report) {
        if (!report) return null;
        if (this.isAsOfReport(report.externalReportType?.referenceId)) {
            return 'as-of';
        }
        if (this.isPriorToReport(report.externalReportType?.referenceId)) {
            return 'prior-to';
        }
        if (report.timePeriod?.id === 1) {
            if (report.activityPeriodStart !== report.activityPeriodEnd) {
                return 'range';
            }
            return 'specific';
        }
        return GENERAL_REPORT_PERIOD_OPTIONS.find(
            periodOption => periodOption.id === report.timePeriod?.id
        )?.option;
    }

    static getPeriodOptionsForType(referenceId) {
        if (this.isAsOfReport(referenceId)) {
            return AS_OF_PERIOD_OPTIONS;
        }
        if (this.isPriorToReport(referenceId)) {
            return PRIOR_TO_PERIOD_OPTIONS;
        }
        return GENERAL_REPORT_PERIOD_OPTIONS;
    }

    static getReportPeriodOptions(referenceId) {
        return this.getPeriodOptionsForType(referenceId).map(x => x.option);
    }

    static isPriorToReport(referenceId) {
        if (!referenceId) return false;
        return ['AR2520', 'AR2520PSUM'].some(refId => refId === referenceId);
    }

    static isAsOfReport(referenceId) {
        if (!referenceId) return false;
        return ['AR2560', 'AR2580', 'AR2585'].some(refId => refId === referenceId);
    }

    // End of temporary functions
}
