export enum StatusType {
    Allow = 'Allow',
    VerifyToken = 'VerifyToken',
    Verify = 'Verify',
    VerifyUsingAlternate = 'VerifyUsingAlternate',
    Locked = 'Locked',
    Success = 'Success',
    SecureTokenLocked = 'SecureTokenLocked',
    Failure = 'Failure',
}
