import { string, number, boolean, list, money, date } from '@treasury/policy/primitives';
import BalanceReportsClient from '../clients/balance-reports-client.js';

// eslint-disable-next-line no-unused-vars
const client = new BalanceReportsClient();

export const columns = [
    {
        label: 'Account Number',
        field: 'accountNumber',
        summary: true,
    },
    {
        label: 'Account Name',
        field: 'accountName',
    },
    {
        label: 'Account Type',
        field: 'accountType',
    },
    {
        label: 'Current Balance',
        field: 'currentBalance',
        summary: true,
    },
    {
        label: 'Available Balance',
        field: 'availableBalance',
        summary: true,
    },
    {
        label: 'Collected Balance',
        field: 'collectedBalance',
        summary: true,
    },
];

export const filters = reportFilterValues => [
    {
        field: 'reportType',
        fieldType: string.with
            .options([{ text: 'Balance', value: 'Balance' }])
            .thatIs.disabled()
            .with.label('Report Type:'),
        value: reportFilterValues.reportType,
    },
    {
        field: 'dateType',
        fieldType: string.with
            .options([{ text: 'Current Day', value: 'Current Day' }])
            .thatIs.disabled()
            .with.label('Date:')
            .thatIs.required(),
        value: reportFilterValues.dateType,
    },
    {
        field: 'accountTypes',
        fieldType: string.with
            .options([
                { text: 'Checking', value: 'Checking' },
                { text: 'Savings', value: 'Savings' },
                { text: 'Checking and Savings', value: 'Checking,Savings' },
                { text: 'Loan', value: 'Loan' },
            ])
            .with.label('Account Type')
            .and.filtering()
            .thatIs.required(),
        value: reportFilterValues.accountTypes,
    },
    {
        field: 'accounts',
        fieldType: string.with
            .options({
                data: reportFilterValues.accounts,
                text: record => record.account.accountDisplayLabel,
                value: record => record,
            })
            .and.label('Account:')
            .with.filtering()
            .thatIs.required()
            .thatHas.placeholder('All Accounts'),
        value: reportFilterValues.accounts,
    },
    {
        field: 'sortBy',
        fieldType: string.with
            .options({
                data: [
                    { text: 'Account Number', value: 'AccountNumber' },
                    { text: 'Account Name', value: 'AccountName' },
                    { text: 'Account Type', value: 'AccountType' },
                    { text: 'Current Balance', value: 'CurrentBalance' },
                    { text: 'Available Balance', value: 'AvailableBalance' },
                    { text: 'Collected Balance', value: 'CollectedBalance' },
                    { text: 'Debits', value: 'Debits' },
                    { text: 'Credits', value: 'Credits' },
                ],
            })
            .and.label('Sort By:'),
        value: reportFilterValues.sortBy,
    },
    {
        field: 'isAscendingSort',
        fieldType: string.with.options([
            { text: 'Ascending', value: true },
            { text: 'Descending', value: false },
        ]),
        inline: true,
        value: reportFilterValues.isAscendingSort,
    },
];

export const reportFields = {
    totalCheckingBalance: money.thatIs.readOnly(), // 293451.22,
    totalSavingsBalance: money.thatIs.readOnly(), // 0.0,
    data: {
        accountName: string, // "Checking"
        accountNumber: string.with.reducer(values => `${values.length} Results`), // "xx8234"
        accountType: string, // "Checking"
        availableBalance: money.with.reducer('sum'), // 9177
        collectedBalance: money.with.reducer('sum'), // 9177
        creditCount: number, // null
        credits: number, // null
        currentBalance: money.with.reducer('sum'), // 9177
        debitCount: number, // null
        debits: number, // null
        floatDay1: number, // 0
        floatDay2: number, // null
        floatDay3: number, // null
    },
    title: string, // 'Current Day Balance : 5/13/2021',
    canEmail: boolean, // null,
    canPrint: boolean, // true,
    dateType: string, // 'Current Day',
    reportType: string, // 'Balance',
    totalRecords: number, // 3,
    downloadOptions: list, // ['PDF', 'CSV', 'BAI2'],
    sortBy: string, // 'AccountNumber',
    isAscending: boolean, // true,
    id: number, // 1,
    updatedBy: string, // null,
    updatedDate: date, // '0001-01-01T00:00:00',
};
