UploadBatchController.$inject = [
    '$scope',
    'achBatchImportsService',
    'achBatchService',
    '$modal',
    '$state',
    'modalService',
    'entitlementsService',
    'activityService',
    'resourceType',
    'auditCode',
];

export default function UploadBatchController(
    $scope,
    achBatchImportsService,
    achBatchService,
    $modal,
    $state,
    modalService,
    entitlementsService,
    activityService,
    resourceType,
    auditCode
) {
    $scope.nachaData = null;
    $scope.fileUid = null;
    $scope.achBatchImport = null;
    $scope.currentView = 'uploadFile';
    $scope.batch = null; // For editing single batch
    $scope.isReviewing = false;

    $scope.confirm = confirm;
    $scope.canUpload = canUpload;
    $scope.review = review;
    $scope.onRemoveFile = onRemoveFile;
    $scope.onSelectFile = onSelectFile;
    $scope.upload = upload;
    $scope.loadRecipients = loadRecipients;
    $scope.createBatch = createBatch;
    $scope.goBack = goBack;
    $scope.cancel = cancel;
    $scope.setForm = setForm;
    $scope.close = close;
    $scope.hasPermission = hasPermission;
    $scope.goToBatchList = goToBatchList;
    $scope.goToCreateBatch = goToCreateBatch;
    $scope.manualEntry = manualEntry;
    $scope.entryType = { selected: 'U' };
    $scope.wizardStep = 1;
    $scope.errorMessage = '';

    init();

    function init() {
        $scope.nachaData = {
            file: null,
        };
        $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
    }

    function canUpload() {
        return $scope.nachaData.file;
    }

    function upload() {
        achBatchImportsService.create($scope.nachaData).then(response => {
            $scope.achBatchImport = response;
            $scope.copyAchBatchImpport = angular.copy(response);
            if (response.errorMessage) {
                $scope.errorMessage = response.errorMessage;
            } else if (
                $scope.achBatchImport.achBatchSummaries &&
                $scope.achBatchImport.achBatchSummaries.length > 1
            ) {
                $scope.currentView = 'multipleBatch';
                $scope.wizardStep = 2;
            } else {
                response = response.achBatchSummaries[0];
                response.status = null;
                $scope.setUploadedBatch(response);

                $state.go('payables.ach.batch-detail', {
                    id: 0,
                    type: 'import',
                });
            }
        });
    }

    function hasPermission(permission) {
        return entitlementsService.hasEntitlement(permission);
    }

    function manualEntry() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelUploadNachaFile
                    );
                    $modalInstance.close();
                    $state.go('payables.ach.batch.create');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(null, () => {
                $scope.entryType.selected = 'U';
            });
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelUploadNachaFile
            );
            $state.go('payables.ach.batch.create');
        }
    }

    //
    // Events

    function onSelectFile(e) {
        if (e.files) {
            $scope.form.$setDirty();
            $scope.nachaData.file = e.files[0].rawFile;
            $scope.fileUid = e.files[0].uid;
            $scope.$apply();
        }
    }

    function onRemoveFile(e) {
        if ($scope.fileUid === e.files[0].uid) {
            $scope.nachaData.file = null;
            $scope.fileUid = null;
            $scope.errorMessage = null;
            $scope.$apply();
        }
    }

    //
    // Private

    function loadSingleBatch(batchId) {
        achBatchService.get(batchId).then(response => {
            $scope.currentView = 'singleBatch';
            $scope.batch = response;
        });
    }

    function loadRecipients(batch) {
        const modalInstance = $modal.open({
            template: require('../views/recipientsModalTemplate.html'),
            size: 'lg',
            controller: 'RecipientsModalController',
            backdrop: 'static',
            resolve: {
                batch() {
                    return batch;
                },
            },
        });
    }

    function review() {
        $('.view')[0].scrollTop = 0;
        $scope.isReviewing = true;
        $scope.wizardStep = 3;
    }

    function close() {
        $state.go('payables.ach.batch-list');
    }

    function confirm() {
        achBatchImportsService.update($scope.achBatchImport).then(response => {
            $('.view')[0].scrollTop = 0;
            $scope.isConfirmation = true;
            $scope.achBatchImport = response;
            $scope.wizardStep = 4;
        });
    }

    function goToBatchList() {
        $state.go('payables.ach.batch-list');
    }

    function goToCreateBatch() {
        $state.go('payables.ach.batch.create');
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to Cancel the Batch Now?',
                submit(result) {
                    $modalInstance.close();
                    $state.go('payables.ach.batch-list');
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $scope.nachaData = {
                file: null,
            };
            $scope.currentView = 'uploadFile';

            $state.go('payables.ach.batch-list');
        }
    }

    function createBatch() {
        $state.go('payables.ach.batch.create');
    }

    function goBack() {
        $scope.isReviewing = false;
        $scope.wizardStep = 2;
    }

    function setForm(form) {
        $scope.form = form;
    }
}
