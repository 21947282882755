/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { BankModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchBanksClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchBanks
     * @name AchBanksGet
     * @summary Gets all Banks.
     * @request GET:/achBanks
     */
    achBanksGet = (params: RequestParams = {}) =>
        this.http.request<BankModelDto[], any>({
            path: `/achBanks`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
