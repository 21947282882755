/* eslint-disable @treasury/style-includes-host-display */
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-badge.js';
import '@treasury/omega/components/omega-textarea.js';
import '@treasury/omega/components/progress/omega-progress';
import '@treasury/omega/layouts/omega-report';
import openDialog from '@treasury/omega/open-dialog.js';
import { css, html, LitElement, nothing } from 'lit';
import '../../components/blocking-loader.js';
import './ach-filter-detail-row.js';

import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import { ListeningElementMixin } from '@treasury/omega/components';
import { mix } from 'mixwith';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';
import AchFilterClient from './clients/ach-filter-client.js';
import { achFilterColumns } from './data/ach-filter-columns.js';
import achFilterFields from './data/ach-filter-fields.js';
import achFilterFilters from './data/ach-filter-filters.js';

class ACHFilterContainer extends mix(LitElement).with(channelAlertMixin, ListeningElementMixin) {
    static get properties() {
        return {
            actions: Object,
            institution: String,
            exceptions: Array,
            filterRecord: Array,
            filters: Array,
            alert: Object,
            loading: Boolean,
            reloading: Boolean,
            selectedAccountNumber: String,
            selectedRecord: Object,
            selectedRowIndex: Number,
            hasEntitlementToCreateFilter: Boolean,
            isAlertOpen: Boolean,
            landingPageMessage: String,
            reportActions: Array,
            reportLinks: Array,
            rowPerPage: Number,
            client: Object,
        };
    }

    constructor() {
        super();
        if (!this.client) {
            this.client = new AchFilterClient();
        }

        this.selectedAccountNumber = null;
        this.selectedRecord = null;
        this.actions = {
            Edit: (record, rowIndex) => this.createRecordFromRecordset(record, rowIndex, 'edit'),
            'Clone Criteria': (record, rowIndex) =>
                this.createRecordFromRecordset(record, rowIndex, 'clone'),
            Delete: record => this.deleteSelectedFilter(record.getField('achFilterUniqueId')),
            Approve: (record, rowIndex) =>
                this.createRecordFromRecordset(record, rowIndex, 'approve'),
            Reject: (record, rowIndex) =>
                this.createRecordFromRecordset(record, rowIndex, 'reject'),
        };

        this.pageTitle = 'ACH Exceptions - Filter Rules';
        this.loading = false;
        this.landingPageMessage = 'No ACH Exceptions - Filter Rules Found';
        this.rowsPerPage = 25;

        this.reportInformation = html`<div style="width: 335px;">
            Allows Treasury Management companies to proactively prevent or block unauthorized
            transactions by providing the ability to filter potentially fraudulent or unauthorized
            Automated Clearing House (ACH) transactions before they post to your account. <br />The
            account’s initial filter settings which is set by the Financial institution cannot be
            edited by a customer user
        </div>`;
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
    }

    get institution() {
        return this._institution;
    }

    set institution(value) {
        this._institution = value;
        this.reportLinks = [
            {
                url: `${this.institution}/payables/positive-pay/ach-exceptions-list`,
                title: 'ACH Exceptions ',
            },
            {
                url: `${this.institution}/payables/arp/ach-exceptions-activity-list`,
                title: 'ACH Exceptions - Decision Activity',
            },
        ];
        // Day 2
        /*
        this.reportActions = [
            {
                type: 'reject',
                label: 'Delete Selected',
                action: () => {
                    const markedForDeletion = this.recordset._filteredRecords.filter(
                        record => record.getField('selected') === true
                    );
                    const DeletedNames = markedForDeletion.map(record =>
                        record.getField('accountName')
                    );
                    this.alert = {
                        ...this.alert,
                        type: 'warning',
                        title: 'Are you sure?',
                        message: 'Are you sure you want to delete the selected filter rules?',
                        visible: true,
                        actions: html`<omega-button
                                type="primary"
                                @click=${() => this.deleteSelectedFilters(DeletedNames)}
                                >Confirm</omega-button
                            ><omega-button
                                @click=${() => {
                                    this.alert = { ...this.alert, visible: false };
                                }}
                                >Cancel</omega-button
                            > `,
                    };
                },
                isDisabled: () => this.recordset.noRecordsMatch('selected', true),
            },
        ];
        */
    }

    async firstUpdated() {
        this.hasAchFilterFeatureFlag = await EntitlementsService.hasEntitlement(
            'Feature.PositivePay.ACHFilterManagement'
        );
        if (!this.hasAchFilterFeatureFlag) {
            window.location.href = `/${this.institution}/payables/arp/ach-exception-filter`;
            return;
        }
        this.loading = true;
        const accountSettings = await this.client.getAccountSettings();
        this.fields = await achFilterFields(accountSettings);
        this.columns = await achFilterColumns(accountSettings);
        this.filters = await achFilterFilters(accountSettings);
        this.recordset = new Recordset(this.fields, this.client.search);
        this.listenTo(this.recordset, 'error', e => this.handleRecordsetError(e));
        this.listenTo(this.recordset, 'loading', ({ detail }) => {
            if (!detail.loading) {
                this.recordset.sortColumns.splice(1);
            }
        });

        this.hasEntitlementToCreateFilter = await this.client.canCreateAchFilter();
        this.loading = false;
    }

    resetSelectedRecords() {
        // eslint-disable-next-line no-return-assign
        this.recordset._filteredRecords.forEach(record => {
            // eslint-disable-next-line no-param-reassign
            if (record.values) record.values.isSelected = false;
        });
    }

    async approveSelectedFilters(selectedFilters) {
        this.reloading = true;
        this.alert = { ...this.alert, visible: false };
        try {
            await this.client.approveFilter(selectedFilters);
            await this.shadowRoot.querySelector('omega-report').runReport();
            this.resetSelectedRecords();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.reloading = false;
        }
    }

    async rejectSelectedFilters(selectedFilters) {
        this.reloading = true;
        this.alert = { ...this.alert, visible: false };
        try {
            await this.client.rejectFilter(selectedFilters);
            await this.shadowRoot.querySelector('omega-report').runReport();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.reloading = false;
        }
    }

    openConfirmDeleteDialog() {
        return openDialog(document.createElement('omega-dialog'), {
            title: 'Confirm Delete',
            content: html`<div style="text-align: center">
                <p>Are you sure you want to delete this filter rule?</p>
            </div>`,
            buttons: [
                { type: 'reject', label: `Delete`, result: 'delete' },
                { type: 'secondary', label: `Cancel`, result: 'cancel' },
            ],
        });
    }

    async deleteSelectedFilter(filterUniqueId) {
        this.reloading = true;
        const response = await this.openConfirmDeleteDialog();
        if (response === 'delete') {
            try {
                await this.client.deleteFilter(filterUniqueId);
                await this.shadowRoot.querySelector('omega-report').runReport();
                this.alert = {
                    visible: true,
                    title: '',
                    message: 'Filter rule deleted successfully.',
                    type: 'success',
                };
            } catch (e) {
                this.setAlertFromError(e);
            } finally {
                this.reloading = false;
            }
        } else {
            this.reloading = false;
        }
    }

    async download({ detail }) {
        this.reloading = true;

        try {
            await this.client.download(detail);
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.reloading = false;
        }
    }

    handleRecordsetError({ detail }) {
        const { error } = detail;
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'error',
            message: error.message,
            time: error.time,
            code: error.code,
        };
    }

    async createRecordFromRecordset(record, rowIndex, mode) {
        const { values } = record;
        const navService = (await DiContainer.getInstance()).get(NavigationService);

        if (mode === 'clone') {
            values.achFilterUniqueId = null;
        }

        navService.navigate('payables.arp.ach-filter-workflow-edit', {
            existingFilter: values,
            id: values.achFilterUniqueId,
        });
    }

    async fullPagePrint() {
        this.client.print('achFiltersFullPage');
    }

    async detailPrint(e) {
        this.client.print('achFiltersDetail', e.detail);
    }

    async goToCreateFilterPage() {
        const di = await DiContainer.getInstance();
        return di.get(NavigationService).navigate('payables.arp.ach-filter-workflow');
    }

    renderDetail(record, close) {
        return html`<ach-filter-detail-row
            .record=${record}
            .closeFunction=${close}
            @detailPrint=${this.detailPrint}
        ></ach-filter-detail-row>`;
    }

    renderBlockingLoader() {
        if (!this.loading && !this.reloading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    renderValidatedCallToAction() {
        if (this.hasEntitlementToCreateFilter) return 'Create New Filter';
        return nothing;
    }

    render() {
        if (this.loading || !this.recordset) return nothing;
        return html`
            ${this.renderBlockingLoader()}${this.renderAlert()}
            <omega-report
                flyout
                autostart
                .title=${this.pageTitle}
                .recordset=${this.recordset}
                .filters=${this.filters}
                .reportLinks=${this.reportLinks}
                .reportInformation=${this.reportInformation}
                .reportActions=${this.reportActions}
                displayToggleAll
                .options=${['print', 'download']}
                .downloadOptions=${['CSV']}
                .columns=${this.columns}
                @reportDownload=${this.download}
                .actions=${this.actions}
                .detailFunction=${(record, close) => this.renderDetail(record, close)}
                .callToAction=${this.renderValidatedCallToAction()}
                @callToAction=${this.goToCreateFilterPage}
                @pagePrint=${this.fullPagePrint}
                @detailPrint=${this.detailPrint}
                .landingPageMessage=${this.landingPageMessage}
                .rowsPerPage=${this.rowPerPage}
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
                display: inherit;
            }
            omega-alert {
                width: 500px;
            }
        `;
    }
}

export default ACHFilterContainer;
window.customElements.define('ach-filter-container', ACHFilterContainer);
