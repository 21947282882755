import { FieldType, Record } from '@treasury/FDL';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-file-upload';
import '@treasury/omega/components/omega-radio-group';
import { DialogUtility } from '@treasury/omega/util/dialog-utility';
import fieldTypes from '@treasury/policy/primitives';
import { exists } from '@treasury/utils';
import { css, html, LitElement, nothing } from 'lit';
import { state } from 'lit/decorators.js';

const tag = 'recipient-file-upload-dialog';

const util = new DialogUtility(tag);

type FileFormat = 'delimited' | 'fixedPosition';
interface RecipientFileUpload {
    files?: Array<File>;
    format: FileFormat;
}
export class RecipientFileUploadDialog extends LitElement {
    @state()
    record!: Record<RecipientFileUpload>;

    @state()
    error: string | null = null;

    static open(context: Element) {
        util.openFromParentContainer(context);
    }

    handleOpen() {
        this.record.setField('format', 'delimited');
        this.record.setField('files', undefined);
    }

    close() {
        util.close(this);
    }

    upload() {
        this.dispatchEvent(
            new CustomEvent<RecipientFileUpload>('upload', {
                detail: {
                    files: this.record.getField('files'),
                    format: this.record.getField('format'),
                },
            })
        );
        this.close();
    }

    async firstUpdated() {
        this.record = new Record<RecipientFileUpload>(
            {
                format: new FieldType<FileFormat>().with
                    .options({
                        data: [
                            { value: 'delimited' as const, text: 'Delimited' },
                            { value: 'fixedPosition' as const, text: 'Fixed' },
                        ],
                        text: option => `${option.text}`,
                        value: option => option.value,
                    })
                    .and.inline()
                    .thatIs.required(),
                files: fieldTypes.file.with.multipleValues(0, 3).and.required() as FieldType<
                    RecipientFileUpload['files']
                >,
            },
            {
                format: 'delimited',
            }
        );
    }

    isDisabled() {
        return exists(this.error) || !this.record?.isValid() || !this.record?.hasRequiredValues();
    }

    renderContent() {
        if (!this.record) return nothing;
        return html`
            <omega-field .record=${this.record} field="format"></omega-field>
            <br />
            ${this.error ? html`<p class="error">${this.error}</p>` : nothing}
            <omega-field
                .record=${this.record}
                field="files"
                @invalidFileSize=${(e: any) => {
                    this.error = 'You have uploaded an invalid file size';
                }}
                @change=${() => {
                    this.error = null;
                    this.requestUpdate();
                }}
            ></omega-field>
        `;
    }

    render() {
        return html`<omega-dialog
            id=${util.dialogId}
            dialogTitle="Import Recipients"
            @close=${() => this.close()}
            @open=${() => this.handleOpen()}
        >
            <div slot="content">
                <div class="dialog-content">${this.renderContent()}</div>
                <hr />
            </div>
            <omega-button
                .disabled=${this.isDisabled()}
                slot="actions"
                type="primary"
                @click=${() => this.upload()}
                >Upload</omega-button
            >
            <omega-button slot="actions" type="link" @click=${() => this.close()}
                >Cancel</omega-button
            >
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
            .error {
                color: var(--omega-error, red);
                text-align: center;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
customElements.define(tag, RecipientFileUploadDialog);
