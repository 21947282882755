/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { OkResultDto, TimeZoneModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ProcessingTimesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ProcessingTimes
     * @name ProcessingTimesGetTimeZones
     * @summary Gets FI time zone.
     * @request GET:/processingTimes/timeZones
     */
    processingTimesGetTimeZones = (params: RequestParams = {}) =>
        this.http.request<any[], any>({
            path: `/processingTimes/timeZones`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcessingTimes
     * @name ProcessingTimesUpdateTimeZone
     * @summary Updates FI time zone information.
     * @request PUT:/ProcessingTimes/TimeZone
     */
    processingTimesUpdateTimeZone = (
        timeZoneModel: TimeZoneModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, any>({
            path: `/ProcessingTimes/TimeZone`,
            method: 'PUT',
            body: timeZoneModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
