import { AlarmClock } from '@treasury/alarm-clock';
import { AchExceptionDetail, PayOrReturnChoice } from '@treasury/domain/channel/mappings/ach';
import {
    ACH_EXCEPTION_CUTOFF,
    ACH_EXCEPTION_START,
} from '@treasury/domain/channel/types/arp/constants.js';
import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import fieldTypes from '@treasury/policy/primitives';

export const fields: FdlFieldDefinitions<AchExceptionDetail> = {
    pay: fieldTypes.boolean.thatIs.readOnly() as FieldType<boolean | undefined>,
    return: fieldTypes.boolean.thatIs.readOnly() as FieldType<boolean | undefined>,
    accountNumber: fieldTypes.string.thatIs.readOnly() as FieldType<string>,
    achCompanyName: fieldTypes.string.thatIs.readOnly() as FieldType<string>,
    amount: fieldTypes.money.thatIs.readOnly() as FieldType<number>,
    // eslint-disable-next-line @treasury/no-date
    postedDate: fieldTypes.date.thatIs.readOnly() as FieldType<Date | undefined>,
    exceptionType: fieldTypes.string.thatIs.readOnly() as FieldType<string>,
    secCode: fieldTypes.string.thatIs.readOnly() as FieldType<string>,
    entryDescription: fieldTypes.string.thatIs.readOnly() as FieldType<string>,
    decisionTaken: fieldTypes.string.thatIs.readOnly().thatHas.template((value: number, record) => {
        const decision = record.getField('decisionToPayOrReturn');
        if (decision === PayOrReturnChoice.Pay) return 'Pay';
        if (decision === PayOrReturnChoice.Return) return 'Return';
        return '';
    }),
};

export const columns = (hasEntitlement: boolean) => {
    const cols: OmegaColumnDefinition<AchExceptionDetail>[] = [
        {
            field: 'decisionToPayOrReturn',
            label: 'Pay',
            value: PayOrReturnChoice.Pay,
            type: OmegaColumnType.RadioIcon,
        },
        {
            field: 'decisionToPayOrReturn',
            label: 'Return',
            value: PayOrReturnChoice.Return,
            type: OmegaColumnType.RadioIcon,
        },
        {
            field: 'accountNumber',
            label: 'Account',
        },
        {
            field: 'achCompanyName',
            label: 'ACH Company',
        },
        {
            field: 'amount',
            label: 'Amount',
        },
        {
            field: 'postedDate',
            label: 'Posted Date',
        },
        {
            field: 'exceptionType',
            label: 'Type',
        },
        {
            field: 'secCode',
            label: 'SEC Code',
        },
        {
            field: 'entryDescription',
            label: 'Description',
        },
    ];

    const fiClock = AlarmClock.getInstance();
    if (fiClock.isBefore(ACH_EXCEPTION_START) || fiClock.isAfter(ACH_EXCEPTION_CUTOFF)) {
        cols.splice(0, 2, { field: 'decisionTaken', label: 'Decision' });
        return cols;
    }

    if (hasEntitlement)
        cols.push({
            label: '',
            type: OmegaColumnType.Actions,
            actions: [
                {
                    label: `Create Filter Rule`,
                    action: 'createFilterRule',
                },
            ],
        });
    return cols;
};
