TaxPaymentDetailController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    'achPaymentsService',
    'achPaymentTypes',
    'taxesService',
    'securityService',
    'modalService',
    'scopeService',
    'downloadPageId',
];

export default function TaxPaymentDetailController(
    $scope,
    $state,
    $uibModal,
    achPaymentsService,
    achPaymentTypes,
    taxesService,
    securityService,
    modalService,
    scopeService,
    downloadPageId
) {
    $scope.id = $state.params.id;
    $scope.errorMessage = null;
    $scope.errorDetails = [];
    $scope.edit = edit;
    $scope.review = review;
    $scope.cancelEdit = cancelEdit;
    $scope.confirm = confirm;
    $scope.goBack = goBack;
    $scope.goBackToActivityPage = goBackToActivityPage;
    $scope.isFederalTaxPayment = isFederalTaxPayment;
    $scope.isStateTaxPayment = isStateTaxPayment;
    $scope.hasValidStatusForEdit = hasValidStatusForEdit;
    $scope.canEditPayment = canEditPayment;
    $scope.canFullEdit = canFullEdit;
    $scope.canCopyPayment = canCopyPayment;
    $scope.copyPayment = copyPayment;
    $scope.canApprove = canApprove;
    $scope.canReject = canReject;
    $scope.approve = approve;
    $scope.reject = reject;
    $scope.downloadPageId = downloadPageId.AchPaymentDetail;

    function init() {
        loadPayment();

        $scope.isNewPayment = false;
        $scope.isDetail = true;
        $scope.isEditing = false;
        $scope.restrict = false;
        $scope.isConfirmation = false;
    }

    function loadPayment() {
        return achPaymentsService.getDetails($scope.id).then(response => {
            setPayment(response.payment);
        });
    }

    function setPayment(batch) {
        // If you set $scope.batch = batch then it will be set on this scope only. The parent scopes
        // have a reference to $scope.batch so you can't update the reference. You have to update
        // the referenced object for the changes to apply to the parent scopes.
        angular.copy(batch, $scope.batch);

        setPayFromAccount(batch);
        $scope.setState(batch.stateId);
        setTaxCode(batch);
    }

    function setTaxCode(batch) {
        if (isStateTaxPayment()) {
            if (!$scope.stateTaxCodes || $scope.stateTaxCodes.length === 0) {
                taxesService.getStateTaxCodes(batch.stateId).then(response => {
                    $scope.stateTaxCodes.length = 0;
                    for (let i = 0; i < response.length; i++) {
                        $scope.stateTaxCodes.push(response[i]);
                    }
                    setStateTaxCode($scope.batch, batch.taxCode);
                });
            } else {
                setStateTaxCode(batch, batch.taxCode);
            }
        } else if (isFederalTaxPayment()) {
            whenDefined('federalTaxCodes', () => {
                setFederalTaxCodes(batch.taxCode);
            });
        }
    }

    function setPayFromAccount(batch) {
        const { achCompany } = batch;
        if (achCompany) {
            if (achCompany.batchBalanceRequirements === 'Balanced') {
                $scope.batch.balancedAccount = $scope.userAccounts.find(
                    item => batch.payFromAccountId === item.id
                );

                $scope.payFromAccountId = batch.payFromAccountId;
            } else {
                whenDefined('offsetAccounts', () => {
                    $scope.payFromAccountId = batch.payFromAccountId;
                    $scope.batch.offsetAccount = $scope.offsetAccounts.find(
                        item => batch.payFromAccountId === item.id
                    );
                });
            }
        }
    }

    function setFederalTaxCodes(code) {
        const taxCode = $scope.federalTaxCodes.find(taxCode => taxCode.taxCode === code);

        $scope.batch.selectedFederalTaxCode = taxCode;
        $scope.taxCode = code;

        taxesService.getFederalTaxAmountTypes(taxCode.id).then(amountTypes => {
            $scope.federalTaxAmountTypes.length = 0;
            $scope.federalTaxAmountTypes.push.apply($scope.federalTaxAmountTypes, amountTypes);
        });
    }

    function setStateTaxCode(batch, taxCode) {
        $scope.batch.selectedStateTaxCode = $scope.stateTaxCodes.find(
            item => taxCode === item.taxCode
        );

        $scope.batch.taxCode = taxCode;
    }

    function edit() {
        $scope.isDetail = false;
        $scope.isEditing = true;
    }

    function review() {
        $scope.isReview = true;
        $scope.isEditing = false;
    }

    function cancelEdit() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel Payment Information?',
                submit(result) {
                    $modalInstance.close();

                    goBackToActivityPage();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            goBackToActivityPage();
        }
    }

    function confirm() {
        securityService
            .verifyUser('Edit ACH Payment', $scope.batch, () =>
                achPaymentsService.update($scope.id, $scope.batch)
            )
            .then(response => {
                setPayment(response.payment);

                $scope.batch.successMessage = response.successMessage;
                $scope.batch.errorMessage = response.errorMessage.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br>'
                );
                $scope.batch.numberOfApprovalsNeeded = response.payment.numberOfApprovalsNeeded;

                $scope.isReview = false;
                $scope.isConfirmation = true;
            });
    }

    function goBack() {
        $scope.isReview = false;
        $scope.isEditing = true;
    }

    function goBackToActivityPage() {
        $state.go('payables.ach.payment-list');
    }

    function whenDefined(expression, callback) {
        scopeService.whenDefined(expression, callback, $scope);
    }

    function isStateTaxPayment() {
        return $scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment;
    }

    function isFederalTaxPayment() {
        return $scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment;
    }

    function hasValidStatusForEdit() {
        switch ($scope.batch.status) {
            case 'Pending Approval':
                return false;
            case 'Cancelled':
                return false;
            case 'Posted':
                return false;
            case 'Initiated':
                return false;
            case 'Approval Rejected':
                return true;
            case 'Failed':
                return true;
            case 'Uninitiated':
                return true;
            case 'Expired':
                return true;
            case 'Scheduled':
                return true;
        }

        return false;
    }

    function canEditPayment() {
        return hasValidStatusForEdit() && ($scope.achFull || $scope.achPartial);
    }

    function canFullEdit() {
        return $scope.achFull;
    }

    function canCopyPayment() {
        return $scope.achCreate;
    }

    function copyPayment() {
        $state.go('payables.ach.payments.createTaxPayment', { paymentToCopy: $scope.batch });
    }

    function showApproveOrRejectConfirmation(type) {
        return $uibModal.open({
            template: require('../../payments/views/approveRejectPaymentModal.html'),
            size: 'lg',
            controller: 'ApproveRejectPaymentController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        }).result;
    }

    function setResponseErrors(transaction) {
        $scope.errorMessage = null;
        $scope.errorDetails.length = 0;
        const { errorSummary } = transaction;
        if (!errorSummary || !errorSummary.summaryMessage) {
            return false;
        }

        $scope.errorMessage = errorSummary.summaryMessage;
        errorSummary.details.forEach(detail => {
            if (!detail.message) {
                return;
            }
            if (detail.messageList && detail.messageList.length > 0) {
                const messages = detail.messageList.map(message => message.value);
                Array.prototype.push.apply($scope.errorDetails, messages);
                return;
            }
            $scope.errorDetails.push(detail.message);
        });
        return true;
    }

    function approve() {
        showApproveOrRejectConfirmation('approve').then(comments => {
            const message = { id: $scope.id, value: comments };
            securityService
                .verifyUser('Approve ACH Payment', message, () =>
                    achPaymentsService.approve(message.id, message)
                )
                .then(response => {
                    const hasError = setResponseErrors(response);
                    if (!hasError) {
                        $state.go('payables.ach.payment-list');
                    }
                });
        });
    }
    function reject() {
        showApproveOrRejectConfirmation('reject').then(comments => {
            const message = { id: $scope.id, value: comments };
            securityService
                .verifyUser('Reject ACH Payment', message, () =>
                    achPaymentsService.reject(message.id, message)
                )
                .then(response => {
                    const hasError = setResponseErrors(response);
                    if (!hasError) {
                        $state.go('payables.ach.payment-list');
                    }
                });
        });
    }

    function canApprove() {
        if (!$scope.batch || !$scope.batch.permissions) {
            return false;
        }

        const hasPermission = $scope.batch.permissions.some(item => item.permission === 'Approve');
        return $scope.batch.status === 'Pending Approval' && hasPermission;
    }

    function canReject() {
        if (!$scope.batch || !$scope.batch.permissions) {
            return false;
        }

        const hasPermission = $scope.batch.permissions.some(
            item => item.permission === 'RejectPayment'
        );
        return $scope.batch.status === 'Pending Approval' && hasPermission;
    }

    init();
}
