/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AnalysisTransactionCodeDto,
    AnalysisTransactionTypeDto,
    CustomTransactionCodeDto,
    LookupModelDto,
    OfxTransactionTypeModelDto,
    TransactionCodeModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class TransactionCodesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesSyncDetails
     * @summary Retrieves the Transaction Code Details between channel and CORE.
     * @request GET:/transactionCodes/syncdetails
     */
    transactionCodesSyncDetails = (params: RequestParams = {}) =>
        this.http.request<any, TransactionCodeModelDto[]>({
            path: `/transactionCodes/syncdetails`,
            method: 'GET',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesGetAnalysisTransactionCodesForInstitution
     * @summary Returns a listing of AnalysisTransactionCodes that have been assigned to an institution
     * @request GET:/TransactionCodes/getAnalysisTransactionCodesForInstitution
     */
    transactionCodesGetAnalysisTransactionCodesForInstitution = (params: RequestParams = {}) =>
        this.http.request<any, AnalysisTransactionCodeDto[]>({
            path: `/TransactionCodes/getAnalysisTransactionCodesForInstitution`,
            method: 'GET',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesGetAnalysisTransactionTypes
     * @summary Returns a list of all transaction types
     * @request GET:/TransactionCodes/getAnalysisTransactionTypes
     */
    transactionCodesGetAnalysisTransactionTypes = (params: RequestParams = {}) =>
        this.http.request<any, AnalysisTransactionTypeDto[]>({
            path: `/TransactionCodes/getAnalysisTransactionTypes`,
            method: 'GET',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesGetCustomTransactionCodesForCompany
     * @summary Returns a collection of all of the custom transaction codes for the requested company
     * @request GET:/transactionCodes/company/{companyId}/category/{analysisTransactionTypeCategoryId}/getCustomTransactionCodesForCompany
     */
    transactionCodesGetCustomTransactionCodesForCompany = (
        analysisTransactionTypeCategoryId: number,
        companyId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<any, CustomTransactionCodeDto[]>({
            path: `/transactionCodes/company/${companyId}/category/${analysisTransactionTypeCategoryId}/getCustomTransactionCodesForCompany`,
            method: 'GET',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesSync
     * @summary Imports the Transaction Code Changes between Channel and CORE.
     * @request POST:/transactionCodes/sync
     */
    transactionCodesSync = (params: RequestParams = {}) =>
        this.http.request<any, TransactionCodeModelDto[]>({
            path: `/transactionCodes/sync`,
            method: 'POST',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesSaveAnalysisTransactionCodes
     * @summary Saves the posted AnalysisTransactionCode's to the database
     * @request POST:/transactionCodes/SaveAnalysisTransactionCodes
     */
    transactionCodesSaveAnalysisTransactionCodes = (
        analysisTransactionCodes: AnalysisTransactionCodeDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<any, AnalysisTransactionCodeDto[]>({
            path: `/transactionCodes/SaveAnalysisTransactionCodes`,
            method: 'POST',
            body: analysisTransactionCodes,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesSaveCustomTransactionCodes
     * @summary Posts custom transaction codes associated to a analysis transaction type category.
     * @request POST:/transactionCodes/company/{companyId}/category/{analysisTransactionTypeCategoryId}/SaveCustomTransactionCodes
     */
    transactionCodesSaveCustomTransactionCodes = (
        companyId: number,
        analysisTransactionTypeCategoryId: number,
        customTransactionCodes: CustomTransactionCodeDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<any, CustomTransactionCodeDto[]>({
            path: `/transactionCodes/company/${companyId}/category/${analysisTransactionTypeCategoryId}/SaveCustomTransactionCodes`,
            method: 'POST',
            body: customTransactionCodes,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesClone
     * @summary Clone the groups from the specified transaction code Id to the passed list of transaction code Ids.
     * @request PUT:/transactionCodes/{id}/clone
     */
    transactionCodesClone = (
        id: number,
        transactionCodeIds: LookupModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<any, boolean>({
            path: `/transactionCodes/${id}/clone`,
            method: 'PUT',
            body: transactionCodeIds,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesGetOfxTransactionTypes
     * @summary Gets transaction codes for OFX
     * @request GET:/TransactionCodes/getOfxTransactionTypes
     */
    transactionCodesGetOfxTransactionTypes = (params: RequestParams = {}) =>
        this.http.request<any, OfxTransactionTypeModelDto[]>({
            path: `/TransactionCodes/getOfxTransactionTypes`,
            method: 'GET',
            ...params,
        });
}
