import { Injectable } from '@jack-henry/frontend-utils/di';
import { AlarmClock } from '@treasury/alarm-clock';
import {
    ArpClient,
    ArpExceptionDetailDataModelDto,
    CheckExceptionClient,
    CheckExceptionCorrectionClient,
    CompanyAccountModelDto,
    CompanyAccountsClient,
    ExceptionCorrectionModelDto,
    InstitutionCheckExceptionReturnReasonClient,
    InstitutionCheckExceptionReturnReasonDto,
    ProcessingCutoffDto,
    SaveCheckExceptionAttachmentRequestModelDto,
} from '@treasury/api/channel';
import { createUniqueId } from '@treasury/utils';
import {
    CHECK_EXCEPTION_CUTOFF,
    CHECK_EXCEPTION_CUTOFF_MINUS_30,
    CHECK_EXCEPTION_START,
} from '../channel/types/arp/constants';
import { downloadBlob, toBase64 } from '../utilities/file-handling';
import { CheckException } from './check-exception.entity';
import { mapSearchParams } from './mappings/check-exception-search.mappers';

@Injectable()
export class CheckExceptionService {
    constructor(
        private arpClient: ArpClient,
        private checkCorrectionClient: CheckExceptionCorrectionClient,
        private checkExceptionClient: CheckExceptionClient,
        private returnReasonClient: InstitutionCheckExceptionReturnReasonClient,
        private companyAccountsClient: CompanyAccountsClient
        // eslint-disable-next-line no-empty-function
    ) {}

    private initPromise?: Promise<
        [ProcessingCutoffDto | undefined, InstitutionCheckExceptionReturnReasonDto[], boolean]
    >;

    private _cutoffTimePromise?: Promise<ProcessingCutoffDto | undefined> = this.getCutoffTime();

    private _cutoffTime?: ProcessingCutoffDto;

    private _returnReasonsPromise = this.getReturnReasons();

    private _returnReasons?: InstitutionCheckExceptionReturnReasonDto[];

    public testId = createUniqueId();

    public get cutoffTime() {
        if (!this._cutoffTime) {
            throw new Error('check exception service initialization has not resolved');
        }
        return this._cutoffTime;
    }

    public get returnReasons() {
        if (!this._returnReasons) {
            throw new Error('check exception service initialization has not resolved');
        }
        return this._returnReasons;
    }

    private get fiClock() {
        return AlarmClock.getInstance();
    }

    public get isAfterCutoff(): boolean {
        if (
            this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF_MINUS_30) &&
            this.fiClock.isBefore(CHECK_EXCEPTION_CUTOFF)
        ) {
            return false;
        }
        return this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF);
    }

    public get isBeforeCutoff(): boolean {
        return this.fiClock.isBefore(CHECK_EXCEPTION_START);
    }

    public async ready() {
        if (!this.initPromise) {
            this.initPromise = Promise.all([
                this._cutoffTimePromise,
                this._returnReasonsPromise,
                this.fiClock.checkReadiness(),
            ]);
            [this._cutoffTime, this._returnReasons] = await this.initPromise;
        }
        return this.initPromise;
    }

    async getCheckExceptions(): Promise<CheckException[]> {
        const arpExceptionAccounts = (
            await this.arpClient.arpGetArpAccounts('WorkArpExceptions', {})
        ).data.map((account: CompanyAccountModelDto) => account.accountUniqueId);
        const parameters = { account: arpExceptionAccounts };
        const body = mapSearchParams(parameters);
        const searchCheckExceptionsResponse = (
            await this.checkExceptionClient.checkExceptionSearchCheckExceptions(body)
        ).data;
        if (!searchCheckExceptionsResponse.items) return [];
        return searchCheckExceptionsResponse.items.map(
            exception => new CheckException(exception, this)
        );
    }

    async getCutoffTime(): Promise<ProcessingCutoffDto | undefined> {
        return (
            await this.companyAccountsClient.companyAccountsGetCutoffTimes()
        ).data.processingCutoffs?.find(cutoff => cutoff.productType === 'Check Exceptions');
    }

    async getReturnReasons(): Promise<InstitutionCheckExceptionReturnReasonDto[]> {
        return (
            await this.returnReasonClient.institutionCheckExceptionReturnReasonGetCheckExceptionReturnReasons()
        ).data;
    }

    async getAttachmentName(checkExceptionId: string): Promise<string | null> {
        const res = await this.checkExceptionClient.checkExceptionGetAttachment(checkExceptionId);
        return res?.headers?.get('x-filename');
    }

    async downloadAttachment(checkExceptionId: string) {
        const res = await this.checkExceptionClient.checkExceptionGetAttachment(checkExceptionId);

        const filename = res.headers.get('x-filename');
        const contentType = res.headers.get('content-type');
        const buffer = await res.arrayBuffer();
        const blob = new Blob([buffer], { type: contentType ?? '' });
        return downloadBlob(blob, filename);
    }

    async submitCheckExceptions(checkExceptions: CheckException[]): Promise<boolean> {
        return (
            await this.arpClient.arpSaveArpExceptions(
                checkExceptions.map(
                    exception =>
                        exception.getDtoForSubmit() as unknown as ArpExceptionDetailDataModelDto
                ),
                {}
            )
        ).data;
    }

    async saveAttachmentAndComment(
        checkException: CheckException,
        comment: string,
        attachment?: File
    ) {
        const body = {
            comment,
        } as SaveCheckExceptionAttachmentRequestModelDto;
        if (attachment) {
            try {
                body.attachment = await toBase64(attachment);
            } catch (e) {
                console.error(e);
            }
            body.fileName = attachment?.name;
        }
        return (
            await this.checkExceptionClient.checkExceptionSaveAttachment(checkException.guid, body)
        ).data;
    }

    async submitCheckExceptionCorrection(correction: ExceptionCorrectionModelDto) {
        return this.checkCorrectionClient.checkExceptionCorrectionRequestExceptionCorrection(
            correction
        );
    }
}
