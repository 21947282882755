import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';
import { isIncomingDateValid as incomingDateValidator } from '@treasury/domain/channel/types/ach';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

/**
 * @param { ng.resource.IResourceService } $resource
 * @param { ng.IFilterService } $filter
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
export function AchPaymentsService($resource, $filter, $q, achPaymentTypes, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}achPayments`;

    return {
        search,
        create,
        createTaxPaymentsFromTemplates,
        uploadNachaFile,
        update,
        getDetails,
        batchApproveOrReject,
        frequencyTypeToFriendly,
        approve,
        reject,
        summarizeFrequency,
        cancel,
        uninitiate,
        getBatchDetails,
        getOffsetAccounts,
        getPaymentDraftDetail,
        isTaxPayment,
        isChildSupportPayment,
        isInternationalAchPayment,
        isIncomingDateValid,
    };

    //
    // Private

    function transformParseIssuedItemsRequest(data) {
        const fd = new FormData();
        fd.append('file', data.file);
        return fd;
    }

    function search(message, type) {
        const requestWithoutAudit = { ...message, isBasic: true };
        return $resource(
            `${resourceUrl}/FilterPayments`,
            { type },
            { search: { method: 'POST', isArray: true } }
        ).search(requestWithoutAudit).$promise;
    }

    /**
     *
     * @param { import('@treasury/api/channel').AchPaymentModelDto } data
     * @returns { Promise<import('@treasury/api/channel').AchEditablePaymentModelDto>}
     */
    function create(data) {
        const { achPaymentTypeId } = data;
        const isTaxPayment =
            achPaymentTypeId === achPaymentTypes.AchStateTaxPayment ||
            achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment;

        if (isTaxPayment) {
            if (data.achCompany.prefundingDays > 0 || data.achCompany.allowUnbalancedPayments) {
                data.payFromAccountId = null;
            } else {
                data.payFromAccountId = getPayFromAccountId(data);
            }
        }

        /**
         * @type { 'state' | 'federal'}
         */
        let taxType;
        let amount = 0;
        let amountType;

        /**
         * @type {Promise<import('@treasury/api/channel').AchEditablePaymentModelDto> }
         */
        let promise;
        if (achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            /**
             * @type {import('@treasury/api/channel').AchFederalTaxPaymentAmountModelDto[]}
             */
            const amounts = data.achFederalTaxPaymentAmounts || [];
            amount = amounts[0].amount;
            amountType = amounts[0].amountType;
            taxType = 'federal';

            promise = $resource(`${resourceUrl}/federalTaxPayment`).save(data).$promise;
        } else if (achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            /**
             * @type {import('@treasury/api/channel').AchStateTaxPaymentAmountModelDto[]}
             */
            const amounts = data.achStateTaxPaymentAmounts || [];
            amount = amounts[0].amount;
            amountType = amounts[0].amountType;

            taxType = 'state';
            promise = $resource(`${resourceUrl}/stateTaxPayment`).save(data).$promise;
        } else {
            promise = $resource(`${resourceUrl}/payment`).save(data).$promise;
        }

        promise.then(() => {
            if (!isTaxPayment) {
                return;
            }

            const analytics = TmDi.get(AnalyticsService);
            analytics.track(AnalyticsEvent.AchTaxPayment, {
                taxType,
                amount,
                amountType,
            });
        });

        return promise;
    }

    function createTaxPaymentsFromTemplates(payment) {
        payment.achBatchSummaries.forEach(batch => {
            batch.payFromAccountId = getPayFromAccountId(batch);
        });

        payment.type = 'AchPaymentWithTemplates';
        return $resource(`${resourceUrl}/taxPayments`).save(payment).$promise;
    }

    function getPayFromAccountId(batch) {
        if (
            batch.achPaymentTypeId !== achPaymentTypes.AchStateTaxPayment &&
            batch.achPaymentTypeId !== achPaymentTypes.AchFederalTaxPayment
        ) {
            return null;
        }
        if (batch.achCompany.prefundingDays > 0) {
            return null;
        }

        if (batch.achCompany.batchBalanceRequirements === 'Balanced') {
            return batch.balancedAccount.id;
        }

        return batch.offsetAccount.id;
    }

    function getPaymentDraftDetail(paymentDrafts) {
        return $resource(`${resourceUrl}/paymentDrafts`, {}, { search: { method: 'POST' } }).search(
            paymentDrafts
        ).$promise;
    }

    function getBatchDetails(id, batchId) {
        return $resource(`${resourceUrl}/:id/batches/:batchId`, {
            id,
            batchId,
        }).get().$promise;
    }

    function uploadNachaFile(nachaData) {
        return $resource(
            `${resourceUrl}/upload`,
            {},
            {
                save: {
                    method: 'POST',
                    transformRequest: transformParseIssuedItemsRequest,
                    headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' },
                },
            }
        ).save(nachaData).$promise;
    }

    function getDetails(id) {
        return $resource(`${resourceUrl}/:id`, { id }).get().$promise;
    }

    /**
     *
     * @param { import('@treasury/api/channel').LookupListModelDto } batch
     * @param { 'approve' | 'reject' } type
     */
    function batchApproveOrReject(batch, type) {
        /**
         * @type { Promise<import('@treasury/api/channel').AchPaymentListModelDto> }
         */
        const p = $resource(`${resourceUrl}/batch?action=:action`, { action: type }).save(
            batch
        ).$promise;

        if (type === 'approve') {
            Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([response, analytics]) => {
                response.achPayments.forEach(({ id }) => {
                    analytics.track(AnalyticsEvent.AchPaymentApproved, {
                        paymentId: id.toString(),
                    });
                });
            });
        }

        return p;
    }

    function update(paymentId, payment) {
        const resource = $resource(
            `${resourceUrl}/:id`,
            { id: paymentId },
            { update: { method: 'PUT' } }
        );
        return resource.update(payment).$promise;
    }

    /**
     * @param { number } id
     * @param { import('@treasury/api/channel').LookupModelDto } message
     */
    function approve(id, message) {
        const resource = $resource(`${resourceUrl}/:id/approve`, { id });
        return $q
            .all([resource.save(message).$promise, TmDi.getAsync(AnalyticsService)])
            .then(([response, analytics]) => {
                analytics.track(AnalyticsEvent.AchPaymentApproved, {
                    paymentId: id.toString(),
                });
                return response;
            });
    }

    function reject(id, message) {
        return $resource(`${resourceUrl}/:id/reject`, { id }).save(message).$promise;
    }

    function frequencyTypeToFriendly(frequencyType) {
        if (frequencyType === null) {
            return 'Undefined';
        }

        let result = frequencyType;

        switch (frequencyType) {
            case 'EveryTwoWeeks':
                result = 'Every Two Weeks';
                break;
            case 'TwiceAMonth':
                result = 'Twice a Month';
                break;
            case 'EverySixMonths':
                result = 'Every Six Months';
                break;
            case 'OneTime':
                result = 'One Time';
                break;
        }

        return result;
    }

    function summarizeFrequency(frequency) {
        if (frequency.type === 'One Time' || frequency.type === 'OneTime') {
            return frequency.effectiveDate
                ? `One Time on ${frequency.effectiveDate}.`
                : 'One Time.';
        }

        let summary = 'Occurs ';
        switch (frequencyTypeToFriendly(frequency.type)) {
            case 'Weekly':
                summary += ` every ${getDayOfWeek(frequency)} ${getStartAndEnd(frequency)}`;
                break;
            case 'Every Two Weeks':
                summary += ` on ${getDayOfWeek(frequency)} every two weeks ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Twice a Month':
                summary += ` on ${getDaysOfMonth(frequency, true)} every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Monthly':
                summary += ` on ${getDaysOfMonth(frequency)} of every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'Quarterly':
                summary += ` on ${getDayForADate(
                    frequency.startOn
                )} every three months ${getStartAndEnd(frequency)}`;
                break;
            case 'Every Six Months':
                summary += ` on ${getDayForADate(
                    frequency.startOn
                )} every six months ${getStartAndEnd(frequency)}`;
                break;
            case 'Annually':
                summary += ` on ${getDayForADate(frequency.startOn)}every year ${getStartAndEnd(
                    frequency
                )}`;
                break;
        }
        summary += '.';
        return summary;
    }

    function getDaysOfMonth(frequency, twoDays) {
        const day1 = frequency.repeatOnDay1;
        const day2 = frequency.repeatOnDay2;

        if (!twoDays) {
            if (frequency.repeatOnLastBusinessDay) {
                return 'the last business day';
            }
            return `the ${formatDay(day1)}`;
        }
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }

    function formatDay(day) {
        if (day === 1 || day === 21 || day === 31) {
            return `${day}st`;
        }
        if (day === 2 || day === 22) {
            return `${day}nd`;
        }
        if (day === 3 || day === 23) {
            return `${day}rd`;
        }
        return `${day}th`;
    }

    function getDayOfWeek(frequency) {
        return frequency.repeatOn;
    }

    function getDayForADate(date) {
        //   date = moment.date();
        date = new Date(date);
        return formatDay($filter('date')(date, 'd'));
    }

    function getStartAndEnd(frequency) {
        if (frequency.endOn && !frequency.noEndDate) {
            return `from ${formatDate(frequency.startOn)} to ${formatDate(frequency.endOn)}`;
        }
        if (frequency.noEndDate) {
            return `starting on ${formatDate(frequency.startOn)}`;
        }
    }

    function formatDate(date) {
        return $filter('date')(date, 'MM/dd/yyyy');
    }

    function cancel(cancelAchPaymentModel) {
        const resource = $resource(`${resourceUrl}/cancel`, {}, { update: { method: 'PUT' } });
        return resource.update(cancelAchPaymentModel).$promise;
    }

    function uninitiate(achUninitiateModel) {
        const resource = $resource(`${resourceUrl}/uninitiate`, {}, { update: { method: 'PUT' } });
        return resource.update(achUninitiateModel).$promise;
    }

    function getOffsetAccounts() {
        return $resource(`${resourceUrl}/offsetAccounts`).query().$promise;
    }

    function isTaxPayment(payment) {
        return (
            payment.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment ||
            payment.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment
        );
    }

    function isChildSupportPayment(payment) {
        return payment.transactionId.includes('-C');
    }

    function isInternationalAchPayment(payment) {
        return payment.secCode.includes('IAT');
    }

    async function isIncomingDateValid(
        incomingDate,
        allowSameDayPayments,
        holidays,
        sameDayAchSettings,
        cutOffTimes
    ) {
        const featureService = await FeatureFlagService.getInstance();
        const ACHNachaEffectiveDateFFIsOn = await featureService.isEnabled(
            Feature.ACHNachaEffectiveDate
        );

        if (ACHNachaEffectiveDateFFIsOn) {
            return incomingDateValidator(
                incomingDate,
                allowSameDayPayments,
                holidays,
                sameDayAchSettings,
                cutOffTimes
            );
        }
        return false;
    }
}

AchPaymentsService.$inject = ['$resource', '$filter', '$q', 'achPaymentTypes', 'TmDi'];
