/* eslint-disable no-use-before-define */
import { FdlFieldDefinitions, FieldType, Record as FdlRecord } from '@treasury/FDL';
import { OmegaReportFilter } from '../../types';

export function createFilterRecord<T>(filters: OmegaReportFilter<T, any>[]) {
    const fieldTypes: FdlFieldDefinitions<T> = {};
    const values = {} as T;

    filters.forEach(filter => {
        const { fieldType, value } = filter;

        // unroll multi-field into individual fields
        if (filter.multiple) {
            filter.fields.forEach(field => {
                build(field, fieldType, value);
            });
            return;
        }

        const { field } = filter;
        build(field, fieldType, value);
    });

    return new FdlRecord(fieldTypes, values);

    function build(key: keyof T, fieldType: FieldType<any>, value?: any) {
        fieldTypes[key] = fieldType;
        values[key] = value ?? fieldType.defaultValue();
    }
}
