/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchFilterAuditHistoryModelCollectionDto,
    AchFilterAuditHistoryRequestModelDto,
    AchFilterReportDownloadRequestModelDto,
    AchFilterReportRequestModelDto,
    AchFilterUserModelCollectionDto,
    CompanyUserAccountModelCollectionDto,
    ErrorResponseModelDto,
    ReportDataModelOfListOfAchFilterReportModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchFilterReportClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchFilterReport
     * @name AchFilterReportGetCompanyUsers
     * @summary Retrieves company users who are eligible for reporting for ACH filters
     * @request GET:/achFilterReport/companyUsers/{companyGuidUniqueId}
     */
    achFilterReportGetCompanyUsers = (companyGuidUniqueId: string, params: RequestParams = {}) =>
        this.http.request<AchFilterUserModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilterReport/companyUsers/${companyGuidUniqueId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description This action will retrieve all accounts which participate in ACH filter management, and which have entitled users.  Entitled users could be users which are explicitly entitled, as well as those in 'Super User' role. The returned collection will indicate, for each account, which users are entitled to the account.
     *
     * @tags AchFilterReport
     * @name AchFilterReportGetAccountsForCompanyUsers
     * @summary Retrieves accounts entitled to be managed for ACH filters by company users
     * @request GET:/achFilterReport/companyUsers/{companyUniqueId}/accounts
     */
    achFilterReportGetAccountsForCompanyUsers = (
        companyUniqueId: string,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyUserAccountModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilterReport/companyUsers/${companyUniqueId}/accounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Retrieves audit history for ACH filter ids passed in the request.  The ACH filter ids would typically be known by extracting from filters after running ACH Filter report. Considerations: * It is the client's responsibility to sort audit history in the desired chronological sequence * Audit history is comprehensive, for all-time, and is not paged.  Performance therefore could be a consideration for large sets of filters; recommend pulling audit history for individual filters as-needed. * While audit history would typically exist for a filter, it is not guaranteed.  In the event no history can be found, the event audit collection for that filter will be empty.
     *
     * @tags AchFilterReport
     * @name AchFilterReportGetAuditHistory
     * @summary Retrieves audit history for specified ACH Filters.
     * @request POST:/achFilterReport/auditHistory
     */
    achFilterReportGetAuditHistory = (
        request: AchFilterAuditHistoryRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchFilterAuditHistoryModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilterReport/auditHistory`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilterReport
     * @name AchFilterReportGetAchFilterReport
     * @summary Given filter parameters returns the ach filters matching the criteria
     * @request POST:/achFilterReport/search
     */
    achFilterReportGetAchFilterReport = (
        reportFilter: AchFilterReportRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ReportDataModelOfListOfAchFilterReportModelDto, ErrorResponseModelDto>({
            path: `/achFilterReport/search`,
            method: 'POST',
            body: reportFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Returns a csv report file.
     *
     * @tags AchFilterReport
     * @name AchFilterReportGetAchFilterReportCsvDownload
     * @summary Given filter parameters returns the csv file stream
     * @request POST:/achFilterReport/download/csv
     */
    achFilterReportGetAchFilterReportCsvDownload = (
        reportFilter: AchFilterReportDownloadRequestModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<File, ErrorResponseModelDto>({
            path: `/achFilterReport/download/csv`,
            method: 'POST',
            body: reportFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilterReport
     * @name AchFilterReportGetAchFilterReportPdfDownload
     * @summary Given filter parameters returns the pdf file stream
     * @request POST:/achFilterReport/download/pdf
     */
    achFilterReportGetAchFilterReportPdfDownload = (
        reportFilter: AchFilterReportDownloadRequestModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<File, ErrorResponseModelDto>({
            path: `/achFilterReport/download/pdf`,
            method: 'POST',
            body: reportFilter,
            format: 'json',
            ...params,
        });
}
