StpAccountsController.$inject = ['$scope', '$modalInstance', 'companyAccounts', '$timeout'];

export default function StpAccountsController($scope, $modalInstance, companyAccounts, $timeout) {
    $scope.accountSearchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.companyAccounts = companyAccounts;
    $scope.selectAccount = selectAccount;
    $scope.filterAccounts = filterAccounts;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function selectAccount(account) {
        $modalInstance.close(account);
    }

    function filterAccounts(account) {
        return (
            isMatch(account.number, $scope.accountSearchText) ||
            isMatch(account.name, $scope.accountSearchText)
        );
    }

    function isMatch(field, matchAgainst) {
        if (field && matchAgainst) {
            return field.toString().toLowerCase().indexOf(matchAgainst.toLowerCase()) !== -1;
        }
    }
}
