import { LitElement, html, css } from 'lit';

export class OmegaIconArrowUp extends LitElement {
    render() {
        return html`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 625">
                <path d="M447 422H53L250 79z" />
                <path
                    d="M447 407H53l13 22 19-34 47-82 57-99 50-86 24-41v-1h-26l20 34 47 82 57 99 49 86 24 42c4 7 14 10 21 6s9-14 5-21l-20-34-47-82-57-99-49-86c-8-14-15-28-24-41v-1c-6-10-20-10-26 0l-20 34-47 82-57 99-49 86-24 42c-6 10 1 23 13 23h394c8 0 16-7 15-15 0-8-6-15-15-15z"
                />
            </svg>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}
customElements.define('omega-icon-arrow-up', OmegaIconArrowUp);
