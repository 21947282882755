QuickTransferWidgetController.$inject = [
    '$scope',
    'toaster',
    '$modal',
    '$state',
    '$stateParams',
    'companyAccountsService',
    'internalTransfersService',
    'utilityService',
    'securityService',
    'holidaysService',
    '$timeout',
    'spinnerService',
    '$filter',
];

export default function QuickTransferWidgetController(
    $scope,
    toaster,
    $modal,
    $state,
    $stateParams,
    companyAccountsService,
    internalTransfersService,
    utilityService,
    securityService,
    holidaysService,
    $timeout,
    spinnerService,
    $filter
) {
    $scope.accounts = [];
    $scope.entitlement = '';
    $scope.form = null;
    $scope.fromAccountsByTransaction = [];
    $scope.goBack = goBack;
    $scope.isConfirmation = null;
    $scope.toAccountsByTransaction = [];
    $scope.loadCompletionCallback = null;
    $scope.defaultDate = moment().format('MM/DD/YYYY');
    $scope.$watch('quickTransferObj.transactions', onTransactionsChange, true);

    $scope.cancel = cancel;
    $scope.createAnotherTransfer = createAnotherTransfer;
    $scope.findAccount = findAccount;
    $scope.getFromAccounts = getFromAccounts;
    $scope.getToAccounts = getToAccounts;
    $scope.isNegative = isNegative;
    $scope.isObject = isObject;
    $scope.isValidAmounts = isValidAmounts;
    $scope.isReviewButtonDisabled = isReviewButtonDisabled;
    $scope.isConfirmButtonDisabled = isConfirmButtonDisabled;
    $scope.navigateToInternalTransfers = navigateToInternalTransfers;
    $scope.reset = reset;
    $scope.review = review;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.getBalanceDisplayForToAccount = getBalanceDisplayForToAccount;
    $scope.showFromBalance = showFromBalance;
    $scope.showToBalance = showToBalance;

    init();

    function init() {
        spinnerService.configureGroup(
            'quickTransferWidget',
            ['/companyaccounts', '/holidays', '/internalTransfers'],
            ['quickTransferWidget.html', 'quickTransferFooter.html']
        );

        $scope.widget.footerTemplate = 'app/dashboard/widgets/views/quickTransferFooter.html';

        $scope.widget.footerController = {
            getForm,
            isValidAmounts,
            isReviewing,
            isOnConfirmation,
            isReviewButtonDisabled,
            isConfirmButtonDisabled,
            review,
            reset,
            save,
            goBack,
            cancel,
            createAnotherTransfer,
            navigateToInternalTransfers,
        };

        initializeObject();
        loadAccountsList('From');
        loadAccountsList('To');
        getHolidays();
        loadCutoffTimes();
        $scope.toOptions = {
            disableDates(date) {
                if (
                    $scope.disableToday &&
                    moment(date).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    return true;
                }
                if (date) {
                    return holidaysService.compareDates(date, $scope.holidayDates);
                }
                return false;
            },
        };
    }

    // Footer
    function getForm() {
        return $scope.form;
    }

    function isReviewing() {
        return $scope.isReviewing;
    }

    function isOnConfirmation() {
        return $scope.isConfirmation;
    }

    // Body
    function getFromAccounts(transactionIndex) {
        return $scope.fromAccountsByTransaction[transactionIndex] || $scope.accountListFrom;
    }

    function getToAccounts(transactionIndex) {
        return $scope.toAccountsByTransaction[transactionIndex] || $scope.accountListTo;
    }

    function review() {
        clearErrors();
        $scope.$parent.flipWidget($scope.widget, true);
        $scope.isReviewing = true;
    }

    function initializeObject() {
        $scope.quickTransferObj = {
            type: 'one',
            transactions: [
                {
                    fromAccount: null,
                    toAccount: null,
                    amount: null,
                    transferDate: $scope.defaultDate,
                },
            ],
        };
    }

    function createAnotherTransfer() {
        $scope.loadCompletionCallback = function () {
            $scope.$parent.flipWidget($scope.widget, false);
        };
        initializeObject();
        $scope.isConfirmation = false;
        $scope.isReviewing = false;
        $scope.form.$setPristine();
    }

    function goBack() {
        $scope.isReviewing = false;
        $scope.$parent.flipWidget($scope.widget, false);
    }

    function calculateNextBusinessDay() {
        utilityService
            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
            .then(response => {
                $scope.defaultDate = formatDate(response);
                $scope.quickTransferObj.transactions[0].transferDate = $scope.defaultDate;
            });
    }

    function onTransactionsChange(newTransactions, oldTransactions) {
        let i;
        let newFromId;
        let oldFromId;
        let newToId;
        let oldToId;

        if (!newTransactions || !oldTransactions) {
            return;
        }

        // Ignore changes in the size of the list.
        if (newTransactions.length !== oldTransactions.length) {
            return;
        }

        function isObject(value) {
            return value === Object(value);
        }

        for (i = 0; i < newTransactions.length; i++) {
            oldFromId = isObject(oldTransactions[i].fromAccount)
                ? oldTransactions[i].fromAccount.id
                : null;
            newFromId = isObject(newTransactions[i].fromAccount)
                ? newTransactions[i].fromAccount.id
                : null;
            oldToId = isObject(oldTransactions[i].toAccount)
                ? oldTransactions[i].toAccount.id
                : null;
            newToId = isObject(newTransactions[i].toAccount)
                ? newTransactions[i].toAccount.id
                : null;

            // From account changed
            if (newFromId !== oldFromId) {
                (function () {
                    const index = i;
                    loadAccountsList('To', newFromId).then(response => {
                        $scope.toAccountsByTransaction[index] = response;
                    });
                })();
            }
            // To account changed
            if (newToId !== oldToId) {
                (function () {
                    const index = i;
                    loadAccountsList('From', newToId).then(response => {
                        $scope.fromAccountsByTransaction[index] = response;
                    });
                })();
            }
        }
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function isValidAmounts() {
        let result = true;
        if (
            $scope.quickTransferObj.transactions[0].fromAccount === null ||
            $scope.quickTransferObj.transactions[0].fromAccount === undefined ||
            typeof $scope.quickTransferObj.transactions[0].fromAccount !== 'object' ||
            $scope.quickTransferObj.transactions[0].toAccount === null ||
            $scope.quickTransferObj.transactions[0].toAccount === undefined ||
            typeof $scope.quickTransferObj.transactions[0].toAccount !== 'object' ||
            isNaN($scope.quickTransferObj.transactions[0].amount)
        ) {
            result = false;
        }
        return result;
    }

    function isReviewButtonDisabled() {
        return $scope.isReviewing || $scope.form.$invalid || !isValidAmounts();
    }

    function isConfirmButtonDisabled() {
        return $scope.isSaving || $scope.form.$invalid || !$scope.isValidAmounts();
    }

    function setForm(form) {
        $scope.form = form;
    }

    $scope.$watch(
        '$parent.dashboardEntitlements',
        newValue => {
            if (newValue !== null && typeof newValue === 'object') {
                $scope.summarySection = newValue;
                angular.forEach($scope.summarySection, item => {
                    if (item.widget === 'quickTransfer') {
                        $scope.entitlement = item.permission;
                    }
                });
            }
        },
        true
    );

    function navigateToInternalTransfers() {
        let fromAccount = null;
        let toAccount = null;
        let amount = null;
        if (
            typeof $scope.quickTransferObj.transactions[0].fromAccount === 'object' &&
            $scope.quickTransferObj.transactions[0].fromAccount !== null &&
            $scope.quickTransferObj.transactions[0].fromAccount !== undefined &&
            angular.isDefined($scope.quickTransferObj.transactions[0].fromAccount.id) &&
            $scope.quickTransferObj.transactions[0].fromAccount.id !== null &&
            $scope.quickTransferObj.transactions[0].fromAccount.id !== undefined
        ) {
            fromAccount = $scope.quickTransferObj.transactions[0].fromAccount.id;
        }
        if (
            typeof $scope.quickTransferObj.transactions[0].toAccount === 'object' &&
            $scope.quickTransferObj.transactions[0].toAccount !== null &&
            $scope.quickTransferObj.transactions[0].toAccount !== undefined &&
            angular.isDefined($scope.quickTransferObj.transactions[0].toAccount.id) &&
            $scope.quickTransferObj.transactions[0].toAccount.id !== null &&
            $scope.quickTransferObj.transactions[0].toAccount.id !== undefined
        ) {
            toAccount = $scope.quickTransferObj.transactions[0].toAccount.id;
        }
        if (
            $scope.quickTransferObj.transactions[0].amount !== null &&
            $scope.quickTransferObj.transactions[0].amount !== undefined
        ) {
            amount = $scope.quickTransferObj.transactions[0].amount;
        }
        $state.go('payables.transfers', {
            fromAccountId: fromAccount,
            toAccountId: toAccount,
            amount,
        });
    }

    function isNegative(value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    }

    function save() {
        $scope.isSaving = true;
        updateSpinner(spinnerService.startGroupRequest('quickTransferWidget'));
        securityService
            .verifyUser('Create One time Transfer', $scope.quickTransferObj, () =>
                internalTransfersService.save($scope.quickTransferObj)
            )
            .then(
                response => {
                    updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
                    if (
                        response.errorSummary !== null &&
                        response.errorSummary !== undefined &&
                        response.errorSummary.summaryMessage !== null &&
                        response.errorSummary.summaryMessage !== undefined &&
                        response.errorSummary.summaryMessage.length > 0
                    ) {
                        displayErrors(response);
                    } else {
                        $scope.quickTransferObj.batchId = response.batchId;
                        $scope.quickTransferObj.transactions[0].status =
                            response.transactions[0].status;
                        updateTransactionIds(response);
                        clearErrors();
                        $scope.isConfirmation = true;
                    }
                    $scope.isSaving = false;
                },
                error => {
                    updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
                    $scope.isSaving = false;
                }
            );
    }

    function findAccount(transaction, attribute, index) {
        const modalInstance = $modal.open({
            template: require('../views/transferAccountSelectionModal.html'),
            size: 'md',
            controller: 'AccountSelectionController',
            backdrop: 'static',
            resolve: {
                accountsList() {
                    let newList = [];
                    if (attribute === 'fromAccount') {
                        if (
                            transaction.transactions[0].toAccount !== null &&
                            transaction.transactions[0].toAccount !== undefined &&
                            typeof transaction.transactions[0].toAccount === 'object'
                        ) {
                            angular.forEach($scope.accountListFrom, item => {
                                if (
                                    item.number !== transaction.transactions[0].toAccount.number ||
                                    (item.number === transaction.transactions[0].toAccount.number &&
                                        item.type !== transaction.transactions[0].toAccount.type)
                                ) {
                                    newList.push(item);
                                }
                            });
                        } else {
                            newList = angular.copy($scope.accountListFrom);
                        }
                    } else if (attribute === 'toAccount') {
                        if (
                            transaction.transactions[0].fromAccount !== null &&
                            transaction.transactions[0].fromAccount !== undefined &&
                            typeof transaction.transactions[0].fromAccount === 'object'
                        ) {
                            angular.forEach($scope.accountListTo, item => {
                                if (
                                    item.number !==
                                        transaction.transactions[0].fromAccount.number ||
                                    (item.number ===
                                        transaction.transactions[0].fromAccount.number &&
                                        item.type !== transaction.transactions[0].fromAccount.type)
                                ) {
                                    newList.push(item);
                                }
                            });
                        } else {
                            newList = angular.copy($scope.accountListTo);
                        }
                    }
                    return newList;
                },
                showCurrentBalanceForLoanAccounts() {
                    return attribute === 'toAccount';
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            $scope.form.$dirty = true;
            transaction.transactions[index][attribute] = selectedAccount;
        });
    }

    function reset() {
        clearErrors();
        initializeObject();
        $scope.form.$setPristine();
        $scope.form.$setUntouched();
    }

    function cancel() {
        initializeObject();
        $scope.form.$setPristine();
        $scope.form.$setUntouched();
    }

    //
    // Private

    function loadAccountsList(type, oppositeAccountId) {
        updateSpinner(spinnerService.startGroupRequest('quickTransferWidget'));
        return companyAccountsService
            .getCompanyAccountsForProductFeature(
                type,
                'InternalTransfer',
                'create',
                oppositeAccountId
            )
            .then(
                response => {
                    updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
                    if (
                        response.length > 0 &&
                        response[0] !== null &&
                        typeof response[0] !== 'undefined'
                    ) {
                        $scope.accountDisplayField = response[0].showAccountNickName
                            ? 'name'
                            : 'number';
                    }
                    if (type === 'From') {
                        $scope.accountListFrom = response;
                    } else if (type === 'To') {
                        $scope.accountListTo = response;
                    }
                    return response;
                },
                error => {
                    updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
                }
            );
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function getHolidays() {
        updateSpinner(spinnerService.startGroupRequest('quickTransferWidget'));
        holidaysService.getAll().then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
                $scope.holidayDates = response.map(item => item.date);
                calculateNextBusinessDay();
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
            }
        );
    }

    function loadCutoffTimes() {
        updateSpinner(spinnerService.startGroupRequest('quickTransferWidget'));
        companyAccountsService.getCutoffTimesByProductType('InternalTransfer').then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
                $scope.processingCutoff = response;
                const timeout = moment(
                    `${moment(response.currentFiTime).format('L')} ${
                        response.processingCutoff.cutoffTime
                    }`
                ).diff(response.currentFiTime);
                $timeout(() => {
                    $scope.disableToday = true;
                    if (timeout > 0) {
                        toaster.alert('Cutoff Time Passed', 'Cannot create transfer for today.');
                        for (let i = 0; i < $scope.quickTransferObj.transactions.length; i++) {
                            if (
                                moment($scope.quickTransferObj.transactions[i].transferDate).format(
                                    'L'
                                ) === moment($scope.processingCutoff.currentFiTime).format('L')
                            ) {
                                calculateNextBusinessDay();
                            }
                        }
                    } else {
                        calculateNextBusinessDay();
                    }
                }, timeout);
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('quickTransferWidget'));
            }
        );
    }

    function updateTransactionIds(response) {
        const responseTransactions = response.transactions;

        for (let i = 0; i < responseTransactions.length; i++) {
            $scope.quickTransferObj.transactions[i].id = responseTransactions[i].id;
            $scope.quickTransferObj.transactions[i].transactionId =
                responseTransactions[i].transactionId;
        }
    }

    function clearErrors() {
        $scope.errorMessage = '';
        angular.forEach($scope.quickTransferObj.transactions, transaction => {
            transaction.balanceErrorMessage = '';
            transaction.fromAccountErrorMessage = '';
        });
    }

    function assembleResponseErrors(response) {
        angular.forEach(response.transactions, transaction => {
            if (
                transaction.errorSummary !== null &&
                transaction.errorSummary !== undefined &&
                transaction.errorSummary.details !== null &&
                transaction.errorSummary.details !== undefined &&
                transaction.errorSummary.details.length > 0
            ) {
                angular.forEach(transaction.errorSummary.details, item => {
                    if (item.attributeName === 'fromAccount') {
                        if (
                            item.messageList !== null &&
                            item.messageList !== undefined &&
                            item.messageList.length > 0
                        ) {
                            if (
                                transaction.fromAccountErrorMessage === null ||
                                transaction.fromAccountErrorMessage === undefined
                            ) {
                                transaction.fromAccountErrorMessage = '';
                            }
                            angular.forEach(item.messageList, message => {
                                transaction.fromAccountErrorMessage += `${message.value}, `;
                            });
                            transaction.fromAccountErrorMessage =
                                transaction.fromAccountErrorMessage.substring(
                                    0,
                                    transaction.fromAccountErrorMessage.length - 2
                                );
                        } else {
                            transaction.fromAccountErrorMessage = `${item.message}, `;
                            transaction.fromAccountErrorMessage =
                                transaction.fromAccountErrorMessage.substring(
                                    0,
                                    transaction.fromAccountErrorMessage.length - 2
                                );
                        }
                    } else if (item.attributeName === 'balance') {
                        if (
                            item.messageList !== null &&
                            item.messageList !== undefined &&
                            item.messageList.length > 0
                        ) {
                            if (
                                transaction.balanceErrorMessage === null ||
                                transaction.balanceErrorMessage === undefined
                            ) {
                                transaction.balanceErrorMessage = '';
                            }
                            angular.forEach(item.messageList, message => {
                                transaction.balanceErrorMessage += `${message.value}, `;
                            });
                            transaction.balanceErrorMessage =
                                transaction.balanceErrorMessage.substring(
                                    0,
                                    transaction.balanceErrorMessage.length - 2
                                );
                        } else {
                            transaction.balanceErrorMessage = `${item.message}, `;
                            transaction.balanceErrorMessage =
                                transaction.balanceErrorMessage.substring(
                                    0,
                                    transaction.balanceErrorMessage.length - 2
                                );
                        }
                    }
                });
            }
        });
    }

    function displayErrors(response) {
        // Clear any previous errors
        clearErrors();

        $scope.errorMessage = response.errorSummary.summaryMessage;

        assembleResponseErrors(response);
        assignErrorsToTransactions(response);

        // Return user to edit form
        $scope.$parent.flipWidget($scope.widget, false);
        $scope.isReviewing = false;
        $scope.isConfirmation = false;
        $scope.form.$setDirty();
    }

    function assignErrorsToTransactions(response) {
        const responseTransactions = response.transactions;
        for (let i = 0; i < responseTransactions.length; i++) {
            $scope.quickTransferObj.transactions[i].balanceErrorMessage =
                responseTransactions[i].balanceErrorMessage;
            $scope.quickTransferObj.transactions[i].fromAccountErrorMessage =
                responseTransactions[i].fromAccountErrorMessage;
        }
    }

    function updateSpinner(numPendingRequests) {
        const isLoading = numPendingRequests > 0;
        if ($scope.widget.isLoading && !isLoading) {
            if ($scope.loadCompletionCallback) {
                $scope.loadCompletionCallback();
                $scope.loadCompletionCallback = null;
            }
        }
        $scope.widget.isLoading = isLoading;
    }

    function getBalanceDisplayForToAccount(account) {
        return $filter('negativeParentheses')($filter('currency')(getToAccountBalance(account)));
    }

    function getToAccountBalance(account) {
        if (account.type === 'Loan') {
            return account.currentBalance;
        }

        return account.availableBalance;
    }

    function showFromBalance(transaction) {
        return (
            transaction != null &&
            transaction.fromAccount != null &&
            transaction.fromAccount.availableBalance != null
        );
    }

    function showToBalance(transaction) {
        return transaction != null && transaction.toAccount != null && getToAccountBalance != null;
    }
}
