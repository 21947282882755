import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record } from '@treasury/FDL/record';
import { UserInformationModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { UserAccountDto } from '@treasury/domain/channel/types/user-account';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/progress/omega-progress';
import { AlertMixin } from '@treasury/omega/mixins/alert.mixin';
import { SessionStorageService } from '@treasury/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserProfileClient } from './clients/user-profile-client';
import { userAccountFields } from './data/user-account-record';
import './parts/user-change-password-dialog';

export const UserProfileTagName = 'user-profile-container';

const mixin = AlertMixin(ListeningElementMixin(LitElement));
@customElement(UserProfileTagName)
export class UserProfileContainer extends mixin {
    @property({
        type: String,
    })
    public institution!: string;

    @InjectProperty()
    public declare client: UserProfileClient;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty({
        async: true,
        token: FeatureFlagService,
    })
    private declare ffServicePromise: Promise<FeatureFlagService>;

    @InjectProperty()
    private declare sessionStorage: SessionStorageService;

    @state()
    private isUisEnabled = false;

    @property({
        type: Object,
    })
    public userAccountRecord = new Record<UserInformationModelDto>(
        userAccountFields,
        {} as UserInformationModelDto
    );

    @state()
    private isLoading = true;

    @state()
    private isUpdating = false;

    @state()
    private isDirty = false;

    @state()
    public passwordDialogOpen = false;

    public async firstUpdated() {
        try {
            this.isUisEnabled = await (await this.ffServicePromise).isEnabled(Feature.UisEnabled);
            const userAccountData = await this.client.getCurrentUserProfile();
            this.userAccountRecord = new Record<UserInformationModelDto>(
                userAccountFields,
                userAccountData
            );
            this.initAccountListening();
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code,
                    time,
                };
            } else {
                const message = 'An unknown error occurred.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }
    }

    private initAccountListening() {
        this.listenTo(this.userAccountRecord, 'change', (detail: CustomEvent) => {
            const targetRecord = detail.target as Record<UserAccountDto>;
            this.isDirty = targetRecord.hasChanged;
        });
    }

    public async saveProfileUpdates() {
        const defaultErrMsg = 'Unable to save user profile changes. Please try again.';
        try {
            this.isUpdating = true;
            const { status } = await this.client.updateCurrentUserProfile(
                this.userAccountRecord.values as UserInformationModelDto
            );

            if (status !== 'Success') {
                // in practice, getting here should never happen since HTTP 200
                // responses of certain shapes will result in TmHttpClient throwing
                throw new Error(defaultErrMsg);
            }

            this.alert = {
                ...this.alert,
                visible: true,
                posture: 'polite',
                actions: '',
                title: '',
                type: 'success',
                message: 'Profile Changes Saved!',
            };
            this.isDirty = false;
            this.userAccountRecord = new Record(userAccountFields, this.userAccountRecord.values);
            this.initAccountListening();
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message,
                    code,
                    visible: true,
                    posture: 'polite',
                    title: '',
                    actions: '',
                    type: 'error',
                };
            } else {
                console.error(defaultErrMsg, err);
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message: defaultErrMsg,
                };
            }
        } finally {
            this.isUpdating = false;
        }
    }

    private cancelProfileUpdates() {
        if (this.userAccountRecord.hasChanged) {
            this.promptForCancellation();
        } else {
            this.goToDashboard();
        }
    }

    public promptForCancellation() {
        this.alert = {
            ...this.alert,
            type: 'warning',
            posture: 'assertive',
            visible: true,
            title: 'Unsaved Changes',
            message: 'Are you sure you want to cancel your profile changes?',
            actions: html`<omega-button
                    type="primary"
                    id="cancel-changes"
                    @click=${this.goToDashboard}
                    >OK</omega-button
                ><omega-button id="continue-editing" @click=${this.closeCancellationPrompt}
                    >Continue Editing</omega-button
                >`,
        };
        this.requestUpdate();
    }

    public closeCancellationPrompt() {
        this.alert = { ...this.alert, visible: false };
        this.requestUpdate();
    }

    public goToDashboard() {
        return this.navService.navigate('dashboard');
    }

    renderBlockingLoader() {
        if (this.isUpdating) {
            return html`<div class="background">
                <omega-progress card class="light-loader"></omega-progress>
            </div>`;
        }
        return nothing;
    }

    renderSecurityButton() {
        if (this.isUisEnabled) {
            const user = this.sessionStorage.get<{ userAccountSettingsLink: string }>('user');
            if (!user || !user.userAccountSettingsLink) {
                console.error('User account settings link not found');
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message: 'User account settings link not found',
                };
                return nothing;
            }
            return html`<omega-button
                id="security-and-password-settings"
                type="secondary"
                @click="${() => {
                    window.open(user.userAccountSettingsLink, '_blank');
                }}"
                >Security and Password Settings</omega-button
            >`;
        }

        return html`<omega-button
            id="change-password"
            type="secondary"
            @click="${() => {
                this.passwordDialogOpen = true;
            }}"
            >Change Password</omega-button
        >`;
    }

    render() {
        const digitalId = this.sessionStorage.get<{ digitalId: string }>('user')?.digitalId || '';
        return html`${this.renderAlert()}${this.renderBlockingLoader()}
            <user-change-password-dialog
                .client=${this.client}
                .institution=${this.institution}
                .open=${this.passwordDialogOpen}
                @close=${() => {
                    this.passwordDialogOpen = false;
                }}
            ></user-change-password-dialog>
            <div class="user-profile-container">
                <h2 class="profile-header">My Profile</h2>

                <div class="profile-content">
                    <span class="required-field-explanation"
                        ><span class="red">*</span> Indicates required field</span
                    >
                    <div class="user-information">
                        <h2 class="section-title">User Information</h2>
                        <hr />

                        <omega-field field="name" .record=${this.userAccountRecord}></omega-field>
                        <div class="form-group">
                            <div class="label">
                                <label for="loginId"
                                    >${this.isUisEnabled ? 'TM User ID:' : 'User ID:'}</label
                                >
                            </div>
                            <div class="control">
                                <span>${this.userAccountRecord.getField('loginId')}</span>
                            </div>
                        </div>
                        ${this.isUisEnabled
                            ? html`<div class="form-group mt-8">
                                  <div class="label">
                                      <label for="digitalId">Digital ID:</label>
                                  </div>
                                  <div class="control">
                                      <span>${digitalId}</span>
                                  </div>
                              </div>`
                            : nothing}
                        <omega-field field="email" .record=${this.userAccountRecord}></omega-field>
                        <div class="phone-ext-container">
                            <omega-field
                                field="phone"
                                class="phone"
                                .record=${this.userAccountRecord}
                            ></omega-field>
                            <omega-field
                                field="extension"
                                .record=${this.userAccountRecord}
                            ></omega-field>
                        </div>
                        <omega-field
                            field="mobile"
                            class="phone"
                            .record=${this.userAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="fax"
                            class="phone"
                            .record=${this.userAccountRecord}
                        ></omega-field>
                    </div>
                    <div class="security-preferences">
                        <h2 class="section-title">Security Preferences</h2>
                        <hr />
                        ${this.renderSecurityButton()}
                        <omega-field
                            field="accountNicknames"
                            .record=${this.userAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="accountMasking"
                            .record=${this.userAccountRecord}
                        ></omega-field>
                        <omega-field
                            field="verificationMethod"
                            .record=${this.userAccountRecord}
                        ></omega-field>
                    </div>
                    <hr />
                    <omega-button
                        id="save-profile-updates"
                        type="primary"
                        ?disabled=${!this.isDirty}
                        @click="${this.saveProfileUpdates}"
                        >Save</omega-button
                    >
                    <omega-button
                        id="cancel-profile-updates"
                        type="secondary"
                        @click=${this.cancelProfileUpdates}
                        >Cancel</omega-button
                    >
                </div>
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            .profile-header {
                font-size: 24px;
            }

            span.required-field-explanation {
                font-style: italic;
                float: right;
            }
            span.red {
                color: var(--omega-text-error);
            }

            .profile-content {
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                box-shadow: 0px 0px 2px 2px rgb(150 150 150 / 75%);
                padding: var(--user-profile-content-padding, 10px);
                margin-bottom: 55px;
                flex: 1 1 auto;
            }

            .section-title {
                margin: 0;
            }

            .user-information omega-field {
                --omega-field-label-max-width: 95px;
                --omega-field-control-font-weight: 600;
            }

            omega-field[field='email'] {
                --omega-field-control-max-width: 250px;
            }

            .phone-ext-container {
                display: flex;
            }

            .phone-ext-container omega-field {
                flex: 1 1;
            }

            omega-field[field='phone'] {
                max-width: 220px;
            }

            omega-field[field='extension'] {
                --omega-field-label-max-width: 30px;
                --omega-field-control-max-width: 60px;
            }

            omega-field.phone {
                --omega-field-control-max-width: 124px;
            }

            omega-field {
                margin: 10px;
            }

            .security-preferences {
                margin-top: 3rem;
            }

            .security-preferences omega-field {
                --omega-field-label-max-width: 215px;
            }

            * {
                box-sizing: border-box;
            }

            .form-group {
                display: flex;
                --omega-field-label-max-width: 95px;
                --omega-field-control-font-weight: 600;
                margin-left: 10px;
            }

            .form-group .label {
                flex: 1 1 var(--omega-field-label-width, 200px);
                font-size: var(--omega-label, 14px);
                font-weight: var(--omega-label-font-weight, 400);
                color: var(--omega-label-color);
                margin-bottom: var(--omega-field-label-margin-bottom, 4px);
                max-width: var(--omega-field-label-max-width, none);
                margin-bottom: 10px;
            }

            .required-indicator {
                color: var(--omega-error);
            }
            .form-group .control {
                font-size: var(--omega-field-control-font-size);
                font-weight: var(--omega-field-control-font-weight);
                flex: 3 1 var(--omega-field-control-width, 250px);
                max-width: var(--omega-field-control-max-width, none);
            }
            .mt-8 {
                margin-top: 8px;
            }
        `;
    }
}
