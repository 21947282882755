import { GetUserAchEntitlementsModelDto, UserClaimModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

const templateFeatureIds = [95, 96, 97, 98, 99];

const paymentFeatureIds = [100, 101, 102, 103, 118, 133];

const internationalTemplateFeatureIds = [202, 203, 204, 205, 206];

const internationalPaymentFeatureIds = [185, 186, 187, 188, 189, 190];

const recipientFeatureIds = [138, 139, 140, 141];

const reviewFeatureIds = [194, 195];

export class AchEntitlements extends DomainEntity<GetUserAchEntitlementsModelDto> {
    constructor(dto: GetUserAchEntitlementsModelDto) {
        super(dto);
    }

    public createDefault() {
        return new AchEntitlements({} as GetUserAchEntitlementsModelDto) as this;
    }

    private getFeatures(featureIds: number[]) {
        const filteredFeatures = this.dto?.userProductClaims?.filter((f: UserClaimModelDto) =>
            featureIds.includes(f.claimId)
        );

        return filteredFeatures?.map((f: UserClaimModelDto) => ({
            ...f,
            edited: isEdited(f.hasUserClaim, f.hasPendingUserClaim),
            value: getDisplayValue(f.hasUserClaim, f.hasPendingUserClaim),
        }));
    }

    public get achTemplateFeatures() {
        return this.getFeatures(templateFeatureIds);
    }

    public get achPaymentFeatures() {
        return this.getFeatures(paymentFeatureIds);
    }

    public get internationalAchTemplateFeatures() {
        return this.getFeatures(internationalTemplateFeatureIds);
    }

    public get internationalAchPaymentFeatures() {
        return this.getFeatures(internationalPaymentFeatureIds);
    }

    public get recipientFeatures() {
        return this.getFeatures(recipientFeatureIds);
    }

    public get reviewFeatures() {
        return this.getFeatures(reviewFeatureIds);
    }
}
