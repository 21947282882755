MessageCenterArchiveModalController.$inject = [
    '$scope',
    '$modalInstance',
    'message',
    'messagingCenterService',
];

export default function MessageCenterArchiveModalController(
    $scope,
    $modalInstance,
    message,
    messagingCenterService
) {
    $scope.message = message;

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.archive = function () {
        messagingCenterService
            .update({
                messageId: message.id,
                actionTypeId: 2,
            })
            .then(() => {
                $modalInstance.close($scope.message);
            });
    };
}
