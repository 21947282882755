import { DatePeriod } from '@treasury/domain/channel/types';
import moment from 'moment';

export enum DownloadPageId {
    ResearchTransactionsList = 'ResearchTransactionsList',
    DayBalanceReport = 'DayBalanceReport',
    DayTransactionsReport = 'DayTransactionsReport',
    DateRangeBalanceReport = 'DateRangeBalanceReport',
    DateRangeTransactionsReport = 'DateRangeTransactionsReport',
    EdiReport = 'EdiReport',
    ArpUploadList = 'ArpUploadList',
    AchExceptionFilterList = 'AchExceptionFilterList',
    ArpOutputFileList = 'ArpOutputFileList',
    StopPayRequestList = 'StopPayRequestList',
    AchPaymentList = 'AchPaymentList',
    AchBatchList = 'AchTemplateList',
    RecurringAchPaymentList = 'RecurringAchPaymentList',
    AchFileActivityList = 'AchFileActivityList',
    BeneficiaryList = 'BeneficiaryList',
    WireList = 'WireActivity',
    WireTemplateList = 'WireTemplateList',
    WireTemplateDetail = 'WireTemplate',
    RecurringWireList = 'RecurringWireList',
    TransferList = 'TransferList',
    TransferTemplateList = 'TransferTemplateList',
    RecurringTransferList = 'RecurringTransferList',
    LoanPaymentList = 'LoanPaymentList',
    AccountList = 'AccountList',
    AccountTransactionDetail = 'AccountTransactionDetail',
    ConfirmationStopPay = 'ConfirmationStopPay',
    ConfirmationAchPayment = 'ConfirmationACHPayment',
    InternalTransferConfirmation = 'InternalTransferConfirmation',
    CreateLoanPaymentConfirmation = 'CreateLoanPaymentConfirmation',
    CreateWireConfirmation = 'CreateWireConfirmation',
    CreateBeneficiaryConfirmation = 'CreateBeneficiaryConfirmation',
    CreateAchBatchConfirmation = 'CreateAchBatchConfirmation',
    BulkCreateWireConfirmation = 'BulkCreateWireConfirmation',
    WirePaymentDetail = 'WirePaymentDetail',
    AchPaymentDetail = 'ACHPaymentDetail',
    MultipleAchPaymentConfirmation = 'MultipleAchPaymentConfirmation',
    MultipleAchTaxPaymentsConfirmation = 'MultipleAchTaxPaymentsConfirmation',
    AchPaymentUploadConfirmation = 'AchPaymentUploadConfirmation',
    ArpExceptionsList = 'ArpExceptionsList',
    AchExceptionsList = 'AchExceptionsList',
    IssuedItemsActivity = 'IssuedItemsActivity',
    AchExceptionsDecisionActivityList = 'AchExceptionsDecisionActivityList',
    CheckImageList = 'CheckImageList',
}

export const constantsModule = angular
    .module('channel.constants', [])
    .constant('downloadPageId', DownloadPageId)
    .constant('moment', moment)
    .constant('dates', {
        MaxValue: new Date(9999, 11, 31),
    })
    .constant('dateConstants', {
        daysInMonth: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
            25, 26, 27, 28, 29, 30, 31,
        ],
        weekDays: [
            { name: 'Monday' },
            { name: 'Tuesday' },
            { name: 'Wednesday' },
            { name: 'Thursday' },
            { name: 'Friday' },
        ],
        paymentFrequencies: [
            {
                name: 'One Time',
                key: 'One Time',
            },
            {
                name: 'Weekly',
                key: 'Weekly',
            },
            {
                name: 'Every Two Weeks',
                key: 'Every Two Weeks',
            },
            {
                name: 'Twice a Month',
                key: 'Twice a Month',
            },
            {
                name: 'Monthly',
                key: 'Monthly',
            },
            {
                name: 'Quarterly',
                key: 'Quarterly',
            },
            {
                name: 'Every Six Months',
                key: 'Every Six Months',
            },
            {
                name: 'Yearly',
                key: 'Yearly',
            },
        ],
    })
    .constant('DatePeriod', DatePeriod);
