/* eslint-disable import/extensions */
// import noPunctuationMaskableValidator from '@treasury/omega/validators/noPunctuationMaskable.validator';
import * as primitives from '../primitives';
import { achCompanyMultiSelectSecCode } from './ach';

export const accountNumber = primitives.string;

export const accountFilterSettings = primitives.string;

export const routingNumber = primitives.string;

export const secCode = achCompanyMultiSelectSecCode;

export const expirationDate = primitives.date;

export const fromAmount = primitives.money;

export const toAmount = primitives.money;

export const amount = primitives.amountRangePrint;

export const transactionStatus = primitives.status;

export const detailStatus = primitives.list;

export const abaNumber = primitives.string;

export const amountRangeFilter = primitives.amountRange;

export const statusFilter = primitives.list;

export const filterRuleName = primitives.string;

export const filterStatus = primitives.string;

export const filterDatePicker = primitives.datePicker.with.options({
    data: ['none', 'today', 'specific'],
});

export const maskableAccountNumber = primitives.string.with
    .cellClass('account-number')
    .and.minColumnWidth(50)
    .and.targetColumnWidth(60)
    .and.maxColumnWidth(100);
