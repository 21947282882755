/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

/**
 * date-related requests for Treasury
 */
export class DateRequests {
    static async getHolidays() {
        return (await TmHttpClient.getInstance()).request(`holidays`, {
            method: 'GET',
            maxAgeInSeconds: 600000,
        });
    }
}

export default DateRequests;
