/* eslint-disable sonarjs/no-duplicate-string */
export const defaultFormat = fields => ({
    id: '',
    name: '',
    title: 'Wire Upload Template Formatting Tool',
    type: 'Delimited',
    excludeHeaderRows: 0,
    excludeFooterRows: 0,
    itemAmountFormat: 'DecimalIncluded',
    effectiveDateFormat: 'mm/dd/yyyy',
    containers: [
        { id: 'in', title: 'Drag and drop items to reorder columns', active: 'true' },
        {
            id: 'out',
            title: 'Drag and drop optional items to the space above to activate',
            active: 'false',
        },
    ],
    tiles: [
        /**
         * Use the fields from /wire/fileFormatField which should
         * contain the comprehensive list of all tiles available
         */
        ...fields.flat(),
        {
            id: 100,
            order: null,
            width: 1,
            type: 'filler',
            name: 'Filler Tile',
            optional: 'true',
            required: 'false',
        },
    ],
});
