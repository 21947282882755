/**
 * @prettier
 */

// <omega-timer name="a-globally-unique-timer-name" on-timeout="onTimeout()">

export default {
    bindings: {
        name: '@',
        timeoutCallback: '=',
    },
    controller: TimerController,
    template:
        '<i class="fa fa-clock-o set-position" aria-hidden="true"></i><span>{{$ctrl.displayTime()}}</span>',
};

TimerController.$inject = ['$interval', 'timerService'];

function TimerController($interval, timerService) {
    const $ctrl = this;

    $ctrl.$onInit = function () {
        $ctrl.updateSecondsRemaining();
        $ctrl.startTick();
    };

    $ctrl.startTick = function () {
        $ctrl.tick = $interval($ctrl.updateSecondsRemaining.bind($ctrl), 1000);
    };

    $ctrl.stopTick = function () {
        $interval.cancel($ctrl.tick);
    };

    $ctrl.updateSecondsRemaining = function () {
        $ctrl.secondsRemaining = timerService.remainingTimeInSeconds($ctrl.name);
        if (timerService.isTimedOut($ctrl.name) && $ctrl.timeoutCallback) {
            timerService.stop($ctrl.name);
            $ctrl.timeoutCallback();
        }
    };

    $ctrl.$onDestroy = function () {
        $ctrl.stopTick();
    };

    $ctrl.displayTime = function displayTime() {
        if (isNaN($ctrl.secondsRemaining)) {
            return '';
        }
        const minutes = Math.floor($ctrl.secondsRemaining / 60);
        const seconds = $ctrl.secondsRemaining % 60;
        return `${minutes}:${`0${seconds}`.slice(-2)}`;
    };
}
