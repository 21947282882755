export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                entityCaption: '@',
                selectAllCaption: '@?',
                selectionList: '=',
                selectionField: '@',
                ngDisabled: '=',
                controlId: '@',
                includeSelectAllOption: '=?',
                reportType: '=',
                isFilter: '@',
                ngRequired: '=?',
                accountMode: '=?',
                id: '@',
            },
            template: require('./dropdownMultiSelectTemplate.html'),
            controller: [
                '$scope',
                '$document',
                '$rootScope',
                function ($scope, $document, $rootScope) {
                    $scope.isDisabled = $scope.ngDisabled;
                    $scope.isRequired = $scope.ngRequired;
                    $scope.list = $scope.selectionList;
                    $scope.status = {
                        isOpen: false,
                        checkAll: false,
                    };

                    $scope.$on('accountTypes', (ev, data) => {
                        $scope.accountTypes = data;
                        if ($scope.isFilter) {
                            setVisibility();
                            setSequence();
                        }
                    });

                    function setSequence() {
                        angular.forEach($scope.list, account => {
                            if (!!account && !!account.number && !isNaN(account.number)) {
                                account.number = parseFloat(account.number);
                            }
                        });
                    }

                    $scope.filterList = function (array, column) {
                        const tmpArr = [];
                        angular.forEach(array, item => {
                            if (item.type === column) {
                                tmpArr.push(item);
                            }
                        });
                        return tmpArr;
                    };

                    function setVisibility() {
                        $scope.isCheckedObj = {
                            Checking: false,
                            Savings: false,
                            Loan: false,
                        };

                        if ($scope.accountTypes && $scope.accountTypes.length > 0) {
                            angular.forEach($scope.accountTypes, accountType => {
                                if (accountType.isChecked === true) {
                                    $scope.isCheckedObj[accountType.name] = true;
                                }
                            });

                            angular.forEach($scope.list, account => {
                                const checking =
                                    $scope.isCheckedObj.Checking === true ? 'Checking' : '';
                                const savings =
                                    $scope.isCheckedObj.Savings === true ? 'Savings' : '';
                                const loan = $scope.isCheckedObj.Loan === true ? 'Loan' : '';

                                if (
                                    account.type !== checking &&
                                    account.type !== savings &&
                                    account.type !== loan
                                ) {
                                    account.isVisible = false;
                                    account.isChecked = false;
                                } else {
                                    account.isVisible = true;
                                    if (angular.isDefined($scope.tmpIsCheckedObj) === true) {
                                        if (
                                            $scope.isCheckedObj.Checking !==
                                                $scope.tmpIsCheckedObj.Checking &&
                                            $scope.isCheckedObj.Checking === true &&
                                            account.type === 'Checking'
                                        ) {
                                            account.isChecked = true;
                                        }
                                        if (
                                            $scope.isCheckedObj.Savings !==
                                                $scope.tmpIsCheckedObj.Savings &&
                                            $scope.isCheckedObj.Savings === true &&
                                            account.type === 'Savings'
                                        ) {
                                            account.isChecked = true;
                                        }
                                        if (
                                            $scope.isCheckedObj.Loan !==
                                                $scope.tmpIsCheckedObj.Loan &&
                                            $scope.isCheckedObj.Loan === true &&
                                            account.type === 'Loan'
                                        ) {
                                            account.isChecked = true;
                                        }
                                    }
                                }
                            });

                            // we keep a temp version, so if they reselect, we "check all" of that type
                            $scope.tmpIsCheckedObj = angular.copy($scope.isCheckedObj);

                            changeDropdownLabel();
                            $scope.processClickEvent(false);
                        }
                    }

                    if ($scope.ngRequired === null || $scope.ngRequired === undefined) {
                        $scope.ngRequired = false;
                    }
                    if (
                        $scope.includeSelectAllOption === null ||
                        $scope.includeSelectAllOption === undefined
                    ) {
                        $scope.includeSelectAllOption = true;
                    }
                    if ($scope.selectAllCaption === null || $scope.selectAllCaption === undefined) {
                        $scope.selectAllCaption = 'Select All';
                    }

                    function changeDropdownLabel() {
                        if (
                            $scope.ngModel !== undefined &&
                            $scope.ngModel !== null &&
                            $scope.ngModel.length > 1
                        ) {
                            $scope.allChecked = true;
                            let atLeastOneVisible = false;

                            angular.forEach($scope.selectionList, item => {
                                if ($scope.isFilter) {
                                    if (item.isVisible === true) {
                                        atLeastOneVisible = true;
                                    }
                                    if (!item.isChecked && item.isVisible === true) {
                                        $scope.allChecked = false;
                                    }
                                } else if (!item.isChecked) {
                                    $scope.allChecked = false;
                                }
                            });

                            if ($scope.allChecked) {
                                $scope.selectedValue = 'All Selected';
                                $scope.status.checkAll = true;
                            } else {
                                $scope.selectedValue = 'Multiple Selected';
                                $scope.status.checkAll = false;
                            }
                        } else if (
                            $scope.ngModel !== undefined &&
                            $scope.ngModel !== null &&
                            $scope.ngModel.length === 1
                        ) {
                            $scope.selectedValue = $scope.ngModel[0][$scope.selectionField];
                            $scope.status.checkAll = false;
                        } else {
                            $scope.selectedValue = $scope.entityCaption;
                            $scope.status.checkAll = false;
                        }
                    }

                    $scope.processClickEvent = function (selectAllMode) {
                        $scope.ngModel = [];
                        let selectedCount = 0;

                        angular.forEach($scope.selectionList, item => {
                            if (selectAllMode === true) {
                                if ($scope.status.checkAll) {
                                    if ($scope.isFilter) {
                                        if (
                                            ($scope.isCheckedObj.Checking === true &&
                                                item.type === 'Checking') ||
                                            ($scope.isCheckedObj.Savings === true &&
                                                item.type === 'Savings') ||
                                            ($scope.isCheckedObj.Loan === true &&
                                                item.type === 'Loan')
                                        ) {
                                            $scope.ngModel.push(item);
                                            selectedCount++;
                                            item.isChecked = $scope.status.checkAll;
                                        }
                                    } else {
                                        $scope.ngModel.push(item);

                                        item.isChecked = $scope.status.checkAll;
                                    }
                                } else {
                                    item.isChecked = $scope.status.checkAll;
                                }
                            } else if (
                                item.isChecked !== null &&
                                item.isChecked !== undefined &&
                                item.isChecked === true
                            ) {
                                $scope.ngModel.push(item);
                                selectedCount++;
                            }
                        });

                        if (
                            $scope.ngModel.length === $scope.selectedCount &&
                            $scope.selectedCount > 0
                        ) {
                            $scope.status.checkAll = true;
                        } else if ($scope.ngModel.length === $scope.selectionList.length) {
                            $scope.status.checkAll = true;
                        } else {
                            $scope.status.checkAll = false;
                        }
                        if ($scope.includeSelectAllOption === false) {
                            processCustomValidation();
                        }
                        changeDropdownLabel();
                    };

                    function processCustomValidation() {
                        let isLoanChecked = false;
                        let isAnyChecked = false;
                        angular.forEach($scope.selectionList, item => {
                            // check if Loan is checked.
                            if (
                                item.isChecked !== null &&
                                item.isChecked !== undefined &&
                                item.isChecked === true &&
                                item.name === 'Loan'
                            ) {
                                isLoanChecked = true;
                            }
                            if (
                                item.isChecked !== null &&
                                item.isChecked !== undefined &&
                                item.isChecked === true
                            ) {
                                isAnyChecked = true;
                            }
                        });
                        $scope.isLoanChecked = isLoanChecked;
                        $scope.isAnyChecked = isAnyChecked;
                    }

                    function init() {
                        changeDropdownLabel();
                    }

                    init();

                    $document.on('click', e => {
                        let target = e.target.parentElement;
                        let parentFound = false;
                        while (angular.isDefined(target) && target !== null && !parentFound) {
                            if (
                                (target.className.split(' ').indexOf('menuitem') > -1 ||
                                    target.className.split(' ').indexOf('dropdown') > -1) &&
                                !parentFound
                            ) {
                                parentFound = true;
                            }
                            target = target.parentElement;
                        }
                        if (!parentFound) {
                            $scope.$apply(() => {
                                $scope.status.isOpen = false;
                            });
                        }
                    });

                    $scope.$watch('ngDisabled', newValue => {
                        $scope.isDisabled = newValue;
                    });

                    $scope.$watch('ngModel', newValue => {
                        $scope.ngModel = newValue;
                    });

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue && newValue.length > 0) {
                                $scope.list = newValue;
                                if ($scope.isFilter) {
                                    setVisibility();
                                    setSequence();
                                }
                                $scope.processClickEvent(false);
                            }
                        },
                        true
                    );

                    $scope.checkEnability = function (list) {
                        if (!$scope.accountMode) {
                            return false;
                        }
                        let result = false;
                        let count = 0;
                        angular.forEach(list, item => {
                            if (item.isChecked && item.name !== 'Loan') {
                                count++;
                            }
                        });
                        if (count >= 1) {
                            result = true;
                        }
                        return result;
                    };

                    $scope.checkLoanEnability = function (list) {
                        if (!$scope.accountMode) {
                            return false;
                        }
                        let result = true;
                        angular.forEach(list, item => {
                            if (
                                (item.name == 'Checking' || item.name == 'Savings') &&
                                item.isChecked
                            ) {
                                result = false;
                            }
                        });
                        return result;
                    };

                    $scope.anyOneChecked = function (list) {
                        let result = false;
                        angular.forEach(list, item => {
                            if (item.isChecked) {
                                result = true;
                            }
                        });
                        return result;
                    };
                },
            ],
        };
    },
];
