import { getChannelAPIPath } from '@treasury/core/http';

TranGroupCodeService.$inject = ['$resource', '$http', '$q'];

export default function TranGroupCodeService($resource, $http, $q) {
    const resourceUrl = `${getChannelAPIPath()}transactioncodegroup/`;
    return {
        get,
    };

    function get() {
        return $resource(
            resourceUrl,
            {},
            {
                getAll: { method: 'get', isArray: true },
            }
        ).getAll().$promise;
    }
}
