import { LitElement, html, css } from 'lit';
import { AlertMixin } from '@treasury/omega/mixins';
import { ListeningElementMixin } from '@treasury/omega/components';

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));

class ChildSupportTemplateReportHeader extends AlertingListeningElement {
    static get properties() {
        return {
            alert: Object,
            recordset: Object,
        };
    }

    firstUpdated() {
        this.listenTo(this.recordset, 'error', ({ detail }) => {
            this.alert = {
                ...this.alert,
                type: 'error',
                visible: true,
                message: detail.error.message,
            };
        });
    }

    render() {
        return [this.renderAlert()];
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}

customElements.define('child-support-template-report-header', ChildSupportTemplateReportHeader);
