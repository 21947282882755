LoanTransferListController.$inject = [
    '$scope',
    '$state',
    'toaster',
    '$timeout',
    '$uibModal',
    '$stateParams',
    '$location',
    'companyAccountsService',
    'internalTransfersService',
    'utilityService',
    'modalService',
    'entitlementsService',
    'downloadPageId',
    'securityService',
    'holidaysService',
    'activityService',
    'resourceType',
    'auditCode',
    'transferStatusFilter',
    'frequencyFilter',
    'frequencyService',
    '$filter',
];

export default function LoanTransferListController(
    $scope,
    $state,
    toaster,
    $timeout,
    $uibModal,
    $stateParams,
    $location,
    companyAccountsService,
    internalTransfersService,
    utilityService,
    modalService,
    entitlementsService,
    downloadPageId,
    securityService,
    holidaysService,
    activityService,
    resourceType,
    auditCode,
    transferStatusFilter,
    frequencyFilter,
    frequencyService,
    $filter,
    dateFilterService
) {
    $scope.downloadPageId = downloadPageId.LoanPaymentList;
    $scope.isCollapsed = false;
    $scope.userHasApproveRights = false;
    $scope.userHasCreateRights = false;
    $scope.item = {
        isChecked: false,
    };
    $scope.accountListFrom = [];
    $scope.accountListTo = [];
    $scope.accountListBoth = [];
    $scope.inEditTransfer = {};
    $scope.fromAccountsByTransaction = null;
    $scope.toAccountsByTransaction = null;

    $scope.$watch('inEditTransfer', onInEditTransferChange, true);

    $scope.isNegative = isNegative;
    $scope.getFromAccounts = getFromAccounts;
    $scope.getToAccounts = getToAccounts;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.timeToCutoff = 0;
    $scope.isCurrentDay = isCurrentDay;
    $scope.filteredTransfers = null;
    $scope.filterSearch = filterSearch;
    $scope.searchObj = { text: null };
    $scope.disableFilter = disableFilter;
    $scope.displayFromBalance = displayFromBalance;
    $scope.displayToBalance = displayToBalance;
    $scope.getToAccountBalance = getToAccountBalance;
    $scope.disableDownload = true;

    function displayFromBalance(account) {
        return account != null && account.availableBalance != null;
    }

    function displayToBalance(account) {
        return account != null && getToAccountBalance(account) != null;
    }

    function getToAccountBalance(account) {
        return account.currentBalance;
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredTransfers = $filter('filter')($scope.loanList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.loanList ||
            $scope.loanList.length <= 0
        ) {
            return true;
        }

        return (
            (!!row.transactionId &&
                row.transactionId.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.fromAccount &&
                !!row.fromAccount.number &&
                row.fromAccount.number
                    .toLowerCase()
                    .indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.toAccount &&
                !!row.toAccount.number &&
                row.toAccount.number.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.createdDate &&
                $filter('date')(row.createdDate, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.transferDate &&
                $filter('date')(row.transferDate, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.parentFrequencyModel &&
                !!row.parentFrequencyModel.type &&
                $scope
                    .frequencyTypeToFriendly(row.parentFrequencyModel.type)
                    .indexOf($scope.searchObj.text) !== -1) ||
            (!!row.amount &&
                $filter('currency')(row.amount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.paymentOptionType &&
                row.paymentOptionType.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function disableFilter() {
        return $scope.loanList ? $scope.loanList.length <= 0 : true;
    }

    function getFromAccounts() {
        return $scope.fromAccountsByTransaction || $scope.accountListFrom;
    }

    function getToAccounts() {
        return $scope.toAccountsByTransaction || $scope.accountListTo;
    }

    function onInEditTransferChange(newInEditTransfer, oldInEditTransfer) {
        let newFromId;
        let oldFromId;
        let newToId;
        let oldToId;

        function isObject(value) {
            return value === Object(value);
        }

        oldFromId = isObject(oldInEditTransfer.fromAccount)
            ? oldInEditTransfer.fromAccount.id
            : null;
        newFromId = isObject(newInEditTransfer.fromAccount)
            ? newInEditTransfer.fromAccount.id
            : null;
        oldToId = isObject(oldInEditTransfer.toAccount) ? oldInEditTransfer.toAccount.id : null;
        newToId = isObject(newInEditTransfer.toAccount) ? newInEditTransfer.toAccount.id : null;

        // From account changed
        if (newFromId !== oldFromId) {
            loadFilteredAccountsList('To', newFromId).then(response => {
                $scope.toAccountsByTransaction = response;
            });
        }
        // To account changed
        if (newToId !== oldToId) {
            loadFilteredAccountsList('From', newToId).then(response => {
                $scope.fromAccountsByTransaction = response;
            });
        }
    }

    function isNegative(value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    }

    function isCurrentDay(value) {
        const today = new Date();
        return moment(today).format('L') === moment(value).format('L') && $scope.timeToCutoff > 0;
    }

    $scope.createTransfer = function () {
        $state.go('payables.create-loan-payment');
    };

    $scope.scrollLocation = function (element) {
        $location.hash(element);
        $anchorScroll();
    };

    $scope.isObject = function (value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    };

    $scope.resetValues = function (type) {
        if (type === 'amount') {
            if (
                $scope.filterObject.amountOperator === 'Specific Amount' ||
                $scope.filterObject.amountOperator === 'Range' ||
                $scope.filterObject.amountOperator === '--Select Amount--'
            ) {
                $scope.filterObject.fromAmount = null;
                $scope.filterObject.toAmount = null;
            }
        }
        if (type === 'createdDate') {
            if (
                $scope.filterObject.createdDateOperator === 'Specific Date' ||
                $scope.filterObject.createdDateOperator === 'Date Range' ||
                $scope.filterObject.createdDateOperator === '--Select Created Date--'
            ) {
                $scope.filterObject.createdDateFrom = null;
                $scope.filterObject.createdDateTo = null;
            }
        }
        if (type === 'transferDate') {
            if (
                $scope.filterObject.transferDateOperator === 'Specific Date' ||
                $scope.filterObject.transferDateOperator === 'Date Range'
            ) {
                $scope.filterObject.transferDateFrom = null;
                $scope.filterObject.transferDateTo = null;
            }
        }
    };

    function formatDate(dt) {
        return moment(dt).format('MM/DD/YYYY');
    }

    $scope.filterObject = {
        transferDateOperator: null,
        transferDateFrom: null, // found
        transferDateTo: null, // found
        paymentOptionType: null,
        status: null,
        accountOperator: null, // found
        fromAccounts: [],
        toAccounts: [],
        amountOperator: null,
        createdDateOperator: null,
        createdDateFrom: null,
        createdDateTo: null,
        transactionId: null, // found
        fromAmount: null, // found
        toAmount: null, // found
    };

    function loadDateTypeList() {
        $scope.dateTypeList = [
            {
                name: 'Specific Date',
            },
            {
                name: 'Date Range',
            },
        ];
    }

    function loadCreatedDateTypeList() {
        $scope.dateTypeListCreatedDate = [
            {
                name: 'Specific Date',
            },
            {
                name: 'Date Range',
            },
        ];
    }

    function loadAmountTypeList() {
        $scope.amountTypeList = [
            {
                name: 'Specific Amount',
            },
            {
                name: 'Range',
            },
        ];
    }

    $scope.reset = function () {
        init(true);
        loadDefaults(true);
    };

    function loadFilteredAccountsList(type, oppositeAccountId) {
        return companyAccountsService
            .getCompanyAccountsForProductFeature(
                type,
                'InternalTransfer',
                'list',
                oppositeAccountId
            )
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                return response;
            });
    }

    function loadAccountsList(type, isFilterReset) {
        if (isFilterReset) {
            // default to all selected accounts
            angular.forEach($scope.accountListFrom, item => {
                item.isChecked = true;
            });

            // default to all selected accounts
            angular.forEach($scope.accountListTo, item => {
                item.isChecked = true;
            });

            // default to all selected accounts
            angular.forEach($scope.accountListBoth, item => {
                item.isChecked = true;
            });
        } else {
            companyAccountsService
                .getCompanyAccountsWithProductFeature(type, 'InternalTransfer')
                .then(response => {
                    if (
                        response.length > 0 &&
                        response[0] !== null &&
                        typeof response[0] !== 'undefined'
                    ) {
                        $scope.accountDisplayField = response[0].showAccountNickName
                            ? 'name'
                            : 'number';
                    }
                    if (type === 'From') {
                        $scope.accountListFrom = response;

                        // default to all selected accounts
                        angular.forEach($scope.accountListFrom, item => {
                            item.isChecked = true;
                            $scope.accountListBoth.push(angular.copy(item));
                        });

                        loadAccountsList('To', isFilterReset);
                    } else if (type === 'To') {
                        $scope.accountListTo = response;

                        // default to all selected accounts
                        angular.forEach($scope.accountListTo, item => {
                            item.isChecked = true;

                            // mail merge accountListFrom and AccountListTo into accountListBoth
                            let toAccountExists = false;
                            angular.forEach($scope.accountListBoth, _item => {
                                if (item.number === _item.number) {
                                    toAccountExists = true;
                                }
                            });
                            if (toAccountExists === false) {
                                $scope.accountListBoth.push(angular.copy(item));
                            }
                        });

                        getLoanList();
                    }
                });
        }
    }

    function loadPaymentOptionTypeList() {
        $scope.paymentOptionTypeList = [
            {
                key: 1,
                value: 'Regular Payment',
                number: 1,
            },
            {
                key: 2,
                value: 'Principal Only',
                number: 2,
            },
        ];
    }

    function loadStatusList() {
        $scope.statusList = [
            {
                key: 'PendingApproval',
                value: 'Pending Approval',
            },
            {
                key: 'Scheduled',
                value: 'Scheduled',
            },
            {
                key: 'Submitted',
                value: 'Submitted',
            },
            {
                key: 'ApprovalRejected',
                value: 'Approval Rejected',
            },
            {
                key: 'Cancelled',
                value: 'Cancelled',
            },
            {
                key: 'Failed',
                value: 'Failed',
            },
            {
                key: 'Expired',
                value: 'Expired',
            },
        ];
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('InternalTransfer').then(response => {
            $scope.processingCutoff = response;
            $scope.timeToCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableToday = true;

                if ($scope.inEditTransfer) {
                    if (
                        moment($scope.inEditTransfer.transferDate).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.inEditTransfer.transferDate = formatDate(response);
                            });
                    }
                }

                if ($scope.loanList) {
                    angular.forEach($scope.loanList, transfer => {
                        if (transfer.status === 'Submitted') {
                            transfer.canDelete = false;
                        }
                    });
                }

                if ($scope.timeToCutoff > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create transfer for today.');
                }
            }, $scope.timeToCutoff);
        });
    }

    function init(isFilterReset) {
        getHolidays();
        loadCutoffTimes();
        loadDateTypeList();
        if (!isFilterReset) {
            loadPaymentOptionTypeList();
            loadStatusList();
        }
        $scope.userHasCreateRights = entitlementsService.hasEntitlement('Transfer, Create');
        loadAmountTypeList();
        loadCreatedDateTypeList();
        loadDefaults();
        $scope.orderByField = 'transferDate';
        loadAccountsList('From', isFilterReset);
        $scope.currentDate = moment().format('MM/DD/YYYY');
    }

    $scope.isValidAccounts = function (transfer) {
        let isValid = true;
        if (
            transfer.fromAccount === null ||
            transfer.fromAccount === undefined ||
            typeof transfer.fromAccount !== 'object'
        ) {
            isValid = false;
        }
        if (
            transfer.toAccount === null ||
            transfer.toAccount === undefined ||
            typeof transfer.toAccount !== 'object'
        ) {
            isValid = false;
        }
        return isValid;
    };

    function loadDefaults(isReset) {
        let date = new Date();
        if (
            ($scope.filterObject.transferDateOperator === null ||
                $scope.filterObject.transferDateOperator === undefined ||
                isReset === true) &&
            !$stateParams.transactionId
        ) {
            $scope.filterObject.transferDateOperator = $scope.dateTypeList[1].name;
        } else if ($stateParams.transactionId) {
            $scope.filterObject.transferDateOperator = $scope.dateTypeList[0].name;
            $scope.filterObject.transactionId = $stateParams.transactionId;
            $scope.filterObject.transferDateFrom = $stateParams.transferDate;
        }
        if (
            ($scope.filterObject.transferDateFrom === null ||
                $scope.filterObject.transferDateFrom === undefined ||
                isReset === true) &&
            !$scope.transactionId
        ) {
            $scope.filterObject.transferDateFrom = `${
                date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()}`;
        }
        if (
            $scope.filterObject.transferDateTo === null ||
            $scope.filterObject.transferDateTo === undefined ||
            isReset === true
        ) {
            date = new Date();
            date.setDate(date.getDate() + 7);
            $scope.filterObject.transferDateTo = `${
                date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()}`;
        }
        if (
            $scope.filterObject.paymentOptionType === null ||
            $scope.filterObject.paymentOptionType === undefined ||
            isReset === true
        ) {
            angular.forEach($scope.paymentOptionTypeList, paymentOptionType => {
                paymentOptionType.isChecked = true;
            });
        }
        if (
            $scope.filterObject.status === null ||
            $scope.filterObject.status === undefined ||
            isReset === true
        ) {
            angular.forEach($scope.statusList, status => {
                status.isChecked = true;
            });
        }
        if (
            $scope.filterObject.accountOperator === null ||
            $scope.filterObject.accountOperator === undefined ||
            isReset === true
        ) {
            $scope.filterObject.accountOperator = 'Both';
        }
        if (
            $scope.filterObject.amountOperator === null ||
            $scope.filterObject.amountOperator === undefined ||
            isReset === true
        ) {
            $scope.filterObject.amountOperator = $scope.amountTypeList[0].name;
        }
        if (
            $scope.filterObject.createdDateOperator === null ||
            $scope.filterObject.createdDateOperator === undefined ||
            isReset === true
        ) {
            $scope.filterObject.createdDateOperator = $scope.dateTypeList[0].name;
        }
        if (isReset) {
            $scope.filterObject.transactionId = null;
            angular.forEach($scope.accountListFrom, account => {
                account.isChecked = true;
            });
            angular.forEach($scope.accountListTo, account => {
                account.isChecked = true;
            });
            angular.forEach($scope.accountListBoth, account => {
                account.isChecked = true;
            });
            $scope.filterObject.createdDateFrom = null;
            $scope.filterObject.createdDateTo = null;
            $scope.filterObject.fromAmount = null;
            $scope.filterObject.toAmount = null;
        }
    }

    function parseErrorsForApproveReject(loanList) {
        determineUserPermissionsForApprove();
        let hasError = false;
        angular.forEach($scope.loanList, loanInList => {
            loanInList.message = '';
            let removeLastComma = false;
            angular.forEach(loanList, transferInResponse => {
                if (transferInResponse.errorSummary) {
                    hasError = true;
                    if (loanInList.id === transferInResponse.id) {
                        loanInList.isError = true;
                        const { errorSummary } = transferInResponse;
                        if (errorSummary.details && errorSummary.details.length > 0) {
                            if (
                                errorSummary.details[0].messageList &&
                                errorSummary.details[0].messageList.length > 0
                            ) {
                                let message = '';
                                angular.forEach(
                                    errorSummary.details[0].messageList,
                                    errorMessage => {
                                        message += `${errorMessage.value}, `;
                                        removeLastComma = true;
                                    }
                                );
                                loanInList.message = message;
                            } else {
                                loanInList.message += errorSummary.details[0].message;
                            }
                        } else {
                            loanInList.message += errorSummary.summaryMessage;
                        }
                        loanInList.messageType = 'Error';
                    }
                }
            });
            if (removeLastComma) {
                loanInList.message = loanInList.message.substring(0, loanInList.message.length - 2);
            }
        });
        return hasError;
    }

    $scope.processBatch = function (processType) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                message() {
                    return processType === 'A'
                        ? 'Are you sure you want to batch approve these loan payments?'
                        : 'Are you sure you want to batch reject these loan payments?';
                },
                actionButtonText() {
                    return processType === 'A' ? 'Approve Loan Payments' : 'Reject Loan Payments';
                },
                actionButtonClass() {
                    return processType === 'A' ? 'btn-success' : 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
                headerText() {
                    return processType === 'A' ? 'Confirm Approval' : 'Confirm Rejection';
                },
            },
        });
        modalInstance.result.then(comments => {
            const batchPayload = {
                comments,
                internalTransfers: [],
            };
            angular.forEach($scope.loanList, transfer => {
                if (transfer.isSelected != undefined && transfer.isSelected === true) {
                    batchPayload.internalTransfers.push(transfer);
                }
            });
            let actionType;
            if (processType === 'A') {
                actionType = 'Approve Transfer';
                securityService
                    .verifyUser(actionType, batchPayload, () =>
                        internalTransfersService.batchApprove(batchPayload, 'loan')
                    )
                    .then(response => {
                        const hasError = parseErrorsForApproveReject(response.internalTransfers);
                        angular.forEach(batchPayload.internalTransfers, localTransfer => {
                            angular.forEach(response.internalTransfers, responseTransfer => {
                                if (responseTransfer.id == localTransfer.id) {
                                    if (responseTransfer.errorSummary != undefined) {
                                        responseTransfer.messageType = localTransfer.messageType;
                                        responseTransfer.message = localTransfer.message;
                                    } else {
                                        localTransfer.messageType = null;
                                        localTransfer.message = null;
                                    }
                                    afterTransferApproved(localTransfer, responseTransfer);
                                }
                            });
                        });
                        if (hasError === false) {
                            toaster.save('Batch Approve');
                        }
                    });
            } else {
                actionType = 'Reject Transfer';
                securityService
                    .verifyUser(actionType, batchPayload, () =>
                        internalTransfersService.batchReject(batchPayload)
                    )
                    .then(response => {
                        $scope.batchResponse = response.internalTransfers;
                        internalTransfersService
                            .getTransferList($scope.filterObject)
                            .then(_response => {
                                $scope.loanList = _response;
                                setApprovalAttributes();
                                const hasError = parseErrorsForApproveReject($scope.batchResponse);
                                const message = 'Batch Reject';
                                if (hasError === false) {
                                    toaster.save(message);
                                }
                                if ($scope.loanList.length === 0) {
                                    $scope.disableDownload = true;
                                } else {
                                    $scope.disableDownload = false;
                                }
                            });
                    });
            }
        });
    };

    function afterTransferApproved(localTransfer, remoteTransfer) {
        angular.extend(localTransfer, remoteTransfer);
        setTransferApprovalAttributes(localTransfer);
        updateTransferPermissions(localTransfer);
        localTransfer.approvalStatusReady = false;
        localTransfer.isSelected = false;
    }

    function hasAccess(permissions, permissionValue) {
        let hasRights = false;
        angular.forEach(permissions, permission => {
            if (permission.permission === permissionValue) {
                hasRights = true;
            }
        });
        return hasRights;
    }

    function determineUserPermissionsForApprove() {
        $scope.userHasApproveRights = false;
        angular.forEach($scope.loanList, transfer => {
            updateTransferPermissions(transfer);
            $scope.userHasApproveRights = $scope.userHasApproveRights || transfer.canApprove;
        });
    }

    function updateTransferPermissions(transfer) {
        if (hasAccess(transfer.permissions, 'Approve') === true) {
            transfer.canApprove = true;
        } else {
            transfer.canApprove = false;
        }
        if (hasAccess(transfer.permissions, 'Edit') === true) {
            transfer.canEdit = true;
        } else {
            transfer.canEdit = false;
        }
        if (hasAccess(transfer.permissions, 'Create') === true) {
            transfer.canCreate = true;
        } else {
            transfer.canCreate = false;
        }
        if (
            hasAccess(transfer.permissions, 'Delete') &&
            (transfer.status !== 'Submitted' ||
                (moment(transfer.transferDate, 'YYYY-MM-DD').format('MM/DD/YYYY') ===
                    $scope.currentDate &&
                    $scope.timeToCutoff > 0))
        ) {
            transfer.canDelete = true;
        } else {
            transfer.canDelete = false;
        }
    }

    $scope.selectOrDeselectTransfers = function () {
        let atLeastOneChecked = false;
        angular.forEach($scope.loanList, transfer => {
            if (transfer.canApprove === true && transfer.status === 'PendingApproval') {
                transfer.isSelected = $scope.item.isChecked;
                atLeastOneChecked = true;
            }
        });
        if (atLeastOneChecked === false) {
            $scope.item.isChecked = false;
        }
    };

    $scope.isAnyTransferInEditMode = function () {
        let result = false;
        angular.forEach($scope.loanList, transfer => {
            if (
                transfer.isEditMode === true ||
                transfer.isConfirmationMode === true ||
                transfer.isEditTransferMode === true
            ) {
                result = true;
            }
        });
        return result;
    };

    function determineIsChecked() {
        $scope.isBatchChecked = false;
        let areAllChecked = true;
        angular.forEach($scope.loanList, loan => {
            if (loan.isSelected === true) {
                $scope.isBatchChecked = true;
            }
            if (loan.canApprove && loan.status === 'PendingApproval') {
                if (
                    loan.isSelected === null ||
                    loan.isSelected === undefined ||
                    loan.isSelected === false
                ) {
                    areAllChecked = false;
                }
            }
        });
        if ($scope.isBatchChecked && areAllChecked) {
            $scope.item.isChecked = areAllChecked;
        } else {
            $scope.item.isChecked = false;
        }
    }

    $scope.$watch(
        'loanList',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                determineIsChecked();
            }
        },
        true
    );

    $scope.search = function () {
        getLoanList();
    };

    function setApprovalAttributes() {
        $scope.loanList.map(setTransferApprovalAttributes);
    }

    function setTransferApprovalAttributes(transfer) {
        transfer.approvalAttributes = {
            approvalEntity: 'internalTransfer',
            approvalCount: transfer.completedApprovalCount,
            amount: transfer.amount,
            createdBy: transfer.createdBy,
            updatedBy: transfer.updatedBy,
            productId: transfer.id,
        };
        return transfer;
    }

    $scope.closeEditMode = function (transfer) {
        clearTransferErrors(transfer);
        transfer.isEditMode = false;
    };

    $scope.closeConfirmation = function (transfer) {
        transfer.isEditTransferMode = false;
        transfer.isConfirmationMode = false;
        transfer.isEditMode = false;
    };

    $scope.cancelTransfer = function (transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                message() {
                    return 'Are you sure you want to cancel this Loan Payment?';
                },
                actionButtonText() {
                    return 'Cancel Loan Payment';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Close';
                },
                headerText() {
                    return 'Confirm Cancellation';
                },
            },
        });
        modalInstance.result.then(comments => {
            const cancelTransferModel = {
                internalTransferId: transfer.id,
                comments,
            };
            securityService
                .verifyUser('DeleteTransfer_ByName', cancelTransferModel, () =>
                    internalTransfersService.cancelTransfer(cancelTransferModel)
                )
                .then(response => {
                    angular.extend(transfer, response);
                    transfer.status = response.status;
                    $scope.batchResponse = [response];
                    const hasError = parseErrorsForApproveReject($scope.batchResponse);
                    if (hasError === false) {
                        activityService.userInteractionAudit(
                            resourceType.PaymentResources,
                            auditCode.CancelOneTimeTransfer
                        );
                        transfer.status = response.status;
                        transfer.isError = false;
                        transfer.isSelected = false;
                        $scope.closeEditMode(transfer);
                        toaster.save('Cancel Loan Payment');
                    } else {
                        transfer.isError = true;
                    }
                });
        });
    };

    $scope.changeTransfer = function (transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                message() {
                    return 'Are you sure you want to resubmit this loan payment?';
                },
                actionButtonText() {
                    return 'Resubmit Loan Payment';
                },
                actionButtonClass() {
                    return 'btn-primary';
                },
                closeButtonText() {
                    return 'Cancel';
                },
                headerText() {
                    return 'Confirm Resubmission';
                },
            },
        });
        modalInstance.result.then(comments => {
            transfer.comments = comments;
            if (
                $scope.tempTransfer.fromAccount.number !==
                    $scope.inEditTransfer.fromAccount.number ||
                $scope.tempTransfer.toAccount.number !== $scope.inEditTransfer.toAccount.number ||
                $scope.tempTransfer.amount !== $scope.inEditTransfer.amount ||
                $scope.tempTransfer.transferDate.substring(0, 10) !==
                    $scope.inEditTransfer.transferDate.substring(0, 10) ||
                $scope.tempTransfer.memo !== $scope.inEditTransfer.memo ||
                $scope.tempTransfer.paymentOptionType !== $scope.inEditTransfer.paymentOptionType
            ) {
                transfer.isDirty = true;
            } else {
                transfer.isDirty = false;
            }

            $scope.inEditTransfer.frequencyModel = { type: 'One Time' };
            $scope.inEditTransfer.comments = comments;
            $scope.inEditTransfer.isDirty = transfer.isDirty;
            $scope.inEditTransfer.errorSummary = null;
            securityService
                .verifyUser('Edit Transfer', $scope.inEditTransfer, () =>
                    internalTransfersService.updateTransfer($scope.inEditTransfer)
                )
                .then(response => {
                    // extend attributes of response to transfer
                    const hasError = parseIndividualErrors(response);
                    angular.extend(transfer, response);
                    determineUserPermissionsForApprove();
                    angular.extend($scope.inEditTransfer, response);
                    transfer.status = response.status;
                    if (hasError === false) {
                        transfer.isConfirmationMode = true;
                        transfer.isEditTransferMode = false;
                        toaster.save('Resubmit Loan Payment');
                    }
                });
        });
    };

    $scope.approveTransfer = function (transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                message() {
                    return 'Are you sure you want to approve this loan payment?';
                },
                headerText() {
                    return 'Confirm Approval';
                },
                actionButtonText() {
                    return 'Approve Loan Payment';
                },
                actionButtonClass() {
                    return 'btn-success';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            transfer.comments = comments;
            securityService
                .verifyUser('Approve Transfer', transfer, () =>
                    internalTransfersService.approveTransfer(transfer, 'loan')
                )
                .then(response => {
                    const hasError = parseIndividualErrors(response);
                    afterTransferApproved(transfer, response);
                    if (hasError === false) {
                        $scope.closeEditMode(transfer);
                        toaster.save('Approve Loan Payment');
                    }
                });
        });
    };

    function parseIndividualErrors(transaction) {
        let hasErrors = false;
        if (
            transaction.errorSummary !== null &&
            transaction.errorSummary !== undefined &&
            transaction.errorSummary.details !== null &&
            transaction.errorSummary.details !== undefined &&
            transaction.errorSummary.details.length > 0
        ) {
            hasErrors = true;
            transaction.errorMessage = transaction.errorSummary.summaryMessage;
            angular.forEach(transaction.errorSummary.details, item => {
                if (item.attributeName === 'fromAccount') {
                    transaction.fromAccountErrorMessage = parseErrorDetailMessage(item);
                } else if (item.attributeName === 'balance') {
                    transaction.balanceErrorMessage = parseErrorDetailMessage(item);
                }
            });
        } else {
            clearTransferErrors(transaction);
        }
        return hasErrors;
    }

    function parseErrorDetailMessage(detail) {
        let errMsg = '';
        if (
            detail.messageList !== null &&
            detail.messageList !== undefined &&
            detail.messageList.length > 0
        ) {
            angular.forEach(detail.messageList, message => {
                errMsg += `${message.value}, `;
            });
            errMsg = errMsg.substring(0, errMsg.length - 2);
        } else {
            errMsg = detail.message;
        }
        return errMsg;
    }

    $scope.rejectTransfer = function (transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                message() {
                    return 'Are you sure you want to reject this loan payment?';
                },
                headerText() {
                    return 'Confirm Rejection';
                },
                actionButtonText() {
                    return 'Reject Loan Payment';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            transfer.comments = comments;
            securityService
                .verifyUser('Reject Transfer', transfer, () =>
                    internalTransfersService.rejectTransfer(transfer)
                )
                .then(response => {
                    // extend attributes of response to transfer
                    const hasError = parseIndividualErrors(response);
                    angular.extend(transfer, response);
                    transfer.status = response.status;
                    if (hasError === false) {
                        transfer.status = response.status;
                        transfer.isSelected = false;
                        $scope.closeEditMode(transfer);
                        toaster.save('Reject Transfer');
                    }
                });
        });
    };

    $scope.findAccount = function (type, transfer) {
        const modalInstance = $uibModal.open({
            template: require('../../../../dashboard/widgets/views/transferAccountSelectionModal.html'),
            size: 'md',
            controller: 'AccountSelectionController',
            backdrop: 'static',
            resolve: {
                accountsList() {
                    if (type === 'fromAccount') {
                        return angular.copy(getFromAccounts());
                    }
                    if (type === 'toAccount') {
                        return angular.copy(getToAccounts());
                    }
                },
                showCurrentBalanceForLoanAccounts() {
                    return type === 'toAccount';
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            $scope.inEditTransfer[type] = selectedAccount;
            transfer[type] = selectedAccount;
        });
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;

        // in Loan Payment Edit Mode, set dirty state of transaction date so red error displays
        if (form.ctrl_datepicker_2 !== undefined) {
            form.ctrl_datepicker_2.$setDirty();
        }
    };

    function clearTransferErrors(transfer) {
        transfer.errorMessage = '';
        transfer.balanceErrorMessage = '';
        transfer.fromAccountErrorMessage = '';
    }

    $scope.setInTransferEdit = function (transfer) {
        transfer.isEditTransferMode = true;
        $state.editLoanPaymentMode = true;
        $state.go('payables.edit-loan-payment', {
            id: transfer.id,
            fromAccount: transfer.fromAccount,
            toAccount: transfer.toAccount,
            amount: transfer.amount,
            paymentOptionType: transfer.paymentOptionType,
        });
    };

    $scope.setEditMode = function (transfer) {
        setEditModeFalse();
        transfer.isEditMode = true;
    };

    function setEditModeFalse() {
        angular.forEach($scope.loanList, transfer => {
            transfer.isEditMode = false;
        });
    }

    $scope.changeAccountType = function (value) {
        if (value === 'Both') {
            angular.forEach($scope.accountListBoth, account => {
                account.isChecked = true;
            });
        } else if (value === 'From' && $scope.filterObject.accountOperator !== 'FromTo') {
            angular.forEach($scope.accountListFrom, account => {
                account.isChecked = true;
            });
        } else if (value === 'To' && $scope.filterObject.accountOperator !== 'FromTo') {
            angular.forEach($scope.accountListTo, account => {
                account.isChecked = true;
            });
        }
        if (
            $scope.filterObject.accountOperator === null ||
            $scope.filterObject.accountOperator === undefined
        ) {
            $scope.filterObject.accountOperator = value;
        } else if (value === 'From' && $scope.filterObject.accountOperator == 'FromTo') {
            $scope.filterObject.accountOperator = 'To';
        } else if (value === 'To' && $scope.filterObject.accountOperator == 'FromTo') {
            $scope.filterObject.accountOperator = 'From';
        } else if (value === 'From' || value === 'To') {
            if (
                ($scope.filterObject.accountOperator === 'From' && value === 'To') ||
                ($scope.filterObject.accountOperator === 'To' && value === 'From')
            ) {
                $scope.filterObject.accountOperator = 'FromTo';
            } else {
                $scope.filterObject.accountOperator = value;
            }
        } else {
            $scope.filterObject.accountOperator = value;
        }
    };

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            if ($scope.inEditTransfer) {
                utilityService
                    .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                    .then(response => {
                        $scope.inEditTransfer.transferDate = formatDate(response);
                    });
            }
        });
    }

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    $scope.getFrequencySummary = function (frequency) {
        return frequencyService.summarizeFrequency(frequency);
    };

    $scope.frequencyTypeToFriendly = function (frequencyType) {
        return frequencyService.frequencyTypeToFriendly(frequencyType);
    };

    function getLoanList() {
        internalTransfersService.getLoanPaymentsList($scope.filterObject).then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }

            let index = 0;
            response = response.map(transfer => {
                transfer.defaultIndex = index++;
                return transfer;
            });
            $scope.orderByField = 'defaultIndex';
            $scope.loanList = response;
            if ($scope.loanList.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
            setApprovalAttributes();
            $scope.searchObj.text = null;
            $scope.filterSearch($scope.searchObj.text);
            determineUserPermissionsForApprove();
        });
    }

    init(false);
}
