/* eslint-disable @treasury/style-includes-host-display */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import { refreshIcon } from '@treasury/omega/css/icons/refresh-icon.js';
import '@treasury/omega/layouts/omega-report';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { printNode } from '@treasury/utils';
import { LitElement, css, html, nothing, render } from 'lit';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-template-action-dialog.js';
import AccountReconClient from '../clients/account-recon-client.js';
import { columns, fields, filters } from '../data/ach-account-reconciliation-library-data.js';
import { AccountReconMessage } from '../data/ach-account-reconciliation-report-workflow.js';

class AchAccountReconciliationLibraryContainer extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            actions: Object,
            reportActions: Array,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            institution: String,
            reportLinks: Array,
            recordset: Object,
            client: Object,
            showDeleteDialog: Boolean,
            loading: Boolean,
            printing: Boolean,
            fileIdsToDeleteQueue: Array,
        };
    }

    constructor() {
        super();
        this.reportLinks = [];
        this.columns = columns;
        this.showDeleteDialog = false;
        this.fileIdsToDeleteQueue = [];
        this.reportPreviewDialogHandle = null;
        this.actions = {
            run: record => this.runReport(record),
            delete: record => this.openDeleteDialog(record),
            download: async record => {
                this.loading = true;
                const fileResponse = await AchAccountReconciliationService.fetchReportFile(
                    record.getField('id'),
                    'PDF'
                );
                this.reportUrl = `data:application/pdf;base64,${fileResponse.fileContent}#toolbar=0`;
                this.reportFileName = fileResponse.fileName;
                await this.downloadReport(1, '');
                this.loading = false;
            },
            edit: record => this.editReport(record),
            clickReportName: record => this.openReportPreview(record),
        };
        this.pageTitle = 'Report Library';
        this.rowsPerPage = 25;
    }

    deleteActiveFile = () => {
        // Queues the active file for deletion
        this.fileIdsToDeleteQueue = [this.activeReport?.getField('id')];
        this.openDeleteDialog(null, true);
    };

    get availableFileOutputTypes() {
        if (!this.activeReport) return [];
        const report = this.recordset.recordsMatching('id', this.activeReport.getField('id'));
        if (!report) return [];

        return report[0].getField('availableFileOutputTypes')?.values || [];
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new AccountReconClient();
        }

        const di = await DiContainer.getInstance();
        this.dialogService = await di.getAsync(OmegaDialogService);
        this.fields = fields(this.client);
        this.filters = filters(this.client);

        this.recordset = new Recordset(this.fields, this.client.fetchReportFiles);
        this.recordset.setInitialPageSize(this.rowsPerPage);
        this.listenTo(this.recordset, 'error', ({ detail }) => {
            this.alert = {
                ...this.alert,
                type: 'error',
                visible: true,
                message: detail.error.message,
            };
        });
        this.reportLinks = [
            {
                title: 'Report Templates',
                route: 'ir.accountReconciliation',
            },
        ];
        this.reportActions = [
            {
                type: 'primary',
                label: 'Delete Selected',
                isDisabled: () => this.areAllRadioButtonsUnselected(),
                action: () => this.deleteSelectedFiles(),
            },
        ];
    }

    areAllRadioButtonsUnselected() {
        return !this.recordset.allRecords.some(record => record.getField('selected'));
    }

    async runReport(report) {
        const reportId = report.getField('id');
        const di = await DiContainer.getInstance();

        this.loading = true;
        try {
            const runPromise = AchAccountReconciliationService.runReport(reportId);
            const displayContent = {
                result: 'Submitted',
                message: AccountReconMessage.SaveAndRunMessage,
            };
            setTimeout(() => {
                this.alert = {
                    type: 'success',
                    title: displayContent.result,
                    message: displayContent.message,
                    code: '',
                    time: '',
                    posture: 'polite',
                    visible: true,
                };
            }, 100);
            await runPromise;
            setTimeout(() => {
                const navService = di.get(NavigationService);
                navService.navigate('ir.accountReconciliation');
            }, 250);
        } catch (e) {
            this.setErrorAlert(e);
        } finally {
            this.loading = false;
        }
    }

    refreshReports() {
        this.clearActiveReport();
        // this.refreshingReports = true;
        this.recordset.requestHardUpdate();
    }

    clearActiveReport() {
        this.reportUrl = null;
        this.reportFileName = null;
        this.activeReport = null;
    }

    async deleteFiles(fileIdsToDelete) {
        if (!fileIdsToDelete?.length) return;
        this.loading = true;
        const singular = fileIdsToDelete.length === 1;
        const resultPromise = AchAccountReconciliationService.deleteReportFiles(fileIdsToDelete);

        if (this.activeReport && fileIdsToDelete.includes(this.activeReport.getField('id'))) {
            this.clearActiveReport();
        }

        // Indicate success when completed
        const result = await resultPromise;
        if (result.every(item => item.success)) {
            const successMessage = singular
                ? 'The selected report successfully deleted!'
                : 'The selected reports successfully deleted!';
            this.dispatchEvent(
                new CustomEvent('alert', {
                    detail: {
                        result: '',
                        message: successMessage,
                    },
                })
            );
        } else {
            this.dispatchEvent(
                new CustomEvent('errorAlert', {
                    detail: {
                        message: 'One or more reports might not have been deleted successfully!',
                    },
                })
            );
        }
        this.loading = false;
        this.refreshReports();
    }

    deleteSelectedFiles() {
        // Queues the selected files for deletion
        this.fileIdsToDeleteQueue = this.recordset
            .recordsMatching('selected', true)
            .map(item => item.getField('id'));
        this.openDeleteDialog();
    }

    async openReportPreview(report) {
        this.loading = true;
        try {
            this.clearActiveReport();
            this.activeReport = report;
            const fileResponse = await AchAccountReconciliationService.fetchReportFile(
                report.getField('id'),
                'PDF'
            );
            this.reportUrl = `data:application/pdf;base64,${fileResponse.fileContent}#toolbar=0`;
            this.reportFileName = fileResponse.fileName;
            this.loading = false;
            this.reportPreviewDialogHandle = this.dialogService.open(
                html`${this.renderFileViewer()}`,
                'Report Preview',
                {
                    flexDirection: 'row',
                    widthUnits: 'vw',
                    width: 90,
                    heightUnits: 'vh',
                    height: 80,
                    fullscreenContent: true,
                    buttons: {
                        [OmegaDialogExitReason.Cancel]: null,
                        [OmegaDialogExitReason.Confirm]: {
                            label: 'Close',
                        },
                    },
                }
            );
            await this.reportPreviewDialogHandle.closed;
        } catch (error) {
            console.log(error);
            this.dispatchEvent(new CustomEvent('errorAlert', { detail: error }));
        } finally {
            this.loading = false;
        }
    }

    /**
     * @param { TmApiError | unknown } error
     */
    setErrorAlert(error) {
        if (error instanceof TmApiError) {
            const { message, errorCode: code, timestamp: time } = error;
            this.alert = {
                type: 'warning',
                title: '',
                message,
                code,
                time,
                posture: 'assertive',
                visible: true,
            };
        } else {
            this.alert = {
                type: 'warning',
                title: '',
                message:
                    error instanceof Error ? error.message : 'An error occurred. Please try again',
                posture: 'assertive',
                visible: true,
            };
        }
    }

    async editReport(reportRecord) {
        const di = await DiContainer.getInstance();
        const navService = di.get(NavigationService);

        return navService.navigate('ir.accountReconciliation-workflow', {
            id: reportRecord.getField('id'),
            reportValues: reportRecord.values,
        });
    }

    async openDeleteDialog(record, isReportShowing) {
        if (record) this.fileIdsToDeleteQueue = [record?.getField('id')];
        // if (!this.fileIdsToDeleteQueue?.length) return nothing;
        await this.reportPreviewDialogHandle?.close();
        const countFilesToDelete = this.fileIdsToDeleteQueue.length;
        const messageOption =
            countFilesToDelete < 2 ? `this report` : `these ${countFilesToDelete} reports`;

        const response = await this.dialogService.open(
            `Are you sure you want to delete ${messageOption}?`,
            'Delete Reports',
            {
                buttons: {
                    [OmegaDialogExitReason.Cancel]: {
                        label: 'No',
                    },
                    [OmegaDialogExitReason.Confirm]: {
                        label: 'Yes',
                    },
                },
            }
        ).closed;

        if (response.reason === OmegaDialogExitReason.Confirm) {
            this.deleteFiles([...this.fileIdsToDeleteQueue]);
        } else if (isReportShowing) this.openReportPreview(this.activeReport);
    }

    async downloadReport(fileId, fileType) {
        const link = document.createElement('a');

        if (fileId === 1) {
            link.href = this.reportUrl;
            link.download = this.reportFileName;
        } else {
            const fileResponse = await AchAccountReconciliationService.fetchReportFile(
                this.activeReport.getField('id'),
                fileId
            );
            link.href = `data:text/${fileType.toLowerCase()};base64,${fileResponse.fileContent}`;
            link.download = fileResponse.fileName;
        }

        // Append link to the body
        this.shadowRoot.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        this.shadowRoot.removeChild(link);
    }

    async printReport() {
        this.printing = true;
        const printRecordset = new Recordset(this.fields, () => this.recordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.columns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    renderBlockingLoader() {
        if (this.loading || this.printing) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    renderFileViewer() {
        return html`<ach-account-reconciliation-report-viewer
            .activeReport=${this.activeReport}
            .reportUrl=${this.reportUrl}
            .reportFileName=${this.reportFileName}
            .loadingFile=${this.loadingFile}
            .availableFileOutputTypes=${this.availableFileOutputTypes}
            @downloadReport=${event => this.downloadReport(event.detail.id, event.detail.name)}
            @deleteActiveFile=${this.deleteActiveFile}
        ></ach-account-reconciliation-report-viewer>`;
    }

    render() {
        if (!this.recordset) return nothing;
        return html`
            ${this.renderAlert()} ${this.renderBlockingLoader()}
            <omega-report
                autostart
                flyout
                .title=${this.pageTitle}
                .actions=${this.actions}
                .recordset=${this.recordset}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .columns=${this.columns}
                .options=${['print']}
                .printFunction=${() => this.printReport()}
                .downloadOptions=${[]}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                >
                    <omega-button
                        type="icon"
                        posture="small"
                        hidelabel="always"
                        class="refresh-button"
                        slot="actions"
                        aria-label="Refresh reports"
                        @click=${() => {
                            this.refreshReports();
                        }}
                    >
                        <div slot="icon">${refreshIcon}</div>
                    </omega-button>
                </omega-filter-bar></omega-report
            >
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
                display: block;
                height: 100%;
            }
            div.dialog-content {
                padding: 30px;
                text-align: center;
            }
            #type-to-filter {
                padding: 0 10px;
            }
            @media print {
                .child-support-templates-container {
                    box-shadow: none;
                    border: 1px solid #ccc;
                }
            }
        `;
    }
}

customElements.define(
    'ach-account-reconciliation-library-container',
    AchAccountReconciliationLibraryContainer
);
export default AchAccountReconciliationLibraryContainer;
