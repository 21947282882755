import { AchBatchRecipientSummary } from '@treasury/domain/channel/types/ach';
import { boolean, Record } from '@treasury/FDL';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import { BatchSelectedRecipientsModal } from '../../../dialogs/batch-selected-recipients-modal';

export const getColumns = (
    context: BatchSelectedRecipientsModal
): OmegaColumnDefinition<AchBatchRecipientSummary>[] => [
    { field: 'batch', label: 'Batch' },
    { field: 'name', label: 'Recipient' },
    { field: 'secCode', label: 'SEC Code' },
    { field: 'accountNumber', label: 'Account Number' },
    { field: 'routingNumber', label: 'Routing Number' },
    {
        field: 'transactionType',
        label: 'Credit/Debit',
    },
    { field: 'amount', label: 'Amount' },
    {
        field: 'prenote',
        label: 'Prenote',
        sortable: true,
    },
    {
        label: 'Addenda',
        type: OmegaColumnType.Actions,
        actions: [
            {
                label: 'Addenda',
                action: (r: Record<AchBatchRecipientSummary>) => context.openAddendaDialog(r),
            },
        ],
    },
];

export const getFilters = () => [
    {
        field: 'prenote',
        fieldType: boolean.with
            .label('Prenote Only')
            .thatIs.inline()
            .as.tag('omega-checkbox')
            .thatHas.schema('boolean'),
        value: false,
    },
];
