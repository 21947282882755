import { svg } from 'lit';

export const listIcon = svg`<svg width="15" height="13" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <path d="M3.75 1.992V.82c0-.259.21-.468.469-.468H14.53c.26 0 .469.21.469.468v1.172c0 .26-.21.469-.469.469H4.22a.469.469 0 01-.469-.469zm.469 5.156H14.53c.26 0 .469-.21.469-.468V5.508a.469.469 0 00-.469-.469H4.22a.469.469 0 00-.469.469V6.68c0 .259.21.468.469.468zm0 4.688H14.53c.26 0 .469-.21.469-.469v-1.172a.469.469 0 00-.469-.468H4.22a.469.469 0 00-.469.468v1.172c0 .26.21.469.469.469zM.469 2.812h1.875c.259 0 .469-.21.469-.468V.469A.469.469 0 002.343 0H.47A.469.469 0 000 .469v1.875c0 .259.21.469.469.469zm0 4.688h1.875c.259 0 .469-.21.469-.469V5.156a.469.469 0 00-.47-.468H.47A.469.469 0 000 5.155v1.875c0 .26.21.469.469.469zm0 4.688h1.875c.259 0 .469-.21.469-.47V9.845a.469.469 0 00-.47-.469H.47A.469.469 0 000 9.844v1.875c0 .259.21.469.469.469z" id="list-a"/>
  </defs>
<g fill="none" fill-rule="evenodd">
  <mask id="list-b" fill="#fff">
    <use xlink:href="#list-a"/>
  </mask><use fill="#FFF" fill-rule="nonzero" xlink:href="#list-a"/>
  <path fill="#8B8D92" mask="url(#list-b)" d="M-5-8h26v26H-5z"/>
</g>
</svg>`;
