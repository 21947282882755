import { Events } from '../../models/events.js';

const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function twoDigit(n) {
    return n < 10 ? `0${n}` : n.toString();
}

function daysInMonth(year, month) {
    const dayBeforeFirstDayOfNextMonth = new Date(year, month + 1, 0);
    return dayBeforeFirstDayOfNextMonth.getDate();
}

export default class DateModel {
    constructor(date) {
        this.isShowingMonthList = false;
        this.listeners = {
            [Events.CHANGE]: [],
            [Events.CHANGE_MONTH]: [],
            [Events.CLEAR]: [],
            [Events.SELECT_MONTH]: [],
            [Events.CLOSE_SELECT_MONTH]: [],
            [Events.CLOSE_SELECT_YEAR]: [],
        };

        if (date) {
            this.setDate(date);
        } else {
            this.isEmpty = true;
        }
    }

    isSet() {
        return !this.isEmpty;
    }

    toString() {
        if (this.isEmpty) {
            return '';
        }
        return `${this.year}-${twoDigit(this.month + 1)}-${twoDigit(this.dayOfMonth)}`;
    }

    displayFormat() {
        if (this.isEmpty) {
            return '';
        }
        return `${twoDigit(this.month + 1)}/${twoDigit(this.dayOfMonth)}/${this.year}`;
    }

    equals(otherDate) {
        if (this.isEmpty) return false;
        if (typeof otherDate === 'string') {
            return this.toString() === otherDate;
        }
        return (
            this.year === otherDate.year &&
            this.month === otherDate.month &&
            this.dayOfMonth === otherDate.dayOfMonth
        );
    }

    lessThan(otherDate) {
        if (this.isEmpty || otherDate.isEmpty) return false;
        return this.toString() < otherDate.toString();
    }

    greaterThan(otherDate) {
        if (this.isEmpty || otherDate.isEmpty) return false;
        return this.toString() > otherDate.toString();
    }

    inRangeOf(startDate, endDate) {
        if (this.isEmpty || startDate.isEmpty || endDate.isEmpty) return false;
        return this.toString() >= startDate.toString() && this.toString() <= endDate.toString();
    }

    on(event, fn) {
        this.listeners[event].push(fn);
    }

    notifyListeners(event) {
        this.listeners[event].forEach(fn => fn());
    }

    setDate(date) {
        this.isEmpty = false;
        const oldYear = this.year;
        const oldMonth = this.month;
        if (typeof date === 'undefined') {
            throw new TypeError('date is undefined!');
        }
        if (typeof date === 'string') {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#Differences_in_assumed_time_zone
            const normalizedDateString = /\d\d\d\d-\d\d-\d\d/.test(date)
                ? `${date}T00:00:00`
                : date;
            const newDate = new Date(normalizedDateString);
            this.year = newDate.getFullYear();
            this.month = newDate.getMonth();
            this.dayOfMonth = newDate.getDate();
            this.dayOfWeek = newDate.getDay();
        } else {
            this.year = date.getFullYear();
            this.month = date.getMonth();
            this.dayOfMonth = date.getDate();
            this.dayOfWeek = date.getDay();
        }
        if (Number.isNaN(this.year)) {
            throw new TypeError(`Invalid date passed to DateModel: ${date}`);
        }
        this.notifyListeners(Events.CHANGE);
        if (this.year !== oldYear || this.month !== oldMonth) {
            this.notifyListeners(Events.CHANGE_MONTH);
        }
    }

    setMonth(n) {
        this.setDate(new Date(this.year, n, this.dayOfMonth));
        this.closeSelectMonth();
    }

    setYear(n) {
        this.setDate(new Date(n, this.month, this.dayOfMonth));
        this.closeSelectYear();
    }

    setDayOfMonth(n) {
        this.dayOfMonth = n;
        this.setDate(new Date(this.year, this.month, n));
    }

    goBackOneWeek() {
        this.setDate(new Date(this.year, this.month, this.dayOfMonth - 7));
    }

    goForwardOneWeek() {
        this.setDate(new Date(this.year, this.month, this.dayOfMonth + 7));
    }

    goBackOneDay() {
        this.setDate(new Date(this.year, this.month, this.dayOfMonth - 1));
    }

    goForwardOneDay() {
        this.setDate(new Date(this.year, this.month, this.dayOfMonth + 1));
    }

    goBackOneMonth() {
        const daysInLastMonth = daysInMonth(this.year, this.month - 1);

        this.setDate(
            new Date(this.year, this.month - 1, Math.min(this.dayOfMonth, daysInLastMonth))
        );
    }

    goForwardOneMonth() {
        const daysInNextMonth = daysInMonth(this.year, this.month + 1);

        this.setDate(
            new Date(this.year, this.month + 1, Math.min(this.dayOfMonth, daysInNextMonth))
        );
    }

    goBackOneYear() {
        this.setDate(new Date(this.year - 1, this.month, this.dayOfMonth));
    }

    goForwardOneYear() {
        this.setDate(new Date(this.year + 1, this.month, this.dayOfMonth));
    }

    selectMonth() {
        this.notifyListeners(Events.SELECT_MONTH);
        this.isShowingMonthList = true;
    }

    closeSelectMonth() {
        this.notifyListeners(Events.CLOSE_SELECT_MONTH);
        this.isShowingMonthList = false;
    }

    closeSelectYear() {
        this.notifyListeners(Events.CLOSE_SELECT_YEAR);
    }

    getMonth() {
        return this.month;
    }

    getFullYear() {
        return this.year;
    }

    getDate() {
        return this.dayOfMonth;
    }

    getDay() {
        return this.dayOfWeek;
    }

    monthName() {
        return MONTH_NAMES[this.month];
    }

    clear() {
        this.isEmpty = true;
        this.notifyListeners(Events.CLEAR);
        this.notifyListeners(Events.CHANGE);
    }

    setToToday() {
        this.setDate(new Date());
    }

    setToFirstDayOfWeek() {
        const today = new Date();
        this.setDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1)
        );
    }

    setToFirstDayOfMonth() {
        const today = new Date();
        this.setDate(new Date(today.getFullYear(), today.getMonth(), 1));
    }

    setToFirstDayOfYear() {
        const today = new Date();
        this.setDate(new Date(today.getFullYear(), 0, 1));
    }

    isWeekend() {
        const checkDate = new Date(this.year, this.month, this.dayOfMonth);
        return [0, 6].includes(checkDate.getUTCDay());
    }

    getMonthDays(startDay = 0) {
        const firstOfMonth = new Date(this.year, this.month, 1);
        let day = new Date(
            firstOfMonth.getFullYear(),
            firstOfMonth.getMonth(),
            firstOfMonth.getDate() - ((7 + firstOfMonth.getDay() - startDay) % 7)
        );

        const days = [];

        while (days.length < 42) {
            days.push(day);
            day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
        }
        return days;
    }
}
