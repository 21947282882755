StateChangeService.$inject = ['$resource'];

export default function StateChangeService($resource) {
    let states = {};

    return {
        isChanged,
        resetAll,
    };

    function isChanged(type, entityId, value) {
        let result = false;

        if (states[type] === undefined) {
            states[type] = {};
        }

        if (states[type][entityId] !== undefined) {
            result = states[type][entityId] != value;
        } else {
            result = false;
        }

        states[type][entityId] = value;

        return result;
    }

    function resetAll() {
        states = {};
    }
}
