import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';

export const mapAccountsBasedOnUserConfiguration = (accountSettings, accounts) =>
    accounts.map(account => ({
        accountUniqueId: account.accountUniqueId,
        accountNumber: account.accountNumber,
        name:
            accountSettings.accountNicknames === true
                ? account.accountNickname
                : account.accountNumber,
        accountType: account.accountType,
        claims: account.claims,
    }));

const mapAccountIds = filter => {
    if (filter.accountNumber === 'all' || !filter.accountNumber) return null;
    return filter.accountNumber;
};

const mapRoutingNumber = filter => {
    if (
        filter.routingNumber === 'all' ||
        !filter.routingNumber ||
        filter.routingNumber.length === 17041
    )
        return null;
    return filter.routingNumber;
};

const mapStatuses = filter => {
    // Change the length check to 5 after the Day 2 options for status are re-enabled.
    if (!filter.status || filter.status.length === 3) return null;
    return filter.status;
};

const mapSecCodes = filter => {
    if (!filter.secCode || filter.secCode.length === filter.secCodeAll.length) return null;
    return filter.secCode;
};

const mapTransactionTypes = filter => {
    if (
        !filter.transactionType ||
        filter.transactionType.length === 2 ||
        filter.transactionType === 'all'
    )
        return null;
    return filter.transactionType;
};

const mapAllowType = filter => {
    if (!filter.transactionType) return new Error('Invalid Combination');
    const allow = 'Allow';
    const disallow = 'Disallow';
    if (filter.transactionType.toLowerCase().trim() === 'debit') {
        if (filter.allowAllDebits) return disallow;
        if (!filter.allowAllDebits) return allow;
    }
    if (filter.transactionType.toLowerCase().trim() === 'credit') {
        if (filter.allowAllCredits) return disallow;
        if (!filter.allowAllCredits) return allow;
    }
    return allow;
};

export const mapAchExceptionToFilterLookup = (exception, accountUniqueId) => ({
    achCompanyId: exception.achCompanyId,
    transactionType: exception.exceptionType.split('ACH')[1].trim(),
    amount: AmountRangeFormatters.numberToSpecificAmount(exception.amount),
    secCode: exception.secCode,
    achCompanyName: exception.achCompanyName,
    remarks: null,
    expirationDate: null,
    nickName: null,
    filterRuleName: null,
    accountNumber: exception.accountNumber,
    accountUniqueId,
    mode: 'edit',
    allowAllDebits: null /* Have to look this up from getAccountMasterSettings API */,
    allowAllCredits: null /* Have to look this up from getAccountMasterSettings API */,
});

export const createAccountFilterMessageFromAllowProperties = response => {
    const { accountAllowsAllCredits, accountAllowsAllDebits } = response;
    if (accountAllowsAllCredits && accountAllowsAllDebits) {
        return 'Debits Allowed / Credits Allowed';
    }
    if (accountAllowsAllCredits) {
        return 'Debits Not Allowed / Credits Allowed';
    }
    if (accountAllowsAllDebits) {
        return 'Debits Allowed / Credits Not Allowed';
    }
    return 'Debits Not Allowed / Credits Not Allowed';
};

export const mapSearchAchFilterRequest = filter => {
    if (!Object.keys(filter).length) return {};
    if (Object.keys(filter).length) {
        const { start, end } = AmountRangeFormatters.getAmountStartAndEnd(filter.amount);
        return {
            AccountUniqueIds: mapAccountIds(filter),
            AchCompanyId: filter.achCompanyId ? filter.achCompanyId : null,
            AchCompanyName: filter.achCompanyName ? filter.achCompanyName : null,
            AmountEnd: end,
            AmountStart: start,
            RoutingNumbers: mapRoutingNumber(filter),
            SecCodes: mapSecCodes(filter),
            Statuses: mapStatuses(filter),
            TransactionTypes: mapTransactionTypes(filter),
        };
    }
    return {};
};

export const mapSearchAchFilterResponse = filters =>
    filters.map(filter => ({
        ...filter,
        detailStatus: filter.status,
        allowAllCredits: filter.accountAllowsAllCredits,
        allowAllDebits: filter.accountAllowsAllDebits,
        accountMasterSettings: createAccountFilterMessageFromAllowProperties(filter),
        transactionType: filter.transactionType,
    }));

export const mapSaveAchFilterRequest = filter => {
    const { start, end } = AmountRangeFormatters.getAmountStartAndEnd(filter.amount);
    let { expirationDate } = filter;
    if (expirationDate?.id === 'none') {
        expirationDate = null;
    }
    if (expirationDate) {
        if (expirationDate.dates) {
            [expirationDate] = expirationDate.dates;
        }
        expirationDate =
            expirationDate instanceof Date
                ? `${expirationDate.toISOString().slice(0, 10)}T00:00:00.000`
                : expirationDate;
    }
    return {
        AchFilterUniqueId: filter.achFilterUniqueId ? filter.achFilterUniqueId : null,
        AccountUniqueId: filter.account.accountUniqueId ? filter.account.accountUniqueId : null,
        AccountNumber: filter.account.accountNumber ? filter.account.accountNumber : null,
        AchCompanyId: filter.achCompanyId ? filter.achCompanyId : null,
        AchCompanyName: filter.achCompanyName ? filter.achCompanyName : null,
        ExpirationDate: expirationDate,
        AllowType: mapAllowType(filter),
        FilterRuleName: filter.filterRuleName ? filter.filterRuleName : null,
        ToAmount: end,
        FromAmount: start,
        Remarks: filter.remarks,
        SecCode: filter.secCode ? filter.secCode : null,
        TransactionType: filter.transactionType,
        RoutingNumber: '',
    };
};

export const mapAchFilterDownloadRequest = filter => {
    const mappedValues = {
        AccountUniqueIds: mapAccountIds(filter),
        AchCompanyId: filter.achCompanyId ? filter.achCompanyId : null,
        AchCompanyName: filter.achCompanyName ? filter.achCompanyName : null,
        // AmountEnd: end,
        // AmountStart: start,
        RoutingNumbers: mapRoutingNumber(filter),
        SecCodes: mapSecCodes(filter),
        Statuses: mapStatuses(filter),
        TransactionTypes: mapTransactionTypes(filter),
    };
    return { mappedValues, DownloadType: filter.type };
};
