const validCharacters = '1234567890,.';
export default [
    '$filter',
    function ($filter) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link($scope, $element, attrs, ngModel) {
                ngModel.$parsers.push(viewValue => {
                    if (viewValue == null || viewValue === '') return viewValue;

                    const trimmed = viewValue.trim();
                    if (trimmed.length === 0) return null;

                    const numberIndex = trimmed.search(/[0-9.]/);
                    if (numberIndex === -1) return null;

                    const stripped = trimmed
                        .substring(numberIndex)
                        .replace(/[^0-9,.]/g, '')
                        .trim();
                    if (stripped.length === 0) return null;

                    return parseFloat(stripped);
                });

                ngModel.$formatters.unshift(modelValue => $filter('currency')(modelValue));

                ngModel.$validators.treasuryCurrency = function (modelValue, viewValue) {
                    let value = modelValue;
                    if (modelValue == null) {
                        value = viewValue;
                    }

                    if (value == null || value === '') return true;

                    if (isNaN(value)) return false;

                    const parsed = parseFloat(value);
                    return !isNaN(parsed);
                };

                ngModel.$viewChangeListeners.push(() => {
                    if (!document.activeElement.isSameNode($element[0])) {
                        displayAsCurrency();
                    }
                });

                $element.on('blur', displayAsCurrency);

                $element.on('keypress', event => {
                    const input = String.fromCharCode(event.keyCode);
                    preventInvalidInput(input, event);
                });

                $element.on('paste', event => {
                    const clipboard = event.originalEvent.clipboardData || window.clipboardData;
                    const input = clipboard.getData('text');
                    preventInvalidInput(input, event);
                });

                $element.on('focus', () => {
                    if (window.getSelection().toString().length === 0) {
                        $element[0].setSelectionRange(0, $element.val().length);
                    }
                });

                function displayAsCurrency() {
                    if (ngModel.$valid) {
                        $element.val($filter('currency')(ngModel.$modelValue));
                    }
                }

                function preventInvalidInput(input, event) {
                    let character;
                    for (
                        let iInputCharacter = 0;
                        iInputCharacter < input.length;
                        ++iInputCharacter
                    ) {
                        character = input.charAt(iInputCharacter);
                        if (validCharacters.indexOf(character) === -1) {
                            event.preventDefault();
                            break;
                        }
                    }
                }
            },
        };
    },
];
