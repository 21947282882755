/* eslint-disable import/extensions */
import FieldType from '@treasury/FDL/field-type.js';
import { alphanumeric } from '../lib/validators';
import { money, string } from '../primitives';

export const childSupportPaymentFrequency = new FieldType().with
    .schema('frequency')
    .and.options([{ text: 'One Time', value: 'OneTime' }])
    .and.defaultValue({
        repeatOn: null,
        startOn: null,
        endOn: null,
        valueDate: null,
        nextPaymentDate: null,
        noEndDate: true,
        repeatOnDay1: null,
        repeatOnDay2: null,
        repeatOnLastBusinessDay: null,
        type: null,
        summary: '',
        id: 0,
        updatedBy: null,
        updatedDate: '0001-01-01T00:00:00',
    });

export const childSupportCaseIdentifier = new FieldType().with
    .validator({
        name: 'no-asterisk',
        validate: value => !value?.includes('*'),
    })
    .and.maxLength(20);

export const childSupportPaymentAmount = money.with.validator({
    name: 'length',
    validate: value => {
        if (!value) return true;
        return value?.toString().length <= 10;
    },
});

export const nonCustodialParentLastName = string.with.validator(alphanumeric).and.maxLength(20);

export const nonCustodialParentFirstName = nonCustodialParentLastName;
