import AchPaymentsServices from '@treasury/domain/channel/services/ach/ach-payments-services.js';
import AchRecurringServices from '@treasury/domain/channel/services/ach/ach-recurring-services.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';

export default class RecurringAchPaymentsClient {
    constructor() {
        this.entitlementsService = EntitlementsService.instance;
    }

    async fetchRecurringAchPayments(...args) {
        return AchRecurringServices.fetchRecurringAchActivity(...args);
    }

    get updatePaymentStatus() {
        return AchPaymentsServices.updateRecurringAchPaymentStatus;
    }

    get downloadRecurringAchPayments() {
        return AchRecurringServices.downloadRecurringAchPayments;
    }

    async hasFeatureFlag(flagStr) {
        const featureFlagService = await FeatureFlagService.getInstance();
        return featureFlagService.isEnabled(flagStr);
    }

    async entitlements(...args) {
        return this.entitlementsService.getEntitlements(...args);
    }

    async fetchApprovers(...args) {
        return UsersService.fetchApprovers(...args);
    }
}
