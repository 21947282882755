import { ReportsDashboardService } from '@treasury/domain/channel/services/reporting/reports-dashboard-service.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';

export default class ReportingDashboardClient {
    async getReports() {
        return ReportsDashboardService.getReports();
    }

    async deleteReport(...args) {
        return ReportsDashboardService.deleteReport(...args);
    }

    async favoriteReport(...args) {
        return ReportsDashboardService.favoriteReport(...args);
    }

    async hasEntitlement(...args) {
        return EntitlementsService.hasEntitlement(...args);
    }
}
