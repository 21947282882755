import { getChannelAPIPath } from '@treasury/core/http';

RemoteDepositCaptureSsoService.$inject = ['$resource'];

export default function RemoteDepositCaptureSsoService($resource) {
    const resourceUrl = `${getChannelAPIPath()}receivables/`;
    const settingsUrl = `${getChannelAPIPath()}users/`;

    return {
        getRemoteDepositCaptureSsoLogin,
        getAdditionalRemoteDepositSettings,
    };

    function getRemoteDepositCaptureSsoLogin() {
        return $resource(
            `${resourceUrl}getRemoteDepositCaptureSsoLogin`,
            {},
            { get: { isArray: false } }
        ).get().$promise;
    }

    function getAdditionalRemoteDepositSettings() {
        return $resource(
            `${settingsUrl}getAdditionalRemoteDepositSettings`,
            {},
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }
}
