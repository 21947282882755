AchExceptionOpenItemsSaveConfirmationModalController.$inject = [
    '$scope',
    '$modalInstance',
    'failedDecisionsCount',
    'confirmedDecisionCount',
];

export default function AchExceptionOpenItemsSaveConfirmationModalController(
    $scope,
    $modalInstance,
    failedDecisionsCount,
    confirmedDecisionCount
) {
    // variables
    $scope.form = null;
    $scope.failedDecisionsCount = failedDecisionsCount;
    $scope.confirmedDecisionCount = confirmedDecisionCount;
    $scope.haveExceptionsSavedSuccessfully = failedDecisionsCount === 0;

    // functions
    $scope.close = close;
    $scope.setForm = setForm;

    // public functions

    function close() {
        $modalInstance.close({
            errorMessage:
                failedDecisionsCount === 0
                    ? ''
                    : `${failedDecisionsCount} out of ${confirmedDecisionCount} decisions failed to save`,
            successMessage:
                failedDecisionsCount === 0 ? 'Your decisions were saved successfully' : '',
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    // private functions
}
