import './cando/cando.module.js';
import './components/module.js';
import './controls/module.js';
import './formatting/module.js';
import {
    AutoTabTo,
    DateGreaterThan,
    DateLowerThan,
    DisableDate,
    FormatDate,
    GreaterThan,
    IgnoreDirty,
    IsValidDate,
    LessThan,
    LessThanOrEqual,
    MaxDaysInFuture,
    NgEnter,
    SyncFocus,
    ValidSubmit,
} from './utility.js';
import './validation/module.js';

import ValidationGroup from './validationGroupDir.js';

export const directivesModule = angular
    .module('channel.directives', [
        'channel.directives.cando',
        'channel.directives.components',
        'channel.directives.controls',
        'channel.directives.formatting',
        'channel.directives.validation',
    ])
    .directive('dateLowerThan', DateLowerThan)
    .directive('maxDaysInFuture', MaxDaysInFuture)
    .directive('dateGreaterThan', DateGreaterThan)
    .directive('isValidDate', IsValidDate)
    .directive('formatDate', FormatDate)
    .directive('validSubmit', ValidSubmit)
    .directive('syncFocus', SyncFocus)
    .directive('ngEnter', NgEnter)
    .directive('ignoreDirty', IgnoreDirty)
    .directive('lessThanOrEqual', LessThanOrEqual)
    .directive('disableDate', DisableDate)
    .directive('autoTabTo', AutoTabTo)
    .directive('ignoreDirty', IgnoreDirty)
    .directive('lessThan', LessThan)
    .directive('greaterThan', GreaterThan)
    .directive('validationGroup', ValidationGroup);
