export const createAccountFilterMessage = response => {
    const { creditDefaultAllowType, debitDefaultAllowType } = response;
    const credit = creditDefaultAllowType.toLowerCase().trim();
    const debit = debitDefaultAllowType.toLowerCase().trim();
    if (credit === 'allow' && debit === 'allow') {
        return {
            label: 'Debits Allowed / Credits Allowed',
            allowAllCredits: true,
            allowAllDebits: true,
        };
    }
    if (credit === 'disallow' && debit === 'disallow') {
        return {
            label: 'Debits Not Allowed / Credits Not Allowed',
            allowAllCredits: false,
            allowAllDebits: false,
        };
    }
    if (credit === 'allow') {
        return {
            label: 'Debits Not Allowed / Credits Allowed',
            allowAllCredits: true,
            allowAllDebits: false,
        };
    }
    if (debit === 'allow') {
        return {
            label: 'Debits Allowed / Credits Not Allowed',
            allowAllCredits: false,
            allowAllDebits: true,
        };
    }
    return {
        label: 'Debits Not Allowed / Credits Not Allowed',
        allowAllCredits: false,
        allowAllDebits: false,
    };
};
