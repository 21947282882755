export default () => ({
    restrict: 'A',
    require: '?ngModel',
    link(scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push(inputValue => {
            if (inputValue == undefined) return '';
            const transformedInput = inputValue.replace(/[^\w ]|_/g, '');
            if (transformedInput !== inputValue) {
                modelCtrl.$setViewValue(transformedInput);
                modelCtrl.$render();
            }
            return transformedInput;
        });
    },
});
