import AchPaymentsServices from '@treasury/domain/channel/services/ach/ach-payments-services.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

export default class AchPaymentActivityClient {
    async fetchAchPaymentActivity(...args) {
        return AchPaymentsServices.fetchAchPaymentActivity(...args);
    }

    async getAchCompanies() {
        return AchPaymentsServices.getAchCompanies();
    }

    async getSecCodes() {
        return AchPaymentsServices.getSecCodes();
    }

    async getEntitlements() {
        return EntitlementsService.instance.getEntitlements();
    }

    async fetchApprovers(...args) {
        return UsersService.fetchApprovers(...args);
    }

    get downloadAchPaymentActivity() {
        return AchPaymentsServices.downloadAchPayments;
    }

    async hasFeatureFlag(flag) {
        const ffService = await FeatureFlagService.getInstance();
        return ffService.isEnabled(flag);
    }

    async generatePaymentDetailLinkData({ values }, id, useLitVersion) {
        const data = {};
        if (AchPaymentsServices.isTaxPayment(values)) {
            data.path = `payables.ach.taxPayment-detail`;
            data.params = { id };
        } else if (AchPaymentsServices.isChildSupportPayment(values)) {
            data.path = `payables.ach.child-support-details`;
            data.params = {
                id,
                type: 'payment',
            };
        } else if (AchPaymentsServices.isInternationalAchPayment(values)) {
            data.path = `payables.ach.international-ach-payment-details`;
            data.params = { id };
        } else if (useLitVersion) {
            data.path = 'payables.ach.payments.view-dark';
            data.params = {
                id,
            };
        } else {
            data.path = `payables.ach.payment-detail`;
            data.params = {
                id,
                type: 'view',
                list: 'payment-list',
            };
        }
        return data;
    }

    async submitPaymentStatusUpdate(...args) {
        const [action, paymentArray, statusChangeComments] = args;
        if (action === 'cancel' || action === 'uninitiate') {
            return AchPaymentsServices.updateAchPaymentStatus(
                action,
                paymentArray[0],
                statusChangeComments
            );
        }
        return AchPaymentsServices.updateBatchAchPaymentStatuses(
            action,
            paymentArray,
            statusChangeComments
        );
    }
}
