import { AlarmClock } from '@treasury/alarm-clock';
import { dateIsTomorrow } from './date-is-tomorrow.js';

function today() {
    const d = new Date();
    d.setHours(0, 0, 0, 0);

    return d;
}

function isDateInvalid(isDateAHoliday) {
    const fiClock = AlarmClock.getInstance();
    return dateModel => {
        const checkDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);

        const isHoliday = isDateAHoliday(checkDate);
        const isTodayOrPrevDate = checkDate <= today();
        const isWeekend = dateModel.isWeekend();
        const isTomorrowAndPastCutoff = dateIsTomorrow(checkDate) && fiClock.isAfter('achCutoff');

        return isHoliday || isTodayOrPrevDate || isTomorrowAndPastCutoff || isWeekend;
    };
}

const internationalAchEffectiveDateDisableFunctions = isDateAHoliday => ({
    isDisabledStartDate: (/* dateModel */) => true,
    isDisabledEndDate: (/* dateModel */) => true,
    // still need to add number of days in advance settings
    // this is not yet using FI time. Im aware of that, and intend on adding
    isStartDateInvalid: isDateInvalid(isDateAHoliday),
    isEndDateInvalid: isDateInvalid(isDateAHoliday),
});

export default internationalAchEffectiveDateDisableFunctions;
