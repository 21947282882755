/* eslint-disable import/extensions */
import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters';
import { normalizeFrequencyType } from '../../../shared/utilities/normalize-frequency-type.js';

export const mapAchPaymentFilters = params => ({
    ...params,
    isBasic: true,
    batchName: params.batchName.trim(),
    transactionId: params.transactionId.trim(),
});

export const mapAchPaymentFiltersToRequest = (params, fileArchiveIds) => {
    const {
        achCompanyList,
        achType,
        status,
        batchName,
        transactionId,
        secCode,
        debitAmount,
        creditAmount,
        effectiveDate,
        initiatedDate,
    } = params;

    return {
        achCompanyList: achCompanyList === 'all' ? [] : achCompanyList,
        achType,
        status,
        batchName: batchName.trim(),
        transactionId: transactionId.trim(),
        secCode,
        ...(creditAmount[0] === 'range' && {
            creditAmountType: 'Range',
            creditAmountMin: AmountRangeFormatters.getRangeStart(creditAmount),
            creditAmountMax: AmountRangeFormatters.getRangeEnd(creditAmount),
        }),
        ...(creditAmount[0] === 'specific' &&
            creditAmount[1] !== null && {
                creditAmountType: 'Specific Amount',
                creditAmount: AmountRangeFormatters.getSpecificAmount(creditAmount),
            }),
        ...(debitAmount[0] === 'range' && {
            debitAmountType: 'Range',
            debitAmountMin: AmountRangeFormatters.getRangeStart(debitAmount),
            debitAmountMax: AmountRangeFormatters.getRangeEnd(debitAmount),
        }),
        ...(debitAmount[0] === 'specific' &&
            debitAmount[1] !== null && {
                debitAmountType: 'Specific Amount',
                debitAmount: AmountRangeFormatters.getSpecificAmount(debitAmount),
            }),
        ...(effectiveDate.isRange && {
            effectiveDateType: 'Range',
            effectiveDateStart: DateFormatters.parseDate(params.effectiveDate)?.value?.start,
            effectiveDateEnd: DateFormatters.parseDate(params.effectiveDate)?.value?.end,
        }),
        ...(!effectiveDate.isRange && {
            effectiveDateType: 'Specific Date',
            effectiveDate: DateFormatters.parseDate(params.effectiveDate)?.value,
        }),
        ...(initiatedDate.isRange && {
            initiatedDateType: 'Range',
            initiatedDateStart: DateFormatters.parseDate(params.initiatedDate)?.value?.start,
            initiatedDateEnd: DateFormatters.parseDate(params.initiatedDate)?.value?.end,
        }),
        ...(!initiatedDate.isRange && {
            initiatedDateType: 'Specific Date',
            initiatedDate: DateFormatters.parseDate(params.initiatedDate)?.value,
        }),
        isBasic: true,
        ...(fileArchiveIds?.length && {
            fileArchiveIds,
            effectiveDateType: 'Specific Date',
            effectiveDate: '',
            effectiveDateStart: '',
            effectiveDateEnd: '',
        }),
    };
};

export const mapResponseToAchPaymentFields = response =>
    response.map(payment => ({
        ...payment,
        effectiveDate: payment.frequency.effectiveDate,
        initiatedDate: payment.frequency.initiatedDate,
        parentFrequencyType: normalizeFrequencyType(payment.parentFrequencyType),
    }));

const capitalizeAction = action => action[0].toUpperCase() + action.substring(1).toLowerCase();

const generateCommentsForUpdate = c => (c && c.trim() !== '' ? c : null);

export const mapUpdatePaymentParamsToRequest = (...args) => {
    const [action, id, commentsForUpdate] = args;
    const comments = generateCommentsForUpdate(commentsForUpdate);
    if (action === 'cancel' || action === 'uninitiate') {
        const endpoint = `achPayments/${action}`;
        const achPaymentId = id;
        const actionType = `Delete ACH Payment`;
        return [action, null, achPaymentId, comments, actionType, 'PUT', endpoint];
    }
    if (action === 'reject') {
        const actionType = `${capitalizeAction(action)} ACH Payment`;
        return [action, id, null, comments, actionType];
    }
    return [action, id, null, comments];
};

export const mapUpdateBatchPaymentParamsToRequest = (...args) => {
    const [action, batchIds, commentsForUpdate] = args;
    const comments = generateCommentsForUpdate(commentsForUpdate);
    const lookups = batchIds.map(id => ({
        key: id,
        ...(comments !== null && { value: comments }),
    }));
    const actionType = `${capitalizeAction(action)} ACH Payment`;
    const capitalizedAction = capitalizeAction(action);
    return [capitalizedAction, lookups, actionType];
};
