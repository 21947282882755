import { WireTemplateModelDto } from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';
import { WireTemplate, WireTemplateQueryDto } from '.';

export const getWirePaymentDetails = async (id: string) =>
    (await TmHttpClient.getInstance()).request(`wires/${id}`, {
        method: 'GET',
    });

export const getWireCompanies = async (nonDli: boolean) =>
    (await TmHttpClient.getInstance()).request(`wireCompanies/?nonDli=${!!nonDli}`, {
        method: 'GET',
    });

export const getWireDebitAccounts = async () =>
    (await TmHttpClient.getInstance()).request(
        `companyaccounts/GetWithProductFeature?type=&productfeaturetype=WireTransfer`,
        { method: 'GET' }
    );

export const getWireConfiguration = async () =>
    (await TmHttpClient.getInstance()).request(`wireCompanies/wireConfiguration`, {
        method: 'GET',
    });

export const searchTemplates = async (params: Partial<WireTemplateQueryDto>) =>
    (await TmHttpClient.getInstance()).request<WireTemplateModelDto[] | null>(
        `wires/wireTemplateSearch`,
        {
            method: 'POST',
            body: params,
        }
    );

export const fetchWireTemplateApprovers = async (template: WireTemplate) => {
    const body = {
        productId: template.id,
        createdBy: template.createdBy,
        approvalEntity: 'wireTemplate',
    };
    const http = await TmHttpClient.getInstance();
    return http.request(`users/getApprovers`, {
        method: 'POST',
        body,
    });
};

export const fetchTransferAccounts = async () =>
    (await TmHttpClient.getInstance()).request(
        `companyaccounts/GetWithProductFeature?type=&productfeaturetype=WireTransfer`,
        {
            method: 'GET',
            maxAgeInSeconds: 600,
        }
    );
