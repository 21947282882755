import AchExceptionsServices from '@treasury/domain/channel/services/ach/ach-exceptions-services';
import DownloadService from '@treasury/domain/channel/services/download/download-service.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';

export default class AchExceptionsClient {
    async downloadAchExceptions(...args) {
        const [confirmationId, outputType, pageId, filterModel, downloadFormats] = args;
        return DownloadService.download(
            confirmationId,
            outputType,
            pageId,
            filterModel,
            downloadFormats
        );
    }

    async findAchExceptions(...args) {
        return AchExceptionsServices.findAchExceptions(...args);
    }

    async findAllAchExceptions(...args) {
        return AchExceptionsServices.findAllAchExceptions(...args);
    }

    async updateUserPrintActivity(...args) {
        return UserActivityService.print(...args);
    }
}
