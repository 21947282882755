export interface NavigationItem<P = Record<string, any>> {
    route: string;
    url: string;
    params?: P;
}

type RequiredNavItem<P> = Required<NavigationItem<P>>;
export class HistoryEntry<P = Record<string, any>> implements RequiredNavItem<P> {
    constructor(
        navItem: Readonly<NavigationItem<P>>,
        public readonly event?: Event
    ) {
        const { route, url, params } = navItem;
        this.route = route;
        this.url = url;
        this.params = params || ({} as P);
    }

    public readonly route: RequiredNavItem<P>['route'];

    public readonly url: RequiredNavItem<P>['url'];

    public readonly params: RequiredNavItem<P>['params'];

    public readonly timestamp = new Date();

    /**
     * Compares two history items for equality.
     * @returns `true` if `other` is equivalent to this history item.
     */
    public compare(other: HistoryEntry<P>) {
        return this.route === other.route && this.url === other.url;
    }
}
