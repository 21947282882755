// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant(
    'achExceptionListTypes',
    Object.freeze({
        openItems: 0,
        history: 1,
    })
);
