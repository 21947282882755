import andFilter from './filters/and-filter.js';
import orFilter from './filters/or-filter.js';
import containsFilter from './filters/contains-filter.js';
import containsAnyFilter from './filters/contains-any-filter.js';
import invalidFilter from './filters/invalid-filter.js';
import equalsFilter from './filters/equals-filter.js';
import betweenFilter from './filters/between-filter.js';
import inDateRange from './filters/in-date-range.js';

const FILTERS = {
    and: andFilter,
    or: orFilter,
    contains: containsFilter,
    containsAny: containsAnyFilter,
    invalid: invalidFilter,
    equals: equalsFilter,
    between: betweenFilter,
    inDateRange,
};

export default function buildFilter(tree) {
    if (!Array.isArray(tree) || tree.length === 0) {
        return () => true;
    }

    const [functionName, ...args] = tree;

    if (!FILTERS[functionName]) {
        console.warn(`unknown filter function: "${functionName}"`);
        return () => true;
    }

    if (functionName === 'and' || functionName === 'or') {
        return FILTERS[functionName](args.map(buildFilter));
    }
    return FILTERS[functionName](...args);
}
