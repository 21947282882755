import { svg } from 'lit';

export const moreIcon = svg`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
  <defs>
    <path id="more-a" d="M9,13 C7.9,13 7,13.9 7,15 C7,16.1 7.9,17 9,17 C10.1,17 11,16.1 11,15 C11,13.9 10.1,13 9,13 L9,13 Z M21,13 C19.9,13 19,13.9 19,15 C19,16.1 19.9,17 21,17 C22.1,17 23,16.1 23,15 C23,13.9 22.1,13 21,13 L21,13 Z M15,13 C13.9,13 13,13.9 13,15 C13,16.1 13.9,17 15,17 C16.1,17 17,16.1 17,15 C17,13.9 16.1,13 15,13 L15,13 Z"/>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="more-b" fill="#fff">
      <use xlink:href="#more-a"/>
    </mask>
    <g fill="#8B8D92" mask="url(#more-b)">
      <rect width="26" height="26" transform="translate(2 2)"/>
    </g>
  </g>
</svg>`;
