/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AccountModelCollectionDto,
    ErrorHttpContentDto,
    PositivePayAccountSearchModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class PositivePayAccountClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * @description Request body parameters: Parameter Description Array of company ids to retrieve accounts for boolean to determine whether to filter to only those accounts that have the Ach Exception product option boolean to determine whether to filter to only those accounts that have the Ach Filter product option boolean to determine whether to filter to only those accounts that have the Check Exception product option Note: HasAchException, HasAchFilter, and HasCheckException are logically AND joined. For example if HasAchException is true and HasAchFilter is true only accounts with both product options will be returned.
     *
     * @tags PositivePayAccounts
     * @name PositivePayAccountsAccountSearch
     * @summary Searches for accounts that match the criteria in the request.
     * @request POST:/PositivePayAccount/Search
     */
    positivePayAccountsAccountSearch = (
        accountSearchModel: PositivePayAccountSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AccountModelCollectionDto, ErrorHttpContentDto>({
            path: `/PositivePayAccount/Search`,
            method: 'POST',
            body: accountSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
