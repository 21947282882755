import { CheckExceptionsServices } from '@treasury/domain/channel/services/positive-pay';
import openDialog from '@treasury/omega/open-dialog';
import { html, nothing } from 'lit';
import './return-reason-comment-dialog';

function renderReturnReasonCommentButton(record: any) {
    if (!record.getField('comment')) return nothing;
    const comment = record.getField('comment');
    return html`<omega-button
        type="icon"
        icon="comment"
        hidelabel="always"
        @click=${() =>
            openDialog(document.createElement('return-reason-comment-dialog'), { comment })}
    ></omega-button>`;
}

function renderReturnReasonAttachmentButton(record: any) {
    if (!record.getField('attachment')) return nothing;
    return html`<omega-button
        type="icon"
        icon="paperclip"
        hidelabel="always"
        @click=${async () =>
            CheckExceptionsServices.getCheckExceptionAttachment(
                record.getField('arpExceptionDetailUniqueId')
            )}
    ></omega-button>`;
}

export function returnReasonCommentAndAttachment(record: any) {
    if (
        record.getField('decisionStatus') === 'Pay' ||
        (!record.getField('comment') && !record.getField('attachment'))
    )
        return nothing;

    return html`<div class="returnReasonDetails">
            ${renderReturnReasonCommentButton(record)} ${renderReturnReasonAttachmentButton(record)}
        </div>
        <style>
            .returnReasonDescription {
                margin-right: 16px;
            }
            .returnReasonDetails {
                display: inline-block;
            }
            .returnReasonDetails omega-button {
                margin-right: 0px;
                margin-left: 0px;
            }
        </style>`;
}
