import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import FieldTypes from '@treasury/policy/primitives';
import { renderAuditText } from '../templates';

const ReadOnlyStringField = FieldTypes.string.thatIs.readOnly() as FieldType<string>;

// eslint-disable-next-line @treasury/no-date
const ReadonlyDateField = FieldTypes.date.thatIs.readOnly() as FieldType<Date>;

const AccountNameField = ReadOnlyStringField.with.minColumnWidth(400) as FieldType<string>;

const AuditItemsField = new FieldType<IIssuedItem['auditItems']>().with
    .sortable(false)
    .and.template(audits => renderAuditText(audits));

const CheckAmountField = FieldTypes.currency.thatIs
    .readOnly()
    .with.reducer('sum') as FieldType<number>;

export const fields: FdlFieldDefinitions<IIssuedItem> = {
    createdDate: ReadonlyDateField as FieldType<IIssuedItem['createdDate']>,
    dateIssued: ReadonlyDateField as FieldType<IIssuedItem['dateIssued']>,
    voidedDate: ReadonlyDateField as FieldType<IIssuedItem['voidedDate']>,
    checkAmount: CheckAmountField,
    accountName: AccountNameField,
    auditItems: AuditItemsField,
    payee: ReadOnlyStringField,
    checkNumber: ReadOnlyStringField as FieldType<IIssuedItem['checkNumber']>,
    itemStatus: ReadOnlyStringField as FieldType<IIssuedItem['itemStatus']>,
    type: ReadOnlyStringField as FieldType<IIssuedItem['type']>,
    itemEntryType: ReadOnlyStringField as FieldType<IIssuedItem['itemEntryType']>,
};

export const columns: readonly OmegaColumnDefinition<IIssuedItem>[] = Object.freeze([
    {
        field: 'itemEntryType',
        label: 'Item Entry Type',
    },
    {
        field: 'type',
        label: 'Item Type',
    },
    {
        field: 'itemStatus',
        label: 'Item Status',
    },
    {
        field: 'dateIssued',
        label: 'Issued Date',
    },
    {
        field: 'createdDate',
        label: 'Created Date',
    },
    {
        field: 'voidedDate',
        label: 'Void Date',
    },
    {
        field: 'accountName',
        label: 'Account',
    },
    {
        field: 'checkAmount',
        label: 'Amount',
        summary: true,
    },
    {
        field: 'checkNumber',
        label: 'Check Number',
    },
    {
        field: 'payee',
        label: 'Payee',
    },
    {
        field: 'auditItems',
        type: OmegaColumnType.Tooltip,
        label: '',
        icon: 'list-alt',
    },
]);
