/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { LookupModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class StatesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags States
     * @name StatesGet
     * @summary Gets all States.
     * @request GET:/states
     */
    statesGet = (params: RequestParams = {}) =>
        this.http.request<LookupModelDto[], any>({
            path: `/states`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
