/* eslint-disable sonarjs/no-nested-template-literals */
import { FiDate } from '@treasury/domain/dates';
import isValidDate from '@treasury/policy/lib/utils/frequency/is-valid-date.ts';
import { css, html, LitElement, nothing } from 'lit';
import dateTimeFormatted from '../../../formatters/dateTime.formatter.js';
import '../../omega-datepicker.js';
import '../../omega-input.js';
import { formStyles } from '../form-styles.js';

export class OneTime extends LitElement {
    static get properties() {
        return {
            frequency: Object,
            readOnly: { type: Boolean, attribute: 'readonly' },
            isStartDateInvalid: Function,
            disabled: { type: Boolean, attribute: 'disabled' },
        };
    }

    constructor() {
        super();
        this.valid = true;
        this.parseRange = true;
    }

    get isValidOneTime() {
        return isValidDate(this.frequency.valueDate || this.frequency.effectiveDate);
    }

    effectiveDateChange(e) {
        const date = e.detail.value?.dates[0] ?? this.frequency.valueDate;
        if (date.toString() === this.frequency?.valueDate?.toString()) return;
        this.frequency = { ...this.frequency, valueDate: date };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    renderRequiredIndicator() {
        if (!this.readOnly) {
            return html`<span class="required"> *</span>`;
        }
        return nothing;
    }

    render() {
        const dateToParse = this.frequency.valueDate ?? this.frequency.effectiveDate;
        const parseDate = date =>
            date ? new FiDate(new Date(dateTimeFormatted(date))) : '(no date selected)';
        return html`
            <div class="row">
                <label class="label">Effective Date ${this.renderRequiredIndicator()}</label>
                ${this.readOnly
                    ? html`<div class="control">${parseDate(dateToParse)}</div>`
                    : html`
                          <omega-datepicker
                              ?disabled=${this.disabled}
                              class="control"
                              .value=${this.frequency.valueDate}
                              .valid=${this.isValidOneTime}
                              .parseDynamicRange=${this.parseRange}
                              .dateDisabledFunction=${this.isStartDateInvalid}
                              @change=${this.effectiveDateChange}
                          ></omega-datepicker>
                      `}
            </div>
        `;
    }

    static get styles() {
        return [
            formStyles,
            css`
                :host {
                    display: block;
                }
            `,
        ];
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define('omega-frequency-one-time', OneTime);
// eslint-disable-next-line @treasury/filename-match-export
export default OneTime;
