/* eslint-disable no-use-before-define */
import { ArpAccountsService, ArpService } from '@treasury/domain/channel/services';
import { AccountDto, PositivePayFormatDto } from '@treasury/domain/channel/types/arp';
import { FieldType, Record } from '@treasury/FDL';
import { Schema as OmegaFormSchema } from '@treasury/omega/layouts/omega-form';
import { exists } from '@treasury/utils';
import { createSearchableAccountField } from './shared.data';

export interface UploadFormData {
    account?: AccountDto;
    savedFormat: PositivePayFormatDto;
}

export type UploadFormChangeEvent = CustomEvent<Record<UploadFormData>>;

function createSelectableFormatField(arpService: ArpService) {
    return new FieldType<PositivePayFormatDto>().with
        .placeholder('Select a Saved Format')
        .and.label('Saved Format')
        .and.options({
            fetch: () => arpService.getSavedFormats(),
            text: dto => dto.name ?? '',
            value: dto => dto,
        })
        .and.tag('omega-select')
        .and.required();
}

export function createUploadFormSchema(
    accountsService: ArpAccountsService,
    arpService: ArpService
): OmegaFormSchema<UploadFormData> {
    const accountIconMessage =
        'If the selected Upload Format includes an account number, selection of an account is not required.';
    const formatFieldDefinition = createSelectableFormatField(arpService);
    const accountFieldDefinition = createSearchableAccountField(accountsService)
        .with.label('Account')
        .and.requiredWhen((r: Record<UploadFormData>) => {
            const format = r.getField('savedFormat');
            if (!exists(format)) {
                return false;
            }

            return !isMultiAccountFormat(format);
        })
        .and.disabledWhen((r: Record<UploadFormData>) => {
            const format = r.getField('savedFormat');
            if (!exists(format)) {
                return true;
            }

            return isMultiAccountFormat(format);
        })
        .and.tag('omega-select')
        .and.iconMessage(accountIconMessage);

    return {
        values: {
            account: undefined,
            savedFormat: undefined,
        },
        fields: {
            savedFormat: formatFieldDefinition,
            account: accountFieldDefinition as FieldType<AccountDto | undefined>,
        },
    };
}

function isMultiAccountFormat(format: PositivePayFormatDto) {
    return isFixedMultiAccountFormat(format) || isDelimitedMultiAccountFormat(format);
}

function isFixedMultiAccountFormat(format: PositivePayFormatDto) {
    return (
        format.type === 'FixedPosition' &&
        exists(format.accountNumberBegin) &&
        format.accountNumberBegin > 0 &&
        exists(format.accountNumberEnd) &&
        format.accountNumberEnd > 0
    );
}

function isDelimitedMultiAccountFormat(format: PositivePayFormatDto) {
    return (
        format.type === 'Delimited' &&
        exists(format.accountNumberOrder) &&
        format.accountNumberOrder > 0
    );
}
