InformationCenterWidgetController.$inject = [
    '$scope',
    'dashboardService',
    'spinnerService',
    'dashboardUserInteractions',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function InformationCenterWidgetController(
    $scope,
    dashboardService,
    spinnerService,
    dashboardUserInteractions
) {
    init();

    function init() {
        spinnerService.configureGroup(
            'informationCenterWidget',
            ['/dashboard/informationcenterdetail'],
            ['informationCenterWidget.html']
        );

        updateSpinner(spinnerService.startGroupRequest('informationCenterWidget'));
        dashboardService.getInformationCenter().then(response => {
            updateSpinner(spinnerService.stopGroupRequest('informationCenterWidget'));
            angular.forEach(response, item => {
                item.isCollapsed = true;
            });
            const filteredItems = response.filter(
                item => item.type !== 'Banner' && item.type !== 'None'
            );
            $scope.informationCenterData = filteredItems;
        });
    }

    $scope.toggleItem = function (informationItem) {
        informationItem.isCollapsed = !informationItem.isCollapsed;
        if (!informationItem.isCollapsed) {
            dashboardService.auditUserIteration(
                dashboardUserInteractions.viewedInformationCenterMessage
            );
        }
    };

    function updateSpinner(numPendingRequests) {
        $scope.widget.isLoading = numPendingRequests > 0;
    }
}
