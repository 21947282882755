/* eslint-disable @treasury/no-date */
import { Nullable } from '@treasury/utils/types';
import { AccountType } from './accounts.dto';
import { IssuedItemEntryType, IssuedItemStatus, IssuedItemType } from './issued-items-legacy.dto';

interface IssuedItemDtoBase {
    accountId: number;
    issuedAmount: number;
    checkNumber: string;
    checkDate: string;
    issuedItemCreatedDate: string;
    checkVoidDate: string | null;
    entryType: IssuedItemEntryType;
    itemType: IssuedItemType;
    payeeName: string;
}

/**
 * DTO of issued items coming back endpoints prefixed `/issuedItem`.
 *
 * This is the updated shape going forward and eventually should supplant use of
 * other legacy issued item shapes, e.g., `IssuedItemLegacyDto`.
 */
export interface IssuedItemDto extends IssuedItemDtoBase {
    acctReconItemDetailId: number;
    id: number;
    accountNumber: string;
    accountType: AccountType;
    companyId: number;
    companyName: string;
    createdBy: string;
    createdByAlias: string;
    effectiveDate: string;
    updatedBy: string;
    updatedDate: string;
    issuedStatus: IssuedItemStatus;
    batchNumber: string;
    sequenceNumber: string;
    issuedItemFileId: number;
    bankNumber: string;
    dataView: string;
    institutionId: number;
    loadDate: string;
    loadSequence: string;
    deleteFlag: false;
    deleted: false;
    sourceKey: string;
    foreignKey: string;
    transactionType: 'Deposit' | 'Withdrawal';
    payeeAddress1: string;
    payeeAddress2: string;
    payeeAddress3: string;
    payeeAddress4: string;
    payeeAddress5: string;
    accountReconViolationRemark: string;
    transactionAmount: number;
    staleDate: string;
    postDate: string;
    dormantDate: string;
    transactionCodeCode: string;
    transactionCodeDescription: string;
    stopCheckPlaceDate: string;
    stopCheckExpirationDate: string;
    accountReconViolationType: string;
    exceptionResolveType: string;
    systemDate: string;
    returnTransactionCount: number;
    itemReturnDate: string;
    accountReconAdjustedType: string;
    accountReconPurgeCode: string;
    accountReconPurgeDescription: string;
    accountReconPurgeDate: string;
    accountReconItemStatus: string;
    transactionItemType: string;
    lastMainDate: string;
    sourceFundsCode: string;
    sourceFundsDescription: string;
    staleExpirationDate: string;
    postExpirationDate: string;
    dormantExpirationDate: string;
    checkVoidExpirationDate: string;
    transactionBatchNumber: string;
    transactionSequenceNumber: string;
    sourceCode: string;
    itemResolveType: string;
    deleteDate: string;
    previousStopCheckPlaceDate: string;
    previousStopCheckExpirationDate: string;
    stopCheckPurgeDate: string;
    custom: string;
    accountReconItemDetailUniqueId: string;
    accountReconItemAudits: {
        accountReconItemAuditId: number;
        institutionId: number;
        accountReconItemDetailUniqueId: string;
        accountReconItemActionType: string;
        createdBy: string;
        createdByAlias: string;
        createdDate: string;
    }[];
}

interface IssuedItemSaveDtoBase extends IssuedItemDtoBase {
    /**
     * Whether the item will modify an existing item in Silverlake when saved.
     */
    isModify: boolean;
}

interface IssuedItemCreateDto extends IssuedItemSaveDtoBase {
    itemType: 'Check';
    isModify: false;
    checkVoidDate: null;
}

/**
 * The shape required creating a brand new void for an item that does not yet exist in Silverlake.
 */
interface IssuedItemNewVoidDto extends IssuedItemSaveDtoBase {
    itemType: 'Void';
    isModify: false;
}

/**
 * The shape required specifically when voiding an existing issued item.
 */
interface IssuedItemExistingVoidDto extends IssuedItemSaveDtoBase {
    itemType: 'Void';
    isModify: true;
    batchNumber: string;
    sequenceNumber: string;
}

/**
 * The shape used to create a new check or void an existing one.
 */
export type IssuedItemSaveDto =
    | IssuedItemCreateDto
    | IssuedItemNewVoidDto
    | IssuedItemExistingVoidDto;

export const nullIssuedItem: Nullable<IssuedItemDto> = {
    id: null,
    acctReconItemDetailId: 0,
    accountId: null,
    accountNumber: null,
    accountType: null,
    companyId: 0,
    companyName: '',
    checkNumber: '',
    checkDate: null,
    issuedAmount: 0,
    payeeName: '',
    createdBy: '',
    createdByAlias: '',
    effectiveDate: new Date().toString(),
    issuedItemFileId: null,
    loadSequence: null,
    foreignKey: null,
    deleted: false,
    deleteFlag: false,
    updatedBy: '',
    updatedDate: new Date().toString(),
    loadDate: null,
    issuedStatus: null,
    itemType: null,
    entryType: 'Manual',
    batchNumber: null,
    sequenceNumber: null,
    bankNumber: '',
    dataView: '',
    institutionId: 0,
    checkVoidDate: new Date().toString(),
    transactionType: null,
    payeeAddress1: null,
    payeeAddress2: null,
    payeeAddress3: null,
    payeeAddress4: null,
    payeeAddress5: null,
    accountReconViolationRemark: '',
    issuedItemCreatedDate: new Date().toString(),
    transactionAmount: 0,
    staleDate: new Date().toString(),
    postDate: new Date().toString(),
    dormantDate: new Date().toString(),
    transactionCodeCode: '',
    transactionCodeDescription: '',
    stopCheckPlaceDate: new Date().toString(),
    stopCheckExpirationDate: new Date().toString(),
    accountReconViolationType: '',
    exceptionResolveType: '',
    systemDate: new Date().toString(),
    returnTransactionCount: 0,
    itemReturnDate: new Date().toString(),
    accountReconAdjustedType: '',
    accountReconPurgeCode: '',
    accountReconPurgeDescription: '',
    accountReconPurgeDate: new Date().toString(),
    accountReconItemStatus: '',
    transactionItemType: '',
    lastMainDate: new Date().toString(),
    sourceFundsCode: '',
    sourceFundsDescription: '',
    staleExpirationDate: new Date().toString(),
    postExpirationDate: new Date().toString(),
    dormantExpirationDate: new Date().toString(),
    checkVoidExpirationDate: new Date().toString(),
    transactionBatchNumber: '',
    transactionSequenceNumber: '',
    sourceCode: '',
    itemResolveType: '',
    deleteDate: null,
    previousStopCheckPlaceDate: new Date().toString(),
    previousStopCheckExpirationDate: new Date().toString(),
    stopCheckPurgeDate: new Date().toString(),
    custom: '',
    accountReconItemDetailUniqueId: '',
    accountReconItemAudits: [],
    sourceKey: null,
};

export interface IssuedItemSavedError {
    issuedItemId: number;
    errorCode: string;
    errorMessage: string;
}
