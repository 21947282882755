import { css } from 'lit';

export const tableStyles = css`
    .padded {
        padding: 10px;
    }

    .row {
        display: flex;
        flex-direction: row;
    }

    .row > * {
        flex: auto;
    }

    .full-width {
        width: 100%;
    }

    .right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .dialog-body {
        padding: 20px;
    }
`;
