export interface AchFrequencyDefinition {
    id: string;
    name: string;
    value: string;
    key:
        | 'Weekly'
        | 'Quarterly'
        | 'Monthly'
        | 'EverySixMonths'
        | 'EveryTwoWeeks'
        | 'TwiceAMonth'
        | 'Yearly';
}

export const FrequencyTypes = {
    OneTime: 'One Time',
    Weekly: 'Weekly',
    EveryTwoWeeks: 'Every Two Weeks',
    TwiceAMonth: 'Twice a Month',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    EverySixMonths: 'Every Six Months',
    Yearly: 'Yearly',
};
