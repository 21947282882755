export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: { printId: '=', additionalPrintAuditInformation: '&?' },
            template: require('./printTemplate.html'),
            controller: PrintController,
        };
    },
];

PrintController.$inject = ['$scope', '$window', 'printService'];

function PrintController($scope, $window, printService) {
    $scope.print = function () {
        printService.userActivityAudit(
            $scope.printId,
            $scope.additionalPrintAuditInformation ? $scope.additionalPrintAuditInformation() : null
        );
        $window.print();
    };
}
