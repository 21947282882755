import { html, css, LitElement } from 'lit';

export default class OmegaContextMenuItem extends LitElement {
    static get properties() {
        return {};
    }

    handleClick() {
        this.dispatchEvent(new CustomEvent('itemClicked'));
    }

    render() {
        return html`<button role="menuitem" @click=${this.handleClick}><slot></slot></button>`;
    }

    static get styles() {
        return css`
            :host() {
                display: block;
            }

            button {
                cursor: pointer;
                font-family: var(--omega-font);
                color: var(--omega-text-default);
                width: 100%;
                text-align: left;
                padding: 8px 16px;
                background: none;
                border: none;
                display: block;
                font-size: 16px;
            }

            button:focus,
            button:hover {
                background: var(--omega-secondary-lighten-400);
            }
        `;
    }
}

window.customElements.define('omega-context-menu-item', OmegaContextMenuItem);
