// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

filtersModule.filter(
    'filterBanks',
    () =>
        function (banks, viewValue) {
            const out = [];
            if (viewValue) {
                angular.forEach(banks, bank => {
                    if (
                        (bank.bankId !== null &&
                            bank.bankId.toLowerCase().indexOf(viewValue.toLowerCase() || '') !==
                                -1) ||
                        (bank.name != null &&
                            bank.name.toLowerCase().indexOf(viewValue.toLowerCase() || '') !==
                                -1) ||
                        (bank.city !== null &&
                            bank.city.toLowerCase().indexOf(viewValue.toLowerCase() || '') !==
                                -1) ||
                        (bank.state !== null &&
                            bank.state.toLowerCase().indexOf(viewValue.toLowerCase() || '') !==
                                -1) ||
                        (bank.state !== null &&
                            bank.city !== null &&
                            `${bank.city.toLowerCase()}, ${bank.state.toLowerCase()}`.indexOf(
                                viewValue.toLowerCase()
                            ) !== -1)
                    ) {
                        out.push(bank);
                    }
                });
            }

            return out;
        }
);
