import isOffsetRequiredForAchCompany from './is-offset-required-for-ach-company.js';

/* 
    Prefunding can only possibly be enabled if the company is unbalanced.
*/
const isPrefundingEnabledForAchCompany = company => {
    if (!isOffsetRequiredForAchCompany(company)) return false;
    return (
        (company.prefundingDays > 0 && company.offsetAccountNumber !== null) ||
        company.offsetAccountNumber !== null
    );
};

export default isPrefundingEnabledForAchCompany;
