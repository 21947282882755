import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { SummaryTileVm } from '../types';
import './omega-summary-tile';

@customElement('omega-summary-tile-select-bar')
export class OmegaSummaryTileSelectBar extends LitElement {
    @property({ type: Array })
    tiles: SummaryTileVm[] = [];

    @property({ type: Object })
    selectedTile?: SummaryTileVm;

    getTileByIndex(index: number) {
        return this.tiles[index];
    }

    tileClickHandler(event: CustomEvent<SummaryTileVm>) {
        event.stopPropagation();
        if (this.selectedTile?.title === event.detail.title) {
            this.selectedTile = undefined;
        } else {
            this.selectedTile = event.detail;
        }
        this.dispatchEvent(
            new CustomEvent<SummaryTileVm>('tileClicked', { detail: this.selectedTile })
        );
    }

    renderTiles() {
        return this.tiles.map(
            tile => html`
                <omega-summary-tile
                    .summaryTile=${tile}
                    .isSelected=${tile.title === this.selectedTile?.title}
                    @tileClicked=${this.tileClickHandler}
                ></omega-summary-tile>
            `
        );
    }

    render() {
        return html`
            <div class="omega-summary-tile-select-bar" id=${this.id}>${this.renderTiles()}</div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            .omega-summary-tile-select-bar {
                overflow: hidden; // Prevent tiles from escaping the select bar.
                width: 100%;
                min-width: 400px; // One tile with padding is 186px wide.
                min-height: 87px; // 71px tile height plus 8px margin on top and bottom.
                padding: 4px;
                text-align: center;
                margin-bottom: 10px;
            }
        `;
    }
}
