AccountsController.$inject = [
    '$scope',
    '$modalInstance',
    'wireCompaniesService',
    'companyAccounts',
];

export default function AccountsController(
    $scope,
    $modalInstance,
    wireCompaniesService,
    companyAccounts
) {
    $scope.accountSearchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.companyAccounts = companyAccounts;
    $scope.selectAccount = selectAccount;
    $scope.filterAccounts = filterAccounts;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function selectAccount(account) {
        $modalInstance.close(account);
    }

    function filterAccounts(account) {
        return (
            (account.number !== null &&
                account.number !== undefined &&
                account.number.toString().indexOf($scope.accountSearchText) !== -1) ||
            (account.name !== null &&
                account.name !== undefined &&
                account.name
                    .toString()
                    .toLowerCase()
                    .indexOf($scope.accountSearchText.toString().toLowerCase()) !== -1) ||
            (account.balance !== null &&
                account.balance !== undefined &&
                account.balance.toString().indexOf($scope.accountSearchText) !== -1) ||
            (account.availableBalance !== null &&
                account.availableBalance !== undefined &&
                account.availableBalance.toString().indexOf($scope.accountSearchText) !== -1)
        );
    }
}
