import { LitElement, html, css, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { Recordset } from '@treasury/FDL';
import { string, boolean, number, date } from '@treasury/policy/primitives';
import '@treasury/omega/components/omega-selection-list.js';
import '@treasury/omega/components/omega-checkbox.js';
import '@treasury/omega/components/omega-checkbox-group.js';
import '@treasury/omega/components/progress/omega-progress.js';
import { ListeningElementMixin } from '@treasury/omega/components';
import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { refreshIcon } from '@treasury/omega/css/icons/refresh-icon.js';
import { loadingIcon } from '@treasury/omega/css/icons/loading-icon.js';
import { downloadIcon } from '@treasury/omega/css/icons/download-icon.js';
import { scheduleIcon } from '@treasury/omega/css/icons/schedule-icon.js';
import { warningIcon } from '@treasury/omega/css/icons/warning-icon.js';
import { trashcanIcon } from '@treasury/omega/css/icons/trash-can-icon.js';

let frequencyEnabled;

export default class AchAccountReconciliationReportLibrary extends ListeningElementMixin(
    LitElement
) {
    static get properties() {
        return {
            reportFiles: Array,
            recordset: Object,
            activeReport: Object,
            creators: Array,
            frequencyEnabled: Boolean,
            reportUrl: String,
            reportFileName: String,
            refreshingReports: Boolean,
            loadingFile: Boolean,
            selectedCount: Number,
            fileIdsToDeleteQueue: Array,
        };
    }

    constructor() {
        super();
        this.fileIdsToDeleteQueue = [];
        this.selectedCount = 0;
    }

    firstUpdated() {
        frequencyEnabled = this.frequencyEnabled;
    }

    // review updated usage
    updated() {
        if (this.reportFiles?.length && !this.recordset) {
            this.createRecordSet();
        }
    }

    onClickReport(evt) {
        const record = evt.detail?.record;
        if (record.getField('jobStatusType') !== 7) return;

        this.loadingFile = true;
        this.displayReport(record).finally(() => {
            this.loadingFile = false;
            this.requestUpdate();
        });
    }

    onCheckRecord(evt) {
        const record = evt.detail?.record;
        record.setField('selected', !record.getField('selected'));
        this.requestUpdate();
    }

    async displayReport(report) {
        try {
            this.clearActiveReport();
            this.activeReport = report;
            const fileResponse = await AchAccountReconciliationService.fetchReportFile(
                report.getField('id'),
                'PDF'
            );
            this.reportUrl = `data:application/pdf;base64,${fileResponse.fileContent}#toolbar=0`;
            this.reportFileName = fileResponse.fileName;
        } catch (error) {
            this.dispatchEvent(new CustomEvent('errorAlert', { detail: error }));
        }
    }

    async downloadReport(fileId, fileType) {
        const link = document.createElement('a');

        if (fileId === 1) {
            link.href = this.reportUrl;
            link.download = this.reportFileName;
        } else {
            const fileResponse = await AchAccountReconciliationService.fetchReportFile(
                this.activeReport.getField('id'),
                fileId
            );
            link.href = `data:text/${fileType.toLowerCase()};base64,${fileResponse.fileContent}`;
            link.download = fileResponse.fileName;
        }

        // Append link to the body
        this.shadowRoot.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        this.shadowRoot.removeChild(link);
    }

    async deleteFiles(fileIdsToDelete) {
        if (!fileIdsToDelete?.length) return;
        const singular = fileIdsToDelete.length === 1;
        const resultPromise = AchAccountReconciliationService.deleteReportFiles(fileIdsToDelete);
        // Remove items without waiting for API responses
        this.reportFiles = this.reportFiles.filter(item => !fileIdsToDelete.includes(item.id));

        if (this.activeReport && fileIdsToDelete.includes(this.activeReport.getField('id'))) {
            this.clearActiveReport();
        }

        this.updateRecordSet();
        // Indicate success when completed
        const result = await resultPromise;
        if (result.every(item => item.success)) {
            const successMessage = singular
                ? 'The selected report successfully deleted!'
                : 'The selected reports successfully deleted!';
            this.dispatchEvent(
                new CustomEvent('alert', {
                    detail: {
                        result: '',
                        message: successMessage,
                    },
                })
            );
        } else {
            this.dispatchEvent(
                new CustomEvent('errorAlert', {
                    detail: {
                        message: 'One or more reports might not have been deleted successfully!',
                    },
                })
            );
        }
        this.refreshReports();
    }

    deleteSelectedFiles() {
        // Queues the selected files for deletion
        this.fileIdsToDeleteQueue = this.recordset
            .recordsMatching('selected', true)
            .map(item => item.getField('id'));
    }

    deleteActiveFile() {
        // Queues the active file for deletion
        this.fileIdsToDeleteQueue = [this.activeReport?.getField('id')];
    }

    clearActiveReport() {
        this.reportUrl = null;
        this.reportFileName = null;
        this.activeReport = null;
    }

    createRecordSet() {
        this.recordset = new Recordset(
            {
                id: number,
                reportName: string,
                reportType: string,
                accountName: string,
                runDate: date,
                createdBy: string,
                selected: boolean.as.tag('omega-checkbox'),
                detail: string,
                jobStatusType: number,
            },
            this.reportFiles
        );
        this.listenTo(this.recordset, 'updated', () => {
            this.requestUpdate();
        });
    }

    updateRecordSet() {
        if (this.recordset) {
            this.recordset.setData(this.reportFiles);
        } else {
            this.createRecordSet();
        }
    }

    closeDeleteDialog() {
        this.fileIdsToDeleteQueue = [];
    }

    refreshReports() {
        this.clearActiveReport();
        this.refreshingReports = true;
        AchAccountReconciliationService.fetchReportFiles().then(response => {
            this.reportFiles = response;
            this.refreshingReports = false;
            this.updateRecordSet();
        });
    }

    get creators() {
        return this.reportFiles.reduce((names, item) => {
            if (item.createdBy && !names.includes(item.createdBy)) {
                names.push(item.createdBy);
            }
            return names;
        }, []);
    }

    get accountNames() {
        return this.reportFiles.reduce((accountNames, item) => {
            if (item.accountName && !accountNames.includes(item.accountName)) {
                accountNames.push(item.accountName);
            }
            return accountNames;
        }, []);
    }

    get filters() {
        const filtersArray = [];

        if (this.accountNames?.length) {
            filtersArray.push({
                field: 'accountName',
                label: 'Account',
                options: this.accountNames,
                values: this.accountNames,
            });
        }

        if (this.creators?.length) {
            filtersArray.push({
                field: 'createdBy',
                label: 'Created by',
                options: this.creators,
                values: this.creators,
            });
        }

        filtersArray.push({
            field: 'runDate',
            label: 'Run Date',
            schema: 'datepicker',
        });

        return filtersArray;
    }

    get availableFileOutputTypes() {
        if (!this.activeReport) return [];
        const report = this.reportFiles.find(item => item.id === this.activeReport.getField('id'));
        if (!report) return [];

        return report.availableFileOutputTypes?.values || [];
    }

    renderRecordTemplate(record) {
        function renderScheduleToolTip() {
            if (!frequencyEnabled || record.getField('schedule') === 'none') return nothing;

            return html`
                <style>
                    svg {
                        fill: var(--omega-primary);
                        height: 20px;
                        width: 20px;
                    }
                </style>
                <omega-tooltip light clickable @click=${evt => evt.stopPropagation()}>
                    ${scheduleIcon}
                    <div slot="content" class="schedule-summary">
                        <h3>Report Scheduled</h3>
                        <p>
                            ${record.print('scheduleSummary')}
                            <span>Next run: ${record.print('nextRunDate')}</span>
                        </p>
                    </div>
                </omega-tooltip>
            `;
        }

        function renderReportStatusText() {
            if ([5, 6].includes(record.getField('jobStatusType'))) {
                return html`<span class="loading">Retrieving Report... ${loadingIcon}</span>`;
            }
            return html`<span class="run-date">${record.print('runDate')}</span>`;
        }

        return html`
            <style>
                .record {
                    display: flex;
                    padding-right: 10px;
                    align-items: center;
                }
                .report-name {
                    font-size: 15px;
                    display: block;
                    color: var(--omega-primary);
                    cursor: pointer;
                }
                .disabled .report-name {
                    color: var(--omega-text-secondary);
                    cursor: not-allowed;
                }
                omega-button {
                    margin: 0 8px;
                }
                omega-button svg {
                    fill: var(--omega-primary);
                    height: 24px;
                    width: 24px;
                    display: none;
                }
                omega-button[checked='true'] svg {
                    display: block;
                }
                .info {
                    flex-grow: 2;
                }
                .info[is-selected='true'] {
                    background-color: var(--omega-primary--focus, #ddebfb);
                }
                .title {
                    display: flex;
                    justify-content: space-between;
                }
                .title omega-tooltip {
                    display: inline-block;
                    vertical-align: middle;
                }
                .sub-title span {
                    display: inline-block;
                    vertical-align: middle;
                    color: var(--omega-text-secondary);
                }
                .account {
                    max-width: 50%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .run-date {
                    color: var(--omega-text-secondary);
                }
                .error svg {
                    width: 18px;
                    height: 18px;
                    position: relative;
                    top: 4px;
                    margin-right: 12px;
                }
                .error,
                .loading {
                    font-weight: 500;
                    font-size: 12px;
                }
                .error {
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                    left: -30px;
                    margin-top: 12px;
                }
                .icon {
                    position: absolute;
                    left: -25px;
                    background: #fff;
                    padding: 5px;
                    top: 8px;
                }
                span.loading svg {
                    vertical-align: bottom;
                }
            </style>
            <div
                class=${classMap({
                    record,
                    disabled: record.getField('jobStatusType') !== 7,
                })}
            >
                <div class="info">
                    <div class="title">
                        <span class="report-name">
                            ${record.print('reportType')}
                            ${record.getField('reportName')
                                ? `- ${record.print('reportName')}`
                                : nothing}
                            ${renderScheduleToolTip()}
                        </span>
                        ${renderReportStatusText()}
                    </div>
                    <div class="sub-title">
                        ${record.getField('accountName')
                            ? html`<span class="account">${record.print('accountName')} | </span>`
                            : nothing}
                        <span class="created-by">${record.print('createdBy')}</span>
                    </div>
                    ${record.getField('detail')
                        ? html`<div class="error">
                              ${warningIcon}
                              <span
                                  >Runtime error<br />
                                  ${record.print('detail')}</span
                              >
                          </div>`
                        : nothing}
                </div>
            </div>
        `;
    }

    renderSelectList() {
        if (!this.recordset) return this.renderEmptyListView();

        if (this.refreshingReports) return this.renderLoadingReports();

        return html`<div class="list">
            <omega-selection-list
                .recordset=${this.recordset}
                .recordTemplate=${this.renderRecordTemplate}
                @recordSelected=${this.onClickReport}
                @recordsChecked=${() => {
                    this.selectedCount = this.recordset.countRecordsMatching('selected', true);
                }}
                checkField="selected"
                showCheckAll
                .sortFields=${[
                    { field: 'reportName', label: 'Report name' },
                    { field: 'runDate', label: 'Run Date', direction: 'descending' },
                    { field: 'createdBy', label: 'Creator' },
                ]}
                sortBy="id"
                sortDirection="descending"
                .filters=${this.filters}
            >
                <omega-button
                    type="icon"
                    hidelabel="always"
                    class="refresh-button"
                    slot="header-tools"
                    aria-label="Refresh reports"
                    @click=${() => {
                        this.refreshReports();
                    }}
                >
                    <div slot="icon">${refreshIcon}</div>
                </omega-button>
                <omega-button
                    slot="footer-tools"
                    type="secondary"
                    .disabled=${!this.selectedCount}
                    @click=${this.deleteSelectedFiles}
                    >Delete Selected</omega-button
                >
            </omega-selection-list>
        </div>`;
    }

    renderEmptyListView() {
        return html`
            <div class="no-content">
                <p>No report files found</p>
                <omega-button
                    type="icon"
                    class="refresh-button"
                    slot="header-tools"
                    aria-label="Refresh reports"
                    @click=${() => {
                        this.refreshReports();
                    }}
                >
                    ${refreshIcon}
                </omega-button>
            </div>
        `;
    }

    renderLoadingReports() {
        return html`<div class="list"><omega-progress card></omega-progress></div>`;
    }

    renderFileViewer() {
        return html`<ach-account-reconciliation-report-viewer
            .activeReport=${this.activeReport}
            .reportUrl=${this.reportUrl}
            .reportFileName=${this.reportFileName}
            .loadingFile=${this.loadingFile}
            .availableFileOutputTypes=${this.availableFileOutputTypes}
            @downloadReport=${event => this.downloadReport(event.detail.id, event.detail.name)}
            @deleteActiveFile=${this.deleteActiveFile}
        ></ach-account-reconciliation-report-viewer>`;
    }

    renderDeleteDialog() {
        if (!this.fileIdsToDeleteQueue?.length) return nothing;
        const countFilesToDelete = this.fileIdsToDeleteQueue.length;
        const messageOption =
            countFilesToDelete < 2 ? `this report` : `these ${countFilesToDelete} reports`;

        return html`
            <omega-dialog open @close=${this.closeDeleteDialog} .dialogTitle=${'Delete Reports'}>
                <div slot="content" class="dialog-content">
                    Are you sure you want to delete ${messageOption}?
                </div>
                <div slot="actions">
                    <omega-button type="secondary" @click=${this.closeDeleteDialog}
                        >No</omega-button
                    >
                    <omega-button
                        type="primary"
                        @click=${() => {
                            this.deleteFiles([...this.fileIdsToDeleteQueue]);
                            this.closeDeleteDialog();
                        }}
                        >Yes</omega-button
                    >
                </div>
            </omega-dialog>
        `;
    }

    render() {
        return html`
            <div class="container">${this.renderSelectList()} ${this.renderFileViewer()}</div>
            ${this.renderDeleteDialog()}
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            div.container {
                display: flex;
                height: 100%;
            }
            div.dialog-content {
                padding: 30px;
                text-align: center;
            }
            div.no-content,
            div.list {
                max-width: 400px;
                background-color: white;
                border: 1px solid rgb(224, 224, 224);
                border-radius: 4px;
                margin: 0px 2.5px;
                height: 100%;
            }
            div.no-content {
                min-width: 25%;
                padding: 30px;
                box-sizing: border-box;
                text-align: center;
            }

            .download-options {
                display: block;
                --omega-button-padding: 0;
                --omega-button-text-align: left;
            }
            .download-button {
                color: var(--omega-primary);
            }
            omega-context-menu {
                display: flex;
                --omega-context-menu-right: 0;
                --omega-context-menu-top: 34px;
                --omega-context-menu-min-width: 120px;
            }
            omega-button.refresh-button {
                margin: 0;
                --omega-button-icon-padding: 0;
            }
            omega-button.refresh-button svg {
                fill: var(--omega-primary);
            }
            div.list {
                width: 400px;
                background-color: white;
                border: 1px solid rgb(224, 224, 224);
                border-radius: 4px;
                display: flex;
                flex-direction: column;
            }
            omega-selection-list {
                height: 1px;
                flex: 1 1 auto;
                --omega-checkbox-top: 22px;
            }
            .list-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px;
            }
            .list-footer omega-button {
                display: block;
                margin: 0;
                margin-top: -4px;
            }
            .selected-indicator svg {
                fill: var(--omega-primary);
                height: 14px;
                width: 14px;
            }
            .selected-indicator {
                color: var(--omega-primary);
            }
            .loading {
                display: flex;
                align-items: center;
                flex: 2;
                background: #fff;
            }
        `;
    }
}

window.customElements.define(
    'ach-account-reconciliation-report-library',
    AchAccountReconciliationReportLibrary
);
