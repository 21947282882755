import { setAddress } from '../../components/additionalLocationHelpers.js';

/**
 * @param { ReturnType<import('../../../../services').WireService> } wireService
 */
export default function CreateWireTemplateController(
    $scope,
    wireCompaniesService,
    $modal,
    modalService,
    entitlementsService,
    $state,
    wireService,
    beneficiariesService,
    securityService,
    $stateParams,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    regexConstants,
    wireIsoService
) {
    $scope.startingBeneficiaryId = $stateParams.beneficiaryId ? $stateParams.beneficiaryId : null;
    $scope.canCreateDomesticWireTemplate = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire Template'
    );
    $scope.canCreateInternationalWireTemplate = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire Template'
    );
    $scope.canCreateDomesticBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Beneficiary'
    );
    $scope.canCreateInternationalBeneficiary =
        entitlementsService.hasEntitlement('Wire, International, Create Beneficiary') ||
        entitlementsService.hasEntitlement('Wire Transfer DLI, Create Beneficiary');
    $scope.wizardController = {};
    $scope.addNotes = addNotes;
    $scope.deleteNotes = deleteNotes;
    $scope.setForm = setForm;
    $scope.setBankIdType = setBankIdType;
    $scope.findAccount = findAccount;
    $scope.findBeneficiaries = findBeneficiaries;
    $scope.isObject = isObject;
    $scope.selectWireCompany = selectWireCompany;
    $scope.checkEnability = checkEnability;
    $scope.beneficiaries = [];
    $scope.reset = reset;
    $scope.resetValues = resetValues;
    $scope.getPlaceHolderText = getPlaceHolderText;
    $scope.cancel = cancel;
    $scope.hasBeneficiaryPermission = hasBeneficiaryPermission;
    $scope.save = save;
    $scope.goToWireTemplateList = goToWireTemplateList;
    $scope.createAnotherWireTemplate = createAnotherWireTemplate;
    $scope.print = print;
    $scope.goBackToEdit = goBackToEdit;
    $scope.getBeneficiaries = getBeneficiaries;
    $scope.downloadPageId = downloadPageId.WireTemplateDetail;
    $scope.validateTemplateName = validateTemplateName;
    $scope.wireTemplateNameRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.isTemplateNameValid = isTemplateNameValid;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.beneficiaryReferenceRegex = regexConstants.BeneficiaryReferenceRegex;
    $scope.wireAdditionalInformationRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.showWireTypeSelectionOption = false;
    $scope.wireConfig = {};
    $scope.beneficiaryAddress = [];
    $scope.wireIsoLabels = wireIsoService.legacyWireIsoLabels;
    $scope.wireIsoFf = false;
    wireIsoService.getLabels().then(response => {
        $scope.wireIsoLabels = response;
    })
    wireIsoService.getEntitlementWireISO20022().then(ff => {
        $scope.wireIsoFf = ff;
    })

    function useThreeLineAddress(beneficiary) {
        return beneficiariesService.canUseThreeLineAddress(beneficiary);
    }

    function isTemplateNameValid() {
        return (
            !!$scope.wireTemplate &&
            !!$scope.wireTemplate.name &&
            !!$scope.form &&
            $scope.form.wireTemplateName.$valid
        );
    }

    init();

    function init() {
        $scope.wireTemplate = {
            wireCompany: null,
            isInternational: !$scope.canCreateDomesticWireTemplate,
            notes: [null],
        };
        $scope.pristine = angular.copy($scope.wireTemplate);
        getWireCompanies();
        getBeneficiaries();
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.requirePurpose = response.requirePurpose;
            $scope.wireConfig = response;
            setWireTypeSelectionVisibilityOption();
        });
    }

    function setWireTypeSelectionVisibilityOption() {
        if (
            $scope.canCreateDomesticWireTemplate &&
            $scope.canCreateInternationalWireTemplate &&
            $scope.wireConfig.fiWireProductEnabled &&
            $scope.wireConfig.fiInternationalUsdEnabled &&
            $scope.wireConfig.companyWireProductEnabled &&
            $scope.wireConfig.companyInternationalUsdEnabled
        ) {
            $scope.showWireTypeSelectionOption = true;
        }
    }

    function validateTemplateName() {
        $scope.form.wireTemplateName.$setValidity('unique', true);
        if ($scope.wireTemplate.name !== $scope.pristine.name) {
            wireService.validateTemplateName($scope.wireTemplate.name).then(resp => {
                if (resp.isValid) {
                    $scope.form.wireTemplateName.$setValidity('unique', true);
                } else {
                    $scope.form.wireTemplateName.$setValidity('unique', false);
                }
            });
        }
    }

    function checkEnability() {
        let result = true;
        if ($scope.form.$valid) {
            result = false;
        }

        return result;
    }

    function resetValues() {
        $scope.wireTemplate = angular.copy($scope.pristine);
        $scope.form.$setPristine();
    }

    function goToWireTemplateList() {
        $state.go('payables.wire.wire-template-list');
    }

    function selectWireCompany(obj) {
        $scope.wireTemplate.debitAccount = null;
        getAccounts(obj.id);
    }

    function setForm(form) {
        $scope.form = form;
    }

    function reset() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Reset Edits',
            headerText: 'Confirm Reset',
            bodyText: 'Are you sure you want to reset the changes you have made?',
            submit(result) {
                $modalInstance.close();
                resetValues();
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function hasBeneficiaryPermission() {
        return $scope.canCreateDomesticBeneficiary || $scope.canCreateInternationalBeneficiary;
    }

    function getPlaceHolderText(index) {
        return `Sender to Receiver Info. Line ${index + 1}`;
    }

    function setBankIdType(intermediaryBank, type) {
        if (intermediaryBank !== null) {
            const index = $scope.wireTemplate.intermediaryBanks.indexOf(intermediaryBank);
            $scope.wireTemplate.intermediaryBanks[index].bankIdType = type;
        } else {
            $scope.wireTemplate.bank.bankIdType = type;
        }
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function addNotes() {
        if ($scope.wireTemplate.notes.length === 4) {
            return;
        }
        $scope.wireTemplate.notes.push('');
    }

    function deleteNotes(index, information) {
        $scope.wireTemplate.notes.splice(index, 1);
    }

    function cancel() {
        if (!$scope.form.$dirty && checkEnability()) {
            if ($scope.isInternationalWire === true) {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateInternationalWire
                );
            } else {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateDomesticWire
                );
            }
            $state.go('payables.wire.wire-template-list');
        } else {
            confirmCancel();
        }
    }

    function save() {
        securityService
            .verifyUser('Create Wire Template', $scope.wireTemplate, () =>
                wireService.createWireTemplate($scope.wireTemplate)
            )
            .then(response => {
                if (response.errorSummary) {
                    $scope.errorMessage = '';
                    $scope.errorMessage = response.errorSummary.summaryMessage;
                    if (response.errorSummary.details) {
                        $scope.errorDetails = [];
                        angular.forEach(response.errorSummary.details, detail => {
                            $scope.errorDetails.push(detail);
                        });
                    }
                } else {
                    $scope.errorMessage = '';
                    $scope.errorDetails = [];
                    $scope.wireTemplate = angular.copy(response);
                    $scope.wizardController.goToNextStep();
                }
            });
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Cancel Edits',
            headerText: 'Confirm Cancellation',
            bodyText: 'Are you sure you want to cancel the changes you have made?',
            submit(result) {
                $modalInstance.close();
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateWire
                );
                $state.go('payables.wire.wire-template-list');
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function getBeneficiaries() {
        const filter = {
            status: 'Ready',
            beneficiaryType: $scope.wireTemplate.isInternational ? 'International' : 'Domestic',
            requireEntitlement: false,
        };
        $scope.wireTemplate.beneficiary = null;
        beneficiariesService.getFilteredBeneficiariesList(filter).then(response => {
            $scope.beneficiaries = response;
            filterBeneficiaries();
            $scope.beneficiaries = $scope.filteredBeneficiaries;
        });
    }

    function filterBeneficiaries() {
        $scope.filteredBeneficiaries = $scope.beneficiaries.filter(beneficiary => {
            if ($scope.wireTemplate.isInternational) {
                return beneficiary.isForeignCurrency === false;
            }
            return beneficiary.isInternational === false;
        });
    }

    function hasType() {
        return (
            ($scope.wire && $scope.wireTemplate.isInternational !== undefined) || $scope.template
        );
    }

    function getWireCompanies() {
        wireCompaniesService.getAll(true).then(response => {
            $scope.wireCompanies = response;
            if ($scope.wireCompanies.length === 1) {
                $scope.wireTemplate.wireCompany = $scope.wireCompanies[0];
            }
        });
    }

    function getAccounts(id, callback) {
        let create;
        if ($scope.wireTemplate.isInternational === true) {
            create = 'createintltemplate';
        } else {
            create = 'createdomestictemplate';
        }
        wireCompaniesService.getDebitAccounts(id, create).then(response => {
            if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
                $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }
            $scope.companyAccounts = response;
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    function findAccount(id) {
        getAccounts(id, () => {
            const modalInstance = $modal.open({
                template: require('../views/accountModalView.html'),
                size: 'md',
                controller: 'AccountsController',
                backdrop: 'static',
                resolve: {
                    wireId() {
                        return id;
                    },
                    companyAccounts() {
                        return $scope.companyAccounts;
                    },
                },
            });
            modalInstance.result.then(selectedAccount => {
                $scope.wireTemplate.debitAccount = angular.copy(selectedAccount);
            });
        });
    }

    $scope.isValidLookups = function () {
        let result = true;
        if (
            $scope.wireTemplate.debitAccount === null ||
            $scope.wireTemplate.debitAccount === undefined ||
            typeof $scope.wireTemplate.debitAccount !== 'object'
        ) {
            result = false;
        }
        if (
            $scope.wireTemplate.beneficiary === null ||
            $scope.wireTemplate.beneficiary === undefined ||
            typeof $scope.wireTemplate.beneficiary !== 'object'
        ) {
            result = false;
        }
        return result;
    };

    function print() {
        window.print();
    }

    function createAnotherWireTemplate() {
        $scope.wizardController.goToFirstStep();
        resetValues();
    }

    function findBeneficiaries(type) {
        const modalInstance = $modal.open({
            template: require('../views/beneficiaryModalView.html'),
            size: 'lg',
            controller: 'BeneficiarySelectionController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                beneficiaries() {
                    return $scope.beneficiaries;
                },
                enableFreeFormEntry() {
                    return false;
                },
            },
        });
        modalInstance.result.then(selectedBeneficiary => {
            $scope.wireTemplate.beneficiary = angular.copy(selectedBeneficiary);
            $scope.beneficiaryAddress = setAddress(selectedBeneficiary);
        });
    }

    $scope.$watch(
        () => $scope.wireTemplate.beneficiary,
        data => {
            if (data && !data.status) {
                angular.forEach($scope.beneficiaries, bene => {
                    if (data.accountNumber === bene.accountNumber) {
                        data = bene;
                        $scope.wireTemplate.beneficiary = bene;
                    }
                });
            }
        },
        true
    );

    function goBackToEdit() {
        $scope.errorMessage = '';
        $scope.errorDetails = [];
        $scope.wizardController.goToPreviousStep();
    }
}

CreateWireTemplateController.$inject = [
    '$scope',
    'wireCompaniesService',
    '$modal',
    'modalService',
    'entitlementsService',
    '$state',
    'wireService',
    'beneficiariesService',
    'securityService',
    '$stateParams',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'regexConstants',
    'wireIsoService',
];
