AccountDetailModalController.$inject = ['$scope', '$modalInstance', 'account', 'dashboardService'];

// eslint-disable-next-line @treasury/filename-match-export
export default function AccountDetailModalController(
    $scope,
    $modalInstance,
    account,
    dashboardService
) {
    $scope.account = account;
    $scope.mapAccountType = mapAccountType;

    $scope.graphData = {
        header: [],
        detail: [],
    };

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.isNegative = function (value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    };

    function loadTransactionDetails() {
        dashboardService
            .getTransactionDetail($scope.account.id, $scope.account.type)
            .then(response => {
                $scope.account.transactions = response.transactions;
                let negativeFound = false;
                let positiveFound = false;
                if (
                    $scope.account.type === 'Checking' ||
                    $scope.account.type === 'Savings' ||
                    $scope.account.type === 'Club'
                ) {
                    angular.forEach(response.balanceHistory, item => {
                        $scope.graphData.header.push(
                            moment(item.date, 'YYYY-MM-DD').format('MM/DD/YYYY')
                        );
                        const val = parseFloat(item.availableBalance);
                        $scope.graphData.detail.push({ value: val });
                        if (val < 0) {
                            negativeFound = true;
                        }
                        if (val > 0) {
                            positiveFound = true;
                        }
                    });
                }

                const categoryAxis = [
                    {
                        majorGridLines: {
                            visible: false,
                        },
                        categories: $scope.graphData.header,
                    },
                ];
                if (negativeFound && positiveFound) {
                    categoryAxis.push({
                        labels: { visible: false },
                        majorTicks: { visible: false },
                    });
                }

                $scope.chart = {
                    title: {
                        text: 'Daily Balance For Last 10 Days',
                    },
                    plotArea: {
                        border: {
                            width: 0.3,
                            color: 'black',
                        },
                    },
                    series: [
                        {
                            field: 'value',
                            color: '#627ba0',
                            data: angular.copy($scope.graphData.detail),
                        },
                    ],
                    seriesDefaults: {
                        type: 'line',
                        style: 'smooth',
                        labels: {
                            visible: true,
                            background: 'transparent',
                            format: '{0:C2}',
                        },
                    },
                    valueAxis: {
                        majorGridLines: {
                            visible: false,
                        },
                        labels: {
                            visible: true,
                            format: '{0:C0}',
                        },
                        axisCrossingValues: [-9999999999, 0],
                    },
                    categoryAxis,
                };
            });
    }

    init();

    function loadDepositTransactions() {
        dashboardService.getDepositTransactionDetail($scope.account.id).then(response => {
            $scope.depositTransactionDetail = response;
        });
    }

    function loadTimeDepositTransactions() {
        dashboardService.getTimeDepositTransactionDetail($scope.account.id).then(response => {
            $scope.timeDepositTransactionDetail = response;
        });
    }

    function loadLoanTransactions() {
        dashboardService.getLoanTransactionDetail($scope.account.id).then(response => {
            $scope.loanTransactionDetail = response;
        });
    }

    function init() {
        loadTransactionDetails();
        if (
            $scope.account.type == 'Checking' ||
            $scope.account.type == 'Savings' ||
            $scope.account.type == 'Club'
        ) {
            loadDepositTransactions();
        } else if ($scope.account.type == 'Time Deposit' || $scope.account.type == 'CD') {
            loadTimeDepositTransactions();
        } else if ($scope.account.type == 'Loan') {
            loadLoanTransactions();
        }
        $scope.orderByField = 'date';
        $scope.reverseSort = true;
    }

    $scope.$watchCollection('graphData.detail', newData => {
        if ($scope.chart && $scope.chart.dataSource) {
            $scope.chart.dataSource.data = angular.copy(newData);
        }
    });

    $scope.calculateTotals = function () {
        let transactionSum = 0;
        $scope.account.transactions.forEach(transaction => {
            if (transaction.description.indexOf('Split Out') === -1) {
                transactionSum += transaction.amount;
            }
        });
        return transactionSum;
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.getTransactionHistoryPeriodByAccountType = function () {
        if (
            $scope.account.type === 'Checking' ||
            $scope.account.type === 'Savings' ||
            $scope.account.type === 'Club'
        ) {
            return 'Transactions - Last 10 days';
        }
        if (
            $scope.account.type === 'Time Deposit' ||
            $scope.account.type === 'CD' ||
            $scope.account.type == 'Loan'
        ) {
            return 'Transactions - Last 180 days';
        }

        return 'Transactions - Last 30 days';
    };

    function mapAccountType(type) {
        switch (type) {
            case 'Checking':
            case 'Savings':
            case 'Club':
                return 'Deposit';
            case 'Time Deposit':
                return 'TimeDeposit';
            default:
                return type;
        }
    }
}
