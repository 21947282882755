import { AchBatchSummary, BatchDetails } from '@treasury/domain/channel/types/ach';
import { boolean, Record, Recordset } from '@treasury/FDL';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import { Schema } from '@treasury/omega/layouts/omega-form';
import { html, nothing, TemplateResult } from 'lit';
import { AchDomesticBatch } from '../../../containers/ach-domestic-batch';
import '../../../parts/AchBatchErrorDetail';

export const getBatchDetailColumns =
    (
        context: AchDomesticBatch
    ): ((
        recordset: Recordset<BatchDetails> | undefined
    ) => OmegaColumnDefinition<BatchDetails>[]) =>
    (recordset: Recordset<BatchDetails> | undefined): OmegaColumnDefinition<BatchDetails>[] => {
        const firstRecord = recordset && recordset.allRecords[0];

        const columns: OmegaColumnDefinition<BatchDetails>[] = [
            {
                field: 'selected',
                label: '',
                type:
                    firstRecord && recordset.allRecords[0].getField('step') <= 1
                        ? OmegaColumnType.CheckBox
                        : OmegaColumnType.Empty,
            },
            {
                field: 'name',
                label: 'Batch Name',
            },
            {
                field: 'achCompany',
                label: 'ACH Company Name',
            },
            {
                field: 'secCode',
                label: 'SEC Code',
            },
            {
                field: 'debitAmount',
                label: 'Total Debits',
            },
            {
                field: 'creditAmount',
                label: 'Total Credits',
            },
            {
                field: 'effectiveDate',
                label: 'Effective Date',
            },
            {
                type: OmegaColumnType.Actions,
                label: '',
                actions: [
                    {
                        label: 'Details',
                        action: context.showBatchDetailDialog.bind(context),
                    },
                ],
            },
        ];

        if (firstRecord && recordset.allRecords[0].getField('step') > 1) {
            columns.splice(7, 0, {
                field: 'offsetAccount',
                label: 'Offset Account',
            });
        }

        return columns;
    };

export const getSchema = (
    context: AchDomesticBatch,
    record: Record<AchBatchSummary>,
    recordset: Recordset<BatchDetails>,
    config: any
): Schema<AchBatchSummary, BatchDetails> => ({
    record,
    recordsetConfiguration: {
        recordset,
        recordsetColumns: getBatchDetailColumns(context),
        recordsetFilters: [
            {
                field: 'unselected',
                fieldType: boolean.with
                    .label('Unselected')
                    .thatIs.inline()
                    .as.tag('omega-checkbox')
                    .thatIs.disabledWhen(() => context.step !== 1),
                value: false,
            },
            {
                field: 'unauthorized',
                fieldType: boolean.with
                    .label('Unauthorized')
                    .thatIs.inline()
                    .as.tag('omega-checkbox')
                    .thatIs.disabledWhen(() => context.step !== 1),
                value: false,
            },
            {
                field: 'hasErrors',
                fieldType: boolean.with
                    .label('Errors')
                    .thatIs.inline()
                    .as.tag('omega-checkbox')
                    .thatIs.disabledWhen(() => context.step !== 1),
                value: false,
            },
        ],
        recordsetDetailRowTemplateFunction: (r: Record<BatchDetails>): TemplateResult<1> => {
            const errorSummary = r.getField('errorSummary');
            return errorSummary?.summaryMessageList.length
                ? html`${errorSummary.summaryMessageList.map(
                      (error: string) =>
                          html`<ach-batch-error-details
                              .errorMessage=${error}
                          ></ach-batch-error-details>`
                  )}`
                : html`${nothing}`;
        },
        autoExpandDetailRows: true,
        tableShouldRender: (r: Record<BatchDetails>) => r.getField('step') === 3,
    },
    sections: [
        {
            columns: [
                {
                    fields: ['fileSize'],
                },
                {
                    fields: [
                        'totalBalancedBatches',
                        'totalBalancedDebitAmount',
                        'totalBalancedCreditAmount',
                    ],
                },
                {
                    fields: [
                        'totalUnbalancedBatches',
                        'totalUnbalancedDebitAmount',
                        'totalUnbalancedCreditAmount',
                    ],
                },
            ],
        },
    ],
});
