// * have to get effective date format and item amount format from the specific tiles that hold those values in additional information...
export const fillerTile = {
    id: 100,
    order: null,
    beginPosition: null,
    endPosition: null,
    width: 1,
    type: 'filler',
    name: 'Filler Tile',
    active: 'true',
    optional: 'true',
    required: 'false',
};

export const getItemAmountFormat = tile => {
    if (tile.additionalInformationOne.toLowerCase() === 'decimalincluded') {
        return 'DecimalIncluded';
    }
    if (tile.additionalInformationTwo) {
        return tile.additionalInformationTwo;
    }
    return 'DecimalIncluded';
};

export const buildAndSortTiles = ({ fileFormatDetails }, fields) => {
    const compareText = (a, b) => a.toLowerCase().trim() === b.toLowerCase().trim();
    const getUsedField = ({ text }) =>
        fileFormatDetails.find(({ fileFormatField }) => compareText(fileFormatField, text));

    const activeTiles = fields
        .flat()
        .filter(getUsedField)
        .map(f => {
            const { beginPosition, endPosition, order, id } = getUsedField(f);
            return {
                ...f,
                width: endPosition - beginPosition + 1 || 1,
                beginPosition,
                endPosition,
                id,
                active: 'true',
                order,
            };
        })
        .sort((a, b) => a.order - b.order)
        .sort((a, b) => a.beginPosition - b.beginPosition);

    const inactiveTiles = fields
        .flat()
        .filter(f => !getUsedField(f))
        .map(f => ({
            ...f,
            order: null,
            beginPosition: null,
            endPosition: null,
        }));

    return { activeTiles, inactiveTiles };
};

/**
 *
 * @param {string} formatType fileFormatType property from the service response: 'FixedPosition', 'Delimited'
 * @param {Array} tiles any tiles with {active: 'true'}
 * @returns array of tiles with filler tiles placed in index or range gaps
 */
export const addFillerTiles = (formatType, tiles) => {
    const tilesAreFixed = formatType === 'FixedPosition';

    /**
     * @returns the tile in the data that should go in the slot position || null
     */
    const mapTileToSlot = (slot, i) => {
        // Service data uses 1 for 0 index position
        const dbIndex = i + 1;

        const tileForSlot = tilesAreFixed
            ? tiles.find(
                  ({ beginPosition, endPosition }) =>
                      beginPosition <= dbIndex && endPosition >= dbIndex
              )
            : tiles.find(({ order }) => order === dbIndex);

        return tileForSlot || null;
    };

    // Any slots still null after adding tiles should be filled with filler tiles
    const mapFillerTilesToEmptySlots = s => s ?? fillerTile;

    const maxTileOrder = Math.max(...tiles.map(({ order }) => +order));
    const maxTileRangePosition = Math.max(...tiles.map(({ endPosition }) => +endPosition));

    // Use highest order or range value to set length of the empty slot array
    const emptySlots = new Array(tilesAreFixed ? maxTileRangePosition : maxTileOrder).fill(null);
    if (tilesAreFixed) {
        // apply a copy of each item to every slot in its range
        const condensedRangeSlots = emptySlots
            .map(mapTileToSlot)
            .map(mapFillerTilesToEmptySlots)
            .reduce((acc, slot) => {
                const previousSlot = acc[acc.length - 1];
                const previousIsSameType = previousSlot && previousSlot.id === slot.id;

                return !acc.length || !previousIsSameType ? [...acc, slot] : acc;
            }, []);

        // use previous and next item tiles to set filler beginPosition and endPosition values
        return condensedRangeSlots.map((slot, i) => {
            if (slot.type !== 'filler') return slot;

            const prevSlot = condensedRangeSlots[i - 1];
            const nextSlot = condensedRangeSlots[i + 1];

            const beginPosition = prevSlot ? prevSlot.endPosition + 1 : 1;
            const endPosition = nextSlot.beginPosition - 1;

            const width = endPosition - beginPosition + 1;

            return { ...slot, beginPosition, endPosition, width };
        });
    }
    return emptySlots
        .map(mapTileToSlot)
        .map(mapFillerTilesToEmptySlots)
        .map((slot, i) => ({ ...slot, order: i + 1 }));
};

export const mapResponseToWireUploadFormat = (res, fields) => {
    const effectiveTile = res.fileFormatDetails.find(
        tile => tile.fileFormatField === 'WireEffectiveDate'
    );
    const amountTile = res.fileFormatDetails.find(tile => tile.fileFormatField === 'WireAmount');
    const effectiveDateFormat = effectiveTile.additionalInformationOne;
    const itemAmountFormat = getItemAmountFormat(amountTile);

    const { activeTiles, inactiveTiles } = buildAndSortTiles(res, fields);

    return {
        id: res.id,
        title: 'Wire Upload Template Formatting Tool',
        type: res.fileFormatType,
        name: res.templateName,
        delimiter: res.fieldDelimiter,
        excludeHeaderRows: res.headerRows || 0,
        excludeFooterRows: res.footerRows || 0,
        itemAmountFormat,
        effectiveDateFormat,
        containers: [
            { id: 'in', title: 'Drag and drop items to reorder columns', active: 'true' },
            {
                id: 'out',
                title: 'Drag and drop optional items to the space above to activate',
                active: 'false',
            },
        ],
        tiles: [
            ...addFillerTiles(res.fileFormatType, activeTiles),
            ...inactiveTiles,
            {
                id: 100,
                order: null,
                beginPosition: null,
                endPosition: null,
                width: 1,
                type: 'filler',
                name: 'Filler Tile',
                optional: 'true',
                required: 'false',
            },
        ],
    };
};

/**
 *
 * @param {*} params id, fileFormatModel
 * * NOTES ON CRAZINESS:
 * - ADDITIONAL INFORMATION FIELDS (while mapping tiles to fields and matching active tiles):
 *      - when the field is EffectiveDate: additionalInformationOne should hold the effectiveDateFormat
 *      - when the field is WireAmount: additionalInformationOne is the itemAmountFormat ONLY when it's decimalIncluded,
 *          ELSE additionalInformationOne must be 'decimalNotIncluded' and additionalInformationTwo must be the itemAmountFormat (wholeDollar or impliedDecimal)
 */
export const mapRequestToUploadFormatParameters = params => ({
    id: params.id || 0,
    templateName: params.name,
    fileFormatType: params.type,
    fieldDelimiter: params.delimiter || ',',
    headerRows: Number(params.excludeHeaderRows),
    footerRows: Number(params.excludeFooterRows),
    fileFormatDetails: params.tiles
        .filter(tile => tile.active === 'true' && tile.type !== 'filler')
        .map((tile, idx) => ({
            id: tile.id ? Number(tile.id) : 0,
            fileFormatField: tile.text,
            order: Number(tile.order),
            beginPosition: Number(tile.beginPosition) || idx,
            endPosition: Number(tile.endPosition) || idx,
            additionalInformationOne:
                // eslint-disable-next-line no-nested-ternary
                tile.text === 'WireEffectiveDate'
                    ? params.effectiveDateFormat
                    : tile.text === 'WireAmount' && params.itemAmountFormat === 'DecimalIncluded'
                    ? params.itemAmountFormat
                    : 'DecimalNotIncluded',
            additionalInformationTwo:
                tile.text === 'WireAmount' && params.itemAmountFormat !== 'DecimalIncluded'
                    ? params.itemAmountFormat
                    : null,
            additionalInformationThree: null,
            additionalInformationFour: null,
        })),
});

export const mapResponseToGlossaryAndTiles = res => {
    const glossary = res.fieldGroups.map(group => ({
        title: group.info,
        label: group.info,
        id: group.name,
        terms: group.fields.map(field => ({
            name: field.description,
            text: field.info,
        })),
    }));

    const tiles = res.fieldGroups.map(group =>
        group.fields.map(field => ({
            id: field.id,
            width: 1,
            type: group.description.toLowerCase(),
            name: field.description,
            text: field.name,
            optional: (!field.required).toString(),
            active: field.required ? 'true' : 'false',
        }))
    );
    return {
        glossary,
        tiles,
    };
};
