/* eslint-disable import/extensions */
import { FiDate } from '../../../dates';
import {
    mapAchTaxTemplatesFieldsToParameters,
    mapActionToActionType,
    mapRecordValuesToKeyValuePair,
} from '../../mappings/ach/ach-tax-templates.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchTaxTemplateRequests from '../../requests/ach/ach-tax-template-requests.js';
import DownloadService from '../download/download-service.js';

export default class AchTaxTemplateService {
    static async fetchAchTaxTemplates(body) {
        const mappedRequestBody = mapAchTaxTemplatesFieldsToParameters(body);
        return AchTaxTemplateRequests.fetchAchTaxTemplates(mappedRequestBody);
    }

    static async fetchCompanies() {
        return AchCompanyRequests.getAchCompanies();
    }

    static async fetchSecCodes() {
        return AchCompanyRequests.getAchCompanySecCodes();
    }

    static async fetchAchSettings() {
        return AchCompanyRequests.getAchSettings();
    }

    static async initiateTaxPaymentsByIds(recordsValues) {
        const mappedRecordKeyPairs = mapRecordValuesToKeyValuePair(recordsValues);
        return AchTaxTemplateRequests.initiateTaxPaymentsByIds(mappedRecordKeyPairs);
    }

    static async downloadAchTaxTemplates(outputType, filterModel) {
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        const mappedDownloadRequestFilters = mapAchTaxTemplatesFieldsToParameters({
            parameters: filterModel,
        });
        return DownloadService.download(
            fileSuffix,
            outputType,
            'ACHTaxTemplateList',
            { ...mappedDownloadRequestFilters, page: 'ACHTaxTemplateList' },
            ['PDF']
        );
    }

    static async deleteTemplateById(id) {
        return AchTaxTemplateRequests.deleteTemplateById(id);
    }

    static async updateTemplateStatus(...args) {
        const actionType = mapActionToActionType(...args);
        return AchTaxTemplateRequests.updateTemplateStatus(actionType, ...args);
    }
}
