/* eslint-disable no-use-before-define */
import { FiDate } from '@treasury/domain/dates';
import { exists } from '@treasury/utils/functions';
import { OmegaDate } from '../components/datepicker';
import { OmegaNumericValue } from '../types';

export function extractDates(date: OmegaDate | null | undefined, formatFn = formatDatesDefault) {
    if (!exists(date) || date.id === 'no-date-selected' || date.dates.length === 0) {
        return {
            start: '',
            end: '',
        };
    }

    const [startDate, endDate] = date.dates;
    const start = formatFn(startDate);
    const end = endDate ? formatFn(endDate) : start;

    return {
        start,
        end,
    };
}

/**
 * Extract the start and end values out of an Omega value definition tuple into an object.
 *
 * @param valueDef A tuple representing a range of values.
 */
export function extractValues(valueDef: OmegaNumericValue | null | undefined) {
    if (!exists(valueDef)) {
        return {
            start: null,
            end: null,
        };
    }

    if (valueDef[0] === 'range') {
        const [, start, end] = valueDef;
        return {
            start,
            end,
        };
    }

    const [, start] = valueDef;
    return {
        start,
        end: start,
    };
}

// eslint-disable-next-line @treasury/no-date
function formatDatesDefault(dateOrStr: Date | string) {
    return new FiDate(dateOrStr).toString();
}

export function coerceString<T>(value: T | string) {
    if (typeof value === 'string') {
        return null;
    }
    return value;
}

export function toKebabCase(str: string) {
    return (
        str
            // in case str has spaces
            .replace(/ /g, '-') // Twice A Month -> Twice-A-Month

            // when str does not have spaces (variable names)
            .replace(/([A-Z])([A-Z])/, '$1-$2') // TwiceAMonth -> TwiceA-Month (two adjacent uppercase)
            .replace(/([a-z])([A-Z])/g, '$1-$2') // TwiceA-Month -> Twice-A-Month (lower followed by upper)
            .toLowerCase() // Twice-A-Month -> twice-a-month
    );
}
