import { svg } from 'lit';

export const refreshIcon = svg`
<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
  <defs>
    <path d="M12.7 0h-1.23a.31.31 0 00-.31.32l.1 2.14A6.38 6.38 0 000 6.6a6.4 6.4 0 0010.68 4.74.3.3 0 000-.45l-.87-.87A.3.3 0 009.4 10a4.54 4.54 0 11.77-5.93l-2.62-.13a.3.3 0 00-.32.31v1.22c0 .17.14.31.3.31h5.17a.3.3 0 00.31-.3V.3a.3.3 0 00-.3-.31z" id="refresh-a"/>
  </defs>
  <g transform="translate(9 9)" fill="none" fill-rule="evenodd">
    <mask id="refresh-b" fill="#fff">
      <use xlink:href="#refresh-a"/>
    </mask>
    <use fill="#000" fill-rule="nonzero" xlink:href="#refresh-a"/>
    <path fill="currentColor" mask="url(#refresh-b)" d="M-4.06-4.06h21.11v21.11H-4.06z"/>
  </g>
</svg>`;
