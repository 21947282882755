import { ChallengeType, ErrorType } from '@treasury/domain/channel/mappings/security';
import { openIdentityDialog } from '@treasury/omega/domain-components/identity-verification-dialog';
import { OmegaDialogExitReason } from '@treasury/omega/services/index';
import { html, nothing } from 'lit';

export const StatusCode = {
    Allow: 'Allow',
    Invalid: 'Invalid',
    Enrollment: 'Enrollment',
    Register: 'Register',
    Challenge: 'Challenge',
    ForgotPassword: 'ForgotPassword',
    ResetPassword: 'ResetPassword',
    ChallengeResetPassword: 'ChallengeResetPassword',
    ChallengeResetExpiredPassword: 'ChallengeResetExpiredPassword',
    ResetExpiredPassword: 'ResetExpiredPassword',
    ResetPasswordEnrollment: 'ResetPasswordEnrollment',
    UserVerification: 'UserVerification',
    RegisterOutOfBand: 'RegisterOutOfBand',
    RegisterSecureToken: 'RegisterSecureToken',
    TermsAndConditions: 'TermsAndConditions',
    PromptToResetPassword: 'PromptToResetPassword',
    ChallengePromptToResetPassword: 'ChallengePromptToResetPassword',
    PromptToResetPasswordEnrollment: 'PromptToResetPasswordEnrollment',
    OobLegacy: 'OobLegacy',
    OobOtp: 'OobOtp',
    SecureToken: 'SecureToken',
    TwoFactorChallenge: 'TwoFactorChallenge',
    UserSelection: 'UserSelection',
    Loading: 'Loading',
};

const renderChangePassword = context =>
    html`<change-password
        .client=${context.client}
        .institution=${context.institution}
        .userRecord=${context.verifiedUserRecord}
        .verifiedUser=${context.verifiedUserResponse}
        .submitting=${context.loading}
        .statusCode=${context.statusCode}
        @submit=${context.submitPasswordChangeForm}
        @complete=${context.completeLogin}
    ></change-password>`;

const renderSecureToken = context =>
    html`<secure-token-registration
        .client=${context.client}
        @register=${e => context.registerSecureToken(e.detail)}
        @remind=${() => context.secureTokenRemindMeLater()}
        .isUisEnabled=${context.isUisEnabled}
    ></secure-token-registration>`;

export const LoginPhases = {
    [StatusCode.Loading]: {
        title: 'Logging in...',
        render: context => html` <omega-progress card></omega-progress>`,
    },
    [StatusCode.Allow]: {
        title: '',
        render: context => context.completeLogin(),
    },
    [StatusCode.Enrollment]: {
        title: 'Security Questions',
        render: context =>
            html`<security-questions
                .loading=${context.loading}
                .securityQuestions=${context.loginResult?.data}
                .numberOfSecurityQuestions=${context.loginResult?.numberOfSecurityQuestions}
                @submit=${context.saveSecurityQuestions}
            ></security-questions>`,
    },
    [StatusCode.Challenge]: {
        title: 'Security Questions',
        render: context =>
            html`<security-questions
                readonly
                .securityQuestions=${context.loginResult?.data}
                .numberOfSecurityQuestions=${context.loginResult?.numberOfSecurityQuestions}
                .loading=${context.loading}
                @submit=${context.verifySecurityQuestions}
            ></security-questions>`,
    },
    [StatusCode.ForgotPassword]: {
        title: 'Forgot Password',
        render: context =>
            html`<forgot-password
                .institution=${context.institution}
                .forgotPasswordResponse=${context.forgotPasswordResponse}
                .loading=${context.loading}
                @submit=${context.submitForgotPasswordForm}
                @back=${context.resetStatusCode}
            ></forgot-password>`,
    },
    [StatusCode.UserVerification]: {
        title: 'User Verification',
        render: context =>
            html`<user-verification
                .institution=${context.institution}
                .loading=${context.loading}
                @submit=${context.submitUserVerificationForm}
            ></user-verification>`,
    },
    [StatusCode.RegisterOutOfBand]: {
        title: 'Phone Numbers for Authentication',
        render: context =>
            html`<oob-registration
                .client=${context.client}
                @done=${() => context.completeOutOfBandConfig()}
                .isUisEnabled=${context.isUisEnabled}
            ></oob-registration>`,
    },
    [StatusCode.Register]: {
        title: 'Register Secure Token',
        render: context => renderSecureToken(context),
    },
    [StatusCode.RegisterSecureToken]: {
        title: 'Register Secure Token',
        render: context => renderSecureToken(context),
    },
    [StatusCode.ResetPassword]: {
        title: 'Change Password',
        render: context => renderChangePassword(context),
    },
    [StatusCode.ResetPasswordEnrollment]: {
        title: 'Change Password',
        render: context => renderChangePassword(context),
    },
    [StatusCode.ChallengeResetPassword]: {
        title: 'Change Password',
        render: context => renderChangePassword(context),
    },
    [StatusCode.ChallengeResetExpiredPassword]: {
        title: 'Change Password',
        render: context => renderChangePassword(context),
    },
    [StatusCode.ResetExpiredPassword]: {
        title: 'Change Password',
        render: context => renderChangePassword(context),
    },
    [StatusCode.UserSelection]: {
        title: 'User Selection',
        render: context =>
            html`<user-selection
                .userOptions=${context.userOptions}
                @selected=${e => context.userSelected(e.detail.userCompanyUniqueId)}
            ></user-selection>`,
    },
    [StatusCode.Challenge]: {
        isDialog: true,
        title: 'Challenge',
        render: context => {
            const securityMessage = {
                challengeMethodTypeId: ChallengeType.OutOfBandOTP,
                errorCode: ErrorType.Verify,
            };

            openIdentityDialog(securityMessage, {
                forLogin: true,
                disableClose: true,
            }).then(result => {
                if (result.reason !== OmegaDialogExitReason.Confirm) {
                    throw new Error('Failed to validate user using MFA.');
                }

                context.checkTermsAndConditions();
            });

            return nothing;
        },
    },
    [undefined]: {
        title: 'Login',
        render: context =>
            html`<channel-login
                .record=${context.loginRecord}
                .loading=${context.loading}
                @login=${context.login}
                @forgot-password=${context.forgotPassword}
            ></channel-login>`,
    },
};
