import { getChannelAPIPath } from '@treasury/core/http';

AchSettingsService.$inject = ['$resource', '$q', '$http'];

export default function AchSettingsService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}achSettings`;

    return {
        get,
    };

    function get() {
        return $resource(resourceUrl).get().$promise;
    }
}
