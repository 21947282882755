/* eslint-disable no-param-reassign */
import { AUTH_TOKEN, getAuthToken, isApiRequest } from '@treasury/core/http';

export async function AuthRequestInterceptor(config: ng.IRequestConfig) {
    const authToken = getAuthToken();
    const { url } = config;
    const apiRequest = await isApiRequest(url);

    if (!config.headers) {
        config.headers = {};
    }

    if (authToken && apiRequest) {
        config.headers[AUTH_TOKEN] = authToken;
    }

    config.headers['Jha-Treasury-ClientInfo'] = 'Web';
    config.headers['x-tm-client-web'] = true;
    return config;
}
