ProfileController.$inject = [
    '$scope',
    '$modal',
    '$state',
    'accountService',
    'toaster',
    'securityService',
    'modalService',
    'navigationService',
    'menulessOptions',
];

export default function ProfileController(
    $scope,
    $modal,
    $state,
    accountService,
    toaster,
    securityService,
    modalService,
    navigationService,
    menulessOptions
) {
    // variables
    $scope.form = null;
    $scope.userInformationVisible = true;
    $scope.securityPreferencesVisible = true;

    // functions
    $scope.setForm = setForm;
    $scope.showChangePassword = showChangePassword;
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.originalEmail = '';

    // public functions
    function setForm(form) {
        $scope.form = form;
    }
    function showChangePassword() {
        const $modalInstance = $modal.open({
            template: require('../views/changePasswordModalView.html'),
            controller: 'ChangePasswordModalController',
            backdrop: 'static',
        });
        $modalInstance.result.then(() => {
            toaster.save('Password');
        });
    }

    function save() {
        assemblePhoneNumbers();
        accountService.setAccountDisplayField($scope.user.accountNicknames ? 'name' : 'number');

        if ($scope.originalEmail !== $scope.user.email) {
            securityService
                .verifyUser('Update Email Address', $scope.user, () =>
                    accountService.updateCurrentUser($scope.user)
                )
                .then(userUpdateRequestComplete);
        } else {
            accountService.updateCurrentUser($scope.user).then(userUpdateRequestComplete);
        }
    }

    function userUpdateRequestComplete(response) {
        toaster.save('User Preferences');
        $scope.form.$setPristine();
        $scope.originalEmail = $scope.user.email;
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit() {
                    $modalInstance.close();
                    loadUserProfile();
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $state.go('dashboard');
        }
    }

    // private functions
    function loadUserProfile() {
        navigationService.userActivityAudit(menulessOptions.UserProfile);

        accountService.getCurrentUser().then(response => {
            $scope.user = response;
            $scope.originalEmail = $scope.user.email;
            loadPhoneNumbers();
            $scope.$digest();
        });
    }

    function assemblePhoneNumbers() {
        if ($scope.user) {
            $scope.user.phone = '';
            $scope.user.phone += $scope.user.phone1 ? $scope.user.phone1 : '';
            $scope.user.phone += $scope.user.phone2 ? $scope.user.phone2 : '';
            $scope.user.phone += $scope.user.phone3 ? $scope.user.phone3 : '';

            $scope.user.mobile = '';
            $scope.user.mobile += $scope.user.mobile1 ? $scope.user.mobile1 : '';
            $scope.user.mobile += $scope.user.mobile2 ? $scope.user.mobile2 : '';
            $scope.user.mobile += $scope.user.mobile3 ? $scope.user.mobile3 : '';

            $scope.user.fax = '';
            $scope.user.fax += $scope.user.fax1 ? $scope.user.fax1 : '';
            $scope.user.fax += $scope.user.fax2 ? $scope.user.fax2 : '';
            $scope.user.fax += $scope.user.fax3 ? $scope.user.fax3 : '';
        }
    }

    function loadPhoneNumbers() {
        // used to break up phone/fax
        if ($scope.user) {
            if ($scope.user.phone) {
                $scope.user.phone1 = $scope.user.phone.substring(0, 3);
                $scope.user.phone2 = $scope.user.phone.substring(3, 6);
                $scope.user.phone3 = $scope.user.phone.substring(6, 10);
            }
            if ($scope.user.mobile) {
                $scope.user.mobile1 = $scope.user.mobile.substring(0, 3);
                $scope.user.mobile2 = $scope.user.mobile.substring(3, 6);
                $scope.user.mobile3 = $scope.user.mobile.substring(6, 10);
            }
            if ($scope.user.fax) {
                $scope.user.fax1 = $scope.user.fax.substring(0, 3);
                $scope.user.fax2 = $scope.user.fax.substring(3, 6);
                $scope.user.fax3 = $scope.user.fax.substring(6, 10);
            }
        }
    }

    loadUserProfile();
}
