export const reportColumns = [
    {
        field: 'effectiveDate',
        label: 'Original Effective Date',
    },
    {
        field: 'receivedDate',
        label: 'Date Return Received',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company',
    },
    {
        field: 'amount',
        label: 'Amount',
    },
    {
        field: 'fullReturnReason',
        label: 'Return Reason',
    },
];
