/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ErrorHttpContentDto, ProductTypeModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class SsoProductTypeClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags SsoProductType
     * @name SsoProductTypeGet
     * @summary Gets a single ProductTypeModel.
     * @request GET:/SsoProductType/{productTypeId}
     */
    ssoProductTypeGet = (productTypeId: number, params: RequestParams = {}) =>
        this.http.request<ProductTypeModelDto, ErrorHttpContentDto>({
            path: `/SsoProductType/${productTypeId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
