interface EntitlementDtoBase {
    id: number;
    updatedBy: string | null;
    updateDate: string;
}

interface ChannelEntitlementDto extends EntitlementDtoBase {
    permission: string;
}

interface BoEntitlementDto extends EntitlementDtoBase {
    description: string;
    name: string;
    isDeleted: boolean;
}

export type EntitlementDto = ChannelEntitlementDto | BoEntitlementDto;

function isChannelEntitlement(dto: EntitlementDto): dto is ChannelEntitlementDto {
    const maybeChannelEntitlement = dto as ChannelEntitlementDto;
    return maybeChannelEntitlement && typeof maybeChannelEntitlement.permission === 'string';
}

export function getEntitlementName(dto: EntitlementDto) {
    return (isChannelEntitlement(dto) ? dto.permission : dto.name).trim();
}
