import {
    PaymentHeader,
    WorkflowTypeEntitlements,
    WorkflowTypes,
} from '@treasury/domain/channel/types/ach';
import { Step } from '@treasury/omega/layouts/omega-form';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import { AchDomesticContainer } from '../containers/ach-domestic-container';
import { EntitlementsFactory } from './factories/entitlementFactory';
import { WorkflowConfigurationFactory } from './factories/workflowConfigurationFactory';
export interface IDictionary<TValue> {
    [id: number]: TValue;
}

export interface StepConfig extends Step<PaymentHeader> {
    formHeading?: string;
}
export interface WorkflowTypeConfiguration {
    title?: string;
    formHeadings?: IDictionary<string>;

    steps: Array<StepConfig>;
    /**
     * Step workflow starts on when page loads
     */
    startStep?: number;
}

export interface Config<TClient> {
    title?: string;
    key?: string;
    downloadKey?: string;
    downloadClientService?: string;
    post: keyof TClient;
    put: keyof TClient;
    delete?: keyof TClient;
    downloadOptions?: string[];
    creationOptions?: string[];
    permissionsKey?: string;
    permissions?: string[];
    statusField?: string;
    prenoteField?: string;
    holdField?: string;
    reportUrl?: string;
    entitlements: WorkflowTypeEntitlements;
    workflow: WorkflowTypeConfiguration;
}

export class ContainerConfiguration {
    constructor(context: AchDomesticContainer, payment: PaymentHeader) {
        this.context = context;
        const entitlementsFactory = new EntitlementsFactory(context);
        this.entitlements = entitlementsFactory.entitlements;

        const workflowConfigurationFactory = new WorkflowConfigurationFactory(
            context,
            payment,
            this.entitlements
        );

        this.template = {
            entitlements: entitlementsFactory.getEntitlements(WorkflowTypes.Template),
            post: 'createTemplate',
            put: 'editTemplate',
            workflow: workflowConfigurationFactory.configuration,
        };

        this.payment = {
            entitlements: entitlementsFactory.getEntitlements(WorkflowTypes.Payment),
            post: 'createPayment',
            put: 'editPayment',
            workflow: workflowConfigurationFactory.configuration,
        };
    }

    context: AchDomesticContainer;

    template: Config<AchDomesticClient>;

    payment: Config<AchDomesticClient>;

    entitlements: WorkflowTypeEntitlements;

    /**
     * Get a workflow based on the current workflow and action
     */
    public get workflow() {
        return this.configuration.workflow;
    }

    public get configuration() {
        return this[this.context.workflow.type];
    }
}
