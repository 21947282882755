/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { OkResultDto, UpdateSsoUserInstitutionModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class NavigationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Navigations
     * @name NavigationsUpdateInstitution
     * @summary Update institution.
     * @request POST:/navigations/UpdateInstitution/{institutionId}
     */
    navigationsUpdateInstitution = (institutionId: number, params: RequestParams = {}) =>
        this.http.request<UpdateSsoUserInstitutionModelDto, any>({
            path: `/navigations/UpdateInstitution/${institutionId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Navigations
     * @name NavigationsAuditMenulessUserActivity
     * @summary Save menu user activity.
     * @request POST:/navigations/AuditMenulessUserActivity/{menulessNavigationId}
     */
    navigationsAuditMenulessUserActivity = (
        menulessNavigationId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, any>({
            path: `/navigations/AuditMenulessUserActivity/${menulessNavigationId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Navigations
     * @name NavigationsAuditUserActivity
     * @summary Save audit user activity
     * @request POST:/navigations/auditUserActivity/{navigationKey}
     */
    navigationsAuditUserActivity = (navigationKey: string, params: RequestParams = {}) =>
        this.http.request<OkResultDto, any>({
            path: `/navigations/auditUserActivity/${navigationKey}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
}
