import { ChallengeType, SecurityMessage } from '@treasury/domain/channel/mappings/security';
import { IdentityDialogResult } from '@treasury/domain/shared';
import { nothing, TemplateResult } from 'lit';
import { IdentityVerificationDialog } from '../identity-verification-dialog';
import { Oob } from './oob';
import { OobOtp } from './oob-otp';
import { SecureToken } from './secure-token';

export interface AccountValidationResult {
    success: boolean;
    result?: SecurityMessage;
}
/**
 * Defines a set of methods for rendering and MFA logic
 * in an `IdentityVerificationDialog` instance.
 */
export interface IdentityDialogStrategy {
    renderContent(): TemplateResult | typeof nothing;
    renderActions(): TemplateResult | typeof nothing;
    validateAccountPassword(password: string): Promise<AccountValidationResult>;
}

const NullIdentityStrategy: IdentityDialogStrategy = {
    renderContent: () => nothing,
    renderActions: () => nothing,
    validateAccountPassword: async () => ({
        success: true,
    }),
};

export function createDialogStrategy(
    context: IdentityVerificationDialog,
    securityMessage?: SecurityMessage
): IdentityDialogStrategy {
    if (!securityMessage) {
        return NullIdentityStrategy;
    }

    const { challengeMethodTypeId } = securityMessage;
    switch (challengeMethodTypeId) {
        case ChallengeType.OutOfBand:
            return new Oob(context);
        case ChallengeType.OutOfBandOTP:
            return new OobOtp(context);
        case ChallengeType.SecureToken:
            return new SecureToken(context);
        case 0:
            throw new Error(
                'Cannot create identity dialog strategy. Challenge code zero provided. Did you forget to normalize in client code?'
            );
        default:
            return NullIdentityStrategy;
    }
}

export enum IdentityDialogEvent {
    Close = 'close',
}

export type IdentityDialogResultInternal = Omit<IdentityDialogResult, 'reason'>;
