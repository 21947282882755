import { hasValidAmount } from './ach-exception-filters-mapping';

export const mapAchExceptionsActivityFiltersToRequest = (filters: any) => ({
    achExceptionDetailSearchModel: {
        ...filters,
        secCodes: filters.secCodes?.map((s: { secCode: string; description: string }) => ({
            code: s.secCode,
        })),
        postedDate: filters.postedDate?.startDate,
        postedDateStart: filters.postedDate?.startDate,
        postedDateEnd: filters.postedDate?.endDate,
        postedDateType: filters.postedDate?.type,
        amountType: hasValidAmount(filters.amountRange) ? filters.amountRange?.rangeType : null,
        amountStart: filters.amountRange?.floorAmount,
        amountEnd: filters.amountRange?.ceilAmount,
        amount: filters.amountRange?.specificAmount,
        amountRange: null,
    },
    pagingParameters: {
        pageSize: 10,
        pageNumber: 1,
        orderBys: [
            {
                name: 'DecisionDate',
                descending: true,
            },
        ],
        currentPage: 1,
    },
    searchText: '',
    searchAccountNickname: true,
});
