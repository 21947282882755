export const normalizeFrequencyType = type => {
    let result = type;

    switch (type) {
        case 'EveryTwoWeeks':
            result = 'Every Two Weeks';
            break;
        case 'TwiceAMonth':
            result = 'Twice a Month';
            break;
        case 'EverySixMonths':
            result = 'Every Six Months';
            break;
        case 'OneTime':
            result = 'One Time';
            break;
        default:
    }

    return result;
};
