export default [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            replace: true,
            scope: {
                ngModel: '=',
                selectedBank: '=',
                placeholder: '@',
                ngRequired: '=',
                ngInvalid: '=?',
                ngPattern: '=?',
                formField: '=?',
                maxLength: '@',
                minLength: '@',
                banks: '=',
                editable: '@',
                name: '@',
                displayColumn: '@',
                returnColumn: '@',
                warnIntermediary: '@',
                onSelectBank: '&',
            },
            link(scope, element, attr, ngModel) {},
            template: require('./typeAheadBeneficiaryBanksTemplate.html'),
            controller: [
                '$scope',
                '$modal',
                function ($scope, $modal) {
                    $scope.showBankDialog = function () {
                        if (!$scope.banksLoaded()) {
                            return;
                        }

                        const modalInstance = $modal.open({
                            template: require('../../../payables/wire/beneficiaries/views/bankDialogView.html'),
                            size: 'md',
                            controller: 'BankDialogController',
                            backdrop: 'static',
                            resolve: {
                                banks() {
                                    return $scope.banks;
                                },
                            },
                        });
                        modalInstance.warnIntermediary = $scope.warnIntermediary;

                        modalInstance.result.then(response => {
                            if ($scope.returnColumn) {
                                $scope.ngModel = response[$scope.returnColumn];
                            } else {
                                $scope.ngModel = response;
                            }

                            if ($scope.formField) {
                                $scope.formField.$setDirty();
                            }

                            if ($scope.onSelectBank) {
                                $scope.onSelectBank({ bank: response });
                            }
                        });
                    };

                    $scope.onSelect = function ($item, $model, $label) {
                        if ($scope.onSelectBank) {
                            $scope.onSelectBank({ bank: $item });
                        }
                    };

                    $scope.banksLoaded = function () {
                        return Array.isArray($scope.banks);
                    };
                },
            ],
        };
    },
];
