import { css, html, LitElement, nothing } from 'lit';

class ActionsDropdown extends LitElement {
    static get properties() {
        return {
            closed: Boolean,
            label: String,
            actions: Array,
            record: Object,
            rowIndex: Number,
            visibleActions: Array,
        };
    }

    constructor() {
        super();
        this.closed = true;
        this.visibleActions = [];
    }

    firstUpdated() {
        this.visibleActions = this.actions.filter(action =>
            action.visibleWhen ? action.visibleWhen(this.record) : true
        );
        if (this.record) {
            this.record.onChange(() => {
                this.visibleActions = this.actions.filter(action =>
                    action.visibleWhen ? action.visibleWhen(this.record) : true
                );
            });
        }
    }

    updated(changedProperties) {
        if (changedProperties.has('record') && this.record) {
            this.visibleActions = this.actions.filter(action =>
                action.visibleWhen ? action.visibleWhen(this.record) : true
            );
        }
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('click', this.handlePossibleExternalClick.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('click', this.handlePossibleExternalClick.bind(this));
    }

    toggleActionsDropdown() {
        this.closed = !this.closed;
    }

    handleAction(record, rowIndex, { action }, e) {
        this.closed = true;
        e.target.dispatchEvent(
            new CustomEvent('action', {
                bubbles: true,
                composed: true,
                detail: {
                    record,
                    rowIndex,
                    action,
                },
            })
        );
    }

    handlePossibleExternalClick(event) {
        if (!this.shadowRoot) return;
        if (event && !this.shadowRoot.contains(event.composedPath()[0]) && !this.closed)
            this.closed = true;
    }

    render() {
        // console.log('rerender dropdown', this.record, this.visibleActions);
        if (this.visibleActions.length === 0) return nothing;
        if (this.visibleActions.length === 1) {
            const [action] = this.visibleActions;
            // eslint-disable-next-line lit-a11y/click-events-have-key-events
            return html`<span
                class="actions-dropdown-trigger"
                aria-controls="actions-dropdown"
                role="button"
                @click=${e => this.handleAction(this.record, this.rowIndex, action, e)}
                @keydown=${e => {
                    if (e.key === 'Enter') this.handleAction(this.record, this.rowIndex, action, e);
                }}
                >${action.label}</span
            >`;
        }

        // eslint-disable-next-line lit-a11y/click-events-have-key-events
        return html` <div
            class="actions-dropdown-wrapper"
            @click=${() => this.toggleActionsDropdown(this.rowIndex)}
        >
            <span class="actions-dropdown-trigger" aria-controls="actions-dropdown" role="button">
                ${this.label}
                <svg
                    class="dropdown-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    viewBox="0 0 684 742.5"
                >
                    <path d="M0 0l342 594L684 0H0z" />
                </svg>
            </span>
            <ul class="actions-dropdown" role="menu" aria-hidden=${this.closed}>
                ${this.visibleActions.map(
                    action =>
                        // eslint-disable-next-line lit-a11y/click-events-have-key-events
                        html`<li
                            role="menuitem"
                            class="action-item"
                            @click=${e => this.handleAction(this.record, this.rowIndex, action, e)}
                        >
                            ${action.label}
                        </li>`
                )}
            </ul>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .actions-dropdown-wrapper {
                position: relative;
                cursor: pointer;
            }
            .actions-dropdown-trigger {
                cursor: pointer;
                color: var(--omega-primary);
                display: inline-block;
                white-space: nowrap;
            }
            .actions-dropdown[aria-hidden='false'] {
                position: absolute;
                right: 0;
                top: 5px;
                z-index: 5;
                min-width: 100px;
                padding: 0;
                border: 1px solid var(--omega-secondary-lighten-400);
                background: var(--omega-white);
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                list-style: none;
            }
            .actions-dropdown[aria-hidden='true'] {
                display: none;
            }

            .actions-dropdown li {
                min-width: 50px;
                padding: 10px 10px;
                text-align: left;
                color: var(--omega-text-default);
                cursor: pointer;
            }

            .actions-dropdown li:hover {
                background-color: var(--omega-white-100);
            }

            .actions-dropdown a {
                text-decoration: none;
            }
            .dropdown-icon {
                width: 6px;
                fill: var(--omega-primary);
            }
        `;
    }
}

customElements.define('actions-dropdown', ActionsDropdown);
export default ActionsDropdown;
