const isStartDateWithinAMonthOfEndDate = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    const startDateWithin = endDate.setDate(endDate.getDate() - 30);
    return startDateWithin < startDate.setDate(startDate.getDate());
};

export default {
    name: 'isStartDateWithinAMonthOfEndDate',
    validate: v => {
        const dynamicRanges = ['$TODAY', '$MTD', '$WTD'];
        if (dynamicRanges.includes(v)) return true;
        if (v.length === 23) {
            const startDate = v.slice(0, 10);
            const endDate = v.slice(13, 23);
            return isStartDateWithinAMonthOfEndDate(startDate, endDate);
        }
        return true;
    },
};
