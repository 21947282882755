import { InternationalAchTemplateOptions } from '@treasury/domain/channel/types/ach/international';

export const optionsAreAvailable: [] | any = async (record: any, field: string, tries: number) => {
    const options = await record.fieldTypes[field].options(record);
    if (options.length || tries <= 1) return options;
    const newTries = tries - 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    return optionsAreAvailable(record, field, newTries);
};

/**
 *
 * @param field - string name of the field
 * @param record - parent record to be updated
 * @param key - string name of identifier from available options
 * @param matchValue - value to find option using key identifier value
 */
export const setValueFromOptions = async (
    field: string,
    record: any,
    key: string,
    matchValue: string | number
) => {
    const options = await record.fieldTypes[field].options(record);
    const selectedOption = options.find((item: any) => item[key] === matchValue) || matchValue;
    await record.setField(
        field,
        typeof selectedOption === 'string' ? selectedOption : selectedOption?.value
    );
    return selectedOption;
};

export const setValueFromCascadingValues = (
    field: string,
    record: any,
    lookup:
        | 'countries'
        | 'internationalTransactionTypes'
        | 'bankIdTypes'
        | 'currencies'
        | 'foreignExchangeIndicators'
        | 'foreignExchangeReferenceIndicators'
        | 'states',
    key: string,
    matchValue: string | number,
    iatAchOptions: InternationalAchTemplateOptions,
    valueKey: string
) => {
    const options: any[] = iatAchOptions.values[lookup];
    const selectedOption = options?.find((option: any) => option[key] === matchValue);
    record.setField(field, valueKey ? selectedOption[valueKey] : selectedOption);
    return selectedOption;
};

export const getValueFromCascadingValues = (
    lookup:
        | 'countries'
        | 'internationalTransactionTypes'
        | 'bankIdTypes'
        | 'currencies'
        | 'foreignExchangeIndicators'
        | 'foreignExchangeReferenceIndicators'
        | 'states',
    key: string,
    matchValue: string | number,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    const options: any[] = iatAchOptions.values[lookup];
    return options?.find((option: any) => option[key] === matchValue);
};
