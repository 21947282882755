import { ElementPart, noChange } from 'lit';
import { Directive, DirectiveParameters, PartInfo, PartType, directive } from 'lit/directive.js';

interface StoredElement {
    element: Element;
    parent: ParentNode | null;
    nextSibling: Node | null;
}

interface Host extends HTMLElement {
    _storedElements?: Map<Element, StoredElement>;
}

class TmIfDirective extends Directive {
    constructor(partInfo: PartInfo) {
        super(partInfo);
        if (partInfo.type !== PartType.ELEMENT) {
            throw new Error(
                // eslint-disable-next-line no-template-curly-in-string
                'The `tmIf` directive must be used as an element directive: <div ${tmIf(condition)}></div>'
            );
        }
    }

    update(part: ElementPart, [condition]: DirectiveParameters<this>): unknown {
        const incomingElement = part.element;
        const host: Host | undefined = part.options?.host as Host;
        if (!host) {
            throw new Error(
                'The `tmIf` directive can only be used in a template with a host element'
            );
        }

        if (condition) {
            // the element should be rendered
            // if the element was previously stored, put it back in the dom
            if (host._storedElements && host._storedElements.has(incomingElement)) {
                const storedElement = host._storedElements.get(incomingElement)!;
                const { element, parent, nextSibling } = storedElement;
                parent?.insertBefore(element, nextSibling);
                host._storedElements.delete(incomingElement);
            }
            // otherwise, do nothing and let it render as usual
        } else {
            // the element should not be rendered, so store it in case it should be rendered later
            if (!host._storedElements) {
                host._storedElements = new Map();
            }

            if (incomingElement.parentNode) {
                host._storedElements.set(incomingElement, {
                    element: incomingElement,
                    parent: incomingElement.parentNode,
                    nextSibling: incomingElement.nextSibling,
                });
            }

            // remove element from the dom so it won't be visible in the inspector or on the page
            incomingElement.remove();
        }

        // this changes the dom imperatively, so we don't want lit to re-render
        return noChange;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(_condition: unknown) {
        return noChange;
    }
}

/**
 * Lit directive for removing (storing) and replacing elements in the DOM based on a condition.
 * Intended as an alternative to ternaries in templates, for more readable inline templates.
 */
export const tmIf = directive(TmIfDirective);
