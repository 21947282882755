import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-tooltip.js';
import { string, boolean } from '@treasury/policy/primitives';
import { mix } from 'mixwith';
import { ListeningElementMixin } from '@treasury/omega/components';

class AchSaveAsTemplateDialog extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            paymentHeaderRecord: Object,
            open: Boolean,
            disableSave: Boolean,
            validateTemplateName: Function,
            loading: Boolean,
        };
    }

    constructor() {
        super();
        this.disableSave = true;
    }

    async firstUpdated() {
        const templateName = string.thatIs
            .requiredWhen(() => this.open)
            .with.label('Template Name')
            .thatHas.maxLength(50)
            .as.tag('omega-input');
        const setToZero = boolean.with
            .label('Reset amounts to $0.00 after Processing?')
            .as.tag('omega-checkbox');
        this.paymentHeaderRecord.addField(
            'templateName',
            templateName,
            this.paymentHeaderRecord.getField('name')
        );
        this.paymentHeaderRecord.addField('setToZero', setToZero, false);
        await this.validateTemplate();
        this.listenTo(this.paymentHeaderRecord, 'change', async ({ detail }) => {
            const changedField = detail.field;
            if (changedField === 'templateName') {
                await this.validateTemplate();
            }
        });
    }

    async validateTemplate() {
        this.loading = true;
        const isValid = await this.validateTemplateName(
            this.paymentHeaderRecord.getField('templateName')
        );
        this.updateDisableSave(isValid);
        this.loading = false;
    }

    updateDisableSave(isValid) {
        this.disableSave =
            this.paymentHeaderRecord.hasErrors() ||
            !this.paymentHeaderRecord.hasRequiredValues() ||
            !isValid;
    }

    cancel() {
        this.open = false;
        this.paymentHeaderRecord.setField(
            'templateName',
            this.paymentHeaderRecord.getField('name')
        );
        this.dispatchEvent(new CustomEvent('close'));
    }

    save() {
        this.dispatchEvent(new CustomEvent('save'));
        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderSaveButton() {
        if (this.disableSave) {
            const message = this.paymentHeaderRecord.hasRequiredValues()
                ? 'A template with this name already exists, please enter a unique name.'
                : 'Template name is required.';
            return html`<omega-tooltip light slot="actions" .message=${message}
                ><omega-button
                    id="save-template"
                    type="primary"
                    class="save"
                    icon="exclamation-triangle"
                    .width=${'150px'}
                    .disabled=${this.disableSave || this.loading}
                    .loading=${this.loading}
                    @click=${() => {
                        this.save();
                    }}
                    >Save as Template</omega-button
                ></omega-tooltip
            >`;
        }
        return html`<omega-button
            id="save-template"
            class="save"
            slot="actions"
            type="primary"
            .width=${'150px'}
            .disabled=${this.loading}
            .loading=${this.loading}
            @click=${() => {
                this.save();
            }}
            >Save as Template</omega-button
        >`;
    }

    render() {
        if (!this.paymentHeaderRecord) return nothing;
        return html`<omega-dialog
            id="save-as-template-dialog"
            .open=${this.open}
            .dialogTitle=${'Confirm Save as Template'}
            @close=${() => {
                this.cancel();
            }}
        >
            <div slot="content">
                <omega-field field="templateName" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field field="setToZero" .record=${this.paymentHeaderRecord}></omega-field>
            </div>
            ${this.renderSaveButton()}
            <omega-button
                id="cancel-save-template"
                class="cancel"
                slot="actions"
                type="secondary"
                @click=${() => {
                    this.cancel();
                }}
                >Cancel</omega-button
            ></omega-dialog
        >`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            div {
                padding: 10px;
            }
            omega-field {
                margin: 10px;
            }
        `;
    }
}

customElements.define('ach-save-as-template-dialog', AchSaveAsTemplateDialog);
