import { html, nothing } from 'lit';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import { FieldType } from '@treasury/FDL';
import { IssuedItemSaved } from '@treasury/domain/channel/mappings/arp';
import { reviewStepFields } from './review-step.data';
import { issuedItemCreationColumns } from '../definitions';

type IssuedItemStatus = IssuedItemSaved['status'];

const issuedItemSavedFields: FdlFieldDefinitions<IssuedItemSaved> = {
    status: new FieldType<IssuedItemStatus>().thatIs
        .readOnly()
        .with.template<IssuedItemStatus, IssuedItemSaved>((status, record) => {
            const { hasFailed } = record.values;
            const icon = hasFailed
                ? html`<omega-icon
                      icon="warning"
                      style="padding: 5px; color: var(--omega-error)"
                  ></omega-icon>`
                : nothing;
            const statusMsg = hasFailed ? 'Failed' : status;
            return html`${statusMsg}${icon}`;
        }),
};

export const confirmStepFields: FdlFieldDefinitions<IssuedItemSaved> = {
    ...(reviewStepFields as FdlFieldDefinitions<IssuedItemSaved>),
    ...issuedItemSavedFields,
};

export const confirmStepColumns: OmegaColumnDefinition<IssuedItemSaved>[] = [
    ...issuedItemCreationColumns,
    {
        field: 'status',
        label: 'Status',
    },
];
