import { LoanPaymentOptionTypeEnumDto } from '@treasury/api/channel';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';

/**
 * @typedef { import('@jack-henry/frontend-utils/di').DiContainer} DiContainer
 * @typedef { import('@treasury/api/channel').InternalTransferTransactionModelDto } InternalTransferTransactionModelDto
 * @typedef { import('@treasury/api/channel').InternalTransferAddTransactionModelDto } InternalTransferAddTransactionModelDto
 * @typedef { import('@treasury/api/channel').InternalTransferBatchModelDto } InternalTransferBatchModelDto
 */

/**
 * @param { ng.resource.IResourceService} $resource
 * @param { DiContainer } TmDi
 */
export function InternalTransfersService($resource, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}internalTransfers/`;

    let selectedTemplates = [];

    return {
        save,
        getTransaction,
        getTransferList,
        getLoanPaymentsList,
        getRecurringTransfersList,
        approveTransfer,
        rejectTransfer,
        cancelTransfer,
        updateTransfer,
        batchApprove,
        batchReject,
        getTransferTemplateList,
        approveOrRejectTransferTemplate,
        getTransferTemplate,
        saveTransferTemplate,
        updateTransferTemplate,
        setSelectedTemplates,
        getSelectedTemplates,
        getTransferTemplateDetails,
        deleteTransferTemplate,
        validateTemplateName,
    };

    function setSelectedTemplates(templates) {
        selectedTemplates = [];
        if (Array.isArray(templates)) {
            selectedTemplates = templates;
        } else {
            selectedTemplates.push(templates);
        }
    }

    function getSelectedTemplates() {
        return selectedTemplates;
    }

    /**
     * @param {InternalTransferAddTransactionModelDto } model
     * @param {'loan' | 'transfer'} type
     * @returns { Promise<InternalTransferTransactionModelDto>}
     */
    function save(model, type = 'transfer') {
        /**
         * @type { Promise<InternalTransferTransactionModelDto> }
         */
        const p = $resource(`${resourceUrl}?type=add`).save(model).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([response, analytics]) => {
            if (type === 'loan') {
                trackLoan(model, analytics);
            } else {
                trackTransfer(response, analytics);
            }
        });

        return p;
    }

    /**
     *
     * @param { import('@treasury/api/channel').InternalTransferAddTransactionModelDto} model
     */
    function saveTransferTemplate(model) {
        const { type } = model;
        /**
         * @type { Promise<import('@treasury/api/channel').InternalTransferAddTransactionModelDto>}
         */
        const p = $resource(`${resourceUrl}saveTransferTemplate/?type=:type`, {
            type,
        }).save(model).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([, analytics]) => {
            const transaction = model.transactions[0];
            const { amount, frequency } = transaction;
            const frequencyType = frequency.type;

            analytics.track(AnalyticsEvent.TransferTemplateCreated, {
                type,
                amount,
                frequencyType,
            });
        });

        return p;
    }

    function updateTransferTemplate(model) {
        return $resource(
            `${resourceUrl}updateTransferTemplate`,
            {},
            { update: { method: 'PUT' } }
        ).update(model).$promise;
    }
    function updateTransfer(transferMessage) {
        return $resource(
            `${resourceUrl}:id`,
            { id: transferMessage.id },
            { update: { method: 'PUT' } }
        ).update(transferMessage).$promise;
    }
    function getTransaction(id) {
        return $resource(`${resourceUrl}?id=:id`, { id }).get().$promise;
    }

    function getTransferList(filter) {
        return $resource(`${resourceUrl}FilterTransfers`, null, {
            get: { method: 'POST', isArray: true },
        }).get(filter).$promise;
    }

    function getLoanPaymentsList(filter) {
        return $resource(`${resourceUrl}getLoanPayments`, null, {
            get: { method: 'POST', isArray: true },
        }).get(filter).$promise;
    }

    function getRecurringTransfersList(filter) {
        return $resource(`${resourceUrl}getRecurringTransfers`, null, {
            get: { method: 'POST', isArray: true },
        }).get(filter).$promise;
    }

    /**
     * @param { InternalTransferTransactionModelDto} transfer
     * @param { 'transfer' | 'loan' } type
     * @returns { Promise<InternalTransferTransactionModelDto>}
     */
    function approveTransfer(transfer, type = 'transfer') {
        const { id } = transfer;
        /**
         * @type { Promise<InternalTransferTransactionModelDto> }
         */
        const p = $resource(`${resourceUrl}:id/approve`, { id }).save(transfer).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([, analytics]) => {
            const event =
                type === 'loan' ? AnalyticsEvent.LoanApproved : AnalyticsEvent.TransferApproved;
            analytics.track(event, {
                id: id.toString(),
            });
        });

        return p;
    }

    function rejectTransfer(transfer) {
        return $resource(`${resourceUrl}:id/reject`, { id: transfer.id }).save(transfer).$promise;
    }

    function cancelTransfer(cancelTransferModel) {
        return $resource(`${resourceUrl}cancel`).save(cancelTransferModel).$promise;
    }

    /**
     *
     * @param { InternalTransferBatchModelDto } batchData
     * @param { 'transfer' | 'loan' } type
     *
     * @returns { Promise<InternalTransferTransactionModelDto>}
     */
    function batchApprove(batchData, type = 'transfer') {
        /**
         * @type { Promise<InternalTransferTransactionModelDto> }
         */
        const p = $resource(`${resourceUrl}batchApprove`).save(batchData).$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([, analytics]) => {
            const event =
                type === 'loan' ? AnalyticsEvent.LoanApproved : AnalyticsEvent.TransferApproved;

            batchData.internalTransfers.forEach(({ id }) => {
                analytics.track(event, {
                    id: id.toString(),
                });
            });
        });

        return p;
    }

    function batchReject(batchData) {
        return $resource(`${resourceUrl}batchReject`).save(batchData).$promise;
    }
    function getTransferTemplateList(filter) {
        return $resource(`${resourceUrl}getTransferTemplates`, null, {
            get: { method: 'POST', isArray: true },
        }).get(filter).$promise;
    }

    /**
     *
     * @param { 'Approve' | 'Reject'} type
     * @param { string } id
     */
    function approveOrRejectTransferTemplate(type, id) {
        /**
         * @type { Promise<import('@treasury/api/channel').InternalTransTemplateModelDto>}
         */
        const p = $resource(`${resourceUrl}:id/approverejecttemplate`, { id, type }).save()
            .$promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([, analytics]) => {
            const event =
                type === 'Approve'
                    ? AnalyticsEvent.TransferTemplateApproved
                    : AnalyticsEvent.TransferTemplateRejected;
            analytics.track(event, { id });
        });

        return p;
    }

    function deleteTransferTemplate(id) {
        return $resource(`${resourceUrl}:id/deletetemplate`, { id }).save().$promise;
    }

    function getTransferTemplate(id) {
        return $resource(`${resourceUrl}getTemplateById/:id`, { id }).get().$promise;
    }

    function getTransferTemplateDetails(id, type) {
        return $resource(`${resourceUrl}getTransferTemplateDetails/:id`, {
            id,
            type,
        }).get().$promise;
    }

    function validateTemplateName(name) {
        return $resource(`${resourceUrl}validateTemplateName/`, { templateName: name }).get()
            .$promise;
    }
}

InternalTransfersService.$inject = ['$resource', 'TmDi'];

/**
 *
 * @param { InternalTransferTransactionModelDto } response
 * @param { AnalyticsService } analytics
 */
function trackTransfer(response, analytics) {
    const { amount, transactionId, status, startDate, endDate, frequency } = response;
    analytics.track(AnalyticsEvent.TransferCreated, {
        amount,
        transactionId,
        status,
        startDate,
        endDate,
        frequency,
    });
}

/**
 * @param { InternalTransferAddTransactionModelDto } payload
 * @param { AnalyticsService } analytics
 */
function trackLoan(payload, analytics) {
    const { transactions } = payload;

    if (!transactions) {
        return;
    }

    transactions.forEach(t => {
        const { amount, paymentOptionType } = t;
        let type;

        switch (+paymentOptionType) {
            case LoanPaymentOptionTypeEnumDto.RegularPayment:
                type = 'regular';
                break;
            case LoanPaymentOptionTypeEnumDto.PrincipalOnly:
                type = 'principal';
                break;
            case LoanPaymentOptionTypeEnumDto.Undefined:
            default:
                type = null;
                break;
        }

        if (!type) {
            return;
        }

        analytics.track(AnalyticsEvent.LoanPayment, {
            amount,
            type,
        });
    });
}
