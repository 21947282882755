import {
    PaymentHeader,
    Workflow,
    WorkflowActions,
    WorkflowTypeEntitlements,
    WorkflowTypes,
} from '@treasury/domain/channel/types/ach';
import { AchDomesticContainer } from '../../containers/ach-domestic-container';
import { WorkflowTypeConfiguration } from '../container-configuration';
import {} from './entitlementFactory';
import { StepFactory } from './stepsFactory';

export class WorkflowConfigurationFactory {
    constructor(
        context: AchDomesticContainer,
        payment: PaymentHeader,
        entitlements: WorkflowTypeEntitlements
    ) {
        this.context = context;
        this.payment = payment;
        this.entitlements = entitlements;
        this.workflow = this.context.workflow;
        this.stepFactory = new StepFactory(context, payment, entitlements);
    }

    stepFactory: StepFactory;

    entitlements: WorkflowTypeEntitlements;

    workflow: Workflow;

    context: AchDomesticContainer;

    payment: PaymentHeader;

    private get paymentWorkflow(): WorkflowTypeConfiguration {
        switch (this.workflow.action) {
            case WorkflowActions.View:
                return {
                    startStep: 3,
                    title: `Payment Detail - ${this.payment.transactionId}`,
                    steps: this.stepFactory.steps,
                };
            case WorkflowActions.Edit:
                return {
                    title: `Edit ACH Payment - ${this.payment.transactionId}`,
                    startStep: 0,
                    steps: this.stepFactory.steps,
                };
            case WorkflowActions.InitiateBatchFromTemplates:
            case WorkflowActions.InitiateBatchFromFile:
                return {
                    title: 'Create ACH Payment',
                    startStep: 1,
                    steps: this.stepFactory.steps,
                };
            case WorkflowActions.InitiateFromTemplate:
            case WorkflowActions.InitiateFromFile:
            case WorkflowActions.Create:
            default:
                return {
                    title: 'Create ACH Payment',
                    startStep: 0,
                    steps: this.stepFactory.steps,
                };
        }
    }

    public get configuration(): WorkflowTypeConfiguration {
        switch (this.workflow.type) {
            case WorkflowTypes.Template:
            case WorkflowTypes.Payment:
                return this.paymentWorkflow;
            default:
                return {
                    title: 'Create ACH Payment',
                    startStep: 0,
                    steps: this.stepFactory.steps,
                };
        }
    }
}
