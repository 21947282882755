/* eslint-disable import/extensions */
/* eslint-disable prefer-destructuring */
import { FieldType, Record } from '@treasury/FDL';
import '@treasury/policy/primitives';
import './omega-input.js';
// eslint-disable-next-line import/extensions
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ListeningElementMixin } from './listening-element';

const ListeningElement = ListeningElementMixin(LitElement);

class OmegaRange extends ListeningElement {
    static get properties() {
        return {
            label: String,
            options: Array,
            values: Array,
            fieldType: Object,
            disabled: { type: Boolean, reflect: true },
            segmented: { type: Boolean, reflect: true },
            field: Object,
        };
    }

    constructor() {
        super();
        this.label = '';
        this.options = [
            { text: 'Amount Range', value: 'range' },
            { text: 'Specific Amount', value: 'specific' },
        ];
        this.values = ['range', '', ''];
        this.fieldType = new FieldType();
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-range--default',
        };
    }

    firstUpdated() {
        this.record = new Record(
            {
                type: this.fieldType,
                start: this.fieldType,
                end: this.fieldType.with.validator({
                    name: 'start-less-than-end',
                    validate: (v, model, record) => {
                        const start = record.getField('start');
                        const end = record.getField('end');
                        const type = record.getField('type');
                        if (!Number(start) && !Number(end)) return true;
                        if (type.toLowerCase().includes('range'))
                            return Number(start) < Number(end);
                        return true;
                    },
                }),
            },
            {
                type: this.values[0],
                start: this.values[1],
                end: this.values[2] || '',
            }
        );

        if (this.field) {
            this.field.record.onChange(() => {
                this.values = this.field.record.getField(this.field.fieldName);
                this.isChanging = true;
                this.record.setField('type', this.values[0]);
                this.record.setField('start', this.values[1]);
                this.record.setField('end', this.values[2] ?? '');
                this.isChanging = false;
                this.setInputState();
                this.requestUpdate();
            });
            this.setInputState();
            this.requestUpdate();
        }

        this.listenTo(this.record, 'change', () => {
            if (this.isChanging) return;
            this.values[0] = this.record.getField('type');
            this.values[1] = this.record.getField('start');
            this.values[2] = this.record.getField('end');
            this.dispatchEvent(
                new CustomEvent('change', {
                    detail: {
                        values: [
                            this.record.getField('type'),
                            this.record.getField('start'),
                            this.record.getField('end'),
                        ],
                    },
                })
            );
            this.setInputState();
            this.requestUpdate();
        });
        this.setInputState();
        this.requestUpdate();
    }

    setInputState() {
        const inputs = this.shadowRoot.querySelectorAll('omega-input');
        if (inputs) {
            inputs.forEach(input => {
                input.touched = true;
                input.setNativeInputInvalidState();
            });
        }
    }

    isRange() {
        return this.record.getField('type')?.toLowerCase()?.includes('range');
    }

    isLimit() {
        return this.record.getField('type')?.toLowerCase()?.includes('limit');
    }

    isSpecific() {
        return this.record.getField('type')?.toLowerCase()?.includes('specific');
    }

    _updateRangeSelection(e) {
        this.record.setField('type', e.target.value);
        this.record.setField('start', '');
        this.record.setField('end', '');
    }

    _handleToggleClick(val) {
        this.record.setField('type', val);
        this.record.setField('start', '');
        this.record.setField('end', '');
        this.requestUpdate();
    }

    renderInputs() {
        if (this.isRange()) {
            return html`<div class="inputs-wrapper">
                <omega-input
                    label="Range Start"
                    placeholder="Range start"
                    .field=${this.record.field('start')}
                    .minLength=${this.fieldType.minLength()}
                    .maxLength=${this.fieldType.maxLength()}
                    ?disabled=${this.disabled}
                    .selectOnFocus=${this.fieldType?.selectOnFocus()}
                ></omega-input>
                <p style="margin-right:2px;margin-top:5px;margin-bottom:0;">to</p>
                <omega-input
                    label="Range End"
                    placeholder="Range end"
                    .field=${this.record.field('end')}
                    .minLength=${this.fieldType.minLength()}
                    .maxLength=${this.fieldType.maxLength()}
                    ?disabled=${this.disabled}
                    .selectOnFocus=${this.fieldType?.selectOnFocus()}
                ></omega-input>
            </div>`;
        }
        if (this.isLimit()) {
            this.record.setField('start', '');
            return html`<div class="inputs-wrapper">
                <omega-input
                    label="Limit"
                    placeholder="Enter limit amount"
                    .field=${this.record.field('end')}
                    .maxLength=${this.fieldType.maxLength()}
                    ?disabled=${this.disabled}
                    .selectOnFocus=${this.fieldType?.selectOnFocus()}
                    .minLength=${this.fieldType.minLength()}
                ></omega-input>
            </div>`;
        }
        if (this.isSpecific()) {
            return html`<div class="inputs-wrapper">
                <omega-input
                    label="Specific Value"
                    placeholder="Enter a specific amount"
                    .field=${this.record.field('start')}
                    .maxLength=${this.fieldType.maxLength()}
                    ?disabled=${this.disabled}
                    .selectOnFocus=${this.fieldType?.selectOnFocus()}
                    .minLength=${this.fieldType.minLength()}
                ></omega-input>
            </div>`;
        }
        return nothing;
    }

    renderSelect() {
        return html`
            <omega-select
                placeholder=""
                .items=${this.options}
                .value=${this.record.getField('type')}
                @change=${this._updateRangeSelection}
                ?disabled=${this.disabled}
            ></omega-select>
        `;
    }

    renderToggle() {
        const buttons = this.options.map(
            option =>
                html`
                    <button
                        class=${classMap({
                            'toggle-btn': true,
                            active: option.value === this.record.getField('type'),
                        })}
                        @click=${() => this._handleToggleClick(option.value)}
                        role="tab"
                    >
                        ${option.text}
                    </button>
                `
        );
        return html` <div role="tablist" class="toggle-btn-wrapper">${buttons}</div> `;
    }

    render() {
        if (!this.record) return nothing;
        const selectionType = this.segmented ? this.renderToggle() : this.renderSelect();
        return html`
            <label for="omega-range">${this.label}</label>
            <div id="omega-range">${selectionType} ${this.renderInputs()}</div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            label {
                font-size: var(--omega-label);
            }
            .inputs-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 5px;
            }
            .inputs-wrapper omega-input {
                flex-grow: 1;
            }
            .inputs-wrapper omega-input:not(:last-child) {
                margin-right: 5px;
            }
            .toggle-btn-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 35em;
            }
            .toggle-btn {
                font-family: var(--omega-font);
                font-size: 13px;
                background-color: #fff;
                border: 1px solid #dfe1e8;
                border-radius: 6px;
                flex: 1;
                padding: 6px;
                max-height: 30px;
            }
            .toggle-btn.active {
                background-color: #dceafb;
                border-color: var(--omega-primary-lighten-200);
                color: var(--omega-primary);
                font-weight: bold;
                margin-left: -6px;
                margin-right: -6px;
                height: 32px;
                max-height: 32px;
                z-index: 2;
            }
            .toggle-btn:not(.active) {
                padding: 6px;
            }
            .toggle-btn:focus {
                outline: none;
            }
        `;
    }
}

customElements.define('omega-range', OmegaRange);
export default OmegaRange;
