import { FrequencyTypeKeys } from '@treasury/domain/channel/types/frequency';
import { FrequencyTypeLabels } from './OmegaFrequencyType.type';

export type FrequencyForSelect = {
    text: string; // /Needs to be specific labels
    value: FrequencyTypeKeys;
};

export const getFrequencyTypesForSelect = (
    types: Array<FrequencyTypeKeys>
): Array<FrequencyForSelect> =>
    types.map(type => ({
        text: FrequencyTypeLabels[type],
        value: type,
    }));

export const DefaultFrequenciesForSelect = getFrequencyTypesForSelect([
    'OneTime',
    'Weekly',
    'EveryTwoWeeks',
    'TwiceAMonth',
    'Monthly',
    'Quarterly',
    'EverySixMonths',
    'Yearly',
]);
