import { getAllAchBanks, resetBanks } from '@treasury/domain/channel/requests/ach/bank-service.js';

export default function AchBankService() {
    return {
        reset,
        getAllBanks,
    };

    function reset() {
        return resetBanks();
    }

    function getAllBanks() {
        return getAllAchBanks();
    }
}
