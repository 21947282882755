AccessController.$inject = ['$scope', 'usersService', '$filter', 'toaster', 'securityService'];

export default function AccessController($scope, usersService, $filter, toaster, securityService) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.selectAllCbox = {};
    $scope.accountAccess = {};
    $scope.accountAccessPristine = {};

    $scope.reset = reset;
    $scope.save = save;
    $scope.selectAll = selectAll;
    $scope.setCheckbox = setCheckbox;
    $scope.setForm = setForm;
    $scope.filterAccounts = filterAccounts;
    $scope.filter = { filterText: null };

    function init() {
        loadAccountAccess();
    }

    function save() {
        const updatedAccounts = {
            addedAccounts: [],
            removedAccounts: [],
            securityMessage: null,
        };

        angular.forEach($scope.accountAccess.accounts, pending => {
            angular.forEach($scope.accountAccessPristine.accounts, pristine => {
                if (pending.companyAccount.id === pristine.companyAccount.id) {
                    if (pending.hasPendingUserAccess && !pristine.hasPendingUserAccess) {
                        updatedAccounts.addedAccounts.push(pending);
                    } else if (!pending.hasPendingUserAccess && pristine.hasPendingUserAccess) {
                        updatedAccounts.removedAccounts.push(pending);
                    }
                }
            });
        });

        if (
            updatedAccounts.addedAccounts.length > 0 ||
            updatedAccounts.removedAccounts.length > 0
        ) {
            securityService
                .verifyUser('Edit User', updatedAccounts, () =>
                    usersService.updateUserAccountAccess($scope.id, updatedAccounts)
                )
                .then(response => {
                    if (response.success) {
                        $scope.accountAccessPristine = window.angular.copy($scope.accountAccess);
                        $scope.$parent.notifySave();
                        $scope.form.$setPristine();
                        toaster.save('Account Access');
                    }
                });
        }
    }

    function selectAll() {
        window.angular.forEach($scope.filteredAccounts, account => {
            account.hasPendingUserAccess = $scope.selectAllCbox.value;
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function setCheckbox() {
        updateSelectAllCheckboxStatus();
    }

    function reset() {
        $scope.accountAccess = angular.copy($scope.accountAccessPristine);
        $scope.filteredAccounts = $scope.accountAccess.accounts;
        $scope.form.$setPristine();
    }

    // Private
    function loadAccountAccess() {
        usersService.getUserAccountAccess($scope.id, false).then(response => {
            $scope.accountAccess = response;
            $scope.accountAccessPristine = angular.copy($scope.accountAccess);
            $scope.filteredAccounts = $scope.accountAccess.accounts;
            updateSelectAllCheckboxStatus();
        });
    }

    function updateSelectAllCheckboxStatus() {
        $scope.selectAllCbox.value = true;
        angular.forEach($scope.filteredAccounts, account => {
            if (account.hasPendingUserAccess === false) {
                $scope.selectAllCbox.value = false;
            }
        });
    }

    function filterAccounts() {
        if ($scope.filter.filterText) {
            $scope.filteredAccounts = $filter('filter')(
                $scope.accountAccess.accounts,
                $scope.filter.filterText
            );
        } else {
            $scope.filteredAccounts = $scope.accountAccess.accounts;
        }
        updateSelectAllCheckboxStatus();
    }

    init();
}
