/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ErrorHttpContentDto, InstitutionOptionModelCollectionDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class PositivePayInstitutionConfigurationClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * @description Supported options: * PositivePay.ACHFilterManagement.AllowManageAchFilters : "0"/"1", indicates whether ACH Filters are allowed to be managed. * PositivePay.ACHFilterManagement.ApproversRequired : 0 (aka "None"), 1, 2, 3. * PositivePay.AllowIssuedItemActivityReview : "0"/"1" * PositivePay.SubstituteProductConfig.AllowSubstituteProduct : "0"/"1" .  Will only be returned if substitute product is installed and active. * PositivePay.SubstituteProductConfig.SsoClientId : Only present if substitute product is installed and active. * PositivePay.SubstituteProductConfig.SsoLandingPageUrl : (readonly) Only present if substitute product is installed and active. * PositivePay.SubstituteProductConfig.SsoProductName : (readonly) Only present if substitute product is installed and active. * PositivePay.ShowAchExceptions : "0"/"1" * PositivePay.WorkAchExceptions : "1"/"0". * PositivePay.WorkCheckExceptions : "1"/"0". * PositivePay.CheckException.AllowReturnReason : "1"/"0". * PositivePay.CheckException.AllowCorrectionRequest : "1"/"0".
     *
     * @tags PositivePayInstitutionConfiguration
     * @name PositivePayInstitutionConfigurationGetAllOptions
     * @summary Retrieve all product options at financial institution for Positive Pay
     * @request GET:/positivePayInstitutionConfiguration/allOptions
     */
    positivePayInstitutionConfigurationGetAllOptions = (params: RequestParams = {}) =>
        this.http.request<InstitutionOptionModelCollectionDto, ErrorHttpContentDto>({
            path: `/positivePayInstitutionConfiguration/allOptions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Supported options for save: * PositivePay.ACHFilterManagement.AllowManageAchFilters : "0"/"1", indicates whether ACH Filters are allowed to be managed. * PositivePay.ACHFilterManagement.ApproversRequired : 0 (aka "None"), 1, 2, 3 * PositivePay.AllowIssuedItemActivityReview : "0"/"1" * PositivePay.SubstituteProductConfig.AllowSubstituteProduct : "0"/"1".  Should only be passed if substitute product is installed and active (i.e. option returned by the 'GET'). * PositivePay.SubstituteProductConfig.SsoClientId : Should only be passed if substitute product is installed and active (i.e. option returned by the 'GET'). * PositivePay.ShowAchExceptions : "0"/"1" * PositivePay.WorkAchExceptions : "1"/"0". * PositivePay.WorkCheckExceptions : "1"/"0". * PositivePay.CheckException.AllowReturnReason : "1"/"0". * PositivePay.CheckException.AllowCorrectionRequest : "1"/"0".
     *
     * @tags PositivePayInstitutionConfiguration
     * @name PositivePayInstitutionConfigurationSaveOptions
     * @summary Saves product options for financial institution Positive Pay
     * @request POST:/positivePayInstitutionConfiguration/options
     */
    positivePayInstitutionConfigurationSaveOptions = (
        request: InstitutionOptionModelCollectionDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/positivePayInstitutionConfiguration/options`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
