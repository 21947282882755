/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { DownloadPaymentResponseDto, GetAchPaymentResponseDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchPaymentsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchPayment
     * @name AchPaymentGet
     * @summary Returns ach payment information
     * @request GET:/achPayments/{achPaymentUniqueId}
     */
    achPaymentGet = (achPaymentUniqueId: string, params: RequestParams = {}) =>
        this.http.request<GetAchPaymentResponseDto, any>({
            path: `/achPayments/${achPaymentUniqueId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayment
     * @name AchPaymentDownloadAchPayment
     * @summary Download ach payment details.
     * @request GET:/achPayments/{achPaymentUniqueId}/download
     */
    achPaymentDownloadAchPayment = (
        achPaymentUniqueId: string,
        query: {
            /** output type to download payment */
            outputType: string | null;
            /**
             * returns landscape pdf document when enabled
             * @default false
             */
            isLandscape?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DownloadPaymentResponseDto, any>({
            path: `/achPayments/${achPaymentUniqueId}/download`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
