import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ChannelAuthenticationService } from '@treasury/domain/channel/services';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { UsersService } from '@treasury/domain/users';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './components/user-verification.js';

export const userEnrollmentContainerTagName = 'user-enrollment-container';

@customElement(userEnrollmentContainerTagName)
export class SsoContainer extends LitElement {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare authService: AuthenticationService;

    @InjectProperty()
    private declare usersService: UsersService;

    @InjectProperty()
    private declare channelAuthService: ChannelAuthenticationService;

    @property({ type: String })
    public institutionId!: string;

    @state()
    private errorMessage?: string;

    @state()
    private loading = false;

    async enroll(companyId: string, username: string) {
        try {
            this.loading = true;
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');

            const hasUserInfo = companyId && username && this.institutionId && code;

            if (hasUserInfo) {
                const { uisEnrollmentLink } = await this.usersService.getUisEnrollmentLink(
                    this.institutionId,
                    companyId,
                    code ?? '',
                    username
                );
                if (uisEnrollmentLink) window.location.href = uisEnrollmentLink;
            } else {
                throw new Error(
                    'No enrollment code was provided. Please contact customer support and ensure your enrollment link is correct.'
                );
            }
        } catch (e) {
            this.errorMessage =
                e instanceof Error ? e.message : 'Enrollment failed. Please try again.';
        } finally {
            this.loading = false;
        }
    }

    renderErrorMessage() {
        if (!this.errorMessage) return nothing;
        return html`
            <div class="error">
                <omega-icon icon="times-circle"></omega-icon>
                ${this.errorMessage}
            </div>
        `;
    }

    protected render() {
        // TODO: Clean this code up to not rely on omega field
        return html`
            <div class="card">
                <div class="card-header">
                    <h1>Login</h1>
                </div>
                ${this.errorMessage ? this.renderErrorMessage() : nothing}
                <user-verification
                    caption="Input your Treasury Company ID and Treasury User ID to begin the enrollment process. 
                    You will be prompted to complete profile details, as well as select a user name and password."
                    .loading=${this.loading}
                    @submit=${(e: any) =>
                        this.enroll(e.detail.formValues.companyId, e.detail.formValues.loginId)}
                ></user-verification>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    --omega-primary: var(--brand-color);
                }

                h1 {
                    font-size: 20px;
                    font-weight: 600;
                    margin: 0;
                }
                .card {
                    position: relative;
                    background: #fff;
                    box-shadow: 0 0 8px rgb(0 0 0 / 40%);
                    width: clamp(300px, 100%, var(--login-card-max-width, 600px));
                }
                .card-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 52px;
                    padding: 0 15px;
                    border-bottom: 1px solid #ccc;
                }
                .error {
                    border: 1px solid #ee3a3b;
                    border-left: 4px solid #ee3a3b;
                    padding: 7px 15px;
                    margin: 15px;
                    display: flex;
                    align-items: center;
                }
                omega-icon {
                    margin-right: 16px;
                    color: #ee3a3b;
                    font-size: 24px;
                }
            `,
        ];
    }
}
