RecipientValidatorService.$inject = ['regexConstants'];

export default function RecipientValidatorService(regexConstants) {
    function specialChars(item) {
        return regexConstants.AlphaNumericPlusSpecialCharsRegex.test(item);
    }

    function maxLength(item, n) {
        return item.length <= n;
    }

    function minLength(item, n) {
        return item.length >= n;
    }

    return function createRecipientValidator(allRecipients, achCompany) {
        function isRecipientValidForAchCompany(recipient) {
            const validName =
                !!recipient.name && specialChars(recipient.name) && maxLength(recipient.name, 25);
            const validId =
                !recipient.idNumber ||
                (specialChars(recipient.idNumber) && maxLength(recipient.idNumber, 15));
            const validAccountNumber =
                recipient.accountNumber &&
                specialChars(recipient.accountNumber) &&
                maxLength(recipient.accountNumber, 26);
            const validRoutingNumber =
                recipient.routingNumber && recipient.routingNumber.length === 9;
            const amountInRange =
                recipient.amount >= recipient.minAmount && recipient.amount <= 9999999999999999.99;
            const validAmount = recipient.prenote || amountInRange;
            return (
                validName &&
                validId &&
                validAccountNumber &&
                recipient.accountType &&
                validRoutingNumber &&
                recipient.transactionType &&
                validAmount &&
                isRecipientTransactionTypeValidForAchCompany(recipient)
            );
        }

        function isRecipientInvalidForAchCompany(recipient) {
            return !isRecipientValidForAchCompany(recipient);
        }

        function errors() {
            return allRecipients.filter(recipient => isRecipientInvalidForAchCompany(recipient));
        }

        function countErrors() {
            return errors().length;
        }

        function nextErrorIndex(startIndex) {
            let nextIndex = -1;
            let foundNextIndex = false;
            allRecipients.forEach((recipient, index) => {
                if (foundNextIndex) return;
                if (isRecipientInvalidForAchCompany(recipient)) {
                    if (nextIndex === -1) {
                        nextIndex = index;
                    }
                    if (index > startIndex) {
                        foundNextIndex = true;
                        nextIndex = index;
                    }
                }
            });

            return nextIndex;
        }

        function isRecipientTransactionTypeValidForAchCompany(recipient) {
            // The goal is to determine if each recipient is valid.

            //check to see if company has on us access management
            if (achCompany?.isOnUsAccessManagement) {
                let allowedTransactionType = achCompany?.notOnUsTransactionTypes;
                if (allowedTransactionType === 'Debit Only') allowedTransactionType = 'DR';
                if (allowedTransactionType === 'Credit Only') allowedTransactionType = 'CR';
                if (recipient.transactionType !== allowedTransactionType) return false;
            }

            if (
                // Unbalanced: the debits and credits don't balance each other out
                // Full offset: must be ALL debits or ALL credits -- not a mix
                achCompany.batchBalanceRequirements === 'Unbalanced - Full Offset' &&
                // Ignore this recipient if it's a hold (I can't remember what hold means, Sierra knows)
                !recipient.hold &&
                // If this recipient is not the same type as the first recipient
                // (remember they have to be ALL debits or ALL credits)
                recipient.transactionType !== allRecipients[0].transactionType &&
                // If this recipient *is* the first recipient, it's always valid.
                // (This condition is probably redundant to the previous one.)
                recipient.$$hashKey !== allRecipients[0].$$hashKey
            ) {
                // If we got here, it ain't valid.
                return false;
            }

            // Possibly valid. But we still have two more conditions to check!
            if (
                // If the routing number is not "on us", i.e. it's a different bank
                !recipient.isRoutingNumberOnUs &&
                // And the ACH company is configured for debits only
                achCompany.notOnUsTransactionTypes === 'Debit Only' &&
                // This better be a debit, otherwise...
                recipient.transactionType !== 'DR'
            ) {
                // No soup for you!
                return false;
            }

            // Now for the third and final test.
            // It's the same as the second, but applies to ACH companies that are "Credit Only"
            if (
                !recipient.isRoutingNumberOnUs &&
                achCompany.notOnUsTransactionTypes === 'Credit Only' &&
                recipient.transactionType !== 'CR'
            ) {
                // The council has spoken.
                return false;
            }

            // Congratulations. You are a valid recipient!
            return true;
        }

        return {
            errors,
            countErrors,
            nextErrorIndex,
            isRecipientTransactionTypeValidForAchCompany,
        };
    };
}
