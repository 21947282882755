/* eslint-disable @treasury/no-branch-in-template-literal */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable lines-between-class-members */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-file-upload.js';
import '@treasury/omega/components/omega-radio-group.js';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration';

export enum CreationTypes {
    Create = 'create',
    Initiate = 'initiate',
}

export enum PaymentCreationTypes {
    Template = 'template',
    File = 'file',
    Manual = 'manual',
}

export class CreateStep extends ListeningElementMixin(LitElement) {
    constructor() {
        super();
        this.creationOptions = [];
    }

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: String })
    institution!: string;
    @property({ type: Number })
    activeStep!: number;
    @property({ type: Boolean })
    loading: boolean = false;
    @property({ type: String })
    header!: string;
    @property({ type: Object })
    paymentHeaderRecord!: any;
    @property({ type: String })
    creationType!: CreationTypes;
    @property({ type: String })
    workflowType: string = 'payment';
    @property({ type: Array })
    fields!: Array<any>;
    @property({ type: Boolean })
    showFileUpload: boolean = false;
    @property({ type: Object })
    file!: any;
    @property({ type: Boolean })
    isUploading: boolean = false;
    @property({ type: Array })
    creationOptions!: Array<any>;

    paymentCreationType: PaymentCreationTypes = PaymentCreationTypes.Manual;

    connectedCallback() {
        super.connectedCallback();
        // @ts-ignore
        this.listenTo(this.paymentHeaderRecord, 'change', () => {
            // @ts-ignore
            this.requestUpdate();
        });
    }

    firstUpdated() {
        // @ts-ignore
        this.creationOptions = CONTAINER_CONFIGURATION[this.workflowType].creationOptions;
        if (this.creationType === CreationTypes.Initiate) {
            this.creationOptions = [];
        }
    }

    get canContinue() {
        return this.paymentHeaderRecord.isValid() && this.paymentHeaderRecord.hasRequiredValues();
    }

    changePaymentCreationTypes(type: PaymentCreationTypes) {
        this.paymentCreationType = type;
        switch (this.paymentCreationType) {
            case 'template':
                this.showFileUpload = false;
                this.navService.navigate(`payables.ach.payments.child-support-templates`);
                break;
            case 'file':
                // open file
                this.showFileUpload = true;
                break;
            default:
                this.file = null;
                this.showFileUpload = false;
                break;
        }
        // @ts-ignore
        this.dispatchEvent(
            new CustomEvent('changePaymentCreationTypes', { detail: this.paymentCreationType })
        );
    }

    uploadedNachaFile() {
        this.isUploading = true;
        // @ts-ignore
        this.dispatchEvent(new CustomEvent('fileUploaded', { detail: this.file[0] }));
    }

    next() {
        // @ts-ignore
        this.dispatchEvent(new CustomEvent('next'));
    }

    /* istanbul ignore next */

    renderPaymentCreationTypes() {
        return html`<omega-radio-group
            name="payment-creation-type"
            id="payment-creation-type-group"
            orientation="horizontal"
            label=""
            .radios=${this.creationOptions}
            .value=${this.paymentCreationType}
            @change=${(e: any) => this.changePaymentCreationTypes(e.target.value)}
        ></omega-radio-group>`;
    }

    renderFileUpload() {
        if (this.showFileUpload)
            /* istanbul ignore next */
            return html` <br />
                <omega-file-upload
                    validationMessage="Maximum 1000 payments"
                    @filesUploaded=${(e: any) => {
                        this.file = e.detail.files;
                    }}
                    >Upload File</omega-file-upload
                >`;
        return nothing;
    }

    renderField(field: any) {
        return html`<omega-field
            .field=${field.field}
            .record=${this.paymentHeaderRecord}
        ></omega-field>`;
    }

    renderPaymentHeaderFields() {
        /* istanbul ignore next */
        if (!this.paymentHeaderRecord) return nothing;
        const visibleFields = this.fields.filter(field =>
            field.fieldType.visible(this.paymentHeaderRecord)
        );
        const visibleIndex = this.workflowType === 'template' ? 0 : 1;
        const firstColFields = visibleFields
            .slice(0, visibleFields.length - visibleIndex)
            .map(field => this.renderField(field));
        const secondColFields = visibleFields
            .slice(visibleFields.length - visibleIndex, visibleFields.length)
            .map(field => this.renderField(field));
        return html` <div class="omega-flex-form">
            <div class="form-column">${firstColFields}</div>
            <div class="form-column">${secondColFields}</div>
        </div>`;
    }
    renderManualCreation() {
        return html`${this.renderPaymentCreationTypes()}
            <div class="omega-form-header">
                <h2 class="step-title">${this.header}</h2>
                <span><span class="red">*</span> indicates Required field</span>
            </div>
            <hr />
            ${this.renderPaymentHeaderFields()}
            <omega-button-bar position="bottom" alignment="left"
                ><omega-button
                    @click=${() => this.next()}
                    type="primary"
                    class="add-recipients"
                    .disabled=${!this.canContinue}
                    >Add Recipients</omega-button
                >
                <omega-button
                    @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                    type="secondary"
                    class="cancel"
                    >Cancel</omega-button
                ></omega-button-bar
            >`;
    }

    render() {
        if (this.paymentCreationType === PaymentCreationTypes.Manual) {
            return this.renderManualCreation();
        }
        return html`
            ${this.renderPaymentCreationTypes()}
            <div class="omega-form-header">
                <h2 class="step-title">Upload Nacha Formatted File</h2>
            </div>
            <hr />
            ${this.renderFileUpload()}
            <omega-button-bar position="bottom" alignment="left"
                ><omega-button
                    class="upload"
                    @click=${this.uploadedNachaFile}
                    .loading=${this.isUploading}
                    type="primary"
                    .disabled=${!this.file}
                    >Upload</omega-button
                >
                <omega-button
                    @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                    type="secondary"
                    class="cancel"
                    >Cancel</omega-button
                ></omega-button-bar
            >
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-form-header {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .omega-form-header span.red {
                color: var(--omega-text-error);
            }
            .step-title {
                margin: 0;
                font-size: 24px;
                color: var(--omega-text-header);
            }
            hr {
                border: none;
                border-top: var(--omega-default-border);
                margin: 16px -10px;
            }

            .omega-flex-form {
                display: flex;
                height: 100%;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }

            omega-file-upload {
                margin-top: 5px;
                width: 365px;
            }

            omega-field {
                margin: 0 16px 16px;
                min-height: 32px;
            }
        `;
    }
}

// @ts-ignore
customElements.define('create-step', CreateStep);
