/* eslint-disable no-param-reassign */
import {
    AchBank,
    AchBankDto,
    BatchRecipient,
    defaultRecipientValues,
    PaymentHeader,
    PaymentRecipient,
    WorkflowActions,
    WorkflowTypeEntitlements,
    WorkflowTypes,
} from '@treasury/domain/channel/types/ach';
import { FieldType, Record as FdlRecord, Recordset } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { Primitives as fieldTypes } from '@treasury/policy/';
import {
    achAccountNumber,
    achAccountType,
    achBank,
    achIdNumber,
    achPaymentRecipientName,
    achTransactionType,
    addenda,
    testAchCompanyName,
} from '@treasury/policy/ach/ach';
import { exists, getKeys } from '@treasury/utils';
import { html } from 'lit';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import '../parts/AchPaymentNameField';
import {
    balancedValidator,
    creditOnlyTransactionTypeValidator,
    debitOnlyTransactionTypeValidator,
    onUsAmountRequiredValidator,
    onUsTransactionsValidator,
    recipientAmountValidator,
    unbalancedPartialOffsetValidator,
} from '../validators';

/**
 *
 * Business Rule
 */
const isPayment = (type: WorkflowTypes) => type !== WorkflowTypes.Template;
const isEditPaymentFlow = (action: WorkflowActions, type: WorkflowTypes) =>
    type === WorkflowTypes.Payment && action === WorkflowActions.Edit;

const hasPartialPaymentEditEntitlement = (
    entitlements: WorkflowTypeEntitlements,
    action: WorkflowActions,
    type: WorkflowTypes
) =>
    entitlements.edit.partial &&
    !entitlements.edit.full &&
    isPayment(type) &&
    action !== WorkflowActions.Create;
const hasPartialTemplateEditEntitlement = (
    entitlements: WorkflowTypeEntitlements,
    action: WorkflowActions,
    type: WorkflowTypes
) =>
    entitlements.edit.partial &&
    !entitlements.edit.full &&
    !isPayment(type) &&
    action !== WorkflowActions.Create;
const hasNoPaymentEditEntitlement = (
    entitlements: WorkflowTypeEntitlements,
    action: WorkflowActions,
    type: WorkflowTypes
) =>
    !entitlements.edit.partial &&
    !entitlements.edit.full &&
    isPayment(type) &&
    action !== WorkflowActions.Create;
const hasNoTemplateEditEntitlement = (
    entitlements: WorkflowTypeEntitlements,
    action: WorkflowActions,
    type: WorkflowTypes
) =>
    !entitlements.edit.partial &&
    !entitlements.edit.full &&
    !isPayment(type) &&
    action !== WorkflowActions.Create;
const editableStep = (step: number) => step === 0;
const isDetailView = (action: WorkflowActions) => Boolean(action) === false;
export const isReadOnlyStates = (
    record: FdlRecord<PaymentRecipient> | undefined,
    entitlements: WorkflowTypeEntitlements,
    action: WorkflowActions,
    type: WorkflowTypes,
    paymentHeaderRecord: FdlRecord<PaymentHeader>
): boolean => {
    const step = paymentHeaderRecord.getField('step');
    const partialPayment = hasPartialPaymentEditEntitlement(entitlements, action, type);
    const partialTemplate = hasPartialTemplateEditEntitlement(entitlements, action, type);
    const notEditableStep = !editableStep(step);
    const detailView = isDetailView(action);
    const noTemplateEdit = hasNoTemplateEditEntitlement(entitlements, action, type);
    const noPaymentEdit = hasNoPaymentEditEntitlement(entitlements, action, type);
    const nachaUpload = paymentHeaderRecord.getField('nachaFileUpload');

    // eslint-disable-next-line @treasury/max-boolean-operators
    return !!(
        partialPayment ||
        partialTemplate ||
        notEditableStep ||
        detailView ||
        noTemplateEdit ||
        noPaymentEdit ||
        nachaUpload
    );
};

const getRecordEditableField = (record?: FdlRecord<PaymentRecipient>): boolean | undefined =>
    exists(record) ? record.getField('editable') : false;

const validateReadonly = (
    record: FdlRecord<PaymentRecipient>,
    entitlements: WorkflowTypeEntitlements,
    action: WorkflowActions,
    type: WorkflowTypes,
    paymentHeaderRecord: FdlRecord<PaymentHeader>
): boolean => {
    const step = paymentHeaderRecord.getField('step');
    return !!(
        !editableStep(step) ||
        isDetailView(action) ||
        hasNoTemplateEditEntitlement(entitlements, action, type) ||
        hasNoPaymentEditEntitlement(entitlements, action, type) ||
        paymentHeaderRecord.getField('nachaFileUpload')
    );
};

export function shouldDisableTransactionType(
    onUsBanks: AchBankDto[],
    companyTransactionType: string,
    hasOnUsAccessManagementFeature: boolean,
    hasOnAccessManagementEntitlement: boolean,
    recipientBank?: AchBankDto | AchBank
) {
    const transactionTypeMatch =
        companyTransactionType === 'Credit Only' || companyTransactionType === 'Debit Only';
    if (hasOnUsAccessManagementFeature && hasOnAccessManagementEntitlement) {
        return transactionTypeMatch;
    }
    return transactionTypeMatch && !onUsBanks.some(bank => bank.bankId === recipientBank?.bankId);
}

/**
 *
 * Fields:
 */
/* we have to define separate fields for the
    nacha upload workflow which uses a different table. this would not be the case if
    the nacha parse did the same thing as the GET request which parses the addenda (detail) fields
*/

const getBaseFields = (
    isNacha: boolean,
    type: WorkflowTypes,
    config: any,
    client: any,
    paymentHeaderRecord: FdlRecord<PaymentHeader>,
    fdlClient: any
): FdlFieldDefinitions<PaymentRecipient> => {
    const viewMode = () =>
        config.action === WorkflowActions.View || config.action === WorkflowActions.ViewBatchDetail;
    const { entitlements, action } = config;
    const fields: FdlFieldDefinitions<PaymentRecipient> = {
        id: fieldTypes.number.thatIs.visibleWhen(() => false),
        addendaId: fieldTypes.string.thatIs.visibleWhen(() => false) as FieldType<
            PaymentHeader['addendaId']
        >,
        name: achPaymentRecipientName.thatIs
            .required()
            .thatIs.readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) ||
                    getRecordEditableField(record) === false
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
                record.getField('fromMasterList')
            )
            .as.tag('omega-input')
            .and.template(
                (field, record) => html`<ach-payment-name-field
                    .record=${record}
                    .isNacha=${isNacha}
                ></ach-payment-name-field>`
            ),
        idNumber: achIdNumber.thatIs
            .readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) ||
                    getRecordEditableField(record) === false
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
                record.getField('fromMasterList')
            )
            .as.tag('omega-input'),
        accountNumber: (achAccountNumber as FieldType<PaymentHeader['accountNumber']>).thatIs
            .required()
            .thatIs.readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) ||
                    getRecordEditableField(record) === false
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
                record.getField('fromMasterList')
            )
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .as.tag('omega-input'),
        accountType: (achAccountType as FieldType<PaymentHeader['accountType']>).thatIs
            .required()
            .thatIs.readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) ||
                    getRecordEditableField(record) === false
            )
            .and.readOnlyWhen(
                () =>
                    isEditPaymentFlow(action, type) &&
                    hasPartialPaymentEditEntitlement(entitlements, action, type)
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
                record.getField('fromMasterList')
            )
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .as.tag('omega-select')
            .thatHas.defaultValue('Checking')
            .with.options([
                { text: 'Checking', value: 'Checking' },
                { text: 'Savings', value: 'Savings' },
                {
                    text: 'General Ledger',
                    value: isEditPaymentFlow(action, type) ? 'GL' : 'General Ledger',
                },
                {
                    text: 'Loan',
                    value: isEditPaymentFlow(action, type) ? 'Loans' : 'Loan',
                },
            ]),
        bank: (achBank(fdlClient) as FieldType<PaymentHeader['bank']>).thatIs
            .required()
            .thatIs.readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) ||
                    getRecordEditableField(record) === false
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
                record.getField('fromMasterList')
            )
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .as.tag('omega-select'),
        amount: (fieldTypes.amount as FieldType<PaymentHeader['amount']>).thatIs
            .required()
            .thatIs.readOnlyWhen((record: FdlRecord<PaymentRecipient>) =>
                validateReadonly(record, entitlements, action, type, paymentHeaderRecord)
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    record.getField('fromMasterList') && !record.getField('testAchCompanyName')
            )
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) => !!record.getField('prenote'))
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .thatHas.validator({
                name: 'must be greater than zero',
                validate: (value: any, viewValue, record: FdlRecord<PaymentRecipient>) =>
                    value > 0 || !!record.getField('prenote'),
            })
            .as.tag('omega-input'),
        prenote: (fieldTypes.yesNoBoolean as FieldType<PaymentHeader['prenote']>).with
            .cellClass('count')
            .and.defaultValue(false)
            .thatIs.readOnlyWhen((record: FdlRecord<PaymentRecipient>) =>
                validateReadonly(record, entitlements, action, type, paymentHeaderRecord)
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .as.tag('omega-checkbox'),
        hold: (fieldTypes.yesNoBoolean as FieldType<PaymentHeader['prenote']>).with
            .cellClass('count')
            .and.defaultValue(false)
            .thatIs.readOnlyWhen((record: FdlRecord<PaymentRecipient>) =>
                validateReadonly(record, entitlements, action, type, paymentHeaderRecord)
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .as.tag('omega-checkbox'),
        addenda: addenda.thatIs
            .readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) &&
                    !(
                        isEditPaymentFlow(action, type) &&
                        hasPartialPaymentEditEntitlement(entitlements, action, type) &&
                        paymentHeaderRecord.getField('step') === 0
                    )
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .with.template(
                (addenda: string) => html`<div style="white-space: pre-line">${addenda}</div>`
            ),
        errors: (fieldTypes.boolean as FieldType<PaymentHeader['errors']>).as.tag('omega-checkbox'),
        step: fieldTypes.number.thatIs.visibleWhen(() => false) as FieldType<number>,
        transactionType: achTransactionType(paymentHeaderRecord, config.onUsAchBanks)
            .thatIs.readOnlyWhen(
                (record: FdlRecord<PaymentRecipient>) =>
                    validateReadonly(record, entitlements, action, type, paymentHeaderRecord) ||
                    getRecordEditableField(record) === false
            )
            .and.readOnlyWhen(
                () =>
                    isEditPaymentFlow(action, type) &&
                    hasPartialPaymentEditEntitlement(entitlements, action, type)
            )
            .thatIs.readOnlyWhen(viewMode)
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
                record.getField('fromMasterList')
            )
            .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
            .and.disabledWhen((record: FdlRecord<PaymentRecipient>) => {
                const { notOnUsTransactionTypes } = paymentHeaderRecord.getField('achCompany');
                return shouldDisableTransactionType(
                    config.onUsAchBanks,
                    notOnUsTransactionTypes,
                    entitlements.onUsAccessManagementFeature,
                    config.allowOnUsAccessManagement,
                    record.getField('bank')
                );
            })
            .as.tag('omega-select'),
        testAchCompanyName: testAchCompanyName.thatIs.visibleWhen(() => false),
        fromMasterList: fieldTypes.boolean.thatIs.visibleWhen(() => false).and.defaultValue(false),
    };

    return fields;
};

export function paymentRecipientsFields(
    isNacha: boolean,
    type: WorkflowTypes,
    config: any,
    client: any,
    paymentHeaderRecord: FdlRecord<PaymentHeader>,
    fdlClient: any
): FdlFieldDefinitions<PaymentRecipient> {
    const fields = getBaseFields(
        isNacha,
        type,
        config,
        client,
        paymentHeaderRecord,
        fdlClient
    ) as FdlFieldDefinitions<PaymentRecipient>;

    const viewMode = () =>
        config.action === WorkflowActions.View || config.action === WorkflowActions.ViewBatchDetail;
    const { entitlements, action } = config;

    fields.bank = (achBank(fdlClient) as FieldType<PaymentHeader['bank']>).thatIs
        .required()
        .thatIs.readOnlyWhen(
            (record: FdlRecord<PaymentRecipient>) =>
                isReadOnlyStates(record, entitlements, action, type, paymentHeaderRecord) ||
                getRecordEditableField(record) === false
        )
        .thatIs.readOnlyWhen(viewMode)
        .and.disabledWhen((record: FdlRecord<PaymentRecipient>) =>
            record.getField('fromMasterList')
        )
        .and.disabledWhen(() => !paymentHeaderRecord.getField('achCompany'))
        .as.tag('omega-select');

    return fields;
}

export function batchRecipientsFields(
    isNacha: boolean,
    type: WorkflowTypes,
    config: any,
    client: any,
    paymentHeaderRecord: FdlRecord<PaymentHeader>,
    fdlClient: any
): FdlFieldDefinitions<BatchRecipient> {
    const fields = getBaseFields(
        isNacha,
        type,
        config,
        client,
        paymentHeaderRecord,
        fdlClient
    ) as FdlFieldDefinitions<BatchRecipient>;

    fields.routingNumber = fieldTypes.string.thatIs.readOnly().as.tag('omega-select');
    fields.name = { ...fields.name! }.with.minColumnWidth(100).and.targetColumnWidth(100);
    fields.idNumber = { ...fields.idNumber! }.with.minColumnWidth(80).and.targetColumnWidth(100);
    fields.accountNumber = { ...fields.accountNumber! }.with
        .minColumnWidth(80)
        .and.targetColumnWidth(100);

    fields.accountType = { ...fields.accountNumber! }.with
        .minColumnWidth(70)
        .and.targetColumnWidth(80);
    fields.amount = { ...fields.amount! }.with.minColumnWidth(60).and.targetColumnWidth(60);

    return fields;
}

export function defaultRecipient<T>(isNacha: boolean): T {
    return {
        ...defaultRecipientValues,
    } as T;
}

function recipients<T extends object>(
    isNacha: boolean,
    type: WorkflowTypes,
    config: any,
    data: Array<T>,
    client: any,
    paymentHeaderRecord: FdlRecord<PaymentHeader>,
    fdlClient: any,
    batch: boolean
): Array<T> {
    data.map((recipient: T) => {
        getKeys<T>(defaultRecipient<T>(isNacha)).forEach(key => {
            if (!recipient[key]) {
                const fields = batch
                    ? (batchRecipientsFields(
                          isNacha,
                          type,
                          config,
                          client,
                          paymentHeaderRecord,
                          fdlClient
                      ) as FdlFieldDefinitions<T>)
                    : (paymentRecipientsFields(
                          isNacha,
                          type,
                          config,
                          client,
                          paymentHeaderRecord,
                          fdlClient
                      ) as FdlFieldDefinitions<T>);
                const value = fields[key]?.defaultValue() as T[typeof key];
                (recipient[key] as any) = value;
            }
        });
        return recipient;
    });

    return data;
}

export const recipientDirty = (recipient: FdlRecord<PaymentRecipient>): boolean => {
    let dirty = false;
    getKeys(recipient.initialValues).forEach(key => {
        if (
            key !== 'transactionType' &&
            !(key === 'bank' && recipient.values[key] === undefined) &&
            recipient.initialValues[key] !== recipient.values[key]
        ) {
            dirty = true;
        }
    });
    return dirty;
};

export const batchRecipientRecordset = (
    data: Array<BatchRecipient>,
    isNacha: boolean,
    type: WorkflowTypes,
    config: any,
    paymentHeaderRecord: FdlRecord<PaymentHeader>,
    client = new AchDomesticClient(),
    fdlClient = null
) =>
    new Recordset<BatchRecipient>(
        batchRecipientsFields(isNacha, type, config, client, paymentHeaderRecord, fdlClient),
        () =>
            recipients<BatchRecipient>(
                isNacha,
                type,
                config,
                data,
                client,
                paymentHeaderRecord,
                fdlClient,
                true
            )
    );

export const recipientRecordset = (
    data: Array<PaymentRecipient>,
    isNacha: boolean,
    type: WorkflowTypes,
    config: any,
    paymentHeaderRecord: FdlRecord<PaymentHeader>,
    client = new AchDomesticClient(),
    fdlClient = null
) => {
    const recordset = new Recordset<PaymentRecipient>(
        paymentRecipientsFields(isNacha, type, config, client, paymentHeaderRecord, fdlClient),
        () =>
            recipients<PaymentRecipient>(
                isNacha,
                type,
                config,
                data,
                client,
                paymentHeaderRecord,
                fdlClient,
                false
            )
    );

    recordset.addValidators([
        unbalancedPartialOffsetValidator(paymentHeaderRecord),
        onUsTransactionsValidator(paymentHeaderRecord),
        onUsAmountRequiredValidator(paymentHeaderRecord),
        balancedValidator(paymentHeaderRecord),
        creditOnlyTransactionTypeValidator(paymentHeaderRecord, config.onUsAchBanks),
        debitOnlyTransactionTypeValidator(paymentHeaderRecord, config.onUsAchBanks),
        recipientAmountValidator(),
    ]);

    return recordset;
};

export default recipientRecordset;
