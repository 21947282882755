/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { downloadBlob } from '../../../utilities/file-handling.js';
import CheckExceptionMappings from '../../mappings/positive-pay/check-exceptions/check-exception-mappings.js';

const endpoint = 'arp';

export class PositivePayExceptionRequests {
    static async saveCheckExceptions(body) {
        return (await TmHttpClient.getInstance()).request(endpoint, {
            method: 'POST',
            body,
        });
    }

    static async saveAttachmentAndComment(body, arpExceptionDetailUniqueId) {
        return (await TmHttpClient.getInstance()).request(
            `CheckException/${arpExceptionDetailUniqueId}/Attachment`,
            {
                method: 'POST',
                body,
            }
        );
    }

    static async searchArpExceptions(searchObject) {
        return (await TmHttpClient.getInstance()).request(`${endpoint}/arpExceptions`, {
            method: 'POST',
            body: searchObject,
        });
    }

    static async searchCheckExceptions(searchObject) {
        return (await TmHttpClient.getInstance()).request(`CheckException/Search`, {
            method: 'POST',
            body: searchObject,
        });
    }

    static async updateArpExceptions(exceptions) {
        return (await TmHttpClient.getInstance()).request(`${endpoint}`, {
            method: 'POST',
            body: exceptions,
        });
    }

    static async CheckExceptionCorrection(correction) {
        return (await TmHttpClient.getInstance()).request(`${endpoint}/CheckExceptionCorrection`, {
            method: 'POST',
            body: correction,
        });
    }

    // The configured Cutoff times apply to both Check Exceptions and ACH Exceptions
    // This call applies to the current business day.
    static async getCutoffTimes() {
        return (await TmHttpClient.getInstance()).request(
            `companyaccounts/cutoffTimesByProductType/ArpException`,
            {
                method: 'GET',
            }
        );
    }

    static async getArpExceptionAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `${endpoint}/GetArpAccounts/WorkArpExceptions`,
            {
                method: 'GET',
            }
        );
    }

    static async getAchExceptionAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `${endpoint}/GetArpAccounts/WorkAchException`,
            {
                method: 'GET',
            }
        );
    }

    static async getArpDownloadAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `${endpoint}/GetArpAccounts/DownloadArpFile`,
            {
                method: 'GET',
            }
        );
    }

    static async getArpUploadAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `${endpoint}/GetArpAccounts/UploadCreateArpFile`,
            {
                method: 'GET',
            }
        );
    }

    static async getReturnReasons() {
        return (await TmHttpClient.getInstance()).request(`InstitutionCheckExceptionReturnReason`, {
            method: 'GET',
        });
    }

    static async getPositivePayCompanyConfiguration() {
        return (await TmHttpClient.getInstance()).request(
            `PositivePayCompanyConfiguration/allOptions`,
            {
                method: 'GET',
            }
        );
    }

    static async getCheckExceptionAttachment(arpExceptionDetailUniqueId) {
        return (await TmHttpClient.getInstance()).request(
            `CheckException/${arpExceptionDetailUniqueId}/Attachment`,
            {
                method: 'GET',
                responseType: TmHttpResponseType.Raw,
            }
        );
    }

    static async getAchExceptionsForWidget() {
        return (await TmHttpClient.getInstance()).request(
            `dashboard/channelWorkExceptions?type=achExceptions`,
            {
                method: 'GET',
            }
        );
    }

    static async saveCorrectionRequest(body) {
        return (await TmHttpClient.getInstance()).request(`CheckExceptionCorrection`, {
            method: 'POST',
            body,
        });
    }

    static async saveAchExceptions(body) {
        return (await TmHttpClient.getInstance()).request(`arp/saveAchExceptions`, {
            method: 'POST',
            body,
        });
    }

    static async saveAchExceptionsDashboard(body) {
        return (await TmHttpClient.getInstance()).request(`dashboard/channelAchExceptionsBatch`, {
            method: 'POST',
            body,
        });
    }

    static async fetchCheckImages(checkImageNumber) {
        return (await TmHttpClient.getInstance()).request(
            `checkimages/check?checkImageNumber=${encodeURIComponent(checkImageNumber)}`,
            {
                method: 'GET',
            }
        );
    }

    static async downloadArpExceptionsList(detail, reportType) {
        const http = await TmHttpClient.getInstance();
        let body = CheckExceptionMappings.mapSearchParams(detail.filter);
        body = {
            ...body,
            type: reportType,
        };
        if (detail.type.toUpperCase() === 'CSV') {
            const response = await http.request(`CheckException/Download/Csv`, {
                method: 'POST',
                body,
                responseType: TmHttpResponseType.ArrayBuffer,
            });
            const blob = new Blob([response], { type: `application/octet-stream` });
            return downloadBlob(blob, 'searchCheckException.csv');
        }

        const response = await http.request(`CheckException/Download/Pdf`, {
            method: 'POST',
            body,
            responseType: TmHttpResponseType.ArrayBuffer,
        });
        const blob = new Blob([response], { type: `application/pdf` });
        return downloadBlob(blob, 'searchCheckException.pdf');
    }

    // TODO: there are more endpoints in the ARP service, for ACH exceptions, that can be added here.
}
