// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping calls associated with ACH Templates.
 */
class AchTemplateRequests {
    //
    static async fetchTemplates(body) {
        return (await TmHttpClient.getInstance()).request(`achBatches/search`, {
            method: 'POST',
            body,
        });
    }

    static async initiatePaymentsByIds(body) {
        return (await TmHttpClient.getInstance()).request(`/achBatches/initiate`, {
            method: 'POST',
            body,
        });
    }

    static async deleteTemplateById(id) {
        return (await TmHttpClient.getInstance()).request(`achBatches/delete`, {
            method: 'DELETE',
            body: {
                achBatchId: id,
                securityMessage: {
                    actionType: 'Delete Batch',
                    errorCode: null,
                    hasAlternate: false,
                    message: null,
                    methodUsed: null,
                    oneTimePassword: null,
                    status: null,
                },
            },
        });
    }

    /**
     *
     * @param {string} name
     * @returns boolean validation
     */
    static async isTemplateNameValid(name) {
        const response = await (
            await TmHttpClient.getInstance()
        ).request(`achBatches/validateTemplateName?templateName=${name}`, {
            method: 'GET',
        });
        return response.isValid;
    }

    static async createTemplate(body) {
        return (await TmHttpClient.getInstance()).request(`achBatches/batch`, {
            method: 'POST',
            body,
        });
    }

    static async getAchTemplateById(id) {
        return (await TmHttpClient.getInstance()).request(`achBatches/${id}`, {
            method: 'GET',
        });
    }
}

export default AchTemplateRequests;
