ArpUploadFormatListController.$inject = ['$scope', 'arpService', '$state', 'modalService'];

export default function ArpUploadFormatListController($scope, arpService, $state, modalService) {
    $scope.arpUploadFormats = [];
    $scope.confirmDelete = confirmDelete;
    $scope.deleteFormat = deleteFormat;
    $scope.downloadOptions = ['CSV', 'PDF', 'Word'];
    $scope.editFormat = editFormat;
    $scope.createFormat = createFormat;
    $scope.isDataLoaded = false;
    $scope.disableDownload = true;

    init();

    function init() {
        getArpUploadFormatsList();
    }

    function getArpUploadFormatsList() {
        arpService.getSavedFormats().then(response => {
            $scope.arpUploadFormats = response;
            $scope.isDataLoaded = true;
            if ($scope.arpUploadFormats.length > 0) {
                $scope.disableDownload = false;
            }
        });
    }

    function confirmDelete(format) {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: `Are you sure you want to delete format ${format.name}?`,
            submit(result) {
                $modalInstance.close();
                deleteFormat(format);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function deleteFormat(format) {
        arpService.deleteSavedFormat(format.id).then(response => {
            $scope.arpUploadFormats = $scope.arpUploadFormats.filter(el => el.id !== format.id);
        });
    }

    function createFormat() {
        $state.go('payables.arp.arp-upload-format-edit');
    }

    function editFormat(format) {
        $state.go('payables.arp.arp-upload-format-edit', { id: format.id });
    }

    function email() {}

    function print() {}

    function download(format) {}
}
