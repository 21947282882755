// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class groupings calls associated with the ach payments:
 *  * update status of an ACH payment
 */
class AchDomesticRequests {
    static async getAchPayment(paymentId) {
        return (await TmHttpClient.getInstance()).request(`achPayments/${paymentId}`);
    }

    static async getPaymentDraft(body) {
        return (await TmHttpClient.getInstance()).request('achPayments/paymentDrafts', {
            method: 'POST',
            body,
        });
    }

    static async updatePaymentStatus(
        action,
        id,
        achPaymentId = null,
        comments = null,
        actionType = 'Approve ACH Payment',
        method = 'POST',
        actionEndpoint = null
    ) {
        const endpoint = actionEndpoint ?? `achPayments/${id}/${action}`;

        const body = {
            ...(id !== null && { id }),
            ...(achPaymentId !== null && { achPaymentId }),
            ...(comments !== null && { comments }),
            securityMessage: {
                actionType,
                status: null,
                methodUsed: null,
                hasAlternate: false,
                errorCode: null,
                message: null,
                oneTimePassword: null,
            },
        };
        return (await TmHttpClient.getInstance()).request(endpoint, {
            method,
            body,
        });
    }

    static async updateBatchPaymentStatuses(
        capitalizedAction,
        lookups,
        actionType = 'Approve ACH Payment'
    ) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/batch?action=${capitalizedAction}`,
            {
                method: 'POST',
                body: {
                    lookups,
                    securityMessage: {
                        actionType,
                        status: null,
                        methodUsed: null,
                        hasAlternate: false,
                        errorCode: null,
                        message: null,
                        oneTimePassword: null,
                    },
                },
            }
        );
    }

    static async updateRecurringAchPaymentStatus(action, data) {
        return (await TmHttpClient.getInstance()).request(`achPayments/${action}`, {
            method: 'PUT',
            body: {
                ...data,
                securityMessage: {
                    actionType: 'Delete ACH Payment',
                    status: null,
                    methodUsed: null,
                    hasAlternate: false,
                    errorCode: null,
                    message: null,
                    oneTimePassword: null,
                },
            },
        });
    }

    static async createPayment(body) {
        return (await TmHttpClient.getInstance()).request(`achPayments/payment`, {
            method: 'POST',
            body,
        });
    }

    static async updatePayment(body) {
        const { id } = body;
        return (await TmHttpClient.getInstance()).request(`achPayments/${id}`, {
            method: 'PUT',
            body,
        });
    }

    /**
     *
     * @param {string} name
     * @returns boolean validation
     */
    static async isTemplateNameValid(name) {
        const response = await (
            await TmHttpClient.getInstance()
        ).request(`achBatches/validateTemplateName?templateName=${name}`, {
            method: 'GET',
        });
        return response.isValid;
    }

    static async fetchAchPaymentActivity(body) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/FilterPayments?type=One+Time`,
            {
                method: 'POST',
                body,
            }
        );
    }

    static async uploadNachaFile(file) {
        const fd = new FormData();
        fd.append('file', file);
        return (await TmHttpClient.getInstance()).request(`achPayments/upload`, {
            method: 'POST',
            body: fd,
            disableContentType: true,
        });
    }

    static async initiateFromTemplate(body) {
        return (await TmHttpClient.getInstance()).request(`achBatches/initiate`, {
            method: 'POST',
            body,
        });
    }
}

export default AchDomesticRequests;
