import { LitElement, PropertyValueMap, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { IssuedItemCreationStep } from '../issued-items-creation-container';

type FirstStep = IssuedItemCreationStep.Create | IssuedItemCreationStep.Upload;
type EventPayload = {
    next: FirstStep;
    previous?: FirstStep;
};

export type IssuedItemsRadioSelectEvent = CustomEvent<EventPayload>;

@customElement('issued-items-initial-step-radio-group')
export class IssuedItemsInitialStepRadioGroup extends LitElement {
    @property({
        type: String,
    })
    public step: FirstStep = IssuedItemCreationStep.Upload;

    private previousStep?: FirstStep;

    private readonly radioOptions = [
        {
            label: 'Manual Entry',
            value: IssuedItemCreationStep.Create,
        },
        {
            label: 'Upload File',
            value: IssuedItemCreationStep.Upload,
        },
    ];

    private onRadioSelect(e: CustomEvent<FirstStep>) {
        const { detail } = e;
        this.previousStep = this.step;
        this.step = detail;

        const payload: EventPayload = {
            next: this.step,
            previous: this.previousStep,
        };

        // propagate change from <omega-radio-group> shadow DOM
        this.dispatchEvent(
            new CustomEvent('change', {
                detail: payload,
                bubbles: true,
                composed: true,
            })
        );
    }

    protected render() {
        return html` <omega-radio-group
            orientation="row"
            .radios=${this.radioOptions}
            .value=${this.step}
            @change="${(e: CustomEvent<FirstStep>) => this.onRadioSelect(e)}"
        ></omega-radio-group>`;
    }
}
