import { AccountDto } from '@treasury/domain/channel/types';
import { OmegaListItem } from '@treasury/omega/view-models';

export type AccountListItem = OmegaListItem<AccountDto>;
export function createAccountListItem(dto: AccountDto): AccountListItem {
    return {
        value: dto,
        text: dto.accountDisplayLabel,
    };
}
