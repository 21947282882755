NachaFileValidationErrorListController.$inject = [
    '$scope',
    '$modalInstance',
    'validationErrorList',
];

export default function NachaFileValidationErrorListController(
    $scope,
    $modalInstance,
    validationErrorList
) {
    $scope.validationErrorList = validationErrorList;
    $scope.filteredErrorList = validationErrorList;
    $scope.searchByText = searchByText;
    $scope.searchFilters = {
        text: '',
    };
    $scope.close = close;
    $scope.printErrors = printErrors;
    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    function filterErrors() {
        if ($scope.validationErrorList) {
            const filteredArray = [];
            angular.forEach($scope.validationErrorList, validationError => {
                if (
                    $scope.searchFilters.text === '' ||
                    (validationError &&
                        validationError
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1)
                ) {
                    filteredArray.push(validationError);
                }
            });
            return filteredArray;
        }
    }

    function searchByText() {
        $scope.filteredErrorList = filterErrors();
    }

    function printErrors() {
        let errors = '<html><body><h3>Nacha File Validation Errors</h3><hr><ul>';
        angular.forEach($scope.filteredErrorList, error => {
            if (error.length > 0) errors += `<li>${error}</li>`;
        });
        errors += '</ul></table></body></html>';

        const printWin = window.open('', '', 'width=1024,height=768');
        printWin.document.write(errors);
        printWin.document.close();
        printWin.focus();
        printWin.print();
        printWin.close();
    }

    (function () {
        // init
        $scope.filteredErrorList = filterErrors();
    })();
}
