// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant('regexConstants', {
    AlphaNumericPlusSpecialCharsRegex:
        /^[a-zA-Z0-9!\"#$%\'\‘\′()\+\/\:\;\\[\]<=>?@_`^{}~|\-\.&\*\, ]+$/,
    AlphaNumericPluslimitedSpecialCharsRegex: /^[a-zA-Z0-9_\s\-\/]+$/,
    AlphaNumericRegex: /^[a-zA-Z0-9\ \']*$/,
    NumericRegex: /^[0-9]*$/,
    RecipientAddendaRegex: /^[a-zA-Z0-9\ \'\#\-\$\.\&\*\,]*$/,
    WireAlphaNumericPlusSpecialCharsRegex: /^[a-zA-Z0-9!\"#$%\'()\+\/\:\;\\<=>?@_`^~\-\.&\, ]+$/,
    // https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
    InternationalPostalCodeRegex: /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/,
    DomesticPostalCodeRegex: /(^\d{5}$)/,
    DomesticPostalCodeExtRegex: /(^\d{4}$)/,
    BeneficiaryGeneralRegex: /^[a-zA-Z0-9\ \/\-\?\:\(\)\.\,\'\+\*\&\=\@\$\#]*$/,
    BeneficiaryReferenceRegex: /^[a-zA-Z0-9!\"#$%\'\‘\′()\+\/\:\;\<=>?@_`~\-\.&\, ]+$/,
    DomesticRoutingNumberRegex: /^[0-9]{9}$/,
});
