/* eslint-disable @treasury/no-date */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record } from '@treasury/FDL';
import {
    AchRecipientsService,
    getTransactionTypeName,
} from '@treasury/domain/channel/services/ach/ach-recipients-service';
import { MasterListRecipient } from '@treasury/domain/channel/types/ach';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/progress/omega-progress.js';
import { format } from 'date-fns';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

type Audit = {
    action: string;
    actionDate: string;
    userAlias: string;
};

@customElement('ach-recipient-details-dialog')
export class AchRecipientDetailsDialog extends LitElement {
    @property({ type: Boolean })
    open = false;

    @property({ type: Object })
    recipient = {} as Record<MasterListRecipient>;

    @state()
    private audit!: Audit[];

    @InjectProperty()
    private achRecipientsService!: AchRecipientsService;

    @state()
    private auditLoading = false;

    get dialogTitle() {
        if (!this.recipient?.getField) return '';
        return `Recipient: ${this.recipient?.getField('recipientName')}`;
    }

    get idNumber() {
        if (this.recipient?.getField) {
            return this.recipient?.getField('recipientIdNumber')
                ? this.recipient?.print('recipientIdNumber')
                : html`&nbsp;`;
        }
        return '';
    }

    async firstUpdated() {
        this.auditLoading = true;
        this.audit = (await this.achRecipientsService.getAchRecipientAudit(
            this.recipient.getField('id')
        )) as unknown as Audit[];
        this.auditLoading = false;
    }

    close() {
        this.open = false;
        this.recipient = {} as Record<MasterListRecipient>;
        this.audit = [] as Audit[];
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderAuditTrail() {
        if (this.auditLoading) return html`<omega-progress></omega-progress>`;
        if (!this.audit?.length) return nothing;
        const audits = this.audit.map(
            (audit: Audit) =>
                html`<li>
                    ${format(new Date(audit.actionDate), 'PPpp')}: ${audit.userAlias}:
                    ${audit.action}
                </li>`
        );
        return html`<div class="audit-wrapper">
            <ul>
                ${audits}
            </ul>
        </div>`;
    }

    renderAchMasterRecipientStatusTypeDescription(status: string | null) {
        if (status === 'Rejected') return `Approval ${status}`;
        return status;
    }

    render() {
        return html`<omega-dialog
            .open=${this.open}
            .dialogTitle=${this.dialogTitle}
            @close=${() => {
                this.close();
            }}
        >
            <div slot="content">
                <div class="flex">
                    <div class="label-column">
                        <div class="label">Status:</div>
                        <div class="label">ID Number:</div>
                        <div class="label">Account Number:</div>
                        <div class="label">Routing Number:</div>
                    </div>
                    <div class="value-column">
                        <div class="value">
                            ${this.renderAchMasterRecipientStatusTypeDescription(
                                this.recipient?.values?.achMasterRecipientStatusTypeDescription
                            )}
                        </div>
                        <div class="value">${this.idNumber}</div>
                        <div class="value">${this.recipient?.print('accountNumber')}</div>
                        <div class="value">${this.recipient?.getField('routingNumber')}</div>
                    </div>
                    <div class="label-column">
                        <div class="label">Credit/Debit:</div>
                        <div class="label">Account Type:</div>
                        <div class="label">Default Amount:</div>
                        <div class="label">Audit:</div>
                    </div>
                    <div class="value-column">
                        <div class="value">
                            ${getTransactionTypeName(this.recipient.getField('transactionTypeId'))}
                        </div>
                        <div class="value">${this.recipient?.print('accountTypeDescription')}</div>
                        <div class="value">${this.recipient?.print('defaultAmount')}</div>
                        <div class="value">${this.renderAuditTrail()}</div>
                    </div>
                </div>
            </div>
            <div slot="actions">
                <omega-button-bar
                    ><omega-button
                        type="primary"
                        @click=${() => {
                            this.close();
                        }}
                        >Close</omega-button
                    ></omega-button-bar
                >
            </div>
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --omega-dialog-min-width: 715px;
            }
            .flex {
                display: flex;
                flex-direction: row;
                flex-wrap: no-wrap;
                justify-content: space-between;
                padding: 10px;
            }
            .audit-wrapper {
                padding: 5px;
                background-color: #f5f5f5;
                border: 1px solid #e5e5e5;
                border-radius: 3px;
            }
            ul {
                list-style-type: none;
            }
            ul,
            li {
                margin-left: 0;
                padding-left: 0;
            }
            .label,
            .value {
                margin-bottom: 10px;
            }
        `;
    }
}
