/*
    NOTE =
    Eventually we might want to fill the auditCode enumeration from the API,
    instead of having it implemented both there and here.
    Even then, some precaution will have to be taken such that
    changes to the API keys won't break presentation code.
*/
export enum AuditCode {
    // Account
    DeclineTermsAndConditions = 9577,

    // Session
    SessionExpiredForced = 12000,
    SessionExpiredUserInitiated = 12001,

    // Payments
    CancelCreateTransfer = 9047,
    CancelOneTimeTransfer = 9048,
    CancelRecurringTransfer = 9049,
    AccessStopPaymentDetail = 9050,
    AccessCreateStopPayment = 9051,
    CancelReviewStopPayment = 9052,
    CancelConfirmStopPayment = 9053,

    // Wires
    CancelCreateBeneficiary = 9856,
    CancelCreateInternationalWire = 9857,
    AccessCreateDomesticWire = 9858,
    AccessCreateInternationalWire = 9859,
    CancelCreateDomesticWire = 9860,
    CancelEditOneTimeWire = 9861,
    CancelEditRecurringWire = 9862,

    // Beneficiaries
    AccessEditBeneficiary = 9863,
    CancelEditBeneficiary = 9864,
    AccessCreateDomesticBeneficiary = 9865,
    CancelCreateDomesticBeneficiary = 9866,
    AccessCreateInternationalBeneficiary = 9867,
    CancelCreateInternationalBeneficiary = 9868,

    // ARP
    AccessCreateIssuedItems = 10021,
    AccessUploadIssuedItems = 10022,
    CancelCreateIssuedItems = 10023,
    CancelUploadIssuedItems = 10024,
    ConfirmCreateIssuedItems = 10025,
    ConfirmUploadIssuedItems = 10026,

    // ACH
    AccessCreateAchBatch = 9941,
    CancelCreateAchBatch = 9942,
    AccessUploadNachaFile = 9943,
    CancelEditBatchDetail = 9944,
    CancelUploadNachaFile = 9945,
    CancelCreateAchPayment = 9946,
}
