// cspell:ignore fileactivity

import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import AchRecurringServices from '@treasury/domain/channel/services/ach/ach-recurring-services.js';
import { Feature } from '@treasury/domain/services/feature-flags';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import {
    achCompanyList,
    achFrequencyList,
    achPaymentStatusList,
    achSecCodeList,
} from '@treasury/policy/ach';
import { amountRange, dateRange, string } from '@treasury/policy/primitives';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-payment-action-dialog.js';
import RecurringAchPaymentsClient from '../clients/recurring-ach-payments-client.js';
import {
    recurringAchPaymentsFields,
    recurringAchPaymentsReportColumns,
} from '../data/recurring-ach-payments.js';

class RecurringAchPayments extends mix(LitElement).with(ListeningElementMixin, channelAlertMixin) {
    static get properties() {
        return {
            client: Object,
            institution: String,
            alert: Object,
            actions: Object,
            reportActions: Array,
            recurringAchPaymentsRecordset: Object,
            selectedPayment: Object,
            submittingActionOnPayment: Boolean,
            actionTaken: String,
            showActionDialog: Boolean,
            downloading: Boolean,
            filters: Array,
            localFilters: Array,
            entitlements: Array,
        };
    }

    constructor() {
        super();
        this.alert = { visible: false, title: '' };
        this.showActionDialog = false;
        this.actions = {};
        this.reportActions = [];
        this.reportLinks = [];
        this.pageTitle = 'Recurring ACH Payments';
        this.reportInformation = html`
            <div style="width:335px">
                <b>Recurring ACH Payments</b> - Includes only recurring payments and the details
                (Frequency, Create Date, Next Payment Date, End Date, etc.) associated with the
                respective payment series.
            </div>
        `;
        this.columns = recurringAchPaymentsReportColumns;
        this.fields = recurringAchPaymentsFields;
        this.downloading = false;
        this.tableFilters = [];
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new RecurringAchPaymentsClient();
        }
        const companies = AchRecurringServices.getCompanies();
        const securityCodes = AchRecurringServices.getSecCodes();
        this.entitlements = await this.client.entitlements();
        this.columns = recurringAchPaymentsReportColumns(this.entitlements);
        this.filters = [
            {
                field: 'status',
                fieldType: achPaymentStatusList.with.label('Status').as.tag('omega-select'),
                value: 'All',
            },
            {
                field: 'achCompanyList',
                fieldType: achCompanyList.with
                    .label('ACH Company Name')
                    .and.options({
                        data: await companies,
                        text: record => record.companyName,
                        value: record => record,
                    })
                    .thatHas.placeholder('Select ACH Companies')
                    .with.multipleValues()
                    .with.hashFunction(item => item.id)
                    .as.tag('omega-select'),
                value: [],
            },
            {
                field: 'batchName',
                fieldType: string.with.label('Batch Name').as.tag('omega-input'),
                value: '',
            },
            {
                field: 'transactionId',
                fieldType: string.with.label('Transaction ID').as.tag('omega-input'),
                value: '',
            },
            {
                field: 'secCode',
                fieldType: achSecCodeList.with
                    .label('SEC Code')
                    .with.options({
                        data: await securityCodes,
                        text: record => record,
                        value: record => record,
                    })
                    .as.tag('omega-select'),
                value: 'All',
            },
            {
                field: 'debitAmount',
                fieldType: amountRange.with.label('Debit Amount').as.tag('omega-range'),
                value: ['range', 0, 0],
            },
            {
                field: 'creditAmount',
                fieldType: amountRange.with.label('Credit Amount').as.tag('omega-range'),
                value: ['range', 0, 0],
            },
            {
                field: 'createdDate',
                fieldType: dateRange.with
                    .label('Created Date')
                    .and.range()
                    .and.defaultValue('')
                    .as.tag('omega-datepicker'),
                value: '',
            },
            {
                field: 'frequencies',
                fieldType: achFrequencyList.with
                    .label('Frequency')
                    .with.hashFunction(item => item.id)
                    .as.tag('omega-select'),
            },
            {
                field: 'nextPaymentDate',
                fieldType: dateRange.with
                    .label('Next Payment Date')
                    .and.range()
                    .and.defaultValue('')
                    .as.tag('omega-datepicker'),
                value: '',
            },
        ];

        this.recurringAchPaymentsRecordset = new Recordset(
            this.fields,
            this.client.fetchRecurringAchPayments
        );
        await this.recurringAchPaymentsRecordset.requestUpdate();
        await this.initializeActions();
    }

    async initializeActions() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        this.actions = {
            Cancel: record => {
                this.showActionDialog = true;
                this.selectedPayment = record;
                this.actionTaken = 'cancel';
            },
            clickTransactionId: async record => {
                const isInternationalAchPayment = record.getField('secCode').includes('IAT');
                if (isInternationalAchPayment) {
                    navService.navigate('payables.ach.international-ach-payment-details', {
                        id: record.getField('id'),
                    });
                } else {
                    navService.navigate('payables.ach.payment-detail', {
                        id: record.getField('id'),
                        type: 'view',
                        list: 'recurringList',
                    });
                }
            },
        };

        this.reportLinks = [
            {
                title: 'ACH Payment Activity',
                route: 'payables.ach.payment-list',
            },
            {
                title: 'ACH File Activity',
                route: 'payables.ach.fileactivity-list',
            },
        ];
    }

    async createNewPayment() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        if (await this.client.hasFeatureFlag(Feature.LitDomesticAch)) {
            return navService.navigate('payables.ach.payments.create-dark');
        }
        return navService.navigate('payables.ach.payments.create');
    }

    async submitActionOnPayment() {
        this.submittingActionOnPayment = true;
        try {
            await this.client.updatePaymentStatus(this.actionTaken, {
                achPaymentId: this.selectedPayment.getField('id'),
                comments: this.statusChangeComments,
            });
            this.alert = {
                ...this.alert,
                message: `Payment successfully cancelled`,
                visible: true,
                type: 'success',
            };
            this.statusChangeComments = '';
            this.recurringAchPaymentsRecordset.requestHardUpdate();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.statusChangeComments = '';
            this.showActionDialog = false;
            this.submittingActionOnPayment = false;
        }
    }

    async handleDownload({ filter, type }) {
        this.downloading = true;
        try {
            await this.client.downloadRecurringAchPayments(type, 'RecurringACHPaymentList', filter);
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.downloading = false;
        }
    }

    renderDownloadDialog() {
        if (this.downloading) {
            const title = 'Downloading Recurring ACH payments';
            return html`<omega-dialog
                id="download-dialog"
                open
                .dialogTitle=${title}
                @close=${() => {
                    this.downloading = false;
                }}
            >
                <omega-progress card></omega-progress>
            </omega-dialog>`;
        }
        return nothing;
    }

    renderAchPaymentActionDialog() {
        return html`<ach-payment-action-dialog
            .paymentHeaderRecord=${this.selectedPayment}
            .submittingActionOnPayment=${this.submittingActionOnPayment}
            .actionTaken=${this.actionTaken}
            .showActionDialog=${this.showActionDialog}
            dialogTitle="Confirm Cancel Payment"
            @close=${() => {
                this.showActionDialog = false;
                this.statusChangeComments = '';
            }}
            @submit=${({ detail }) => {
                this.statusChangeComments = detail;
                this.submitActionOnPayment();
            }}
        ></ach-payment-action-dialog>`;
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html` <div class="alert-wrapper">
            <omega-alert
                type=${type}
                title=${title}
                posture=${posture}
                ?isVisible=${visible}
                @close=${() => {
                    this.alert = { ...this.alert, visible: false };
                }}
            >
                ${renderedCode} ${message} ${renderedTime} ${actions}
            </omega-alert>
        </div>`;
    }

    renderPageLoader() {
        return html`<div class="background">
            <omega-progress card class="light-loader"></omega-progress>
        </div>`;
    }

    render() {
        if (!this.filters) {
            return this.renderPageLoader();
        }
        return html`
            ${this.renderAchPaymentActionDialog()} ${this.renderAlert()}
            ${this.renderDownloadDialog()}
            <omega-report
                id="recurringAchPaymentReport"
                title="Recurring ACH Payments"
                flyout
                autostart
                .reportInformation=${this.reportInformation}
                .actions=${this.actions}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recurringAchPaymentsRecordset}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .columns=${this.columns}
                .options=${['download', 'print']}
                .downloadOptions=${['PDF']}
                callToAction="Create New Payment"
                @callToAction=${this.createNewPayment}
                @reportDownload=${({ detail }) => this.handleDownload(detail)}
            >
                <div slot="above-table" style="margin:0px 16px;">
                    <omega-filter-bar
                        .filters=${this.tableFilters}
                        .recordset=${this.recurringAchPaymentsRecordset}
                        @change=${({ detail }) => {
                            this.localFilters = detail;
                        }}
                    ></omega-filter-bar>
                </div>
            </omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            .light-loader {
                color: #fff;
                width: 50px;
                height: 50px;
                border-width: 5px;
                margin-top: 35%;
            }
            .background {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: var(--omega-dialog-overlay);
                opacity: 0;
                z-index: -1;
                height: 100%;
                width: 100%;
                display: block;
                opacity: 1;
                visibility: visible;
                z-index: 999;
            }
        `;
    }
}

customElements.define('recurring-ach-payments', RecurringAchPayments);
