import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import {
    PaymentHeader,
    Workflow,
    WorkflowActions,
    WorkflowTypeEntitlements,
} from '@treasury/domain/channel/types/ach';
import { ActionType } from '@treasury/omega/layouts/omega-form';
import { html } from 'lit';
import { AchDomesticBatch } from '../../containers/ach-domestic-batch';
import { AchDomesticContainer } from '../../containers/ach-domestic-container';
import { RecipientFileUploadDialog } from '../../dialogs';
import { StepConfig } from '../container-configuration';

export class StepFactory {
    constructor(
        context: AchDomesticContainer,
        payment: PaymentHeader,
        entitlements: WorkflowTypeEntitlements
    ) {
        this.context = context;
        this.payment = payment;
        this.entitlements = entitlements;
        this.workflow = this.context.workflow;
        this._next = this.context.next.bind(this.context);
        this._back = this.context.back.bind(this.context);
        this._cancel = this.context.cancel.bind(this.context);
        this._submit = this.context.submit.bind(this.context);
        this._routeToBlankStepOne = this.context.routeToBlankCreateStepOne.bind(this.context);

        this.backConfirmationAction = () => {
            const continueEditingAction = () => {
                this.context.alert = { ...this.context.alert, visible: false };
            };
            const backToStepOneAction = () => {
                continueEditingAction();
                this._routeToBlankStepOne(WorkflowActions.InitiateFromFile);
            };
            this.context.alert = {
                ...this.context.alert,
                visible: true,
                posture: 'assertive',
                type: 'error',
                message:
                    'Clicking OK will void the file. You will have to upload the file again. Are you sure you want to cancel these changes?',
                actions: html`<omega-button type="secondary" @click=${continueEditingAction}
                        >Continue Editing</omega-button
                    ><omega-button type="primary" @click=${backToStepOneAction}>OK</omega-button>`,
                title: 'Cancel',
            };
        };
    }

    @InjectProperty()
    private declare readonly navService: NavigationService;

    private _submit: () => Promise<void>;

    private _cancel: () => void;

    private _back: (record: Record<PaymentHeader>) => void;

    private _next: (record: Record<PaymentHeader>) => void;

    private _routeToBlankStepOne: (action: WorkflowActions) => void;

    backConfirmationAction: () => void;

    entitlements: WorkflowTypeEntitlements;

    workflow: Workflow;

    context: AchDomesticContainer;

    payment: PaymentHeader;

    private get _canEdit() {
        const editablePayment = this.payment.isEditable;
        const editPermissions = this.entitlements.edit.full || this.entitlements.edit.partial;
        return editablePayment && editPermissions;
    }

    private get _canPartialEdit() {
        const isEditPaymentFlow =
            this.workflow.action === 'edit' && this.workflow.type === 'payment';
        const hasPartialEditEntitlement =
            this.entitlements.edit.partial && !this.entitlements.edit.full;
        return isEditPaymentFlow && hasPartialEditEntitlement;
    }

    private get _batchContainer() {
        return this.context.shadowRoot?.querySelector('ach-domestic-batch') as AchDomesticBatch;
    }

    private get _steps(): Array<StepConfig> {
        return [
            {
                label: 'Create Payment',
                formHeading: 'Payment Header Information',
                step: 0,
                filterBarActions: [
                    {
                        label: 'Select From Recipient List',
                        type: 'secondary',
                        action: () => {
                            this.context.showSelectFromRecipientListDialog = true;
                        },
                        disabledWhen: (record: Record<PaymentHeader>) =>
                            !record.getField('achCompany') || this._canPartialEdit,
                    },
                    {
                        label: 'Import Recipients From File',
                        type: 'secondary',
                        action: () => RecipientFileUploadDialog.open(this.context),
                        disabledWhen: (record: Record<PaymentHeader>) =>
                            !record.getField('achCompany') || this._canPartialEdit,
                    },
                ],
                actions: [
                    {
                        label: 'Review',
                        type: 'primary',
                        action: (record: Record<PaymentHeader>) => this._next(record),
                        submit: true,
                        validation: true,
                    },
                    {
                        label: 'Cancel',
                        type: 'secondary',
                        action: () => this._cancel(),
                    },
                ],
            },
            {
                label: 'Review',
                formHeading: 'Review Payment',
                step: 1,
                actions: [
                    {
                        label: 'Confirm',
                        type: 'primary',
                        action: () => this._submit(),
                        submit: true,
                    },
                    {
                        label: 'Back',
                        type: 'secondary',
                        action: (record: Record<PaymentHeader>) => {
                            this._back(record);
                        },
                    },
                    {
                        label: 'Cancel',
                        type: 'secondary',
                        action: () => this._cancel(),
                    },
                ],
            },
            {
                label: 'Confirmation',
                formHeading: 'Payment Confirmation',
                step: 2,
                actions: [
                    {
                        label: 'Create Another Payment',
                        type: 'secondary',
                        action: () => {
                            this.context.routeToBlankCreateStepOne();
                        },
                    },
                    {
                        label: 'Save as a Template',
                        type: 'secondary',
                        action: () => this.context.promptSaveAsTemplate(),
                    },
                    {
                        label: 'ACH Payment Activity',
                        type: 'link',
                        action: () => this.context.routeToActivity(),
                    },
                ],
            },
        ];
    }

    private get _editSteps(): Array<StepConfig> {
        const steps = this._steps;
        steps[0].label = 'Edit Payment';
        steps[0].actions = [
            {
                label: 'Review',
                type: 'primary',
                action: (record: Record<PaymentHeader>) => this._next(record),
                submit: true,
                validation: true,
            },
            {
                label: 'Cancel',
                type: 'secondary',
                action: () => this._cancel(),
            },
        ];
        return steps;
    }

    private get _fileSteps(): Array<StepConfig> {
        const stepTwoLabel = () => {
            if (this.context.step === 0) return 'File Summary';
            return 'Payment Detail';
        };

        const steps = this._steps;
        const reviewStep = steps[1];
        reviewStep.step = 2;
        const confirmStep = steps[2];
        confirmStep.step = 3;

        return [
            {
                label: 'Upload File',
                formHeading: 'Upload Nacha Formatted File',
                step: 0,
                actions: [
                    {
                        label: 'Upload',
                        type: 'primary',
                        action: () => this.context.uploadNachaFile(),
                        submit: true,
                    },
                    {
                        label: 'Cancel',
                        type: 'secondary',
                        action: () => this._cancel(),
                    },
                ],
            },
            {
                label: stepTwoLabel(),
                formHeading: 'Payment Details',
                step: 1,
                actions: [
                    {
                        label: 'Review',
                        type: 'primary',
                        action: this._next,
                        submit: true,
                        validation: true,
                    },
                    {
                        label: 'Back',
                        type: 'secondary',
                        action: this.backConfirmationAction,
                    },
                    {
                        label: 'Cancel',
                        type: 'secondary',
                        action: () => this._cancel(),
                    },
                ],
            },
            reviewStep,
            confirmStep,
        ];
    }

    private get _batchSteps(): Array<StepConfig> {
        return [
            {
                label: 'Upload File',
                actions: [],
                submit: false,
                step: 0,
            },
            {
                label: 'File Summary',
                actions: [
                    {
                        label: 'Review',
                        type: 'primary',
                        action: record => {
                            this._batchContainer.next.apply(this._batchContainer);
                        },
                        submit: true,
                        disabledWhen: record => {
                            const recordIsValid = record.isValid();
                            const recordsetIsValid =
                                this._batchContainer.batchRecordset.invalidRecordCount() === 0;
                            const allRecordsHaveErrors =
                                this._batchContainer.batchRecordset.allRecords.every(
                                    batchRecord => {
                                        const errorSummary = batchRecord.getField('errorSummary');
                                        return !!errorSummary?.summaryMessageList.length;
                                    }
                                );
                            return !recordIsValid || !recordsetIsValid || allRecordsHaveErrors;
                        },
                    },
                    {
                        label: 'Back',
                        type: 'secondary',
                        action: this.backConfirmationAction,
                    },
                    {
                        label: 'Cancel',
                        type: 'secondary',
                        action: () => this._cancel(),
                    },
                ],
                submit: false,
                step: 1,
                filterBarActions: [
                    {
                        label: 'Review Selected',
                        type: 'secondary',
                        action: () => {
                            this._batchContainer.showReviewSelected();
                        },
                        submit: true,
                    },
                    {
                        label: 'Print Errors',
                        type: 'secondary',
                        action: () => {
                            this._batchContainer.printErrors();
                        },
                        disabledWhen: () => !this._batchContainer.errors,
                    },
                ],
            },
            {
                label: 'Review',
                actions: [
                    {
                        label: 'Confirm',
                        type: 'primary',
                        action: () => {
                            this._batchContainer.submit.apply(this._batchContainer);
                        },
                        submit: true,
                    },
                    {
                        label: 'Back',
                        type: 'secondary',
                        action: () => {
                            this._batchContainer.back.apply(this._batchContainer);
                        },
                    },
                    {
                        label: 'Cancel',
                        type: 'secondary',
                        action: () => this._cancel(),
                    },
                ],
                submit: true,
                step: 2,
            },
            {
                label: 'Confirmation',
                actions: [
                    {
                        label: 'Create Another Payment',
                        type: 'primary',
                        action: () => this.context.routeToBlankCreateStepOne(),
                        submit: true,
                    },
                    {
                        label: 'File Activity',
                        type: 'secondary',
                        action: () => this.navService.navigate('payables.ach.fileactivity-list'),
                    },
                ],
                step: 3,
            },
        ];
    }

    private get _initiateSteps(): Array<StepConfig> {
        return this._steps;
    }

    private get _initiateBatchSteps(): Array<StepConfig> {
        const base = this._steps;
        const batchBase = this._batchSteps;
        const step2 = {
            ...batchBase[1],
            label: 'Batch Summary',
        };
        return [base[0], step2, batchBase[2], batchBase[3]];
    }

    public get steps(): Array<StepConfig> {
        switch (this.workflow.action) {
            case WorkflowActions.View:
                return [
                    {
                        label: 'View Payment',
                        formHeading: `${this.payment.name}`,
                        step: 3,
                        actions: [
                            ...(this._canEdit
                                ? [
                                      {
                                          label: 'Edit Payment',
                                          type: 'primary' as ActionType,
                                          action: () => this.context.routeToEditPayment(),
                                      },
                                  ]
                                : []),
                            {
                                label: 'Close',
                                type: 'link',
                                action: () => this.context.routeToActivity(),
                            },
                        ],
                    },
                ];
            case WorkflowActions.InitiateFromTemplate:
                return this._steps;
            case WorkflowActions.InitiateFromFile:
                return this._fileSteps;
            case WorkflowActions.Edit:
                return this._editSteps;
            case WorkflowActions.InitiateBatchFromTemplates:
                return this._initiateBatchSteps;
            case WorkflowActions.InitiateBatchFromFile:
                return this._batchSteps;
            case WorkflowActions.Create:
            default:
                return this._steps;
        }
    }
}
