import { svg } from 'lit';

export const reportIcon = svg`
<svg width="15px" height="20px" viewBox="0 0 15 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <defs>
        <path d="M13.125,2.5 L10,2.5 C10,1.12109375 8.87890625,0 7.5,0 C6.12109375,0 5,1.12109375 5,2.5 L1.875,2.5 C0.83984375,2.5 0,3.33984375 0,4.375 L0,18.125 C0,19.1601562 0.83984375,20 1.875,20 L13.125,20 C14.1601562,20 15,19.1601562 15,18.125 L15,4.375 C15,3.33984375 14.1601562,2.5 13.125,2.5 Z M7.5,1.5625 C8.01953125,1.5625 8.4375,1.98046875 8.4375,2.5 C8.4375,3.01953125 8.01953125,3.4375 7.5,3.4375 C6.98046875,3.4375 6.5625,3.01953125 6.5625,2.5 C6.5625,1.98046875 6.98046875,1.5625 7.5,1.5625 Z M13.125,17.890625 C13.125,18.0195312 13.0195313,18.125 12.890625,18.125 L2.109375,18.125 C1.98046875,18.125 1.875,18.0195312 1.875,17.890625 L1.875,4.609375 C1.875,4.48046875 1.98046875,4.375 2.109375,4.375 L3.75,4.375 L3.75,5.78125 C3.75,6.0390625 3.9609375,6.25 4.21875,6.25 L10.78125,6.25 C11.0390625,6.25 11.25,6.0390625 11.25,5.78125 L11.25,4.375 L12.890625,4.375 C13.0195313,4.375 13.125,4.48046875 13.125,4.609375 L13.125,17.890625 Z" id="report-path-1"></path>
    </defs>
    <g id="Colors-/-Styles-/-Typography-/-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons" transform="translate(-831.000000, -44.000000)">
            <g id="Group" transform="translate(823.000000, 40.000000)">
                <g id="Color" transform="translate(8.000000, 4.000000)">
                    <mask id="report-mask-2" fill="white">
                        <use xlink:href="#report-path-1"></use>
                    </mask>
                    <use id="Mask" fill="#FFFFFF" fill-rule="nonzero" xlink:href="#report-path-1"></use>
                    <rect fill="#8B8D92" mask="url(#report-mask-2)" x="-5" y="-3" width="26" height="26"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>`;
