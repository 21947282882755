import { UserActivityService } from '@treasury/domain/channel/services/user-activity';

ActivityService.$inject = [];

// eslint-disable-next-line @treasury/filename-match-export
export default function ActivityService() {
    function navigationAudit(toState, fromState) {
        UserActivityService.navigationAudit(toState, fromState);
    }

    function userInteractionAudit(resourceType, auditCode) {
        UserActivityService.userInteractionAudit(resourceType, auditCode);
    }

    return {
        navigationAudit,
        userInteractionAudit,
    };
}
