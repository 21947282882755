FreeFormBeneficiaryController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'beneficiariesService',
    'modalService',
    '$modal',
    'bankService',
    'entitlementsService',
    '$location',
    'statesService',
    'countriesService',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'regexConstants',
    'wireCompaniesService',
    'securityService',
    'wireIsoService'
];

export default function FreeFormBeneficiaryController(
    $scope,
    $state,
    $stateParams,
    beneficiariesService,
    modalService,
    $modal,
    bankService,
    entitlementsService,
    $location,
    statesService,
    countriesService,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    regexConstants,
    wireCompaniesService,
    securityService,
    wireIsoService
) {
    $scope.wizardController = {};
    $scope.beneficiary = null;

    $scope.addIntermediaryBank = addIntermediaryBank;
    $scope.checkBankBeneficiaryState = checkBankBeneficiaryState;
    $scope.checkBeneficiaryAccountNumber = checkBeneficiaryAccountNumber;
    $scope.hasType = hasType;
    $scope.isInternational = isInternational;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.removeIntermediary = removeIntermediary;
    $scope.setBankIdType = setBankIdType;
    $scope.setForm = setForm;
    $scope.setToDomestic = setToDomestic;
    $scope.hideAudit = true;
    $scope.getCountry = getCountry;
    $scope.hasInternationalIntermediary = hasInternationalIntermediary;
    $scope.primaryDomesticBanks = {};
    $scope.intermediaryDomesticBanks = {};
    wireIsoService.getEntitlementWireISO20022().then(response => {
        $scope.wireIsoFF = response;
    })
    $scope.wireIsoFfIsOn = wireIsoFfIsOn;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    })

    // Entitlements
    $scope.canCreateDomesticBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Beneficiary'
    );
    $scope.canCreateInternationalBeneficiary = entitlementsService.hasEntitlement(
        'Wire, International, Create Beneficiary'
    );
    $scope.canCreateFxBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Fx, Create Beneficiary'
    );
    $scope.canEditDomesticBeneficiary = entitlementsService.hasEntitlement('Edit Beneficiary');
    $scope.canEditInternationalBeneficiary = entitlementsService.hasEntitlement(
        'Wire, International, Edit Beneficiary'
    );
    $scope.canEditFxBeneficiary = entitlementsService.hasEntitlement('Wire, Fx, Edit Beneficiary');

    $scope.selection = {};
    $scope.previousSelection = {};
    $scope.beneficiaryNameRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.showBankDialog = showBankDialog;
    $scope.InternationalPostalCodeRegex = regexConstants.InternationalPostalCodeRegex;
    $scope.DomesticPostalCodeRegex = regexConstants.DomesticPostalCodeRegex;
    $scope.DomesticPostalCodeExtRegex = regexConstants.DomesticPostalCodeExtRegex;
    $scope.BeneficiaryGeneralRegex = regexConstants.BeneficiaryGeneralRegex;
    $scope.DomesticRoutingNumberRegex = regexConstants.DomesticRoutingNumberRegex;
    $scope.onSelectDomesticBank = onSelectDomesticBank;
    $scope.onSelectDomesticIntermediaryBank = onSelectDomesticIntermediaryBank;
    $scope.resetDomesticBeneficiaryBank = resetDomesticBeneficiaryBank;
    $scope.useDomesticIntermediary = useDomesticIntermediary;
    $scope.domesticBanksValid = domesticBanksValid;
    $scope.domesticIntermediaryRequired = domesticIntermediaryRequired;
    $scope.isValidRoutingNumber = isValidRoutingNumber;
    $scope.domesticBanksDuplicated = domesticBanksDuplicated;
    $scope.addDomesticIntermediaryBank = addDomesticIntermediaryBank;
    $scope.addInternationalIntermediaryBank = addInternationalIntermediaryBank;
    $scope.continueFreeFormBeneficiary = continueFreeFormBeneficiary;
    $scope.backFreeFormBeneficiary = backFreeFormBeneficiary;
    $scope.deleteInternationalIntermediaryBank = deleteInternationalIntermediaryBank;
    $scope.hasDomesticIntermediary = hasDomesticIntermediary;
    $scope.continueFreeFormEntry = false;
    $scope.prevFreeFormValid = false;
    $scope.nextFreeFormValid = false;
    $scope.isBackFormValid = isBackFormValid;

    $scope.$watch('freeFormBeneficiaryInfo.$valid', newValue => {
        if (!$scope.continueFreeFormEntry) {
            $scope.prevFreeFormValid = newValue;
        }
        $scope.wire.isFreeFormBeneficiaryValid = $scope.wire.isInternational
            ? $scope.prevFreeFormValid && $scope.nextFreeFormValid
            : $scope.prevFreeFormValid;
    });

    $scope.$watch('continueFreeFormBeneficiaryInfo.$valid', newValue => {
        if ($scope.wire.isInternational) {
            if ($scope.continueFreeFormEntry) {
                $scope.nextFreeFormValid = newValue;
            } else {
                $scope.nextFreeFormValid =
                    $scope.continueFreeFormBeneficiaryInfo.$pristine &&
                    !!$scope.beneficiary.bank &&
                    !!$scope.beneficiary.bank.bankId;
            }
            $scope.wire.isFreeFormBeneficiaryValid =
                $scope.prevFreeFormValid && $scope.nextFreeFormValid;
        }
    });

    function wireIsoFfIsOn() {
        console.log($scope.wireIsoFF);
        return $scope.wireIsoFF;
    }

    function deleteInternationalIntermediaryBank() {
        $scope.beneficiary.intermediaryBanks.splice(1, 1);
    }

    function continueFreeFormBeneficiary() {
        $scope.continueFreeFormEntry = true;
    }

    function backFreeFormBeneficiary() {
        $scope.continueFreeFormEntry = false;
    }

    function isBackFormValid() {
        return (
            $scope.nextFreeFormValid ||
            ($scope.continueFreeFormBeneficiaryInfo.$pristine &&
                !!$scope.beneficiary.bank &&
                !!$scope.beneficiary.bank.bankId)
        );
    }

    function addDomesticIntermediaryBank() {
        $scope.beneficiary.intermediaryBanks.push({ isInternational: false });
    }

    function addInternationalIntermediaryBank() {
        $scope.beneficiary.intermediaryBanks[1] = {
            isInternational: true,
            bankIdType: 'Swift Code',
        };
    }

    function useDomesticIntermediary() {
        let useIntermediary = false;
        if (!isInternational() && !!$scope.beneficiary && !!$scope.beneficiary.bank) {
            useIntermediary =
                !$scope.beneficiary.bank.id &&
                (!!$scope.beneficiary.bank.bankId ||
                    !!$scope.beneficiary.bank.state ||
                    !!$scope.beneficiary.bank.city ||
                    !!$scope.beneficiary.bank.name);
        }
        return useIntermediary;
    }

    function domesticBanksValid() {
        let isValid;
        // Because of how a domestic bank and an intermediary bank is conditionally displayed, this check is way more complicated then it should be
        isValid = $scope.freeFormBeneficiaryInfo.bankId
            ? $scope.freeFormBeneficiaryInfo.bankId.$valid
            : false;
        isValid =
            isValid &&
            !!$scope.freeFormBeneficiaryInfo.BeneficiaryBankName &&
            $scope.freeFormBeneficiaryInfo.beneficiaryBankName.$valid &&
            !!$scope.freeFormBeneficiaryInfo.beneficiaryBankCity &&
            $scope.freeFormBeneficiaryInfo.beneficiaryBankCity.$valid &&
            !!$scope.freeFormBeneficiaryInfo.beneficiaryBankState &&
            $scope.freeFormBeneficiaryInfo.beneficiaryBankState.$valid;
        if ($scope.freeFormBeneficiaryInfo.intermediaryBankId) {
            isValid = isValid && $scope.freeFormBeneficiaryInfo.intermediaryBankId.$valid;
        }
        return isValid;
    }

    function domesticBanksDuplicated() {
        const domInter = getDomesticIntermediary();
        return domInter && $scope.beneficiary.bank.bankId === domInter.bankId;
    }

    function resetDomesticBeneficiaryBank() {
        $scope.beneficiary.intermediaryBanks = [];
        $scope.primaryDomesticBanks = $scope.beneficiaryBanks;
        evaluateNewRoutingNumber($scope.beneficiary.bank.bankId);
        $scope.form.$setDirty();
    }

    function onSelectDomesticBank(bank) {}

    function onSelectDomesticIntermediaryBank(bank) {}

    (function () {
        // init

        // Incoming free form beneficiary from create wire controller
        if (!!$scope.wire && !!$scope.wire.freeFormBeneficiary) {
            $scope.beneficiary = $scope.wire.freeFormBeneficiary;
        }

        getStates();
        getCountries();
        getBanks();
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;
        });
    })();

    function getBanks() {
        bankService.getAllBanks().then(response => {
            $scope.beneficiaryBanks = response;
            $scope.primaryDomesticBanks = response;
            $scope.intermediaryDomesticBanks = response;
        });
    }

    function checkBeneficiaryAccountNumber(blurred) {
        let isValid;

        if (blurred) {
            $scope.confirmFieldBlurred = true;
        }
        isValid =
            $scope.beneficiary.accountNumber === $scope.beneficiary.confirmAccountNumber ||
            !$scope.confirmFieldBlurred;
        $scope.freeFormBeneficiaryInfo.confirmAccountNumber.$setValidity('doesNotMatch', isValid);
    }

    function hasType() {
        return $scope.beneficiary && $scope.beneficiary.isInternational !== undefined;
    }

    function isInternational() {
        return $scope.wire && $scope.wire.isInternational;
    }

    function useThreeLineAddress() {
        return beneficiariesService.canUseThreeLineAddress($scope.beneficiary);
    }

    function getStates() {
        statesService.getAll().then(response => {
            $scope.states = response;
        });
    }

    function getCountries() {
        countriesService.getAll().then(response => {
            $scope.countries = response;
        });
    }

    $scope.$watch('beneficiary.postalCode', newValue => {
        if (
            newValue !== null &&
            newValue !== undefined &&
            newValue.length === 5 &&
            isNaN(newValue) === false
        ) {
            $scope.beneficiary.postalCodeValid = true;
        }
    });

    $scope.$watch('beneficiary.bank.bankId', (newValue, oldValue) => {
        evaluateNewRoutingNumber(newValue);
    });

    $scope.$watch('beneficiary.intermediaryBanks[0].bankId', (newValue, oldValue) => {
        if (
            !!$scope.beneficiary &&
            !!$scope.beneficiary.intermediaryBanks &&
            $scope.beneficiary.intermediaryBanks.length > 0 &&
            !!$scope.beneficiary.intermediaryBanks[0]
        ) {
            processDomesticIntermediaryBank(0, newValue);
        }
    });

    function evaluateNewRoutingNumber(newValue) {
        if (!!$scope.beneficiaryBanks && $scope.beneficiaryBanks.length > 0 && !isInternational()) {
            const results = $scope.primaryDomesticBanks.filter(bank => bank.bankId === newValue);
            if (results.length === 1) {
                $scope.beneficiary.bank.id = results[0].id;
                $scope.beneficiary.bank.city = results[0].city;
                $scope.beneficiary.bank.state = results[0].state;
                $scope.beneficiary.bank.name = results[0].name;
                $scope.intermediaryDomesticBanks = $scope.beneficiaryBanks.filter(
                    bank => bank.bankId !== $scope.beneficiary.bank.bankId
                );
            } else if (
                !!$scope.beneficiary &&
                !!$scope.beneficiary.bank &&
                !!$scope.beneficiary.bank.id
            ) {
                $scope.beneficiary.bank.id = '';
                $scope.beneficiary.bank.city = '';
                $scope.beneficiary.bank.state = '';
                $scope.beneficiary.bank.name = '';
            }
        }
    }
    function processDomesticIntermediaryBank(index, bankId) {
        if ($scope.beneficiaryBanks) {
            const results = $scope.intermediaryDomesticBanks.filter(bank => bank.bankId === bankId);
            if (results.length === 1) {
                $scope.beneficiary.intermediaryBanks[index].id = results[0].id;
                $scope.beneficiary.intermediaryBanks[index].city = results[0].city;
                $scope.beneficiary.intermediaryBanks[index].state = results[0].state;
                $scope.beneficiary.intermediaryBanks[index].name = results[0].name;
                $scope.beneficiary.intermediaryBanks[index].isInternational = false;
                $scope.primaryDomesticBanks = $scope.beneficiaryBanks.filter(
                    bank => bank.bankId !== results[0].bankId
                );
            }
        }
    }

    function setToDomestic() {
        $scope.beneficiary.isInternational = false;
        $scope.beneficiary.country = 'US';
        if (!$scope.beneficiary.bank) {
            $scope.beneficiary.bank = { bankIdType: undefined };
        }
    }

    function setToInternational() {
        $scope.beneficiary.isInternational = true;
        $scope.beneficiary.isForeignCurrency = false;
        if (!$scope.beneficiary.bank) {
            $scope.beneficiary.bank = { bankIdType: 'Swift Code' };
        }
    }

    function showBankDialog(index) {
        const modalInstance = $modal.open({
            template: require('../../beneficiaries/views/bankDialogView.html'),
            size: 'md',
            controller: 'BankDialogController',
            backdrop: 'static',
            resolve: {
                banks() {
                    return $scope.beneficiaryBanks;
                },
                beneficiary() {
                    return $scope.beneficiary;
                },
            },
        });
        modalInstance.result.then(response => {
            let tempId;

            if (index !== undefined) {
                $scope.beneficiary.intermediaryBanks[index] = response;
            } else {
                $scope.beneficiary.bank = response;
            }

            if (response) {
                $scope.form.$setDirty();
            }
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function setBankIdType(intermediaryBank, type) {
        if (intermediaryBank !== null) {
            const index = $scope.beneficiary.intermediaryBanks.indexOf(intermediaryBank);
            $scope.beneficiary.intermediaryBanks[index].bankIdType = type;
        } else {
            $scope.beneficiary.bank.bankIdType = type;
        }
    }

    function checkBankBeneficiaryState() {
        let disable = false;
        if (!!$scope.beneficiary && (!$scope.beneficiary.bank || !$scope.beneficiary.bank.bankId)) {
            disable = true;
        } else if (
            !!$scope.beneficiary &&
            $scope.beneficiary.isInternational &&
            !$scope.beneficiary.isForeignCurrency &&
            $scope.wireConfig.fiInternationalUsdEnabled &&
            !hasDomesticIntermediary()
        ) {
            disable = true;
        }
        return disable;
    }

    function hasDomesticIntermediary() {
        return (
            !!$scope.beneficiary &&
            !$scope.beneficiary.isInternational &&
            !!$scope.beneficiary.intermediaryBanks &&
            $scope.beneficiary.intermediaryBanks.length === 1
        );
    }

    function addIntermediaryBank(type) {
        if (type === 'international') {
            $scope.beneficiary.intermediaryBanks.push({
                isInternational: true,
                bankIdType: 'Swift Code',
            });
        } else {
            $scope.beneficiary.intermediaryBanks.push({ isInternational: false });
        }
    }

    function removeIntermediary(bank) {
        const modalOptions = {
            headerText: 'Confirm Deletion',
            bodyText: 'Are you sure you want to delete this Intermediary Bank?',
            actionButtonText: 'Delete Intermediary Bank',
            closeButtonText: 'Cancel',
            submit(result) {
                removeIntermediaryBank(bank);
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function removeIntermediaryBank(bank) {
        const index = $scope.beneficiary.intermediaryBanks.indexOf(bank);
        $scope.beneficiary.intermediaryBanks.splice(index, 1);
    }

    function getCountry(countryCode) {
        let result = null;
        window.angular.forEach($scope.countries, country => {
            if (country.countryCode === countryCode) {
                result = country.countryName;
            }
        });
        return result;
    }

    function hasInternationalIntermediary() {
        let result = false;
        result =
            !!$scope.beneficiary &&
            !!$scope.beneficiary.intermediaryBanks &&
            $scope.beneficiary.intermediaryBanks.length == 2;
        return result;
    }

    function getDomesticIntermediary() {
        let result = null;
        if (!$scope.beneficiary) {
            return result;
        }
        window.angular.forEach($scope.beneficiary.intermediaryBanks, intermediaryBank => {
            if (!intermediaryBank.isInternational) {
                result = intermediaryBank;
            }
        });
        return result;
    }

    function domesticIntermediaryRequired() {
        return (
            $scope.beneficiary &&
            $scope.beneficiary.bank &&
            $scope.freeFormBeneficiaryInfo.bankId &&
            $scope.freeFormBeneficiaryInfo.bankId.$valid &&
            !$scope.beneficiary.bank.id
        );
    }

    function isValidRoutingNumber() {
        if (
            $scope.freeFormBeneficiaryInfo.bankId &&
            $scope.freeFormBeneficiaryInfo.bankId.$viewValue
        ) {
            if (
                !beneficiariesService.isValidRoutingNumber($scope.freeFormBeneficiaryInfo.bankId.$viewValue) || !$scope.beneficiaryBanks.find(bank => bank.bankId === $scope.freeFormBeneficiaryInfo.bankId.$viewValue)
            ) {
                $scope.freeFormBeneficiaryInfo.bankId.$setValidity('InvalidRoutingNumber', hasDomesticIntermediary());
                return false;
            }
            $scope.freeFormBeneficiaryInfo.bankId.$setValidity('InvalidRoutingNumber', true);
        }

        return true;
    }
}
