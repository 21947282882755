export default [
    function () {
        return {
            restrict: 'A',
            scope: {},
            link,
        };
        function link(scope, element, attrs) {
            element.on('click', event => {
                $('.navbar-collapse.in').collapse('hide');
            });
        }
    },
];
