// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant(
    'achPaymentTypes',
    Object.freeze({
        AchPayment: 1,
        AchStateTaxPayment: 2,
        AchFederalTaxPayment: 3,
    })
);
