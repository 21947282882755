CreateTaxTemplateController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    '$controller',
    'statesService',
    'taxesService',
    'achPaymentTypes',
    'scopeService',
];

export default function CreateTaxTemplateController(
    $scope,
    $state,
    $uibModal,
    $controller,
    statesService,
    taxesService,
    achPaymentTypes,
    scopeService
) {
    angular.extend(this, $controller('CreateBatchController', { $scope }));
    const maximumNumberOfAmounts = 3;
    const firstAccountTypeId = 1;
    const firstStateAmountTypeId = 1;

    $scope.stateTaxCodes = [];
    $scope.federalTaxAmountTypes = [];

    $scope.isObject = isObject;
    $scope.stateChanged = stateChanged;
    $scope.stateUndefined = stateUndefined;
    $scope.findStateTaxCodes = findStateTaxCodes;
    $scope.findFederalTaxCodes = findFederalTaxCodes;
    $scope.findStates = findStates;
    $scope.stateTaxCodesLoaded = stateTaxCodesLoaded;
    $scope.federalTaxCodesLoaded = federalTaxCodesLoaded;
    $scope.stateTaxCodeChanged = stateTaxCodeChanged;
    $scope.federalTaxCodeChanged = federalTaxCodeChanged;
    $scope.addAmountType = addAmountType;
    $scope.deleteAmountType = deleteAmountType;
    $scope.addFederalAmountType = addFederalAmountType;
    $scope.deleteFederalAmountType = deleteFederalAmountType;
    $scope.onSelectBank = onSelectBank;
    $scope.statesLoaded = statesLoaded;
    $scope.isValidTaxCode = isValidTaxCode;
    $scope.batch.recipientAccountTypeId = firstAccountTypeId;
    $scope.getStateAmountTypeDescription = getStateAmountTypeDescription;
    $scope.getFederalAmountTypeDescription = getFederalAmountTypeDescription;
    $scope.getRecipientAccountTypeDescription = getRecipientAccountTypeDescription;
    $scope.getStateLongName = getStateLongName;
    $scope.createAnotherTaxTemplate = createAnotherTaxTemplate;
    $scope.canFullEdit = canFullEdit;
    $scope.federalAmountTypeChanged = federalAmountTypeChanged;
    $scope.getTaxCodeDescription = getTaxCodeDescription;

    function federalAmountTypeChanged(index) {
        const { amountType } = $scope.batch.achFederalTaxPaymentAmounts[index];

        if ($scope.federalTaxAmountTypes && $scope.federalTaxAmountTypes.length) {
            $scope.batch.achFederalTaxPaymentAmounts[index].achFederalAmountTypeId =
                taxesService.getFederalAmountType(
                    $scope.federalTaxAmountTypes,
                    amountType
                ).achFederalAmountTypeId;
        }
    }

    function onSelectBank(bank) {
        if (bank) {
            $scope.batch.recipientRoutingNumber = bank.bankId;
        }
    }

    function getTaxCodeDescription() {
        let taxCode;

        if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            if ($scope.federalTaxCodes) {
                taxCode = $scope.federalTaxCodes.find(
                    federalTaxCode => federalTaxCode.taxCode === $scope.batch.taxCode
                );
            }
        } else if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            if ($scope.stateTaxCodes) {
                taxCode = $scope.stateTaxCodes.find(
                    stateTaxCode => stateTaxCode.taxCode === $scope.batch.taxCode
                );
            }
        }

        if (taxCode) {
            return taxCode.description;
        }

        return '';
    }

    function getStateAmountTypeDescription(achStateAmountTypeId) {
        return taxesService.getStateAmountTypeDescription(
            $scope.stateTaxAmountTypes,
            achStateAmountTypeId
        );
    }

    function getFederalAmountTypeDescription(amountType) {
        return taxesService.getFederalAmountTypeDescription(
            $scope.federalTaxAmountTypes,
            amountType
        );
    }

    function getRecipientAccountTypeDescription(recipientAccountTypeId) {
        return taxesService.getRecipientAccountTypeDescription(
            $scope.recipientAccountTypes,
            recipientAccountTypeId
        );
    }

    function addAmountType() {
        if ($scope.batch.achStateTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        $scope.batch.achStateTaxPaymentAmounts.push({
            achStateAmountTypeId: firstStateAmountTypeId,
            amount: 0,
        });
    }

    function deleteAmountType(index) {
        $scope.batch.achStateTaxPaymentAmounts.splice(index, 1);
    }

    function addFederalAmountType() {
        if ($scope.batch.achFederalTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        $scope.batch.achFederalTaxPaymentAmounts.push({
            achFederalAmountTypeId: $scope.federalTaxAmountTypes[0].achFederalAmountTypeId,
            amountType: $scope.federalTaxAmountTypes[0].amountType,
            amount: 0,
        });
    }

    function deleteFederalAmountType(index) {
        $scope.batch.achFederalTaxPaymentAmounts.splice(index, 1);
    }

    function getStateLongName(id) {
        if (!($scope.states && $scope.states.length)) return '';

        const selected = $scope.states.find(item => item.id === id);

        return selected ? selected.value : '';
    }

    function getStateFromList(state) {
        return $scope.states.find(
            item =>
                state &&
                (item.key.toLowerCase() === state.toLowerCase() ||
                    item.value.toLowerCase() === state.toLowerCase())
        );
    }

    function stateChanged(state) {
        if (!statesLoaded()) return;

        if (!(state && state.id)) {
            const selected = getStateFromList(state);

            if (selected) {
                $scope.batch.selectedState = selected;
                $scope.batch.stateId = selected.id;

                getStateTaxCodes($scope.batch.stateId);
                return;
            }

            $scope.batch.stateId = '';

            $scope.batch.selectedStateTaxCode = '';
            $scope.batch.taxCode = '';
            return;
        }

        if (
            $scope.batch.selectedState &&
            $scope.batch.selectedState.key == state.key &&
            $scope.batch.stateId == state.id
        ) {
            return;
        }

        $scope.batch.selectedState = state;
        $scope.batch.stateId = state.id;

        getStateTaxCodes($scope.batch.stateId);
    }

    function federalTaxCodeChanged(federalTaxCode) {
        if (!federalTaxCodesLoaded()) return;

        if (!(federalTaxCode && federalTaxCode.taxCode)) {
            if (isValidTaxCode(federalTaxCode)) {
                $scope.batch.taxCode = federalTaxCode;
                $scope.batch.selectedFederalTaxCode = federalTaxCode;

                const selected = $scope.federalTaxCodes.find(
                    item => item.taxCode === federalTaxCode
                );
                if (selected) {
                    $scope.batch.selectedFederalTaxCode = selected;
                    $scope.batch.taxCode = federalTaxCode.taxCode;

                    getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode);
                    return;
                }
            } else {
                $scope.batch.taxCode = '';
            }

            if ($scope.federalTaxAmountTypes) $scope.federalTaxAmountTypes.length = 0;

            return;
        }

        if (isSelectedTaxCode(federalTaxCode)) {
            return;
        }

        $scope.batch.selectedFederalTaxCode = federalTaxCode;
        $scope.batch.taxCode = federalTaxCode.taxCode;

        getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode);
    }

    function isSelectedTaxCode(federalTaxCode) {
        return (
            $scope.batch.selectedFederalTaxCode &&
            $scope.batch.selectedFederalTaxCode.taxCode == federalTaxCode.taxCode &&
            $scope.batch.taxCode == federalTaxCode.taxCode
        );
    }

    function stateTaxCodeChanged(stateTaxCode) {
        if (!(stateTaxCode && stateTaxCode.taxCode)) {
            if (isValidTaxCode(stateTaxCode)) {
                $scope.batch.taxCode = stateTaxCode;
                $scope.batch.selectedStateTaxCode = stateTaxCode;
            } else {
                $scope.batch.taxCode = '';
                $scope.batch.selectedStateTaxCode = '';
            }

            return;
        }

        $scope.batch.selectedStateTaxCode = stateTaxCode;
        $scope.batch.taxCode = stateTaxCode.taxCode;
    }

    function getStateTaxCodes(stateId, taxCode) {
        return taxesService.getStateTaxCodes(stateId).then(response => {
            $scope.stateTaxCodes = response;

            stateTaxCodeChanged(taxCode);
        });
    }

    function getFederalTaxCodes() {
        taxesService.getFederalTaxCodes().then(response => {
            $scope.federalTaxCodes = response;
        });
    }

    function getStateTaxAmountTypes() {
        taxesService.getStateTaxAmountTypes().then(response => {
            $scope.stateTaxAmountTypes = response;
        });
    }

    function getFederalTaxAmountTypes(selectedFederalTaxCode) {
        taxesService.getFederalTaxAmountTypes(selectedFederalTaxCode.id).then(response => {
            $scope.federalTaxAmountTypes = response;
            if ($scope.federalTaxAmountTypes && $scope.federalTaxAmountTypes.length)
                $scope.batch.achFederalTaxPaymentAmounts = [
                    {
                        achFederalAmountTypeId:
                            $scope.federalTaxAmountTypes[0].achFederalAmountTypeId,
                        amountType: $scope.federalTaxAmountTypes[0].amountType,
                        amount: 0,
                    },
                ];
            else
                $scope.batch.achFederalTaxPaymentAmounts = [
                    {
                        achFederalAmountTypeId: null,
                        amountType: selectedFederalTaxCode.taxCode,
                        amount: 0,
                    },
                ];
        });
    }

    function getRecipientAccountTypes() {
        taxesService.getRecipientAccountTypes().then(response => {
            $scope.recipientAccountTypes = response;
        });
    }

    function findStateTaxCodes() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.stateTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedStateTaxCode => {
            $scope.batch.selectedStateTaxCode = angular.copy(selectedStateTaxCode);
            $scope.batch.taxCode = selectedStateTaxCode.taxCode;
        });
    }

    function findFederalTaxCodes() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.federalTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedFederalTaxCode => {
            $scope.batch.selectedFederalTaxCode = angular.copy(selectedFederalTaxCode);
        });
    }

    function findStates() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/selectStatesModalView.html'),
            size: 'md',
            controller: 'SelectStatesController',
            backdrop: 'static',
            resolve: {
                states() {
                    return $scope.states;
                },
            },
        });
        modalInstance.result.then(selectedState => {
            $scope.batch.selectedState = angular.copy(selectedState);
        });
    }

    function isObject(value) {
        return angular.isObject(value);
    }

    function isValidTaxCode(value) {
        return taxesService.isValidTaxCode(value);
    }

    function stateUndefined() {
        return !$scope.batch.stateId;
    }

    function stateTaxCodesLoaded() {
        return $scope.stateTaxCodes && $scope.stateTaxCodes.length;
    }

    function federalTaxCodesLoaded() {
        return $scope.federalTaxCodes && $scope.federalTaxCodes.length;
    }

    function statesLoaded() {
        return $scope.states && $scope.states.length;
    }

    function getStates() {
        statesService.getAll().then(response => {
            $scope.states = response;
        });
    }

    function initStateTaxPaymentAmounts() {
        $scope.batch.achStateTaxPaymentAmounts = [
            { achStateAmountTypeId: firstStateAmountTypeId, amount: 0 },
        ];
    }

    function createAnotherTaxTemplate() {
        $state.go('payables.ach.batch.createTaxTemplate');
    }

    function canFullEdit() {
        return true;
    }

    function configureTaxPayments(achSettings) {
        if (!achSettings) return;

        if (achSettings.allowFederalTaxPayments) {
            $scope.batch.achPaymentTypeId = achPaymentTypes.AchFederalTaxPayment;
        } else if (achSettings.allowStateTaxPayments) {
            $scope.batch.achPaymentTypeId = achPaymentTypes.AchStateTaxPayment;
        }
    }

    function whenDefined(expression, callback) {
        scopeService.whenDefined(expression, callback, $scope);
    }

    function setAchPaymentType() {
        if (!$scope.batch.achPaymentTypeId) {
            whenDefined('achSettings', () => {
                configureTaxPayments($scope.achSettings);
            });
        }
    }

    function setState(stateId, taxCode) {
        whenDefined('states', () => {
            $scope.batch.selectedState = $scope.states.find(state => stateId === state.id);

            if ($scope.batch.selectedState) {
                getStateTaxCodes(stateId, taxCode);
            }
        });
    }

    function setFederalAmountTypes(taxCode) {
        whenDefined('federalTaxCodes', () => {
            if (taxCode) {
                $scope.batch.selectedFederalTaxCode = $scope.federalTaxCodes.find(
                    item => item.taxCode === taxCode
                );

                if ($scope.batch.selectedFederalTaxCode) {
                    taxesService
                        .getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode.id)
                        .then(response => {
                            $scope.federalTaxAmountTypes = response;
                        });
                }
            }
        });
    }

    function setFieldsForExistingBatch() {
        whenDefined('batch.id', () => {
            setState($scope.batch.stateId, $scope.batch.taxCode);
            setFederalAmountTypes($scope.batch.taxCode);
        });
    }

    (function () {
        // init
        getStates();
        getFederalTaxCodes();
        initStateTaxPaymentAmounts();
        getStateTaxAmountTypes();
        getRecipientAccountTypes();
        setAchPaymentType();
        setFieldsForExistingBatch();
    })();
}
