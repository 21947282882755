ApproveOrRejectWireController.$inject = ['$scope', '$modalInstance', 'type', 'listType', 'isDli'];

export default function ApproveOrRejectWireController(
    $scope,
    $modalInstance,
    type,
    listType,
    isDli
) {
    $scope.cancel = cancel;
    $scope.type = type;
    $scope.save = save;
    $scope.isDliReject = isDliReject;
    $scope.setForm = setForm;
    $scope.listType = listType;
    $scope.isDli = isDli;

    function isDliReject() {
        return $scope.isDli === true && $scope.type === 'Reject';
    }

    // this will dismiss the window.
    function cancel() {
        $modalInstance.dismiss();
    }

    // this will update the company
    function save() {
        $modalInstance.close($scope.comments);
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }
}
