import { isValidDate } from './index';

const isValidRepeatOnEnd = (frequency: any) => {
    if (!frequency) return false;

    // if (logging) console.log(`valid repeat on end date: ${result}`);

    // Note: isValidDate allows for argument to be number or string ->
    // last two checks will allow for non-integer values to be accepted
    return (
        frequency.repeatOnLastBusinessDay ||
        isValidDate(frequency.repeatOnDay2) ||
        Number.isInteger(frequency.repeatOnDay2)
    );
};

export default isValidRepeatOnEnd;
