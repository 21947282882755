import { getChannelAPIPath } from '@treasury/core/http';

AchBatchImportsService.$inject = ['$resource', '$q', '$http'];

export default function AchBatchImportsService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}achBatchImports`;

    return {
        create,
        update,
    };

    function create(nachaData) {
        return $resource(
            resourceUrl,
            {},
            {
                save: {
                    method: 'POST',
                    transformRequest: tranformParseIssuedItemsRequest,
                    headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' },
                },
            }
        ).save(nachaData).$promise;
    }

    //
    // Private

    function tranformParseIssuedItemsRequest(data) {
        const fd = new FormData();
        fd.append('file', data.file);
        return fd;
    }

    function update(batches) {
        const resource = $resource(`${resourceUrl}`, {}, { update: { method: 'PUT' } });
        return resource.update(batches).$promise;
    }
}
