/* eslint-disable lit-a11y/click-events-have-key-events */
import { css, html, LitElement } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';

class OmegaFlyout extends LitElement {
    static get properties() {
        return {
            open: {
                type: Boolean,
            },
            title: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.open = true;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-flyout--default',
        };
    }

    isFlyoutOpen() {
        return this.open !== false;
    }

    toggleSearchBar() {
        this.open = !this.open;
    }

    setPageTitle(pageState) {
        return html`
            <p class="omega-flyout-title omega-flyout-title-${pageState}">${this.title}</p>
        `;
    }

    renderPageOpen() {
        return html`
            <div class="omega-flyout-open">
                ${this.setPageTitle('open')}
                <i
                    @click="${this.toggleSearchBar}"
                    class="fa fa-chevron-left omega-flyout-icon-open"
                    aria-label="close search panel"
                ></i>
            </div>
            <div class="omega-slotted-content">
                <slot></slot>
            </div>
        `;
    }

    renderPageCollapsed() {
        return html`
            <div class="omega-flyout-collapsed">
                <i
                    @click="${this.toggleSearchBar}"
                    class="fa fa-chevron-right omega-flyout-icon-collapsed"
                    aria-label="open search panel"
                ></i>
                ${this.setPageTitle('collapsed')}
            </div>
        `;
    }

    render() {
        return html`<div class="omega-flyout">
            ${this.isFlyoutOpen() ? this.renderPageOpen() : this.renderPageCollapsed()}
        </div>`;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                    height: 100%;
                    overflow-y: hidden;
                }
                .omega-flyout {
                    background: var(--omega-secondary-lighten-400);
                    height: 100%;
                    width: 100%;
                    border-right: 1px solid var(--omega-secondary-lighten-300);
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }
                .omega-flyout-open {
                    display: flex;
                    font-size: 16px;
                    padding: 0 10px;
                    border-bottom: 1px solid var(--omega-secondary-lighten-300);
                    justify-content: space-between;
                    align-items: center;
                    height: 55px;
                }
                .omega-flyout-header-open {
                    font-size: 14px;
                    padding: 10px;
                    border-bottom: 1px solid var(--omega-secondary-lighten-300);
                }
                .omega-flyout-title {
                    display: inline;
                    white-space: nowrap;
                    overflow-x: ellipsis;
                }
                .omega-flyout-title-open {
                    font-weight: bold;
                }
                .omega-flyout-icon-open {
                    cursor: pointer;
                    justify-content: center;
                    padding-left: 100px;
                }
                .omega-flyout-collapsed {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 16px;
                    max-width: 40px;
                }
                .omega-flyout-icon-collapsed {
                    cursor: pointer;
                    padding-top: 15px;
                }
                .omega-flyout-title-collapsed {
                    text-align: right;
                    writing-mode: vertical-rl;
                    text-orientation: mixed;
                    transform: rotate(180deg);
                    padding-bottom: 10px;
                }
                .omega-slotted-content {
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 55px);
                }
                @media print {
                    .omega-no-print {
                        display: none;
                    }
                }
            `,
        ];
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define('omega-flyout', OmegaFlyout);
export default OmegaFlyout;
