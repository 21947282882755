export default class PhoneNumber {
    constructor(input = '') {
        if (typeof input === 'string') {
            const digits = input.replace(/-/g, '');
            this.areaCode = digits.substring(0, 3);
            this.prefix = digits.substring(3, 6);
            this.lineNumber = digits.substring(6, 10);
        } else {
            [this.areaCode, this.prefix, this.lineNumber] = input;
        }
    }

    get digits() {
        return `${this.areaCode}${this.prefix}${this.lineNumber}`;
    }

    get isNotBlank() {
        return this.toString().length > 0;
    }

    withAreaCode(areaCode) {
        return new PhoneNumber([areaCode, this.prefix, this.lineNumber]);
    }

    withPrefix(prefix) {
        return new PhoneNumber([this.areaCode, prefix, this.lineNumber]);
    }

    withLineNumber(lineNumber) {
        return new PhoneNumber([this.areaCode, this.prefix, lineNumber]);
    }

    get isValid() {
        return /\d{10}/.test(this.digits);
    }

    toString() {
        if (this.digits.length === 10) {
            return `${this.areaCode}-${this.prefix}-${this.lineNumber}`;
        }
        return this.digits;
    }

    copy() {
        return new PhoneNumber([this.areaCode, this.prefix, this.lineNumber]);
    }
}
