import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

export const mapAchTaxTemplatesFieldsToParameters = body => {
    const { parameters } = body;
    const dateType = DateFormatters.getDateType(parameters.updatedDate);
    const getDateValue = DateFormatters.parseDate(parameters.updatedDate).value;
    return {
        ...parameters,
        creditAmountType: parameters.creditAmount[0] === 'range' ? 'Range' : 'Specific Amount',
        creditAmount: AmountRangeFormatters.getSpecificAmount(parameters.creditAmount),
        creditAmountMin: AmountRangeFormatters.getRangeStart(parameters.creditAmount),
        creditAmountMax: AmountRangeFormatters.getRangeEnd(parameters.creditAmount),
        updatedDate: dateType === 'specificDate' ? getDateValue : null,
        updatedDateType: dateType === 'dateRange' ? 'Range' : 'Specific Date',
        lastUpdatedEnd: dateType === 'dateRange' ? getDateValue.end : null,
        lastUpdatedStart: dateType === 'dateRange' ? getDateValue.start : null,
    };
};

export const mapRecordValuesToKeyValuePair = recordsValues =>
    recordsValues.map(record => ({ key: record.id, value: '' }));

export const mapActionToActionType = action =>
    action === 'approve' ? 'Approve Batch' : 'Reject Batch';
