import { getChannelAPIPath } from '@treasury/core/http';

WausauStatementsService.$inject = ['$resource', '$http'];

export default function WausauStatementsService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}users/`;

    return {
        getAdditionalSettingsAndAccounts,
        getReportList,
        getReport,
    };

    function getAdditionalSettingsAndAccounts(electronicDocumentTypeId) {
        return $resource(
            `${resourceUrl}wausaustatements/` + `:id/additionalsettingsandaccounts`,
            { id: electronicDocumentTypeId },
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }

    function getReportList(productName, accountId) {
        return $resource(
            `${getChannelAPIPath()}wausaustatements/` + `:name/reportlist/:id`,
            {
                name: productName,
                id: accountId,
            },
            { get: { method: 'GET', isArray: true } }
        ).get().$promise;
    }

    function getReport(productName, reportId, reportName) {
        return $http.post(
            `${getChannelAPIPath()}wausaustatements/download`,
            {
                productName,
                reportId,
                reportName,
            },
            {
                responseType: 'arraybuffer',
            }
        );
    }
}
