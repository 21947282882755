export default function recipientsTable() {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            batch: '=',
            isEditView: '=',
            banks: '=',
            type: '@',
            controller: '=',
            readOnly: '=',
            enableSaveRecipients: '=?',
            onRecipientsImported: '&?',
            isManageRecipients: '=',
        },
        link(scope, element, attrs) {
            scope.getTemplateUrl = function () {
                return scope.readOnly
                    ? '/app/payables/ach/batch/directives/recipientsTable/recipientsTableReadOnlyTemplate.html'
                    : '/app/payables/ach/batch/directives/recipientsTable/recipientsTableTemplate.html';
            };
        },
        template: '<div ng-include="getTemplateUrl()"></div>',
        controller: recipientsTableCtrl,
    };
}

/**
 * @typedef { ReturnType<import('../../../../../services/ach/achMasterRecipientService.js').AchMasterRecipientService> } AchMasterRecipientService
 */

/**
 *
 * @param { ng.IScope } $scope
 * @param { ng.ITimeoutService } $timeout
 * @param { import('@uirouter/angularjs').StateService } $state
 * @param { AchMasterRecipientService } achMasterRecipientService
 */
function recipientsTableCtrl(
    $scope,
    $timeout,
    achBatchService,
    $uibModal,
    entitlementsService,
    achSettingsService,
    regexConstants,
    recipientValidatorService,
    $state,
    achMasterRecipientService
) {
    $scope.accountTypes = achBatchService.getAccountTypes();
    $scope.transactionTypes = achBatchService.getTransactionTypes();
    $scope.achFull = entitlementsService.hasEntitlement('ACH, Payment, Full Edit');
    $scope.achPartial = entitlementsService.hasEntitlement('ACH, Payment, Partial Edit');
    $scope.achTemplateFull = entitlementsService.hasEntitlement('Full Edit Ach Template');
    $scope.achTemplatePartial = entitlementsService.hasEntitlement('Partial Edit Ach Template');
    $scope.allowOnUsAccessManagementEntitlement = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowOnUsAccessManagement'
    );
    $scope.tableController = {};
    $scope.showSelectAchMasterRecipients = showSelectAchMasterRecipients;
    $scope.selectAchMasterRecipients = selectAchMasterRecipients;

    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };

    $scope.alphanumericRegex = regexConstants.AlphaNumericRegex;
    $scope.alphanumericSplCharsRegex = regexConstants.AlphaNumericPlusSpecialCharsRegex;
    $scope.numericRegex = /^\d+$/;

    $scope.addRecipient = addRecipient;
    $scope.removeRecipient = removeRecipient;
    $scope.filterRecipients = filterRecipients;
    $scope.calculateAmounts = calculateAmounts;
    $scope.resetAmount = resetAmount;
    $scope.showAddendaModal = showAddendaModal;
    $scope.hasErrors = hasErrors;
    $scope.getRecipientErrors = getRecipientErrors;
    $scope.isReadOnly = isReadOnly;
    $scope.isOnUsAccessManagement = isOnUsAccessManagement;
    $scope.setSelectedRecipient = setSelectedRecipient;
    $scope.onSelectBank = onSelectBank;
    $scope.goToNextError = goToNextError;
    $scope.invalidRecipientCount = invalidRecipientCount;
    $scope.isPayment = false;
    $scope.counts = {
        prenote: 0,
        hold: 0,
    };
    $scope.selectAll = {
        prenote: false,
        hold: false,
    };
    $scope.selectAllPrenote = selectAllPrenote;
    $scope.selectAllHold = selectAllHold;
    $scope.showSaveMasterRecipientButton = showSaveMasterRecipientButton;
    $scope.addMasterRecipient = addMasterRecipient;
    $scope.showWarningIcon = showWarningIcon;

    if ($scope.controller) {
        $scope.controller.filterRecipients = filterRecipients;
    }
    $scope.importRecipients = importRecipients;

    $scope.$watch(
        () => $scope.batch.recipients.length,
        (newValue, oldValue) => {
            if (newValue !== oldValue) {
                calculateAmounts();
            }
        }
    );

    function isEveryRecipientAPrenote() {
        return $scope.batch.recipients.every(recipient => recipient.prenote);
    }

    function showWarningIcon() {
        return (
            hasErrors($scope.currentRecipient) &&
            getRecipientErrors().length > 0 &&
            $scope.isManageRecipients != null
        );
    }

    function updatePrenoteHoldSelectAll() {
        let prenoteCount = 0;
        let holdCount = 0;
        $scope.filteredRecipients.forEach(recipient => {
            prenoteCount += recipient.prenote ? 1 : 0;
            holdCount += recipient.hold ? 1 : 0;
        });
        $scope.selectAll.prenote = prenoteCount === $scope.filteredRecipients.length;
        $scope.selectAll.hold = holdCount === $scope.filteredRecipients.length;
    }

    function selectAllPrenote() {
        $scope.filteredRecipients.forEach(recipient => {
            recipient.prenote = $scope.selectAll.prenote;
            resetAmount(recipient, true);
        });
        calculateAmounts();
    }

    function selectAllHold() {
        $scope.filteredRecipients.forEach(recipient => {
            recipient.hold = $scope.selectAll.hold;
            resetAmount(recipient, true);
        });
        calculateAmounts();
    }

    function getMinimumAmount(item) {
        if ($scope.isPayment) return item.prenote || item.hold ? '0.00' : '0.01';
        return '0.00';
    }

    function importRecipients() {
        const modalInstance = $uibModal.open({
            template: require('../../views/importRecipientsModal.html'),
            size: 'md',
            controller: 'ImportRecipientsModalController',
            backdrop: 'static',
        });

        modalInstance.result.then(response => {
            prunePreImportRecipients();

            response.forEach(item => {
                $scope.batch.recipients.push(
                    angular.extend(new achBatchService.Recipient(), item, {
                        minAmount: getMinimumAmount(item),
                    })
                );
            });

            achBatchService.updateFilterCounts(
                $scope.counts,
                $scope.batch.recipients,
                updatePrenoteHoldSelectAll
            );

            filterRecipients();

            // validate imported recipients
            $timeout(() => {
                response.forEach((item, i) => {
                    [
                        'name',
                        'idNumber',
                        'accountNumber',
                        'routingNumber',
                        'transactionType',
                    ].forEach(key => {
                        $scope.$parent.form[`recipient-${i}`][key].$validate();
                    });

                    setRoutingNumberValidity(i, response[i]);
                });
            }, 150);

            if ($scope.onRecipientsImported) $scope.onRecipientsImported();
        });
    }

    function prunePreImportRecipients() {
        const preImportRecipients = [];

        $scope.batch.recipients.forEach(recipient => {
            if (
                recipient.name ||
                recipient.idNumber ||
                recipient.accountNumber ||
                recipient.routingNumber
            ) {
                preImportRecipients.push(recipient);
            }
        });

        $scope.batch.recipients = preImportRecipients;
    }

    function onSelectBank(bank, index) {
        if (bank) {
            const recipient = $scope.batch.recipients[index];
            recipient.isRoutingNumberValid = true;
            recipient.isRoutingNumberOnUs = bank.onUs;

            const recipientModel = $scope.$parent.form[`recipient-${index}`];
            const valid = recipientValidatorService(
                $scope.batch.recipients,
                $scope.batch.achCompany
            ).isRecipientTransactionTypeValidForAchCompany(recipient);
            recipientModel.transactionType.$setValidity('notOnUsTransactionTypes', valid);
        }
    }

    init();

    function init() {
        $scope.isPayment =
            $scope.$parent.isNewPayment ||
            ($scope.$parent.isEditing && $scope.$parent.batch.id === undefined);
        // Min Amount for recipient
        $scope.batch.recipients.filter(item => {
            item.minAmount = getMinimumAmount(item);
        });

        primeRecipients();

        filterRecipients();

        // onUsTransactionsRequired
        // set form validity depending on the number of recipients and the minimum number of transactions required
        $scope.$watch(
            () => {
                if (isEveryRecipientAPrenote()) {
                    return true;
                }

                const onUsRecipients = $scope.batch.recipients.filter(
                    item => item.isRoutingNumberValid && item.isRoutingNumberOnUs && !item.hold
                );

                return (
                    $scope.batch.achCompany.onUsTransactionsRequired &&
                    onUsRecipients.length >= $scope.batch.achCompany.onUsTransactionsRequired
                );
            },
            valid => {
                $scope.$parent.form.$setValidity('onUsTransactionsRequired', valid);
            }
        );

        // onUsAmountRequiredPerBatch
        $scope.$watch(
            () => {
                if (
                    !isEveryRecipientAPrenote() &&
                    $scope.batch.achCompany.onUsAmountRequiredPerBatch > 0
                ) {
                    let totalOnUs = 0;
                    let totalAmount = 0;

                    // get the totals of all recipient amounts and recipients with their
                    $scope.batch.recipients.forEach(item => {
                        if (!item.hold) {
                            totalOnUs +=
                                item.isRoutingNumberValid &&
                                item.isRoutingNumberOnUs &&
                                !isNaN(item.amount)
                                    ? item.amount
                                    : 0;
                            totalAmount +=
                                item.isRoutingNumberValid && !isNaN(item.amount) ? item.amount : 0;
                        }
                    });

                    totalOnUs = totalOnUs.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);

                    return (
                        totalAmount > 0 &&
                        totalOnUs >=
                            totalAmount * ($scope.batch.achCompany.onUsAmountRequiredPerBatch / 100)
                    );
                }

                return true;
            },
            valid => {
                if (!$scope.batch.isTemplate) {
                    $scope.$parent.form.$setValidity('onUsAmountRequiredPerBatch', valid);
                }
            }
        );

        // batchBalanceRequirements
        $scope.$watch(
            () => {
                if ($scope.batch.achCompany.batchBalanceRequirements) {
                    const totals = {
                        credit: 0,
                        debit: 0,
                    };
                    if ($scope.batch.achCompany.batchBalanceRequirements === 'Balanced') {
                        $scope.batch.recipients.forEach(recipient => {
                            if (!recipient.hold) {
                                if (recipient.transactionType === 'CR') {
                                    totals.credit += recipient.amount;
                                } else if (recipient.transactionType === 'DR') {
                                    totals.debit += recipient.amount;
                                }
                            }
                        });
                        return totals.credit.toFixed(2) === totals.debit.toFixed(2);
                    }
                    if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Full Offset'
                    ) {
                        const invalidRecipients = $scope.batch.recipients.filter(
                            recipient =>
                                recipient.transactionType !==
                                $scope.batch.recipients[0].transactionType
                        );

                        $scope.batch.recipients.forEach((recipient, i) => {
                            const form = $scope.$parent.form[`recipient-${i}`];
                            if (form && form.transactionType && !form.hold.$modelValue) {
                                const valid =
                                    form.transactionType.$modelValue ===
                                    $scope.batch.recipients[0].transactionType;

                                form.transactionType.$setValidity(
                                    'batchBalanceRequirements',
                                    valid
                                );
                            }
                        });

                        return invalidRecipients.length === 0;
                    }
                    if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Partial Offset'
                    ) {
                        $scope.batch.recipients.forEach(recipient => {
                            if (!recipient.hold) {
                                if (recipient.transactionType === 'CR') {
                                    totals.credit += recipient.amount;
                                } else if (recipient.transactionType === 'DR') {
                                    totals.debit += recipient.amount;
                                }
                            }
                        });

                        return (
                            totals.credit !== totals.debit && totals.credit > 0 && totals.debit > 0
                        );
                    }
                }
                return true;
            },
            valid => {
                $scope.$parent.form.$setValidity('batchBalanceRequirements', valid);
            }
        );

        achBatchService.updateFilterCounts(
            $scope.counts,
            $scope.batch.recipients,
            updatePrenoteHoldSelectAll
        );

        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function primeRecipients() {
        if ($scope.banks) {
            $scope.batch.recipients.forEach((recipient, index) => {
                if (recipient.routingNumber) {
                    const banks = $scope.banks.filter(
                        bank => bank.bankId === recipient.routingNumber
                    );
                    recipient.isRoutingNumberValid = banks && banks.length === 1;
                    if (recipient.isRoutingNumberValid) {
                        recipient.isRoutingNumberOnUs = banks[0].onUs;
                    }

                    function validateRoutingNumber() {
                        if ($scope.$parent.form[`recipient-${index}`] != null) {
                            setRoutingNumberValidity(index, recipient);
                        } else {
                            $timeout(validateRoutingNumber);
                        }
                    }
                    validateRoutingNumber();
                }
            });
        }
    }

    function goToLastPage() {
        const lastPage = Math.ceil(
            $scope.batch.recipients.length / $scope.tableController.pagination.itemsPerPage
        );
        $scope.tableController.pagination.currentPage = lastPage;
        $scope.tableController.paginate();
        $scope.$apply();
    }

    function goToPageContainingIndex(index) {
        const targetPage = Math.ceil(index / $scope.tableController.pagination.itemsPerPage);
        $scope.tableController.pagination.currentPage = targetPage;
        $scope.tableController.paginate();
    }

    function clearSearchFilters() {
        $scope.searchFilters.text = '';
        $scope.searchFilters.errors = false;
        $scope.searchFilters.hold = false;
        $scope.searchFilters.prenote = false;
        filterRecipients();
    }

    function addRecipient() {
        const recipient = new achBatchService.Recipient({
            accountType: $scope.accountTypes[0], // defaults to 'Checking'
            transactionType: isOnUsAccessManagement()
                ? setOnUsTransactionType()
                : $scope.transactionTypes[0], // defaults to 'CR'
        });

        // set required amount to a non zero positive amount if it is a payment else set required amount to 0, if it is a template
        recipient.minAmount = $scope.isPayment ? '0.01' : '0.00';

        $scope.batch.recipients.push(recipient);

        $timeout(() => {
            goToLastPage();
            // set focus on the first field of the last recipient
            document.querySelector('#recipientsTable tbody tr:last-of-type input').focus();
        });

        clearSearchFilters();
    }

    function removeRecipient(recipient) {
        $scope.batch.recipients = $scope.batch.recipients.filter(
            r => r.$$hashKey !== recipient.$$hashKey
        );
        const lastPage = Math.ceil(
            $scope.batch.recipients.length / $scope.tableController.pagination.itemsPerPage
        );
        if (lastPage < $scope.tableController.pagination.currentPage) {
            $scope.tableController.pagination.currentPage = lastPage;
        }
        filterRecipients();
        calculateAmounts();
    }

    function filterRecipients() {
        let { recipients } = $scope.batch;

        if ($scope.searchFilters.errors) {
            const recipientsWithErrors = recipientValidatorService(
                recipients,
                $scope.batch.achCompany
            ).errors();
            recipients = recipientsWithErrors;
        }

        let filteredArray = recipients.filter(recipient => {
            if (recipient.isDeleted) {
                return false;
            }
            return (
                $scope.searchFilters.text === '' ||
                (recipient.name &&
                    (recipient.name
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1 ||
                        recipient.name
                            .toUpperCase()
                            .indexOf($scope.searchFilters.text.toUpperCase()) !== -1)) ||
                (recipient.idNumber &&
                    recipient.idNumber
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                (recipient.accountNumber &&
                    recipient.accountNumber
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                (recipient.amount &&
                    recipient.amount.toString().indexOf($scope.searchFilters.text) !== -1) ||
                (recipient.accountType &&
                    recipient.accountType
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                (recipient.routingNumber &&
                    recipient.routingNumber
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                (recipient.routingNumber &&
                    recipient.routingNumber
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                (recipient.transactionType &&
                    recipient.transactionType
                        .toLowerCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1)
            );
        });

        if ($scope.searchFilters.prenote) {
            filteredArray = filteredArray.filter(
                recipient => $scope.searchFilters.prenote && recipient.prenote
            );
        }

        if ($scope.searchFilters.hold) {
            filteredArray = filteredArray.filter(
                recipient => $scope.searchFilters.hold && recipient.hold
            );
        }

        $scope.filteredRecipients = filteredArray;
        updatePrenoteHoldSelectAll();
    }

    function invalidRecipientCount() {
        if (isOnUsAccessManagement()) {
            $scope.batch.achCompany.isOnUsAccessManagement = true;
        }

        const count = recipientValidatorService(
            $scope.batch.recipients,
            $scope.batch.achCompany
        ).countErrors();
        $scope.$parent.hasErrors = count > 0;
        return count;
    }

    function calculateAmounts() {
        const amounts = achBatchService.calculateAmounts($scope.batch.recipients);
        $scope.batch.debitAmount = amounts.debit || 0;
        $scope.batch.creditAmount = amounts.credit || 0;
        achBatchService.updateFilterCounts(
            $scope.counts,
            $scope.batch.recipients,
            updatePrenoteHoldSelectAll
        );
    }

    function resetAmount(recipient, skipCounts) {
        if (recipient.prenote || recipient.hold) {
            recipient.amount = recipient.prenote ? 0 : recipient.amount;
            if ($scope.isPayment) recipient.minAmount = '0.00';
        }
        if ($scope.isPayment && !recipient.prenote && !recipient.hold) {
            recipient.minAmount = '0.01';
        }
        if (!skipCounts) {
            calculateAmounts();
        }
    }

    function showAddendaModal(recipient) {
        const modalInstance = $uibModal.open({
            template: require('../../views/addendaModalView.html'),
            size: 'lg',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.batch,
                        recipient,
                    };
                },
                readOnly() {
                    return isReadOnly('partial');
                },
            },
        });

        modalInstance.result.then(data => {
            recipient.addenda = data.addendas;
            if (data.dirty) {
                $scope.$parent.form.$setDirty();
            }
        });
    }

    function hasErrors(recipient) {
        // check if fields that require validation have been touched
        let valid = true;
        ['name', 'idNumber', 'accountNumber', 'routingNumber', 'transactionType', 'amount'].forEach(
            key => {
                if (recipient && recipient[key] && recipient[key].$invalid) {
                    valid = false;
                }
            }
        );

        return recipient?.$invalid && !valid;
    }

    function setSelectedRecipient(recipient, index) {
        $scope.currentRecipient = recipient;
        $scope.currentIndex = index;
    }

    function getRecipientErrors() {
        const errors = [];
        const fields = {
            name: { label: 'Recipient Name' },
            idNumber: { label: 'ID Number' },
            accountNumber: { label: 'Account Number' },
            accountType: { label: 'Account Type' },
            routingNumber: { label: 'Routing Number' },
            transactionType: { label: 'Credit/Debit' },
            amount: { label: 'Amount' },
        };

        if ($scope.currentRecipient) {
            // generate a list of errors per field
            const allErrors = getErrorsandInvalid();

            if (allErrors.size < 1) {
                return errors;
            }

            Object.keys(fields).forEach(key => {
                const item = fields[key];
                for (const type in allErrors) {
                    switch (type) {
                        case 'required':
                            errors.push(`${item.label} is required.`);
                            break;
                        case 'pattern':
                            errors.push(`${item.label} has invalid characters.`);
                            break;
                        case 'minlength':
                            errors.push(`Invalid ${item.label}.`);
                            break;
                        case 'min':
                            errors.push(`${item.label} does not meet minimum.`);
                            break;
                        case 'editable':
                        case 'routingNumber':
                            errors.push(`Invalid ${item.label}.`);
                            break;
                        case 'notOnUsTransactionTypes':
                            errors.push('Invalid transaction type for this routing number.');
                            break;
                        case 'batchBalanceRequirements':
                            errors.push(
                                `Transaction type must be set to "${$scope.batch.recipients[0].transactionType}".`
                            );
                            break;
                    }
                }
            });
        }

        return errors;
    }

    function getErrorsandInvalid(key) {
        if (!key || !$scope.recipient) {
            return new Set();
        }

        return new Set($scope.recipient[key].$invalid + $scope.currentRecipient[key].$error);
    }

    function goToNextError() {
        const nextError = document.querySelector('#recipientsTable tr .ng-invalid');

        if (nextError) {
            $timeout(() => {
                nextError.focus();
            });
        } else {
            clearSearchFilters();
            $scope.currentIndex = recipientValidatorService(
                $scope.batch.recipients,
                $scope.batch
            ).nextErrorIndex($scope.currentIndex);
            goToPageContainingIndex($scope.currentIndex);
            $timeout(goToNextError);
        }
    }

    function isReadOnly(editEntitlementLevel, recipient) {
        if (recipient != null && recipient.achMasterRecipientId) {
            return true;
        }

        // editEntitlementLevel is the lowest requirement to edit a field.
        // if the level is partial then you need partial entitlement or full entitlement.
        // if level is full then you need full entitlement.
        if ($scope.type === 'payment') {
            if (editEntitlementLevel === 'partial') {
                return $scope.isEditView && !$scope.achPartial && !$scope.achFull;
            }
            if (editEntitlementLevel === 'full') {
                return $scope.isEditView && !$scope.achFull;
            }
            return true;
        }
        if ($scope.type === 'batch') {
            if (editEntitlementLevel === 'partial') {
                return $scope.isEditView && !$scope.achTemplatePartial && !$scope.achTemplateFull;
            }
            if (editEntitlementLevel === 'full') {
                return $scope.isEditView && !$scope.achTemplateFull;
            }
            return true;
        }
        return true;
    }

    function setOnUsTransactionType() {
        const notOnUsTransactionType = $scope.batch?.achCompany?.notOnUsTransactionTypes;
        if (notOnUsTransactionType === 'Debit Only') return 'DR';
        if (notOnUsTransactionType === 'Credit Only') return 'CR';
    }

    function isOnUsAccessManagement() {
        const notOnUsTransactionType = $scope.batch?.achCompany?.notOnUsTransactionTypes;
        const isTransactionTypeCrDrOnly =
            notOnUsTransactionType === 'Debit Only' || notOnUsTransactionType === 'Credit Only';

        if (
            $scope.allowOnUsAccessManagementEntitlement &&
            $scope.achSettings?.allowOnUsAccessManagement &&
            isTransactionTypeCrDrOnly
        ) {
            return true;
        }
        return false;
    }

    function showSelectAchMasterRecipients() {
        return !isReadOnly('full') && !$scope.batch.isTemplate;
    }

    function selectAchMasterRecipients() {
        $state.go('payables.ach.recipients-list', { achPayment: $scope.batch });
    }

    function showSaveMasterRecipientButton(row) {
        return (
            $scope.enableSaveRecipients &&
            row.achMasterRecipientId == null &&
            !$scope.batch.isTemplate
        );
    }

    function addMasterRecipient(row) {
        const accountTypes = {
            Checking: 1,
            Savings: 2,
            Loans: 3,
            GL: 7,
        };

        let addenda = null;
        if (row.addenda != null && row.addenda.length) {
            addenda = row.addenda[0].value;
        }

        const masterRecipient = {
            recipientName: row.name,
            recipientIdNumber: row.idNumber,
            accountNumber: row.accountNumber,
            accountTypeId: accountTypes[row.accountType],
            routingNumber: row.routingNumber,
            transactionTypeId: $scope.transactionTypes.indexOf(row.transactionType) + 1,
            defaultAmount: row.amount,
            addenda,
        };
        achMasterRecipientService.saveMasterRecipient(masterRecipient).then(() => {
            row.isSaved = true;
        });
    }

    function setRoutingNumberValidity(recipientIndex, recipient) {
        const routingNumberTypeAhead =
            $scope.$parent.form[`recipient-${recipientIndex}`].routingNumber;
        routingNumberTypeAhead.$setValidity('editable', recipient.isRoutingNumberValid);
    }
}

recipientsTableCtrl.$inject = [
    '$scope',
    '$timeout',
    'achBatchService',
    '$uibModal',
    'entitlementsService',
    'achSettingsService',
    'regexConstants',
    'recipientValidatorService',
    '$state',
    'achMasterRecipientService',
];
