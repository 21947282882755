ReadyForQuoteController.$inject = [
    '$scope',
    'wire',
    'wireService',
    '$modalInstance',
    '$interval',
    '$timeout',
    'companyAccountsService',
    'securityService',
    'timeZones',
];

export default function ReadyForQuoteController(
    $scope,
    wire,
    wireService,
    $modalInstance,
    $interval,
    $timeout,
    companyAccountsService,
    securityService,
    timeZones
) {
    $scope.wire = wire;
    $scope.wire.rateQuote = {};
    $scope.accept = accept;
    $scope.cancel = cancel;
    $scope.getAnotherQuote = getAnotherQuote;
    $scope.confirm = confirm;
    $scope.views = {
        main: 1,
        expired: 2,
        confirm: 3,
    };
    $scope.view = $scope.views.main;
    $scope.timerStarted = false;
    $scope.minutes = 0;
    $scope.seconds = 0;
    $scope.disableDliToday = false;

    init();

    function loadCutoffTimesDli() {
        companyAccountsService.getCutoffTimesByProductType('WireTransferDli').then(response => {
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $scope.timeToCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $scope.cutOff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            )
                .tz(timeZones[response.fiTimeZone])
                .format('hh:mm A z');
            $timeout(() => {
                $scope.disableDliToday = true;
            }, timeout);
        });
    }

    $scope.onInterval = function () {
        $scope.counter -= 1;
    };

    $scope.$watch('counter', () => {
        if ($scope.counter >= 0) {
            $scope.minutes = Math.floor($scope.counter / 60);
            $scope.seconds = $scope.counter % 60;
        } else if ($scope.timerStarted) {
            $scope.stop();
        }
    });

    function getAnotherQuote() {
        getRateQuote();
    }

    $scope.stop = function () {
        $scope.view = $scope.views.expired;
        $interval.cancel($scope.myTimeOut);
    };

    function init() {
        loadCutoffTimesDli();
        getRateQuote();
    }

    function getRateQuote() {
        wireService
            .getRateQuote({
                destinationCurrencyCode: wire.destinationCurrency,
                amount: wire.amount,
                isAmountUSD: true,
            })
            .then(
                response => {
                    $scope.view = $scope.views.main;
                    $scope.wire.rateQuote = response;
                    $scope.counter = $scope.wire.rateQuote.expirationTime;
                    $scope.myTimeOut = $interval($scope.onInterval, 1000);
                    $scope.timerStarted = true;
                },
                error => {
                    $modalInstance.dismiss();
                }
            );
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function accept() {
        $scope.view = $scope.views.confirm;
    }

    function confirm() {
        $scope.wire.frequency = {
            type: 'One Time',
            valueDate: $scope.wire.rateQuote.valueDate,
        };
        securityService
            .verifyUser('CreateFxWire_ByName', $scope.wire, () =>
                wireService.acceptRateQuote($scope.wire)
            )
            .then(response => {
                $scope.wire = response;
                $scope.wire.rateQuote.accepted = true;
                $modalInstance.close($scope.wire);
            });
    }
}
