import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';

/**
 * @typedef { import('@treasury/api/channel').SaveableMasterRecipientDto } SaveableMasterRecipientDto
 */

/**
 *
 * @param { ng.resource.IResourceService } $resource
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
export function AchMasterRecipientService($resource, $q, TmDi) {
    return {
        approveMasterRecipients,
        deleteMasterRecipients,
        getMasterRecipientAudits,
        getMasterRecipients,
        rejectMasterRecipients,
        saveMasterRecipient,
    };

    function getMasterRecipients(searchFilters) {
        const cancelPromise = $q.defer();
        const getRecipientsRequest = $resource(buildUrl('GetMasterRecipients'), null, {
            timeout: cancelPromise,
        }).save(searchFilters);
        return {
            promise: getRecipientsRequest.$promise,
            cancel() {
                cancelPromise.resolve();
            },
        };
    }

    /**
     *
     * @param { SaveableMasterRecipientDto | SaveableMasterRecipientDto[]} achMasterRecipients
     * @returns
     */
    function saveMasterRecipient(achMasterRecipients) {
        const recipientList = angular.isArray(achMasterRecipients)
            ? achMasterRecipients
            : [achMasterRecipients];
        const savemasterRecipientsRequest = {
            saveableMasterRecipients: recipientList,
        };
        const saveRecipientRequest = $resource(buildUrl('SaveMasterRecipient')).save(
            savemasterRecipientsRequest
        );

        return $q
            .all([saveRecipientRequest.$promise, TmDi.getAsync(AnalyticsService)])
            .then(([response, analytics]) => {
                const { achMasterRecipients } = response;
                analytics.track(AnalyticsEvent.AchRecipientsCreated, {
                    recipientIds: achMasterRecipients.map(recipient =>
                        recipient.id ? recipient.id.toString() : 'undefined'
                    ),
                });

                return response;
            });
    }

    /**
     * @param { string[] } achMasterRecipientIds
     */
    function approveMasterRecipients(achMasterRecipientIds) {
        const request = {
            achMasterRecipientIds,
        };
        const resource = $resource(buildUrl('approve'));
        /**
         * @type { Promise<import('@treasury/api/channel').BaseResponse3Dto>}
         */
        const p = resource.save(request).promise;

        Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([, analytics]) => {
            analytics.track(AnalyticsEvent.AchRecipientsApproved, {
                recipientIds: achMasterRecipientIds,
            });
        });

        return p;
    }

    function rejectMasterRecipients(achMasterRecipientIds) {
        const request = {
            achMasterRecipientIds,
        };
        const rejectRecipientRequest = $resource(buildUrl('reject')).save(request);
        return rejectRecipientRequest.$promise;
    }

    function deleteMasterRecipients(achMasterRecipientIds) {
        const request = {
            achMasterRecipientIds,
        };
        const deleteRecipientRequest = $resource(buildUrl('delete')).save(request);
        return deleteRecipientRequest.$promise;
    }

    function getMasterRecipientAudits(achMasterRecipientId) {
        return $resource(buildUrl('audits/:achMasterRecipientId'), {
            achMasterRecipientId,
        }).get().$promise;
    }

    function buildUrl(actionPath) {
        return `${getChannelAPIPath()}AchMasterRecipient/${actionPath}`;
    }
}

AchMasterRecipientService.$inject = ['$resource', '$q', 'TmDi'];
