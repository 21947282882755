import { html, LitElement } from 'lit';
import './subTypes/omega-frequency-repeat-on-days.js';
import './subTypes/start-and-end.js';

export class TwiceAMonth extends LitElement {
    static get properties() {
        return {
            frequency: Object,
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
            isValidStartDate: Function,
            isValidEndDate: Function,
            isStartDateInvalid: Function,
            isEndDateInvalid: Function,
        };
    }

    static get is() {
        return 'omega-frequency-twice-a-month';
    }

    setFrequency(e) {
        this.frequency = e.detail;
        this.dispatchEvent(new CustomEvent('change', { detail: e.detail }));
    }

    render() {
        return html`
            <omega-frequency-repeat-on-days
                showSecondDay
                .frequency=${this.frequency}
                @change=${this.setFrequency}
                ?disabled=${this.disabled}
                ?readonly=${this.readOnly}
            ></omega-frequency-repeat-on-days>
            <omega-frequency-start-and-end
                .frequency=${this.frequency}
                .isStartDateInvalid=${this.isStartDateInvalid}
                .isEndDateInvalid=${this.isEndDateInvalid}
                @change=${this.setFrequency}
                ?disabled=${this.disabled}
                ?readonly=${this.readOnly}
            ></omega-frequency-start-and-end>
        `;
    }
}

window.customElements.define(TwiceAMonth.is, TwiceAMonth);
export default TwiceAMonth;
