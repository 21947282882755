import { getChannelAPIPath } from '@treasury/core/http';

WireCompaniesService.$inject = ['$resource', '$http'];

export default function WireCompaniesService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}wireCompanies/`;

    return {
        getAll,
        getDebitAccounts,
        getBeneficiaries,
        getWireConfiguration,
    };

    function getAll(nonDli) {
        return $resource(`${resourceUrl}?nonDli=:nonDli`, { nonDli: !!nonDli }).query().$promise;
    }

    function getDebitAccounts(id, type) {
        return $resource(`${resourceUrl}:id/accounts`, { id, type }).query().$promise;
    }

    function getBeneficiaries(id) {
        return $resource(`${resourceUrl}:id/beneficiaries`, { id }).query().$promise;
    }

    function getWireConfiguration() {
        return $http.get(`${resourceUrl}wireConfiguration`).then(response => response.data);
    }
}
