AccountsWidgetController.$inject = [
    '$scope',
    '$modal',
    'dashboardService',
    'spinnerService',
    'accountTransactionsService',
    'entitlementsService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function AccountsWidgetController(
    $scope,
    $modal,
    dashboardService,
    spinnerService,
    accountTransactionsService,
    entitlementsService
) {
    $scope.groups = null;
    $scope.getTotalAvailableBalance = getTotalAvailableBalance;
    $scope.isLarge = isLarge;
    $scope.viewAccountDetail = viewAccountDetail;
    $scope.displayCollectedBalance = displayCollectedBalance;
    $scope.displayCollectedBalanceSpinner = displayCollectedBalanceSpinner;
    $scope.isLoanOrTimeDepositAccount = isLoanOrTimeDepositAccount;
    $scope.mapAccountType = mapAccountType;
    $scope.isTimeDepositAccount = isTimeDepositAccount;
    $scope.toggleGroupCollapse = toggleGroupCollapse;

    init();

    function init() {
        spinnerService.configureGroup(
            'accountsWidget',
            ['/dashboard/myaccounts'],
            [
                'accountsLeftControls.html',
                'accountsRightControls.html',
                'accountGroupsModal.html',
                'accountGroupNameModal.html',
                'accountGroupAccountListModal.html',
                'accountDetailModal.html',
            ]
        );

        $scope.widget.footerTemplate = 'app/dashboard/widgets/views/accountsFooter.html';

        $scope.widget.leftControlsTemplate =
            'app/dashboard/widgets/views/accountsLeftControls.html';
        $scope.widget.leftControlsController = {
            manageGroups,
        };

        $scope.widget.rightControlsTemplate =
            'app/dashboard/widgets/views/accountsRightControls.html';
        $scope.widget.rightControlsController = {
            expandAll,
            collapseAll,
            isLarge,
        };
        $scope.hasGroupTotalFeature = !entitlementsService.hasEntitlement(
            'Feature.SupressGroupAvailBalance.AccountWidget'
        );

        updateSpinner(spinnerService.startGroupRequest('accountsWidget'));
        dashboardService.getAccountGroups().then(response => {
            updateSpinner(spinnerService.stopGroupRequest('accountsWidget'));
            $scope.groups = response;
            $scope.groups.forEach(group => {
                group.isCollapsed = true;
            });

            if ($scope.groups && $scope.groups.length > 0) {
                $scope.groups[0].isCollapsed = false;

                fetchMissingCollectedBalances($scope.groups[0]);
            }
        });
    }

    function fetchMissingCollectedBalances(group) {
        const accounts = [];

        if (group) {
            Array.prototype.push.apply(accounts, group.accounts);
        } else {
            $scope.groups.forEach(group => {
                Array.prototype.push.apply(accounts, group.accounts);
            });
        }

        const NUMBER_OF_ACCOUNT_IDS_THAT_CAN_FIT_ON_ONE_REQUEST = 75;
        const binsOfAccounts = fibonacciBins(
            accounts.filter(isMissingCollectedBalance),
            NUMBER_OF_ACCOUNT_IDS_THAT_CAN_FIT_ON_ONE_REQUEST
        );
        binsOfAccounts.forEach(loadCollectedBalances);
    }

    function fibonacciBins(sourceItems, maxSize) {
        const bins = [];
        const items = Array.prototype.slice.call(sourceItems);
        let size = 1;
        let lastSize = 1;
        while (items.length > 0) {
            bins.push(items.splice(0, size));
            const nextSize = size + lastSize;
            if (nextSize <= maxSize) {
                lastSize = size;
                size = nextSize;
            }
        }

        return bins;
    }

    function loadCollectedBalances(accounts) {
        const accountIds = accounts.map(account => account.id);

        if (accountIds.length > 0) {
            accountTransactionsService
                .getAccountsCoreData(accountIds, true, false)
                .then(response => {
                    mapBalances(accounts, response);
                });
        }
    }

    function mapBalances(accounts, balances) {
        accounts.forEach(account => {
            balances.forEach(balance => {
                if (account.id === balance.id) {
                    account.collectedBalance = balance.collectedBalance;
                    account.permissions = balance.permissions;
                }
            });
        });
    }

    //
    // Left header controls

    function manageGroups() {
        const modalInstance = $modal.open({
            template: require('../views/accountGroupsModal.html'),
            size: 'lg',
            controller: 'AccountGroupsModalController',
            backdrop: 'static',
            resolve: {
                groups() {
                    return $scope.groups;
                },
            },
        });
        modalInstance.result.then(result => {
            $scope.groups = result;
            fetchMissingCollectedBalances();
        });
    }

    function expandAll() {
        $scope.groups.forEach(group => {
            group.isCollapsed = false;
        });

        fetchMissingCollectedBalances();
    }

    function collapseAll() {
        $scope.groups.forEach(group => {
            group.isCollapsed = true;
        });
    }

    function toggleGroupCollapse(group) {
        group.isCollapsed = !group.isCollapsed;

        if (!group.isCollapsed) {
            fetchMissingCollectedBalances(group);
        }
    }

    function getTotalAvailableBalance(group) {
        let sum = 0;
        group.accounts.forEach(account => {
            sum += account.availableBalance;
        });
        return sum;
    }

    function isLarge() {
        return $scope.widget.width > 600;
    }

    function viewAccountDetail(account) {
        $modal.open({
            template: require('../views/accountDetailModal.html'),
            size: 'lg',
            controller: 'AccountDetailModalController',
            backdrop: 'static',
            resolve: {
                account() {
                    return account;
                },
            },
        });
    }

    function displayCollectedBalance(account) {
        return typeof account.collectedBalance === 'number';
    }

    function displayCollectedBalanceSpinner(account) {
        return isMissingCollectedBalance(account);
    }

    function isLoanOrTimeDepositAccount(account) {
        return account.type === 'Loan' || account.type === 'Time Deposit';
    }

    function isTimeDepositAccount(account) {
        return account.type === 'Time Deposit';
    }

    function isMissingCollectedBalance(account) {
        return typeof account.collectedBalance !== 'number' && !isLoanOrTimeDepositAccount(account);
    }

    function updateSpinner(numPendingRequests) {
        $scope.widget.isLoading = numPendingRequests > 0;
    }

    function mapAccountType(type) {
        switch (type) {
            case 'Checking':
            case 'Savings':
            case 'Club':
                return 'Deposit';
            case 'Time Deposit':
                return 'TimeDeposit';
            default:
                return type;
        }
    }
}
