/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ChannelUserActivityReportFilterDto,
    DownloadPageDto,
    GetBoReportFilterModelDto,
    ReportColumnModelBaseDto,
    ReportDataModelOfListOfUserActivityModelDto,
    ReportFilterBaseDto,
    ReportModelBaseDto,
    ReportSequenceDto,
    WireHistoryModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ReportsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReport
     * @summary Gets a report by id.
     * @request GET:/reports/report/{id}
     */
    reportsGetReport = (id: number, params: RequestParams = {}) =>
        this.http.request<ReportModelBaseDto, any>({
            path: `/reports/report/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReportFilter
     * @summary Gets report filters.
     * @request GET:/reports/reportFilter
     */
    reportsGetReportFilter = (
        query: {
            /** The filter type. */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ReportFilterBaseDto, any>({
            path: `/reports/reportFilter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReportFilterModel
     * @summary Gets the BO report filters model for the specified report ID.
     * @request GET:/reports/report/{id}/reportFiltersModel
     */
    reportsGetReportFilterModel = (id: number, params: RequestParams = {}) =>
        this.http.request<GetBoReportFilterModelDto, any>({
            path: `/reports/report/${id}/reportFiltersModel`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReportCustomFilter
     * @summary Gets custom report filters.
     * @request GET:/reports/reportCustomFilter
     */
    reportsGetReportCustomFilter = (
        query: {
            /** The report type */
            type: string | null;
            /**
             * The Id.
             * @format int32
             */
            id: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<any, any>({
            path: `/reports/reportCustomFilter`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsUpdateReportCustomFilter
     * @summary Updates custom report filters.
     * @request POST:/reports/reportCustomFilter
     */
    reportsUpdateReportCustomFilter = (
        reportFilter: ReportFilterBaseDto,
        params: RequestParams = {}
    ) =>
        this.http.request<any, any>({
            path: `/reports/reportCustomFilter`,
            method: 'POST',
            body: reportFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReportColumnModels
     * @summary Gets custom report columns.
     * @request GET:/reports/reportColumnModels
     */
    reportsGetReportColumnModels = (
        query: {
            /** The type. */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ReportColumnModelBaseDto[], any>({
            path: `/reports/reportColumnModels`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsFavorite
     * @summary Gets favorites.
     * @request GET:/reports/favorite/{id}
     */
    reportsFavorite = (
        id: number,
        query: {
            /** True or False */
            value: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ReportModelBaseDto, any>({
            path: `/reports/favorite/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsDeleteCustomReport
     * @summary Deletes custom reports.
     * @request GET:/reports/deletecustomreport/{id}
     */
    reportsDeleteCustomReport = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/reports/deletecustomreport/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsUpdateSequence
     * @summary Updates sequences.
     * @request POST:/reports/updatesequence
     */
    reportsUpdateSequence = (sequences: ReportSequenceDto[] | null, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/reports/updatesequence`,
            method: 'POST',
            body: sequences,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetWireHistory
     * @summary Gets wire history report.
     * @request GET:/reports/getwirehistory/{id}
     */
    reportsGetWireHistory = (id: number, params: RequestParams = {}) =>
        this.http.request<WireHistoryModelDto[], any>({
            path: `/reports/getwirehistory/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetUserActivity
     * @summary Gets user activity.
     * @request POST:/reports/getUserActivity
     */
    reportsGetUserActivity = (
        reportFilter: ReportFilterBaseDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ReportDataModelOfListOfUserActivityModelDto[], any>({
            path: `/reports/getUserActivity`,
            method: 'POST',
            body: reportFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetChannelUserActivity
     * @summary Gets Channel user activities.
     * @request POST:/reports/ChannelUserActivity
     */
    reportsGetChannelUserActivity = (
        reportFilter: ChannelUserActivityReportFilterDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ReportDataModelOfListOfUserActivityModelDto[], any>({
            path: `/reports/ChannelUserActivity`,
            method: 'POST',
            body: reportFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetDownloadPageByReportType
     * @summary Gets download page by report type.
     * @request GET:/reports/GetDownloadPageByReportType
     */
    reportsGetDownloadPageByReportType = (
        query: {
            /** The report type */
            reportType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DownloadPageDto, any>({
            path: `/reports/GetDownloadPageByReportType`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
