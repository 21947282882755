import { GetUserProductLimitsResponseModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

export class UserWireLimits extends DomainEntity<GetUserProductLimitsResponseModelDto> {
    constructor(dto: GetUserProductLimitsResponseModelDto) {
        super(dto);
    }

    public createDefault() {
        return new UserWireLimits({} as GetUserProductLimitsResponseModelDto) as this;
    }

    get dailyLimit() {
        return this.dto.productDailyLimit;
    }

    get wireLimits() {
        return {
            initiationLimit: {
                value: getDisplayValue(
                    this.dto.userProductLimit?.initiationLimit,
                    this.dto.pendingUserProductLimit?.initiationLimit
                ),
                edited: isEdited(
                    this.dto.userProductLimit?.initiationLimit,
                    this.dto.pendingUserProductLimit?.initiationLimit
                ),
            },

            approvalLimit: {
                value: getDisplayValue(
                    this.dto.userProductLimit?.approvalLimit,
                    this.dto.pendingUserProductLimit?.approvalLimit
                ),
                edited: isEdited(
                    this.dto.userProductLimit?.approvalLimit,
                    this.dto.pendingUserProductLimit?.approvalLimit
                ),
            },
        };
    }

    get userWireAccounts() {
        return this.dto.userAccountLimits?.map(account => ({
            accountName: account.userAccount?.account?.accountDisplayLabel,
            accountNumber: account.userAccount?.account?.number,
            id: account.userAccount?.account?.id,
            transactionLimit: {
                value: getDisplayValue(
                    account.userAccountLimit?.perTransactionLimit,
                    account.pendingUserAccountLimit?.perTransactionLimit
                ),
                edited: isEdited(
                    account.userAccountLimit?.perTransactionLimit,
                    account.pendingUserAccountLimit?.perTransactionLimit
                ),
            },
            dailyLimit: {
                value: getDisplayValue(
                    account.userAccountLimit?.dailyLimit,
                    account.pendingUserAccountLimit?.dailyLimit
                ),
                edited: isEdited(
                    account.userAccountLimit?.dailyLimit,
                    account.pendingUserAccountLimit?.dailyLimit
                ),
            },
            accountDailyLimit: account.userAccount?.account?.dailyLimit || 9999999999999.99,
        }));
    }
}
