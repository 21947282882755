/* eslint-disable sonarjs/no-nested-template-literals */
import { AlarmClock } from '@treasury/alarm-clock';
import { CHECK_EXCEPTION_CUTOFF } from '@treasury/domain/channel/types/arp/constants.js';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-field.js';
import openDialog from '@treasury/omega/open-dialog.js';
import { html, nothing } from 'lit';
import './correction-request-dialog.js';

export function createCheckExceptionDetail(
    hasRequestCorrection = false,
    isDecisionActivity = false,
    hasAttachmentEntitlement = false
) {
    return function checkExceptionDetail(record, close) {
        return html`
            <style>
                h3 {
                    margin: 5px;
                }
                .details-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0 10px;
                }
                .details-footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0 10px;
                }
                .omega-flex-form {
                    display: flex;
                }
                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }
                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 10px;
                    min-height: 32px;
                }
            </style>

            <div class="details-header">
                <span class="title">
                    <h3>Details ${record.getField('accountNumber')}</h3>
                </span>
                <omega-button
                    type="icon"
                    icon="close"
                    hideLabel="always"
                    @click=${close}
                ></omega-button>
            </div>
            <div class="details-body omega-flex-form">
                <div class="form-column">
                    <omega-field field="accountNumber" .record=${record}></omega-field>
                    <omega-field field="checkNumber" .record=${record}></omega-field>
                    <omega-field field="paidAmount" .record=${record}></omega-field>
                    <omega-field field="issuedAmount" .record=${record}></omega-field>
                    <omega-field field="postedDate" .record=${record}></omega-field>
                    <omega-field field="issuedDate" .record=${record}></omega-field>
                    <omega-field field="issuedPayee" .record=${record}></omega-field>
                </div>
                <div class="form-column">
                    ${
                        isDecisionActivity && hasAttachmentEntitlement
                            ? html`<omega-field
                                  field="returnReasonWithCommentAndAttachment"
                                  .record=${record}
                              ></omega-field>`
                            : html`<omega-field
                                  field="returnReasonDescription"
                                  .record=${record}
                              ></omega-field>`
                    }
                    </omega-field>
                    <omega-field field="exceptionReason" .record=${record}></omega-field>
                    <omega-field field="lastDecisionTakenBy" .record=${record}></omega-field>
                    <omega-field field="decisionStatus" .record=${record}></omega-field>
                    <omega-field field="sourceOfEntry" .record=${record}></omega-field>
                    <omega-field field="protected" .record=${record}></omega-field>
                    <omega-field field="ddaBatchNumber" .record=${record}></omega-field>
                    <omega-field field="ddaSequenceNumber" .record=${record}></omega-field>
                </div>
                <div class="form-column">
                    <omega-field field="audits" .record=${record}></omega-field>
                    ${
                        hasRequestCorrection &&
                        !AlarmClock.getInstance().isAfter(CHECK_EXCEPTION_CUTOFF)
                            ? html`
                                  <omega-button
                                      @click=${() =>
                                          openDialog(
                                              document.createElement('correction-request-dialog'),
                                              {
                                                  record,
                                              }
                                          )}
                                      .disabled=${record.getField('isCorrectionRequestCreated')}
                                  >
                                      Request Correction
                                  </omega-button>
                              `
                            : nothing
                    }
                </div>
            </div>

            <div class="details-footer">
                <omega-button-bar alignment="left">
                    <omega-button type="secondary" @click=${close}>Close</omega-button>
                </omega-button-bar>
            </div>
        `;
    };
}
