import { InjectProperty, Injectable } from '@jack-henry/frontend-utils/di';
import {
    AchExceptionDetailDataDto,
    AchExceptionDetailSearchModelDto,
    AchExceptionsDecisionsActivityRequestModelDto,
    ArpClient,
    DashboardClient,
    ErrorResponseModelDto,
} from '@treasury/api/channel';
import { HttpResponse } from '@treasury/api/channel/generated/http-client';
import { AchException } from '../arp';
import { mapAchExceptionFiltersToRequest } from '../arp/mappings/ach-exception-filters-mapping';
import { mapAchExceptionsActivityFiltersToRequest } from '../arp/mappings/ach-exceptions-activity-filters-mapping';

@Injectable()
export class AchExceptionService {
    constructor(private readonly dashboardClient: DashboardClient) {}

    @InjectProperty()
    private declare arpClient: ArpClient;

    async getAchExceptions() {
        const response = (await this.dashboardClient.dashboardGetWorkExceptions({
            type: 'achExceptions',
        })) as HttpResponse<AchExceptionDetailDataDto[], ErrorResponseModelDto>;
        if (response.data == null || response.data === undefined) return [];
        return response.data.map((d: AchExceptionDetailDataDto) => new AchException(d));
    }

    async searchAchExceptions(params: AchExceptionDetailSearchModelDto) {
        const request = mapAchExceptionFiltersToRequest(params);
        const response = await this.arpClient.arpFindAchExceptions(request);
        if (!response.data.achExceptionDetails) return [];
        // Fixes bad typing from the API
        return response.data.achExceptionDetails.map(
            d => new AchException(d as unknown as AchExceptionDetailDataDto)
        );
    }

    async searchAchExceptionsActivity(
        params: AchExceptionsDecisionsActivityRequestModelDto | null
    ) {
        const request = mapAchExceptionsActivityFiltersToRequest(
            params
        ) as unknown as AchExceptionsDecisionsActivityRequestModelDto;
        const response = await this.arpClient.arpFindAchExceptionsDecisionsActivity(request);
        return response.data.items;
    }

    async searchPaginatedAchExceptionsActivity(
        params: AchExceptionsDecisionsActivityRequestModelDto | null
    ) {
        const request = mapAchExceptionsActivityFiltersToRequest(
            params
        ) as unknown as AchExceptionsDecisionsActivityRequestModelDto;
        const response = await this.arpClient.arpFindAchExceptionsDecisionsActivity(request);
        return response.data.items;
    }
}
