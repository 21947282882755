import BalanceReportsRequests from '../../requests/reporting/balance-reports-requests.js';
import InformationReportingRequests from '../../requests/reporting/information-reporting-requests.js';

export default class BalanceReportsService {
    static async fetchAccounts() {
        return BalanceReportsRequests.fetchAccounts();
    }

    static async fetchTransactionCodeGroups() {
        return BalanceReportsRequests.fetchTransactionCodeGroups();
    }

    static async fetchReportData(body) {
        return BalanceReportsRequests.fetchReportData(body);
    }

    static async fetchReportFilters(id) {
        return InformationReportingRequests.fetchReportFilters(id);
    }
}
