RequiredNotificationsController.$inject = ['$scope', '$modalInstance', 'errors'];

export default function RequiredNotificationsController($scope, $modalInstance, errors) {
    $scope.close = close;
    $scope.errors = errors;

    init();
    function init() {}

    function close() {
        $modalInstance.dismiss();
    }
}
