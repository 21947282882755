/* eslint-disable consistent-return */
/* eslint-disable sonarjs/cognitive-complexity */

const logging = false; // for debugging in browser console, set logging to true

export const repeatDay1BeforeRepeatDay2 = frequency => {
    if (!frequency) return false;
    const { repeatOnDay1, repeatOnDay2 } = frequency;
    if (repeatOnDay2) {
        const result = Number.parseInt(repeatOnDay1) < Number.parseInt(repeatOnDay2);
        if (logging) console.log(`repeat day 1 is before day 2: ${result}`);
        return result;
    }
    return true;
};
export const isValidDate = date => {
    if (!date) {
        return false;
    }
    if ((typeof date === 'string' && date.length) || typeof date === 'number') {
        return true;
    }
    // date from datepicker should have getDate method
    return typeof date === 'object' && typeof date.getDate === 'function';
};
export const validEndOn = frequency => {
    if (!frequency) return false;
    const result = frequency.noEndDate || isValidDate(frequency.endOn);
    if (logging) console.log(`valid frequency end on date: ${result}`);
    return result;
};
export const validStartOn = frequency => {
    if (!frequency) return false;
    const result = isValidDate(frequency.startOn);
    if (logging) console.log(`valid frequency start on date: ${result}`);
    return result;
};
export const validRepeat = frequency => {
    if (!frequency) return false;
    const result = isValidDate(frequency.repeatOn);
    if (logging) console.log(`valid repeat on date: ${result}`);
    return result;
};
export const validRepeatOnEnd = frequency => {
    if (!frequency) return false;
    const result =
        frequency.repeatOnLastBusinessDay ||
        isValidDate(frequency.repeatOnDay2) ||
        Number.isInteger(frequency.repeatOnDay2);
    if (logging) console.log(`valid repeat on end date: ${result}`);
    return result;
};
export const validRepeatOnStart = frequency => {
    if (!frequency) return false;
    const result =
        frequency.repeatOnLastBusinessDay ||
        isValidDate(frequency.repeatOnDay1) ||
        Number.isInteger(frequency.repeatOnDay1);
    if (logging) console.log(`valid repeat on start date: ${result}`);
    return result;
};
export const validRepeatBothDays = frequency => {
    if (!frequency) return false;
    const result =
        isValidDate(frequency.repeatOnDay1) &&
        validRepeatOnEnd(frequency) &&
        repeatDay1BeforeRepeatDay2(frequency);
    if (logging) console.log(`valid repeat on (all checks both days): ${result}`);
    return result;
};

export const validWeekly = (startDate, endDate) => {
    startDate.setDate(startDate.getDate() + 7);
    return startDate <= endDate;
};

export const validEveryTwoWeeks = (startDate, endDate) => {
    startDate.setDate(startDate.getDate() + 14);
    return startDate <= endDate;
};

export const validMonthly = (startDate, endDate) => {
    startDate.setMonth(startDate.getMonth() + 1);
    return startDate <= endDate;
};

export const validQuarterly = (startDate, endDate) => {
    startDate.setMonth(startDate.getMonth() + 3);
    return startDate <= endDate;
};

export const validEverySixMonths = (startDate, endDate) => {
    startDate.setMonth(startDate.getMonth() + 6);
    return startDate <= endDate;
};

export const validYearly = (startDate, endDate) => {
    startDate.setFullYear(startDate.getFullYear() + 1);
    return startDate <= endDate;
};
