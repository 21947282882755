import { getChannelModule } from '../../../app.module';

const channelModule = getChannelModule();

channelModule.controller('bdiEStatementsController', bdiEStatementsController);

bdiEStatementsController.$inject = ['$scope', '$state', 'bdiEStatementsService', 'modalService'];

function bdiEStatementsController($scope, $state, bdiEStatementsService, modalService) {
    const defaultProductDisplayName = 'BDI eStatements';
    const defaultNoAccountText = 'You do not have any eligible accounts available.';
    const productTypeId = 13;

    const errorMessage =
        'An error has occurred.  Please try again later or contact customer service.';
    let redirectUrl = '';

    $scope.noAccountExists = false;
    $scope.handoffMessage = 'Your BDI EStatements will open in a new browser window.';

    function setupGetInformation() {
        bdiEStatementsService.getInformation(productTypeId).then(response => {
            if (response) {
                processSettings(response);
            } else {
                displayError(response.Error);
            }
        });
    }

    function processSettings(settings) {
        if (settings.content && settings.content !== 0) {
            redirectUrl = settings.content;
            if (redirectUrl.length !== 0) {
                window.open(redirectUrl, '_blank');
            }
        } else {
            $scope.noAccountText =
                settings.errorMessages && settings.errorMessages.length !== 0
                    ? settings.errorMessages[0]
                    : defaultNoAccountText;
            $scope.noAccountExists = true;
        }
        $scope.productDisplayName =
            settings.productDisplayName && settings.productDisplayName.length !== 0
                ? settings.productDisplayName
                : defaultProductDisplayName;
    }

    function displayError(errorNumber) {
        modalService.showModal(
            {},
            {
                alertType: 'Error',
                isAlert: true,
                summaryText: errorNumber ? `Error ${errorNumber}: ${errorMessage}` : errorMessage,
            }
        );
    }

    function init() {
        setupGetInformation();
    }

    init();
}
