import {
    BatchRecipient,
    Config,
    PaymentHeader,
    PaymentRecipient,
} from '@treasury/domain/channel/types/ach';
import { WorkflowActions, WorkflowTypes } from '@treasury/domain/channel/types/ach/constants';
import { Record, Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/progress/omega-progress';
import { Column, RecordsetFilter, Schema, StepBase } from '@treasury/omega/layouts/omega-form';
import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import { getBatchDetailViewSchema } from '../data/factories/multi-payment-batch/viewBatchDetailSchema';
import { Field, getPaymentHeaderRecord } from '../data/payment-header-record';
import { batchRecipientRecordset } from '../data/recipient-recordset';
import { AddendaDialog } from './addenda-dialog';

export class BatchDetailModal extends ListeningElementMixin(LitElement) {
    @property()
    paymentId?: number;

    @property()
    config!: Config;

    @property({ type: Boolean, reflect: true })
    visible!: boolean;

    @property()
    batchName?: string;

    @property()
    selectedPaymentValueDate!: string;

    @property()
    setParentVisibility!: (val: boolean) => void;

    @state()
    paymentHeaderRecord!: Record<PaymentHeader>;

    @state()
    recipientsRecordset!: Recordset<BatchRecipient> | Recordset<PaymentRecipient>;

    @state()
    loading = false;

    @state()
    schema!: Schema<PaymentHeader, BatchRecipient>;

    @state()
    recordsetFilters: RecordsetFilter[] = [];

    @state()
    fields: Array<Field> = [];

    @state()
    addendaRecord?: Record<BatchRecipient>;

    @state()
    recipientCount = 0;

    steps = [
        {
            label: 'View Payment',
            formHeading: 'Batch Detail',
            step: 3,
            actions: [
                {
                    label: 'Close',
                    type: 'link',
                    action: () => this.close(),
                },
            ],
        },
    ];

    client = new AchDomesticClient();

    async updated(changedProperties: PropertyValues<this>) {
        if (changedProperties.has('visible') && this.visible) {
            await this.init();
        }
    }

    async init() {
        this.loading = true;
        this.setupConfigData();
        await this.getPaymentData();
        this.addListeners();
        this.loading = false;
    }

    addListeners() {
        this.recipientsRecordset.addEventListener('change', e => {
            this.recipientCount = this.recipientsRecordset.totalCount;
        });
    }

    setupConfigData() {
        this.config = {
            ...this.config,
            action: WorkflowActions.ViewBatchDetail,
        };
    }

    async getPaymentData() {
        if (this.paymentId && this.batchName) {
            try {
                const [achBatchDetail] = await this.client.getPaymentDraft(
                    this.paymentId,
                    this.batchName
                );
                achBatchDetail.frequency.valueDate = this.selectedPaymentValueDate;
                if (!achBatchDetail) throw new Error('batch not found');
                const recordData = getPaymentHeaderRecord(
                    this.config,
                    new PaymentHeader(achBatchDetail),
                    this.client
                );
                this.paymentHeaderRecord = recordData.source;
                this.fields = recordData.fields;
                this.recipientsRecordset = batchRecipientRecordset(
                    achBatchDetail.recipients,
                    true,
                    WorkflowTypes.Payment,
                    this.config,
                    this.paymentHeaderRecord,
                    this.client,
                    null
                );
                this.schema = getBatchDetailViewSchema(
                    this,
                    this.paymentHeaderRecord,
                    this.recipientsRecordset,
                    this.config
                );

                this.recordsetFilters = this.schema.recordsetConfiguration?.recordsetFilters ?? [];
            } catch (e) {
                console.error(e);
            }
        }
    }

    close() {
        if (this.visible) this.setParentVisibility(false);
    }

    openAddendaDialog(r: Record<BatchRecipient>) {
        this.addendaRecord = r;
        AddendaDialog.open(this);
    }

    handleAction(e: {
        detail: {
            record: Record<BatchRecipient>;
            rowIndex: number;
            action: (r: Record<any>) => void;
        };
    }) {
        e.detail.action(e.detail.record);
    }

    renderAddendaDialog() {
        return html`<addenda-dialog
            .record=${this.addendaRecord}
            .readonly=${true}
        ></addenda-dialog>`;
    }

    renderField(field: keyof (PaymentHeader & StepBase)) {
        if (!this.paymentHeaderRecord || !field) return nothing;
        if (!this.paymentHeaderRecord.fieldTypeForField(field).visible(this.paymentHeaderRecord))
            return nothing;
        return html`<omega-field
            .field=${field}
            .record=${this.paymentHeaderRecord}
        ></omega-field>`;
    }

    renderColumn(column: Column<PaymentHeader & StepBase>) {
        if (!column) return nothing;
        let fields;
        let groups;
        if (column.fields) {
            fields = column.fields.map(field => this.renderField(field));
        }

        return html`<div class="form-column">${fields}${groups}</div>`;
    }

    renderPaymentHeader() {
        if (!this.schema || !this.paymentHeaderRecord) return nothing;

        const { sections } = this.schema;

        if (sections)
            return html` ${sections.map(section => {
                const sectionColumns = section.columns?.map(
                    (column: Column<PaymentHeader & StepBase>) => this.renderColumn(column)
                );
                const sectionFields = section.fields?.map(field => this.renderField(field));
                return html`<div class="omega-flex-form">${sectionColumns}</div>
                    <div class="form-fields">${sectionFields}</div>`;
            })}`;
        return nothing;
    }

    renderRecipientsTable() {
        if (!this.schema.recordsetConfiguration) return nothing;
        if (!this.recipientsRecordset) return nothing;
        const itemLabel = { singular: 'recipient', plural: 'recipients' };

        const { recordsetColumns } = this.schema.recordsetConfiguration;
        const columnDefinitions =
            typeof recordsetColumns === 'function'
                ? recordsetColumns(this.schema.recordsetConfiguration.recordset)
                : recordsetColumns;

        const filters = this.schema.recordsetConfiguration.recordsetFilters
            ? html`<omega-filter-bar
                  .recordset=${this.recipientsRecordset}
                  .itemLabel=${itemLabel}
                  .filters=${this.schema.recordsetConfiguration.recordsetFilters}
                  @change=${(e: any) => {
                      this.recordsetFilters = e.detail;
                  }}
              >
              </omega-filter-bar> `
            : nothing;
        const table = html`<omega-table
            .recordset=${this.recipientsRecordset}
            .itemLabel=${itemLabel}
            .columnDefinitions=${columnDefinitions}
            .filters=${this.recordsetFilters}
            @action=${this.handleAction}
        ></omega-table>`;
        return [filters, table];
    }

    renderTitle() {
        return html`<div class="title">
            <div class="form-title" style="font-size: 24px; margin-top: 20px; margin-left: 5px;">
                Batch Detail
            </div>
        </div>`;
    }

    renderPaymentTitle() {
        if (this.paymentHeaderRecord) {
            const formHeading = this.paymentHeaderRecord
                ? this.paymentHeaderRecord.getField('name')
                : '';
            const recipientCount =
                this.recipientsRecordset && this.recipientsRecordset.totalCount > 0
                    ? html`<span class="recipient-count">${this.recipientCount} Recipient(s)</span>`
                    : nothing;
            return html`<h2 class="step-title">${formHeading} ${recipientCount}</h2> `;
        }
        return nothing;
    }

    renderCloseButton() {
        return html`<omega-button class="close-button-bottom" type="primary" @click=${this.close}
            >Close</omega-button
        >`;
    }

    renderContent() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        if (this.paymentHeaderRecord && this.schema)
            return html`
                ${this.renderPaymentTitle()} ${this.renderPaymentHeader()}
                ${this.renderRecipientsTable()} ${this.renderCloseButton()}
            `;

        return nothing;
    }

    render() {
        if (this.visible)
            return html`<omega-dialog
                    @close=${this.close}
                    .open=${this.visible}
                    .dialogTitle=${this.renderTitle()}
                >
                    <div slot="content">
                        <div class="dialog-content">${this.renderContent()}</div>
                    </div>
                </omega-dialog>
                ${this.renderAddendaDialog()}`;

        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
            .error {
                color: var(--omega-error, red);
                text-align: center;
            }
            .omega-flex-form {
                display: flex;
            }
            omega-field {
                --omega-label: 14px;
                --omega-field-control-font-size: 14px;
                --omega-field-control-font-weight: 700;
                --omega-field-control-font-weight-editable: 400;
                --omega-field-control-width: 100px;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-fields {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid var(--omega-secondary-lighten-300);
            }
            omega-field {
                margin: 10px;
            }
            omega-field-2 {
                margin: 10px;
            }
            fieldset {
                background: var(--omega-primary--focus);
                border: 2px solid var(--omega-primary-darken-100);
                border-radius: 3px;
            }

            legend {
                padding: 5px;
                color: var(--omega-white);
                font-size: var(--omega-label);
                background: var(--omega-primary-darken-100);
                border: 2px solid var(--omega-primary-darken-100);
                border-radius: 3px;
            }

            .form-title-container {
                margin: 0;
                display: flex;
                align-items: center;
            }
            .form-title-container .title {
                flex: 1;
            }
            .form-title {
                font-size: 24px;
                color: var(--omega-text-header);
            }
            .form-title-container .toolbar {
                display: flex;
                align-items: center;
            }

            .form-title-container .toolbar .content {
                padding-right: 2px;
            }

            hr {
                border: none;
                border-top: var(--omega-default-border);
            }
            .payment-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .payment-title span {
                font-style: italic;
            }
            .header-margin-top {
                margin-top: 20px;
            }
            .recipient-count {
                font-size: 13px;
                font-weight: 400;
                display: inline-block;
                margin-left: 10px;
            }
            .close-button-bottom {
                margin-top: 30px;
            }
        `;
    }
}

customElements.define('batch-detail-modal', BatchDetailModal);
