import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '@treasury/omega/components/omega-alert';
import '@treasury/omega/components/omega-button-bar';
import '@treasury/omega/components/omega-file-upload';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-radio-group';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/omega-workflow';
import '@treasury/omega/components/progress/omega-progress';
import './templates/initial-step-radio-group.template';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { IIssuedItem, IssuedItemSaved } from '@treasury/domain/channel/mappings/arp';
import { ArpAccountsService, ArpService } from '@treasury/domain/channel/services';
import { Recordset, RecordsetEvent } from '@treasury/FDL';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { Action, LitPropertyVerifier, noOp } from '@treasury/utils';

import { confirmStepFields, createFields, createRecordset, reviewStepFields } from './data';
import {
    IssuedItemsInitialStepRadioGroup,
    IssuedItemsRadioSelectEvent,
    renderConfirmationStep,
    renderCreationStep,
    renderReviewStep,
    renderUploadStep,
} from './templates';

export const IssuedItemsCreationTagName = 'issued-items-creation-container';

const allStepLabels = ['Upload Issued Items File', 'Manage Issued Items', 'Review', 'Confirmation'];

export enum IssuedItemCreationStep {
    Upload = 'upload-step',
    Create = 'create-step',
    Review = 'review-step',
    Confirm = 'confirm-step',
}

@customElement(IssuedItemsCreationTagName)
export class IssuedItemsCreationContainer extends LitPropertyVerifier {
    @property({
        type: String,
    })
    public institution!: string;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare accountService: ArpAccountsService;

    @InjectProperty()
    private declare arpService: ArpService;

    @InjectProperty()
    private declare dialogService: OmegaDialogService;

    @state()
    private activeStep = IssuedItemCreationStep.Upload;

    @state()
    private recordset!: Recordset<IIssuedItem>;

    @state()
    private savedItemsRecordset?: Recordset<IssuedItemSaved>;

    @state()
    private failureMessage?: string;

    @state()
    private successMessage?: string;

    private uploadedFileName?: string;

    @state()
    private stepLabels: string[] = [];

    protected verifiedPropNames: (keyof this)[] = ['institution'];

    @state()
    private hadRequestFailure = false;

    private readonly unsubscribeHandles: Action[] = [];

    private get isUploadWorkflow() {
        return this.stepLabels.length === 4;
    }

    private get activeRecordset() {
        return this.savedItemsRecordset || this.recordset;
    }

    private get radioGroup() {
        const element = this.shadowRoot?.querySelector<IssuedItemsInitialStepRadioGroup>(
            'issued-items-initial-step-radio-group'
        );

        if (!element) {
            throw new Error('Could not get radio group.');
        }

        return element;
    }

    private init(
        activeStep:
            | IssuedItemCreationStep.Upload
            | IssuedItemCreationStep.Create = IssuedItemCreationStep.Upload
    ) {
        if (this.recordset) {
            this.recordset.removeListeners();
        }

        if (this.savedItemsRecordset) {
            this.savedItemsRecordset.removeListeners();
        }

        this.hadRequestFailure = false;
        this.failureMessage = undefined;
        this.successMessage = undefined;
        this.savedItemsRecordset = undefined;
        this.recordset = createRecordset(this.accountService);
        this.recordset.update();
        this.activeStep = activeStep;
        this.setStepLabels(activeStep);

        const handle = this.recordset.listen(RecordsetEvent.Error, ({ detail }) => {
            this.failureMessage = detail.error.message;
            this.hadRequestFailure = true;
        });
        this.unsubscribeHandles.push(handle.unsubscribe);
    }

    protected firstUpdated(changedProperties: Map<keyof IssuedItemsCreationContainer, unknown>) {
        super.firstUpdated(changedProperties);
        this.init();
    }

    public disconnectedCallback() {
        // eslint-disable-next-line wc/guard-super-call
        super.disconnectedCallback();
        this.unsubscribeHandles.forEach(unsubscribe => unsubscribe());
    }

    /**
     *
     * @param nextStep The proposed step to transition to.
     * @returns A promise containing `true` if the transition should occur, otherwise one containing `false`.
     */
    private async showUndoDialog(nextStep: IssuedItemCreationStep) {
        const prompt = 'Are you sure you want to undo all of your changes?';
        const { reason } = await this.dialogService.open(prompt, 'Undo Changes').closed;
        return this.handleUndoDialogClose(reason, nextStep);
    }

    private async showCancelDialog() {
        const prompt = 'Are you sure you want to cancel the changes?';
        const { reason } = await this.dialogService.open(prompt, 'Continue Editing').closed;
        this.handleCancelDialogClose(reason);
    }

    private saveItems() {
        const items = this.recordset.allRecords
            .map(record => record.values)
            .filter(item => item.voidEligible);
        return this.arpService.saveIssuedItems(items, this.uploadedFileName);
    }

    /**
     *
     * @param reason The dialog exist reason.
     * @param nextStep The proposed step to transition to.
     * @returns `true` if the transition should occur.
     */
    private handleUndoDialogClose(reason: OmegaDialogExitReason, nextStep: IssuedItemCreationStep) {
        if (nextStep !== IssuedItemCreationStep.Upload) {
            return false;
        }

        if (reason === OmegaDialogExitReason.Confirm) {
            this.recordset.reset(true);
            this.requestUpdate();
            return true;
        }

        return false;
    }

    private handleCancelDialogClose(reason: OmegaDialogExitReason) {
        if (reason !== OmegaDialogExitReason.Confirm) {
            return;
        }

        // if this is the creation step and it follows the upload step,
        // return to the upload step
        if (this.activeStep === IssuedItemCreationStep.Create && this.stepNumber === 1) {
            this.recordset = createRecordset(this.accountService);
            this.activeStep = IssuedItemCreationStep.Upload;
        } else {
            this.navigateToActivityView();
        }
    }

    private async changeInitialStep(event: IssuedItemsRadioSelectEvent) {
        const { next, previous } = event.detail;

        let commenceTransition = true;
        if (next === IssuedItemCreationStep.Upload && this.recordset.hasChanged) {
            commenceTransition = await this.showUndoDialog(next);
        }

        if (!commenceTransition) {
            // state in this class hasn't changed yet; manually reset it on component
            if (previous) {
                this.radioGroup.step = previous;
            }

            return;
        }

        this.setStepLabels(next);
        this.activeStep = next;
    }

    /**
     * Set the step labels based on whether the initial step is the upload tab or the creation tab.
     */
    private setStepLabels(
        activeStep: IssuedItemCreationStep.Upload | IssuedItemCreationStep.Create
    ) {
        const labels = [...allStepLabels];
        if (activeStep === IssuedItemCreationStep.Create) {
            labels.shift();
        }

        // re-assign array reference to get Lit to recognize an update
        this.stepLabels = labels;
    }

    /**
     * Map the active step label to its sequence number within the workflow tabs, based on
     * whether or not the upload step has been selected in the radio group.
     */
    private get stepNumber() {
        const includesUpload = this.stepLabels.length === 4;

        switch (this.activeStep) {
            case IssuedItemCreationStep.Upload:
                if (!includesUpload) {
                    throw new Error(
                        'Cannot map upload step to sequence number. It is not selected in the radio group.'
                    );
                }

                return 0;
            case IssuedItemCreationStep.Create:
                return includesUpload ? 1 : 0;
            case IssuedItemCreationStep.Review:
                return includesUpload ? 2 : 1;
            case IssuedItemCreationStep.Confirm:
                return includesUpload ? 3 : 2;
            default:
                throw new Error('Could not map active step to sequence number.');
        }
    }

    private navigateToActivityView() {
        this.navService.navigate('payables.arp.activity');
    }

    private createSavedItemRecordset() {
        const recordset = new Recordset<IssuedItemSaved>(confirmStepFields, () => this.saveItems());

        const handle = recordset.listen(RecordsetEvent.Error, ({ detail }) => {
            this.failureMessage = detail.error.message;
            this.hadRequestFailure = true;
        });

        this.unsubscribeHandles.push(handle.unsubscribe);

        return recordset;
    }

    private renderUploadStep() {
        return renderUploadStep(
            () => this.navigateToActivityView(),
            ({ items, fileName }) => {
                this.recordset = createRecordset(this.accountService, items);
                this.recordset.update();
                this.uploadedFileName = fileName;
                this.activeStep = IssuedItemCreationStep.Create;
            }
        );
    }

    private renderCreationStep() {
        const { recordset } = this;
        recordset.applyFieldTypes(createFields(this.accountService));

        return renderCreationStep({
            recordset,
            onNext: () => {
                recordset.saveState();
                this.activeStep = IssuedItemCreationStep.Review;
            },
            onCancel: () => {
                this.showCancelDialog();
            },
        });
    }

    private renderReviewStep() {
        const { recordset } = this;
        recordset.applyFieldTypes(reviewStepFields);

        return renderReviewStep({
            recordset,
            onNext: () => {
                this.activeStep = IssuedItemCreationStep.Confirm;
            },
            onBack: () => {
                recordset.revertState();
                this.activeStep = IssuedItemCreationStep.Create;
            },
            onCancel: () => this.showCancelDialog(),
        });
    }

    private renderConfirmationStep() {
        if (!this.savedItemsRecordset) {
            this.savedItemsRecordset = this.createSavedItemRecordset();
        }

        return renderConfirmationStep({
            recordset: this.savedItemsRecordset,
            onRestart: () => {
                this.init();
            },
            onResult: results => {
                const { successMessage, failureMessage } = results;
                // don't overwrite existing messages with empty values
                if (successMessage) {
                    this.successMessage = successMessage;
                }

                if (failureMessage) {
                    this.failureMessage = failureMessage;
                }
            },
        });
    }

    private renderStep() {
        switch (this.activeStep) {
            case IssuedItemCreationStep.Upload:
                return this.renderUploadStep();
            case IssuedItemCreationStep.Create:
                return this.renderCreationStep();
            case IssuedItemCreationStep.Review:
                return this.renderReviewStep();
            case IssuedItemCreationStep.Confirm:
                return this.renderConfirmationStep();
            default:
                throw new Error(`Invalid step ID. Cannot render step: ${this.activeStep}.`);
        }
    }

    private renderRadioGroup() {
        if (this.stepNumber > 0) {
            return nothing;
        }

        return html`<issued-items-initial-step-radio-group
            .step=${this.activeStep}
            @change=${(e: IssuedItemsRadioSelectEvent) => this.changeInitialStep(e)}
        ></issued-items-initial-step-radio-group>`;
    }

    private renderReportTop() {
        if (this.activeStep === IssuedItemCreationStep.Upload || this.hadRequestFailure) {
            return nothing;
        }

        return html` <hr />
            <issued-items-report-top
                totalsLabel="Total Checks"
                .recordset=${this.activeRecordset}
                .onFilter=${noOp}
                .includeExistingVoids=${false}
            ></issued-items-report-top>
            <hr />`;
    }

    private renderAlerts() {
        const templates: TemplateResult[] = [];

        if (this.successMessage) {
            templates.push(
                html`<omega-alert
                    .closeable=${false}
                    .isVisible=${true}
                    title="Success"
                    type="success"
                    alignment="left"
                    >${this.successMessage}</omega-alert
                >`
            );
        }

        if (this.failureMessage) {
            templates.push(
                html`<omega-alert
                    .closeable=${false}
                    .isVisible=${true}
                    title="Failure"
                    type="error"
                    alignment="left"
                    >${this.failureMessage}</omega-alert
                >`
            );
        }

        return templates;
    }

    protected render() {
        if (!this.recordset) {
            return html`<omega-progress></omega-progress>`;
        }

        return html`
            <h1>Create Issued Items</h1>
            <div class="workflow-container">
                <omega-workflow .activeStep=${this.stepNumber} .stepLabels=${this.stepLabels}>
                    <div slot="step">
                        ${this.renderRadioGroup()} ${this.renderAlerts()} ${this.renderReportTop()}
                        ${this.renderStep()}
                    </div>
                </omega-workflow>
            </div>
        `;
    }

    public static styles = css`
        .workflow-container {
            position: relative;
            background-color: #fff;
            box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
        }
    `;
}
