import { boolean, Recordset } from '@treasury/FDL';
import { internationalAchOriginatorCompany } from '@treasury/policy/ach/international-ach-originator.js';
import {
    internationalAchPaymentRecipient,
    internationalAchPaymentRecipientAmount,
    internationalAchPaymentRecipientBankIdType,
    internationalAchPaymentRecipientBankName,
    internationalAchPaymentRecipientBankNumber,
    internationalAchPaymentRecipientHold,
    internationalAchPaymentRecipientIdNumber,
    internationalAchPaymentRecipientPaymentType,
    internationalAchPaymentRecipientPrenote,
    internationalAchPaymentRecipientState,
    internationalAchPaymentRecipientStreetAddress,
    internationalAchRecipientAddenda,
    internationalRecipientAccountNumber,
    internationalRecipientAccountType,
    internationalRecipientCity,
    internationalRecipientCountry,
    internationalRecipientZipCode,
    internationalTransactionType,
} from '@treasury/policy/ach/international-ach-recipient.js';
import { nachaCountryCode } from '@treasury/policy/nacha';
import { number, string } from '@treasury/policy/primitives';
import InternationalAchPaymentClient from '../clients/international-ach-payment-client.js';

const generateActions = editable => {
    const options = [
        {
            action: 'add',
            tooltip: 'This feature adds a new empty recipient',
            collapseAllValidRows: true,
            isDisabled: () => !editable,
        },
        {
            action: 'clone',
            tooltip:
                "This feature may be used to copy all the existing recipient's data to a new recipient",
            fieldsToClone: [
                'name',
                'streetAddress',
                'city',
                'state',
                'zipCode',
                'country',
                'idNumber',
                'accountType',
                'accountNumber',
                'iatTransactionType',
                'transactionType',
                'amount',
                'prenote',
                'hold',
                'addenda',
                // bank info
                'receivingBankName',
                'receivingBankType',
                'receivingBankNumber',
                'receivingBankCountry',
                // payment header linked fields
                'destination',
                'achCompany',
            ],
            // to do: fix test suite to enable collapse all functionality on clone
            collapseAllValidRows: true,
            isDisabled: () => !editable,
        },
        {
            action: 'delete',
            isDisabled: () => !editable,
        },
    ];
    return editable ? options : [];
};

export const columns = editable => [
    {
        field: 'name',
        label: 'Recipient Name',
        editable,
    },
    {
        field: 'prenote',
        label: 'Prenote',
        editable,
        type: 'checkbox',
        sortable: true,
    },
    {
        field: 'hold',
        label: 'Hold',
        editable,
        type: 'checkbox',
        sortable: true,
    },
    {
        field: '',
        label: '',
        type: 'edit-row',
        options: generateActions(editable),
    },
    {
        field: '',
        label: '',
        'display-toggle-all': true,
        'display-chevron': true,
        type: 'detail',
    },
];

const destinationNotSelected = record => !record.getField('destination');

const notStep1 = record =>
    /* This shows as step 1 in the UI bc arrays start at position 0 */
    record.getField('step') !== 0;

const isNachaUploadStep2 = record => {
    const step2 = record.getField('step') === 1;
    return step2 && record.getField('nachaUpload');
};

const hasFullEditIntlAchPaymentEntitlement = entitlements =>
    entitlements.find(
        entitlement => entitlement.permission === 'Full Edit International ACH Payment'
    );

const shouldRestrictEditing = (status, entitlements) => {
    const restrictedStatus = status === 'Expired' || status === 'Approval Rejected';
    return restrictedStatus && !hasFullEditIntlAchPaymentEntitlement(entitlements);
};

export const recipientsFields = (
    client,
    payment = { status: '' },
    configurationDetails = { entitlements: [{ permission: '' }] }
) => {
    const AchIntlServices = client ?? new InternationalAchPaymentClient();

    const { status } = payment;
    const { entitlements } = configurationDetails;
    const restrictedEditing = shouldRestrictEditing(status, entitlements);
    const editingIsNotPermitted = () => restrictedEditing;

    return {
        name: internationalAchPaymentRecipient.thatIs
            .required()
            .thatIs.disabledWhen(notStep1)
            .with.label('Recipient Name')
            .thatIs.readOnlyWhen(editingIsNotPermitted)
            .with.minColumnWidth(200)
            .as.tag('omega-input'),
        streetAddress: internationalAchPaymentRecipientStreetAddress.with
            .label('Street Address')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        city: internationalRecipientCity.with
            .label('City')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        state: internationalAchPaymentRecipientState.with
            .label('State/Providence')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        zipCode: internationalRecipientZipCode.with
            .label('Zip Code')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        country: internationalRecipientCountry.with
            .label('Country')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        idNumber: internationalAchPaymentRecipientIdNumber.with
            .label('ID Number')
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        accountType: internationalRecipientAccountType.with
            .label('Account Type')
            .thatIs.disabledWhen(destinationNotSelected)
            .with.options({
                fetch: record => {
                    const destination = record.getField('destination');
                    if (destination) {
                        const accountType = destination.internationalAchAccountTypes;
                        const isZeroDollarRecipient = Number(record.getField('amount')) === 0;
                        if (isZeroDollarRecipient) {
                            return new Promise(resolve =>
                                resolve(accountType.filter(type => type.accountName !== 'Loan'))
                            );
                        }
                        return new Promise(resolve => resolve(accountType));
                    }
                    return new Promise(resolve => resolve([]));
                },
                text: record => record.accountName,
                value: record => record.accountName,
            })
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-select'),
        accountNumber: internationalRecipientAccountNumber.with
            .label('Account Number')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        iatTransactionType: internationalTransactionType.with
            .label('Transaction Type')
            .with.options({
                fetch: AchIntlServices.getInternationalTransactionTypes,
                text: record => `${record.code} - ${record.name}`,
                value: record => record,
            })
            .with.template(record => (record ? `${record.code} - ${record.name}` : ''))
            .with.hashFunction(a => a?.code)
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-select'),
        achCompany: internationalAchOriginatorCompany.as.tag('omega-input'),
        transactionType: internationalAchPaymentRecipientPaymentType.with
            .label('Debit/Credit')
            .thatIs.disabledWhen(destinationNotSelected)
            .thatIs.disabledWhen(record => !record.getField('accountType'))
            .thatIs.disabledWhen(record => !record.getField('destination'))
            .thatIs.required()
            .with.options({
                fetch: record => {
                    const destination = record.getField('destination');
                    const accountType = record.getField('accountType');
                    if (!destination) return new Promise(resolve => resolve([]));
                    if (destination.countryCode === 'CA' && accountType !== 'Loan')
                        return new Promise(resolve => resolve(['Debit', 'Credit']));
                    return new Promise(resolve => resolve(['Credit']));
                },
                text: record => record,
                value: record => record,
            })
            .with.validator({
                name: 'Allow debits for Canada and for AccountType of Loan',
                validate: (value, viewValue, record) => {
                    if (value === 'Debit') {
                        const destination = record.getField('destination');
                        const accountType = record.getField('accountType');
                        return destination?.countryCode === 'CA' || accountType === 'Loan';
                    }
                    return true;
                },
            })
            .with.validator({
                name: 'Ach company allows option(s)',
                validate: (value, viewValue, record) => {
                    const achCompany = record.getField('achCompany');
                    if (achCompany) {
                        const creditTypes = ['Debits and Credits', 'Credit Only'];
                        const debitTypes = ['Debits and Credits', 'Debit Only'];
                        if (value === 'Credit') {
                            return creditTypes.includes(achCompany.notOnUsTransactionTypes);
                        }
                        if (value === 'Debit') {
                            return debitTypes.includes(achCompany.notOnUsTransactionTypes);
                        }
                    }

                    return false;
                },
            })
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-select'),

        amount: internationalAchPaymentRecipientAmount.with
            .label('Amount')
            .with.validator({
                name: 'AccountType is Loan',
                validate: (value, viewValue, record) =>
                    record.getField('accountType')?.toLowerCase() !== 'loan' || value > 0,
            })
            .thatIs.disabledWhen(record => !record.getField('transactionType'))
            .thatIs.disabledWhen(record => record.getField('prenote'))
            .thatIs.requiredWhen(record => record.getField('prenote') !== true)
            .thatIs.readOnlyWhen(notStep1)
            .as.tag('omega-input'),

        prenote: internationalAchPaymentRecipientPrenote.thatIs
            .readOnlyWhen(notStep1)
            .thatHas.label('')
            .as.tag('omega-checkbox'),
        hold: internationalAchPaymentRecipientHold.thatIs
            .readOnlyWhen(notStep1)
            .thatHas.label('')
            .as.tag('omega-checkbox'),
        addenda: internationalAchRecipientAddenda.with
            .defaultValue([''])
            .and.maxLength(80)
            .thatIs.readOnlyWhen(record => notStep1(record) && !isNachaUploadStep2(record))
            .and.readOnlyWhen(editingIsNotPermitted),
        receivingBankName: internationalAchPaymentRecipientBankName.with
            .label('Bank Name')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        receivingBankType: internationalAchPaymentRecipientBankIdType.with
            .label('Bank Id Type')
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-select'),
        receivingBankNumber: internationalAchPaymentRecipientBankNumber.with
            .label('Bank Number')
            .thatHas.maxLength(34)
            .thatIs.required()
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-input'),
        receivingBankCountry: nachaCountryCode.with
            .label('Receiving Bank Country')
            .thatIs.required()
            .thatIs.disabledWhen(destinationNotSelected)
            .with.options({
                fetch: async record => {
                    const destination = record.getField('destination');
                    if (destination) {
                        return AchIntlServices.getDestinationCountry(destination);
                    }
                    return Promise.resolve([]);
                },
                fetchOnChange: true,
                text: record => `${record.countryCode} - ${record.countryName}`,
                value: record => record,
            })
            .with.template(value => (typeof value === 'string' ? value : `${value?.countryCode}`))
            .with.hashFunction(a => a?.countryName || a)
            .thatIs.readOnlyWhen(notStep1)
            .and.readOnlyWhen(editingIsNotPermitted)
            .as.tag('omega-select'),
        destination: string.thatIs.visibleWhen(() => false),
        step: number.thatIs.visibleWhen(() => false),
        nachaUpload: boolean.thatIs.visibleWhen(() => false),
    };
};

export const defaultRecipient = {
    name: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    idNumber: '',
    accountType: '',
    accountNumber: '',
    iatTransactionType: '',
    transactionType: '',
    amount: 0,
    prenote: false,
    hold: false,
    addenda: [],
    // bank info
    receivingBankName: '',
    receivingBankType: '',
    receivingBankNumber: '',
    receivingBankCountry: '',
    destination: '',
    step: 0,
    nachaUpload: false,
};

/**
 * @description when data comes from service response, some values are misaligned with values needed for setting UI
 * @returns the recipient with the appropriate data mutations
 */
export const formatRecipientData = recipient => {
    // Ensure local value is 'Debit' or 'Credit'. When data comes from service response the value is 'CR' or 'DR'
    if (['CR', 'DR'].includes(recipient.transactionType)) {
        recipient.transactionType = { CR: 'Credit', DR: 'Debit' }[recipient.transactionType];
    }

    return recipient;
};

export const recipients = (data, client) => {
    const dataToMap = data ?? [{ ...defaultRecipient }];

    dataToMap.map(formatRecipientData).map(recipient => {
        const completeRecipient = {};
        Object.keys(defaultRecipient).forEach(key => {
            completeRecipient[key] = recipient[key] ?? recipientsFields(client)[key].defaultValue();
        });
        return completeRecipient;
    });
    return dataToMap;
};

export const recipientRecordset = (data, payment, configurationDetails, client) =>
    new Recordset(
        recipientsFields(client, payment, configurationDetails),
        recipients(data, client)
    );
