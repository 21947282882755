import '@treasury/omega/components/progress/omega-progress.js';
import { css, html, LitElement } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { TermsAndConditionsClient } from './terms-and-conditions-client.js';

export class TermsAndConditions extends LitElement {
    static get properties() {
        return {
            termsAndConditions: Object,
            showAcceptanceDate: Boolean,
            loading: Boolean,
        };
    }

    constructor() {
        super();
        this.title = `Terms & Conditions`;
        this.client = new TermsAndConditionsClient();
        this.loading = true;
        this.termsAndConditions = {};
        this.showAcceptanceDate = false;
    }

    async connectedCallback() {
        super.connectedCallback();

        if (!this.termsAndConditions.text) {
            const result = await this.client.getTermsAndConditions();
            if (result) {
                this.termsAndConditions = result;
                this.loading = false;
            }
            return;
        }
        this.loading = false;
    }

    handleDownload(e) {
        this.client.downloadTermsAndConditions(e.detail.downloadType);
    }

    handlePrint() {
        window.print();
    }

    formattedLastAcceptedDate() {
        // TODO: implement this in fi-datetime and use it here.
        const date = new Date(this.termsAndConditions.lastAcceptedDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const dayOfMonth = String(date.getDate()).padStart(2, '0');
        return `${month}/${dayOfMonth}/${year}`;
    }

    renderLastAcceptedOn() {
        if (!this.showAcceptanceDate) return ``;
        if (!this.termsAndConditions?.lastAcceptedDate) return ``;

        return html` <div class="body-coda">
            <strong>Accepted On: ${this.formattedLastAcceptedDate()} </strong>
        </div>`;
    }

    render() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        return html`<div>
            <div>
                <omega-action-bar
                    .title="${this.title}"
                    .downloadOptions=${['PDF']}
                    @download="${this.handleDownload}"
                    @print="${this.handlePrint}"
                ></omega-action-bar>
            </div>
            <div class="body-text">${unsafeHTML(this.termsAndConditions.text)}</div>
            ${this.renderLastAcceptedOn()}
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .body-text {
                margin-left: 16px;
                margin-top: 8px;
            }
            .body-coda {
                margin-top: 8px;
            }
        `;
    }
}

window.customElements.define('terms-and-conditions', TermsAndConditions);
