import { AbstractConstructor, Constructor, PrimitiveConstructor } from '../types';
import { exists } from './object.helpers';

export function isConstructor<T>(maybeCtor: unknown): maybeCtor is Constructor<T> {
    if (typeof maybeCtor !== 'function') {
        return false;
    }

    // https://stackoverflow.com/a/48036194/574930
    try {
        const handler = {
            construct() {
                return {};
            },
        };
        const proxy = new Proxy(maybeCtor, handler);
        const instance = new (proxy as any)();
        return exists(instance);
    } catch (e) {
        return false;
    }
}

export function isPrimitiveCtor<T>(
    ctor: Constructor<T> | AbstractConstructor<T> | PrimitiveConstructor
): ctor is PrimitiveConstructor {
    const { name } = ctor;
    switch (name) {
        case 'Number':
        case 'String':
        case 'Array':
        case 'Date':
        case 'Object':
            return true;
        default:
            return false;
    }
}

/**
 * Produces a list of constructors found in an object's
 * [prototype chain](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Inheritance_and_the_prototype_chain),
 * starting with (and including) the provided constructor.
 */
function getPrototypeList<T>(ctor: Constructor<T> | AbstractConstructor<T>) {
    const list: (Constructor<unknown> | AbstractConstructor<unknown>)[] = [ctor];
    let { prototype } = ctor;
    let nextProto = Object.getPrototypeOf(prototype);

    // Object is the root of all types and is not useful
    // in determining a base class
    while (exists(nextProto) && nextProto.constructor !== Object) {
        prototype = nextProto;
        nextProto = Object.getPrototypeOf(prototype);
        list.push(prototype.constructor);
    }

    return list;
}

/**
 * Traverses the prototype chain of a constructor until it
 * finds the first, non-`Object` constructor.
 */
export function getBaseClass<T, S extends T>(ctor: Constructor<S> | AbstractConstructor<S>) {
    const protoList = getPrototypeList(ctor);

    return protoList[protoList.length - 1] as Constructor<T>;
}

/**
 * Returns `true` if `child` is a subclass of `parent`.
 *
 * Supports deep class hierarchies.
 *
 * @param parent The parent class to check against.
 * @param child The subclass to verify.
 */
export function isSubclassOf<T>(
    parent: Constructor<T> | AbstractConstructor<T>,
    child: Constructor<unknown>
): child is Constructor<T> {
    if (parent === child) {
        return false;
    }

    const childProtoList = getPrototypeList(child);

    // any constructor that appears somewhere in the prototype list
    // of the tested class is considered a parent of it
    return childProtoList.includes(parent);
}
