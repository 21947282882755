import DateModel from '../../components/datepicker/DateModel.js';

export default function inDateRange(key, start, end) {
    return record => {
        function normalizedDate(DateString) {
            const dateObj = new Date(DateString);
            return new Date(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate());
        }

        // If the record doesn't have the field or doesn't have a date value don't return the record
        if (!record.hasField(key) || !record.getField(key)) return false;

        const date = new DateModel(normalizedDate(record.getField(key)));
        const startDate = new DateModel(normalizedDate(start));

        if (!end) {
            return date.equals(startDate);
        }

        const endDate = new DateModel(normalizedDate(end));
        return date.inRangeOf(startDate, endDate);
    };
}
