AchExceptionsListController.$inject = [
    '$scope',
    '$filter',
    '$modal',
    '$timeout',
    'toaster',
    'companyAccountsService',
    'arpService',
    '$stateParams',
    '$state',
];

export default function AchExceptionsListController(
    $scope,
    $filter,
    $modal,
    $timeout,
    toaster,
    companyAccountsService,
    arpService,
    $stateParams,
    $state
) {
    $scope.filterObject = {};
    $scope.accounts = [];
    $scope.secCodes = [];
    $scope.amountOptions = ['Specific Amount', 'Amount Range'];
    $scope.searchText = '';
    $scope.filteredExceptions = [];
    $scope.achExceptionsWorking = {};
    $scope.achExceptionsPristine = {};
    $scope.allowEdit = true;
    $scope.processingCutoff = {};
    $scope.payToggle = [
        {
            name: 'Pay',
            value: false,
        },
        {
            name: 'Return',
            value: false,
        },
    ];

    $scope.search = search;
    $scope.reset = reset;
    $scope.filterSearch = filterSearch;
    $scope.setPay = setPay;
    $scope.review = review;
    $scope.undoChanges = undoChanges;
    $scope.isDataLoaded = false;

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('AchException').then(response => {
            $scope.processingCutoff = response;
            const timeToStart = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.startTime
                }`
            ).diff(response.currentFiTime);
            const timeToCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            if (timeToStart <= 0 && timeToCutoff > 0) {
                $scope.allowEdit = true;
            }
            if (timeToStart > 0) {
                $timeout(() => {
                    toaster.alert(
                        'Start Time Passed',
                        'You can now edit decisions for the listed exceptions.'
                    );
                    $scope.allowEdit = true;
                }, timeToStart);
            }
            if (timeToCutoff > 0) {
                $timeout(() => {
                    toaster.alert(
                        'Cutoff Time Passed',
                        'You can no edit decisions for the listed exceptions.'
                    );
                    $scope.allowEdit = false;
                }, timeToCutoff);
            }
        });
    }

    function init() {
        reset();
        loadAccounts();
        loadCutoffTimes();
    }

    // Public Functions
    function search(filteredObject) {
        arpService.searchAchExceptions(filteredObject).then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            clearToggles();
            $scope.achExceptionsWorking = response;
            $scope.achExceptionsPristine = angular.copy($scope.achExceptionsWorking);
            filterSearch($scope.searchText);
            $scope.isDataLoaded = true;
        });
    }

    function reset() {
        angular.forEach($scope.accounts, account => {
            account.isChecked = true;
        });
        angular.forEach($scope.secCodes, code => {
            code.isChecked = true;
        });
        $scope.filterObject.amountType = '';
        $scope.filterObject.amountStart = '';
        $scope.filterObject.amountEnd = '';
        $scope.filterObject.amount = '';
    }

    function filterSearch(searchText) {
        $scope.searchText = searchText || '';
        $scope.filteredExceptions = $filter('filter')(
            $scope.achExceptionsWorking,
            $scope.searchText
        );
    }

    function setPay(toggle, index) {
        if (index === 0) {
            // clear other header
            $scope.payToggle[1].value = false;
            // set rows
            if (toggle.value === false) {
                // pay checkbox was unchecked - clear values
                angular.forEach($scope.achExceptionsWorking, exception => {
                    exception.pay = null;
                });
            } else {
                // pay checkbox was checked - set to true
                angular.forEach($scope.achExceptionsWorking, exception => {
                    exception.pay = toggle.value;
                });
            }
        } else {
            // clear other header
            $scope.payToggle[0].value = false;
            if (toggle.value === true) {
                // return checkbox was checked - set pay to false (return)
                angular.forEach($scope.achExceptionsWorking, exception => {
                    exception.pay = !toggle.value;
                });
            } else {
                // return checkbox was unchecked - clear values
                angular.forEach($scope.achExceptionsWorking, exception => {
                    exception.pay = null;
                });
            }
        }

        filterSearch($scope.searchText);
    }

    function review() {
        // only filtered exceptions will be sent for review
        const modalInstance = $modal.open({
            template: require('../views/achExceptionReviewModalView.html'),
            controller: 'AchExceptionReviewModalController',
            backdrop: 'static',
            resolve: {
                achExceptions() {
                    return $scope.filteredExceptions;
                },
            },
        });
        modalInstance.result.then(achExceptions => {
            arpService.updateAchExceptions(achExceptions).then(response => {
                toaster.save('ACH Exception Decisions');
                search($scope.filterObject);
            });
        });
    }

    function undoChanges() {
        clearToggles();
        $scope.achExceptionsWorking = angular.copy($scope.achExceptionsPristine);
        filterSearch($scope.searchText);
    }

    // Private Functions
    function loadAccounts() {
        arpService.getArpAccounts('WorkAchException').then(response => {
            if (response.length === 0) {
                $modal
                    .open({
                        template: require('../views/missingAccountsModal.html'),
                        controller: 'MissingAccountsModalController',
                        backdrop: 'static',
                        resolve: {
                            contextSpecificMessage() {
                                return "You don't have any accounts associated with ACH exceptions.";
                            },
                        },
                    })
                    .result.then(() => {
                        $state.go('dashboard');
                    });
                return;
            }

            $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            $scope.filterObject.accounts = $scope.accounts = response;

            if (!$stateParams.accountNumber) {
                angular.forEach($scope.accounts, account => {
                    account.isChecked = true;
                });
            } else if ($stateParams.accountNumber) {
                angular.forEach($scope.accounts, account => {
                    if (
                        (account.showAccountNickName &&
                            account.name === $stateParams.accountNumber) ||
                        (!account.showAccountNickName &&
                            account.number === $stateParams.accountNumber)
                    ) {
                        account.isChecked = true;
                    } else {
                        account.isChecked = false;
                    }
                });
            }

            loadSecCodes();
        });
    }

    function loadSecCodes() {
        // remove string and put in constant
        arpService.getSecCodes().then(response => {
            $scope.secCodes = response;
            $scope.filterObject.secCodes = [];
            angular.forEach($scope.secCodes, secCode => {
                secCode.isChecked = true;
            });
            search($scope.filterObject);
        });
    }

    function clearToggles() {
        $scope.payToggle[0].value = false;
        $scope.payToggle[1].value = false;
    }

    function checkIfAllSelected(pay) {
        let allSelected = true;
        angular.forEach($scope.achExceptionsWorking, exception => {
            if (exception.pay != pay) {
                allSelected = false;
            }
        });

        if (allSelected && pay === true) {
            $scope.payToggle[0].value = true;
        } else if (allSelected && pay === false) {
            $scope.payToggle[1].value = true;
        }
    }

    $scope.checkToggle = function (pay) {
        if (pay === true && $scope.payToggle[1].value === true) {
            $scope.payToggle[1].value = false;
        } else if (pay === false && $scope.payToggle[0].value === true) {
            $scope.payToggle[0].value = false;
        }
        checkIfAllSelected(pay);
    };

    $scope.dblClickToggle = function (exception) {
        const { pay } = exception;
        if (pay === true && $scope.payToggle[0].value === true) {
            $scope.payToggle[0].value = false;
        } else if (pay === false && $scope.payToggle[1].value === true) {
            $scope.payToggle[1].value = false;
        }
        exception.pay = null;
    };

    function anyExceptionsProtected() {
        for (let i = 0; i < $scope.achExceptionsWorking.length; i++) {
            if ($scope.achExceptionsWorking[i].protected === 'Y') {
                return true;
            }
        }
        return false;
    }

    $scope.canDecision = function (item) {
        return !!item && item.protected !== 'Y';
    };

    $scope.canDecisionAny = function () {
        return !!$scope.achExceptionsWorking && !anyExceptionsProtected();
    };

    init();
}
