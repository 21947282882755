/* eslint-disable @treasury/style-includes-host-display */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-workflow.js';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import AchFilterClient from '../clients/ach-filter-client.js';
import { createAccountFilterMessage } from '../data/account-filter-message.js';
import { createNewFilter } from '../data/create-new-filter.js';
import './steps/workflow-step.js';

class AchFilterWorkflowContainer extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            alert: Object,
            activeStep: Number,
            stepLabels: Array,
            client: Object,
            record: Object,
            selectedException: Object,
            masterSettingsLoading: Boolean,
            masterSettingsLabel: String,
            canContinue: Boolean,
            loading: Boolean,
            mode: String,
        };
    }

    constructor() {
        super();
        this.activeStep = 0;
        this.stepLabels = ['Create ACH Filter', 'Review', 'Confirm'];
        this.masterSettingsLabel = 'Select an account to load its filter settings';
        this.masterSettingsLoading = false;

        /**
         * @type { 'create' | 'clone' | 'edit' | 'create-from-exception' | 'create-from-decision'}
         */
        this.mode = 'create';
    }

    get canContinue() {
        if (this.record) {
            return (
                this.record.isValid() &&
                this.record.hasRequiredValues() &&
                !this.masterSettingsLoading
            );
        }
        return false;
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new AchFilterClient();
            await this.initRecord();
        }
        this.initListener();
    }

    newRecord() {
        this.record = createNewFilter({}, 'create', this.client);
    }

    async initRecord() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        const { params } = await navService.getRouteData();
        const { exceptionJson, existingFilter, referringPage } = params;
        const [mode, id] = window.location.pathname.split('/').slice(-2);
        this.mode = mode;

        if (
            exceptionJson ||
            (id && ['create-from-exception', 'create-from-decision'].includes(mode))
        ) {
            this.referringPage = referringPage;
            if (!exceptionJson) {
                navService.navigate('payables.arp.ach-filter-workflow');
                return undefined;
            }

            return this.createFromException(exceptionJson);
        }

        if (existingFilter || (id && mode === 'edit')) {
            if (!existingFilter) {
                navService.navigate('payables.arp.ach-filter-workflow');
                return undefined;
            }

            return this.createFromExistingFilter(existingFilter);
        }

        return this.newRecord();
    }

    initListener() {
        this.listenTo(this.record, 'change', ({ detail }) => {
            if (detail.field === 'account' && this.record.getField('account')) {
                this.updateAccountMasterSettings();
            }
            this.requestUpdate();
        });
    }

    async createFromException(exception) {
        this.selectedException = await this.client.mapException(exception);
        const account = {
            accountNumber: this.selectedException.accountNumber,
            accountUniqueId: this.selectedException.accountUniqueId,
        };
        this.selectedException.account = account;
        this.record = createNewFilter(this.selectedException, 'create-from-exception', this.client);
        this.updateAccountMasterSettings();
    }

    async createFromExistingFilter(filter) {
        this.mode = filter.achFilterUniqueId ? 'edit' : 'clone';
        const capitalizeMode = this.mode.charAt(0).toUpperCase() + this.mode.slice(1);
        this.stepLabels = [`${capitalizeMode} ACH Filter`, 'Review', 'Confirm'];
        const account = {
            accountNumber: filter.accountNumber,
            accountUniqueId: filter.accountUniqueId,
        };
        filter.account = account;
        this.record = createNewFilter(filter, this.mode, this.client);
        this.updateAccountMasterSettings();
    }

    updateAccountMasterSettings() {
        this.masterSettingsLoading = true;
        const account = this.record.getField('account');
        const uniqueId = account.accountUniqueId;
        this.client.getAccountMasterSettings(uniqueId).then(async response => {
            const { label, allowAllCredits, allowAllDebits } = createAccountFilterMessage(response);
            this.record.setField('allowAllCredits', allowAllCredits);
            this.record.setField('allowAllDebits', allowAllDebits);
            this.masterSettingsLabel = label;
            this.masterSettingsLoading = false;
        });
    }

    async save() {
        const { values } = this.record;
        try {
            this.loading = true;
            if (this.mode === 'edit') {
                await this.client.editFilter(values);
            } else {
                await this.client.saveFilter(values);
            }

            this.alert = {
                visible: true,
                title: '',
                message: 'Filter rule created successfully.',
                type: 'success',
            };
            this.nextStep();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    dismissAlert() {
        this.alert = {
            visible: false,
        };
    }

    nextStep() {
        this.activeStep++;
        this.record.setField('step', this.activeStep);
    }

    previousStep() {
        this.activeStep--;
        this.record.setField('step', this.activeStep);
        this.dismissAlert();
    }

    firstStep() {
        this.record.reset();
        this.activeStep = 0;
        this.record.setField('step', this.activeStep);
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    renderStep() {
        if (!this.client) return nothing;
        return html`
            <workflow-step
                .client=${this.client}
                .record=${this.record}
                .selectedException=${this.selectedException}
                .masterSettingsLabel=${this.masterSettingsLabel}
                .masterSettingsLoading=${this.masterSettingsLoading}
                .canContinue=${this.canContinue}
                .step=${this.activeStep}
                .referringPage=${this.referringPage}
                @next=${this.nextStep}
                @previous=${this.previousStep}
                @save=${() => this.save()}
                @firstStep=${e => this.firstStep(e)}
            ></workflow-step>
        `;
    }

    renderWorkflow() {
        if (!this.stepLabels) return nothing;
        return html`
            <omega-workflow .activeStep=${this.activeStep} .stepLabels=${this.stepLabels}>
                <div slot="step">
                    <div slot="content">${this.renderStep()}</div>
                </div>
            </omega-workflow>
        `;
    }

    renderHeader() {
        let modeLabel = 'Create';
        let modeSuffix = '';
        switch (this.mode) {
            case 'clone':
                modeLabel = 'Clone';
                break;
            case 'edit':
                modeLabel = 'Edit';
                break;
            case 'create-from-exception':
                modeSuffix = ' from Exception';
                break;
            case 'create-from-decision':
                modeSuffix = ' from Decision Activity';
                break;
            default:
                modeLabel = 'Create';
        }
        return html`<h2 class="workflow-header">
            ACH Exceptions - ${modeLabel} ACH Filter Rule${modeSuffix}
        </h2>`;
    }

    render() {
        return html`
            ${this.renderBlockingLoader()} ${this.renderAlert()} ${this.renderHeader()}
            <div class="workflow-container">${this.renderWorkflow()}</div>
        `;
    }

    static get styles() {
        return css`
            .workflow-header {
                font-size: 24px;
                margin-top: 0;
                font-weight: 400;
            }
            .workflow-container {
                position: relative;
                min-height: 60%;
                background-color: #fff;
                box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
            }
            div[slot='step'] {
                height: 100%;
            }
            div[slot='content'] {
                height: 100%;
            }
        `;
    }
}

export default AchFilterWorkflowContainer;
window.customElements.define('ach-filter-workflow-container', AchFilterWorkflowContainer);
