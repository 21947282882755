import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';

export const mapRecordValuesToKeyValuePair = recordsValues =>
    recordsValues.map(record => ({ key: record.id, value: '' }));

export const mapAchTemplatesFieldsToParameters = ({ parameters }) => {
    const { achCompanyList, batchName, creditAmount, debitAmount, secCode, status, updatedDate } =
        parameters;

    const dateType = DateFormatters.getDateType(updatedDate);
    const dateValues = DateFormatters.parseDate(updatedDate)?.value;

    const isDateRange = dateType === 'dateRange';
    const hasValidSpecificDate = !isDateRange && dateValues;

    const mappedParameters = {
        achCompanyList,
        batchName,
        status,
        secCode: secCode === 'all' ? 'All' : secCode,
        creditAmountType: creditAmount[0] === 'range' ? 'Range' : 'Specific Amount',
        debitAmountType: debitAmount[0] === 'range' ? 'Range' : 'Specific Amount',
        updatedDateType: isDateRange ? 'Range' : 'Specific Date',
        updatedDate: hasValidSpecificDate ? dateValues : null,
        lastUpdatedStart: isDateRange ? dateValues.start : null,
        lastUpdatedEnd: isDateRange ? dateValues.end : null,
    };

    if (mappedParameters.creditAmountType === 'Specific Amount')
        mappedParameters.creditAmount = AmountRangeFormatters.getSpecificAmount(creditAmount);

    if (mappedParameters.debitAmountType === 'Specific Amount')
        mappedParameters.debitAmount = AmountRangeFormatters.getSpecificAmount(debitAmount);

    if (mappedParameters.debitAmountType === 'Range') {
        mappedParameters.debitAmountMax = AmountRangeFormatters.getRangeEnd(debitAmount);
        mappedParameters.debitAmountMin = AmountRangeFormatters.getRangeStart(debitAmount);
    }
    if (mappedParameters.creditAmountType === 'Range') {
        mappedParameters.creditAmountMax = AmountRangeFormatters.getRangeEnd(creditAmount);
        mappedParameters.creditAmountMin = AmountRangeFormatters.getRangeStart(creditAmount);
    }

    return mappedParameters;
};
