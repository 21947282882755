import { AchBatchSummary, Config } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import FieldTypes from '@treasury/policy/primitives';

export const getAchBatchSummaryFieldsObject = () => ({
    step: FieldTypes.number.thatIs.visibleWhen(() => false),
    name: FieldTypes.string.thatIs.readOnly().with.label('Name').as.tag('omega-input'),
    totalBalancedBatches: FieldTypes.number.thatIs
        .readOnly()
        .with.label('Total Balanced Batches')
        .as.tag('omega-input'),
    totalBalancedDebitAmount: FieldTypes.money.thatIs
        .readOnly()
        .with.label('Total Balanced Debit')
        .as.tag('omega-input'),
    totalBalancedCreditAmount: FieldTypes.money.thatIs
        .readOnly()
        .with.label('Total Balanced Credits')
        .as.tag('omega-input'),
    totalUnbalancedBatches: FieldTypes.number.thatIs
        .readOnly()
        .with.label('Total Unbalanced Batches')
        .as.tag('omega-input'),
    totalUnbalancedDebitAmount: FieldTypes.money.thatIs
        .readOnly()
        .with.label('Total Unbalanced Debit')
        .as.tag('omega-input'),
    totalUnbalancedCreditAmount: FieldTypes.money.thatIs
        .readOnly()
        .with.label('Total Unbalanced Credits')
        .as.tag('omega-input'),
    fileSize: FieldTypes.string.thatIs.readOnly().with.label('File Size').as.tag('omega-input'),
    batchCount: FieldTypes.number.thatIs.readOnly().with.label('Batch Count').as.tag('omega-input'),
    effectiveDate: FieldTypes.string.thatIs.visibleWhen(() => false),
    oneEffectiveDate: FieldTypes.boolean.thatIs.visibleWhen(() => false).with.defaultValue(false),
    selectedCount: FieldTypes.number.thatIs
        .visibleWhen(() => false)
        .thatHas.validator({
            name: 'at-least-one-batch-is-selected',
            validate: (selected: number) => selected > 0,
        }),
});

export const getBatchSummaryRecord = (
    config: Config,
    batch: AchBatchSummary
): Record<AchBatchSummary> => new Record(getAchBatchSummaryFieldsObject(), batch);
