import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components';
import { credentialId, pin, token } from '@treasury/policy/login';
import { LitElement, html, nothing } from 'lit';

export default class SecureTokenRegistration extends ListeningElementMixin(LitElement) {
    static get properties() {
        return {
            record: Object,
            loading: Boolean,
            isUisEnabled: Boolean,
        };
    }

    constructor() {
        super();
        this.record = new Record(
            {
                credentialId: credentialId.thatIs.required(),
                token: token.thatIs.required(),
                pin: pin.thatIs.required(),
                confirmPin: pin.thatIs
                    .required()
                    .with.label('Confirm PIN')
                    .and.validator({
                        name: 'confirm-pin',
                        validate(modelValue, viewValue, record) {
                            return modelValue === record.getField('pin');
                        },
                    }),
            },
            {
                credentialId: '',
                token: '',
                pin: '',
                confirmPin: '',
            }
        );
    }

    fillMeIn() {
        if (this.record.isValid() && this.record.hasRequiredValues()) {
            this.record.setField('credentialId', 'SYDC12345678'); // cSpell:disable-line
            this.record.setField('token', '654321');
            this.record.setField('pin', '1111');
            this.record.setField('confirmPin', '1111');
            return;
        }
        this.shadowRoot.querySelector('#register-button').click();
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.record, 'change', () => this.requestUpdate());
    }

    remindMeLater() {
        this.dispatchEvent(new CustomEvent('remind'));
    }

    register() {
        this.dispatchEvent(new CustomEvent('register', { detail: this.record.values }));
    }

    renderFields() {
        return ['credentialId', 'token', 'pin', 'confirmPin'].map(
            field => html`<omega-field .field=${field} .record=${this.record}></omega-field>`
        );
    }

    renderButtons() {
        return html`
            <omega-button
                id="register-button"
                type="primary"
                @click=${() => this.register()}
                .disabled=${!(this.record.isValid() && this.record.hasRequiredValues())}
                >Register</omega-button
            >
            <omega-button @click=${() => this.remindMeLater()} type="link"
                >Remind me later</omega-button
            >
        `;
    }

    renderInstructions() {
        return html`
            <p>
                If you have received or installed your Secure Token/Credential, register it by
                entering the information requested below. If you do not wish to register your Secure
                Token/Credential at this time, or if you have not yet received or installed it,
                select Remind me later.
                ${this.isUisEnabled
                    ? html`<span
                          >Your secure token credentials will be used for authentication purposes
                          when working with transactions within the Treasury Management
                          platform.</span
                      >`
                    : nothing}
            </p>

            <ol>
                <li>
                    If you have a key fob, enter the Serial Number, which is located on the back of
                    the fob after the letters "S/N". If you are using a virtual token, enter the
                    Credential ID, which begins with 4 letters.
                </li>
                <li>
                    Enter your Credential/Token Code. This is the random, six-digit code that is
                    displayed on your credential.
                </li>
                <li>
                    Create a 4-digit PIN and enter it twice to confirm it. You will use this PIN in
                    conjunction with the random code generated by your Secure Token/Credential.
                </li>
            </ol>
        `;
    }

    render() {
        return html`
            <style>
                :host {
                    display: block;
                }
                footer {
                    padding: 4px 15px;
                    border-top: 1px solid #ccc;
                }
                omega-field {
                    --omega-field-label-width: 250px;
                    --omega-field-control-width: 250px;
                    max-width: 560px;
                    margin-bottom: 15px;
                }
                .content {
                    padding: 15px;
                }
            </style>
            <div class="content">${this.renderInstructions()} ${this.renderFields()}</div>
            <footer>${this.renderButtons()}</footer>
        `;
    }
}

window.customElements.define('secure-token-registration', SecureTokenRegistration);
