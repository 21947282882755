import { DiContainer, InjectProperty } from '@jack-henry/frontend-utils/di';
import { BuildType, ConfigurationService } from '@treasury/core/config';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { LitElement, css, html, nothing, render } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { OmegaDialogService } from '../../services/omega-dialog';
import './feature-flag-overrides';

const FeatureFlagButtonTagName = 'feature-flag-button';
const BUTTON_SIZE = 50;
const description = 'Feature flag overrides';

@customElement(FeatureFlagButtonTagName)
export class FeatureFlagButton extends LitElement {
    /**
     * This component must wait for both DI and
     * the feature service to be ready before rendering.
     */
    @state()
    private ready = false;

    @InjectProperty()
    private declare readonly dialogService: OmegaDialogService;

    @InjectProperty()
    private declare readonly configService: ConfigurationService;

    @InjectProperty({
        async: true,
        token: FeatureFlagService,
    })
    private declare readonly ffServicePromise: Promise<FeatureFlagService>;

    protected firstUpdated() {
        this.ffServicePromise.then(() => {
            this.ready = true;
        });
    }

    private onClick() {
        this.dialogService.open(
            html`<feature-flag-overrides></feature-flag-overrides>`,
            'Feature Flag Overrides',
            {
                buttons: {
                    cancel: null,
                    confirm: {
                        label: 'Close',
                    },
                },
            }
        );
    }

    public render() {
        if (!this.ready) {
            return nothing;
        }

        // only allow overrides in development builds
        if (this.configService.buildType === BuildType.Production) {
            return nothing;
        }

        return html`<omega-icon
            title="${description}"
            id="ff-button"
            icon="flag"
            description="${description}"
            @click=${() => this.onClick()}
        ></omega-icon>`;
    }

    public static styles = css`
        :host {
            /** Keep this above most content but below dialog windows */
            z-index: 999;
        }

        #ff-button {
            height: ${BUTTON_SIZE}px;
            width: ${BUTTON_SIZE}px;
            font-size: 2em;
            text-align: center;
            border: 1px solid;
            border-color: var(--omega-primary-darken-100);
            border-radius: 25px;
            box-sizing: border-box;
            cursor: pointer;
            padding: 5px;
            color: var(--omega-primary-lighten-200);
            background-color: var(--omega-primary);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
        }
    `;
}
