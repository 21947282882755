import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { Record as FdlRecord } from '@treasury/FDL/record';
import { RecordsetFetchArgs } from '@treasury/FDL/recordset';
import { AlarmClock } from '@treasury/alarm-clock';
import { NavigationService } from '@treasury/core/navigation';
import { AchExceptionDetail, PayOrReturnChoice } from '@treasury/domain/channel/mappings/ach/';
import { PositivePayExceptionRequests as PosPayService } from '@treasury/domain/channel/requests/';
import { EntitlementsService } from '@treasury/domain/channel/services';
import { AchExceptionsService } from '@treasury/domain/channel/services/ach';
import { SecCodesService } from '@treasury/domain/channel/services/sec-codes/';
import {
    AchExceptionSaveResultDto,
    SecCodeDto,
    WorkAchExceptionDto,
} from '@treasury/domain/channel/types';
import { AchExceptionDetailForApiDto } from '@treasury/domain/channel/types/arp/ach-exception-detail-for-api.dto';
import {
    ACH_EXCEPTION_CUTOFF,
    ACH_EXCEPTION_CUTOFF_MINUS_30,
    ACH_EXCEPTION_START,
} from '@treasury/domain/channel/types/arp/constants.js';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-filter-bar.js';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import '@treasury/omega/layouts/omega-report';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { OmegaReportAction, OmegaReportFilter } from '@treasury/omega/types';
import { printNode } from '@treasury/utils';
import { LitElement, css, html, nothing, render } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../../components/blocking-loader';
import AchExceptionsClient from '../clients/ach-exceptions-client.js';
import { createOmegaReportFilters } from '../data';
import { columns, fields } from '../data/field-definitions';
import { AchExceptionsSearchParams, mapSearchParams } from '../data/map-search-params';
import { renderBlockingLoader } from '../templates';

interface DownloadEventDetail {
    filter: AchExceptionsSearchParams;
    type: any;
}

export const TagName = 'ach-exceptions-list';
const Mixins = AlertMixin(ListeningElementMixin(LitElement));
@customElement(TagName)
export class AchExceptionsList extends Mixins {
    constructor() {
        super();
        this.client = new AchExceptionsClient();
    }

    private client: AchExceptionsClient;

    public readonly pageTitle = 'ACH Exceptions';

    @state()
    private actions = {};

    @state()
    private filters: OmegaReportFilter<AchExceptionDetail>[] = [];

    @state()
    private localFilters: OmegaReportFilter<AchExceptionDetail>[] = [];

    @state()
    private isDownloading = false;

    @state()
    private hasCreateFilterEntitlement = false;

    @state()
    private reportColumns: OmegaColumnDefinition<AchExceptionDetail>[] = [];

    @state()
    private cutoffMessage: string | undefined;

    @state()
    private isLoading = true;

    @state()
    private isSaving = false;

    @state()
    private recordSet = new Recordset<AchExceptionDetail, AchExceptionsSearchParams>(fields, args =>
        this.fetchRecords(args)
    );

    @state()
    private reportActions?: OmegaReportAction<any>[];

    private reportInformation = html`<div style="max-width: 250px;">
        <p>
            <b>ACH Exceptions</b> - Includes all ACH Exception items that need to be decisioned
            before the established cutoff time. If items are not decisioned prior to the cutoff
            time, the financial institution's default decision will be applied.
        </p>
    </div>`;

    private reportLinks = [
        {
            title: 'ACH Exceptions - Decision Activity',
            route: 'payables.arp.ach-exceptions-activity-list',
        },
    ];

    @state()
    private secCodes: SecCodeDto[] = [];

    @state()
    private workAchExceptions: WorkAchExceptionDto[] = [];

    @InjectProperty()
    private declare dialogService: OmegaDialogService;

    @InjectProperty()
    private declare navService: NavigationService;

    private get fiClock() {
        return AlarmClock.getInstance();
    }

    public async firstUpdated() {
        await this.setCutoffMessage();
        await this.setReportProperties();
        this.isLoading = false;
    }

    connectedCallback() {
        // eslint-disable-next-line wc/guard-super-call
        super.connectedCallback();
        this.listenTo(this.fiClock, ACH_EXCEPTION_START, this.setCutoffMessage.bind(this));
        this.listenTo(
            this.fiClock,
            ACH_EXCEPTION_CUTOFF_MINUS_30,
            this.setCutoffMessage.bind(this)
        );
        this.listenTo(this.fiClock, ACH_EXCEPTION_CUTOFF, this.setCutoffMessage.bind(this));
    }

    private async setCutoffMessage() {
        this.reportColumns = columns(this.hasCreateFilterEntitlement);
        await this.fiClock.checkReadiness();
        if (
            this.fiClock.isAfter(ACH_EXCEPTION_CUTOFF_MINUS_30) &&
            this.fiClock.isBefore(ACH_EXCEPTION_CUTOFF)
        ) {
            this.cutoffMessage = `ACH exception items will be disabled for decisioning at ${this.fiClock.getAlarm(
                ACH_EXCEPTION_CUTOFF
            )} Cut-Off time (${this.fiClock.timeZone}).`;
        } else if (this.fiClock.isAfter(ACH_EXCEPTION_CUTOFF)) {
            this.cutoffMessage = `ACH exception items are disabled because the current time is past the ${this.fiClock.getAlarm(
                ACH_EXCEPTION_CUTOFF
            )} Cut-Off time (${this.fiClock.timeZone})`;
        } else if (this.fiClock.isBefore(ACH_EXCEPTION_START)) {
            this.cutoffMessage = `ACH exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                ACH_EXCEPTION_START
            )} (${this.fiClock.timeZone}).`;
        } else {
            this.cutoffMessage = undefined;
        }
    }

    private async setReportProperties() {
        this.hasCreateFilterEntitlement = await this.isEntitledCreateAchFilter();
        if (this.hasCreateFilterEntitlement) {
            this.addFilterRulesReportLink();
        }
        this.reportColumns = columns(this.hasCreateFilterEntitlement);
        this.secCodes = await this.getAndMapSecCodes();
        this.workAchExceptions = await this.getWorkAchExceptions();
        this.filters = createOmegaReportFilters(this.secCodes, this.workAchExceptions);
        this.localFilters = this.filters;
        this.actions = {
            createFilterRule: (record: FdlRecord<AchExceptionDetail>) =>
                this.showFilterRuleDialog(record),
        };
        this.reportActions = [
            {
                type: 'approve',
                label: 'Review',
                isDisabled: () => this.areAllRadioButtonsUnselected(),
                action: () => this.showReviewConfirmationDialog(),
                visibleWhen: () => true,
            },
            {
                type: 'secondary',
                label: 'Reset',
                isDisabled: () => this.areAllRadioButtonsUnselected(),
                action: () => {
                    this.recordSet.allRecords.forEach(record => record.reset());
                },
                visibleWhen: () => true,
            },
        ];
    }

    private addFilterRulesReportLink() {
        this.reportLinks.push({
            title: 'ACH Exceptions - Filter Rules',
            route: 'payables.arp.ach-filters',
        });
    }

    private areAllRadioButtonsUnselected() {
        return !(
            this.isDecisionMade(PayOrReturnChoice.Pay) ||
            this.isDecisionMade(PayOrReturnChoice.Return)
        );
    }

    private disableFunction() {
        return (
            this.fiClock.isAfter(ACH_EXCEPTION_CUTOFF) || this.fiClock.isBefore(ACH_EXCEPTION_START)
        );
    }

    private fetchRecords(args: RecordsetFetchArgs<AchExceptionDetail, AchExceptionsSearchParams>) {
        const { parameters } = args;
        const queryDto = mapSearchParams(parameters);
        return AchExceptionsService.findAchExceptions(queryDto);
    }

    private async getAndMapSecCodes() {
        let secCodes: any[] = [];
        secCodes = await SecCodesService.getSecCodes();
        return secCodes.map(item => {
            const secCode: SecCodeDto = { code: item.secCode, description: item.description };
            return secCode;
        });
    }

    private async getWorkAchExceptions() {
        return PosPayService.getAchExceptionAccounts() as Promise<WorkAchExceptionDto[]>;
    }

    private isDecisionMade(choice: PayOrReturnChoice) {
        return this.recordSet.allRecords.some(record =>
            record.getField('decisionToPayOrReturn').toString().includes(choice.toString())
        );
    }

    private async isEntitledAchFilterManagement() {
        return EntitlementsService.instance.hasEntitlement(
            'Feature.PositivePay.ACHFilterManagement'
        );
    }

    private async isEntitledCreateAchFilter() {
        const isEntitled = await this.isEntitledAchFilterManagement();
        const hasCreateAchFilter =
            await EntitlementsService.instance.hasEntitlement('Create ACH Filter');
        return isEntitled && hasCreateAchFilter;
    }

    private mapSelectedRecords() {
        return this.recordSet.allRecords
            .map(record => record.values)
            .filter(
                exceptionDetail =>
                    exceptionDetail.decisionToPayOrReturn === PayOrReturnChoice.Pay ||
                    exceptionDetail.decisionToPayOrReturn === PayOrReturnChoice.Return
            );
    }

    private async showFilterRuleDialog(record: FdlRecord<AchExceptionDetail>) {
        const { reason } = await this.dialogService.open(
            'Click continue to create a new ACH Filter.',
            'Create a new ACH Filter',
            {
                buttons: {
                    [OmegaDialogExitReason.Confirm]: {
                        label: 'Continue',
                    },
                },
                height: 25,
            }
        ).closed;
        if (reason === OmegaDialogExitReason.Confirm) {
            this.goToCreateFilterInterface(record);
        }
    }

    private goToCreateFilterInterface(record: FdlRecord<AchExceptionDetail>) {
        this.navService.navigate('payables.arp.ach-filter-workflow-create-from-exception', {
            exceptionJson: record.values,
            id: record.values.id,
        });
    }

    async handleDownload(detail: DownloadEventDetail) {
        this.isDownloading = true;
        try {
            await this.client.downloadAchExceptions(
                '',
                detail.type,
                'AchExceptionsList',
                mapSearchParams(detail.filter),
                ['PDF', 'CSV']
            );
        } catch {
            this.alert = { ...this.alert, visible: true, type: 'error' };
        } finally {
            this.isDownloading = false;
        }
    }

    async handlePrint() {
        const printRecordset = new Recordset(fields, () => this.recordSet.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.reportColumns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    private async showReviewConfirmationDialog() {
        const selectedRecords = this.mapSelectedRecords();
        const definedRecordset = new Recordset(fields, () => selectedRecords);
        await definedRecordset.requestUpdate();
        const { reason } = await this.dialogService.open(
            this.renderReviewContent(definedRecordset),
            'Review and Confirm Decisions',
            {
                buttons: {
                    [OmegaDialogExitReason.Confirm]: {
                        label: 'Confirm',
                    },
                },
            }
        ).closed;
        if (reason !== OmegaDialogExitReason.Confirm) {
            return;
        }
        return this.saveDecisions();
    }

    private async saveDecisions() {
        document.querySelector('#sectionView')?.scrollTo(0, 0);
        const selectedRecords = this.mapSelectedRecords();
        const achFragments: AchExceptionDetailForApiDto[] = selectedRecords.map(record =>
            record.toDto()
        );
        this.isSaving = true;
        try {
            const saveResponse: AchExceptionSaveResultDto =
                await AchExceptionsService.saveAchExceptions(achFragments);
            this.isSaving = false;
            this.recordSet.requestHardUpdate();
            this.showSaveAlert(saveResponse);
        } catch (e: any) {
            this.isSaving = false;
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                posture: 'polite',
                message: html`<div style="text-align:left">
                    <h2 style="color: var(--omega-warning); margin: 0;">Save incomplete</h2>
                    <p style="margin: 0 0 0 1px;">
                        An error occurred while saving ach decisions.${e?.message ? e?.message : ''}
                    </p>
                </div>`,
            };
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private showSaveAlert(saveResult: AchExceptionSaveResultDto) {
        if (saveResult?.totalDecisionsCount !== undefined && saveResult.totalDecisionsCount > 0) {
            if (saveResult.failedDecisionsCount === 0) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    message: html`<div style="text-align:left">
                        <h2 style="color: var(--omega-success); margin: 0;">Saved!</h2>
                        <p style="margin: 0 0 0 1px;">Your decisions were saved successfully</p>
                    </div>`,
                    type: 'success',
                    actions: '',
                    posture: 'polite',
                };
            } else {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    message: html`<div style="text-align:left">
                        <h2 style="color: var(--omega-warning); margin: 0;">Save incomplete</h2>
                        <p style="margin: 0 0 0 1px;">
                            ${saveResult.failedDecisionsCount} out of
                            ${saveResult.totalDecisionsCount} decisions failed to save.
                        </p>
                    </div>`,
                    type: 'warning',
                    actions: '',
                    posture: 'polite',
                };
            }
        }
    }

    public renderCutoffAlert() {
        if (!this.cutoffMessage) return nothing;
        return html` <omega-alert isVisible type="info"> ${this.cutoffMessage} </omega-alert> `;
    }

    private renderReviewContent(definedRecordset: Recordset<AchExceptionDetail>) {
        return html`<div style="overflow:auto; max-height: 310px;">
                <omega-table
                    .recordset=${definedRecordset}
                    .columnDefinitions=${[
                        { label: 'Decision', field: 'decisionTaken' },
                        { label: 'Account', field: 'accountNumber' },
                        { label: 'ACH Company', field: 'achCompanyName' },
                        { label: 'Amount', field: 'amount' },
                        { label: 'Debit/Credit', field: 'exceptionType' },
                        { label: 'SEC Code', field: 'secCode' },
                        { label: 'Description', field: 'entryDescription' },
                    ]}
                ></omega-table>
            </div>
            <div style="margin-top:20px; line-height: 1.5em;">
                You are about to decision ACH Exception items that can only be decisioned one
                time.<br />
                Are you sure you would like to proceed?
            </div> `;
    }

    private renderScreenBlocker() {
        if (this.isSaving || this.isDownloading) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    private renderTypeToFilter() {
        return html`<div style="margin-left:15px">
            <omega-filter-bar
                id="type-to-filter"
                .filters=${[]}
                .recordset=${this.recordSet}
                @change=${({ detail }: CustomEvent) => {
                    this.localFilters = detail;
                }}
            ></omega-filter-bar>
        </div>`;
    }

    public render() {
        if (!this.recordSet) return nothing;
        if (this.isLoading) return html`${renderBlockingLoader(true)}`;

        return html`${this.renderAlert()}${this.renderScreenBlocker()}
            <div class="report-container">
                <omega-report
                    flyout
                    autostart
                    .title=${this.pageTitle}
                    .filters=${this.filters}
                    .recordset=${this.recordSet}
                    .columns=${this.reportColumns}
                    .actions=${this.actions}
                    .reportActions=${this.disableFunction() ? [] : this.reportActions}
                    .reportInformation=${this.reportInformation}
                    .reportLinks=${this.reportLinks}
                    .options=${['download', 'print']}
                    .downloadOptions=${['PDF', 'CSV']}
                    .printFunction=${() => this.handlePrint()}
                    @reportDownload=${({ detail }: { detail: DownloadEventDetail }) =>
                        this.handleDownload(detail)}
                >
                    <div style="margin:0px 16px;" slot="above-table">
                        ${this.renderCutoffAlert()}${this.renderTypeToFilter()}
                    </div>
                </omega-report>
            </div>`;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                    height: 100%;
                }
                .report-container {
                    height: calc(100% - 10px);
                }
                .padded {
                    padding: 10px;
                }
                .row {
                    display: flex;
                    flex-direction: row;
                }
                .row > * {
                    flex: auto;
                }
                .full-width {
                    width: 100%;
                }
                .right {
                    text-align: right;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .dialog-body {
                    padding: 20px;
                }
                .tm-alert {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    margin: 15px;
                    border: 1px solid black;
                    border-left-width: 4px;
                }
                .tm-alert-icon {
                    display: block;
                    width: 50px;
                    font-size: 24px;
                    text-align: center;
                }
                .tm-alert p {
                    padding: 0;
                    margin: 0;
                }
                .tm-alert-info {
                    border-color: #4384b5;
                }
                .tm-alert-info .tm-alert-icon {
                    color: #4384b5;
                }
                .matting {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    position: relative;
                    background-color: var(--omega-white);
                    border: 1px solid #eeededfd;
                    box-sizing: border-box;
                    box-shadow: 0px 0px 2px 2px rgb(150 150 150 / 75%);
                }
            `,
        ];
    }
}
