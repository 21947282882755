/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    BadRequestResultDto,
    ExchangeRateDto,
    ExchangeRateMetadataDto,
    ExchangeRatesheetDto,
    NonCoreInternationalWireCompanyModelDto,
    WireCompanyModelDto,
    WireExchangeFeeDto,
    WireModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class WiresClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Wires
     * @name WiresCompaniesAll
     * @summary Returns the Companies for the specified wire Id.
     * @request GET:/wires/{id}/companies/{isDli}
     */
    wiresCompaniesAll = (id: number, isDli: boolean, params: RequestParams = {}) =>
        this.http.request<WireCompanyModelDto[], any>({
            path: `/wires/${id}/companies/${isDli}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresCompaniesWithoutAccounts
     * @summary Returns the Companies for the specified wire Id without returning accounts.
     * @request GET:/wires/{id}/companieswithoutaccounts/{isDli}
     */
    wiresCompaniesWithoutAccounts = (id: number, isDli: boolean, params: RequestParams = {}) =>
        this.http.request<WireCompanyModelDto[], any>({
            path: `/wires/${id}/companieswithoutaccounts/${isDli}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresCompaniesPut
     * @summary Updates the wire company for the specified wire Id.
     * @request PUT:/wires/{id}/companies/{actionId}
     */
    wiresCompaniesPut = (
        id: number,
        actionId: number,
        companyModel: WireCompanyModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<WireCompanyModelDto, any>({
            path: `/wires/${id}/companies/${actionId}`,
            method: 'PUT',
            body: companyModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresCompaniesPost
     * @summary Creates the wire company for the specified wire Id.
     * @request POST:/wires/{id}/companies
     */
    wiresCompaniesPost = (
        id: number,
        companyModel: WireCompanyModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<WireCompanyModelDto, any>({
            path: `/wires/${id}/companies`,
            method: 'POST',
            body: companyModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetNonCoreInternationalWireCompany
     * @summary Gets non-core international wire company.
     * @request GET:/wires/getnoncoreinternationalwirecompany
     */
    wiresGetNonCoreInternationalWireCompany = (
        query: {
            /**
             * The company id.
             * @format int32
             */
            id: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<NonCoreInternationalWireCompanyModelDto, any>({
            path: `/wires/getnoncoreinternationalwirecompany`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresCreateNonCoreInternationalWireCompany
     * @summary Creates non-core international wire company.
     * @request POST:/wires/{id}/createnoncoreinternationalwirecompany
     */
    wiresCreateNonCoreInternationalWireCompany = (
        id: number,
        companyModel: NonCoreInternationalWireCompanyModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<NonCoreInternationalWireCompanyModelDto, any>({
            path: `/wires/${id}/createnoncoreinternationalwirecompany`,
            method: 'POST',
            body: companyModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresEditNonCoreInternationalWireCompanies
     * @summary Edits non-core international wire companies.
     * @request PUT:/wires/{id}/editnoncoreinternationalwirecompany
     */
    wiresEditNonCoreInternationalWireCompanies = (
        id: number,
        companyModel: NonCoreInternationalWireCompanyModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<NonCoreInternationalWireCompanyModelDto, any>({
            path: `/wires/${id}/editnoncoreinternationalwirecompany`,
            method: 'PUT',
            body: companyModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresUpdateExternallyProcessedFxWire
     * @summary Updates the externally processed Fx wire.
     * @request POST:/wires/updateexternallyprocessedfxwire
     */
    wiresUpdateExternallyProcessedFxWire = (
        wireModel: WireModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WireModelDto, any>({
            path: `/wires/updateexternallyprocessedfxwire`,
            method: 'POST',
            body: wireModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresAccounts
     * @summary Get the wire company for the specified wire Id and wire company id.
     * @request GET:/wires/{id}/companies/{actionId}/accounts/{isDli}
     */
    wiresAccounts = (id: number, actionId: number, isDli: boolean, params: RequestParams = {}) =>
        this.http.request<WireCompanyModelDto, any>({
            path: `/wires/${id}/companies/${actionId}/accounts/${isDli}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetExchangeFees
     * @summary Gets exchange fees.
     * @request GET:/wires/getExchangeFees
     */
    wiresGetExchangeFees = (params: RequestParams = {}) =>
        this.http.request<WireExchangeFeeDto[], any>({
            path: `/wires/getExchangeFees`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresSaveExchangeFees
     * @summary Saves exchange fees.
     * @request POST:/wires/saveExchangeFees
     */
    wiresSaveExchangeFees = (
        exchangeFees: WireExchangeFeeDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WireExchangeFeeDto[], any>({
            path: `/wires/saveExchangeFees`,
            method: 'POST',
            body: exchangeFees,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGet
     * @summary Gets a wire.
     * @request GET:/wires/{id}
     */
    wiresGet = (id: number, params: RequestParams = {}) =>
        this.http.request<WireModelDto, any>({
            path: `/wires/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresUploadRatesheet
     * @summary Uploads the rate sheet.
     * @request POST:/wires/uploadRatesheet
     */
    wiresUploadRatesheet = (params: RequestParams = {}) =>
        this.http.request<ExchangeRatesheetDto, BadRequestResultDto>({
            path: `/wires/uploadRatesheet`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetExchangeRatesMetadata
     * @summary Gets exchange rates metadata.
     * @request GET:/wires/getExchangeRatesMetadata
     */
    wiresGetExchangeRatesMetadata = (params: RequestParams = {}) =>
        this.http.request<ExchangeRateMetadataDto[], any>({
            path: `/wires/getExchangeRatesMetadata`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetExchangeRates
     * @summary Gets exchange rates.
     * @request GET:/wires/getExchangeRates
     */
    wiresGetExchangeRates = (
        query: {
            /**
             * An instance of the DateTime class.
             * @format date-time
             */
            effectiveDate: string;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ExchangeRateDto[], any>({
            path: `/wires/getExchangeRates`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
