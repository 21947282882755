// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

export class RecurringAchRequests {
    static async fetchRecurringAchActivity(body) {
        return (await TmHttpClient.getInstance()).request(
            `achPayments/FilterPayments?type=Recurring`,
            {
                method: 'POST',
                body,
            }
        );
    }
}
