import { channelDiModule } from '../constants/di.constants';
import { servicesCommonModule } from '../services/common/module';
import { servicesModule } from '../services/module';
import TransferAccountSelectionController from './account/controllers/transferAccountSelectionModalCtrl';
import FooterController from './footer/footerCtrl.js';
import HeaderController from './header/headerCtrl';
import ResetPassWordController from './resetPassword/controllers/resetPasswordCtrl.js';
import VerifyUserModalController from './security/controllers/verifyUserModal.js';

export const sharedModule = angular
    .module('channel.shared', [
        servicesCommonModule.name,
        servicesModule.name,
        channelDiModule.name,
    ])
    .controller('FooterController', FooterController)
    .controller('HeaderController', HeaderController)
    .controller('AccountSelectionController', TransferAccountSelectionController)
    .controller('ResetPassWordController', ResetPassWordController)
    .controller('VerifyUserModalController', VerifyUserModalController);
