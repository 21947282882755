import { LitElement, html, css } from 'lit';

class OmegaIconInformation extends LitElement {
    render() {
        return html`<svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 22 22"
        >
            <defs>
                <path
                    id="a"
                    d="M11 0c6.075 0 11 4.927 11 11 0 6.076-4.925 11-11 11S0 17.076 0 11C0 4.927 4.925 0 11 0zm0 2.156A8.846 8.846 0 002.156 11 8.844 8.844 0 0011 19.844 8.844 8.844 0 0019.844 11 8.846 8.846 0 0011 2.156zm0 2.726a1.87 1.87 0 110 3.74 1.87 1.87 0 010-3.74zm2.504 11.26a.531.531 0 01-.53.532H9.09a.531.531 0 01-.53-.532v-1.065c0-.294.237-.533.53-.533h.53v-2.84h-.53a.531.531 0 01-.53-.533v-1.065c0-.294.237-.533.53-.533h2.826c.292 0 .53.239.53.533v4.438h.53c.292 0 .53.239.53.533v1.065z"
                />
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlink:href="#a" />
                </mask>
                <use fill="#0065CF" fill-rule="nonzero" xlink:href="#a" />
                <path fill="#0065CF" d="M-3-3h28v28H-3z" mask="url(#b)" />
            </g>
        </svg>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}
customElements.define('omega-icon-information', OmegaIconInformation);
