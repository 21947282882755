SelectStatesController.$inject = ['$scope', '$modalInstance', 'states'];

export default function SelectStatesController($scope, $modalInstance, states) {
    $scope.searchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.states = states;
    $scope.selectState = selectState;
    $scope.filterStates = filterStates;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function selectState(tc) {
        $modalInstance.close(tc);
    }

    function filterStates(state) {
        return (
            (state.key !== null &&
                state.key !== undefined &&
                state.key.toString().indexOf($scope.searchText) !== -1) ||
            (state.value !== null &&
                state.value !== undefined &&
                state.value.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1)
        );
    }
}
