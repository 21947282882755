ManageAchCompanyController.$inject = [
    '$scope',
    'achCompanies',
    'userAchCompanies',
    '$modalInstance',
    '$stateParams',
    '$filter',
];

export default function ManageAchCompanyController(
    $scope,
    achCompanies,
    userAchCompanies,
    $modalInstance,
    $stateParams,
    $filter
) {
    $scope.companyCifNumber = $stateParams.cifNumber;
    $scope.userId = $stateParams.id;
    $scope.achCompanies = achCompanies;
    $scope.userAchCompanies = userAchCompanies;
    $scope.searchText = '';
    $scope.selectAll = {
        value: false,
    };
    $scope.getSecCodesString = getSecCodesString;
    $scope.search = search;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    $scope.selectCompany = selectCompany;
    $scope.cancel = cancel;
    $scope.submit = submit;

    function removeReturnCompany(company) {
        const returnCompanyIndex = $scope.returnAchCompanies.findIndex(
            returnCompany => returnCompany.id === company.id
        );
        if (returnCompanyIndex >= 0) $scope.returnAchCompanies.splice(returnCompanyIndex, 1);
    }

    function setReturnCompany(company) {
        if (!$scope.returnAchCompanies.some(returnCompany => returnCompany.id === company.id)) {
            $scope.returnAchCompanies.push({
                id: company.id,
                companyName: company.companyName,
            });
        }
    }

    function setAchCompanies() {
        angular.forEach($scope.achCompanies, company => {
            company.isSelected = false;
            angular.forEach($scope.userAchCompanies, userCompany => {
                if (userCompany.id === company.id) {
                    company.isSelected = true;
                }
            });
            company.concatSecCodes = getSecCodesString(company);
        });
    }

    function updateAllSelected() {
        const allSelected = $scope.filteredAchCompanies.every(
            filteredCompany => filteredCompany.isSelected
        );
        $scope.selectAll.value = allSelected;
    }

    function init() {
        $scope.returnAchCompanies = angular.copy($scope.userAchCompanies);
        setAchCompanies();
        $scope.filteredAchCompanies = angular.copy($scope.achCompanies);
        updateAllSelected();
    }

    function search(name) {
        $scope.searchText = name || '';
        $scope.filteredAchCompanies = $filter('filter')($scope.achCompanies, $scope.searchText);
        updateAllSelected();
    }

    function submit() {
        angular.forEach($scope.filteredAchCompanies, company => {
            if (company.isSelected) {
                setReturnCompany(company);
            }
        });
        setAchCompanies();
        $modalInstance.close($scope.returnAchCompanies);
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function selectOrDeselectAll(selectAll) {
        $scope.selectAll.value = selectAll;
        angular.forEach($scope.filteredAchCompanies, company => {
            company.isSelected = $scope.selectAll.value;
            if (company.isSelected) {
                setReturnCompany(company);
            } else {
                removeReturnCompany(company);
            }
        });
    }

    function selectCompany(company) {
        if (company.isSelected) {
            setReturnCompany(company);
        } else {
            removeReturnCompany(company);
        }
        updateAllSelected();
    }

    function getSecCodesString(achCompany) {
        return achCompany.secCodes.map(elem => elem.code).join(', ');
    }

    init();
}
