export const DEFAULT_OPTIONS = [
    {
        id: 'today',
        label: 'Today',
        dates: (() => [new Date()])(),
        legacyId: '$TODAY',
    },
    {
        id: 'specific',
        label: 'Specific Date',
        exact: true,
    },
    {
        id: 'range',
        label: 'Date Range',
        exact: true,
        isRange: true,
    },
    {
        id: 'week-to-date',
        label: 'Week to Date',
        dates: (() => {
            const today = new Date();
            return [
                new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()),
                today,
            ];
        })(),
        legacyId: '$WTD',
        isRange: true,
    },
    {
        id: 'month-to-date',
        label: 'Month to Date',
        dates: (() => {
            const today = new Date();
            return [new Date(today.getFullYear(), today.getMonth(), 1), today];
        })(),
        legacyId: '$MTD',
        isRange: true,
    },
    {
        id: 'year-to-date',
        label: 'Year to Date',
        dates: (() => {
            const today = new Date();
            return [new Date(today.getFullYear(), 0, 1), today];
        })(),
        legacyId: '$YTD',
        isRange: true,
    },
];

export const DEFAULT_ORDER = DEFAULT_OPTIONS.map(item => item.id);

export const ADDITIONAL_OPTIONS = [
    {
        id: 'as-of',
        label: 'As Of',
        exact: true,
    },
    {
        id: 'prior-to',
        label: 'Prior To',
        exact: true,
    },
    {
        id: 'as-of-today',
        label: 'As Of Today',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [today, null];
        })(),
    },
    {
        id: 'prior-to-today',
        label: 'Prior To Today',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [null, today];
        })(),
    },
    {
        id: 'previous-week',
        label: 'Previous Week',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [
                new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() - 7
                ),
                new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - today.getDay() - 1
                ),
            ];
        })(),
    },
    {
        id: 'previous-month',
        label: 'Previous Month',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [
                new Date(today.getFullYear(), today.getMonth() - 1, 1),
                new Date(today.getFullYear(), today.getMonth(), 0),
            ];
        })(),
    },
    {
        id: 'previous-year',
        label: 'Previous Year',
        isRange: true,
        dates: (() => {
            const today = new Date();
            return [
                new Date(today.getFullYear() - 1, 0, 1),
                new Date(today.getFullYear() - 1, 11, 31),
            ];
        })(),
    },
];

export const NO_SELECTION_OPTION = {
    id: 'no-date-selected',
    label: 'Select Date',
    formattedValue: '',
};

export const ANY_DATE_SELECTION_OPTION = {
    id: 'any-date',
    label: 'Any Date',
    formattedValue: '',
};

export const NONE_SELECTION_OPTION = {
    id: 'none',
    label: 'None',
    formattedValue: '',
};

export const NO_SELECTION_OPTIONS = [
    NONE_SELECTION_OPTION,
    ANY_DATE_SELECTION_OPTION,
    NO_SELECTION_OPTION,
];
