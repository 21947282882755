/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ErrorHttpContentDto,
    ExternalReportFileDownloadModelDto,
    ExternalReportFileModelDto,
    ExternalReportFileSearchCriteriaModelDto,
    ExternalReportGroupModelDto,
    ExternalReportGroupTemplateModelDto,
    ExternalReportModelDto,
    ExternalReportSearchCriteriaModelDto,
    ExternalReportTypeModelDto,
    GenericDownloadResponseModelDto,
    GenericEntityCollectionResponseModelDto,
    GenericEntityResponseModelDto,
    GenericEnumCollectionModelDto,
    PagedListModelOfAccountModelDto,
    ReportAccountsModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AccountReconClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconDeleteExternalReport
     * @summary Deletes an existing ExternalReportModel.
     * @request DELETE:/AccountRecon/Report/{externalReportId}
     */
    accountReconDeleteExternalReport = (externalReportId: number, params: RequestParams = {}) =>
        this.http.request<GenericEntityResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/${externalReportId}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReport
     * @summary Gets a single ExternalReportModel.
     * @request GET:/AccountRecon/Report/{externalReportId}
     */
    accountReconGetExternalReport = (externalReportId: number, params: RequestParams = {}) =>
        this.http.request<ExternalReportModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/${externalReportId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconDeleteExternalReportFile
     * @summary Deletes an ExternalReportFileModel.
     * @request DELETE:/AccountRecon/Report/File/{externalReportFileId}
     */
    accountReconDeleteExternalReportFile = (
        externalReportFileId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericEntityResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/File/${externalReportFileId}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags AccountRecon
 * @name AccountReconDownloadExternalReportGet
 * @summary Downloads the result of a ExternalReportModel represented by a
ExternalReportFileModel.
 * @request GET:/AccountRecon/Report/File/Download/{externalReportFileId}
 */
    accountReconDownloadExternalReportGet = (
        externalReportFileId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericDownloadResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/File/Download/${externalReportFileId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags AccountRecon
 * @name AccountReconDownloadExternalReportPost
 * @summary Downloads the result of a ExternalReportModel represented by a
ExternalReportFileModel.
 * @request POST:/AccountRecon/Report/File/Download
 */
    accountReconDownloadExternalReportPost = (
        externalReportFileDownloadModel: ExternalReportFileDownloadModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericDownloadResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/File/Download`,
            method: 'POST',
            body: externalReportFileDownloadModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconExecuteReport
     * @summary Begins the operation to execute an ExternalReportModel.
     * @request POST:/AccountRecon/Report/Execute/{externalReportId}
     */
    accountReconExecuteReport = (externalReportId: number, params: RequestParams = {}) =>
        this.http.request<GenericEntityCollectionResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Execute/${externalReportId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReportFiles
     * @summary Searches for matching ExternalReportFileModels.
     * @request POST:/AccountRecon/Report/File/Search
     */
    accountReconGetExternalReportFiles = (
        externalReportFileSearchModel: ExternalReportFileSearchCriteriaModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ExternalReportFileModelDto[], ErrorHttpContentDto>({
            path: `/AccountRecon/Report/File/Search`,
            method: 'POST',
            body: externalReportFileSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReports
     * @summary Searches for matching ExternalReportModel.
     * @request POST:/AccountRecon/Report/Search
     */
    accountReconGetExternalReports = (
        externalReportSearchModel: ExternalReportSearchCriteriaModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ExternalReportModelDto[], ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Search`,
            method: 'POST',
            body: externalReportSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReportTypes
     * @summary Gets a collection of all ExternalReportTypeModel for an ExternalReportModel.
     * @request GET:/AccountRecon/ReportType
     */
    accountReconGetExternalReportTypes = (params: RequestParams = {}) =>
        this.http.request<ExternalReportTypeModelDto[], ErrorHttpContentDto>({
            path: `/AccountRecon/ReportType`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags AccountRecon
 * @name AccountReconGetFrequencyTypes
 * @summary Gets a collection of all available FrequencyTypeEnum for an ExternalReportModel
.
 * @request GET:/AccountRecon/FrequencyType
 */
    accountReconGetFrequencyTypes = (params: RequestParams = {}) =>
        this.http.request<GenericEnumCollectionModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/FrequencyType`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetTimePeriodTypes
     * @summary Gets a collection of all available TimePeriodTypeEnum for an ExternalReportModel.
     * @request GET:/AccountRecon/TimePeriodType
     */
    accountReconGetTimePeriodTypes = (params: RequestParams = {}) =>
        this.http.request<GenericEnumCollectionModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/TimePeriodType`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconSaveExternalReport
     * @summary Saves a ExternalReportModel.
     * @request POST:/AccountRecon/Report
     */
    accountReconSaveExternalReport = (
        externalReportModel: ExternalReportModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericEntityResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report`,
            method: 'POST',
            body: externalReportModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetReportAccounts
     * @summary Gets a collection of all AccountModel for an ReportAccountsModel.
     * @request POST:/AccountRecon/Accounts
     */
    accountReconGetReportAccounts = (
        reportAccountsModel: ReportAccountsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<PagedListModelOfAccountModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Accounts`,
            method: 'POST',
            body: reportAccountsModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconAddExternalReportGroupMember
     * @summary Adds a ExternalReportModel to a ExternalReportGroupModel.
     * @request PUT:/AccountRecon/Report/Group/{externalReportGroupId}/Member/{externalReportId}
     */
    accountReconAddExternalReportGroupMember = (
        externalReportGroupId: number,
        externalReportId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericEntityResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Group/${externalReportGroupId}/Member/${externalReportId}`,
            method: 'PUT',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconRemoveExternalReportGroupMember
     * @summary Removes an ExternalReportModel from a ExternalReportGroupModel.
     * @request DELETE:/AccountRecon/Report/Group/{externalReportGroupId}/Member/{externalReportId}
     */
    accountReconRemoveExternalReportGroupMember = (
        externalReportGroupId: number,
        externalReportId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericEntityResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Group/${externalReportGroupId}/Member/${externalReportId}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReportGroups
     * @summary Gets all of the ExternalReportGroupModels.
     * @request GET:/AccountRecon/Report/Group
     */
    accountReconGetExternalReportGroups = (params: RequestParams = {}) =>
        this.http.request<ExternalReportGroupModelDto[], ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Group`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconSaveExternalReportGroup
     * @summary Saves an ExternalReportGroupModel.
     * @request POST:/AccountRecon/Report/Group
     */
    accountReconSaveExternalReportGroup = (
        externalReportGroupModel: ExternalReportGroupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<GenericEntityResponseModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Group`,
            method: 'POST',
            body: externalReportGroupModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReportGroup
     * @summary Gets a ExternalReportGroupModel.
     * @request GET:/AccountRecon/Report/Group/{externalReportGroupId}
     */
    accountReconGetExternalReportGroup = (
        externalReportGroupId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<ExternalReportGroupModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Group/${externalReportGroupId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetExternalReportGroupTemplates
     * @summary Gets all of the ExternalReportGroupTemplateModels.
     * @request GET:/AccountRecon/Report/Group/Template
     */
    accountReconGetExternalReportGroupTemplates = (params: RequestParams = {}) =>
        this.http.request<ExternalReportGroupTemplateModelDto[], ErrorHttpContentDto>({
            path: `/AccountRecon/Report/Group/Template`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
