InspectSelectedBatchesController.$inject = [
    '$scope',
    '$modal',
    '$modalInstance',
    'payment',
    '$filter',
];

export default function InspectSelectedBatchesController(
    $scope,
    $modal,
    $modalInstance,
    payment,
    $filter
) {
    $scope.payment = payment;
    $scope.showAddendaModal = showAddendaModal;
    $scope.filterRecipients = filterRecipients;
    $scope.searchByText = searchByText;
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };
    $scope.close = close;
    $scope.prenotecount = 0;
    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../../batch/views/addendaModalView.html'),
            size: 'md',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.payment,
                        recipient,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });

        modalInstance.result.then(data => {
            batch.addendaType = data.addendaType;
            batch.addendaBody = data.addendaBody;
        });
    }

    function filterRecipients() {
        if ($scope.payment) {
            let filteredArray = [];
            angular.forEach($scope.payment, payment => {
                angular.forEach(payment.recipients, recipient => {
                    recipient.batchName = payment.name;
                    recipient.secCode = payment.secCode;
                    recipient.effectiveDate = payment.frequency.effectiveDate;
                    recipient.accountNumber = parseInt(recipient.accountNumber);
                    if (recipient.prenote) $scope.prenotecount++;
                    if (
                        $scope.searchFilters.text === '' ||
                        (recipient.batchName &&
                            recipient.batchName
                                .toLowerCase()
                                .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                        recipient.batchName
                            .toUpperCase()
                            .indexOf($scope.searchFilters.text.toUpperCase()) !== -1 ||
                        (recipient.name &&
                            recipient.name
                                .toLowerCase()
                                .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                        recipient.name
                            .toUpperCase()
                            .indexOf($scope.searchFilters.text.toUpperCase()) !== -1 ||
                        (recipient.effectiveDate &&
                            recipient.effectiveDate
                                .toLowerCase()
                                .indexOf($scope.searchFilters.text) !== -1) ||
                        (recipient.accountNumber &&
                            recipient.accountNumber
                                .toString()
                                .indexOf($scope.searchFilters.text) !== -1) ||
                        (recipient.amount &&
                            (recipient.amount.toString().indexOf($scope.searchFilters.text) !==
                                -1 ||
                                `${recipient.amount.toString()}.00`.indexOf(
                                    $scope.searchFilters.text
                                ) !== -1)) ||
                        (recipient.routingNumber &&
                            recipient.routingNumber.indexOf(
                                $scope.searchFilters.text.toLowerCase()
                            ) !== -1) ||
                        (recipient.accountType &&
                            recipient.accountType
                                .toLowerCase()
                                .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                        (recipient.secCode &&
                            recipient.secCode
                                .toLowerCase()
                                .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                        (recipient.transactionType &&
                            recipient.transactionType
                                .toLowerCase()
                                .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                        (recipient.batchName &&
                            recipient.batchName.indexOf($scope.searchFilters.text.toLowerCase()) !==
                                -1)
                    ) {
                        filteredArray.push(recipient);
                    }
                });
            });

            if ($scope.searchFilters.prenote) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.prenote && recipient.prenote
                );
            }

            return filteredArray;
        }
    }

    function searchByText() {
        $scope.prenotecount = 0;
        $scope.filteredRecipients = filterRecipients();
    }

    (function () {
        // init
        $scope.filteredRecipients = filterRecipients();
    })();
}
