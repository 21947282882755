EditInternalTransferController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'toaster',
    '$timeout',
    '$modal',
    'companyAccountsService',
    'internalTransfersService',
    'utilityService',
    'modalService',
    'holidaysService',
    'frequencyService',
    'securityService',
    'dateConstants',
];

export default function EditInternalTransferController(
    $scope,
    $state,
    $stateParams,
    toaster,
    $timeout,
    $modal,
    companyAccountsService,
    internalTransfersService,
    utilityService,
    modalService,
    holidaysService,
    frequencyService,
    securityService,
    dateConstants
) {
    $scope.accounts = [];
    $scope.currentDate = '';
    $scope.endOnDate = moment().format('MM/DD/YYYY');
    $scope.totalDebitAmount = 0;
    $scope.editLoanPaymentMode = $state.editLoanPaymentMode;
    $scope.panelTitle = $scope.editLoanPaymentMode ? 'Loan Payment' : 'Internal Transfer';
    $scope.editPageTitleText = $scope.editLoanPaymentMode
        ? 'Edit a Loan Payment'
        : 'Edit a Transfer';
    $scope.editPageReviewTitle = $scope.editLoanPaymentMode
        ? 'Loan Payment Review'
        : 'Internal Transfer Review';
    $scope.editPageConfirmationTitle = $scope.editLoanPaymentMode
        ? 'Loan Payment Confirmation'
        : 'Internal Transfer Confirmation';
    $scope.editPageSuccessMessage = $scope.editLoanPaymentMode
        ? 'Loan Payment successfully updated.'
        : 'Transfer(s) successfully updated.';
    $scope.frequencies = frequencyService.getFrequencies().filter(item => item !== 'One Time');
    $scope.weekDays = frequencyService.getWeekdays();
    $scope.numbers = dateConstants.daysInMonth;
    $scope.wizardController = {};
    $scope.paymentOptionTypes = {
        1: 'Regular Payment',
        2: 'Principal Only',
    };
    $scope.transaction = {
        fromAccount: null,
        toAccount: null,
        amount: null,
        transferDate: null,
        memo: null,
        frequencyModel: { type: 'One Time' },
        paymentOptionType: null,
    };
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.getBalanceDisplayForToAccount = getBalanceDisplayForToAccount;

    function clearErrors() {
        $scope.errorMessage = '';
        $scope.transaction.balanceErrorMessage = '';
        $scope.transaction.fromAccountErrorMessage = '';
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function recalculateDebitAmount() {
        let result = 0;
        if ($scope.transaction.amount !== null && $scope.transaction.amount !== undefined) {
            result += $scope.transaction.amount;
        }
        $scope.totalDebitAmount = result;
    }

    function initializeTransaction(callback) {
        if (
            typeof $stateParams.id !== 'undefined' &&
            $stateParams.id !== null &&
            $stateParams.id > 0
        ) {
            internalTransfersService.getTransaction($stateParams.id).then(response => {
                response.frequency = response.frequency.replace(/([A-Z])/g, ' $1').trim();
                response.frequencyModel.type = response.frequency;
                $scope.transaction = response;
                if (typeof callback === 'function') {
                    callback();
                }
            });
        } else if (typeof callback === 'function') {
            callback();
        }
    }

    $scope.restart = function () {
        initializeTransaction(() => {
            $scope.wizardController.goToStep(0);
        });
    };

    $scope.viewScheduledTransfers = function () {
        $state.go('payables.transferList');
    };

    $scope.viewLoanPayments = function () {
        $state.go('payables.loan-payment-list');
    };

    $scope.reset = function () {
        initializeTransaction(() => {
            clearErrors();
            $scope.form.$setPristine();
        });
    };

    $scope.isNegative = function (value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    };

    $scope.gotoList = function () {
        $state.go('payables.transferList');
    };

    $scope.gotoRecurringList = function () {
        $state.go('payables.recurringTransferList');
    };

    $scope.canReview = function () {
        return !$scope.form.$invalid && $scope.isValidAmounts() === true;
    };

    $scope.isReviewStep = function () {
        return $scope.wizardController.currentStep().name === 'Review';
    };

    $scope.isConfirmStep = function () {
        return $scope.wizardController.currentStep().name === 'Confirmation';
    };

    $scope.review = function () {
        if ($scope.transaction.frequencyModel.noEndDate) {
            $scope.transaction.frequencyModel.endOn = null;
        }

        clearErrors();
        recalculateDebitAmount();
        $scope.wizardController.goToNextStep();
    };

    $scope.confirm = function () {
        $scope.wizardController.goToNextStep();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.isObject = function (value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    };

    $scope.dirtyState = function (type) {
        const modalOptions = {
            size: 'sm',
            headerText: 'Confirm Cancellation',
            actionButtonText: 'Cancel Edits',
            closeButtonText: 'Close',
            bodyText:
                'You have changes on the page, are you sure you would like to leave the page without saving?',
            submit(result) {
                $scope.changeTransactionType(type, true);
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    $scope.isValidAmounts = function () {
        let result = true;
        const index = 0;
        if (
            $scope.transaction.fromAccount === null ||
            $scope.transaction.fromAccount === undefined ||
            typeof $scope.transaction.fromAccount !== 'object' ||
            $scope.transaction.toAccount === null ||
            $scope.transaction.toAccount === undefined ||
            typeof $scope.transaction.toAccount !== 'object' ||
            isNaN($scope.transaction.amount)
        ) {
            result = false;
        }
        return result;
    };

    $scope.changeEdit = function (item) {
        if (item.isEdit === undefined || item.isEdit === false) {
            item.isEdit = true;
        } else {
            item.isEdit = !item.isEdit;
        }
    };

    $scope.$watch('transaction.frequencyModel.type', (newValue, oldValue) => {
        if (
            newValue !== oldValue &&
            oldValue !== 'One Time' &&
            !!$scope.transaction.frequencyModel
        ) {
            $scope.transaction.frequencyModel.type = newValue;
            $scope.transaction.frequencyModel.startOn = null;
            $scope.transaction.frequencyModel.endOn = null;
            $scope.transaction.frequencyModel.valueDate = null;
            $scope.transaction.frequencyModel.noEndDate = false;
            $scope.transaction.frequencyModel.repeatOnDay1 = '';
            $scope.transaction.frequencyModel.repeatOnDay2 = '';
            $scope.transaction.frequencyModel.repeatOnLastBusinessDay = false;
        }
    });

    $scope.findAccount = function (transaction, attribute, index) {
        const modalInstance = $modal.open({
            template: require('../../../../dashboard/widgets/views/transferAccountSelectionModal.html'),
            size: 'md',
            controller: 'AccountSelectionController',
            backdrop: 'static',
            resolve: {
                accountsList() {
                    let newList = [];
                    const count = 0;
                    const found = false;
                    if (attribute === 'fromAccount') {
                        if (
                            transaction.toAccount !== null &&
                            transaction.toAccount !== undefined &&
                            typeof transaction.toAccount === 'object'
                        ) {
                            angular.forEach($scope.accountListFrom, item => {
                                if (
                                    item.number !== transaction.toAccount.number ||
                                    (item.number === transaction.toAccount.number &&
                                        item.type !== transaction.toAccount.type)
                                ) {
                                    newList.push(item);
                                }
                            });
                        } else {
                            newList = angular.copy($scope.accountListFrom);
                        }
                    } else if (attribute === 'toAccount') {
                        if (
                            transaction.fromAccount !== null &&
                            transaction.fromAccount !== undefined &&
                            typeof transaction.fromAccount === 'object'
                        ) {
                            angular.forEach($scope.accountListTo, item => {
                                if (
                                    item.number !== transaction.fromAccount.number ||
                                    (item.number === transaction.fromAccount.number &&
                                        item.type !== transaction.fromAccount.type)
                                ) {
                                    newList.push(item);
                                }
                            });
                        } else {
                            newList = angular.copy($scope.accountListTo);
                        }
                    }
                    return newList;
                },
                showCurrentBalanceForLoanAccounts() {
                    return attribute === 'toAccount';
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            $scope.form.$dirty = true;
            transaction[attribute] = selectedAccount;
        });
    };

    function assignErrorsToTransactions(transaction) {
        if (
            transaction.errorSummary != null &&
            transaction.errorSummary.details != null &&
            transaction.errorSummary.details.length > 0
        ) {
            transaction.errorSummary.details.forEach(errorDetail => {
                if (errorDetail.attributeName === 'fromAccount') {
                    $scope.transaction.fromAccountErrorMessage = messageAsString(errorDetail);
                } else if (errorDetail.attributeName === 'balance') {
                    $scope.transaction.balanceErrorMessage = messageAsString(errorDetail);
                }
            });
        }
    }

    $scope.save = function () {
        // NOTE: This is the new code for OOB checking. Since we are getting an error on the
        // internalTransfersService call, I am leaving the new changes commented out for now.
        let actionType;
        if ($scope.transaction.frequencyModel.type === 'One Time') {
            actionType = 'Saving One time Transfer';
        } else {
            actionType = 'Saving Recurring Transfer';
        }

        $scope.transaction.fromAccount.status = 'Active';
        $scope.transaction.toAccount.status = 'Active';

        securityService
            .verifyUser(actionType, $scope.transaction, () =>
                internalTransfersService.updateTransfer($scope.transaction)
            )
            .then(response => {
                // Add summary message.
                if (
                    response.errorSummary !== null &&
                    response.errorSummary !== undefined &&
                    response.errorSummary.summaryMessage !== null &&
                    response.errorSummary.summaryMessage !== undefined &&
                    response.errorSummary.summaryMessage.length > 0
                ) {
                    displayErrors(response);
                } else {
                    $scope.transaction.batchId = response.batchId;
                    updateTransactionIds(response);
                    clearErrors();
                    $scope.confirm();
                }
            });
    };

    function messageAsString(errorDetail) {
        let returnMessage = '';
        const errorMessages = [];
        if (errorDetail.messageList != null && errorDetail.messageList.length > 0) {
            errorDetail.messageList.forEach(message => {
                errorMessages.push(message.value);
            });

            returnMessage = errorMessages.join(',');
        } else if (errorDetail.message != null) {
            returnMessage = item.message;
        }

        return returnMessage;
    }

    function displayErrors(response) {
        // Clear any previous errors
        clearErrors();

        $scope.errorMessage = response.errorSummary.summaryMessage;

        assignErrorsToTransactions(response);

        // Return user to edit form
        $scope.wizardController.goToStep(0);
        $scope.form.$setDirty();
    }

    function updateTransactionIds(response) {
        const responseTransactions = response.transactions;

        if (
            typeof responseTransactions !== 'undefined' &&
            responseTransactions !== null &&
            responseTransactions.length > 0
        ) {
            $scope.transaction.id = responseTransactions[0].id;
            $scope.transaction.transactionId = responseTransactions[0].transactionId;
        }
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit() {
                $modalInstance.close();
                $state.go('payables.recurringTransferList');
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    $scope.cancel = function () {
        if (
            ($scope.form && $scope.form.$dirty) ||
            $scope.wizardController.currentStep().name === 'Review' ||
            !!$scope.errorMessage
        ) {
            confirmCancel();
        } else {
            $state.go('payables.recurringTransferList');
        }
    };

    function autoPopulateValues() {
        if (
            typeof $stateParams.fromAccountId !== 'undefined' &&
            $stateParams.fromAccountId !== null
        ) {
            angular.forEach($scope.accountListFrom, item => {
                if (item.number === $stateParams.fromAccountId) {
                    $scope.transaction.fromAccount = item;
                }
            });
        }
        if (typeof $stateParams.toAccountId !== 'undefined' && $stateParams.toAccountId !== null) {
            angular.forEach($scope.accountListTo, item => {
                if (item.number === $stateParams.toAccountId) {
                    $scope.transaction.toAccount = item;
                }
            });
        }
        if (typeof $stateParams.amount !== 'undefined' && $stateParams.amount !== null) {
            $scope.transaction.amount = $stateParams.amount;
        }
    }

    function loadAccountsList(type) {
        companyAccountsService
            .getCompanyAccountsForProductFeature(type, 'InternalTransfer', 'create')
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                if (type === 'From') {
                    $scope.accountListFrom = response;
                    loadAccountsList('To');
                } else if (type === 'To') {
                    $scope.accountListTo = response;
                    initializeTransaction();
                    if (
                        (typeof $stateParams.fromAccountId !== 'undefined' &&
                            $stateParams.fromAccountId !== null) ||
                        (typeof $stateParams.toAccountId !== 'undefined' &&
                            $stateParams.toAccountId !== null) ||
                        (typeof $stateParams.amount !== 'undefined' && $stateParams.amount !== null)
                    ) {
                        autoPopulateValues();
                    }
                }
            });
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
        });
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('InternalTransfer').then(response => {
            $scope.processingCutoff = response;
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableToday = true;

                if (
                    moment($scope.transaction.frequencyModel.startOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                        .then(response => {
                            $scope.transaction.frequencyModel.startOn = formatDate(response);
                        });
                }
                if (
                    moment($scope.transaction.frequencyModel.endOn).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
                ) {
                    utilityService
                        .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                        .then(response => {
                            $scope.transaction.frequencyModel.startOn = formatDate(response);
                        });
                }

                if (timeout > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create transfer for today.');
                }
            }, timeout);
        });
    }

    function init() {
        getHolidays();
        loadCutoffTimes();
        loadAccountsList('From');
        $scope.currentDate = moment().format('MM/DD/YYYY');
    }

    $scope.getMaximumDate = function (startDate, frequencyType) {
        return frequencyService.getMaximumDate(startDate, frequencyType);
    };

    $scope.onChange = function () {
        if (!isNaN(Date.parse($scope.transaction.frequencyModel.startOn))) {
            const dt = new Date($scope.transaction.frequencyModel.startOn);

            if ($scope.transaction.frequencyModel.type === 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.transaction.frequencyModel.type === 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.transaction.frequencyModel.type === 'Twice A Month' ||
                $scope.transaction.frequencyModel.type === 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.transaction.frequencyModel.type === 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.transaction.frequencyModel.type === 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.transaction.frequencyModel.type === 'Yearly') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };

    $scope.checkRepeatOnDays = function () {
        if (!$scope.transaction.frequencyModel.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.transaction.frequencyModel.repeatOnDay1 <
                    $scope.transaction.frequencyModel.repeatOnDay2 ||
                $scope.transaction.frequencyModel.repeatOnDay2 === '';
            const isValid2 =
                $scope.transaction.frequencyModel.repeatOnDay2 >
                    $scope.transaction.frequencyModel.repeatOnDay1 ||
                $scope.transaction.frequencyModel.repeatOnDay1 === '';

            if (
                $scope.transaction.frequencyModel.repeatOnDay1 !== undefined &&
                $scope.transaction.frequencyModel.repeatOnDay2 !== undefined &&
                $scope.transaction.frequencyModel.repeatOnDay2 !== ''
            ) {
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', isValid1);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
    };

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    $scope.summarizeFrequency = function (frequency) {
        return frequencyService.summarizeFrequency(frequency);
    };

    function getBalanceDisplayForToAccount(account) {
        if (account.type === 'Loan') {
            return balanceDisplay(account.currentBalance);
        }

        return balanceDisplay(account.availableBalance);
    }

    function balanceDisplay(balance) {
        return $filter('negativeParentheses')($filter('currency')(balance));
    }

    init();
}
