AddWidgetModalController.$inject = [
    '$scope',
    '$modalInstance',
    'dashboardService',
    'configuredWidgets',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function AddWidgetModalController(
    $scope,
    $modalInstance,
    dashboardService,
    configuredWidgets
) {
    $scope.widgetList = null;
    $scope.widgetGroupList = null;
    $scope.configuredWidgets = configuredWidgets;

    $scope.$watch('widgetList', onWidgetListChange);

    $scope.areWidgetsReadyToAdd = areWidgetsReadyToAdd;
    $scope.cancel = cancel;
    $scope.confirm = confirm;
    $scope.toggleWidget = toggleWidget;

    init();

    function init() {
        dashboardService.getAvailableWidgets().then(response => {
            $scope.widgetList = response.filter(widget => {
                let match = false;
                window.angular.forEach($scope.configuredWidgets, configuredWidget => {
                    match = match || configuredWidget.id === widget.id;
                });
                return match === false;
            });
        });
    }

    function confirm() {
        $modalInstance.close(getSelectedWidgets());
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function toggleWidget(widget) {
        widget.readyToAdd = !widget.readyToAdd;
    }

    function areWidgetsReadyToAdd() {
        return getSelectedWidgets().length > 0;
    }

    //
    // Events

    function onWidgetListChange(widgetList) {
        if (widgetList) {
            $scope.widgetGroupList = groupWidgets($scope.widgetList);
        }
    }

    //
    // Private

    function groupWidgets(widgets) {
        let i;
        let resultList;
        let group;
        resultList = [];
        for (i = 0; i < widgets.length; i += 2) {
            group = [];
            if (widgets[i]) {
                group.push(widgets[i]);
            }
            if (widgets[i + 1]) {
                group.push(widgets[i + 1]);
            }
            resultList.push(group);
        }
        return resultList;
    }

    function getSelectedWidgets() {
        const widgets = [];
        angular.forEach($scope.widgetList, widget => {
            if (widget.readyToAdd) {
                widgets.push(widget);
            }
        });
        return widgets;
    }
}
