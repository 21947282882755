/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { TmApiError } from '@treasury/domain/shared';
import { Recordset } from '@treasury/FDL/recordset';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { css, html, LitElement, nothing } from 'lit';
import '../../../components/blocking-loader.js';
import AchFileActivityClient from '../clients/ach-file-activity-client.js';
import { columns, fields, filters } from '../data/ach-file-data.js';

class AchFileActivityContainer extends LitElement {
    static get properties() {
        return {
            institution: String,
            recordset: Object,
            loading: Boolean,
            downloading: Boolean,
            reportLinks: Array,
        };
    }

    constructor() {
        super();
        this.client = new AchFileActivityClient();
        this.columns = columns;
        this.filters = filters;
        this.fields = fields;
        this.actions = {
            review: async record =>
                (await getNavService()).navigate('payables.ach.payment-list', {
                    fileArchiveIds: [record.getField('fileArchiveId')],
                }),
        };
        this.recordset = new Recordset(this.fields, this.client.fetchAchFileActivity);
        this.title = 'ACH File Activity';
        this.reportInformation = html`<div style="width: 300px;">
            ACH File Activity - Includes all the File names and it's details, which are uploaded by
            entitled user or transmitted machine to machine (File Name, File Size, source, upload
            date etc...)
        </div>`;
        this.reportActions = [
            {
                type: 'primary',
                label: 'Review',
                action: async () =>
                    (await getNavService()).navigate('payables.ach.payment-list', {
                        fileArchiveIds: this.recordset.allRecords
                            .filter(r => r.getField('selected'))
                            .map(r => r.getField('fileArchiveId')),
                    }),
                isDisabled: () =>
                    this.recordset.allRecords.filter(r => r.getField('selected')).length === 0,
            },
        ];
        this.downloadOptions = ['PDF', 'CSV'];
        this.downloading = false;
        this.options = ['download', 'print'];
        this.rowsPerPage = 25;
    }

    firstUpdated() {
        this.reportLinks = [
            {
                title: 'ACH Payment Activity',
                route: 'payables.ach.payment-list',
            },
            {
                title: 'Recurring ACH Payments',
                route: 'payables.ach.recurring-payment-list',
            },
        ];
    }

    async handleDownload({ detail }) {
        this.downloading = true;
        try {
            await this.client.downloadAchFileActivity(detail.type, detail.filter);
        } catch (e) {
            const message =
                e instanceof TmApiError ? e.message : 'Download failed. Please try again.';
            this.alert = { ...this.alert, message, visible: true, type: 'error' };
        } finally {
            this.downloading = false;
        }
    }

    renderDownloadDialog() {
        if (this.downloading) {
            return html`<blocking-loader></blocking-loader> `;
        }
        return nothing;
    }

    render() {
        return html`
            ${this.renderDownloadDialog()}
            <omega-report
                flyout
                autostart
                .title=${this.title}
                .actions=${this.actions}
                .reportInformation=${this.reportInformation}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recordset}
                .columns=${this.columns}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .reportActions=${this.reportActions}
                .downloadOptions=${this.downloadOptions}
                .downloadFunction=${this.handleDownload}
                .options=${this.options}
                .callToAction=${this.callToActionText}
                .rowsPerPage=${this.rowsPerPage}
                @reportDownload=${this.handleDownload}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                ></omega-filter-bar>
            </omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
        `;
    }
}

customElements.define('ach-file-activity-container', AchFileActivityContainer);

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
