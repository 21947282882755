/* eslint-disable no-param-reassign */
/* eslint-disable @treasury/filename-match-export */
import '../../components/omega-toast';

import { Injectable } from '@jack-henry/frontend-utils/di';
import { html, render } from 'lit';
import { OmegaToastOptions } from './omega-toast.service.types';

interface ToastStackStyles {
    position: string;
    top: string;
    right: string;
    zIndex: string;
    maxHeight: string;
    display: string;
    gridTemplateColumns: string;
    gap: string;
    overflowY: string;
    scrollbarWidth: string;
}

const toastStackStyles: ToastStackStyles = {
    position: 'fixed',
    zIndex: '10000',
    top: '3vh',
    right: '3vw',
    maxHeight: '94vh',
    display: 'grid',
    gridTemplateColumns: '350px',
    gap: '5px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
};

@Injectable()
export class OmegaToastService {
    private applyToastStackStyles(toastStack: any) {
        Object.keys(toastStackStyles).forEach((key: string) => {
            toastStack.style[key as keyof ToastStackStyles] =
                toastStackStyles[key as keyof ToastStackStyles];
        });
    }

    public render(toasts: OmegaToastOptions[]) {
        const toastStack = document.createElement('div');
        this.applyToastStackStyles(toastStack);
        document.body.appendChild(toastStack);
        render(
            html`${toasts.map(
                toast =>
                    html`<omega-toast
                        .type=${toast.type}
                        .message=${toast.message}
                        .visible=${true}
                    ></omega-toast>`
            )}`,
            toastStack
        );
    }
}
