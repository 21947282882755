/* eslint-disable class-methods-use-this */

import { css, html, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { ListeningElementMixin } from './listening-element';

const ListeningElement = ListeningElementMixin(LitElement);

class OmegaInput extends ListeningElement {
    static get properties() {
        return {
            label: { type: String },
            field: { type: Object },
            disabled: { type: Boolean, defaultValue: false },
            validate: { attribute: false },
            placeholder: { type: String },
            minLength: { type: Number },
            maxLength: { type: Number },
            dirty: Boolean,
            selectOnFocus: Boolean,
            formatOnChange: Boolean,
            type: { type: String },
        };
    }

    constructor() {
        super();
        this.disabled = false;
        this.field = { onChange: () => true, allowInputChar: () => true };
        this.placeholder = '';
        this.inputHasFocus = false;
        this.touched = false;
        this.formatOnChange = false;
        this.maxLength = 524288;
        this.minLength = 0;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-input--input',
        };
    }

    firstUpdated() {
        this.listenTo(this.field, 'change', () => {
            if (!this.inputHasFocus) {
                this.nativeInput.value = this.blurredValue;
                this.setNativeInputInvalidState();
                this.requestUpdate();
            }
        });
    }

    updated(changedProps) {
        // when used via omega-field, we reassign the field property
        // meaning we need to replicate the change event above
        if (changedProps.has('field')) {
            this.nativeInput.value = this.blurredValue;
            this.setNativeInputInvalidState();
            this.requestUpdate();
        }
    }

    get value() {
        return this.field.value;
    }

    set value(v) {
        this.field.value = v;
    }

    get focusedValue() {
        return this.field.focusedInputValue || '';
    }

    get blurredValue() {
        return this.field.blurredInputValue || '';
    }

    setNativeInputInvalidState() {
        this.nativeInput?.setAttribute('aria-invalid', !this.valid && this.touched);
    }

    get valid() {
        return this.field?.valid;
    }

    get nativeInput() {
        return this.shadowRoot.querySelector('input');
    }

    maskKey(event) {
        this.dispatchEvent(new CustomEvent('keydown', event));
        if (this.field.allowInputChar(event.key, event)) return;
        event.preventDefault();
    }

    onChange() {
        this.value = this.nativeInput.value;
        this.setNativeInputInvalidState();
        if (this.formatOnChange) {
            this.nativeInput.value = this.focusedValue;
        }
        this.dispatchEvent(new CustomEvent('change', { detail: this.nativeInput.value }));
    }

    shouldUpdate(changedProperties) {
        // Only update element if prop1 changed.
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (changedProperties.size === 1 && changedProperties.has('validate')) {
            return false;
        }
        return true;
    }

    focus() {
        this.nativeInput.focus();
    }

    onFocus() {
        this.nativeInput.value = this.focusedValue;
        this.inputHasFocus = true;
        if (this.selectOnFocus) {
            this.nativeInput.setSelectionRange(0, this.nativeInput.value.length);
        }
    }

    onBlur() {
        this.inputHasFocus = false;
        this.touched = true;
        this.setNativeInputInvalidState();
        this.nativeInput.value = this.blurredValue;
        this.dispatchEvent(new CustomEvent('change', { detail: this.nativeInput.value }));
        this.dispatchEvent(new CustomEvent('blur'));
    }

    render() {
        return html`
            <input
                type=${this.type}
                placeholder=${this.placeholder}
                .value=${this.blurredValue}
                .minLength=${this.minLength}
                .maxLength=${this.maxLength}
                ?disabled=${this.disabled}
                aria-label=${this.label}
                aria-invalid=${!this.valid && this.touched}
                @focus=${this.onFocus}
                @blur=${this.onBlur}
                @keydown=${e => this.maskKey(e)}
                @input=${this.onChange}
            />
        `;
    }

    static get styles() {
        return css`
            * {
                box-sizing: border-box;
            }

            :host {
                display: block;
                color: var(--omega-ux-text-color, #000);
            }

            input {
                width: 100%;
                height: 32px;
                line-height: 34px;
                text-align: middle;
                border: 1px solid var(--omega-input-default-border);
                padding: 0 10px;
                border-radius: var(--omega-input-border-radius);
                font-size: var(--omega-input);
            }

            input[aria-invalid='true'] {
                /* outline: 1px solid var(--omega-input-error-border); */
                border: 2px solid var(--omega-input-error-border);
                outline: none;
            }

            input:active {
                border-color: var(--omega-input-active-border);
            }

            input:disabled {
                /* matching omega-select and datepicker */
                /* border-color: var(--omega-input-disabled-border); */
                /* background-color: var(--omega-grayed-out); */
                opacity: 0.6;
                background: var(--omega-input-disabled-background);
            }
        `;
    }
}

window.customElements.define('omega-input', OmegaInput);
export default OmegaInput;
