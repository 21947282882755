VerifyUserModalController.$inject = [
    '$scope',
    '$modalInstance',
    '$state',
    'securityMessage',
    'reverifyCallback',
    '$templateCache',
];

export default function VerifyUserModalController(
    $scope,
    $modalInstance,
    $state,
    securityMessage,
    reverifyCallback,
    $templateCache
) {
    $templateCache.put(
        '/app/shared/security/views/oob/verifyUserOobModalBody.html',
        require('../views/oob/verifyUserOobModalBody.html')
    );
    $templateCache.put(
        '/app/shared/security/views/oob/verifyUserOobModalFooter.html',
        require('../views/oob/verifyUserOobModalFooter.html')
    );
    $templateCache.put(
        '/app/shared/security/views/tokens/verifyUserTokenModalBody.html',
        require('../views/tokens/verifyUserTokenModalBody.html')
    );
    $templateCache.put(
        '/app/shared/security/views/tokens/verifyUserTokenModalFooter.html',
        require('../views/tokens/verifyUserTokenModalFooter.html')
    );
    $templateCache.put(
        '/app/shared/security/views/oob2/verifyUserOob2ModalBody.html',
        require('../views/oob2/verifyUserOob2ModalBody.html')
    );
    $templateCache.put(
        '/app/shared/security/views/oob2/verifyUserOob2ModalFooter.html',
        require('../views/oob2/verifyUserOob2ModalFooter.html')
    );

    $scope.securityMessage = securityMessage;
    $scope.oob2InputValid = oob2InputValid;

    $scope.verifyUsingAlternate = function () {
        $scope.securityMessage.status = 'VerifyUsingAlternate';
        reverifyCallback();
    };

    $scope.$on('modal.closing', (event, reason, closed) => {
        if (reason === 'escape key press') {
            event.preventDefault();
        }
    });

    $scope.canShowTokenForm = function () {
        return securityMessage.status === 'Verify' || securityMessage.status === 'Failure';
    };

    $scope.canShowOob2Form = function () {
        return securityMessage.status === 'Verify' || securityMessage.status === 'Failure';
    };

    function verifyOob() {
        $scope.securityMessage.status = 'Verify';
        reverifyCallback();
    }

    function verifyOob2() {
        $scope.securityMessage.status = 'Verifying';
        $scope.securityMessage.errorCode = null;
        $scope.securityMessage.message = null;
        reverifyCallback();
    }

    function resendOob2() {
        $scope.securityMessage.status = null;
        $scope.securityMessage.errorCode = null;
        $scope.securityMessage.message = null;
        $scope.securityMessage.oneTimePassword = null;
        reverifyCallback();
    }

    function verifyToken() {
        $scope.securityMessage.status = 'VerifyToken';
        reverifyCallback();
    }

    function oob2InputValid() {
        return $scope.verifyForm.oob2Input ? !!$scope.verifyForm.oob2Input.$modelValue : false;
    }

    function init() {
        switch (securityMessage.challengeMethodTypeId) {
            case 1:
                oobInit();
                break;
            case 2:
                tokenInit();
                break;
            case 3:
                oob2Init();
                break;
            default:
                $modalInstance.dismiss();
                $state.go('dashboard');
        }
    }

    function oobInit() {
        $scope.bodyTemplate = templateUrl('oob/verifyUserOobModalBody.html');
        $scope.footerTemplate = templateUrl('oob/verifyUserOobModalFooter.html');
        $scope.verify = verifyOob;
    }

    function tokenInit() {
        $scope.bodyTemplate = templateUrl('tokens/verifyUserTokenModalBody.html');
        $scope.footerTemplate = templateUrl('tokens/verifyUserTokenModalFooter.html');
        $scope.verify = verifyToken;
    }
    function oob2Init() {
        $scope.bodyTemplate = templateUrl('oob2/verifyUserOob2ModalBody.html');
        $scope.footerTemplate = templateUrl('oob2/verifyUserOob2ModalFooter.html');
        $scope.verify = verifyOob2;
        $scope.resend = resendOob2;
    }

    function templateUrl(relativePath) {
        return `/app/shared/security/views/${relativePath}`;
    }

    init();
}
