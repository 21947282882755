FavoriteReportsController.$inject = ['$scope', 'dashboardService', '$state', 'spinnerService'];

// eslint-disable-next-line @treasury/filename-match-export
export default function FavoriteReportsController(
    $scope,
    dashboardService,
    $state,
    spinnerService
) {
    $scope.runReport = runReport;
    $scope.goToIrDashboard = goToIrDashboard;

    init();

    function init() {
        spinnerService.configureGroup(
            'favoriteReportsWidget',
            ['/dashboard/favouriteReports'],
            ['favoriteReportsWidget.html']
        );

        updateSpinner(spinnerService.startGroupRequest('favoriteReportsWidget'));
        dashboardService.getFavouriteReports().then(response => {
            updateSpinner(spinnerService.stopGroupRequest('favoriteReportsWidget'));
            $scope.favouriteReports = response.filter(report => report.isFavorite);
        });
    }

    function runReport(report) {
        if (report.reportType === 'Transaction') {
            if (report.dateType === 'Current Day' || report.dateType === 'Prior Day') {
                $state.go('ir.dt', { id: report.id });
            } else {
                $state.go('ir.drt', { id: report.id });
            }
        } else if (report.reportType === 'Balance') {
            if (report.dateType === 'Current Day' || report.dateType === 'Prior Day') {
                $state.go('ir.db', { id: report.id });
            } else {
                $state.go('ir.drb', { id: report.id });
            }
        } else if (report.reportType === 'EDI') {
            $state.go('ir.edi', { id: report.id });
        }
    }

    function goToIrDashboard() {
        $state.go('ir.dashboard');
    }

    function updateSpinner(numPendingRequests) {
        $scope.widget.isLoading = numPendingRequests > 0;
    }
}
