import { LitElement, html, css, nothing } from 'lit';

import { Record } from '@treasury/FDL/record';
import { string } from '@treasury/policy/primitives';

import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-textarea';
import '@treasury/omega/components/progress/omega-progress.js';

class AchTemplateActionDialog extends LitElement {
    static get properties() {
        return {
            showActionDialog: Boolean,
            dialogAction: Function,
            dialogTitle: String,
            actionTaken: String,
            record: Object,
            templateName: String,
            statusChangeComments: String,
            loading: Boolean,
            closeEventHandler: Function,
        };
    }

    constructor() {
        super();
        this.showActionDialog = true;
        this.actionTaken = '';
        this.loading = false;
        this.step = 'prompt';
        this.statusChangeComments = null;
        this.templateName = '';
    }

    connectedCallback() {
        super.connectedCallback();
        this.statusCommentRecord = new Record(
            { comments: string.as.tag('omega-input').with.maxLength(150) },
            { comments: '' }
        );
    }

    get disableDialogAction() {
        return typeof this.dialogAction !== 'function';
    }

    getDialogTitle() {
        return this.dialogTitle ?? 'Delete Template';
    }

    async handleDialogAction() {
        let response = { type: 'error', message: 'An error has occurred.' };
        this.loading = true;
        if (this.dialogAction) {
            response = await this.dialogAction(this.record, this.statusChangeComments);
        }
        this.loading = false;
        this.step = response.type;
        this.message = response.message;
        this.dispatchEvent(new CustomEvent(this.actionTaken, { detail: response }));
    }

    handleModalClose() {
        this.showActionDialog = false;
        if (typeof this.closeEventHandler === 'function') this.closeEventHandler();
        this.statusCommentRecord.setField('comments', '');
        this.dispatchEvent(new CustomEvent('close'));
    }

    capitalizeAction(action) {
        return action[0].toUpperCase() + action.substring(1).toLowerCase();
    }

    renderName() {
        if (this.templateName === '') return 'this ';
        return html`the <b>${this.templateName}</b> `;
    }

    renderComments() {
        if (this.actionTaken !== 'approve' && this.actionTaken !== 'reject') return nothing;
        return html`
            Comments:
            <omega-textarea
                .rows=${this.rowCount}
                .field=${this.statusCommentRecord.field('comments')}
                @change=${({ detail }) => {
                    this.statusChangeComments = detail;
                }}
                .maxLength=${this.statusCommentRecord.field('comments').fieldType.maxLength()}
            ></omega-textarea>
        `;
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<omega-progress card class="large-loader"></omega-progress>`;
    }

    renderContent() {
        const types = {
            delete: 'primary',
            reject: 'reject',
            approve: 'approve',
        };
        if (this.step === 'prompt') {
            const content = html`Are you sure you want to ${this.actionTaken} this template?`;
            return html`<div slot="content" id="modalContent">
                    ${content}
                    <br />
                    <br />
                    ${this.renderComments()}
                </div>
                <omega-button
                    slot="actions"
                    type="${types[this.actionTaken]}"
                    id="templateDialogActionButton"
                    ?disabled=${this.disableDialogAction}
                    @click=${this.handleDialogAction}
                    >${this.capitalizeAction(this.actionTaken)}</omega-button
                >
                <omega-button
                    slot="actions"
                    @click=${this.handleModalClose}
                    id="closeTemplateDialogActionButton"
                    >Cancel</omega-button
                >`;
        }
        if (this.step === 'success' || this.step === 'error') {
            return html`<div slot="content" id="modalContent">
                    ${this.message}
                    <br />
                    <br />
                </div>
                <omega-button
                    slot="actions"
                    @click=${this.handleModalClose}
                    id="closeTemplateDialogActionButton"
                    >Close</omega-button
                >`;
        }
        return html`<div slot="content" id="modalContent">
                An error occurred while processing your request.
                <br />
                <br />
            </div>
            <omega-button
                slot="actions"
                @click=${this.handleModalClose}
                id="closeTemplateDialogActionButton"
                >Close</omega-button
            >`;
    }

    renderDialogBody() {
        if (this.loading) return this.renderLoader();
        return this.renderContent();
    }

    render() {
        if (this.showActionDialog) {
            return html`<omega-dialog
                id="template-action-dialog"
                open
                .dialogTitle=${this.getDialogTitle()}
                @close=${this.handleModalClose}
            >
                ${this.renderDialogBody()}
            </omega-dialog>`;
        }
        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #modalContent {
                padding: 0 15px;
            }
        `;
    }
}

customElements.define('ach-template-action-dialog', AchTemplateActionDialog);
