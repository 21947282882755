import { AchBankDto, PaymentRecipientDto } from '@treasury/domain/channel/types/ach';
import { AchDomesticClient } from '../clients/ach-domestic-client';

export const setAchBanks = async (
    recipients: Array<PaymentRecipientDto>,
    client: AchDomesticClient
) => {
    const banks = await client.achBanks();
    recipients.forEach(recipient => {
        // eslint-disable-next-line no-param-reassign
        recipient.bank =
            banks.find((bank: AchBankDto) => bank.bankId === recipient.routingNumber) ?? undefined;
    });
};
