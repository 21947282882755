import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import { RecordsetValidator } from '@treasury/FDL/recordset';

export const onUsTransactionsValidator = (
    record: Record<PaymentHeader>
): RecordsetValidator<PaymentRecipient> => ({
    name: (recipients: Array<Record<PaymentRecipient>>) => {
        const achCompany = record.getField('achCompany');
        return `At least ${achCompany.onUsTransactionsRequired} recipients are required to have "On Us" routing numbers.`;
    },
    validate: (recipients: Array<Record<PaymentRecipient>>) => {
        const achCompany = record.getField('achCompany');
        if (!achCompany.onUsTransactionsRequired) return true;

        const onUsRecipients = recipients.filter((recipientRecord: Record<PaymentRecipient>) => {
            const recipient = recipientRecord.values;
            if (!recipient.bank) return false;
            return recipient.bank.onUs;
        });

        return onUsRecipients.length >= achCompany.onUsTransactionsRequired;
    },
});
