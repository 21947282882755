export function arrayHasElements<T>(array: T[]) {
    return array.length > 0;
}

export function enforceArray<T>(arrayOrItem: T[] | T): T[] {
    return Array.isArray(arrayOrItem) ? arrayOrItem : [arrayOrItem];
}

/**
 * Removes an element from the provided array.
 *
 * @param inline - If `true` (default), removes the element from provided array,
 * mutating it and maintaining the reference. Otherwise, the array is left untouched
 * and a new array is returned.
 *
 * @returns The original array if `inline` is `true`; otherwise, a new array sans `removed`.
 */
export function removeArrayElement<T>(removed: T, array: T[], inline = true) {
    if (inline) {
        const index = array.indexOf(removed);
        if (index > -1) {
            array.splice(index, 1);
        }

        return array;
    }

    return array.filter(element => element !== removed);
}

/**
 * Produces a new array that contains the values only occurring in `target` but not in `source`.
 * Items that are in `source` but not `target` will not be considered (for that, use `symmetricDifference()`).
 * See [set theory definition](https://en.wikipedia.org/wiki/Complement_(set_theory)#Relative_complement).
 *
 * Prefer [`Set.prototype.difference()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/difference)
 * when it becomes widely available.
 *
 * @param target The array to compare against `source`.
 * @param source The array to be compared against.
 */
export function difference<T>(target: T[], source: T[]) {
    return target.filter(value => !source.includes(value));
}

/**
 * Produces a new array that contains the values unique to each array.
 * See [set theory definition](https://en.wikipedia.org/wiki/Symmetric_difference).
 *
 * Prefer [`Set.prototype.symmetricDifference()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/symmetricDifference)
 * when it becomes widely available.
 */
export function symmetricDifference<T>(a: T[], b: T[]) {
    const diffA = difference(a, b);
    const diffB = difference(b, a);
    // use a set to guarantee uniqueness
    return Array.from(new Set(diffA.concat(diffB))).sort();
}
