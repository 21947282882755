FocusIf.$inject = ['$timeout'];

export default function FocusIf($timeout) {
    function link($scope, $element, $attrs) {
        const dom = $element[0];
        if ($attrs.focusIf) {
            $scope.$watch($attrs.focusIf, focus);
        } else {
            focus(true);
        }
        function focus(condition) {
            if (condition) {
                $timeout(() => {
                    dom.focus();
                }, 0);
            }
        }
    }
    return {
        restrict: 'A',
        link,
    };
}
