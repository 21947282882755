UtilityService.$inject = ['$q'];

export default function UtilityService($q) {
    return {
        getNextBusinessDay,
        getAchNextBusinessDay,
        getAchNextEffectiveDate,
    };

    function getNextBusinessDay(holidays, disableToday, desiredDate = undefined) {
        const deferred = $q.defer();
        const { promise } = deferred;
        let shouldDisableToday = disableToday;
        if (desiredDate) {
            const today = moment();
            shouldDisableToday = disableToday && moment(desiredDate).isSame(today, 'day');
        }
        
        let currentDate = shouldDisableToday
            ? moment(desiredDate).add(1, 'd').format('MM/DD/YYYY')
            : moment(desiredDate).format('MM/DD/YYYY');
        while (
            holidays &&
            (holidays.reduce(
                (acc, val) => moment(val).format('MM/DD/YYYY') === currentDate || acc,
                false
            ) ||
                moment(currentDate).day() === 0 ||
                moment(currentDate).day() === 6)
        ) {
            currentDate = moment(currentDate).add(1, 'd').format('MM/DD/YYYY');
        }
        deferred.resolve(currentDate);
        return promise;
    }

    function getAchNextBusinessDay(holidays, disableToday, cutoffPassed) {
        const deferred = $q.defer();
        const { promise } = deferred;
        let currentDate =
            disableToday && cutoffPassed
                ? moment().add(2, 'd').format('MM/DD/YYYY')
                : disableToday || cutoffPassed
                  ? moment().add(1, 'd').format('MM/DD/YYYY')
                  : moment().format('MM/DD/YYYY');
        while (
            holidays &&
            (holidays.reduce(
                (acc, val) => moment(val).format('MM/DD/YYYY') === currentDate || acc,
                false
            ) ||
                moment(currentDate).day() === 0 ||
                moment(currentDate).day() === 6)
        ) {
            currentDate = moment(currentDate).add(1, 'd').format('MM/DD/YYYY');
        }
        deferred.resolve(currentDate);
        return promise;
    }

    function getAchNextEffectiveDate(holidays, disableToday, cutoffPassed) {
        let nonEffectiveDate = moment();
        let nonEffectiveDay = nonEffectiveDate.day();

        if (cutoffPassed && isNonBusinessDay(nonEffectiveDate, holidays)) {
            // have to ignore cutoff if current date falls on holidays or Weekends
            cutoffPassed = false;
        }

        if (disableToday || cutoffPassed) {
            holidays.forEach(holiday => {
                if (
                    moment(holiday).isSame(nonEffectiveDate, 'day') ||
                    nonEffectiveDay === 0 ||
                    nonEffectiveDay === 6
                ) {
                    nonEffectiveDate = nonEffectiveDate.add(1, 'd');
                    nonEffectiveDay = nonEffectiveDate.day();
                }
            });
            holidays.push(nonEffectiveDate.toISOString());
        }

        if (disableToday && cutoffPassed) {
            nonEffectiveDate = nonEffectiveDate.add(1, 'd');
            nonEffectiveDay = nonEffectiveDate.day();
            holidays.forEach(holiday => {
                if (
                    moment(holiday).isSame(nonEffectiveDate, 'day') ||
                    nonEffectiveDay === 0 ||
                    nonEffectiveDay === 6
                ) {
                    nonEffectiveDate = nonEffectiveDate.add(1, 'd');
                    nonEffectiveDay = nonEffectiveDate.day();
                }
            });
            holidays.push(nonEffectiveDate.toISOString());
        }

        return getAchNextBusinessDay(holidays, disableToday, cutoffPassed);
    }

    function isNonBusinessDay(currentDate, holidays) {
        const currentDay = currentDate.day();

        if (currentDay === 0 || currentDay === 6) {
            return true;
        }

        return holidays.some(holiday => moment(holiday).isSame(currentDate, 'day'));
    }
}
