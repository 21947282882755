/* eslint-disable class-methods-use-this */
import { html } from 'lit';
import StandardColumn from './standard-column.js';

export default class RadioColumn extends StandardColumn {
    constructor(...args) {
        super(...args);
        const [{ field, value }, recordset, cursor] = args;
        this.field = field;
        this.fieldType = recordset.getFieldType(field);
        this.value = value;
        this.recordset = recordset;
        this.minWidth = 50;
        this.targetWidth = 55;
        this.maxWidth = 60;
        this.cursor = cursor;
    }

    isSortable() {
        return false;
    }

    columnToggle(field) {
        const allRecords =
            this.recordset.allRecords ?? /* for backwards compatibility */ this.cursor.allRecords;
        allRecords.forEach(record => record.setField(field, this.value));
    }

    renderThContents() {
        return html`<div style="text-align: center">
            ${this.label}
            <br />
            <span
                role="button"
                style="cursor: pointer; text-decoration: underline; color: var(--omega-primary);"
                @click="${e => this.columnToggle(this.field, e)}"
                @keypress=${e => {
                    if (e.key === 'Enter') {
                        this.columnToggle(this.field);
                    }
                }}
                >All</span
            >
        </div>`;
    }

    renderTdContents(record, rowIndex) {
        const radioButton = document.createElement('input');
        radioButton.type = 'radio';
        radioButton.name = `${rowIndex}_${this.field}`;
        radioButton.value = this.value;
        radioButton.checked = this.value === record.getField(this.field);

        this.listenTo(radioButton, 'click', () => record.setField(this.field, this.value));

        this.listenTo(record, 'change', () => {
            radioButton.checked = this.value === record.getField(this.field);
        });

        return radioButton;
    }

    renderTd(record, rowIndex) {
        const contents = record
            ? this.renderTdContents(record, rowIndex)
            : this.renderContentLoading();
        return html`
            <td class="${this.cellClasses().join(' ')}" style="text-align:center;">${contents}</td>
        `;
    }
}
