import { getChannelAPIPath } from '@treasury/core/http';

NotificationConfigurationService.$inject = ['$resource'];

export default function NotificationConfigurationService($resource) {
    const resourceUrl = `${getChannelAPIPath()}notificationConfiguration/`;

    return {
        getNotificationConfigurations,
        save,
    };

    function getNotificationConfigurations() {
        return $resource(resourceUrl).get().$promise;
    }

    function save(configuration) {
        const resource = $resource(resourceUrl, {}, { update: { method: 'PUT' } });
        return resource.update(configuration).$promise;
    }
}
