ChangeUserStatusController.$inject = [
    '$scope',
    'usersService',
    'userId',
    'isActive',
    '$modalInstance',
];

export default function ChangeUserStatusController(
    $scope,
    usersService,
    userId,
    isActive,
    $modalInstance
) {
    // selected User
    $scope.userId = userId;

    $scope.comments = null;

    $scope.isActive = isActive;
    $scope.setForm = setForm;
    $scope.cancel = cancel;
    $scope.save = save;

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        updateUserStatus();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    // update user
    function updateUserStatus() {
        usersService
            .changeUserActiveStatus($scope.userId, $scope.isActive, $scope.comments)
            .then(response => {
                $modalInstance.close(response);
            });
    }
}
