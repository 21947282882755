import { css, html, LitElement, nothing } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';
import './omega-tooltip.js';

const KEY_CODES = {
    enter: 13,
};

class OmegaDownloadBar extends LitElement {
    static get properties() {
        return {
            description: String,
            message: String,
            tooltipDirection: String,
            disableSave: Boolean,
            disableExportOptions: Boolean,
            disablePrint: Boolean,
            actions: Array,
            downloadOptions: Array,
            customReport: Boolean,
            reportTitle: String,
            pageTitle: String,
            isDownloadDropdownOpen: Boolean,
            saveReportModal: Boolean,
            reportName: String,
            hideActionLabels: Boolean,
        };
    }

    constructor() {
        super();
        this.isDownloadDropdownOpen = false;
        this.hideActionLabels = 'always';
        this.actions = [];
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-download-bar--default',
        };
    }

    firstUpdated() {
        this.reportName = this.reportTitle ?? '';
    }

    hasAction(action) {
        return this.actions.includes(action);
    }

    toggleDropdownDownloadOptions() {
        this.isDownloadDropdownOpen = !this.isDownloadDropdownOpen;
        // focus on first item in dropdown
    }

    setReportName() {
        if (this.isCustomReport() && this.isPageTitleValid()) {
            this.reportName = this.reportTitle;
        }
    }

    areDownloadEventOptionsValid() {
        return this.downloadOptions !== undefined;
    }

    isDownloadEventVisible() {
        return this.isDownloadEventValid() && this.areDownloadEventOptionsValid();
    }

    openSaveReportOptions() {
        this.saveReportModal = !this.saveReportModal;
        this.setReportName();
    }

    showSaveReport() {
        return this.saveReportModal === true;
    }

    isCustomReport() {
        return this.customReport === true;
    }

    isReportNameInvalid() {
        return this.reportName === undefined || this.reportName === null;
    }

    isReportTitleValid() {
        return !!this.reportTitle;
    }

    isPageTitleValid() {
        return !!this.pageTitle;
    }

    clearInputs() {
        this.reportName = null;
    }

    updateReportName(e) {
        this.reportName = e.target.value;
    }

    _download(e) {
        this.dispatchEvent(
            new CustomEvent('download', {
                composed: true,
                bubbles: true,
                detail: { downloadType: e.target.innerText },
            })
        );
        this.toggleDropdownDownloadOptions();
    }

    _onKeyUp(e) {
        if (e.keyCode === KEY_CODES.enter) {
            this._download(e);
        }
    }

    _save() {
        this.dispatchEvent(
            new CustomEvent('save', {
                composed: true,
                bubbles: true,
                detail: { reportName: this.reportName },
            })
        );
    }

    _print() {
        this.dispatchEvent(
            new CustomEvent('print', {
                composed: true,
                bubbles: true,
            })
        );
    }

    renderSaveReportOptions() {
        return html` <div class="omega-download-bar-actions">
            <i
                ng-if="$ctrl.isCustomReport()"
                id="clear-report-name"
                role="button"
                class="fa fa-times-circle"
                @keyup=${this.clearInputs}
                @click=${this.clearInputs}
                aria-label="Clear Report Name"
            ></i>
            <input
                class="omega-save-report"
                .value=${this.reportName}
                required
                type="text"
                maxlength="50"
                aria-label="Report Name"
                placeholder="Save report as ..."
                @keyup=${this.updateReportName}
            />
            <omega-button
                type="primary"
                posture="small"
                ?disabled=${this.isReportNameInvalid()}
                @click=${this._save}
            >
                Save
            </omega-button>
            <omega-button type="secondary" posture="small" @click=${this.openSaveReportOptions}>
                Cancel
            </omega-button>
        </div>`;
    }

    renderDownloadDropdown() {
        const downloadOptions = this.downloadOptions.map(
            option =>
                html`<li
                    role="menuitem"
                    class="omega-download-dropdown-item"
                    tabindex="0"
                    @click=${this._download}
                    @keyup=${this._onKeyUp}
                >
                    ${option}
                </li>`
        );
        return html`<ul
            class="omega-download-dropdown"
            role="menu"
            tabindex="0"
            aria-hidden=${this.isDownloadDropdownOpen}
        >
            ${downloadOptions}
        </ul>`;
    }

    renderTitle() {
        return html`<span class="omega-download-bar-report-title"
            >${this.reportTitle || this.pageTitle} ${this.renderInformation()}</span
        >`;
    }

    renderSubTitle() {
        if (this.reportTitle) {
            return html`<span class="omega-download-bar-page-title">${this.pageTitle}</span>`;
        }
        return nothing;
    }

    renderInformation() {
        if (this.message) {
            return html`<omega-tooltip
                id="tm-message"
                light
                icon="info-circle"
                .message=${this.message}
                .direction=${this.tooltipDirection}
            ></omega-tooltip>`;
        }
        return nothing;
    }

    renderSaveOption() {
        return this.hasAction('save')
            ? html`<omega-button
                  class="download-bar-item"
                  type="icon"
                  icon="save"
                  .hideLabel=${this.hideActionLabels}
                  @click=${this.openSaveReportOptions}
                  ?disabled=${this.disableSave}
              >
                  Save
              </omega-button>`
            : nothing;
    }

    renderSaveDetails() {
        return this.showSaveReport() ? this.renderSaveReportOptions() : nothing;
    }

    renderPrintOption() {
        return this.hasAction('print')
            ? html`<omega-button
                  class="download-bar-item print"
                  type="icon"
                  .hideLabel=${this.hideActionLabels}
                  icon="print"
                  ?disabled="${this.disablePrint}"
                  @click=${this._print}
              >
                  Print
              </omega-button>`
            : nothing;
    }

    renderDescriptionOption() {
        if (this.hasAction('description') && this.description) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.description, 'text/html');
            const description = doc.body;
            return html`<omega-tooltip light clickable direction="bottom-left"
                ><omega-button type="icon" icon="question-circle" hideLabel="always"
                    >Description</omega-button
                >
                <div slot="content" style="width: 450px;">${description}</div></omega-tooltip
            >`;
        }
        return nothing;
    }

    render() {
        return html`
            <div class="omega-download-bar">
                <span class="omega-download-bar-page-info">
                    ${this.renderTitle()} ${this.renderSubTitle()}</span
                >
                <div class="omega-download-bar-button-group omega-no-print">
                    ${this.renderSaveDetails()} ${this.renderSaveOption()}
                    ${this.hasAction('download')
                        ? html`<div class="omega-download-bar-menu download-bar-item">
                              <omega-button
                                  class="omega-download-bar-menu-button download"
                                  type="icon"
                                  icon="download"
                                  .hideLabel=${this.hideActionLabels}
                                  ?disabled="${this.disableExportOptions}"
                                  @click=${this.toggleDropdownDownloadOptions}
                              >
                                  Download
                              </omega-button>
                              ${this.isDownloadDropdownOpen
                                  ? this.renderDownloadDropdown()
                                  : nothing}
                          </div>`
                        : nothing}
                    ${this.renderPrintOption()} ${this.renderDescriptionOption()}<slot
                        name="right"
                    ></slot>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                .omega-download-bar {
                    z-index: 2;
                    top: 0;
                    display: flex;
                    justify-content: space-between;
                    background-color: var(--omega-white);
                    align-items: center;
                }

                .omega-download-bar-button-group {
                    display: flex;
                    justify-content: flex-end;
                }

                .download-bar-item {
                    display: inline-block;
                    font-size: 16px;
                }

                .download-bar-item button {
                    width: 44px;
                    height: 44px;
                }

                .omega-download-bar-menu {
                    position: relative;
                }

                .omega-download-dropdown {
                    position: absolute;
                    right: 0;
                    top: 30px;
                    z-index: 120;
                    min-width: 50px;
                    padding: 0;
                    border: 1px solid var(--omega-secondary-lighten-400);
                    background: var(--omega-white);
                    border-radius: 3px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                    list-style: none;
                }

                .omega-download-dropdown:focus {
                    border: 1px solid var(--omega-primary);
                }

                .omega-download-dropdown li {
                    white-space: nowrap;
                    min-width: 50px;
                    padding: 10px 25px;
                    text-align: center;
                    color: var(--omega-primary);
                    cursor: pointer;
                }

                .omega-download-dropdown li:hover {
                    background-color: var(--omega-white-100);
                }

                .omega-download-dropdown li:focus {
                    border: 1px solid var(--omega-primary);
                }

                .omega-download-dropdown a {
                    text-decoration: none;
                }

                .omega-download-bar-actions {
                    display: inline;
                }

                .omega-download-bar-actions i {
                    position: absolute;
                    margin-top: 16px;
                    margin-left: 192px;
                    color: var(--omega-secondary-lighten-100);
                }

                .omega-download-save-button {
                    height: 25px;
                    margin-right: 5px;
                    border: 1px solid var(--omega-primary-darken-100);
                    background-color: var(--omega-primary);
                    border-radius: 3px;
                    color: var(--omega-white);
                }

                .omega-download-cancel-button {
                    height: 25px;
                    margin-right: 28px;
                    border: 1px solid var(--omega-secondary-lighten-300);
                    background-color: white;
                    border-radius: 3px;
                    color: dimgrey; //cspell:disable-line
                }

                .omega-save-report {
                    overflow: hidden;
                    width: 200px;
                    height: 25px;
                    padding: 0 0 0 8px;
                    margin-right: 5px;
                    border: 1px solid var(--omega-primary-darken-100);
                    border-radius: 3px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                #clear-report-name:focus {
                    border: 1px solid var(--omega-primary);
                    border-radius: 2px;
                    box-shadow: 1px 1px 1px;
                }

                .omega-download-bar.open.dropdown-menu.omega-dropdown {
                    margin-left: 190px;
                }

                button[disabled].omega-download-save-button {
                    cursor: not-allowed;
                    opacity: 65%;
                }

                .omega-download-bar-page-title {
                    padding-left: 10px;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgb(129, 127, 127);
                }

                .omega-download-bar-report-title {
                    padding-left: var(--omega-download-bar-report-title-padding, 18px);
                    font-size: 17px;
                    font-weight: 600;
                }
                @media print {
                    .omega-no-print {
                        display: none;
                    }
                }
            `,
        ];
    }
}

customElements.define('omega-download-bar', OmegaDownloadBar);
export default OmegaDownloadBar;
