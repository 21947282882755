import convertStringToPascalCase from './convert-string-to-pascal-case.js';
import { PagingFormatters } from './paging-formatters.js';

const createOrderByColumnSorting = (defaultOrderBy, sortColumns) => {
    if (!defaultOrderBy) {
        console.error(
            'You must pass a default orderby parameter(string) in as the first parameter to this function to create column sorting'
        );
    }
    /*
    Core BSL paging is case sensitive, always pascal. We must transform what we send here to get accurate responses
    */

    sortColumns.splice(1);
    if (PagingFormatters.parseSortColumns(sortColumns).length) {
        return PagingFormatters.parseSortColumns(sortColumns);
    }
    const defaultOrderByInPascalCase = convertStringToPascalCase(defaultOrderBy);
    return [
        {
            Name: `${defaultOrderByInPascalCase}`,
            Descending: false, // default to ascending
        },
    ];
};

const corePagingParameters = (defaultSortColumn, sortColumns, startRowNumber, rowCount) => {
    const paging = createOrderByColumnSorting(defaultSortColumn, sortColumns);
    return {
        OrderBys: paging,
        pageSize: rowCount,
        pageNumber: PagingFormatters.getPageNumber(startRowNumber, rowCount),
    };
};

export default corePagingParameters;
