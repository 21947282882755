RemoveBeneficiaryController.$inject = ['$scope', '$modalInstance', 'wireIsoService'];

export default function RemoveBeneficiaryController($scope, $modalInstance, wireIsoService) {
    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will remove the beneficiary
    $scope.remove = function () {
        $modalInstance.close($scope.comments);
    };

    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });
}
