/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AccountSummaryModelDto,
    CompanyCutoffTimesDto,
    CompanyProductTypeCutoffTimeDto,
} from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class CompanyAccountsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags CompanyAccounts
     * @name CompanyAccountsGetCutoffTimes
     * @summary Returns the company cutoff times.
     * @request GET:/companyaccounts/cutoffTimes
     */
    companyAccountsGetCutoffTimes = (params: RequestParams = {}) =>
        this.http.request<CompanyCutoffTimesDto, any>({
            path: `/companyaccounts/cutoffTimes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyAccounts
     * @name CompanyAccountsGetCutoffTimes2
     * @summary Returns cutoff times for a specific product type.
     * @request GET:/companyaccounts/cutoffTimesByProductType/{productType}
     */
    companyAccountsGetCutoffTimes2 = (productType: string, params: RequestParams = {}) =>
        this.http.request<CompanyProductTypeCutoffTimeDto, any>({
            path: `/companyaccounts/cutoffTimesByProductType/${productType}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyAccounts
     * @name CompanyAccountsGetAccountNicknames
     * @summary Gets All Company Accounts Summaries.
     * @request GET:/companyaccounts/nickNames
     */
    companyAccountsGetAccountNicknames = (params: RequestParams = {}) =>
        this.http.request<AccountSummaryModelDto[], any>({
            path: `/companyaccounts/nickNames`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
