import {
    mapAchTemplatesFieldsToParameters,
    mapRecordValuesToKeyValuePair,
} from '../../mappings/ach/ach-templates.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchTemplateRequests from '../../requests/ach/ach-template-requests.js';
import SecCodesRequests from '../../requests/codes/sec-codes-requests.js';
import DownloadService from '../download/download-service.js';

export default class AchTemplateService {
    static async fetchAchTemplates(body) {
        return AchTemplateRequests.fetchTemplates(mapAchTemplatesFieldsToParameters(body));
    }

    static async fetchCompanies() {
        return AchCompanyRequests.getAchCompanies();
    }

    static async fetchSecCodes() {
        return SecCodesRequests.getSecCodes();
    }

    static async fetchAchSettings() {
        return AchCompanyRequests.getAchSettings();
    }

    static async initiatePaymentsByIds(recordValues) {
        const body = mapRecordValuesToKeyValuePair(recordValues);
        return AchTemplateRequests.initiatePaymentsByIds(body);
    }

    static async deleteTemplateById(id) {
        return AchTemplateRequests.deleteTemplateById(id);
    }

    static async downloadAchTemplates(outputType, filterModel) {
        const mappedDownloadRequestFilters = mapAchTemplatesFieldsToParameters({
            parameters: filterModel,
        });
        return DownloadService.download(
            null,
            outputType,
            'ACHTemplateList',
            { ...mappedDownloadRequestFilters, page: 'ACHTemplateList', taxTemplateType: 3 },
            ['PDF']
        );
    }

    static async isTemplateNameValid(name) {
        return AchTemplateRequests.isTemplateNameValid(name);
    }

    static async getAchTemplateById(id) {
        const response = await AchTemplateRequests.getAchTemplateById(id);
        return response.batch;
    }

    static async createTemplate(payment) {
        return AchTemplateRequests.createTemplate(payment);
    }
}
