﻿angular.module('interpol', [])
  .config(['$provide', function ($provide) {
      $provide.decorator('ngModelDirective', ['$delegate', function ($delegate) {
          const ngModel = $delegate[0]; const {controller} = ngModel;
          ngModel.controller = ['$scope', '$element', '$attrs', '$injector', function (scope, element, attrs, $injector) {
              const $interpolate = $injector.get('$interpolate');
              attrs.$set('name', $interpolate(attrs.name || '')(scope));
              // see https://stackoverflow.com/a/53912419
              $injector.invoke(controller, Object.setPrototypeOf(this, controller.prototype), {
                  '$scope': scope,
                  '$element': element,
                  '$attrs': attrs
              });
          }];
          return $delegate;
      }]);
      $provide.decorator('formDirective', ['$delegate', function ($delegate) {
          const form = $delegate[0]; const {controller} = form;
          form.controller = ['$scope', '$element', '$attrs', '$injector', function (scope, element, attrs, $injector) {
              const $interpolate = $injector.get('$interpolate');
              attrs.$set('name', $interpolate(attrs.name || attrs.ngForm || '')(scope));
              // see https://stackoverflow.com/a/53912419
              $injector.invoke(controller, Object.setPrototypeOf(this, controller.prototype), {
                  '$scope': scope,
                  '$element': element,
                  '$attrs': attrs
              });
          }];
          return $delegate;
      }]);
  }]);