/* eslint-disable no-use-before-define */
/* eslint-disable @treasury/style-includes-host-display */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { TmApiError } from '@treasury/domain/shared';
import { Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { OmegaDialogService } from '@treasury/omega/services';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-template-action-dialog.js';
import AccountReconClient from '../clients/account-recon-client.js';
import { columns, fields, filters } from '../data/ach-account-reconciliation-report-data.js';
import { AccountReconMessage } from '../data/ach-account-reconciliation-report-workflow.js';

class AchAccountReconciliationReportsContainer extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            actions: Object,
            reportActions: Array,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            institution: String,
            reportLinks: Array,
            recordset: Object,
            client: Object,
            showDeleteDialog: Boolean,
            loading: Boolean,
            printing: Boolean,
        };
    }

    constructor() {
        super();
        this.reportLinks = [];
        this.columns = columns;
        this.showDeleteDialog = false;
        this.actions = {
            run: record => this.confirmRunReport(record),
            delete: record => this.openDeleteDialog(record),
            edit: record => this.editReport(record),
        };
        this.pageTitle = 'Report Templates';
        this.rowsPerPage = 25;
    }

    get callToActionText() {
        return 'Create New Report';
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new AccountReconClient();
        }

        const container = await DiContainer.getInstance();
        this.dialogService = container.get(OmegaDialogService);

        this.fields = fields(this.client);
        this.filters = filters(this.client);

        this.recordset = new Recordset(this.fields, this.client.fetchReports);
        this.recordset.setInitialPageSize(this.rowsPerPage);
        this.listenTo(this.recordset, 'error', ({ detail }) => {
            this.alert = {
                ...this.alert,
                type: 'error',
                visible: true,
                message: detail.error.message,
            };
        });
        this.reportLinks = [
            {
                title: 'Report Library',
                route: 'ir.accountReconciliation-library',
            },
        ];
    }

    async createNewReport() {
        return (await getNavService()).navigate('ir.accountReconciliation-workflow', { id: 'new' });
    }

    async confirmRunReport(report) {
        // TODO: we will need conditional logic for a speed bump after API work is completed
        // if (report.values.timePeriod.name === 'AdHoc') {
        //     const { reason } = await this.dialogService.open(
        //         'You are about to run a report that might have been previously posted to your Report Library. An additional billing fee could be incurred by running this report again. Are you sure you would like to proceed?',
        //         'Confirm Run Report',
        //         {
        //             maxWidth: 350,
        //         }
        //     ).closed;

        //     if (reason === OmegaDialogExitReason.Confirm) {
        //         this.runReport(report);
        //     }
        // }
        this.runReport(report);
    }

    async runReport(report) {
        const reportId = report.getField('id');
        this.loading = true;
        try {
            const runPromise = AchAccountReconciliationService.runReport(reportId);
            const displayContent = {
                result: 'Submitted',
                message: AccountReconMessage.SaveAndRunMessage,
            };
            await runPromise;
            setTimeout(() => {
                this.alert = {
                    type: 'success',
                    title: displayContent.result,
                    message: displayContent.message,
                    code: '',
                    time: '',
                    posture: 'polite',
                    visible: true,
                };
            }, 100);
        } catch (e) {
            this.setErrorAlert(e);
        } finally {
            this.loading = false;
        }
    }

    async deleteReport(report) {
        this.alert = {
            ...this.alert,
            visible: false,
        };
        this.loading = true;
        try {
            // const response = await AchAccountReconciliationService.deleteReport(
            //     report.getField('id')
            // );
            const response = await this.client.deleteReport(report.getField('id'));
            console.log(response);
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'success',
                posture: 'polite',
                title: '',
                message: `The report was deleted successfully.`,
            };
            this.recordset.deleteRecord(report);
        } catch (e) {
            this.setErrorAlert(e);
        } finally {
            this.loading = false;
        }
    }

    setErrorAlert(error) {
        if (error instanceof TmApiError) {
            const { message, timestamp: time, errorCode: code } = error;
            this.alert = {
                type: 'warning',
                title: '',
                message,
                code,
                time,
                posture: 'assertive',
                visible: true,
            };
        } else {
            this.alert = {
                type: 'warning',
                title: '',
                message:
                    error instanceof Error ? error.message : 'An error occurred. Please try again.',
                posture: 'assertive',
                visible: true,
            };
        }
    }

    async editReport(reportRecord) {
        return (await getNavService()).navigate('ir.accountReconciliation-workflow', {
            id: reportRecord.getField('id'),
            reportValues: reportRecord.values,
        });
    }

    openDeleteDialog(record) {
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'warning',
            message: 'Are you sure you want to delete this report?',
            title: 'Delete Report',
            posture: 'assertive',
            actions: html`<omega-button type="reject" @click=${() => this.deleteReport(record)}
                    >Yes</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert = {
                            ...this.alert,
                            visible: false,
                        };
                    }}
                    >No</omega-button
                >`,
        };
    }

    renderBlockingLoader() {
        if (this.loading || this.printing) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    render() {
        if (!this.recordset) return nothing;
        return html`
            ${this.renderAlert()} ${this.renderBlockingLoader()}
            <omega-report
                flyout
                autostart
                .title=${this.pageTitle}
                .actions=${this.actions}
                .recordset=${this.recordset}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .columns=${this.columns}
                .options=${[]}
                .downloadOptions=${[]}
                .callToAction=${this.callToActionText}
                @callToAction=${this.createNewReport}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                ></omega-filter-bar
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
            @media print {
                .child-support-templates-container {
                    box-shadow: none;
                    border: 1px solid #ccc;
                }
            }
        `;
    }
}

customElements.define(
    'ach-account-reconciliation-reports-container',
    AchAccountReconciliationReportsContainer
);
export default AchAccountReconciliationReportsContainer;

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
