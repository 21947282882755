CreatePaymentController.$inject = ['$rootScope', '$scope', '$state', 'achBankService'];

export default function CreatePaymentController($rootScope, $scope, $state, achBankService) {
    init();
    async function init() {
        if ($state.params.upload && !$scope.payment) {
            $state.go('payables.ach.payments.create');
        }
        achBankService.getAllBanks().then(response => {
            $rootScope.banks = response;
        });
    }
}
