import { ImportedAchRecipient } from '@treasury/domain/channel/types/ach/api/masterListRecipient.dto';
import { FdlFieldDefinitions } from '@treasury/FDL';
import {
    achAccountNumber,
    achAccountType,
    achIdNumber,
    achPaymentRecipientName,
    addenda,
    transactionType,
} from '@treasury/policy/ach';
import { money } from '@treasury/policy/primitives';
import { achBank } from './ach-recipients-table-data';

export const importedRecipientFields: FdlFieldDefinitions<ImportedAchRecipient> = {
    name: achPaymentRecipientName,
    idNumber: achIdNumber,
    accountNumber: achAccountNumber,
    accountType: achAccountType.and.minColumnWidth(200),
    routingNumber: achBank() as any,
    transactionType: transactionType.with.options([
        { text: 'Credit', value: 'CR' },
        { text: 'Debit', value: 'DR' },
    ]),
    amount: money,
    addenda,
};

export const importedRecipientColumns = [
    {
        label: 'Recipient Name',
        field: 'name',
        type: 'command',
        action: 'selectRecipient',
    },
    {
        label: 'ID Number',
        field: 'idNumber',
    },
    {
        label: 'Account Number',
        field: 'accountNumber',
    },
    {
        label: 'Account Type',
        field: 'accountType',
    },
    {
        label: 'Routing Number',
        field: 'routingNumber',
    },
    {
        label: 'Transaction Type',
        field: 'transactionType',
    },
    {
        label: 'Default Amount',
        field: 'amount',
    },
    {
        label: '',
        type: 'actions',
        actions: [
            {
                label: 'Addenda',
                action: 'openAddendaDialog',
            },
        ],
    },
    {
        label: '',
        type: 'edit-row',
        options: [{ action: 'delete' }],
    },
];
