import { getChannelAPIPath } from '@treasury/core/http';

StatesService.$inject = ['$resource', '$q'];

export default function StatesService($resource, $q) {
    const resourceUrl = `${getChannelAPIPath()}states/`;
    return {
        getAll,
    };

    function getAll() {
        return $resource(resourceUrl).query().$promise;
    }
}
