import { CurrentUserTimeAccessDto } from '@treasury/api/bo';
import {
    DayOfWeekDto,
    TimeAccessDto,
    TimeAccessTypeDto,
    UserTimeAccessDayDto,
} from '@treasury/api/channel';

/**
 * This mapper reconciles the differences between the Channel and Backoffice time access settings response objects.
 */
export function mapUserTimeAccess(userTimeAccess: CurrentUserTimeAccessDto): TimeAccessDto {
    const { timeRestricted, userTimeAccessDays } = userTimeAccess;

    return {
        timeRestricted,
        userTimeAccessDays: (userTimeAccessDays || []).map(day => {
            const { dayOfWeekId, userTimeAccessTypeId } = day;

            return {
                beginTime: day.startTime.split('T').pop() ?? '',
                dayOfWeek: dayOfWeekId,
                endTime: day.endTime.split('T').pop() ?? '',
                timeAccessType: userTimeAccessTypeId,
            };
        }),
    };
}

export function createDefaultTimeAccess(): UserTimeAccessDayDto {
    return {
        beginTime: '',
        dayOfWeek: DayOfWeekDto.Sunday,
        endTime: '',
        timeAccessType: TimeAccessTypeDto.Undefined,
    };
}
