import { exists, isNonEmptyString } from '@treasury/utils';

/**
 * @typedef { ReturnType<import('../../../../services/internalTransfersSrvc.js').InternalTransfersService> InternalTransfersService}
 */

InternalTransferController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'toaster',
    '$timeout',
    '$uibModal',
    'companyAccountsService',
    'internalTransfersService',
    'utilityService',
    'modalService',
    'holidaysService',
    'frequencyService',
    'securityService',
    '$rootScope',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'dateConstants',
    '$filter',
];

/**
 * @param { ReturnType<import('../../../../services/securitySrvc.js').default>} securityService
 * @param { InternalTransfersService} internalTransfersService
 */
export default function InternalTransferController(
    $scope,
    $state,
    $stateParams,
    toaster,
    $timeout,
    $uibModal,
    companyAccountsService,
    internalTransfersService,
    utilityService,
    modalService,
    holidaysService,
    frequencyService,
    securityService,
    $rootScope,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    dateConstants,
    $filter
) {
    const fromAccountKey = 'fromAccount';
    const toAccountKey = 'toAccount';
    $scope.accounts = [];
    $scope.currentDate = moment().format('MM/DD/YYYY');
    $scope.endOnDate = moment().format('MM/DD/YYYY');
    $scope.totalDebitAmount = 0;
    $scope.frequencies = frequencyService.getFrequencies();
    $scope.weekDays = frequencyService.getWeekdays();
    $scope.numbers = dateConstants.daysInMonth;
    $scope.wizardController = {};
    $scope.downloadPageId = downloadPageId.InternalTransferConfirmation;
    $scope.getFromAccounts = getFromAccounts;
    $scope.getToAccounts = getToAccounts;
    $scope.getManyToManyFromAccounts = getManyToManyFromAccounts;
    $scope.getManyToManyToAccounts = getManyToManyToAccounts;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.transferHeaderPanel = {
        header: '',
        createHeader: '',
        reviewHeader: '',
        confirmheader: '',
    };
    $scope.wizardPanel = { stepOneText: '' };
    $scope.transfers = { templateName: '', templateId: null };
    $scope.templateId = $stateParams.id;
    $scope.transfers.templateId = $scope.templateId;
    $scope.type = $stateParams.type;
    $scope.createTemplateMode = $stateParams.createTemplateMode;
    $scope.editTemplateMode = $scope.templateId && $scope.type === 'Edit';
    $scope.accountCtrlDisable = accountCtrlDisable;
    $scope.createtTransferFromTemplate = createtTransferFromTemplate;
    $scope.validateTemplateName = validateTemplateName;
    $scope.isTemplateNameValid = isTemplateNameValid;
    $scope.loadFromAccounts = loadFromAccounts;
    $scope.loadToAccounts = loadToAccounts;
    $scope.updateToAccounts = updateToAccounts;
    $scope.updateFromAccounts = updateFromAccounts;
    $scope.updateAccounts = updateAccounts;
    $scope.showModalFromAccount = showModalFromAccount;
    $scope.showModalToAccount = showModalToAccount;
    $scope.displayFieldFilter = displayFieldFilter;
    $scope.typeAheadFromAccountBlur = typeAheadFromAccountBlur;
    $scope.typeAheadToAccountBlur = typeAheadToAccountBlur;
    $scope.displayFromBalance = displayFromBalance;
    $scope.displayToBalance = displayToBalance;
    $scope.getBalanceDisplayForToAccount = getBalanceDisplayForToAccount;
    $scope.fromAccount = null;
    $scope.toAccount = null;
    $scope.isSaving = false;
    $scope.hasErrors = false;

    function displayFromBalance(transferTransaction) {
        return (
            transferTransaction != null &&
            transferTransaction.fromAccount != null &&
            transferTransaction.fromAccount.availableBalance != null
        );
    }

    function displayToBalance(transferTransaction) {
        return (
            transferTransaction != null &&
            transferTransaction.toAccount != null &&
            transferTransaction.toAccount.availableBalance != null
        );
    }

    function createtTransferFromTemplate() {
        const templates = [];
        templates.push($scope.templateForTransfer);
        internalTransfersService.setSelectedTemplates(templates);
        $state.reload();
    }

    function accountCtrlDisable(account) {
        return !$scope.isObject(account) || $scope.createTransferFromTemplateMode;
    }

    function getFromAccounts() {
        return $scope.filteredFromAccounts || $scope.accountListFrom;
    }

    function getToAccounts() {
        return $scope.filteredToAccounts || $scope.accountListTo;
    }

    function getManyToManyFromAccounts(index) {
        return $scope.filteredManyToManyFromAccounts[index] || $scope.accountListFrom;
    }

    function getManyToManyToAccounts(index) {
        return $scope.filteredManyToManyToAccounts[index] || $scope.accountListTo;
    }

    function setAccounts(accounts, index, isFromAccount) {
        if (isFromAccount) {
            $scope.filteredManyToManyToAccounts[index] = accounts;
        } else {
            $scope.filteredManyToManyFromAccounts[index] = accounts;
        }
    }

    function updateAccounts(account, index, isFromAccount) {
        if (angular.isObject(account)) {
            companyAccountsService
                .getCompanyAccountsForProductFeature(
                    isFromAccount ? 'From' : 'To',
                    getProductFeature(),
                    'create',
                    account.id,
                    false
                )
                .then(response => {
                    setAccountDisplayField(response);
                    setAccounts(response, index, isFromAccount);
                });
        } else {
            setAccounts(null, index, isFromAccount);
        }
    }

    function updateToAccounts(fromAccount, shouldRemoveInvalidAccounts) {
        if (angular.isObject(fromAccount)) {
            loadToAccounts(fromAccount.id).then(() => {
                removeInvalidToAccount(shouldRemoveInvalidAccounts);
            });
        } else {
            $scope.filteredToAccounts = null;
            removeInvalidToAccount(shouldRemoveInvalidAccounts);
        }
    }

    function updateFromAccounts(toAccount, shouldRemoveInvalidAccounts) {
        if (angular.isObject(toAccount)) {
            loadFromAccounts(toAccount.id).then(() => {
                removeInvalidFromAccount(shouldRemoveInvalidAccounts);
            });
        } else {
            $scope.filteredFromAccounts = null;
            removeInvalidFromAccount(shouldRemoveInvalidAccounts);
        }
    }

    function clearErrors() {
        $scope.errorMessage = '';
        angular.forEach($scope.transaction.transactions, transaction => {
            transaction.balanceErrorMessage = '';
            transaction.fromAccountErrorMessage = '';
        });
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function calculateNextBusinessDay() {
        if ($scope.transaction === undefined) return;
        if (
            $scope.transaction.type === 'one' ||
            $scope.transaction.type === 'oneToMany' ||
            $scope.transaction.type === 'manyToOne'
        ) {
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                .then(response => {
                    $scope.currentDate = formatDate(response);
                    $scope.transaction.transactions[0].transferDate = formatDate(response);
                });
        } else {
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                .then(response => {
                    $scope.currentDate = formatDate(response);
                    angular.forEach($scope.transaction.transactions, transaction => {
                        if (
                            transaction.transferDate === null ||
                            transaction.transferDate === undefined
                        ) {
                            transaction.transferDate = formatDate(response);
                        }
                    });
                });
        }
    }

    function recalculateDebitAmount() {
        let result = 0;
        angular.forEach($scope.transaction.transactions, transaction => {
            if (transaction.amount !== null && transaction.amount !== undefined) {
                result += transaction.amount;
            }
        });
        $scope.totalDebitAmount = result;
    }

    function initializeTransaction() {
        $scope.transaction = {
            type: 'one',
            templateName: null,
            transactions: [
                {
                    fromAccount: null,
                    toAccount: null,
                    amount: null,
                    transferDate: $scope.currentDate,
                    memo: null,
                    frequency: { type: 'One Time' },
                },
            ],
        };
    }

    function resetEditTemplateMode() {
        loadTemplate();
        initializeAccounts();
        getTransferTemplateDetails($scope.templateId, 'edit');
        getHolidays();
        loadCutoffTimes();
        getTransferHeaderPanelText();
    }

    function resetCreateTransferFromTemplateMode() {
        internalTransfersService.setSelectedTemplates([$scope.templateForTransfer]);
        loadTemplate();
        initializeAccounts();
        getHolidays();
        loadCutoffTimes();
        getTransferHeaderPanelText();
    }

    $scope.restart = function () {
        initializeTransaction();
        initializeAccounts();
        $scope.wizardController.goToStep(0);
    };

    $scope.viewScheduledTransfers = function () {
        $state.go('payables.transferList');
    };

    $scope.viewTemplates = function () {
        $state.go('payables.transferTemplatesList');
    };

    $scope.createTemplate = function (reload) {
        reload === true ? $state.reload() : $state.go('payables.createtransfertemplate');
    };

    $scope.reset = function () {
        clearErrors();
        if ($scope.editTemplateMode) {
            resetEditTemplateMode();
        } else if ($scope.createTransferFromTemplateMode) {
            resetCreateTransferFromTemplateMode();
        } else {
            $scope.filteredFromAccounts = null;
            $scope.filteredToAccounts = null;
            initializeTransaction();
            $scope.transfers = { templateName: '', templateId: null };
            if ($scope.transaction.type === 'many') {
                $scope.transaction.transactions.push({});
            } else if (
                $scope.transaction.type === 'oneToMany' ||
                $scope.transaction.type === 'manyToOne'
            ) {
                $scope.transaction.transactions.push({});
                $scope.transaction.transactions.push({});
            }
            calculateNextBusinessDay();
            $scope.form.$setPristine();
        }
    };

    $scope.isNegative = function (value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    };

    $scope.gotoList = function () {
        $state.go('payables.transferList');
    };

    $scope.gotoRecurringList = function () {
        $state.go('payables.recurringTransferList');
    };

    $scope.canReset = function () {
        return $scope.form.$dirty;
    };

    $scope.canReview = function () {
        return !$scope.form.$invalid && $scope.isValidAmounts() === true;
    };

    $scope.isReviewStep = function () {
        return $scope.wizardController.currentStep().name === 'Review';
    };

    $scope.isConfirmStep = function () {
        return $scope.wizardController.currentStep().name === 'Confirmation';
    };

    $scope.isConfirmButtonDisabled = function () {
        return $scope.isSaving;
    };

    $scope.review = function () {
        clearErrors();
        recalculateDebitAmount();
        $scope.wizardController.goToNextStep();
    };

    $scope.confirm = function () {
        $scope.wizardController.goToNextStep();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.isObject = function (value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    };

    $scope.dirtyState = function (type) {
        const modalOptions = {
            size: 'sm',
            headerText: 'Confirm Cancellation',
            actionButtonText: 'Cancel Edits',
            closeButtonText: 'Close',
            bodyText:
                'You have changes on the page, are you sure you would like to leave the page without saving?',
            submit(result) {
                $scope.changeTransactionType(type, true);
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    };

    $scope.changeTransactionType = function (type, alreadyAskedDirty) {
        $scope.fromAccountErrorMessage = '';
        $scope.balanceErrorMessage = '';

        if (
            alreadyAskedDirty === null ||
            alreadyAskedDirty === undefined ||
            alreadyAskedDirty === false
        ) {
            $scope.dirtyState(type);
            return;
        }
        if (type === 'one') {
            initializeTransaction();
        } else {
            if (type === 'oneToMany' || type === 'manyToOne') {
                $scope.transaction = {
                    type,
                    transactions: [
                        {
                            fromAccount: null,
                            toAccount: null,
                            amount: null,
                            transferDate: $scope.currentDate,
                            memo: null,
                        },
                    ],
                };
            }
            if ($scope.transaction.transactions.length === 1 && type === 'many') {
                $scope.transaction.transactions.push({});
            } else if (
                $scope.transaction.transactions.length === 1 &&
                (type === 'oneToMany' || type === 'manyToOne')
            ) {
                $scope.transaction.transactions.push({});
                $scope.transaction.transactions.push({});
            }
        }

        $scope.transaction.type = type;

        if (type !== 'many') {
            $scope.filteredFromAccounts = null;
            $scope.filteredToAccounts = null;
        } else {
            $scope.filteredManyToManyFromAccounts = [];
            $scope.filteredManyToManyToAccounts = [];

            $scope.filteredManyToManyFromAccounts.push($scope.filteredFromAccounts);
            $scope.filteredManyToManyToAccounts.push($scope.filteredToAccounts);
        }

        $scope.transfers = { templateName: '', templateId: null };
        clearErrors();
        $scope.form.$setPristine();
        $scope.form.$setUntouched();
        calculateNextBusinessDay();
    };

    $scope.isValidAmounts = function () {
        let result = true;
        let index = 0;
        if ($scope.transaction === undefined) return;
        if ($scope.transaction.type === 'oneToMany') {
            if (
                $scope.transaction.transactions[0].fromAccount === null ||
                $scope.transaction.transactions[0].fromAccount === undefined ||
                typeof $scope.transaction.transactions[0].fromAccount !== 'object'
            ) {
                result = false;
            } else {
                angular.forEach($scope.transaction.transactions, transaction => {
                    if (index > 0) {
                        if (
                            transaction.toAccount === null ||
                            transaction.toAccount === undefined ||
                            typeof transaction.toAccount !== 'object' ||
                            isNaN(transaction.amount)
                        ) {
                            result = false;
                        }
                    }
                    index++;
                });
            }
        } else if ($scope.transaction.type === 'manyToOne') {
            if (
                $scope.transaction.transactions[0].toAccount === null ||
                $scope.transaction.transactions[0].toAccount === undefined ||
                typeof $scope.transaction.transactions[0].toAccount !== 'object'
            ) {
                result = false;
            } else {
                angular.forEach($scope.transaction.transactions, transaction => {
                    if (index > 0) {
                        if (
                            transaction.fromAccount === null ||
                            transaction.fromAccount === undefined ||
                            typeof transaction.fromAccount !== 'object' ||
                            isNaN(transaction.amount)
                        ) {
                            result = false;
                        }
                    }
                    index++;
                });
            }
        } else if ($scope.transaction.type === 'many') {
            angular.forEach($scope.transaction.transactions, transaction => {
                if (
                    transaction.fromAccount === null ||
                    transaction.fromAccount === undefined ||
                    typeof transaction.fromAccount !== 'object' ||
                    transaction.toAccount === null ||
                    transaction.toAccount === undefined ||
                    typeof transaction.toAccount !== 'object' ||
                    isNaN(transaction.amount)
                ) {
                    result = false;
                }
            });
        } else if ($scope.transaction.type === 'one') {
            if (
                $scope.transaction.transactions[0].fromAccount === null ||
                $scope.transaction.transactions[0].fromAccount === undefined ||
                typeof $scope.transaction.transactions[0].fromAccount !== 'object' ||
                $scope.transaction.transactions[0].toAccount === null ||
                $scope.transaction.transactions[0].toAccount === undefined ||
                typeof $scope.transaction.transactions[0].toAccount !== 'object' ||
                isNaN($scope.transaction.transactions[0].amount)
            ) {
                result = false;
            }
        }
        return result;
    };

    $scope.changeEdit = function (item) {
        if (item.isEdit === undefined || item.isEdit === false) {
            item.isEdit = true;
        } else {
            item.isEdit = !item.isEdit;
        }
    };

    $scope.$watch(
        'transaction.transactions',
        () => {
            if ($scope.transaction === undefined) return;
            if (
                $scope.transaction.type === 'oneToMany' ||
                $scope.transaction.type === 'many' ||
                $scope.transaction.type === 'manyToOne'
            ) {
                recalculateDebitAmount();
            }
        },
        true
    );

    $scope.$watch('transaction.transactions[0].frequency.type', (newValue, oldValue) => {
        if (newValue !== oldValue && !!$scope.transaction.transactions[0].frequency) {
            $scope.transaction.transactions[0].frequency.type = newValue;
            $scope.transaction.transactions[0].frequency.startOn = null;
            $scope.transaction.transactions[0].frequency.endOn = null;
            $scope.transaction.transactions[0].frequency.valueDate = null;
            $scope.transaction.transactions[0].frequency.noEndDate = false;
            $scope.transaction.transactions[0].frequency.repeatOnDay1 = '';
            $scope.transaction.transactions[0].frequency.repeatOnDay2 = '';
            $scope.transaction.transactions[0].frequency.repeatOnLastBusinessDay = false;
        }
    });

    $scope.removeTransfer = function (index, type) {
        if (type === 'many') {
            $scope.filteredManyToManyFromAccounts.splice(index, 1);
            $scope.filteredManyToManyToAccounts.splice(index, 1);
            $scope.transaction.transactions.splice(index, 1);

            if ($scope.transaction.transactions.length === 0) {
                $scope.transaction.transactions.push({});
            }
        } else if (type === 'oneToMany' || type === 'manyToOne') {
            $scope.transaction.transactions.splice(index, 1);
            if ($scope.transaction.transactions.length === 1) {
                $scope.transaction.transactions.push({});
            }
        }
    };

    $scope.addManyTransfer = function (type) {
        $scope.transaction.transactions.push({});
        if (type !== 'oneToMany' && type !== 'manyToOne') {
            calculateNextBusinessDay();
        }
    };

    function showModalFromAccount(index, shouldUpdateToAccounts, removeInvalidAccounts) {
        let accounts;
        const { type } = $scope.transaction;

        if (type === 'many') {
            accounts = getManyToManyFromAccounts(index);
        } else {
            accounts = getFromAccounts();
        }

        showAccountModal(accounts, false).result.then(selectedAccount => {
            $scope.form.$dirty = true;
            const transaction = $scope.transaction.transactions[index];
            transaction.fromAccount = selectedAccount;

            if (
                transaction.fromAccountErrorMessage != null &&
                transaction.fromAccountErrorMessage.length > 0
            ) {
                clearErrors();
                transaction.fromAccountErrorMessage = '';
            }

            if (shouldUpdateToAccounts) {
                if (type === 'many') {
                    updateAccounts(selectedAccount, index, true);
                } else {
                    updateToAccounts(selectedAccount, removeInvalidAccounts);
                }
            }
        });
    }

    function showModalToAccount(index, shouldUpdateFromAccount, removeInvalidAccounts) {
        let accounts;
        const { type } = $scope.transaction;

        if (type === 'many') {
            accounts = getManyToManyToAccounts(index);
        } else {
            accounts = getToAccounts();
        }

        showAccountModal(accounts, true).result.then(selectedAccount => {
            $scope.form.$dirty = true;
            $scope.transaction.transactions[index].toAccount = selectedAccount;

            if (shouldUpdateFromAccount) {
                if ($scope.transaction.type === 'many') {
                    updateAccounts(selectedAccount, index, false);
                } else {
                    updateFromAccounts(selectedAccount, removeInvalidAccounts);
                }
            }
        });
    }

    function showAccountModal(accounts, isDestinationAccounts) {
        return $uibModal.open({
            template: require('../../../../dashboard/widgets/views/transferAccountSelectionModal.html'),
            size: 'md',
            controller: 'AccountSelectionController',
            backdrop: 'static',
            resolve: {
                accountsList() {
                    return accounts;
                },
                showCurrentBalanceForLoanAccounts() {
                    return isDestinationAccounts;
                },
            },
        });
    }

    function assignErrorsToTransactions(response) {
        const responseTransactions = response.transactions;
        if (!responseTransactions) return;
        if ($scope.transaction.type === 'one' || $scope.transaction.type === 'many') {
            for (var i = 0; i < responseTransactions.length; i++) {
                $scope.transaction.transactions[i].balanceErrorMessage =
                    responseTransactions[i].balanceErrorMessage;
                $scope.transaction.transactions[i].fromAccountErrorMessage =
                    responseTransactions[i].fromAccountErrorMessage;
            }
        } else {
            for (var i = 1; i < responseTransactions.length; i++) {
                $scope.transaction.transactions[i].balanceErrorMessage =
                    responseTransactions[i].balanceErrorMessage;
                $scope.transaction.transactions[i].fromAccountErrorMessage =
                    responseTransactions[i].fromAccountErrorMessage;
            }
        }
    }

    $scope.save = function () {
        $scope.isSaving = true;
        $scope.hasErrors = false;
        if (!$scope.createTemplateMode && !$scope.editTemplateMode) {
            let actionType = 'CreateTransfer_ByName';
            if ($scope.transaction.type === 'one') {
                if ($scope.transaction.transactions[0].frequency.type === 'One Time') {
                    actionType = 'Create One Time Transfer';
                } else {
                    actionType = 'Create Recurring Transfer';
                }
            } else if ($scope.transaction.type === 'oneToMany') {
                actionType = 'Create One to Many Transfer';
            } else if ($scope.transaction.type === 'manyToOne') {
                actionType = 'Create Many to One Transfer';
            } else if ($scope.transaction.type === 'many') {
                actionType = 'Create Many to Many Transfer';
            }

            securityService
                .verifyUser(actionType, $scope.transaction, () =>
                    internalTransfersService.save($scope.transaction, 'transfer')
                )
                .then(response => {
                    const { batchId, errorSummary } = response;
                    angular.forEach(response.transactions, transaction => {
                        if (transaction.errorSummary !== null) {
                            $scope.hasErrors = true;
                        }
                    });

                    // Add summary message.
                    if (exists(errorSummary) && isNonEmptyString(errorSummary.summaryMessage)) {
                        displayErrors(response);
                    } else {
                        $scope.transaction.batchId = batchId;
                        updateTransactionIds(response);
                        clearErrors();
                        getTransferHeaderPanelText();
                        $scope.confirm();
                        $scope.filterModel = { batchId };
                        $scope.filteredFromAccounts = null;
                        $scope.filteredToAccounts = null;
                    }
                })
                .finally(() => {
                    $scope.isSaving = false;
                });
        } // this save is for transfer templates
        else {
            $scope.transaction.templateName = $scope.transfers.templateName;
            if ($scope.createTemplateMode && !$scope.editTemplateMode) {
                internalTransfersService
                    .saveTransferTemplate($scope.transaction)
                    .then(response => {
                        handleResponse(response);
                    })
                    .finally(() => {
                        $scope.isSaving = false;
                    });
            } else if ($scope.editTemplateMode) {
                // update
                $scope.transaction.templateId = $scope.transfers.templateId;
                internalTransfersService
                    .updateTransferTemplate($scope.transaction)
                    .then(response => {
                        handleResponse(response);
                    })
                    .finally(() => {
                        $scope.isSaving = false;
                    });
            }
        }
    };

    function handleResponse(response) {
        const { errorSummary } = response;
        if (exists(errorSummary) && isNonEmptyString(errorSummary.summaryMessage)) {
            displayErrors(response);
        } else {
            updateTransactionIds(response);
            clearErrors();
            getTransferHeaderPanelText();
            $scope.confirm();
        }
    }

    function assembleResponseErrors(response) {
        angular.forEach(response.transactions, transaction => {
            if (
                transaction.errorSummary !== null &&
                transaction.errorSummary !== undefined &&
                transaction.errorSummary.details !== null &&
                transaction.errorSummary.details !== undefined &&
                transaction.errorSummary.details.length > 0
            ) {
                angular.forEach(transaction.errorSummary.details, item => {
                    if (item.attributeName === fromAccountKey) {
                        if (
                            item.messageList !== null &&
                            item.messageList !== undefined &&
                            item.messageList.length > 0
                        ) {
                            if (
                                transaction.fromAccountErrorMessage === null ||
                                transaction.fromAccountErrorMessage === undefined
                            ) {
                                transaction.fromAccountErrorMessage = '';
                            }
                            angular.forEach(item.messageList, message => {
                                transaction.fromAccountErrorMessage += `${message.value}, `;
                            });
                            transaction.fromAccountErrorMessage =
                                transaction.fromAccountErrorMessage.substring(
                                    0,
                                    transaction.fromAccountErrorMessage.length - 2
                                );
                        } else {
                            transaction.fromAccountErrorMessage = `${item.message}, `;
                            transaction.fromAccountErrorMessage =
                                transaction.fromAccountErrorMessage.substring(
                                    0,
                                    transaction.fromAccountErrorMessage.length - 2
                                );
                        }
                    } else if (item.attributeName === 'balance') {
                        if (
                            item.messageList !== null &&
                            item.messageList !== undefined &&
                            item.messageList.length > 0
                        ) {
                            if (
                                transaction.balanceErrorMessage === null ||
                                transaction.balanceErrorMessage === undefined
                            ) {
                                transaction.balanceErrorMessage = '';
                            }
                            angular.forEach(item.messageList, message => {
                                transaction.balanceErrorMessage += `${message.value}, `;
                            });
                            transaction.balanceErrorMessage =
                                transaction.balanceErrorMessage.substring(
                                    0,
                                    transaction.balanceErrorMessage.length - 2
                                );
                        } else {
                            transaction.balanceErrorMessage = `${item.message}, `;
                            transaction.balanceErrorMessage =
                                transaction.balanceErrorMessage.substring(
                                    0,
                                    transaction.balanceErrorMessage.length - 2
                                );
                        }
                    }
                });
            }
        });
    }

    function displayErrors(response) {
        // Clear any previous errors
        clearErrors();

        $scope.errorMessage = response.errorSummary.summaryMessage;

        assembleResponseErrors(response);
        assignErrorsToTransactions(response);

        // Return user to edit form
        $scope.wizardController.goToStep(0);
        $scope.form.$setDirty();
    }

    function updateTransactionIds(response) {
        const responseTransactions = response.transactions;
        if ($scope.transaction.type === 'one' || $scope.transaction.type === 'many') {
            for (var i = 0; i < responseTransactions.length; i++) {
                $scope.transaction.transactions[i].id = responseTransactions[i].id;
                $scope.transaction.transactions[i].transactionId =
                    responseTransactions[i].transactionId;
                $scope.transaction.transactions[i].status = responseTransactions[i].status;
            }
        } else {
            for (var i = 0; i < responseTransactions.length; i++) {
                $scope.transaction.transactions[i + 1].id = responseTransactions[i].id;
                $scope.transaction.transactions[i + 1].transactionId =
                    responseTransactions[i].transactionId;
                $scope.transaction.transactions[i + 1].status = responseTransactions[i].status;
            }
        }
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit() {
                activityService.userInteractionAudit(
                    resourceType.PaymentResources,
                    auditCode.CancelCreateTransfer
                );
                $modalInstance.close();
                goBack();
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    $scope.cancel = function () {
        if (
            ($scope.form && $scope.form.$dirty) ||
            $scope.wizardController.currentStep().name === 'Review' ||
            !!$scope.errorMessage
        ) {
            confirmCancel();
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateTransfer
            );
            goBack();
        }
    };

    function goBack() {
        if ($rootScope.previousState.name === 'payables.recurringTransferList') {
            $state.go('payables.recurringTransferList');
        } else if ($rootScope.previousState.name === 'payables.transferList') {
            $state.go('payables.transferList');
        } else if (
            $scope.transaction.type !== 'one' &&
            !$scope.createTemplateMode &&
            !$scope.createTransferFromTemplateMode &&
            !$scope.editTemplateMode
        ) {
            $state.go('payables.recurringTransferList');
        } else if ($scope.createTemplateMode || $scope.editTemplateMode) {
            $state.go('payables.transferTemplatesList');
        } else {
            $state.go('payables.transferList');
        }
    }

    function loadFromAccounts(toAccountId) {
        return companyAccountsService
            .getCompanyAccountsForProductFeature('From', getProductFeature(), 'create', toAccountId)
            .then(response => {
                setAccountDisplayField(response);
                if (toAccountId) {
                    $scope.filteredFromAccounts = response;
                } else {
                    $scope.accountListFrom = response;
                }
            });
    }

    function loadToAccounts(fromAccountId) {
        return companyAccountsService
            .getCompanyAccountsForProductFeature(
                'To',
                getProductFeature(),
                'create',
                fromAccountId,
                false
            )
            .then(response => {
                setAccountDisplayField(response);
                if (fromAccountId) {
                    $scope.filteredToAccounts = response;
                } else {
                    $scope.accountListTo = response;
                }
            });
    }

    function setAccountDisplayField(response) {
        if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
            $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
        }
    }

    function getProductFeature() {
        return !$scope.createTemplateMode ? 'InternalTransfer' : 'InternalTransferTemplate';
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);

            calculateNextBusinessDay();
        });
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('InternalTransfer').then(response => {
            $scope.processingCutoff = response;
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableToday = true;

                if (timeout > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create transfer for today.');

                    for (var i = 0; i < $scope.transaction.transactions.length; i++) {
                        if (
                            moment($scope.transaction.transactions[i].transferDate).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    $scope.transaction.transactions[i].transferDate =
                                        formatDate(response);
                                });
                        }
                        if (
                            moment($scope.transaction.transactions[i].frequency.startOn).format(
                                'L'
                            ) === moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    $scope.transaction.transactions[i].frequency.startOn =
                                        formatDate(response);
                                });
                        }
                        if (
                            moment($scope.transaction.transactions[i].frequency.endOn).format(
                                'L'
                            ) === moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    $scope.transaction.transactions[i].frequency.endOn =
                                        formatDate(response);
                                });
                        }
                    }
                } else {
                    calculateNextBusinessDay();
                }
            }, timeout);
        });
    }

    function init() {
        loadTemplate();

        if (isNotTemplate()) {
            initializeTransaction();
            if ($stateParams.amount != null) {
                $scope.transaction.transactions[0].amount = $stateParams.amount;
            }
        }

        initializeAccounts();

        if ($scope.editTemplateMode) {
            getTransferTemplateDetails($scope.templateId, 'edit');
        }

        getHolidays();
        loadCutoffTimes();
        getTransferHeaderPanelText();
        if ($scope.createTemplateMode) {
            $scope.pristine = angular.copy($scope.transfers);
        }
    }

    function initializeAccounts() {
        const fromAccountIdParam = tryParseInt($stateParams.fromAccountId, 0);
        const toAccountIdParam = tryParseInt($stateParams.toAccountId, 0);

        loadFromAccounts().then(() => {
            if (isNotTemplate() && fromAccountIdParam !== 0) {
                angular.forEach($scope.accountListFrom, fromAccount => {
                    if (fromAccount.id === fromAccountIdParam) {
                        $scope.transaction.transactions[0].fromAccount = fromAccount;
                    }
                });
            }
        });

        loadToAccounts().then(() => {
            if (isNotTemplate() && toAccountIdParam !== 0) {
                angular.forEach($scope.accountListTo, toAccount => {
                    if (toAccount.id === toAccountIdParam) {
                        $scope.transaction.transactions[0].toAccount = toAccount;
                    }
                });
            }
        });
    }

    function tryParseInt(stringValue, defaultValue) {
        const parsed = parseInt(stringValue);
        if (isNaN(parsed)) {
            return defaultValue;
        }
        return parsed;
    }

    function isNotTemplate() {
        return !$scope.editTemplateMode && !$scope.createTransferFromTemplateMode;
    }

    function getTransferHeaderPanelText() {
        if (
            !$scope.createTemplateMode &&
            !$scope.editTemplateMode &&
            !$scope.createTransferFromTemplateMode
        ) {
            $scope.wizardPanel.stepOneText = 'Create Transfer';
            $scope.transferHeaderPanel.header = 'Internal Transfer';
            $scope.transferHeaderPanel.createHeader = 'Create Transfer';
            $scope.transferHeaderPanel.confirmHeader = 'Internal Transfer Confirmation';
            $scope.transferHeaderPanel.reviewHeader = 'Transfer Detail';
        } else if ($scope.createTemplateMode) {
            $scope.wizardPanel.stepOneText = 'Create Template';
            $scope.transferHeaderPanel.header = 'Transfer Template Details';
            $scope.transferHeaderPanel.createHeader = 'Create Transfer Template';
            $scope.transferHeaderPanel.confirmHeader = 'Internal Transfer Template Confirmation';
            $scope.transferHeaderPanel.reviewHeader = 'Transfer Template Detail';
        } else if ($scope.createTransferFromTemplateMode) {
            $scope.wizardPanel.stepOneText = 'Create Transfer';
            $scope.transferHeaderPanel.header = 'Transfer Details';
            $scope.transferHeaderPanel.createHeader = 'Create Transfer from Template';
            $scope.transferHeaderPanel.confirmHeader = 'Internal Transfer Confirmation';
            $scope.transferHeaderPanel.reviewHeader = 'Transfer Detail';
        } else if ($scope.editTemplateMode) {
            $scope.wizardPanel.stepOneText = 'Edit Template';
            $scope.transferHeaderPanel.header = 'Transfer Template Details';
            $scope.transferHeaderPanel.createHeader = 'Edit Transfer Template';
            $scope.transferHeaderPanel.confirmHeader = 'Internal Transfer Template Confirmation';
            $scope.transferHeaderPanel.reviewHeader = 'Transfer Template Detail';
        }
    }

    function loadTemplate() {
        const templates = internalTransfersService.getSelectedTemplates();
        if (templates !== null && templates !== undefined && templates.length > 0) {
            $scope.templateForTransfer = templates[0];
            $scope.createTransferFromTemplateMode = !!$scope.templateForTransfer;
            internalTransfersService.setSelectedTemplates([]);
            getTransferTemplateDetails($scope.templateForTransfer.id, 'initiate');
        }
    }

    function isTemplateNameValid() {
        return (
            !!$scope.transfers &&
            !!$scope.transfers.templateName &&
            !!$scope.form &&
            $scope.form.ctrltemplatename.$valid
        );
    }

    function validateTemplateName() {
        $scope.form.ctrltemplatename.$setValidity('unique', true);
        if ($scope.transfers.templateName !== $scope.pristine.templateName) {
            internalTransfersService
                .validateTemplateName($scope.transfers.templateName)
                .then(resp => {
                    if (resp.isValid) {
                        $scope.form.ctrltemplatename.$setValidity('unique', true);
                    } else {
                        $scope.form.ctrltemplatename.$setValidity('unique', false);
                    }
                });
        }
    }

    function getTransferTemplateDetails(templateId, type) {
        internalTransfersService.getTransferTemplateDetails(templateId, type).then(response => {
            $scope.transaction = response;
            $scope.transfers.templateName = $scope.transaction.templateName;
            if ($scope.transaction.type === 'one') {
                $scope.transaction.transactions[0].frequency = { type: 'One Time' };
            }
            calculateNextBusinessDay();
            $scope.transaction.transactions[0].transferDate = $scope.currentDate;
            if (type === 'edit') {
                $scope.pristine = angular.copy($scope.transfers);
            }
        });
    }

    $scope.getMaximumDate = function (startDate, frequencyType) {
        $scope.endOnDate = frequencyService.getMaximumDate(startDate, frequencyType);
        return frequencyService.getMaximumDate(startDate, frequencyType);
    };

    $scope.onChange = function (e) {
        if (!isNaN(Date.parse($scope.transaction.transactions[0].frequency.startOn))) {
            const dt = new Date($scope.transaction.transactions[0].frequency.startOn);

            if ($scope.transaction.transactions[0].frequency.type === 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.transaction.transactions[0].frequency.type === 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.transaction.transactions[0].frequency.type === 'Twice A Month' ||
                $scope.transaction.transactions[0].frequency.type === 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.transaction.transactions[0].frequency.type === 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.transaction.transactions[0].frequency.type === 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.transaction.transactions[0].frequency.type === 'Yearly') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };

    $scope.resetValues = function (type) {
        if ($scope.transaction.transactions[0].frequency.repeatOnLastBusinessDay) {
            if (type === 'Monthly') {
                $scope.transaction.transactions[0].frequency.repeatOnDay1 = null;
            } else if (type === 'Twice A Month') {
                $scope.transaction.transactions[0].frequency.repeatOnDay2 = null;
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', true);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', true);
            }
        }
    };

    $scope.checkRepeatOnDays = function () {
        if (!$scope.transaction.transactions[0].frequency.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.transaction.transactions[0].frequency.repeatOnDay1 <
                    $scope.transaction.transactions[0].frequency.repeatOnDay2 ||
                $scope.transaction.transactions[0].frequency.repeatOnDay2 === '';
            const isValid2 =
                $scope.transaction.transactions[0].frequency.repeatOnDay2 >
                    $scope.transaction.transactions[0].frequency.repeatOnDay1 ||
                $scope.transaction.transactions[0].frequency.repeatOnDay1 === '';

            if (
                $scope.transaction.transactions[0].frequency.repeatOnDay1 !== undefined &&
                $scope.transaction.transactions[0].frequency.repeatOnDay2 !== undefined &&
                $scope.transaction.transactions[0].frequency.repeatOnDay2 !== ''
            ) {
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', isValid1);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
    };

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    $scope.createAnotherTransferFromEditTemplate = function () {
        $state.go('payables.transfers');
    };

    $scope.summarizeFrequency = function (frequency) {
        return frequencyService.summarizeFrequency(frequency);
    };

    function displayFieldFilter(searchText) {
        return function (account, index, allAccounts) {
            return (
                account[$scope.accountDisplayField]
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
            );
        };
    }

    function typeAheadFromAccountBlur(account) {
        if (!angular.isObject(account)) {
            updateToAccounts();
        }
    }

    function typeAheadToAccountBlur(account) {
        if (!angular.isObject(account)) {
            updateFromAccounts();
        }
    }

    function removeInvalidFromAccount(shouldRemoveInvalidAccounts) {
        if (shouldRemoveInvalidAccounts) removeInvalidAccounts(getFromAccounts(), fromAccountKey);
    }

    function removeInvalidToAccount(shouldRemoveInvalidAccounts) {
        if (shouldRemoveInvalidAccounts) removeInvalidAccounts(getToAccounts(), toAccountKey);
    }

    function removeInvalidAccounts(availableAccounts, accountTypeKey) {
        angular.forEach($scope.transaction.transactions, transfer => {
            if (transfer[accountTypeKey] == undefined) return;
            const foundAccount = availableAccounts.find(
                account => account.id === transfer[accountTypeKey].id
            );
            if (foundAccount === undefined) {
                transfer[accountTypeKey] = null;
            }
        });
    }

    function getBalanceDisplayForToAccount(account) {
        if (account.type === 'Loan') {
            return balanceDisplay(account.currentBalance);
        }

        return balanceDisplay(account.availableBalance);
    }

    function balanceDisplay(balance) {
        return $filter('negativeParentheses')($filter('currency')(balance));
    }

    init();
}
