ResourcesController.$inject = [
    '$scope',
    '$modal',
    'modalService',
    '$window',
    'dashboardService',
    'spinnerService',
    'dashboardUserInteractions',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function ResourcesController(
    $scope,
    $modal,
    modalService,
    $window,
    dashboardService,
    spinnerService,
    dashboardUserInteractions
) {
    $scope.manageWarning = manageWarning;

    init();

    function init() {
        spinnerService.configureGroup(
            'resourcesWidget',
            ['/dashboard/resourcedetail'],
            ['resourcesWidget.html']
        );

        updateSpinner(spinnerService.startGroupRequest('resourcesWidget'));
        dashboardService.getResources().then(response => {
            updateSpinner(spinnerService.stopGroupRequest('resourcesWidget'));
            $scope.resourceTemplateData = response;
        });
    }

    function manageWarning(resource) {
        if (resource.displayExternalWarning === true) {
            const modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Continue',
                headerText: 'External Warning',
                bodyText: resource.externalWarningMessage,
                submit(result) {
                    $window.open(resource.link, '_blank');
                    $modalInstance.close(result);
                    dashboardService.auditUserIteration(dashboardUserInteractions.viewedResource);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $window.open(resource.link, '_blank');
            dashboardService.auditUserIteration(dashboardUserInteractions.viewedResource);
        }
    }

    function updateSpinner(numPendingRequests) {
        $scope.widget.isLoading = numPendingRequests > 0;
    }
}
