import { html, nothing } from 'lit';
import Column from './column.js';

function parseOptions(options) {
    if (typeof options === 'string') {
        return options.split(',').map(option => ({
            action: option.trim(),
        }));
    }
    return options;
}

export default class EditRowColumn extends Column {
    constructor(...args) {
        super(...args);
        const [{ label, options, visible }, recordset] = args;
        this.iconLabel = label;
        this.options = parseOptions(options);
        this.visible = typeof visible === 'boolean' ? visible : true;
        this.minWidth = 50;
        this.targetWidth = 55;
        this.maxWidth = 60;
        this.recordset = recordset;
    }

    isSortable() {
        return false;
    }

    isLastRecord(record) {
        return this.recordset.isLastRecord(record);
    }

    isFirstAndOnlyRecord(rowIndex) {
        return rowIndex === 0 && this.recordset.filteredRecordCount() === 1;
    }

    hasOption(name) {
        return this.options.some(option => option.action === name);
    }

    tooltipForOption(name) {
        return this.options.find(option => option.action === name).tooltip;
    }

    shouldCollapseAll(name) {
        // returns the array of additional action names
        return this.options.find(option => option.action === name).collapseAllValidRows;
    }

    isOptionDisabled(name) {
        const disabledFunction = this.options.find(option => option.action === name).isDisabled;
        return disabledFunction ? disabledFunction(this.recordset) : false;
    }

    collapseValidRecords(recordsetRow) {
        if (recordsetRow.isValid()) {
            recordsetRow.isExpanded = false; // eslint-disable-line no-param-reassign
        }
    }

    renderThContents() {
        if (!this.visible) return nothing;
        const addButton = this.hasOption('add')
            ? html` <omega-button
                  type="icon"
                  icon="plus"
                  @click=${() => {
                      if (this.shouldCollapseAll('add')) {
                          this.recordset.allRecords.forEach(this.collapseValidRecords);
                      }
                      this.recordset.insertDefaultRecord(-1);
                  }}
              >
                  Add Row
              </omega-button>`
            : nothing;
        return html`
            <style>
                .sr-only {
                    position: absolute;
                    left: -10000px;
                    top: auto;
                    width: 1px;
                    height: 1px;
                    overflow: hidden;
                }
            </style>
            <span class="sr-only">Table Actions</span>
            ${addButton}
        `;
    }

    renderTdContents(record, rowIndex) {
        if (!this.visible) return nothing;
        return html`
            <style>
                .icon-row {
                    display: flex;
                    justify-content: flex-end;
                }
                omega-button {
                    margin: 0;
                }
            </style>
            <div class="icon-row">
                ${this.renderAdd(record, rowIndex)} ${this.renderClone(record, rowIndex)}
                ${this.renderDelete(record, rowIndex)}
            </div>
        `;
    }

    renderAdd(record) {
        if (!this.hasOption('add')) return nothing;
        if (!this.isLastRecord(record)) return nothing;

        const tooltip = this.tooltipForOption('add') ?? 'Add an item';

        return html`
            <omega-tooltip message="${tooltip}" light>
                <omega-button
                    type="icon"
                    ?disabled=${!record}
                    icon="plus"
                    hideLabel="always"
                    .disabled=${this.isOptionDisabled('add')}
                    @click=${() => {
                        if (this.shouldCollapseAll('add')) {
                            this.recordset.allRecords.forEach(this.collapseValidRecords);
                        }
                        this.recordset.insertDefaultRecord();
                    }}
                >
                    Add Row
                </omega-button>
            </omega-tooltip>
        `;
    }

    renderClone(record, rowIndex) {
        if (!this.hasOption('clone')) return nothing;

        const tooltip = this.tooltipForOption('clone') ?? 'Create a copy of this item';

        const { fieldsToClone } = this.options.find(option => option.action === 'clone');
        return html`
            <omega-tooltip message="${tooltip}" light>
                <omega-button
                    type="icon"
                    ?disabled=${!record}
                    icon="clone"
                    .disabled=${this.isOptionDisabled('clone')}
                    hideLabel="always"
                    @click=${() => {
                        if (this.shouldCollapseAll('clone')) {
                            this.recordset.allRecords.forEach(this.collapseValidRecords);
                        }
                        const clonedRecord = this.recordset.cloneRecord(
                            record,
                            rowIndex,
                            fieldsToClone
                        );
                        clonedRecord.isExpanded = true;
                    }}
                >
                    Clone Row
                </omega-button>
            </omega-tooltip>
        `;
    }

    renderDelete(record, rowIndex) {
        if (!this.hasOption('delete')) return nothing;
        if (this.isFirstAndOnlyRecord(rowIndex)) return nothing;

        const tooltip = this.tooltipForOption('delete') ?? 'Delete this item';

        return html`
            <omega-tooltip message="${tooltip}" light>
                <omega-button
                    type="icon"
                    icon="trash"
                    .disabled=${this.isOptionDisabled('delete')}
                    hideLabel="always"
                    @click=${() => this.recordset.deleteRecord(record, rowIndex)}
                >
                    Delete Row
                </omega-button>
            </omega-tooltip>
        `;
    }

    renderContentLoading() {
        return html`
            <omega-button type="icon" disabled icon="plus" hideLabel="always">
                Edit Row
            </omega-button>
        `;
    }
}
