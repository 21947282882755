import { LitElement, html, css } from 'lit';

class OmegaIconMore extends LitElement {
    render() {
        return html`
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 16 4"
            >
                <defs>
                    <path
                        d="M9 13a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2zm12 0a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2zm-6 0a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z"
                        id="a"
                    />
                </defs>
                <g transform="translate(-7 -13)" fill="none" fill-rule="evenodd">
                    <mask id="b" fill="#fff">
                        <use xlink:href="#a" />
                    </mask>
                    <g mask="url(#b)" fill="#d6d6d6">
                        <path d="M2 2h26v26H2z" />
                    </g>
                </g>
            </svg>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                width: 24px;
                height: 24px;
                fill: red;
            }
            svg {
                fill: red;
            }
        `;
    }
}
customElements.define('omega-icon-more', OmegaIconMore);
