import { css, html, LitElement, nothing } from 'lit';

class AchNoticeOfChangeDetailRowV2 extends LitElement {
    static get properties() {
        return {
            parentRecord: Object,
            closeFunction: Object,
            detailRecord: Object,
            downloadOptionsOpen: Boolean,
            showBlockingLoader: Boolean,
        };
    }

    constructor() {
        super();
        this.downloadOptionsOpen = false;
        this.downloadOptions = ['PDF', 'CSV', 'NACHA'];
    }

    async _detailDownload(e) {
        if (e.type === 'click' || e.keyCode === 13) {
            this.toggleDownloadDropdown();
            this.dispatchEvent(
                new CustomEvent('saveNotice', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        guid: this.parentRecord.getField('achNocTransactionUniqueId'),
                        type: e.target.innerText,
                        notificationReceivedDate: this.parentRecord.getField('receivedDate'),
                        changeCodeDescription:
                            this.parentRecord.getField('returnReasonDescription'),
                    },
                })
            );
        }
    }

    _detailPrint() {
        this.dispatchEvent(
            new CustomEvent('detailPrint', {
                bubbles: true,
                composed: true,
                detail: this.parentRecord.getField('achNocTransactionUniqueId'),
            })
        );
        // window.print();
        const printWindow = window.open();
        printWindow.document.write(`
        <head>
            <style>
                body {
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                    font-size: 18px;
                }
                h1 {
                    font-size: 24px;
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }
                li {
                    padding: 8px 0;
                    font-weight: 300;
                    color: #444;
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                }
                li > span {
                    font-weight: 400;
                    font-size: 16px;
                }
                .bold {
                    font-weight: 700;
                }
                .small {
                    font-size: 14px;
                }
                .italic {
                    font-style: italic;
                }
            </style>
            <body>
                <h1>ACH Notification of Change Detail</h1>
                <ul>
                    <li>Original Effective Date: <span id="effectiveDate"></span></li>
                    <li>Date Notification Received: <span id="receivedDate"></span></li>
                    <li>ACH Company: <span id="achCompanyName"></span></li>
                    <li>Company Entry Description: <span id="companyEntryDescription"></span></li>
                    <li>SEC Code: <span id="updatedSecCode"></span></li>
                    <li>Recipient Name: <span id="updatedRecipientName"></span></li>
                    <li>Recipient Financial Institution: <span id="updatedRecipientFi"></span></li>
                    <li>Recipient Account Number: <span id="updatedAccountNumber"></span></li>
                    <li>Account Type: <span id="updatedAccountType"></span></li>
                    <li>Identification Number: <span id="updatedIdNumber"></span></li>
                    <li>Trace Number: <span id="updatedTraceNumber"></span></li>
                    <li>Change Code: <span id="changeCode"></span></li>
                </ul>
            </body>
        </head>
        `);

        this._fetchAndPrintRecord(printWindow.document, 'effectiveDate');
        this._fetchAndPrintRecord(printWindow.document, 'receivedDate');
        this._fetchAndPrintRecord(printWindow.document, 'achCompanyName');
        this._fetchAndPrintRecord(printWindow.document, 'companyEntryDescription');
        this._fetchAndPrintChangedRecord(printWindow.document, 'updatedSecCode', 'originalSecCode');
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedRecipientName',
            'originalRecipientName'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedRecipientFi',
            'originalRecipientFi'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedAccountNumber',
            'originalAccountNumber'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedAccountType',
            'originalAccountType'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedIdNumber',
            'originalIdNumber'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedTraceNumber',
            'originalTraceNumber'
        );
        this._fetchAndPrintRecord(printWindow.document, 'changeCode');

        printWindow.stop();
        printWindow.print();
        printWindow.close();
    }

    _fetchAndPrintRecord(document, record) {
        const field = document.getElementById(record);
        const text = this.parentRecord.print(record);
        if (text) {
            field.textContent = this.parentRecord.print(record);
        }
    }

    _fetchAndPrintChangedRecord(document, record, originalRecord) {
        const field = document.getElementById(record);
        const updatedText = this.parentRecord.print(record);
        const originalText = this.parentRecord.print(originalRecord);

        if (updatedText) {
            field.innerHTML = `<span class="bold small">Updated Entry:</span> ${updatedText} <span class="italic small">(Original Entry: ${originalText})</span>`;
        } else {
            field.textContent = originalText;
        }
    }

    toggleDownloadDropdown() {
        this.downloadOptionsOpen = !this.downloadOptionsOpen;
    }

    isDownloadDropdownOpen() {
        return this.downloadOptionsOpen === true;
    }

    renderFieldWithChangedValue(original, updated) {
        if (original && updated && this.parentRecord.getField(updated)) {
            /* Specific exception for IAT and Recipient FIs.  The original FI will always be empty, this code prevents an empty set of parentheses from displaying */
            if (
                this.parentRecord.getField('achCompanyName') === 'IATCOR' &&
                updated === 'updatedRecipientFi'
            ) {
                return html` <span class="updated"> ${this.parentRecord.print(updated)} </span>`;
            }

            return html`
                <span class="updated">
                ${this.parentRecord.print(updated)}
            </span>
                <span class="original"
             >${this.parentRecord.print(original)}</span
          ></span>`;
        }
        return html`${this.parentRecord.print(original)}`;
    }

    renderDayOfDeath() {
        if (this.parentRecord.getField('dateOfDeath')) {
            return html`
                <span class="detail-row-value"> ${this.parentRecord.print('dateOfDeath')} </span>
                <label class="detail-row-label under">Date of Death </label>
            `;
        }
        return nothing;
    }

    renderDownloadDropdown() {
        if (this.isDownloadDropdownOpen()) {
            return html`<ul
                class="download-dropdown"
                role="menu"
                aria-hidden=${this.isDownloadDropdownOpen()}
            >
                ${this.downloadOptions.map(
                    option =>
                        html`<li
                            role="menuitem"
                            class="download-dropdown-item"
                            @click=${e => this._detailDownload(e)}
                            @keyup=${e => this._detailDownload(e)}
                        >
                            ${option}
                        </li>`
                )}
            </ul>`;
        }
        return nothing;
    }

    renderDetailRow() {
        if (this.parentRecord) {
            return html`
                <div class="detail-row-container">
                    <div class="detail-row-header">
                        <div>
                            <span class="detail-row-title">ACH Notification Of Change Detail</span>
                            <label class="detail-row-label"
                                >Updated entries in <span class="updated">Green</span>. Original
                                entries in ().</label
                            >
                        </div>
                        <div>
                            <omega-button
                                type="icon"
                                icon="download"
                                @click=${this.toggleDownloadDropdown}
                                hideLabel="always"
                            ></omega-button>
                            ${this.renderDownloadDropdown()}
                            <omega-button
                                type="icon"
                                icon="print"
                                @click=${this._detailPrint}
                                hideLabel="always"
                            ></omega-button>
                            <omega-button
                                class="close-button"
                                type="icon"
                                icon="close"
                                @click=${this.closeFunction}
                                hideLabel="always"
                            ></omega-button>
                        </div>
                    </div>
                    <div class="detail-content-container">
                        <div class="detail-content-column">
                            <label class="detail-row-label">Original Effective Date</label>
                            <span class="detail-row-value under">
                            ${this.parentRecord.print('effectiveDate')}</span
                            >
                            </span>
                            <label class="detail-row-label">Date Notification Received</label>
                            <span class="detail-row-value under"
                                >${this.parentRecord.print('receivedDate')}</span
                            >
                            <label class="detail-row-label">ACH Company</label>
                            <span class="detail-row-value under"
                                >${this.parentRecord.print('achCompanyName')}</span
                            >
                            
                        </div>
                        <div class="detail-content-column">
                        <label class="detail-row-label">Company Entry Description</label>
                            <span class="detail-row-value under">
                                ${this.parentRecord.print('companyEntryDescription')}</span
                            >
                            <label class="detail-row-label">SEC Code</label>
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalSecCode',
                                    'updatedSecCode'
                                )}
                            </span>
                            
                            <label class="detail-row-label">Recipient Name</label>
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalRecipientName',
                                    'updatedRecipientName'
                                )}
                            </span>
                            
                        </div>
                        <div class="detail-content-column">
                        <label class="detail-row-label"
                                >Recipient Financial Institution</label
                            >
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalRecipientFi',
                                    'updatedRecipientFi'
                                )}</span
                            >
                            
                            <label class="detail-row-label">Recipient Account Number</label>
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalAccountNumber',
                                    'updatedAccountNumber'
                                )}
                            </span>
                            <label class="detail-row-label">Account Type</label>
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalAccountType',
                                    'updatedAccountType'
                                )}
                            </span>
                            
                        </div>
                        <div class="detail-content-column">
                        <label class="detail-row-label">Identification Number</label>
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalIdNumber',
                                    'updatedIdNumber'
                                )}
                            </span>
                            <label class="detail-row-label">Trace Number</label>
                            <span class="detail-row-value under">
                                ${this.renderFieldWithChangedValue(
                                    'originalTraceNumber',
                                    'updatedTraceNumber'
                                )}</span
                            >
                           
                        </div>

                        <div class="detail-content-column">
                        <label class="detail-row-label">Change Code</label>
                            <span class="detail-row-value under">
                            ${this.parentRecord.print('changeCode')}
                            </span>
                           
                        </div>
                    </div>
                </div>
            `;
        }

        return nothing;
    }

    render() {
        return html`${this.renderDetailRow()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            div.detail-row-container {
                padding: 0px;
                padding-bottom: 8px;
                margin-top: 0px;
                position: relative;
            }
            div.detail-row-header {
                height: 30px;
                padding: 4px 17px;
                padding-right: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .close-button {
                opacity: 0.6;
            }
            .detail-header {
                height: 30px;
                padding: 10px 17px 20px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .detail-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .detail-content-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
            }
            .detail-content-column {
                margin: 20px 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            .detail-row-title {
                padding-top: 0px;
                margin-top: 0px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #333333;
            }
            .detail-row-label {
                font-size: 13px;
                color: #787878;
                margin-left: 0px;
                margin-right: 5px;
                margin-bottom: 4px;
                flex: 0;
                white-space: nowrap;
                min-width: 140px;
            }
            .detail-row-label.align-center {
                display: flex;
                align-items: center;
            }

            .detail-row-value {
                flex: 1;
                font-size: 14px;
            }

            .detail {
                text-align: right;
            }

            .under {
                margin-left: 0px;
                padding-bottom: 15px;
            }

            .detail-row-buttons {
                align-self: flex-end;
            }
            .updated {
                font-weight: 500;
                color: #197a00;
            }
            .original {
                font-size: 12px;
            }
            .original::before {
                content: '(';
            }
            .original::after {
                content: ')';
            }
            .download-dropdown {
                position: absolute;
                top: 25px;
                z-index: 5;
                min-width: 50px;
                padding: 0;
                border: 1px solid var(--omega-white-150);
                background: var(--omega-white);
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                list-style: none;
            }
            .download-dropdown li {
                min-width: 50px;
                padding: 10px 25px;
                text-align: left;
                color: var(--omega-primary);
                cursor: pointer;
            }

            .download-dropdown li:hover {
                background-color: var(--omega-white-100);
            }

            .download-dropdown a {
                text-decoration: none;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default AchNoticeOfChangeDetailRowV2;
// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define('ach-notice-of-change-detail-row-v2', AchNoticeOfChangeDetailRowV2);
