SaveAsTemplateController.$inject = [
    '$scope',
    '$modalInstance',
    'achPaymentTypes',
    'securityService',
    'achBatchService',
    'batch',
];

export default function SaveAsTemplateController(
    $scope,
    $modalInstance,
    achPaymentTypes,
    securityService,
    achBatchService,
    batch
) {
    $scope.close = close;
    $scope.setForm = setForm;

    $scope.batch = angular.copy(batch);
    $scope.createBatch = createBatch;
    $scope.validateTemplateName = validateTemplateName;
    $scope.resetAmountsToZero;

    function close() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function resetAmounts(batch) {
        if (batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            batch.achStateTaxPaymentAmounts = [];
        } else if (batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            batch.achFederalTaxPaymentAmounts = [];
        }
    }

    function createBatch() {
        securityService
            .verifyUser('', $scope.batch, () => {
                if ($scope.resetAmountsToZero) {
                    resetAmounts($scope.batch);
                }
                return achBatchService.create($scope.batch);
            })
            .then(response => {
                if (response.errorMessage && response.errorMessage !== '') {
                    $scope.batch.errorMessage = response.errorMessage;
                } else {
                    $modalInstance.close(response.batch);
                }
            });
    }

    function validateTemplateName() {
        if ($scope.batch.name == undefined) return;

        $scope.form.name.$setValidity('unique', true);
        if ($scope.batch.name !== $scope.name) {
            achBatchService.validateTemplateName($scope.batch.name).then(resp => {
                $scope.form.name.$setValidity('unique', !!resp.isValid);
                $scope.name = $scope.batch.name;
            });
        }
    }
}
