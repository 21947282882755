/* eslint-disable prettier/prettier */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record, Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-checkbox';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-icon';
import '@treasury/omega/components/omega-textarea';
import '@treasury/omega/components/omega-validation-button';
import '@treasury/omega/components/progress/omega-progress';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { LitElement, css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { AchDomesticClient } from '../clients/ach-domestic-client';

export class SaveAsTemplateDialog extends LitElement {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property()
    show = false;

    @property()
    paymentHeaderRecord!: Record<PaymentHeader>;

    @property()
    recipientsRecordset!: Recordset<PaymentRecipient>;

    @property()
    client!: AchDomesticClient;

    @property()
    setAlert!: (alert: Partial<OmegaAlertConfig>) => void;

    @state()
    resetAmounts = false;

    @state()
    loading = false;

    @state()
    templateName = '';

    @state()
    validTemplateName = false;

    @state()
    dirty = false;

    @state()
    validatingName = false;

    @state()
    errorMessage?: string;

    validateTemplateName = this.debounceValidateTemplateName();

    open() {
        this.show = true;
    }

    close() {
        this.show = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    get textAreaShouldDisplayValid() {
        return this.validTemplateName || !this.dirty;
    }

    updated(changedProperties: Map<keyof SaveAsTemplateDialog, unknown>) {
        if (changedProperties.has('show') && this.show) {
            this.nameChange(this.paymentHeaderRecord.getField('name'));
        }
    }

    nameChange(nameValue: string) {
        if (this.templateName !== nameValue) {
            this.templateName = nameValue;
            this.validateTemplateName();
        }
    }

    handleTemplateNameChange(e: { target: HTMLTextAreaElement }) {
        if (!this.dirty) this.dirty = true;
        this.nameChange(e.target.value);
    }

    private debounceValidateTemplateName() {
        const delay = 250;
        const sendTemplateNameValidationRequest = this.sendTemplateNameValidationRequest.bind(this);
        let timeout: any;
        return () => {
            this.validatingName = true;
            clearTimeout(timeout);
            timeout = setTimeout(sendTemplateNameValidationRequest, delay);
        };
    }

    async sendTemplateNameValidationRequest() {
        this.validatingName = true;
        if (this.templateName.length) {
            this.validTemplateName = await this.client.isTemplateNameValid(this.templateName);
        }
        this.validatingName = false;
    }

    handleResetAmountsToggle(
        e: CustomEvent<{ value: any; checked: boolean; indeterminate: boolean }>
    ) {
        this.resetAmounts = e.detail.checked;
    }

    private async save() {
        if (this.paymentHeaderRecord && this.recipientsRecordset) {
            this.loading = true;

            const paymentData = this.paymentHeaderRecord.values;
            const payment: any = {
                ...paymentData,
                id: 0,
                achCompanyName: paymentData.achCompany.companyName,
                resetAmounts: this.resetAmounts,
                name: this.templateName,
                templateName: null,
                isTemplate: false,
            };
            const recipients: any[] = this.recipientsRecordset.allRecords.map(record => ({
                accountNumber: record.getField('accountNumber'),
                accountType: record.getField('accountType'),
                addendaBody: record.getField('addenda'),
                addendaType: null,
                amount: record.getField('amount'),
                default: true,
                hold: record.getField('hold'),
                idNumber: record.getField('idNumber'),
                isDeleted: false,
                isRoutingNumberOnUs: record.getField('bank')?.onUs,
                isRoutingNumberValid: true,
                minAmount: '0.01',
                name: record.getField('name'),
                prenote: record.getField('prenote'),
                transactionType: record.getField('transactionType'),
                routingNumber: record.getField('bank')?.bankId,
            }));
            payment.recipients = recipients;

            try {
                this.errorMessage = undefined;
                await this.initiateCreateTemplateRequest(payment);
            } catch (e) {
                this.errorMessage =
                    e instanceof Error ? e.message : 'An unknown error occurred. Please try again.';
            } finally {
                this.loading = false;
            }

            if (!this.errorMessage) {
                // success actions - close dialog and route to batch list
                this.close();
                await this.navService.navigate('payables.ach.batch-list');
            }
        }
    }

    private async initiateCreateTemplateRequest(payment: any) {
        return this.client.createTemplate(payment);
    }

    renderValidationMessage() {
        if (this.validatingName) return 'Validating Template Name';
        if (!this.validTemplateName) return 'Invalid Template Name';
        return null;
    }

    renderErrors() {
        if (this.errorMessage) {
            return html`
                <div class="error">
                    <div class="alert-body">
                        <omega-icon
                            .icon=${'exclamation-triangle danger'}
                            .description=${'Error message'}
                        ></omega-icon>
                        <b>One or more errors occurred.</b>
                    </div>
                    <span>${this.errorMessage}</span>
                </div>
            `;
        }
        return nothing;
    }

    render() {
        return html`<omega-dialog
            .dialogTitle=${'Confirm Save as Template'}
            .open=${this.show}
            @close=${this.close}
        >
            <div slot="content">
                <div class="dialog-content">
                    ${this.renderErrors()}

                    <div class="templateName">
                        <label for="templateNameText"
                            >Template Name:<span class="error-asterisk"> *</span></label
                        >
                        <textarea
                            id="templateNameText"
                            name="templateNameText"
                            rows="4"
                            aria-invalid=${!this.textAreaShouldDisplayValid}
                            @input=${this.handleTemplateNameChange}
                            @change=${this.handleTemplateNameChange}
                            @focus=${this.handleTemplateNameChange}
                            required
                            .disabled=${this.loading}
                        >
${this.templateName}</textarea
                        >
                    </div>

                    <div class="reset-amounts-check">
                        <omega-checkbox
                            id="resetAmounts"
                            name="resetAmounts"
                            .hideLabel=${false}
                            .checked=${this.resetAmounts}
                            @toggle=${this.handleResetAmountsToggle}
                        ></omega-checkbox>

                        <label for="resetAmounts" class="reset-amounts-label"
                            >Reset amounts to $0.00 after processing?</label
                        >
                    </div>
                </div>
            </div>
            <omega-validation-button
                slot="actions"
                type="primary"
                .loading=${this.validatingName}
                .disabled=${this.validatingName || !this.validTemplateName}
                .message=${this.renderValidationMessage() || undefined}
                .tooltipDirection=${'top-left'}
                @submit=${this.save}
                >Save as Template</omega-validation-button
            >
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
            .templateName {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
            }
            .error-asterisk {
                color: red;
            }
            .reset-amounts-check {
                display: flex;
                margin-top: 5px;
                align-items: end;
            }
            .reset-amounts-label {
                margin-left: -15px;
            }
            .alert-body {
                display: flex;
            }
            .error {
                margin-bottom: 10px;
            }
            textarea {
                line-height: 1.5em;
                text-align: middle;
                border: 1px solid var(--omega-input-default-border);
                padding: 10px;
                border-radius: var(--omega-input-border-radius);
                font-size: var(--omega-input);
                font-family: var(--omega-font);
            }
            #templateNameText {
                text-align: left;
                margin: 5px 0px;
            }
            textarea:active {
                border-color: var(--omega-input-active-border);
            }
            textarea[aria-invalid='true'] {
                border: 2px solid var(--omega-input-error-border);
            }
            textarea:disabled {
                opacity: 0.6;
                background: var(--omega-white);
            }
        `;
    }
}

customElements.define('save-as-template-dialog', SaveAsTemplateDialog);
