import { WorkAchExceptionDto } from '@treasury/domain/channel/types';
import { OmegaListItem } from '@treasury/omega/view-models';

export type WorkAchExceptionListItem = OmegaListItem<WorkAchExceptionDto>;
export function createWorkAchExceptionListItem(dto: WorkAchExceptionDto): WorkAchExceptionListItem {
    return {
        value: dto,
        text: dto.accountDisplayLabel,
    };
}
