import { FrequencyType } from '@treasury/domain/channel/types/frequency';

export const FrequencyTypeLabels = {
    [FrequencyType.OneTime]: 'One Time',
    [FrequencyType.Weekly]: 'Weekly',
    [FrequencyType.EveryTwoWeeks]: 'Every Two Weeks',
    [FrequencyType.TwiceAMonth]: 'Twice a Month',
    [FrequencyType.Monthly]: 'Monthly',
    [FrequencyType.Quarterly]: 'Quarterly',
    [FrequencyType.EverySixMonths]: 'Every Six Months',
    [FrequencyType.Yearly]: 'Yearly',
} as const;
