export default () => ({
    restrict: 'A',
    replace: true,
    scope: {
        actions: '=',
        showDropDown: '=?',
    },
    template: require('./actionMenuTemplate.html'),
    controller: ActionMenuController,
});

ActionMenuController.$inject = ['$scope'];

function ActionMenuController($scope) {
    $scope.initiateAction = initiateAction;

    init();

    function init() {
        angular.forEach($scope.actions, action => {
            if (!(action.show === undefined || action.show === null)) {
                $scope.showDropDown = true;
            }
        });
    }

    function initiateAction(action) {
        action.initiate.apply(this, action.params);
    }
}
