/* eslint-disable no-use-before-define */

export type NavigationRouteMap = typeof navigationRouteMap;
export const appConstantsModule = angular
    .module('channel.app-constants', [])
    .constant('achConstants', {
        Errors: {
            entry: 'Entry description is a required field.  If uploading a file, the entry description in the UI must match the entry description in the file being uploaded.',
            repeatOn: 'Repeat On is a required field.',
            repeatOnDay1: 'Repeat on Day 1 is a required field.',
            repeatOnDay2: 'Repeat On Day 2 is a required field.',
            repeatOnDay1Invalid: 'Repeat on Day 1 must be less than Repeat on Day 2.',
            startOn: 'Start On Date is a required field.',
            endOn: 'End On Date is a required field.',
            unbalanced: 'Debit amount total must be equal to Credit amount total.',
            partialOffset:
                'Debit and Credit amounts must be greater than zero and different from one another.',
            cutoffPassed: 'Cutoff Time Passed. Cannot create ACH for today.',
            startOnInvalid: 'Start On Date must be before End On Date',
            invalidDate: 'Invalid date.  Please select a processing day.',
            offsetAccountRequired: 'Offset Account is a required field.',
        },
    })
    .constant('reportMenuOptions', [
        'irAll',
        'irFavorite',
        'irCustom',
        'irStandard',
        'informationReporting',
    ])
    .constant('informationReportingActivityCodes', [
        'irAll',
        'irFavorite',
        'irCustom',
        'irStandard',
    ])
    .constant('moment', window.moment)
    .constant('menulessOptions', {
        MessageCenter: 'messagecenter',
        Notifications: 'notifications',
        UserProfile: 'userProfile',
        NotificationConfiguration: 'notificationSetup',
    });

const navigationRouteMap = {
    dashboard: 'dashboard' as const,
    accountList: 'accounts.list' as const,
    researchTransactions: 'ir.rt' as const,
    payables: null,
    transfer: null,
    transferList: 'payables.transferList' as const,
    recurringTransferList: 'payables.recurringTransferList' as const,
    transferTemplateList: 'payables.transferTemplatesList' as const,
    createTransferTemplates: 'payables.readytransferTemplatesList' as const,
    createTransfer: 'payables.transfers' as const,
    createLoanPayment: 'payables.create-loan-payment' as const,
    loanPaymentList: 'payables.loan-payment-list' as const,
    ach: null,
    beneficiaryList: 'payables.wire.beneficiary-list' as const,
    wireList: 'payables.wire.wire-list' as const,
    recurringWireList: 'payables.wire.recurring-wire-list' as const,
    recipientsList: 'payables.ach.recipients-list' as const,
    recipientActivity: 'payables.ach.recipients-activity-list' as const,
    createWire: 'payables.wire.create-wire' as const,
    createFXWire: 'payables.wire.create-fx-wire' as const,
    uploadWires: 'payables.wire.wire-upload' as const,
    createCorpayWire: 'payables.wire.createCorpayWire' as const,
    wireFileActivity: 'payables.wire.wire-file-activity' as const,
    wireUploadFormats: 'payables.wire.wire-upload-formats' as const,
    wire: null,
    achCreatePayment: 'payables.ach.payments.create' as const,
    achCreatePaymentLit: 'payables.ach.payments.create-dark' as const,
    achCreateTaxPayment: 'payables.ach.payments.createTaxPayment' as const,
    CreateChildSupportPayment: 'payables.ach.payments.child-support-workflow' as const,
    createInternationalAchPayment:
        'payables.ach.payments.create-international-ach-payment' as const,
    internationalTemplateList: 'payables.ach.payments.international-ach-templates' as const,
    achNotificationOfChangeReview: 'payables.ach.notice-of-change' as const,
    achNotificationOfChangeNacha: 'payables.ach.notice-of-change-nacha' as const,
    achReturnReview: 'payables.ach.return-activity' as const,
    achReturnNacha: 'payables.ach.return-activity-nacha' as const,
    arp: null,
    stp: null,
    stpCreate: 'payables.stp.create' as const,
    stpList: 'payables.stp.list' as const,
    importLayout: 'payables.ach.import-layout' as const,
    batchList: 'payables.ach.batch-list' as const,
    // achTemplatesList: 'payables.ach.payments.templates',
    achTaxTemplateList: 'payables.ach.payments.tax-templates' as const,
    childSupportTemplateList: 'payables.ach.payments.child-support-templates' as const,
    paymentList: 'payables.ach.payment-list' as const,
    recurringAchPaymentList: 'payables.ach.recurring-payment-list' as const,
    fileActivityList: 'payables.ach.fileactivity-list' as const,
    arpExceptionsList: 'payables.arp.check-exceptions' as const,
    checkExceptionsDecisionActivityList: 'payables.arp.check-exception-decision-activity' as const,
    arpCreate: 'payables.arp.create' as const,
    newAchFiltersView: 'payables.arp.ach-filters' as const,
    arpActivity: 'payables.arp.activity' as const,
    arpAchExceptionFilterList: 'payables.arp.ach-exceptions-filter-list' as const,
    arpOutputFileList: 'payables.arp.arp-output-file-list' as const,
    achExceptionsList: 'payables.arp.ach-exceptions-list' as const,
    achExceptionsDecisionActivityList: 'payables.arp.ach-exceptions-activity-list' as const,
    arpUploadFormatList: 'payables.arp.arp-upload-format-list' as const,
    informationReporting: 'ir.dashboard' as const,
    irAll: 'ir.dashboard' as const,
    irFavorite: 'ir.dashboard.favorite' as const,
    irCustom: 'ir.dashboard.custom' as const,
    irStandard: 'ir.dashboard.standard' as const,
    createUser: 'admin.user-information' as const,
    userList: 'admin.user-list' as const,
    accountNicknames: 'admin.account-nick-names' as const,
    notificationConfiguration: 'admin.notification-configuration' as const,
    wireTemplateList: 'payables.wire.wire-template-list' as const,
    createWireFromTemplate: 'payables.wire.create-from-template' as const,
    billPay: null,
    arpCentrixSso: 'payables.arp.centrix-sso' as const,
    rdc: 'receivables.rdc-sso' as const,
    electronicDocuments: 'ir.electronicDocuments' as const,
    accountReconciliation: 'ir.accountReconciliation' as const,
};

appConstantsModule.constant('navigationRouteMap', navigationRouteMap);
