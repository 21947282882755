import { FrequencyType } from '@treasury/domain/channel/types/frequency';
import { isValidDate } from '../../utils/frequency';

export const startOnIsRequired = {
    name: 'Start On Date is a required field',
    validate: (val: any) => {
        if (val.type === FrequencyType.OneTime) {
            return true;
        }

        return isValidDate(val.startOn);
    },
};
