import { CompanyAccountModelDto, UserModelDto, WireCompanyModelDto } from '@treasury/api/channel';
import { WireTemplate } from '@treasury/domain/channel/mappings/wires';
import { WireFileActivity } from '@treasury/domain/channel/mappings/wires/wire-file-activity.entity';
import { WireStatus } from '@treasury/domain/channel/types/wires';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import { OmegaReportFilter } from '@treasury/omega/types';
import { OmegaListItem } from '@treasury/omega/view-models';
import { disableFutureDates } from '@treasury/policy/lib/utils';
import FieldTypes from '@treasury/policy/primitives';
import { amountFilter } from '@treasury/policy/wires';
import {
    DebitAccountListItem,
    WireCompanyListItem,
    createDebitAccountListItem,
    createWireCompanyListItem,
} from '../models';
import { createUserModelListItem } from '../models/user-model-list-item.model';

export const statusTypes: WireStatus[] = ['Pending Approval', 'Approval Rejected', 'Ready'];

const statusTypesVm: OmegaListItem<string>[] = statusTypes.map(type => ({
    name: type,
    text: type,
    value: type.trim(),
}));

const today = new Date();
const oneWeekAgo = new Date();
oneWeekAgo.setDate(today.getDate() - 7);

export const thisWeek: OmegaDate = {
    id: 'range',
    exact: true,
    isRange: true,
    label: '',
    // eslint-disable-next-line @treasury/no-date
    dates: [oneWeekAgo, today],
};

export function createFilters(
    wireCompanies: WireCompanyModelDto[],
    debitAccounts: CompanyAccountModelDto[]
): OmegaReportFilter<WireTemplate, any>[] {
    return [
        {
            field: 'name',
            fieldType: FieldTypes.string.with.label('Template Name').as.tag('omega-input'),
            value: '',
        },
        {
            field: 'wireCompany',
            fieldType: FieldTypes.string.with
                .label('Wire Company')
                .and.options({
                    data: wireCompanies.map(a => createWireCompanyListItem(a)),
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .thatHas.hashFunction<WireCompanyListItem['value']>(v => v.name)
                .as.tag('omega-select'),
            value: wireCompanies,
        },
        {
            field: 'type',
            fieldType: FieldTypes.string.with
                .label('Wire Type')
                .thatHas.options([
                    { text: 'Both', value: 'Both' },
                    { text: 'Domestic', value: 'Domestic' },
                    { text: 'International', value: 'International' },
                ])
                .thatIs.inline()
                .as.tag('omega-radio-group'),
            value: 'Both',
        },
        {
            field: 'status',
            fieldType: FieldTypes.string.with
                .label('Status')
                .and.options({
                    data: statusTypesVm,
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .as.tag('omega-select'),
            value: statusTypes,
        },
        {
            field: 'debitAccount',
            fieldType: FieldTypes.string.with
                .label('Debit Account')
                .and.options({
                    data: debitAccounts.map(a => createDebitAccountListItem(a)),
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .thatHas.hashFunction<DebitAccountListItem['value']>(
                    v => v.accountDisplayLabel || ''
                )
                .as.tag('omega-select'),
            value: debitAccounts,
        },
        {
            field: 'beneficiaryName',
            fieldType: FieldTypes.string.with.label('Beneficiary Name').as.tag('omega-input'),
            value: '',
        },
    ];
}

export function createWireActivityFilters(
    fileUploadUsers: UserModelDto[],
    wireFileName: string,
    isWireSftpEnabled: boolean
): OmegaReportFilter<WireFileActivity, any>[] {
    const uploadUsers = fileUploadUsers.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    if (isWireSftpEnabled) {
        uploadUsers.unshift({
            id: -99,
            loginID: 'System',
            name: 'System',
            useClientBiometricsAuthentication: false,
        });
    }
    const uploadUsersItems = uploadUsers.map(a => createUserModelListItem(a));

    return [
        {
            field: 'fileName',
            fieldType: FieldTypes.string.with.label('File Name').as.tag('omega-input'),
            value: wireFileName || '',
        },
        {
            field: 'uploadedBy',
            fieldType: FieldTypes.string.with
                .label('Source')
                .and.options({
                    data: uploadUsersItems,
                })
                .with.placeholder('All Selected')
                .with.multipleValues()
                .thatHas.hashFunction<WireCompanyListItem['value']>(v => v.name)
                .as.tag('omega-select'),
            value: uploadUsers,
        },
        {
            field: 'uploadDate',
            fieldType: FieldTypes.dateRange.with
                .placeholder('Received Date')
                .with.label('Received Date')
                .as.tag('omega-datepicker')
                .thatHas.selectionDisabledFunctions(disableFutureDates),
            value: thisWeek,
        },
        {
            field: 'amount',
            fieldType: amountFilter.with.schema('range').and.label('Amount').as.tag('omega-range'),
            value: ['specific', 0],
        },
    ];
}
