/**
 * @prettier
 */

// <rate-quote-details rate-quote="{}" is-confirmation="false">

export default {
    bindings: {
        rateQuote: '=',
        isConfirmation: '=',
        hideQuoteRestrictionInfo: '=',
    },
    template: require('./rateQuoteDetails.html'),
};
