import { DocumentType } from '../electronic-documents.types';

ElectronicDocumentsController.$inject = [
    '$scope',
    '$rootScope',
    'electronicDocumentsService',
    '$sce',
    '$timeout',
    'spinnerService',
    'modalService',
    'FileSaver',
];

export default function ElectronicDocumentsController(
    $scope,
    $rootScope,
    electronicDocumentsService,
    $sce,
    $timeout,
    spinnerService,
    modalService,
    FileSaver
) {
    const defaultElectronicDocumentServiceName = 'Electronic Documents';
    const defaultAccountSelectText =
        'Please select an account and statement period below to view your online statement.';
    const defaultNoStatementText =
        'You do not have any statements available for this account. Please select another account.';

    $scope.useFullPage = useFullPage;
    $scope.isNoStatement = isNoStatement;
    $scope.isDisplayAccounts = isDisplayAccounts;
    $scope.isDisplayYears = isDisplayYears;
    $scope.isDisplayStatements = isDisplayStatements;
    $scope.getReportDisplayName = getReportDisplayName;
    $scope.getStatementList = getStatementList;
    $scope.getStatementsForYear = getStatementsForYear;
    $scope.getStatement = getStatement;

    $scope.currentSpinnerGroupName = null; // need to be in scope for spinnerService to work.

    let documentTypeId = DocumentType.None;
    let isInitialized = false;
    let isYearsLoaded = false;
    let isStatementsLoaded = false;
    let productName = null;
    let statements = null;
    let errorMessage =
        'An error has occurred.  Please try again later or contact customer service.';

    (function () {
        // init
        setupSso();

        electronicDocumentsService.getElectronicDocumentSso().then(response => {
            endSpinner();

            if (response) {
                documentTypeId = response.documentTypeId;
                processSso(response);
            } else {
                displayError();
            }
        });
    })();

    function useFullPage() {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                return true;

            default:
                return false;
        }
    }

    function isNoStatement() {
        return hasLoadedStatements() && statements && statements.length === 0;
    }

    function isDisplayAccounts() {
        return isInitialized && $scope.accountList && $scope.accountList.length > 0;
    }

    function isDisplayYears() {
        return hasLoadedYears() && $scope.years && $scope.years.length > 0;
    }

    function isDisplayStatements() {
        return (
            hasLoadedStatements() && $scope.displayStatements && $scope.displayStatements.length > 0
        );
    }

    function getReportDisplayName(report) {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                return `Statement Ending ${report.date}`;

            default:
                return report.name;
        }
    }

    function getStatementList(account) {
        setupGetReportList(account);

        electronicDocumentsService.getReportList(productName, account.id).then(response => {
            endSpinner();

            if (response) {
                isStatementsLoaded = true;
                statements = processReportList(response);
            } else {
                displayError();
            }
        });
    }

    function getStatementsForYear(year) {
        $scope.selectedYear = year;
        $scope.displayStatements = statements.filter(x => x.year === year);
    }

    function getStatement(report) {
        setupGetReport();

        electronicDocumentsService
            .getReport(productName, report.id, report.fileName)
            .then(response => {
                endSpinner();

                if (response) {
                    processReport(report, response);
                } else {
                    displayError();
                }
            });
    }

    function hasLoadedStatements() {
        return isInitialized && isStatementsLoaded;
    }

    function hasLoadedYears() {
        return hasLoadedStatements() && isYearsLoaded;
    }

    function getAccountsAndSettings(id) {
        setupGetAccountsAndSettings(id);

        electronicDocumentsService.getAdditionalSettingsAndAccounts(id).then(response => {
            endSpinner();

            if (response) {
                $scope.accountList = response.accounts;
                processSettings(response.additionalSettings);
            } else {
                displayError();
            }
        });
    }

    function loadIFrame() {
        $rootScope.$broadcast('retain_loader');
        angular.element('#electronicDocumentsForm').submit();
        angular.element('#electronicDocumentsIframe')[0].addEventListener(
            'load',
            () => {
                $rootScope.$broadcast('release_loader');
            },
            false
        );
    }

    function setupSso() {
        $scope.currentSpinnerGroupName = setupSpinner(
            'electronicDocumentSso',
            '/users/getElectronicDocumentSso'
        );
    }

    function processSso(response) {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                productName = 'Wausau';
                processWausauSso(response);
                break;

            case DocumentType.Esi:
                productName = 'Esi';
                processOtherSso(response);
                break;

            case DocumentType.PrintMail:
                productName = 'PrintMail';
                processOtherSso(response);
                break;

            default:
                productName = 'none';
                break;
        }

        isInitialized = true;
    }

    function setupGetAccountsAndSettings(id) {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                setupWausauGetAccountsAndSettings(id);
                break;

            default:
                break;
        }
    }

    function processSettings(settings) {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                processWausauSettings(settings);
                break;

            default:
                break;
        }
    }

    function setupGetReportList(account) {
        $scope.selectedAccount = account;

        switch (documentTypeId) {
            case DocumentType.Wausau:
                setupWausauGetReportList(account);
                break;

            default:
                break;
        }
    }

    function processReportList(reportList) {
        switch (documentTypeId) {
            case DocumentType.Wausau: {
                const years = processWausauReportList(reportList);

                isYearsLoaded = true;
                $scope.years = years;
                break;
            }
            default:
                break;
        }

        return reportList;
    }

    function setupGetReport() {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                setupWausauGetReport();
                break;

            default:
                break;
        }
    }

    function processReport(report, response) {
        switch (documentTypeId) {
            case DocumentType.Wausau:
                processWausauReport(report.fileName, response);
                break;

            default:
                break;
        }
    }

    function setupSpinner(groupName, path) {
        spinnerService.configureGroup(groupName, [path], ['electronicdocumentView.html']);

        spinnerService.startGroupRequest(groupName);

        return groupName;
    }

    function endSpinner() {
        if ($scope.currentSpinnerGroupName) {
            spinnerService.stopGroupRequest($scope.currentSpinnerGroupName);
            $scope.currentSpinnerGroupName = null;
        }
    }

    function displayError(errorNumber) {
        modalService.showModal(
            {},
            {
                alertType: 'Error',
                isAlert: true,
                summaryText: errorNumber ? `Error ${errorNumber}: ${errorMessage}` : errorMessage,
            }
        );
    }

    // Existing PrintMail & ESI SSO.
    function processOtherSso(response) {
        $scope.useFormPost = response.useFormPost;
        $scope.useIFrame = true;

        if ($scope.useFormPost) {
            $scope.actionUrl = $sce.trustAsResourceUrl(response.url);
            response.formFields.cssstring = require('../templates/esiStyle.html');

            const fields = [];
            for (const property in response.formFields) {
                if (response.formFields.hasOwnProperty(property)) {
                    fields.push({
                        name: property,
                        value: response.formFields[property],
                    });
                }
            }
            $scope.fields = fields;
            $timeout(() => {
                loadIFrame();
            }, 0);
        } else {
            $scope.url = $sce.trustAsResourceUrl(response.url);
        }
    }

    function processWausauSso(response) {
        $scope.useFormPost = false;
        $scope.useIFrame = false;
        $scope.electronicDocumentServiceName =
            response.electronicDocumentServiceName &&
            response.electronicDocumentServiceName.length !== 0
                ? response.electronicDocumentServiceName
                : defaultElectronicDocumentServiceName;

        getAccountsAndSettings(documentTypeId);
    }

    function setupWausauGetAccountsAndSettings(id) {
        $scope.currentSpinnerGroupName = setupSpinner(
            'electronicDocumentGetSettings',
            `/users/electronicdocuments/${id}/additionalsettingsandaccounts`
        );
    }

    function processWausauSettings(settings) {
        $scope.accountSelectText =
            settings.selectAccountLabel && settings.selectAccountLabel.length !== 0
                ? settings.selectAccountLabel
                : defaultAccountSelectText;

        if (settings.errorMessage && settings.errorMessage.length !== 0) {
            errorMessage = settings.errorMessage;
        }

        $scope.noStatementText =
            settings.noStatementMessage && settings.noStatementMessage.length !== 0
                ? settings.errorMessage
                : defaultNoStatementText;
    }

    function setupWausauGetReportList(account) {
        $scope.currentSpinnerGroupName = setupSpinner(
            'electronicDocumentGetStatements',
            `electronicdocuments/${productName}/reportlist/${account.id}`
        );
        isYearsLoaded = false;
        $scope.years = null;
        isStatementsLoaded = false;
        $scope.displayStatements = null;
    }

    function processWausauReportList(reportList) {
        const years = [];

        if (reportList && reportList.length > 0) {
            reportList.forEach(report => {
                const year = report.data.substring(0, 4);
                const month = report.data.substring(4, 6);
                const day = report.data.substring(6, 8);

                if (years.indexOf(year) === -1) {
                    years.push(year);
                }

                report.year = year;
                report.date = `${month}/${day}/${year}`;
                report.fileName = `${month}-${day}-${year}`;
            });

            years.sort();
        }

        return years;
    }

    function setupWausauGetReport() {
        $scope.currentSpinnerGroupName = setupSpinner(
            'electronicDocumentGetStatement',
            'electronicdocuments/report/'
        );
    }

    function processWausauReport(fileName, response) {
        if (response && response.data) {
            const blob = new Blob([response.data], {
                type: 'application/pdf',
            });

            FileSaver.saveAs(blob, `${fileName}.pdf`);
        } else {
            displayError();
        }
    }
}
