/**
 *
 * @param {(object|string)} checkDate any date object or string
 * @returns true if checkDate is tomorrow
 */
export function dateIsTomorrow(checkDate) {
    if (typeof checkDate === 'string') checkDate = new Date(checkDate);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        tomorrow.getYear() === checkDate.getYear() &&
        tomorrow.getMonth() === checkDate.getMonth() &&
        tomorrow.getDate() === checkDate.getDate()
    );
}
