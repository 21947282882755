export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                selectionList: '=',
                columnName: '@',
                controlId: '@',
                placeholder: '@',
                ngRequired: '=',
                includeTemplate: '=',
                transaction: '=?',
                type: '@',
                index: '=?',
                ngInvalid: '=?',
                disabled: '=',
                onChanged: '&?',
            },
            template: require('./typeAheadStatesTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.internalSelectionList = $scope.selectionList;

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue) {
                                $scope.internalSelectionList = $scope.selectionList;
                            }
                        },
                        true
                    );

                    $scope.$watch('ngModel', () => {
                        if ($scope.onChanged) $scope.onChanged();
                    });
                },
            ],
        };
    },
];
