import { LitElement, html, css } from 'lit';

class OmegaIconChevronDownOutline extends LitElement {
    render() {
        return html`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M15.9,9.4c-0.2,0-0.4,0.1-0.5,0.2l-3.5,3.3L8.4,9.7C8.1,9.5,7.8,9.6,7.6,9.8c-0.2,0.2-0.3,0.5-0.1,0.8
                    l4,3.8c0.3,0.2,0.7,0.2,1,0l4-3.8c0.2-0.2,0.3-0.5,0.2-0.8S16.2,9.4,15.9,9.4L15.9,9.4z"
                />
            </svg>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}
customElements.define('omega-icon-chevron-down-outline', OmegaIconChevronDownOutline);
