MockDataService.$inject = ['$resource'];

export default function MockDataService($resource) {
    return {
        getCashPositionData,
    };

    function getCashPositionData() {
        return $resource(resourceUrl).query().$promise;
    }
}
