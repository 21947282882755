import {
    IIssuedItem,
    IssuedItemPendingStatus,
    IssuedItemUiState,
} from '@treasury/domain/channel/mappings/arp';
import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import FieldTypes from '@treasury/policy/primitives';
import { exists } from '@treasury/utils';
import { html } from 'lit';

import { issuedItemCreationColumns } from '../definitions';
import { getIssuedItemColorAndIcon } from '../helpers';

type Account = IIssuedItem['account'];

const AccountField = new FieldType<Account>().with.template<Account, IIssuedItem>(
    (account, record) =>
        account ? `${record.values.accountName} (${account.type})` : 'Unknown account'
);

export const reviewStepFields: FdlFieldDefinitions<IIssuedItem> = {
    pendingStatus: (FieldTypes.string as unknown as FieldType<IssuedItemPendingStatus>).thatIs
        .readOnly()
        .with.template<IssuedItemPendingStatus, IIssuedItem>((pendingStatus, record) => {
            if (!exists(pendingStatus)) {
                return '';
            }

            let iconName = '';
            let color = 'black';

            const colorIcon = getIssuedItemColorAndIcon(record.values);
            if (colorIcon) {
                iconName = colorIcon.iconName;
                color = colorIcon.iconColor;
            }

            // set explicit width style to account for empty icon;
            // this is primarily to achieve consistent text alignment when no icon is displayed
            return html`<omega-icon
                icon="${iconName}"
                style="display:inline-block; padding: 5px; min-width: 25px; color: ${color}"
            ></omega-icon>`;
        })
        .with.validator<IIssuedItem>({
            name: 'issued-item-type-validator',
            validate: (pendingStatus, _, record) => {
                if (!exists(pendingStatus)) {
                    return false;
                }

                return record.values.errorState !== IssuedItemUiState.Error;
            },
        })
        .thatIs.sortable(false)
        .thatHas.maxColumnWidth(30)
        .thatHas.targetColumnWidth(30),
    type: (FieldTypes.string as FieldType<IIssuedItem['type']>).thatIs
        .readOnly()
        .thatHas.maxColumnWidth(50)
        .thatHas.targetColumnWidth(50),
    account: AccountField.thatIs.readOnly().thatHas.targetColumnWidth(250),
    checkNumber: FieldTypes.string.thatIs.readOnly().thatHas.targetColumnWidth(100) as FieldType<
        IIssuedItem['checkNumber']
    >,
    dateIssued: FieldTypes.date.thatIs.readOnly().thatHas.maxColumnWidth(100) as FieldType<
        IIssuedItem['dateIssued']
    >,
    checkAmount: FieldTypes.currency.thatIs
        .readOnly()
        .thatHas.maxColumnWidth(150)
        .thatHas.targetColumnWidth(150),
    payee: FieldTypes.string.thatIs
        .readOnly()
        .thatHas.maxColumnWidth(150)
        .thatHas.targetColumnWidth(150),
};

export const reviewStepColumns: OmegaColumnDefinition<IIssuedItem>[] = [
    {
        field: 'pendingStatus',
        label: '',
    },
    ...issuedItemCreationColumns,
];
