import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

class OmegaWorkflow extends LitElement {
    static get properties() {
        return {
            activeStep: { type: Number },
            stepLabels: { type: Array },
            startingIndex: { type: Number },
            showIndicator: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.activeStep = 0;
        this.startingIndex = 0;
        this.showIndicator = true;
        this.addEventListener('nextStep', this._handleNextStep);
        this.addEventListener('previousStep', this._handlePreviousStep);
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-workflow--simple',
        };
    }

    hasUniqueSlotsForEveryStep() {
        return this.stepContents?.length === this.stepLabels?.length;
    }

    // review updated usage
    updated() {
        if (!this.stepContents) return;
        if (this.hasUniqueSlotsForEveryStep()) this._setVisibleStep();
    }

    _handleSlotChange(e) {
        const nodes = [...e.target.assignedNodes({ flatten: true })];
        /* Assign the step contents to the active step when we only have one item in the nodes array
           so we can use the active step to get the contents */
        this.stepContents =
            nodes.length === 1
                ? Object.assign({}, ...nodes.map(n => ({ [this.activeStep]: n })))
                : nodes;
        this.stepLabels = this.stepLabels ?? this.stepContents.map(step => step.dataset.label);
        if (this.hasUniqueSlotsForEveryStep()) {
            this._setVisibleStep();
        }
    }

    _handleNextStep() {
        if (this.activeStep < this.stepLabels.length - 1) this.activeStep++;
    }

    _handlePreviousStep() {
        if (this.activeStep > 0) this.activeStep--;
    }

    _setVisibleStep() {
        this.stepContents.forEach(step => {
            step.style = 'display: none;';
        });

        if (this.stepContents[this.activeStep]) {
            this.stepContents[this.activeStep].style = '';
        }
    }

    renderStepIndicator() {
        return html`
            <ol>
                ${this.stepLabels?.map((step, idx) => {
                    const index = idx + this.startingIndex;
                    return html`
                        <li
                            class=${classMap({
                                active: this.activeStep === index,
                                completed: this.activeStep > index,
                            })}
                        >
                            <div>${idx + 1}. ${step}</div>
                        </li>
                    `;
                })}
            </ol>
        `;
    }

    render() {
        return html`
            <div class="omega-workflow">
                ${this.showIndicator
                    ? html`<div class="step-indicator">${this.renderStepIndicator()}</div>`
                    : nothing}
                <div class="step-content">
                    <slot name="step" @slotchange=${this._handleSlotChange}></slot>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
                .omega-workflow {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                .step-content {
                    padding: var(--workflow-content-padding, 10px);
                    margin-bottom: 55px;
                    flex: 1 1 auto;
                }
                .step-indicator {
                    background: #f7f7f7;
                }
                ol {
                    display: flex;
                    width: 100%;
                    padding: 0px;
                    margin: 0px;
                    position: relative;
                    list-style-type: none;
                    border-bottom: 4px solid #e6e6e6;
                }
                ol > li {
                    min-width: 200px;
                    height: 35px;
                    position: relative;
                    padding: 0px;
                    margin: 0px;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                    color: #747474;
                }
                ol > li.active {
                    background-color: var(--omega-primary-darken-100);
                    color: var(--omega-white);
                    border-top: 1px solid var(--omega-primary-darken-100);
                    border-bottom: 1px solid var(--omega-primary-darken-100);
                }
                ol > li.completed {
                    background-color: #f7f7f7;
                    color: #747474;
                    border-top: 1px solid #f7f7f7;
                    border-bottom: 1px solid #f7f7f7;
                }
                ol > li > div {
                    position: relative;
                    line-height: 35px; /* equal to the list item's height */
                    height: 100%;
                    width: 100%;
                    padding-left: 30px;
                }
                ol > li > div:after,
                ol > li > div:before {
                    content: '';
                    display: inline-block;
                    border-width: 18px;
                    border-style: solid;
                    width: 0px;
                    height: 0px;
                    left: calc(100% - 30px);
                    position: absolute;
                    z-index: 1;
                }
                ol > li > div:after {
                    border-color: transparent transparent transparent #cccccc;
                }
                ol > li.active > div:before,
                ol > li.active > div:after {
                    border-color: transparent transparent transparent
                        var(--omega-primary-darken-100);
                }
                ol > li.completed > div:before,
                ol > li.completed > div:after {
                    border-color: transparent transparent transparent #f7f7f7;
                }
                ol > li > div:before {
                    border-width: 17px;
                    display: block;
                    border-color: transparent transparent transparent #f7f7f7;
                    z-index: 2;
                    top: 1px;
                }
            `,
        ];
    }

    // NEW version....
    // Carl says we can't use it yet, but I'm not throwing away someone's work.
    // static get styles() {
    //     return [
    //         css`
    //             :host {
    //                 display: block;
    //             }
    //             .omega-workflow {
    //                 width: 100%;
    //                 height: 100%;
    //                 display: flex;
    //                 flex-direction: column;
    //             }
    //             .step-content {
    //                 padding: var(--workflow-content-padding, 10px);
    //                 margin-bottom: 55px;
    //                 flex: 1 1 auto;
    //             }
    //             .step-indicator {
    //                 background: #f7f7f7;
    //             }
    //             ol {
    //                 display: flex;
    //                 width: 100%;
    //                 padding: 0px;
    //                 margin: 0px;
    //                 position: relative;
    //                 list-style-type: none;
    //             }
    //             ol > li {
    //                 min-width: 200px;
    //                 height: 30px;
    //                 position: relative;
    //                 padding: 0px;
    //                 margin: 0px;
    //                 text-align: left;
    //                 font-size: 13px;
    //                 font-weight: 500;
    //                 color: #747474;
    //                 border-top-left-radius: 4px;
    //                 border-bottom-left-radius: 4px;
    //                 background-color: #dddddd;
    //                 border-bottom: 1px solid #dddddd;
    //                 border-top: 1px solid #dddddd;
    //             }

    //             ol > li.active {
    //                 background-color: var(--omega-primary);
    //                 color: var(--omega-white);
    //                 border-top: 1px solid var(--omega-primary);
    //                 border-bottom: 1px solid var(--omega-primary);
    //             }
    //             ol > li.completed {
    //                 background-color: #34aa44;
    //                 color: #f7f7f7;
    //                 border-top: 1px solid #34aa44;
    //                 border-bottom: 1px solid #34aa44;
    //             }
    //             ol > li > div {
    //                 position: relative;
    //                 line-height: 30px; /* equal to the list item's height */
    //                 height: 100%;
    //                 width: 100%;
    //                 padding-left: 30px;
    //             }
    //             ol > li > div:after,
    //             ol > li > div:before {
    //                 content: '';
    //                 display: inline-block;
    //                 border-width: 15px;
    //                 border-style: solid;
    //                 width: 0px;
    //                 height: 0px;
    //                 left: calc(100% - 30px);
    //                 position: absolute;
    //                 z-index: 1;
    //             }
    //             ol > li > div:after {
    //                 border-color: transparent transparent transparent #dddddd;
    //             }
    //             ol > li.active > div:before,
    //             ol > li.active > div:after {
    //                 border-color: transparent transparent transparent var(--omega-primary);
    //             }
    //             ol > li.completed > div:before,
    //             ol > li.completed > div:after {
    //                 border-color: transparent transparent transparent #34aa44;
    //             }
    //             ol > li > div:before {
    //                 border-width: 15px;
    //                 display: block;
    //                 border-color: transparent transparent transparent #dddddd;
    //                 z-index: 2;
    //                 top: 1px;
    //             }
    //         `,
    //     ];
    // }
}

customElements.define('omega-workflow', OmegaWorkflow);
export default OmegaWorkflow;
