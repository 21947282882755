import AchNoticeOfChangeServices from '@treasury/domain/channel/services/ach/ach-notice-of-change-services.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';

export class AchNoticeOfChangeActivityClient {
    async search(...args) {
        return AchNoticeOfChangeServices.search(...args);
    }

    async searchV2(...args) {
        return AchNoticeOfChangeServices.searchV2(...args);
    }

    async userActivity(...args) {
        return UserActivityService.print(...args);
    }

    async saveUserActivity(...args) {
        return UserActivityService.saveUserActivity(...args);
    }

    async getCompanies() {
        return AchNoticeOfChangeServices.getCompanies();
    }

    async getDetails(...args) {
        return AchNoticeOfChangeServices.getDetails(...args);
    }

    async getDetailsV2(...args) {
        return AchNoticeOfChangeServices.getDetailsV2(...args);
    }

    async downloadNotice(...args) {
        return AchNoticeOfChangeServices.downloadNotice(...args);
    }

    async downloadNoticeV2(...args) {
        return AchNoticeOfChangeServices.downloadNoticeV2(...args);
    }

    async downloadNoticeReport(...args) {
        return AchNoticeOfChangeServices.downloadNoticeReport(...args);
    }
}
