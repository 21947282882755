ResizerDirective.$inject = ['$window', '$timeout'];

export default function ResizerDirective($window, $timeout) {
    return function (scope, element, attr) {
        const w = angular.element($window);
        scope.$watch(
            () => ({
                h: w.height(),
                w: w.width(),
            }),
            newValue => {
                scope.windowHeight = newValue.h;
                scope.windowWidth = newValue.w;

                scope.resizeWithOffset = function (offsetH) {
                    return {
                        height: `${newValue.h - offsetH}px`,
                    };
                };

                scope.resizeWithOffsetMaxHeight = function (offsetH) {
                    return {
                        'max-height': `${newValue.h - offsetH}px`,
                    };
                };

                scope.resizeWithOffsetW = function (offsetW) {
                    return {
                        width: `${newValue.w - offsetW}px`,
                    };
                };

                scope.setHeight = function () {
                    const height = element[0].querySelector('.panel').offsetHeight;
                    return {
                        'margin-top': `-${height / 2}px`,
                    };
                };

                scope.pageWrapper = function (offsetW) {
                    let result = '0px';

                    if (newValue.w <= offsetW) {
                        result = '65px';
                    }
                    return {
                        'margin-top': result,
                    };
                };

                scope.documentWidthIsSmaller = function (offsetW) {
                    let isSmaller = false;

                    if (newValue.w <= offsetW) {
                        isSmaller = true;
                    }
                    return isSmaller;
                };
            },
            true
        );

        w.bind('resize', () => {
            $timeout(() => {
                scope.$apply();
            });
        });
    };
}
