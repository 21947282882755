CreatePaymentSingleTaxController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    '$controller',
    'statesService',
    'taxesService',
    'usersService',
    'achBatchService',
    'taxPaymentAccountTypes',
    'achPaymentTypes',
    'scopeService',
    'entitlementsService',
    'achCompaniesService',
    'achSettingsService',
];

export default function CreatePaymentSingleTaxController(
    $scope,
    $state,
    $uibModal,
    $controller,
    statesService,
    taxesService,
    usersService,
    achBatchService,
    taxPaymentAccountTypes,
    achPaymentTypes,
    scopeService,
    entitlementsService,
    achCompaniesService,
    achSettingsService
) {
    angular.extend(this, $controller('CreatePaymentSingleBatchController', { $scope }));
    const maximumNumberOfAmounts = 3;
    const firstStateAmountTypeId = 1;

    $scope.title = 'Create ACH Tax Payment';
    $scope.stateTaxCodes = [];
    $scope.federalTaxAmountTypes = [];
    $scope.findAccountForBalancedCompany = findAccountForBalancedCompany;
    $scope.achCompanyUndefined = achCompanyUndefined;
    $scope.isPrefunding = isPrefunding;
    $scope.isObject = isObject;
    $scope.stateChanged = stateChanged;
    $scope.stateUndefined = stateUndefined;
    $scope.recipientsLoaded = recipientsLoaded;
    $scope.findStateTaxCodes = findStateTaxCodes;
    $scope.findFederalTaxCodes = findFederalTaxCodes;
    $scope.findStates = findStates;
    $scope.stateTaxCodesLoaded = stateTaxCodesLoaded;
    $scope.federalTaxCodesLoaded = federalTaxCodesLoaded;
    $scope.stateTaxCodeChanged = stateTaxCodeChanged;
    $scope.federalTaxCodeChanged = federalTaxCodeChanged;
    $scope.addAmountType = addAmountType;
    $scope.deleteAmountType = deleteAmountType;
    $scope.addFederalAmountType = addFederalAmountType;
    $scope.deleteFederalAmountType = deleteFederalAmountType;
    $scope.onSelectBank = onSelectBank;
    $scope.statesLoaded = statesLoaded;
    $scope.isValidTaxCode = isValidTaxCode;
    $scope.taxHolidayDates = [];
    $scope.batch.recipientAccountTypeId = 1;
    $scope.getStateAmountTypeDescription = getStateAmountTypeDescription;
    $scope.getFederalAmountTypeDescription = getFederalAmountTypeDescription;
    $scope.getRecipientAccountTypeDescription = getRecipientAccountTypeDescription;
    $scope.getStateLongName = getStateLongName;
    $scope.createAnotherTaxPayment = createAnotherTaxPayment;
    $scope.canFullEdit = canFullEdit;
    $scope.federalAmountTypeChanged = federalAmountTypeChanged;
    $scope.setState = setState;
    $scope.getTaxCodeDescription = getTaxCodeDescription;
    $scope.getPayFromAccountName = getPayFromAccountName;
    $scope.isCopy = isCopy;
    $scope.saveAsTemplate = saveAsTemplate;
    $scope.shouldDisableAchPaymentTypeId = shouldDisableAchPaymentTypeId;
    $scope.isAchPrefundingEnabled = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowUnbalancedPayments'
    );
    $scope.shouldShowOffsetReadOnly = shouldShowOffsetReadOnly;

    $scope.isZeroDollarStateTaxPaymentEnabled =
        entitlementsService.hasEntitlement('Feature.ACH.ZeroDollarStateTaxPayments') || false;
    $scope.achStateTaxPaymentAmountsMinimum = $scope.isZeroDollarStateTaxPaymentEnabled ? 0 : 0.01;
    $scope.zeroDollarClick = zeroDollarClick;
    function zeroDollarClick(event, index) {
        const { checked } = event.target;
        const amountInput = document.getElementById(`amount-${index}`);
        if (checked) {
            amountInput.disabled = true;
            $scope.batch.achStateTaxPaymentAmounts[index].amount = 0;
        } else {
            amountInput.disabled = false;
        }
    }
    function shouldDisableAchPaymentTypeId() {
        return isCopy() || $scope.isCreatedFromBatch;
    }

    function isCopy() {
        return $state.params.paymentToCopy != null;
    }

    function isBalancedAchCompany() {
        const payment = $scope.batch;

        return (
            payment &&
            payment.achCompany &&
            payment.achCompany.companyName &&
            payment.achCompany.batchBalanceRequirements === 'Balanced'
        );
    }

    function getOffsetAccountName() {
        const payment = $scope.batch;
        if (!payment) return '';
        if (payment.achCompany.prefundingDays > 0 || payment.achCompany.allowUnbalancedPayments)
            return payment.achCompany.offsetAccountNumber;
        if (!payment.offsetAccount) return '';

        const { offsetAccount } = payment;
        return offsetAccount.showAccountNickname ? offsetAccount.nickName : offsetAccount.number;
    }

    function getBalancedAccountName() {
        const payment = $scope.batch;

        if (!payment || !payment.balancedAccount) {
            return '';
        }

        return payment.balancedAccount.number;
    }

    function getPayFromAccountName() {
        if (isBalancedAchCompany()) {
            return getBalancedAccountName();
        }

        return getOffsetAccountName();
    }

    function federalAmountTypeChanged(index) {
        const { amountType } = $scope.batch.achFederalTaxPaymentAmounts[index];

        if ($scope.federalTaxAmountTypes && $scope.federalTaxAmountTypes.length) {
            $scope.batch.achFederalTaxPaymentAmounts[index].achFederalAmountTypeId =
                taxesService.getFederalAmountType(
                    $scope.federalTaxAmountTypes,
                    amountType
                ).achFederalAmountTypeId;
        }
    }

    function onSelectBank(bank) {
        if (bank) {
            $scope.batch.recipientRoutingNumber = bank.bankId;
        }
    }

    function getTaxCodeDescription() {
        let taxCode;

        if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            if ($scope.federalTaxCodes) {
                taxCode = $scope.federalTaxCodes.find(
                    federalTaxCode => federalTaxCode.taxCode === $scope.batch.taxCode
                );
            }
        } else if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            if ($scope.stateTaxCodes) {
                taxCode = $scope.stateTaxCodes.find(
                    stateTaxCode => stateTaxCode.taxCode === $scope.batch.taxCode
                );
            }
        }

        if (taxCode) {
            return taxCode.description;
        }

        return '';
    }

    function getStateAmountTypeDescription(achStateAmountTypeId) {
        return taxesService.getStateAmountTypeDescription(
            $scope.stateTaxAmountTypes,
            achStateAmountTypeId
        );
    }

    function getFederalAmountTypeDescription(amountType) {
        return taxesService.getFederalAmountTypeDescription(
            $scope.federalTaxAmountTypes,
            amountType
        );
    }

    function getRecipientAccountTypeDescription(recipientAccountTypeId) {
        return taxesService.getRecipientAccountTypeDescription(
            $scope.recipientAccountTypes,
            recipientAccountTypeId
        );
    }

    function loadUserAccounts() {
        usersService.getAccountsAccessibleToUser().then(response => {
            const accounts = response || [];
            $scope.userAccounts = accounts.filter(
                item =>
                    item.type === taxPaymentAccountTypes.Savings ||
                    item.type === taxPaymentAccountTypes.Checking
            );
        });
    }

    function addAmountType() {
        if ($scope.batch.achStateTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        $scope.batch.achStateTaxPaymentAmounts.push({
            achStateAmountTypeId: firstStateAmountTypeId,
            amount: 0,
        });
    }

    function deleteAmountType(index) {
        $scope.batch.achStateTaxPaymentAmounts.splice(index, 1);
    }

    function addFederalAmountType() {
        if ($scope.batch.achFederalTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        $scope.batch.achFederalTaxPaymentAmounts.push({
            achFederalAmountTypeId: $scope.federalTaxAmountTypes[0].achFederalAmountTypeId,
            amountType: $scope.federalTaxAmountTypes[0].amountType,
            amount: 0,
        });
    }

    function deleteFederalAmountType(index) {
        $scope.batch.achFederalTaxPaymentAmounts.splice(index, 1);
    }

    function getStateLongName(id) {
        if (!statesLoaded()) return '';

        const selected = find($scope.states, item => item.id === id);

        return selected ? selected.value : '';
    }

    function find(array, predicate) {
        for (let i = 0; i < array.length; i++) {
            if (predicate(array[i])) return array[i];
        }
    }

    function stateChanged(state) {
        if (!statesLoaded()) return;

        if (!(state && state.id)) {
            const selected = find(
                $scope.states,
                item =>
                    state &&
                    (item.key.toLowerCase() === state.toLowerCase() ||
                        item.value.toLowerCase() === state.toLowerCase())
            );

            if (selected) {
                $scope.batch.selectedState = selected;
                $scope.batch.stateId = selected.id;

                getStateTaxCodes($scope.batch.stateId);
                return;
            }

            $scope.batch.stateId = '';

            $scope.batch.selectedStateTaxCode = '';
            $scope.batch.taxCode = '';
            return;
        }

        if (
            $scope.batch.selectedState &&
            $scope.batch.selectedState.key === state.key &&
            $scope.batch.stateId === state.id
        ) {
            return;
        }

        $scope.batch.selectedState = state;
        $scope.batch.stateId = state.id;

        getStateTaxCodes($scope.batch.stateId);
    }

    function federalTaxCodeChanged(ftc) {
        if (!federalTaxCodesLoaded()) return;

        if (!(ftc && ftc.taxCode)) {
            if (isValidTaxCode(ftc)) {
                $scope.batch.taxCode = ftc;
                $scope.batch.selectedFederalTaxCode = ftc;

                const selected = find($scope.federalTaxCodes, item => item.taxCode === ftc);
                if (selected) {
                    $scope.batch.selectedFederalTaxCode = selected;
                    $scope.batch.taxCode = ftc.taxCode;

                    getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode);
                    return;
                }
            } else {
                $scope.batch.taxCode = '';
            }

            if ($scope.federalTaxAmountTypes) $scope.federalTaxAmountTypes.length = 0;

            return;
        }

        if (
            $scope.batch.selectedFederalTaxCode &&
            $scope.batch.selectedFederalTaxCode.taxCode === ftc.taxCode &&
            $scope.batch.taxCode === ftc.taxCode
        ) {
            return;
        }

        $scope.batch.selectedFederalTaxCode = ftc;
        $scope.batch.taxCode = ftc.taxCode;

        getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode);
    }

    function stateTaxCodeChanged(stc) {
        if (!(stc && stc.taxCode)) {
            if (isValidTaxCode(stc)) {
                $scope.batch.taxCode = stc;
                $scope.batch.selectedStateTaxCode = stc;
            } else {
                $scope.batch.taxCode = '';
                $scope.batch.selectedStateTaxCode = '';
            }

            return;
        }

        $scope.batch.selectedStateTaxCode = stc;
        $scope.batch.taxCode = stc.taxCode;
    }

    function getStateTaxCodes(stateId) {
        return taxesService.getStateTaxCodes(stateId).then(response => {
            $scope.stateTaxCodes = response;
        });
    }

    function getFederalTaxCodes() {
        taxesService.getFederalTaxCodes().then(response => {
            $scope.federalTaxCodes = response;

            if ($scope.batch.taxCode) {
                const taxCode = $scope.federalTaxCodes.find(
                    item => item.taxCode === $scope.batch.taxCode
                );

                if (taxCode) {
                    $scope.batch.selectedFederalTaxCode = taxCode;

                    taxesService.getFederalTaxAmountTypes(taxCode.id).then(response => {
                        $scope.federalTaxAmountTypes = response;
                    });
                }
            }
        });
    }

    function getStateTaxAmountTypes() {
        taxesService.getStateTaxAmountTypes().then(response => {
            $scope.stateTaxAmountTypes = response;
        });
    }

    function getFederalTaxAmountTypes(selectedFederalTaxCode) {
        taxesService.getFederalTaxAmountTypes(selectedFederalTaxCode.id).then(response => {
            $scope.federalTaxAmountTypes = response;
            initFederalTaxPaymentAmounts(selectedFederalTaxCode);
        });
    }

    function initFederalTaxPaymentAmounts(selectedFederalTaxCode) {
        if ($scope.federalTaxAmountTypes && $scope.federalTaxAmountTypes.length)
            $scope.batch.achFederalTaxPaymentAmounts = [
                {
                    achFederalAmountTypeId: $scope.federalTaxAmountTypes[0].achFederalAmountTypeId,
                    amountType: $scope.federalTaxAmountTypes[0].amountType,
                    amount: 0,
                },
            ];
        else
            $scope.batch.achFederalTaxPaymentAmounts = [
                {
                    achFederalAmountTypeId: null,
                    amountType: selectedFederalTaxCode.taxCode,
                    amount: 0,
                },
            ];
    }

    function getRecipientAccountTypes() {
        taxesService.getRecipientAccountTypes().then(response => {
            $scope.recipientAccountTypes = response;
        });
    }

    function findAccountForBalancedCompany() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/userAccountsModalView.html'),
            size: 'md',
            controller: 'UserAccountsController',
            backdrop: 'static',
            resolve: {
                companyAccounts() {
                    return $scope.userAccounts;
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            $scope.batch.balancedAccount = angular.copy(selectedAccount);
            $scope.batch.payFromAccountId = selectedAccount.id;
            $scope.form.$setDirty();
        });
    }

    function findStateTaxCodes() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.stateTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedStateTaxCode => {
            $scope.batch.selectedStateTaxCode = angular.copy(selectedStateTaxCode);
            $scope.batch.taxCode = selectedStateTaxCode.taxCode;
        });
    }

    function findFederalTaxCodes() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.federalTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedFederalTaxCode => {
            $scope.batch.selectedFederalTaxCode = angular.copy(selectedFederalTaxCode);
        });
    }

    function findStates() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/selectStatesModalView.html'),
            size: 'md',
            controller: 'SelectStatesController',
            backdrop: 'static',
            resolve: {
                states() {
                    return $scope.states;
                },
            },
        });
        modalInstance.result.then(selectedState => {
            $scope.batch.selectedState = angular.copy(selectedState);
        });
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function isValidTaxCode(value) {
        return taxesService.isValidTaxCode(value);
    }

    function achCompanyUndefined() {
        return !$scope.batch.achCompanyName || !$scope.validateAchCompany();
    }

    function stateUndefined() {
        return !$scope.batch.stateId;
    }

    function recipientsLoaded() {
        return $scope.recipients && $scope.recipients.length;
    }

    function stateTaxCodesLoaded() {
        return $scope.stateTaxCodes && $scope.stateTaxCodes.length;
    }

    function federalTaxCodesLoaded() {
        return $scope.federalTaxCodes && $scope.federalTaxCodes.length;
    }

    function statesLoaded() {
        return $scope.states && $scope.states.length;
    }

    function getStates() {
        statesService.getAll().then(response => {
            $scope.states = response;
        });
    }

    async function createBatch() {
        if (!$scope.batch) {
            $scope.batch = $scope.payment || new achBatchService.Batch();
        }

        $scope.batch.achStateTaxPaymentAmounts = [
            { achStateAmountTypeId: firstStateAmountTypeId, amount: 0 },
        ];

        if ($state.params.paymentToCopy) {
            const originalPayment = $state.params.paymentToCopy;
            const payment = $scope.batch;

            payment.name = originalPayment.name;
            payment.achPaymentTypeId = originalPayment.achPaymentTypeId;
            payment.taxPayerId = originalPayment.taxPayerId;
            payment.taxPeriodEndDate = originalPayment.taxPeriodEndDate;
            payment.recipientRoutingNumber = originalPayment.recipientRoutingNumber;
            payment.recipientName = originalPayment.recipientName;
            payment.recipientIdNumber = originalPayment.recipientIdNumber;
            payment.recipientAccountTypeId = originalPayment.recipientAccountTypeId;
            payment.recipientAccountNumber = originalPayment.recipientAccountNumber;
            payment.restricted = originalPayment.restricted;
            payment.secCode = originalPayment.secCode;
            payment.achCompanyId = originalPayment.achCompanyId;
            payment.achCompanyName = originalPayment.achCompanyName;
            payment.achCompany = originalPayment.achCompany;
            payment.discretionaryData = originalPayment.discretionaryData;
            payment.entryDescription = originalPayment.entryDescription;
            payment.copiedFromTransactionId = originalPayment.transactionId;
            payment.frequency = {
                effectiveDate: originalPayment.frequency.effectiveDate,
                type: originalPayment.frequency.type,
            };

            setPayFromAccount(originalPayment.payFromAccountId);

            if (payment.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
                payment.taxCode = originalPayment.taxCode;
                payment.achFederalTaxPaymentAmounts = [];

                originalPayment.achFederalTaxPaymentAmounts.forEach(item => {
                    payment.achFederalTaxPaymentAmounts.push({
                        amountType: item.amountType,
                        amount: item.amount,
                    });
                });
            } else if (payment.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
                setState(originalPayment.stateId);
                setStateTaxCode(originalPayment.taxCode);
                payment.achStateTaxPaymentAmounts = [];

                originalPayment.achStateTaxPaymentAmounts.forEach(item => {
                    payment.achStateTaxPaymentAmounts.push({
                        achStateAmountTypeId: item.achStateAmountTypeId,
                        amount: item.amount,
                    });
                });
            }
        }
        await populateAchCompany();
    }

    function createAnotherTaxPayment() {
        $state.go(
            'payables.ach.payments.createTaxPayment',
            {
                masterRecipients: null,
                paymentToCopy: null,
            },
            {
                reload: true,
            }
        );
    }

    function saveAsTemplate() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/saveAsTemplateModal.html'),
            size: 'md',
            controller: 'SaveAsTemplateController',
            backdrop: 'static',
            resolve: {
                batch() {
                    return $scope.batch;
                },
            },
        });

        modalInstance.result.then(batch => {
            $state.go('payables.ach.payments.tax-templates');
        });
    }

    function canFullEdit() {
        if (!$scope.isCreatedFromBatch) return true;

        return $scope.achFull;
    }

    function configureTaxPayments(achSettings) {
        if (achSettings) {
            if (achSettings.allowFederalTaxPayments) {
                $scope.batch.achPaymentTypeId = achPaymentTypes.AchFederalTaxPayment;
            } else if (achSettings.allowStateTaxPayments) {
                $scope.batch.achPaymentTypeId = achPaymentTypes.AchStateTaxPayment;
            }
        }
    }

    function whenDefined(expression, callback) {
        scopeService.whenDefined(expression, callback, $scope);
    }

    function setAchPaymentType() {
        if (!$scope.batch.achPaymentTypeId) {
            whenDefined('achSettings', () => {
                configureTaxPayments($scope.achSettings);
            });
        }
    }

    function setPayFromAccount(payFromAccountId) {
        const { achCompany } = $scope.batch;
        if (achCompany) {
            if (achCompany.batchBalanceRequirements === 'Balanced') {
                whenDefined('userAccounts', () => {
                    $scope.batch.balancedAccount = $scope.userAccounts.find(
                        item => payFromAccountId === item.id
                    );

                    $scope.batch.payFromAccountId = payFromAccountId;
                });
            } else {
                whenDefined('offsetAccounts', () => {
                    $scope.batch.payFromAccountId = payFromAccountId;
                    $scope.batch.offsetAccount = $scope.offsetAccounts.find(
                        item => payFromAccountId === item.id
                    );
                });
            }
        }
    }

    function setState(stateId) {
        setStateAndTaxCode(stateId);
    }

    function setStateAndTaxCode(stateId, taxCode) {
        $scope.batch.stateId = stateId;

        whenDefined('states', () => {
            $scope.batch.selectedState = $scope.states.find(state => stateId === state.id);

            if ($scope.batch.selectedState) {
                getStateTaxCodes(stateId);
                if (taxCode) setStateTaxCode(taxCode);
            }
        });
    }

    function setStateTaxCode(code) {
        getStateTaxCodes($scope.batch.stateId).then(() => {
            $scope.batch.taxCode = code;

            const taxCode = $scope.stateTaxCodes.find(
                item => item.taxCode === $scope.batch.taxCode
            );

            $scope.batch.selectedStateTaxCode = taxCode;
        });
    }

    function setFederalAmountTypes(taxCode, achFederalTaxPaymentAmounts) {
        whenDefined('federalTaxCodes', () => {
            if (taxCode) {
                $scope.batch.selectedFederalTaxCode = $scope.federalTaxCodes.find(
                    item => item.taxCode === taxCode
                );

                if ($scope.batch.selectedFederalTaxCode) {
                    taxesService
                        .getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode.id)
                        .then(response => {
                            $scope.federalTaxAmountTypes = response;
                            if (achFederalTaxPaymentAmounts && achFederalTaxPaymentAmounts.length) {
                                $scope.batch.achFederalTaxPaymentAmounts =
                                    achFederalTaxPaymentAmounts;
                            } else {
                                initFederalTaxPaymentAmounts($scope.batch.selectedFederalTaxCode);
                            }
                        });
                }
            }
        });
    }

    function setStateAmounts(achStateTaxPaymentAmounts) {
        whenDefined('stateTaxAmountTypes', () => {
            if (achStateTaxPaymentAmounts && achStateTaxPaymentAmounts.length) {
                $scope.batch.achStateTaxPaymentAmounts = [];

                achStateTaxPaymentAmounts.forEach(item => {
                    $scope.batch.achStateTaxPaymentAmounts.push({
                        achStateAmountTypeId: item.achStateAmountTypeId,
                        amount: item.amount,
                    });
                });
            }
        });
    }

    function setFieldsForExistingBatch() {
        whenDefined('batch.id', () => {
            if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
                setFederalAmountTypes(
                    $scope.batch.taxCode,
                    $scope.batch.achFederalTaxPaymentAmounts
                );
            } else if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
                setStateAndTaxCode($scope.batch.stateId, $scope.batch.taxCode);
                setStateAmounts($scope.batch.achStateTaxPaymentAmounts);
            }
        });
    }

    function isPrefunding() {
        if ($scope.isAchPrefundingEnabled && $scope.batch.achCompany?.prefundingDays > 0) {
            return true;
        }
        return false;
    }

    function getAchSettings() {
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function getAchSettingsAllowUnbalancedPayments() {
        return $scope.achSettings?.allowUnbalancedPayments ?? true;
    }

    function allowUnbalancedPayments() {
        return $scope.isAchPrefundingEnabled && $scope.batch.achCompany?.allowUnbalancedPayments;
    }

    function shouldShowOffsetReadOnly() {
        return (
            isPrefunding() || (allowUnbalancedPayments() && getAchSettingsAllowUnbalancedPayments())
        );
    }

    async function populateAchCompany() {
        const achCompanies = await achCompaniesService.getAll();
        const achCompany = achCompanies.find(company => company.id === $scope.batch.achCompany.id);
        if (achCompany) {
            $scope.batch.achCompany.prefundingDays = achCompany.prefundingDays;
            $scope.batch.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
            $scope.batch.achCompany.allowUnbalancedPayments = achCompany.allowUnbalancedPayments;
        }
    }

    (async function () {
        // init
        setFieldsForExistingBatch();
        await createBatch();
        loadUserAccounts();
        getStates();
        getFederalTaxCodes();
        getStateTaxAmountTypes();
        getRecipientAccountTypes();
        getAchSettings();
        setAchPaymentType();
    })();
}
