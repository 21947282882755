import { DiContainer } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';

ArpExceptionsListController.$inject = [
    '$filter',
    '$uibModal',
    '$timeout',
    'toaster',
    'companyAccountsService',
    'arpService',
    '$stateParams',
    '$state',
    'TmDi',
    'downloadPageId',
];

/**
 * @param { DiContainer } TmDi 
 */
export default function ArpExceptionsListController(
    $filter,
    $uibModal,
    $timeout,
    toaster,
    companyAccountsService,
    arpService,
    $stateParams,
    $state,
    TmDi,
    downloadPageId
) {
    const analyticsService = TmDi.get(AnalyticsService);
    let canDisplayCurrentData = false;
    let isPastStartTime = null;

    // Public controller properties
    const vm = this;
    vm.downloadPageId = downloadPageId.ArpExceptionsList;
    vm.maxDate = new Date();
    vm.searchParams = {
        type: 'OpenItems',
        decisionTaken: 'both',
    };
    vm.downloadOptions = {
        downloadFormats: ['PDF', 'CSV'],
        pageId: vm.downloadPageId,
        filterModel: vm.searchParams,
    };
    vm.exportOptions = { canPrint: true };
    vm.accounts = []; // Accounts list in Search filter
    vm.issuedAmountOptions = ['Specific Amount', 'Amount Range'];
    vm.paidAmountOptions = ['Specific Amount', 'Amount Range'];
    vm.dateOptions = ['Specific Date', 'Date Range'];
    vm.searchText = '';
    vm.allowEdit = false;
    vm.accountDisplayField = '';
    vm.arpExceptionsPristine = {};
    vm.arpExceptionsWorking = {};
    vm.filteredExceptions = [];
    vm.showHistoryMode = false;
    vm.isDataLoaded = false;
    vm.alertMessage = null;
    vm.hasEtlRunSuccessfully = null;

    // Public controller function delegates
    vm.search = search;
    vm.filterSearch = filterSearch;
    vm.review = review;

    vm.reset = function () {
        // For this case, select all of the accounts, regardless of the page parameter
        vm.accounts.forEach(account => {
            account.isChecked = true;
        });
        initReset();
    };

    vm.undoChanges = function () {
        vm.arpExceptionsWorking = angular.copy(vm.arpExceptionsPristine);
        filterSearch(vm.searchText);
    };

    vm.historyFilterSelected = function () {
        return vm.searchParams.type === 'History';
    };

    vm.canDecision = function (exception) {
        return exception && exception.protected !== 'Y';
    };

    vm.showPayLock = function (exception) {
        return (
            vm.canDecision(exception) === false &&
            exception.pay !== false &&
            exception.decisionTaken != 'No Decision'
        );
    };

    vm.showReturnLock = function (exception) {
        return (
            vm.canDecision(exception) === false &&
            exception.pay !== true &&
            exception.decisionTaken != 'No Decision'
        );
    };

    vm.disableDetailDecisionButton = function () {
        return !vm.allowEdit || vm.showHistoryMode;
    };

    vm.setDecisionToExistingDecision = function (exception) {
        exception.pay = exception.existingDecision;
        filterSearch(vm.searchText);
    };

    vm.setAllPay = function () {
        vm.filteredExceptions.forEach(exception => {
            if (exception.protected !== 'Y') {
                exception.pay = true;
            }
        });
        filterSearch(vm.searchText);
    };

    vm.setAllReturn = function () {
        vm.filteredExceptions.forEach(exception => {
            if (exception.protected !== 'Y') {
                exception.pay = false;
            }
        });
        filterSearch(vm.searchText);
    };

    vm.resetIssuedAmount = function () {
        if (vm.searchParams) {
            vm.searchParams.issuedAmount = '';
            vm.searchParams.issuedAmountEnd = '';
            vm.searchParams.issuedAmountStart = '';
        }
    };

    vm.resetPaidAmount = function () {
        if (vm.searchParams) {
            vm.searchParams.paidAmount = '';
            vm.searchParams.paidAmountEnd = '';
            vm.searchParams.paidAmountStart = '';
        }
    };

    vm.resetIssuedDate = function () {
        if (vm.searchParams) {
            vm.searchParams.issuedDate = '';
            vm.searchParams.issuedDateEnd = '';
            vm.searchParams.issuedDateStart = '';
        }
    };

    vm.resetPostedDate = function () {
        if (vm.searchParams) {
            vm.searchParams.postedDate = '';
            vm.searchParams.postedDateEnd = '';
            vm.searchParams.postedDateStart = '';
        }
    };

    vm.allowDataDisplay = function () {
        const anyData = vm.filteredExceptions.length != null && vm.filteredExceptions.length > 0;
        return anyData && (canDisplayCurrentData || vm.showHistoryMode);
    };

    vm.displayAlertMessage = function () {
        return vm.alertMessage && vm.historyFilterSelected() === false;
    };

    vm.displayEmptyStateDiv = function () {
        return (
            (vm.searchParams.type === 'OpenItems' && vm.hasEtlRunSuccessfully) ||
            vm.searchParams.type === 'History'
        );
    };

    // Local Functions
    //
    function getPayState(decisionCode) {
        if (decisionCode === 'Pay') {
            return true;
        }
        if (decisionCode === 'Return') {
            return false;
        }
        return null;
    }

    function getDecisionItems() {
        const decisionItems = [];
        vm.filteredExceptions.forEach(exception => {
            decisionItems.push(exception);
        });
        return decisionItems;
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('ArpException').then(response => {
            const timeZone = response.fiTimeZone;
            const timeUntilStart = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.startTime
                }`
            ).diff(response.currentFiTime);
            const timeUntilCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);

            const startTime = convertTime(response.processingCutoff.startTime);
            const cutoffTime = convertTime(response.processingCutoff.cutoffTime);

            const startAlertMessage = `Exception items are not available at this time.  Please try again after ${startTime} (${timeZone}).`;
            const cutoffAlertMessage = `Exception items are disabled because the current time is past the ${cutoffTime} Cut-Off time (${timeZone}).`;

            vm.alertMessage = null;
            canDisplayCurrentData = timeUntilStart <= 0;
            vm.allowEdit = timeUntilStart <= 0 && timeUntilCutoff > 0;
            isPastStartTime = vm.allowEdit;

            if (timeUntilStart > 0) {
                // If before Start Time:
                vm.alertMessage = startAlertMessage;
                isPastStartTime = false;
                //  set a timed event to enable edits and alert, when the start time has passed.
                $timeout(() => {
                    search(vm.searchParams);
                    isPastStartTime = true;
                    if (vm.hasEtlRunSuccessfully) {
                        canDisplayCurrentData = true;
                        vm.allowEdit = true;
                        vm.alertMessage = null;
                        toaster.alert(
                            'Past Start Time',
                            'You can now edit decisions for the listed Exceptions.'
                        );
                    }
                }, timeUntilStart);
            }
            if (timeUntilCutoff > 0) {
                // If before Cut-Off time
                //  set a timed event to disable edits and alert, when the Cut-Off Time has passed.
                $timeout(() => {
                    vm.allowEdit = false;
                    vm.alertMessage = cutoffAlertMessage;
                    isPastCutOffTime = true;
                    toaster.alert(
                        'Past Cut-Off Time',
                        'Exception items are disabled because the current time is past the Cut-Off Time.'
                    );
                }, timeUntilCutoff);
            } else {
                // If after Cut-Off time
                vm.alertMessage = cutoffAlertMessage;
                vm.allowEdit = false;
            }
        });
    }

    function init() {
        loadAccounts();
        loadCutoffTimes();
        initReset();
    }

    function search(searchObject) {
        arpService.searchArpExceptions(searchObject).then(response => {
            if (response.arpExceptionDetails != null) {
                response.arpExceptionDetails.forEach(exception => {
                    exception.pay = getPayState(exception.decisionTaken);
                    exception.existingDecision = exception.pay;
                });
            }
            vm.hasEtlRunSuccessfully = response.hasEtlRunSuccessfully;
            vm.arpExceptionsPristine = response;
            vm.arpExceptionsWorking = angular.copy(response);
            vm.isDataLoaded = true;

            vm.sidebarClosed = !(
                response.arpExceptionDetails == null || response.arpExceptionDetails.length === 0
            );

            if (vm.arpExceptionsWorking != null && vm.arpExceptionsWorking.length === 1) {
                vm.arpExceptionsWorking.forEach(item => {
                    item.expanded = true;
                });
            }

            filterSearch(vm.searchText);

            displayAlertMessageWhenEtlRunIsNotComplete();
        });
    }

    function displayAlertMessageWhenEtlRunIsNotComplete() {
        if (vm.searchParams.type === 'OpenItems') {
            vm.showHistoryMode = false;
            const etlHasNotCompletedMessage =
                'Exception items are currently not available for review, please try again later.';
            if (!vm.hasEtlRunSuccessfully && isPastStartTime) {
                // If the ETL run has not completed and start time passed display message
                vm.allowEdit = false;
                canDisplayCurrentData = false;

                if (vm.alertMessage == null || (vm.alertMessage != null && isPastStartTime)) {
                    vm.alertMessage = etlHasNotCompletedMessage;

                    toaster.alert('Exceptions Not Available', etlHasNotCompletedMessage);
                }
            } else if (vm.alertMessage == etlHasNotCompletedMessage) {
                init();
            }
        } else {
            vm.showHistoryMode = true;
        }
    }

    function initReset() {
        vm.searchParams.type = 'OpenItems';

        vm.searchParams.checkNumber = '';
        vm.searchParams.issuedAmountType = null;
        vm.resetIssuedAmount();

        vm.searchParams.paidAmountType = null;
        vm.resetPaidAmount();

        vm.searchParams.postedDateRangeType = null;
        vm.resetPostedDate();

        vm.searchParams.issuedDateRangeType = null;
        vm.resetIssuedDate();

        vm.searchParams.payee = '';
        vm.searchParams.decisionTaken = 'both';
    }

    function filterSearch(searchText) {
        let result = $filter('filter')(vm.arpExceptionsWorking.arpExceptionDetails, searchText);
        if (result == null) result = []; // The table element expects a non-null array
        vm.filteredExceptions = result;
    }

    function review() {
        // only filtered exceptions will be sent for review
        const decisionItems = getDecisionItems();
        const modalInstance = $uibModal.open({
            template: require('../views/arpExceptionReviewModalView.html'),
            controller: 'ArpExceptionReviewModalController',
            backdrop: 'static',
            resolve: {
                arpExceptions() {
                    return decisionItems;
                },
            },
        });
        modalInstance.result.then(arpExceptions => {
            const confirmedDecisions = [];
            arpExceptions.forEach(exception => {
                if (exception.pay === true || exception.pay === false) {
                    confirmedDecisions.push(exception);
                }
            });

            if (confirmedDecisions.length > 0) {
                arpService.updateArpExceptions(confirmedDecisions).then(response => {
                    toaster.save('Check Exception Decisions');
                    vm.search(vm.searchParams);
                });

                const totalAmount = arpExceptions.reduce((total, exception) => total += exception.paidAmount || 0, 0);
                analyticsService.track(AnalyticsEvent.CheckExceptionReview, {
                    itemCount: confirmedDecisions.length,
                    totalAmount
                })
            }
        });
    }

    function loadAccounts() {
        arpService.getArpAccounts('WorkArpExceptions').then(response => {
            if (response.length === 0) {
                $uibModal
                    .open({
                        template: require('../views/missingAccountsModal.html'),
                        controller: 'MissingAccountsModalController',
                        backdrop: 'static',
                        resolve: {
                            contextSpecificMessage() {
                                return "You don't have any accounts associated with Check exceptions.";
                            },
                        },
                    })
                    .result.then(() => {
                        $state.go('dashboard');
                    });
                return;
            }

            vm.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            vm.accounts = response;
            // initialize accounts
            // the search filter must be pre-initialized because binding won't be complete before the initial search is done.
            if (!$stateParams.accountNumber) {
                vm.accounts.forEach(account => {
                    account.isChecked = true;
                });
                vm.searchParams.accounts = vm.accounts;
            } else if ($stateParams.accountNumber) {
                vm.searchParams.accounts = [];
                vm.accounts.forEach(account => {
                    if (
                        (account.showAccountNickName &&
                            account.name === $stateParams.accountNumber) ||
                        (!account.showAccountNickName &&
                            account.number === $stateParams.accountNumber)
                    ) {
                        account.isChecked = true;
                        vm.searchParams.accounts.push(account);
                    } else {
                        account.isChecked = false;
                    }
                });
            }
            search(vm.searchParams);
        });
    }

    function convertTime(time) {
        return moment(`${moment().format('l')} ${time}`).format('LT');
    }

    // init
    init();
}
