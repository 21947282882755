import { FrequencyType } from '@treasury/domain/channel/types/frequency';
import { isValidRepeatBothDays } from '../../utils/frequency';

export const repeatOnDaysIsRequired = {
    name: 'Repeat On Days is a required field',
    validate: (val: any) => {
        if (val.type === FrequencyType.OneTime) {
            return true;
        }

        if (val.type === FrequencyType.Weekly) {
            return true;
        }

        if (val.type === FrequencyType.EveryTwoWeeks) {
            return true;
        }

        if (val.type === FrequencyType.Monthly) {
            return true;
        }

        if (val.type === FrequencyType.Quarterly) {
            return true;
        }

        if (val.type === FrequencyType.EverySixMonths) {
            return true;
        }

        if (val.type === FrequencyType.Yearly) {
            return true;
        }

        return isValidRepeatBothDays(val);
    },
};
