/* istanbul ignore file */
export default async (record, recordset, step) => {
    if (step === 0) {
        if (record.isValid()) {
            this.nextStep();
            return;
        }
        record.setField('name', 'office supplies');
        record.setField(
            'achCompany',
            (await record.fieldTypeForField('achCompany').options())[0]?.value
        );
        return;
    }
    if (step === 1) {
        const accountType = (await recordset.getFieldType('accountType').options())[0]?.value;

        const routingNumber = (await recordset.getFieldType('routingNumber').options())[0]?.value;

        for (const recipient of recordset.allRecords) {
            if (recipient.isValid()) break;
            recipient.setField('name', 'Dwight Schrute');
            recipient.setField('accountNumber', '1234');
            recipient.setField('accountType', accountType);
            recipient.setField('routingNumber', routingNumber);
            recipient.setField('amount', 1);
            recipient.setField('nonCustodialParentSsn', '123-45-6789');
            recipient.setField('caseIdentifier', '123');
            recipient.setField('payDate', new Date().toISOString().slice(0, 10));
            return;
        }
    }
};
