SaveReportController.$inject = [
    '$scope',
    '$modalInstance',
    'selectedReport',
    'informationReportsService',
    'blnSaveAs',
];

export default function SaveReportController(
    $scope,
    $modalInstance,
    selectedReport,
    informationReportsService,
    blnSaveAs
) {
    $scope.selectedReport = selectedReport;
    $scope.blnSaveAs = blnSaveAs;

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will update the company
    $scope.save = function () {
        saveReport();
    };

    function saveReport() {
        // save a new instance of the report, remove ID
        if (blnSaveAs) {
            $scope.selectedReport.id = 0;
        }
        if ($scope.selectedReport.type === 'Transaction') {
            $scope.selectedReport.type = 'TransactionFilter';
        }
        if ($scope.selectedReport.type === 'Balance') {
            $scope.selectedReport.type = 'BalanceFilter';
        }
        informationReportsService.saveFilter($scope.selectedReport).then(response => {
            $modalInstance.close(response);
        });
    }

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };
}
