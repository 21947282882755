export default [
    '$modal',

    function ($modal) {
        const modalDefaults = {
            backdrop: true,
            keyboard: true,
            modalFade: true,
            size: 'md',
            template: require('../../shared/confirmation.html'),
        };

        const modalOptions = {
            closeButtonText: 'No',
            actionButtonText: 'Yes',
            headerText: 'Confirmation',
            bodyMessages: [],
            summaryText: '',
            actionCode: '',
            bodyTitle: '',
            isAlert: false,
            alertType: 'Success',
            actionButtonClass: 'btn-primary',
            time: '',
        };

        this.showModal = function (customModalDefaults, customModalOptions) {
            if (!customModalDefaults) customModalDefaults = {};
            customModalDefaults.backdrop = 'static';
            if (
                customModalOptions !== null &&
                customModalOptions !== undefined &&
                customModalOptions.isAlert &&
                !angular.isDefined(customModalOptions.headerText)
            ) {
                if (customModalOptions.alertType == 'Validation')
                    customModalOptions.headerText = 'Validation errors';
                else {
                    customModalOptions.headerText = customModalOptions.alertType;
                }
            }

            return this.show(customModalDefaults, customModalOptions);
        };

        this.show = function (customModalDefaults, customModalOptions) {
            const tempModalDefaults = {};
            const tempModalOptions = {};

            angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

            angular.extend(tempModalOptions, modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = [
                    '$scope',
                    '$modalInstance',

                    function ($scope, $modalInstance) {
                        $scope.showBodyMessage = showBodyMessage;
                        $scope.showBodyMessageException = showBodyMessageException;
                        $scope.showAlertModal = showAlertModal;
                        $scope.showDefaultModal = showDefaultModal;
                        $scope.showBodyHeaderModal = showBodyHeaderModal;

                        function showAlertModal() {
                            return $scope.modalOptions.isAlert && !$scope.modalOptions.bodyHeader;
                        }
                        function showDefaultModal() {
                            return !$scope.modalOptions.isAlert && !$scope.modalOptions.bodyHeader;
                        }
                        function showBodyHeaderModal() {
                            return (
                                $scope.modalOptions.bodyHeader &&
                                !showDefaultModal() &&
                                !showAlertModal()
                            );
                        }

                        function showBodyMessage() {
                            return (
                                $scope.modalOptions.bodyMessages &&
                                !$scope.showBodyMessageException()
                            );
                        }

                        function showBodyMessageException() {
                            return (
                                $scope.modalOptions.bodyMessages &&
                                $scope.modalOptions.bodyMessages.data &&
                                $scope.modalOptions.bodyMessages.data.exceptionType
                            );
                        }

                        $scope.modalOptions = tempModalOptions;
                        $scope.modalOptions.ok = function (result) {
                            $modalInstance.close(result);
                        };
                        $scope.modalOptions.close = function () {
                            $modalInstance.dismiss();
                        };
                    },
                ];
            }
            if (angular.isDefined(customModalOptions.size)) {
                tempModalDefaults.size = customModalOptions.size;
            }
            return $modal.open(tempModalDefaults);
        };
    },
];
