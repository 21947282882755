export default () => ({
    restrict: 'A',
    require: '?ngModel',
    link(scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push(inputValue => {
            if (inputValue == undefined) {
                return '';
            }
            if (!angular.isString(inputValue)) {
                inputValue = inputValue.toString();
            }
            const transformedInput = inputValue.replace(/[^0-9]/g, '');
            if (transformedInput !== inputValue) {
                modelCtrl.$setViewValue(transformedInput);
                modelCtrl.$render();
            }
            return transformedInput;
        });
    },
});
