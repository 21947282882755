/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';

const getFileExtension = type => {
    switch (type) {
        case 'PDF':
            return type.toLowerCase();
        case 'CSV':
            return type.toLowerCase();
        default:
            return 'txt';
    }
};

const downloadBlob = (blob, id = 'file', name = 'file', type = 'txt') => {
    // Convert blob to a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set the file type (extension)
    const fileExtension = getFileExtension(type);

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = `${name}-${id}.${fileExtension}`;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    // Remove link from body
    document.body.removeChild(link);
};

export default class Download {
    static async download(
        confirmationId,
        outputType,
        pageId,
        filterModel,
        downloadFormats = ['CSV', 'NACHA', 'PDF']
    ) {
        let body = {
            confirmationId,
            downloadFormats,
            outputType,
            pageId,
            filterModel,
        };
        let fileOutputType = outputType;

        if (outputType.indexOf('PDF') >= 0) {
            body = { ...body, outputType: 'PDF', downloadPdfTypeSelected: outputType };
            fileOutputType = 'PDF';
        }

        if (outputType.indexOf('CSV') >= 0) {
            body.outputType = outputType.indexOf('Detail') >= 0 ? 'CsvInDetail' : 'CSV';
            fileOutputType = 'CSV';
        }

        const http = await TmHttpClient.getInstance();
        const response = await http.request(`download/`, {
            method: 'POST',
            responseType: TmHttpResponseType.ArrayBuffer,
            body,
        });

        const type =
            outputType.indexOf('PDF') >= 0 ? 'application/pdf' : 'application/octet-stream';

        const blob = new Blob([response], { type });

        return downloadBlob(blob, confirmationId, pageId, fileOutputType);
    }
}
