/**
 * Retrieves the institution ID for the current financial institution. 
 * 
 * @returns The institution ID for the current FI.
 */
export function getInstitution() {
    const { institution } = document.documentElement.dataset;
    if(!institution){
        throw new Error('Institution ID not available.');
    }    

    return institution;
}