/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchExceptionDetailModelDto,
    AchExceptionModelDto,
    ArpExceptionDetailDataModelDto,
    ArpExceptionModelDto,
    CompanyAccountModelDto,
    DashboardAccountDetailModelDto,
    DashboardAccountsGroupModelDto,
    DashboardDepositTransactionDetailModelDto,
    DashboardEntitlementsModelDto,
    DashboardInformationCenterModelDto,
    DashboardLoanTransactionDetailModelDto,
    DashboardPaymentResponseModelDto,
    DashboardResourcesModelDto,
    DashboardSummaryModelDto,
    DashboardTimeDepositTransactionDetailModelDto,
    DashboardWidgetModelDto,
    ErrorResponseModelDto,
    FilterBaseModelDto,
    LookupModelDto,
    PaymentBatchModelDto,
    PendingApprovalCountsModelDto,
    ProductModelDto,
    ProductSettingsModelDto,
    SaveAchExceptionDetailResponseModelDto,
    SecurityBaseDto,
    StpModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class DashboardClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetSummary
     * @summary Retrieves the My Accounts information.
     * @request GET:/dashboard/summary
     */
    dashboardGetSummary = (params: RequestParams = {}) =>
        this.http.request<DashboardSummaryModelDto, ErrorResponseModelDto>({
            path: `/dashboard/summary`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetTransactionDetail
     * @summary Retrieves Transaction detail for specified account detail.
     * @request GET:/dashboard/transactionDetail
     */
    dashboardGetTransactionDetail = (
        query: {
            /**
             * Account Id.
             * @format int32
             */
            accountId: number;
            /** Account Type. */
            accountType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardAccountDetailModelDto, ErrorResponseModelDto>({
            path: `/dashboard/transactionDetail`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetDepositTransactionDetail
     * @summary Retrieves the Deposit Transaction detail for the specified account id.
     * @request GET:/dashboard/depositTransactionDetail
     */
    dashboardGetDepositTransactionDetail = (
        query: {
            /**
             * Account Id.
             * @format int32
             */
            accountId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardDepositTransactionDetailModelDto, ErrorResponseModelDto>({
            path: `/dashboard/depositTransactionDetail`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetLoanTransactionDetail
     * @summary Retrieves the Loan transaction detail.
     * @request GET:/dashboard/loanTransactionDetail
     */
    dashboardGetLoanTransactionDetail = (
        query: {
            /**
             * Account Id.
             * @format int32
             */
            accountId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardLoanTransactionDetailModelDto, ErrorResponseModelDto>({
            path: `/dashboard/loanTransactionDetail`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetTimeDepositTransactionDetail
     * @summary Retrieves the Time deposit transaction details.
     * @request GET:/dashboard/timeDepositTransactionDetail
     */
    dashboardGetTimeDepositTransactionDetail = (
        query: {
            /**
             * Account Id.
             * @format int32
             */
            accountId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardTimeDepositTransactionDetailModelDto, ErrorResponseModelDto>({
            path: `/dashboard/timeDepositTransactionDetail`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetInformationReportsReports
     * @summary Retrieves the Reports for Dashboard.
     * @request GET:/dashboard/favouriteReports
     */
    dashboardGetInformationReportsReports = (params: RequestParams = {}) =>
        this.http.request<FilterBaseModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/favouriteReports`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetInformationCenterDetail
     * @summary Retrieves the Info center details.
     * @request GET:/dashboard/informationCenterDetail
     */
    dashboardGetInformationCenterDetail = (params: RequestParams = {}) =>
        this.http.request<DashboardInformationCenterModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/informationCenterDetail`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetResourceDetail
     * @summary Retrieves the active dashboard resources.
     * @request GET:/dashboard/resourceDetail
     */
    dashboardGetResourceDetail = (params: RequestParams = {}) =>
        this.http.request<DashboardResourcesModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/resourceDetail`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetPaymentPendingApproval
     * @summary Retrieves the transactions that needs approval.
     * @request GET:/dashboard/paymentPendingApproval
     */
    dashboardGetPaymentPendingApproval = (
        query: {
            /** Transfer/LoanPayment/WIRE/ACH */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<any, ErrorResponseModelDto>({
            path: `/dashboard/paymentPendingApproval`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetPaymentPendingApprovalMobile
     * @summary Retrieves the transactions that needs approval
     * @request GET:/dashboard/mobile/paymentPendingApproval
     */
    dashboardGetPaymentPendingApprovalMobile = (
        query: {
            /** transfer/wire/ach */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<any, ErrorResponseModelDto>({
            path: `/dashboard/mobile/paymentPendingApproval`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetWorkExceptions
     * @summary Retrieves the transactions that needs approval.
     * @request GET:/dashboard/channelWorkExceptions
     */
    dashboardGetWorkExceptions = (
        query: {
            /** Ach Exceptions/Arp Exception */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<any, ErrorResponseModelDto>({
            path: `/dashboard/channelWorkExceptions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetWorkExceptionForMobile
     * @summary Gets the work exception for mobile.
     * @request GET:/dashboard/workExceptions
     * @deprecated
     */
    dashboardGetWorkExceptionForMobile = (
        query: {
            /** The type. */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<any, ErrorResponseModelDto>({
            path: `/dashboard/workExceptions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetStopRequestsPendingApproval
     * @summary Retrieves the transactions that needs approval.
     * @request GET:/dashboard/stopRequests
     */
    dashboardGetStopRequestsPendingApproval = (params: RequestParams = {}) =>
        this.http.request<StpModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/stopRequests`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetEntitlements
     * @summary Retrieves the entitlements for Dashboard.
     * @request GET:/dashboard/entitlements
     */
    dashboardGetEntitlements = (params: RequestParams = {}) =>
        this.http.request<DashboardEntitlementsModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/entitlements`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetAccountGroups
     * @summary Retrieves the My Accounts information.
     * @request GET:/dashboard/myaccounts
     */
    dashboardGetAccountGroups = (params: RequestParams = {}) =>
        this.http.request<DashboardAccountsGroupModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/myaccounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardCreateAccountGroup
     * @summary Creates a new account group.
     * @request POST:/dashboard/myaccounts/groups
     */
    dashboardCreateAccountGroup = (
        group: DashboardAccountsGroupModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardAccountsGroupModelDto, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups`,
            method: 'POST',
            body: group,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardUpdateGroupName
     * @summary Updates a group with a new name.
     * @request PUT:/dashboard/myaccounts/groups
     */
    dashboardUpdateGroupName = (
        query: {
            /**
             * Group ID
             * @format int32
             */
            groupId: number;
        },
        group: DashboardAccountsGroupModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardAccountsGroupModelDto, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups`,
            method: 'PUT',
            query: query,
            body: group,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardDeleteGroup
     * @summary Deletes an account group.
     * @request DELETE:/dashboard/myaccounts/groups
     */
    dashboardDeleteGroup = (
        query: {
            /**
             * Group ID
             * @format int32
             */
            groupId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetAccounts
     * @summary Retrieves Accounts for the user.
     * @request GET:/dashboard/myaccounts/accounts
     */
    dashboardGetAccounts = (params: RequestParams = {}) =>
        this.http.request<CompanyAccountModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/accounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardAddAccounts
     * @summary Adds accounts to a group.
     * @request POST:/dashboard/myaccounts/groups/accounts
     */
    dashboardAddAccounts = (
        query: {
            /**
             * Group ID
             * @format int32
             */
            groupId: number;
        },
        accounts: CompanyAccountModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardAccountsGroupModelDto, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups/accounts`,
            method: 'POST',
            query: query,
            body: accounts,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardRemoveAccount
     * @summary Removes account from a group.
     * @request DELETE:/dashboard/myaccounts/groups/accounts
     */
    dashboardRemoveAccount = (
        query: {
            /**
             * Group ID
             * @format int32
             */
            groupId: number;
            /**
             * Account ID
             * @format int32
             */
            accountId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups/accounts`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardResequenceGroup
     * @summary Resequence a group of accounts.
     * @request PUT:/dashboard/myaccounts/groups/resequence
     */
    dashboardResequenceGroup = (
        query: {
            /**
             * Group ID
             * @format int32
             */
            groupId: number;
            /**
             * New Index
             * @format int32
             */
            newIndex: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups/resequence`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardResequenceAccounts
     * @summary Resequences accounts in a group.
     * @request PUT:/dashboard/myaccounts/groups/accounts/resequence
     */
    dashboardResequenceAccounts = (
        query: {
            /**
             * Group ID
             * @format int32
             */
            groupId: number;
            /**
             * Account ID
             * @format int32
             */
            accountId: number;
            /**
             * New Index
             * @format int32
             */
            newIndex: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/myaccounts/groups/accounts/resequence`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardApproveOrReject
     * @summary Process BATCH
     * @request POST:/dashboard/batch
     */
    dashboardApproveOrReject = (
        query: {
            type: string | null;
        },
        model: PaymentBatchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SecurityBaseDto, ErrorResponseModelDto>({
            path: `/dashboard/batch`,
            method: 'POST',
            query: query,
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardApproveOrRejectArpExceptions
     * @summary Approves the or reject arp exceptions.
     * @request POST:/dashboard/channelArpExceptionsBatch
     */
    dashboardApproveOrRejectArpExceptions = (
        arpExceptionDetails: ArpExceptionDetailDataModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ArpExceptionDetailDataModelDto, ErrorResponseModelDto>({
            path: `/dashboard/channelArpExceptionsBatch`,
            method: 'POST',
            body: arpExceptionDetails,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardApproveOrRejectArpExceptionsForMobile
     * @summary Process BATCH for Mobile clients. This is so that the Mobile endpoint will not be disrupted.
     * @request POST:/dashboard/arpExceptionsBatch
     * @deprecated
     */
    dashboardApproveOrRejectArpExceptionsForMobile = (
        arpExceptions: ArpExceptionModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/arpExceptionsBatch`,
            method: 'POST',
            body: arpExceptions,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardApproveOrRejectAchExceptions
     * @summary Process BATCH
     * @request POST:/dashboard/channelAchExceptionsBatch
     */
    dashboardApproveOrRejectAchExceptions = (
        model: AchExceptionDetailModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SaveAchExceptionDetailResponseModelDto, ErrorResponseModelDto>({
            path: `/dashboard/channelAchExceptionsBatch`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardApproveOrRejectAchExceptionsForMobile
     * @summary Approves the or reject ach exceptions for mobile.
     * @request POST:/dashboard/achExceptionsBatch
     * @deprecated
     */
    dashboardApproveOrRejectAchExceptionsForMobile = (
        model: AchExceptionModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/achExceptionsBatch`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardProcessBatch
     * @summary Process BATCH
     * @request POST:/dashboard/arpBatch
     */
    dashboardProcessBatch = (
        query: {
            type: string | null;
        },
        model: LookupModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardPaymentResponseModelDto, ErrorResponseModelDto>({
            path: `/dashboard/arpBatch`,
            method: 'POST',
            query: query,
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardPostBatch
     * @summary Process BATCH
     * @request POST:/dashboard/stpBatch
     */
    dashboardPostBatch = (model: LookupModelDto[] | null, params: RequestParams = {}) =>
        this.http.request<StpModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/stpBatch`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetConfiguredWidgets
     * @summary Returns the user's dashboard configuration.
     * @request GET:/dashboard/configuredWidgets
     */
    dashboardGetConfiguredWidgets = (params: RequestParams = {}) =>
        this.http.request<DashboardWidgetModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/configuredWidgets`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardSetConfiguredWidgets
     * @summary Saves the user's dashboard configuration.
     * @request PUT:/dashboard/configuredWidgets
     */
    dashboardSetConfiguredWidgets = (
        widgets: DashboardWidgetModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardWidgetModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/configuredWidgets`,
            method: 'PUT',
            body: widgets,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardPendingApprovalCounts
     * @summary Gets the pending approval counts.
     * @request GET:/dashboard/pendingApprovalCounts
     */
    dashboardPendingApprovalCounts = (params: RequestParams = {}) =>
        this.http.request<PendingApprovalCountsModelDto, ErrorResponseModelDto>({
            path: `/dashboard/pendingApprovalCounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardAvailableWidgets
     * @summary Gets all of the widgets available to the user.
     * @request GET:/dashboard/availableWidgets
     */
    dashboardAvailableWidgets = (params: RequestParams = {}) =>
        this.http.request<DashboardWidgetModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/availableWidgets`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags Dashboard
 * @name DashboardAvailableWidgetsProducts
 * @summary Gets all of the widgets available to the user.
Transfer/WIRE/ACH
 * @request GET:/dashboard/WidgetProducts
 */
    dashboardAvailableWidgetsProducts = (
        query: {
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DashboardWidgetModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/WidgetProducts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetProducts
     * @summary Gets the products.
     * @request GET:/dashboard/getProducts
     */
    dashboardGetProducts = (params: RequestParams = {}) =>
        this.http.request<ProductModelDto[], ErrorResponseModelDto>({
            path: `/dashboard/getProducts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardGetProductSettings
     * @summary Gets the product settings.
     * @request GET:/dashboard/getProductSettings
     */
    dashboardGetProductSettings = (params: RequestParams = {}) =>
        this.http.request<ProductSettingsModelDto, ErrorResponseModelDto>({
            path: `/dashboard/getProductSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardAuditUserInteraction
     * @summary Audits the user interaction.
     * @request POST:/dashboard/auditUserInteraction/{interaction}
     */
    dashboardAuditUserInteraction = (interaction: number, params: RequestParams = {}) =>
        this.http.request<boolean, ErrorResponseModelDto>({
            path: `/dashboard/auditUserInteraction/${interaction}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
}
