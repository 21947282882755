import { AchCompanyRequests } from '@treasury/domain/channel/requests/ach';
import {
    Config,
    OffsetAccount,
    OffsetAccountConfig,
    PaymentEntitlements,
    PaymentHeader,
    WorkflowActions,
} from '@treasury/domain/channel/types/ach';
import { FieldType, Record } from '@treasury/FDL';

const isNachaFileUpload = (config: Config): boolean =>
    config.action === WorkflowActions.InitiateFromFile;

export const isPrefunding = (entitlements: PaymentEntitlements, record: Record<any>) => {
    const prefundingDays = record?.getField('achCompany')?.prefundingDays;
    if (!prefundingDays) return false;
    return entitlements.allowUnbalanced && prefundingDays > 0;
};

export const stepApplicable = (
    config: Config,
    state: keyof OffsetAccountConfig,
    record: Record<any>
) => {
    if (config?.offsetAccount === undefined) {
        return true;
    }
    const stateSteps =
        isNachaFileUpload(config) && config?.offsetAccount.nachaFileSteps
            ? config?.offsetAccount.nachaFileSteps
            : config?.offsetAccount;

    const currentStep = record.getField('step');
    if (stateSteps[state] && Array.isArray(stateSteps[state])) {
        const steps = stateSteps[state] as number[];
        if (steps.includes(currentStep)) {
            return true;
        }
    }
    return stateSteps[state] === currentStep;
};

export const fullOffsetAccountAllDebit = (record: Record<any>) => {
    const creditAmount = record.getField('creditAmount');
    const debitAmount = record.getField('debitAmount');
    const debitOnly = debitAmount > 0 && creditAmount === 0;
    const { notOnUsTransactionTypes, batchBalanceRequirements } = record.getField('achCompany');
    return (
        notOnUsTransactionTypes === 'Debits and Credits' &&
        batchBalanceRequirements === 'Unbalanced - Full Offset' &&
        debitOnly
    );
};

export const isDebitTransaction = (record: Record<any>) =>
    record.getField('debitAmount') > record.getField('creditAmount');

export const isCreditTransaction = (record: Record<any>) =>
    record.getField('debitAmount') < record.getField('creditAmount');

export const isBalancedTransaction = (record: Record<any>) =>
    record.getField('debitAmount') === record.getField('creditAmount');

export const requirePrefundingOffsetAccount = (
    entitlements: PaymentEntitlements,
    record: Record<any>
) => {
    const prefundedFullOffsetAllDebit =
        isPrefunding(entitlements, record) && fullOffsetAccountAllDebit(record);
    return !isPrefunding(entitlements, record) || prefundedFullOffsetAllDebit;
};

export const isExistingPayment = (record: Record<any>) => !!record.getField('id');
export const isAllowUnbalancedPayments = (
    entitlements: PaymentEntitlements,
    record: Record<any>
) => {
    const allowUnbalancedPayments = record?.getField('achCompany')?.allowUnbalancedPayments;
    if (!allowUnbalancedPayments) return false;

    return entitlements.allowUnbalanced && allowUnbalancedPayments;
};
export const isAchCompanyBalanced = (record: Record<any>) => {
    const batchBalanceRequirements = record?.getField('achCompany')?.batchBalanceRequirements;
    if (!batchBalanceRequirements) return false;
    return batchBalanceRequirements.toLowerCase().trim() === 'balanced';
};

const isOffsetAccountVisible = (config: Config, record: Record<PaymentHeader>) => {
    if (!config) return true;
    if (!config.offsetAccount || stepApplicable(config, 'visibleSteps', record)) {
        if (!isNachaFileUpload(config)) {
            return !isAchCompanyBalanced(record);
        }
        return true;
    }
    return false;
};

const isOffsetAccountReadOnly = (config: Config, record: Record<PaymentHeader>) => {
    if (!config) return false;
    if (config.offsetAccount && stepApplicable(config, 'readOnlySteps', record)) {
        return true;
    }
    if (!config.offsetAccount || stepApplicable(config, 'visibleSteps', record)) {
        if (
            isPrefunding(config.entitlements as PaymentEntitlements, record) &&
            !fullOffsetAccountAllDebit(record)
        ) {
            return true;
        }
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
            isAllowUnbalancedPayments(config.entitlements as PaymentEntitlements, record) ||
            isAchCompanyBalanced(record)
        ) {
            return true;
        }
        return false;
    }

    return false;
};

const isOffsetAccountRequired = (config: Config, record: Record<PaymentHeader>) => {
    if (!config) return false;
    if (!config.offsetAccount || stepApplicable(config, 'requiredSteps', record)) {
        if (isAchCompanyBalanced(record)) return false;

        if (isAllowUnbalancedPayments(config.entitlements as PaymentEntitlements, record))
            return false;

        return requirePrefundingOffsetAccount(config.entitlements as PaymentEntitlements, record);
    }
    return false;
};

export const achOffsetAccount = (config: Config, service = AchCompanyRequests) =>
    new FieldType<OffsetAccount>().with
        .defaultValue(null)
        .and.options<OffsetAccount, Record<PaymentHeader>>({
            fetch: async record => {
                const company = record.getField('achCompany');
                const id = company.id || record.getField('achCompanyId');
                if (company && id)
                    return service
                        ? (service.getAchCompanyOffsetAccounts(id) as Promise<OffsetAccount[]>)
                        : (AchCompanyRequests.getAchCompanyOffsetAccounts(id) as Promise<
                              OffsetAccount[]
                          >);
                return [];
            },
            text: 'accountDisplayLabel',
            value: offsetAccount => offsetAccount,
        })
        .with.formatter(value => value?.accountDisplayLabel ?? value?.value ?? value)
        .and.filtering()
        .and.search({
            title: 'Search Accounts',
            columns: [
                {
                    label: 'Account Number',
                    field: 'number',
                },
                {
                    label: 'Account Name',
                    field: 'name',
                },
            ],
        })
        .and.disabledWhen((record: Record<PaymentHeader>) => !record.getField('achCompany'))
        .and.requiredWhen((record: Record<PaymentHeader>) =>
            isOffsetAccountRequired(config, record)
        )
        .and.visibleWhen((record: Record<PaymentHeader>) => isOffsetAccountVisible(config, record))
        .and.readOnlyWhen((record: Record<PaymentHeader>) =>
            isOffsetAccountReadOnly(config, record)
        );
