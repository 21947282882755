import { css, html, LitElement } from 'lit';
import '../../tables/wire-table.js';
import { workflowStyles } from '../workflow.styles.js';

export class WireUploadReview extends LitElement {
    static get properties() {
        return {
            records: Array,
            confirming: { type: Boolean, reflect: true },
            accountConfiguration: Object,
            filters: Array,
        };
    }

    constructor() {
        super();
        this.readOnly = true;
    }

    static get is() {
        return 'wire-upload-review';
    }

    back() {
        this.dispatchEvent(new CustomEvent('back'));
    }

    showAlert() {
        this.shadowRoot.querySelector('omega-alert').isVisible = true;
    }

    dismissAlert() {
        this.shadowRoot.querySelector('omega-alert').isVisible = false;
    }

    cancelUpload() {
        window.location.reload();
    }

    confirm() {
        const wireTable = this.shadowRoot.querySelector('wire-table');
        this.dispatchEvent(
            new CustomEvent('confirm', {
                bubbles: true,
                composed: true,
                detail: { value: wireTable.records },
            })
        );
    }

    render() {
        return html`
            <slot></slot>

            <omega-alert posture="assertive" type="warning" title="Are you sure?">
                Clicking OK will void the file. You will have to upload the file again. Are you sure
                you want to cancel these changes?
                <div slot="actions">
                    <omega-button @click=${this.dismissAlert}>Continue Editing</omega-button>
                    <omega-button @click=${this.cancelUpload} type="primary">OK</omega-button>
                </div>
            </omega-alert>

            <wire-table
                readonly
                .records=${this.records}
                .accountConfiguration=${this.accountConfiguration}
                .filters=${this.filters}
            >
            </wire-table>

            <omega-button-bar alignment="left" position="bottom">
                <omega-button ?loading=${this.confirming} type="primary" @click=${this.confirm}
                    >Confirm</omega-button
                >
                <omega-button type="link" @click=${this.back}>Back</omega-button>
                <omega-button type="link" @click=${this.showAlert}>Cancel</omega-button>
            </omega-button-bar>
        `;
    }

    static get styles() {
        return [
            workflowStyles,
            css`
                :host {
                    display: block;
                    height: 100%;
                    position: relative;
                }
            `,
        ];
    }
}

window.customElements.define(WireUploadReview.is, WireUploadReview);
export default WireUploadReview;
