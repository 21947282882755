import { AchDomesticServices } from '@treasury/domain/channel/services/ach/ach-domestic-service';
import { AchRecipientsService } from '@treasury/domain/channel/services/ach/ach-recipients-service';
import {
    AchBankDto,
    PaymentHeader,
    PaymentRecipient,
    PaymentRecipientForSelect,
} from '@treasury/domain/channel/types/ach';
import { Record, Recordset, RecordsetEvent } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-table';
import { AlertMixin } from '@treasury/omega/mixins';
import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import {
    convertSelectedToPaymentRecipients,
    fetchedRecipientFields,
    getAchRecipientsColumns,
} from '../data/fetched-recipients-data';

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));

type MasterListRecipientsRecordsetParams = {
    searchText: string;
    achCompanyId: number;
};

type FilterTreeNode<T> = ['contains', keyof T, string];
type FilterTreeFirstNode = 'and' | 'or';
type FilterTreeBase<T> = [FilterTreeFirstNode, ...[FilterTreeNode<T>]];
interface FilterTree<T> extends FilterTreeBase<T> {
    searchText?: string;
}

@customElement('select-from-recipient-list-dialog')
export class SelectFromRecipientListDialog extends AlertingListeningElement {
    @state()
    // Populates table in dialog
    masterListRecipientsRecordset!: Recordset<
        PaymentRecipientForSelect,
        MasterListRecipientsRecordsetParams
    >;

    @state()
    canRenderTable = true;

    @state()
    anySelectedRecipients = false;

    @property({
        type: String,
    })
    public institution!: string;

    @property()
    client!: AchDomesticClient;

    @property({ type: Boolean })
    private tableLoading = false;

    @property()
    searchText = '';

    @property()
    addToExistingRecipients!: (selected: Array<PaymentRecipient>) => void;

    @property()
    paymentHeaderRecord!: Record<PaymentHeader>;

    @property()
    onUsAchBanks: Array<AchBankDto> = [];

    @property()
    onUsAccessManagementFeature = false;

    @property()
    allowOnUsAccessManagement = false;

    @property({ type: Boolean })
    open = false;

    @property({ type: Array })
    selectedRecipients: Array<Record<PaymentRecipientForSelect>> = [];

    @state()
    private _updating: Promise<void> | boolean | void = false;

    @state()
    private _updateQueue: Array<MasterListRecipientsRecordsetParams> = [];

    @state()
    columns = getAchRecipientsColumns();

    private async setParameters() {
        const { searchText } = this;

        if (!this.masterListRecipientsRecordset) return;

        const params = {
            ...this.masterListRecipientsRecordset.parameters,
            searchText,
            achCompanyId: this.paymentHeaderRecord.getField('achCompany').id,
        };

        // need to wait for the previous recordset update to resolve before submitting a request
        // recordset handles debouncing already, so as long as we are submitting a request with the most recent set of parameters,
        // we dont need to do any strict debouncing on our side, just keep the last

        this._updateQueue.push(params);
        await this._updating;

        const mostRecentParams = this._updateQueue.pop() ?? params;

        this.masterListRecipientsRecordset.parameters = mostRecentParams;

        this._updating = this.masterListRecipientsRecordset.requestHardUpdate();

        this.checkIfCanRenderTable();
    }

    async firstUpdated() {
        this.onUsAchBanks = await AchDomesticServices.getOnUsAchBanks();
        this.masterListRecipientsRecordset = new Recordset(
            fetchedRecipientFields(this.paymentHeaderRecord),
            AchRecipientsService.fetchValidRecipientsForPayment,
            0,
            true
        );
        this.addRecordsetListeners();
    }

    private addRecordsetListeners() {
        this.listenTo(this.masterListRecipientsRecordset, RecordsetEvent.Updated, () => {
            this.removeDuplicateSelectedRecipients();
            this.anySelectedRecipients = !!this.selectedRecipients.length;
        });
        this.listenTo(this.masterListRecipientsRecordset, RecordsetEvent.PageChanged, () => {
            this.handleSelectedRecipientsOnPageChange();
            this.removeDuplicateSelectedRecipients();
        });
    }

    private removeDuplicateSelectedRecipients() {
        const currentPageSelected = this.masterListRecipientsRecordset.recordsMatching(
            'selected',
            true
        );

        this.selectedRecipients.forEach(recipient => {
            if (
                recipient.getField('selected') &&
                !currentPageSelected.find(
                    selectedRecord => selectedRecord.getField('id') === recipient.getField('id')
                )
            ) {
                currentPageSelected.push(recipient);
            }
        });

        this.selectedRecipients = currentPageSelected.filter(
            (record, index, array) =>
                array.findIndex(
                    compareRecord => compareRecord.getField('id') === record.getField('id')
                ) === index
        );
    }

    private handleSelectedRecipientsOnPageChange() {
        this.masterListRecipientsRecordset.allRecords.forEach(record => {
            if (
                this.selectedRecipients.find(
                    selectedRecord => selectedRecord.values.id === record.values.id
                )
            ) {
                record.setField('selected', true);
            }
        });
    }

    protected updated(changedProperties: PropertyValueMap<any>): void {
        if (changedProperties.has('searchText')) {
            this.setParameters();
        }
        if (changedProperties.has('open') && this.open) {
            this.setParameters();
        }
    }

    private close() {
        this.searchText = '';
        this.masterListRecipientsRecordset.reset();
        this.selectedRecipients = [];
        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    private selectRecipients() {
        this.addToExistingRecipients(convertSelectedToPaymentRecipients(this.selectedRecipients));
        this.close();
    }

    private async checkIfCanRenderTable() {
        if (this.masterListRecipientsRecordset.allRecords.length >= 1) this.canRenderTable = true;
    }

    private renderFilterBar() {
        if (!this.open) return nothing;
        return html`<omega-filter-bar
            .filters=${[]}
            .isLocal=${false}
            .recordset=${this.masterListRecipientsRecordset}
            @change=${({ detail }: CustomEvent<FilterTree<PaymentRecipientForSelect>>) => {
                if (detail.searchText !== this.searchText)
                    this.searchText = detail.searchText as string;
            }}
        ></omega-filter-bar>`;
    }

    private renderTable() {
        if (this.canRenderTable && this.masterListRecipientsRecordset) {
            return html`${this.renderFilterBar()}
                <omega-table
                    .recordset=${this.masterListRecipientsRecordset}
                    .columnDefinitions=${this.columns}
                    .loading=${this.tableLoading}
                ></omega-table>`;
        }
        return html`No recipients found`;
    }

    private renderSelectRecipientsButton() {
        if (this.canRenderTable)
            return html`<omega-button
                type="primary"
                @click=${this.selectRecipients}
                .disabled=${!this.anySelectedRecipients ||
                this.masterListRecipientsRecordset.filteredCount === 0}
                >Select recipients</omega-button
            >`;
        return nothing;
    }

    render() {
        return html`<omega-dialog
            .dialogTitle=${'ACH Recipients'}
            .open=${this.open}
            @close=${() => this.close()}
        >
            <div slot="content" class="table">${this.renderTable()}</div>
            <div class="buttons">
                <div class="left">${this.renderSelectRecipientsButton()}</div>
                <omega-button slot="actions" type="link" @click=${() => this.close()}
                    >Close</omega-button
                >
            </div>
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .table {
                padding: 0 15px;
            }
            .buttons {
                display: flex;
                justify-content: space-between;
                padding: 0 7px;
            }
        `;
    }
}
