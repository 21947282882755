RecurringTransferListController.$inject = [
    '$scope',
    '$uibModal',
    '$filter',
    'companyAccountsService',
    'internalTransfersService',
    'modalService',
    'toaster',
    'downloadPageId',
    '$state',
    'securityService',
    'entitlementsService',
    'activityService',
    'frequencyFilter',
    'resourceType',
    'auditCode',
];

export default function RecurringTransferListController(
    $scope,
    $uibModal,
    $filter,
    companyAccountsService,
    internalTransfersService,
    modalService,
    toaster,
    downloadPageId,
    $state,
    securityService,
    entitlementsService,
    activityService,
    frequencyFilter,
    resourceType,
    auditCode
) {
    // scope
    $scope.downloadPageId = downloadPageId.RecurringTransferList;
    $scope.selectAllCbox = false;
    $scope.statusList = [];
    $scope.accountListFrom = [];
    $scope.accountListTo = [];
    $scope.accountListBoth = [];
    $scope.frequencyList = [];
    $scope.amountTypeList = ['', 'Specific Amount', 'Amount Range'];
    $scope.dateTypeList = ['', 'Specific Date', 'Date Range'];

    $scope.filterObject = {
        status: [],
        accountOperator: 'Both',
        fromAccounts: [],
        toAccounts: [],
        bothAccounts: [],
        transactionId: '',
        frequency: [],
        amountType: '',
        amountStart: 0,
        amountEnd: 0,
        amountSpecific: 0,
        nextTransferDateType: '',
        nextTransferDateSpecific: '',
        nextTransferDateStart: '',
        nextTransferDateEnd: '',
        createdDateType: '',
        createdDateSpecific: '',
        createdDateStart: '',
        createdDateEnd: '',
    };

    $scope.transferList = [];

    // scope functions
    $scope.setForm = setForm;
    $scope.changeAccountType = changeAccountType;
    $scope.search = search;
    $scope.reset = reset;
    $scope.cancel = cancel;
    $scope.approve = approve;
    $scope.reject = reject;
    $scope.selectAll = selectAll;
    $scope.selectTransfer = selectTransfer;
    $scope.isAnyTransfersSelected = isAnyTransfersSelected;
    $scope.batchApprove = batchApprove;
    $scope.batchReject = batchReject;
    $scope.userHasCreateRights = entitlementsService.hasEntitlement('Transfer, Create');
    $scope.canApproveTransfer = canApproveTransfer;
    $scope.canApproveAnyTransfers = canApproveAnyTransfers;
    $scope.canCancel = canCancel;
    $scope.hasAccess = hasAccess;
    $scope.editSeries = editSeries;

    $scope.filteredTransfers = null;
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.searchObj = { text: null };
    $scope.goToTransferList = goToTransferList;
    $scope.goToTransferTemplatesList = goToTransferTemplatesList;
    $scope.disableDownload = true;

    function setApprovalAttributes() {
        $scope.transferList.map(transfer => {
            transfer.approvalAttributes = {
                approvalEntity: 'internalTransfer',
                approvalCount: transfer.completedApprovalCount,
                amount: transfer.amount,
                createdBy: transfer.createdBy,
                updatedBy: transfer.updatedBy,
                productId: transfer.id,
            };
            return transfer;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredTransfers = $filter('filter')($scope.transferList, filterRows);
    }

    function goToTransferTemplatesList() {
        $state.go('payables.transferTemplatesList');
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.transferList ||
            $scope.transferList.length <= 0
        ) {
            return true;
        }

        return (
            (!!row.transactionId &&
                row.transactionId.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.fromAccount &&
                !!row.fromAccount.number &&
                row.fromAccount.number
                    .toLowerCase()
                    .indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.toAccount &&
                !!row.toAccount.number &&
                row.toAccount.number.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.createdDate &&
                $filter('date')(row.createdDate, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.nextTransferDate &&
                $filter('date')(row.nextTransferDate, 'MM/dd/yyyy').indexOf(
                    $scope.searchObj.text
                ) !== -1) ||
            (!!row.frequency &&
                frequencyFilter(row.frequency).toLowerCase().indexOf($scope.searchObj.text) !==
                    -1) ||
            (!!row.endDate &&
                $filter('date')(row.endDate, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.amount &&
                $filter('currency')(row.amount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function disableFilter() {
        return $scope.transferList ? $scope.transferList.length <= 0 : true;
    }

    function goToTransferList() {
        $state.go('payables.transferList');
    }

    // init
    function init() {
        loadStatusList();
        loadAccounts('From');
        loadFrequencyList();
    }

    // scope functions imple
    function setForm(form) {
        $scope.form = form;
    }

    function changeAccountType(accountType) {
        switch (accountType) {
            case 'Both':
                $scope.filterObject.accountOperator = accountType;
                break;
            case 'From':
                if ($scope.filterObject.accountOperator === 'To') {
                    $scope.filterObject.accountOperator = 'FromTo';
                } else {
                    $scope.filterObject.accountOperator = accountType;
                }
                break;
            case 'To':
                if ($scope.filterObject.accountOperator === 'From') {
                    $scope.filterObject.accountOperator = 'FromTo';
                } else {
                    $scope.filterObject.accountOperator = accountType;
                }
                break;
        }
    }

    function search() {
        getRecurringTransfersList();
    }

    function getRecurringTransfersList() {
        internalTransfersService.getRecurringTransfersList($scope.filterObject).then(response => {
            let index = 0;
            response = response.map(transfer => {
                transfer.defaultIndex = index++;
                return transfer;
            });
            $scope.transferList = $filter('orderBy')(response, ['defaultIndex']);
            $scope.searchObj.text = null;
            setApprovalAttributes();
            $scope.filterSearch($scope.searchText);

            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
                $scope.disableDownload = true;
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
                $scope.disableDownload = false;
            }
        });
    }

    function reset() {
        $scope.filterObject.accountOperator = 'Both';
        $scope.filterObject.transactionId = '';
        $scope.filterObject.amountType = '';
        $scope.filterObject.amountStart = 0;
        $scope.filterObject.amountEnd = 0;
        $scope.filterObject.amountSpecific = 0;
        $scope.filterObject.nextTransferDateType = '';
        $scope.filterObject.nextTransferDateSpecific = '';
        $scope.filterObject.nextTransferDateStart = '';
        $scope.filterObject.nextTransferDateEnd = '';
        $scope.filterObject.createdDateType = '';
        $scope.filterObject.createdDateSpecific = '';
        $scope.filterObject.createdDateStart = '';
        $scope.filterObject.createdDateEnd = '';

        angular.forEach($scope.statusList, item => {
            item.isChecked = true;
        });
        angular.forEach($scope.accountListFrom, item => {
            item.isChecked = true;
        });
        angular.forEach($scope.accountListTo, item => {
            item.isChecked = true;
        });
        angular.forEach($scope.accountListBoth, item => {
            item.isChecked = true;
        });
        angular.forEach($scope.frequencyList, item => {
            item.isChecked = true;
        });
    }

    function cancel(transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                message() {
                    return 'Are you sure you want to cancel this series?';
                },
                actionButtonText() {
                    return 'Cancel Recurring Series';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
                headerText() {
                    return 'Confirm Cancellation';
                },
            },
        });
        modalInstance.result.then(comments => {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelRecurringTransfer
            );
            const cancelTransferModel = {
                internalTransferId: transfer.id,
                comments,
            };
            securityService
                .verifyUser('DeleteTransfer_ByName', cancelTransferModel, () =>
                    internalTransfersService.cancelTransfer(cancelTransferModel)
                )
                .then(() => {
                    toaster.cancel('Recurring Transfer');
                    search();
                });
        });
    }

    function approve(transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Approve Transfer';
                },
                message() {
                    return 'Are you sure you want to approve this transfer?';
                },
                actionButtonText() {
                    return 'Approve';
                },
                actionButtonClass() {
                    return 'btn-success';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            transfer.comments = comments;
            securityService
                .verifyUser('ApproveTransfer_ByName', transfer, () =>
                    internalTransfersService.approveTransfer(transfer, 'transfer')
                )
                .then(response => {
                    parseIndividualErrors(transfer, response);
                    if (!response || !response.errorSummary) {
                        toaster.approve('Recurring Transfer');
                        for (let i = 0; i < $scope.transferList.length; i++) {
                            if ($scope.transferList[i].id === response.id) {
                                $scope.transferList[i] = response;
                            }
                        }
                    }
                });
        });
    }

    function reject(transfer) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Reject Transfer';
                },
                message() {
                    return 'Are you sure you want to reject this transfer?';
                },
                actionButtonText() {
                    return 'Reject';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            transfer.comments = comments;
            securityService
                .verifyUser('RejectTransfer_ByName', transfer, () =>
                    internalTransfersService.rejectTransfer(transfer)
                )
                .then(response => {
                    toaster.reject('Recurring Transfer');
                    search();
                });
        });
    }

    function selectAll(isChecked) {
        angular.forEach($scope.transferList, transfer => {
            if (hasAccess(transfer.permissions, 'Approve')) {
                transfer.isChecked = isChecked;
            }
        });
    }

    function selectTransfer() {
        let allSelected = true;
        angular.forEach($scope.transferList, transfer => {
            if (!transfer.isChecked) {
                allSelected = false;
            }
        });
        $scope.selectAllCbox = allSelected;
    }

    function isAnyTransfersSelected() {
        let anyChecked = false;
        angular.forEach($scope.transferList, transfer => {
            if (transfer.isChecked) {
                anyChecked = true;
            }
        });
        return anyChecked;
    }

    function batchApprove() {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Approve Transfers';
                },
                message() {
                    return 'Are you sure you want to approve these transfers?';
                },
                actionButtonText() {
                    return 'Approve Transfers';
                },
                actionButtonClass() {
                    return 'btn-success';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            const batchPayload = {
                comments,
                internalTransfers: {},
            };
            batchPayload.internalTransfers = $filter('filter')($scope.transferList, {
                isChecked: true,
            });
            securityService
                .verifyUser('ApproveTransfer_ByName', batchPayload, () =>
                    internalTransfersService.batchApprove(batchPayload, 'transfer')
                )
                .then(response => {
                    $scope.batchResponse = response.internalTransfers;
                    toaster.approve('Batch Approve');
                    search(parseErrors);
                });
        });
    }

    function batchReject() {
        const modalInstance = $uibModal.open({
            template: require('../views/changeTransferModalView.html'),
            size: 'sm',
            controller: 'ChangeTransferController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Reject Transfers';
                },
                message() {
                    return 'Are you sure you want to reject these transfers?';
                },
                actionButtonText() {
                    return 'Reject Transfers';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            const batchPayload = {
                comments,
                internalTransfers: {},
            };
            batchPayload.internalTransfers = $filter('filter')($scope.transferList, {
                isChecked: true,
            });
            securityService
                .verifyUser('RejectTransfers_ByName', batchPayload, () =>
                    internalTransfersService.batchReject(batchPayload)
                )
                .then(response => {
                    toaster.reject('Recurring Transfers');
                    search();
                });
        });
    }

    function canApproveTransfer(transfer) {
        if (transfer) {
            return hasAccess(transfer.permissions, 'Approve');
        }
        return false;
    }

    function canApproveAnyTransfers() {
        let claim = false;
        angular.forEach($scope.transferList, transfer => {
            angular.forEach(transfer.permissions, permission => {
                if (permission.permission === 'Approve') {
                    claim = true;
                }
            });
        });
        return claim;
    }

    function canCancel() {
        let claim = false;
        angular.forEach($scope.transferList, transfer => {
            angular.forEach(transfer.permissions, permission => {
                if (permission.permission === 'Delete') {
                    claim = true;
                }
            });
        });
        return claim;
    }

    function hasAccess(permissions, permissionValue) {
        let hasRights = false;
        angular.forEach(permissions, permission => {
            if (permission.permission === permissionValue) {
                hasRights = true;
            }
        });
        return hasRights;
    }

    function editSeries(transfer) {
        $state.go('payables.transfers-edit', { id: transfer.id });
    }

    // private functions
    function loadStatusList() {
        $scope.statusList = [
            { key: 'PendingApproval', value: 'Pending Approval', isChecked: true },
            { key: 'Scheduled', value: 'Scheduled', isChecked: true },
            { key: 'Submitted', value: 'Submitted', isChecked: true },
            { key: 'ApprovalRejected', value: 'Approval Rejected', isChecked: true },
            { key: 'Cancelled', value: 'Cancelled', isChecked: true },
            { key: 'Failed', value: 'Failed', isChecked: true },
        ];
    }

    function loadFrequencyList() {
        $scope.frequencyList = [
            { key: 'Weekly', value: 'Weekly', isChecked: true },
            { key: 'EveryTwoWeeks', value: 'Every Two Weeks', isChecked: true },
            { key: 'TwiceAMonth', value: 'Twice a Month', isChecked: true },
            { key: 'Monthly', value: 'Monthly', isChecked: true },
            { key: 'Quarterly', value: 'Quarterly', isChecked: true },
            { key: 'EverySixMonths', value: 'Every Six Months', isChecked: true },
            { key: 'Yearly', value: 'Yearly', isChecked: true },
        ];
    }

    function loadAccounts(type) {
        companyAccountsService
            .getCompanyAccountsWithProductFeature(type, 'InternalTransfer')
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                if (type === 'From') {
                    $scope.accountListFrom = response;
                    angular.forEach($scope.accountListFrom, item => {
                        item.isChecked = true;
                        $scope.accountListBoth.push(angular.copy(item));
                    });
                    loadAccounts('To');
                } else {
                    $scope.accountListTo = response;
                    angular.forEach($scope.accountListTo, item => {
                        item.isChecked = true;
                        let toAccountExists = false;
                        angular.forEach($scope.accountListBoth, _item => {
                            if (item.number === _item.number) {
                                toAccountExists = true;
                            }
                        });
                        if (toAccountExists === false) {
                            $scope.accountListBoth.push(angular.copy(item));
                        }
                    });

                    getRecurringTransfersList();
                }
            });
    }

    function parseErrors() {
        angular.forEach($scope.transferList, transferInList => {
            transferInList.message = '';
            angular.forEach($scope.batchResponse, transferInResponse => {
                if (transferInResponse.errorSummary) {
                    if (transferInList.id === transferInResponse.id) {
                        transferInList.isError = true;
                        const { errorSummary } = transferInResponse;
                        if (errorSummary.details && errorSummary.details.length > 0) {
                            if (
                                errorSummary.details[0].messageList &&
                                errorSummary.details[0].messageList.length > 0
                            ) {
                                let message = '';
                                angular.forEach(
                                    errorSummary.details[0].messageList,
                                    errorMessage => {
                                        message += `${errorMessage.value}, `;
                                    }
                                );
                                transferInList.message = message;
                            } else {
                                transferInList.message += errorSummary.summaryMessage;
                            }
                        } else {
                            transferInList.message += errorSummary.summaryMessage;
                        }
                        transferInList.messageType = 'Error';
                    }
                }
            });
            transferInList.message = transferInList.message.substring(
                0,
                transferInList.message.length - 2
            );
        });
    }

    function parseIndividualErrors(transfer, response) {
        transfer.message = '';
        if (response.errorSummary) {
            transfer.isError = true;
            const { errorSummary } = response;
            if (errorSummary.details && errorSummary.details.length > 0) {
                if (
                    errorSummary.details[0].messageList &&
                    errorSummary.details[0].messageList.length > 0
                ) {
                    let message = '';
                    angular.forEach(errorSummary.details[0].messageList, errorMessage => {
                        message += `${errorMessage.value}, `;
                    });
                    transfer.message = message;
                } else {
                    transfer.message += errorSummary.summaryMessage;
                }
            } else {
                transfer.message += errorSummary.summaryMessage;
            }
            transfer.messageType = 'Error';
        } else {
            transfer.isError = false;
            transfer.messageType = '';
        }
        transfer.message = transfer.message.substring(0, transfer.message.length - 2);
    }

    $scope.resetValues = function (type) {
        if (type === 'createdDate') {
            $scope.filterObject.createdDateSpecific = null;
            $scope.filterObject.createdDateStart = null;
            $scope.filterObject.createdDateEnd = null;
        } else if (type === 'nextTransferDate') {
            $scope.filterObject.nextTransferDateSpecific = null;
            $scope.filterObject.nextTransferDateStart = null;
            $scope.filterObject.nextTransferDateEnd = null;
        }
    };

    init();
}
