/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { Feature } from '@treasury/domain/services/feature-flags/feature-flag.types';
import userHasEntitlement from '@treasury/domain/shared/utilities/user-has-entitlement.js';
import { Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { printNode } from '@treasury/utils';
import { css, html, LitElement, nothing, render } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-payment-action-dialog.js';
import AchPaymentActivityClient from '../clients/ach-payment-activity-client.js';
import { columns, fields, filters, printColumns } from '../data/ach-payment-activity-data.js';

@customElement('ach-payment-activity')
export class AchPaymentActivity extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            institution: String,
            alert: Object,
            actions: Object,
            reportActions: Array,
            recordset: Object,
            filters: Array,
            client: Object,
            needsUpdate: Boolean,
            downloading: Boolean,
            achConfiguration: Object,
            entitlements: Array,
            selectedBatchPaymentRecords: Array,
            submittingActionOnPayment: Boolean,
            actionTaken: String,
            showActionDialog: Boolean,
            actionDialogTitle: String,
            fileArchiveIds: Array,
        };
    }

    constructor() {
        super();
        this.alert = { visible: false, title: '' };
        this.reportInformation = html`<div style="max-width: 350px;">
            <p>
                <b>ACH Payment Activity</b> - Includes all payments including recurring payments
                regardless of payment status (Payments Pending Approval, Scheduled, Submitted,
                etc.). Utilize the search feature to search payment history.
            </p>
        </div>`;
        this.showActionDialog = false;
        this.actions = {};
        this.pageTitle = 'ACH Payment Activity';
        this.downloading = false;
        this.needsUpdate = false;
        this.achConfiguration = {};
        this.rowsPerPage = 25;
        this.itemLabel = { singular: 'payment' };
    }

    async firstUpdated() {
        const navService = await getNavService();
        const { params } = await navService.getRouteData();

        if (!this.client) {
            this.client = new AchPaymentActivityClient();
        }

        this.fileArchiveIds = params?.fileArchiveIds ?? null;
        this.fields = fields(this.client);
        this.filters = filters(this.client);
        this.entitlements = await this.client.getEntitlements();
        this.columns = columns(this.entitlements);
        this.recordset = new Recordset(this.fields, (...args) =>
            this.client.fetchAchPaymentActivity(...args, this.fileArchiveIds)
        );
        this.recordset.setInitialPageSize(this.rowsPerPage);
        this.listenTo(this.recordset, 'loading', ({ detail }) => {
            const { loading } = detail;
            const hasApprovePermission = userHasEntitlement(
                this.entitlements,
                'Approve Ach Payment'
            );
            if (hasApprovePermission && !loading) {
                this.reportActions = [
                    {
                        type: 'approve',
                        label: 'Approve',
                        action: () => {
                            this.selectedPayment = null;
                            this.selectedBatchPaymentRecords =
                                this.getSelectedBatchPaymentRecords();
                            this.actionTaken = 'approve';
                            this.showActionDialog = true;
                            this.actionDialogTitle = this.generateActionDialogTitle('approve');
                        },
                        isDisabled: () => this.recordset?.noRecordsMatch('selected', true),
                    },
                    {
                        type: 'reject',
                        label: 'Reject',
                        action: () => {
                            this.selectedPayment = null;
                            this.selectedBatchPaymentRecords =
                                this.getSelectedBatchPaymentRecords();
                            this.actionTaken = 'reject';
                            this.showActionDialog = true;
                            this.actionDialogTitle = this.generateActionDialogTitle('reject');
                        },
                        isDisabled: () => this.recordset?.noRecordsMatch('selected', true),
                    },
                ];
            }
        });
        this.canCreatePayment = userHasEntitlement(this.entitlements, 'ACH, Batch, CreatePayment');
        this.initializeActions();
    }

    clearRouteParams() {
        this.fileArchiveIds = null;
    }

    async initializeActions() {
        const useLitVersionInPaymentDetailLink = await this.client.hasFeatureFlag(
            Feature.LitDomesticAch
        );
        this.actions = {
            cancel: record => {
                this.selectedBatchPaymentRecords = [];
                this.showActionDialog = true;
                this.selectedPayment = record;
                this.actionTaken = 'cancel';
                this.actionDialogTitle = this.generateActionDialogTitle(this.actionTaken);
            },
            uninitiate: record => {
                this.selectedBatchPaymentRecords = [];
                this.showActionDialog = true;
                this.selectedPayment = record;
                this.actionTaken = 'uninitiate';
                this.actionDialogTitle = this.generateActionDialogTitle(this.actionTaken);
            },
            clickTransactionId: async record => {
                const navService = await getNavService();
                const id = record.getField('id');
                const { path, params } = await this.client.generatePaymentDetailLinkData(
                    record,
                    id,
                    useLitVersionInPaymentDetailLink
                );
                navService.navigate(path, params);
            },
            clickFileName: async record => {
                const navService = await getNavService();
                const fileArchiveId = record.getField('fileArchiveId');

                if (fileArchiveId) {
                    navService.navigate('payables.ach.fileactivity-list-param', {
                        id: fileArchiveId,
                    });
                } else {
                    navService.navigate('payables.ach.fileactivity-list');
                }
            },
        };
        this.reportLinks = [
            {
                title: 'ACH File Activity',
                route: 'payables.ach.fileactivity-list',
            },
            {
                title: 'Recurring ACH Payments',
                route: 'payables.ach.recurring-payment-list',
            },
        ];
    }

    createCallToAction() {
        if (this.canCreatePayment) {
            return 'Create New Payment';
        }
        return nothing;
    }

    getSelectedBatchPaymentRecords() {
        return this.recordset.recordsMatching('selected', true);
    }

    async createNewPayment() {
        const navService = await getNavService();
        if (await this.client.hasFeatureFlag(Feature.LitDomesticAch)) {
            return navService.navigate('payables.ach.payments.create-dark');
        }
        return navService.navigate('payables.ach.payments.create');
    }

    getActionMessage(actionTaken) {
        switch (actionTaken) {
            case 'approve':
                return 'approved';
            case 'reject':
                return 'rejected';
            case 'cancel':
                return 'cancelled';
            case 'uninitiate':
                return 'uninitiated';
            default:
                return 'updated';
        }
    }

    pluralizePayments() {
        return this.selectedBatchPaymentRecords?.length > 1 ? 'Payments' : 'Payment';
    }

    getSelectedPaymentsForUpdate() {
        if (this.selectedBatchPaymentRecords && this.selectedBatchPaymentRecords.length > 0) {
            return this.selectedBatchPaymentRecords.map(record => record.getField('id'));
        }
        return [this.selectedPayment.getField('id')];
    }

    async submitActionOnPayment() {
        this.submittingActionOnPayment = true;
        const selectedPaymentsForUpdate = this.getSelectedPaymentsForUpdate();
        try {
            await this.client.submitPaymentStatusUpdate(
                this.actionTaken,
                selectedPaymentsForUpdate,
                this.statusChangeComments
            );

            this.alert = {
                ...this.alert,
                message: `${this.pluralizePayments()} successfully ${this.getActionMessage(
                    this.actionTaken
                )}`,
                visible: true,
                type: 'success',
            };
            await this.recordset.requestHardUpdate();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.showActionDialog = false;
            this.submittingActionOnPayment = false;
        }
    }

    generateActionDialogTitle(action) {
        const capitalizedAction = action[0].toUpperCase() + action.substring(1).toLowerCase();

        return `Confirm ${capitalizedAction} ${this.pluralizePayments()}`;
    }

    async printReport() {
        this.printing = true;
        const printRecordset = new Recordset(this.fields, () => this.recordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${printColumns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    async handleDownload({ filter, type }) {
        this.downloading = true;
        try {
            await this.client.downloadAchPaymentActivity(type, 'ACHPaymentList', {
                ...filter,
                fileArchiveIds: this.fileArchiveIds,
            });
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.downloading = false;
        }
    }

    renderDownloadDialog() {
        if (this.downloading) {
            const title = 'Downloading ACH payment activity';
            return html`<omega-dialog
                id="download-dialog"
                open
                .dialogTitle=${title}
                @close=${() => {
                    this.downloading = false;
                }}
            >
                <omega-progress card></omega-progress>
            </omega-dialog>`;
        }
        return nothing;
    }

    renderAchPaymentActionDialog() {
        return html`<ach-payment-action-dialog
            .paymentHeaderRecord=${this.selectedPayment}
            .batchPaymentRecords=${this.selectedBatchPaymentRecords}
            .submittingActionOnPayment=${this.submittingActionOnPayment}
            .actionTaken=${this.actionTaken}
            .showActionDialog=${this.showActionDialog}
            dialogTitle=${ifDefined(this.actionDialogTitle)}
            @close=${() => {
                this.showActionDialog = false;
                this.statusChangeComments = '';
            }}
            @submit=${({ detail }) => {
                this.statusChangeComments = detail;
                this.submitActionOnPayment();
            }}
        ></ach-payment-action-dialog>`;
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html` <div class="alert-wrapper">
            <omega-alert
                type=${type}
                title=${title}
                posture=${posture}
                ?isVisible=${visible}
                @close=${() => {
                    this.alert = { ...this.alert, visible: false };
                }}
            >
                ${renderedCode} ${message} ${renderedTime} ${actions}
            </omega-alert>
        </div>`;
    }

    renderPageLoader() {
        return html`<div class="background">
            <omega-progress card class="light-loader"></omega-progress>
        </div>`;
    }

    render() {
        if (!this.filters || !this.recordset) {
            return this.renderPageLoader();
        }

        return html`
            ${this.renderAchPaymentActionDialog()} ${this.renderAlert()}
            ${this.renderDownloadDialog()}
            <omega-report
                id="ach-payment-activity-report"
                .title=${this.pageTitle}
                flyout
                autostart
                displayToggleAll
                .persistState=${!this.fileArchiveIds}
                .reportInformation=${this.reportInformation}
                .itemLabel=${this.itemLabel}
                .actions=${this.actions}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recordset}
                .filters=${this.filters}
                .columns=${this.columns}
                .options=${['download', 'print']}
                .rowsPerPage=${this.rowsPerPage}
                .downloadOptions=${[
                    'Summary - PDF',
                    'Detail - PDF',
                    'Summary - CSV',
                    'Detail - CSV',
                ]}
                callToAction=${this.createCallToAction()}
                @callToAction=${this.createNewPayment}
                .printFunction=${() => this.printReport()}
                @reportDownload=${({ detail }) => this.handleDownload(detail)}
                @search=${this.clearRouteParams}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    .itemLabel=${this.itemLabel}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                ></omega-filter-bar>
            </omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
        `;
    }
}

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
