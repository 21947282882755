import { NachaFile } from '@treasury/domain/channel/types/ach';
import '@treasury/omega/components/omega-file-upload';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { css, html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';

interface InputChangeEvent extends Event {
    target: HTMLInputElement;
}
export class AchNachaUpload extends LitElement {
    @state()
    creationType = '';

    @state()
    workflowType = '';

    @state()
    recordId = '';

    @state()
    file: NachaFile = null;

    @state()
    valid = false;

    @property({ type: Function })
    setNachaData!: (file: NachaFile) => void;

    @property({ type: Object, reflect: true })
    public alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: '',
        type: 'error',
        posture: 'polite',
        actions: '',
    };

    async firstUpdated() {
        [this.creationType, this.workflowType, this.recordId] = window.location.pathname.split('/');
    }

    handleChange(files: FileList) {
        // we are currently only supporting one file upload at a time
        if (files[0].size / 1024 / 1024 > 15) {
            this.alert = {
                ...this.alert,
                message: 'The number of payments exceeds the maximum of 100,000.',
                visible: true,
                type: 'error',
            };
        } else {
            this.setNachaData(files[0]);
        }
        [this.file] = files;
        this.valid = true;
    }

    handleSelect(e: InputChangeEvent) {
        const { files } = e.target;
        if (files && files.length) {
            this.handleChange(files);
        } else {
            this.handleRemove();
        }
    }

    handleRemove() {
        this.file = null;
        const input = <HTMLInputElement>this.shadowRoot?.getElementById('select-file');
        if (input && input.value) {
            input.value = input.defaultValue;
        }
        this.setNachaData(null);
    }

    renderFileIcon() {
        return html`
            <span class="file-icon">
                <svg class="icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        fill-rule="evenodd"
                        d="M6 3.25a.75.75 0 0 0-.75.75v16c0 .414.336.75.75.75h12a.75.75 0 0 0 .75-.75v-9a.75.75 0 0 0-.22-.53l-7-7a.75.75 0 0 0-.53-.22H6Zm.75 16V4.75h3.5V11c0 .414.336.75.75.75h6.25v7.5H6.75Zm9.439-9L11.75 5.81v4.44h4.439Z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </span>
        `;
    }

    renderRemoveFileIcon() {
        return html`
            <button @click=${this.handleRemove} class="remove-file-icon">
                <svg class="icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                        fill-rule="evenodd"
                        d="M6.53 5.47a.75.75 0 0 0-1.06 1.06L10.94 12l-5.47 5.47a.75.75 0 1 0 1.06 1.06L12 13.06l5.47 5.47a.75.75 0 1 0 1.06-1.06L13.06 12l5.47-5.47a.75.75 0 0 0-1.06-1.06L12 10.94 6.53 5.47Z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </button>
        `;
    }

    renderFilesList() {
        return this.file
            ? html`<ul class="files-list">
                  <li class="files-list-item">
                      ${this.renderFileIcon()}
                      <span class="file-name-text">${this.file.name}</span>
                      ${this.renderRemoveFileIcon()}
                  </li>
              </ul>`
            : nothing;
    }

    renderButton() {
        return html`<label for="select-file" class="select-file-label">
                <input
                    required
                    id="select-file"
                    aria-label="select nacha file"
                    name="files"
                    type="file"
                    accept=".txt"
                    size="5000"
                    @change=${this.handleSelect}
                />
                <span> Select File </span>
            </label>
            ${this.renderFilesList()}`;
    }

    render() {
        return html`<div class="container-margin">
            <div class="grey-box">${this.renderButton()}</div>
            <span class="max-payment-message">Maximum of 10,000 payments</span>
        </div> `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
                .container-margin {
                    margin: 15px 0px 3px 20px;
                }
                .grey-box {
                    background-color: #eae8e8;
                    max-width: 400px;
                    background-image: none,
                        linear-gradient(
                            to bottom,
                            rgba(255, 255, 255, 0.6) 0%,
                            rgba(255, 255, 255, 0) 100%
                        );
                    border: 1px solid #c5c5c5;
                    border-radius: 4px;
                }
                .max-payment-message {
                    font-style: italic;
                }
                #select-file {
                    margin: 0.8em;
                    min-width: 7.167em;
                }
                input[type='file'] {
                    display: none;
                    position: absolute;
                }
                .select-file-label {
                    min-width: 7.26em;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    display: inline-block;
                    padding: 2px 7px;
                    line-height: 1.72em;
                    cursor: pointer;
                    margin: 0.8em;
                    color: #2e2e2e;
                    border-color: #c5c5c5;
                    background-color: #e9e9e9;
                    text-align: center;
                    background-image: none,
                        linear-gradient(
                            to bottom,
                            rgba(255, 255, 255, 0.6) 0%,
                            rgba(255, 255, 255, 0) 100%
                        );
                    background-position: 50% 50%;
                    position: relative;
                }
                .select-file-label:hover {
                    color: #2e2e2e;
                    border-color: #b6b6b6;
                    background-color: #bcb4b0;
                    background-image: none,
                        linear-gradient(
                            to bottom,
                            rgba(255, 255, 255, 0.4) 0%,
                            rgba(255, 255, 255, 0) 100%
                        );
                    background-position: 50% 50%;
                }
                .files-list {
                    list-style-type: none;
                    width: 100%;
                    padding: 0;
                    margin: 0 0 13px 0;
                }
                .files-list-item {
                    display: flex;
                    align-items: center;
                    background-color: #ffffff;
                    padding: 0.167em 0.167em 0.167em 0.8em;
                    position: relative;
                    height: 40px;
                    padding: 0;
                    border: 1px solid #ccc;
                    border-left: none;
                    border-right: none;
                }
                .file-icon {
                    margin: 0 10px;
                    height: 18px;
                    width: 18px;
                    position: relative;
                    top: -1px;
                }
                .remove-file-icon {
                    position: absolute;
                    right: 12px;
                    margin-left: 8px;
                    padding: 0;
                    border-color: transparent;
                    background-color: transparent;
                    height: 20px;
                    width: 20px;
                }
                .icon-svg {
                    height: 100%;
                    width: 100%;
                }
            `,
        ];
    }
}
window.customElements.define('ach-nacha-upload', AchNachaUpload);
