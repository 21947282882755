import { v4 as uuid } from 'uuid';

ConfigureNotificationsController.$inject = [
    '$scope',
    'notificationConfigurationsService',
    'toaster',
    'securityService',
    '$filter',
    '$modal',
    'navigationService',
    'menulessOptions',
    'accountTransactionsService',
];

export default function ConfigureNotificationsController(
    $scope,
    notificationConfigurationsService,
    toaster,
    securityService,
    $filter,
    $modal,
    navigationService,
    menulessOptions,
    accountTransactionsService
) {
    $scope.searchFilterResults = [];
    $scope.selectAllEmails = [];
    $scope.isDuplicateEmail = false;
    $scope.selectAllTextMessages = [];
    $scope.selectAllDesktopNotifications = [];
    $scope.searchFilters = [];
    $scope.selectAllEmail = selectAllEmail;
    $scope.updateList = updateList;
    $scope.selectAllText = selectAllText;
    $scope.setForm = setForm;
    $scope.selectAllDesktop = selectAllDesktop;
    $scope.searchGroups = searchGroups;
    $scope.disableDesktopNotification = disableDesktopNotification;
    $scope.disableTextNotification = disableTextNotification;
    $scope.disableEmailNotification = disableEmailNotification;
    $scope.disableStatusToggle = disableStatusToggle;
    $scope.addHelpText = addHelpText;
    $scope.changeState = changeState;
    $scope.changeAccountBalanceState = changeAccountBalanceState;
    $scope.changeDuplicationEmail = changeDuplicationEmail;
    $scope.checkPhoneNumber = checkPhoneNumber;
    $scope.configurations = {};
    $scope.save = save;
    $scope.reset = reset;
    $scope.originalEmail = '';
    $scope.accountList = [];
    $scope.accountDisplayLabel = '';
    $scope.addAlert = addAlert;
    // $scope.loadAccountBalanceFeatures = loadAccountBalanceFeatures;
    $scope.oneAccountBalanceNotificationIsRequired = oneAccountBalanceNotificationIsRequired;
    $scope.canDeleteNotification = canDeleteNotification;
    $scope.deleteAlert = deleteAlert;
    $scope.accountBalanceNotificationsUpdated = false;
    $scope.getTableIndex = getTableIndex;
    $scope.loading = true;
    $scope.getFilteredRows = getFilteredRows;

    function getFilteredRows(rows) {
        return rows.filter(row => !row.threshold?.isDeleted);
    }

    function getDefaultAccountBalanceConfiguration() {
        return {
            accountList: [...$scope.accountList.map(account => ({ ...account }))],
            name: null,
            isDesktopRequired: false,
            isActive: true,
            isChanged: true,
            isEmailAllowed: true,
            isEmailActive: true,
            isEmailRequired: false,
            isDesktopAllowed: true,
            isDesktopActive: true,
            isTextMessageActive: true,
            isTextMessageAllowed: true,
            isTextMessageRequired: false,
            notificationTypeId: 0,
            sequence: 0,
            sortOrder: null,
            threshold: {
                id: '',
                underAmount: 0,
                overAmount: 0,
                accountIds: [],
                isDeleted: false,
            },
            id: 0,
            updatedBy: null,
            updatedDate: '0001-01-01T00:00:00',
        };
    }

    $scope.$watch(
        'tempNumberHolder',
        newValue => {
            loadPhone(newValue);
            checkPhoneNumber();
        },
        true
    );

    $scope.$watch(
        'searchFilterResults',
        (newVal, oldVal) => {
            /* 
                We need to make this watch as performant as possible so we are doing
                as many checks as possible to skip the feature checks
            */
            if ($scope.loading || !newVal || newVal.length < 1) return;
            const newValIndex = newVal
                .map(group => group.name.toLowerCase())
                .indexOf('account balance');
            const oldValIndex = oldVal
                .map(group => group.name.toLowerCase())
                .indexOf('account balance');
            if (newValIndex === -1 || oldValIndex === -1) return;
            newVal[newValIndex].features.forEach(newFeature => {
                checkForFeatureChanges(
                    newFeature,
                    oldVal[oldValIndex].features,
                    newVal[newValIndex].name
                );
            });
        },
        true
    );

    function init() {
        $scope.loading = true;
        navigationService.userActivityAudit(menulessOptions.NotificationConfiguration);

        $scope.tempNumberHolder = {
            phone1: null,
            phone2: null,
            phone3: null,
            phone1Valid: false,
            phone2Valid: false,
        };

        notificationConfigurationsService.getNotificationConfigurations().then(response => {
            Promise.all([
                accountTransactionsService.getAccountList('Deposit'),
                accountTransactionsService.getAccountList('Loan'),
                accountTransactionsService.getAccountList('TimeDeposit'),
            ]).then(response2 => {
                setTimeout(() => $scope.$digest());
                const accounts = response2.flat();
                $scope.accountDisplayLabel = 'accountDisplayLabel';
                $scope.accountList = accounts.map(account => ({ ...account, isChecked: false }));
                $scope.configurations = response;

                // Add account list to each feature
                const accountBalanceConfigurationIndex = response.groups
                    .map(group => group.name.toLowerCase())
                    .indexOf('account balance');

                // Update the feature to include an account list so we don't get duplicate results for isChecked
                if (accountBalanceConfigurationIndex >= 0) {
                    const accountBalanceConfiguration =
                        response.groups[accountBalanceConfigurationIndex];
                    accountBalanceConfiguration.features = accountBalanceConfiguration.features.map(
                        feature => ({
                            ...feature,
                            uuid: uuid(),
                            accountList: $scope.accountList.map(account => ({
                                ...account,
                                isChecked: !!feature.threshold?.accountIds?.includes(
                                    account.accountUniqueId
                                ),
                            })),
                        })
                    );
                }

                sortConfigurationGroups();
                collapseGroups();

                $scope.originalEmail = $scope.configurations.email;
                $scope.copyConfigurations = $scope.configurations.groups;
                if ($scope.configurations.isDuplicateEmail) {
                    $scope.isDuplicateEmail = true;
                }

                $scope.searchFilterResults = angular.copy($scope.configurations.groups);

                let groupIndex = 0;
                $scope.searchFilterResults.forEach(group => {
                    updateList(groupIndex);
                    groupIndex++;
                });

                $scope.standardConfigurations = $scope.searchFilterResults.filter(
                    group => group.name.toLowerCase() !== 'account balance'
                );

                $scope.accountBalanceConfigurations = $scope.searchFilterResults.filter(
                    group => group.name.toLowerCase() === 'account balance'
                );

                setTimeout(() => {
                    $scope.loading = false;
                });
            });
        });
    }

    function oneAccountBalanceNotificationIsRequired(index) {
        return $scope.accountBalanceConfigurations[0].features[index].isDesktopRequired;
    }

    function canDeleteNotification(index) {
        return index > 0 || !oneAccountBalanceNotificationIsRequired(index);
    }

    function addAlert() {
        const groupIndex = $scope.searchFilterResults
            .map(group => group.name.toLowerCase())
            .indexOf('account balance');

        const config = $scope.searchFilterResults[groupIndex];
        $scope.searchFilterResults[groupIndex] = {
            ...config,
            features: [...config.features, getDefaultAccountBalanceConfiguration()],
        };
    }

    function deleteAlert(index) {
        const groupIndex = $scope.searchFilterResults
            .map(group => group.name.toLowerCase())
            .indexOf('account balance');

        const config = $scope.searchFilterResults[groupIndex];
        const features = $scope.searchFilterResults[groupIndex].features.map((f, i) => {
            if (i === index) {
                f.threshold.isDeleted = true;
                f.isChanged = true;
                return f;
            }
            return f;
        });
        $scope.searchFilterResults[groupIndex] = {
            ...config,
            features,
        };
        $scope.accountBalanceNotificationsUpdated = true;
    }

    function getTableIndex(index) {
        if ($scope.accountBalanceConfigurations.length) return index + 1;
        return index;
    }

    function updateList(parentIndex) {
        for (let i = 0; i < $scope.searchFilterResults.length; i++) {
            $scope.searchFilters.push('');
            $scope.selectAllEmails.push({ isSelected: false });
            $scope.selectAllTextMessages.push({ isSelected: false });
            $scope.selectAllDesktopNotifications.push({ isSelected: false });
        }

        const allEmailSelected = !$scope.searchFilterResults[parentIndex].features.some(
            feature => feature.isActive && !feature.isEmailActive && feature.isEmailAllowed
        );

        const allDesktopNotificationSelected = !$scope.searchFilterResults[
            parentIndex
        ].features.some(
            feature => feature.isActive && !feature.isDesktopActive && feature.isDesktopAllowed
        );

        const allTextMessageSelected = !$scope.searchFilterResults[parentIndex].features.some(
            feature =>
                feature.isActive && !feature.isTextMessageActive && feature.isTextMessageAllowed
        );

        const selectedEmailCount = $scope.searchFilterResults[parentIndex].features.filter(
            feature =>
                feature.isActive &&
                feature.isEmailActive &&
                feature.isEmailAllowed &&
                !feature.isEmailRequired
        ).length;

        const selectedDesktopCount = $scope.searchFilterResults[parentIndex].features.filter(
            feature =>
                feature.isActive &&
                feature.isDesktopActive &&
                feature.isDesktopAllowed &&
                !feature.isDesktopRequired
        ).length;

        const selectedTextCount = $scope.searchFilterResults[parentIndex].features.filter(
            feature =>
                feature.isActive &&
                feature.isTextMessageActive &&
                feature.isTextMessageAllowed &&
                !feature.isTextMessageRequired
        ).length;

        $scope.selectAllEmails[parentIndex].isSelected = selectedEmailCount && allEmailSelected;
        $scope.selectAllTextMessages[parentIndex].isSelected =
            selectedTextCount && allTextMessageSelected;
        $scope.selectAllDesktopNotifications[parentIndex].isSelected =
            selectedDesktopCount && allDesktopNotificationSelected;

        checkPhoneNumber();
        manipulateData();
    }

    $scope.$on('response', (event, data) => {
        $scope.isEmailDuplicated = data;
    });

    function changeState(feature) {
        feature.isChanged = true;
        feature.isActive = !feature.isActive;
        this.accountBalanceNotificationsUpdated = true;
    }

    function changeAccountBalanceState(feature) {
        feature.isChanged = true;
        this.accountBalanceNotificationsUpdated = true;
    }

    function changeDuplicationEmail() {
        $scope.isDuplicateEmail = false;
        $scope.$emit('response', $scope.isDuplicateEmail);
    }

    function disableStatusToggle(feature) {
        if (feature.isDesktopRequired) {
            return true;
        }
        return false;
    }

    function disableDesktopNotification(feature) {
        if (feature.isDesktopRequired) {
            return true;
        }
        if (!feature.isActive || !feature.isDesktopAllowed) {
            return true;
        }

        return false;
    }

    function disableEmailNotification(feature) {
        if (feature.isEmailRequired) {
            return true;
        }
        if (!feature.isActive || !feature.isEmailAllowed) {
            return true;
        }

        return false;
    }

    function disableTextNotification(feature) {
        if (feature.isTextMessageRequired) {
            return true;
        }
        if (!feature.isActive || !feature.isTextMessageAllowed) {
            return true;
        }

        return false;
    }

    function addHelpText(type, feature) {
        if (type === 'SMS') {
            if (feature.isTextRequired) {
                return 'This Text Message Notification is required by your Financial Institution.';
            }
            if (!feature.isTextMessageAllowed) {
                return 'This Text Message Notification is not allowed by your Financial Institution.';
            }
        } else if (type === 'Email') {
            if (feature.isEmailRequired) {
                return 'This Email Notification is required by your Financial Institution.';
            }
            if (!feature.isEmailAllowed) {
                return 'This Email Notification is not allowed by your Financial Institution.';
            }
        } else if (type === 'Desktop') {
            if (feature.isDesktopRequired) {
                return 'This Desktop Notification is required by your Financial Institution.';
            }
            if (!feature.isDesktopAllowed) {
                return 'This Desktop Notification is not allowed by your Financial Institution.';
            }
        } else if (type === 'Required') {
            return 'Notifications are required by your Financial Institution.';
        } else {
            return '';
        }
    }

    function manipulateData() {
        // used to break up phone
        if ($scope.configurations) {
            if ($scope.configurations.phoneNumber) {
                $scope.tempNumberHolder.phone1 = $scope.configurations.phoneNumber.substring(0, 3);
                $scope.tempNumberHolder.phone2 = $scope.configurations.phoneNumber.substring(3, 6);
                $scope.tempNumberHolder.phone3 = $scope.configurations.phoneNumber.substring(6, 10);
            }
        }
    }

    function searchGroups(group, index) {
        $scope.searchFilterResults[index].features = group.filter(
            feature =>
                feature.name &&
                (feature.name.toLowerCase().indexOf($scope.searchFilters[index].toLowerCase()) !==
                    -1 ||
                    feature.name
                        .toUpperCase()
                        .indexOf($scope.searchFilters[index].toUpperCase()) !== -1)
        );
        updateList(index);
    }

    function selectAllText(group, index) {
        group.forEach(feature => {
            if (
                feature.isActive &&
                feature.isTextMessageAllowed &&
                !feature.isTextMessageRequired
            ) {
                feature.isTextMessageActive = $scope.selectAllTextMessages[index].isSelected;
                feature.isChanged = true;
            }
        });
        checkPhoneNumber();
    }

    function selectAllEmail(group, index) {
        group.forEach(feature => {
            if (feature.isActive && feature.isEmailAllowed && !feature.isEmailRequired) {
                feature.isEmailActive = $scope.selectAllEmails[index].isSelected;
                feature.isChanged = true;
            }
        });
    }

    function selectAllDesktop(group, index) {
        group.forEach(feature => {
            if (feature.isActive && feature.isDesktopAllowed && !feature.isDesktopRequired) {
                feature.isDesktopActive = $scope.selectAllDesktopNotifications[index].isSelected;
                feature.isChanged = true;
            }
        });
    }

    function checkPhoneNumber() {
        $scope.userOptedToReceiveTextMsgs = false;
        $scope.searchFilterResults.forEach(group => {
            group.features.forEach(feature => {
                if (feature.isTextMessageActive) {
                    $scope.userOptedToReceiveTextMsgs = true;
                }
            });
        });
        $scope.phoneNumberRequired = $scope.userOptedToReceiveTextMsgs;
    }

    function loadPhone(newValue) {
        if (
            newValue.phone1 !== null &&
            newValue.phone1 !== undefined &&
            newValue.phone1.length === 3 &&
            isNaN(newValue.phone1) === false
        ) {
            $scope.tempNumberHolder.phone1Valid = true;
        } else {
            $scope.tempNumberHolder.phone1Valid = false;
        }

        if (
            newValue.phone2 !== null &&
            newValue.phone2 !== undefined &&
            newValue.phone2.length === 3 &&
            isNaN(newValue.phone2) === false
        ) {
            $scope.tempNumberHolder.phone2Valid = true;
        } else {
            $scope.tempNumberHolder.phone2Valid = false;
        }

        if (
            newValue.phone1 !== null &&
            newValue.phone1 !== undefined &&
            newValue.phone1.length === 3 &&
            newValue.phone2 !== null &&
            newValue.phone2 !== undefined &&
            newValue.phone2.length === 3 &&
            newValue.phone3 !== null &&
            newValue.phone3 !== undefined &&
            newValue.phone3.length === 4
        ) {
            $scope.configurations.phoneNumber =
                newValue.phone1.toString() +
                newValue.phone2.toString() +
                newValue.phone3.toString();
        }

        $scope.phoneNumberRequired = $scope.userOptedToReceiveTextMsgs;
        $scope.carrierRequired =
            $scope.phoneNumberRequired ||
            ($scope.configurations.phoneNumber && $scope.configurations.phoneNumber.length === 10);
    }

    function setForm(form) {
        $scope.form = form;
    }

    function collapseGroups() {
        $scope.configurations.groups.forEach(group => {
            if ($scope.configurations.groups.indexOf(group) !== 0) {
                group.isCollapsed = true;
            }
        });
    }

    function mapToApiConfigurationObject(configuration) {
        const dto = { ...configuration };
        const groupIndex = dto.groups
            .map(group => group.name.toLowerCase())
            .indexOf('account balance');

        const dtoGroup = dto.groups[groupIndex];
        if (dtoGroup) {
            dto.groups[groupIndex] = {
                ...dtoGroup,
                features: dtoGroup.features.map(feature => ({
                    ...feature,
                    threshold: {
                        ...feature.threshold,
                        accountIds: feature.threshold.accounts.map(
                            account => account.accountUniqueId
                        ),
                    },
                })),
            };
        }

        return dto;
    }

    function save() {
        $scope.configurations.groups = angular.copy($scope.searchFilterResults);
        notificationConfigurationsService.getNotificationConfigurations().then(response => {
            const existingConfigurations = response;
            const validationResults =
                checkForRecentlyUpdatedRequiredNotifications(existingConfigurations);

            if (validationResults.isValid) {
                if ($scope.originalEmail !== $scope.configurations.email) {
                    securityService
                        .verifyUser('Update Email Address', $scope.configurations, () =>
                            notificationConfigurationsService.save($scope.configurations)
                        )
                        .then(afterNotificationPreferencesSaved);
                } else {
                    const configurations = mapToApiConfigurationObject($scope.configurations);
                    notificationConfigurationsService
                        .save(configurations)
                        .then(afterNotificationPreferencesSaved);
                }
            } else {
                $modal.open({
                    template: require('./requiredNotifications.html'),
                    size: 'md',
                    controller: 'RequiredNotificationsController',
                    backdrop: 'static',
                    resolve: {
                        errors() {
                            return validationResults.errorItems;
                        },
                    },
                });
            }
        });
    }

    function checkForFeatureChanges(changed, persistedFeatures, changedGroupName) {
        if (changedGroupName.toLowerCase() !== 'account balance') return;

        const persistedIndex = persistedFeatures.map(feature => feature.uuid).indexOf(changed.uuid);
        const persistedThreshold = persistedFeatures[persistedIndex]?.threshold;
        const changedThreshold = changed?.threshold;
        let hasChanged = false;
        // check for deletion
        // check for over under change
        // check for account changes

        if (persistedThreshold?.underAmount !== changedThreshold?.underAmount) hasChanged = true;
        if (persistedThreshold?.overAmount !== changedThreshold?.overAmount) hasChanged = true;

        const persistedAccountIds = persistedThreshold?.accounts.map(
            account => account.accountUniqueId
        );
        const changedAccountIds = changedThreshold?.accounts?.map(
            account => account.accountUniqueId
        );

        if (persistedAccountIds.toString() !== changedAccountIds.toString()) {
            changedThreshold.accountIds = changedAccountIds;
            hasChanged = true;
        }

        if (hasChanged) {
            changed.isChanged = true;
        }

        return hasChanged;
    }

    function checkForRecentlyUpdatedRequiredNotifications(refreshedConfigurations) {
        // Before save, do a concurrency check for Required notifications in case a notification was updated by the
        // FI to be required at the same time they were updated by the channel user.
        let isValid = true;
        const errorItems = [];
        refreshedConfigurations.groups.forEach(group => {
            group.features.forEach(feature => {
                const updatedFeature = getFeature($scope.configurations, group.name, feature.name);
                if (feature.isDesktopRequired && feature.isActive) {
                    // Validate Desktop Notification with FI Settings
                    if (
                        updatedFeature &&
                        (!updatedFeature.isDesktopActive || !updatedFeature.isActive)
                    ) {
                        errorItems.push(feature.name);
                        isValid = false;
                    }
                } else if (feature.isTextRequired && feature.isActive) {
                    // Validate Text Message Notification with FI Settings
                    if (
                        updatedFeature &&
                        (!updatedFeature.isTextMessageActive || !updatedFeature.isActive)
                    ) {
                        errorItems.push(feature.name);
                        isValid = false;
                    }
                } else if (feature.isEmailRequired && feature.isActive) {
                    // Validate Email Notification with FI Settings
                    if (
                        updatedFeature &&
                        (!updatedFeature.isEmailActive || !updatedFeature.isActive)
                    ) {
                        errorItems.push(feature.name);
                        isValid = false;
                    }
                }
            });
        });

        return { isValid, errorItems };
    }

    function getFeature(configuration, groupName, featureName) {
        const foundGroupIndex = configuration.groups.map(group => group.name).indexOf(groupName);
        if (foundGroupIndex === -1) return null;

        const foundFeatureIndex = configuration.groups[foundGroupIndex].features
            .map(feature => feature.name)
            .indexOf(featureName);
        if (foundFeatureIndex === -1) return null;

        return configuration.groups[foundGroupIndex].features[foundFeatureIndex];
    }

    function afterNotificationPreferencesSaved(response) {
        $scope.configurations = response;

        sortConfigurationGroups();

        if ($scope.configurations.isDuplicateEmail) {
            $scope.isDuplicateEmail = true;
        }

        $scope.searchFilterResults = $scope.configurations.groups.map(item => item);

        $scope.configurations.phoneNumber =
            $scope.tempNumberHolder.phone1 +
            $scope.tempNumberHolder.phone2 +
            $scope.tempNumberHolder.phone3;
        $scope.originalEmail = $scope.configurations.email;

        let groupIndex = 0;
        $scope.searchFilterResults.forEach(group => {
            updateList(groupIndex);
            groupIndex++;
        });

        collapseGroups();

        toaster.save('Changes');
        $scope.form.$setPristine();
    }

    function reset() {
        init();
        $scope.form.$setPristine();
    }

    function sortConfigurationGroups() {
        $scope.configurations.groups = $filter('orderBy')($scope.configurations.groups, 'name');
    }

    init();
}
