export default [
    'localStorageService',
    function (localStorageService) {
        return {
            retrieve(key) {
                return localStorageService.get(key);
            },
            persist(key, data) {
                localStorageService.add(key, JSON.stringify(data));
            },
            remove(key) {
                localStorageService.remove(key);
            },
            clearAll() {
                localStorageService.clearAll();
            },
        };
    },
];
