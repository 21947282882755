/**
 * Function used for dynamically loading nested modules. Checks to see if module already exists
 * and if it does not, uses Webpack to load the correct module then injects it into
 * the existing angular code.
 *
 * @param $injector - Injector used to inject the new module.
 * @param { string } moduleNameToLoad - Name of the module you wish to dynamically load follows
 * @param { string } [prefix]
 */
export async function loadNewModules($injector, moduleNameToLoad, prefix) {
    // Here we are forcing nested modules to use the . syntax
    const fullModuleName = `channel.${moduleNameToLoad}`;
    try {
        angular.module(fullModuleName);
    } catch {
        const modulePath = moduleNameToLoad.split('.').join('/');
        if (prefix) {
            await import(`./${prefix}/${modulePath}/module.js`);
        } else {
            await import(`./${modulePath}/module.js`);
        }

        $injector.loadNewModules([fullModuleName]);
    }
}
