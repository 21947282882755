import { html } from 'lit';
import Column from './column.js';

export default class EmptyColumn extends Column {
    constructor(...args) {
        super(...args);
        const [{ label }] = args;
        this.label = label;

        this.minWidth = 50;
        this.targetWidth = 75;
        this.maxWidth = 100;
    }

    cellClasses() {
        return [...super.cellClasses(), 'empty-column', 'omega-no-print'];
    }

    renderThContents() {
        return this.label;
    }

    renderTdContents(record, rowIndex) {
        return html` <div style="min-width: ${this.minWidth}; max-width: ${this.maxWidth}"></div> `;
    }
}
