PaymentRecipientsModalController.$inject = [
    '$scope',
    '$modal',
    'batch',
    '$modalInstance',
    'achBatchService',
];

export default function PaymentRecipientsModalController(
    $scope,
    $modal,
    batch,
    $modalInstance,
    achBatchService
) {
    $scope.batch = batch;
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };

    $scope.showAddendaModal = showAddendaModal;
    $scope.filterRecipients = filterRecipients;
    $scope.close = close;
    $scope.searchByText = searchByText;
    $scope.counts = {
        prenote: 0,
        hold: 0,
    };

    function searchByText() {
        $scope.filteredRecipients = filterRecipients();
    }
    function close() {
        $modalInstance.dismiss();
    }

    function filterRecipients() {
        if ($scope.batch.recipients) {
            let filteredArray = $scope.batch.recipients.filter(recipient => {
                if (recipient.isDeleted) {
                    return false;
                }

                return (
                    $scope.searchFilters.text === '' ||
                    (recipient.name &&
                        recipient.name
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    recipient.name
                        .toUpperCase()
                        .indexOf($scope.searchFilters.text.toLowerCase()) !== -1 ||
                    (recipient.idNumber &&
                        recipient.idNumber.indexOf($scope.searchFilters.text) !== -1) ||
                    (recipient.accountNumber &&
                        recipient.accountNumber.indexOf($scope.searchFilters.text) !== -1)
                );
            });

            if ($scope.searchFilters.prenote && $scope.batch.recipients) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.prenote && recipient.prenote
                );
            }

            return filteredArray;
        }
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../../batch/views/addendaModalView.html'),
            size: 'sm',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.batch,
                        recipient,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });

        modalInstance.result.then(data => {
            batch.addendaType = data.addendaType;
            batch.addendaBody = data.addendaBody;
        });
    }

    (function () {
        // init
        $scope.filteredRecipients = filterRecipients();
        achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
    })();
}
