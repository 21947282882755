/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import { FiDate } from '../../../dates';
import removeUnusedKeys from '../../../shared/utilities/remove-unused-keys.js';
import { mapPaymentHeaderAndRecipientsRecordset } from '../../mappings/ach/ach-domestic-payments.js';
import {
    mapAchPaymentFiltersToRequest,
    mapResponseToAchPaymentFields,
    mapUpdateBatchPaymentParamsToRequest,
    mapUpdatePaymentParamsToRequest
} from '../../mappings/ach/ach-payment-activity.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchDomesticRequests from '../../requests/ach/ach-domestic-requests.js';
import SecCodesRequests from '../../requests/codes/sec-codes-requests.js';
import DownloadRequests from '../../requests/download/download.js';

export default class AchPaymentsServices {
    static async updateRecurringAchPaymentStatus(action, data) {
        return AchDomesticRequests.updateRecurringAchPaymentStatus(action, data);
    }

    static async updateAchPaymentStatus(...args) {
        const params = mapUpdatePaymentParamsToRequest(...args);
        return AchDomesticRequests.updatePaymentStatus(...params);
    }

    static async updateBatchAchPaymentStatuses(...args) {
        const params = mapUpdateBatchPaymentParamsToRequest(...args);
        return AchDomesticRequests.updateBatchPaymentStatuses(...params);
    }

    static async createAchPayment(paymentHeaderRecord, recipientsRecordset) {
        const body = mapPaymentHeaderAndRecipientsRecordset(
            paymentHeaderRecord,
            recipientsRecordset
        );
        return AchDomesticRequests.createPayment(body);
    }

    static async fetchAchPaymentActivity({ parameters }, fileArchiveIds) {
        const body = mapAchPaymentFiltersToRequest(parameters, fileArchiveIds);
        const fetchedData = await AchDomesticRequests.fetchAchPaymentActivity(body);
        const mappedData = mapResponseToAchPaymentFields(fetchedData);
        return {
            data: mappedData,
            totalCount: mappedData.length,
        };
    }

    static async fetchAchPayments(parameters) {
        const fetchedData = await AchDomesticRequests.fetchAchPaymentActivity(parameters);
        const mappedData = mapResponseToAchPaymentFields(fetchedData);
        return {
            data: mappedData,
            totalCount: mappedData.length,
        };
    }

    static async fetchPaymentById(paymentId) {
        const http = await TmHttpClient.getInstance();
        const response = await http.request(`achPayments/${paymentId}`, {
            method: 'GET',
        });
        return {
            ...response,
            payment: {
                ...response.payment,
                recipients: response.payment.recipients.map(recipient => ({
                    ...recipient,
                    formattedAmount: recipient.amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }),
                })),
                formattedTotalAmount: (
                    response.payment.creditAmount + response.payment.debitAmount
                ).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                formattedDebitAmount: response.payment.debitAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
                formattedCreditAmount: response.payment.creditAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
            },
        };
    }

    static async fetchAchPaymentTemplates({ parameters }) {
        const http = await TmHttpClient.getInstance();
        // const body = mapAchPaymentFiltersToRequest(parameters);
        const fetchedData = await http.request(`achBatches/search`, {
            method: 'POST',
            body: parameters,
        });
        // const mappedData = mapResponseToAchPaymentFields(fetchedData);
        return {
            data: fetchedData,
            totalCount: fetchedData.length,
        };
    }

    static async downloadAchPayments(type, page, params) {
        const fileSuffix = `${type.split(' ')[0]}-${new FiDate(new Date()).toIsoDate()}`;
        const { fileArchiveIds } = params;
        const filters = removeUnusedKeys(mapAchPaymentFiltersToRequest(params, fileArchiveIds));
        const downloadFormats = ['Summary - PDF', 'Detail - PDF', 'Summary - CSV', 'Detail - CSV'];
        return DownloadRequests.download(
            fileSuffix,
            type,
            page,
            { ...filters, page: 'ACHPaymentList' },
            downloadFormats
        );
    }

    static async getSecCodes() {
        const data = await SecCodesRequests.getSecCodes();
        if (!data.includes('All')) {
            data.unshift('All');
        }
        return data;
    }

    static async getAchCompanies() {
        return AchCompanyRequests.getAchCompanies();
    }

    static isTaxPayment(values) {
        return values.achPaymentTypeId === 2 || values.achPaymentTypeId === 3;
    }

    static isChildSupportPayment(values) {
        return values.transactionId?.includes('-C');
    }

    static isInternationalAchPayment(values) {
        return values.secCode?.includes('IAT');
    }
}
