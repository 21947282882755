import { html, LitElement } from 'lit';
import './subTypes/omega-frequency-week-recurring.js';

export class EveryTwoWeeks extends LitElement {
    static get properties() {
        return {
            frequency: Object,
            readOnly: { type: Boolean, attribute: 'readonly' },
            disabled: { type: Boolean, attribute: 'disabled' },
            isValidStartDate: Function,
            isValidEndDate: Function,
            isStartDateInvalid: Function,
            isEndDateInvalid: Function,
        };
    }

    static get is() {
        return 'omega-frequency-every-two-weeks';
    }

    setFrequency(e) {
        this.frequency = e.detail;
        this.dispatchEvent(new CustomEvent('change', { detail: e.detail }));
    }

    render() {
        return html`<omega-frequency-week-recurring
            .frequency=${this.frequency}
            .isStartDateInvalid=${this.isStartDateInvalid}
            .isEndDateInvalid=${this.isEndDateInvalid}
            @change=${this.setFrequency}
            ?disabled=${this.disabled}
            ?readonly=${this.readOnly}
        ></omega-frequency-week-recurring>`;
    }
}

window.customElements.define(EveryTwoWeeks.is, EveryTwoWeeks);
export default EveryTwoWeeks;
