const d = new Date();
const m = d.getMonth();
const y = d.getFullYear();
const offsetToday = new Date().getTimezoneOffset() * 60000;
const today = () => new Date(d - offsetToday);

const dateStrings = {
    TODAY: 'Today',
    WTD: 'Week To Date',
    MTD: 'Month To Date',
    YTD: 'Year To Date',
    RANGE: 'Date Range',
    SPECIFIC: 'Specific Date',
};
const datePeriods = {
    TODAY: {
        text: 'Today',
        value: today(),
    },
    WTD: {
        text: 'Week To Date',
        value: {
            start: new Date(today().setDate(today().getDate() - today().getDay() + 1)),
            end: today(),
        },
    },
    MTD: {
        text: 'Month To Date',
        value: {
            start: new Date(y, m, 1),
            end: today(),
        },
    },
    YTD: {
        text: 'Year To Date',
        value: {
            start: new Date(new Date().getFullYear(), 0, 1),
            end: today(),
        },
    },
};

/**
 * @typedef ApiDateTuple
 * @property {string} start
 * @property {string} end
 */

/**
 * Static class collection date formatting functionality.
 */
export class DateFormatters {
    static getToday() {
        return today();
    }

    /**
     *
     * @param {Date|string} value
     * This is messy - we were going to run into issues where we have to handle something like '$TODAY' on the front end sometimes (passing the date) or just passing
     * the string 'Today' for saved reports. My solution is to pass an object with the text and the date value and then we can take which value we need for the implementation
     * We also have to remove time on dates, it looks like the endpoints (or at least some of them) are only set up to take yyyy-mm-dd format and do not recognize dates
     * despite what you may see in the models
     */
    static parseDate(value) {
        if (typeof value === 'undefined') {
            // eslint-disable-next-line no-console
            console.warn('Value passed to parseDate was undefined');
            return null;
        }
        if (value.formattedValue === '') {
            return null;
        }
        if (value?.dates) {
            return this.apiFormattedDates(value.dates);
        }
        if (this.isDatePeriod(value)) {
            const date = this.datePeriodValueMapper(value);
            if (date.value.start) {
                date.value.start = this.removeTime(date.value.start);
                date.value.end = this.removeTime(date.value.end);
            } else {
                date.value = this.removeTime(date.value);
            }
            return date;
        }
        if (this.isDateRange(value)) return this.getDateRange(value);
        return {
            value,
        };
    }

    static getDateRange(value) {
        const dates = value.split(' - ');
        return {
            text: 'dateRange',
            value: {
                start: this.removeTime(dates[0]),
                end: this.removeTime(dates[1]),
            },
        };
    }

    static getLegacyDatePeriod(value) {
        if (value.legacyId) return value.legacyId;
        return value;
    }

    static getDateType(value) {
        if (!value) return '';
        if (this.isDatePeriod(value) && this.getLegacyDatePeriod(value) !== '$TODAY')
            return 'dateRange';
        if (this.isDateRange(value)) return 'dateRange';
        return 'specificDate';
    }

    static isDateToday(dateValues) {
        return dateValues[0] === '$TODAY';
    }

    static isDateRangeSameDay(dateValues) {
        if (!Array.isArray(dateValues)) return false;
        return dateValues[0] === dateValues[1];
    }

    static isDateSpanSameDay(datePeriod) {
        if (typeof datePeriod === 'string' && datePeriod.length === 10) return true;
        return this.dateRangeMapper(datePeriod)[0] === this.dateRangeMapper(datePeriod)[1];
    }

    static dateRangeMapper(date) {
        return date.split(' - ');
    }

    static isDateRange(value) {
        if (value instanceof Object) {
            return value.isRange;
        }
        return !this.isDatePeriod(value) && value?.split(' - ').length > 1;
    }

    static isDynamicOrSelectedDateRange({ value }) {
        if (value instanceof Object) return true;
        if (['week-to-date', 'month-to-date', 'year-to-date'].includes(value)) return true;
        return false;
    }

    static isDatePeriod(value) {
        if (value instanceof Object) {
            return value.legacyId?.indexOf('$') > -1;
        }
        return value?.indexOf('$') > -1;
    }

    static datePeriodMapper(datePeriod) {
        if (!datePeriod) return dateStrings.YTD;
        // if (!this.isDateRange(datePeriod) && !this.isDateSpanSameDay(datePeriod))
        //     return dateStrings.RANGE;
        if (datePeriod === '$TODAY') return dateStrings.TODAY;
        if (datePeriod === '$MTD') return dateStrings.MTD;
        if (datePeriod === '$WTD') return dateStrings.WTD;
        if (datePeriod === '$YTD') return dateStrings.YTD;
        if (this.isDateSpanSameDay(datePeriod)) return dateStrings.SPECIFIC;
        return datePeriod;
    }

    static datePeriodValueMapper(datePeriod) {
        if (datePeriod === '$TODAY') return datePeriods.TODAY;
        if (datePeriod === '$MTD') return datePeriods.MTD;
        if (datePeriod === '$WTD') return datePeriods.WTD;
        if (datePeriod === '$YTD') return datePeriods.YTD;
        return datePeriod;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    static buildFullDate(formattedDate, index, time) {
        const dayWithoutTime = formattedDate;
        if (time || time === 0) {
            return `${dayWithoutTime} ${this.formatTimeFilter(time)}`;
        }
        if (index === 0) {
            return `${dayWithoutTime} ${this.formatTimeFilter(0)}`;
        }
        if (index === 1) {
            return `${dayWithoutTime} ${this.formatTimeFilter(1425)}`;
        }
        return ``;
    }

    static getDatesFromPeriod(type, from, to, specific) {
        switch (type) {
            case 'Today':
                return '$TODAY';
            case 'Specific Date':
                return `${this.removeTime(new Date(specific))} - ${this.removeTime(
                    new Date(specific)
                )}`;
            case 'Week To Date':
                return '$WTD';
            case 'Month To Date':
                return '$MTD';
            case 'Year To Date':
                return '$YTD';
            default:
                return `${this.removeTime(new Date(from))} - ${this.removeTime(new Date(to))}`;
        }
    }

    static removeTime(date) {
        if (date instanceof Date) {
            const offset = date.getTimezoneOffset() * 60000;
            const offsetDate = new Date(date - offset).toISOString().slice(0, -1);
            return offsetDate.toString().split('T')[0];
        }
        return date;
    }

    /**
     *
     * @param {Date[]} dates
     * @returns {{value: ApiDateTuple | string}
     */
    static apiFormattedDates(dates) {
        if (!dates?.length) return null;

        const formattedDates = {};
        const start = this.removeTime(dates[0]);

        if (dates[1]) {
            formattedDates.value = {
                start,
                end: this.removeTime(dates[1]),
            };
        } else {
            formattedDates.value = start;
        }

        return formattedDates;
    }

    /**
     * Syntax sugar function to format a single date as a string.
     *
     * Convenience alterative that passes through to `apiFormattedDates()`.
     *
     * @param {Date} date
     * @returns { string }
     */
    static apiFormattedDate(date) {
        const formatted = DateFormatters.apiFormattedDates([date]).value;
        return typeof formatted === 'string' ? formatted : formatted.start;
    }
}

export default DateFormatters;
