import { string } from '@treasury/FDL';
import { enumeration } from './primitives';
import noPunctuation from './lib/validators/noPunctuation.validator.js';
import mustBePositiveInteger from './lib/validators/mustBePositiveInteger.validator.js';

export const accountName = string;

export const accountType = enumeration.with.exampleValue('Credit');

export const accountNumber = string.with
    .cellClass('account-number')
    .and.validator(noPunctuation)
    .and.validator(mustBePositiveInteger)
    .and.minColumnWidth(50)
    .and.targetColumnWidth(60)
    .and.maxColumnWidth(100)
    .and.inputMask(/^\d+$/);

export const multipleAccounts = string.with
    .cellClass('account-number')
    .and.minColumnWidth(50)
    .and.targetColumnWidth(60)
    .and.maxColumnWidth(100)
    .and.inputMask(/^\d+$/);
