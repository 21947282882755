import { css, html, LitElement, nothing } from 'lit';

import '@treasury/omega/components/omega-button-bar.js';

import '@treasury/omega/components/omega-workflow.js';
import { mapWiresToRecordSet } from '../helpers/recordsetMapping.js';
import './workflow-steps/wire-file-upload.js';
import './workflow-steps/wire-upload-confirmation.js';
import './workflow-steps/wire-upload-review.js';
import './workflow-steps/wire-upload-summary.js';

class WireUploadWorkflow extends LitElement {
    static get properties() {
        return {
            activeStep: Number,
            files: Array,
            fileFormats: Array,
            wireCompanies: Array,
            wireFile: Object,
            wireFileUniqueId: String,
            wireConfiguration: Object,
            accountConfiguration: Object,
            reviewRecords: Array,
            uploading: { type: Boolean, reflect: true },
            confirming: { type: Boolean, reflect: true },
            institution: Number,
            holidays: Array,
            filters: Array,
            records: Object,
        };
    }

    constructor() {
        super();
        this.files = [];
        this.uploadDisabled = true;
        this.wireCompanies = [];
    }

    static get is() {
        return 'wire-upload-workflow';
    }

    next() {
        this.activeStep++;
        this.dispatchEvent(new CustomEvent('stepChange', { detail: { value: this.activeStep } }));
    }

    back() {
        if (this.wireFileUniqueId && this.activeStep === 0) {
            this.dispatchEvent(new CustomEvent('goToWireFileActivity'));
        }
        this.activeStep--;
        this.dispatchEvent(new CustomEvent('stepChange', { detail: { value: this.activeStep } }));
    }

    setUpdatedRecordData(reviewRecordset, selectedRecords) {
        reviewRecordset.allRecords.forEach((record, index) => {
            record.setField('wireAmount', selectedRecords[index].values.wireAmount);
            record.setField('frequency', selectedRecords[index].values.frequency);
            record.setField('effectiveDate', selectedRecords[index].values.effectiveDate);
            record.setField('purpose', selectedRecords[index].values.purpose);
            record.setField(
                'additionalInformation',
                selectedRecords[index].values.additionalInformation
            );
            record.setField(
                'referenceBeneficiary',
                selectedRecords[index].values.referenceBeneficiary
            );
        });
    }

    async review({ detail }) {
        this.filteredRecords = detail.value.recordsMatching('selected', true);
        const selectedRecordsData = this.filteredRecords.map(record => record.values.data);
        this.reviewRecords = mapWiresToRecordSet(
            selectedRecordsData,
            this.wireConfiguration,
            this.holidays
        );

        await this.reviewRecords.requestUpdate();
        this.setUpdatedRecordData(this.reviewRecords, this.filteredRecords);
        this.next();
    }

    updateRecords(e) {
        this.records = e.detail.records;
    }

    renderSelectFileStep() {
        if (this.wireFileUniqueId) return nothing;
        return html` <div slot="step" data-label="Select File">
            <wire-file-upload
                .fileFormats=${this.fileFormats}
                .wireCompanies=${this.wireCompanies}
                .files=${this.files}
                @upload=${this.uploadFiles}
                @next=${this.next}
                @formatChange=${this.formatChange}
                @companyChange=${this.companyChange}
                ?uploading=${this.uploading}
            ></wire-file-upload>
        </div>`;
    }

    render() {
        const summaryTitle = this.wireFileUniqueId ? 'File Process Summary' : 'File Upload Summary';
        const reviewTitle = this.wireFileUniqueId ? 'File Process Review' : 'File Upload Review';
        const confirmationTitle = this.wireFileUniqueId
            ? 'File Process Confirmation'
            : 'File Upload Confirmation';
        return html`
            <omega-workflow .activeStep=${this.activeStep}>
                ${this.renderSelectFileStep()}
                <div slot="step" data-label="Summary">
                    <wire-upload-summary
                        .wireFile=${this.wireFile}
                        .wireConfiguration=${this.wireConfiguration}
                        .accountConfiguration=${this.accountConfiguration}
                        .holidays=${this.holidays}
                        @change=${this.updateRecords}
                        @updateRecords=${this.updateRecords}
                        @review=${this.review}
                        @back=${this.back}
                    >
                        <wire-file-summary-header
                            .files=${this.files}
                            .wireFile=${this.wireFile}
                            title=${summaryTitle}
                            .records=${this.records}
                        ></wire-file-summary-header>
                    </wire-upload-summary>
                </div>
                <div slot="step" data-label="Review">
                    <wire-upload-review
                        .records=${this.reviewRecords}
                        .accountConfiguration=${this.accountConfiguration}
                        readonly="true"
                        .filters=${this.filters}
                        @confirm=${this.confirm}
                        @back=${this.back}
                        ?confirming=${this.confirming}
                    >
                        <wire-file-summary-header
                            .files=${this.files}
                            .wireFile=${this.wireFile}
                            .records=${this.records}
                            title=${reviewTitle}
                        ></wire-file-summary-header>
                    </wire-upload-review>
                </div>
                <div slot="step" data-label="Confirmation">
                    <wire-upload-confirmation
                        .wireFileUniqueId=${this.wireFileUniqueId}
                        .records=${this.reviewRecords}
                        .accountConfiguration=${this.accountConfiguration}
                        readonly="true"
                        .filters=${this.filters}
                        .institution=${this.institution}
                    >
                        <wire-file-summary-header
                            .files=${this.files}
                            .wireFile=${this.wireFile}
                            .records=${this.records}
                            title=${confirmationTitle}
                        ></wire-file-summary-header>
                    </wire-upload-confirmation>
                </div>
            </omega-workflow>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* this makes everything match production */
                /* design is inconsistent with the 'style guide' they've given us */
                display: block;
                height: calc(100% - 66px);
                --omega-button-bar-offset: -50px;
            }
            omega-workflow {
                height: 100%;
                --workflow-content-padding: 0;
            }
        `;
    }
}

customElements.define(WireUploadWorkflow.is, WireUploadWorkflow);
export default WireUploadWorkflow;
