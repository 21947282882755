/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AchCompanyModelDto, CompanyAccountModelDto, GuidKeyCollectionDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchCompaniesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchCompanies
     * @name AchCompaniesGet
     * @summary Returns the ACH Companies.
     * @request GET:/achCompanies
     */
    achCompaniesGet = (params: RequestParams = {}) =>
        this.http.request<AchCompanyModelDto[], any>({
            path: `/achCompanies`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchCompanies
     * @name AchCompaniesGet2
     * @summary Gets All The Sec Codes For The ACH Company.
     * @request GET:/achCompanies/{id}/secCodes
     */
    achCompaniesGet2 = (id: number, params: RequestParams = {}) =>
        this.http.request<GuidKeyCollectionDto, any>({
            path: `/achCompanies/${id}/secCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchCompanies
     * @name AchCompaniesOffsetAccounts
     * @summary Get a list of offset accounts linked to a given ACH company.
     * @request GET:/achCompanies/{id}/offsetAccounts
     */
    achCompaniesOffsetAccounts = (
        id: number,
        query?: {
            /**
             * Whether or not to apply the 'display account nicknames' user preference to
             * the results. Defaults to 'true' if nothing is supplied.
             *
             * @default true
             */
            enableAccountNicknameSetting?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/achCompanies/${id}/offsetAccounts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
