export const setAddress = function (address, countries) {
    const lines = [];
    // Line 1 – Building Number, Street Name
    if (address.addressLine1 || address.addressLine2 || address.addressLine3) {
        const line1 = [address.addressLine1, address.addressLine2, address.addressLine3]
            .filter(Boolean)
            .join(' ');
        lines.push(line1);
    } else if (address.buildingNumber || address.streetName) {
        const line1 = [address.buildingNumber, address.streetName].filter(Boolean).join(' ');
        lines.push(line1);
    }
    // Line 2 – City/Town Name, State/Country Sub division, Post Code
    if (address.city || address.state || address.postalCode) {
        let line2 = [address.city, address.state].filter(Boolean).join(', ');
        if (address.postalCode) {
            line2 = [line2, address.postalCode].filter(Boolean).join(' ');
        }
        if (line2 && address.postalCodeExtension) {
            line2 = [line2, address.postalCodeExtension].filter(Boolean).join('-');
        }
        lines.push(line2);
    }
    // Line 3 – Country Code
    if (address.countryCode) {
        if (countries) {
            lines.push(getCountryNameFromCode(address.countryCode, countries));
        } else {
            lines.push(address.countryCode);
        }
    }
    // Lines 4-11 – Department, Sub Department, Post Box, Building Name, Floor, Room, Town Location Name, District Name
    optionalLocationFieldIds.forEach(field => {
        if (address[field]) {
            lines.push(address[field]);
        }
    });
    return lines;
};

export const getCountryNameFromCode = function (countryCode, countries) {
    let result = null;
    countries.forEach(country => {
        if (country.countryCode === countryCode) {
            result = country.countryName;
        }
    });
    return result;
};

export const acceptedAdditionalInfoChars = /^[a-zA-Z0-9!#&%*=^_`{}|~";@\[\]\ ]+$/;

export const additionalLocationInfoFields = [
    {
        name: 'Department',
        id: 'department',
        index: 0,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Sub department',
        id: 'subDepartment',
        index: 1,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Post box',
        id: 'postBox',
        index: 2,
        charLimit: 16,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Building Name',
        id: 'buildingName',
        index: 3,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Floor',
        id: 'buildingFloor',
        index: 4,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Room',
        id: 'buildingRoom',
        index: 5,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Town location name',
        id: 'townLocationName',
        index: 6,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'District name',
        id: 'districtName',
        index: 7,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
];

export const optionalLocationFieldIds = additionalLocationInfoFields.map(field => field.id); // ['department', 'subDepartment', 'postBox', 'buildingName', 'buildingFloor', 'buildingRoom', 'townLocationName', 'districtName'];
