import { createIssuedItem, IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { ArpAccountsService } from '@treasury/domain/channel/services';
import { FieldType, Recordset } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import {
    OmegaColumnAction,
    OmegaColumnDefinition,
    OmegaColumnType,
} from '@treasury/omega/components/table';
import FieldTypes from '@treasury/policy/primitives';
import { exists, isIntegerString } from '@treasury/utils';
import { issuedItemCreationColumns } from '../definitions';
import { createSearchableAccountField } from './shared.data';

const itemTypeField = new FieldType<IIssuedItem['type']>().thatIs
    .required()
    .with.defaultValue(null)
    .with.placeholder('')
    .and.options([
        {
            text: 'Check',
            value: 'Check',
        },
        {
            text: 'Void',
            value: 'Void',
        },
    ]);

/** The maximum characters allowed by Silverlake in a payee name. */
const MAX_PAYEE_LENGTH = 80;
const MAX_CHECK_NUM_LENGTH = 10;

export function createFields(
    accountsService: ArpAccountsService
): FdlFieldDefinitions<IIssuedItem> {
    return {
        account: createSearchableAccountField(accountsService)
            .and.required()
            .and.disabledWhen<IIssuedItem>(record => record.values.itemEntryType === 'Upload'),
        checkNumber: (FieldTypes.string as FieldType<IIssuedItem['checkNumber']>).thatIs
            .required()
            .and.validator({
                name: 'checkNumberValidator',
                validate: v =>
                    exists(v) &&
                    v.length > 0 &&
                    v.length <= MAX_CHECK_NUM_LENGTH &&
                    isIntegerString(v),
            })
            .and.maxLength(MAX_CHECK_NUM_LENGTH)
            .and.minLength(1)
            .and.inputMask(/[0-9]/),
        dateIssued: (FieldTypes.datePicker as unknown as FieldType<IIssuedItem['dateIssued']>).with
            .defaultValue(null)
            .and.placeholder('Select a date')
            .and.transform<OmegaDate>(
                v =>
                    (v.id !== 'no-date-selected' && v.dates.length > 0
                        ? v.dates[0]
                        : undefined) as Date
            )
            .and.minColumnWidth(250),
        checkAmount: FieldTypes.currency.with
            .defaultValue(0)
            .thatIs.requiredWhen<IIssuedItem>(
                record => exists(record) && record.getField('type') === 'Check'
            )
            .with.validator<IIssuedItem>({
                name: 'checkAmount',
                validate: (value, viewValue, record) =>
                    // $0.00 is considered an invalid/empty value when this field is required;
                    // for voids, any positive value is accepted
                    record.getField('type') === 'Check' ? value > 0 : value >= 0,
            }),
        type: itemTypeField,
        payee: (FieldTypes.string as FieldType<string>).with.maxLength(MAX_PAYEE_LENGTH),
    };
}

export function createRecordset(accountsService: ArpAccountsService, items?: IIssuedItem[]) {
    const fields = createFields(accountsService);
    const nullItem = createIssuedItem();
    return new Recordset<IIssuedItem>(fields, () => items ?? [nullItem]);
}

export const columns: OmegaColumnDefinition<IIssuedItem>[] = [
    ...issuedItemCreationColumns,
    {
        label: '',
        type: OmegaColumnType.EditRow,
        options: [
            {
                action: OmegaColumnAction.Add,
            },
            {
                action: OmegaColumnAction.Delete,
            },
        ],
    },
];
