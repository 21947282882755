import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-tooltip.js';
import { normalizeFrequencyType } from '@treasury/domain/shared/utilities/normalize-frequency-type.js';

class FrequencyDetail extends LitElement {
    static get properties() {
        return {
            record: Object,
            displayDate: String,
        };
    }

    constructor() {
        super();
        this.displayDate = '';
        this.model = null;
    }

    setFrequencyType(type) {
        return normalizeFrequencyType(type);
    }

    renderFrequencySummary() {
        this.model =
            this.record?.getField('parentFrequencyModel') ||
            this.record?.getField('parentFrequency');
        if (this.model) {
            return html`<div id="frequencyWrapper">
                <omega-tooltip light direction="top" icon="false" message="${this.model.summary}">
                    <span>${this.setFrequencyType(this.model.type)}</span></omega-tooltip
                >
            </div>`;
        }
        return nothing;
    }

    render() {
        return html`${this.displayDate}${this.renderFrequencySummary()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #frequencyWrapper {
                cursor: pointer;
                line-height: 1.1;
            }
            span {
                font-size: 90%;
                text-decoration: underline;
            }
        `;
    }
}

customElements.define('frequency-detail', FrequencyDetail);
