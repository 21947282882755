import { RecordsetSummaryModel } from '@treasury/FDL/recordset/recordset-summary-model';

/**
 * View modeling representing a selectable summary tile.
 */
export interface SummaryTileVm extends RecordsetSummaryModel {
    isSelected?: boolean;
}

export const nullTileModel: SummaryTileVm = {
    totalAmount: 0,
    count: 0,
    isSelected: false,
    title: '',
};
