import AchCompanyRequests from '@treasury/domain/channel/requests/ach/ach-company-requests';
import { dateRangePicker, dateRangePickerNoDefault, string } from '@treasury/policy/primitives';

export const reportFilters = [
    {
        field: 'effectiveDate',
        fieldType: dateRangePickerNoDefault.with
            .label('Original Effective Date')
            .with.defaultValue(null),
    },
    {
        field: 'receivedDate',
        fieldType: dateRangePicker.with.label('Date Notification Received'),
    },
    {
        field: 'achCompanyIds',
        fieldType: string.with
            .label('ACH Company')
            .with.options({
                fetch: AchCompanyRequests.getAchCompanies,
                text: record =>
                    `${record.companyName} - ${record.companyId} - ${record.secCodes[0]?.code}`,
                value: record => record.companyGuidUniqueId,
            })
            .with.filtering()
            .and.multipleValues()
            .thatHas.placeholder('Select ACH Company'),
        value: [],
    },
    {
        field: 'recipientName',
        fieldType: string.with
            .label('Recipient Name')
            .and.minLength(2)
            .and.maxLength(22)
            .thatHas.inputMask(/[A-Za-z0-9 ]/),
        value: '',
    },
];
