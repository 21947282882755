import { CompanyConfigService } from '@treasury/domain/services/company/configuration.service.ts';

UserInformationContainerController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'modalService',
    'usersService',
    '$modal',
    'securityService',
    'entitlementsService',
    'TmDi',
];

/**
 * @param { ng.IScope } $scope
 * @param { import('@jack-henry/frontend-utils/di').DiContainer} TmDi
 */
export default function UserInformationContainerController(
    $scope,
    $state,
    $stateParams,
    modalService,
    usersService,
    $modal,
    securityService,
    entitlementsService,
    TmDi
) {
    // view scope
    $scope.isACHFilterLinkVisible = false;
    $scope.editMode = $stateParams.view !== 'true';
    $scope.compareMode = false;
    $scope.showPending = false;
    $scope.showFlags = false;
    $scope.hasWireTransfersDomestic = hasWireTransfersDomestic;
    $scope.hasWireTransfersInternational = hasWireTransfersInternational;
    $scope.isDraft = isDraft;
    $scope.isEditable = isEditable;
    $scope.isNew = isNew;
    $scope.isPendingApproval = isPendingApproval;
    $scope.isPendingApprovalAndApprover = isPendingApprovalAndApprover;
    $scope.isPendingApprovalAndNonApprover = isPendingApprovalAndNonApprover;
    $scope.canSubmit = canSubmit;
    $scope.getHeadingText = getHeadingText;
    $scope.getNavHeaderClass = getNavHeaderClass;
    $scope.isSelected = isSelected;
    $scope.navSectionIsCollapsed = navSectionIsCollapsed;
    $scope.userError = '';
    $scope.updateCopyUser = updateCopyUser;

    // scope objects
    $scope.id = $stateParams.id && $stateParams.id !== '' ? $stateParams.id : 0;
    if (!$scope.id) {
        $scope.copyUser = $stateParams.copyUser;
    }
    $scope.sidebar = {};
    $scope.userHeader = {};
    $scope.productFeatureSummary = {};
    $scope.selectedSubview = $stateParams.subview ? $stateParams.subview : 'userInformation';
    $scope.wireTabName = 'Domestic';
    $scope.arpTabName = 'ArpExceptions';

    // events
    $scope.setArpTabName = setArpTabName;
    $scope.setWireTabName = setWireTabName;
    $scope.approveOrReject = approveOrReject;
    $scope.submit = submit;
    $scope.toggleSection = toggleSection;
    $scope.unlock = unlock;
    $scope.notifySave = notifySave;
    $scope.backToUserList = backToUserList;
    $scope.discardChanges = discardChanges;
    $scope.edit = edit;
    $scope.compare = compare;
    $scope.select = select;
    $scope.setId = setId;

    $scope.new = 'New';
    $scope.edited = 'Edited';
    $scope.pending = 'Pending';
    $scope.fiPending = 'FiPending';

    $scope.$watch('userHeader', updateData);
    $scope.$watch('editMode', updateData);
    $scope.$watch('compareMode', updateData);

    function updateData() {
        if ($scope.userHeader) {
            $scope.showFlags = $scope.userHeader.isEnrolled && $scope.compareMode;
            if ($scope.userHeader.isEnrolled) {
                if ($scope.compareMode || $scope.editMode) {
                    $scope.showPending = true;
                } else {
                    $scope.showPending = false;
                }
            } else {
                $scope.showPending = true;
            }
        }
    }

    function isACHFilterEnabled() {
        const service = TmDi.get(CompanyConfigService);
        const hasUserEntitlement = entitlementsService.hasEntitlement(
            'Feature.PositivePay.ACHFilterManagement'
        );
        service
            .hasOption('PositivePay.ACHFilterManagement.AllowManageAchFilters')
            .then(hasCompanyOptionEnabled => {
                $scope.isACHFilterLinkVisible = hasCompanyOptionEnabled && hasUserEntitlement;
            });
    }

    // Child interface
    function notifySave() {
        loadUserHeader();
        loadUserProductFeatureSummary();
    }

    // View interface
    function isSelected(subviewName) {
        return $scope.selectedSubview === subviewName;
    }

    function select(subviewName) {
        $scope.selectedSubview = subviewName;
    }

    function getNavHeaderClass(type) {
        if (navSectionIsCollapsed(type)) {
            return 'fa-chevron-down';
        }
        return 'fa-chevron-up';
    }

    function updateCopyUser(user) {
        $scope.copyUser = user;
    }

    function navSectionIsCollapsed(type) {
        return $scope.sidebar[`${type}Collapsed`];
    }

    function toggleSection(type) {
        $scope.sidebar[`${type}Collapsed`] = !$scope.sidebar[`${type}Collapsed`];
    }

    function getHeadingText() {
        if (!$scope.id) {
            return 'Create New User';
        }
        if ($scope.userHeader && $scope.userHeader.name) {
            return $scope.userHeader.name;
        }
    }

    function canSubmit() {
        return (
            !isPendingApproval() &&
            $scope.userHeader.hasPendingChanges &&
            $scope.userHeader.hasMinimumRequirements
        );
    }

    function edit(value) {
        $scope.compareMode = false;
        $scope.editMode = !value;
        $state.go(
            $state.$current.name,
            { id: $scope.id, view: !value },
            { location: 'replace', inherit: false, notify: false }
        );
    }

    function compare() {
        $scope.compareMode = !$scope.compareMode;
    }

    function submit() {
        let modalInstance;
        let modalOptions;
        modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Submit User',
            headerText: 'Confirm Submission',
            bodyText: 'Are you sure you want to submit this user?',
            submit(result) {
                modalInstance.close(result);
            },
        };
        modalInstance = modalService.showModal({}, modalOptions);
        modalInstance.result.then(result => {
            $scope.userError = '';
            usersService.submitUser($scope.id).then(response => {
                if (response) {
                    $state.go(
                        $state.$current.name,
                        { id: $scope.id, view: true, subview: $scope.selectedSubview },
                        { reload: true }
                    );
                } else {
                    $scope.userError = 'An error occurred during submit.';
                }
            });
        });
    }

    function discardChanges() {
        const modalInstance = $modal.open({
            template: require('../views/discardChangesModalTemplate.html'),
            size: 'lg',
            controller: 'DiscardChangesController',
            backdrop: 'static',
        });
        modalInstance.result.then(comments => {
            $scope.userError = '';
            usersService.discardChanges($scope.id, comments).then(response => {
                if (response) {
                    if ($scope.userHeader.isEnrolled) {
                        $state.go(
                            $state.$current.name,
                            { id: $scope.id, view: true, subview: $scope.selectedSubview },
                            { reload: true }
                        );
                    } else {
                        $state.go('admin.user-list');
                    }
                } else {
                    $scope.userError = 'An error occurred during discard.';
                }
            });
        });
    }

    function approveOrReject(type) {
        const modalInstance = $modal.open({
            template: require('../views/approveReject.html'),
            size: 'lg',
            controller: 'ApproveRejectController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            $scope.userError = '';
            if (type === 'approve') {
                securityService.verifyUser('Approve User').then(() => {
                    usersService.approve($scope.id, comments).then(response => {
                        if (response) {
                            $state.go('admin.user-list');
                        } else {
                            $scope.userError = 'An error occurred during approval.';
                        }
                    });
                });
            } else if (type === 'reject') {
                securityService.verifyUser('Approve User').then(() => {
                    usersService.reject($scope.id, comments).then(response => {
                        if (response) {
                            $state.go('admin.user-list');
                        } else {
                            $scope.userError = 'An error occurred during reject.';
                        }
                    });
                });
            }
        });
    }

    function unlock() {
        let modalInstance;
        let modalOptions;
        modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Unlock User',
            headerText: 'Confirm Unlock',
            bodyText: 'Are you sure you want to unlock this user?',
            submit(result) {
                modalInstance.close(result);
            },
        };
        modalInstance = modalService.showModal({}, modalOptions);
        modalInstance.result.then(result => {
            usersService.unlock($scope.id).then(response => {
                $state.go(
                    $state.$current.name,
                    { id: $scope.id, view: true, subview: $scope.selectedSubview },
                    { reload: true }
                );
            });
        });
    }

    function backToUserList() {
        $state.go('admin.user-list');
    }

    function setWireTabName(tabName) {
        $scope.wireTabName = tabName;
    }

    function setArpTabName(tabName) {
        $scope.arpTabName = tabName;
    }

    function setId(id) {
        $scope.id = id;
    }

    //
    // State helpers
    function isEditable() {
        return !isPendingApproval();
    }

    function isNew() {
        return $scope.userHeader.pendingState === $scope.new;
    }

    function isDraft() {
        return isNew() || $scope.userHeader.pendingState === $scope.edited;
    }

    function isPendingApproval() {
        return isPendingApprovalBackOffice() || isPendingApprovalChannel();
    }

    function isPendingApprovalChannel() {
        return $scope.userHeader.pendingState === $scope.pending;
    }

    function isPendingApprovalBackOffice() {
        return $scope.userHeader.pendingState === $scope.fiPending;
    }

    function isPendingApprovalAndNonApprover() {
        return (
            (isPendingApprovalChannel() && $scope.userHeader.isEditor) ||
            isPendingApprovalBackOffice()
        );
    }

    function isPendingApprovalAndApprover() {
        return isPendingApprovalChannel() && !$scope.userHeader.isEditor;
    }

    function hasWireTransfersDomestic() {
        return (
            $scope.productFeatureSummary && $scope.productFeatureSummary.hasWireTransfersDomestic
        );
    }

    function hasWireTransfersInternational() {
        return (
            $scope.productFeatureSummary &&
            $scope.productFeatureSummary.hasWireTransfersInternational
        );
    }

    // Private
    function loadUserHeader() {
        if ($scope.id) {
            usersService.getUserHeader($scope.id).then(response => {
                $scope.userHeader = response;
            });
        }
    }

    function loadUserProductFeatureSummary() {
        if ($scope.id && $scope.id > 0) {
            usersService.getUserProductFeatureSummary($scope.id).then(response => {
                $scope.productFeatureSummary = response;
            });
        }
    }

    (function () {
        // init
        $scope.sidebar = {
            achCollapsed: false,
            arpCollapsed: false,
            informationReportingCollapsed: false,
            internalTransfersCollapsed: false,
            wireTransfersCollapsed: false,
            wireTransfersDliCollapsed: false,
            stopPaymentCollapsed: false,
        };

        loadUserHeader();
        loadUserProductFeatureSummary();
        isACHFilterEnabled();
    })();
}
