/* eslint-disable lit/no-native-attributes */
/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable import/no-duplicates */
/* eslint-disable lines-between-class-members */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { FdlRecordEvent, Record, Recordset, RecordsetEvent } from '@treasury/FDL';
import { AchEditablePaymentModelDto } from '@treasury/api/channel';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { LegacyNavigationService, NavigationService } from '@treasury/core/navigation';
import { BatchData, getBatchDataFromPayments } from '@treasury/domain/channel/services/ach';
import {
    AchBankDto,
    AchConfiguration,
    Config,
    NachaFile,
    PaymentHeader,
    PaymentRecipient,
    getDefaultValueDate,
    isIncomingDateValid,
} from '@treasury/domain/channel/types/ach';
import {
    Workflow,
    WorkflowActions,
    WorkflowTypes,
} from '@treasury/domain/channel/types/ach/constants';
import { DefaultFrequency } from '@treasury/domain/channel/types/frequency';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-workflow.js';
import '@treasury/omega/layouts/omega-form';
import { DownloadBarOptions, Schema, Step } from '@treasury/omega/layouts/omega-form';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { OmegaReportItemLabel } from '@treasury/omega/types';
import { deepEquals, exists } from '@treasury/utils';
import { LitElement, css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../components/ach-creation-toggle';
import '../../components/ach-nacha-upload';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import { ContainerConfiguration } from '../data/container-configuration';
import fillMeInCreatePayment from '../data/fill-me-in-create-payment';
import { lookupBank, setAchCompany, setNachaRecipientsData } from '../data/nacha-upload.formatter';
import { getPaymentHeaderRecord } from '../data/payment-header-record';
import recipientRecordset, { recipientDirty } from '../data/recipient-recordset';
import { getSchema } from '../data/workflow';
import { AddendaDialog, RecipientFileUploadDialog } from '../dialogs';
import '../dialogs/addenda-dialog';
import '../dialogs/recipient-file-upload-dialog';
import '../dialogs/save-as-template-dialog';
import '../dialogs/select-from-recipient-list-dialog';
import { setAchBanks } from '../util/setAchBanks';
import './ach-domestic-batch';

interface RouteParams {
    payment: any;
    id: string;
    batchList: Array<{ key: string; value: string }>;
    paymentRecipients: any[];
    paymentState: {
        id: string;
        type: string;
        paymentCreationType: string;
    };
}

const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));
export class AchDomesticContainer extends AlertingListeningElement {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly analyticsService: AnalyticsService;

    @state()
    workflow!: Workflow;
    @state()
    paymentId?: number;
    @state()
    steps!: Array<Step<PaymentHeader>>;
    @state()
    containerTitle!: string;
    @state()
    loading = false;
    @state()
    paymentHeaderRecord!: Record<PaymentHeader>;
    @state()
    recipientsRecordset!: Recordset<PaymentRecipient>;
    @state()
    recipientLabel: OmegaReportItemLabel = { singular: 'recipient' };
    @state()
    schema!: Schema<PaymentHeader, PaymentRecipient>;
    @state()
    selectedRecord!: Record<PaymentRecipient>;
    @state()
    achConfiguration!: AchConfiguration;
    @state()
    client!: AchDomesticClient;
    @state()
    step = 0;
    @state()
    recipientCount = 0;
    @state()
    showWorkflowStepIndicator = true;
    @state()
    containerConfiguration!: ContainerConfiguration;
    @state()
    hasEditablePayment = false;
    @state()
    isDownloading = false;
    @state()
    public overrideContentConfig = { formValid: false, useFormBody: false };
    @state()
    fetchedRecipientsRecordset!: any;
    @state()
    multiplePayments = false;
    @state()
    batchData!: BatchData;
    @state()
    private config!: Config;
    @state()
    showSelectFromRecipientListDialog = false;
    @state()
    paymentsPendingProcessFeatureFlag = false;
    @state()
    showSaveAsATemplateDialog = false;

    @state()
    private params: Partial<RouteParams> = {};

    @property({ attribute: false })
    public nachaFile: NachaFile = null;

    @property({ type: Object })
    public alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: '',
        type: 'success',
        posture: 'polite',
        actions: '',
    };

    @property()
    incomingRecipients!: null | Array<PaymentRecipient>;

    get isCreatePayment(): boolean {
        const createAction =
            this.workflow?.action === WorkflowActions.Create ||
            this.workflow?.action === WorkflowActions.InitiateFromFile;

        return createAction && this.workflow?.type === WorkflowTypes.Payment;
    }

    get useNachaUpload(): boolean {
        return this.workflow?.action === WorkflowActions.InitiateFromFile && this.step === 0;
    }

    get isNachaFileUpload(): boolean {
        return this.workflow?.action === WorkflowActions.InitiateFromFile;
    }

    async firstUpdated() {
        await this.init();
        this.addListeners();

        const { params } = await this.navService.getRouteData<RouteParams>();
        this.params = params;
    }

    addListeners() {
        [RecordsetEvent.Updated, RecordsetEvent.RecordDeleted, RecordsetEvent.RecordCloned].forEach(
            event =>
                this.listenTo(
                    this.recipientsRecordset,
                    event,
                    ({ detail }: { detail: { field: string; record: Record<PaymentRecipient> } }) =>
                        this.updateDebitCredit(detail)
                )
        );

        this.listenTo(this.recipientsRecordset, RecordsetEvent.CountsChanged, () => {
            this.recipientCount = this.recipientsRecordset.totalCount;
        });

        this.listenTo(this.paymentHeaderRecord, FdlRecordEvent.Change, (detail: CustomEvent) => {
            if (detail.detail.field === 'achCompany') {
                this.recipientsRecordset.allRecords.forEach(r => {
                    r.setField(
                        'testAchCompanyName',
                        this.paymentHeaderRecord.getField('achCompany').companyName
                    );
                    this.updateRecipientTransactionType(r);
                });
            }
            this.updateDefaultRecipientTransactionType(detail);
            if (this.step === 2) {
                this.requestUpdate();
            }
        });
    }

    updateDefaultRecipientTransactionType(detail: CustomEvent) {
        const defaultRecipient = this.recipientsRecordset.allRecords[0];
        if (detail.detail.field === 'achCompany' && !recipientDirty(defaultRecipient)) {
            const achCompany = this.paymentHeaderRecord.getField('achCompany');
            if (achCompany && achCompany.notOnUsTransactionTypes === 'Debit Only') {
                defaultRecipient.setField('transactionType', 'DR');
            } else {
                defaultRecipient.setField('transactionType', 'CR');
            }
        }
    }

    updateRecipientTransactionType(recipient: Record<PaymentRecipient>) {
        const achCompany = this.paymentHeaderRecord.getField('achCompany');
        if (achCompany && achCompany.notOnUsTransactionTypes === 'Debit Only') {
            recipient.setField('transactionType', 'DR');
        }
        if (achCompany && achCompany.notOnUsTransactionTypes === 'Credit Only') {
            recipient.setField('transactionType', 'CR');
        }
    }

    updateDebitCredit(detail: { field: string; record: Record<PaymentRecipient> | undefined }) {
        if (!detail) return;
        let debits = 0;
        let credits = 0;

        if (detail.field === 'prenote' && detail.record !== undefined) {
            detail.record.setField('amount', 0);
        }

        const recipients = this.recipientsRecordset.getData();

        if (recipients) {
            recipients.forEach(v => {
                if (v.transactionType === 'CR' && !v.hold) {
                    credits += v.amount || 0;
                }
                if (v.transactionType === 'DR' && !v.hold) {
                    debits += v.amount || 0;
                }
            });
        }

        this.paymentHeaderRecord.setFields({
            debitAmount: debits,
            creditAmount: credits,
            step: this.step,
        });
    }
    async fillMeIn() {
        await fillMeInCreatePayment(
            this,
            this.paymentHeaderRecord,
            this.recipientsRecordset,
            this.step
        );
    }

    async getAchPaymentAndRecipients() {
        let payment;
        let recipients;

        switch (this.workflow?.action) {
            case WorkflowActions.Edit:
            case WorkflowActions.View: {
                if (!this.paymentId) this.setPaymentId();
                const result = await this.client.getPayment(this.paymentId as number);
                payment = result.payment;
                recipients = payment.recipients;
                break;
            }

            case WorkflowActions.InitiateFromTemplate: {
                try {
                    const batchList = this.params.batchList?.length
                        ? this.params.batchList
                        : [{ key: this.params.id || '', value: '' }];
                    // To Do: initiate multiple templates in lit element
                    payment =
                        this.params.payment ?? (await this.client.initiateFromTemplate(batchList));
                    recipients = payment.recipients ?? [{}];
                    await setAchBanks(recipients, this.client);
                } catch (error) {
                    console.error(error);
                }

                break;
            }

            default:
                payment = {};
                recipients = [{}];
                break;
        }
        if (this.workflow?.action === WorkflowActions.Edit) {
            if (payment.frequency.type === 'One Time') {
                payment.frequency.valueDate = null;
            }
            payment.frequency.startOn = null;
        }
        return { payment: new PaymentHeader(payment), recipients };
    }

    async setRecordsAndRecordsets(payment: PaymentHeader, recipients: Array<PaymentRecipient>) {
        this.config = {
            ...this.achConfiguration,
            ...this.workflow,
            entitlements: this.containerConfiguration.entitlements,
            onUsAchBanks: (await this.client.onUsAchBanks()) as Array<AchBankDto>,
        };

        this.paymentHeaderRecord = await getPaymentHeaderRecord(
            this.config,
            payment,
            this.client,
            null
        ).source;

        // Set the field in the header record so omega-form knows what step we are on
        this.paymentHeaderRecord.setField('step', this.step);
        this.paymentHeaderRecord.setField('nachaFileUpload', this.isNachaFileUpload);
        this.recipientsRecordset = await recipientRecordset(
            recipients,
            this.isNachaFileUpload,
            this.workflow.type,
            this.config,
            this.paymentHeaderRecord,
            this.client
        );
        this.schema = getSchema(
            this,
            this.paymentHeaderRecord,
            this.recipientsRecordset,
            this.config
        );
    }

    setPaymentId() {
        const id = window.location.pathname.split('/')[6];
        const { action } = this.workflow;
        const paymentIdRequired =
            action === WorkflowActions.Edit || action === WorkflowActions.View;
        const paymentId = paymentIdRequired
            ? parseInt(id, 10) ?? parseInt(this.params.paymentState?.id || '', 10)
            : undefined;
        if (paymentId && Number.isNaN(paymentId)) {
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                actions: '',
                title: 'An Error Occurred',
                message: 'Unable to read payment ID.',
                posture: 'assertive',
            };
        }
        this.paymentId = paymentId;
    }

    setWorkflow() {
        let [workflowType, paymentCreationType] = window.location.pathname.split('/').slice(-3);
        paymentCreationType = paymentCreationType ?? this.params.paymentState?.paymentCreationType;

        workflowType = workflowType ?? this.params.paymentState?.type;

        this.workflow = {
            type: workflowType as WorkflowTypes,
            action: paymentCreationType as WorkflowActions,
        };
    }

    /**
     * This method checks the workflow and then if it is in edit mode checks to see
     * if the payment is editable based on permissions and the status of the payment
     */
    private testForEditable(payment: PaymentHeader, config: ContainerConfiguration) {
        const editablePayment = payment.isEditable;
        const editPermissions = config.entitlements.edit.full || config.entitlements.edit.partial;
        const isPayment = this.workflow.type === WorkflowTypes.Payment;
        const isEdit = this.workflow.action === WorkflowActions.Edit;

        if (isPayment && isEdit) {
            return editablePayment && editPermissions;
        }

        return true;
    }

    async init() {
        this.loading = true;
        this.setWorkflow();
        this.setPaymentId();
        this.client = new AchDomesticClient();
        this.achConfiguration = await this.client.achConfiguration();

        // eslint-disable-next-line prefer-const
        let { payment, recipients } = await this.getAchPaymentAndRecipients();

        this.initContainerData(payment);

        if (this.incomingRecipients) {
            recipients = this.incomingRecipients;
        }

        await this.setRecordsAndRecordsets(payment, recipients);

        const featureService = await FeatureFlagService.getInstance();
        this.paymentsPendingProcessFeatureFlag = await featureService.isEnabled(
            Feature.PaymentsPendingProcess
        );

        this.loading = false;
    }

    initContainerData(payment: PaymentHeader) {
        this.containerConfiguration = new ContainerConfiguration(this, payment);
        this.hasEditablePayment = this.testForEditable(payment, this.containerConfiguration);
        this.step = this.containerConfiguration.workflow.startStep ?? 0;
        this.steps = this.containerConfiguration.workflow.steps;
        this.containerTitle = this.containerConfiguration.workflow.title ?? '';
        this.overrideContentConfig = {
            formValid: !!this.nachaFile,
            useFormBody: this.useNachaUpload,
        };
        this.incomingRecipients = this.params.paymentRecipients || [];
    }

    next(record: Record<PaymentHeader>) {
        if (!this.multiplePayments) {
            this.step = record.getField('step') + 1;
            record.setField('step', this.step);
            this.recipientsRecordset.allRecords.forEach(r => r.setField('step', this.step));
            this.overrideContentConfig.useFormBody = this.useNachaUpload;
            const sectionView = document.querySelector('#sectionView');
            if (sectionView) sectionView.scrollTop = 0;
        }
    }

    back(record: Record<PaymentHeader>) {
        if (this.isNachaFileUpload && this.step === 1) {
            this.first();
        } else {
            this.step = record.getField('step') - 1;
            record.setField('step', this.step);
        }
    }
    first() {
        (this.navService as LegacyNavigationService).reload();
    }

    setNachaData(nachaData: NachaFile) {
        this.nachaFile = nachaData;
        this.overrideContentConfig.formValid = !!nachaData;
    }

    setFrequency(incomingFrequency: any) {
        const incomingEffectiveDateFromAPI = incomingFrequency?.effectiveDate;
        const incomingEffectiveDate = new Date(incomingEffectiveDateFromAPI);

        const { sameDayAchSettings, allowSameDayPayments, cutoffTimes, holidays } =
            this.achConfiguration;

        const defaultValueDate = getDefaultValueDate(
            allowSameDayPayments,
            cutoffTimes,
            holidays,
            sameDayAchSettings
        );

        const isIncomingEffectiveDateValid = isIncomingDateValid(
            incomingEffectiveDate,
            allowSameDayPayments,
            holidays,
            sameDayAchSettings
        );

        this.paymentHeaderRecord.setField('frequency', {
            ...DefaultFrequency,
            valueDate: isIncomingEffectiveDateValid
                ? incomingEffectiveDateFromAPI
                : defaultValueDate,
        });
    }

    async uploadNachaFile() {
        if (this.overrideContentConfig.formValid && this.nachaFile) {
            this.loading = true;
            try {
                const uploadResult = await this.client.uploadNachaFile(this.nachaFile);
                const {
                    payments,
                    multiplePayments,
                    summary: { frequency: frequencyFromAPI },
                } = uploadResult;

                const { payment, recipients } = payments[0];

                this.multiplePayments = multiplePayments;
                if (multiplePayments && payments?.length) {
                    this.workflow.action = WorkflowActions.InitiateBatchFromFile;
                    this.initContainerData(this.paymentHeaderRecord.values);
                    this.batchData = getBatchDataFromPayments(uploadResult, this.config);
                }

                this.alert = { ...this.alert, visible: false };

                if (payment) {
                    this.paymentHeaderRecord.setFields(payment);
                    await setAchCompany(this.paymentHeaderRecord);
                }
                if (recipients) {
                    await setNachaRecipientsData(this.recipientsRecordset, recipients);
                }
                this.updateDebitCredit({ field: '', record: undefined });
                this.setFrequency(frequencyFromAPI);
                if (!this.multiplePayments)
                    this.containerConfiguration.payment.workflow.steps[1].label = 'Payment Details';
                this.next(this.paymentHeaderRecord);
            } catch (e) {
                console.error(e);
                this.overrideContentConfig.formValid = false;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    message: e instanceof Error ? e.message : 'Invalid NACHA file',
                    type: 'error',
                    posture: 'polite',
                    title: 'Found error on upload:',
                };
            } finally {
                this.loading = false;
            }
        } else {
            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Invalid NACHA File',
                type: 'error',
                posture: 'polite',
                title: 'Found error on upload:',
            };
        }
    }

    openAddendaDialog(record: Record<PaymentRecipient>) {
        this.selectedRecord = record;
        AddendaDialog.open(this);
    }

    openImportRecipientsDialog() {
        RecipientFileUploadDialog.open(this);
    }

    dialogContinueEditing() {
        this.alert = { ...this.alert, visible: false };
    }

    cancel() {
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'warning',
            message: `Are you sure you want to cancel? Select 'Cancel' to return to ACH Payment Activity or Continue Editing.`,
            posture: 'assertive',
            actions: html`<div slot="actions">
                <omega-button type="reject" @click=${() => this.routeToActivity()}
                    >Cancel</omega-button
                >
                <omega-button @click=${() => this.dialogContinueEditing()}
                    >Continue Editing</omega-button
                >
            </div>` as any,
        };
    }

    promptSaveAsTemplate() {
        this.showSaveAsATemplateDialog = true;
    }

    async routeToBlankCreateStepOne(action?: WorkflowActions) {
        this.step = 0;
        this.paymentHeaderRecord.setField('step', 0);
        this.paymentHeaderRecord.clear();
        this.recipientsRecordset.reset();
        this.recipientsRecordset.setData([]);
        if (action === WorkflowActions.InitiateFromFile) {
            this.workflow.action = action;
            this.multiplePayments = false;
            await this.initContainerData(this.paymentHeaderRecord.values);
        }
        this.handleCreationToggle({ detail: action ?? WorkflowActions.Create });
    }

    routeToEditPayment() {
        this.navService.navigate('payables.ach.payments.edit-dark', { id: this.paymentId });
    }

    handleCreationToggle(e: { detail: WorkflowActions }) {
        this.workflow.action = e.detail;
        this.navService.navigate(`payables.ach.payments.${e.detail}-dark`);
    }

    async submit() {
        this.loading = true;
        try {
            if (!exists(this.containerConfiguration)) {
                throw new Error('Container configuration is not defined.');
            }
            const { post, put } = this.containerConfiguration.configuration;

            if (!exists(post)) {
                throw new Error('Post is not defined in the configuration');
            }
            if (!exists(put)) {
                throw new Error('Put is not defined in the configuration');
            }

            // If the payment has an id update the payment else create a new one
            const action = this.paymentHeaderRecord.values.id ? put : post;
            const result = await this.client[action](
                this.paymentHeaderRecord.values,
                this.recipientsRecordset.getData()
            );
            const { payment } = result as AchEditablePaymentModelDto;
            const {
                id,
                audit,
                status,
                transactionId,
                creditAmount,
                debitAmount,
                achCompanyId: companyId,
            } = payment;

            this.paymentHeaderRecord.setField('status', status);
            this.paymentHeaderRecord.setField('id', id);
            this.paymentHeaderRecord.setField('audit', audit || '');
            this.paymentHeaderRecord.setField('transactionId', transactionId);

            const alertMessage = {
                Initiated: html`ACH Payment is processing. Please review the
                    <span class="link" @click=${() => this.routeToActivity()}>
                        ACH Payment Activity
                    </span>`,
                Scheduled: html`ACH Payment is scheduled. Please review the
                    <span class="link" @click=${() => this.routeToActivity()}>
                        ACH Payment Activity
                    </span>`,
                'Pending Approval': html`<strong>Pending Approval!</strong> ACH Payment is in
                    pending approval status.`,
                'Pending Process': html`ACH Payment is processing. Please review the
                    <span class="link" @click=${() => this.routeToActivity()}>
                        ACH Payment Activity
                    </span>`,
            } as any;

            this.analyticsService.track(AnalyticsEvent.AchPayment, {
                companyId,
                transactionId,
                status,
                creditAmount,
                debitAmount,
            });

            if (
                'challengeMethodTypeId' in result.securityMessage &&
                result.securityMessage.status === 'Failure'
            ) {
                this.dialogContinueEditing();
            } else {
                const alertType =
                    this.paymentsPendingProcessFeatureFlag && status === 'Pending Process'
                        ? 'info'
                        : 'success';

                this.alert = {
                    ...this.alert,
                    visible: true,
                    title: '',
                    type: alertType,
                    posture: 'polite',
                    message: alertMessage[status],
                    actions: '',
                    code: '',
                    time: '',
                };

                this.next(this.paymentHeaderRecord);
            }
        } catch (error) {
            const message =
                error instanceof Error
                    ? error.message
                    : 'An unknown error occurred submitting the ACH payment.';
            const code = error instanceof TmApiError ? error.errorCode : undefined;
            const time = error instanceof TmApiError ? error.timestamp : undefined;
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                title: 'Error',
                posture: 'polite',
                message,
                code,
                time,
            };
        }

        this.loading = false;
    }

    routeToActivity() {
        return this.navService.navigate('payables.ach.payment-list');
    }

    recipientEqualityCheck(existing: any, uploaded: any) {
        return uploaded.some((recipient: any) => deepEquals(existing, recipient));
    }

    async uploadFileRecipients(event: CustomEvent) {
        try {
            const [file] = event.detail.files;
            const { format } = event.detail;
            this.loading = true;
            const uploadedRecipients = await this.client.uploadRecipients(file, format);
            const existingRecipients = this.recipientsRecordset.getData();
            const existingDirty = existingRecipients.filter(
                (existingRecipientData, i) =>
                    recipientDirty(this.recipientsRecordset.getRecordAtIndex(i)) &&
                    !this.recipientEqualityCheck(existingRecipientData, uploadedRecipients)
            );

            this.recipientsRecordset.setData([
                ...existingDirty,
                ...uploadedRecipients.map((recipient: PaymentRecipient) => {
                    const isEditWorkflow = this.workflow?.action === WorkflowActions.Edit;

                    const getAccountType = () => {
                        if (!isEditWorkflow && recipient.accountType === 'GL')
                            return 'General Ledger';
                        if (isEditWorkflow && recipient.accountType === 'Loan') return 'Loans';
                        return recipient.accountType;
                    };

                    return {
                        ...recipient,
                        accountType: getAccountType(),
                    };
                }),
            ]);
            this.updateDebitCredit({ field: '', record: undefined });
            this.loading = false;
        } catch (error) {
            this.loading = false;

            if (error instanceof TmApiError) {
                const { errorCode: code, timestamp: time } = error;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    actions: '',
                    title: 'Error',
                    posture: 'assertive',
                    message: error.message,
                    code,
                    time,
                };
            } else {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    title: 'Error',
                    posture: 'assertive',
                    message: 'Upload failed. Please try again.',
                };
            }
        }
    }

    async download({ detail }: { detail: { downloadType: string } }) {
        this.isDownloading = true;
        try {
            // if (this.template) {
            //     await this.client.downloadTemplate(this.paymentId, detail.downloadType);
            // } else {
            await this.client.download(
                this.paymentHeaderRecord.getField('id'),
                detail.downloadType,
                'ConfirmationACHPayment'
            );
            // }
        } catch (err) {
            const message = err instanceof TmApiError ? err.message : 'Download failed';
            this.alert = { ...this.alert, visible: true, type: 'error', message };
        } finally {
            this.isDownloading = false;
        }
    }

    get displayCreationToggle() {
        if (this.step > 0) return false;
        return !(
            this.workflow.action === WorkflowActions.Edit ||
            this.workflow.action === WorkflowActions.View
        );
    }

    appendRecord() {
        this.recipientsRecordset.insertDefaultRecord();
    }

    async addToExistingRecipients(selected: Array<PaymentRecipient>) {
        const record = this.recipientsRecordset.allRecords[0];
        const banks = await record.fieldTypeForField('bank').options(record);
        const existing = this.recipientsRecordset.getData();
        const existingRecords = this.recipientsRecordset.allRecords;

        if (!WorkflowActions.Edit) {
            for (let i = 0; i < existingRecords.length; i++) {
                if (deepEquals(existingRecords[i]?.values, existingRecords[i]?.initialValues)) {
                    const index = existing.indexOf(existingRecords[i].values);
                    existing.splice(index, 1);
                }
            }
        }

        const existingAndSelected = [
            ...existing,
            ...selected.map((paymentRecipient): PaymentRecipient => {
                const recipientBankId = paymentRecipient?.bank?.bankId;

                if (!recipientBankId) {
                    console.error('Payment recipient without bank was selected');
                    return paymentRecipient;
                }

                const isEditWorkflow = this.workflow?.action === WorkflowActions.Edit;

                const getAccountType = () => {
                    let { accountType } = paymentRecipient;
                    if (isEditWorkflow) {
                        switch (accountType) {
                            case 'General Ledger':
                                accountType = 'GL';
                                break;
                            case 'Loan':
                                accountType = 'Loans';
                                break;
                            default:
                                break;
                        }
                    }
                    return accountType;
                };

                return {
                    ...paymentRecipient,
                    bank: lookupBank(banks, recipientBankId.toString()),
                    testAchCompanyName: this.paymentHeaderRecord.getField('achCompany').companyName,
                    editable: true,
                    fromMasterList: true,
                    accountType: getAccountType(),
                };
            }),
        ];

        this.recipientsRecordset.setData(existingAndSelected);
        // This hack appears to fix our issue, but
        // we need to address the underlying cause, where setData doesn't
        // seem to engage the same changes as a change on the records within
        // the recordset.
        this.recipientsRecordset.allRecords[0].announceChange('accountType');

        this.recipientsRecordset.allRecords.forEach(r => {
            const { name, idNumber, accountNumber, bank } = r.values;

            if (!name && !idNumber && !accountNumber && !bank) {
                this.recipientsRecordset.deleteRecord(r);
            }
        });
    }

    setAlert(alert: Partial<OmegaAlertConfig>) {
        this.alert = {
            ...this.alert,
            ...alert,
        };
    }

    handleSaveAsTemplateClose() {
        this.showSaveAsATemplateDialog = false;
    }
    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<blocking-loader></blocking-loader>`;
    }

    renderFormHeader() {
        const formHeading =
            this.containerConfiguration?.workflow.steps.find(s => s.step === this.step)
                ?.formHeading ?? nothing;

        const recipientCount =
            this.step > 0 && this.recipientsRecordset.totalCount > 0
                ? html`<span class="recipient-count">${this.recipientCount} Recipient(s)</span>`
                : nothing;
        return html`
            <div
                class=${classMap({
                    'omega-form-header': true,
                    'header-margin-top': this.showWorkflowStepIndicator,
                })}
            >
                <h2 class="step-title">${formHeading} ${recipientCount}</h2>
            </div>
        `;
    }

    renderToggle() {
        if (!this.displayCreationToggle) return nothing;
        return html`<ach-creation-toggle
            @change=${this.handleCreationToggle}
            .value=${this.workflow.action}
        ></ach-creation-toggle>`;
    }

    renderNachaUpload() {
        const setNachaData = this.setNachaData.bind(this);
        return this.useNachaUpload
            ? html`<ach-nacha-upload
                  .alert=${this.alert}
                  .setNachaData=${setNachaData}
              ></ach-nacha-upload>`
            : nothing;
    }

    renderForm() {
        if (!this.steps || !this.schema) return nothing;
        if (this.multiplePayments) {
            return this.renderBatchForm();
        }
        const showDownload =
            (!this.paymentHeaderRecord.getField('nachaFileUpload') && this.step > 1) ||
            (this.paymentHeaderRecord.getField('nachaFileUpload') && this.step > 2);
        const downloadBarOptions: DownloadBarOptions = {
            actions: showDownload ? ['download'] : [],
            downloadOptions: ['CSV', 'NACHA', 'PDF'],
            hideActionLabels: false,
        };
        this.showWorkflowStepIndicator = true;
        const requiredFieldCaption =
            !this.useNachaUpload && this.step < 2
                ? html`<span><span class="red">*</span> <em>Indicates Required Field</em></span>`
                : nothing;

        return html`<omega-form
            .record=${this.paymentHeaderRecord}
            .activeStep=${this.step}
            .showWorkflowStepIndicator=${this.showWorkflowStepIndicator}
            .schema=${this.schema}
            .steps=${this.steps}
            .itemLabel=${this.recipientLabel}
            .downloadBarOptions=${downloadBarOptions}
            .overrideConfig=${this.overrideContentConfig}
            @download=${this.download}
        >
            <div slot="form-title">${this.renderToggle()} ${this.renderFormHeader()}</div>
            <div slot="form-title-toolbar">${requiredFieldCaption}</div>
            <div slot="form-body">${this.renderNachaUpload()}</div>
        </omega-form>`;
    }

    renderBatchForm() {
        const setAlert = this.setAlert.bind(this);
        const handleLoading = (event: { detail: { loading: boolean } }) => {
            this.loading = event.detail.loading;
        };
        const batchConfig = {
            ...this.config,
            offsetAccount: {
                requiredSteps: 2,
                readOnlySteps: 3,
                visibleSteps: [2, 3],
            },
        };

        return html`<ach-domestic-batch
            .paymentBatch=${this.batchData}
            .download=${this.download}
            .config=${batchConfig}
            .containerConfiguration=${this.containerConfiguration}
            .client=${this.client}
            .alert=${this.alert}
            .setAlert=${setAlert}
            .loading=${this.loading}
            @loading=${handleLoading}
        ></ach-domestic-batch>`;
    }

    renderTooltip() {
        if (this.step > 0) return nothing;
        if (this.containerTitle && this.isCreatePayment) {
            const tooltip = this.useNachaUpload
                ? html`<p style="width: 350px;">
                          From this page, you can select a file from your device to upload into
                          Treasury Management. Click the Select File option to locate the file and
                          submit for upload.
                      </p>
                      <p style="width: 350px;">
                          Your file must be formatted to NACHA specifications and all ABA numbers
                          must be accurate. The company header record must patch a company that you
                          are entitled to create ACH batches for.
                      </p>
                      <p style="width: 350px;">
                          After the batch has been uploaded successfully, it can be used for
                          immediate payments and can be saved for future use.
                      </p>`
                : html`<span
                      >Create an ACH Payment manually, when all payment details have been entered
                      select the <br />
                      effective date to initiate the payment.</span
                  >`;

            return html`<omega-tooltip light icon="info-circle" direction="bottom-right">
                <span slot="content"> ${tooltip} </span>
            </omega-tooltip>`;
        }

        return nothing;
    }

    renderSelectFromRecipientListDialog() {
        if (this.loading) return nothing;
        const addToExistingRecipients = this.addToExistingRecipients.bind(this);
        const onUsFeature =
            this.containerConfiguration.entitlements.type === WorkflowTypes.Payment
                ? this.containerConfiguration.entitlements.onUsAccessManagementFeature
                : false;
        return html`
            <select-from-recipient-list-dialog
                .addToExistingRecipients=${addToExistingRecipients}
                .client=${this.client}
                .open=${this.showSelectFromRecipientListDialog}
                .paymentHeaderRecord=${this.paymentHeaderRecord}
                .onUsAccessManagementFeature=${onUsFeature}
                .allowOnUsAccessManagement=${this.config?.allowOnUsAccessManagement}
                @close=${() => {
                    this.showSelectFromRecipientListDialog = false;
                }}
            ></select-from-recipient-list-dialog>
        `;
    }

    renderSaveAsATemplateDialog() {
        if (this.loading) return nothing;
        return html`<save-as-template-dialog
            .show=${this.showSaveAsATemplateDialog}
            .client=${this.client}
            .setAlert=${this.setAlert}
            .paymentHeaderRecord=${this.paymentHeaderRecord}
            .recipientsRecordset=${this.recipientsRecordset}
            @close=${this.handleSaveAsTemplateClose}
        ></save-as-template-dialog>`;
    }

    render() {
        if (!this.hasEditablePayment && !this.loading)
            return html`<div>
                <h1>This payment cannot be edited in its current state.</h1>
            </div>`;
        return html`${this.renderAlert()}${this.renderBlockingLoader()}
            <h1 class="title">${this.containerTitle} ${this.renderTooltip()}</h1>
            <div class="workflow-container">${this.renderForm()}</div>
            <addenda-dialog
                .record=${this.selectedRecord}
                .readonly=${this.selectedRecord?.fieldTypes?.addenda?.readonly()}
            ></addenda-dialog>
            <recipient-file-upload-dialog
                @upload=${this.uploadFileRecipients}
            ></recipient-file-upload-dialog>
            ${this.renderSelectFromRecipientListDialog()} ${this.renderSaveAsATemplateDialog()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --bottom-offset: -15px;
            }
            .workflow-container {
                position: relative;
                min-height: 60%;
                background-color: #fff;
                box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
            }
            .recipient-count {
                font-size: 13px;
                font-weight: 400;
                display: inline-block;
                margin-left: 10px;
            }
            omega-workflow {
                height: 100%;
            }
            .workflow-header {
                font-size: 24px;
                margin-top: 0;
                font-weight: 400;
            }
            div[slot='step'] {
                height: 100%;
            }
            div[slot='content'] {
                height: 100%;
            }
            .link {
                color: var(--omega-primary);
                text-decoration: underline;
                cursor: pointer;
            }
            .light-loader {
                color: #fff;
                width: 50px;
                height: 50px;
                border-width: 5px;
                margin-top: 35%;
            }
            .header-margin-top {
                margin-top: 20px;
            }
            .omega-form-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .red {
                color: var(--omega-text-error);
            }
            .step-title {
                margin: 0;
                font-size: 24px;
                color: var(--omega-text-header);
                display: flex;
                align-items: center;
            }
            hr {
                border: none;
                border-top: var(--omega-default-border);
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
            h1.title {
                display: flex;
                align-items: flex-start;
            }
            omega-tooltip {
                margin-top: 5px;
                --omega-secondary-lighten-100: var(--omega-primary);
            }
        `;
    }
}

window.customElements.define('ach-domestic-container', AchDomesticContainer);
