import { BankModelDto } from '@treasury/api/channel';
import { clone } from '@treasury/utils';
import { nullBankModel } from '../channel/types';
import { DomainEntity } from '../shared';
import { address2Array } from '../utilities';

/**
 * Represents the client-side contract for Banks.
 */
export class Bank extends DomainEntity<BankModelDto> {
    constructor(dto = clone(nullBankModel)) {
        super(dto);
    }

    public createDefault(): this {
        return new Bank(this.dto) as this;
    }

    public get name() {
        return this.dto.name || '';
    }

    public set name(name) {
        this.dto.name = name;
    }

    public get bankId() {
        return this.dto.bankId;
    }

    public set bankId(bankId) {
        this.dto.bankId = bankId;
    }

    public get bankIdType() {
        return this.dto.bankIdType;
    }

    public set bankIdType(bankIdType) {
        this.dto.bankIdType = bankIdType;
    }

    public get address() {
        return address2Array(Object.assign(this));
    }

    public get addressLine1() {
        return this.dto.addressLine1;
    }

    public set addressLine1(addressLine1) {
        this.dto.addressLine1 = addressLine1;
    }

    public get addressLine2() {
        return this.dto.addressLine2;
    }

    public set addressLine2(addressLine2) {
        this.dto.addressLine2 = addressLine2;
    }

    public get addressLine3() {
        return this.dto.addressLine3;
    }

    public set addressLine3(addressLine3) {
        this.dto.addressLine3 = addressLine3;
    }

    public get city() {
        return this.dto.city;
    }

    public get state() {
        return this.dto.state;
    }

    public get postalCode() {
        return this.dto.postalCode;
    }

    public get country() {
        return this.dto.country;
    }

    public get countryName() {
        return this.dto.countryName;
    }

    public get notes() {
        return this.dto.notes;
    }

    public set notes(notes) {
        this.dto.notes = notes;
    }

    public get isInternational() {
        return this.dto.isInternational || false;
    }

    public set isInternational(isInternational) {
        this.dto.isInternational = isInternational;
    }

    public toDto() {
        return this.dto;
    }
}
