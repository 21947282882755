import { svg } from 'lit';

export const searchIcon = svg`
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" fill="currentColor">
  <defs>
    <path id="search-a" d="M22.78125,20.834375 L19.665625,17.71875 C19.525,17.578125 19.334375,17.5 19.134375,17.5 L18.625,17.5 C19.4875,16.396875 20,15.009375 20,13.5 C20,9.909375 17.090625,7 13.5,7 C9.909375,7 7,9.909375 7,13.5 C7,17.090625 9.909375,20 13.5,20 C15.009375,20 16.396875,19.4875 17.5,18.625 L17.5,19.134375 C17.5,19.334375 17.578125,19.525 17.71875,19.665625 L20.834375,22.78125 C21.128125,23.075 21.603125,23.075 21.89375,22.78125 L22.778125,21.896875 C23.071875,21.603125 23.071875,21.128125 22.78125,20.834375 Z M13.5,17.5 C11.290625,17.5 9.5,15.7125 9.5,13.5 C9.5,11.290625 11.2875,9.5 13.5,9.5 C15.709375,9.5 17.5,11.2875 17.5,13.5 C17.5,15.709375 15.7125,17.5 13.5,17.5 Z"/>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="search-b" fill="#fff">
      <use xlink:href="#search-a"/>
    </mask>
    <use fill="#000" fill-rule="nonzero" xlink:href="#search-a"/>
    <g fill="#8B8D92" mask="url(#search-b)">
      <rect width="26" height="26" transform="translate(2 2)"/>
    </g>
  </g>
</svg>`;
