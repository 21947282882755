export default function calculateStartIndex({
    viewportSize,
    scrollSize,
    itemSize,
    allItemsCount,
    maxItemsToRender,
}) {
    if (allItemsCount <= maxItemsToRender) return 0;

    const itemsPerPage = viewportSize / itemSize;

    const endBuffer = Math.min(
        (itemSize * allItemsCount - viewportSize - scrollSize) / itemSize,
        (maxItemsToRender - itemsPerPage) / 2
    );

    const startBuffer = maxItemsToRender - itemsPerPage - endBuffer;

    return Math.max(0, Math.round(scrollSize / itemSize - startBuffer));
}
