/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AccountListDataModelDto, AccountSummaryModelDto, CompanyAccountModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AccountTransactionsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AccountTransactions
     * @name AccountTransactionsGetAll
     * @summary Returns the Account Summary for the specified Type (Deposit/TimeDeposit/Loan).
     * @request GET:/AccountTransactions
     */
    accountTransactionsGetAll = (
        query: {
            /**
             * Category of the account (Deposit/TimeDeposit/Loan).
             *
             */
            category: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AccountSummaryModelDto[], any>({
            path: `/AccountTransactions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountTransactions
     * @name AccountTransactionsGetAccountsByCategory
     * @summary Returns a collection of accounts for a specified category.
     * @request GET:/AccountTransactions/accounts
     */
    accountTransactionsGetAccountsByCategory = (
        query: {
            /**
             * Category of the account (Deposit/TimeDeposit/Loan).
             *
             */
            category: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/AccountTransactions/accounts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountTransactions
     * @name AccountTransactionsGetAccountsCoreData
     * @summary Returns Core Account Data based on provided criteria.
     * @request GET:/AccountTransactions/accounts/coredata
     */
    accountTransactionsGetAccountsCoreData = (
        query: {
            /**
             * Specific account IDs to return.
             *
             */
            accountIds?: number[] | null;
            /**
             * Indicate whether to include collected balance.
             *
             */
            includeCollectedBalance: boolean;
            /**
             * Indicate whether to include permissions.
             *
             */
            includePermissions: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AccountListDataModelDto[], any>({
            path: `/AccountTransactions/accounts/coredata`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags AccountTransactions
 * @name AccountTransactionsGet
 * @summary Returns the Account and its transaction details for the
specified account number and account type.
 * @request GET:/AccountTransactions/{id}
 */
    accountTransactionsGet = (
        id: number,
        query: {
            /**
             * Account type to retrieve.
             *
             */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AccountSummaryModelDto, any>({
            path: `/AccountTransactions/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags AccountTransactions
 * @name AccountTransactionsGet2
 * @summary Returns the Account and its transaction details for the
specified account number and account type.
 * @request GET:/AccountTransactions/{id}/{type}
 */
    accountTransactionsGet2 = (id: number, type: string, params: RequestParams = {}) =>
        this.http.request<AccountSummaryModelDto, any>({
            path: `/AccountTransactions/${id}/${type}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountTransactions
     * @name AccountTransactionsGetByDateRange
     * @summary Returns the account and the transactions for the specified criteria.
     * @request GET:/AccountTransactions/GetByDateRange/{id}/{type}
     */
    accountTransactionsGetByDateRange = (
        id: number,
        type: string,
        query: {
            /**
             * Starting date for transaction range.
             *
             * @format date-time
             */
            fromDate: string;
            /**
             * Ending date for transaction range.
             *
             * @format date-time
             */
            toDate: string;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AccountSummaryModelDto, any>({
            path: `/AccountTransactions/GetByDateRange/${id}/${type}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
