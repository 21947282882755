import { exists } from '@treasury/utils/functions';
import { DateFormatters } from '../../../shared/utilities/date-formatters';
import { AchExceptionDetailForApiDto } from '../../types/arp/ach-exception-detail-for-api.dto';
import { AchExceptionDetailDto } from '../../types/arp/ach-exception-detail.dto';

export enum PayOrReturnChoice {
    Unselected = 0,
    Pay = 1,
    Return = 2,
}

export class AchExceptionDetail {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto: AchExceptionDetailDto) {}

    private _postedDate = this.dto.postedDate ? new Date(this.dto.postedDate) : undefined;

    public decisionToPayOrReturn = PayOrReturnChoice.Unselected;

    public get postedDate() {
        return this._postedDate;
    }

    public set postedDate(date) {
        this._postedDate = date;
        if (!exists(date)) {
            this.dto.postedDate = '';
            return;
        }

        const formatted = DateFormatters.apiFormattedDates([date as Date]).value;
        this.dto.postedDate = typeof formatted === 'string' ? formatted : formatted.start;
    }

    public get pay() {
        return this.dto.pay;
    }

    public get return() {
        return this.dto.return;
    }

    public get accountId() {
        return this.dto.accountId || 0;
    }

    public set accountId(value) {
        this.dto.accountId = value;
    }

    public get accountName() {
        return this.dto.accountName;
    }

    public set accountName(value) {
        this.dto.accountName = value;
    }

    public get accountNickname() {
        return this.dto.accountNickname;
    }

    public set accountNickname(value) {
        this.dto.accountNickname = value;
    }

    public get accountNumber() {
        return this.dto.accountNumber;
    }

    public set accountNumber(value) {
        this.dto.accountNumber = value;
    }

    public get accountType() {
        return this.dto.accountType;
    }

    public set accountType(value) {
        this.dto.accountType = value;
    }

    public get achCompanyId() {
        return this.dto.achCompanyId;
    }

    public set achCompanyId(value) {
        this.dto.achCompanyId = value;
    }

    public get achCompanyName() {
        return this.dto.achCompanyName;
    }

    public set achCompanyName(value) {
        this.dto.achCompanyName = value;
    }

    public get achExceptionDecisionStatusId() {
        return this.dto.achExceptionDecisionStatusId || 0;
    }

    public set achExceptionDecisionStatusId(value) {
        this.dto.achExceptionDecisionStatusId = value;
    }

    public get achExceptionDetailId() {
        return this.dto.achExceptionDetailId || 0;
    }

    public set achExceptionDetailId(value) {
        this.dto.achExceptionDetailId = value;
    }

    public get amount() {
        return this.dto.amount || 0;
    }

    public set amount(value) {
        this.dto.amount = value;
    }

    public get batchNumber() {
        return this.dto.batchNumber;
    }

    public set batchNumber(value) {
        this.dto.batchNumber = value;
    }

    public get companyId() {
        return this.dto.companyId || 0;
    }

    public set companyId(value) {
        this.dto.companyId = value;
    }

    public get companyName() {
        return this.dto.companyName;
    }

    public set companyName(value) {
        this.dto.companyName = value;
    }

    public get decisionBy() {
        return this.dto.decisionBy;
    }

    public set decisionBy(value) {
        this.dto.decisionBy = value;
    }

    public get decisionDate() {
        return this.dto.decisionDate;
    }

    public set decisionDate(value) {
        this.dto.decisionDate = value;
    }

    public get decisionTaken() {
        return this.dto.decisionTaken;
    }

    public set decisionTaken(value) {
        this.dto.decisionTaken = value;
    }

    public get description() {
        return this.dto.description;
    }

    public set description(value) {
        this.dto.description = value;
    }

    public get entryDescription() {
        return this.dto.entryDescription;
    }

    public set entryDescription(value) {
        this.dto.entryDescription = value;
    }

    public get exceptionItemId() {
        return this.dto.exceptionItemId;
    }

    public set exceptionItemId(value) {
        this.dto.exceptionItemId = value;
    }

    public get exceptionType() {
        return this.dto.exceptionType;
    }

    public set exceptionType(value) {
        this.dto.exceptionType = value;
    }

    public get fromWorkStation() {
        return this.dto.fromWorkStation;
    }

    public set fromWorkStation(value) {
        this.dto.fromWorkStation = value;
    }

    public get id() {
        return this.dto.id || 0;
    }

    public set id(value) {
        this.dto.id = value;
    }

    // postedDate appears at the top of this list of properties.

    public get protected() {
        return this.dto.protected;
    }

    public set protected(value) {
        this.dto.protected = value;
    }

    public get secCode() {
        return this.dto.secCode;
    }

    public set secCode(value) {
        this.dto.secCode = value;
    }

    public get updatedBy() {
        return this.dto.updatedBy;
    }

    public set updatedBy(value) {
        this.dto.updatedBy = value;
    }

    public get updatedDate() {
        return this.dto.updatedDate;
    }

    public set updatedDate(value) {
        this.dto.updatedDate = value;
    }

    private setDateToApiConsumableFormat(originalDate: Date | undefined) {
        if (originalDate === undefined) return '';
        const dateSansTime = DateFormatters.removeTime(originalDate);
        return `${dateSansTime.toString()}T00:00:00`;
    }

    public toDto() {
        const fragment: AchExceptionDetailForApiDto = {
            accountId: this.accountId,
            accountName: this.accountName,
            accountNumber: this.accountNumber,
            accountType: this.accountType,
            achCompanyId: this.achCompanyId,
            achCompanyName: this.achCompanyName,
            achExceptionDecisionStatusId: this.decisionToPayOrReturn,
            achExceptionDetailId: this.achExceptionDetailId,
            amount: this.amount,
            batchNumber: this.batchNumber,
            companyId: this.companyId,
            companyName: this.companyName,
            decisionBy: this.decisionBy,
            decisionDate: this.decisionDate,
            entryDescription: this.entryDescription,
            exceptionItemId: this.exceptionItemId,
            exceptionType: this.exceptionType,
            fromWorkStation: this.fromWorkStation,
            id: this.id,
            postedDate: this.setDateToApiConsumableFormat(this.postedDate),
            protected: this.protected,
            secCode: this.secCode,
            updatedBy: this.updatedBy,
            updatedDate: this.updatedDate,
        };
        return fragment;
    }
}
