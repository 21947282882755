/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import { url } from 'inspector';

interface AgileticsWebEscrowSsoResponse {
    endPoint: string;
    body: AgileticsWebEscrowSsoResponseBody;
}

interface AgileticsWebEscrowSsoResponseBody {
    samlResponse: string;
    relayState: string;
}

export default class AgileticsWebEscrowSsoRequest {
    static async getAgileticsWebEscrowSsoUrl(): Promise<AgileticsWebEscrowSsoResponse> {
        return (await TmHttpClient.getInstance()).request<any>(`Authentication/Agiletics`, {
            method: 'POST',
        });
    }

    static async login(url: string, body: AgileticsWebEscrowSsoResponseBody): Promise<string> {
        const params = new URLSearchParams({
            SAMLResponse: body.samlResponse,
            RelayState: body.relayState,
        });

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString(),
            credentials: 'include',
        });

        return new URL(url).origin + body.relayState;
    }
}
