import { css, LitElement } from 'lit';

export const OmegaProgressTagName = 'omega-progress';
export class OmegaProgress extends LitElement {
    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-progress--progress',
        };
    }

    static get styles() {
        return css`
            @keyframes spin {
                100% {
                    transform: rotate(360deg);
                }
            }
            :host {
                display: inline-block;
                animation: spin 0.75s linear infinite;
                transform-origin: center;
                width: 16px;
                height: 16px;
                border-width: 2px;
                border-style: solid;
                border-color: var(--omega-progress-color, var(--omega-border-color));
                border-right-color: transparent !important;
                border-radius: 50%;
            }
            :host([inline]) {
                width: 12px;
                height: 12px;
            }
            :host([card]) {
                display: block;
                width: 28px;
                height: 28px;
                border-width: var(--omega-progress-card-border-width, 4px);
                margin: 80px auto;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
window.customElements.define(OmegaProgressTagName, OmegaProgress);
export default OmegaProgress;
