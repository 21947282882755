import { getChannelAPIPath } from '@treasury/core/http';

AchFileActivityService.$inject = ['$resource', '$http', '$filter'];

export default function AchFileActivityService($resource, $http, $filter) {
    const resourceUrl = `${getChannelAPIPath()}achFileActivity`;

    return {
        search,
        getsourcelist,
        deleteFile,
        downloadNachaFile,
        processNachaFile,
    };

    function search(message) {
        return $resource(resourceUrl, {}, { search: { method: 'POST', isArray: true } }).search(
            message
        ).$promise;
    }

    function getsourcelist() {
        return $resource(
            `${resourceUrl}/getACHSourceList`,
            {},
            { get: { method: 'GET', isArray: true } }
        ).get().$promise;
    }

    function downloadNachaFile(fileArchiveId) {
        return $http
            .get(`${resourceUrl}/getNachaFileContent/${fileArchiveId}`, {
                responseType: 'arraybuffer',
            })
            .then(response => response.data);
    }

    function deleteFile(fileId) {
        return $resource(`${resourceUrl}/delete`, { fileId }).delete().$promise;
    }

    function processNachaFile(fileId) {
        return $resource(
            `${resourceUrl}/process/${fileId}`,
            {},
            { search: { method: 'POST' } }
        ).search(fileId).$promise;
    }
}
