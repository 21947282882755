import {
    IIssuedItem,
    IssuedItemPendingStatus,
    IssuedItemUiState,
} from '@treasury/domain/channel/mappings/arp';

/**
 * Based on its status,
 * get the icon, icon color, and row color for an issued item in the creation UI.
 */
export function getIssuedItemColorAndIcon(item: IIssuedItem) {
    const { pendingStatus, errorState } = item;

    if (errorState === IssuedItemUiState.Error) {
        const color = 'var(--omega-error)';
        return {
            iconColor: color,
            iconName: 'exclamation-triangle',
            rowColor: color,
        };
    }

    if (errorState === IssuedItemUiState.Warning) {
        const color = 'var(--omega-warning)';
        return {
            iconColor: color,
            iconName: 'info-circle',
            rowColor: color,
        };
    }

    if (pendingStatus === IssuedItemPendingStatus.SelectedFromMatches) {
        return {
            iconColor: 'var(--omega-success)',
            iconName: 'check-circle',
        };
    }

    return undefined;
}
