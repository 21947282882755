/* eslint-disable class-methods-use-this */
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import Column from './column.js';

export default class DetailColumn extends Column {
    constructor(...args) {
        super(...args);
        const [attributes] = args;
        this.maxWidth = 100;
        this.minWidth = 50;
        this.targetWidth = 100;
        this.displayToggleAll = attributes['display-toggle-all'] !== undefined;
        this.displayChevron = attributes['display-chevron'] !== undefined;
        this.noPrint = true;
    }

    cellClasses() {
        return [...super.cellClasses(), 'detail-column'];
    }

    renderThContents(cursor) {
        if (!this.displayToggleAll) return null;

        const isEveryRecordExpanded = cursor.visibleRecords.every(vr => vr.isExpanded);
        function toggleAllDetailRow() {
            this.dispatchEvent(
                new CustomEvent('toggle-all-detail-row', {
                    bubbles: true,
                    composed: true,
                    detail: { value: !isEveryRecordExpanded },
                })
            );
        }

        const toggleLabel = isEveryRecordExpanded ? 'Close All' : 'Open All';
        return html` <style>
                .link-column-header {
                    text-align: right;
                }
                .link-column-header button {
                    cursor: pointer;
                    border: none;
                    background: none;
                    color: var(--omega-primary);
                    text-decoration: underline;
                }
            </style>
            <div class="link-column-header">
                <button @click=${toggleAllDetailRow}>${toggleLabel}</button>
            </div>`;
    }

    renderTdContents(record, rowIndex) {
        function toggleDetailRow() {
            this.dispatchEvent(
                new CustomEvent('toggle-detail-row', {
                    detail: {
                        rowIndex,
                        record,
                    },
                    bubbles: true,
                    composed: true,
                })
            );
        }

        if (this.displayChevron) {
            const directionClass = record.isExpanded ? 'chevron up' : 'chevron down';
            return html` <style>
                    .detail-row-trigger {
                        text-align: right;
                    }
                    .chevron::before {
                        cursor: pointer;
                        position: relative;
                        right: 12px;
                        border-style: solid;
                        border-color: var(--omega-text-secondary);
                        border-width: 0.25em 0.25em 0 0;
                        content: '';
                        display: inline-block;
                        height: 0.45em;
                        top: 0.15em;
                        transform: rotate(-45deg);
                        vertical-align: top;
                        width: 0.45em;
                    }
                    .chevron.down::before {
                        top: 0;
                        transform: rotate(135deg);
                    }
                    .chevron.up::before {
                        transform: rotate(-45deg);
                    }
                </style>
                <div class="detail-row-trigger">
                    <span class=${directionClass} @click=${toggleDetailRow}></span>
                </div>`;
        }

        return html`
            <style>
                .detail-row-trigger {
                    text-align: right;
                }
            </style>
            <div class="detail-row-trigger">
                <span
                    class=${classMap({
                        'link-column': !!record,
                    })}
                    @click=${toggleDetailRow}
                    >Details</span
                >
            </div>
        `;
    }

    /*
    We may want to restore this later. Ken 4/21/2021
    renderContentLoading() {
        return html` <style>
                .detail-row-trigger {
                    text-align: right;
                }
            </style>
            <span>Details</span>`;
    }
    */
}
