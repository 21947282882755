/* eslint-disable @treasury/style-includes-host-display */

import { Recordset } from '@treasury/FDL';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import '@treasury/omega/layouts/omega-report';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../components/blocking-loader.js';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';
import './ach-return-activity-detail-row.js';
import { AchReturnActivityClient } from './clients/ach-return-activity-client.js';
import { reportColumns } from './data/ach-return-activity-columns.js';
import { reportFields } from './data/ach-return-activity-fields.js';
import { reportFilters } from './data/ach-return-activity-filters.js';

class AchReturnActivityContainer extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            institution: String,
            filterRecord: Array,
            reportFilters: Array,
            reportFields: Array,
            reportColumns: Array,
            reportActions: Array,
            defaultPerPage: Number,
            landingPageMessage: String,
            alert: Object,
            showBlockingLoader: Boolean,
            recordset: Object,
        };
    }

    constructor() {
        super();
        this.reportFilters = reportFilters;
        this.reportFields = reportFields;
        this.columns = reportColumns;
        this.client = new AchReturnActivityClient();
        this.defaultPerPage = 25;
        this.landingPageMessage = 'No Returns Found';
        this.showBlockingLoader = false;
        this.reportTitle = 'ACH Return Activity';
        this.reportInformation = html`<div style="width: 335px">
            ACH Return Activity - Includes all return activity for the current day. Utilize the
            search feature to review return activity.
        </div>`;
        this.recordset = new Recordset(this.reportFields, this.client.search);
    }

    get institution() {
        return this._institution;
    }

    set institution(value) {
        this._institution = value;
    }

    async _fullPagePrint() {
        this.client.print('achReturnActivityChangeFullPage');
    }

    async _detailPrint(e) {
        this.client.print('achReturnActivityDetail', e.detail);
    }

    async _saveReturn({ detail }) {
        try {
            this.showBlockingLoader = true;
            await this.client.downloadReturn(detail);
        } catch (err) {
            this.setAlertFromError(err);
        } finally {
            this.showBlockingLoader = false;
        }
    }

    firstUpdated() {
        this.setNocVersionPath();
    }

    async setNocVersionPath() {
        const nocV2FeatureFlagEnabled = await FeatureFlagService.isEnabled(
            Feature.AchNotificationOfChangeNacha
        );

        const nocVersionPath = nocV2FeatureFlagEnabled ? '' : '/v1';
        this.reportActions = [
            {
                url: `${this.institution}/ach/ach-notice-of-change${nocVersionPath}`,
                title: 'ACH Notification of Change Activity',
            },
        ];
    }

    renderBlockingLoader() {
        if (this.showBlockingLoader) return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    renderDetail(record, close) {
        return html`
            <ach-return-activity-detail-row
                .client=${this.client}
                .parentRecord=${record}
                .closeFunction=${close}
                @detailPrint=${this._detailPrint}
                @saveReturn=${e => this._saveReturn(e)}
            ></ach-return-activity-detail-row>
        `;
    }

    render() {
        return html`
            ${this.renderAlert()} ${this.renderBlockingLoader()}
            <omega-report
                flyout
                .title=${this.reportTitle}
                .recordset=${this.recordset}
                .reportInformation=${this.reportInformation}
                displayToggleAll
                .reportLinks=${this.reportActions}
                .options=${['print']}
                .filters=${this.reportFilters}
                .columns=${this.columns}
                .detailFunction=${(record, close) => this.renderDetail(record, close)}
                @pagePrint=${this._fullPagePrint}
                @detailPrint=${this._detailPrint}
                .rowsPerPage=${this.defaultPerPage}
                .landingPageMessage=${this.landingPageMessage}
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
            }
        `;
    }
}

export default AchReturnActivityContainer;
window.customElements.define('ach-return-activity-container', AchReturnActivityContainer);
