import { PositivePayExceptionRequests } from '@treasury/domain/channel/requests/positive-pay/positive-pay-exception-requests.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-alert.js';
import { LitElement, css, html, nothing } from 'lit';
import './parts/ach-exception-widget.js';
import './parts/check-exception-widget-v2.js';
import './parts/check-exception-widget.js';

const CHECK_EXCEPTIONS = 'checkExceptions';
const ACH_EXCEPTIONS = 'achExceptions';

class PositivePayWidget extends ListeningElementMixin(LitElement) {
    static get properties() {
        return {
            exceptionType: String,
            checkExceptionCount: Number,
            achExceptionCount: Number,
            checkExceptionRecords: Object,
            achExceptionRecords: Array,
            isReviewStep: Boolean,
            loadingCheckExceptions: Boolean,
            loadingAchExceptions: Boolean,
            options: Array,
            disableCheckExceptions: Boolean,
            _hasWorkACHExceptionsEntitlement: { state: true, type: Boolean },
            _hasWorkCheckExceptionsEntitlement: { state: true, type: Boolean },
            _isCheckExceptionV2: { state: true, type: Boolean },
        };
    }

    constructor() {
        super();
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
        this.exceptionType = CHECK_EXCEPTIONS;
        this.checkExceptionCount = 0;
        this.achExceptionCount = 0;
        this.loadCheckExceptions();
        this.loadAchExceptions();
        this.getOptions();
    }

    async firstUpdated() {
        this.parentElement.style.overflow = 'hidden';

        this._hasWorkACHExceptionsEntitlement =
            await EntitlementsService.instance.hasEntitlement('Work ACH Exceptions');

        this._hasWorkCheckExceptionsEntitlement =
            await EntitlementsService.instance.hasEntitlement('Work Check Exceptions');

        this._isCheckExceptionV2 = await FeatureFlagService.isEnabled(Feature.CheckExceptionV2);
    }

    async getOptions() {
        this.options = await PositivePayExceptionRequests.getPositivePayCompanyConfiguration();
    }

    async loadCheckExceptions() {
        this.loadingCheckExceptions = true;
        try {
            this.arpExceptionAccounts =
                await PositivePayExceptionRequests.getArpExceptionAccounts();
            if (this.arpExceptionAccounts && this.arpExceptionAccounts.length > 0) {
                const params = {
                    parameters: {
                        account: this.arpExceptionAccounts.map(account => account.accountUniqueId),
                    },
                };
                this.checkExceptionRecords =
                    await CheckExceptionsServices.searchCheckExceptions(params);
                this.checkExceptionCount = this.checkExceptionRecords.totalCount;
                this.checkExceptionReturnReasons =
                    await PositivePayExceptionRequests.getReturnReasons();
            } else {
                this.alert = {
                    title: '',
                    code: '',
                    message: 'No ARP exception accounts found.',
                    visible: true,
                    type: 'warning',
                };
                this.loadingCheckExceptions = false;
                this.disableCheckExceptions = true;
                this.setExceptionType(ACH_EXCEPTIONS);
            }
        } catch (error) {
            this.alert = {
                title: '',
                code: error instanceof TmApiError ? error.code : undefined,
                message:
                    error instanceof Error
                        ? error.message
                        : 'An error occurred retrieving check exceptions.',
                visible: true,
                type: 'error',
            };
            throw error;
        } finally {
            this.loadingCheckExceptions = false;
        }
    }

    async loadAchExceptions() {
        this.loadingAchExceptions = true;
        try {
            this.achExceptionRecords =
                await PositivePayExceptionRequests.getAchExceptionsForWidget();
            this.achExceptionCount = this.achExceptionRecords?.length
                ? this.achExceptionRecords.length
                : 0;
        } catch (error) {
            this.alert = {
                title: '',
                code: error instanceof TmApiError ? error.code : undefined,
                message:
                    error instanceof Error
                        ? error.message
                        : 'An error occurred retrieving ACH exceptions.',
                visible: true,
                type: 'error',
            };
            throw error;
        } finally {
            this.loadingAchExceptions = false;
        }
    }

    setExceptionType(exceptionType) {
        this.exceptionType = exceptionType;
    }

    setReviewstep(detail) {
        this.isReviewStep = detail;
    }

    renderCheckExceptionButton() {
        if (!this._hasWorkCheckExceptionsEntitlement || this.disableCheckExceptions) return nothing;
        return html`
            <omega-button
                type="link"
                ?disabled=${this.exceptionType === CHECK_EXCEPTIONS || this.isReviewStep}
                @click=${() => this.setExceptionType(CHECK_EXCEPTIONS)}
            >
                Check Exceptions (${this.checkExceptionCount})
            </omega-button>
        `;
    }

    renderAchExceptionButton() {
        if (!this._hasWorkACHExceptionsEntitlement) return nothing;

        return html`
            <omega-button
                type="link"
                ?disabled=${this.exceptionType === ACH_EXCEPTIONS || this.isReviewStep}
                @click=${() => this.setExceptionType(ACH_EXCEPTIONS)}
            >
                ACH Exceptions (${this.achExceptionCount})
            </omega-button>
        `;
    }

    renderHeader() {
        return html`
            <div class="header">
                <div>
                    <span class="widget-title"> Positive Pay </span>
                    <span class="sublinks">
                        ${this.renderCheckExceptionButton()} ${this.renderAchExceptionButton()}
                    </span>
                </div>
            </div>
        `;
    }

    renderSuccessMessage() {
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Decisions saved successfully!',
            type: 'success',
        };
    }

    renderCheckExceptions() {
        if (this.loadingCheckExceptions)
            return html`
                <div class="loader-wrapper">
                    <omega-progress class="bigger-loader"></omega-progress>
                </div>
            `;
        if (this.checkExceptionCount === 0) {
            return html`
                <div class="message-container">
                    <p class="message">There are no check exceptions to review.</p>
                </div>
            `;
        }
        if (this._isCheckExceptionV2) {
            return html`
                <check-exception-widget-v2
                    .records=${this.checkExceptionRecords}
                    .returnReasons=${this.checkExceptionReturnReasons}
                    .options=${this.options}
                    @setReviewStep=${({ detail }) => this.setReviewstep(detail)}
                    @recordUpdateRequest=${() => this.loadCheckExceptions()}
                    @showSuccessMessage=${() => this.renderSuccessMessage()}
                ></check-exception-widget-v2>
            `;
        }
        return html`
            <check-exception-widget
                .records=${this.checkExceptionRecords}
                .returnReasons=${this.checkExceptionReturnReasons}
                .options=${this.options}
                @setReviewStep=${({ detail }) => this.setReviewstep(detail)}
                @saved=${() => this.loadCheckExceptions()}
                @showSuccessMessage=${() => this.renderSuccessMessage()}
            ></check-exception-widget>
        `;
    }

    renderAchExceptions() {
        if (this.loadingAchExceptions)
            return html`
                <div class="loader-wrapper">
                    <omega-progress class="bigger-loader"></omega-progress>
                </div>
            `;
        if (this.achExceptionCount === 0) {
            return html`
                <div class="message-container">
                    <p class="message">There are no ACH exceptions to review.</p>
                </div>
            `;
        }
        return html`
            <ach-exception-widget
                .records=${this.achExceptionRecords}
                @setReviewStep=${({ detail }) => this.setReviewstep(detail)}
                @saved=${() => this.loadAchExceptions()}
                @showSuccessMessage=${() => this.renderSuccessMessage()}
            ></ach-exception-widget>
        `;
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html`
            <div style="padding: 0px 8px;">
                <omega-alert
                    type=${type}
                    title=${title}
                    posture=${posture}
                    ?isVisible=${visible}
                    @close=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                >
                    ${renderedCode} ${message} ${renderedTime} ${actions}
                </omega-alert>
            </div>
        `;
    }

    render() {
        return html`
            ${this.renderAlert()} ${this.renderHeader()}
            ${this.exceptionType === CHECK_EXCEPTIONS
                ? this.renderCheckExceptions()
                : this.renderAchExceptions()}
        `;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                height: 100%;
                background-color: white;
                --omega-table-overflow: auto;
            }
            .header {
                display: flex;
                flex: 0 0 54px;
                border-bottom: 1px solid var(--omega-light-grey);
                align-items: center;
            }
            .widget-title {
                font-size: 18px;
                margin-left: 15px;
            }
            .sublinks omega-button {
                text-decoration: none;
                border-left: 1px solid var(--omega-light-grey);
                margin: 0;
            }
            .sublinks omega-button:first-of-type {
                border: none;
            }
            .loader-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 85%;
            }
            .bigger-loader {
                width: 50px;
                height: 50px;
                border-width: 6px;
            }
            .message-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .message-container .message {
                font-size: 15px;
            }
        `;
    }
}

window.customElements.define('positive-pay-widget', PositivePayWidget);
export default PositivePayWidget;
