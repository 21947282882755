// cspell:ignore uirouter
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-tooltip';
import '@treasury/omega/components/progress/omega-progress.js';
import '@treasury/omega/layouts/omega-report';

import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { WireFileActivity } from '@treasury/domain/channel/mappings/wires/wire-file-activity.entity';
import { WiresService } from '@treasury/domain/channel/services';
import { TmApiError } from '@treasury/domain/shared';
import { Record, Recordset } from '@treasury/FDL';
import { RecordsetFetchArgs } from '@treasury/FDL/recordset';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import { AlertMixin } from '@treasury/omega/mixins/alert.mixin';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { LocalFilterEvent, OmegaReportFilter, OmegaReportLink } from '@treasury/omega/types';
import { noOp } from '@treasury/utils';

import { createWireActivityFilters, fileActivityColumns, fileActivityFields } from './data';
import { mapWireFileActivitySearchParams, WireFileActivitySearchParams } from './models';

type WireFileActivityFilterEvent = LocalFilterEvent<WireFileActivitySearchParams>;

export const WireFileActivityTagName = 'wire-file-activity-container';

const mixin = AlertMixin(ListeningElementMixin(LitElement));
@customElement(WireFileActivityTagName)
export class WireFileActivityContainer extends mixin {
    public readonly reportTitle = 'Wire File Activity';

    private isWireSftpEnabled = false;

    @property({
        type: String,
    })
    public institution!: string;

    @InjectProperty()
    private wiresService!: WiresService;

    @InjectProperty()
    private declare dialogService: OmegaDialogService;

    @InjectProperty({
        async: true,
        token: FeatureFlagService,
    })
    private declare ffServicePromise: Promise<FeatureFlagService>;

    @InjectProperty()
    private declare navService: NavigationService;

    private readonly toolbarOptions = ['print'];

    @state()
    private filters: OmegaReportFilter<WireFileActivity>[] = [];

    private columns: OmegaColumnDefinition<WireFileActivity>[] = [];

    private localFilters: WireFileActivityFilterEvent['detail'] = [];

    @state()
    private isLoading = false;

    public actions = {
        review: (record: Record<WireFileActivity>) => this.reviewWires(record),
        view: (record: Record<WireFileActivity>) => this.viewWireFile(record),
        process: (record: Record<WireFileActivity>) => this.showWireFileProcessDialog(record),
        delete: (record: Record<WireFileActivity>) => this.showWireFileDeleteDialog(record),
    };

    @state({
        hasChanged: (
            newValue: Recordset<WireFileActivity>,
            oldValue: Recordset<WireFileActivity>
        ) => {
            if (!oldValue) {
                return true;
            }

            return newValue.allRecords !== oldValue.allRecords;
        },
    })
    private recordset!: Recordset<WireFileActivity, WireFileActivitySearchParams>;

    // eslint-disable-next-line @treasury/consistent-name-for-function-that-returns-template-literal
    private get reportInformation() {
        const wirePaymentWord = this.isWireSftpEnabled ? 'Payment ' : '';
        return html`<div style="width: 260px;">
            <p>
                <b>Wire File Activity</b> - Views all wire files that have been submitted to the
                bank for processing.
            </p>
            <p>
                <b>Recurring Wires</b> - Includes only recurring payments and the details
                (Frequency, Create Date, Next Payment Date, End Date, etc.) associated with the
                respective payment series.
            </p>
            <p>
                <b>Wire ${wirePaymentWord}Activity</b> - Includes all payments including recurring
                payments regardless of payment status (Payments Pending Approval, Scheduled,
                Submitted, etc.). Utilize the search feature to search payment history.
            </p>
        </div>`;
    }

    private get reportLinks() {
        const linkTitle = this.isWireSftpEnabled ? 'Wire Payment Activity' : 'Wire Activity';
        const links: OmegaReportLink[] = [
            {
                title: 'Recurring Wires',
                route: 'payables.wire.recurring-wire-list',
                parameters: {},
            },
            {
                title: linkTitle,
                route: 'payables.wire.wire-list',
                parameters: {},
            },
        ];

        return links;
    }

    public async reviewWires(record: Record<WireFileActivity>) {
        const useNewWirePaymentActivity = await (
            await this.ffServicePromise
        ).isEnabled(Feature.WirePaymentActivityTotals);
        if (useNewWirePaymentActivity) {
            this.navService.navigate('payables.wire.wire-list', {
                wireFileId: record.getField('wireFileId'),
                fileName: record.getField('fileName'),
            });
        } else {
            const url = `${this.institution}/payables/wire/wire-list`;
            window.history.pushState(
                { fileName: record.values.fileName, fileId: record.values.wireFileId },
                '',
                url
            );
            window.location.reload();
        }
    }

    private async getFileContents(record: Record<WireFileActivity>) {
        const fileContents: any = await this.wiresService.getWireFileContents(
            record.values.wireFileUniqueId ?? ''
        );
        return html`<pre>${fileContents.contentData}</pre>`;
    }

    public async viewWireFile(record: Record<WireFileActivity>) {
        const dialogContents = html`
            <p>${record.getField('fileFormat')}</p>
            ${until(
                this.getFileContents(record),
                html`<omega-progress card class="light-loader"></omega-progress>`
            )}
        `;

        this.dialogService.open(dialogContents, record.getField('fileName'), {
            buttons: {
                [OmegaDialogExitReason.Cancel]: null,
            },
            width: 800,
        });
    }

    private async showWireFileDeleteDialog(record: Record<WireFileActivity>) {
        // TODO: Change button label to "Delete File" and make it Red.
        const prompt = html`
            <p>Are you sure you want to delete this transmitted wire file?</p>
            <p>${record.getField('fileName')}</p>
        `;
        const { reason } = await this.dialogService.open(prompt, 'Delete File').closed;
        return this.handleDeleteDialogClose(reason, record);
    }

    private async showWireFileProcessDialog(record: Record<WireFileActivity>) {
        if (!record.getField('isValid')) {
            return this.goToWireFileUpload(record);
        }
        const prompt = html`
            <p>Are you sure you want to process this transmitted wire file?</p>
            <p>${record.getField('fileName')}</p>
        `;
        const buttons = {
            [OmegaDialogExitReason.Confirm]: {
                label: 'Process File',
                onClick: noOp,
            },
            [OmegaDialogExitReason.Cancel]: {
                label: 'Cancel',
                onClick: noOp,
            },
        };
        const { reason } = await this.dialogService.open(prompt, 'Process Wire File', { buttons })
            .closed;
        return this.handleProcessDialogClose(reason, record);
    }

    private handleDeleteDialogClose(
        reason: OmegaDialogExitReason,
        record: Record<WireFileActivity>
    ) {
        if (reason === OmegaDialogExitReason.Confirm) {
            this.removeFile(record);
            return true;
        }

        return false;
    }

    private goToWireFileUpload(record: Record<WireFileActivity>) {
        this.navService.navigate('payables.wire.wire-upload', {
            wireFileUniqueId: record.getField('wireFileUniqueId'),
            fileName: record.getField('fileName'),
            fileSize: record.getField('fileSize'),
        });
    }

    private handleProcessDialogClose(
        reason: OmegaDialogExitReason,
        record: Record<WireFileActivity>
    ) {
        if (reason === OmegaDialogExitReason.Confirm) {
            this.goToWireFileUpload(record);
            return true;
        }

        return false;
    }

    public async firstUpdated() {
        this.isWireSftpEnabled = await (
            await this.ffServicePromise
        ).isEnabled(Feature.WireSftpEnabled);

        if (!this.institution) {
            throw new Error(
                `The institution attribute of <${WireFileActivityTagName}> is required.`
            );
        }
        this.columns = fileActivityColumns(this.isWireSftpEnabled);

        const { params } = await this.navService.getRouteData<{ wireFileName: string }>();
        const { wireFileName } = params;

        try {
            const fileUploadUsers = await this.wiresService.getAllFileUploadUsers();
            this.filters = createWireActivityFilters(
                fileUploadUsers,
                wireFileName,
                this.isWireSftpEnabled
            );
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code: code.toString(),
                    time,
                };
            } else {
                const message = 'An unknown error occurred.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }
        this.recordset = new Recordset<WireFileActivity, WireFileActivitySearchParams>(
            fileActivityFields(this.isWireSftpEnabled),
            args => this.fetchFiles(args)
        );
        this.recordset.sortColumns = [
            { field: 'processedDate', sort: 'descending' },
            { field: 'uploadDate', sort: 'descending' },
        ];
    }

    private async fetchFiles(
        fetchArgs: RecordsetFetchArgs<WireFileActivity, WireFileActivitySearchParams>
    ) {
        let { sort } = fetchArgs;
        const { parameters, pageSize, startIndex } = fetchArgs;
        // Temporary hack to remove processedDate from sort until the API is updated
        sort = sort.filter(s => s.field.toLowerCase() !== 'processeddate');

        try {
            const searchDtoCopy = mapWireFileActivitySearchParams(
                parameters,
                pageSize,
                sort,
                startIndex
            );
            return this.wiresService.getFiles(searchDtoCopy);
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode, timestamp } = err;

                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code: errorCode,
                    time: timestamp,
                };
            } else {
                const message = 'An unknown error occurred.';
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }

            throw err;
        } finally {
            this.isLoading = false;
        }
    }

    private onFilter(event: WireFileActivityFilterEvent) {
        const { detail } = event;
        this.localFilters = detail;
    }

    public async removeFile(file: Record<WireFileActivity>) {
        if (!file.getField('wireFileUniqueId')) {
            return;
        }
        try {
            const response = await this.wiresService.removeFile(
                file.getField('wireFileUniqueId') ?? ''
            );
            if (response) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: 'Delete',
                    type: 'success',
                    message: 'Wire File successfully deleted!',
                };
                this.recordset.deleteRecord(file);
            } else {
                this.alert = {
                    ...this.alert,
                    message: 'Unexpected error',
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: '',
                    type: 'error',
                };
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message: html`${message} <br />#${code}`,
                    visible: true,
                    posture: 'polite',
                    title: '',
                    actions: '',
                    type: 'error',
                };
            } else {
                const message = 'Could not delete file. Please try again.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        }
    }

    private renderBlockingLoader() {
        return this.isLoading ? html`<blocking-loader></blocking-loader>` : nothing;
    }

    public render() {
        // don't render the UI until the request comes back and filters can be constructed
        if (!this.filters || this.filters.length < 1) {
            return nothing;
        }

        return html`${this.renderAlert()} ${this.renderBlockingLoader()}

            <div class="report-container">
                <omega-report
                    flyout
                    autostart
                    .actions=${this.actions}
                    .title=${this.reportTitle}
                    .columns=${this.columns}
                    .recordset=${this.recordset}
                    .localFilters=${this.localFilters}
                    .options=${this.toolbarOptions}
                    .filters=${this.filters}
                    .reportLinks=${this.reportLinks}
                    .reportInformation=${this.reportInformation}
                >
                    <div id="top-of-table" slot="above-table">
                        <omega-filter-bar
                            .recordset=${this.recordset}
                            .onFilter=${(e: CustomEvent) => this.onFilter(e)}
                        ></omega-filter-bar>
                    </div>
                </omega-report>
            </div>`;
    }

    static styles = css`
        :host {
            --icon-column-max-width: 45px;
            --icon-column-min-width: 45px;
        }

        omega-filter-bar {
            margin-left: 10px;
        }

        .report-container {
            height: 100%;
            position: relative;
            background-color: #fff;
            border: 1px solid #eeededfd;
            box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
            overflow-x: auto;
        }

        .padded {
            padding: 10px;
        }

        .row {
            display: flex;
            flex-direction: row;
        }

        .row > * {
            flex: auto;
        }

        .full-width {
            width: 100%;
        }

        .right {
            text-align: right;
        }

        .text-center {
            text-align: center;
        }

        .text-right {
            text-align: right;
        }

        .dialog-body {
            padding: 20px;
        }
    `;
}
