import { isValidDate, isValidRepeatDay1BeforeRepeatDay2, isValidRepeatOnEnd } from './index';

const isValidRepeatBothDays = (frequency: any) => {
    if (!frequency) return false;

    // if (logging) console.log(`valid repeat on (all checks both days): ${result}`);

    return (
        isValidDate(frequency.repeatOnDay1) &&
        isValidRepeatOnEnd(frequency) &&
        isValidRepeatDay1BeforeRepeatDay2(frequency)
    );
};

export default isValidRepeatBothDays;
