import { css, html, nothing } from 'lit';
import { OmegaDialog } from './omega-dialog.js';

class OmegaDialogBranded extends OmegaDialog {
    static get properties() {
        return {
            logoSource: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.hideCloseButton = true;
    }

    // STUB: not relevant to storybook
    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-button--button',
        };
    }

    renderLogo() {
        if (this.logoSource) {
            return html` <img class="logo" src=${this.logoSource} alt="" /> `;
        }
        return nothing;
    }

    renderHeader() {
        return html` <div class="omega-dialog-header">
            <span class="omega-dialog-header-title">${this.dialogTitle}</span>
            <span class="omega-dialog-header-supplement">${this.renderLogo()}</span>
        </div>`;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [
            super.styles,
            css`
                .omega-dialog {
                    padding-top: var(--omega-dialog-panel-padding, 15px);
                    padding-left: var(--omega-dialog-panel-padding, 15px);
                    padding-right: var(--omega-dialog-panel-padding, 15px);
                }
                .omega-dialog-header-title {
                    font-size: var(--omega-dialog-header-font-size, 20px);
                    justify-content: flex-start;
                }
                .omega-dialog-content {
                    border-top: var(--omega-content-separator, 1px solid #dddddd);
                    border-bottom: var(--omega-content-separator, 1px solid #dddddd);
                }
                .omega-dialog-actions {
                    justify-content: flex-start;
                }
                .logo {
                    max-width: 250px;
                }
            `,
        ];
    }
}

customElements.define('omega-dialog-branded', OmegaDialogBranded);
export default OmegaDialogBranded;
