/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { HealthCheckActionResponseDto, QboSettingsModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class ApiClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Download
     * @name DownloadGetQboSettings
     * @summary Gets the qbo settings.
     * @request GET:/api/Download
     */
    downloadGetQboSettings = (params: RequestParams = {}) =>
        this.http.request<QboSettingsModelDto, any>({
            path: `/api/Download`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Health
     * @name HealthGet
     * @summary Retrieve health check information.
     * @request GET:/api/health
     */
    healthGet = (
        query?: {
            /**
             * Skip package information.
             * @default true
             */
            skipPackageInformation?: boolean;
            /**
             * Skip redis connectivity.
             * @default true
             */
            skipRedisConnectivity?: boolean;
            /**
             * Skip database connectivity.
             * @default true
             */
            skipDatabaseConnectivity?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<HealthCheckActionResponseDto, any>({
            path: `/api/health`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Manifest
     * @name ManifestGet
     * @summary Get the manifest
     * @request GET:/api/Manifest
     */
    manifestGet = (params: RequestParams = {}) =>
        this.http.request<string, any>({
            path: `/api/Manifest`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
