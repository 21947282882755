import { getChannelAPIPath } from '@treasury/core/http';

DownloadService.$inject = ['$resource', '$q', '$http'];

export default function DownloadService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}download/`;

    return {
        download,
        email,
        getQboSettings,
        getQfxEnabledFlag,
    };

    function download(options) {
        return $http
            .post(resourceUrl, options, { responseType: 'arraybuffer' })
            .then(response => response.data);
    }

    function email(form) {
        return $resource(`${resourceUrl}email`).save(form).$promise;
    }

    function getQboSettings() {
        return $http.get(`${resourceUrl}qboSettings`).then(response => response.data);
    }

    function getQfxEnabledFlag() {
        return $http.get(`${resourceUrl}getQfxEnabledFlag`).then(response => response.data);
    }
}
