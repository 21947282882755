export const reorderArray = (
    optionsArray: any[],
    keyName: string,
    orderedValues: string[] | number[]
): any[] => {
    const result: any[] = [];
    orderedValues.forEach(value => {
        const correspondingValue = optionsArray.find(resultItem => resultItem[keyName] === value);
        result.push(correspondingValue);
    });
    return result;
};
