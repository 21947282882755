import {
    boolean,
    string,
    datePicker,
    money,
    status,
    amountRange,
} from '@treasury/policy/primitives';

export const columns = [
    {
        type: 'checkbox',
        field: 'selected',
    },
    {
        field: 'name',
        label: 'Template Name',
        type: 'command',
        action: 'clickTemplateName',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company Name',
    },
    {
        field: 'secCode',
        label: 'SEC Code',
    },
    {
        field: 'lastUpdated',
        label: 'Last Updated',
    },
    {
        field: 'debitAmount',
        label: 'Debit Amount',
        summary: true,
    },
    {
        field: 'creditAmount',
        label: 'Credit Amount',
        summary: true,
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions: [
            {
                label: 'Delete',
                action: 'delete',
                // eslint-disable-next-line no-unused-vars
                visibleWhen: record => record.getField('status') !== 'Deleted',
            },
            {
                label: 'Clone',
                action: 'clone',
                // eslint-disable-next-line no-unused-vars
                visibleWhen: record => record.getField('status') !== 'Deleted',
            },
            {
                label: 'Initiate',
                action: 'initiate',
                // eslint-disable-next-line no-unused-vars
                visibleWhen: record => record.getField('status') !== 'Deleted',
            },
        ],
    },
];

export const fields = {
    selected: boolean,
    name: string.thatIs.readOnly(),
    achCompanyName: string.thatIs.readOnly(),
    secCode: string.thatIs.readOnly(),
    lastUpdated: datePicker.thatIs.readOnly(),
    debitAmount: money.thatIs.readOnly().with.reducer('sum'),
    creditAmount: money.thatIs.readOnly().with.reducer('sum'),
    status: status.thatIs.readOnly(),
};

export const filters = client => [
    {
        field: 'status',
        fieldType: status.with
            .label('Status')
            .with.options([
                {
                    text: 'All',
                    value: 'All',
                },
                {
                    text: 'Pending Approval',
                    value: 'Pending Approval',
                },
                {
                    text: 'Rejected',
                    value: 'Rejected',
                },
                {
                    text: 'Ready',
                    value: 'Ready',
                },
            ])
            .as.tag('omega-select'),
        value: 'All',
    },
    {
        field: 'achCompanyList',
        fieldType: string.with
            .label('ACH Company Name')
            .and.options({
                fetch: () => client.fetchAchCompanies(),
                text: record => record.companyName,
                value: record => record,
            })
            .with.hashFunction(item => item.id)
            .and.multipleValues()
            .as.tag('omega-select'),
    },
    {
        label: 'Template Name',
        field: 'batchName',
        fieldType: string.with.label('Template Name').as.tag('omega-input'),
        value: null,
    },
    {
        field: 'secCode',
        fieldType: string.with
            .label('SEC Code')
            .and.options({
                fetch: async () => {
                    const secCodes = await client.fetchSecCodes();
                    return ['All', ...secCodes];
                },
                text: record => record,
                value: record => record,
            })
            .and.defaultValue('All')
            .as.tag('omega-select'),
    },
    // requires type for amounts (debitAmountType: Range or 'Specific Amount', creditAmountType: Range or 'Specific Amount')
    // on range, we require creditAmountMax, creditAmountMin, debitAmountMax,DebitAmountMin
    // updated date type: 'Specific Date', 'Range'
    // on date range: LastUpdatedStart, LastUpdatedEnd
    // when specific date: updatedDate
    {
        label: 'Debit Amount',
        field: 'debitAmount',
        fieldType: amountRange.with
            .label('Debit Amount')
            .and.defaultValue(['range', 0, 0])
            .as.tag('omega-range'),
    },
    {
        label: 'Credit Amount',
        field: 'creditAmount',
        fieldType: amountRange.with
            .label('Credit Amount')
            .and.defaultValue(['range', 0, 0])
            .as.tag('omega-range'),
    },
    {
        label: 'Last Updated',
        field: 'updatedDate',
        fieldType: datePicker.with
            .label('Last Updated')
            .and.options(['specific', 'range'])
            .with.defaultValue('')
            .as.tag('omega-datepicker'),
    },
];
