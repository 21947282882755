BillPayController.$inject = ['$rootScope', 'billPayService'];

export default function BillPayController($rootScope, billPayService) {
    if (angular.element('form#billPayForm')[0].target.length > 0) {
        loadIFrame();
    } else {
        billPayService.getBillPayUrl().then(response => {
            if (response) {
                angular.element('form#billPayForm')[0].action = response.url;
                angular.element('form#billPayForm')[0].target = 'billPayIframe';
                angular.element('form#billPayForm #p1')[0].value = response.sessionId;
                loadIFrame();
            } else {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText: 'Unable to open Bill Pay.',
                    }
                );
            }
        });
    }

    function loadIFrame() {
        $rootScope.$broadcast('retain_loader');
        angular.element('#billPayForm').submit();
        angular.element('#billPayIframe')[0].addEventListener(
            'load',
            () => {
                $rootScope.$broadcast('release_loader');
            },
            false
        );
    }
}
