CentrixSsoController.$inject = ['$scope', 'centrixSsoService', '$sce'];

export default function CentrixSsoController($scope, centrixSsoService, $sce) {
    (function () {
        // init
        centrixSsoService.getCentrixSsoLogin().then(response => {
            if (response) {
                $scope.url = $sce.trustAsResourceUrl(response.ssoLoginUrl);
            } else {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText:
                            'We were unable to complete your request at this time. Please contact your financial institution for additional information.',
                    }
                );
            }
        });
    })();
}
