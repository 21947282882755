import { IssuedItemLegacyDto } from '@treasury/domain/channel/types';
import { html } from 'lit';

/**
 * Renders HTMl for the audit text associated with an issued item.
 */
export function renderAuditText(auditItems: IssuedItemLegacyDto['auditItems']) {
    if (auditItems.length < 1) {
        return html`<strong>Not available.</strong>`;
    }

    return auditItems
        .map(item => {
            const date = new Date(item.createdFiDateTime);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            return `${formattedDate} ${formattedTime} ${item.createdByUserName} ${item.action}`;
        })
        .map(msg => html`<span>${msg}</span><br />`);
}
