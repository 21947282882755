/* eslint-disable no-unused-vars */
import { downloadBlob, getFileExtension } from '../../../utilities/file-handling.js';
import { mapPaymentHeaderAndRecipientsRecordset } from '../../mappings/ach/ach-domestic-payments.js';
import {
    mapFilterRecordToTemplatesRequest,
    mapPaymentHeaderAndRecipients,
    mapPaymentHeaderAndRecipientsRecordsetToTemplate,
    mapPaymentResponseDto,
    mapTemplateResponseDto,
} from '../../mappings/ach/create-ach-child-support-payment.js';
import AchChildSupportRequests from '../../requests/ach/ach-child-support-requests.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchDomesticRequests from '../../requests/ach/ach-domestic-requests.js';

export default class AchChildSupportServices {
    static async getPaymentById(paymentId) {
        const response = await AchChildSupportRequests.getChildSupportPayment(paymentId);
        return mapPaymentResponseDto(response);
    }

    static async getAchCompanies() {
        const companies = await AchCompanyRequests.getAchCompanies();
        return companies.filter(
            company =>
                company.batchBalanceRequirements === 'Unbalanced - Full Offset' &&
                company.secCodes.some(code => code.code === 'CCD') &&
                company.notOnUsTransactionTypes === 'Credit Only'
        );
    }

    static async getAchBanks() {
        return AchCompanyRequests.getAchBanks();
    }

    static async createPayment(paymentHeaderRecord, recipientsRecordset) {
        const body = mapPaymentHeaderAndRecipients(paymentHeaderRecord, recipientsRecordset);
        return AchChildSupportRequests.createPayment({ ...body, id: 0 });
    }

    static async editPayment(paymentHeaderRecord, recipientsRecordset) {
        const body = mapPaymentHeaderAndRecipients(paymentHeaderRecord, recipientsRecordset);
        return AchChildSupportRequests.editPayment(body);
    }

    static async createPaymentFromNachaUpload(paymentHeaderRecord, recipientRecordset) {
        const body = mapPaymentHeaderAndRecipientsRecordset(
            paymentHeaderRecord,
            recipientRecordset
        );
        return AchDomesticRequests.createPayment(body);
    }

    static async uploadNachaFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return AchChildSupportRequests.uploadChildSupportFile(formData);
    }

    /**
     *
     * TEMPLATES
     */
    static async saveTemplate(paymentHeaderRecord, recipientsRecordset) {
        const body = mapPaymentHeaderAndRecipientsRecordsetToTemplate(
            paymentHeaderRecord,
            recipientsRecordset
        );
        return AchChildSupportRequests.createTemplate(body);
    }

    static async updateTemplate(paymentHeaderRecord, recipientsRecordset) {
        const body = mapPaymentHeaderAndRecipientsRecordsetToTemplate(
            paymentHeaderRecord,
            recipientsRecordset
        );
        return AchChildSupportRequests.updateTemplate(body);
    }

    static async getPaymentTemplates(body) {
        const mappedBody = mapFilterRecordToTemplatesRequest(body);
        const response = await AchChildSupportRequests.fetchPaymentTemplates(mappedBody);
        if (response.successLevel === 2) {
            const message = response.responseDetailCollection
                .map(m => `code: ${m.responseCode}: ${m.responseMessage}`)
                .join('\n');
            throw new Error(message);
        }
        let count;
        if (response.templates) {
            count = response.templates.length;
        } else {
            count = 0;
        }
        return { data: response.templates ?? [], totalCount: count };
    }

    static async getPaymentTemplate(guid) {
        const response = await AchChildSupportRequests.fetchPaymentTemplate(guid);
        const achCompanies = await AchCompanyRequests.getAchCompanies();
        const company = achCompanies.find(c => c.id === response.achCompanyId);
        return mapTemplateResponseDto(response, company);
    }

    static async deleteTemplate(guid) {
        return AchChildSupportRequests.deleteTemplate(guid);
    }

    static async isUniqueTemplateName(name) {
        return AchDomesticRequests.isTemplateNameValid(name);
    }

    static async downloadChildSupportTemplate(guid, outputType) {
        const response = await AchChildSupportRequests.downloadChildSupportTemplate(
            guid,
            outputType
        );
        const type = outputType === 'PDF' ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { type });
        const fileExtension = getFileExtension(outputType);
        const filename = `Child Support Template.${fileExtension}`;
        return downloadBlob(blob, filename);
    }

    static async downloadChildSupportTemplates(filterModel, outputType) {
        const mappedBody = mapFilterRecordToTemplatesRequest({ parameters: filterModel });
        const response = await AchChildSupportRequests.downloadChildSupportTemplates(
            mappedBody,
            outputType
        );
        const type = outputType === 'PDF' ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { type });
        const fileExtension = getFileExtension(outputType);
        const filename = `Child Support Templates.${fileExtension}`;
        return downloadBlob(blob, filename);
    }
}
