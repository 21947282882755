import { FrequencyType } from '@treasury/domain/channel/types/frequency';
import { isValidDate } from '../../utils/frequency';

export const endOnIsRequired = {
    name: 'End On Date is a required field',
    validate: (val: any) => {
        if (val.type === FrequencyType.OneTime) {
            return true;
        }

        if (!val.startOn || val.noEndDate) {
            return true;
        }

        return isValidDate(val.endOn);
    },
};
