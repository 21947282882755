export default () => ({
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {
        controller: '=',
        collapsible: '=',
    },
    template: require('./sidebarContainerTemplate.html'),
    controller: SidebarContainerController,
});

SidebarContainerController.$inject = ['$scope'];

function SidebarContainerController($scope) {
    $scope.panels = [];
    $scope.collapsed = true;

    this.addPanel = addPanel;

    function addPanel(panelScope) {
        $scope.panels.push(panelScope);
    }

    $scope.controller = {
        isCollapsed,
        toggleCollapse,
        setCollapsed,
    };

    function toggleCollapse() {
        $scope.collapsed = !$scope.collapsed;
    }

    function isCollapsed() {
        return $scope.collapsed;
    }

    function setCollapsed(value) {
        $scope.collapsed = value;
    }
}
