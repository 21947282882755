TermsAndConditionsAcceptController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'pagePushService',
    'termsAndConditionsService',
    '$sanitize',
    'modalService',
    'activityService',
    'resourceType',
    'auditCode',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function TermsAndConditionsAcceptController(
    $scope,
    $state,
    $stateParams,
    pagePushService,
    termsAndConditionsService,
    $sanitize,
    modalService,
    activityService,
    resourceType,
    auditCode
) {
    $scope.termsAndConditions = $stateParams.termsAndConditions;
    $scope.downloadOptions = ['PDF'];

    $scope.accept = accept;
    $scope.agrees = agrees;
    $scope.cancel = cancel;
    $scope.download = download;

    init();

    function download() {}

    function accept() {
        termsAndConditionsService.accept().then(response => {
            $state.go('dashboard');
        });
    }

    function cancel() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText:
                'If you do not accept the Terms and Conditions, you will not be able to proceed and your session will be terminated.',
            submit(result) {
                $modalInstance.close();
                activityService.userInteractionAudit(
                    resourceType.AccountResources,
                    auditCode.DeclineTermsAndConditions
                );
                $state.go('login');
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function init() {
        if (!$scope.termsAndConditions || !$scope.termsAndConditions.text) {
            termsAndConditionsService.get().then(response => {
                $scope.termsAndConditions = response;
                $scope.termsAndConditions.html = displayTermsAndConditions();
                $scope.termsAndConditions.agrees = false;
            });
        } else {
            $scope.termsAndConditions.html = displayTermsAndConditions();
            $scope.termsAndConditions.agrees = false;
        }
    }

    function agrees() {
        return !!$scope.termsAndConditions && !!$scope.termsAndConditions.agrees;
    }

    function displayTermsAndConditions() {
        const txt = document.createElement('textarea');
        txt.innerHTML = $scope.termsAndConditions.text;
        return txt.value;
    }

    $scope.$on('$locationChangeStart', event => {
        if (!$scope.termsAndConditions.agrees) {
            event.preventDefault();
        }
    });
}
