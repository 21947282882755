TaxTemplateDetailController.$inject = [
    '$scope',
    '$controller',
    '$state',
    '$uibModal',
    'achPaymentTypes',
    'taxesService',
    'statesService',
    'scopeService',
];

export default function TaxTemplateDetailController(
    $scope,
    $controller,
    $state,
    $uibModal,
    achPaymentTypes,
    taxesService,
    statesService,
    scopeService
) {
    angular.extend(this, $controller('BatchDetailController', { $scope }));

    const maximumNumberOfAmounts = 3;
    const checkingAccountTypeId = 1;
    const firstStateAmountTypeId = 1;

    $scope.stateTaxCodes = [];
    $scope.federalTaxAmountTypes = [];
    $scope.batch.recipientAccountTypeId = checkingAccountTypeId;

    $scope.isObject = isObject;
    $scope.findStateTaxCodes = findStateTaxCodes;
    $scope.findFederalTaxCodes = findFederalTaxCodes;
    $scope.stateTaxCodesLoaded = stateTaxCodesLoaded;
    $scope.federalTaxCodesLoaded = federalTaxCodesLoaded;
    $scope.stateTaxCodeChanged = stateTaxCodeChanged;
    $scope.federalTaxCodeChanged = federalTaxCodeChanged;
    $scope.addStateAmountType = addStateAmountType;
    $scope.deleteStateAmountType = deleteStateAmountType;
    $scope.addFederalAmountType = addFederalAmountType;
    $scope.deleteFederalAmountType = deleteFederalAmountType;
    $scope.onSelectBank = onSelectBank;
    $scope.isValidTaxCode = isValidTaxCode;
    $scope.getStateAmountTypeDescription = getStateAmountTypeDescription;
    $scope.getFederalAmountTypeDescription = getFederalAmountTypeDescription;
    $scope.getRecipientAccountTypeDescription = getRecipientAccountTypeDescription;
    $scope.getTaxCodeDescription = getTaxCodeDescription;
    $scope.goToTaxTemplateList = goToTaxTemplateList;
    $scope.federalAmountTypeChanged = federalAmountTypeChanged;
    $scope.isStateTaxTemplate = isStateTaxTemplate;
    $scope.isFederalTaxTemplate = isFederalTaxTemplate;
    $scope.isFederalWithNoAmountTypes = isFederalWithNoAmountTypes;
    $scope.getStateLongName = getStateLongName;
    $scope.federalAmountTypesLoaded = federalAmountTypesLoaded;
    $scope.canDeleteStateAmount = canDeleteStateAmount;
    $scope.canDeleteFederalAmount = canDeleteFederalAmount;
    $scope.canAddStateAmount = canAddStateAmount;
    $scope.canAddFederalAmount = canAddFederalAmount;

    function canAddStateAmount(index) {
        return index === $scope.batch.achStateTaxPaymentAmounts.length - 1 && index !== 2;
    }

    function canAddFederalAmount(index) {
        return index === $scope.batch.achFederalTaxPaymentAmounts.length - 1 && index !== 2;
    }

    function canDeleteStateAmount(index) {
        return (
            index !== $scope.batch.achStateTaxPaymentAmounts.length &&
            $scope.batch.achStateTaxPaymentAmounts.length !== 1
        );
    }

    function canDeleteFederalAmount(index) {
        return (
            index !== $scope.batch.achFederalTaxPaymentAmounts.length &&
            $scope.batch.achFederalTaxPaymentAmounts.length !== 1
        );
    }

    function federalAmountTypesLoaded() {
        return $scope.federalTaxAmountTypes && $scope.federalTaxAmountTypes.length;
    }

    function getStateLongName(id) {
        if (!statesLoaded()) return '';

        const selectedState = $scope.states.find(item => item.id === id);

        return selectedState ? selectedState.value : '';
    }

    function goToTaxTemplateList() {
        $state.go('payables.ach.payments.tax-templates');
    }

    function isStateTaxTemplate() {
        return $scope.batch && $scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment;
    }

    function isFederalTaxTemplate() {
        return (
            $scope.batch && $scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment
        );
    }

    function isFederalWithNoAmountTypes() {
        return (
            $scope.batch &&
            $scope.batch.achFederalTaxPaymentAmounts &&
            $scope.batch.achFederalTaxPaymentAmounts.length === 1 &&
            $scope.batch.achFederalTaxPaymentAmounts[0].amountType === $scope.batch.taxCode
        );
    }

    function federalAmountTypeChanged(index) {
        const { amountType } = $scope.batch.achFederalTaxPaymentAmounts[index];

        if (federalAmountTypesLoaded()) {
            $scope.batch.achFederalTaxPaymentAmounts[index].achFederalAmountTypeId =
                taxesService.getFederalAmountType(
                    $scope.federalTaxAmountTypes,
                    amountType
                ).achFederalAmountTypeId;
        }
    }

    function onSelectBank(bank) {
        if (bank) {
            $scope.batch.recipientRoutingNumber = bank.bankId;
        }
    }

    function getTaxCodeDescription() {
        let taxCode;

        if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            if ($scope.federalTaxCodes) {
                taxCode = findTaxCodeObject($scope.batch.taxCode);
            }
        } else if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            if ($scope.stateTaxCodes) {
                taxCode = $scope.stateTaxCodes.find(
                    stateTaxCode => stateTaxCode.taxCode === $scope.batch.taxCode
                );
            }
        }

        if (taxCode) {
            return taxCode.description;
        }

        return '';
    }

    function getStateAmountTypeDescription(achStateAmountTypeId) {
        return taxesService.getStateAmountTypeDescription(
            $scope.stateTaxAmountTypes,
            achStateAmountTypeId
        );
    }

    function getFederalAmountTypeDescription(amountType) {
        return taxesService.getFederalAmountTypeDescription(
            $scope.federalTaxAmountTypes,
            amountType
        );
    }

    function getRecipientAccountTypeDescription(recipientAccountTypeId) {
        return taxesService.getRecipientAccountTypeDescription(
            $scope.recipientAccountTypes,
            recipientAccountTypeId
        );
    }

    function addStateAmountType() {
        if ($scope.batch.achStateTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        $scope.batch.achStateTaxPaymentAmounts.push({
            achStateAmountTypeId: firstStateAmountTypeId,
            amount: 0,
        });
    }

    function deleteStateAmountType(index) {
        $scope.batch.achStateTaxPaymentAmounts.splice(index, 1);
    }

    function addFederalAmountType() {
        if ($scope.batch.achFederalTaxPaymentAmounts.length === maximumNumberOfAmounts) {
            return;
        }
        $scope.batch.achFederalTaxPaymentAmounts.push({
            achFederalAmountTypeId: $scope.federalTaxAmountTypes[0].achFederalAmountTypeId,
            amountType: $scope.federalTaxAmountTypes[0].amountType,
            amount: 0,
        });
    }

    function deleteFederalAmountType(index) {
        $scope.batch.achFederalTaxPaymentAmounts.splice(index, 1);
    }

    function federalTaxCodeChanged(federalTaxCode) {
        if (!federalTaxCodesLoaded()) return;

        if (federalTaxCode && federalTaxCode.taxCode) {
            if (isSelectedTaxCode(federalTaxCode)) {
                return;
            }

            $scope.batch.selectedFederalTaxCode = federalTaxCode;
            $scope.batch.taxCode = federalTaxCode.taxCode;

            getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode);
            return;
        }

        if (taxesService.isPatternMatchForTaxCode(federalTaxCode)) {
            const selectedTaxCode = findTaxCodeObject(federalTaxCode);

            if (selectedTaxCode) {
                $scope.batch.selectedFederalTaxCode = selectedTaxCode;
                $scope.batch.taxCode = federalTaxCode.taxCode;

                getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode);
                return;
            }

            $scope.batch.taxCode = federalTaxCode;
            $scope.batch.selectedFederalTaxCode = federalTaxCode;
        } else {
            $scope.batch.taxCode = '';
        }

        if ($scope.federalTaxAmountTypes) $scope.federalTaxAmountTypes.length = 0;
    }

    function isSelectedTaxCode(federalTaxCode) {
        return (
            $scope.batch.selectedFederalTaxCode &&
            $scope.batch.selectedFederalTaxCode.taxCode === federalTaxCode.taxCode &&
            $scope.batch.taxCode === federalTaxCode.taxCode
        );
    }

    function stateTaxCodeChanged(stateTaxCode) {
        if (stateTaxCode && stateTaxCode.taxCode) {
            $scope.batch.selectedStateTaxCode = stateTaxCode;
            $scope.batch.taxCode = stateTaxCode.taxCode;
            return;
        }

        if (taxesService.isPatternMatchForTaxCode(stateTaxCode)) {
            $scope.batch.taxCode = stateTaxCode;
            $scope.batch.selectedStateTaxCode = stateTaxCode;
        } else {
            $scope.batch.taxCode = '';
            $scope.batch.selectedStateTaxCode = '';
        }
    }

    function getStateTaxCodes(stateId, taxCode) {
        return taxesService.getStateTaxCodes(stateId).then(response => {
            $scope.stateTaxCodes = response;

            stateTaxCodeChanged(taxCode);
        });
    }

    function findTaxCodeObject(taxCode) {
        return $scope.federalTaxCodes.find(item => item.taxCode === taxCode);
    }

    function getFederalTaxCodes() {
        taxesService.getFederalTaxCodes().then(response => {
            $scope.federalTaxCodes = response;

            if ($scope.batch.taxCode) {
                const selectedTaxCode = findTaxCodeObject($scope.batch.taxCode);

                $scope.batch.selectedFederalTaxCode = selectedTaxCode;

                if (selectedTaxCode) {
                    taxesService.getFederalTaxAmountTypes(selectedTaxCode.id).then(response => {
                        $scope.federalTaxAmountTypes = response;
                    });
                }
            }
        });
    }

    function getStateTaxAmountTypes() {
        taxesService.getStateTaxAmountTypes().then(response => {
            $scope.stateTaxAmountTypes = response;
        });
    }

    function getFederalTaxAmountTypes(selectedFederalTaxCode) {
        taxesService.getFederalTaxAmountTypes(selectedFederalTaxCode.id).then(response => {
            $scope.federalTaxAmountTypes = response;
            initFederalTaxPaymentAmounts(selectedFederalTaxCode);
        });
    }

    function initFederalTaxPaymentAmounts(selectedFederalTaxCode) {
        if (federalAmountTypesLoaded())
            $scope.batch.achFederalTaxPaymentAmounts = [
                {
                    achFederalAmountTypeId: $scope.federalTaxAmountTypes[0].achFederalAmountTypeId,
                    amountType: $scope.federalTaxAmountTypes[0].amountType,
                    amount: 0,
                },
            ];
        else
            $scope.batch.achFederalTaxPaymentAmounts = [
                {
                    achFederalAmountTypeId: null,
                    amountType: selectedFederalTaxCode.taxCode,
                    amount: 0,
                },
            ];
    }

    function getRecipientAccountTypes() {
        taxesService.getRecipientAccountTypes().then(response => {
            $scope.recipientAccountTypes = response;
        });
    }

    function findStateTaxCodes() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.stateTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedStateTaxCode => {
            $scope.batch.selectedStateTaxCode = angular.copy(selectedStateTaxCode);
        });
    }

    function findFederalTaxCodes() {
        const modalInstance = $uibModal.open({
            template: require('../../taxes/views/taxCodesModalView.html'),
            size: 'md',
            controller: 'TaxCodesController',
            backdrop: 'static',
            resolve: {
                taxCodes() {
                    return $scope.federalTaxCodes;
                },
            },
        });
        modalInstance.result.then(selectedFederalTaxCode => {
            $scope.batch.selectedFederalTaxCode = angular.copy(selectedFederalTaxCode);
        });
    }

    function isObject(value) {
        return angular.isObject(value);
    }

    function isValidTaxCode(value) {
        return taxesService.isValidTaxCode(value);
    }

    function stateTaxCodesLoaded() {
        return $scope.stateTaxCodes && $scope.stateTaxCodes.length;
    }

    function federalTaxCodesLoaded() {
        return $scope.federalTaxCodes && $scope.federalTaxCodes.length;
    }

    function whenDefined(expression, callback) {
        scopeService.whenDefined(expression, callback, $scope);
    }

    function getStates() {
        statesService.getAll().then(response => {
            $scope.states = response;
        });
    }

    function statesLoaded() {
        return $scope.states && $scope.states.length;
    }

    function setState(stateId, taxCode) {
        $scope.batch.stateId = stateId;

        whenDefined('states', () => {
            $scope.batch.selectedState = $scope.states.find(state => stateId === state.id);

            if ($scope.batch.selectedState) {
                getStateTaxCodes(stateId, taxCode);
            }
        });
    }

    function setFederalAmountTypes(taxCode, achFederalTaxPaymentAmounts) {
        whenDefined('federalTaxCodes', () => {
            if (!taxCode) {
                return;
            }

            $scope.batch.selectedFederalTaxCode = $scope.federalTaxCodes.find(
                item => item.taxCode === taxCode
            );

            if ($scope.batch.selectedFederalTaxCode) {
                taxesService
                    .getFederalTaxAmountTypes($scope.batch.selectedFederalTaxCode.id)
                    .then(response => {
                        $scope.federalTaxAmountTypes = response;
                        if (achFederalTaxPaymentAmounts && achFederalTaxPaymentAmounts.length) {
                            $scope.batch.achFederalTaxPaymentAmounts = achFederalTaxPaymentAmounts;
                        } else {
                            initFederalTaxPaymentAmounts($scope.batch.selectedFederalTaxCode);
                        }
                    });
            }
        });
    }

    function setStateAmounts(achStateTaxPaymentAmounts) {
        whenDefined('stateTaxAmountTypes', () => {
            if (achStateTaxPaymentAmounts && achStateTaxPaymentAmounts.length) {
                $scope.batch.achStateTaxPaymentAmounts = [];

                achStateTaxPaymentAmounts.forEach(item => {
                    $scope.batch.achStateTaxPaymentAmounts.push({
                        achStateAmountTypeId: item.achStateAmountTypeId,
                        amount: item.amount,
                    });
                });
            } else {
                $scope.batch.achStateTaxPaymentAmounts = [
                    { achStateAmountTypeId: firstStateAmountTypeId, amount: 0 },
                ];
            }
        });
    }

    (function () {
        // init
        getStates();
        whenDefined('batch.id', () => {
            setState($scope.batch.stateId, $scope.batch.taxCode);
            setFederalAmountTypes($scope.batch.taxCode, $scope.batch.achFederalTaxPaymentAmounts);
            setStateAmounts($scope.batch.achStateTaxPaymentAmounts);
            getRecipientAccountTypes();

            if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
                getFederalTaxCodes();
            }

            if ($scope.batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
                getStateTaxAmountTypes();
            }
        });
    })();
}
