// eslint-disable-next-line import/no-extraneous-dependencies
import { TransferActivityRequests } from '@treasury/domain/channel/requests/transfers/transfer-activity-requests.js';
import { boolean, string, datePicker, amountRange } from '../primitives';

export const transferSelected = boolean;

export const transferDateFilter = datePicker.with.range().and.label('Transfer Date');

const statusOptions = [
    { value: 'Pending Approval', key: 'PendingApproval' },
    { value: 'Scheduled', key: 'Scheduled' },
    { value: 'Submitted', key: 'Submitted' },
    {
        value: 'Approval Rejected',
        key: 'ApprovalRejected',
    },
    { value: 'Cancelled', key: 'Cancelled' },
    { value: 'Failed', key: 'Failed' },
    { value: 'Expired', key: 'Expired' },
];

export const transferStatusFilter = string.with
    .options({
        data: statusOptions,
        text: 'value',
        value: v => v,
    })
    .and.multipleValues()
    .and.defaultValue(statusOptions);

export const transferAccountTypeFilter = string.with.options([
    { text: 'Both', value: 'Both' },
    { text: 'From', value: 'From' },
    { text: 'To', value: 'To' },
]);

export const transferAccountFilter = string.with
    .options({
        fetch: TransferActivityRequests.fetchTransferAccounts,
        text: 'accountDisplayLabel',
        value: record => record,
    })
    .and.filtering()
    .and.multipleValues();

export const transferAmountFilter = amountRange.with.schema('range').and.multipleValues();

export const transferCreatedDateFilter = datePicker.with.range().and.defaultValue('');
