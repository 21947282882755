/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchNoticeOfChangeFilterModelDto,
    AchNotificationOfChangeDetailModelDto,
    AchNotificationOfChangeReportModelDto,
    AchNotificationOfChangeReportRequestModelDto,
    ErrorHttpContentDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchNotificationOfChangeClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchNotificationOfChange
     * @name AchNotificationOfChangeSearchAchNotificationOfChange2
     * @summary Searches for ACH notification of change records.
     * @request POST:/AchNotificationOfChange/Search
     */
    achNotificationOfChangeSearchAchNotificationOfChange2 = (
        notificationOfChangeFilter: AchNoticeOfChangeFilterModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchNotificationOfChangeReportModelDto, ErrorHttpContentDto>({
            path: `/AchNotificationOfChange/Search`,
            method: 'POST',
            body: notificationOfChangeFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchNotificationOfChange
     * @name AchNotificationOfChangeGetNocDetail
     * @summary Gets the details of an ACH notification of change record.
     * @request GET:/AchNotificationOfChange/{achNotificationOfChangeId}/Detail
     */
    achNotificationOfChangeGetNocDetail = (
        achNotificationOfChangeId: string,
        params: RequestParams = {}
    ) =>
        this.http.request<AchNotificationOfChangeDetailModelDto, ErrorHttpContentDto>({
            path: `/AchNotificationOfChange/${achNotificationOfChangeId}/Detail`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchNotificationOfChange
     * @name AchNotificationOfChangeNachaDownload
     * @summary Creates a NACHA file for an ACH notification of change record.
     * @request POST:/AchNotificationOfChange/{achNotificationOfChangeId}/Nacha
     */
    achNotificationOfChangeNachaDownload = (
        achNotificationOfChangeId: string,
        params: RequestParams = {}
    ) =>
        this.http.request<string, ErrorHttpContentDto>({
            path: `/AchNotificationOfChange/${achNotificationOfChangeId}/Nacha`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchNotificationOfChange
     * @name AchNotificationOfChangeDownloadDetails
     * @summary Downloads an ACH notification of change record as a NACHA file
     * @request POST:/AchNotificationOfChange/{achNotificationOfChangeId}/Download
     */
    achNotificationOfChangeDownloadDetails = (
        achNotificationOfChangeId: string,
        notificationOfChangeReportRequestModel: AchNotificationOfChangeReportRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<string, ErrorHttpContentDto>({
            path: `/AchNotificationOfChange/${achNotificationOfChangeId}/Download`,
            method: 'POST',
            body: notificationOfChangeReportRequestModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
