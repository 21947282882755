/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Attempts to retrieve the core Angular injector service from outside
 * of the application it's used in.
 *
 * Useful for Lit element conversions which don't have access to Angular DI.
 *
 * @returns The injector service associated with the root element.
 */
export function getAngularInjector(rootElem: Element = document.documentElement) {
    // @ts-ignore
    if (!angular) {
        throw new Error('Global Angular reference required to retrieve injector.');
    }

    return new Promise<ng.auto.IInjectorService>((resolve, reject) => {
        // @ts-ignore
        angular.element(() => {
            // @ts-ignore
            const injector = angular.element(rootElem).injector();

            if (!injector) {
                reject(new Error('Could not get Angular injector'));
            }

            resolve(injector);
        });
    });
}
