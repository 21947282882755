export default {
    name: 'alphanumeric',
    validate(value: string | undefined | null) {
        /**
         * @memberof alphanumeric
         * @method validate
         * @param {*} value
         * @returns true if string only contains ASCII between
         *          char 30 and 39 or between char 41 and 5A
         *          or between char 61 and 7A
         */
        if (!value) return true;
        if (Array.isArray(value))
            return value.every((val: string) =>
                val
                    .split(/\s+/)
                    .filter(part => part.trim())
                    .every(v => v.match(/^[a-z0-9]+$/i))
            );
        if (typeof value !== 'string') {
            throw new Error('alphanumeric validator did not receive string type value');
        }
        return value
            .split(/\s+/)
            .filter(part => part.trim())
            .every(v => v.match(/^[a-z0-9]+$/i));
    },
};
