// cSpell:ignore Signout, securityquestions, verifysecurityquestions, updatepassword

import { getChannelAPIPath } from '@treasury/core/http';
import { AccountService as AccountClient } from '@treasury/domain/channel/services/account/account.service.ts';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { openIdentityDialog } from '@treasury/omega/domain-components/identity-verification-dialog';
import { StatusCode } from '../../src/login/data/login-phases.js';

AccountService.$inject = [
    '$resource',
    '$http',
    '$state',
    'termsAndConditionsService',
    '$q',
    'localStorageService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function AccountService(
    $resource,
    $http,
    $state,
    termsAndConditionsService,
    $q,
    localStorageService
) {
    const resourceUrl = `${getChannelAPIPath()}account/`;
    let settings = null;

    return {
        getCurrentUser,
        updateCurrentUser,
        forgotPassword,
        getSettings,
        validateUser,
        validateUserForPasswordReset,
        checkForError,
        saveSecurityQuestions,
        verifySecurityQuestions,
        updatePassword,
        changePassword,
        getPasswordRequirements,
        checkLoginCompletion,
        checkTermsAndConditions,
        setAccountDisplayField,
        goToNextStep,
        setAuth,
    };

    function getCurrentUser() {
        AccountClient.getInstance().then(service => service.getCurrentUserAccountSettings());
    }

    function updateCurrentUser(user) {
        AccountClient.getInstance().then(service => service.updateCurrentUserAccountSettings(user));
    }

    function forgotPassword(userPayload) {
        return $http
            .post(`${resourceUrl}forgotpassword`, userPayload)
            .then(response => response.data);
    }

    function getSettings() {
        const deferred = $q.defer();
        if (settings) {
            deferred.resolve(settings);
        } else {
            AccountClient.getInstance().then(service => {
                service.getUserSettings().then(response => {
                    settings = response;
                    deferred.resolve(settings);
                });
            });
        }
        return deferred.promise;
    }

    function setAccountDisplayField(field) {
        if (settings) {
            settings.accountDisplayField = field;
        }
    }

    /* This is deprecated for resets of an existing users password */
    function validateUser(newUserPayload, verifyUser) {
        if (verifyUser) {
            return $http
                .put(`${resourceUrl}verifyuser`, newUserPayload)
                .then(response => response.data);
        }
        return $http.post(`${resourceUrl}login`, newUserPayload).then(response => response.data);
    }

    /* Use this function instead of validate existing users password */
    function validateUserForPasswordReset(newUserPayload, verifyUser) {
        if (verifyUser) {
            return $http
                .put(`${resourceUrl}VerifyUserForPasswordReset`, newUserPayload)
                .then(response => response.data);
        }
    }

    function checkForError(validateUserResponse) {
        if (validateUserResponse.statusCode === 'Block') {
            return validateUserResponse.message;
        }
        if (validateUserResponse.statusCode === 'Invalid') {
            return validateUserResponse.message;
        }
        if (validateUserResponse.statusCode === 'Locked') {
            $state.go('locked-out');
            return true;
        }
        return '';
    }

    function saveSecurityQuestions(securityQuestionsPayload) {
        return $http
            .post(`${resourceUrl}securityquestions`, securityQuestionsPayload)
            .then(response => response.data);
    }

    function verifySecurityQuestions(userQuestions) {
        return $http
            .put(`${resourceUrl}verifysecurityquestions`, userQuestions)
            .then(response => response.data);
    }

    function updatePassword(newUserPayload) {
        return $http
            .put(`${resourceUrl}updatepassword`, newUserPayload)
            .then(response => response.data);
    }

    function changePassword(user) {
        return $resource(`${resourceUrl}changePassword`).save(user).$promise;
    }

    function getPasswordRequirements(fiId) {
        return $resource(`${resourceUrl}passwordrequirements?fiId=:fiId`, { fiId }).query()
            .$promise;
    }

    async function checkLoginCompletion(callback) {
        const response = await $resource(`${resourceUrl}checkLoginCompletion`).get().$promise;
        if (response.isComplete) {
            return response;
        }

        if (response.action === 'Challenge') {
            const securityMessage = {
                challengeMethodTypeId: response.challengeMethodType,
                errorCode: 'Verify',
            };
            const dialogPromise = openIdentityDialog(securityMessage, {
                forLogin: true,
                disableClose: true,
            });

            if (callback) {
                dialogPromise.then(callback);
            }

            return response;
        }

        const getChallengeTypeRoute = () => {
            switch (response.challengeMethodType) {
                case 1:
                    return StatusCode.OobLegacy;
                case 2:
                    return StatusCode.SecureToken;
                case 3:
                    return StatusCode.OobOtp;
                default:
                    throw new Error(`Unknown challengeMethodType: ${response.challengeMethodType}`);
            }
        };

        //If 2FA/Challenge Point registration is necessary,
        // add it to the top of the queue
        const auth = localStorageService.get('auth');
        localStorageService.set('auth', {
            ...auth,
            steps: [getChallengeTypeRoute(), ...auth.steps],
        });

        return response;
    }

    async function checkTermsAndConditions() {
        const response = await termsAndConditionsService.get(true);
        if (response.text) {
            $state.go('terms-and-conditions-accept', { termsAndConditions: response });
        } else {
            const authService = await AuthenticationService.getInstance();

            await EntitlementsService.instance.reset();
            authService.authenticate();
            $state.go('dashboard');
        }
    }

    function setAuth(options) {
        const { statusCode, daysUntilPasswordExpires } = options;
        const auth = localStorageService.get('auth');

        localStorageService.set('auth', {
            ...auth,
            steps: getSteps(statusCode),
            daysUntilPasswordExpires,
        });
    }

    function getSteps(statusCode) {
        const stringConstants = new Proxy({}, { get: (_, string) => string });

        const {
            Allow,
            Challenge,
            ChallengePromptToResetPassword,
            ChallengeResetExpiredPassword,
            ChallengeResetPassword,
            Enrollment,
            PromptToResetPassword,
            PromptToResetPasswordEnrollment,
            ResetExpiredPassword,
            ResetExpiredPasswordEnrollment,
            ResetPassword,
            ResetPasswordEnrollment,
            TwoFactorChallenge,
        } = stringConstants;

        const stepDict = {
            [Allow]: [TwoFactorChallenge, Allow],
            [Challenge]: [Challenge, TwoFactorChallenge, Allow],
            [ChallengeResetPassword]: [Challenge, TwoFactorChallenge, ResetPassword, Allow],
            [ChallengeResetExpiredPassword]: [
                Challenge,
                TwoFactorChallenge,
                ResetExpiredPassword,
                Allow,
            ],
            [ChallengePromptToResetPassword]: [
                Challenge,
                TwoFactorChallenge,
                PromptToResetPassword,
                Allow,
            ],
            [Enrollment]: [TwoFactorChallenge, Enrollment, Allow],
            [ResetPasswordEnrollment]: [TwoFactorChallenge, ResetPassword, Enrollment, Allow],
            [ResetExpiredPasswordEnrollment]: [
                TwoFactorChallenge,
                ResetExpiredPassword,
                Enrollment,
                Allow,
            ],
            [PromptToResetPasswordEnrollment]: [
                TwoFactorChallenge,
                PromptToResetPassword,
                Enrollment,
                Allow,
            ],
            [ResetPassword]: [TwoFactorChallenge, ResetPassword, Allow],
            [ResetExpiredPassword]: [TwoFactorChallenge, ResetExpiredPassword, Allow],
            [PromptToResetPassword]: [TwoFactorChallenge, PromptToResetPassword, Allow],
        };

        if (!statusCode) {
            throw new Error('Could not generate login steps. No status code provided.');
        }

        if (!(statusCode in stepDict)) {
            throw new Error(
                `Could not generate login steps. Status code "${statusCode}" was not recognized.`
            );
        }

        return stepDict[statusCode];
    }

    async function goToNextStep() {
        const auth = localStorageService.get('auth');
        const { daysUntilPasswordExpires } = auth;
        const statusCode = auth.steps.shift();
        localStorageService.set('auth', {
            ...auth,
            steps: auth.steps,
        });
        if (!statusCode) return false;
        switch (statusCode) {
            case StatusCode.Allow:
                checkTermsAndConditions();
            case StatusCode.TwoFactorChallenge:
                const callback = async () => await goToNextStep();
                const response = await checkLoginCompletion(callback);
                if (response.action === 'Challenge') break;
                await goToNextStep();
                break;

            case StatusCode.Challenge:
                $state.go('answer-security-questions', { nextStep: 'dashboard' });
                break;

            case StatusCode.ChallengeResetPassword:
                $state.go('answer-security-questions', {
                    nextStep: 'changePassword',
                    reason: 'temp',
                });
                break;

            case StatusCode.ChallengeResetExpiredPassword:
                $state.go('answer-security-questions', {
                    nextStep: 'changePassword',
                    reason: 'expired',
                });
                break;

            case StatusCode.ChallengePromptToResetPassword:
                $state.go('answer-security-questions', {
                    nextStep: 'changePassword',
                    reason: 'expiresSoon',
                    daysUntilPasswordExpires,
                });
                break;

            case StatusCode.Enrollment:
                $state.go('create-security-questions');
                break;

            case StatusCode.ResetPasswordEnrollment:
                $state.go('change-password', {
                    nextStep: 'createSecurityQuestions',
                    reason: 'temp',
                });
                break;

            case StatusCode.ResetExpiredPasswordEnrollment:
                $state.go('change-password', {
                    nextStep: 'createSecurityQuestions',
                    reason: 'expired',
                });
                break;

            case StatusCode.PromptToResetPasswordEnrollment:
                $state.go('change-password', {
                    nextStep: 'createSecurityQuestions',
                    reason: 'expiresSoon',
                    daysUntilPasswordExpires,
                });
                break;

            case StatusCode.ResetPassword:
                $state.go('change-password', { reason: 'temp' });
                break;

            case StatusCode.ResetExpiredPassword:
                $state.go('change-password', { reason: 'expired' });
                break;

            case StatusCode.PromptToResetPassword:
                $state.go('change-password', {
                    reason: 'expiresSoon',
                    daysUntilPasswordExpires,
                });
                break;
            case StatusCode.OobLegacy:
                $state.go('verify-security-methods', { challengeMethodType: 1 });
                break;

            case StatusCode.OobOtp:
                $state.go('verify-security-methods', { challengeMethodType: 3 });
                break;

            case StatusCode.SecureToken:
                $state.go('collect-secure-token');
                break;
        }

        return true;
    }
}
