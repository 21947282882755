import { getChannelAPIPath } from '@treasury/core/http';

BillPayService.$inject = ['$resource'];

export default function BillPayService($resource) {
    const resourceUrl = `${getChannelAPIPath()}billpay/`;

    return {
        getBillPayUrl,
    };

    function getBillPayUrl() {
        return $resource(`${resourceUrl}getbillpayurl`).get().$promise;
    }
}
