import { LitElement, html, css } from 'lit';
import { Recordset } from '@treasury/FDL';
import { string, money } from '@treasury/policy/primitives';
import { Record } from '@treasury/FDL/record';

export default class DevSandbox extends LitElement {
    constructor() {
        super();

        this.salesFigures = new Recordset({ name: string, sales: money }, () => [
            { name: 'Jim', sales: 12893.58 },
            { name: 'Pam', sales: 14579.03 },
            { name: 'Dwight', sales: 10000000000000 },
        ]);
        this.salesFigures.requestHardUpdate();

        this.entry = new Record(
            {
                name: string.with.label('Name'),
                sales: money.with.label('Sales'),
            },
            { name: '', sales: 0 }
        );

        this.columnDefinitions = [
            {
                field: 'name',
                label: 'Name',
            },

            {
                field: 'sales',
                label: 'Sales',
            },
        ];
    }

    addEntryToSalesFigures() {
        this.salesFigures.insertRecord(this.entry);
    }

    render() {
        return html`
            <h1>Company Self-Reported Sales Figures</h1>
            <omega-table
                .recordset=${this.salesFigures}
                .columnDefinitions=${this.columnDefinitions}
            ></omega-table>
            <form id="entry-form">
                <h2>Your Sales Figures</h2>
                <omega-field .record=${this.entry} field="name"></omega-field>
                <omega-field .record=${this.entry} field="sales"></omega-field>
                <omega-button type="primary" @click=${this.addEntryToSalesFigures}
                    >Add</omega-button
                >
            </form>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #entry-form {
                border: 2px solid black;
                background-color: white;
                padding: 20px;
            }
        `;
    }
}

window.customElements.define('dev-sandbox', DevSandbox);
