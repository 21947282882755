/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping calls associated with Current Day, Prior Day and Date Range Balance Reports.
 */
class BalanceReportsRequests {
    // account filter
    static async fetchAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `companyaccounts/GetForProductFeature?productFeatureType=InformationReporting&reportType=cdb&type=`,
            {
                method: 'GET',
                maxAgeInSeconds: 600,
            }
        );
    }

    // 'sort by' filter
    static async fetchTransactionCodeGroups() {
        return (await TmHttpClient.getInstance()).request(`transactioncodegroup`, {
            method: 'GET',
            maxAgeInSeconds: 600,
        });
    }

    // report results
    static async fetchReportData(body) {
        console.log(body);
        return (await TmHttpClient.getInstance()).request(`generatereport/?type=cdb`, {
            method: 'POST',
            body,
        });
    }
}

export default BalanceReportsRequests;
