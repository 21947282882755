import { FdlRecordEvent, Record } from '@treasury/FDL';
import field from '@treasury/FDL/field';
import fieldType from '@treasury/FDL/field-type';
import { SaveMasterRecipientResponseDto } from '@treasury/api/channel';
import { PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-icon';
import '@treasury/omega/components/omega-input';
import { LitElement, css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { AchDomesticClient } from '../clients/ach-domestic-client';

export class AchPaymentNameField extends ListeningElementMixin(LitElement) {
    @property()
    record!: Record<PaymentRecipient>;

    @property()
    isNacha?: boolean;

    @state()
    fieldModel?: field;

    @state()
    fieldType?: fieldType<string>;

    @state()
    disabled?: boolean;

    @state()
    step = 0;

    @state()
    isSaved = false;

    field: keyof PaymentRecipient = 'name';

    client = new AchDomesticClient();

    firstUpdated() {
        this.fieldModel = this.record.field(this.field);
        this.fieldType = this.record.fieldTypeForField(this.field);
        this.disabled = this.fieldType.isDisabled(this.record);

        this.listenTo(this.record, FdlRecordEvent.Change, (e: CustomEvent) => {
            if (e.detail.field === 'step') this.step = this.record.getField('step');
        });
    }

    handleInputKeyDown(e: KeyboardEvent) {
        this.dispatchEvent(new CustomEvent('keydown', e));
    }

    handleMasterListSaveKeyUp(e: KeyboardEvent) {
        console.log(e);
    }

    async addMasterRecipient(record: Record<PaymentRecipient>) {
        try {
            const response = (await this.client.saveMasterRecipient([
                record.values,
            ])) as SaveMasterRecipientResponseDto;
            if (response.success) {
                this.isSaved = true;
            }
        } catch (e) {
            console.error(e);
        }
    }

    renderInput() {
        return html`<omega-input
            .record=${this.record}
            .field=${this.fieldModel}
            .type=${this.fieldType?.type()}
            .minLength=${this.fieldType?.minLength()}
            .maxLength=${this.fieldType?.maxLength()}
            .validate=${(v: any) => {
                this.fieldModel?.isValidValue(v);
            }}
            ?disabled=${this.disabled}
            .value=${this.record.getField('name') ?? 'hello, world! input'}
            .selectOnFocus=${this.fieldType?.selectOnFocus()}
            .formatOnChange=${this.fieldType?.formatOnChange()}
            @keydown=${this.handleInputKeyDown}
            @blur=${() => this.record.announceBlur(this.field as keyof PaymentRecipient)}
        ></omega-input>`;
    }

    renderMasterListInteraction() {
        const handleSave = this.addMasterRecipient.bind(this, this.record);

        if (this.record.getField('fromMasterList')) return nothing;
        return this.isSaved
            ? html`<omega-icon
                  .icon=${'check'}
                  .description=${'Payment Recipient Saved'}
              ></omega-icon>`
            : html` <span
                  role="button"
                  class="save-recipient-button"
                  @click=${handleSave}
                  @keyup=${this.handleMasterListSaveKeyUp}
                  >SAVE</span
              >`;
    }

    renderValue() {
        if (this.fieldType?.readonly(this.record) || !this.fieldModel)
            return html`<div>${this.record.getField('name') ?? 'hello, world! value'}</div>`;
        return this.renderInput();
    }

    render() {
        if (
            !this.isNacha &&
            this.record.getField('step') === 2 &&
            !this.record.getField('fromMasterList')
        ) {
            return html`<div class="name-value-container">
                ${this.renderValue()}${this.renderMasterListInteraction()}
            </div>`;
        }
        return this.renderValue();
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .name-value-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .save-recipient-button {
                color: #777777;
                cursor: pointer;
            }
        `;
    }
}

window.customElements.define('ach-payment-name-field', AchPaymentNameField);
