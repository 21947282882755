import {
    editable,
    transferAmount,
    transferAudit,
    transferCreatedDate,
    transferScheduledDate,
    transferId,
    transferMemo,
    transferStatus,
    transferTransactionId,
    transferAccount,
    transferAccountTypeFilter,
    transferAmountFilter,
    transferCreatedDateFilter,
    transferDateFilter,
    transferSelected,
    transferStatusFilter,
} from '@treasury/policy/transfers';
import paymentCanBeCancelled from '@treasury/domain/shared/utilities/payment-can-be-cancelled.js';
import { html } from 'lit';
import '../../../components/channel-status-badge.js';
import '../../../components/frequency-detail.js';
import '../../../components/field-with-tooltip-icon.js';

const canCancelTransfer = record => {
    const status = record.getField('status');
    const userCanCancel = record.getField('permissions').some(e => e.permission === 'Delete');

    return paymentCanBeCancelled(status) && userCanCancel;
};

const compareAccountsForSorting = (a, b) => {
    const aValue = a.accountDisplayLabel;
    const bValue = b.accountDisplayLabel;

    if (aValue === bValue) {
        return 0;
    }

    return aValue > bValue ? 1 : -1;
};

export const fields = (client, transferAccounts) => ({
    selected: transferSelected.thatIs
        .disabledWhen(
            record =>
                record.getField('status') !== 'PendingApproval' ||
                !record.getField('permissions').some(e => e.permission === 'Approve')
        )
        .as.tag('omega-checkbox'),
    transactionId: transferTransactionId.with
        .template(
            (transactionId, record) =>
                html`<field-with-tooltip-icon
                    .displayString=${transactionId}
                    .messageType=${record.values.messageType}
                    .message=${record.values.message}
                    .isEdited=${record.values.isEdited}
                    recordType="transfer"
                ></field-with-tooltip-icon>`
        )
        .with.label('Transaction ID')
        .thatIs.readOnly(),
    fromAccount: transferAccount.thatIs
        .required()
        .with.label('From Account')
        .and.readOnlyWhen(record => record && !record.getField('editable'))
        .and.options({
            fetch: async record => {
                const toAccount = record.getField('toAccount').id;
                const accts = await transferAccounts;
                return accts.filter(acct => acct.id !== toAccount && acct.type !== 'Loan');
            },
            text: 'accountDisplayLabel',
            value: record => record,
        })
        .and.compareFunction(compareAccountsForSorting)
        .as.tag('omega-select'),
    toAccount: transferAccount.thatIs
        .required()
        .with.label('To Account')
        .and.readOnlyWhen(record => record && !record.getField('editable'))
        .and.options({
            fetch: async record => {
                const fromAccount = record.getField('fromAccount').id;
                const accts = await transferAccounts;
                return accts.filter(acct => acct.id !== fromAccount);
            },
            text: 'accountDisplayLabel',
            value: record => record,
        })
        .and.compareFunction(compareAccountsForSorting)
        .as.tag('omega-select'),
    amount: transferAmount.thatIs
        .required()
        .with.label('Amount')
        .thatIs.readOnlyWhen(record => record && !record.getField('editable'))
        .as.tag('omega-input'),
    createdDate: transferCreatedDate.thatIs.readOnly(),
    transferDate: transferScheduledDate.with
        .template(
            (transferDate, record) => html`<frequency-detail
                .displayDate=${transferDate}
                .record=${record}
            ></frequency-detail>`
        )
        .thatIs.required()
        .with.label('Transfer Date')
        .thatIs.readOnlyWhen(record => record && !record.getField('editable'))
        .as.tag('omega-datepicker'),
    status: transferStatus.with
        .template(
            (status, record) => html`<channel-status-badge
                .fetchApproversFunction=${async () =>
                    client.fetchApprovers({
                        approvalEntity: 'internalTransfer',
                        productId: record.getField('id'),
                        amount: record.getField('amount'),
                        updatedBy: record.getField('updatedBy'),
                    })}
                .status=${status}
                .approvalsNeeded=${record.getField('numberOfApprovalsNeeded')}
                .approvalsCount=${record.getField('completedApprovalCount')}
            ></channel-status-badge>`
        )
        .thatIs.readOnly(),

    memo: transferMemo.with
        .label('Memo')
        .thatIs.readOnlyWhen(record => record && !record.getField('editable'))
        .as.tag('omega-input'),
    audit: transferAudit.thatIs.readOnly(),
    id: transferId,
    editable,
});

export const columns = [
    {
        type: 'checkbox',
        label: '',
        field: 'selected',
    },
    {
        label: 'Transaction ID',
        field: 'transactionId',
    },
    {
        label: 'From Account',
        field: 'fromAccount',
    },
    {
        label: 'To Account',
        field: 'toAccount',
    },
    {
        type: 'read-only',
        label: 'Amount',
        field: 'amount',
    },
    {
        label: 'Created Date',
        field: 'createdDate',
    },
    {
        type: 'read-only',
        field: 'transferDate',
        label: 'Transfer Date',
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        type: 'actions',
        actions: [
            {
                label: 'Cancel Transfer',
                action: 'Cancel Transfer',
                visibleWhen: record => canCancelTransfer(record),
            },
        ],
        label: 'Actions',
    },
];

export const filters = async transferAccounts => [
    {
        field: 'transferDate',
        fieldType: transferDateFilter,
        value: {
            id: 'range',
            dates: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date(new Date().setDate(new Date().getDate() + 7)),
            ],
        },
    },
    {
        field: 'status',
        fieldType: transferStatusFilter.with
            .label('Status')
            .thatIs.required()
            .with.hashFunction(item => item.value),
    },
    {
        field: 'accountOperator',
        fieldType: transferAccountTypeFilter.with.label('Account').with.inline(),
        value: 'Both',
    },
    {
        field: 'activityAccounts',
        fieldType: transferAccount.and
            .multipleValues()
            .with.label('From / To')
            .thatIs.required()
            .with.hashFunction(item => item.id),
        value: await transferAccounts,
    },
    {
        field: 'amount',
        fieldType: transferAmountFilter.with.label('Amount'),
        value: ['specific', 0, 0],
    },
    {
        field: 'transactionId',
        fieldType: transferTransactionId.with.label('Transaction ID'),
        value: '',
    },
    {
        field: 'createdDate',
        fieldType: transferCreatedDateFilter.with.label('Created Date'),
        value: '',
    },
];
