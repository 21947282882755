import { AchFilterServices } from '@treasury/domain/channel/services/ach/ach-filter-services.js';
import SecCodesService from '@treasury/domain/channel/services/sec-codes/sec-codes-service.js';
import {
    achCompanyId,
    achCompanyName,
    amountRangeFilter,
    filterStatus,
    secCode,
    transactionType,
} from '@treasury/policy/ach';
import { string } from '@treasury/policy/primitives';

const achFilterFilters = async accountSettings => [
    {
        field: 'accountNumber',
        fieldType: string.with
            .options({
                data: await AchFilterServices.getAccountsAll(accountSettings),
                text: option => `${option.name}`,
                value: 'accountUniqueId',
            })
            .and.multipleValues()
            .and.filtering()
            .with.label('Account'),
        value: (await AchFilterServices.getAccountsAll(accountSettings)).map(
            option => option.accountUniqueId
        ),
    },
    {
        field: 'achCompanyName',
        fieldType: achCompanyName.with.label('ACH Company Name'),
        value: '',
    },
    {
        field: 'achCompanyId',
        fieldType: achCompanyId.with.label('Company ID'),
        value: '',
    },
    {
        field: 'secCode',
        fieldType: secCode.with
            .label('SEC Code')
            .with.options({
                data: await SecCodesService.getSecCodes(),
                text: option => `${option.secCode} - ${option.description}`,
                value: 'secCode',
            })
            .with.multipleValues(),
        value: await SecCodesService.getSecCodes().then(result => result.map(code => code.secCode)),
    },
    {
        field: 'secCodeAll',
        fieldType: secCode.with
            .options({
                data: await SecCodesService.getSecCodes(),
                text: option => `${option.secCode} - ${option.description}`,
                value: 'secCode',
            })
            .with.multipleValues()
            .and.visibleWhen(() => false),
        value: await SecCodesService.getSecCodes().then(result => result.map(code => code.secCode)),
    },
    {
        field: 'amount',
        fieldType: amountRangeFilter.with
            .label('Amount')
            .with.schema('range')
            .and.multipleValues()
            .thatHas.options([
                { text: 'None', value: '' },
                { text: 'Amount Range', value: 'range' },
                { text: 'Specific Amount', value: 'specific' },
                { text: 'Amount Limit', value: 'limit' },
            ]),
        value: ['', '', ''],
    },
    {
        field: 'transactionType',
        fieldType: transactionType.with
            .label('Transaction Type')
            .with.options({
                data: [
                    { text: 'Credit', value: 'Credit' },
                    { text: 'Debit', value: 'Debit' },
                ],
                text: option => `${option.text}`,
                value: 'value',
            })
            .and.multipleValues(),
        value: 'all',
    },
    {
        field: 'status',
        fieldType: filterStatus.with
            .label('Status')
            .with.options([
                // TODO: Re-enable these commented values after Day 1 is complete
                // { text: 'PENDING', value: 'pending' },
                { text: 'ACTIVE', value: 'Active' },
                { text: 'INACTIVE', value: 'Inactive' },
                // { text: 'REJECTED', value: 'rejected' },
                // { text: 'FAILED', value: 'Failed' },
            ])
            .with.multipleValues(),
        value: 'all',
    },
];

export default achFilterFilters;
