import { MasterListRecipient } from '@treasury/domain/channel/types/ach';
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-dialog.js';
import { DialogUtility } from '@treasury/omega/util/dialog-utility';
import { html, nothing } from 'lit';
import { achSelectedRecipientsColumns } from '../../data/ach-selected-recipients-dialog-data';

interface AchSelectedRecipientsDialogProps {
    selectedRecipientsRecordset: Recordset<MasterListRecipient>;
    openDialogStatus: boolean;
    closeDialog: () => void;
}

export function renderAchSelectedRecipientsDialog({
    selectedRecipientsRecordset,
    openDialogStatus,
    closeDialog,
}: AchSelectedRecipientsDialogProps) {
    const tag = 'ach-selected-recipients-dialog';
    const util = new DialogUtility(tag);

    const handleClose = () => {
        closeDialog();
    };

    const renderTable = () => {
        if (selectedRecipientsRecordset.allRecords.length < 1) return nothing;

        return html`<omega-table
            .recordset=${selectedRecipientsRecordset}
            .columnDefinitions=${achSelectedRecipientsColumns()}
        ></omega-table>`;
    };

    return html`
        <omega-dialog
            id=${util.dialogId}
            .dialogTitle=${'Selected Recipients'}
            @close=${handleClose}
            .open=${openDialogStatus}
        >
            <div slot="content" class="table">${renderTable()}</div>
            <omega-button slot="actions" type="link" @click=${handleClose}>Close</omega-button>
        </omega-dialog>
    `;
}
