export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            require: 'ngModel',
            scope: {
                ngModel: '=',
                placeholder: '@',
                ngRequired: '=',
                ngInvalid: '=?',
                classes: '=?',
                ngDisabled: '=?',
                offsetAccounts: '=',
                displayColumn: '@',
                returnColumn: '@',
                onSelectOffsetAccount: '&',
            },
            link(scope, element, attr, ngModel) {
                ngModel.$formatters.push(model => {
                    if (!!model && !!scope.displayColumn && !!model[scope.displayColumn]) {
                        return model[scope.displayColumn];
                    }
                    return model;
                });
            },
            template: require('./typeAheadAchOffsetAccountsTemplate.html'),
            controller: [
                '$scope',
                'achBankService',
                '$modal',
                function ($scope, achBankService, $modal) {
                    $scope.label = function (item) {
                        return item.accountId;
                    };

                    $scope.showOffsetAccountsDialog = function () {
                        const modalInstance = $modal.open({
                            template: require('../../../payables/ach/batch/views/offsetAccountsModalView.html'),
                            size: 'md',
                            controller: 'OffsetAccountsController',
                            backdrop: 'static',
                            resolve: {
                                offsetAccounts() {
                                    return $scope.offsetAccounts;
                                },
                            },
                        });

                        modalInstance.result.then(response => {
                            if ($scope.returnColumn) {
                                $scope.ngModel = response[$scope.returnColumn];
                            } else {
                                $scope.ngModel = response;
                            }
                            if ($scope.onSelectOffsetAccount) {
                                $scope.onSelectOffsetAccount({ account: response });
                            }
                        });
                    };

                    $scope.onSelect = function ($item, $model, $label) {
                        if ($scope.onSelectOffsetAccount) {
                            $scope.onSelectOffsetAccount({ account: $item });
                        }
                    };
                },
            ],
        };
    },
];
