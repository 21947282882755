/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { DownloadBaseDto, QfxSettingsModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class DownloadClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Download
     * @name DownloadPost
     * @summary Posts the specified model.
     * @request POST:/download
     */
    downloadPost = (model: DownloadBaseDto | null, params: RequestParams = {}) =>
        this.http.request<File, any>({
            path: `/download`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Download
     * @name DownloadGetQfxEnabledFlag
     * @summary Get the Qfx Enabled Flag for a company if it exists
     * @request GET:/download/getQfxEnabledFlag
     */
    downloadGetQfxEnabledFlag = (params: RequestParams = {}) =>
        this.http.request<QfxSettingsModelDto, any>({
            path: `/download/getQfxEnabledFlag`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
