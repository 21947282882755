AnswerSecurityQuestionsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'pagePushService',
    'accountService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function AnswerSecurityQuestionsController(
    $scope,
    $state,
    $stateParams,
    pagePushService,
    accountService
) {
    $scope.message = '';
    $scope.challenges = [];

    $scope.reset = function () {
        init();
        $scope.form.$setPristine();
    };

    $scope.initialize = function (form) {
        $scope.form = form;
    };

    function init() {
        if (!$stateParams.nextStep) {
            $state.go('login');
        }
        $scope.user = pagePushService.getUser();
        if ($scope.user === null || $scope.user === undefined) {
            $state.go('login');
        } else {
            $scope.challenges = angular.copy($scope.user.credential.data);
        }
    }

    $scope.confirmSecurityQuestions = function () {
        accountService.verifySecurityQuestions($scope.challenges).then(response => {
            accountService.checkForError(response);
            if (response.statusCode === 'Allow') {
                goToNextStep();
            } else {
                $scope.message = response.message;
            }
        });
    };

    function goToNextStep() {
        if ($stateParams.nextStep === 'changePassword') {
            $state.go('change-password', {
                reason: $stateParams.reason,
                daysUntilPasswordExpires: $stateParams.daysUntilPasswordExpires,
            });
        } else {
            accountService.goToNextStep();
        }
    }

    init();
}
