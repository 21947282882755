/* eslint-disable @treasury/no-date */
export const timeFormatter = (value: string) => {
    const date = new Date(value);

    if (!date.getTime() || Number.isNaN(date.getTime())) return 'Invalid Date';

    const result = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    return decodeURIComponent(encodeURIComponent(result).replace(/%E2%80%AF/g, '%20'));
};

export const formatTwelveHourTime = (hoursMinutes: string) => {
    if (hoursMinutes == null) return 'no data';
    let hours = parseInt(hoursMinutes.slice(0, 2));
    const minutes = hoursMinutes.slice(3, 5);
    const period = hours >= 12 ? 'PM' : 'AM';
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;
    const hourString = String(hours).padStart(2, '0');

    return `${hourString}:${minutes} ${period}`;
};
