export default function TimerService() {
    this.timers = {};
    this.start = function (name, durationInSeconds) {
        this.timers[name] = new Date().getTime() + durationInSeconds * 1000;
    };

    this.stop = function (name) {
        this.timers[name] = null;
    };

    this.isTimedOut = function (name) {
        return this.timers[name] && this.timers[name] - new Date().getTime() <= 0;
    };

    this.remainingTimeInSeconds = function (name) {
        return Math.max(Math.round((this.timers[name] - new Date().getTime()) / 1000), 0);
    };
}
