// eslint-disable-next-line import/extensions
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';

export default class AchReturnActivityRequests {
    static async search(body) {
        return (await TmHttpClient.getInstance()).request(`AchReturn/Search`, {
            body,
            method: 'POST',
        });
    }

    static async searchV2(body) {
        return (await TmHttpClient.getInstance()).request(`ach/return/search`, {
            body,
            method: 'POST',
        });
    }

    static async getDetails(guid) {
        return (await TmHttpClient.getInstance()).request(`AchReturn/${guid}/Detail`, {
            method: 'GET',
            maxAgeInSeconds: 1000,
        });
    }

    static async downloadReturn(guid, type, notificationReceivedDate, returnReasonDescription) {
        if (type.toLowerCase() === 'nacha') {
            return (await TmHttpClient.getInstance()).request(`AchReturn/${guid}/Nacha`, {
                method: 'POST',
                responseType: TmHttpResponseType.ArrayBuffer,
            });
        }
        return (await TmHttpClient.getInstance()).request(`AchReturn/${guid}/Download`, {
            method: 'POST',
            responseType: TmHttpResponseType.ArrayBuffer,
            body: { notificationReceivedDate, returnReasonDescription },
        });
    }

    static async downloadReturnV2(guid, body) {
        return (await TmHttpClient.getInstance()).request(`ach/return/${guid}/download`, {
            method: 'POST',
            responseType: TmHttpResponseType.Raw,
            body,
        });
    }

    static async downloadReturnReport(body) {
        return (await TmHttpClient.getInstance()).request('ach/return/search/download', {
            method: 'POST',
            responseType: TmHttpResponseType.Raw,
            body,
        });
    }
}
