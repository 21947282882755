import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { boolean, Record, Recordset } from '@treasury/FDL';
import {
    OmegaColumnAction,
    OmegaColumnDefinition,
    OmegaColumnType,
} from '@treasury/omega/components/table';
import { Schema } from '@treasury/omega/layouts/omega-form';
import { AchDomesticContainer } from '../containers/ach-domestic-container';
import { isReadOnlyStates } from './recipient-recordset';

export const getColumns = (
    context: AchDomesticContainer,
    config: any,
    record: Record<PaymentHeader>
): OmegaColumnDefinition<PaymentRecipient>[] => {
    const { entitlements, action, type } = config;
    const isReadOnlyState = isReadOnlyStates(undefined, entitlements, action, type, record);
    const step = context.paymentHeaderRecord.getField('step');
    return [
        { field: 'name', label: 'Recipient Name' },
        { field: 'idNumber', label: 'ID Number' },
        { field: 'accountNumber', label: 'Account Number' },
        { field: 'accountType', label: 'Account Type' },
        { field: 'bank', label: 'Routing Number' },
        {
            field: 'transactionType',
            label: 'Credit/Debit',
        },
        { field: 'amount', label: 'Amount' },
        {
            field: 'prenote',
            label: 'Prenote',
            type: isReadOnlyState ? undefined : OmegaColumnType.CheckBox,
            sortable: true,
        },
        {
            field: 'hold',
            label: 'Hold',
            type: isReadOnlyState ? undefined : OmegaColumnType.CheckBox,
            sortable: true,
        },
        {
            label: 'Addenda',
            type: OmegaColumnType.Actions,
            actions: [
                {
                    label: 'Addenda',
                    action: (r: Record<PaymentRecipient>) => context.openAddendaDialog(r),
                },
            ],
        },
        {
            label: '',
            type: OmegaColumnType.EditRow,
            visible: isReadOnlyState ? false : step === 0,
            options: [
                {
                    action: OmegaColumnAction.Add,
                },
                {
                    action: OmegaColumnAction.Clone,
                    tooltip:
                        'This feature may be utilized to copy the Recipient Name, ID Number, Account Number, Account Type and Routing Number.',
                    fieldsToClone: [
                        'name',
                        'idNumber',
                        'accountNumber',
                        'accountType',
                        'transactionType',
                        'bank',
                    ],
                },
                {
                    action: OmegaColumnAction.Delete,
                },
            ],
        },
    ];
};

export const getSchema = (
    context: AchDomesticContainer,
    record: Record<PaymentHeader>,
    recordset: Recordset<PaymentRecipient>,
    config: any
): Schema<PaymentHeader, PaymentRecipient> => ({
    record,
    recordsetConfiguration: {
        recordset,
        recordsetColumns: () => getColumns(context, config, record),
        recordsetVisibleWhen: record => {
            const nachaFileUpload = Boolean(record.getField('nachaFileUpload'));
            return !nachaFileUpload || (nachaFileUpload && record.getField('step') > 0);
        },
        recordsetFilters: [
            {
                field: 'prenote',
                fieldType: boolean.with
                    .label('Prenote Only')
                    .thatIs.inline()
                    .as.tag('omega-checkbox'),
                value: false,
            },
            {
                field: 'hold',
                fieldType: boolean.with.label('Hold Only').thatIs.inline().as.tag('omega-checkbox'),
                value: false,
            },
            {
                field: '#invalid',
                fieldType: boolean.with.label('Errors').thatIs.inline().as.tag('omega-checkbox'),
                value: false,
            },
        ],
    },
    sections: [
        {
            columns: [
                {
                    fields: [
                        'transactionId',
                        'name',
                        'status',
                        'achCompany',
                        'achCompanyId',
                        'secCode',
                        'entryDescription',
                        'discretionaryData',
                        'restricted',
                    ],
                },
                {
                    fields: ['debitAmount', 'creditAmount', 'frequency', 'offsetAccount', 'audit'],
                },
            ],
        },
    ],
});
