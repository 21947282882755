import { getChannelAPIPath } from '@treasury/core/http';

TaxesService.$inject = ['$resource', 'achPaymentTypes'];

export default function TaxesService($resource, achPaymentTypes) {
    const resourceUrl = `${getChannelAPIPath()}taxPayment/`;
    return {
        getStateTaxCodes,
        getStateTaxAmountTypes,
        getFederalTaxCodes,
        getFederalTaxAmountTypes,
        getRecipientAccountTypes,
        getStateAmountTypeDescription,
        getFederalAmountTypeDescription,
        getRecipientAccountTypeDescription,
        getPaymentTypeName,
        getFederalAmountType,
        isValidTaxCode,
        isPatternMatchForTaxCode,
    };

    function getStateTaxCodes(id) {
        return getArray(':id/stateTaxCodes', { id });
    }

    function getStateTaxAmountTypes() {
        return getArray('stateTaxAmountTypes', {});
    }

    function getFederalTaxCodes() {
        return getArray('', {});
    }

    function getFederalTaxAmountTypes(id) {
        return getArray('federalTaxAmountTypes', { id });
    }

    function getRecipientAccountTypes() {
        return getArray('recipientAccountTypes', {});
    }

    function getArray(uri, params) {
        return $resource(resourceUrl + uri, params, { get: { method: 'GET', isArray: true } }).get()
            .$promise;
    }

    function getStateAmountTypeDescription(stateTaxAmountTypes, achStateAmountTypeId) {
        if (!(stateTaxAmountTypes && stateTaxAmountTypes.length)) return null;

        const amountType = find(stateTaxAmountTypes, item => item.id === achStateAmountTypeId);

        if (!amountType) return null;

        return amountType.description;
    }

    function getFederalAmountTypeDescription(federalTaxAmountTypes, amountType) {
        const federalAmountType = getFederalAmountType(federalTaxAmountTypes, amountType);

        if (!federalAmountType) return null;

        return federalAmountType.description;
    }

    function getFederalAmountType(federalTaxAmountTypes, amountType) {
        if (!(federalTaxAmountTypes && federalTaxAmountTypes.length)) return null;

        const federalAmountType = find(
            federalTaxAmountTypes,
            item => item.amountType === amountType
        );

        if (!federalAmountType) return null;

        return federalAmountType;
    }

    function getRecipientAccountTypeDescription(recipientAccountTypes, recipientAccountTypeId) {
        if (!(recipientAccountTypes && recipientAccountTypes.length)) return null;

        const accountType = find(recipientAccountTypes, item => item.id === recipientAccountTypeId);

        if (!accountType) return null;

        return accountType.name;
    }

    function find(array, predicate) {
        for (let i = 0; i < array.length; i++) {
            if (predicate(array[i])) return array[i];
        }
    }

    function isValidTaxCode(taxCodeObject) {
        if (taxCodeObject && taxCodeObject.taxCode) {
            return true;
        }

        return isPatternMatchForTaxCode(taxCodeObject);
    }

    function isPatternMatchForTaxCode(taxCode) {
        return taxCode && taxCode.match(/^[a-zA-Z0-9]{1,5}$/);
    }

    function getPaymentTypeName(paymentTypeId) {
        if (paymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            return 'State';
        }

        if (paymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            return 'Federal';
        }

        return 'Unknown';
    }
}
