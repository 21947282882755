import TermsAndConditionsService from '@treasury/domain/channel/services/account/terms-and-conditions-service';
import DownloadService from '@treasury/domain/channel/services/download/download-service.js';
import { AuditCode, ResourceType } from '@treasury/domain/channel/services/user-activity';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity/user-activity-service.js';
import { FiDate } from '@treasury/domain/dates';

export class TermsAndConditionsClient {
    getTermsAndConditions() {
        return TermsAndConditionsService.getTermsAndConditions();
    }

    acceptTermsAndConditions() {
        return TermsAndConditionsService.acceptTermsAndConditions();
    }

    declineTermsAndConditions() {
        return UserActivityService.userInteractionAudit(
            ResourceType.AccountResources,
            AuditCode.DeclineTermsAndConditions
        );
    }

    downloadTermsAndConditions(outputType) {
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        return DownloadService.download(fileSuffix, outputType, 'TermsAndConditions', null);
    }
}
