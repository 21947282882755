/* eslint-disable import/extensions */
import { css, html, LitElement, nothing, render } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { ListeningElementMixin } from './listening-element';

function clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
}

export function positioningStrategies({
    x,
    y,
    anchorWidth,
    anchorHeight,
    popupWidth,
    popupHeight,
    viewportWidth,
    viewportHeight,
}) {
    const vertical = {
        left: clamp(x + anchorWidth / 2, popupWidth / 2, viewportWidth - popupWidth / 2),
        transform: 'translate(-50%, 0)',
    };

    const horizontal = {
        top: clamp(y + anchorHeight / 2, popupHeight / 2, viewportHeight - popupHeight / 2),
        transform: 'translate(0, -50%)',
    };

    return {
        up: {
            ...vertical,
            bottom: viewportHeight - y,
            fits() {
                return y - popupHeight > 0;
            },
        },

        down: {
            ...vertical,
            top: y + anchorHeight,
            fits() {
                return y + anchorHeight + popupHeight < viewportHeight;
            },
        },

        left: {
            ...horizontal,
            right: viewportWidth - x,
            fits() {
                return x - popupWidth > 0;
            },
        },

        right: {
            ...horizontal,
            left: x + anchorWidth,
            fits() {
                return x + anchorWidth + popupWidth < viewportWidth;
            },
        },
    };
}

export function mapStrategyToStyles(strategy) {
    const styles = {
        transform: strategy.transform,
    };

    for (const property of ['left', 'right', 'top', 'bottom']) {
        styles[property] = property in strategy ? `${strategy[property]}px` : 'unset';
    }

    return styles;
}

export function closePopup() {
    [...document.querySelectorAll('#omega-popup-backdrop')].forEach(el => el.remove());
}

export function openPopup(anchor, template, { directions = ['down', 'up'] } = {}) {
    const backdrop = document.createElement('div');
    backdrop.id = 'omega-popup-backdrop';
    backdrop.style =
        'display: block; position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 10000';
    document.body.appendChild(backdrop);
    return new Promise((resolve, reject) => {
        render(
            html`<omega-popup .anchorElement=${anchor} .directions=${directions}
                >${template(resolve, reject)}</omega-popup
            >`,
            backdrop
        );
        backdrop.addEventListener('click', () => reject());
    }).finally(closePopup);
}

export function getPopup() {
    return document.querySelectorAll('#omega-popup-backdrop')[0].querySelector('omega-popup');
}

export function isPopupShowing() {
    return document.querySelectorAll('#omega-popup-backdrop').length > 0;
}

const ListeningElement = ListeningElementMixin(LitElement);

export const OmegaPopupTagName = 'omega-popup';
export default class OmegaPopup extends ListeningElement {
    static get properties() {
        return {
            anchorElement: Object,
            directions: Array,
        };
    }

    constructor() {
        super();
        this.listeningTo = [];
        this.directions = ['down', 'up'];
    }

    firstUpdated() {
        this.listenTo(document, 'scroll', () => {
            this.requestUpdate();
        });

        this.listenTo(window, 'resize', () => {
            this.requestUpdate();
        });

        // render again because we don't know the size of the popup until after it's been inserted
        this.requestUpdate();
    }

    firstPositioningStrategyThatFits(strategies) {
        const firstDirectionThatFits = this.directions.find(d => strategies[d].fits());
        return strategies[firstDirectionThatFits ?? this.directions[0]];
    }

    get popupStyles() {
        const anchorElementRect = this.anchorElement.getBoundingClientRect();
        const popup = this.shadowRoot.querySelector('#popup');
        return mapStrategyToStyles(
            this.firstPositioningStrategyThatFits(
                positioningStrategies({
                    x: anchorElementRect.x,
                    y: anchorElementRect.y,
                    anchorWidth: anchorElementRect.width,
                    anchorHeight: anchorElementRect.height,
                    popupWidth: popup?.clientWidth ?? 0,
                    popupHeight: popup?.clientHeight ?? 0,
                    viewportWidth: document.documentElement.clientWidth,
                    viewportHeight: document.documentElement.clientHeight,
                })
            )
        );
    }

    render() {
        if (!this.anchorElement) {
            return nothing;
        }

        // eslint-disable-next-line lit-a11y/click-events-have-key-events
        return html`<div
            id="popup"
            @click=${e => e.stopPropagation()}
            style=${styleMap(this.popupStyles)}
        >
            <slot></slot>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #popup {
                position: fixed;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
customElements.define(OmegaPopupTagName, OmegaPopup);
