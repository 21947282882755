/* eslint-disable lit-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
// CSpell:ignore sublinks
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { css, html, LitElement, nothing } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';
import './omega-button.js';
import './omega-tooltip.js';

const KEY_CODES = {
    enter: 13,
};
class OmegaActionBar extends LitElement {
    static get properties() {
        return {
            title: String,
            description: String,
            actions: Array,
            options: Array,
            callToAction: String,
            downloadOptions: Array,
            disableOptions: Boolean,
            disableAction: Boolean,
            message: String,
            downloadOptionsOpen: Boolean,
            tooltipDirection: String,
        };
    }

    constructor() {
        super();
        this.options = ['download', 'print']; /* set default to show both print and download */
        this.downloadOptions = ['PDF', 'CSV'];
        this.downloadOptionsOpen = false;
        this.disableOptions = false;
        this.disableAction = false;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-action-bar--action-bar',
        };
    }

    toggleDropdownDownloadOptions() {
        this.downloadOptionsOpen = !this.downloadOptionsOpen;
    }

    handlePossibleExternalClick(event) {
        if (!this.shadowRoot) return;
        const downloadTrigger = this.shadowRoot.querySelector('#download-options-trigger');
        if (event.composedPath().includes(downloadTrigger)) return;
        const downloadDropdown = this.shadowRoot.querySelector('.omega-download-dropdown');
        if (!downloadDropdown?.contains(event.composedPath()[0]) && this.downloadOptionsOpen)
            this.downloadOptionsOpen = false;
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('click', this.handlePossibleExternalClick.bind(this), true);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('click', this.handlePossibleExternalClick.bind(this), true);
    }

    _print() {
        this.dispatchEvent(
            new CustomEvent('print', {
                composed: true,
                bubbles: true,
            })
        );
    }

    _download(e) {
        this.dispatchEvent(
            new CustomEvent('download', {
                composed: true,
                bubbles: true,
                detail: { downloadType: e.target.innerText },
            })
        );
        this.toggleDropdownDownloadOptions();
    }

    _onKeyUp(e) {
        if (e.keyCode === KEY_CODES.enter) {
            this._download(e);
        }
    }

    _callToActionClicked() {
        this.dispatchEvent(
            new CustomEvent('callToActionClicked', {
                composed: true,
                bubbles: true,
            })
        );
    }

    hasOption(option) {
        return this.options.includes(option);
    }

    isCallToActionUsed() {
        return this.callToAction != null && this.callToAction.length > 0;
    }

    isPageActionsUsed() {
        return this.actions != null;
    }

    isDownloadDropdownOpen() {
        return this.downloadOptionsOpen === true;
    }

    async routeToLink(route, parameters) {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        navService.navigate(route, parameters);
    }

    renderPageTitle() {
        return html` <h2 class="tm-title">${this.title} ${this.renderTitleInformation()}</h2> `;
    }

    renderTitleInformation() {
        if (this.message) {
            return html`<omega-tooltip
                id="tm-message"
                light
                icon="info-circle"
                .message=${this.message}
                .direction=${this.tooltipDirection}
            ></omega-tooltip>`;
        }
        return nothing;
    }

    renderPageActions() {
        if (this.isPageActionsUsed()) {
            return html`
                <div class="panel-sublinks">
                    ${this.actions.map(action => {
                        let anchor = html`<a class="tm-action-link" href="${action.url}"
                            >${action.title}</a
                        >`;
                        if (action.route) {
                            anchor = html`
                                <a
                                    class="tm-action-link"
                                    href="javascript:void(0)"
                                    @click="${() =>
                                        this.routeToLink(action.route, action.parameters)}"
                                    >${action.title}</a
                                >
                            `;
                        }
                        return html`
                            <span class="tm-panel-sublink omega-no-print"> ${anchor} </span>
                        `;
                    })}
                </div>
            `;
        }
        return nothing;
    }

    renderCallToAction() {
        if (this.isCallToActionUsed()) {
            return html`<omega-button
                posture="small"
                type="secondary"
                .disabled=${this.disableAction}
                @click="${this._callToActionClicked}"
                >${this.callToAction}</omega-button
            > `;
        }
        return nothing;
    }

    renderDownloadDropdown() {
        if (this.isDownloadDropdownOpen()) {
            return html`<ul
                class="omega-download-dropdown"
                role="menu"
                aria-hidden=${this.isDownloadDropdownOpen()}
            >
                ${this.downloadOptions.map(
                    option =>
                        html`<li
                            role="menuitem"
                            class="omega-download-dropdown-item"
                            @click=${this._download}
                            @keyup=${this._onKeyUp}
                        >
                            ${option}
                        </li>`
                )}
            </ul>`;
        }
        return nothing;
    }

    renderDownloadButton() {
        if (this.hasOption('download')) {
            return html`<omega-button
                id="download-options-trigger"
                posture="small"
                type="icon"
                icon="download"
                .disabled=${this.disableOptions}
                @click="${this.toggleDropdownDownloadOptions}"
            >
                <span class="icon-button-text">Download</span>
            </omega-button>`;
        }
        return nothing;
    }

    renderPrintButton() {
        if (this.hasOption('print')) {
            return html`<omega-button
                class="print-button"
                .disabled=${this.disableOptions}
                posture="small"
                type="icon"
                icon="print"
                @click="${this._print}"
            >
                <span class="icon-button-text">Print</span>
            </omega-button>`;
        }
        return nothing;
    }

    renderDescriptionOption() {
        if (this.hasOption('description') && this.description) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.description, 'text/html');
            const description = doc.body;
            return html`<omega-tooltip light clickable direction="bottom-left">
                <omega-button
                    class="description-button"
                    type="icon"
                    icon="question-circle"
                    hideLabel="always"
                    >Description</omega-button
                >
                <div slot="content" style="width: 450px;">${description}</div></omega-tooltip
            >`;
        }
        return nothing;
    }

    render() {
        return html`
            <div class="omega-action-bar">
                <div class="page-title-and-links">
                    ${this.renderPageTitle()} ${this.renderPageActions()}
                </div>
                <div class="omega-page-actions omega-no-print">
                    ${this.renderCallToAction()} ${this.renderDownloadButton()}
                    ${this.renderDownloadDropdown()} ${this.renderPrintButton()}
                    ${this.renderDescriptionOption()}
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                .omega-action-bar {
                    display: flex;
                    flex-direction: column wrap;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--omega-light-grey);
                    align-items: center;
                    height: 54px;
                }
                .tm-title {
                    color: var(--omega-text-header);
                    padding: 0;
                    margin: 0 15px;
                    display: flex;
                    font-size: 18px;
                    font-weight: 500;
                }
                .page-title-and-links {
                    display: flex;
                    align-items: center;
                }
                .panel-sublinks {
                    display: flex;
                }
                .tm-panel-sublink {
                    border-left: 1px solid var(--omega-light-grey);
                    padding-left: 16px;
                    padding-right: 16px;
                    font-size: 13px;
                    white-space: nowrap;
                }
                .tm-action-link {
                    color: var(--omega-primary);
                    text-decoration: none;
                }
                .omega-action-button {
                    color: var(--omega-primary);
                    cursor: pointer;
                }
                .omega-page-actions {
                    display: flex;
                    justify-content: flex-end;
                }
                .omega-download-dropdown {
                    position: absolute;
                    right: 60px;
                    top: 30px;
                    z-index: 5;
                    min-width: 50px;
                    padding: 0;
                    border: 1px solid var(--omega-white-150);
                    background: var(--omega-white);
                    border-radius: 3px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                    list-style: none;
                }

                .print-button {
                    position: relative;
                }

                .print-button::before {
                    content: '';
                    position: absolute;
                    left: -8px;
                    height: 22px;
                    top: 3px;
                    border-left: var(--omega-default-border);
                }

                .description-button::before {
                    content: '';
                    position: absolute;
                    left: -8px;
                    height: 22px;
                    top: 11px;
                    border-left: var(--omega-default-border);
                }

                .omega-download-dropdown li {
                    min-width: 50px;
                    padding: 10px 25px;
                    text-align: center;
                    color: var(--omega-primary);
                    cursor: pointer;
                }

                .omega-download-dropdown li:hover {
                    background-color: var(--omega-white-100);
                }

                .omega-download-dropdown a {
                    text-decoration: none;
                }
                @media (max-width: 1200px) {
                    .icon-button-text {
                        display: none;
                    }
                }
                @media print {
                    .omega-no-print {
                        display: none;
                    }
                }
            `,
        ];
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default OmegaActionBar;
window.customElements.define('omega-action-bar', OmegaActionBar);
