export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                selectionList: '=',
                columnName: '@',
                id: '@',
                placeholder: '@',
                ngRequired: '=',
                includeTemplate: '=',
                type: '@',
                index: '=?',
                ngInvalid: '=?',
                disabled: '=',
            },
            template: require('./typeAheadCompanyAccountTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.internalSelectionList = [];

                    $scope.$watch('columnName', newValue => {
                        if (newValue !== null && newValue !== undefined) {
                            $scope.query = `item as item.${$scope.columnName} for item in internalSelectionList`;
                        }
                    });

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.internalSelectionList = angular.copy($scope.selectionList);
                            }
                        },
                        true
                    );
                },
            ],
        };
    },
];
