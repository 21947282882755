import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import { RecordsetValidator } from '@treasury/FDL/recordset';

export const onUsAmountRequiredValidator = (
    record: Record<PaymentHeader>
): RecordsetValidator<PaymentRecipient> => ({
    name: (recipients: Array<Record<PaymentRecipient>>) => {
        const achCompany = record.getField('achCompany');
        return `At least ${achCompany.onUsAmountRequiredPerBatch}% of the batch amount must be allocated to "On Us" routing numbers.`;
    },
    validate: (recipients: Array<Record<PaymentRecipient>>) => {
        const achCompany = record.getField('achCompany');
        if (!achCompany.onUsAmountRequiredPerBatch) return true;

        const onUsRecipientsAmountTotal = recipients
            .filter((recipientRecord: Record<PaymentRecipient>) => {
                const recipient = recipientRecord.values;
                if (!recipient.bank) return false;
                return recipient.bank.onUs;
            })
            .reduce((acc, curr) => acc + (curr.values.amount || 0), 0);

        const recipientAmountTotal = recipients.reduce(
            (acc, curr) => acc + (curr.values.amount || 0),
            0
        );

        /**
         * Business logic:
         * Valid recordset amount totals must be greater than zero and on us recipient amount total percentage
         * must be greater than or equal to the onUsAmountRequiredPerBatch value (which comes back from the API
         * as a percentage not a decimal).
         */
        return (
            recipientAmountTotal > 0 &&
            onUsRecipientsAmountTotal >=
                recipientAmountTotal * (achCompany.onUsAmountRequiredPerBatch / 100)
        );
    },
});
