import { boolean, FieldType, string } from '@treasury/FDL';
import { html } from 'lit';
// eslint-disable-next-line import/extensions
import { accountName } from '../account.js';
import { companyName } from '../company.js';
import { amountRange, date, datePicker, frequency, list, money, number } from '../primitives';
import { userName } from '../user';

/**
 * payments
 */

export const wireAudit = new FieldType().with.rowCount(5).and.template(v => {
    const auditArray = v.split('\r\n');
    const auditText = auditArray.map(audit => html`${audit} <br />`);
    return html`<style>
            .audit-wrapper {
                overflow-y: scroll;
                min-width: 100px;
                min-height: 90px;
                max-height: 400px;
                padding: 5px;
                border: 1px solid #ccc;
                background-color: #eee;
            }
        </style>
        <div class="audit-wrapper">${auditText}</div> `;
});

/**
 * templates
 */
export const fieldDelimiter = string;

export const fileFormatDetails = string;

export const fileFormatType = string;

export const footerRows = number;

export const headerRows = number;

export const id = number;

export const templateName = string;

export const updatedBy = userName;

/**
 * upload
 */

export const beneficiaryName = accountName.as.tag('omega-input');

export const intermediaryBanks = list.as.tag('omega-input');

export const referenceBeneficiary = string.with
    .label('Reference Beneficiary')
    .as.tag('omega-input');

export const debitAccount = string.as.tag('omega-input');

export const debitAccountNumber = string.as.tag('omega-input');

export const wireCompanyName = companyName.as.tag('omega-input');

export const effectiveDate = date.as.tag('omega-datepicker');

export const wireAmount = money.with.label('Wire Amount').as.tag('omega-input');

export const bankId = number.as.tag('omega-input');

export const bankName = string.as.tag('omega-input');

export const purpose = string.with.label('Purpose').as.tag('omega-input');

export const additionalInformation = string.with
    .label('Additional Information')
    .as.tag('omega-input');

export const wireFrequency = frequency.with
    .options([
        { text: 'One Time', value: 'OneTime' },
        { text: 'Weekly', value: 'Weekly' },
        { text: 'Every Two Weeks', value: 'EveryTwoWeeks' },
        { text: 'Twice a Month', value: 'TwiceAMonth' },
        { text: 'Monthly', value: 'Monthly' },
        { text: 'Quarterly', value: 'Quarterly' },
        { text: 'Every Six Months', value: 'EverySixMonths' },
        { text: 'Yearly', value: 'Yearly' },
    ])
    .as.tag('omega-select');

export const selected = boolean.as.tag('omega-checkbox');

export const lineNumber = number.as.tag('omega-input');

export const errorText = string.as.tag('omega-input');

export const wireDetailList = list.as.tag('omega-input');

/**
 * file activity
 */

export const uploadedByFilter = string;

export const uploadedDateFilter = datePicker;

export const amountFilter = amountRange;

export const fileName = string;

export const fileSize = number;

export const uploadedBy = string;

export const uploadDate = date;

export const totalWireCount = number;

export const approvedCount = number;

export const pendingCount = number;

export const rejectedCount = number;

export const expiredCount = number;

export const wireFileId = number;
