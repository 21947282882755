// TODO: error column (for ACH recipients -- this is not a high priority and it was never finished in AngularJS)
// TODO: select rows (not super urgent, I don't think anything coming up uses it, but it's not too hard either)
// TODO: row actions column (only POC was finished in AngularJS)
// TODO: icon column (this one is important -- user activity and the new stuff both use it)
// TODO: links -- AngularJS used an sref attribute on omega-column; for Lit passing in a function that returns a URL  makes more sense
// TODO: edit mode -- a primitive version exists in AngularJS, but it's not used anywhere
// TODO: <omega-column-part>
// TODO: for edge cases, do we allow the app to supply additional columns as a property of omega-table?

import ActionsColumn from './actions-column.js';
import CheckboxColumn from './checkbox-column.js';
import CommandColumn from './command-column.js';
import DetailColumn from './detail-column.js';
import EditRowColumn from './edit-row-column.js';
import EmptyColumn from './empty-column.js';
import IconColumn from './icon-column.js';
import RadioColumn from './radio-column.js';
import RadioIconColumn from './radio-icon-column.js';
import ReadOnlyColumn from './read-only-column.js';
import StandardColumn from './standard-column.js';
import TooltipColumn from './tooltip-column.js';

export default {
    'omega-table-column': StandardColumn,
    'omega-table-command-column': CommandColumn,
    'omega-table-checkbox-column': CheckboxColumn,
    'omega-table-actions-column': ActionsColumn,
    'omega-table-detail-column': DetailColumn,
    'omega-table-icon-column': IconColumn,
    'omega-table-edit-row-column': EditRowColumn,
    'omega-table-radio-column': RadioColumn,
    'omega-table-radio-icon-column': RadioIconColumn,
    'omega-table-read-only-column': ReadOnlyColumn,
    'omega-table-tooltip-column': TooltipColumn,
    'omega-table-empty-column': EmptyColumn,
};
