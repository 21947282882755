/* eslint-disable lit-a11y/click-events-have-key-events */
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { v4 as uuid } from 'uuid';
import DateOptionsModel from './datepicker/date-options-model.js';
import DateModel from './datepicker/DateModel.js';
import './omega-calendar.js';
import './omega-tabs.js';

const HIDE_DONE_BUTTON = true; // flag to remove the done button for now so as to match angular version

class OmegaDatepicker extends LitElement {
    static get properties() {
        return {
            optionOrder: Array,
            tabs: Array,
            activeTab: String,
            value: String,
            dateDisabledFunction: Function,
            secondaryDateDisabledFunction: Function,
            hideRangeDates: Boolean,
            dateFormatter: Function,
            showOptions: Boolean,
            openCalendar: String,
            tempDates: Array,
            valid: { type: Boolean, reflect: true },
            range: { type: Boolean, reflect: true },
            disabled: { type: Boolean, reflect: true },
            required: { type: Boolean, reflect: false },
            selectedOption: Object,
            placeholder: String,
            id: String,
        };
    }

    constructor() {
        super();
        this.placeholder = 'Select A Date';
        this.disabled = false;
        this.valid = true;
        this._boundHandleFocus = this._handleFocus.bind(this);
        this.tempDates = [];
        this.dateOptions = new DateOptionsModel();
        this.dirty = false;
        this.id = uuid();
    }

    get value() {
        return this.formattedDates;
    }

    set value(newValue) {
        const oldValue = this.value;
        this.findSelectedOption(newValue);
        this.requestUpdate('value', oldValue);
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-datepicker--date-picker',
        };
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('click', this._boundHandleFocus);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        window.removeEventListener('click', this._boundHandleFocus);
        super.disconnectedCallback();
    }

    _handleFocus(event) {
        if (this.showOptions && !event.composedPath().find(element => element.id === this.id)) {
            this.showOptions = false;
            this.dirty = true;
        }
    }

    findSelectedOption(value) {
        let optionId;
        let dates;

        if (!value) {
            this.selectedOption = null;
            return;
        }

        // value is option id or legacy id
        if (this.dateOptions.getOption(value)) {
            optionId = value;
            // value is an option object
        } else if (value?.id && this.dateOptions.getOption(value.id)) {
            optionId = value.id;
            // If the value has valid dates, set the dates
            if (this.dateOptions.getDatesArray(value?.dates)) {
                dates = this.dateOptions.getDatesArray(value?.dates);
            }
            // If the value is a dateString, or dateArray, or a date Object, set the datesArray and select specific or range
        } else if (this.dateOptions.getDatesArray(value)?.length) {
            dates = this.dateOptions.getDatesArray(value);
            optionId = dates.length === 1 ? 'specific' : 'range';
        }

        if (dates) {
            this.dateOptions.setDates(optionId, dates);
        }
        if (optionId) {
            this.selectOption(optionId);
        }
    }

    onOptionClicked(option) {
        if (option.exact) {
            const dateArray = this.dateOptions.getOption(option.id).dates;
            this.tempDates = dateArray?.length ? dateArray.slice() : [];
            // Don't populate secondaryDate for single date calendar
            if (option.id === 'specific' && this.tempDates && this.tempDates[1]) {
                this.tempDates[1] = null;
            }
            this.openCalendar = option.id;
        } else {
            this.selectOption(option.id);
            this.closeOptions();
        }
    }

    onDatesChange(dates) {
        this.tempDates = [dates.selectedDate];
        if (this.openCalendar === 'range') {
            this.tempDates.push(dates.secondaryDate);
        }

        if (!this.invalidDates() && HIDE_DONE_BUTTON) {
            const option = this.dateOptions.getOption(this.openCalendar);
            if (JSON.stringify(option.dates) !== JSON.stringify(this.tempDates)) {
                option.dates = this.tempDates;
                this.selectOption(this.openCalendar, true);
                this.dispatchChangeEvent();
            }
            this.closeOptions();
        }
    }

    confirmDates() {
        if (HIDE_DONE_BUTTON || this.invalidDates()) return;

        const option = this.dateOptions.getOption(this.openCalendar);
        option.dates = this.tempDates;
        this.selectOption(this.openCalendar);
        this.closeOptions();
    }

    invalidDates() {
        return !(this.tempDates.length && this.tempDates?.every(date => date));
    }

    closeOptions() {
        this.openCalendar = null;
        this.tempDates = null;
        this.showOptions = false;
    }

    selectOption(optionId, suppressChangeEvent) {
        if (optionId === this.selectedOption?.id) return;

        this.selectedOption = this.dateOptions.getOption(optionId);

        if (!suppressChangeEvent) this.dispatchChangeEvent();
    }

    dispatchChangeEvent() {
        this.dispatchEvent(
            new CustomEvent('change', {
                detail: { value: this.selectedOption },
            })
        );
    }

    isOptionInvalid(option) {
        // Only want to disable the today option as the others are either relative,
        // or calendar options that all you to choose another date
        return (
            option.id === 'today' &&
            this.dateDisabledFunction &&
            this.dateDisabledFunction(new DateModel(option.dates[0]))
        );
    }

    get formattedDates() {
        if (!this.selectedOption) return '';
        if (this.hideRangeDates && !this.selectedOption.exact) return '';

        return this.dateOptions.formatDates(this.selectedOption.id, this.dateFormatter);
    }

    get selectedOptionLegacyDateString() {
        function twoDigit(n) {
            return n < 10 ? `0${n}` : n.toString();
        }
        if (!this.selectedOption.dates) {
            return this.selectedOption.formattedValue || '';
        }
        return this.selectedOption.dates
            .map(
                newDate =>
                    `${newDate.getFullYear()}-${twoDigit(newDate.getMonth() + 1)}-${twoDigit(
                        newDate.getDate()
                    )}`
            )
            .join(' - ');
    }

    get availableOptions() {
        return this.dateOptions.filteredOptions(this.optionOrder, this.range, this.required);
    }

    renderOption(opt) {
        return html`
            <li class="omega-datepicker-option" ?active=${this.openCalendar === opt?.id}>
                <button
                    class="omega-datepicker-option-button"
                    @click=${() => this.onOptionClicked(opt)}
                    @keyup=${event => {
                        if (event.key === 'Escape') {
                            this.closeOptions();
                        }
                    }}
                    .disabled=${this.isOptionInvalid(opt)}
                >
                    ${opt.label}
                </button>
                ${this.renderCalendar(opt)}
            </li>
        `;
    }

    renderDropdownList() {
        if (!this.showOptions) return nothing;

        return html`
            <ul class="omega-datepicker-list">
                ${this.availableOptions.map(opt => this.renderOption(opt))}
            </ul>
        `;
    }

    renderTabs() {
        if (!this.tabs) return nothing;
        const buttons = this.tabs.map(
            option =>
                html`
                    <button
                        class=${classMap({
                            'toggle-btn': true,
                            active: option.id === this.activeTab,
                        })}
                        @click=${() => {
                            this.activeTab = option.id;
                            this.dispatchEvent(
                                new CustomEvent('switchTab', {
                                    detail: { activeTab: option.id },
                                })
                            );
                        }}
                        role="tab"
                    >
                        ${option.label}
                    </button>
                `
        );
        return html` <div role="tablist" class="toggle-btn-wrapper">${buttons}</div> `;
    }

    renderCalendar(option) {
        if (this.openCalendar === option.id) {
            return html`
                <omega-calendar
                    .range=${option.id === 'range'}
                    .dateDisabledFunction=${this.dateDisabledFunction}
                    .secondaryDateDisabledFunction=${option.id === 'range'
                        ? this.secondaryDateDisabledFunction
                        : null}
                    @dateChange=${evt => this.onDatesChange(evt.detail)}
                    .selectedDate=${this.tempDates && this.tempDates[0]}
                    .secondaryDate=${this.tempDates && this.tempDates[1]}
                    .dateFormatter=${this.dateFormatter}
                    @blur=${() => this.confirmDates()}
                ></omega-calendar>
            `;
        }
        return nothing;
    }

    render() {
        return html`
            ${this.renderTabs()}
            <div class="omega-datepicker ${this.showOptions ? 'is-open' : ''}" id=${this.id}>
                <div
                    aria-invalid=${this.dirty && !this.valid}
                    disabled=${this.disabled}
                    class="omega-datepicker-main-input"
                    tabindex="0"
                    @click=${() => {
                        if (!this.disabled) {
                            this.showOptions = !this.open;
                        }
                    }}
                    @keyup=${event => {
                        if (event.key === 'Enter') {
                            this.showOptions = !this.open;
                        }
                    }}
                >
                    <span class="omega-datepicker-main-input-type"
                        >${this.selectedOption?.label || this.placeholder}</span
                    >
                    <span class="dates">${this.formattedDates}</span>
                </div>
                ${this.renderDropdownList()}
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: relative;
                height: 38px;
            }

            * {
                font-size: 13px;
                box-sizing: border-box;
            }

            .omega-datepicker {
                position: absolute;
                display: inline-block;
                width: 100%;

                --datepicker-day-button-size: 30px;
                --datepicker-month-button-width: 75px;
                --datepicker-month-button-height: 45px;
            }

            .omega-datepicker.is-open {
                z-index: 3;
                padding: 0px 10px 10px 10px;
                border: 1px solid var(--omega-input-default-border);
                background-color: #fff;
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
            }

            .omega-datepicker-main-input {
                min-width: 120px;
                border-radius: 4px;
                background-color: #fff;
                border: 1px solid #ccc;
                padding: var(--omega-datepicker-input-padding, 6px 10px);
                height: var(--omega-datepicker-input-height, 36px);
                cursor: pointer;
                position: relative;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 26px;
            }

            .omega-datepicker-main-input[aria-invalid='true'] {
                border: 2px solid var(--omega-input-error-border);
            }

            .omega-datepicker-main-input[disabled='true'] {
                opacity: 0.65;
                cursor: default;
            }

            .omega-datepicker-main-input::after {
                position: absolute;
                top: 50%;
                right: 8px;
                width: 0;
                height: 0;
                margin-top: -2px;
                border-width: 6px 6px 0;
                border-style: solid;
                border-color: #4b4742 transparent;
                content: '';
            }

            .omega-datepicker.is-open .omega-datepicker-main-input {
                display: none;
            }

            .omega-datepicker-main-input-type {
                white-space: nowrap;
            }

            .omega-datepicker-main-input .dates {
                color: rgb(109, 107, 107);
                white-space: nowrap;
            }

            .omega-datepicker:not(.is-open) .omega-datepicker-list {
                display: none;
            }

            .omega-datepicker-list {
                padding: 0;
                margin: 0 -10px -10px;
                list-style: none;
            }

            .omega-datepicker-list .omega-datepicker-option .omega-datepicker-option-button:focus {
                border: 2px solid var(--omega-primary-lighten-100);
                border-radius: 2px;
            }

            .omega-datepicker-option {
                padding: 0;
            }

            .omega-datepicker-option button {
                cursor: pointer;
                background: #fff;
                border: none;
                font-family: inherit;
                font-size: 13px;
                color: var(--omega-text-default);
                padding: 5px 10px;
                border-top: var(--omega-default-border);
                width: 100%;
                text-align: left;
            }

            .omega-datepicker-option button[disabled] {
                opacity: 0.65;
                cursor: not-allowed;
            }

            omega-calendar {
                margin: 12px 8px;
            }
            .confirm-buttons {
                margin-right: 12px;
                display: flex;
                justify-content: flex-end;
            }

            .confirm-buttons omega-button {
                margin: 0;
            }

            .omega-datepicker-option[active] button {
                font-size: 14px;
                font-weight: 500;
            }
            .toggle-btn-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 35em;
                margin-bottom: 5px;
            }
            .toggle-btn {
                font-family: var(--omega-font);
                font-size: 13px;
                background-color: #fff;
                border: 1px solid #dfe1e8;
                border-radius: 6px;
                flex: 1;
                padding: 6px;
                max-height: 30px;
            }
            .toggle-btn.active {
                background-color: #dceafb;
                border-color: var(--omega-primary-lighten-200);
                color: var(--omega-primary);
                font-weight: bold;
                margin-left: -6px;
                margin-right: -6px;
                height: 32px;
                max-height: 32px;
                z-index: 2;
            }
            .toggle-btn:not(.active) {
                padding: 6px;
            }
            .toggle-btn:focus {
                outline: none;
            }
        `;
    }
}

customElements.define('omega-datepicker', OmegaDatepicker);
export default OmegaDatepicker;
