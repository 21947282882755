// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

filtersModule.filter(
    'filterAccounts',
    () =>
        function (accounts, viewValue) {
            const out = [];
            if (viewValue) {
                angular.forEach(accounts, account => {
                    if (
                        !!account.accountDisplayLabel &&
                        account.accountDisplayLabel
                            .toLowerCase()
                            .indexOf(viewValue.toLowerCase() || '') !== -1
                    ) {
                        out.push(account);
                    }
                });
            }

            return out;
        }
);
