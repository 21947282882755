/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { exists } from '@treasury/utils';
import corePagingParameters from '../../../shared/utilities/core-paging-parameters.js';
import { downloadBlob } from '../../../utilities/file-handling.js';
import {
    mapAccountsBasedOnUserConfiguration,
    mapAchExceptionToFilterLookup,
    mapSaveAchFilterRequest,
    mapSearchAchFilterRequest,
    mapSearchAchFilterResponse,
} from '../../mappings/ach-filters/ach-filter-search.js';
import AccountRequests from '../../requests/account/account.js';
import AchFilterRequests from '../../requests/ach/ach-filter-requests.js';
import { getAllAchBanks } from '../../requests/ach/bank-service.js';
import { UserRequests } from '../../requests/users/users.js';

let exceptionCache = {};
export class AchFilterServices {
    static async search({ sort, startIndex, pageSize, parameters }) {
        const body = mapSearchAchFilterRequest(parameters);
        const pagingParameters = corePagingParameters('accountNumber', sort, startIndex, pageSize);

        const results = await AchFilterRequests.search({
            ...body,
            pagingParameters,
        });
        const data = mapSearchAchFilterResponse(results.achFilters);
        return { data, totalCount: results.paging.totalCount };
    }

    static async download(parameters) {
        const body = mapSearchAchFilterRequest(parameters.filter);
        const formattedParameters = { ...body, downloadType: parameters.type };
        const result = await AchFilterRequests.download(formattedParameters);

        const type = parameters.type.toLowerCase();
        const blob = new Blob([result], {
            type: type === 'pdf' ? `application/pdf` : `application/octet-stream`,
        });
        const filename = `ACH Exceptions Filter Rules.${type}`;
        return downloadBlob(blob, filename);
    }

    static getAccountSettings() {
        return AccountRequests.getCurrentUserAccountSettings();
    }

    static async getAccountsAll(accountSettings) {
        let accounts;
        try {
            accounts = await AchFilterRequests.getAccountsAll();
        } catch (ex) {
            console.log(ex);
        }
        return mapAccountsBasedOnUserConfiguration(accountSettings, accounts);
    }

    static async getAccountsCreate() {
        const accountSettings = await AccountRequests.getCurrentUserAccountSettings();
        let accounts;
        try {
            accounts = await AchFilterRequests.getAccountsCreate();
        } catch (ex) {
            console.log(ex);
        }
        return mapAccountsBasedOnUserConfiguration(accountSettings, accounts);
    }

    static async getAccountInfo(accountNumber, accountUniqueId) {
        const accounts = await AchFilterRequests.getAccounts();
        if (accountUniqueId) {
            return accounts.find(a => a.accountUniqueId === accountUniqueId);
        }
        return accounts.find(
            a => a.accountNumber.replace(/\D/g, '') === accountNumber.replace(/\D/g, '')
        );
    }

    static async getAccountUniqueIdById(id) {
        return UserRequests.getAccountUniqueIdById(id);
    }

    static async getAbaNumber() {
        return getAllAchBanks();
    }

    static async getAccountMasterSettings(accountUniqueId) {
        return AchFilterRequests.getAccountMasterSettings(accountUniqueId);
    }

    static async getAuditLog(AchFilterUniqueId) {
        return AchFilterRequests.getAuditLog(AchFilterUniqueId);
    }

    /**
     *
     * @param { import('@treasury/api/channel').SaveAchFiltersRequestModelDto} filter
     * @param { 'create' | 'edit'} mode
     */
    static saveFilter(filter, mode) {
        const body = mapSaveAchFilterRequest(filter);
        return Promise.all([
            DiContainer.getInstance().then(di => di.getAsync(AnalyticsService)),
            AchFilterRequests.saveFilter(body),
        ]).then(([analytics, response]) => {
            const event =
                mode === 'create'
                    ? AnalyticsEvent.AchFilterCreated
                    : AnalyticsEvent.AchFilterEdited;
            trackFilterMutation(analytics, body, event);
            return response;
        });
    }

    static async findAchExceptions(filter) {
        const exceptions = await AchFilterRequests.findAchExceptions(filter);
        exceptionCache = exceptions;
        return exceptions;
    }

    static async lookupException(filterException) {
        if (exceptionCache?.achExceptionDetails) {
            const accountUniqueId = await AchFilterServices.getAccountUniqueIdById(
                filterException.accountId
            );
            const mappedException = mapAchExceptionToFilterLookup(filterException, accountUniqueId);
            return Promise.resolve(mappedException);
        }
        return Promise.resolve([]);
    }

    static async mapException(exception) {
        const accountUniqueId = await AchFilterServices.getAccountUniqueIdById(exception.accountId);
        const mappedException = mapAchExceptionToFilterLookup(exception, accountUniqueId);
        return Promise.resolve(mappedException);
    }

    static approveFilter(AchFilterUniqueId) {
        return AchFilterRequests.approveFilter(AchFilterUniqueId);
    }

    /**
     *
     * @param { string } filterId
     * @returns
     */
    static deleteFilter(filterId) {
        return Promise.all([
            DiContainer.getInstance().then(di => di.getAsync(AnalyticsService)),
            AchFilterRequests.deleteFilter(filterId),
        ]).then(([analytics, result]) => {
            analytics.track(AnalyticsEvent.AchFilterDeleted, {
                filterId,
            });

            return result;
        });
    }

    static rejectFilter(AchFilterUniqueId) {
        return AchFilterRequests.rejectFilter(AchFilterUniqueId);
    }

    static async findEligibleApprovers(AchFilterUniqueId) {
        return AchFilterRequests.findEligibleApprovers(AchFilterUniqueId);
    }
}

/**
 * @param { AnalyticsService } analytics
 * @param { ReturnType<mapSaveAchFilterRequest>} filterDto
 * @param { AnalyticsEvent.AchFilterCreated | AnalyticsEvent.AchFilterEdited } event
 */
function trackFilterMutation(analytics, filterDto, event) {
    const {
        TransactionType: transactionType,
        ToAmount: toAmount,
        FromAmount: fromAmount,
        SecCode: secCode,
        AllowType: allowType,
    } = filterDto;
    const isRange = exists(toAmount) && exists(fromAmount) && toAmount !== fromAmount;

    analytics.track(event, {
        allowType,
        transactionType,
        secCode,
        fromAmount,
        toAmount,
        isRange,
    });
}
