/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AccountModel4Dto,
    AccountModelCollectionDto,
    AchFilterAccountMasterSettingsModelDto,
    AchFilterAuditHistoryModelCollectionDto,
    AchFilterAuditHistoryRequestModelDto,
    AchFilterUserModelCollectionDto,
    AchFiltersSearchRequestModelDto,
    AchFiltersSearchResponseModelDto,
    ApproveAchFiltersRequestModelDto,
    ApproveAchFiltersResponseModelDto,
    DeleteAchFiltersRequestModelDto,
    DeleteAchFiltersResponseModelDto,
    ErrorResponseModelDto,
    RejectAchFiltersRequestModelDto,
    RejectAchFiltersResponseModelDto,
    SaveAchFiltersRequestModelDto,
    SaveAchFiltersResponseModelDto,
    ValidateSaveAchFilterRequestRequestModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchFiltersClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * @description * Endpoint supports both single and bulk approvals. * To approve, the approval user must either be a super user, or have 'Approve ACH Filter Exception' entitlement for the accounts linked to the filters. * On approval, pending filter changes will be committed and saved to core if required approvals are met; otherwise, pending filter changes will await additional approvals. * For bulk approvals, partially successful commits are possible (i.e. one commit fails within the batch).  Failures for individual items within a batch will be reported in returned response details.
     *
     * @tags AchFilters
     * @name AchFiltersApprove
     * @summary Approves one or more ACH filters.
     * @request POST:/achFilters/approve
     */
    achFiltersApprove = (
        request: ApproveAchFiltersRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ApproveAchFiltersResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilters/approve`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description * To delete, the user must either be a super user, or have 'Delete ACH Filter Exception' entitlement for the account linked to the filter being deleted. * If no approvals are required, deletion will be processed immediately; otherwise, deletion will be pending approval.
     *
     * @tags AchFilters
     * @name AchFiltersDelete
     * @summary Deletes an ACH filter.
     * @request POST:/achFilters/delete
     */
    achFiltersDelete = (
        request: DeleteAchFiltersRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<DeleteAchFiltersResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilters/delete`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description * Endpoint supports both single and bulk rejections. * To reject, the rejecting user must either be a super user, or have 'Approve ACH Filter Exception' entitlement for the accounts linked to the filters.
     *
     * @tags AchFilters
     * @name AchFiltersReject
     * @summary Rejects one or more pending ACH filters (new or modified filters).
     * @request POST:/achFilters/reject
     */
    achFiltersReject = (
        request: RejectAchFiltersRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<RejectAchFiltersResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilters/reject`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilters
     * @name AchFiltersGetAccounts
     * @summary Retrieves accounts which are eligible for ACH Filter management.
     * @request GET:/achFilters/accounts
     */
    achFiltersGetAccounts = (params: RequestParams = {}) =>
        this.http.request<AccountModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilters/accounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilters
     * @name AchFiltersGetAllAccounts
     * @summary Retrieves accounts which are entitled and eligible for ACH Filter management.
     * @request GET:/achFilters/all/accounts
     */
    achFiltersGetAllAccounts = (params: RequestParams = {}) =>
        this.http.request<AccountModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilters/all/accounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilters
     * @name AchFiltersGetAccount
     * @summary Retrieves account which is entitled and eligible for ACH Filter management.
     * @request GET:/achFilters/accounts/{accountUniqueId}
     */
    achFiltersGetAccount = (accountUniqueId: string, params: RequestParams = {}) =>
        this.http.request<AccountModel4Dto, ErrorResponseModelDto>({
            path: `/achFilters/accounts/${accountUniqueId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilters
     * @name AchFiltersGetCreateAccounts
     * @summary Retrieves accounts which are entitled and eligible for ACH Filter creation.
     * @request GET:/achFilters/create/accounts
     */
    achFiltersGetCreateAccounts = (params: RequestParams = {}) =>
        this.http.request<AccountModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilters/create/accounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description To return the settings, the ACH filter master record must exist in core.  If the record does not exist, will return HTTP 500, and reason will be in the error response details.
     *
     * @tags AchFilters
     * @name AchFiltersGetAccountMasterSettings
     * @summary Retrieves ACH Filter master record settings for a company account.
     * @request GET:/achFilters/account/{accountUniqueId}/masterSettings
     */
    achFiltersGetAccountMasterSettings = (accountUniqueId: string, params: RequestParams = {}) =>
        this.http.request<AchFilterAccountMasterSettingsModelDto, ErrorResponseModelDto>({
            path: `/achFilters/account/${accountUniqueId}/masterSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Retrieves audit history for Ach filter ids passed in the request.  The Ach filter ids would typically be known by extracting from filters after invoking the 'Search' API. Considerations: * To retrieve audit history, caller must be entitled to the related Ach filters.  Requesting history when not entitled will result in an error for the entire request. * Audit history will generally be returned in chronological order for each filter, however it is the client's responsibility to sort audit history in the desired sequence. * Audit history is comprehensive, for all-time, and is not paged.  Performance therefore could be a consideration for large sets of filters; recommend pulling audit history for individual filters as-needed. * While audit history would typically exist for a filter, it is not guaranteed.  In the event no history can be found, the event audit collection for that filter will be empty.
     *
     * @tags AchFilters
     * @name AchFiltersGetAuditHistory
     * @summary Retrieves audit history for specified Ach Filters.
     * @request POST:/achFilters/auditHistory
     */
    achFiltersGetAuditHistory = (
        request: AchFilterAuditHistoryRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchFilterAuditHistoryModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilters/auditHistory`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description * Statuses are constrained to "Active" and "Inactive". * If no statuses are specified both will be added to the search criteria. * Adding statuses outside of the two above will be ignored.
     *
     * @tags AchFilters
     * @name AchFiltersSearch
     * @summary Searches for ACH filters using provided search criteria
     * @request POST:/achFilters/search
     */
    achFiltersSearch = (
        searchModel: AchFiltersSearchRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchFiltersSearchResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilters/search`,
            method: 'POST',
            body: searchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Http Status Codes: 406 - NotAcceptable: AchFilter Validation failure. 500 - InternalServerError: Any other failure. Validation Errors Code Message 3001 Account not specified. 3002 AchCompanyId must be no longer than 10 characters. 3003 AchCompanyName must be no longer than 16 characters. 3004 Disallow not valid for current Allow All Debits/Credits settings must be Allow. 3005 Allow not valid for current Allow All Debits/Credits settings must be Disallow. 3006 Allow type must be specified. 3007 Allow type must be either Allow or Disallow. 3008 Expiration date cannot be less than the current processing date 3009 Filter rule name is required. 3010 Filter rule name must be no longer than 30 characters. 3011 Filter rule name must be unique. 3012 To amount must be greater than from amount 3013 Remarks must be no longer than 50 characters. 3014 SEC Code must be a valid Code. 3012 To amount must be greater than from amount 3015 Transaction type is required. 3016 Transaction Type must be debit or credit. 3017 Must be a 9 digit number including leading zeros or contain at least one non-integer character. Note: If approvals are required, pending filter changes will be created and will not be committed to core until required approvals have been collected. If approval are not required then the change will be committed and saved to core immediately. To create a new filter, the AchFilterId should be null in the request and the user must either be a super user or have the 'Create ACH Filter Exception' entitlement for the account linked to the filter. To edit an existing filter, the AchFilterId should be completed in the request and the user must either be a super user or have the 'Edit ACH Filter Exception' entitlement for the account linked to the filter.
     *
     * @tags AchFilters
     * @name AchFiltersSave
     * @summary Saves an ACH filter using provided data
     * @request POST:/achFilters/save
     */
    achFiltersSave = (
        saveModel: SaveAchFiltersRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SaveAchFiltersResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilters/save`,
            method: 'POST',
            body: saveModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Validates a SaveAchFilterRequest using the same logic used to validated SaveAchFilterRequest in the SaveAchFilter API. Http Status Codes: 406 - NotAcceptable: AchFilter Validation failure. 500 - InternalServerError: Any other failure. Validation Errors Code Message 3001 Account not specified. 3002 AchCompanyId must be no longer than 10 characters. 3003 AchCompanyName must be no longer than 16 characters. 3004 Disallow not valid for current Allow All Debits/Credits settings must be Allow. 3005 Allow not valid for current Allow All Debits/Credits settings must be Disallow. 3006 Allow type must be specified. 3007 Allow type must be either Allow or Disallow. 3008 Expiration date cannot be less than the current processing date 3009 Filter rule name is required. 3010 Filter rule name must be no longer than 30 characters. 3011 Filter rule name must be unique. 3012 To amount must be greater than from amount 3013 Remarks must be no longer than 50 characters. 3014 SEC Code must be a valid Code. 3012 To amount must be greater than from amount 3015 Transaction type is required. 3016 Transaction Type must be debit or credit. 3017 Must be a 9 digit number including leading zeros or contain at least one non-integer character.
     *
     * @tags AchFilters
     * @name AchFiltersValidate
     * @summary Validates ACH filter for save using provided data
     * @request POST:/achFilters/validate
     */
    achFiltersValidate = (
        request: ValidateSaveAchFilterRequestRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SaveAchFiltersResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilters/validate`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilters
     * @name AchFiltersGetEligibleApprovers
     * @summary Retrieves list of eligible approvers for an ACH filter.
     * @request GET:/achFilters/eligibleApprovers/{achFilterUniqueId}
     */
    achFiltersGetEligibleApprovers = (achFilterUniqueId: string, params: RequestParams = {}) =>
        this.http.request<AchFilterUserModelCollectionDto, ErrorResponseModelDto>({
            path: `/achFilters/eligibleApprovers/${achFilterUniqueId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
