/* eslint-disable @treasury/style-includes-host-display */
/* eslint-disable class-methods-use-this */
import { html, nothing } from 'lit';
import StandardColumn from './standard-column.js';
import '../omega-checkbox.js';

// usage: <omega-table-checkbox-column field="selected" label="Select"></omega-table-checkbox-column>
export default class CheckboxColumn extends StandardColumn {
    constructor(...args) {
        super(...args);
        const [{ sortable, allowOne, field }, recordset] = args;
        this.field = field;
        this.recordset = recordset;
        this.fieldType = this.recordset.getFieldType(field);
        this.minWidth = this.fieldType.properties.minColumnWidth ?? 25;
        this.targetWidth = this.fieldType.properties.targetColumnWidth ?? 50;
        this.maxWidth = this.fieldType.properties.maxColumnWidth ?? 100;
        this.sortable = sortable;
        this.allowOne = allowOne;
    }

    get selectAllHtmlId() {
        return `select-all-${this.field}`;
    }

    isDisabled(record) {
        return (
            this.allowOne &&
            this.recordset.partialRecordsMatch(this.field, true) &&
            !record.getField(this.field)
        );
    }

    isCellChecked(record) {
        return record.getField(this.field);
    }

    columnToggle(field, e) {
        const value = e.detail.checked;
        this.recordset.setColumnValue(field, value);
    }

    cellToggle(record, e) {
        record.setField(this.field, e.detail.checked);
        e.stopPropagation();
    }

    isHeaderCheckboxIndeterminate() {
        return (
            this.recordset.partialRecordsMatch(this.field, true) ||
            this.recordset.filteredRecordCount() <= 0
        );
    }

    renderSortableTh(cursor) {
        const sortDirection = cursor.getColumnSortDirection(this);
        const thDisplayStyle = this.label ? '' : 'display: flex; ';
        const unsortedTopPos = this.label ? '2px' : '-2px';
        const sortedTopPos = this.label ? '4px' : '0px';
        return html` <style>
                @media print {
                    .no-print {
                        display: none;
                    }
                }
            </style>
            <th
                class="sortable ${this.cellClasses().join(' ')}"
                aria-sort="${sortDirection}"
                @click=${e => this.nextSortState(cursor, e)}
                style="${thDisplayStyle}align-items: center; text-align:${this.textAlign}"
            >
                <span>${this.renderThContents(cursor)}</span>

                <svg
                    class="unsorted-icon no-print"
                    style="top: ${unsortedTopPos}; margin-left: -10px;"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 75 40"
                >
                    <path
                        d="M50 34H14a1 1 0 00-1 2l18 23a1 1 0 002 0l18-23a1 1 0 00-1-2zm-36-4h36a1 1 0 001-2L33 5a1 1 0 00-2 0L13 28a1 1 0 001 2z"
                        data-name="29"
                    />
                </svg>

                <svg
                    class="sort-icon no-print"
                    style="top: ${sortedTopPos}; margin-left: -10px; width: 17px; padding: 3px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    viewBox="0 0 684 742.5"
                >
                    <path d="M0 0l342 594L684 0H0z" />
                </svg>
            </th>`;
    }

    renderThContents() {
        const checkbox = document.createElement('omega-checkbox');
        const labelStyles = this.label ? ' position: relative; top: -3px' : '';
        const allRecordsAreReadOnly = this.recordset.allRecords.every(record =>
            this.fieldType.readonly(record)
        );
        checkbox.id = this.selectAllHtmlId;
        checkbox.style = `display: inline-block;margin: 0;${labelStyles}`;
        checkbox.hideLabel = true;
        checkbox.checked = this.recordset.allRecordsMatch(this.field, true);
        checkbox.indeterminate = this.isHeaderCheckboxIndeterminate();
        checkbox.disabled = this.allowOne || allRecordsAreReadOnly;
        this.listenTo(checkbox, 'toggle', e => this.columnToggle(this.field, e));

        this.listenTo(this.recordset, 'updated', () => {
            checkbox.checked = this.recordset.allRecordsMatch(this.field, true);
            checkbox.indeterminate = this.isHeaderCheckboxIndeterminate();
        });

        return html` ${this.renderLabel()} ${checkbox} `;
    }

    renderTdContents(record, rowIndex) {
        if (this.fieldType.readonly(record))
            return html`<omega-field .record=${record} .field=${this.field}></omega-field>`;
        const checkbox = document.createElement('omega-checkbox');
        checkbox.style = 'margin-right: 15px;';
        checkbox.hideLabel = true;
        checkbox.value = rowIndex;
        checkbox.checked = this.isCellChecked(record);
        checkbox.disabled = this.fieldType.isDisabled(record) || this.isDisabled(record);

        this.listenTo(checkbox, 'toggle', e => this.cellToggle(record, e));

        this.listenTo(record, 'change', () => {
            this.isDisabled(record);
            checkbox.checked = this.isCellChecked(record);
        });

        return checkbox;
    }

    renderLabel() {
        if (!this.label) return nothing;
        return html`<label for="${this.selectAllHtmlId}" style="display: block; margin-top: 4px;"
            >${this.label}</label
        >`;
    }

    renderContentLoading() {
        return html`<omega-checkbox disabled></omega-checkbox>`;
    }
}
