/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    FiNumberModelDto,
    OfxCompanyModelDto,
    OfxUserAccessModelDto,
    SaveOfxCompanyRequestDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class OfxsettingsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags OfxSettings
     * @name OfxSettingsGetOfxEnabledFlagForFi
     * @summary Get the FI level flag that indicates whether OFX is Enabled or not
     * @request GET:/ofxsettings/getOfxEnabledFlagForFI
     */
    ofxSettingsGetOfxEnabledFlagForFi = (
        query: {
            fiId: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<FiNumberModelDto, any>({
            path: `/ofxsettings/getOfxEnabledFlagForFI`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags OfxSettings
     * @name OfxSettingsUpdateOfxEnabledFlagForFi
     * @summary Update the FI level flag that indicates whether OFX is Enabled or not
     * @request POST:/ofxsettings/updateOfxEnabledFlagForFI
     */
    ofxSettingsUpdateOfxEnabledFlagForFi = (
        query: {
            fiId: string | null;
            isOfxEnabled: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<FiNumberModelDto, any>({
            path: `/ofxsettings/updateOfxEnabledFlagForFI`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags OfxSettings
     * @name OfxSettingsGetOfxEnabledFlagForUser
     * @summary Get the flag that indicates whether OFX access is enabled for the user
     * @request GET:/ofxsettings/getOfxEnabledFlagForUser
     */
    ofxSettingsGetOfxEnabledFlagForUser = (
        query: {
            fiId: string | null;
            userName: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<OfxUserAccessModelDto, any>({
            path: `/ofxsettings/getOfxEnabledFlagForUser`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags OfxSettings
     * @name OfxSettingsUpdateOfxEnabledFlagForUser
     * @summary Update the flag that indicates whether OFX access is enabled for the user.
     * @request POST:/ofxsettings/updateOfxEnabledFlagForUser
     */
    ofxSettingsUpdateOfxEnabledFlagForUser = (
        query: {
            fiId: string | null;
            userName: string | null;
            ofxUserEnabled: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<OfxUserAccessModelDto, any>({
            path: `/ofxsettings/updateOfxEnabledFlagForUser`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags OfxSettings
     * @name OfxSettingsGetOfxEnabledFlagForCompany
     * @summary Get the flag that indicates whether OFX access is enabled for the company.
     * @request GET:/ofxsettings/getOfxEnabledFlagForCompany
     */
    ofxSettingsGetOfxEnabledFlagForCompany = (
        query: {
            /** The FI id. */
            fiId: string | null;
            /**
             * The company id.
             * @format int32
             */
            companyId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<OfxCompanyModelDto, any>({
            path: `/ofxsettings/getOfxEnabledFlagForCompany`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags OfxSettings
     * @name OfxSettingsUpdateOfxEnabledFlagForCompany
     * @summary Update the flag that indicates whether OFX access is enabled for the company.
     * @request POST:/ofxsettings/updateOfxEnabledFlagForCompany
     */
    ofxSettingsUpdateOfxEnabledFlagForCompany = (
        request: SaveOfxCompanyRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<OfxCompanyModelDto, any>({
            path: `/ofxsettings/updateOfxEnabledFlagForCompany`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
