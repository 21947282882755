// eslint-disable-next-line import/extensions
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';

export default class AchInternationalTemplateRequests {
    static async createInternationalAchTemplate(body) {
        return (await TmHttpClient.getInstance()).request(`ach/template/international`, {
            method: 'POST',
            body,
        });
    }

    static async updateInternationalAchTemplate(body) {
        return (await TmHttpClient.getInstance()).request(`ach/template/international`, {
            method: 'PUT',
            body,
        });
    }

    static async getTemplates(body) {
        return (await TmHttpClient.getInstance()).request('ach/templates/international/search', {
            method: 'POST',
            body,
        });
    }

    static async downloadReport(body) {
        return (await TmHttpClient.getInstance()).request('ach/template/international/download', {
            method: 'POST',
            body,
        });
    }

    static async downloadTemplateList(body) {
        return (await TmHttpClient.getInstance()).request(
            'ach/templates/international/search/download',
            {
                method: 'POST',
                body,
                responseType: TmHttpResponseType.Blob,
            }
        );
    }

    static async downloadTemplate(body) {
        return (await TmHttpClient.getInstance()).request('ach/template/international/download', {
            method: 'POST',
            body,
            responseType: TmHttpResponseType.Blob,
        });
    }

    static async getTemplateById(id) {
        return (await TmHttpClient.getInstance()).request(`ach/template/international/${id}`, {
            method: 'GET',
        });
    }

    static async updateTemplate(body) {
        return (await TmHttpClient.getInstance()).request('ach/template/international', {
            method: 'PUT',
            body,
        });
    }

    static async deleteTemplate(id) {
        return (await TmHttpClient.getInstance()).request(`ach/template/international/${id}`, {
            method: 'DELETE',
        });
    }

    static async getOptions() {
        return (await TmHttpClient.getInstance()).request('InternationalAchOptions', {
            method: 'GET',
        });
    }

    static async getApprovers(id) {
        return (await TmHttpClient.getInstance()).request(
            `ach/template/international/getapprovers/${id}`,
            {
                method: 'GET',
            }
        );
    }

    /**
     *
     * @param { import('@treasury/api/channel').AchTemplateApprovalModelDto} dto
     * @returns
     */
    static async approve(dto) {
        return (await TmHttpClient.getInstance()).request('ach/template/international/approve', {
            method: 'PUT',
            body: dto,
        });
    }

    static async reject(body) {
        return (await TmHttpClient.getInstance()).request('ach/template/international/reject', {
            method: 'PUT',
            body,
        });
    }
}
