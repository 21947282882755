import { LitElement, html, css, nothing } from 'lit';

class FilterPopupContents extends LitElement {
    static get properties() {
        return {
            resolve: Function,
            reject: Function,
            filters: Array,
            sortAndFilterRecord: Object,
        };
    }

    renderFilterField(filter) {
        return html`<omega-field
            field=${filter.field}
            .record=${this.sortAndFilterRecord}
        ></omega-field>`;
    }

    renderFilters() {
        if (this.filters) {
            return html` ${this.filters.map(filter => this.renderFilterField(filter))} `;
        }
        return nothing;
    }

    renderSortControl() {
        return html`
            <omega-field field="SORT_BY" .record=${this.sortAndFilterRecord}></omega-field>
        `;
    }

    render() {
        return html`
            <div class=""></div>
            <div id="popup-content">
                <div id="sort-and-filter">
                    ${this.renderSortControl()}
                    <hr />
                    ${this.renderFilters()}
                </div>
                <div class="flex-container">
                    <omega-button
                        id="sort-and-filter-cancel"
                        block
                        type="secondary"
                        @click=${() => this.reject('canceled')}
                        >Cancel</omega-button
                    >
                    <omega-button
                        id="sort-and-filter-ok"
                        block
                        type="primary"
                        @click=${() => this.resolve('apply')}
                        >OK</omega-button
                    >
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    background: #fff;
                    display: block;
                    padding: 12px;
                    border-radius: 6px;
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
                    border: 1px solid #eee;
                    position: relative;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
                :host::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: -9px;
                    width: 14px;
                    height: 14px;
                    background: #fff;
                    border: 1px solid #eee;
                    border-right-color: #fff;
                    border-bottom-color: #fff;
                    transform: rotate(45deg);
                }
                omega-field {
                    --omega-label: 14px;
                    --omega-label-font-weight: 400;
                }
                omega-button {
                    margin: 0;
                }
                omega-field {
                    margin-top: 16px;
                }
                .flex-container {
                    margin-top: 6px;
                    display: flex;
                    justify-content: space-between;
                }
                hr {
                    color: gray;
                    border: 0;
                    border-top: var(--omega-default-border);
                    height: 1px;
                    margin: 24px -16px;
                }
                #sort-and-filter {
                    min-width: 330px;
                }
                #popup-content {
                    padding: 0px;
                    background-color: white;
                }
                #popup-content .flex-container {
                    margin-top: 20px;
                    gap: 8px;
                }
            `,
        ];
    }
}

window.customElements.define('filter-popup-contents', FilterPopupContents);
