/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */

import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-alert';
import '@treasury/omega/components/progress/omega-progress';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import WireUploadFormatsClient from '../clients/wire-upload-formats-client.js';
import '../components/wire-upload-template-formatter.js';
import '../components/wire-upload-templates-table.js';

/**
 * This acts as an index to all of the 'Wire Upload Formats' view from the main navigation:
 * 1. the wire upload formats list / report
 * 2. the wire upload formats formatting tool
 */
class WireUploadFormatsContainer extends mix(LitElement).with(
    ListeningElementMixin,
    channelAlertMixin
) {
    static get properties() {
        return {
            institution: String,
            id: {
                type: String,
                attribute: false,
            },
            alert: Object,
            loading: Boolean,
            savingFormat: Boolean,
            glossary: Array,
            formatFields: Array,
        };
    }

    constructor() {
        super();
        this.format = null;
        this.client = new WireUploadFormatsClient();
        this.loading = false;
    }

    async firstUpdated() {
        const urlId = window.location.pathname.split('/')[5];
        await this.getWireUploadFormatsData();
        if (urlId) {
            this.id = urlId;
            this.getFormat(this.id);
        }
    }

    async getWireUploadFormatsData() {
        this.loading = true;
        try {
            await Promise.all([this.getEntitlements(), this.getFields()]);
        } catch (e) {
            this.showErrorAlert(e);
        } finally {
            this.loading = false;
        }
    }

    async getEntitlements() {
        this.entitlement = await this.client.hasEntitlement('Manage Wire File Format');
    }

    /**
     * fetches the format fields used to build the tiles and descriptions in
     * the template formatter
     */
    async getFields() {
        const fields = await this.client.getFormatFields();
        this.glossary = fields.glossary;
        this.formatFields = fields.tiles;
    }

    /**
     *
     * @param {int} id : the format Id from the link row selected or id in url path
     */
    async getFormat(id) {
        this.loading = true;

        if (id === 'new') {
            this.loading = false;
            return;
        }

        try {
            this.id = id;
            this.format = null;
            this.format = await this.client.getFormat(id, this.formatFields);
        } catch (e) {
            this.showErrorAlert(e);
        } finally {
            if (this.format) {
                this.goToUploadFormatter();
            }
            this.loading = false;
        }
    }

    /**
     *
     * @param {obj} format properties filled from the template formatter
     */
    async saveFormat(format) {
        this.savingFormat = true;
        this.format = { ...format };
        try {
            await this.client.saveFormat(format);
            this.alert = {
                title: '',
                visible: true,
                type: 'success',
                message: html`Your Wire Upload Format Template has been successfully saved!
                    <span class="link" @click=${this.goToUploadFormatsTable}>
                        Return to Wire Upload Formats
                    </span>`,
            };
        } catch (e) {
            this.showErrorAlert(e);
        } finally {
            this.savingFormat = false;
        }
    }

    /**
     *
     * @param {int} formatId: format Id from the delete action row selected
     */
    async deleteFormat(formatId) {
        this.loading = true;
        try {
            await this.client.deleteFormat(formatId);
            this.getWireUploadFormatsData();
        } catch (e) {
            this.showErrorAlert(e);
            this.loading = false;
        }
    }

    showErrorAlert(error) {
        const message =
            error instanceof Error ? error.message : 'An error occurred. Please try again.';
        this.alert = { ...error, message, type: 'error', visible: true };
    }

    async goToUploadFormatsTable() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        this.id = undefined;
        this.format = null;
        this.alert = { ...this.alert, visible: false };

        navService.navigate('payables.wire.wire-upload-formats', { id: null });
    }

    async goToUploadFormatter() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        navService.navigate(`payables.wire.wire-upload-formats`, {
            id: this.id,
        });
    }

    renderLoader() {
        if (this.loading) {
            return html`<omega-progress card class="large-loader"></omega-progress>`;
        }
        return nothing;
    }

    renderFormatter() {
        if (this.loading) return nothing;
        return html`<wire-upload-template-formatter
            id=${this.id}
            institution=${this.institution}
            .glossary=${this.glossary}
            .format=${this.format}
            .fields=${this.formatFields}
            .entitlement=${this.entitlement}
            .loading=${this.savingFormat}
            @cancelFormat=${this.goToUploadFormatsTable}
            @saveFormat=${e => this.saveFormat(e.detail.format)}
        ></wire-upload-template-formatter>`;
    }

    renderTable() {
        if (this.loading) return nothing;
        return html`<wire-upload-templates-table
            institution=${this.institution}
            .fetchFormats=${this.client.getFormats}
            .entitlement=${this.entitlement}
            @newFormat=${() => {
                this.id = 'new';
                this.goToUploadFormatter();
            }}
            @selectFormat=${e => this.getFormat(e.detail.formatId)}
            @deleteFormat=${e => this.deleteFormat(e.detail.formatId)}
        ></wire-upload-templates-table>`;
    }

    renderView() {
        if (this.id) {
            return this.renderFormatter();
        }
        return this.renderTable();
    }

    render() {
        return [this.renderAlert(), this.renderLoader(), this.renderView()];
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            omega-alert {
                --omega-alert-margin: 16px 16px -10px;
            }
            .large-loader {
                width: 50px;
                height: 50px;
                border-width: 5px;
            }
            .link {
                color: var(--omega-primary);
                text-decoration: underline;
                cursor: pointer;
            }
        `;
    }
}

customElements.define('wire-upload-formats-container', WireUploadFormatsContainer);
export default WireUploadFormatsContainer;
