import { AlarmClock } from '@treasury/alarm-clock';
import { CheckException } from '@treasury/domain/arp';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import { CHECK_EXCEPTION_CUTOFF } from '@treasury/domain/channel/types/arp/constants.js';
import CheckImageColumn from '@treasury/omega/components/table/check-image-column.js';

const columns = [
    {
        field: 'decisionChoice',
        label: 'Pay',
        type: 'radio-icon',
        value: 'Pay',
    },
    {
        field: 'decisionChoice',
        label: 'Return',
        type: 'radio-icon',
        value: 'Return',
    },
    {
        field: 'accountNumber',
        label: 'Account',
    },
    {
        field: 'checkNumber',
        label: 'Check Number',
        type: CheckImageColumn,
        fetchCheckImages: CheckExceptionsServices.fetchCheckImages,
    },
    {
        field: 'paidAmount',
        label: 'Paid Amount',
    },
    {
        field: 'issuedAmount',
        label: 'Issued Amount',
    },
    {
        field: 'postedDate',
        label: 'Posted Date',
    },
    {
        field: 'issuedDate',
        label: 'Issued Date',
    },
    {
        field: 'issuedPayee',
        label: 'Issued Payee',
    },
    {
        field: 'exceptionReason',
        label: 'Exception Reason',
    },
];

export function setColumns(
    isReviewStep,
    hasReturnReason,
    isDecisionActivity,
    isCheckExceptionV2,
    isNotYetDecisioned
) {
    const modifiedColumns = Array.from(columns);

    if (isDecisionActivity) {
        modifiedColumns.splice(0, 2, {
            field: 'decisionStatus',
            label: 'Decision',
        });
        if (hasReturnReason) {
            modifiedColumns.splice(1, 0, {
                field: 'returnReasonDescription',
                label: 'Return Reason',
            });
        }
        return modifiedColumns;
    }

    if (isReviewStep || AlarmClock.getInstance().isAfter(CHECK_EXCEPTION_CUTOFF)) {
        modifiedColumns.splice(0, 2, {
            field: 'decisionChoice',
            label: 'Decision',
        });
    }

    if (hasReturnReason) {
        if (isReviewStep || AlarmClock.getInstance().isAfter(CHECK_EXCEPTION_CUTOFF)) {
            modifiedColumns.splice(1, 0, {
                field: 'returnReason',
                label: 'Return Reasons',
            });
        } else {
            modifiedColumns.splice(
                2,
                0,
                {
                    field: 'returnReason',
                    label: 'Return Reasons',
                },
                {
                    field: 'returnReasonComment',
                    label: '',
                    type: 'icon',
                    icon: 'comment-o',
                    action: 'openCommentDialog',
                    conditional: record => {
                        if (record.getField('decisionChoice') === 'Pay') {
                            return {
                                icon: '',
                                action: '',
                            };
                        }
                        if (record.getField('comment') || record.getField('attachmentFileName'))
                            return {
                                icon: 'comment',
                                label: '',
                                action: 'openCommentDialog',
                            };
                        return false;
                    },
                }
            );
        }
    }

    // Add user decisioned icon column if it's V2, not the review modal, and not yet decisioned
    if (
        isCheckExceptionV2 &&
        (!isReviewStep || AlarmClock.getInstance().isAfter(CHECK_EXCEPTION_CUTOFF)) &&
        !isNotYetDecisioned
    ) {
        modifiedColumns.splice(0, 0, {
            field: 'userDecisionedToday',
        });
    }

    return modifiedColumns;
}
