import { getBatchRecipientSummaryFromDraft } from '@treasury/domain/channel/services/ach';
import {
    AchBatchDetail,
    AchBatchRecipientSummary,
    BatchDetails,
    Config,
} from '@treasury/domain/channel/types/ach';
import { Record, Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/progress/omega-progress';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import { RecordsetFilter } from '@treasury/omega/layouts/omega-form';
import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import { batchRecipientSummaryRecordset } from '../data/batch-recipient-summary-recordset';
import {
    getColumns,
    getFilters,
} from '../data/factories/multi-payment-batch/viewBatchSelectedRecipientsColumns';
import { AddendaDialog } from './addenda-dialog';

interface BatchDraftData {
    draftId: number;
    batchName: string;
}

export class BatchSelectedRecipientsModal extends ListeningElementMixin(LitElement) {
    @property()
    achPaymentDraftId!: number;

    @property()
    batchName!: string;

    @property({ type: Boolean, reflect: true })
    visible!: boolean;

    @property()
    selectedBatches!: Array<Record<BatchDetails>>;

    @property()
    config!: Config;

    @state()
    loading = false;

    @state()
    columns!: OmegaColumnDefinition<AchBatchRecipientSummary>[];

    @state()
    recipientsRecordset!: Recordset<AchBatchRecipientSummary>;

    @state()
    recordsetFilters: RecordsetFilter[] = [];

    @state()
    showAddendaDialog = false;

    @state()
    addendaRecord?: Record<AchBatchRecipientSummary>;

    selectedBatchDraftRequestData?: Array<BatchDraftData>;

    previousVisibleStatus = this.visible;

    client = new AchDomesticClient();

    updated(_changedProperties: PropertyValues<this>) {
        const visibleChanged = _changedProperties.has('visible');
        if (visibleChanged && this.visible) {
            this.init();
        }
    }

    async init() {
        this.loading = true;
        await this.getPaymentData();
        this.loading = false;
    }

    async getPaymentData() {
        if (this.selectedBatches.length) {
            try {
                this.selectedBatchDraftRequestData = this.selectedBatches.map(record => ({
                    draftId: record.getField('achPaymentDraftId'),
                    batchName: record.getField('name'),
                }));

                const achBatchDetail: AchBatchDetail[] = await this.client.getPaymentDrafts(
                    this.selectedBatchDraftRequestData
                );

                if (!achBatchDetail) throw new Error('batch not found');

                this.recipientsRecordset = batchRecipientSummaryRecordset(
                    getBatchRecipientSummaryFromDraft(achBatchDetail, this.achPaymentDraftId)
                );
                this.columns = getColumns(this);
                this.recordsetFilters = getFilters();
            } catch (e) {
                console.error(e);
            }
        }
    }

    close() {
        if (this.visible) this.visible = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    openAddendaDialog(r: Record<AchBatchRecipientSummary>) {
        this.addendaRecord = r;
        AddendaDialog.open(this);
    }

    handleAction(e: {
        detail: {
            record: Record<AchBatchRecipientSummary> | Record<BatchDetails>;
            rowIndex: number;
            action: (r: Record<any>) => void;
        };
    }) {
        e.detail.action(e.detail.record);
    }

    renderAddendaDialog() {
        return html`<addenda-dialog
            .record=${this.addendaRecord}
            .readonly=${true}
        ></addenda-dialog>`;
    }

    renderFilters() {
        if (!this.recipientsRecordset) return nothing;

        return this.recordsetFilters
            ? html`<omega-filter-bar
                  .recordset=${this.recipientsRecordset}
                  .filters=${this.recordsetFilters}
              >
              </omega-filter-bar> `
            : nothing;
    }

    renderRecipientsTable() {
        if (!this.recipientsRecordset) return nothing;
        const itemLabel = { singular: 'recipient', plural: 'recipients' };
        const filters = this.renderFilters();

        const table = html`<omega-table
            .recordset=${this.recipientsRecordset}
            .itemLabel=${itemLabel}
            .columnDefinitions=${this.columns}
            .filters=${this.recordsetFilters}
            @action=${this.handleAction}
        ></omega-table>`;
        return [filters, table];
    }

    renderTitle() {
        return html` <div class="title"><div class="form-title">Review Batches</div></div>`;
    }

    renderContent() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        if (this.columns) return html`${this.renderTitle()}${this.renderRecipientsTable()}`;
        return nothing;
    }

    render() {
        if (this.visible)
            return html`<omega-dialog
                    .id=${12345}
                    .dialogTitle="Payment Detail - Dialog Title"
                    @close=${this.close}
                    .open=${this.visible}
                >
                    <div slot="content">
                        <div class="dialog-content">${this.renderContent()}</div>
                    </div>
                </omega-dialog>
                ${this.renderAddendaDialog()} `;

        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                padding: 20px;
                padding-top: 0;
            }
            .error {
                color: var(--omega-error, red);
                text-align: center;
            }
            omega-field {
                --omega-label: 14px;
                --omega-field-control-font-size: 14px;
                --omega-field-control-font-weight: 700;
                --omega-field-control-font-weight-editable: 400;
                --omega-field-control-width: 100px;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-fields {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid var(--omega-secondary-lighten-300);
            }
            omega-field {
                margin: 10px;
            }
            omega-field-2 {
                margin: 10px;
            }
            fieldset {
                background: var(--omega-primary--focus);
                border: 2px solid var(--omega-primary-darken-100);
                border-radius: 3px;
            }

            legend {
                padding: 5px;
                color: var(--omega-white);
                font-size: var(--omega-label);
                background: var(--omega-primary-darken-100);
                border: 2px solid var(--omega-primary-darken-100);
                border-radius: 3px;
            }

            .form-title-container {
                margin: 0;
                display: flex;
                align-items: center;
            }
            .form-title-container .title {
                flex: 1;
            }
            .form-title {
                font-size: 24px;
                color: var(--omega-text-header);
            }
            .form-title-container .toolbar {
                display: flex;
                align-items: center;
            }

            .form-title-container .toolbar .content {
                padding-right: 2px;
            }

            hr {
                border: none;
                border-top: var(--omega-default-border);
            }
        `;
    }
}

customElements.define('batch-selected-recipients-modal', BatchSelectedRecipientsModal);
