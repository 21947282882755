import '@treasury/omega/components/progress/omega-progress.js';
import '@treasury/omega/layouts/omega-report';
import { printNode } from '@treasury/utils';
import { html, LitElement, nothing } from 'lit';
import ACHFilterDetailRowStyles from './ach-filter-detail-row-styles.js';
import AchFilterClient from './clients/ach-filter-client.js';

class ACHFilterDetailRow extends LitElement {
    static get properties() {
        return {
            record: Object,
            closeFunction: Object,
            detailRow: Object,
            loadingAudit: Boolean,
            client: Object,
        };
    }

    constructor() {
        super();
        this.client = new AchFilterClient();
        this.auditLog = nothing;
    }

    firstUpdated() {
        this.fetchAuditLogs();
    }

    _detailPrint() {
        const content = this.shadowRoot.querySelector('.detail-row-container');
        printNode('ACH Filter Detail', content, ACHFilterDetailRowStyles.printStyle());
    }

    async fetchAuditLogs() {
        try {
            this.loadingAudit = true;
            const achFilterId = this.record.getField('achFilterUniqueId');
            const results = await this.client.getAuditLog(achFilterId);
            const logs = results.map(event =>
                event.auditEvents.map(e => this.renderAuditRecord(e))
            );
            this.auditLog = html`<ul class="audit-logs">
                ${logs}
            </ul>`;
        } catch (e) {
            console.log(e);
        } finally {
            this.loadingAudit = false;
        }
    }

    renderProgress() {
        if (this.loadingAudit)
            return html`<div id="progress-wrapper"><omega-progress></omega-progress></div>`;
        return nothing;
    }

    renderAuditRecord(auditEvent) {
        const actionDate = auditEvent.actionDate
            ? new Date(auditEvent.actionDate)
                  .toLocaleDateString('en-US', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                  })
                  .concat(':')
            : '';
        return html`<li>${actionDate} ${auditEvent.actionUsername}: ${auditEvent.action}</li>`;
    }

    renderFieldWithDefaultValue(field) {
        const printedValue = this.record.print(field);
        return html`<span class="detail-row-value">${printedValue || 'N/A'}</span>`;
    }

    render() {
        return html`
            <div class="detail-row-container">
                <div class="detail-row-header">
                    <div>
                        <span class="detail-row-title">Filter Rule Detail</span>
                        <span class="header-group">
                            <label class="detail-row-label">Account</label>
                            <span class="detail-row-value"
                                >${this.record.print('accountNumber')}</span
                            >
                        </span>
                        <span class="header-group">
                            <label class="detail-row-label">Account Filter Settings</label>
                            <span class="detail-row-value"
                                >${this.record.print('accountMasterSettings')}
                            </span>
                        </span>
                        <omega-tooltip
                            icon="info-circle"
                            message="This is the account’s initial filter settings which is set by the Financial institution and cannot be edited"
                        ></omega-tooltip>
                    </div>
                    <div class="action-buttons">
                        <omega-button
                            type="icon"
                            icon="print"
                            class="print-button"
                            @click=${this._detailPrint}
                        ></omega-button>
                        <omega-button
                            class="close-button"
                            type="icon"
                            icon="close"
                            @click=${this.closeFunction}
                        ></omega-button>
                    </div>
                </div>
                <div class="rules-container">
                    <div class="rule-column">
                        <span class="rule-set">
                            <label class="detail-row-label">ACH Company Name</label>
                            ${this.renderFieldWithDefaultValue('achCompanyName')}
                        </span>
                        <span class="rule-set">
                            <label class="detail-row-label">Company ID</label>
                            ${this.renderFieldWithDefaultValue('achCompanyId')}
                        </span>
                    </div>
                    <div class="rule-column">
                        <span class="rule-set">
                            <label class="detail-row-label">SEC Code</label>
                            ${this.renderFieldWithDefaultValue('secCode')}
                        </span>
                        <span class="rule-set">
                            <label class="detail-row-label">Amount</label>
                            ${this.renderFieldWithDefaultValue('toAmount')}
                        </span>
                        <span class="rule-set">
                            <label class="detail-row-label">Transaction Type</label>
                            ${this.renderFieldWithDefaultValue('transactionType')}
                        </span>
                    </div>
                    <div class="rule-column">
                        <span class="rule-set">
                            <label class="detail-row-label">Expiration Date</label>
                            ${this.renderFieldWithDefaultValue('expirationDate')}
                        </span>
                        <span class="rule-set">
                            <label class="detail-row-label">Status</label>
                            ${this.renderFieldWithDefaultValue('detailStatus')}
                        </span>
                    </div>
                    <div class="audit-column">
                        <label class="audit-label">Audit</label>
                        <div class="audit-log">${this.auditLog}${this.renderProgress()}</div>
                    </div>
                </div>
            </div>
        `;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return ACHFilterDetailRowStyles.screenStyle();
    }
}

export default ACHFilterDetailRow;
window.customElements.define('ach-filter-detail-row', ACHFilterDetailRow);
