/* eslint-disable import/extensions */
import { exists } from '@treasury/utils';
import {
    BACKOFFICE_API,
    CHANNEL_API,
    ConfigurationService,
    boApiRoot,
    channelApiRoot,
} from '../config';

export const AUTH_TOKEN = 'Jha-Treasury-JhaAuthToken';

export const getAuthToken = () => window.sessionStorage.getItem(AUTH_TOKEN);
export const removeAuthToken = () => window.sessionStorage.removeItem(AUTH_TOKEN);
export const updateAuthToken = token => {
    if (!exists(token) || token === '') {
        removeAuthToken();
        return;
    }

    window.sessionStorage.setItem(AUTH_TOKEN, token);
};

/**
 * Determine if the request to the provided URL is an API request and requires an authentication token.
 * @param {string } url
 */
export async function isApiRequest(url) {
    const { apiRoot } = await ConfigurationService.getInstance();
    return (
        url.includes(apiRoot) ||
        // the following two checks are included for legacy behavior
        // but should not be necessary while using injected API URLs
        url.includes(BACKOFFICE_API) ||
        url.includes(CHANNEL_API)
    );
}

export const getChannelAPIPath = () => `${channelApiRoot}/`;
export const getBackOfficeAPIPath = () => `${boApiRoot}/`;
