/* eslint-disable import/no-extraneous-dependencies */
import FieldType from '@treasury/FDL/field-type.js';

export const achPaymentStatusRecurringMultiSelect = new FieldType().with
    .options({
        data: [
            { value: 'Pending Approval', key: 'PendingApproval', isChecked: true },
            {
                value: 'Approval Rejected',
                key: 'ApprovalRejected',
                isChecked: 'all',
            },
            { value: 'Cancelled', key: 'Cancelled', isChecked: true },
            { value: 'Scheduled', key: 'Scheduled', isChecked: true },
        ],
        text: 'value',
        value: record => record,
    })
    .and.multipleValues();
