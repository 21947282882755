import { exists } from '@treasury/utils';

export type AccountType = 'Checking' | 'Savings' | 'Loan' | 'Time Deposit';

export interface AccountDto {
    account: string;
    /**
     * The same as {@link AccountDto.number | `number`}.
     */
    accountDisplayLabel: string;
    accountUniqueId: string;
    allowLoanPaymentPastDue: boolean;
    availableBalance: number;
    availableBalanceFormatted: string;
    cifNumber: number;
    collectedBalance: number;
    collectedBalanceFormatted: string;
    currentBalance: number;
    currentBalanceFormatted: string;
    dailyLimit: number;
    id: number;
    isDeleted: boolean;
    loanPaymentAmountDue: number;
    loanPaymentDueDate: string;
    name: string;
    /**
     * The same as {@link AccountDto.accountDisplayLabel | `accountDisplayLabel`}
     */
    number: string;
    perTransactionLimit: number;
    showAccountNickName: boolean;
    status: string;
    transferAmount: number;
    transferMemo: string;
    type: AccountType;
    updatedBy: string;
    updatedDate: string;
}

export interface AccountsGroupDto {
    accounts: AccountDto[];
    id: number;
    name: string;
    updatedBy: string | null;
    updatedDate: string | null;
}

export const nullAccount: AccountDto = {
    account: '',
    accountDisplayLabel: '',
    accountUniqueId: '',
    allowLoanPaymentPastDue: false,
    availableBalance: 0,
    availableBalanceFormatted: '$0.00',
    cifNumber: 0,
    collectedBalance: 0,
    collectedBalanceFormatted: '$0.00',
    currentBalance: 0,
    currentBalanceFormatted: '$0.00',
    dailyLimit: 0,
    id: 0,
    isDeleted: false,
    loanPaymentAmountDue: 0,
    loanPaymentDueDate: '',
    name: '',
    number: '',
    perTransactionLimit: 0,
    showAccountNickName: false,
    status: '',
    transferAmount: 0,
    transferMemo: '',
    type: 'Checking',
    updatedBy: '',
    updatedDate: '',
};

export function isNullAccount(dto: AccountDto) {
    return !exists(dto.id) || dto.id < 1;
}
