import { id, date, string } from '@treasury/policy/primitives';
import { companyName, secCode } from '@treasury/policy/company';
import { userIdentification } from '@treasury/policy/user';
import { accountType, accountNumber } from '@treasury/policy/account.js';
import { achCompanyName } from '@treasury/policy/ach';

export const reportFields = {
    achNotificationOfChangeUniqueId: id.thatIs.readOnly(),
    effectiveDate: date.thatIs.readOnly(),
    receivedDate: date.thatIs.readOnly(),
    achCompanyName: companyName.thatIs.readOnly(),
    originalAccountType: accountType.thatIs.readOnly(),
    updatedAccountType: string.thatIs.readOnly(),
    fullReturnReason: string.thatIs.readOnly(),
    returnReasonDescription: string.thatIs.readOnly(),
    originalIdentificationNumber: userIdentification.thatIs.readOnly(),
    updatedIdentificationNumber: userIdentification.thatIs.readOnly(),
    originalRecipientName: achCompanyName.thatIs.readOnly(),
    updatedRecipientName: achCompanyName.thatIs.readOnly(),
    originalRoutingNumber: accountNumber.thatIs.readOnly(),
    updatedRoutingNumber: accountNumber.thatIs.readOnly(),
    originalSecCode: secCode.thatIs.readOnly(),
    updatedSecCode: secCode.thatIs.readOnly(),
    traceNumber: accountType.thatIs.readOnly(),
    dateOfDeath: date.thatIs.readOnly(),
};

export const detailFields = {
    ...reportFields,
    companyEntryDescription: string.thatIs.readOnly(),
    originalAccountNumber: accountNumber.thatIs.readOnly(),
    updatedAccountNumber: accountNumber.thatIs.readOnly(),
    fullReturnReason: string.thatIs.readOnly(),
};
