import { BankModelDto, BeneficiaryModelDto } from '@treasury/api/channel';

export const nullBankModel: BankModelDto = {
    id: 0,
    updatedDate: '0001-01-01T00:00:00',
    bankId: '',
    bankIdType: '',
    name: '',
    /** @format int32 */
    addressId: 0,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    postalCode: '',
    postalCodeExtension: '',
    country: '',
    countryName: '',
    isInternational: false,
    notes: '',
    onUs: false,
};

export const nullBeneficiary: BeneficiaryModelDto = {
    id: 0,
    updatedDate: '0001-01-01T00:00:00',
    accountNumber: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    notes: '',
    audit: '',
    /** @format date-time */
    createdDate: '0001-01-01T00:00:00',
    status: '',
    /** @format int32 */
    numberOfApprovalsNeeded: 0,
    bank: nullBankModel,
    intermediaryBanks: [],
    isEdited: false,
    isTemplateBeneficiary: false,
    postalCodeExtension: '',
    permissions: [],
    isInternational: false,
    isUserApprovalLimitLess: false,
    completedApprovals: [],
    availableApprovers: [],
    /** @format int32 */
    completedApprovalCount: 0,
    isForeignCurrency: false,
    currencyType: '',
    isFreeForm: false,
};
