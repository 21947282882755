/* eslint-disable sonarjs/no-nested-template-literals */
/* cspell:ignore initial Checkexception sublinks */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { AlarmClock } from '@treasury/alarm-clock';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { CheckException } from '@treasury/domain/arp';
import { PositivePayExceptionRequests } from '@treasury/domain/channel/requests/positive-pay/positive-pay-exception-requests.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import {
    ACCEPTED_ATTACHMENT_EXTENSIONS,
    CHECK_EXCEPTION_CUTOFF,
    CHECK_EXCEPTION_CUTOFF_MINUS_30,
    CHECK_EXCEPTION_START,
    CORRECTION_REQUEST_OPTION,
    EXCEPTION_REASONS,
    RETURN_REASON_OPTION,
} from '@treasury/domain/channel/types/arp/constants.js';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components';
import { openDialog } from '@treasury/omega/components/dialog';
import '@treasury/omega/components/omega-file-upload.js';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { AlertMixin } from '@treasury/omega/mixins';
import { amountRange, dateRangePickerNoDefault, string } from '@treasury/policy/primitives';
import { LitElement, css, html, nothing } from 'lit';
import '../../components/blocking-loader.js';
import checkExceptionFields from './data/check-exception-fields.js';
import { setColumns } from './data/table-columns.js';
import { createCheckExceptionDetail } from './parts/check-exception-detail.js';

class CheckExceptions extends AlertMixin(ListeningElementMixin(LitElement)) {
    static get properties() {
        return {
            institution: String,
            loading: Boolean,
            loadingFilters: Boolean,
            actions: Array,
            filters: Array,
            exceptions: Object,
            recordset: Object,
            arpExceptionAccounts: Array,
            returnReasons: Array,
            options: Array,
            localFilters: Array,
            isSavingExceptions: Boolean,
            alert: Object,
            changedRecords: Array,
            isReturnReasonUnchanged: Boolean,
            commentSaveButtonDisabled: Boolean,
            columns: Array,
            loadingImages: Boolean,
            _hasAttachmentEntitlement: { state: true, type: Boolean },
            cutoffMessage: String,
        };
    }

    constructor() {
        super();
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
        this.loadingFilters = true;
        this.isSavingExceptions = false;
        this.isReturnReasonUnchanged = true;
        this.isAfterCutoff = this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF);
        this.itemLabel = { singular: 'check exception' };
        this.cutoffMessage = '';
    }

    get fiClock() {
        return AlarmClock.getInstance();
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.fiClock, CHECK_EXCEPTION_START, () => this.requestUpdate());
        this.listenTo(this.fiClock, CHECK_EXCEPTION_CUTOFF_MINUS_30, this.setCutoffMessage());
        this.listenTo(this.fiClock, CHECK_EXCEPTION_CUTOFF, async () => {
            this.recordset.allRecords.forEach(record => {
                record.setField('isPastCutOffTime', true);
            });
            this.columns = [...setColumns(false, this.hasReturnReason)];
            this.setCutoffMessage();
            this.requestUpdate();
        });
        this.listenTo(document, 'correctionRequestError', e => {
            const error = e.detail;
            const message = error instanceof Error ? error.message : 'An unknown error occurred.';
            const code = error instanceof TmApiError ? error.code : undefined;

            this.alert = {
                title: '',
                code,
                message,
                visible: true,
                type: 'error',
            };
        });
        this.listenTo(document, 'correctionRequestSuccess', () => {
            this.alert = {
                title: '',
                message: 'Correction Request saved successfully!',
                visible: true,
                code: '',
                type: 'success',
            };
        });
    }

    async firstUpdated() {
        await this.makeRequests();
        this._hasAttachmentEntitlement = await EntitlementsService.instance.hasEntitlement(
            'Feature.PositivePay.CheckException.Attachments'
        );
        this.columns = [...setColumns(false, this.hasReturnReason)];
        this.actions = {
            openCommentDialog: async record => {
                this.commentSaveButtonDisabled = !record.getField('comment');
                this.listenTo(record, 'change', ({ detail }) => {
                    const doneButton = document.querySelector(
                        'omega-dialog omega-button:not([type="icon"])'
                    );
                    if (
                        ((detail.field === 'commentToSave' && record.getField('commentToSave')) ||
                            (detail.field === 'fileToUpload' && record.getField('fileToUpload'))) &&
                        doneButton
                    ) {
                        doneButton.disabled = false;
                    }
                });
                const saveResult = await openDialog({
                    title: 'Comment',
                    content: this.renderCommentDialog(record),
                    actions: resolve => html`
                        <omega-button
                            type="primary"
                            .loading=${this.isSavingExceptions}
                            @click=${() => resolve('confirm')}
                            .disabled=${this.commentSaveButtonDisabled}
                        >
                            Done
                        </omega-button>
                    `,
                });

                if (saveResult === 'confirm') {
                    record.setField('comment', record.getField('commentToSave'));
                    this.saveAttachmentAndComment.bind(this)(record);
                } else if (saveResult === null) {
                    record.setField('fileToUpload', null);
                    // Delay to match omega dialog close animation so field doesn't appear empty before dialog disappears
                    setTimeout(() => record.setField('commentToSave', null), 500);
                }
            },
        };
        this.fields = checkExceptionFields(
            this.returnReasons,
            this.isAfterCutoff,
            this.hasReturnReason
        );
        this.recordset = new Recordset(this.fields, CheckExceptionsServices.searchCheckExceptions);
        this.listenTo(this.recordset, 'updated', () => {
            this.isReturnReasonUnchanged = this.recordset.allRecords.every(
                record => record.getField('returnReason') === record.initialValues?.returnReason
            );
            this.recordset.allRecords.forEach(record => {
                if (
                    record.getField('decisionChoice') === 'Pay' &&
                    record.getField('returnReason') !== null
                ) {
                    record.setField('returnReason', null);
                }
            });
            this.requestUpdate();
        });
        this.filters = [
            {
                field: 'account',
                fieldType: string.with
                    .options({
                        data: this.arpExceptionAccounts,
                        text: record => record.accountDisplayLabel,
                        value: record => record.accountUniqueId,
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .thatHas.label('Account')
                    .as.tag('omega-select'),
                value: this.arpExceptionAccounts.map(account => account.accountUniqueId),
            },
            {
                field: 'checkNumber',
                fieldType: string.with
                    .defaultValue('')
                    .thatHas.label('Check Number')
                    .as.tag('omega-input'),
            },
            {
                field: 'paidAmount',
                fieldType: amountRange.with
                    .schema('range')
                    .thatHas.label('Paid Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0],
            },
            {
                field: 'issuedAmount',
                fieldType: amountRange.with
                    .schema('range')
                    .thatHas.label('Issued Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0],
            },
            {
                field: 'postedDate',
                fieldType: dateRangePickerNoDefault.and
                    .label('Posted Date')
                    .as.tag('omega-datepicker'),
            },
            {
                field: 'issuedDate',
                fieldType: dateRangePickerNoDefault.thatHas
                    .label('Issued Date')
                    .as.tag('omega-datepicker'),
            },
            {
                field: 'issuedPayee',
                fieldType: string.thatHas.label('Issued Payee').as.tag('omega-input'),
            },
            {
                field: 'exceptionReasons',
                fieldType: string.with
                    .label('Exception Reason')
                    .with.options({
                        data: EXCEPTION_REASONS.map(reason => ({
                            text: reason,
                            value: reason === 'No Reason' ? '' : reason,
                        })),
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .as.tag('omega-select'),
            },
        ];
    }

    async makeRequests() {
        try {
            this.arpExceptionAccounts =
                await PositivePayExceptionRequests.getArpExceptionAccounts();

            if (this.arpExceptionAccounts && this.arpExceptionAccounts.length === 0) {
                this.alert = {
                    message: 'No ARP exception accounts found',
                    visible: true,
                    type: 'warn',
                };
            }

            this.options = await PositivePayExceptionRequests.getPositivePayCompanyConfiguration();
            this.hasRequestCorrection =
                this.hasOption(CORRECTION_REQUEST_OPTION) &&
                EntitlementsService.instance.hasEntitlement('Feature.PositivePay.ReturnReason');
            this.hasReturnReason =
                this.hasOption(RETURN_REASON_OPTION) &&
                (await EntitlementsService.instance.hasEntitlement(
                    'Feature.PositivePay.ReturnReason'
                ));
            if (this.hasReturnReason) {
                this.returnReasons = await PositivePayExceptionRequests.getReturnReasons();
                this.defaultReturnReason = this.returnReasons.find(e => e.isDefault === true);
            }
        } catch (e) {
            this.alert = {
                code: e instanceof TmApiError ? e.code : undefined,
                message: e instanceof Error ? e.message : 'An error occurred ',
                visible: true,
            };
        } finally {
            this.loadingFilters = false;
        }
    }

    async saveCheckExceptions() {
        this.isSavingExceptions = true;
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Saving decisions...',
            code: '',
            type: 'time-sensitive',
        };
        try {
            const values = this.recordset.allRecords.map(record => record.values);
            const totalAmount = values.reduce(
                (total, exception) => (total += exception.paidAmount || 0),
                0
            );

            await CheckExceptionsServices.saveCheckExceptions(values);

            const analyticsService = (await DiContainer.getInstance()).get(AnalyticsService);
            analyticsService.track(AnalyticsEvent.CheckExceptionReview, {
                itemCount: values.length,
                totalAmount,
            });

            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Decisions saved successfully!',
                code: '',
                type: 'success',
            };
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                message: e instanceof Error ? e.message : 'Could not save. Please try again.',
                code: e instanceof TmApiError ? e.errorCode : undefined,
                type: 'error',
            };
        }
        this.isSavingExceptions = false;
        this.shadowRoot.querySelector('#check-exception-report').forceFetch();
    }

    async setCutoffMessage() {
        await this.fiClock.checkReadiness();
        if (
            this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF_MINUS_30) &&
            this.fiClock.isBefore(CHECK_EXCEPTION_CUTOFF)
        ) {
            this.cutoffMessage = `Check exception items will be disabled for decisioning at ${this.fiClock.getAlarm(
                CHECK_EXCEPTION_CUTOFF
            )} Cut-Off time (${this.fiClock.timeZone}).`;
        } else if (this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF)) {
            this.cutoffMessage = `Check exception items are disabled because the current time is past the ${this.fiClock.getAlarm(
                CHECK_EXCEPTION_CUTOFF
            )} Cut-Off time (${this.fiClock.timeZone})`;
        } else if (this.fiClock.isBefore(CHECK_EXCEPTION_START)) {
            this.cutoffMessage = `Check exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                CHECK_EXCEPTION_START
            )} (${this.fiClock.timeZone}).`;
        } else {
            this.cutoffMessage = undefined;
        }
    }

    async saveAttachmentAndComment(record) {
        try {
            await CheckExceptionsServices.saveAttachmentAndComment(
                new CheckException({ ...record.values }),
                record.getField('comment'),
                record.getField('fileToUpload')
            );
            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Comment saved successfully!',
                type: 'success',
            };
            if (record.getField('fileToUpload')) {
                record.setField('attachmentFileName', record.getField('fileToUpload').name);
            }
        } catch (e) {
            console.log(e);
            this.alert = {
                ...this.alert,
                visible: true,
                message: e instanceof Error ? e.message : 'Could not save. Please try again.',
                code: e instanceof TmApiError ? e.errorCode : undefined,
                type: 'error',
            };
            record.setField('fileToUpload', null);
        }
    }

    async handleDownload(e) {
        this.downloading = true;
        try {
            await PositivePayExceptionRequests.downloadArpExceptionsList(e.detail, 'OpenItems');
        } catch (error) {
            const message =
                error instanceof Error ? error.message : 'Could not download. Please try again.';
            this.alert = { ...this.alert, message, visible: true, type: 'error' };
        } finally {
            this.downloading = false;
        }
    }

    async getCheckExceptionAttachment(arpExceptionDetailUniqueId) {
        await CheckExceptionsServices.getCheckExceptionAttachment(arpExceptionDetailUniqueId);
    }

    disableFunction() {
        return (
            this.fiClock.isBefore(CHECK_EXCEPTION_START) ||
            this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF)
        );
    }

    hasOption(optionName) {
        const foundOption = this.options.find(option => option.name === optionName);
        return foundOption && foundOption.value === '1';
    }

    hasDecisionChanged(recordset) {
        return recordset.allRecords.some(
            record => record.getField('initialDecision') !== record.getField('decisionChoice')
        );
    }

    setDefaultReturnReason() {
        this.recordset.allRecords.forEach(record => {
            if (
                record.getField('decisionChoice') === 'Return' &&
                record.getField('protected') === 'N' &&
                !record.getField('returnReason')
            ) {
                record.setField('returnReason', this.defaultReturnReason?.returnReasonUniqueId);
            }
        });
    }

    renderBlockingLoader() {
        return this.loadingImages ? html`<blocking-loader></blocking-loader>` : nothing;
    }

    renderReviewTable() {
        return html`
            <div style="padding: 0 10px;">
                <omega-table
                    autostart
                    .recordset=${this.recordset}
                    .columnDefinitions=${[...setColumns(true, this.hasReturnReason)]}
                ></omega-table>
            </div>
        `;
    }

    renderFileControl(record) {
        if (!this._hasAttachmentEntitlement) return nothing;

        const fileName = record.getField('attachmentFileName');
        return fileName
            ? html`<omega-button
                      type="icon"
                      icon="download"
                      @click=${() =>
                          this.getCheckExceptionAttachment(
                              record.values.arpExceptionDetailUniqueId
                          )}
                  >
                      ${fileName} </omega-button
                  ><omega-file-upload
                      icon="true"
                      buttonText="Replace Attachment"
                      buttonIcon="retweet"
                      .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                      @filesUploaded=${e => {
                          record.setField('fileToUpload', e.detail.files[0]);
                      }}
                  ></omega-file-upload>`
            : html` <omega-file-upload
                  icon="true"
                  buttonText="Add Attachment"
                  buttonIcon="paperclip"
                  .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                  @filesUploaded=${e => {
                      record.setField('fileToUpload', e.detail.files[0]);
                  }}
              ></omega-file-upload>`;
    }

    renderCommentDialog(record) {
        record.setField('commentToSave', record.getField('comment'));
        return html`
            <div style="padding: 0 10px;">
                <omega-field field="commentToSave" .record=${record}></omega-field>
                ${this.renderFileControl(record)}
            </div>
        `;
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html` <div class="alert-wrapper">
            <omega-alert
                type=${type}
                title=${title}
                posture=${posture}
                ?isVisible=${visible}
                @close=${() => {
                    this.alert = { ...this.alert, visible: false };
                }}
            >
                ${renderedCode} ${message} ${renderedTime} ${actions}
            </omega-alert>
        </div>`;
    }

    renderOutsideCutoffTime(message) {
        return html`
            <div class="outside-cutoff-time">
                <div class="header">
                    <div>
                        <h2 class="tm-title">Check Exceptions</h2>
                        <div class="sublinks">
                            <a
                                class="tm-link"
                                href="${`${window.location.origin}/${this.institution}/payables/arp/check-exceptions/decision-activity`}"
                                >Decision Activity</a
                            >
                            <a
                                class="tm-link"
                                href="${`${window.location.origin}/${this.institution}/payables/arp/issued-items-activity`}"
                                >Issued Items Activity</a
                            >
                        </div>
                    </div>
                </div>
                <p class="message">${message}</p>
            </div>
        `;
    }

    renderCutoffAlert() {
        if (!this.cutoffMessage) return nothing;
        return html` <omega-alert isVisible type="info"> ${this.cutoffMessage} </omega-alert> `;
    }

    renderReturnReasonInfo() {
        if (this.hasReturnReason) {
            return html`
                <p>
                    If no return reason is selected the default reason of
                    <strong>${this.defaultReturnReason?.description}</strong>
                    will be applied at cutoff.
                </p>
            `;
        }
        return nothing;
    }

    render() {
        if (this.fiClock.isBefore(CHECK_EXCEPTION_START)) {
            return this.renderOutsideCutoffTime(
                `Exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                    CHECK_EXCEPTION_START
                )} (${this.fiClock.timeZone}).`
            );
        }

        if (!this.recordset || this.loadingFilters || !this.columns) return nothing;
        return html`
            ${this.renderBlockingLoader()} ${this.renderAlert()}
            <omega-report
                id="check-exception-report"
                title="Check Exceptions"
                .filters=${this.filters}
                .fields=${this.fields}
                .columns=${this.columns}
                .recordset=${this.recordset}
                .itemLabel=${this.itemLabel}
                .localFilters=${this.localFilters}
                .detailFunction=${createCheckExceptionDetail(this.hasRequestCorrection)}
                .tableLoading=${this.isSavingExceptions}
                .actions=${this.actions}
                .reportLinks=${[
                    {
                        title: 'Check Exceptions - Decision Activity',
                        url: `${window.location.origin}/${this.institution}/payables/arp/check-exceptions/decision-activity`,
                    },
                    {
                        title: 'Issued Items Activity',
                        url: `${window.location.origin}/${this.institution}/payables/arp/issued-items-activity`,
                    },
                ]}
                .reportActions=${this.disableFunction()
                    ? []
                    : [
                          {
                              type: 'approve',
                              label: 'Review',
                              action: async () => {
                                  this.recordset.allRecords.forEach(record =>
                                      record.setField('step', 1)
                                  );
                                  this.setDefaultReturnReason();
                                  const saveResult = await openDialog({
                                      title: 'Review Decisions',
                                      content: this.renderReviewTable(),
                                      actions: resolve => html`
                                          <omega-button
                                              type="approve"
                                              .loading=${this.isSavingExceptions}
                                              @click=${() => resolve('confirm')}
                                          >
                                              Confirm Decisions
                                          </omega-button>
                                          <omega-button @click=${() => resolve('cancel')}>
                                              Cancel
                                          </omega-button>
                                      `,
                                  });
                                  if (saveResult === 'confirm') this.saveCheckExceptions();
                                  this.recordset.allRecords.forEach(record =>
                                      record.setField('step', 0)
                                  );
                              },
                              isDisabled: () => this.isSavingExceptions,
                          },
                          {
                              type: 'secondary',
                              label: 'Reset',
                              action: () => {
                                  this.recordset.allRecords.forEach(record => record.reset());
                              },
                              isDisabled: () =>
                                  !this.hasDecisionChanged(this.recordset) ||
                                  this.isSavingExceptions,
                          },
                      ]}
                .options=${['save', 'print', 'download']}
                .downloadOptions=${['CSV', 'PDF']}
                @reportDownload=${this.handleDownload}
                @loadingCheckImage=${e => {
                    this.loadingImages = e.detail;
                }}
                flyout
                autostart
            >
                <div style="margin:0px 16px;" slot="above-table">
                    ${this.renderCutoffAlert()} ${this.renderReturnReasonInfo()}
                    <omega-filter-bar
                        .recordset=${this.recordset}
                        .itemLabel=${this.itemLabel}
                        @change=${({ detail }) => {
                            this.localFilters = detail;
                        }}
                    ></omega-filter-bar>
                </div>
            </omega-report>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inherit;
                }
                .outside-cutoff-time .header {
                    display: flex;
                    border-bottom: 1px solid var(--omega-light-grey);
                    align-items: center;
                    height: 54px;
                    background-color: #fff;
                }
                .outside-cutoff-time-header .sublinks {
                    display: flex;
                }
                .outside-cutoff-time .message {
                    text-align: center;
                    font-size: 15px;
                }
                .tm-title {
                    color: var(--omega-text-header);
                    padding: 0;
                    margin: 0 16px;
                    display: flex;
                    font-size: 18px;
                    font-weight: 500;
                }
                .tm-link {
                    color: var(--omega-primary);
                    text-decoration: none;
                    border-left: 1px solid var(--omega-light-grey);
                    padding-left: 16px;
                    padding-right: 16px;
                    font-size: 13px;
                    white-space: nowrap;
                }
                .tm-link:first-of-type {
                    border: none;
                }
            `,
        ];
    }
}

window.customElements.define('check-exceptions', CheckExceptions);
export default CheckExceptions;
