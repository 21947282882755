/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { NavigationDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class ActivityClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityNavigationAudit
     * @summary Navigations the audit.
     * @request POST:/activity
     */
    activityNavigationAudit = (navigation: NavigationDto, params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/activity`,
            method: 'POST',
            body: navigation,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Activity
     * @name ActivityUserInteractionAudit
     * @summary Users the interaction audit.
     * @request GET:/activity
     */
    activityUserInteractionAudit = (
        query: {
            /**
             * Type of the resource.
             * @format int32
             */
            resourceType: number;
            /**
             * The audit code.
             * @format int32
             */
            auditCode: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/activity`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Activity
     * @name ActivityNavigationAudit2
     * @summary Navigations the audit.
     * @request POST:/activity/navigationAudit
     */
    activityNavigationAudit2 = (navigation: NavigationDto, params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/activity/navigationAudit`,
            method: 'POST',
            body: navigation,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Activity
     * @name ActivityUserInteractionAudit2
     * @summary Users the interaction audit.
     * @request GET:/activity/userInteractionAudit
     */
    activityUserInteractionAudit2 = (
        query: {
            /**
             * Type of the resource.
             * @format int32
             */
            resourceType: number;
            /**
             * The audit code.
             * @format int32
             */
            auditCode: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/activity/userInteractionAudit`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
