import { css } from 'lit';

export const formStyles = css`
    .row {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding: var(--omega-input-margin, 8px) 0;
        align-items: center;
    }

    .row.first {
        padding-top: 0;
    }
    .row.last {
        padding-bottom: 0;
    }

    .row > * {
        flex: 1 0 0px;
    }
    .row > label,
    .row > .label {
        flex: 200px 1 1;
        font-size: var(--omega-label, 14px);
        font-weight: var(--omega-label-font-weight, 400);
        color: var(--omega-label-color);
        margin-bottom: var(--omega-field-label-margin-bottom, 4px);
    }
    .row > .control {
        font-size: var(--omega-field-control-font-size);
        font-weight: var(--omega-field-control-font-weight-editable);
        flex: var(--omega-field-control-width, 250px) 3 1;
    }

    :host([readOnly]) .row > .control {
        font-weight: var(--omega-field-control-font-weight);
    }

    .row.right {
        justify-content: flex-end;
    }
    .row.right > label {
        text-align: right;
    }
    .italic {
        font-style: italic;
    }
    .required {
        color: var(--omega-error);
    }
`;

export default formStyles;
