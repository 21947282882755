const fillPayment = async (record, recordSet) => {
    record.setField('name', 'International');
    record.setField(
        'achCompany',
        (await record.fieldTypeForField('achCompany').options())[0]?.value
    );
    record.setField(
        'achCompanyId',
        (await record.fieldTypeForField('achCompany').options())[0]?.value.companyId
    );
    record.setField(
        'entryDescription',
        (await record.fieldTypeForField('achCompany').options())[0]?.value.entryDescription
    );
    record.setField('streetAddress', '1234 Strawberry Lane');
    record.setField('city', 'New York');
    record.setField('state', (await record.fieldTypeForField('state').options())[0].value);
    record.setField('zipCode', '12345');
    record.setField(
        'destinationCountryCode',
        (await record.fieldTypeForField('destinationCountryCode').options())[0].value
    );
    record.setField('originatorCurrencyCode', 'USD');

    recordSet.allRecords.forEach(async recipient => {
        const iatTransactionType = (
            await recordSet.getFieldType('iatTransactionType').options(recipient)
        )[0]?.value;
        const bankIdType = (await recordSet.getFieldType('receivingBankType').options())[0]?.value;
        const receivingBankCountry = (
            await recordSet.getFieldType('receivingBankCountry').options(recipient)
        )[0] || { countryName: 'Canada', countryCode: 'CA' };
        const transactionType =
            (await recordSet.getFieldType('transactionType').options(recipient)[0]?.value) ||
            'Credit';
        recipient.setField('name', 'Sherlock');
        recipient.setField('streetAddress', '12345 First Street');
        recipient.setField('city', 'Austin');
        recipient.setField('state', 'TX');
        recipient.setField('zipCode', '12345');
        recipient.setField('country', 'US');
        recipient.setField('accountNumber', '1234567');
        recipient.setField('accountType', 'Checking');
        recipient.setField('iatTransactionType', iatTransactionType);
        recipient.setField('amount', 1);
        recipient.setField('transactionType', transactionType);
        recipient.setField('receivingBankName', 'Bens Bank');
        recipient.setField('receivingBankType', bankIdType);
        recipient.setField('receivingBankNumber', '1234567');
        recipient.setField('receivingBankCountry', receivingBankCountry);
    });
    record.setField('foreignExchangeIndicator', 'FF');
    record.setField('foreignExchangeReferenceNumber', 1);
    record.setField('foreignExchangeReference', 'test');
    record.setField(
        'destinationCurrencyCode',
        (await record.fieldTypeForField('destinationCurrencyCode').options(record))[0].value
    );
};

const fillTemplate = async (record, recordSet) => {
    record.setField('name', 'International');
    record.setField(
        'achCompany',
        (await record.fieldTypeForField('achCompany').options(record))[0]?.value
    );
    record.setField(
        'achCompanyId',
        (await record.fieldTypeForField('achCompany').options(record))[0]?.value.companyId
    );
    record.setField(
        'entryDescription',
        (await record.fieldTypeForField('achCompany').options(record))[0]?.value.entryDescription
    );
    record.setField('streetAddress', '1234 Strawberry Lane');
    record.setField('city', 'New York');
    record.setField('state', (await record.fieldTypeForField('state').options(record))[0].value);
    record.setField('zipCode', '12345');
    record.setField(
        'destinationCountryCode',
        (await record.fieldTypeForField('destinationCountryCode').options(record))[0].value
    );
    const bankIdType = (await recordSet.getFieldType('receivingBankType').options(record))[0]
        ?.value;

    const iatTransactionType = {
        code: 'ANN',
        name: 'Annuity',
    };
    record.setField('foreignExchangeIndicator', 'FV');

    record.setField(
        'foreignExchangeReferenceNumber',
        (await record.fieldTypeForField('foreignExchangeReferenceNumber').options(record))[0].value
    );
    record.setField('foreignExchangeReference', 'test');
    record.setField(
        'destinationCurrencyCode',
        (await record.fieldTypeForField('destinationCurrencyCode').options(record))[0].value
    );
    recordSet.allRecords.forEach(async recipient => {
        recipient.setField('destination', record.getField('destinationCountryCode'));
        recipient.setField('name', 'Sherlock');
        recipient.setField('streetAddress', '12345 First Street');
        recipient.setField('city', 'Austin');
        recipient.setField('state', 'TX');
        recipient.setField('zipCode', '12345');
        recipient.setField('country', 'US');
        recipient.setField('accountNumber', '1234567');
        recipient.setField('accountType', 'Checking');
        recipient.setField('iatTransactionType', iatTransactionType);
        recipient.setField('amount', 1);
        recipient.setField('transactionType', 'Credit');
        recipient.setField('receivingBankName', 'Bens Bank');
        recipient.setField('receivingBankType', bankIdType);
        recipient.setField('receivingBankNumber', '1234567');
        const receivingBankCountry = (
            await recordSet.getFieldType('receivingBankCountry').options(recipient)
        )[0]?.value;
        recipient.setField('receivingBankCountry', receivingBankCountry);
    });
};

export default async (record, recordSet) =>
    record.getField('template') ? fillTemplate(record, recordSet) : fillPayment(record, recordSet);
