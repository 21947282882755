/* eslint-disable lit-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Recordset, RecordsetEvent } from '@treasury/FDL';
import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { ListeningElement } from '@treasury/omega/components';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { LocalFilterEvent } from '@treasury/omega/types';
import { usdFormatter } from '@treasury/policy/lib/formatters/usd.formatter';
import { exists } from '@treasury/utils';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { tableStyles } from '../../shared/styles';
import { IssuedItemAccountSummary, createAccountsSummary } from '../shared.helpers';
import { renderAccountsSummary } from './accounts-summary.template';

export type IssuedItemFilterEvent = LocalFilterEvent<IIssuedItem>;
type FilterCallback = (event: IssuedItemFilterEvent) => void;

@customElement('issued-items-report-top')
export class IssuedItemsReportTop extends ListeningElement {
    protected verifiedPropNames: (keyof this)[] = ['recordset'];

    @property({
        type: Object,
    })
    public recordset!: Recordset<IIssuedItem>;

    @property({
        attribute: false,
    })
    public readonly onFilter?: FilterCallback;

    @property({
        type: Boolean,
    })
    public readonly includeExistingVoids = true;

    @property({
        type: String,
    })
    public readonly totalsLabel = 'Total Issued Items';

    @InjectProperty()
    private declare readonly dialogService: OmegaDialogService;

    @state()
    private checkSum = 0;

    @state()
    private itemCount = 0;

    protected firstUpdated(changedProperties: Map<string | number | symbol, unknown>) {
        super.firstUpdated(changedProperties);

        this.listenToMulti(
            this.recordset,
            [RecordsetEvent.Updated, RecordsetEvent.CountsChanged],
            () => this.setSummary()
        );
        this.setSummary();
    }

    private setSummary() {
        const { backingValues } = this.recordset;
        const accountSummary = createAccountsSummary(backingValues, this.includeExistingVoids);
        const { checkSum, itemCount } = generateAccountTotals(accountSummary);

        this.checkSum = checkSum;
        this.itemCount = itemCount;
    }

    private showDialog() {
        this.dialogService.open(
            html` ${renderAccountsSummary(this.recordset, this.includeExistingVoids)} `,
            'Total Issued Items',
            {
                buttons: {
                    [OmegaDialogExitReason.Cancel]: null,
                },
            }
        );
    }

    private renderFilterBar() {
        const { onFilter } = this;
        if (!exists(onFilter)) {
            return nothing;
        }

        return html`<omega-filter-bar
            .recordset=${this.recordset}
            @change=${(event: IssuedItemFilterEvent) => onFilter(event)}
        ></omega-filter-bar>`;
    }

    protected render() {
        return html`
            <div class="row padded">
                ${this.renderFilterBar()}
                <div class="text-right">
                    <span>
                        <strong>${this.totalsLabel}:</strong>
                        ${this.itemCount}
                    </span>
                    <br />
                    <span>
                        <strong>Total Amount:</strong>
                        <a href="javascript: void 0;" @click=${() => this.showDialog()}
                            >${usdFormatter.format(this.checkSum)}</a
                        >
                    </span>
                </div>
            </div>
        `;
    }

    static styles = [tableStyles];
}

function generateAccountTotals(accountSummary: IssuedItemAccountSummary[]) {
    const summary = {
        checkSum: 0,
        itemCount: 0,
    };

    return accountSummary.reduce((summary, account) => {
        const { itemCount, amountTotal } = account;
        summary.itemCount += itemCount;
        summary.checkSum += amountTotal;

        return summary;
    }, summary);
}
