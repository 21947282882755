import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-field.js';
import { boolean, string } from '@treasury/policy/primitives';
import { css, html, LitElement } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';

class UserVerification extends mix(LitElement).with(ListeningElementMixin, channelAlertMixin) {
    static get properties() {
        return {
            record: Object,
            institution: String,
            formActionsDisabled: Boolean,
            loading: Boolean,
            caption: String,
        };
    }

    constructor() {
        super();
        this.fields = {
            companyId: string.with
                .label('Company ID')
                .and.placeholder('Enter Company ID')
                .thatHas.maxLength(25)
                .and.minLength(1)
                .thatIs.required(),
            loginId: string.with
                .label('Login ID')
                .and.placeholder('Enter Login ID')
                .thatHas.maxLength(25)
                .and.minLength(1)
                .thatIs.required(),
            institution: string,
            password: string,
            useRefreshTokens: boolean,
            code: string,
        };
        this.caption =
            'To verify your identity and process with a password change, please enter your Company ID and Login ID and provide the answer(s) for your security question(s).';
    }

    get resetCode() {
        const codeParameter = new URLSearchParams(window.location.search).get('code');
        return decodeURIComponent(codeParameter);
    }

    connectedCallback() {
        super.connectedCallback();
        this.formActionsDisabled = true;
        this.record = new Record(this.fields, {
            companyId: null,
            loginId: null,
            code: this.resetCode,
            useRefreshTokens: false,
            institution: this.institution,
        });
        this.listenTo(this.record, 'change', () => {
            this.isFormInvalid();
        });
    }

    submitUser() {
        this.dispatchEvent(
            new CustomEvent('submit', {
                detail: { formValues: this.record.values, record: this.record },
            })
        );
    }

    reset() {
        this.record.reset();
    }

    isFormInvalid() {
        this.formActionsDisabled = !this.record.isValid() || !this.record.hasRequiredValues();
    }

    render() {
        return html`
            ${this.renderAlert()}
            <summary>${this.caption}</summary>
            <form>
                <omega-field field="companyId" .record=${this.record}></omega-field>
                <omega-field field="loginId" .record=${this.record}></omega-field>
            </form>
            <footer>
                <omega-button
                    id="log-in"
                    type="primary"
                    .loading=${this.loading}
                    ?disabled=${this.formActionsDisabled}
                    @click=${this.submitUser}
                >
                    Submit
                </omega-button>
                <omega-button
                    id="reset"
                    type="link"
                    ?disabled=${this.formActionsDisabled}
                    @click=${this.reset}
                >
                    Reset
                </omega-button>
            </footer>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            footer {
                padding: 4px 15px;
                border-top: 1px solid #ccc;
            }
            summary,
            form {
                padding: 15px;
            }
            omega-field {
                --omega-field-label-width: 100px;
                --omega-field-control-width: 250px;
                max-width: 360px;
                margin-bottom: 15px;
            }
            omega-field:last-of-type {
                margin-bottom: 0;
            }
        `;
    }
}

customElements.define('user-verification', UserVerification);
