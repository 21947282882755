/* eslint-disable @treasury/no-date */
import { Record } from '@treasury/FDL/record';
import {
    disableAchPaymentValueDates,
    getDefaultValueDate,
} from '@treasury/domain/channel/types/ach';
import {
    achCompany,
    achCompanyId,
    achCompanyName,
    achCompanySecCode,
    achDiscretionaryData,
    achEntryDescription,
    achOffsetAccount,
    achPaymentAudit,
    achPaymentName,
    achPaymentStatus,
    achRestrictPayment,
    achTransactionId,
    childSupportPaymentFrequency,
} from '@treasury/policy/ach';
import { money, number, string } from '@treasury/policy/primitives';
import ChildSupportWorkflowClient from '../clients/child-support-workflow-client.ts';

export const getPaymentFieldValue = (payment, field) => {
    if (!payment) return '';
    return payment[field] ?? '';
};
export const getPaymentAchCompany = (payment, companies) =>
    companies.find(c => c.companyId === payment?.achCompany?.companyId);
export const fullCompanyMatch = match => match === 5;
export const isPrefunding = (entitlements, record) =>
    entitlements.some(({ permission }) => permission === 'Feature.ACH.AllowUnbalancedPayments') &&
    record.getField('achCompany').prefundingDays > 0;
export const isExistingPayment = record => !!record.getField('id');
export const isAllowUnbalancedPayments = (entitlements, record) =>
    entitlements.some(({ permission }) => permission === 'Feature.ACH.AllowUnbalancedPayments') &&
    record.getField('achCompany').allowUnbalancedPayments;
export const notStepOne = record => record.getField('step') !== 0;

const sharedTemplateAndPaymentFields = (config, payment, client, fdlClient) => {
    const {
        entitlements,
        companyMatchType,
        type,
        action,
        holidays,
        allowSameDayPayments,
        cutoffTimes,
        companies,
    } = config;
    // TODO: move all of these checks outside this fields function
    const isPayment = () => type !== 'template';
    const hasPartialPaymentEditEntitlement = () =>
        entitlements.some(e => e.permission === 'ACH, Payment, Partial Edit') &&
        !entitlements.some(e => e.permission === 'ACH, Payment, Full Edit') &&
        isPayment() &&
        action !== 'create';

    const hasPartialTemplateEditEntitlement = () =>
        entitlements.some(e => e.permission.toLowerCase() === 'partial edit ach template') &&
        !entitlements.some(e => e.permission.toLowerCase() === 'full edit ach template') &&
        !isPayment() &&
        action !== 'create';
    const hasNoPaymentEditEntitlement = () =>
        !entitlements.some(e => e.permission === 'ACH, Payment, Partial Edit') &&
        !entitlements.some(e => e.permission === 'ACH, Payment, Full Edit') &&
        isPayment() &&
        action !== 'create';
    const hasNoTemplateEditEntitlement = () =>
        !entitlements.some(e => e.permission.toLowerCase() === 'partial edit ach template') &&
        !entitlements.some(e => e.permission.toLowerCase() === 'full edit ach template') &&
        !isPayment() &&
        action !== 'create';

    const isCreatePaymentFlow = () => type === 'payment' && action === 'create';

    /**
     * Note: includes only works here because we're using guid for templates. We need to
     * take a look at either combining identity and companyId to remove this check
     * or extend the initiate checks to know if we're initiating from a payment
     * or a template
     * @returns {boolean}
     */
    const isInitiatePaymentFlow = () =>
        type === 'payment' && action === 'initiate' && !payment.id.toString().includes('-');

    const isInitiatePaymentFromTemplateFlow = () =>
        type === 'payment' && action === 'initiate' && payment.id.toString().includes('-');

    const isEditPaymentFlow = () => type === 'payment' && action === 'edit';

    const isEditTemplateFlow = () => type === 'template' && action === 'edit';

    const isTemplateDetailView = () =>
        !!(action === false || action === 'view') && type === 'template';

    const isPaymentDetailView = () =>
        !!(action === false || action === 'view') && type === 'payment';

    // Match criteria comes from the BO produce feature configuration for ACH
    const doesMatchCriteriaContainCompanyName = () => companyMatchType >= 1;

    const doesMatchCriteriaContainCompanyID = () => companyMatchType >= 2;

    const doesMatchCriteriaContainSECCode = () => companyMatchType >= 3;

    const doesMatchCriteriaContainEntryDescription = () => companyMatchType >= 4;

    const doesMatchCriteriaContainDiscretionaryData = () => companyMatchType >= 5;

    const hasRestrictPaymentEntitlement = () =>
        entitlements.some(({ permission }) => permission === 'Restricted Batch');

    const isSameDayPayment = record => {
        const today = new Date().setHours(0, 0, 0, 0);
        const todayDateTime = new Date(today);
        return record.getField('frequency').effectiveDate === todayDateTime.toISOString();
    };
    const effectiveDate = getDefaultValueDate(allowSameDayPayments, cutoffTimes, holidays);

    return [
        {
            field: 'status',
            // we always set the step to 3 on the detail view, so we shouldn't force it to be visible when there's an id
            fieldType: achPaymentStatus.thatIs
                .visibleWhen(record => record.getField('step') === 3)
                .and.readOnlyWhen(record => record.getField('id') || notStepOne(record))
                .with.label('Status')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'status'),
        },
        {
            field: 'name',
            fieldType: achPaymentName.thatIs
                .required()
                .and.readOnlyWhen(record => notStepOne(record))
                .and.readOnlyWhen(() => isEditTemplateFlow() && hasPartialTemplateEditEntitlement())
                .and.readOnlyWhen(() => isEditTemplateFlow() && hasNoTemplateEditEntitlement())
                .thatHas.label(() => (type === 'template' ? 'Template Name' : 'Payment Name'))
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'name'),
        },
        {
            field: 'achCompany',
            fieldType: achCompany(fdlClient)
                .thatIs.required()
                .thatHas.options({
                    fetch: client.getAchCompanies,
                    text: 'companyName',
                    value: record => record,
                })
                .and.readOnlyWhen(record => notStepOne(record))
                .and.readOnlyWhen(
                    record => isExistingPayment(record) && doesMatchCriteriaContainCompanyName()
                )
                .thatHas.hashFunction(item => item?.id)
                .thatHas.label('ACH Company Name')
                .as.tag('omega-select'),
            value: getPaymentAchCompany(payment, companies),
        },
        {
            field: 'achCompanyName',
            fieldType: achCompanyName.thatIs.visibleWhen(() => false).and.readOnly(),
            value: getPaymentFieldValue(payment, 'achCompanyName'),
        },
        {
            field: 'achCompanyId',
            fieldType: achCompanyId.thatIs
                .visibleWhen(
                    () =>
                        isCreatePaymentFlow() ||
                        isEditPaymentFlow() ||
                        isPaymentDetailView() ||
                        isInitiatePaymentFlow()
                )
                .and.readOnlyWhen(
                    () =>
                        isTemplateDetailView() ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainCompanyID()
                )
                .and.readOnlyWhen(notStepOne)
                .and.readOnlyWhen(
                    () => hasNoPaymentEditEntitlement() || hasNoTemplateEditEntitlement()
                ),
            value: getPaymentFieldValue(payment, 'achCompanyId'),
        },
        {
            field: 'companyIdentity',
            fieldType: achCompanyId.thatIs
                .visibleWhen(
                    () =>
                        isEditTemplateFlow() ||
                        isTemplateDetailView() ||
                        isInitiatePaymentFromTemplateFlow()
                )
                .thatIs.readOnlyWhen(
                    record =>
                        isTemplateDetailView() ||
                        notStepOne(record) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainCompanyID()
                )
                .with.label('ACH Company ID'),
            value: getPaymentFieldValue(payment, 'companyIdentity'),
        },
        {
            field: 'secCode',
            fieldType: achCompanySecCode(secCode => secCode.code === 'CCD')
                .and.readOnlyWhen(
                    record =>
                        doesMatchCriteriaContainSECCode() ||
                        isTemplateDetailView() ||
                        notStepOne(record) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        hasNoPaymentEditEntitlement()
                )
                .thatHas.label('SEC Code')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'secCode'),
        },
        {
            field: 'entryDescription',
            fieldType: achEntryDescription.thatIs
                .required()
                .and.readOnlyWhen(
                    record =>
                        notStepOne(record) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainEntryDescription() ||
                        hasNoPaymentEditEntitlement()
                )
                .thatHas.label('Entry Description')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'entryDescription'),
        },
        {
            field: 'discretionaryData',
            fieldType: achDiscretionaryData.thatIs
                .readOnlyWhen(notStepOne)
                .and.readOnlyWhen(
                    () =>
                        fullCompanyMatch(companyMatchType) ||
                        hasPartialPaymentEditEntitlement() ||
                        hasPartialTemplateEditEntitlement() ||
                        doesMatchCriteriaContainDiscretionaryData() ||
                        hasNoPaymentEditEntitlement()
                )
                .thatHas.label('Discretionary Data')
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'discretionaryData'),
        },
        {
            field: 'restricted',
            fieldType: achRestrictPayment.thatIs
                .disabledWhen(record => notStepOne(record))
                .and.readOnlyWhen(() => !hasRestrictPaymentEntitlement())
                .thatHas.label('Restrict Payment')
                .thatIs.visibleWhen(() => isPayment() && hasRestrictPaymentEntitlement())
                .as.tag('omega-checkbox'),
            value: getPaymentFieldValue(payment, 'restricted'),
        },
        {
            field: 'offsetAccount',
            fieldType: achOffsetAccount(null, fdlClient)
                .thatIs.requiredWhen(
                    record =>
                        !isPrefunding(entitlements, record) &&
                        !isAllowUnbalancedPayments(entitlements, record) &&
                        record.getField('step') === 2 &&
                        !isEditTemplateFlow()
                )
                .thatIs.readOnlyWhen(record => record.getField('step') === 3)
                .thatIs.readOnlyWhen(record => isPrefunding(entitlements, record))
                .thatIs.readOnlyWhen(record => isAllowUnbalancedPayments(entitlements, record))
                .and.visibleWhen(
                    record =>
                        (record.getField('step') === 2 || record.getField('step') === 3) &&
                        isPayment()
                )
                .and.template((value, record) => {
                    if (
                        isPrefunding(entitlements, record) ||
                        isAllowUnbalancedPayments(entitlements, record)
                    ) {
                        return record.getField('achCompany').offsetAccountNumber;
                    }
                    // accountDisplayLabel exists when fetching offsetAccounts from the endpoint. This value disappears
                    // and is replaced by 'value' when loading a child support payment by Id from `getChildSupportPayment`...
                    return (
                        record.getField('offsetAccount').accountDisplayLabel ??
                        record.getField('offsetAccount').value
                    );
                })
                .with.label('Offset Account')
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'offsetAccount'),
        },
        {
            field: 'debitAmount',
            fieldType: money.thatIs
                .visibleWhen(notStepOne)
                .and.readOnlyWhen(notStepOne)
                .thatHas.label('Debit')
                .thatHas.validator({
                    name: 'debit amount cannot be greater than $100,000 on same day payments',
                    validate: (value, model, record) => {
                        if (isSameDayPayment(record)) return value <= 100000;
                        return true;
                    },
                }),
            value: payment ? getPaymentFieldValue(payment, 'debitAmount') : 0,
        },
        {
            field: 'creditAmount',
            fieldType: money.thatIs
                .visibleWhen(notStepOne)
                .and.readOnlyWhen(notStepOne)
                .thatHas.label('Credit')
                .thatHas.validator({
                    name: 'credit amount cannot be greater than $100,000 on same day payments',
                    validate: (value, model, record) => {
                        if (isSameDayPayment(record)) return value <= 100000;
                        return true;
                    },
                }),
            value: payment ? getPaymentFieldValue(payment, 'creditAmount') : 0,
        },
        {
            field: 'frequency',
            label: '',
            fieldType: childSupportPaymentFrequency.with
                .validator({
                    name: 'effectiveDate',
                    validate: v => {
                        if (isPayment()) {
                            return !!v.valueDate;
                        }
                        return true;
                    },
                })
                .and.selectionDisabledFunctions(
                    disableAchPaymentValueDates(allowSameDayPayments, holidays, cutoffTimes)
                )
                .and.defaultValue({
                    effectiveDate,
                    repeatOn: null,
                    startOn: null,
                    endOn: null,
                    valueDate: effectiveDate,
                    nextPaymentDate: null,
                    noEndDate: true,
                    repeatOnDay1: null,
                    repeatOnDay2: null,
                    repeatOnLastBusinessDay: null,
                    type: 'One Time',
                    summary: '',
                })
                .and.readOnlyWhen(record => {
                    // if we've uploaded a file
                    if (payment?.fileArchiveId) {
                        return record.getField('step') === 2 || record.getField('step') === 3;
                    }
                    return record.getField('step') !== 0;
                })
                .thatIs.visibleWhen(isPayment)
                .as.tag('omega-frequency')
                .that.usesCustomPrint(),
            value: getPaymentFieldValue(payment, 'frequency'),
        },
        {
            field: 'audit',
            fieldType: achPaymentAudit.thatIs
                .visibleWhen(record => record.getField('step') === 3)
                .and.readOnlyWhen(notStepOne)
                .thatHas.label('Audit'),
            value: getPaymentFieldValue(payment, 'audit'),
        },
        {
            field: 'transactionId',
            fieldType: achTransactionId.thatIs
                .visibleWhen(record => record.getField('step') === 3)
                .thatIs.visibleWhen(isPayment)
                .thatHas.label('Transaction ID'),
            value: getPaymentFieldValue(payment, 'transactionId'),
        },
        {
            field: 'id',
            fieldType: number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'id'),
        },
        /* meta */
        {
            field: 'step',
            fieldType: number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'step'),
        },
        {
            field: 'action',
            fieldType: string.thatIs.visibleWhen(() => false),
            value: action,
        },
        {
            field: 'type',
            fieldType: string.thatIs.visibleWhen(() => false),
            value: type,
        },
        {
            field: 'statusDescription',
            fieldType: string.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'statusDescription'),
        },
        {
            field: 'achCompanyUniqueId',
            fieldType: string.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'achCompanyUniqueId'),
        },
    ];
};

export const paymentFields = (config, payment, client, fdlClient) => [
    ...sharedTemplateAndPaymentFields(config, payment, client, fdlClient),
];

export const paymentHeaderRecord = (
    config,
    payment,
    client = new ChildSupportWorkflowClient(),
    fdlClient = null
) =>
    new Record(
        paymentFields(config, payment, client, fdlClient).reduce((acc, curr) => {
            acc[curr.field] = curr.fieldType;
            return acc;
        }, {}),
        paymentFields(config, payment, client, fdlClient).reduce((acc, curr) => {
            acc[curr.field] = curr.value || curr.fieldType.defaultValue();
            return acc;
        }, {})
    );
