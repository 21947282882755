WausauStatementsController.$inject = [
    '$scope',
    '$state',
    'wausauStatementsService',
    'spinnerService',
    'modalService',
    'FileSaver',
];

export default function WausauStatementsController(
    $scope,
    $state,
    wausauStatementsService,
    spinnerService,
    modalService,
    FileSaver
) {
    const defaultProductDisplayName = 'Wausau eStatements';
    const defaultAccountSelectText =
        'Please select an account and statement period below to view your online statement.';
    const defaultNoStatementText =
        'You do not have any statements available for this account. Please select another account.';
    const productName = 'Wausau';
    const documentTypeId = 3;

    $scope.isNoStatement = isNoStatement;
    $scope.isDisplayAccounts = isDisplayAccounts;
    $scope.isDisplayYears = isDisplayYears;
    $scope.isDisplayStatements = isDisplayStatements;
    $scope.getReportDisplayName = getReportDisplayName;
    $scope.getStatementList = getStatementList;
    $scope.getStatementsForYear = getStatementsForYear;
    $scope.getStatement = getStatement;
    $scope.currentSpinnerGroupName = null; // need to be in scope for spinnerService to work.

    let isInitialized = false;
    let isYearsLoaded = false;
    let isStatementsLoaded = false;
    let statements = null;
    let errorMessage =
        'An error has occurred.  Please try again later or contact customer service.';

    (function () {
        // init
        setupGetAccountsAndSettings();

        wausauStatementsService.getAdditionalSettingsAndAccounts(documentTypeId).then(response => {
            endSpinner();

            if (response) {
                $scope.accountList = response.accounts;
                processSettings(response.additionalSettings);
            } else {
                displayError();
            }
        });
    })();

    function isNoStatement() {
        return hasLoadedStatements() && statements && statements.length === 0;
    }

    function isDisplayAccounts() {
        return isInitialized && $scope.accountList && $scope.accountList.length > 0;
    }

    function isDisplayYears() {
        return hasLoadedYears() && $scope.years && $scope.years.length > 0;
    }

    function isDisplayStatements() {
        return (
            hasLoadedStatements() && $scope.displayStatements && $scope.displayStatements.length > 0
        );
    }

    function getReportDisplayName(report) {
        return `Statement Ending ${report.date}`;
    }

    function getStatementList(account) {
        setupGetReportList(account);

        wausauStatementsService.getReportList(productName, account.id).then(response => {
            endSpinner();

            if (response) {
                isStatementsLoaded = true;
                statements = processReportList(response);
            } else {
                displayError();
            }
        });
    }

    function getStatementsForYear(year) {
        $scope.selectedYear = year;
        $scope.displayStatements = statements.filter(x => x.year === year);
    }

    function getStatement(report) {
        setupGetReport();

        wausauStatementsService
            .getReport(productName, report.id, report.fileName)
            .then(response => {
                endSpinner();

                if (response) {
                    processReport(report, response);
                } else {
                    displayError();
                }
            });
    }

    function hasLoadedStatements() {
        return isInitialized && isStatementsLoaded;
    }

    function hasLoadedYears() {
        return hasLoadedStatements() && isYearsLoaded;
    }

    function setupGetAccountsAndSettings() {
        $scope.currentSpinnerGroupName = setupSpinner(
            'getSettings',
            `/users/wausaustatements/${documentTypeId}/additionalsettingsandaccounts`
        );
    }

    function processSettings(settings) {
        $scope.productDisplayName =
            settings.productDisplayName && settings.productDisplayName.length !== 0
                ? settings.productDisplayName
                : defaultProductDisplayName;

        $scope.accountSelectText =
            settings.selectAccountLabel && settings.selectAccountLabel.length !== 0
                ? settings.selectAccountLabel
                : defaultAccountSelectText;

        if (settings.errorMessage && settings.errorMessage.length !== 0) {
            errorMessage = settings.errorMessage;
        }

        $scope.noStatementText =
            settings.noStatementMessage && settings.noStatementMessage.length !== 0
                ? settings.errorMessage
                : defaultNoStatementText;

        isInitialized = true;
    }

    function setupGetReportList(account) {
        $scope.selectedAccount = account;
        $scope.currentSpinnerGroupName = setupSpinner(
            'getStatements',
            `wausaustatements/${productName}/reportlist/${account.id}`
        );
        isYearsLoaded = false;
        $scope.years = null;
        isStatementsLoaded = false;
        $scope.displayStatements = null;
    }

    function processReportList(reportList) {
        const years = [];

        if (reportList && reportList.length > 0) {
            reportList.forEach(report => {
                const year = report.data.substring(0, 4);
                const month = report.data.substring(4, 6);
                const day = report.data.substring(6, 8);

                if (years.indexOf(year) === -1) {
                    years.push(year);
                }

                report.year = year;
                report.date = `${month}/${day}/${year}`;
                report.fileName = `${month}-${day}-${year}`;
            });

            years.sort();
        }

        isYearsLoaded = true;
        $scope.years = years;

        return reportList;
    }

    function setupGetReport() {
        $scope.currentSpinnerGroupName = setupSpinner('getStatement', 'wausaustatements/report/');
    }

    function processReport(report, response) {
        if (response && response.data) {
            const blob = new Blob([response.data], {
                type: 'application/pdf',
            });

            FileSaver.saveAs(blob, `${report.fileName}.pdf`);
        } else {
            displayError();
        }
    }

    function setupSpinner(groupName, path) {
        spinnerService.configureGroup(groupName, [path], ['wausauStatementsView.html']);

        spinnerService.startGroupRequest(groupName);

        return groupName;
    }

    function endSpinner() {
        if ($scope.currentSpinnerGroupName) {
            spinnerService.stopGroupRequest($scope.currentSpinnerGroupName);
            $scope.currentSpinnerGroupName = null;
        }
    }

    function displayError(errorNumber) {
        modalService.showModal(
            {},
            {
                alertType: 'Error',
                isAlert: true,
                summaryText: errorNumber ? `Error ${errorNumber}: ${errorMessage}` : errorMessage,
            }
        );
    }
}
