import { LitElement, html, css } from 'lit';
import '@treasury/omega/components/omega-tooltip.js';

class FieldWithTooltipIcon extends LitElement {
    static get properties() {
        return {
            messageType: String,
            message: String,
            isEdited: Boolean,
            displayString: String,
            recordType: String,
        };
    }

    constructor() {
        super();
        this.messageType = null;
        this.message = null;
        this.isEdited = false;
        this.displayString = '';
        this.recordType = '';
    }

    capitalizeString(val) {
        return val[0].toUpperCase() + val.substring(1).toLowerCase();
    }

    setTooltipParams() {
        if (this.isEdited)
            return {
                icon: 'edit',
                message: `${this.capitalizeString(this.recordType)} has been edited`,
            };
        if (this.messageType)
            return {
                icon: 'exclamation-triangle',
                message: this.message ?? `An error occurred processing this ${this.recordType}.`,
            };
        return '';
    }

    renderTooltip() {
        return html` <omega-tooltip light direction="top" icon="${this.setTooltipParams().icon}">
            <div slot="content" style="max-width: 300px;">${this.setTooltipParams().message}</div>
        </omega-tooltip>`;
    }

    render() {
        if (!this.messageType && !this.isEdited) return this.displayString;
        return html`<div id="fieldWithIcon">${this.displayString}${this.renderTooltip()}</div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #fieldWithIcon {
                display: flex;
            }
        `;
    }
}

customElements.define('field-with-tooltip-icon', FieldWithTooltipIcon);
