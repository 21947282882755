/**
 * @param { ng.IModule } module
 */
export function registerConfigBlock(module) {
    return (
        module
            .config([
                '$httpProvider',
                '$animateProvider',
                '$locationProvider',
                function ($httpProvider, $animateProvider, $locationProvider) {
                    $locationProvider.html5Mode(true);
                    $locationProvider.hashPrefix('');

                    $animateProvider.classNameFilter(/animation-enabled/);

                    // initialize get if not there
                    if (!$httpProvider.defaults.headers.get) {
                        $httpProvider.defaults.headers.get = {};
                    }

                    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
                    $httpProvider.defaults.headers.get.Pragma = 'no-cache';

                    $httpProvider.interceptors.push('authInterceptorService');
                    $httpProvider.defaults.withCredentials = true;
                    $httpProvider.interceptors.push('spinnerService');
                },
            ])
            // TOASTER EXTENSION
            .config([
                '$provide',
                function ($provide) {
                    $provide.decorator('toaster', [
                        '$delegate',
                        '$state',
                        function ($delegate, $state) {
                            $delegate.save = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Saved',
                                    `${entityType} saved successfully!`
                                );
                            };

                            $delegate.send = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Sent',
                                    `${entityType} sent successfully!`
                                );
                            };

                            $delegate.sort = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Sort',
                                    `${entityType} updated successfully!`
                                );
                            };

                            $delegate.delete = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Deleted',
                                    `${entityType} successfully deleted!`
                                );
                            };

                            $delegate.approve = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Approved',
                                    `${entityType} successfully approved!`
                                );
                            };

                            $delegate.reject = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Rejected',
                                    `${entityType} successfully rejected.`
                                );
                            };

                            $delegate.cancel = function (entityType) {
                                this.pop(
                                    'default',
                                    'success',
                                    'Cancelled',
                                    `${entityType} successfully cancelled.`
                                );
                            };

                            $delegate.alert = function (subject, details, timeout) {
                                this.pop('alert', 'info', subject, details, timeout, null, () => {
                                    $state.go('notifications');
                                });
                            };

                            $delegate.successText = function (text) {
                                this.pop('default', 'success', 'Saved', text);
                            };

                            return $delegate;
                        },
                    ]);
                },
            ])
    );
}
