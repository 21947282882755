export enum WorkflowTypes {
    Payment = 'payment',
    Template = 'template',
}

export enum WorkflowActions {
    InitiateFromTemplate = 'initiate',
    Create = 'create',
    InitiateFromFile = 'file',
    Edit = 'edit',
    View = 'view',
    InitiateBatchFromFile = 'batch-file',
    InitiateBatchFromTemplates = 'batch-initiate',
    ViewBatchDetail = 'view-batch-detail',
}

export enum PaymentTypes {
    Prefunding,
    NonPrefunding,
    Unbalanced,
    Null,
}

export interface Workflow {
    type: WorkflowTypes;
    action: WorkflowActions;
}

export const Workflows = {
    CreatePayment: { type: WorkflowTypes.Payment, action: WorkflowActions.Create },
    EditPayment: { type: WorkflowTypes.Payment, action: WorkflowActions.Edit },
    InitiatePayment: { type: WorkflowTypes.Payment, action: WorkflowActions.InitiateFromTemplate },
    CreateTemplate: { type: WorkflowTypes.Template, action: WorkflowActions.Create },
    EditTemplate: { type: WorkflowTypes.Template, action: WorkflowActions.Edit },
    PaymentDetail: { type: WorkflowTypes.Payment, action: WorkflowActions.View },
    TemplateDetail: { type: WorkflowTypes.Template, action: WorkflowActions.View },
};
