import { isValidDate } from '../../utils/frequency';

export const effectiveDateIsRequired = {
    name: 'Effective Date is a required field',
    validate: (val: any) => {
        if (val.type !== 'OneTime' && val.type !== 'One Time') return true;
        const date = val.valueDate || val.effectiveDate;
        return isValidDate(date);
    },
};
