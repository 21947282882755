import { EntitlementsRequests } from '../../requests/entitlements/entitlements.js';

/**
 * @type Readonly<EntitlementsService>?
 */
let entitlementsService;

/**
 * @deprecated Instead, use the singular EntitlementService.
 */
export default class EntitlementsService {
    static get instance() {
        if (!entitlementsService) {
            entitlementsService = Object.freeze(new EntitlementsService());
        }

        return entitlementsService;
    }

    /**
     * @deprecated Use `instance` reference instead.
     */
    static async getEntitlements() {
        return this.instance.getEntitlements();
    }

    /**
     * @deprecated Use `instance` reference instead.
     */
    static async hasEntitlement(entitlement) {
        return this.instance.hasEntitlement(entitlement);
    }

    /**
     * @deprecated Use `instance` reference instead.
     */
    static reset() {
        return this.instance.reset();
    }

    /**
     * @deprecated Use `instance` reference instead.
     */
    static reload() {
        return this.instance.reload();
    }

    getEntitlements() {
        return EntitlementsRequests.getEntitlements();
    }

    /**
     * @param {string} entitlement
     * @returns {Promise<boolean>}
     */
    hasEntitlement(entitlement) {
        return EntitlementsRequests.fetchEntitlement(entitlement);
    }

    reset() {
        return EntitlementsRequests.resetEntitlementsCache();
    }

    reload() {
        this.reset();
        this.getEntitlements();
    }
}
