export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: '/app/directives/components/dateSearch/dateSearchTemplate.html',
            controller: TmDateSearchController,
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                dateModel: '=tmModel',
                formInstance: '=',
                name: '@',
                label: '@',
                maxDate: '=?',
                nullOptionLabel: '@?',
            },
        };
    },
];

TmDateSearchController.$inject = ['$scope', 'DatePeriod'];

function TmDateSearchController($scope, DatePeriod) {
    const ctrl = this;
    ctrl.dateOptions = [
        DatePeriod.TODAY,
        DatePeriod.WEEK_TO_DATE,
        DatePeriod.MONTH_TO_DATE,
        DatePeriod.YEAR_TO_DATE,
        DatePeriod.SPECIFIC_DATE,
        DatePeriod.DATE_RANGE,
    ];
    ctrl.startInputControlName = `${ctrl.name}StartInput`;
    ctrl.endInputControlName = `${ctrl.name}EndInput`;
    const today = new Date();
    const currentYear = today.getFullYear();
    ctrl.maxDate = ctrl.maxDate || new Date(`${currentYear}-12-31`);

    ctrl.isSpecificDateOption = isSpecificDateOption;
    ctrl.isDateRangeOption = isDateRangeOption;
    ctrl.hasNullOptionLabel = hasNullOptionLabel;

    $scope.$watchCollection(
        '[ctrl.dateModel.searchOption, ctrl.dateModel.start, ctrl.dateModel.end]',
        handleInputChange
    );

    function handleInputChange(newValues, oldValues) {
        if (newValues != null) {
            const newSearchOption = newValues[0];

            const searchOptionChanged = oldValues && newSearchOption !== oldValues[0];
            if (searchOptionChanged) {
                setDateRange(newSearchOption);
                return;
            }

            if (isDateRangeOption(newSearchOption)) {
                const { formInstance } = ctrl;
                const fromControl = formInstance[ctrl.startInputControlName];
                const toControl = formInstance[ctrl.endInputControlName];
                if (fromControl && toControl) {
                    const fromDate = newValues[1];
                    const toDate = newValues[2];
                    validateInput(fromDate, toDate, fromControl, toControl);
                }
            }
        }
    }

    function validateInput(fromDateString, toDateString, fromControl, toControl) {
        const neitherDateIsEntered = !fromDateString && !toDateString;
        const fromDate = Date.parse(fromDateString);
        const toDate = Date.parse(toDateString);

        const fromDateValid = !isNaN(fromDate);
        const toDateValid = !isNaN(toDate);

        const fromInputIsValid = neitherDateIsEntered || fromDateValid;

        const toInputIsValid =
            neitherDateIsEntered || (toDateValid && (!fromDateValid || toDate - fromDate >= 0));

        fromControl.$setValidity('input', fromInputIsValid);
        toControl.$setValidity('input', toInputIsValid);
    }

    function isSpecificDateOption(dateSearchOption) {
        return dateSearchOption === DatePeriod.SPECIFIC_DATE;
    }

    function isDateRangeOption(dateSearchOption) {
        return dateSearchOption === DatePeriod.DATE_RANGE;
    }

    function hasNullOptionLabel() {
        return ctrl.nullOptionLabel != null;
    }

    function setDateRange(dateSearchOption) {
        if (ctrl.dateModel) {
            if (
                !dateSearchOption ||
                dateSearchOption === DatePeriod.SPECIFIC_DATE ||
                dateSearchOption === DatePeriod.DATE_RANGE
            ) {
                ctrl.dateModel.end = '';
                ctrl.dateModel.start = '';
            } else {
                const today = new Date(ctrl.maxDate);
                ctrl.dateModel.end = formatDate(today);
                ctrl.dateModel.start = getStartDate(today, dateSearchOption);
            }
        }
    }

    function formatDate(dateToFormat) {
        return `${(dateToFormat.getMonth() + 1).toString()}/${dateToFormat
            .getDate()
            .toString()}/${dateToFormat.getFullYear().toString()}`;
    }

    function getStartDate(today, dateOption) {
        let formattedDate = null;
        if (dateOption === DatePeriod.TODAY) {
            formattedDate = formatDate(today);
        }
        if (dateOption === DatePeriod.WEEK_TO_DATE) {
            const dayOffset = (today.getDay() + 6) % 7; // For week Monday through Sunday
            const lastMonday = new Date(today);
            lastMonday.setDate(today.getDate() - dayOffset);
            formattedDate = formatDate(lastMonday);
        }
        if (dateOption === DatePeriod.MONTH_TO_DATE) {
            formattedDate = `${(today.getMonth() + 1).toString()}/1/${today
                .getFullYear()
                .toString()}`;
        }
        if (dateOption === DatePeriod.YEAR_TO_DATE) {
            formattedDate = `1/1/${today.getFullYear().toString()}`;
        }
        return formattedDate;
    }
}
