import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { OmegaDate, OmegaDatePickerChangeEvent } from './datepicker';
import DateModel from './datepicker/DateModel.js';
import './omega-datepicker';
import OmegaSelect from './omega-select';

interface SelectItem<T = unknown> {
    text: string;
    value: T;
}

interface ChangePayload {
    fieldName: string;
    date: OmegaDate | string;
    originalEvent: OmegaDatePickerChangeEvent;
}

export type OmegaMultiDateChangeEvent = CustomEvent<ChangePayload>;
@customElement('omega-multi-date')
export class OmegaMultiDate extends LitElement {
    @property({
        type: Object,
    })
    public value?: OmegaDate | string;

    @property({
        type: Array,
    })
    public readonly items: SelectItem[] = [];

    @property({
        type: String,
    })
    public readonly placeholder?: string;

    @property({
        type: Object,
    })
    public readonly disableDateFn?: (model: DateModel) => boolean;

    @property()
    public readonly range = false;

    @state()
    private selectedField?: string;

    @state()
    private disableDatePicker = true;

    private readonly selectRef = createRef<OmegaSelect>();

    private onFieldSelected(e: CustomEvent) {
        this.selectedField = this.selectRef.value?.value;
        this.disableDatePicker = !this.selectedField;

        if (!this.value || !this.selectedField) {
            return;
        }

        this.dispatchChange(this.selectedField, this.value, e);
    }

    private onDateSelected(e: OmegaDatePickerChangeEvent) {
        const { value } = e.detail;
        if (!value || !this.selectedField) {
            return;
        }

        this.value = value;
        this.dispatchChange(this.selectedField, value, e);
    }

    private dispatchChange(
        fieldName: string,
        date: OmegaDate | string,
        originalEvent: CustomEvent
    ) {
        const event: OmegaMultiDateChangeEvent = new CustomEvent('change', {
            detail: {
                fieldName,
                date,
                originalEvent,
            },
        });
        this.dispatchEvent(event);
    }

    public reset() {
        this.value = undefined;
        this.selectedField = undefined;
        this.disableDatePicker = true;
    }

    protected render() {
        return html`
            <omega-select
                ${ref(this.selectRef)}
                .items=${this.items}
                .value=${this.selectedField}
                placeholder="Select a Date Type"
                @change=${(e: CustomEvent) => this.onFieldSelected(e)}
            ></omega-select>
            <omega-datepicker
                .range=${this.range}
                .value=${this.value === '$TODAY' ? undefined : this.value}
                .placeholder=${this.placeholder}
                .disabled=${this.disableDatePicker}
                .dateDisabledFunction=${this.disableDateFn}
                @change=${(e: OmegaDatePickerChangeEvent) => this.onDateSelected(e)}
            ></omega-datepicker>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            omega-datepicker {
                margin-top: 5px;
            }
        `;
    }
}
