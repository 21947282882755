ForgotPasswordController.$inject = ['$scope', '$state', 'accountService'];

// eslint-disable-next-line @treasury/filename-match-export
export default function ForgotPasswordController($scope, $state, accountService) {
    $scope.reset = function () {
        init();
    };

    $scope.message = '';

    $scope.processForgotPassword = function () {
        accountService.forgotPassword($scope.forgotPassword).then(response => {
            if (response !== false) {
                $state.go('email-sent-confirmation');
            } else {
                $scope.message =
                    'The login information entered does not match any records in our system. Please re-enter the required information to try again.';
            }
        });
    };

    $scope.message = '';

    function init() {
        $scope.forgotPassword = {
            institution: document.documentElement.dataset.institution,
            companyId: '',
            loginId: '',
            password: '',
            email: '',
        };
    }

    init();

    $scope.initialize = function (form) {
        $scope.form = form;
    };
}
