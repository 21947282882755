/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable lines-between-class-members */
//@ts-ignore
import AchChildSupportServices from '@treasury/domain/channel/services/ach/ach-child-support-services.js';
import AchConfigurationServices from '@treasury/domain/channel/services/ach/ach-configuration-services.js';
import AchPaymentsServices from '@treasury/domain/channel/services/ach/ach-payments-services.js';
//@ts-ignore
import DownloadService from '@treasury/domain/channel/services/download/download-service.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity/user-activity-service.js';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { WorkflowTypes } from '@treasury/domain/channel/types/ach/constants';

export default class ChildSupportDetailsClient {
    async submitPayment(...args: any) {
        return AchChildSupportServices.createPayment(...args);
    }

    async achConfiguration() {
        const configuration: any = await AchConfigurationServices.getConfiguration();
        const entitlements = await EntitlementsService.getEntitlements();
        const companies = await AchChildSupportServices.getAchCompanies();
        return {
            companyMatchType: configuration.companyMatchType,
            cutoffTimes: configuration.cutoffTimes,
            holidays: configuration.holidays,
            allowSameDayPayments: configuration.isSameDayAchEnabled,
            entitlements,
            companies,
        };
    }

    async getChildSupportPaymentById(...args: any) {
        return AchChildSupportServices.getPaymentById(...args);
    }

    async getCurrentUser(...args: any) {
        return UsersService.fetchCurrentUser();
    }

    async getApprovers(...args: any) {
        return UsersService.fetchApprovers({ ...args });
    }

    async updateUserPrintActivity(...args: any) {
        return UserActivityService.print(...args);
    }

    async downloadChildSupportPayment(...args: any) {
        //@ts-ignore
        return DownloadService.download(...args);
    }

    async downloadChildSupportTemplate(guid: string, type: WorkflowTypes) {
        return AchChildSupportServices.downloadChildSupportTemplate(guid, type);
    }

    async updatePaymentStatus(...args: any) {
        return AchPaymentsServices.updateAchPaymentStatus(...args);
    }

    async getPaymentTemplate(guid: string) {
        return AchChildSupportServices.getPaymentTemplate(guid);
    }

    async deletePaymentTemplate(guid: string) {
        return AchChildSupportServices.deleteTemplate(guid);
    }
}
