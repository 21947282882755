/* eslint-disable @treasury/filename-match-export */
import { css } from 'lit';

export const workflowStyles = css`
    .workflow-button-bar-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid #e0e0e0;
    }
`;

export default workflowStyles;
