// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

filtersModule.filter('fileSizeFormatter', fileSizeFormatter);

fileSizeFormatter.$inject = ['$filter', '$locale'];

function fileSizeFormatter($filter, $locale) {
    return function (inputFileSize) {
        let outputFileSize = 0;

        // Ensure that the passed in data is a number
        if (isNaN(inputFileSize) || inputFileSize < 1) {
            // If the data is not a number or is less than one (thus not having a cardinal value) return it unmodified.
            return inputFileSize;
        }
        const sizeInBytes = inputFileSize * 1024;
        outputFileSize = formatSizeUnits(sizeInBytes);

        function formatSizeUnits(bytes) {
            if (bytes >= 1073741824) {
                bytes = `${(bytes / 1073741824).toFixed(2)} GB`;
            } else if (bytes >= 1048576) {
                bytes = `${(bytes / 1048576).toFixed(2)} MB`;
            } else if (bytes >= 1024) {
                bytes = `${(bytes / 1024).toFixed(2)} KB`;
            } else if (bytes > 1) {
                bytes += ' bytes';
            } else if (bytes == 1) {
                bytes += ' byte';
            } else {
                bytes = '0 byte';
            }
            return bytes;
        }

        return outputFileSize;
    };
}
