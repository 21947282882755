import { getChannelAPIPath } from '@treasury/core/http';
import { AchFilterServices } from '@treasury/domain/channel/services/ach/ach-filter-services.js';

ArpService.$inject = ['$resource'];

export default function ArpService($resource) {
    const resourceUrl = `${getChannelAPIPath()}arp/`;

    return {
        searchArpExceptions,
        updateArpExceptions,
        getAchExceptionFilters,
        searchAchExceptionFilters,
        searchArpOutputFiles,
        getAchExceptions,
        searchAchExceptions,
        findAchExceptions,
        findAchExceptionsDecisionsActivity,
        saveAchExceptions,
        updateAchExceptions,
        getSecCodes,
        getSavedFormats,
        getSavedFormat,
        updateSavedFormat,
        deleteSavedFormat,
        parseIssuedItems,
        saveIssuedItems,
        searchIssuedItems,
        getArpAccounts,
        getAchExceptionsCutOffTimes,
    };

    function searchArpExceptions(filter) {
        return $resource(
            `${resourceUrl}arpExceptions`,
            {},
            { search: { method: 'POST', isArray: false } }
        ).search(filter).$promise;
    }

    function updateArpExceptions(arpExceptions) {
        return $resource(resourceUrl).save(arpExceptions).$promise;
    }

    function getAchExceptionFilters() {
        return $resource(`${resourceUrl}achExceptionFilters`).query().$promise;
    }

    function searchAchExceptionFilters(filter) {
        return $resource(
            `${resourceUrl}achExceptionFilters`,
            {},
            { search: { method: 'POST', isArray: true } }
        ).search(filter).$promise;
    }

    function searchArpOutputFiles(filter) {
        return $resource(
            `${resourceUrl}arpOutputFiles`,
            {},
            { search: { method: 'POST', isArray: true } }
        ).search(filter).$promise;
    }

    function getAchExceptions() {
        return $resource(`${resourceUrl}achExceptions`).query().$promise;
    }

    function searchAchExceptions(filter) {
        return $resource(
            `${resourceUrl}searchAchExceptions`,
            {},
            { search: { method: 'POST', isArray: true } }
        ).search(filter).$promise;
    }

    async function findAchExceptions(filter) {
        return AchFilterServices.findAchExceptions(filter);
    }

    function findAchExceptionsDecisionsActivity(searchModel) {
        return $resource(
            `${resourceUrl}findAchExceptionsDecisionsActivity`,
            {},
            { search: { method: 'POST' } }
        ).search(searchModel).$promise;
    }

    function saveAchExceptions(achExceptions) {
        return $resource(`${resourceUrl}saveAchExceptions`).save(achExceptions).$promise;
    }

    function updateAchExceptions(achExceptions) {
        return $resource(`${resourceUrl}achExceptions`).save(achExceptions).$promise;
    }

    function getSecCodes() {
        return $resource(`${resourceUrl}secCodes`).query().$promise;
    }

    function getSavedFormats() {
        return $resource(`${resourceUrl}savedFormats`).query().$promise;
    }

    function getSavedFormat(id) {
        return $resource(`${resourceUrl}savedFormat`, { id }).get().$promise;
    }

    function updateSavedFormat(savedFormat) {
        return $resource(`${resourceUrl}savedFormat`).save(savedFormat).$promise;
    }

    function deleteSavedFormat(id) {
        return $resource(`${resourceUrl}deleteSavedFormat/:id`).delete({ id }).$promise;
    }

    function saveIssuedItems(issuedItems, fileName) {
        const arpModel = { account: null, issuedItems, issuedFileName: fileName };
        return $resource(
            `${resourceUrl}saveIssuedItems`,
            {},
            {
                save: {
                    method: 'POST',
                    isArray: true,
                },
            }
        ).save(arpModel).$promise;
    }

    function parseIssuedItems(formatId, arpData) {
        return $resource(
            `${resourceUrl}parseIssuedItems`,
            {},
            {
                save: {
                    method: 'POST',
                    transformRequest: tranformParseIssuedItemsRequest,
                    headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' },
                },
            }
        ).save({ formatId, arpData }).$promise;
    }

    function searchIssuedItems(filter) {
        return $resource(
            `${resourceUrl}searchIssuedItems`,
            {},
            { search: { method: 'POST', isArray: true } }
        ).search(filter).$promise;
    }

    function tranformParseIssuedItemsRequest(data) {
        const fd = new FormData();
        fd.append('formatId', data.formatId);
        fd.append('arpData', data.arpData);
        return fd;
    }

    function getArpAccounts(claimType) {
        return $resource(`${resourceUrl}GetArpAccounts/:claimType`, {
            claimType,
        }).query().$promise;
    }

    function getAchExceptionsCutOffTimes() {
        return $resource(
            `${resourceUrl}achExceptionsCutOffTimes`,
            {},
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }
}
