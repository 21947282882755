export default () => ({
    restrict: 'E',
    template: require('./amountFilter.html'),
    scope: {
        filterValue: '=',
    },
    controller() {
        const vm = this;

        vm.isAmountType = isAmountType;

        vm.amountTypes = {
            '': 'undefined',
            'Specific Value': 'specific',
            Range: 'range',
        };

        if (vm.filterValue == null) {
            vm.filterValue = {};
        }
        if (vm.filterValue.amountType == null) {
            vm.filterValue.amountType = 'undefined';
        }

        function isAmountType(amountType) {
            return vm.filterValue.amountType === amountType;
        }
    },
    controllerAs: 'vm',
    bindToController: true,
});
