FrequencyService.$inject = ['$resource', '$q', '$http', '$filter'];

export default function FrequencyService($resource, $q, $http, $filter) {
    return {
        summarizeFrequency,
        getFrequencies,
        getWeekdays,
        getMaximumDate,
        frequencyTypeToFriendly,
    };

    function getFrequencies() {
        return [
            'One Time',
            'Weekly',
            'Every Two Weeks',
            'Twice A Month',
            'Monthly',
            'Quarterly',
            'Every Six Months',
            'Yearly',
        ];
    }

    function getWeekdays() {
        return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }

    function getMaximumDate(startDate, frequencyType) {
        let date = moment(new Date(startDate));

        if (frequencyType === 'Every Six Months') {
            date = date.add(6, 'months');
        } else if (frequencyType === 'Monthly' || frequencyType === 'Twice A Month') {
            date = date.add(1, 'month');
        } else if (frequencyType === 'Quarterly') {
            date = date.add(3, 'months');
        } else if (frequencyType === 'Yearly' || frequencyType === 'Annually') {
            date = date.add(1, 'year');
        } else if (frequencyType === 'Weekly') {
            date = date.add(1, 'week');
        } else if (frequencyType === 'Every Two Weeks') {
            date = date.add(2, 'weeks');
        }

        return date.format('MM/DD/YYYY');
    }

    function summarizeFrequency(frequency) {
        if (frequency.type === 'One Time') {
            return 'One Time.';
        }

        let summary = 'Occurs ';
        const type = frequency.type.toLowerCase().replace(/\s+/g, '');
        switch (type) {
            case 'weekly':
                summary += ` every ${getDayOfWeek(frequency)} ${getStartAndEnd(frequency)}`;
                break;
            case 'everytwoweeks':
                summary += ` on ${getDayOfWeek(frequency)} every two weeks ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'twiceamonth':
                summary += ` on ${getDaysOfMonth(frequency, true)} every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'monthly':
                summary += ` on ${getDaysOfMonth(frequency)} of every month ${getStartAndEnd(
                    frequency
                )}`;
                break;
            case 'quarterly':
                summary += ` on the ${getDayForADate(
                    frequency.startOn
                )} every three months ${getStartAndEnd(frequency)}`;
                break;
            case 'everysixmonths':
                summary += ` on the ${getDayForADate(
                    frequency.startOn
                )} every six months ${getStartAndEnd(frequency)}`;
                break;
            case 'yearly':
            case 'annually':
                summary += ` on ${formatDateWithoutYear(
                    frequency.startOn
                )} every year ${getStartAndEnd(frequency)}`;
                break;
        }
        summary += '.';
        return summary;
    }

    //
    // Private

    function getDayOfWeek(frequency) {
        return frequency.repeatOn;
    }

    function getDayForADate(date) {
        return formatDay($filter('date')(date, 'd'));
    }

    function getDaysOfMonth(frequency, twoDays) {
        const day1 = frequency.repeatOnDay1;
        const day2 = frequency.repeatOnDay2;

        if (!twoDays) {
            if (frequency.repeatOnLastBusinessDay) {
                return 'the last business day';
            }
            return `the ${formatDay(day1)}`;
        }
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }

    function formatDay(day) {
        if (typeof day === 'string' && day.split('/').length === 3) {
            day = day.split('/')[1];
        }
        if (typeof day === 'string') {
            day = parseInt(day);
        }
        if (day === 1 || day === 21 || day === 31) {
            return `${day}st`;
        }
        if (day === 2 || day === 22) {
            return `${day}nd`;
        }
        if (day === 3 || day === 23) {
            return `${day}rd`;
        }
        return `${day}th`;
    }

    function getStartAndEnd(frequency) {
        if (frequency.endOn) {
            return `from ${formatDate(frequency.startOn)} to ${formatDate(frequency.endOn)}`;
        }
        return `starting on ${formatDate(frequency.startOn)}`;
    }

    function formatDate(date) {
        return $filter('date')(date, 'MM/dd/yyyy');
    }

    function formatDateWithoutYear(date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        return $filter('date')(date, 'MM/dd');
    }

    function frequencyTypeToFriendly(frequencyType) {
        if (frequencyType === null) {
            return 'Undefined';
        }

        let result = frequencyType;

        switch (frequencyType) {
            case 'EveryTwoWeeks':
                result = 'Every Two Weeks';
                break;
            case 'TwiceAMonth':
                result = 'Twice a Month';
                break;
            case 'EverySixMonths':
                result = 'Every Six Months';
                break;
            case 'OneTime':
                result = 'One Time';
                break;
        }

        return result;
    }
}
