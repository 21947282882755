MessageCenterController.$inject = [
    '$scope',
    '$filter',
    '$uibModal',
    'toaster',
    'messagingCenterService',
    'navigationService',
    'menulessOptions',
];

export default function MessageCenterController(
    $scope,
    $filter,
    $uibModal,
    toaster,
    messagingCenterService,
    navigationService,
    menulessOptions
) {
    $scope.currentView = 1;
    $scope.setView = setView;
    $scope.compose = compose;
    $scope.advancedSearch = advancedSearch;
    $scope.showMessage = showMessage;
    $scope.messages = null;
    $scope.filteredMessages = null;
    $scope.filterMessages = filterMessages;
    $scope.filter = '';
    $scope.resolve = resolve;
    $scope.assign = assign;
    $scope.archive = archive;
    $scope.fromToField = 'From';
    $scope.refresh = refresh;
    $scope.cachedSearch = {};

    (function () {
        // init
        navigationService.userActivityAudit(menulessOptions.MessageCenter);
        loadMessages();
    })();

    function loadMessages(searchTerms, fromSearch, currentView, silent) {
        currentView = currentView || $scope.currentView;
        if (!searchTerms) {
            searchTerms = {};
            switch (currentView) {
                case 1:
                    searchTerms.messageTypeId = 2;
                    break;
                case 2:
                    searchTerms.messageTypeId = 3;
                    break;
                case 4:
                    searchTerms.messageTypeId = 4;
                    break;
                case 5:
                    searchTerms = $scope.cachedSearch.terms;
                    break;
                default:
                    break;
            }
        }

        const messageSvcCall = silent
            ? messagingCenterService.silentSearch
            : messagingCenterService.search;

        messageSvcCall(searchTerms).then(response => {
            $scope.messages = response;
            $scope.filter = '';
            filterMessages();

            if (fromSearch) {
                $scope.currentView = 5;
            } else {
                $scope.currentView = currentView;
            }
        });
    }

    function setView(view) {
        $scope.cachedSearch = {};
        loadMessages(null, null, view, false);
    }

    function refresh() {
        loadMessages();
    }

    $scope.$on('messageCountUpdated', () => {
        loadMessages(undefined, undefined, undefined, true);
    });

    $scope.$watch('filter', filterMessages);

    function filterMessages() {
        $scope.filteredMessages = $filter('filter')($scope.messages, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.filter ||
            $scope.filter.trim() === '' ||
            !$scope.messages ||
            $scope.messages.length <= 0
        ) {
            return true;
        }

        return (
            (row.messageId &&
                row.messageId.toLowerCase().indexOf($scope.filter.toLowerCase()) !== -1) ||
            (row.subject &&
                row.subject.toLowerCase().indexOf($scope.filter.toLowerCase()) !== -1) ||
            (row.body && row.body.toLowerCase().indexOf($scope.filter.toLowerCase()) !== -1) ||
            (row.sentDate &&
                row.sentDate.toLowerCase().indexOf($scope.filter.toLowerCase()) !== -1) ||
            (row.sentTime &&
                row.sentTime.toLowerCase().indexOf($scope.filter.toLowerCase()) !== -1) ||
            (row.fromToUserName &&
                row.fromToUserName.toLowerCase().indexOf($scope.filter.toLowerCase()) !== -1 &&
                $scope.currentView !== 2)
        );
    }

    function advancedSearch() {
        const modalInstance = $uibModal.open({
            template: require('../views/messageCenterSearchModalView.html'),
            size: 'md',
            controller: 'MessageCenterSearchModalController',
            backdrop: 'static',
            resolve: {
                cachedSearch() {
                    return $scope.cachedSearch;
                },
            },
        });
        modalInstance.result.then(response => {
            loadMessages(response.terms, true);
            $scope.cachedSearch = response;
        });
    }

    function compose() {
        const modalInstance = $uibModal.open({
            template: require('../views/messageCenterComposeModalView.html'),
            size: 'md',
            controller: 'MessageCenterComposeModalController',
            backdrop: 'static',
        });
        modalInstance.result.then(response => {
            if (response) {
                toaster.send('Message');
                setView(1);
            }
        });
    }

    function showMessage(message) {
        if (message.status === 'New') {
            message.status = 'Open';
        }
        const modalInstance = $uibModal.open({
            template: require('../views/messageCenterViewModalView.html'),
            size: 'md',
            controller: 'MessageCenterViewModalController',
            backdrop: 'static',
            resolve: {
                message() {
                    return message;
                },
            },
        });
        modalInstance.result.then(response => {
            if (response.refresh) {
                refresh();
            } else if (response) {
                setView(1);
                toaster.send('Reply');
            }
        });
    }

    function resolve(message) {
        const modalInstance = $uibModal.open({
            template: './../views/messageCenterResolveModalView.html', // TODO: Where is this file?
            size: 'md',
            controller: 'messageCenterResolveModalController',
            backdrop: 'static',
            resolve: {
                message() {
                    return message;
                },
            },
        });
        modalInstance.result.then(response => {
            message = response;
            toaster.save('Message');
        });
    }

    function assign(message) {
        const modalInstance = $uibModal.open({
            template: './../views/messageCenterAssignModalView.html', // TODO: Where is this file?
            size: 'md',
            controller: 'messageCenterAssignModalController',
            backdrop: 'static',
            resolve: {
                message() {
                    return message;
                },
            },
        });
        modalInstance.result.then(response => {
            message = response;
            toaster.save('Message');
        });
    }

    function archive(message) {
        const modalInstance = $uibModal.open({
            template: require('../views/messageCenterArchiveModalView.html'),
            size: 'md',
            controller: 'MessageCenterArchiveModalController',
            backdrop: 'static',
            resolve: {
                message() {
                    return message;
                },
            },
        });
        modalInstance.result.then(response => {
            message = response;
            toaster.save('Message');
            refresh();
        });
    }
}
