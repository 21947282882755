import { number } from '@treasury/FDL';
import nachaAlphaNumeric from '@treasury/policy/lib/validators/nacha/nacha-alphanumeric.validator.js';
import {
    amountRange,
    boolean,
    datePicker,
    list,
    money,
    status,
    string,
} from '@treasury/policy/primitives';
import { html } from 'lit';
import '../../../components/channel-status-badge.js';

const canTakeApprovalAction = record => {
    const pendingApproval = record.getField('status') === 'Pending Approval';
    const userCanApprove = record.getField('userPermissions').includes('ApproveTemplate');
    return pendingApproval && userCanApprove;
};

const canTakeDeleteAction = record => {
    const recordStatus = record.getField('status');
    const statusAcceptableForDelete =
        recordStatus === 'Ready' || recordStatus === 'Approval Rejected';
    const canDelete = record.getField('userPermissions').includes('DeleteTemplate');
    return statusAcceptableForDelete && canDelete;
};

const actions = [
    {
        label: 'Delete',
        action: 'delete',
        visibleWhen: canTakeDeleteAction,
    },
    {
        label: 'Approve/Reject',
        action: 'approve',
        visibleWhen: canTakeApprovalAction,
    },
    {
        label: 'Initiate',
        action: 'initiate',
        visibleWhen: record => record.getField('status') === 'Ready',
    },
];

export const columns = [
    {
        type: 'checkbox',
        field: 'selected',
        allowOne: true,
        sortable: true,
    },
    {
        field: 'templateName',
        label: 'Template Name',
        type: 'command',
        action: 'clickTemplateName',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company Name',
    },
    {
        field: 'lastUpdated',
        label: 'Last Updated',
    },
    {
        field: 'debitAmount',
        label: 'Debit Amount',
        summary: true,
    },
    {
        field: 'creditAmount',
        label: 'Credit Amount',
        summary: true,
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions,
    },
];

const irregularStatus = {
    PendingApproval: 'Pending Approval',
    Rejected: 'Approval Rejected',
};

export const fields = client => ({
    selected: boolean.as
        .tag('omega-checkbox')
        .thatIs.disabledWhen(
            record =>
                record.getField('status') === 'Pending Approval' ||
                record.getField('status') === 'Approval Rejected'
        ),
    templateName: string.thatIs.readOnly(),
    achCompanyName: string.thatIs.readOnly(),
    secCode: string.thatIs.readOnly(),
    lastUpdated: datePicker.thatIs.readOnly(),
    debitAmount: money.thatIs.readOnly().with.reducer('sum'),
    creditAmount: money.thatIs.readOnly().with.reducer('sum'),
    numberOfApprovalsNeeded: number.thatIs.visibleWhen(() => false),
    completedApprovalCount: number.thatIs.visibleWhen(() => false),
    status: status.thatIs
        .readOnly()
        .with.template((statusValue, record) => {
            const component = html`<channel-status-badge
                .fetchApproversFunction=${async () =>
                    client.getTemplateApprovers(record.getField('templateId'))}
                .status=${statusValue}
                type="tracker"
                .approvalsNeeded=${record.getField('numberOfApprovalsNeeded')}
                .approvalsCount=${record.getField('completedApprovalCount')}
            ></channel-status-badge>`;
            component.toString = () => irregularStatus[statusValue] ?? statusValue;
            return component;
        })
        .and.textAlign('left'),
    userPermissions: list.thatIs.visibleWhen(() => false),
});

export const filters = client => [
    {
        field: 'status',
        fieldType: status.with
            .label('Status')
            .with.options([
                {
                    text: 'All',
                    value: 'All',
                },
                {
                    text: 'Pending Approval',
                    value: 1,
                },
                {
                    text: 'Approval Rejected',
                    value: 3,
                },
                {
                    text: 'Ready',
                    value: 2,
                },
            ])
            .as.tag('omega-select'),
        value: 'All',
    },
    {
        field: 'achCompanyList',
        fieldType: string.with
            .label('ACH Company Name')
            .and.options({
                fetch: () => client.getAchCompanies(),
                text: record => record.companyName,
                value: record => record,
            })
            .with.hashFunction(item => item.id)
            .and.multipleValues()
            .as.tag('omega-select'),
    },
    {
        label: 'Template Name',
        field: 'batchName',
        fieldType: string.with
            .label('Template Name')
            .thatHas.maxLength(50)
            .and.validator(nachaAlphaNumeric)
            .as.tag('omega-input'),
        value: null,
    },
    {
        label: 'Debit Amount',
        field: 'debitAmount',
        fieldType: amountRange.with
            .label('Debit Amount')
            .and.defaultValue(['specific', 0, 0])
            .as.tag('omega-range'),
    },
    {
        label: 'Credit Amount',
        field: 'creditAmount',
        fieldType: amountRange.with
            .label('Credit Amount')
            .and.defaultValue(['specific', 0, 0])
            .as.tag('omega-range'),
    },
    {
        label: 'Last Updated',
        field: 'updatedDate',
        fieldType: datePicker.with
            .label('Last Updated')
            .and.options([
                'today',
                'specific',
                'range',
                'week-to-date',
                'month-to-date',
                'year-to-date',
            ])
            .with.defaultValue('')
            .as.tag('omega-datepicker'),
    },
];
