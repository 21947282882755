import '@treasury/omega/components/progress/omega-progress.js';
import { css, html, LitElement } from 'lit';

class BlockingLoader extends LitElement {
    static get properties() {
        return {};
    }

    render() {
        return html`<div class="background">
            <omega-progress card class="light-loader"></omega-progress>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .light-loader {
                color: #fff;
                width: 50px;
                height: 50px;
                border-width: 5px;
                margin-top: 25%;
            }
            .background {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: var(--omega-dialog-overlay);
                opacity: 0;
                z-index: -1;
                height: 100%;
                width: 100%;
                display: block;
                opacity: 1;
                visibility: visible;
                z-index: 999;
            }
        `;
    }
}

customElements.define('blocking-loader', BlockingLoader);
