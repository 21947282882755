AchExceptionsDecisionsActivityController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'achExceptionDecisionStatusTypes',
    'arpService',
    'companyAccountsService',
    'downloadPageId',
    'secCodesService',
    'spinnerService',
    'modalService',
    'entitlementsService',
    '$scope',
];

export default function AchExceptionsDecisionsActivityController(
    $q,
    $state,
    $stateParams,
    $timeout,
    $uibModal,
    achExceptionDecisionStatusTypes,
    arpService,
    companyAccountsService,
    downloadPageId,
    secCodesService,
    spinnerService,
    modalService,
    entitlementsService,
    $scope
) {
    const spinnerServiceGroupName = 'decisionsActivities';

    const vm = this;
    vm.downloadPageId = downloadPageId.AchExceptionsDecisionActivityList;
    vm.maxDate = new Date();

    vm.searchParams = {
        type: 'OpenItems',
        achExceptionDecisionStatus: 'all',
    };

    vm.downloadOptions = {};

    vm.exportOptions = { canPrint: true };
    vm.accounts = [];
    vm.secCodes = [];
    vm.amountOptions = ['Specific Amount', 'Amount Range'];
    vm.dateOptions = ['Specific Date', 'Date Range'];
    vm.searchText = '';
    vm.allowEdit = false;
    vm.accountDisplayField = '';
    vm.filteredExceptions = [];

    vm.pageSizeChange = pageSizeChange;
    vm.paginate = paginate;

    vm.filterExceptions = filterExceptions;
    vm.exceptions = [];
    vm.exceptionTextFilter = '';
    vm.orderColumn = 'DecisionDate';
    vm.orderDescending = true;
    vm.pageSizeOptions = [10, 25, 50, 100];
    vm.pagination = {
        pageSize: vm.pageSizeOptions[0],
        pageNumber: 1,
        orderBys: buildOrderBys(),
    };
    vm.pageStart = 0;
    vm.pageEnd = 0;
    vm.selectedExceptions = {};
    vm.achExceptionsCutOffTimes = {};
    vm.totalExceptionCount = 0;
    vm.showPagination = showPagination;

    vm.sort = sort;
    vm.sortIconClasses = sortIconClasses;

    vm.exceptionTextFilterDisabled = exceptionTextFilterDisabled;

    vm.toggleSidebarClosed = toggleSidebarClosed;
    vm.search = search;
    vm.goToAOpentems = goToAOpentems;
    vm.reset = reset;
    vm.allowDataDisplay = allowDataDisplay;
    vm.resetAmount = resetAmount;
    vm.resetPostedDate = resetPostedDate;
    vm.lastFilterPromise = null;
    vm.getDecision = getDecision;
    vm.toggleExpanded = toggleExpanded;
    vm.closeExpanded = closeExpanded;
    vm.showCreateFilterModal = showCreateFilterModal;
    vm.getNoDecisionMessage = getNoDecisionMessage;
    vm.noDecision = noDecision;
    vm.isSearchFormValid = isSearchFormValid;
    vm.isACHFilterEnabled = isACHFilterEnabled;
    vm.hasACHCreateFilter = hasACHCreateFilter;
    vm.goToFilterRules = goToFilterRules;

    function isSearchFormValid() {
        return vm.searchParams.accounts && vm.searchParams.accounts.length;
    }

    function getNoDecisionMessage(exception) {
        return `No Decision made prior to ${vm.achExceptionsCutOffTimes.institutionName || ''}'s ${
            vm.cutoffTime
        } cut-off time (${vm.timeZone}).`;
    }

    function noDecision(exception) {
        return (
            exception.achExceptionDecisionStatusId === achExceptionDecisionStatusTypes.noDecision
        );
    }

    function toggleExpanded(exception) {
        exception.expanded = !exception.expanded;
    }

    function closeExpanded(exception) {
        exception.expanded = false;
    }

    function isACHFilterEnabled() {
        return entitlementsService.hasEntitlement('Feature.PositivePay.ACHFilterManagement');
    }

    function hasACHCreateFilter() {
        return isACHFilterEnabled() && entitlementsService.hasEntitlement('Create ACH Filter');
    }

    function showCreateFilterModal(exception) {
        const modalOptions = {
            headerText: 'Create a New ACH Filter',
            bodyText: 'Click continue to create a new ACH Filter.',
            actionButtonText: 'Continue',
            closeButtonText: 'Cancel',
            submit(result) {
                $modalInstance.close(result);
                $state.go('payables.arp.ach-filter-workflow-create-from-decision', {
                    exceptionJson: exception,
                    id: exception.id,
                    referringPage: 'payables.arp.ach-exceptions-activity-list',
                });
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function getDecision(exception) {
        switch (exception.achExceptionDecisionStatusId) {
            case achExceptionDecisionStatusTypes.pay:
                return 'PAY';
            case achExceptionDecisionStatusTypes.return:
                return 'RETURN';
            case achExceptionDecisionStatusTypes.noDecision:
                return 'NO DECISION';
            default:
                return '';
        }
    }

    function resetAmount() {
        if (vm.searchParams) {
            vm.searchParams.amount = '';
            vm.searchParams.amountEnd = '';
            vm.searchParams.amountStart = '';
        }
    }

    function resetPostedDate() {
        if (vm.searchParams) {
            vm.searchParams.postedDate = '';
            vm.searchParams.postedDateEnd = '';
            vm.searchParams.postedDateStart = '';
        }
    }

    function goToAOpentems() {
        $state.go('payables.arp.ach-exceptions-list');
    }

    function goToFilterRules() {
        $state.go('payables.arp.ach-filters');
    }

    function showPagination() {
        return vm.totalExceptionCount > vm.exceptions.length;
    }

    function reset() {
        initReset();
    }

    function allowDataDisplay() {
        return vm.exceptions.length;
    }

    function toggleSidebarClosed() {
        vm.sidebarClosed = !vm.sidebarClosed;
    }

    function filterExceptions() {
        const request = angular.copy(vm.lastSearch);
        request.searchText = getSearchText();

        if (request.searchText === vm.lastSearch.searchText) return;

        request.pagingParameters.currentPage = 1;
        vm.lastSearch = request;
        spinnerService.startGroupRequest(spinnerServiceGroupName);
        vm.lastFilterPromise = getExceptions(request, vm.cancelRequestPromise);
        vm.lastFilterPromise.finally(filterPromiseComplete);
    }

    function exceptionTextFilterDisabled() {
        return vm.exceptions.length === 0 && vm.exceptionTextFilter.length === 0;
    }

    function reloadOnCutoff() {
        getAchExceptionsCutOffTimes().then(() => {
            search();
        });
    }

    function pageSizeChange() {
        vm.pagination.pageNumber = 1;
        paginate();
    }

    function paginate() {
        vm.lastSearch.pagingParameters = vm.pagination;
        getExceptions(vm.lastSearch);
    }

    function search() {
        vm.pagination.currentPage = 1;
        vm.lastSearch = getExceptionsRequest();
        getExceptions(vm.lastSearch);
    }

    function getExceptionsRequest() {
        return {
            pagingParameters: vm.pagination,
            achExceptionDetailSearchModel: vm.searchParams,
            searchText: getSearchText(),
            searchAccountNickName: vm.searchAccountNickName,
            currentBusinessDayCutoff: vm.achExceptionsCutOffTimes.current,
            previousBusinessDayCutoff: vm.achExceptionsCutOffTimes.previous,
        };
    }

    function getSearchText() {
        return vm.exceptionTextFilter.length < 3 ? '' : vm.exceptionTextFilter;
    }

    function getExceptions(request) {
        cancelPreviousSearch();

        return arpService.findAchExceptionsDecisionsActivity(request).then(response => {
            vm.exceptions = response.items;
            vm.totalExceptionCount = response.totalCount;
            vm.pageStart = (response.pageNumber - 1) * response.pageSize + 1;
            vm.pageEnd =
                (response.pageNumber - 1) * response.pageSize + response.recordCountInCurrentPage;

            if (vm.exceptions && vm.exceptions.length === 1) {
                vm.exceptions[0].expanded = true;
            }

            vm.sidebarClosed = !(vm.exceptions == null || vm.exceptions.length === 0);

            vm.isDataLoaded = true;
            return response;
        });
    }

    function cancelPreviousSearch() {
        if (vm.lastFilterPromise != null) {
            vm.lastFilterPromise.cancel();
            filterPromiseComplete();
        }
    }

    function filterPromiseComplete() {
        vm.lastFilterPromise = null;
        spinnerService.stopGroupRequest(spinnerServiceGroupName);
    }

    function sort(newOrderColumn) {
        if (newOrderColumn === vm.orderColumn) {
            vm.orderDescending = !vm.orderDescending;
        } else {
            vm.orderDescending = false;
            vm.orderColumn = newOrderColumn;
        }
        vm.pagination.orderBys = buildOrderBys();
        vm.pagination.pageNumber = 1;
        paginate();
    }

    function sortIconClasses(sortField) {
        const classes = ['fa'];
        if (sortField !== vm.orderColumn) {
            classes.push('fa-sort');
        } else {
            classes.push(vm.orderDescending ? 'fa-sort-desc' : 'fa-sort-asc');
        }

        return classes.join(' ');
    }

    function buildOrderBys() {
        return [
            {
                name: vm.orderColumn,
                descending: vm.orderDescending,
            },
        ];
    }

    function initReset() {
        vm.accounts.forEach(account => {
            account.isChecked = true;
        });

        vm.secCodes.forEach(secCode => {
            secCode.isChecked = false;
        });

        vm.searchParams.amountType = null;
        vm.resetAmount();

        vm.searchParams.postedDateType = null;
        vm.resetPostedDate();

        vm.searchParams.description = '';
        vm.searchParams.achExceptionDecisionStatus = 'all';
    }

    function loadCutoffTimes() {
        return companyAccountsService.getCutoffTimesByProductType('AchException').then(response => {
            vm.timeZone = response.fiTimeZone;

            const timeUntilCutoff = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            vm.cutoffTime = convertTime(response.processingCutoff.cutoffTime);

            if (timeUntilCutoff > 0) {
                // If before Cut-Off time
                //  set a timed event to reload the page when the timeout expires
                $timeout(() => {
                    reloadOnCutoff();
                }, timeUntilCutoff);
            }
        });
    }

    function loadAccounts() {
        return arpService.getArpAccounts('WorkAchException').then(response => {
            if (response.length === 0) {
                $uibModal
                    .open({
                        template: require('../views/missingAccountsModal.html'),
                        controller: 'MissingAccountsModalController',
                        backdrop: 'static',
                        resolve: {
                            contextSpecificMessage() {
                                return "You don't have any accounts associated with ACH exceptions.";
                            },
                        },
                    })
                    .result.then(() => {
                        $state.go('dashboard');
                    });
                return;
            }

            vm.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            vm.accountSortField = response[0].showAccountNickName ? 'AccountName' : 'AccountNumber';
            vm.searchAccountNickName = response[0].showAccountNickName;
            vm.accounts = response;

            if (!$stateParams.accountNumber) {
                vm.accounts.forEach(account => {
                    account.isChecked = true;
                });
                vm.searchParams.accounts = vm.accounts;
            } else if ($stateParams.accountNumber) {
                vm.searchParams.accounts = [];
                vm.accounts.forEach(account => {
                    if (
                        (account.showAccountNickName &&
                            account.name === $stateParams.accountNumber) ||
                        (!account.showAccountNickName &&
                            account.number === $stateParams.accountNumber)
                    ) {
                        account.isChecked = true;
                    } else {
                        account.isChecked = false;
                    }
                });
            }
        });
    }

    function loadSecCodes() {
        return secCodesService.getAll().then(response => {
            if (!(response && response.length)) return;

            vm.secCodes = response.map(secCode => ({ code: secCode, isChecked: true }));

            vm.searchParams.secCodes = vm.secCodes;
        });
    }

    function getAchExceptionsCutOffTimes() {
        return arpService.getAchExceptionsCutOffTimes().then(response => {
            if (!response) return;

            vm.achExceptionsCutOffTimes.previous = response.previousBusinessDayCutoff;
            vm.achExceptionsCutOffTimes.current = response.currentBusinessDayCutoff;
            vm.achExceptionsCutOffTimes.institutionName = response.institutionName;
        });
    }

    function convertTime(time) {
        return moment(`${moment().format('l')} ${time}`).format('LT');
    }

    function init() {
        initReset();

        $q.all([
            loadAccounts(),
            loadSecCodes(),
            loadCutoffTimes(),
            getAchExceptionsCutOffTimes(),
        ]).then(() => {
            search(vm.searchParams);
            vm.downloadOptions = {
                downloadFormats: ['PDF', 'CSV'],
                pageId: vm.downloadPageId,
                filterModel: getExceptionsRequest(),
            };
        });

        spinnerService.configureGroup(spinnerServiceGroupName, [
            'findAchExceptionsDecisionsActivity',
        ]);
    }

    init();
}
