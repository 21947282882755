import { dateRangePicker, dateRangePickerNoDefault, string } from '@treasury/policy/primitives';

export const reportFilters = client => [
    {
        field: 'effectiveDate',
        fieldType: dateRangePickerNoDefault.with
            .label('Original Effective Date')
            .with.defaultValue(null),
    },
    {
        field: 'receivedDate',
        fieldType: dateRangePicker.with.label('Date Return Received'),
    },
    {
        field: 'achCompanyIds',
        fieldType: string.with
            .label('ACH Company')
            .with.hashFunction(company => company.companyGuidUniqueId)
            .with.options({
                fetch: client.getCompanies,
                text: record =>
                    `${record.companyName} - ${record.companyId} - ${record.secCodes[0]?.code}`,
                value: record => record,
            })
            .with.filtering()
            .and.multipleValues()
            .thatHas.placeholder('Select ACH Company'),
        value: [],
    },
    {
        field: 'recipientName',
        fieldType: string.with
            .label('Recipient Name')
            .and.minLength(2)
            .and.maxLength(22)
            .thatHas.inputMask(/[A-Za-z0-9 ]/),
        value: '',
    },
];
