import { LitElement, html, css, nothing } from 'lit';
import './omega-input.js';
import './omega-button.js';

export default class OmegaAdditionalInfo extends LitElement {
    static get properties() {
        return {
            label: String,
            field: Object,
            values: Array,
            fields: Array,
            maximumItemCount: Number,
            minimumItemCount: Number,
            maxLength: Number, // we'll pass this to omega-input instead of the field model upon refactor
        };
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-additional-info--empty',
        };
    }

    get canAddMoreLines() {
        return this.values.length < this.field.maxValueCount();
    }

    get values() {
        return this.field.value;
    }

    set values(v) {
        this.field.value = v;
        this.requestUpdate(); // we'll have to keep this request until we remove the field dependency
    }

    // review updated usage
    updated() {
        if (this.values?.length === 0) {
            this.values = [''];
        }
    }

    addARow() {
        this.values = [...this.values, ''];
        this.dispatchChangeEvent();
        setTimeout(() => {
            this.shadowRoot.querySelectorAll('omega-input')[this.values.length - 1].focus();
        });
    }

    deleteRow(index) {
        this.values = this.values.filter((v, i) => i !== index);
        this.shadowRoot
            .querySelectorAll('omega-input')
            [Math.min(index, this.values.length - 1)].focus();
        this.dispatchChangeEvent();
    }

    updateRow(index, value) {
        this.values = this.values.map((v, i) => (i === index ? value : v));
        this.dispatchChangeEvent();
    }

    dispatchChangeEvent() {
        this.dispatchEvent(new CustomEvent('change', { detail: { value: this.values } }));
    }

    renderRow(index) {
        const deleteButton =
            this.values.length === 1
                ? nothing
                : html`<omega-button
                      type="icon"
                      icon="trash"
                      x-small
                      @click=${() => this.deleteRow(index)}
                      hideLabel="always"
                      >Remove</omega-button
                  >`;
        const addButton =
            index === this.values.length - 1 && this.canAddMoreLines
                ? html`<omega-button
                      type="icon"
                      icon="plus"
                      x-small
                      @click=${this.addARow}
                      hideLabel="always"
                      >Add</omega-button
                  >`
                : nothing;

        return html`<li>
            <div class="input-cell">
                <omega-input
                    .label="${this.label} Line ${index + 1}"
                    .field=${this.field.field(index)}
                    @change=${e => this.updateRow(index, e.target.value, e)}
                ></omega-input>
            </div>
            <div>${addButton}</div>
            <div>${deleteButton}</div>
        </li>`;
    }

    renderRows() {
        return this.values.map((value, index) => this.renderRow(index));
    }

    render() {
        if (!this.field) return html`<div>loading</div>`;
        return html`<ul>
            ${this.renderRows()}
        </ul>`;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
            }

            ul {
                display: flex;
                flex-direction: column;
                flex: 1;
                border-collapse: collapse;
                padding: 0;
                margin: 0;
                list-style-type: none;
            }
            li {
                flex: 0;
                display: flex;
                align-items: center;
                padding: 0;
                margin: var(--omega-input-margin, 8px) 0;
            }
            li:first-of-type {
                margin-top: 0;
            }
            li:last-of-type {
                margin-bottom: 0;
            }

            li > div {
                flex: 0;
                margin: 0;
                padding: 0;
                display: flex;
            }

            .input-cell {
                flex-grow: 1;
                flex: 1;
            }
            omega-input {
                width: 100%;
            }
        `;
    }
}

customElements.define('omega-additional-info', OmegaAdditionalInfo);
