import AchTemplateService from '@treasury/domain/channel/services/ach/ach-template-service.js';

export default class AchTemplatesClient {
    async fetchAchTemplates(...args) {
        return AchTemplateService.fetchAchTemplates(...args);
    }

    async fetchAchCompanies(...args) {
        return AchTemplateService.fetchCompanies(...args);
    }

    async fetchSecCodes(...args) {
        return AchTemplateService.fetchSecCodes(...args);
    }

    async fetchAchSettings() {
        return AchTemplateService.fetchAchSettings();
    }

    async initiatePaymentsByIds(recordValues) {
        return AchTemplateService.initiatePaymentsByIds(recordValues);
    }

    async deleteTemplateById(id) {
        return AchTemplateService.deleteTemplateById(id);
    }

    async downloadAchTemplates(outputType, filterModel) {
        return AchTemplateService.downloadAchTemplates(outputType, filterModel);
    }
}
