import { LitElement, css } from 'lit';

class ACHFilterDetailRowStyles extends LitElement {
    static screenStyle() {
        return css`
            :host {
                display: block;
            }
            div.detail-row-container {
                padding: 0px;
                padding-bottom: 8px;
                margin-top: 0px;
            }
            div.detail-row-header {
                height: 30px;
                padding: 4px 17px;
                padding-right: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            ul.audit-logs {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
            .print-container {
                padding: 15px;
                margin-top: 15px;
            }
            .close-button {
                opacity: 0.6;
            }
            .detail-header {
                height: 30px;
                padding: 10px 17px 20px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .detail-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .detail-content-column {
                flex: 1;
                padding: 10px;
                min-height: 150px;
            }
            .detail-content-column:not(:last-child) {
                padding-right: 10px;
                border-right: 1px solid #dde7ef;
            }
            .detail-row-title {
                padding-top: 0px;
                margin-top: 0px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #333333;
            }
            .detail-row-value-na {
                color: #787878;
            }
            .detail-row-label,
            .audit-label {
                font-size: 13px;
                color: #787878;
                margin-left: 15px;
                margin-right: 5px;
                flex: 0;
                white-space: nowrap;
                min-width: 140px;
            }
            .detail-row-label.align-center {
                display: flex;
                align-items: center;
            }
            .rules-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .rule-column {
                margin: 20px 20px 0px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            .rule-column .rule-set {
                margin-bottom: 15px;
            }
            .rule-column .detail-row-label {
                display: block;
                margin: 0px;
            }
            .detail-row-value {
                flex: 1;
                font-size: 14px;
            }
            .detail {
                text-align: right;
            }
            .detail-row-buttons {
                align-self: flex-end;
            }
            #progress-wrapper {
                text-align: center;
                margin-top: 25px;
            }
            .audit-column {
                /* Per conversation with Cody, it's better to create an .audit-column class instead of applying
                   the coincidental :last-child to .rule-column. Displayed audit logs may move in the future.
                 */
                margin: 12px 20px 0px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            .audit-label {
                display: block;
            }
            .audit-log {
                font-size: 11px;
                overflow-x: wrap;
                overflow-y: auto;
                width: 269px;
                height: 90px;
                padding: 5px;
                border: 1px solid #dfdfdf;
                background-color: #fcfcfc;
            }
        `;
    }

    static printStyle() {
        return css`
            .detail-row-container {
                font-family: 'Roboto', Helvetica, Arial, sans-serif;
                color: #3e3f42;
            }
            .detail-row-header {
                margin-bottom: 20px;
            }
            .detail-row-title {
                display: block;
                font-size: 12px;
                font-weight: 500;
            }
            .header-group {
                display: block;
                line-height: 9px;
            }
            .header-group .detail-row-label {
                font-size: 9px;
                font-weight: 400;
                white-space: nowrap;
            }
            .header-group .detail-row-value {
                font-size: 11px;
                font-weight: 500;
            }
            .rules-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .rule-column {
                font-size: 11px;
                font-weight: 500;
                justify-content: flex-start;
            }
            .rule-column label {
                font-size: 9px;
                font-weight: 400;
            }
            .rule-set {
                display: block;
                margin-bottom: 10px;
            }
            .rule-set .detail-row-label {
                display: block;
            }
            omega-tooltip,
            .action-buttons {
                display: none;
            }
            .audit-column {
                font-size: 9px;
                font-weight: 400;
            }
            .audit-label {
                display: block;
                font-size: 11px;
                font-weight: 500;
            }
            .audit-log {
                border: none;
                background-color: inherit;
                font-family: 9px;
                font-weight: 400;
            }
            ul.audit-logs {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
        `;
    }
}

export default ACHFilterDetailRowStyles;
