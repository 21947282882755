import corePagingParameters from '../../../shared/utilities/core-paging-parameters.js';
import { DateFormatters } from '../../../shared/utilities/date-formatters.js';
import { outputTypeId } from '../../../shared/utilities/download-output-type.ts';
import { downloadBlob } from '../../../utilities/file-handling.js';
import {
    mapNoticeOfChangeDetailResponse,
    mapNoticeOfChangeResponse,
    mapNoticeOfChangeSearchFilter,
} from '../../mappings/ach/ach-notice-of-change.js';
import AchCompanyRequests from '../../requests/ach/ach-company-requests.js';
import AchNoticeOfChangeRequests from '../../requests/ach/ach-notice-of-change-requests.js';

export default class AchNoticeOfChangeServices {
    static async getCompanies() {
        return AchCompanyRequests.getAchCompanies();
    }

    static async search({ sort, startIndex, pageSize, parameters }) {
        const body = {
            CompanyIds: parameters.achCompanyIds,
            ReceivedDate: {
                StartDate:
                    DateFormatters.parseDate(parameters.receivedDate).value.start ??
                    DateFormatters.parseDate(parameters.receivedDate).value,
                EndDate:
                    DateFormatters.parseDate(parameters.receivedDate).value.end ??
                    DateFormatters.parseDate(parameters.receivedDate).value,
            },
            pagingParameters: corePagingParameters('ReceivedDate', sort, startIndex, pageSize),
        };
        const results = await AchNoticeOfChangeRequests.search(body);
        const mappedResults = mapNoticeOfChangeResponse(results.items);
        return { data: mappedResults, totalCount: results.totalCount };
    }

    static async searchV2({ parameters }) {
        const body = mapNoticeOfChangeSearchFilter(parameters);
        const results = await AchNoticeOfChangeRequests.searchV2(body);
        const mappedResults = results.notificationOfChanges
            ? results.notificationOfChanges.map(noc => mapNoticeOfChangeDetailResponse(noc))
            : [];
        return { data: mappedResults, totalCount: results.totalCount };
    }

    static async getDetails(parentRecord) {
        const data = await AchNoticeOfChangeRequests.getDetails(
            parentRecord.getField('achNotificationOfChangeUniqueId')
        );
        data.achNotificationOfChangeUniqueId = parentRecord.getField(
            'achNotificationOfChangeUniqueId'
        );
        data.achCompanyName = parentRecord.getField('achCompanyName');
        data.receivedDate = parentRecord.getField('receivedDate');
        data.effectiveDate = parentRecord.getField('effectiveDate');
        data.entryDescription = parentRecord.getField('entryDescription');
        data.fullReturnReason = parentRecord.getField('fullReturnReason');
        return {
            ...data,
            companyEntryDescription: data.companyEntryDescription,
            originalAccountNumber: data.accountNumber.original,
            updatedAccountNumber: data.accountNumber?.updated ?? null,
            originalAccountType: data.accountType.original,
            updatedAccountType: data.accountType?.updated ?? null,
            originalIdentificationNumber: data.identificationNumber.original,
            updatedIdentificationNumber: data.identificationNumber?.updated ?? null,
            originalRecipientName: data.recipientName.original,
            updatedRecipientName: data.recipientName?.updated ?? null,
            originalRoutingNumber: data.routingNumber.original,
            updatedRoutingNumber: data.routingNumber?.updated ?? null,
            originalSecCode: data.secCode.original,
            updatedSecCode: data.secCode?.updated ?? null,
            traceNumber: data.traceNumber.original,
            updatedTraceNumber: data.traceNumber?.updated ?? null,
            dateOfDeath: data.dateOfDeath?.original ?? null,
        };
    }

    static async downloadNotice({ guid, type, notificationReceivedDate, changeCodeDescription }) {
        const response = await AchNoticeOfChangeRequests.downloadNotice(
            guid,
            type,
            notificationReceivedDate,
            changeCodeDescription
        );
        const blob = new Blob([response], { type });
        const fileExtension = type.toLowerCase() === 'nacha' ? 'txt' : type.toLowerCase();
        const filename = `ACH Notification of Change-${guid}.${fileExtension}`;
        return downloadBlob(blob, filename);
    }

    static async downloadNoticeV2({ guid, type }) {
        const { typeId: outputType } = outputTypeId(type);
        const body = {
            OutputType: outputType,
        };
        const response = await AchNoticeOfChangeRequests.downloadNoticeV2(guid, body);
        const filename = response.headers.get('x-filename');
        const contentType = response.headers.get('content-type');
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: contentType });
        return downloadBlob(blob, filename);
    }

    static async downloadNoticeReport({ filter, type }, achCompanyTotal) {
        const informationTypes = {
            summary: 'Summary',
            detail: 'Detail',
            nacha: 'nacha',
        };
        const outputTypes = {
            pdf: 'pdf',
            csv: 'csv',
            nacha: 'nacha',
        };

        const filterModel = mapNoticeOfChangeSearchFilter(filter, achCompanyTotal);
        const informationType = informationTypes[type.split(' ').pop().toLowerCase()];
        const { typeId: outputType } = outputTypeId(outputTypes[type.split(' ')[0].toLowerCase()]);

        const body = {
            InformationType: informationType,
            OutPutType: outputType,
            filter: filterModel,
        };

        const response = await AchNoticeOfChangeRequests.downloadNoticeReport(body);
        const filename = response.headers.get('x-filename');
        const contentType = response.headers.get('content-type');
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: contentType });
        return downloadBlob(blob, filename);
    }
}
