/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ReportFilterModelOfWireFileFilterModelDto, WireFileFilterModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class WireFileUploadReportClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags WireFileUploadReport
     * @name WireFileUploadReportWireFileUploadReport
     * @summary Returns the wire file upload report results based on the parameters of the filter
     * @request POST:/WireFileUploadReport
     */
    wireFileUploadReportWireFileUploadReport = (
        wireFileFilter: WireFileFilterModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/WireFileUploadReport`,
            method: 'POST',
            body: wireFileFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireFileUploadReport
     * @name WireFileUploadReportSaveWireFileUploadReport
     * @summary Saves the WireFileFilterModel as a custom report
     * @request PUT:/WireFileUploadReport
     */
    wireFileUploadReportSaveWireFileUploadReport = (
        wireFileFilter: ReportFilterModelOfWireFileFilterModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/WireFileUploadReport`,
            method: 'PUT',
            body: wireFileFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireFileUploadReport
     * @name WireFileUploadReportGetWireFileUploadReport
     * @summary Retrieves a saved WireFileFilterModel
     * @request GET:/WireFileUploadReport
     */
    wireFileUploadReportGetWireFileUploadReport = (
        query: {
            /**
             * The id of the report to retrieve
             *
             * @format int32
             */
            reportId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/WireFileUploadReport`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireFileUploadReport
     * @name WireFileUploadReportDownloadWireFileUploadReport
     * @summary Download the results of the wire file upload report in a supported format
     * @request POST:/WireFileUploadReport/Download
     */
    wireFileUploadReportDownloadWireFileUploadReport = (
        wireFileFilter: WireFileFilterModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/WireFileUploadReport/Download`,
            method: 'POST',
            body: wireFileFilter,
            format: 'json',
            ...params,
        });
}
