import { AchFilterServices } from '@treasury/domain/channel/services/ach/ach-filter-services.js';

export const achFilterColumns = async accountSettings => {
    const accounts = await AchFilterServices.getAccountsAll(accountSettings);
    const hasPermission = (permission, record) => {
        const account = accounts.find(
            currentAccount => currentAccount.accountUniqueId === record.getField('accountUniqueId')
        );
        return account?.claims.includes(permission);
    };

    return [
        // Sounds like this is a column we'll need when Delete, Approve and Reject
        // functionality is added
        // {
        //     type: 'checkbox',
        //     field: 'isSelected',
        //     label: '',
        //     action: 'selectRecordForReview',
        // },
        {
            field: 'filterRuleName',
            label: 'Rule Name',
        },
        {
            field: 'accountNumber',
            label: 'Account',
        },
        {
            field: 'achCompanyName',
            label: 'ACH Company Name',
        },
        {
            field: 'achCompanyId',
            label: 'Company ID',
        },
        {
            field: 'secCode',
            label: 'SEC',
        },
        {
            field: 'toAmount',
            label: 'Amount',
        },
        {
            field: 'transactionType',
            label: 'Transaction Type',
        },
        {
            field: 'status',
            label: 'Status',
        },
        {
            type: 'actions',
            label: 'Actions',
            actions: [
                {
                    label: 'Edit',
                    action: 'Edit',
                    visibleWhen: record => hasPermission('EditAchFilter', record),
                },
                {
                    label: 'Clone Criteria',
                    action: 'Clone Criteria',
                    visibleWhen: record => hasPermission('CreateAchFilter', record),
                },
                {
                    label: 'Delete',
                    action: 'Delete',
                    visibleWhen: record => hasPermission('DeleteAchFilter', record),
                },
                // { label: 'Reject', action: 'Reject', visibleWhen: () => true },
            ],
        },
    ];
};
