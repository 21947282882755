export abstract class StorageProvider {
    // eslint-disable-next-line no-useless-constructor
    constructor(private storage: Storage) {}

    public set<T>(key: string, value: T) {
        const serialized = JSON.stringify(value);
        this.storage.setItem(key, serialized);
    }

    public get<T>(key: string) {
        const serialized = this.storage.getItem(key);
        if (!serialized) {
            return undefined;
        }

        try {
            return JSON.parse(serialized) as T;
        } catch {
            return serialized as T;
        }
    }

    public has(key: string) {
        return !!this.storage.getItem(key);
    }

    public clear() {
        this.storage.clear();
    }

    public remove(key: string) {
        this.storage.removeItem(key);
    }
}
