import { DiContainer } from '@jack-henry/frontend-utils/di';
import { AccountService } from '@treasury/domain/channel/services';
import openDialog from '@treasury/omega/open-dialog.js';
import { css, html, LitElement, nothing } from 'lit';
import PhoneNumber from '../data/phone-number.js';
import './oob-registration-dialog.js';

export default class OobRegistration extends LitElement {
    static get properties() {
        return {
            client: Object,
            verificationMethods: Object,
            isUisEnabled: Boolean,
        };
    }

    constructor() {
        super();
        this.verificationMethods = {
            Text: {
                id: 'Text',
                isVerified: false,
                label: 'Text Message',
                singular: 'a text message (SMS)',
                plural: 'text messages',
                aMessageWasSentToYourDevice: 'A text message (SMS) was sent to your phone.',
                retryLabel: 'Retry Text Message',
                oneTimePasswordLength: 8,
            },

            Call: {
                id: 'Call',
                isVerified: false,
                label: 'Automated Phone Call',
                singular: 'an automated phone call',
                plural: 'automated phone calls',
                aMessageWasSentToYourDevice: 'A phone call was made to the number provided below.',
                retryLabel: 'Call Again',
                oneTimePasswordLength: 6,
            },
        };

        this.verificationMethods.Text.other = this.verificationMethods.Call;
        this.verificationMethods.Call.other = this.verificationMethods.Text;
    }

    get textMessage() {
        return this.verificationMethods.Text;
    }

    get phoneCall() {
        return this.verificationMethods.Call;
    }

    async firstUpdated() {
        const di = await DiContainer.getInstance();
        await this.loadConfig(di);
    }

    async loadConfig(di) {
        const accountService = di.get(AccountService);
        const config = await accountService.getOutOfBandConfig();

        this.verificationMethods.Text.phoneNumber = new PhoneNumber(
            config.textVerificationMethod?.phoneNumber
        );
        this.verificationMethods.Call.phoneNumber = new PhoneNumber(
            config.callVerificationMethod?.phoneNumber
        );
        this.requestUpdate();
    }

    async openRegistrationDialog(verificationMethod) {
        const result = await openDialog(document.createElement('oob-registration-dialog'), {
            verificationMethod,
            client: this.client,
            phoneNumber: verificationMethod.phoneNumber.copy(),
        });
        this.requestUpdate();
        if (result === 'request-other-method') {
            this.openRegistrationDialog(verificationMethod.other);
        }
    }

    remindMeLater() {
        this.client.oobRemindMeLater().then(() => this.done());
    }

    async removeVerificationMethod(verificationMethod) {
        /* eslint-disable no-param-reassign */
        await this.client.removeVerificationMethod(verificationMethod.id);

        verificationMethod.phoneNumber = new PhoneNumber();
        verificationMethod.isVerified = false;

        this.requestUpdate();
    }

    done() {
        this.dispatchEvent(new CustomEvent('done'));
    }

    renderAddNumberButton(verificationMethod) {
        if (!verificationMethod.phoneNumber) return nothing;
        if (verificationMethod.phoneNumber.isNotBlank)
            return html` <p>Phone number: ${verificationMethod.phoneNumber}</p>
                <div class="card-footer">
                    <omega-button
                        type="link"
                        @click=${() => this.openRegistrationDialog(verificationMethod)}
                        >Edit Phone Number</omega-button
                    >
                    <omega-button
                        type="link"
                        @click=${() => this.removeVerificationMethod(verificationMethod)}
                        >Remove</omega-button
                    >
                </div>`;
        return html`<div class="card-footer">
            <omega-button
                type="primary"
                @click=${() => this.openRegistrationDialog(verificationMethod)}
                >Add Phone Number</omega-button
            >
        </div>`;
    }

    renderDoneButton() {
        if (!(this.textMessage.toString() || this.phoneCall.toString())) return nothing;
        return html`<omega-button @click=${() => this.done()}>Done</omega-button>`;
    }

    renderRemindMeLaterButton() {
        if (this.textMessage.toString() || this.phoneCall.toString()) return nothing;
        return html`<omega-button type="link" @click=${() => this.remindMeLater()}
            >Remind Me Later</omega-button
        >`;
    }

    render() {
        return html`
            <div class="content">
                <p class="heading-text">
                    ${this.isUisEnabled
                        ? html`<span
                              >For authentication purposes when working with transactions, please
                              provide phone numbers to receive text messages (SMS) and automated
                              phone calls. You may be prompted to verify your identity by responding
                              to a text message or automated phone call when working with
                              transactions within the Treasury Management platform.
                          </span>`
                        : html`<span
                              >For additional authentication purposes, please provide phone numbers
                              to receive text messages (SMS) and automated phone calls. You may be
                              prompted to verify your identity by responding to a text message or
                              automated phone call at login or when initiating transactions.</span
                          >`}
                </p>
                <div class="card">
                    <h4>Text Message (SMS)</h4>
                    <p>Get a prompt via text message and reply to verify your identity.</p>

                    ${this.renderAddNumberButton(this.textMessage)}
                </div>
                <div class="card">
                    <h4>Automated Phone Call</h4>
                    <p>
                        Receive a prompt via automated phone call and reply to verify your identity.
                    </p>
                    ${this.renderAddNumberButton(this.phoneCall)}
                </div>
                <p>
                    You can only enter this information one time. You must contact your financial
                    institution to change your security phone numbers.
                </p>
            </div>

            <footer>${this.renderDoneButton()} ${this.renderRemindMeLaterButton()}</footer>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .content {
                padding: 15px;
            }
            footer {
                padding: 7px 15px;
                border-top: 1px solid #ccc;
                text-align: right;
            }
            .card {
                border: 1px solid #ccc;
                margin-bottom: 30px;
            }
            .card h4 {
                background: #eee;
                border-bottom: 1px solid #ccc;
                margin: 0;
                padding: 7px 15px;
                font-size: 16px;
            }
            .heading-text {
                margin: 0 0 30px;
            }
            .card p {
                margin: 15px;
            }
            .card-footer {
                padding: 7px 15px;
                border-top: 1px solid #ccc;
            }
            omega-button {
                margin: 0;
            }
        `;
    }
}

window.customElements.define('oob-registration', OobRegistration);
