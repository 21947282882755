import { LitElement, css, html } from 'lit';

export const OmegaSkeletonLoaderTagName = 'omega-skeleton-loader';

export class OmegaSkeletonLoader extends LitElement {
    render() {
        return html`<div class="loading" role="alert"></div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .loading {
                height: 14px;
                margin-top: 10px;
                width: 70px;
                border-radius: 4px;
                background: #f6f7f8e4;
                background-image: linear-gradient(
                    to right,
                    #f6f7f8f0 0%,
                    #edeef1e8 20%,
                    #f6f7f8d5 40%,
                    #f6f7f8 100%
                );
                background-repeat: no-repeat;
                background-size: 800px 100px;
                display: inline-block;
                position: relative;
                animation: loadingShimmer 1s forwards infinite linear;
            }

            @keyframes loadingShimmer {
                0% {
                    background-position: -450px 0;
                }

                100% {
                    background-position: 450px 0;
                }
            }
        `;
    }
}

// @ts-ignore
window.customElements.define(OmegaSkeletonLoaderTagName, OmegaSkeletonLoader);
export default OmegaSkeletonLoader;
