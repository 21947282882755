/* eslint-disable @treasury/no-date */
import { Injectable } from '@jack-henry/frontend-utils/di';
import { Record, Recordset } from '@treasury/FDL';
import { WindowService, createUniqueId, exists } from '@treasury/utils';
import { OmegaReportFilter } from '../../types';

const DEFAULT_CACHE_SIZE = 10;

export interface StoreItem<T> {
    recordset: Recordset<T, any>;
    filterRecord?: Record<T>;
    filters?: OmegaReportFilter<T>[];
    timestamp: Date;
}

interface StoreConfig {
    /**
     * The maximum number of items that can exist in the cache.
     */
    cacheSize: number;
    /**
     * Amount of time (in milliseconds) to keep cached report state.
     */
    duration: number | null;
}

const defaultConfig: StoreConfig = {
    cacheSize: DEFAULT_CACHE_SIZE,
    duration: null,
};

/**
 * Provides a cache for persisting results and filters across
 * `<omega-report></omega-report>` navigations.
 */
@Injectable()
export class OmegaReportCache {
    // eslint-disable-next-line no-useless-constructor
    constructor(private window: WindowService) {}

    private cache = new Map<string, StoreItem<any>>();

    private duration: number | null = null;

    private maxSize = DEFAULT_CACHE_SIZE;

    private get Date() {
        return this.window.Date;
    }

    public get size() {
        return this.cache.size;
    }

    public configure(config: Partial<StoreConfig>) {
        const { cacheSize, duration } = {
            ...defaultConfig,
            ...config,
        };

        this.duration = duration;
        this.maxSize = cacheSize;
        this.cache.clear();
    }

    public store<T, P>(
        recordset: Recordset<T, P>,
        filterRecord?: Record<T>,
        filters?: OmegaReportFilter<T>[]
    ) {
        this.pruneExpired();
        while (this.cache.size >= this.maxSize) {
            this.evictOldest();
        }

        const uuid = createUniqueId();
        this.cache.set(uuid, {
            recordset,
            filterRecord,
            filters,
            timestamp: new this.Date(),
        });

        return uuid;
    }

    public retrieve(storeId: string) {
        this.pruneExpired();
        const item = this.cache.get(storeId);
        if (!item) {
            return undefined;
        }

        const { recordset, filterRecord, filters } = item;
        return {
            recordset,
            filterRecord,
            filters,
        };
    }

    private pruneExpired() {
        const { duration } = this;
        if (!exists(duration)) {
            return;
        }

        const now = this.Date.now();
        this.cache.forEach((item, key) => {
            const { timestamp } = item;
            const lifetime = now - timestamp.getTime();

            if (lifetime >= duration) {
                this.cache.delete(key);
            }
        });
    }

    /**
     * Evicts the oldest item in the cache.
     */
    private evictOldest() {
        // insert order is from least to most recent; use the first key
        const keys = Array.from(this.cache.keys());
        const oldestKey = keys[0];

        this.cache.delete(oldestKey);
    }
}
