export const reportColumns = [
    {
        field: 'effectiveDate',
        label: 'Original Effective Date',
    },
    {
        field: 'receivedDate',
        label: 'Date Return Received',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company',
    },
    {
        field: 'recipientName',
        label: 'Recipient Name',
    },
    {
        field: 'returnReason',
        label: 'Return Reason',
    },
];
