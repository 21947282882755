RejectDliController.$inject = ['$scope', '$modalInstance'];

export default function RejectDliController($scope, $modalInstance) {
    $scope.cancel = cancel;
    $scope.setForm = setForm;

    // this will dismiss the window.
    function cancel() {
        $modalInstance.close();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }
}
