/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Recordset } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import '@treasury/omega/layouts/omega-report';
import openDialog from '@treasury/omega/open-dialog.js';
import { LitElement, css, html, nothing } from 'lit';
import '../../components/ach-template-action-dialog.js';
import AchTemplatesClient from '../clients/ach-templates-client.js';
import { columns, fields, filters } from '../data/ach-templates-data.js';

class AchTemplatesContainer extends LitElement {
    static get properties() {
        return {
            institution: String,
            recordset: Object,
            loading: Boolean,
            client: Object,
        };
    }

    async firstUpdated() {
        const navService = await getNavService();
        if (!this.client) {
            this.client = new AchTemplatesClient();
        }

        this.columns = columns;
        this.filters = filters(this.client);
        this.fields = fields;
        this.recordset = new Recordset(this.fields, this.client.fetchAchTemplates);
        this.itemLabel = { singular: 'template' };
        this.forceFetch = async () => this.shadowRoot.querySelector('omega-report').forceFetch();
        this.deleteAction = async record => {
            try {
                const { id, name } = record.values;
                const result = await this.client.deleteTemplateById(id);
                if (result.securityMessage.status === 'Success') {
                    this.result = {
                        type: 'success',
                        message: html`ACH Tax Template <b>${name}</b> has been deleted.`,
                    };
                    this.forceFetch();
                }
            } catch (error) {
                this.result = {
                    type: 'error',
                    message: error instanceof Error ? error.message : 'An error occurred.',
                };
            }
            return this.result;
        };

        this.actions = {
            clickTemplateName: record => {
                navService.navigate('payables.ach.batch-detail', { id: record.getField('id') });
            },
            delete: record => {
                const deleteDialog = document.createElement('ach-template-action-dialog');
                openDialog(deleteDialog, {
                    dialogTitle: 'Delete this template?',
                    record,
                    actionTaken: 'delete',
                    templateName: record.getField('name'),
                    dialogAction: () => this.deleteAction(record),
                });
            },
            clone: record => {
                navService.navigate('payables.ach.batch.createClonedBatch', {
                    cloneId: record.getField('id'),
                });
            },
            initiate: async record => {
                const paymentList = await this.client.initiatePaymentsByIds([record.values]);
                navService.navigate('payables.ach.payments.createFromBatch', { paymentList });
            },
        };
        this.title = 'ACH Templates';
        this.reportInformation = 'ACH templates are...';
        this.reportLinks = [
            {
                title: 'ACH Tax Templates',
                url: `${window.location.origin}/${this.institution}/ach/tax-templates`,
            },
        ];
        this.reportActions = [
            {
                type: 'primary',
                label: 'Initiate Selected Templates',
                action: async () => {
                    const selectedRecords = this.recordset
                        .recordsMatching('selected', true)
                        .map(record => record.values);
                    const paymentList = await this.client.initiatePaymentsByIds(selectedRecords);
                    navService.navigate('payables.ach.payments.createFromBatch', { paymentList });
                },
                isDisabled: () => {
                    for (let i = 0; i < this.recordset.allRecords.length; i++) {
                        if (this.recordset.allRecords[i].values.selected) {
                            return false;
                        }
                    }
                    return true;
                },
            },
        ];
        this.downloadOptions = ['PDF'];
        this.callToActionText = 'Create New Template';
        this.callToAction = () => navService.navigate('payables.ach.batch.create');
        this.options = ['download', 'print'];
        this.rowsPerPage = 25;
    }

    async downloadAchTemplates({ detail }) {
        const achCompanyList = await this.client.fetchAchCompanies();
        const filterModel = {
            ...detail.filter,
            achCompanyList,
        };

        return this.client.downloadAchTemplates(detail.type, filterModel);
    }

    render() {
        if (!this.recordset) return nothing;

        return html`
            <omega-report
                flyout
                autostart
                .title=${this.title}
                .actions=${this.actions}
                .reportInformation=${this.reportInformation}
                .itemLabel=${this.itemLabel}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recordset}
                .columns=${this.columns}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .reportActions=${this.reportActions}
                .downloadOptions=${this.downloadOptions}
                .downloadFunction=${this.downloadAchTemplates}
                .options=${this.options}
                .callToAction=${this.callToActionText}
                .rowsPerPage=${this.rowsPerPage}
                @callToAction=${this.callToAction}
                @reportDownload=${this.downloadAchTemplates}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    .itemLabel=${this.itemLabel}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                ></omega-filter-bar>
            </omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
        `;
    }
}

customElements.define('ach-templates-container', AchTemplatesContainer);

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
