import ActionMenuController from './actionMenu/actionMenuDir.js';
import AlertController from './alert/alertDir.js';
import TmAmountSearchController from './amountSearch/amountSearchDir.js';
import AttributeListController from './attributeList/attributeListDir.js';
import CheckImageDirective from './checkImage/checkImageDir.js';
import CheckImageController from './checkImage/checkImageModal/checkImageCtrl.js';
import TmDateSearchController from './dateSearch/dateSearchDir.js';
import DifferenceFlagController from './differenceFlag/differenceFlagDir.js';
import EmptyStateDirective from './emptyState/emptyStateDir.js';
import ExportOptionBarDirective from './exportOptionBar/exportOptionBarDir.js';
import FixedHeaderDirective from './fixedHeader/fixedHeaderDir.js';
import FlexSpinnerDirective from './flexSpinner/flexSpinner.js';
import FocusIf from './focusIf/focusIfDir.js';
import NonBlockingSpinner from './nonBlockingSpinner/nonBlockingSpinner.js';
import Panel from './panel/panelDir.js';
import ReportFilter from './reportFilter/reportFilterDir.js';
import SaveReportController from './reportFilter/saveReport/saveReportCtrl.js';
import ResizerDirective from './resizer/resizerDir.js';
import SidebarContainer from './sidebarContainer/sidebarContainerDir.js';
import SortableTable from './sortableTable/sortableTableDir.js';
import SpinnerDirective from './spinner/spinnerDir.js';
import SuperUserPanelDir from './superUserPanel/superUserPanelDir.js';
import TimerComponent from './timer/timer.js';
import TimerService from './timer/timerService.js';
import Wizard from './wizard/wizardDir.js';

export const directivesComponentsModule = angular
    .module('channel.directives.components', ['channel.services', 'channel.services.common'])
    .directive('actionMenu', ActionMenuController)
    .directive('tmAlert', AlertController)
    .directive('tmAmountSearch', TmAmountSearchController)
    .directive('attributeList', AttributeListController)
    .directive('checkImage', CheckImageDirective)
    .controller('CheckImageController', CheckImageController)
    .directive('tmDateSearch', TmDateSearchController)
    .directive('differenceFlag', DifferenceFlagController)
    .directive('emptyState', EmptyStateDirective)
    .directive('exportOptionBar', ExportOptionBarDirective)
    .directive('fixedHeader', FixedHeaderDirective)
    .directive('flexSpinner', FlexSpinnerDirective)
    .directive('focusIf', FocusIf)
    .directive('nonBlockingSpinner', NonBlockingSpinner)
    .directive('panel', Panel)
    .controller('SaveReportController', SaveReportController)
    .directive('reportFilter', ReportFilter)
    .directive('resizer', ResizerDirective)
    .directive('sidebarContainer', SidebarContainer)
    .directive('sortableTable', SortableTable)
    .directive('spinner', SpinnerDirective)
    .directive('superUserPanel', SuperUserPanelDir)
    .component('omegaTimer', TimerComponent)
    .service('timerService', TimerService)
    .directive('wizard', Wizard);
