import { FiDate } from '@treasury/domain/dates';
import {
    validEverySixMonths,
    validEveryTwoWeeks,
    validMonthly,
    validQuarterly,
    validWeekly,
    validYearly,
} from '@treasury/policy/lib/validators/frequency.validator';
import { css, html, LitElement } from 'lit';
import { formStyles } from '../../form-styles.js';

export class StartAndEnd extends LitElement {
    static get properties() {
        return {
            frequency: Object,
            start: String,
            end: String,
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
            isStartDateInvalid: Function,
            isEndDateInvalid: Function,
        };
    }

    static get is() {
        return 'omega-frequency-start-and-end';
    }

    onNoEndDate() {
        const noEndDate = !this.frequency?.noEndDate;
        this.frequency = {
            ...this.frequency,
            noEndDate,
        };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    startChange(e) {
        this.frequency = { ...this.frequency, startOn: e.detail.value?.dates[0] };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    endChange(e) {
        if (this.frequency?.noEndDate) return;
        this.frequency = { ...this.frequency, endOn: e.detail.value?.dates[0] };
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    startAndEndInvalid(startDate, endDate) {
        let isInvalid = false;
        if (this.frequency.type === 'Weekly') {
            isInvalid = !validWeekly(startDate, endDate);
        } else if (this.frequency.type === 'EveryTwoWeeks') {
            isInvalid = !validEveryTwoWeeks(startDate, endDate);
        } else if (this.frequency.type === 'TwiceAMonth') {
            isInvalid = !validMonthly(startDate, endDate);
        } else if (this.frequency.type === 'Monthly') {
            isInvalid = !validMonthly(startDate, endDate);
        } else if (this.frequency.type === 'Quarterly') {
            isInvalid = !validQuarterly(startDate, endDate);
        } else if (this.frequency.type === 'EverySixMonths') {
            isInvalid = !validEverySixMonths(startDate, endDate);
        } else if (this.frequency.type === 'Yearly') {
            isInvalid = !validYearly(startDate, endDate);
        }
        return isInvalid;
    }

    checkEndDate(dateModel) {
        const startDate = new Date(this.frequency.startOn);
        const endDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
        const isInvalid = this.startAndEndInvalid(startDate, endDate);
        const result = this.isEndDateInvalid && this.isEndDateInvalid(dateModel);
        return result || isInvalid;
    }

    get startValid() {
        return !!this.frequency?.startOn;
    }

    get endValid() {
        let invalidStartAndEnd = false;
        if (this.frequency) {
            const startDate = new Date(this.frequency.startOn);
            const endDate = new Date(this.frequency.endOn);
            invalidStartAndEnd = this.startAndEndInvalid(startDate, endDate);
        }
        return this.frequency?.noEndDate || (!!this.frequency?.endOn && !invalidStartAndEnd);
    }

    renderReadOnly() {
        const parseDate = date => (date ? new FiDate(date) : '(no date selected)');
        return html`<div class="control italic">
            Starts on ${parseDate(this.frequency?.startOn)}
            ${this.frequency?.noEndDate
                ? ' with no end date'
                : `and ends on ${parseDate(this.frequency?.endOn)}`}
        </div>`;
    }

    render() {
        if (this.readOnly) return this.renderReadOnly();
        const endDateDisabledFunction = this.checkEndDate.bind(this);
        return html`
            <div class="row">
                <label
                    >Start On:
                    ${!this.readOnly ? html`<span class="required"> *</span>` : null}</label
                >
                <omega-datepicker
                    class="control startOn"
                    @change=${this.startChange}
                    .value=${this.frequency?.startOn}
                    .dateDisabledFunction=${this.isStartDateInvalid}
                    ?disabled=${this.disabled}
                    .valid=${this.startValid}
                ></omega-datepicker>
            </div>
            <div class="row">
                <label
                    >End On:
                    ${!this.readOnly ? html`<span class="required"> *</span>` : null}</label
                >
                <omega-datepicker
                    class="control endOn"
                    @change=${this.endChange}
                    .value=${!this.frequency?.noEndDate ? this.frequency?.endOn : null}
                    .dateDisabledFunction=${endDateDisabledFunction}
                    .disabled=${this.frequency?.noEndDate ||
                    !this.frequency?.startOn ||
                    this.disabled}
                    .valid=${this.endValid}
                ></omega-datepicker>
            </div>
            <div class="row right last">
                <label>
                    <input
                        class="noEndDate"
                        type="checkbox"
                        @change=${this.onNoEndDate}
                        ?checked=${this.frequency?.noEndDate}
                        ?disabled=${this.disabled}
                    />
                    No End Date
                </label>
            </div>
        `;
    }

    static get styles() {
        return [
            formStyles,
            css`
                :host {
                    display: block;
                }
            `,
        ];
    }
}
// eslint-disable-next-line @treasury/consistent-custom-element-name, wc/no-invalid-element-name
window.customElements.define(StartAndEnd.is, StartAndEnd);
export default StartAndEnd;
