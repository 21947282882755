import { OmegaDialog } from '../components/omega-dialog';

export class DialogUtility {
    constructor(public containerTag: string) {
        this.containerTag = containerTag;
        this.dialogId = `${containerTag}-instance`;
    }

    public dialogId: string;

    /**
     * Opens the dialog from a parent container
     * @param context Parent container context to open the dialog from
     */
    public openFromParentContainer(context: Element) {
        const container = context.shadowRoot?.querySelector(this.containerTag) as Element;
        const dialog = this.getInstance(container);
        dialog.open = true;
    }

    /**
     * Closes the dialog from within a wrapper component
     * @param context Parent component to open the dialog from
     */
    public close(context: Element) {
        const instance = this.getInstance(context);
        instance.open = false;
    }

    private getInstance(context: Element) {
        const dialog = context.shadowRoot?.querySelector(`#${this.dialogId}`) as OmegaDialog;
        if (!dialog) {
            throw new Error(`Could not find ${this.dialogId}`);
        }

        return dialog;
    }
}
