import { html, nothing } from 'lit';
import { isNullOrUndefined } from 'util';
import '../omega-field.js';
import Column from './column.js';

// usage: <omega-table-column field="accountNumber" label="AccountNumber"></omega-table-column>
export default class StandardColumn extends Column {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    constructor(...args) {
        super(...args);
        const [{ field, label, editable, summary, sortable }, recordset] = args;
        this.field = field;
        this.label = label;
        this.editable = editable || false;
        this.summary = summary || false;
        this.fieldType = recordset.getFieldType(field);
        this.sortable = this.fieldType.properties.sortable ?? sortable ?? true;
    }

    // eslint-disable-next-line class-methods-use-this
    isSortable() {
        return this.sortable;
    }

    cellClasses() {
        const editableClass = this.editable ? 'editable-cell' : '';
        return [...super.cellClasses(), ...this.fieldType.cellClasses(), editableClass];
    }

    renderThContents(cursor) {
        if (
            cursor.getFieldType(this.field).required(cursor.record) &&
            !cursor.getFieldType(this.field).readonly(cursor.record)
        ) {
            const showRequiredIndicator = !!cursor.showRequiredIndicator;
            const headerDecoration = showRequiredIndicator
                ? html`<span class="required-indicator">&nbsp;*</span>`
                : nothing;

            return html` <style>
                    .required-indicator {
                        color: var(--omega-error);
                    }
                </style>
                ${this.label}${headerDecoration}`;
        }
        return this.label;
    }

    renderTdContents(record) {
        return html` <omega-field
            .field=${this.field}
            .readOnly=${this.fieldType.readonly(record)}
            .record=${record}
            hideLabel
            truncateText
        ></omega-field>`;
    }

    renderSummaryContents(visibleRecords) {
        const columnValues = visibleRecords.map(record => record.getField(this.field));
        if (this.summary) {
            const sum = this.fieldType.aggregate(columnValues);
            return html`${this.fieldType.print(sum)}`;
        }
        return nothing;
    }
}
