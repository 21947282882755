RemoveWireController.$inject = ['$scope', '$modalInstance', 'type'];

export default function RemoveWireController($scope, $modalInstance, type) {
    // this will dismiss the window.
    $scope.type = type;
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // closing the modal rather than dismissing the modal
    // unblocks the UI and a service call is made after the fact
    // so the service call can be made after the fact in the parent controller
    $scope.remove = function () {
        $modalInstance.close($scope.comments);
    };
}
