import '@treasury/omega/components/omega-file-upload.js';
import '@treasury/omega/components/omega-select.js';
import { css, html, LitElement } from 'lit';
import { workflowStyles } from '../workflow.styles.js';

export class WireFileUpload extends LitElement {
    static get properties() {
        return {
            formatId: Number,
            companyId: Number,
            disableUpload: Boolean,
            fileFormats: Array,
            files: Array,
            uploading: { type: Boolean, reflect: true },
        };
    }

    _addFiles(e) {
        this.disableUpload = false;
        this.files = e.detail.files;
    }

    _uploadFiles() {
        this.dispatchEvent(
            new CustomEvent('upload', { bubbles: true, composed: true, detail: this.files })
        );
    }

    _formatChange(e) {
        this.formatId = e.target.value;
        this.dispatchEvent(
            new CustomEvent('formatChange', {
                bubbles: true,
                composed: true,
                detail: e.target.value,
            })
        );
    }

    _companyChange(e) {
        this.companyId = e.target.value;
        this.dispatchEvent(
            new CustomEvent('companyChange', {
                bubbles: true,
                composed: true,
                detail: e.target.value,
            })
        );
    }

    render() {
        const fileFormats = this.fileFormats
            ? this.fileFormats.map(ff => ({ text: `${ff.templateName}`, value: ff.id }))
            : null;
        const wireCompanies = this.wireCompanies
            ? this.wireCompanies.map(wc => ({ text: `${wc.name}`, value: wc.id }))
            : null;
        const isValid =
            !!this.formatId && !!this.files && this.files.length > 0 && !!this.companyId;

        return html` <div class="wire-company-select-wrapper">
                <label>Wire Company <span class="required">*</span></label>
                <omega-select
                    .items=${wireCompanies}
                    placeholder="Select a Wire Company"
                    @change=${this._companyChange}
                ></omega-select>
            </div>
            <div class="wire-company-select-wrapper">
                <label>Saved Format <span class="required">*</span></label>
                <omega-select
                    .items=${fileFormats}
                    placeholder="Select A File Format"
                    @change=${this._formatChange}
                ></omega-select>
            </div>
            <omega-file-upload
                .disabled=${!this.formatId || !this.companyId}
                @filesUploaded=${this._addFiles}
                .files=${this.files}
            ></omega-file-upload>
            <div class="workflow-button-bar-wrapper">
                <omega-button-bar alignment="left">
                    <omega-button
                        id="uploadFileButton"
                        type="primary"
                        ?disabled=${this.disableUpload || !isValid}
                        @click=${this._uploadFiles}
                        ?loading=${this.uploading}
                    >
                        Upload
                    </omega-button>
                </omega-button-bar>
            </div>`;
    }

    static get styles() {
        return [
            workflowStyles,
            css`
                :host {
                    /* this makes everything match production */
                    /* design is inconsistent with the 'style guide' they've given us */
                    display: block;
                    padding-left: 88px;
                    padding-top: 38px;
                }
                .wire-company-select-wrapper {
                    display: flex;
                    align-items: center;
                    max-width: 375px;
                    margin-bottom: 25px;
                }
                .wire-company-select-wrapper label {
                    width: 112px;
                    flex-shrink: 0;
                }
                omega-file-upload {
                    display: block;
                    margin-left: 112px;
                    width: 263px;
                }
                table.error-results {
                    font-size: 10px;
                }
                .required {
                    color: red;
                }
            `,
        ];
    }
}

window.customElements.define('wire-file-upload', WireFileUpload);
export default WireFileUpload;
