/* eslint-disable @treasury/style-includes-host-display */
import { css, html, LitElement, nothing } from 'lit';

import UserActivityRequests from '@treasury/domain/channel/requests/user-activity/user-activity';
import AchNoticeOfChangeServices from '@treasury/domain/channel/services/ach/ach-notice-of-change-services.js';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/layouts/omega-report';
import { mix } from 'mixwith';
import '../../components/blocking-loader.js';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';
import './ach-notice-of-change-detail-row.js';
import { reportColumns } from './data/ach-notice-of-change-columns.js';
import { reportFields } from './data/ach-notice-of-change-fields.js';
import { reportFilters } from './data/ach-notice-of-change-filters.js';

class ACHNoticeOfChangeContainer extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            institution: String,
            filterRecord: Array,
            reportFilters: Array,
            reportFields: Array,
            reportColumns: Array,
            reportActions: Array,
            defaultPerPage: Number,
            landingPageMessage: String,
            alert: Object,
            showBlockingLoader: Boolean,
            recordset: Object,
        };
    }

    constructor() {
        super();
        this.reportFilters = reportFilters;
        this.reportFields = reportFields;
        this.columns = reportColumns;
        this.defaultPerPage = 25;
        this.landingPageMessage = 'No Notification of Changes Found';
        this.showBlockingLoader = false;
        this.reportTitle = 'ACH Notification of Change Activity';
        this.reportInformation = html`<div style="width:335px">
            ACH Notification of Change Activity <br />
            Utilize the search feature to review notification of change activity.
        </div>`;
        this.recordset = new Recordset(this.reportFields, AchNoticeOfChangeServices.search);
    }

    get institution() {
        return this._institution;
    }

    set institution(value) {
        this._institution = value;
    }

    async _fullPagePrint() {
        UserActivityRequests.print('achNoticeOfChangeFullPage');
    }

    async _detailPrint(e) {
        UserActivityRequests.print('achNoticeOfChangeDetail', e.detail);
    }

    async _saveNotice({ detail }) {
        try {
            this.showBlockingLoader = true;
            await AchNoticeOfChangeServices.downloadNotice(detail);
        } catch (err) {
            this.setAlertFromError(err);
        } finally {
            this.showBlockingLoader = false;
        }
    }

    firstUpdated() {
        this.setReturnVersionPath();
    }

    async setReturnVersionPath() {
        const returnV2FeatureFlagEnabled = await FeatureFlagService.isEnabled(
            Feature.AchReturnReviewNacha
        );
        const returnVersionPath = returnV2FeatureFlagEnabled ? '' : '/v1';
        this.reportActions = [
            {
                url: `${this.institution}/ach/ach-return-activity${returnVersionPath}`,
                title: 'ACH Return Activity ',
            },
        ];
    }

    renderDetail(record, close) {
        return html`<ach-notice-of-change-detail-row
            .parentRecord=${record}
            .closeFunction=${close}
            @detailPrint=${this._detailPrint}
            @saveNotice=${e => this._saveNotice(e)}
        ></ach-notice-of-change-detail-row>`;
    }

    renderBlockingLoader() {
        if (this.showBlockingLoader) return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    render() {
        return html`
            ${this.renderAlert()} ${this.renderBlockingLoader()}
            <omega-report
                flyout
                .title=${this.reportTitle}
                .reportInformation=${this.reportInformation}
                .recordset=${this.recordset}
                .reportLinks=${this.reportActions}
                displayToggleAll
                .options=${['print']}
                .filters=${this.reportFilters}
                .columns=${this.columns}
                .detailFunction=${(record, close) => this.renderDetail(record, close)}
                @pagePrint=${this._fullPagePrint}
                @detailPrint=${this._detailPrint}
                .rowsPerPage=${this.defaultPerPage}
                .landingPageMessage=${this.landingPageMessage}
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
            }
            @media print {
                .ach-notice-of-change-container {
                    box-shadow: none;
                    border: 1px solid #ccc;
                }
            }
        `;
    }
}

export default ACHNoticeOfChangeContainer;
window.customElements.define('ach-notice-of-change-container', ACHNoticeOfChangeContainer);
