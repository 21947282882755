ResetPassWordController.$inject = [
    '$scope',
    '$modalInstance',
    '$modal',
    'userDetails',
    'toaster',
    'usersService',
];

export default function ResetPassWordController(
    $scope,
    $modalInstance,
    $modal,
    userDetails,
    toaster,
    usersService
) {
    // variables
    $scope.resetPassword = {
        emailPasswordLink: true,
    };

    $scope.helpText = '';
    $scope.buttonText = '';
    $scope.selectedUser = userDetails;
    $scope.sendPassword = sendPassword;

    // functions
    $scope.onMethodChanged = onMethodChanged;
    $scope.cancel = cancel;

    // watch
    $scope.$watch('form.$dirty', isDirty => {
        $modalInstance.isDirty = isDirty;
    });

    function cancel() {
        $modalInstance.dismiss();
    }

    function sendPassword() {
        if (!$scope.resetPassword.emailPasswordLink) {
            usersService
                .manualResetPassword($scope.selectedUser.id, $scope.resetPassword)
                .then(response => {
                    toaster.save('User Temporary Password');
                    $modalInstance.close();
                });
        } else {
            usersService.resetPassword($scope.selectedUser.id).then(response => {
                toaster.save('User Password Reset');
                $modalInstance.close();
            });
        }
    }

    function init() {
        onMethodChanged();
    }

    function onMethodChanged() {
        if ($scope.resetPassword.emailPasswordLink) {
            $scope.buttonText = 'Email Password Link';
            $scope.helpText = `An email will be sent to ${$scope.selectedUser.userName} with a link to reset their password.`;
            $scope.resetPassword.tempPassword = ''; // clear if they switch back to auto generated link
        } else {
            $scope.buttonText = 'Save Password';
            $scope.helpText =
                'The user will not receive an automated message. You must provide the temporary password to the user. The user will be prompted to change their password after successfully logging in.';
        }
    }

    init();
}
