import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { SummaryTileVm } from '../types';

@customElement('omega-summary-tile')
export class OmegaSummaryTile extends LitElement {
    @property({ type: Object })
    summaryTile!: SummaryTileVm;

    @property()
    isSelected = false;

    currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    handleTileClick() {
        this.dispatchEvent(
            new CustomEvent<SummaryTileVm>('tileClicked', { detail: this.summaryTile })
        );
        this.requestUpdate();
    }

    render() {
        return html`
            <button
                class=${classMap({
                    'tile-selected': this.isSelected,
                    'omega-summary-tile': true,
                })}
                id=${this.id}
                @click=${this.handleTileClick}
                @keypress=${this.handleTileClick}
                .disabled=${this.summaryTile.count === 0}
            >
                <span class="title">${this.summaryTile.title}</span>
                <span class="count">${this.summaryTile.count}</span>
                <span class="amount">
                    ${this.currencyFormatter.format(this.summaryTile.totalAmount)}
                </span>
            </button>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: inline-block;
            }

            .title {
                display: block;
                text-transform: uppercase;
                font-size: 12px;
                user-select: none; // Prevent a blinking cursor if a user clicks the tile text.
            }
            .count {
                display: block;
                font-size: 20px;
                user-select: none;
            }
            .amount {
                display: block;
                font-size: 12px;
                user-select: none;
            }

            .omega-summary-tile {
                cursor: pointer;
                overflow: hidden;
                font-family: 'Roboto';
                width: 126px;
                padding: 8px 16px;
                margin: 8px 12px;
                color: #494949;
                background-color: #f9f9f9;
                border: 1px solid #e8e8e8;
                border-radius: 8px;
                text-align: center;
            }

            /* Remove the default button styles set by the browser agent */
            button {
                all: unset;
            }

            .omega-summary-tile:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }

            .tile-selected {
                color: #fff;
                background-color: #0065cf;
                border: 1px solid #0065cf;
            }
        `;
    }
}
