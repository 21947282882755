import { Nullable } from '@treasury/utils/types';
import { Frequency } from '../../channel/types/frequency';
import { BankDto } from './bank.dto';
import { BeneficiaryDto } from './beneficiary.dto';
import { RateQuote } from './rate-quote';
import { WireCompanyDto } from './wireCompany.dto';

export interface WireDto {
    additionalInformation: string;
    amount: number;
    audit: string;
    availableApprovers: string;
    bank: BankDto;
    beneficiary: BeneficiaryDto;
    canUserEditWire: boolean;
    /**
     * Labeled as "OMAD" (the federal tracking number for a wire transfer).
     * See wire-field-definitions.ts
     */
    confirmationNumber: string;
    createdBy: string;
    createdDate: Date;
    debitAccount: string;
    destinationCurrency: string;
    errorSummary?: {
        details: string;
        summaryMessage: string;
    };
    externallyProcessed: boolean;
    frequency?: Frequency;
    id: number;
    initialValues: object;
    intermediaryBanks: { bank: BankDto }[];
    isDli: boolean;
    isEdited: boolean;
    isInternational: boolean;
    isUserApprovalLimitLess: boolean;
    isWireEditable: boolean;
    purpose: string;
    rateQuote: RateQuote;
    referenceBeneficiary: string;
    statusId: number;
    status: string;
    transactionId: number;
    wireCompany: WireCompanyDto;
}

export const nullWire: Nullable<WireDto> = {
    additionalInformation: '',
    amount: 0.0,
    audit: '',
    availableApprovers: '',
    bank: null,
    beneficiary: null,
    canUserEditWire: null,
    confirmationNumber: '',
    createdBy: '',
    createdDate: null,
    debitAccount: '',
    destinationCurrency: '',
    externallyProcessed: null,
    frequency: null,
    id: null,
    initialValues: null,
    intermediaryBanks: null,
    isDli: null,
    isEdited: null,
    isInternational: null,
    isUserApprovalLimitLess: null,
    isWireEditable: null,
    purpose: '',
    rateQuote: null,
    referenceBeneficiary: '',
    statusId: null,
    status: '',
    transactionId: null,
    wireCompany: null,
};
