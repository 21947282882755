/* eslint-disable @treasury/no-branch-in-template-literal */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable lines-between-class-members */
import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-input';
import { until } from 'lit/directives/until.js';
import { property } from 'lit/decorators.js';
import { ListeningElementMixin } from '@treasury/omega/components';

//@ts-ignore
const mixin = ListeningElementMixin(LitElement);
export class ChildSupportPaymentHeader extends mixin {
    @property({ type: Object })
    paymentHeaderRecord: any;
    @property({ type: Object })
    recipientsRecordset: any;
    @property({ type: Number })
    activeStep!: number;
    @property({ type: Number })
    recipientCount!: number;
    @property({ type: Boolean, reflect: true })
    isFromFile = false;
    @property({ type: Boolean, reflect: true })
    expanded = false;
    loading = false;
    readOnly = true;
    renderPaymentName() {
        if (this.isFromFile && this.paymentHeaderRecord.getField('step') === 1) {
            const field = this.paymentHeaderRecord.field('name');
            return html`<omega-input
                .value=${this.paymentHeaderRecord.getField('name')}
                .field=${field}
            ></omega-input>`;
        }
        return html`<div>${this.paymentHeaderRecord.getField('name')}</div>`;
    }

    async renderRecipientCount() {
        if (this.recipientsRecordset) {
            await this.recipientsRecordset.requestUpdate();
            this.recipientCount = this.recipientsRecordset.allRecords.length;
            const recipientLabel = this.recipientCount === 1 ? 'Recipient' : 'Recipients';
            return html`${this.recipientCount} ${recipientLabel}`;
        }
        return nothing;
    }

    render() {
        if (!this.paymentHeaderRecord) return nothing;
        return html` <omega-accordion .expanded=${this.expanded}>
            <div class="accordion-header" slot="header">
                ${this.renderPaymentName()}
                <small>${until(this.renderRecipientCount(), nothing)}</small>
            </div>
            <div slot="content">
                <div class="omega-flex-form">
                    <div class="form-column">
                        ${this.activeStep === 3
                            ? html`<omega-field
                                  field="status"
                                  label="Status"
                                  .record=${this.paymentHeaderRecord}
                              ></omega-field>`
                            : nothing}
                        ${this.activeStep === 3
                            ? html` <omega-field
                                  field="transactionId"
                                  label="Transaction ID"
                                  .record=${this.paymentHeaderRecord}
                              ></omega-field>`
                            : nothing}
                        <omega-field
                            field="achCompany"
                            label="ACH Company Name"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="achCompanyId"
                            label="ACH Company ID"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="companyIdentity"
                            label="ACH Company ID"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="secCode"
                            label="SEC Code"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="entryDescription"
                            label="Entry Description"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="discretionaryData"
                            label="Discretionary Data"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="restricted"
                            label="Restrict Payment"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                    </div>
                    <div class="form-column">
                        <omega-field
                            field="debitAmount"
                            label="Debit"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="creditAmount"
                            label="Credit"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                        <omega-field
                            field="frequency"
                            label=""
                            .record=${this.paymentHeaderRecord}
                            .readOnly=${this.isFromFile && this.activeStep !== 3
                                ? false
                                : this.readOnly}
                        ></omega-field>
                        <omega-field
                            field="offsetAccount"
                            label="Offset Account"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                    </div>
                    <div class="form-column">
                        <omega-field
                            field="audit"
                            label="Audit"
                            .record=${this.paymentHeaderRecord}
                        ></omega-field>
                    </div>
                </div>
            </div>
        </omega-accordion>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-flex-form {
                display: flex;
            }

            .accordion-header {
                display: inline-flex;
                align-items: center;
            }

            omega-field {
                --omega-label: 14px;
                --omega-field-control-font-size: 14px;
                --omega-field-control-font-weight: 700;
                --omega-field-control-width: 100px;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }
            .omega-flex-form omega-field {
                margin: 10px;
            }
            small {
                font-size: 14px;
                font-weight: 400;
                margin-left: 5px;
            }
        `;
    }
}

//@ts-ignore
customElements.define('child-support-payment-header', ChildSupportPaymentHeader);
