import { transferAmountFilter } from '@treasury/policy';
import FieldTypes from '@treasury/policy/primitives';

const achRecipientsStatusOptions = [
    {
        value: '0',
        text: 'All',
    },
    {
        value: '1',
        text: 'Pending Approval',
    },
    {
        value: '2',
        text: 'Ready',
    },
    {
        value: '3',
        text: 'Rejected',
    },
];

export const createAchRecipientsFilters = () => [
    {
        field: 'recipientName',
        fieldType: FieldTypes.string.with.label('Recipient Name').as.tag('omega-input'),
        value: '',
    },
    {
        field: 'amount',
        fieldType: transferAmountFilter.with.label('Amount'),
        value: ['', 0, 0],
    },
    {
        field: 'status',
        fieldType: FieldTypes.string.with
            .label('Status')
            .as.tag('omega-select')
            .with.options({ data: achRecipientsStatusOptions }),
        value: achRecipientsStatusOptions[0].value,
    },
];
