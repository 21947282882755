/* eslint-disable @treasury/style-includes-host-display */
import { css, html, LitElement } from 'lit';

class OmegaButtonBar extends LitElement {
    static get properties() {
        return {
            alignment: {
                type: String,
            },
            position: {
                type: String,
            },
        };
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-button-bar--button-bar',
        };
    }

    getAlignmentClass() {
        if (!this.alignment) return 'omega-button-bar-left';
        const _alignment = this.alignment.toLowerCase();
        if (_alignment === 'center') {
            return 'omega-button-bar-center';
        }
        if (_alignment === 'right') {
            return 'omega-button-bar-right';
        }

        return 'omega-button-bar-left';
    }

    getPositionClass() {
        if (!this.position) return 'omega-button-bar-top';
        const _position = this.position.toLowerCase();
        if (_position === 'bottom') return 'omega-button-bar-bottom';
        return 'omega-button-bar-top';
    }

    render() {
        return html`
            <div class="omega-button-bar ${this.getAlignmentClass()} ${this.getPositionClass()}">
                <slot></slot>
            </div>
        `;
    }

    static get styles() {
        return css`
            .omega-button-bar {
                display: flex;
                flex-direction: row wrap;
                background-color: transparent;
                min-height: 52px;
            }

            .omega-button-bar-center {
                justify-content: center;
            }
            .omega-button-bar-left {
                justify-content: flex-start;
            }

            .omega-button-bar-right {
                justify-content: flex-end;
            }

            .omega-button-bar-bottom {
                position: absolute;
                bottom: var(--omega-button-bar-offset, 0);
                left: 0;
                height: 50px;
                line-height: 50px;
                width: 100%;
                border-top: 1px solid var(--omega-secondary-lighten-300);
                display: flex;
                align-items: center;
            }
            @media print {
                .omega-no-print {
                    display: none;
                }
            }
        `;
    }
}

customElements.define('omega-button-bar', OmegaButtonBar);
export default OmegaButtonBar;
