export default {
    template: require('../views/issueItemAccountSummaryView.html'),
    controller: IssueItemAccountSummaryController,
    bindings: {
        issueItems: '<',
    },
};

function IssueItemAccountSummaryController() {
    const $ctrl = this;
    $ctrl.accountSummaries = [];
    $ctrl.totalAmount = 0;
    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if (changes.issueItems) {
            validateInputs();
            calculateSummaries();
        }
    }

    function validateInputs() {
        if (!angular.isArray($ctrl.issueItems)) {
            throw 'issue-items passed to account summary must be an array';
        }
    }

    function calculateSummaries() {
        $ctrl.accountSummaries = calculateAccountSummary();
        $ctrl.totalAmount = calculateTotalAmount();
    }

    function calculateAccountSummary() {
        const accountSummaries = {};

        $ctrl.issueItems.forEach(issueItem => {
            const { account } = issueItem;
            if (account == null) return;

            let accountSummary = accountSummaries[account.id];
            if (accountSummary == null) {
                accountSummary = accountSummaries[account.id] = newAccountSummary(
                    account.number,
                    account.type
                );
            }

            updateAccountSummary(accountSummary, issueItem);
        });

        return objectValuesToArray(accountSummaries);
    }

    function calculateTotalAmount() {
        return $ctrl.issueItems.reduce(
            (totalAmount, issueItem) => totalAmount + issueItem.checkAmount,
            0
        );
    }

    function updateAccountSummary(accountSummary, issueItem) {
        ++accountSummary.itemCount;
        accountSummary.amountTotal += issueItem.checkAmount;
    }

    function newAccountSummary(accountNumber, accountType) {
        return {
            accountNumber,
            accountType,
            itemCount: 0,
            amountTotal: 0,
        };
    }

    function objectValuesToArray(object) {
        const values = [];
        for (const index in object) {
            values.push(object[index]);
        }

        return values;
    }
}
