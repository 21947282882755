IncludeHeaderOptionController.$inject = ['$scope', '$modalInstance', 'format'];

export default function IncludeHeaderOptionController($scope, $modalInstance, format) {
    $scope.header = false;
    $scope.format = format;

    $scope.includeHeader = function (include) {
        $modalInstance.close(include);
    };
}
