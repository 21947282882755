// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';
import { MasterListRecipientsRaw, MasterRecipientsRaw, PaymentRecipient } from '../../types/ach';

export class AchRecipientsRequests {
    static async uploadRecipients(file: File, format: string) {
        const fd = new FormData();
        fd.append('file', file);

        return (await TmHttpClient.getInstance()).request<Array<PaymentRecipient>>(
            `achRecipients?format=${format}`,
            {
                method: 'POST',
                body: fd,
                disableContentType: true,
            }
        );
    }

    static async approveRecipients(achMasterRecipientIds: number[]) {
        return (await TmHttpClient.getInstance()).request('AchMasterRecipient/approve', {
            method: 'POST',
            body: {
                achMasterRecipientIds,
            },
        });
    }

    static async rejectRecipients(achMasterRecipientIds: number[]) {
        return (await TmHttpClient.getInstance()).request('AchMasterRecipient/reject', {
            method: 'POST',
            body: {
                achMasterRecipientIds,
            },
        });
    }

    static async fetchRecipients(fetchRecipientsReqParams: any) {
        return (await TmHttpClient.getInstance()).request<MasterRecipientsRaw>(
            `AchMasterRecipient/GetMasterRecipients`,
            {
                method: 'POST',
                body: fetchRecipientsReqParams,
            }
        );
    }

    static async fetchMasterListRecipients(fetchRecipientsReqParams: any) {
        return (await TmHttpClient.getInstance()).request<MasterListRecipientsRaw>(
            `AchMasterRecipient/GetMasterRecipients`,
            {
                method: 'POST',
                body: fetchRecipientsReqParams,
            }
        );
    }

    static async createOrUpdateRecipients(saveableMasterRecipients: any) {
        return (await TmHttpClient.getInstance()).request(
            `AchMasterRecipient/SaveMasterRecipient`,
            {
                method: 'POST',
                body: {
                    saveableMasterRecipients,
                },
            }
        );
    }

    static async deleteRecipients(achMasterRecipientIds: number[]) {
        return (await TmHttpClient.getInstance()).request(`AchMasterRecipient/delete`, {
            method: 'POST',
            body: {
                achMasterRecipientIds,
            },
        });
    }

    static async getAchRecipientAudit(id: number) {
        return (await TmHttpClient.getInstance()).request(`AchMasterRecipient/audits/${id}`, {
            method: 'GET',
        });
    }
}
