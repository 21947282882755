import { html } from 'lit';
import '@treasury/omega/components/omega-field.js';

export const recipientDetails = (record: any) => html`
    <style>
        .omega-flex-form {
            display: flex;
        }

        .form-column {
            flex: 1 1 0;
            margin: 10px;
        }

        .form-column:not(:last-of-type) {
            padding-right: 10px;
            border-right: 1px solid #d3d6d9;
        }
        .omega-flex-form omega-field {
            --omega-field-control-width: 120px;
            margin: 10px;
        }
    </style>
    <div class="omega-flex-form">
        <div class="form-column">
            <omega-field field="nonCustodialParentSsn" .record=${record}></omega-field>
            <omega-field field="nonCustodialParentFirstName" .record=${record}></omega-field>
            <omega-field field="nonCustodialParentLastName" .record=${record}></omega-field>
        </div>
        <div class="form-column">
            <omega-field field="caseIdentifier" .record=${record}></omega-field>
            <omega-field field="fipsCode" .record=${record}></omega-field>
        </div>
        <div class="form-column">
            <omega-field field="payDate" .record=${record}></omega-field>
            <omega-field field="employmentTermination" .record=${record}></omega-field>
            <omega-field field="medicalSupport" .record=${record}></omega-field>
        </div>
    </div>
`;
