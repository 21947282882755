/* eslint-disable import/extensions */
import { PayOrReturnChoice } from '../../mappings/ach';
import { PositivePayExceptionRequests } from '../../requests/positive-pay/positive-pay-exception-requests.js';

export default class AchExceptionServices {
    static async saveAchExceptions(records) {
        const exceptions = records.map(record => ({
            ...record,
            decisionReviewLabel: record.decisionChoice,
            achExceptionDecisionStatusId:
                record.decisionChoice in PayOrReturnChoice
                    ? PayOrReturnChoice[record.decisionChoice]
                    : PayOrReturnChoice.Unselected,
        }));
        return PositivePayExceptionRequests.saveAchExceptionsDashboard(exceptions);
    }
}
