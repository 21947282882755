const Events = {
    OPEN: 'open',
    CHANGE: 'change',
    UPDATED: 'updated',
    CHANGE_MONTH: 'change-month',
    CLOSE: 'close',
    CLEAR: 'clear',
    SELECT_MONTH: 'select-month',
    CLOSE_SELECT_MONTH: 'close-select-month',
};

export { Events };
