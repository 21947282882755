DateFilterService.$inject = ['DatePeriod', 'moment'];

export default function DateFilterService(DatePeriod, moment) {
    const dateTypes = [
        { name: DatePeriod.TODAY, default: true },
        { name: DatePeriod.SPECIFIC_DATE },
        { name: DatePeriod.DATE_RANGE },
        { name: DatePeriod.WEEK_TO_DATE },
        { name: DatePeriod.MONTH_TO_DATE },
        { name: DatePeriod.YEAR_TO_DATE },
    ];

    const dateFormat = 'MM/DD/YYYY';

    function getPeriodDates(dateType, formattedFromDate, formattedToDate) {
        const today = moment().startOf('day');
        const sunday = 0;
        let fromDate;

        if (dateType === DatePeriod.TODAY) {
            formattedToDate = today.format(dateFormat);
            formattedFromDate = formattedToDate;
        } else if (dateType === DatePeriod.WEEK_TO_DATE) {
            formattedToDate = today.format(dateFormat);

            if (today.day() === sunday) {
                // The week starts on sunday for moment, -7 is last sunday, and add 1 for last monday
                fromDate = moment(today).day(-6);
            } else {
                fromDate = moment(today).day('Monday');
            }

            formattedFromDate = fromDate.format(dateFormat);
        } else if (dateType === DatePeriod.MONTH_TO_DATE) {
            formattedToDate = today.format(dateFormat);
            formattedFromDate = moment({
                year: today.year(),
                month: today.month(),
                day: 1,
            }).format(dateFormat);
        } else if (dateType === DatePeriod.YEAR_TO_DATE) {
            formattedToDate = today.format(dateFormat);
            formattedFromDate = moment({ year: today.year(), month: 0, day: 1 }).format(dateFormat);
        } else if (dateType === DatePeriod.SPECIFIC_DATE) {
            formattedToDate = formattedFromDate;
        }

        return {
            formattedFromDate,
            formattedToDate,
        };
    }

    function getDefaultDateTypes() {
        return dateTypes.map(dateType => dateType.name);
    }

    function getDefaultDateType() {
        const defaultDateType = dateTypes.filter(item => item.default)[0];

        if (defaultDateType != null) return defaultDateType.name;

        return null;
    }

    return {
        getDefaultDateTypes,
        getDefaultDateType,
        getPeriodDates,
    };
}
