/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ApproveAchMasterRecipientRequestDto,
    BaseResponse3Dto,
    DeleteAchMasterRecipientRequestDto,
    GetMasterRecipientsRequestDto,
    RejectAchMasterRecipientRequestDto,
    SaveMasterRecipientRequestDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchMasterRecipientClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchMasterRecipient
     * @name AchMasterRecipientGetMasterRecipients
     * @summary Gets the master recipients.
     * @request POST:/AchMasterRecipient/GetMasterRecipients
     */
    achMasterRecipientGetMasterRecipients = (
        request: GetMasterRecipientsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaseResponse3Dto, any>({
            path: `/AchMasterRecipient/GetMasterRecipients`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchMasterRecipient
     * @name AchMasterRecipientSaveMasterRecipient
     * @summary Saves the master recipient.
     * @request POST:/AchMasterRecipient/SaveMasterRecipient
     */
    achMasterRecipientSaveMasterRecipient = (
        request: SaveMasterRecipientRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaseResponse3Dto, any>({
            path: `/AchMasterRecipient/SaveMasterRecipient`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchMasterRecipient
     * @name AchMasterRecipientApproveMasterRecipients
     * @summary Approves the master recipients.
     * @request POST:/achMasterRecipient/approve
     */
    achMasterRecipientApproveMasterRecipients = (
        request: ApproveAchMasterRecipientRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaseResponse3Dto, any>({
            path: `/achMasterRecipient/approve`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchMasterRecipient
     * @name AchMasterRecipientRejectMasterRecipients
     * @summary Rejects the master recipients.
     * @request POST:/achMasterRecipient/reject
     */
    achMasterRecipientRejectMasterRecipients = (
        request: RejectAchMasterRecipientRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaseResponse3Dto, any>({
            path: `/achMasterRecipient/reject`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchMasterRecipient
     * @name AchMasterRecipientDeleteMasterRecipients
     * @summary Deletes the master recipients.
     * @request POST:/achMasterRecipient/delete
     */
    achMasterRecipientDeleteMasterRecipients = (
        request: DeleteAchMasterRecipientRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaseResponse3Dto, any>({
            path: `/achMasterRecipient/delete`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchMasterRecipient
     * @name AchMasterRecipientGetAudits
     * @summary Gets the audits.
     * @request GET:/achMasterRecipient/audits/{achMasterRecipientId}
     */
    achMasterRecipientGetAudits = (achMasterRecipientId: number, params: RequestParams = {}) =>
        this.http.request<BaseResponse3Dto, any>({
            path: `/achMasterRecipient/audits/${achMasterRecipientId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
