// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping calls associated with ACH companies.
 */
class AchSettingsRequests {
    static async getAchSettings() {
        return (await TmHttpClient.getInstance()).request(`achSettings`, {
            method: 'GET',
            maxAgeInSeconds: 600000,
        });
    }

    static async getSameDayAch() {
        return (await TmHttpClient.getInstance()).request(
            `companyaccounts/cutoffTimesByProductType/SameDayAch`,
            {
                method: 'GET',
                maxAgeInSeconds: 600000,
            }
        );
    }
}

export default AchSettingsRequests;
