import { AuditModelDto, CheckExceptionModelDto } from '@treasury/api/channel';
import { CheckExceptionModelForSubmissionDto, ReturnReasonsDescriptions } from '../channel/types';
import { DomainEntity } from '../shared';
import { type CheckExceptionService } from './check-exception.service';
import { ExceptionDecisionStatus } from './data';
import { defaultCheckExceptionModelDto } from './data/check-exception.data';

/**
 * A business object responsible for managing the client-side check exception data.
 *
 * Ingests the check exception dto model from the api request and makes the relevant
 * data available to the check exception components and view model for presentation.
 */
export class CheckException extends DomainEntity<CheckExceptionModelDto> {
    constructor(
        dto: CheckExceptionModelDto,
        private checkExceptionService: CheckExceptionService
    ) {
        super(dto);
        this.originalDecisionStatus = dto.decisionStatus;
        this.dto.decisionStatus = this.getDecisionStatusFromString(this.dto.decisionStatus);
        this.comment = this.dto.comment;
        this.exceptionReason = this.dto.exceptionReason;
        if (!this.dto.returnReasonDescription) {
            this.dto.returnReasonDescription = ReturnReasonsDescriptions.None;
        }
        this.isPastCutoff = false;
    }

    private _decisionPay = false;

    private _decisionReturn = false;

    private _decisionModified = false;

    public readonly = false;

    public acknowledged = false;

    public comment?: string;

    public exceptionReason?: string;

    public isExpanded = false;

    public originalDecisionStatus: string | undefined;

    public isPastCutoff = false;

    public file?: File;

    private _hasAttachment?: boolean;

    public editComment?: string = this.dto.comment;

    public get showModified() {
        return !this.readonly && this.decisionModified;
    }

    public createDefault(): this {
        return new CheckException(
            defaultCheckExceptionModelDto,
            this.checkExceptionService
        ) as this;
    }

    public updateComment() {
        this.comment = this.editComment;
    }

    public revertCommentEdit() {
        if (this.editComment !== this.comment) this.editComment = this.comment ?? '';
    }

    /**
     * use `pay()`, `return()`, or `clearDecision()` methods to update the value of this property
     */
    public get decisionPay() {
        return this._decisionPay;
    }

    /**
     * use `pay()`, `return()`, or `clearDecision()` methods to update the value of this property
     */
    public get decisionReturn() {
        return this._decisionReturn;
    }

    public get decisionModified() {
        return this.decisionStatus !== this.originalDecisionStatus || this._decisionModified;
    }

    public set decisionModified(val: boolean) {
        if (this.protected) {
            return;
        }
        this._decisionModified = val;
    }

    public pay() {
        if (this.protected) return;
        if (
            this.decisionStatus === 'Return' ||
            this.originalDecisionStatus?.toLocaleLowerCase() !== 'pay'
        ) {
            this.modify();
        } else {
            this.decisionModified = !this.decisionModified;
        }
        this._decisionPay = true;
        this._decisionReturn = false;
        this.dto.decisionStatus = ExceptionDecisionStatus.Pay;
    }

    public return() {
        if (this.protected) return;
        if (
            this.decisionStatus === 'Pay' ||
            this.originalDecisionStatus?.toLocaleLowerCase() !== 'return'
        ) {
            this.modify();
        } else {
            this.decisionModified = !this.decisionModified;
        }
        this._decisionPay = false;
        this._decisionReturn = true;
        this.dto.decisionStatus = ExceptionDecisionStatus.Return;
    }

    public clearDecision() {
        this.decisionModified = false;
        this._decisionPay = this.originalDecisionStatus === ExceptionDecisionStatus.Pay;
        this._decisionReturn = this.originalDecisionStatus === ExceptionDecisionStatus.Return;
        this.dto.decisionStatus = this.originalDecisionStatus;
    }

    public resetDecision() {
        this.decisionModified = false;
        this._decisionPay = false;
        this._decisionReturn = false;
        this.dto.decisionStatus = this.originalDecisionStatus;
    }

    public modify() {
        this.decisionModified = true;
    }

    public getDecisionStatusFromString(str?: string) {
        switch (str?.toLocaleLowerCase()) {
            case 'pay':
                return ExceptionDecisionStatus.Pay;
            case 'return':
                return ExceptionDecisionStatus.Return;
            default:
                return ExceptionDecisionStatus.NA;
        }
    }

    public setPastCutoff() {
        this.isPastCutoff = true;
    }

    public get id() {
        return this.dto.arpExceptionDetailId;
    }

    public get accountNumber() {
        return this.dto.accountNumber;
    }

    public get checkNumber() {
        return this.dto.checkNumber === '0' ? '' : this.dto.checkNumber;
    }

    public get paidAmount() {
        return this.dto.paidAmount;
    }

    public get issuedAmount() {
        return this.dto.issuedAmount || 0;
    }

    public get postedDate() {
        return this.dto.postedDate;
    }

    public get issuedDate() {
        return this.dto.issuedDate;
    }

    public get issuedPayee() {
        return this.dto.issuedPayee;
    }

    public get checkImageNumber() {
        return this.dto.checkImageNumber;
    }

    public get guid() {
        return this.dto.arpExceptionDetailUniqueId;
    }

    public get companyName() {
        return this.dto.companyName;
    }

    public get companyId() {
        return this.dto.companyId;
    }

    public get returnReasonId() {
        return this.dto.returnReasonUniqueId;
    }

    public get audits() {
        return this.dto.audits;
    }

    public get isCorrectionRequestCreated() {
        return this.dto.isCorrectionRequestCreated;
    }

    get hasAttachment() {
        return this._hasAttachment || !!this.dto.attachment;
    }

    set hasAttachment(val: boolean) {
        this._hasAttachment = val;
    }

    /**
     * guid format attachment id
     */
    public get attachmentId(): string | undefined {
        return this.dto.attachment;
    }

    public get protected() {
        return this.dto.protected?.toLocaleLowerCase() === 'y';
    }

    public get returnReasonDescription() {
        return this.dto.returnReasonDescription as ReturnReasonsDescriptions;
    }

    public set returnReasonDescription(val: ReturnReasonsDescriptions) {
        this.dto.returnReasonUniqueId = this.checkExceptionService.returnReasons.find(
            reason => reason.description === val
        )?.returnReasonUniqueId;
        this.dto.returnReasonDescription = val;
    }

    public get decisionStatus() {
        return this.dto.decisionStatus;
    }

    public get updatedBy() {
        return this.dto.updatedBy;
    }

    public get fromWorkStation() {
        return this.dto.fromWorkStation;
    }

    public get sourceOfEntry() {
        return this.dto.sourceOfEntry;
    }

    public get ddaBatchNumber() {
        return this.dto.ddaBatchNumber;
    }

    public get ddaSequenceNumber() {
        return this.dto.ddaSequenceNumber;
    }

    public get lastDecisionTakenBy() {
        return this.dto.lastDecisionTakenBy;
    }

    public getDtoForSubmit(): CheckExceptionModelForSubmissionDto {
        return {
            ...this.dto,
            existingDecision: this.decisionPay,
            pay: this.decisionPay,
            returnReasonComment: this.comment,
            step: 1,
            /**
             * missing fields from generated model: ArpExceptionDetailDataModelDto
             * dto for submit is currently working, but need to examine when these additional fields should be implemented
             */
        };
    }

    public get originalApprovalStatus() {
        return this.originalDecisionStatus === ExceptionDecisionStatus.Pay
            ? 'Approved'
            : 'Rejected';
    }

    public get decidedToday() {
        return (
            this.dto.audits?.reduce((prev: boolean, audit: AuditModelDto) => {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const sinceYesterday = new Date(audit.timestamp) >= yesterday;
                return prev || (sinceYesterday && audit.username !== 'Financial Institution');
            }, false) || this.checkExceptionService.isAfterCutoff
        );
    }

    public get serviceTestId() {
        return this.checkExceptionService.testId;
    }

    public get returnReasons() {
        return this.checkExceptionService.returnReasons;
    }
}
