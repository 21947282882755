/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { SecurityMessage } from '@treasury/domain/channel/mappings/security';
import { IdentityDialogOptions, IdentityDialogResult } from '@treasury/domain/shared';
import { html } from 'lit';
import { OmegaDialogExitReason, OmegaDialogService } from '../../services/omega-dialog';
import { IdentityVerificationDialog } from './identity-verification-dialog';

const defaultDialogOptions: Omit<IdentityDialogOptions, 'otpRequestFn'> = {
    disableClose: false,
    forLogin: false,
    withoutUserInteraction: false,
};

export async function openIdentityDialog<T = object>(
    securityMessage: SecurityMessage,
    options: Partial<IdentityDialogOptions> = {}
): Promise<IdentityDialogResult<T>> {
    const { forLogin, withoutUserInteraction, otpRequestFn, disableClose } = {
        ...defaultDialogOptions,
        ...options,
    };
    const diContainer = await DiContainer.getInstance();
    const dialogService = diContainer.get(OmegaDialogService);
    const handle = dialogService.open<IdentityVerificationDialog, IdentityDialogResult<T>>(
        html`<identity-verification-dialog
            .securityMessage=${securityMessage}
            .disableClose=${disableClose}
            .forLogin=${forLogin}
            .withoutUserInteraction=${withoutUserInteraction}
            .otpRequestFn=${otpRequestFn}
        ></identity-verification-dialog>`,
        'Identity Verification',
        {
            // component renders its own buttons
            renderButtons: false,
            width: 400,
            preventClose: true
        }
    );

    const { reason, data } = await handle.closed;

    if (!data) {
        throw new Error('Identity dialog exited with no data.');
    }

    const { response, message } = data;

    return {
        reason: mapExitReason(reason),
        response,
        message,
    };
}

/**
 * Exists to decouple the auth fetch code from having a dependency
 * on `@treasury/omega`.  Even though the values are the same/similar,
 * this mapping must occur to maintain the constrain that `@treasury/domain`
 * cannot depend on `@treasury/omega`.
 */
function mapExitReason(reason: OmegaDialogExitReason): IdentityDialogResult['reason'] {
    switch (reason) {
        case OmegaDialogExitReason.Confirm:
            return 'confirm';
        case OmegaDialogExitReason.Cancel:
            return 'cancel';
        default:
            throw new Error(`Invalid identity dialog exit reason ${reason}.`);
    }
}
