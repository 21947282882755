import { UserInformationModelDto } from '@treasury/api/channel';
import { ChangePasswordDto, UserAccountDto } from '@treasury/domain/channel/types/user-account';
import { FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import fieldTypes from '@treasury/policy/primitives';

export const userAccountInformationFields: FdlFieldDefinitions<UserInformationModelDto> = {
    name: fieldTypes.string.with.label('Name:').thatIs.readOnly() as FieldType<string>,
    loginId: fieldTypes.string.with.label('Login ID:').thatIs.readOnly() as FieldType<string>,
    email: (fieldTypes.string as FieldType<string>).with
        .label('Email:')
        .thatIs.required()
        .thatHas.validator({
            name: 'email-format',
            validate: value => {
                if (!value) return true;
                const re =
                    /^[a-zA-Z0-9.!#$%&amp;’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
                return re.test(value);
            },
        })
        .as.tag('omega-input'),
    phone: fieldTypes.phone.with
        .label('Phone #:')
        .thatIs.required()
        .as.tag('omega-input') as FieldType<string>,
    extension: fieldTypes.string.with
        .label('Ext.')
        .and.inputMask(/[0-9]/)
        .as.tag('omega-input') as FieldType<UserInformationModelDto['extension']>,
    mobile: fieldTypes.phone.with
        .label('Mobile #:')
        .and.iconMessage('Messaging and data rates may apply')
        .as.tag('omega-input') as FieldType<UserInformationModelDto['mobile']>,
    fax: fieldTypes.phone.with.label('Fax #:').as.tag('omega-input') as FieldType<
        UserInformationModelDto['fax']
    >,
};

export const userAccountSecurityPreferenceFields: FdlFieldDefinitions<UserInformationModelDto> = {
    accountNicknames: fieldTypes.boolean.with
        .label('Account Label Display:')
        .thatHas.options([
            { text: 'Account Nicknames', value: true },
            { text: 'Account Numbers', value: false },
        ])
        .thatIs.inline()
        .as.tag('omega-radio-group') as FieldType<boolean | undefined>,
    accountMasking: fieldTypes.boolean.with
        .label('Account Number Masking:')
        .with.iconMessage('Masked Account Number Example: ****-1234')
        .with.toggle()
        .thatIs.inline()
        .thatIs.disabledWhen((record: any) => !record.getField('isAccountMaskingConfigurable'))
        .as.tag('omega-radio-group') as FieldType<boolean | undefined>,
    verificationMethod: (
        fieldTypes.string as FieldType<UserInformationModelDto['verificationMethod']>
    ).with
        .label('Preferred Method of Verification:')
        .thatHas.options([
            { text: 'Text Message', value: 'text' },
            { text: 'Automated Phone Call', value: 'call' },
        ])
        .thatIs.inline()
        .thatIs.visibleWhen((record: any) => record.getField('isMethodOfVerificationConfigurable'))
        .as.tag('omega-radio-group'),
};

export const userAccountChangePasswordFields: FdlFieldDefinitions<ChangePasswordDto> = {
    currentPassword: fieldTypes.string.with
        .label('Current Password')
        .thatIs.required()
        .thatHas.maxLength(250)
        .and.type('password')
        .as.tag('omega-input') as FieldType<string>,
    newPassword: fieldTypes.string.with
        .label('New Password')
        .thatIs.required()
        .thatHas.maxLength(250)
        .and.type('password')
        .as.tag('omega-input') as FieldType<string>,
    confirmPassword: new FieldType<string>().with
        .defaultValue('')
        .and.type('password')
        .and.label('Confirm New Password')
        .thatIs.required()
        .thatHas.maxLength(250)
        .and.validator<ChangePasswordDto>({
            name: 'match-password',
            validate: (value, _view, record) => {
                if (value && record.getField('newPassword')) {
                    return value === record.getField('newPassword');
                }
                return true;
            },
        })
        .and.type('password')
        .as.tag('omega-input'),
};

export const userAccountFields: FdlFieldDefinitions<UserInformationModelDto> = {
    ...userAccountInformationFields,
    ...userAccountSecurityPreferenceFields,
};
