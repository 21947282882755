import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const logoutTagName = 'logout-container';

@customElement(logoutTagName)
export class Logout extends LitElement {
    @property({ type: String })
    public institutionId!: string;

    render() {
        return html`
            <div class="card">
                <div class="card-header">
                    <h1>Logout</h1>
                </div>
                <div class="card-content">
                    <p>
                        Thank you.<br />
                        You have been logged out.<br />
                        <strong>Please close your browser to end your session.</strong>
                    </p>
                </div>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                --omega-primary: var(--brand-color);
            }
            .card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 52px;
                padding: 0 15px;
                border-bottom: 1px solid #ccc;
            }
            .card-content {
                padding: 0 15px;
                font-size: 14px;
            }
            .card-footer {
                border-top: 1px solid #ccc;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 52px;
                padding: 0 15px;
            }
            h1 {
                font-size: 20px;
                font-weight: 600;
                margin: 0;
            }
            .card {
                position: relative;
                background: #fff;
                box-shadow: 0 0 8px rgb(0 0 0 / 40%);
                width: clamp(300px, 100%, var(--login-card-max-width, 600px));
            }
            .error {
                border: 1px solid #ee3a3b;
                border-left: 4px solid #ee3a3b;
                padding: 7px 15px;
                margin: 15px;
                display: flex;
                align-items: center;
            }
            omega-icon {
                margin-right: 16px;
                color: #ee3a3b;
                font-size: 24px;
            }
            :host([statuscode='Register']) {
                --login-card-max-width: 800px;
            }
        `;
    }
}
