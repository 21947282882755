/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    MessageCategoryModelDto,
    MessageCenterSearchTermsDto,
    MessageModelDto,
    UpdateMessageModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class MessagingcenterClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterGetMessageCategories
     * @summary Gets the message categories.
     * @request GET:/messagingcenter/getmessagecategories
     */
    messagingCenterGetMessageCategories = (params: RequestParams = {}) =>
        this.http.request<MessageCategoryModelDto[], any>({
            path: `/messagingcenter/getmessagecategories`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterSaveMessage
     * @summary Saves the message.
     * @request POST:/messagingcenter/savemessage
     */
    messagingCenterSaveMessage = (params: RequestParams = {}) =>
        this.http.request<MessageModelDto, any>({
            path: `/messagingcenter/savemessage`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterSearchMessagesAll
     * @summary Searches the messages.
     * @request POST:/messagingcenter/search
     */
    messagingCenterSearchMessagesAll = (
        searchTerms: MessageCenterSearchTermsDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<MessageModelDto[], any>({
            path: `/messagingcenter/search`,
            method: 'POST',
            body: searchTerms,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterSearchMessages
     * @summary Searches the messages.
     * @request POST:/messagingcenter/update
     */
    messagingCenterSearchMessages = (
        update: UpdateMessageModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/messagingcenter/update`,
            method: 'POST',
            body: update,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterGetMessageThread
     * @summary Gets the message thread.
     * @request GET:/messagingcenter/messagethread/{id}
     */
    messagingCenterGetMessageThread = (id: number, params: RequestParams = {}) =>
        this.http.request<MessageModelDto[], any>({
            path: `/messagingcenter/messagethread/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterGetAttachment
     * @summary Gets the attachment.
     * @request GET:/messagingcenter/downloadattachment/{id}
     */
    messagingCenterGetAttachment = (id: number, params: RequestParams = {}) =>
        this.http.request<File, any>({
            path: `/messagingcenter/downloadattachment/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
