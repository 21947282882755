import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { AccountService, ChannelAuthenticationService } from '@treasury/domain/channel/services';
import { StatusCode } from '@treasury/domain/channel/services/login';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserOptionViewModel } from './components/user-selection';
import './container.js';

export const ssoCallbackContainerTagName = 'sso-callback-container';

@customElement(ssoCallbackContainerTagName)
export class SsoContainer extends LitElement {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare authService: AuthenticationService;

    @InjectProperty()
    private declare channelAuthService: ChannelAuthenticationService;

    @InjectProperty()
    private declare accountService: AccountService;

    @property({ type: String })
    public institutionId!: string;

    @state()
    private errorMessage?: string;

    @state()
    private loading = true;

    @state()
    private statusCode?: string;

    @state()
    private userOptions: Array<UserOptionViewModel> = [];

    protected async firstUpdated(): Promise<void> {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        try {
            this.loading = true;
            const {
                statusCode,
                message,
                alias,
                companyUniqueId,
                digitalId,
                userCompanyInformation,
                userAccountSettingsLink,
            } = await this.authService.authenticationSsoViaUis(code ?? '', state ?? '');

            // Go to user selection if more than one TM is linked to the UIS ID
            if (userCompanyInformation && userCompanyInformation.length > 1) {
                this.statusCode = StatusCode.UserSelection;
                this.userOptions = userCompanyInformation;
                return;
            }

            // If only one user is linked go ahead and log them in
            const loginData = {
                institution: this.institutionId,
                companyId: companyUniqueId,
                loginId: alias,
                userName: alias,
                useRefreshTokens: false,
                digitalId,
                userAccountSettingsLink,
            };

            window.sessionStorage.setItem('user', JSON.stringify(loginData));

            this.statusCode = await this.channelAuthService.startAuthWorkflow({
                statusCode,
                message,
                numberOfSecurityQuestions: 0,
                daysUntilPasswordExpires: 0,
            });
        } catch (e) {
            this.errorMessage = e instanceof Error ? e.message : 'An unknown error occurred.';
        } finally {
            this.loading = false;
        }
    }

    renderErrorMessage() {
        if (!this.errorMessage) return nothing;
        return html`
            <div class="error">
                <omega-icon icon="times-circle"></omega-icon>
                ${this.errorMessage}
            </div>
        `;
    }

    protected render() {
        return html`
            ${this.loading || this.statusCode === StatusCode.Loading
                ? html`<blocking-loader></blocking-loader>`
                : nothing}
            ${this.errorMessage ? this.renderErrorMessage() : nothing}
            ${this.statusCode && this.statusCode !== StatusCode.Loading && !this.errorMessage
                ? html`<login-container
                      .statusCode=${this.statusCode}
                      .userOptions=${this.userOptions}
                  ></login-container>`
                : nothing}
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }

                @media screen and (min-width: 1024px) {
                    :host {
                        max-width: 50vw;
                    }
                }

                .error {
                    border: 1px solid #ee3a3b;
                    border-left: 4px solid #ee3a3b;
                    padding: 7px 15px;
                    margin: 15px;
                    display: flex;
                    align-items: center;
                }
                omega-icon {
                    margin-right: 16px;
                    color: #ee3a3b;
                    font-size: 24px;
                }
            `,
        ];
    }
}
