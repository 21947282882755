import { isValidDate } from './index';

const isValidRepeatOnStart = (frequency: any) => {
    if (!frequency) return false;

    // if (logging) console.log(`valid repeat on start date: ${result}`);

    return (
        frequency.repeatOnLastBusinessDay ||
        isValidDate(frequency.repeatOnDay1) ||
        Number.isInteger(frequency.repeatOnDay1)
    );
};

export default isValidRepeatOnStart;
