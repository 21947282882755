/**
 * @typedef { ReturnType<import('../../../../services/internalTransfersSrvc.js').InternalTransfersService> InternalTransfersService}
 */

/**
 *
 * @param { ng.IScope } $scope
 * @param { ng.ITimeoutService } $timeout
 * @param { InternalTransfersService } internalTransfersService
 * @param { ng.IFilterService } $filter
 */
export function CreateLoanTransferController(
    $scope,
    $state,
    $stateParams,
    toaster,
    $timeout,
    $uibModal,
    companyAccountsService,
    internalTransfersService,
    utilityService,
    modalService,
    holidaysService,
    securityService,
    $rootScope,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    dateConstants,
    $filter
) {
    const fromAccountKey = 'fromAccount';
    const toAccountKey = 'toAccount';
    $scope.accounts = [];
    $scope.currentDate = moment().format('MM/DD/YYYY');
    $scope.endOnDate = moment().format('MM/DD/YYYY');
    $scope.totalDebitAmount = 0;
    $scope.paymentOptionTypes = {
        1: 'Regular Payment',
        2: 'Principal Only',
    };
    $scope.loanPaymentPastDue = false;
    $scope.numbers = dateConstants.daysInMonth;
    $scope.wizardController = {};
    $scope.downloadPageId = downloadPageId.CreateLoanPaymentConfirmation;
    $scope.getFromAccounts = getFromAccounts;
    $scope.getToAccounts = getToAccounts;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.transferHeaderPanel = {
        header: '',
        createHeader: '',
        reviewHeader: '',
        confirmheader: '',
    };
    $scope.wizardPanel = { stepOneText: '' };
    $scope.loans = { templateName: '', templateId: null };
    $scope.type = $stateParams.type;
    $scope.createLoanPaymentMode = true;
    $scope.loadFromAccounts = loadFromAccounts;
    $scope.loadToAccounts = loadToAccounts;
    $scope.updateToAccounts = updateToAccounts;
    $scope.updateFromAccounts = updateFromAccounts;
    $scope.showModalFromAccount = showModalFromAccount;
    $scope.showModalToAccount = showModalToAccount;
    $scope.showPastDueModalIfDueDatePassed = showPastDueModalIfDueDatePassed;
    $scope.displayFieldFilter = displayFieldFilter;
    $scope.typeAheadFromAccountBlur = typeAheadFromAccountBlur;
    $scope.typeAheadToAccountBlur = typeAheadToAccountBlur;
    $scope.displayFromBalance = displayFromBalance;
    $scope.displayToBalance = displayToBalance;
    $scope.balanceDisplay = balanceDisplay;
    $scope.messageAsString = messageAsString;
    $scope.fromAccount = null;
    $scope.toAccount = null;
    $scope.isSaving = false;
    $scope.hasErrors = false;

    function displayFromBalance(loanTransaction) {
        return (
            loanTransaction != null &&
            loanTransaction.fromAccount != null &&
            loanTransaction.fromAccount.availableBalance != null
        );
    }

    function displayToBalance(loanTransaction) {
        return (
            loanTransaction != null &&
            loanTransaction.toAccount != null &&
            loanTransaction.toAccount.availableBalance != null
        );
    }

    function getFromAccounts() {
        return $scope.filteredFromAccounts || $scope.accountListFrom;
    }

    function getToAccounts() {
        return $scope.accountListTo;
    }

    function updateToAccounts(fromAccount, shouldRemoveInvalidAccounts) {
        if (angular.isObject(fromAccount)) {
            loadToAccounts(fromAccount.id).then(() => {
                removeInvalidToAccount(shouldRemoveInvalidAccounts);
            });
        } else {
            $scope.filteredToAccounts = null;
            removeInvalidToAccount(shouldRemoveInvalidAccounts);
        }
    }

    function updateFromAccounts(toAccount, shouldRemoveInvalidAccounts) {
        if (angular.isObject(toAccount)) {
            loadFromAccounts(toAccount.id).then(() => {
                removeInvalidFromAccount(shouldRemoveInvalidAccounts);
            });
            showPastDueModalIfDueDatePassed();
        } else {
            $scope.filteredFromAccounts = null;
            removeInvalidFromAccount(shouldRemoveInvalidAccounts);
        }
    }

    function clearErrors() {
        $scope.errorMessage = '';
        angular.forEach($scope.transaction.transactions, transaction => {
            transaction.balanceErrorMessage = '';
            transaction.fromAccountErrorMessage = '';
        });
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function calculateNextBusinessDay() {
        if ($scope.transaction === undefined) return;
        if ($scope.transaction.type !== 'manyToMany') {
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                .then(response => {
                    $scope.currentDate = formatDate(response);
                    $scope.transaction.transactions[0].transferDate = formatDate(response);
                });
        } else {
            utilityService
                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                .then(response => {
                    $scope.currentDate = formatDate(response);
                    angular.forEach($scope.transaction.transactions, transaction => {
                        if (
                            transaction.transferDate === null ||
                            transaction.transferDate === undefined
                        ) {
                            transaction.transferDate = formatDate(response);
                        }
                    });
                });
        }
    }

    function initializeTransaction() {
        $scope.transaction = {
            type: 'one',
            transactions: [
                {
                    fromAccount: null,
                    toAccount: null,
                    amount: null,
                    transferDate: $scope.currentDate,
                    memo: null,
                    paymentOptionType: '1',
                },
            ],
        };
    }

    $scope.restart = function () {
        initializeTransaction();
        initializeAccounts();
        $scope.wizardController.goToStep(0);
    };

    $scope.viewLoanPayments = function () {
        $state.go('payables.loan-payment-list');
    };

    $scope.reset = function () {
        clearErrors();
        $scope.filteredFromAccounts = null;
        $scope.filteredToAccounts = null;
        initializeTransaction();
        $scope.loans = {};

        calculateNextBusinessDay();
        $scope.form.$setPristine();
    };

    $scope.isNegative = function (value) {
        return value !== null && value !== undefined && (value[0] === '-' || value < 0);
    };

    $scope.gotoList = function () {
        $state.go('payables.loanPaymentList');
    };

    $scope.canReset = function () {
        return $scope.form.$dirty;
    };

    $scope.canReview = function () {
        return (
            !$scope.form.$invalid &&
            $scope.isValidAmounts() === true &&
            $scope.loanPaymentPastDue === false
        );
    };

    $scope.isReviewStep = function () {
        return $scope.wizardController.currentStep().name === 'Review';
    };

    $scope.isConfirmStep = function () {
        return $scope.wizardController.currentStep().name === 'Confirmation';
    };

    $scope.isConfirmButtonDisabled = function () {
        return $scope.isSaving;
    };

    $scope.review = function () {
        clearErrors();
        $scope.wizardController.goToNextStep();
    };

    $scope.confirm = function () {
        $scope.wizardController.goToNextStep();
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.isObject = function (value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    };

    $scope.isValidAmounts = function () {
        let result = true;
        if ($scope.transaction === undefined) return;

        if (
            $scope.transaction.transactions[0].fromAccount === null ||
            $scope.transaction.transactions[0].fromAccount === undefined ||
            typeof $scope.transaction.transactions[0].fromAccount !== 'object' ||
            $scope.transaction.transactions[0].toAccount === null ||
            $scope.transaction.transactions[0].toAccount === undefined ||
            typeof $scope.transaction.transactions[0].toAccount !== 'object' ||
            isNaN($scope.transaction.transactions[0].amount)
        ) {
            result = false;
        }

        return result;
    };

    $scope.changeEdit = function (item) {
        if (item.isEdit === undefined || item.isEdit === false) {
            item.isEdit = true;
        } else {
            item.isEdit = !item.isEdit;
        }
    };

    $scope.$watch('transaction.transactions[0].paymentOptionType', (newValue, oldValue) => {
        if (newValue !== oldValue && !!$scope.transaction.transactions[0].paymentOptionType) {
            $scope.transaction.transactions[0].paymentOptionType = newValue;
        }
    });

    function showPastDueModalIfDueDatePassed() {
        const dueDate = moment(
            $scope.transaction.transactions[0].toAccount.loanPaymentDueDate
        ).format('YYYY/MM/DD');
        const dateToday = moment($scope.currentDate).format('YYYY/MM/DD');
        if (
            $scope.transaction.transactions[0].toAccount.allowLoanPaymentPastDue === false &&
            dueDate < dateToday
        ) {
            $scope.loanPaymentPastDue = true;
            modalService.showModal(
                {},
                {
                    alertType: 'Error',
                    isAlert: true,
                    summaryText:
                        'Loan Payment cannot be completed at this time. Please contact your Financial Institution.',
                }
            );
        } else {
            $scope.loanPaymentPastDue = false;
        }
    }

    function showModalFromAccount(index, shouldUpdateToAccounts, removeInvalidAccounts) {
        showAccountModal(getFromAccounts(), false).result.then(selectedAccount => {
            $scope.form.$dirty = true;
            const transaction = $scope.transaction.transactions[index];
            transaction.fromAccount = selectedAccount;

            if (
                transaction.fromAccountErrorMessage != null &&
                transaction.fromAccountErrorMessage.length > 0
            ) {
                clearErrors();
                transaction.fromAccountErrorMessage = '';
            }

            if (shouldUpdateToAccounts) {
                updateToAccounts(selectedAccount, removeInvalidAccounts);
            }
        });
    }

    function showModalToAccount(index, shouldUpdateFromAccount, removeInvalidAccounts) {
        showAccountModal(getToAccounts(), true).result.then(selectedAccount => {
            $scope.form.$dirty = true;
            $scope.transaction.transactions[index].toAccount = selectedAccount;
            if (shouldUpdateFromAccount) {
                updateFromAccounts(selectedAccount, removeInvalidAccounts);
            }
        });
    }

    function showAccountModal(accounts, isDestinationAccounts) {
        return $uibModal.open({
            template: require('../../../../dashboard/widgets/views/transferAccountSelectionModal.html'),
            size: 'md',
            controller: 'AccountSelectionController',
            backdrop: 'static',
            resolve: {
                accountsList() {
                    return accounts;
                },
                showCurrentBalanceForLoanAccounts() {
                    return isDestinationAccounts;
                },
            },
        });
    }

    function assignErrorsToTransactions(response) {
        const responseTransactions = response.transactions;
        for (let i = 0; i < responseTransactions.length; i++) {
            $scope.transaction.transactions[i].balanceErrorMessage =
                responseTransactions[i].balanceErrorMessage;
            $scope.transaction.transactions[i].fromAccountErrorMessage =
                responseTransactions[i].fromAccountErrorMessage;
        }
    }

    $scope.save = function () {
        $scope.isSaving = true;
        $scope.hasErrors = false;
        const actionType = 'Create Transfer';

        securityService
            .verifyUser(actionType, $scope.transaction, () =>
                internalTransfersService.save($scope.transaction, 'loan')
            )
            .then(response => {
                angular.forEach(response.transactions, transaction => {
                    if (transaction.errorSummary !== null) {
                        $scope.hasErrors = true;
                    }
                });
                // Add summary message.
                if (
                    response.errorSummary !== null &&
                    response.errorSummary !== undefined &&
                    response.errorSummary.summaryMessage !== null &&
                    response.errorSummary.summaryMessage !== undefined &&
                    response.errorSummary.summaryMessage.length > 0
                ) {
                    displayErrors(response);
                } else {
                    $scope.transaction.batchId = response.batchId;
                    updateTransactionIds(response);
                    clearErrors();
                    getLoanTransferHeaderPanelText();
                    $scope.confirm();
                    $scope.filterModel = { batchId: response.batchId };
                    $scope.filteredFromAccounts = null;
                    $scope.filteredToAccounts = null;
                }
            })
            .finally(() => {
                $scope.isSaving = false;
            });
    };

    function assembleResponseErrors(response) {
        angular.forEach(response.transactions, transaction => {
            if (
                transaction.errorSummary !== null &&
                transaction.errorSummary !== undefined &&
                transaction.errorSummary.details !== null &&
                transaction.errorSummary.details !== undefined &&
                transaction.errorSummary.details.length > 0
            ) {
                angular.forEach(transaction.errorSummary.details, item => {
                    if (item.attributeName === fromAccountKey) {
                        messageAsString(item);
                    } else if (item.attributeName === 'balance') {
                        messageAsString(item);
                    }
                });
            }
        });
    }

    function messageAsString(item) {
        return item.messageList ? item.messageList.join(',') : item.message;
    }

    function displayErrors(response) {
        clearErrors();

        $scope.errorMessage = response.errorSummary.summaryMessage;

        assembleResponseErrors(response);
        assignErrorsToTransactions(response);

        $scope.wizardController.goToStep(0);
        $scope.form.$setDirty();
    }

    function updateTransactionIds(response) {
        const responseTransactions = response.transactions;
        for (let i = 0; i < responseTransactions.length; i++) {
            $scope.transaction.transactions[i].id = responseTransactions[i].id;
            $scope.transaction.transactions[i].transactionId =
                responseTransactions[i].transactionId;
            $scope.transaction.transactions[i].status = responseTransactions[i].status;
        }
    }

    function confirmCancel() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText: 'Are you sure you want to cancel the changes?',
            submit() {
                activityService.userInteractionAudit(
                    resourceType.PaymentResources,
                    auditCode.CancelCreateTransfer
                );
                $modalInstance.close();
                goBack();
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    $scope.cancel = function () {
        if (
            ($scope.form && $scope.form.$dirty) ||
            $scope.wizardController.currentStep().name === 'Review' ||
            !!$scope.errorMessage
        ) {
            confirmCancel();
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateTransfer
            );
            goBack();
        }
    };

    function goBack() {
        if ($rootScope.previousState.name === 'payables.loanPaymentList') {
            $state.go('payables.loanPaymentList');
        }
    }

    function loadFromAccounts(toAccountId) {
        return companyAccountsService
            .getCompanyAccountsForProductFeature(
                'From',
                getProductFeature(),
                'create',
                toAccountId,
                false
            )
            .then(response => {
                setAccountDisplayField(response);
                if (toAccountId) {
                    $scope.filteredFromAccounts = response;
                } else {
                    $scope.accountListFrom = response;
                }
            });
    }

    function loadToAccounts(fromAccountId) {
        return companyAccountsService
            .getCompanyAccountsForProductFeature(
                'To',
                getProductFeature(),
                'create',
                fromAccountId,
                true
            )
            .then(response => {
                setAccountDisplayField(response);
                if (fromAccountId) {
                    $scope.filteredToAccounts = response;
                } else {
                    $scope.accountListTo = response;
                }
            });
    }

    function setAccountDisplayField(response) {
        if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
            $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
        }
    }

    function getProductFeature() {
        return 'InternalTransfer';
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);

            calculateNextBusinessDay();
        });
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('InternalTransfer').then(response => {
            $scope.processingCutoff = response;
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableToday = true;

                if (timeout > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create loan transfer for today.');

                    for (var i = 0; i < $scope.transaction.transactions.length; i++) {
                        if (
                            moment($scope.transaction.transactions[i].transferDate).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            utilityService
                                .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                                .then(response => {
                                    $scope.transaction.transactions[i].transferDate =
                                        formatDate(response);
                                });
                        }
                    }
                } else {
                    calculateNextBusinessDay();
                }
            }, timeout);
        });
    }

    function init() {
        loadTemplate();

        initializeTransaction();
        if ($stateParams.amount != null) {
            $scope.transaction.transactions[0].amount = $stateParams.amount;
        }

        initializeAccounts();

        getHolidays();
        loadCutoffTimes();
        getLoanTransferHeaderPanelText();
    }

    function initializeAccounts() {
        const fromAccountIdParam = tryParseInt($stateParams.fromAccountId, 0);
        const toAccountIdParam = tryParseInt($stateParams.toAccountId, 0);

        loadFromAccounts().then(() => {
            angular.forEach($scope.accountListFrom, fromAccount => {
                if (fromAccount.id === fromAccountIdParam) {
                    $scope.transaction.transactions[0].fromAccount = fromAccount;
                }
            });
        });

        loadToAccounts().then(() => {
            angular.forEach($scope.accountListTo, toAccount => {
                if (toAccount.id === toAccountIdParam) {
                    $scope.transaction.transactions[0].toAccount = toAccount;
                }
            });
        });
    }

    function tryParseInt(stringValue, defaultValue) {
        const parsed = parseInt(stringValue);
        if (isNaN(parsed)) {
            return defaultValue;
        }
        return parsed;
    }

    function getLoanTransferHeaderPanelText() {
        $scope.wizardPanel.stepOneText = 'Create Payment';
        $scope.transferHeaderPanel.header = 'Loan Payment';
        $scope.transferHeaderPanel.createHeader = 'Create Loan Payment';
        $scope.transferHeaderPanel.confirmHeader = 'Loan Payment Confirmation';
        $scope.transferHeaderPanel.reviewHeader = 'Loan Payment Detail';
    }

    function loadTemplate() {
        const templates = internalTransfersService.getSelectedTemplates();
        if (templates !== null && templates !== undefined && templates.length > 0) {
            $scope.templateForTransfer = templates[0];
            internalTransfersService.setSelectedTemplates([]);
            getTransferTemplateDetails($scope.templateForTransfer.id, 'initiate');
        }
    }

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function displayFieldFilter(searchText) {
        return function (account, index, allAccounts) {
            return (
                account[$scope.accountDisplayField]
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
            );
        };
    }

    function typeAheadFromAccountBlur(account) {
        if (!angular.isObject(account)) {
            updateToAccounts();
        }
    }

    function typeAheadToAccountBlur(account) {
        if (!angular.isObject(account)) {
            updateFromAccounts();
        }
    }

    function removeInvalidFromAccount(shouldRemoveInvalidAccounts) {
        if (shouldRemoveInvalidAccounts) removeInvalidAccounts(getFromAccounts(), fromAccountKey);
    }

    function removeInvalidToAccount(shouldRemoveInvalidAccounts) {
        if (shouldRemoveInvalidAccounts) removeInvalidAccounts(getToAccounts(), toAccountKey);
    }

    function removeInvalidAccounts(availableAccounts, accountTypeKey) {
        angular.forEach($scope.transaction.transactions, transfer => {
            if (transfer[accountTypeKey] === undefined) return;
            const foundAccount = availableAccounts.find(
                account => account.id === transfer[accountTypeKey].id
            );
            if (foundAccount === undefined) {
                transfer[accountTypeKey] = null;
            }
        });
    }

    function balanceDisplay(balance) {
        return $filter('negativeParentheses')($filter('currency')(balance));
    }

    init();
}

CreateLoanTransferController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'toaster',
    '$timeout',
    '$uibModal',
    'companyAccountsService',
    'internalTransfersService',
    'utilityService',
    'modalService',
    'holidaysService',
    'securityService',
    '$rootScope',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'dateConstants',
    '$filter',
];
