/* eslint-disable lit/no-useless-template-literals */ // https://github.com/43081j/eslint-plugin-lit/issues/72

import { html } from 'lit';
import '@treasury/omega/components/omega-field.js';

export const recipientDetails = (record, close, editable) => html`
        <style>
            .omega-flex-form {
                display: flex;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }
            .form-column:not(:last-of-type) {
                padding-right: 10px;
            }
            .form-column.with-border:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }
            .omega-flex-form omega-field {
                margin: 10px;
                min-height: 32px;
            }
            .border-style {
                border-top: 3px solid #d3d6d9;
            }
            .fi-details {
                margin: 5px 0 0 5px;
                font-size: 16px;
                color: var(--omega-primary-lighten-100)
            }
            .addenda-dialog-trigger {
                border-style: none;
                background-color: transparent;
                color: var(--omega-primary);
                text-decoration: underline;
                cursor: pointer;
            }
        </style>
        <div class="omega-flex-form">
            <div class="form-column">
                <omega-field
                    field="streetAddress"
                    .record=${record}
                ></omega-field>
                <omega-field
                    field="city"
                    .record=${record}                   
                ></omega-field>
                <omega-field
                    field="state"
                    .record=${record}                  
                ></omega-field>
            </div>
            <div class="form-column">
                <omega-field
                    field="zipCode"
                    .record=${record}                   
                ></omega-field>
                <omega-field
                    field="country"
                    .record=${record}                    
                ></omega-field>
                <omega-field
                    field="idNumber"                   
                    .record=${record}
                ></omega-field>
            </div>
            <div class="form-column">
                <omega-field
                    field="accountType"
                    .record=${record}                   
                ></omega-field>
                <omega-field
                    field="accountNumber"
                    .record=${record}                   
                ></omega-field>
                <omega-field
                    field="iatTransactionType"
                    .record=${record}                   
                ></omega-field>
            </div>
            <div class="form-column">
                <omega-field
                    field="transactionType"
                    .record=${record}                    
                ></omega-field>
                <omega-field
                    field="amount"
                    .record=${record}                   
                ></omega-field>
                
                <button class="addenda-dialog-trigger" @click=${e =>
                    e.target.dispatchEvent(
                        new CustomEvent('openAddenda', {
                            detail: record,
                            bubbles: true,
                            composed: true,
                        })
                    )}>Addenda</button>
            </div>
        </div>
        <div class="border-style"></div>
        <div class="fi-details">Receiving Financial Institution Information</div>
        <div class="omega-flex-form">
            <div class="form-column">
                <omega-field
                    field="receivingBankName"
                    .record=${record}                  
                ></omega-field>
            </div>
            <div class="form-column">
                <omega-field
                    field="receivingBankType"
                    .record=${record}                   
                ></omega-field>
                </div>
                <div class="form-column">
                <omega-field
                    field="receivingBankNumber"
                    label="Bank Number"
                    .record=${record}                   
                ></omega-field>
                </div>
               <div class="form-column">
               <omega-field
                    field="receivingBankCountry"
                    .record=${record}                   
                ></omega-field>
               </div>
            </div>
        </div>
    `;
