InternalTransferUserLimitsController.$inject = [
    '$scope',
    'usersService',
    'toaster',
    'securityService',
];

export default function InternalTransferUserLimitsController(
    $scope,
    usersService,
    toaster,
    securityService
) {
    $scope.userLimits = null;
    $scope.userLimitsPristine = null;

    $scope.reset = reset;
    $scope.save = save;
    $scope.setForm = setForm;

    function init() {
        loadUserLimits();
    }

    function save() {
        $scope.userLimits.ProductType = 'InternalTransfer';

        securityService
            .verifyUser('Edit User', $scope.userLimits, () =>
                usersService.updateProductLimit($scope.id, $scope.userLimits)
            )
            .then(response => {
                if (response.success) {
                    $scope.userLimitsPristine = angular.copy($scope.userLimits);
                    $scope.$parent.notifySave();
                    $scope.form.$setPristine();
                    toaster.save('Internal Transfer User Limits');
                }
            });
    }

    function reset() {
        $scope.userLimits = angular.copy($scope.userLimitsPristine);
        $scope.form.$setPristine();
    }

    function setForm(form) {
        $scope.form = form;
    }

    // Private
    function loadUserLimits() {
        usersService.getProductLimit($scope.id, 'InternalTransfer').then(response => {
            $scope.userLimits = response;
            $scope.userLimitsPristine = angular.copy($scope.userLimits);

            if (!$scope.userLimits.pendingUserProductLimit.approvalLimit) {
                $scope.userLimits.pendingUserProductLimit.approvalLimit =
                    $scope.userLimits.userProductLimit.approvalLimit;
            }

            if (!$scope.userLimits.pendingUserProductLimit.initiationLimit) {
                $scope.userLimits.pendingUserProductLimit.initiationLimit =
                    $scope.userLimits.userProductLimit.initiationLimit;
            }
        });
    }

    init();
}
