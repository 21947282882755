import { Record as FdlRecord, FieldType, boolean, number, string } from '@treasury/FDL';
import { FdlFieldDefinitions, Record } from '@treasury/FDL/record';
import {
    CompanyAccountModelDto,
    WireCompanyModelDto,
    WireTemplateModelDto,
} from '@treasury/api/channel';
import { WireTemplate } from '@treasury/domain/channel/mappings/wires';
import { WireFileActivity } from '@treasury/domain/channel/mappings/wires/wire-file-activity.entity';
import { WiresService } from '@treasury/domain/channel/services';
import { WireConfigurationDto, WireTemplateEntryType } from '@treasury/domain/channel/types';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import { FullNonNullable } from '@treasury/utils';
import { html } from 'lit';
import '../../components/channel-status-badge.js';

const systemFile = 'SYSTEM';
const minDate = '0001-01-01T00:00:00';

const isProcessedDateBlank = (record: FdlRecord<WireFileActivity>) => {
    const processedDate = record.getField('processedDate');
    return !processedDate || processedDate === '' || processedDate === minDate;
};

const makeFileNamePlainText = (isWireSftpEnabled: boolean, record: FdlRecord<WireFileActivity>) => {
    // If the source is anything other than system, it is always a link.
    if (record.getField('uploadedBy').toUpperCase() !== systemFile) return false;
    if (!isWireSftpEnabled) return false; // Recognize the feature flag.
    return isProcessedDateBlank(record);
};

const addAction = (label: string, action: string, isWireSftpEnabled: boolean) => ({
    label,
    action,
    visibleWhen: (record: FdlRecord<WireFileActivity>) =>
        isWireSftpEnabled &&
        record.getField('isConfirmed') !== true &&
        record.getField('uploadedBy').toUpperCase() === systemFile &&
        isProcessedDateBlank(record) &&
        !record.getField('isExpired'),
});

const isReviewColumnVisible = (isWireSftpEnabled: boolean, record: FdlRecord<WireFileActivity>) => {
    if (record.getField('uploadedBy').toUpperCase() !== systemFile) return true;
    return !!(isWireSftpEnabled && !isProcessedDateBlank(record));
};

const getFileActivityActions = (isWireSftpEnabled: boolean) => [
    {
        label: 'Review',
        action: 'review',
        visibleWhen: (record: FdlRecord<WireFileActivity>) =>
            isReviewColumnVisible(isWireSftpEnabled, record),
    },
    {
        label: 'View File',
        action: 'view',
        visibleWhen: (record: FdlRecord<WireFileActivity>) =>
            isWireSftpEnabled && record.getField('uploadedBy').toUpperCase() === systemFile,
    },
    addAction('Process File', 'process', isWireSftpEnabled),
    addAction('Delete File', 'delete', isWireSftpEnabled),
];

export function createFields(
    service: WiresService
): FdlFieldDefinitions<FullNonNullable<WireTemplate>> {
    return {
        isChecked: boolean.with.maxColumnWidth(30),
        name: string.thatIs.readOnly(),
        wireCompany: new FieldType<WireCompanyModelDto>().thatIs
            .readOnly()
            .with.template((value, record) => record.values.wireCompany.name)
            .thatHas.compareFunction((a, b) => a.name.localeCompare(b.name)),
        type: (string as FieldType<WireTemplateEntryType>).thatIs
            .readOnly()
            .with.template((value, record) => {
                let type;
                if (record.getField('isInternational')) {
                    type = 'International';
                } else {
                    type = 'Domestic';
                }
                return type;
            }),
        debitAccount: new FieldType<CompanyAccountModelDto>().thatIs
            .readOnly()
            .with.template((value, record) => record.values.debitAccount.accountDisplayLabel)
            .thatHas.compareFunction((a, b) => {
                if (a.accountDisplayLabel === b.accountDisplayLabel) {
                    return 0;
                }
                return a.accountDisplayLabel < b.accountDisplayLabel ? -1 : 1;
            }),
        beneficiaryName: string.thatIs
            .readOnly()
            .with.template((value, record) => record.values.beneficiary.name),
        status: string.thatIs.readOnly().with.template(
            (statusValue, record) =>
                html`<channel-status-badge
                    .fetchApproversFunction=${async () =>
                        service.fetchWireTemplateApprovers({
                            templateId: record.getField('id'),
                            createdBy: record.getField('createdBy'),
                        })}
                    .status=${statusValue}
                    type="tracker"
                    .approvalsNeeded=${record.getField('numberOfApprovalsNeeded')}
                    .approvalsCount=${record.getField('completedApprovalCount')}
                ></channel-status-badge>`
        ),
        numberOfApprovalsNeeded: number.thatIs.readOnly().thatIs.visibleWhen(() => false),
        completedApprovalCount: number.thatIs.readOnly().thatIs.visibleWhen(() => false),
        id: number.thatIs.readOnly().thatIs.visibleWhen(() => false),
        createdBy: number.thatIs.readOnly().thatIs.visibleWhen(() => false),
    };
}

export const fileActivityFields = (
    isWireSftpEnabled: boolean
): FdlFieldDefinitions<WireFileActivity> => ({
    fileName: string.thatIs
        .readOnly()
        .thatIs.disabledWhen((record: Record<WireFileActivity>) =>
            makeFileNamePlainText(isWireSftpEnabled, record)
        ),
    fileSize: number.thatIs.readOnly().with.maxColumnWidth(100),
    isValid: boolean.thatIs.readOnly().with.maxColumnWidth(20),
    uploadedBy: string.thatIs.readOnly().with.maxColumnWidth(100),
    uploadDate: string.thatIs
        .readOnly()
        .with.maxColumnWidth(100)
        .with.template(value => {
            if (!value) return '';
            return new Date(value).toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }),
    processedBy: string.thatIs.readOnly().with.maxColumnWidth(100),
    processedDate: string.thatIs
        .readOnly()
        .with.maxColumnWidth(100)
        .with.template(value => {
            if (!value || value === minDate) return '';
            return new Date(value).toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        })
        .with.compareFunction((a, b) => {
            // Note: We needed to add this compare function because the default compare function
            // didn't handle the logic of needing unprocessed files first, but then descending
            // by date.
            if (a === b) return 0;
            if (!a) return 1;
            if (!b) return -1;
            const difference = new Date(a).getTime() - new Date(b).getTime();
            if (difference <= -1) return -1;
            if (difference >= 1) return 1;
            return 0;
        }),
    amount: number.thatIs
        .readOnly()
        .with.maxColumnWidth(100)
        .with.template(
            (value, record) =>
                html`<div>
                    (${record.getField('totalWireCount')})
                    ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                        record.getField('amount')
                    )}
                </div>`
        ),
    approvedCount: number.thatIs.readOnly().with.maxColumnWidth(100),
    pendingCount: number.thatIs.readOnly().with.maxColumnWidth(100),
    rejectedCount: number.thatIs.readOnly().with.maxColumnWidth(100),
    expiredCount: number.thatIs.readOnly().with.maxColumnWidth(100),
});

const internationalWireColumns = [
    {
        field: 'isChecked',
        type: OmegaColumnType.CheckBox,
        label: '',
    },
    {
        field: 'name',
        label: 'Template Name',
        type: OmegaColumnType.Command,
        action: 'view',
    },
    {
        field: 'type',
        label: 'Wire Type',
    },
    {
        field: 'wireCompany',
        label: 'Wire Company',
    },
    {
        field: 'debitAccount',
        label: 'Debit Account',
    },
    {
        field: 'beneficiaryName',
        label: 'Beneficiary Name',
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        label: 'Actions',
        type: OmegaColumnType.Actions,
        actions: [
            {
                label: 'Approve',
                action: 'approve',
                visibleWhen: (record: FdlRecord<WireTemplateModelDto>) =>
                    !!record.values.permissions![1] && record.values.status === 'Pending Approval',
            },
            {
                label: 'Reject',
                action: 'reject',
                visibleWhen: (record: FdlRecord<WireTemplateModelDto>) =>
                    !!record.values.permissions![1] && record.values.status === 'Pending Approval',
            },
            {
                label: 'Initiate',
                action: 'initiate',
                visibleWhen: (record: FdlRecord<WireTemplateModelDto>) =>
                    record.values.status === 'Ready',
            },
            {
                label: 'View',
                action: 'view',
            },
            {
                label: 'Edit',
                action: 'edit',
                visibleWhen: (record: FdlRecord<WireTemplateModelDto>) =>
                    !!(
                        record.values.status === 'Ready' ||
                        record.values.status === 'Approval Rejected'
                    ),
            },
            {
                label: 'Delete',
                action: 'delete',
            },
        ],
    },
];

export const fileActivityColumns = (
    isWireSftpEnabled: boolean
): OmegaColumnDefinition<WireFileActivity>[] => {
    const columns: OmegaColumnDefinition<WireFileActivity>[] = [
        {
            field: 'isValid',
            label: '',
            type: OmegaColumnType.Icon,
            icon: 'exclamation-triangle',
            action: 'process',
            tooltip: 'File errors found. Click to view errors.',
            visibleWhen: (record: FdlRecord<WireFileActivity>) =>
                (isWireSftpEnabled && !record.getField('isValid')) || false,
        },
        {
            field: 'fileName',
            label: 'File Name',
            type: OmegaColumnType.Command,
            action: 'review',
        },
        {
            field: 'fileSize',
            label: 'File Size',
        },
        {
            field: 'uploadedBy',
            label: 'Source',
        },
        {
            field: 'uploadDate',
            label: 'Received Date',
        },
        {
            field: 'processedBy',
            label: 'Processed By',
        },
        {
            field: 'processedDate',
            label: 'Processed Date',
            sortable: false,
        },
        {
            field: 'amount',
            label: 'Total Wires/Amount',
        },
        {
            field: 'approvedCount',
            label: 'Approved/Submitted',
        },
        {
            field: 'pendingCount',
            label: 'Pending',
        },
        {
            field: 'rejectedCount',
            label: 'Rejected',
        },
        {
            field: 'expiredCount',
            label: 'Expired',
        },
        {
            label: 'Actions',
            type: OmegaColumnType.Actions,
            actions: getFileActivityActions(isWireSftpEnabled),
        },
    ];
    return columns.filter(column => {
        if (!isWireSftpEnabled && column.field === 'processedBy') return false;
        return !(!isWireSftpEnabled && column.field === 'processedDate');
    });
};

export const isConfiguredForInternationalWires = (wireConfiguration: WireConfigurationDto) =>
    wireConfiguration.fiWireProductEnabled &&
    wireConfiguration.fiInternationalUsdEnabled &&
    wireConfiguration.companyWireProductEnabled &&
    wireConfiguration.companyInternationalUsdEnabled;

export const createColumns = (wireConfigurations: WireConfigurationDto) => {
    if (isConfiguredForInternationalWires(wireConfigurations)) {
        return internationalWireColumns;
    }
    return internationalWireColumns.filter(column => column.field !== 'type');
};
