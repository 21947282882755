export default () => ({
    restrict: 'E',
    replace: false,
    scope: {
        type: '@',
    },
    transclude: true,
    template: require('./alertTemplate.html'),
    controller: AlertController,
});

AlertController.$inject = ['$scope'];

function AlertController($scope) {
    $scope.getFontAwesomeClassName = function () {
        return {
            info: 'fa-info-circle',
            success: 'fa-check-circle',
            warning: 'fa-exclamation-triangle',
            error: 'fa-exclamation-triangle',
        }[$scope.type || 'info'];
    };
}
