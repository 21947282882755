// eslint-disable-next-line import/no-extraneous-dependencies
import FieldType from '@treasury/FDL/field-type';
import { datePicker, string } from '../primitives';
import IsStartDateWithinAMonthOfEndDate from '../lib/validators/is-start-date-within-a-month-of-end-date.js';

export const nocChangeCodes = new FieldType().with.defaultValue('');

export const nocDatePicker = datePicker.with
    .range()
    .thatIs.required()
    .with.parseDynamicRange()
    .with.validator(IsStartDateWithinAMonthOfEndDate);

export const nocCompanyIds = string.thatIs.required().with.validator({
    name: '1to11CompaniesSelected',
    validate: v => v.length > 0 && v.length <= 11,
});
