/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { CheckImageModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class CheckimagesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags CheckImages
     * @name CheckImagesGetCheckImage
     * @summary Gets the check image.
     * @request GET:/checkimages/check
     */
    checkImagesGetCheckImage = (
        query: {
            /** The check image number. */
            checkImageNumber: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CheckImageModelDto, any>({
            path: `/checkimages/check`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CheckImages
     * @name CheckImagesGetDepositSet
     * @summary Gets the deposit set.
     * @request GET:/checkimages/deposit
     */
    checkImagesGetDepositSet = (
        query: {
            /** The deposit image number. */
            depositImageNumber: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CheckImageModelDto[], any>({
            path: `/checkimages/deposit`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
