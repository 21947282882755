import { WireFormatRequests } from '../../requests/wires/wire-upload-formats.js';
import {
    mapRequestToUploadFormatParameters,
    mapResponseToGlossaryAndTiles,
    mapResponseToWireUploadFormat,
} from '../../mappings/wires/wire-upload-formats.js';

// eslint-disable-next-line @treasury/filename-match-export
export default class WireUploadFormatsService {
    static async getFormats() {
        return WireFormatRequests.getFormats();
    }

    static async getFormat(params, fields) {
        const response = await WireFormatRequests.getFormat(params);
        return mapResponseToWireUploadFormat(response, fields);
    }

    static async saveFormat(params) {
        const format = mapRequestToUploadFormatParameters(params);
        return WireFormatRequests.saveFormat(format);
    }

    static async deleteFormat(params) {
        return WireFormatRequests.deleteFormat(params);
    }

    static async getFormatFields() {
        const response = await WireFormatRequests.getFormatFields();
        return mapResponseToGlossaryAndTiles(response);
    }
}
