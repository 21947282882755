/* eslint-disable @treasury/consistent-custom-element-name */
/* eslint-disable @treasury/filename-match-export */
/* eslint-disable @treasury/style-includes-host-display */

import { Recordset } from '@treasury/FDL';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../components/blocking-loader.js';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';
import './ach-notice-of-change-detail-row-v2.js';
import { AchNoticeOfChangeActivityClient } from './clients/ach-notice-of-change-client.js';
import { reportColumns } from './data/v2/ach-notice-of-change-columns.js';
import { reportFields } from './data/v2/ach-notice-of-change-fields.js';
import { reportFilters } from './data/v2/ach-notice-of-change-filters.js';

export class AchNoticeOfChangeContainerV2 extends mix(LitElement).with(
    ListeningElementMixin,
    channelAlertMixin
) {
    static get properties() {
        return {
            institution: String,
            filterRecord: Array,
            reportFilters: Array,
            reportFields: Array,
            reportColumns: Array,
            reportActions: Array,
            recordset: Object,
            defaultPerPage: Number,
            landingPageMessage: String,
            alert: Object,
            showBlockingLoader: Boolean,
            downloading: Boolean,
            client: Object,
        };
    }

    constructor() {
        super();
        this.reportFields = reportFields;
        this.columns = reportColumns;
        this.client = new AchNoticeOfChangeActivityClient();
        this.defaultPerPage = 25;
        this.landingPageMessage = 'No Notification of Changes Found';
        this.showBlockingLoader = false;
        this.downloading = false;
        this.reportTitle = 'ACH Notification of Change Activity';
        this.reportInformation = html`<div style="width:335px">
            ACH Notification of Change Activity <br />
            Includes all notification of change activity for the current day. Utilize the search
            feature to review notification of change activity.
        </div>`;
        this.itemLabel = {
            singular: 'notification',
        };
        this.typeToFiltered = false;
    }

    get institution() {
        return this._institution;
    }

    set institution(value) {
        this._institution = value;
    }

    async _fullPagePrint() {
        this.client.print('achNoticeOfChangeFullPage');
    }

    async _detailPrint(e) {
        this.client.print('achNoticeOfChangeDetail', e.detail);
    }

    async firstUpdated() {
        this.reportFilters = reportFilters;
        this.recordset = await new Recordset(this.reportFields, this.client.searchV2);
        this.recordset.sortColumns = [{ field: 'receivedDate', sort: 'descending' }];
        this.recordset.pageSize = this.defaultPerPage;
        this.setReturnVersionPath();
        this.listenTo(this, 'toggle-detail-row', () => {
            if (!this.typeToFiltered) {
                this.client.saveUserActivity(2, 'Viewed ACH Notification of Change Details');
            }
        });
        const achCompanies = await this.client.getCompanies();
        this.achCompanyTotal = achCompanies.length;
    }

    async setReturnVersionPath() {
        const returnV2FeatureFlagEnabled = await FeatureFlagService.isEnabled(
            Feature.AchReturnReviewNacha
        );
        const returnVersionPath = returnV2FeatureFlagEnabled ? '' : '/v1';
        this.reportActions = [
            {
                url: `${this.institution}/ach/ach-return-activity${returnVersionPath}`,
                title: 'ACH Return Activity ',
            },
        ];
    }

    async typeToFilterRecordExpansion() {
        await this.recordset.debouncedUpdate();
        if (this.recordset.filter.length > 0) {
            this.typeToFiltered = true;
            this.recordset.records.forEach(record => {
                record.isExpanded = true;
            });
        } else if (this.typeToFiltered) {
            this.recordset.records.forEach(record => {
                record.isExpanded = false;
            });
            this.typeToFiltered = false;
        }
    }

    async handleDownload(e) {
        this.downloading = true;
        try {
            await this.client.downloadNoticeReport(e.detail);
        } catch (error) {
            console.log(error);
            this.setAlertFromError(error);
        } finally {
            this.downloading = false;
        }
    }

    async _saveNotice({ detail }) {
        try {
            this.showBlockingLoader = true;
            await this.client.downloadNoticeV2(detail);
        } catch (err) {
            this.setAlertFromError(err);
        } finally {
            this.showBlockingLoader = false;
        }
    }

    renderBlockingLoader() {
        if (this.showBlockingLoader || this.downloading)
            return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    renderDetail(record, close) {
        return html`<ach-notice-of-change-detail-row-v2
            .parentRecord=${record}
            .closeFunction=${close}
            @detailPrint=${this._detailPrint}
            @saveNotice=${e => this._saveNotice(e)}
        ></ach-notice-of-change-detail-row-v2>`;
    }

    render() {
        if (!this.recordset) {
            return nothing;
        }
        return html`
            ${this.renderAlert()} ${this.renderBlockingLoader()}
            <omega-report
                flyout
                autostart
                .title=${this.reportTitle}
                .recordset=${this.recordset}
                .reportInformation=${this.reportInformation}
                .itemLabel=${this.itemLabel}
                displayToggleAll
                .reportLinks=${this.reportActions}
                .options=${['print', 'download']}
                .downloadOptions=${[
                    'PDF Summary',
                    'PDF Detail',
                    'CSV Summary',
                    'CSV Detail',
                    'NACHA',
                ]}
                @reportDownload=${this.handleDownload}
                .localFilters=${this.localFilters}
                .filters=${this.reportFilters}
                .columns=${this.columns}
                .detailFunction=${(record, close) => this.renderDetail(record, close)}
                @pagePrint=${this._fullPagePrint}
                @detailPrint=${this._detailPrint}
                .rowsPerPage=${this.defaultPerPage}
                .landingPageMessage=${this.landingPageMessage}
                ><omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    class="no-print"
                    .recordset=${this.recordset}
                    .itemLabel=${this.itemLabel}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                        this.typeToFilterRecordExpansion();
                    }}
                ></omega-filter-bar
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
            }
            #type-to-filter {
                padding: 0 10px;
            }
            @media print {
                .no-print {
                    display: none;
                }
            }
        `;
    }
}

export default AchNoticeOfChangeContainerV2;
window.customElements.define('ach-notice-of-change-container-v2', AchNoticeOfChangeContainerV2);
