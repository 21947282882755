import { html, nothing } from 'lit';

function selectAll(input) {
    if (!input.value) return;
    this.setSelectionRange(0, input.value.length);
}

function numeralsOnly(s) {
    return s.replace(/\D/g, '');
}

function onlyAllowNumerals(event) {
    if (event.ctrlKey || event.altKey || event.metaKey) return;
    if (event.key.length === 1 && /\D/.test(event.key)) event.preventDefault();
}

function renderErrorMessage(context) {
    if (!context.errorMessage) return nothing;

    return html`<omega-alert type="error" isVisible>${context.errorMessage}</omega-alert>`;
}

function renderUseSameNumberCheckbox(context) {
    if (context.verificationMethod.other.isVerified) return nothing;
    return html`<div class="checkbox-container">
        <input
            type="checkbox"
            id="use-for-both"
            @input=${e => {
                context.useForBoth = e.target.checked;
            }}
        />
        <label for="use-for-both"
            >Use same number for ${context.verificationMethod.other.plural}</label
        >
    </div>`;
}

export default {
    title: 'Add Phone Number',

    renderBody(context) {
        return html` <style>
                fieldset {
                    border: none;
                    padding: 15px 0;
                }
                /* hide legend without disabling it for screen readers */
                legend {
                    width: 0;
                    height: 0;
                    overflow: hidden;
                }
                p {
                    margin: 0px 0px 18px;
                }

                input {
                    font-size: 16px;
                    font-family: inherit;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    padding: 8px;
                }
                .checkbox-container {
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                }
                .visible-label {
                    margin-right: 12px;
                }
            </style>

            ${renderErrorMessage(context)}
            <p>Receive ${context.verificationMethod.singular} and reply to verify your identity.</p>
            <fieldset>
                <legend>Phone Number:</legend>
                <span class="visible-label">Phone Number:</span>
                <input
                    type="text"
                    size="3"
                    maxlength="3"
                    aria-label="area code"
                    .value=${numeralsOnly(context.phoneNumber.areaCode)}
                    @input=${e => {
                        context.phoneNumber = context.phoneNumber.withAreaCode(e.target.value);
                    }}
                    @keydown=${onlyAllowNumerals}
                    @focus=${selectAll}
                />
                -
                <input
                    type="text"
                    size="3"
                    maxlength="3"
                    aria-label="telephone prefix"
                    .value=${numeralsOnly(context.phoneNumber.prefix)}
                    @input=${e => {
                        context.phoneNumber = context.phoneNumber.withPrefix(e.target.value);
                    }}
                    @keydown=${onlyAllowNumerals}
                    @focus=${selectAll}
                />
                -
                <input
                    type="text"
                    size="4"
                    maxlength="4"
                    aria-label="line number"
                    .value=${numeralsOnly(context.phoneNumber.lineNumber)}
                    @input=${e => {
                        context.phoneNumber = context.phoneNumber.withLineNumber(e.target.value);
                    }}
                    @keydown=${onlyAllowNumerals}
                    @focus=${selectAll}
                />
            </fieldset>

            ${renderUseSameNumberCheckbox(context)}`;
    },

    renderActions(context) {
        const label = context.errorMessage
            ? context.verificationMethod.retryLabel
            : 'Verify Number';

        return html`<omega-button
                type="primary"
                .loading=${context.isLoading}
                .disabled=${!context.phoneNumber?.isValid}
                @click=${context.sendOneTimePassword}
                >${label}</omega-button
            >
            <omega-button type="link" @click=${() => context.close()}>Cancel</omega-button>`;
    },
};
