StpListController.$inject = [
    '$scope',
    '$uibModal',
    'statesService',
    '$state',
    '$filter',
    'stpService',
    'toaster',
    '$stateParams',
    'activityService',
    'resourceType',
    'auditCode',
];

export default function StpListController(
    $scope,
    $uibModal,
    statesService,
    $state,
    $filter,
    stpService,
    toaster,
    $stateParams,
    activityService,
    resourceType,
    auditCode
) {
    $scope.filterObj = {};
    $scope.filteredStps = [];
    $scope.form = {};
    $scope.searchObj = {
        accounts: [],
        checkNumberType: 'All',
        checkAmountType: 'Specific Amount',
        checkDateType: 'Specific Date',
        createdDateType: 'Specific Date',
        statuses: [],
    };

    $scope.searchOptions = {
        checkNumberOptions: ['All', 'Specific Check Number', 'Check Range'],
        checkAmountOptions: ['Specific Amount', 'Amount Range'],
        createdDateOptions: ['Specific Date', 'Date Range'],
        checkDateOptions: ['Specific Date', 'Date Range'],
        statusOptions: [
            { name: 'PendingApproval', display: 'Pending Approval' },
            { name: 'ApprovalRejected', display: 'Approval Rejected' },
            { name: 'Cancelled', display: 'Cancelled' },
            { name: 'Active', display: 'Active' },
            { name: 'Expired', display: 'Expired' },
        ],
    };

    $scope.selectionObj = {
        allItemsAreSelected: false,
        selectionList: [],
    };
    $scope.stopPayments = null;

    $scope.approve = approve;
    $scope.batchUpdate = batchUpdate;
    $scope.canApproveOrReject = canApproveOrReject;
    $scope.canApprove = canApprove;
    $scope.canCancel = canCancel;
    $scope.canReject = canReject;
    $scope.cancel = cancel;
    $scope.checkAmountRange = checkAmountRange;
    $scope.checkNumberRange = checkNumberRange;
    $scope.createStp = createStp;
    $scope.filter = filter;
    $scope.getCheckNumbers = getCheckNumbers;
    $scope.itemIsSelected = itemIsSelected;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.hideDetails = hideDetails;
    $scope.reject = reject;
    $scope.reset = reset;
    $scope.search = search;
    $scope.selectOrDeselectAll = selectOrDeselectAll;
    $scope.setForm = setForm;
    $scope.showDetails = showDetails;
    $scope.updateSelectionList = updateSelectionList;
    $scope.disableDownload = true;

    function setApprovalAttributes() {
        $scope.stopPayments.map(stp => {
            stp.approvalAttributes = {
                approvalEntity: 'stopPayment',
                approvalCount: stp.completedApprovalCount,
                amount: stp.amount,
                createdBy: stp.createdBy,
                updatedBy: stp.updatedBy,
                productId: stp.id,
            };
            return stp;
        });
    }

    function getCheckNumbers(stopPayment) {
        if (!!stopPayment.checkNumbers && stopPayment.checkNumbers.length > 0) {
            if (stopPayment.checkNumbers.length === 1) {
                return stopPayment.checkNumbers[0];
            }
            return `${stopPayment.checkNumbers[0]}-${stopPayment.checkNumbers[1]}`;
        }
    }

    function checkAmountRange() {
        if ($scope.searchObj.checkAmountType === 'Amount Range') {
            const isValid1 =
                $scope.searchObj.checkAmountStart < $scope.searchObj.checkAmountEnd ||
                $scope.searchObj.checkAmountEnd === '';
            const isValid2 =
                $scope.searchObj.checkAmountEnd > $scope.searchObj.checkAmountStart ||
                $scope.searchObj.checkAmountStart === '';
            $scope.form.checkAmountStart.$setValidity('doesNotMatch', isValid1);
            $scope.form.checkAmountEnd.$setValidity('doesNotMatch', isValid2);
        }
    }

    function checkNumberRange() {
        if ($scope.searchObj.checkNumberType === 'Check Range') {
            const isValid1 =
                parseInt($scope.searchObj.checkNumberStart, 10) <
                    parseInt($scope.searchObj.checkNumberEnd, 10) ||
                $scope.searchObj.checkNumberEnd === '';
            const isValid2 =
                parseInt($scope.searchObj.checkNumberEnd, 10) >
                    parseInt($scope.searchObj.checkNumberStart, 10) ||
                $scope.searchObj.checkNumberStart === '';
            $scope.form.checkNumberStart.$setValidity('doesNotMatch', isValid1);
            $scope.form.checkNumberEnd.$setValidity('doesNotMatch', isValid2);
        }
    }

    function itemIsSelected() {
        return (
            $scope.selectionObj &&
            $scope.selectionObj.selectionList &&
            $scope.selectionObj.selectionList.length > 0
        );
    }

    function canApproveOrReject(stopPayment) {
        if (
            !hasAccessPermissions(stopPayment, 'Approve') ||
            stopPayment.status !== 'Pending Approval'
        ) {
            return false;
        }
        return true;
    }

    function canCancel(stopPayment) {
        if (!hasAccessPermissions(stopPayment, 'Cancel')) {
            return false;
        }
        return true;
    }

    function hasAccessPermissions(stp, permissionType) {
        let result = false;
        angular.forEach(stp.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    function selectOrDeselectAll() {
        angular.forEach($scope.filteredStps, stp => {
            if (hasAccessPermissions(stp, 'Approve') && stp.status === 'Pending Approval') {
                stp.isSelected = $scope.selectionObj.allItemsAreSelected;
                if ($scope.selectionObj.allItemsAreSelected === true) {
                    $scope.selectionObj.selectionList.push(stp);
                } else {
                    $scope.selectionObj.selectionList = [];
                }
            }
        });
    }

    function updateSelectionList(stopPayment) {
        const count = 0;
        let allSelected = true;

        if (stopPayment.isSelected === true) {
            $scope.selectionObj.selectionList.push(stopPayment);
        } else if (stopPayment.isSelected === false) {
            $scope.selectionObj.selectionList.splice($scope.selectionList.indexOf(stopPayment), 1);
        }

        angular.forEach($scope.filteredStps, stp => {
            if (stp.status === 'Pending Approval') {
                if (!stp.isSelected) {
                    allSelected = false;
                }
            }
        });

        if (allSelected) {
            $scope.selectionObj.allItemsAreSelected.value = true;
        } else {
            $scope.selectionObj.allItemsAreSelected.value = false;
        }
    }

    function canApprove(stopPayment) {
        return (
            hasAccessPermissions(stopPayment, 'Approve') &&
            stopPayment.status === 'Pending Approval'
        );
    }

    function canReject(stopPayment) {
        return (
            hasAccessPermissions(stopPayment, 'Approve') &&
            stopPayment.status === 'Pending Approval'
        );
    }

    function createStp() {
        $state.go('payables.stp.create');
    }

    function showDetails(stopPayment) {
        // TODO - Include stopPayment.checkNumber when possible
        activityService.userInteractionAudit(
            resourceType.PaymentResources,
            auditCode.AccessStopPaymentDetail
        );
        stopPayment.expanded = true;
    }

    function hideDetails(stopPayment) {
        stopPayment.expanded = false;
    }

    function filter() {
        $scope.filterObj.text = $scope.filterObj.text ? $scope.filterObj.text : '';
        $scope.filteredStps = $filter('filter')($scope.stopPayments, $scope.filterObj.text);
    }

    (function () {
        // init
        loadStpAccounts();
        loadStopPayments();

        angular.forEach($scope.searchOptions.statusOptions, status => {
            status.isChecked = true;
        });
    })();

    function reset() {
        $scope.searchObj = {
            accounts: [],
            checkNumberType: 'All',
            checkAmountType: 'Specific Amount',
            checkDateType: 'Specific Date',
            createdDateType: 'Specific Date',
            statuses: [],
        };

        angular.forEach($scope.searchOptions.statusOptions, statusOption => {
            statusOption.isChecked = true;
            $scope.searchObj.statuses.push(statusOption);
        });
        angular.forEach($scope.searchOptions.accounts, account => {
            account.isChecked = true;
            $scope.searchObj.accounts.push(account);
        });
    }

    $scope.resetValues = function (type) {
        if (type === 'checknumber') {
            $scope.searchObj.checkNumber = null;
            $scope.searchObj.checkNumberStart = null;
            $scope.searchObj.checkNumberEnd = null;
        } else if (type === 'checkamount') {
            $scope.searchObj.checkAmount = null;
            $scope.searchObj.checkAmountStart = null;
            $scope.searchObj.checkAmountEnd = null;
        } else if (type === 'createddate') {
            $scope.searchObj.createdDate = null;
            $scope.searchObj.createdDateStart = null;
            $scope.searchObj.createdDateEnd = null;
        } else if (type === 'checkdate') {
            $scope.searchObj.checkDate = null;
            $scope.searchObj.checkDateStart = null;
            $scope.searchObj.checkDateEnd = null;
        }
    };

    function search() {
        stpService.search($scope.searchObj).then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.stopPayments = response;
            if ($scope.stopPayments.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
            setApprovalAttributes();
            filter();
        });
    }

    function loadStpAccounts() {
        stpService.getFilterAccounts().then(response => {
            if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
                $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }
            $scope.searchOptions.accounts = response;
            $scope.searchOptions.accounts = $scope.searchOptions.accounts.sort(
                (a, b) => a.number - b.number
            );
            if (!$stateParams.accountNumber) {
                angular.forEach($scope.searchOptions.accounts, account => {
                    account.isChecked = true;
                });
            } else if ($stateParams.accountNumber) {
                angular.forEach($scope.searchOptions.accounts, account => {
                    if (account.number == $stateParams.accountNumber) {
                        account.isChecked = true;
                    } else {
                        account.isChecked = false;
                    }
                });
            }
        });
    }

    function loadStopPayments() {
        stpService.getAll($scope.searchObj).then(response => {
            $scope.stopPayments = response;
            setApprovalAttributes();
            if ($scope.stopPayments.length === 1) {
                angular.forEach($scope.stopPayments, stp => {
                    stp.expanded = true;
                });
            }
            angular.forEach($scope.stopPayments, stp => {
                if (stp.checkNumbers.length > 1) {
                    stp.checkNumber = getRangeOfCheckNumbers(stp.checkNumbers);
                } else if (stp.checkNumbers.length === 1) {
                    stp.checkNumber = stp.checkNumbers[0];
                }
            });
            $scope.filteredStps = angular.copy($scope.stopPayments);
            if ($scope.stopPayments === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
        });
    }

    function getRangeOfCheckNumbers(checkNumbers) {
        const min = Math.min.apply(null, checkNumbers);
        const max = Math.max.apply(null, checkNumbers);
        return `${min}-${max}`;
    }

    function setForm(form) {
        $scope.form = form;
    }

    function batchUpdate(type) {
        const list = $scope.selectionList;
        let batchPayload = {
            lookups: [],
        };
        angular.forEach($scope.filteredStps, stp => {
            if (
                stp.isSelected !== null &&
                stp.isSelected !== undefined &&
                stp.isSelected === true
            ) {
                batchPayload.lookups.push({ key: stp.id });
            }
        });
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeStpStatusDialogController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return `${type} Stop Payments`;
                },
                message() {
                    return `Are you sure you want to ${type.toLowerCase()} these Stop Payments?`;
                },
                actionButtonText() {
                    return type;
                },
                actionButtonClass() {
                    if (type === 'Approve') {
                        return 'btn-success';
                    }
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            batchPayload = {
                lookups: [],
            };
            angular.forEach($scope.filteredStps, stp => {
                if (
                    stp.isSelected !== null &&
                    stp.isSelected !== undefined &&
                    stp.isSelected === true
                ) {
                    batchPayload.lookups.push({ key: stp.id, value: comments });
                }
            });
            if (type === 'Approve') {
                stpService.batchApprove(batchPayload, 'transfer').then(response => {
                    response.forEach(stopPayment => {
                        updateStopPayment(stopPayment);
                    });
                    toaster.approve('Stop Payment');
                });
            } else if (type === 'Reject') {
                stpService.batchReject(batchPayload).then(response => {
                    response.forEach(stopPayment => {
                        updateStopPayment(stopPayment);
                    });
                    toaster.reject('Stop Payment');
                });
            }
        });
    }

    function approve(stopPayment) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeStpStatusDialogController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Approve Stop Payment';
                },
                message() {
                    return 'Are you sure you want to approve this Stop Payment?';
                },
                actionButtonText() {
                    return 'Approve';
                },
                actionButtonClass() {
                    return 'btn-success';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            stpService.approve(stopPayment.id, comments).then(response => {
                updateStopPayment(response);
                toaster.approve('Stop Payment');
            });
        });
    }

    function reject(stopPayment) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeStpStatusDialogController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Reject Stop Payment';
                },
                message() {
                    return 'Are you sure you want to reject this Stop Payment?';
                },
                actionButtonText() {
                    return 'Reject';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            stpService.reject(stopPayment.id, comments).then(response => {
                updateStopPayment(response);
                toaster.reject('Stop Payment');
            });
        });
    }

    function updateStopPayment(stopPayment) {
        $scope.stopPayments = $scope.stopPayments.map(stp => {
            if (stp.id === stopPayment.id) {
                stopPayment.expanded = stp.expanded;
                return stopPayment;
            }
            return stp;
        });
        setApprovalAttributes();

        $scope.filteredStps = $scope.filteredStps.map(stp => {
            if (stp.id === stopPayment.id) {
                stopPayment.expanded = stp.expanded;
                return stopPayment;
            }
            return stp;
        });
    }

    function cancel(stopPayment) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeStpStatusDialogController',
            backdrop: 'static',
            resolve: {
                headerText() {
                    return 'Cancel Stop Payment';
                },
                message() {
                    return 'Are you sure you want to Cancel this Stop Payment?';
                },
                actionButtonText() {
                    return 'Confirm';
                },
                actionButtonClass() {
                    return 'btn-danger';
                },
                closeButtonText() {
                    return 'Cancel';
                },
            },
        });
        modalInstance.result.then(comments => {
            stpService.cancel(stopPayment.id, comments).then(response => {
                updateStopPayment(response);
                toaster.cancel('Stop Payment');
            });
        });
    }
}
