/**
 * Convenience method for adding event listeners that returns a subscription handle.
 */
export function listenFor(target: EventTarget, eventName: string, callback: EventListener) {
    target.addEventListener(eventName, callback);

    return {
        unsubscribe: () => target.removeEventListener(eventName, callback),
    };
}
