import { FieldType } from '@treasury/FDL';
import { OmegaTableActionMap } from './table.types';

export type OmegaReportDownloadFormat =
    | 'PDF'
    | 'CSV'
    | 'PDF Summary'
    | 'PDF Detail'
    | 'CSV Summary'
    | 'CSV Detail'
    | 'NACHA';

/**
 * Tuple defining a specific number or range of numbers with an Omega filter:
 * 1. type
 * 2. start value
 * 3. end value
 */
export type OmegaNumericValue =
    | ['range', number, number]
    | ['specific', number]
    | ['specific', string, number]; // To accommodate the omega range control for specific amounts.

export interface OmegaReportAction<T extends OmegaTableActionMap> {
    type: keyof T;
    label: string;
    action: () => void;
    isDisabled: () => boolean;
    visibleWhen: () => boolean;
    alignment?: string;
}

interface OmegaReportLinkBase {
    title: string;
}

export interface OmegaReportAnchor extends OmegaReportLinkBase {
    url: string;
}
export interface OmegaReportRoute extends OmegaReportLinkBase {
    route: string;
    parameters: object;
}

export type OmegaReportLink = OmegaReportAnchor | OmegaReportRoute;

interface OmegaReportFilterBase<T, V extends T[keyof T]> {
    multiple?: boolean;
    required?: boolean;
    label?: string;
    inline?: string;
    value: V | V[];
    fieldType: FieldType<V>;
}
/**
 * A definition for a filter inside of an `<omega-report>`.
 */
interface OmegaReportFilterSingle<T, V extends T[keyof T]> extends OmegaReportFilterBase<T, V> {
    field: keyof T;
    multiple?: false;
}

interface OmegaReportFilterMultiVm<T> {
    text: string;
    value: keyof T;
}

interface OmegaReportFilterMulti<T, V extends T[keyof T]> extends OmegaReportFilterBase<T, V> {
    fields: (keyof T)[];
    multiple: true;
    items: OmegaReportFilterMultiVm<T>[];
}

export type OmegaReportFilter<T, V extends T[keyof T] = T[keyof T]> =
    | OmegaReportFilterSingle<T, V>
    | OmegaReportFilterMulti<T, V>;

export type OmegaReportItemLabel = {
    singular: string;
    plural?: string;
};

type OmegaReportFilterConfig<T, K extends keyof T> = Pick<
    OmegaReportFilterBase<T, T[K]>,
    'label' | 'required' | 'inline'
>;

/**
 * This class primarily exists to get better typing at around report filters.
 * It does not impart additional logic or functionality.
 *
 * Report filters can be created inline, using object literals.
 * However, TypeScript's interface generics do not allow the same level
 * of type inference as classes and functions do. Specifying a type on the class
 * combined with the field name in the method dramatically reduces the boilerplate required
 * to get properly typed filter definitions.
 */
export class ReportFilterFactory<T> {
    /**
     * Helper function for creating strongly-typed report filters.
     * Ensures that types are consistent with the chosen field name.
     *
     * @param field The field name that exists on the type filters are being created for.
     * @param fieldType A field type definition that defines how the filter appears in the UI.
     * @param value The value or values to use in the filter.
     */
    public create<K extends keyof T>(
        field: K,
        fieldType: FieldType<T[K]>,
        value: T[K] | T[K][],
        config: OmegaReportFilterConfig<never, never> = {}
    ): OmegaReportFilterSingle<T, T[K]> {
        return {
            field,
            fieldType,
            value,
            ...config,
        };
    }

    public createMulti<Keys extends (keyof T)[]>(
        fields: Keys,
        fieldType: FieldType<T[Keys[0]]>,
        items: OmegaReportFilterMulti<T, T[Keys[0]]>['items'],
        value: T[Keys[0]] | T[Keys[0]][],
        config: OmegaReportFilterConfig<never, never> = {}
    ): OmegaReportFilterMulti<T, T[Keys[0]]> {
        if (fields.length < 2) {
            throw new Error(
                `Parameter "fields" must contain at least 2 elements. Got ${fields.length} instead.`
            );
        }

        return {
            multiple: true,
            fields,
            fieldType,
            value,
            items,
            ...config,
        };
    }
}
