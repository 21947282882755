export default class ColumnFactory {
    constructor(tags) {
        this.tags = tags;
    }

    // creating columns from node will apply lowercase to node attributes
    // therefore createColumnFromObjectAndRecordset is preferred
    createColumnFromNodeAndRecordset(node, recordset) {
        const Column = this.tags[node.tagName?.toLowerCase()];

        if (!Column) return null;

        const attributes = Object.fromEntries(
            Array.from(node.attributes).map(a => [a.name, a.value])
        );
        return new Column(attributes, recordset);
    }

    createColumnFromObjectAndRecordset(col, recordset, cursor) {
        const Column = this.getColumnClass(col);

        if (!Column) return null;

        return new Column(col, recordset, cursor);
    }

    getColumnClass(col) {
        if (typeof col.type === 'function') {
            return col.type;
        }
        return this.tags[`omega-table-${col.type}-column`] ?? this.tags['omega-table-column'];
    }

    createColumnsFromColumnDefinitions(columnGenerator, recordset) {
        const createsColumns =
            typeof columnGenerator === 'function' ? columnGenerator() : columnGenerator;
        const columns = createsColumns.map(column => column.columns ?? column).flat();
        return columns.map(column => this.createColumnFromObjectAndRecordset(column, recordset));
    }
}
