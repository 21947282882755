// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant(
    'taxPaymentAccountTypes',
    Object.freeze({
        Checking: 'Checking',
        Savings: 'Savings',
    })
);
