import { CustomEventCallback } from '@treasury/utils';
import Record from './record';

export enum FdlRecordEvent {
    Change = 'change',
    Blur = 'blur',
    Reset = 'reset',
}

interface RecordEventPayload<T> {
    field: keyof T;
}

type RecordEventMap<T> = {
    [FdlRecordEvent.Change]: RecordEventPayload<T>;
    [FdlRecordEvent.Blur]: RecordEventPayload<T>;
    [FdlRecordEvent.Reset]: void;
};

export function dispatchRecordEvent<E extends FdlRecordEvent, T>(
    record: Record<T>,
    eventName: E,
    payload: RecordEventMap<T>[E]
) {
    const event = new CustomEvent(eventName, {
        detail: payload,
    });

    record.dispatchEvent(event);
}

export type RecordEventCallback<E extends FdlRecordEvent, T> = CustomEventCallback<
    RecordEventMap<T>[E]
>;

export function listenForRecordEvent<E extends FdlRecordEvent, T>(
    record: Record<T>,
    eventName: E,
    fn: RecordEventCallback<E, T>
) {
    record.addEventListener(eventName, fn as EventListener);

    return {
        unsubscribe: () => record.removeEventListener(eventName, fn as EventListener),
    };
}
