/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    BadRequestResultDto,
    FiArpProductFeatureModelDto,
    FiProductFeatureModelDto,
    FiQfxProductModelDto,
    OkResultDto,
    StateTaxCodeFileMetadataModelDto,
    UpdateRemoteDepositProductModelDto,
    UploadStateTaxCodeFileResponseModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ProductFeatureConfigurationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsGetAll
     * @summary Provides the Product features available for the FI.
     * @request GET:/ProductFeatureConfigurations
     */
    productFeatureConfigurationsGetAll = (params: RequestParams = {}) =>
        this.http.request<FiProductFeatureModelDto[], any>({
            path: `/ProductFeatureConfigurations`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsChangeStatus
     * @summary Provides the endpoint to change the status for the specified Product Feature Id.
     * @request POST:/ProductFeatureConfigurations/{id}/ChangeStatus/{status}
     */
    productFeatureConfigurationsChangeStatus = (
        id: number,
        status: string,
        params: RequestParams = {}
    ) =>
        this.http.request<FiProductFeatureModelDto, any>({
            path: `/ProductFeatureConfigurations/${id}/ChangeStatus/${status}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsGet
     * @summary Provides the Product Feature for the specified Id.
     * @request GET:/ProductFeatureConfigurations/{id}
     */
    productFeatureConfigurationsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<FiProductFeatureModelDto, any>({
            path: `/ProductFeatureConfigurations/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsPut
     * @summary Provides the endpoint to update the parameters for the Product Feature.
     * @request PUT:/ProductFeatureConfigurations/{id}
     */
    productFeatureConfigurationsPut = (
        id: number,
        productFeature: FiProductFeatureModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<FiProductFeatureModelDto, BadRequestResultDto>({
            path: `/ProductFeatureConfigurations/${id}`,
            method: 'PUT',
            body: productFeature,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsGetByName
     * @summary Provides the Product Feature for the specified Type.
     * @request GET:/productFeatureConfigurations/getByName/{name}
     */
    productFeatureConfigurationsGetByName = (name: string, params: RequestParams = {}) =>
        this.http.request<FiProductFeatureModelDto, any>({
            path: `/productFeatureConfigurations/getByName/${name}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags ProductFeatureConfigurations
 * @name ProductFeatureConfigurationsUpdateInstitutionArpProduct
 * @summary Provides the endpoint to update the Account Reconciliation Processing
(ARP/Positive Pay) configuration for the financial institution.
 * @request POST:/productFeatureConfigurations/UpdateInstitutionArpProduct
 */
    productFeatureConfigurationsUpdateInstitutionArpProduct = (
        fiArpProductModel: FiArpProductFeatureModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<FiProductFeatureModelDto, any>({
            path: `/productFeatureConfigurations/UpdateInstitutionArpProduct`,
            method: 'POST',
            body: fiArpProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags ProductFeatureConfigurations
 * @name ProductFeatureConfigurationsUpdateRemoteDepositProduct
 * @summary Provides the endpoint to update the Remote Deposit product
configuration for the financial institution.
 * @request POST:/productFeatureConfigurations/UpdateRemoteDepositProduct
 */
    productFeatureConfigurationsUpdateRemoteDepositProduct = (
        remoteDepositProductModel: UpdateRemoteDepositProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, any>({
            path: `/productFeatureConfigurations/UpdateRemoteDepositProduct`,
            method: 'POST',
            body: remoteDepositProductModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsUploadAchStateTaxCodes
     * @summary Provides the endpoint to upload ACH State Tax Codes.
     * @request POST:/productFeatureConfigurations/UploadAchStateTaxCodes
     */
    productFeatureConfigurationsUploadAchStateTaxCodes = (params: RequestParams = {}) =>
        this.http.request<UploadStateTaxCodeFileResponseModelDto, BadRequestResultDto>({
            path: `/productFeatureConfigurations/UploadAchStateTaxCodes`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsDownloadAchStateTaxCodes
     * @summary Provides the endpoint to download ACH State Tax Codes.
     * @request GET:/productFeatureConfigurations/DownloadAchStateTaxCodes
     */
    productFeatureConfigurationsDownloadAchStateTaxCodes = (params: RequestParams = {}) =>
        this.http.request<File, any>({
            path: `/productFeatureConfigurations/DownloadAchStateTaxCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsGetAchStateTaxCodeFileMetadata
     * @summary Provides the endpoint to download ACH State Tax Code file metadata.
     * @request GET:/productFeatureConfigurations/GetAchStateTaxCodeFileMetadata
     */
    productFeatureConfigurationsGetAchStateTaxCodeFileMetadata = (params: RequestParams = {}) =>
        this.http.request<StateTaxCodeFileMetadataModelDto, any>({
            path: `/productFeatureConfigurations/GetAchStateTaxCodeFileMetadata`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProductFeatureConfigurations
     * @name ProductFeatureConfigurationsCheckQfxProductStatusForFi
     * @summary Check the status of Qfx enabled flag in institution option table.
     * @request GET:/productFeatureConfigurations/checkQfxProductStatusForFi
     */
    productFeatureConfigurationsCheckQfxProductStatusForFi = (params: RequestParams = {}) =>
        this.http.request<FiQfxProductModelDto, any>({
            path: `/productFeatureConfigurations/checkQfxProductStatusForFi`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
