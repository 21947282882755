AccountGroupsModalController.$inject = [
    '$scope',
    '$modal',
    '$modalInstance',
    'modalService',
    'dashboardService',
    'groups',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function AccountGroupsModalController(
    $scope,
    $modal,
    $modalInstance,
    modalService,
    dashboardService,
    groups
) {
    $scope.accountSearchText = '';
    $scope.filteredAccountsForGroup = {};
    $scope.groups = angular.copy(groups);
    $scope.sortableOptions = null;
    $scope.MAX_NUMBER_OF_GROUPS = 20;
    $scope.MAX_ACCOUNTS_PER_GROUP = 50;

    $scope.$watch('accountSearchText', onAccountsSearchTextChanged);
    $scope.$watch('groups', onGroupsChanged, true);

    $scope.accountResequenceCallback = accountResequenceCallback;
    $scope.addAccountsToGroup = addAccountsToGroup;
    $scope.close = close;
    $scope.createNewGroup = createNewGroup;
    $scope.getFilteredAccountsForGroup = getFilteredAccountsForGroup;
    $scope.removeAccountFromGroup = removeAccountFromGroup;
    $scope.removeGroup = removeGroup;
    $scope.renameGroup = renameGroup;
    $scope.isCreateNewGroupDisabled = isCreateNewGroupDisabled;
    $scope.hasMaximumNumberOfGroups = hasMaximumNumberOfGroups;

    init();

    function init() {
        $scope.sortableOptions = {
            helper: 'original',
            cursor: 'move',
            stop: onStop,
        };
    }

    function close() {
        $modalInstance.close($scope.groups);
    }

    function createNewGroup() {
        const newGroup = {
            accounts: [],
        };
        showGroupNameModal(newGroup).then(newGroupWithName => {
            showAccountListModal(newGroupWithName).then(newGroupWithAccounts => {
                dashboardService.createAccountGroup(newGroupWithAccounts).then(response => {
                    $scope.groups.push(mapBalances(newGroupWithAccounts, response));
                });
            });
        });
    }

    function renameGroup(group) {
        showGroupNameModal(group).then(groupWithName => {
            groupWithName.accounts = [];

            dashboardService.updateGroupName(groupWithName).then(response => {
                group.name = response.name;
            });
        });
    }

    function addAccountsToGroup(group) {
        showAccountListModal(group).then(groupWithAccounts => {
            dashboardService
                .addAccounts(groupWithAccounts.accounts, groupWithAccounts.id)
                .then(response => {
                    angular.copy(mapBalances(groupWithAccounts, response), group);
                });
        });
    }

    function mapBalances(groupWithAccountBalances, groupWithoutAccountBalances) {
        for (let i = 0; i < groupWithAccountBalances.accounts.length; i++) {
            const accountWithBalance = groupWithAccountBalances.accounts[i];
            for (let j = 0; j < groupWithoutAccountBalances.accounts.length; j++) {
                const accountWithoutBalance = groupWithoutAccountBalances.accounts[j];
                if (accountWithoutBalance.id === accountWithBalance.id) {
                    accountWithoutBalance.collectedBalance = accountWithBalance.collectedBalance;
                    accountWithoutBalance.availableBalance = accountWithBalance.availableBalance;
                    accountWithoutBalance.currentBalance = accountWithBalance.currentBalance;
                    break;
                }
            }
        }
        return groupWithoutAccountBalances;
    }

    function removeGroup(group) {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete Group',
            headerText: 'Confirm Deletion',
            bodyText: 'Are you sure you want to delete this group?',
            submit() {
                dashboardService.deleteGroup(group.id).then(response => {
                    const index = $scope.groups.indexOf(group);
                    $scope.groups.splice(index, 1);
                });
                modalInstance.dismiss();
            },
        };
        const modalInstance = modalService.showModal({}, modalOptions);
    }

    function removeAccountFromGroup(group, account) {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Remove Account',
            headerText: 'Confirm Removal',
            bodyText: 'Are you sure you want to remove this account?',
            submit() {
                dashboardService.removeAccount(group.id, account.id).then(() => {
                    const index = group.accounts.indexOf(account);
                    group.accounts.splice(index, 1);
                });
                modalInstance.dismiss();
            },
        };
        const modalInstance = modalService.showModal({}, modalOptions);
    }

    function getFilteredAccountsForGroup(group) {
        return group.accounts.filter(
            account =>
                account.name.toLowerCase().indexOf($scope.accountSearchText.toLowerCase()) !== -1
        );
    }

    function accountResequenceCallback(group, account, newIndex) {
        dashboardService.resequenceAccount(group.id, account.id, newIndex);
    }

    function onStop(event, ui) {
        const newIndex = ui.item.sortable.dropindex;
        dashboardService.resequenceGroup($scope.groups[newIndex].id, newIndex);
    }

    function onGroupsChanged() {
        updateFilteredAccounts();
    }

    function onAccountsSearchTextChanged() {
        updateFilteredAccounts();
    }

    function updateFilteredAccounts() {
        $scope.groups.forEach(group => {
            $scope.filteredAccountsForGroup[group.id] = getFilteredAccountsForGroup(group);
        });
    }

    function isCreateNewGroupDisabled() {
        return hasMaximumNumberOfGroups();
    }

    function hasMaximumNumberOfGroups() {
        return $scope.groups.length >= $scope.MAX_NUMBER_OF_GROUPS;
    }

    function showGroupNameModal(group) {
        const modalInstance = $modal.open({
            template: require('../views/accountGroupNameModal.html'),
            size: 'sm',
            controller: 'AccountGroupNameModalController',
            backdrop: 'static',
            resolve: {
                group() {
                    return group;
                },
            },
        });
        return modalInstance.result;
    }

    function showAccountListModal(group) {
        const modalInstance = $modal.open({
            template: require('../views/accountGroupAccountListModal.html'),
            size: 'lg',
            controller: 'AccountGroupAccountListModalController',
            backdrop: 'static',
            resolve: {
                maxAccountsPerGroup() {
                    return $scope.MAX_ACCOUNTS_PER_GROUP;
                },
                group() {
                    return group;
                },
            },
        });
        return modalInstance.result;
    }
}
