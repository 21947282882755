import { MasterListRecipient } from '@treasury/domain/channel/types/ach/api/masterListRecipient.dto';
import { Record } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-dialog';
import { DialogUtility } from '@treasury/omega/util/dialog-utility';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

const tag = 'ach-recipients-addenda-dialog';

const util = new DialogUtility(tag);

const ListeningElement = ListeningElementMixin(LitElement);

@customElement('ach-recipients-addenda-dialog')
export class AchRecipientsAddendaDialog extends ListeningElement {
    @state()
    previousAddenda!: string;

    @property({ type: Object })
    record!: Record<MasterListRecipient>;

    @state()
    private saveDisabled = true;

    @state()
    private addendaIsReadOnly = true;

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (this.record && changedProperties.has('record')) {
            this.listenTo(this.record, 'change', () => {
                this.saveDisabled =
                    this.record?.getField('addendaRaw')?.length === 0 ||
                    this.previousAddenda === this.record.getField('addendaRaw');
            });
        }
    }

    handleOpen() {
        if (!this.record) return;
        const addenda = this.record.getField('addendaRaw');
        this.previousAddenda = addenda ?? '';
        this.addendaIsReadOnly = !this.record.getField('editable');
    }

    static open(context: Element) {
        util.openFromParentContainer(context);
    }

    close() {
        this.addendaIsReadOnly = !this.record.getField('editable');
        util.close(this);
    }

    cancel() {
        this.record.setField('addendaRaw', this.previousAddenda);
        this.close();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private renderAddenda() {
        if (!this.record) return false;
        const addenda = this.record.getField('addendaRaw');
        const hasValue = !!addenda && addenda.toString().trim().length > 0;
        const editable = this.record.getField('editable');
        if (hasValue || editable) {
            return html`<div>${this.renderDialogContent()}</div>`;
        }
        return html`<p>There is no addenda to display</p>`;
    }

    renderDialogContent() {
        return html`<omega-field
            id="addendaRaw"
            field="addendaRaw"
            .record=${this.record}
            .readOnly=${this.addendaIsReadOnly}
        ></omega-field>`;
    }

    private renderButtons() {
        const editable = this.record?.getField('editable');
        if (editable) {
            return html`<div class="buttons">
                <div>
                    <omega-button
                        slot="actions"
                        type="primary"
                        @click=${() => this.close()}
                        .disabled=${this.saveDisabled}
                        >Save</omega-button
                    >
                    <omega-button slot="actions" type="link" @click=${() => this.cancel()}
                        >Cancel</omega-button
                    >
                </div>
            </div>`;
        }
        return html`<omega-button slot="actions" type="link" @click=${() => this.close()}
            >Close</omega-button
        >`;
    }

    public render() {
        return html` <omega-dialog
            id=${util.dialogId}
            dialogTitle="Addenda"
            @open=${() => this.handleOpen()}
            @close=${() => this.close()}
            class="omega-dialog-addenda-dialog"
        >
            <div slot="content">
                <div class="dialog-content">${this.renderAddenda()}</div>
                <hr />
            </div>
            ${this.renderButtons()}
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .dialog-content {
                width: 800px;
                padding: 0 15px;
            }
            .buttons {
                display: flex;
                justify-content: space-between;
                padding: 0 8px;
            }
        `;
    }
}
