import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import { string } from '@treasury/policy/primitives';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';

import '@treasury/omega/components/omega-button-bar.js';
import './exception-table.js';

class WorkflowStep extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            creationType: String,
            client: Object,
            record: Object,
            canContinue: Boolean,
            selectedException: Object,
            masterSettingsLabel: String,
            masterSettingsLoading: Boolean,
            step: Number,
            referringPage: String,
        };
    }

    constructor() {
        super();
        this.creationType = 'manual';
        this.creationOptions = [
            { label: 'Manual', value: 'manual' },
            { label: 'From Exception', value: 'fromException' },
        ];
        this.fields = {
            account: string.with
                .options({ data: [{ text: 'test', value: 'test' }] })
                .thatHas.label('Select Account'),
        };
    }

    next() {
        this.dispatchEvent(new CustomEvent('next'));
    }

    previous() {
        this.dispatchEvent(new CustomEvent('previous'));
    }

    firstStep() {
        this.dispatchEvent(new CustomEvent('firstStep'));
    }

    async goToFilterRules() {
        const di = await DiContainer.getInstance();
        return di.get(NavigationService).navigate('payables.arp.ach-filters');
    }

    changeCreationType(type) {
        this.creationType = type;
    }

    save() {
        this.dispatchEvent(new CustomEvent('save'));
    }

    renderLabelAndField(fieldName) {
        return html` <omega-field .record=${this.record} .field=${fieldName}></omega-field> `;
    }

    renderMasterSettingsProgress() {
        if (!this.masterSettingsLoading) return nothing;
        return html`<omega-progress></omega-progress> `;
    }

    renderMasterSettingsLabel() {
        if (this.masterSettingsLoading) return nothing;
        return html`${this.masterSettingsLabel}`;
    }

    renderAccountMasterSettings() {
        return html`<div class="master-settings">
            <p>
                ${this.renderMasterSettingsProgress()}${this.renderMasterSettingsLabel()}
                <omega-tooltip
                    icon="info-circle"
                    message="This is the account’s initial filter settings which is set by the Financial institution and cannot be edited"
                    direction="bottom"
                ></omega-tooltip>
            </p>
            <span>Account Filter Settings</span>
        </div> `;
    }

    renderHeader() {
        let header = html`
            <h2 class="step-title">ACH Filter Rule Settings</h2>
            ${this.renderAccountMasterSettings()}
            <span><span class="red">*</span> indicates Required field</span>
        `;
        if (this.step === 1) {
            header = html` <h2 class="step-title">Review ACH Filter Rule Settings</h2> `;
        }

        return html`
            <div class="omega-form-header">${header}</div>
            <hr />
        `;
    }

    // TODO: This is removed for day 1.
    // In the future we may consider adding this functionality so the code is being left in place.

    // renderSelectCreationType() {
    //     if (this.step !== 0) return nothing;
    //     return html`
    //         <omega-radio-group
    //             name="payment-creation-type"
    //             id="payment-creation-type-group"
    //             orientation="horizontal"
    //             label=""
    //             .radios=${this.creationOptions}
    //             .value=${this.creationType}
    //             @change=${e => this.changeCreationType(e.target.value)}
    //         ></omega-radio-group>
    //     `;
    // }

    renderButtons() {
        const returnButton = this.referringPage
            ? html`
                  <omega-button
                      @click=${async () => {
                          const di = await DiContainer.getInstance();
                          di.get(NavigationService).navigate(this.referringPage);
                      }}
                      type="secondary"
                  >
                      Continue Working Exceptions
                  </omega-button>
              `
            : nothing;

        const achFilterButton = html`
            <omega-button type="link" @click=${this.goToFilterRules}>
                ACH Filter Rules
            </omega-button>
        `;

        let buttons = html`
            <omega-button @click=${this.next} .disabled=${!this.canContinue} type="primary">
                Review
            </omega-button>
            ${achFilterButton}
        `;

        if (this.creationType === 'fromException') {
            buttons = html`
                <omega-button
                    @click=${this.createFromException}
                    type="primary"
                    .disabled=${!this.selectedException}
                >
                    Create From Exception
                </omega-button>
            `;
        }

        if (this.step === 1) {
            buttons = html`
                <omega-button @click=${this.save} .disabled=${!this.canContinue} type="primary">
                    Save Filter Rule
                </omega-button>
                <omega-button @click=${this.previous} type="secondary"> Back </omega-button>
                ${achFilterButton}
            `;
        }
        if (this.step === 2) {
            buttons = html`
                ${returnButton}
                <omega-button @click=${this.firstStep} type="secondary">
                    Create New Filter
                </omega-button>
                ${achFilterButton}
            `;
        }
        return html`
            <omega-button-bar position="bottom" alignment="left"> ${buttons} </omega-button-bar>
        `;
    }

    renderCreationForm() {
        return html`
            <div class="form-container">
                <div class="left col">
                    ${this.renderLabelAndField('account')}
                    ${this.renderLabelAndField('filterRuleName')}
                    ${this.renderLabelAndField('achCompanyId')}
                    ${this.renderLabelAndField('transactionType')}
                </div>
                <div class="right col">
                    ${this.renderLabelAndField('amount')} ${this.renderLabelAndField('secCode')}
                    ${this.renderLabelAndField('achCompanyName')}
                    ${this.renderLabelAndField('expirationDate')}
                </div>
            </div>
        `;
    }

    renderFromException() {
        if (this.selectedException) {
            return this.renderCreationForm();
        }
        return html`
            <h3 class="sub-heading">Select an Exception</h3>
            <exception-table
                @recordSelected=${({ detail }) => {
                    this.selectedException = detail;
                }}
            ></exception-table>
        `;
    }

    renderCreationType() {
        return this.creationType === 'fromException'
            ? this.renderFromException()
            : this.renderCreationForm();
    }

    render() {
        if (!this.record) return nothing;
        return html` ${this.renderHeader()} ${this.renderCreationType()} ${this.renderButtons()} `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-form-header {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .omega-form-header span.red {
                color: var(--omega-text-error);
            }
            .step-title {
                margin: 0;
                font-size: 24px;
                color: var(--omega-text-header);
            }
            hr {
                border: none;
                border-top: var(--omega-default-border);
                margin: 16px -10px;
            }
            .sub-heading {
                font-size: 20px;
            }
            .form-container {
                display: flex;
            }
            .form-container > .col {
                flex: 1;
                width: 50%;
                display: inline-block;
                vertical-align: top;
            }
            .form-container > .left {
                border-right: 1px solid #cccccc;
                padding-right: 15px;
            }
            .form-container > .right {
                border-left: 1px solid #cccccc;
                padding-left: 15px;
            }
            .form-container omega-field {
                padding: 5px 0px;
            }
            div.master-settings {
                text-align: center;
            }
            div.master-settings p {
                margin: 0;
            }
        `;
    }
}

customElements.define('workflow-step', WorkflowStep);
