import AchFileActivityService from '@treasury/domain/channel/services/ach/ach-file-activity-service.js';

export default class AchFileActivityClient {
    async fetchAchFileActivity(...args) {
        return AchFileActivityService.fetchAchFileActivity(...args);
    }

    async downloadAchFileActivity(outputType, filterModel) {
        return AchFileActivityService.downloadAchFileActivity(outputType, filterModel);
    }

    async fetchFileSources() {
        return AchFileActivityService.fetchFileSources();
    }

    async fetchCompanies() {
        return AchFileActivityService.fetchCompanies();
    }
}
