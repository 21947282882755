import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-icon';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

export class AchBatchErrorDetails extends ListeningElementMixin(LitElement) {
    @property()
    errorMessage!: string;

    renderMessage() {
        return this.errorMessage
            ? html`<div class="error-message">${this.errorMessage}</div>`
            : nothing;
    }

    render() {
        return html`<div class="batch-error-detail">
            <omega-icon icon="exclamation-triangle pointer-cursor danger"></omega-icon
            >${this.renderMessage()}
        </div> `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .error-message {
                margin-left: 10px;
            }
            .batch-error-detail {
                margin: 15px;
                display: flex;
            }
        `;
    }
}

window.customElements.define('ach-batch-error-details', AchBatchErrorDetails);
