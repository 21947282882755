import { filterGrid } from '../../../filters/gridFilterHelper.js';

AccountTransactionsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'accountTransactionsService',
    'downloadPageId',
    'downloadService',
    'dateFilterService',
    'DatePeriod',
    'moment',
    '$filter',
    'entitlementsService',
];

export default function AccountTransactionsController(
    $scope,
    $state,
    $stateParams,
    accountTransactionsService,
    downloadPageId,
    downloadService,
    dateFilterService,
    DatePeriod,
    moment,
    $filter,
    entitlementsService
) {
    $scope.displayTransactionCodeDescriptionInUI = entitlementsService.hasEntitlement(
        'Feature.Report.DisplayTransactionCodeDescriptionInUI'
    );
    $scope.downloadPageId = downloadPageId.AccountTransactionDetail;
    $scope.downloadFormats = ['CSV', 'PDF', 'OFX'];
    $scope.isDateRangeSelected = isDateRangeSelected;
    $scope.isSpecificDateSelected = isSpecificDateSelected;
    $scope.resetTransactionDates = resetTransactionDates;
    $scope.searchTransactions = searchTransactions;
    $scope.filterSearch = filterSearch;
    $scope.filterText = '';
    $scope.filteredList = [];
    $scope.transactionList = [];

    function resetTransactionDates() {
        $scope.filterObj.fromDate = null;
        $scope.filterObj.toDate = null;
    }

    function isDateRangeSelected() {
        return getTransactionDateType() === DatePeriod.DATE_RANGE;
    }

    function isSpecificDateSelected() {
        return getTransactionDateType() === DatePeriod.SPECIFIC_DATE;
    }

    function getTransactionDateType() {
        if ($scope.filterObj != null) {
            return $scope.filterObj.transactionDateType;
        }

        return null;
    }

    $scope.isNegative = isNegative;

    function isNegative(value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    }

    $scope.calculateAmount = function (array, field) {
        let total = 0;
        angular.forEach(array, item => {
            if (item.description.indexOf('Split Out') === -1) {
                total += item[field];
            }
        });
        return total;
    };

    $scope.filterObj = {
        selectedAccount: null,
        selectedAccountDetails: null,
        fromDate: null,
        toDate: null,
        isEditMode: false,
    };

    $scope.returnToAccountList = function () {
        $state.go('accounts.list');
    };

    $scope.cancelEditMode = function () {
        loadDefaults();
    };

    function searchTransactions() {
        const period = dateFilterService.getPeriodDates(
            $scope.filterObj.transactionDateType,
            $scope.filterObj.fromDate,
            $scope.filterObj.toDate
        );

        $scope.filterObj.fromDate = period.formattedFromDate;
        $scope.filterObj.toDate = period.formattedToDate;

        accountTransactionsService
            .getAccountTransactionsWithDates(
                $scope.filterObj.selectedAccount.type,
                $scope.filterObj.selectedAccount.id,
                $scope.filterObj.fromDate,
                $scope.filterObj.toDate
            )
            .then(response => {
                $scope.filterObj.selectedAccountDetails = response;
                $scope.filteredList = $scope.filterObj.selectedAccountDetails.transactions;
                $scope.filterObj.type = response.type;
                $scope.filterObj.isEditMode = false;
                $scope.isDateChanged = false;
            });
    }

    (function () {
        // init
        if (
            $stateParams.accountId === null ||
            $stateParams.accountId === undefined ||
            $stateParams.accountId === '' ||
            $stateParams.type === null ||
            $stateParams.type === undefined ||
            $stateParams.type === ''
        ) {
            $scope.returnToAccountList();
        } else {
            $scope.accountId = $stateParams.accountId;
            $scope.type = $stateParams.type;
        }

        loadAccounts();
        loadDefaults();
        checkQboSettings();
        getQfxEnabledForCompanyFlag();
        $scope.currentDate = moment().format('MM/DD/YYYY');
    })();

    function checkQboSettings() {
        downloadService.getQboSettings().then(response => {
            if (response && response.intuitBankId) {
                $scope.downloadFormats.push('QBO');
            }
        });
    }
    $scope.fromDateChanged = function () {
        $scope.isDateChanged = true;
    };

    $scope.goAdvancedSearch = function () {
        $state.go('ir.rt');
    };

    $scope.setInEditMode = function () {
        $scope.filterObj.isEditMode = true;
    };

    function loadDefaults() {
        resetTransactionDates();

        $scope.dateTypes = dateFilterService.getDefaultDateTypes();
        $scope.filterObj.transactionDateType = dateFilterService.getDefaultDateType();

        $scope.filterObj.isEditMode = false;
        $scope.orderByField = 'date';
        $scope.reverseSort = true;
        $scope.isDateChanged = false;
    }

    function loadAccounts() {
        accountTransactionsService.getAccountList($scope.type).then(response => {
            if (response.length > 0 && response[0] !== null && typeof response[0] !== 'undefined') {
                $scope.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }
            $scope.accountList = response;
            angular.forEach($scope.accountList, account => {
                if (account.id == $scope.accountId) {
                    $scope.filterObj.selectedAccount = account;
                    $scope.filterObj.id = $scope.accountId;
                }
            });
        });
    }

    $scope.$watch(
        'filterObj.selectedAccount',
        newValue => {
            if (newValue !== null && newValue !== undefined) {
                loadAccountDetails();
            }
        },
        true
    );

    function loadAccountDetails() {
        $scope.filterObj.id = $scope.filterObj.selectedAccount.id;
        searchTransactions();
    }

    function getQfxEnabledForCompanyFlag() {
        downloadService.getQfxEnabledFlag().then(response => {
            if (response.enabledFlag) {
                $scope.downloadFormats.push('QFX');
            }
        });
    }

    function filterSearch(filterText) {
        const transactionList = $scope.filterObj.selectedAccountDetails.transactions;
        $scope.filterText = filterText;
        filterText = filterText || '';
        $scope.filteredList = $filter('filter')(
            transactionList,
            filterGrid(filterText, [
                'date',
                'description',
                'credit',
                'debit',
                'checkNumber',
                'tranCodeDescription',
                'runningBalance',
            ])
        );
    }
}
