/**
 * NOTE: we have some things missing we'll need to add in order to accommodate this report:
 * 1. conditional actions: we'll need to check a value from the record to check what type of action should be shown in the actions column
 * 2. waiting for the options api to finish populating the options before running an autostart report
 */

import { TransferActivityRequests } from '@treasury/domain/channel/requests/transfers/transfer-activity-requests.js';
import { Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-textarea.js';
import '@treasury/omega/layouts/omega-report';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../components/transfer-action-dialog.js';
import TransferActivityReportClient from '../clients/transfer-activity-report-client.js';
import { columns, fields, filters } from '../data/transfer-activity-data.js';
import '../parts/edit-transfer-detail.js';

class TransferActivityReport extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            institution: String,
            actions: Object,
            reportActions: Array,
            recordset: Object,
            selectedTransferRecords: Array,
            showActionDialog: Boolean,
            downloading: Boolean,
            filters: Array,
            client: Object,
            needsUpdate: Boolean,
            achConfiguration: Object,
        };
    }

    constructor() {
        super();
        this.reportInformation = html`<div style="max-width: 350px;">
            <p>
                <b>Transfer Activity</b> - Includes all payments including recurring payments
                regardless of payment status (Payments Pending Approval, Scheduled, Submitted,
                etc.). Utilize the search feature to search payment history.
            </p>
            <p>
                <b>Recurring Transfers</b> - Includes only recurring payments and the details
                (Frequency, Create Date, Next Payment Date, End Date, etc.) associated with the
                respective payment series.
            </p>
            <p><b>Transfer Templates</b> - Includes only payment templates.</p>
        </div>`;
        this.actions = {};
        this.reportLinks = [
            {
                title: 'Recurring Transfers',
                url: `${window.location.origin}/${this.institution}/payables/recurring-transfer-list`,
            },
            {
                title: 'Transfer Templates',
                url: `${window.location.origin}/${this.institution}/payables/transfer-templates`,
            },
        ];
        this.columns = columns;
        this.pageTitle = 'Transfer Activity';
        this.showActionDialog = false;
        this.downloading = false;
        this.needsUpdate = false;
        this.achConfiguration = {};
        this.itemLabel = { singular: 'transfer' };
    }

    connectedCallback() {
        super.connectedCallback();
        this.initializeActions();
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new TransferActivityReportClient();
        }

        const transferAccounts = TransferActivityRequests.fetchTransferAccounts();
        this.achConfiguration = await TransferActivityRequests.fetchAchConfig();
        this.filters = await filters(transferAccounts);
        this.fields = fields(this.client, transferAccounts);
        this.recordset = new Recordset(this.fields, TransferActivityRequests.fetchTransferActivity);
        this.listenTo(this.recordset, 'loading', ({ detail }) => {
            const { loading } = detail;
            const hasApprovePermission = this.recordset.allRecords.some(record =>
                record
                    .getField('permissions')
                    .map(v => v.permission)
                    .includes('Approve')
            );
            if (hasApprovePermission && !loading) {
                this.reportActions = [
                    {
                        type: 'approve',
                        label: 'Approve',
                        action: () => {
                            this.selectedTransferRecords = this.getSelectedTransferRecords();
                            this.actionTaken = 'approve';
                            this.showActionDialog = true;
                        },
                        isDisabled: () => this.recordset?.noRecordsMatch('selected', true),
                    },
                    {
                        type: 'reject',
                        label: 'Reject',
                        action: () => {
                            this.selectedTransferRecords = this.getSelectedTransferRecords();
                            this.actionTaken = 'reject';
                            this.showActionDialog = true;
                        },
                        isDisabled: () => this.recordset?.noRecordsMatch('selected', true),
                    },
                ];
            }
        });
    }

    initializeActions() {
        this.actions = {
            'Cancel Transfer': record => {
                this.actionTaken = 'cancel';
                this.selectedTransferRecords = [record];
                this.showActionDialog = true;
            },
        };
    }

    createNewTransfer() {
        const url = `${this.institution}/payables/transfers`;
        window.history.pushState(null, null, url);
        window.location.reload();
    }

    getSelectedTransferRecords() {
        return this.recordset.recordsMatching('selected', true);
    }

    getActionMessage(actionTaken) {
        switch (actionTaken) {
            case 'approve':
                return 'approved';
            case 'reject':
                return 'rejected';
            case 'cancel':
                return 'cancelled';
            case 'resubmit':
                return 'resubmitted';
            default:
                return 'updated';
        }
    }

    async submitActionOnTransfer(selectedTransferRecords, statusChangeComments) {
        this.needsUpdate = false;
        const pluralizeTransfers = selectedTransferRecords.length > 1 ? 'Transfers' : 'Transfer';
        try {
            const response = await TransferActivityRequests.updateTransferStatus(this.actionTaken, {
                selectedTransferRecords,
                statusChangeComments,
            });
            if (response.securityMessage.status === 'Success') {
                this.result = {
                    type: 'success',
                    message: html`${pluralizeTransfers} successfully
                    ${this.getActionMessage(this.actionTaken)}`,
                };
                this.needsUpdate = true;
                // await this.recordset.requestHardUpdate();
            }
        } catch (error) {
            this.result = {
                type: 'error',
                message:
                    error instanceof Error
                        ? error.message
                        : 'Could not submit transfer. Please try again.',
            };
        }
        return this.result;
    }

    async handleDownload({ filter, type }) {
        this.downloading = true;
        try {
            await TransferActivityRequests.downloadTransfers(type, 'TransferList', filter);
        } catch (e) {
            const message =
                e instanceof Error ? e.message : 'Could not download. Please try again.';
            this.alert = { ...this.alert, message, visible: true, type: 'error' };
        } finally {
            this.downloading = false;
        }
    }

    setDetailActionDialogProperties(action, records) {
        this.actionTaken = action;
        this.selectedTransferRecords = records;
        this.showActionDialog = true;
    }

    renderTransferActionDialog() {
        return html`<transfer-action-dialog
            .transferRecords=${this.selectedTransferRecords}
            .actionTaken=${this.actionTaken}
            .showActionDialog=${this.showActionDialog}
            .dialogAction=${(selectedTransferRecords, statusChangeComments) =>
                this.submitActionOnTransfer(selectedTransferRecords, statusChangeComments)}
            .recordset=${this.recordset}
            @close=${async () => {
                if (this.needsUpdate) {
                    await this.recordset.requestHardUpdate();
                }
                this.needsUpdate = false;
                this.showActionDialog = false;
            }}
        ></transfer-action-dialog>`;
    }

    renderDownloadDialog() {
        if (this.downloading) {
            const title = 'Downloading Transfer Activity';
            return html`<omega-dialog
                id="download-dialog"
                open
                .dialogTitle=${title}
                @close=${() => {
                    this.downloading = false;
                }}
            >
                <omega-progress card></omega-progress>
            </omega-dialog>`;
        }
        return nothing;
    }

    renderPageLoader() {
        return html`<div class="background">
            <omega-progress card class="light-loader"></omega-progress>
        </div>`;
    }

    render() {
        const transferDetails = (record, close) =>
            html`<edit-transfer-details
                .record=${record}
                .recordset=${this.recordset}
                .config=${this.achConfiguration}
                @close=${close}
                .setActionDialogProperties=${(action, records) =>
                    this.setDetailActionDialogProperties(action, records)}
            ></edit-transfer-details>`;
        if (!this.filters) {
            return this.renderPageLoader();
        }
        return html`
            ${this.renderTransferActionDialog()} ${this.renderDownloadDialog()}
            <omega-report
                id="transfer-activity-report"
                .title=${this.pageTitle}
                flyout
                autostart
                displayToggleAll
                .reportInformation=${this.reportInformation}
                .itemLabel=${this.itemLabel}
                .actions=${this.actions}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recordset}
                .filters=${this.filters}
                .columns=${this.columns}
                .options=${['download', 'print']}
                .downloadOptions=${['PDF']}
                .detailFunction=${transferDetails}
                callToAction="Create New Transfer"
                @callToAction=${this.createNewTransfer}
                @reportDownload=${({ detail }) => this.handleDownload(detail)}
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
        `;
    }
}

customElements.define('transfer-activity-report', TransferActivityReport);
