export default () => ({
    restrict: 'A',
    replace: true,
    transclude: true,
    scope: {},
    template: require('./attributeListTemplate.html'),
    link($scope, $el, $attrs) {
        $scope.$watch(
            () => $el[0].childNodes.length,
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    let children;
                    let largestWidth;
                    children = $($el).children();
                    largestWidth = 0;

                    // Determine largest width.
                    window.angular.forEach(children, child => {
                        if ($(child.childElementCount)[0] != 1) {
                            const width = $(child).children().first()[0].scrollWidth;
                            largestWidth = Math.max(width, largestWidth);
                        }
                    });

                    // Apply largest width.
                    window.angular.forEach(children, child => {
                        if ($(child.childElementCount)[0] != 1) {
                            $(child).children().first().css('width', `${largestWidth}px`);
                        }
                    });
                }
            }
        );

        $scope.$watch(
            // If the attribute list is hidden by a ng-show directive, recalculate the field sizes when
            // it is unhidden.
            () => $($el[0]).parents('.ng-hide').length > 0 || $($el[0]).hasClass('ng-hide'),
            (newValue, oldValue) => {
                if (newValue === false && oldValue === true) {
                    let children;
                    let largestWidth;
                    children = $($el).children();
                    largestWidth = 0;

                    // Determine largest width.
                    window.angular.forEach(children, child => {
                        if ($(child.childElementCount)[0] != 1) {
                            let width = $(child).children().first()[0].scrollWidth;
                            // For whatever reason (confirmed on IE and Chrome), the scrollwidth
                            // of the li elements does not include the right-side padding when
                            // an attribute list is unhidden using the ng-show directive. In the
                            // latter instance, one has to get it separately and add it to the
                            // scroll width.
                            const outerWidth = $($(child).children().first()[0]).outerWidth();
                            if (outerWidth > 0 && outerWidth !== width) {
                                width += outerWidth;
                            }
                            largestWidth = Math.max(width, largestWidth);
                        }
                    });

                    // Apply largest width.
                    window.angular.forEach(children, child => {
                        if ($(child.childElementCount)[0] != 1) {
                            $(child).children().first().css('width', `${largestWidth}px`);
                        }
                    });
                }
            }
        );
    },
    controller: attributeListController,
});

attributeListController.$inject = ['$scope', '$element', '$timeout'];

function attributeListController($scope, $element, $timeout) {
    init();

    function init() {
        $timeout(() => {
            resize();
        });
    }

    function resize() {
        let children;
        let largestWidth;
        children = $($element).children();
        largestWidth = 0;

        // Determine largest width.
        window.angular.forEach(children, child => {
            if ($(child.childElementCount)[0] != 1) {
                const width = $(child).children().first()[0].scrollWidth;
                largestWidth = Math.max(width, largestWidth);
            }
        });

        // Apply largest width.
        window.angular.forEach(children, child => {
            if ($(child.childElementCount)[0] != 1) {
                $(child).children().first().css('width', `${largestWidth}px`);
            }
        });
    }
}
