import { LitElement, html, css } from 'lit';
import '@treasury/omega/layouts/omegaFileFormatDesigner';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/progress/omega-progress';
import { defaultFormat } from '../data/defaultFormat.js';

class WireUploadTemplateFormatter extends LitElement {
    static get properties() {
        return {
            id: {
                type: String,
            },
            institution: {
                type: String,
            },
            entitlement: {
                type: Boolean,
            },
            format: {
                type: Object,
            },
            fields: {
                type: Array,
            },
            glossary: {
                type: Array,
            },
            alert: {
                type: Object,
            },
            loading: {
                type: Boolean,
            },
        };
    }

    constructor() {
        super();
        this.format = {};
        this.glossary = [];
        this.alert = {
            visible: false,
            message: '',
        };
    }

    firstUpdated() {
        if (!this.id || this.id === 'new') {
            this.format = defaultFormat(this.fields);
        }
    }

    _showCancelAlert() {
        this.alert = { ...this.alert, visible: true };
    }

    _dismissCancelAlert() {
        this.alert = { ...this.alert, visible: false };
    }

    /**
     * Handle saving at the container level, send format data
     */
    _saveFormat({ detail }) {
        this.format = { ...detail.format };
        this.dispatchEvent(
            new CustomEvent('saveFormat', {
                bubbles: true,
                composed: true,
                detail: { format: detail.format },
            })
        );
    }

    /**
     * pushing to the table route, but the view gets updated by 'cancelFormat'
     * reassigning the format 'id' to undefined
     */
    _cancelFormat() {
        this.dispatchEvent(new CustomEvent('cancelFormat'));
    }

    render() {
        if (!this.format) return html`<omega-progress card class="large-loader"></omega-progress>`;
        return html` <omega-file-format-designer
                .format=${this.format}
                .glossary=${this.glossary}
                .entitlement=${this.entitlement}
                .loading=${this.loading}
                @save=${this._saveFormat}
                @cancel=${this._showCancelAlert}
            ></omega-file-format-designer>

            <omega-alert
                id="cancel-format-alert"
                posture="assertive"
                title="Are You Sure?"
                type="warning"
                ?isVisible=${this.alert.visible}
                @close=${() => {
                    this.alert = { ...this.alert, visible: false };
                }}
            >
                Current template formatting will be lost. Are you sure you want to proceed?
                <omega-button-bar slot="actions" alignment="right"
                    ><omega-button @click=${this._cancelFormat}>Continue</omega-button
                    ><omega-button type="primary" @click=${this._dismissCancelAlert}>
                        Cancel
                    </omega-button>
                </omega-button-bar>
            </omega-alert>`;
    }

    static get styles() {
        return css`
            .large-loader {
                width: 50px;
                height: 50px;
                border-width: 5px;
            }
        `;
    }
}

customElements.define('wire-upload-template-formatter', WireUploadTemplateFormatter);
export default WireUploadTemplateFormatter;
