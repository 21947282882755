export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**
 * @param {number} value
 * @param {string} [context]
 * @returns A string representation of the number, formatted as  USD currency for the
 * given context.
 */
export default function usd(value, context) {
    if (value?.toString() === '') return '';
    let val = value?.toString().length && !Number.isNaN(value) ? value : 0;
    if (context === 'input-focus') {
        return Number(val).toFixed(2);
    }
    if (Array.isArray(val)) return val; // TODO: This is due to omega-range needing to go away.
    // eslint-disable-next-line prefer-destructuring
    val = val?.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0];
    return usdFormatter.format(val, 'USD');
}
