ChangePasswordModalController.$inject = [
    '$scope',
    '$modalInstance',
    'accountService',
    'pagePushService',
];

export default function ChangePasswordModalController(
    $scope,
    $modalInstance,
    accountService,
    pagePushService
) {
    // variables
    $scope.form = null;
    $scope.user = {};
    $scope.passwordsNotMatching = false;
    $scope.success = false;

    // functions
    $scope.setForm = setForm;
    $scope.close = close;
    $scope.cancel = close;
    $scope.save = save;

    // public functions
    function setForm(form) {
        $scope.form = form;
    }

    function close() {
        $modalInstance.dismiss();
    }

    function save() {
        if ($scope.user.newPassword !== $scope.user.confirmPassword) {
            $scope.passwordsNotMatching = true;
        } else {
            $scope.passwordsNotMatching = false;
            accountService.changePassword($scope.user).then(() => {
                $modalInstance.close();
            });
        }
    }

    function init() {
        const fiId = pagePushService.getUser().institution;
        accountService.getPasswordRequirements(fiId).then(response => {
            $scope.passwordRequirementsList = response;
        });
    }

    init();
}
