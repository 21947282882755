// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant('channel.constants').constant(
    'remoteDepositTypes',
    Object.freeze({
        UNDEFINED: 0,
        WAUSAU: 1,
        SMARTPAY: 2,
        properties: {
            1: { value: 1, name: 'Wausau', description: 'Wausau Deposit 24/7' },
            2: {
                value: 2,
                name: 'SmartPay',
                description: 'ProfitStars Enterprise Payment Solutions',
            },
        },
    })
);
