/* eslint-disable @treasury/consistent-custom-element-name */
/* eslint-disable @treasury/filename-match-export */
import '@treasury/omega/components/omega-detail-row-content.js';
import { css, html, LitElement, nothing } from 'lit';
import { achReturnActivityDetailRowLayout } from './data/v2/ach-return-activity-detail-row-layout.js';

class AchReturnActivityDetailRowV2 extends LitElement {
    static get properties() {
        return {
            parentRecord: Object,
            closeFunction: Object,
            client: Object,
            downloadOptionsOpen: Boolean,
        };
    }

    constructor() {
        super();
        this.downloadOptionsOpen = false;
        this.downloadOptions = ['PDF', 'CSV', 'NACHA'];
    }

    async _detailDownload(e) {
        if (e.type === 'click' || e.keyCode === 13) {
            this.toggleDownloadDropdown();
            this.dispatchEvent(
                new CustomEvent('saveReturn', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        guid: this.parentRecord.getField('achReturnTransactionUniqueId'),
                        type: e.target.innerText,
                    },
                })
            );
        }
    }

    _detailPrint() {
        this.dispatchEvent(
            new CustomEvent('detailPrint', {
                bubbles: true,
                composed: true,
                detail: this.parentRecord.getField('achReturnTransactionUniqueId'),
            })
        );
        // window.print();
        const printWindow = window.open();
        printWindow.document.write(`
        <head>
            <style>
                body {
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                    font-size: 18px;
                }
                h1 {
                    font-size: 24px;
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }
                li {
                    padding: 8px 0;
                    font-weight: 300;
                    color: #444;
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                }
                li > span {
                    font-weight: 400;
                    font-size: 16px;
                }
                .bold {
                    font-weight: 700;
                }
                .small {
                    font-size: 14px;
                }
                .italic {
                    font-style: italic;
                }
            </style>
            <body>
                <h1>ACH Return Activity Detail</h1>
                <ul>
                    <li>Original Effective Date: <span id="effectiveDate"></span></li>
                    <li>Date Return Received: <span id="receivedDate"></span></li>
                    <li>ACH Company: <span id="achCompanyName"></span></li>
                    <li>SEC Code: <span id="secCode"></span></li>
                    <li>Recipient Name: <span id="recipientName"></span></li>
                    <li>Recipient Financial Institution: <span id="recipientFinancialInstitution"></span></li>
                    <li>Recipient Account Number: <span id="accountNumber"></span></li>
                    <li>Account Type: <span id="accountType"></span></li>
                    <li>Amount: <span id="amount"></span></li>
                    <li>Identification Number: <span id="identificationNumber"></span></li>
                    <li>Trace Number: <span id="traceNumber"></span></li>
                    <li>Return Reason: <span id="returnReason"></span></li>`);
        if (this.parentRecord.getField('dateOfDeath')) {
            printWindow.document.write(`<li>Date Of Death: <span id="dateOfDeath"></span></li>`);
        }
        printWindow.document.write(`       </ul>
            </body>
        </head>
        `);

        this._fetchAndPrintRecord(printWindow.document, 'effectiveDate');
        this._fetchAndPrintRecord(printWindow.document, 'receivedDate');
        this._fetchAndPrintRecord(printWindow.document, 'achCompanyName');
        this._fetchAndPrintRecord(printWindow.document, 'companyEntryDescription');
        this._fetchAndPrintRecord(printWindow.document, 'secCode');
        this._fetchAndPrintRecord(printWindow.document, 'recipientName');
        this._fetchAndPrintRecord(printWindow.document, 'recipientFinancialInstitution');
        this._fetchAndPrintRecord(printWindow.document, 'accountNumber');
        this._fetchAndPrintRecord(printWindow.document, 'accountType');
        this._fetchAndPrintRecord(printWindow.document, 'amount');
        this._fetchAndPrintRecord(printWindow.document, 'identificationNumber');
        this._fetchAndPrintRecord(printWindow.document, 'traceNumber');
        this._fetchAndPrintRecord(printWindow.document, 'returnReason');
        this._fetchAndPrintRecord(printWindow.document, 'dateOfDeath');

        printWindow.stop();
        printWindow.print();
        printWindow.close();
    }

    _fetchAndPrintRecord(document, record) {
        const field = document.getElementById(record);
        const text = this.parentRecord.print(record);
        if (text) {
            field.textContent = this.parentRecord.print(record);
        }
    }

    toggleDownloadDropdown() {
        this.downloadOptionsOpen = !this.downloadOptionsOpen;
    }

    isDownloadDropdownOpen() {
        return this.downloadOptionsOpen === true;
    }

    renderDownloadDropdown() {
        if (this.isDownloadDropdownOpen()) {
            return html`<ul
                class="download-dropdown"
                role="menu"
                aria-hidden=${this.isDownloadDropdownOpen()}
            >
                ${this.downloadOptions.map(
                    option =>
                        html`<li
                            role="menuitem"
                            class="download-dropdown-item"
                            @click=${e => this._detailDownload(e)}
                            @keyup=${e => this._detailDownload(e)}
                        >
                            ${option}
                        </li>`
                )}
            </ul>`;
        }
        return nothing;
    }

    render() {
        if (this.parentRecord) {
            const layout = achReturnActivityDetailRowLayout(this.parentRecord);
            return html`
                <div class="detail-row-container">
                    <div class="detail-row-header">
                        <div>
                            <span class="detail-row-title">ACH Return Detail</span>
                        </div>
                        <div>
                            <omega-button
                                type="icon"
                                icon="download"
                                @click=${this.toggleDownloadDropdown}
                                hideLabel="always"
                            ></omega-button>
                            ${this.renderDownloadDropdown()}
                            <omega-button
                                type="icon"
                                icon="print"
                                @click=${this._detailPrint}
                                hideLabel="always"
                            ></omega-button>
                            <omega-button
                                class="close-button"
                                type="icon"
                                icon="close"
                                @click=${this.closeFunction}
                                hideLabel="always"
                            ></omega-button>
                        </div>
                    </div>
                    <omega-detail-row-content
                        .contentColumns=${layout}
                        .record=${this.parentRecord}
                    ></omega-detail-row-content>
                </div>
            `;
        }

        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            div.detail-row-container {
                position: relative;
                padding: 0px;
                padding-bottom: 8px;
                margin-top: 0px;
            }
            div.detail-row-header {
                height: 30px;
                padding: 4px 17px;
                padding-right: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .detail-row-title {
                padding-top: 0px;
                margin-top: 0px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #333333;
            }
            .close-button {
                opacity: 0.6;
            }
            .detail-header {
                height: 30px;
                padding: 10px 17px 20px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .download-dropdown {
                position: absolute;
                top: 25px;
                z-index: 5;
                min-width: 50px;
                padding: 0;
                border: 1px solid var(--omega-white-150);
                background: var(--omega-white);
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                list-style: none;
            }
            .download-dropdown li {
                min-width: 50px;
                padding: 10px 25px;
                text-align: left;
                color: var(--omega-primary);
                cursor: pointer;
            }

            .download-dropdown li:hover {
                background-color: var(--omega-white-100);
            }

            .download-dropdown a {
                text-decoration: none;
            }
        `;
    }
}

export default AchReturnActivityDetailRowV2;
window.customElements.define('ach-return-activity-detail-row-v2', AchReturnActivityDetailRowV2);
