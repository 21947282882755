import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UsersService } from '@treasury/domain/channel/services/users/users-service.ts';

export default class TransferActivityReportClient {
    async fetchApprovers(...args) {
        return UsersService.fetchApprovers(...args);
    }

    async getEntitlements() {
        return EntitlementsService.instance.getEntitlements();
    }
}
