// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

/**
 * Static class grouping calls associated with ACH File Activity.
 */
class AchFileActivityRequests {
    //
    static async fetchFileActivity(body) {
        return (await TmHttpClient.getInstance()).request(`achFileActivity`, {
            method: 'POST',
            body,
        });
    }

    static async fetchFileSources() {
        return (await TmHttpClient.getInstance()).request(`achFileActivity/getACHSourceList`, {
            method: 'GET',
            maxAgeInSeconds: 600,
        });
    }
}

export default AchFileActivityRequests;
