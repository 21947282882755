// eslint-disable-next-line import/no-extraneous-dependencies
import { printNode } from '@treasury/utils';
import { html, nothing, render } from 'lit';
import openDialog from '../../open-dialog.js';
import '../omega-tabs.js';
import StandardColumn from './standard-column.js';

export default class CheckImageColumn extends StandardColumn {
    constructor(...args) {
        super(...args);
        const [{ fetchCheckImages }] = args;
        this.fetchCheckImages = fetchCheckImages;
        this.tabs = [
            {
                label: 'Front View',
                id: 'front',
            },
            {
                label: 'Back View',
                id: 'back',
            },
            { label: 'Both Sides', id: 'both' },
        ];
        this.activeTab = 'front';
    }

    switchCheckViewTab(detail) {
        this.activeTab = detail.activeTab;
        const dialogTabs = Array.from(
            document.querySelector('omega-dialog').shadowRoot.querySelectorAll('[role="tabpanel"]')
        );
        dialogTabs.forEach(tab => {
            tab.style.display = 'none';
        });
        const selectedTab = document
            .querySelector('omega-dialog')
            .shadowRoot.querySelector(`#${this.activeTab}`);
        if (selectedTab) {
            selectedTab.style.display = 'block';
        }
    }

    async openCheckImageDialog(checkImageNumber, record) {
        this.anchor.dispatchEvent(
            new CustomEvent('loadingCheckImage', {
                detail: true,
                bubbles: true,
                composed: true,
            })
        );
        const checkImages = await this.fetchCheckImages(checkImageNumber);
        this.anchor.dispatchEvent(
            new CustomEvent('loadingCheckImage', {
                detail: false,
                bubbles: true,
                composed: true,
            })
        );
        const dialog = document.createElement('omega-dialog');
        const printElement = document.createElement('print-element');
        render(this.renderPrintElement(record, checkImages), printElement);
        dialog.addEventListener('print', () => {
            printNode('Check Information', printElement);
        });

        openDialog(dialog, {
            title: 'View Images',
            headerActions: ['print'],
            content: this.renderCheckImageDialog(record, checkImages),
        });
    }

    renderFrontOfCheck(checkImages) {
        if (checkImages.frontImage) {
            return html`<img
                src="data:image/jpeg;base64,${encodeURI(checkImages.frontImage)}"
                alt="Front of check"
                style="width: 100%"
            />`;
        }
        return nothing;
    }

    renderBackOfCheck(checkImages) {
        if (checkImages.backImage) {
            return html`<img
                src="data:image/jpeg;base64,${encodeURI(checkImages.backImage)}"
                alt="Back of check"
                style="width: 100%"
            />`;
        }
        return nothing;
    }

    renderPrintElement(record, checkImages) {
        return html`
            <div>
                ${this.renderTableData(record)} ${this.renderFrontOfCheck(checkImages)}
                ${this.renderBackOfCheck(checkImages)}
            </div>
        `;
    }

    renderCheckImageDialog(record, checkImages) {
        return html`
            <omega-tabs
                .tabs=${this.tabs}
                .activeTab=${this.activeTab}
                @switchTab=${({ detail }) => this.switchCheckViewTab(detail)}
            ></omega-tabs>
            <div role="tabpanel" id="front" active=${this.activeTab === 'front'}>
                ${this.renderTableData(record)}
                <div style="margin: 10px; text-align: center">
                    ${this.renderFrontOfCheck(checkImages)}
                </div>
            </div>
            <div role="tabpanel" id="back" active=${this.activeTab === 'back'}>
                ${this.renderTableData(record)}
                <div style="margin: 10px; text-align: center">
                    ${this.renderBackOfCheck(checkImages)}
                </div>
            </div>
            <div role="tabpanel" id="both" active=${this.activeTab === 'both'}>
                ${this.renderTableData(record)}
                <div style="margin: 10px; text-align: center">
                    ${this.renderFrontOfCheck(checkImages)} ${this.renderBackOfCheck(checkImages)}
                </div>
            </div>
        `;
    }

    renderTableData(record) {
        return html`<table style="margin: 10px auto;">
            <tr>
                <td>Account Number:</td>
                <td>${record.print('accountNumber')}</td>
            </tr>
            <tr>
                <td>Check Number:</td>
                <td>${record.print('checkNumber')}</td>
            </tr>
            <tr>
                <td>Posted Date:</td>
                <td>${record.print('postDate')}</td>
            </tr>
            <tr>
                <td>Issued Amount:</td>
                <td>${record.print('issuedAmount')}</td>
            </tr>
            <tr>
                <td>Paid Amount:</td>
                <td>${record.print('paidAmount')}</td>
            </tr>
        </table>`;
    }

    renderTdContents(record) {
        const checkImageNumber = record.getField('checkImageNumber');
        const checkNumber = record.getField(this.field);
        if (checkImageNumber) {
            return html`
                <a
                    id="check${checkImageNumber}"
                    href="#check-image-for-${checkNumber}"
                    @click=${event => {
                        this.anchor = event.target;
                        event.preventDefault();
                        this.openCheckImageDialog(checkImageNumber, record);
                    }}
                    >View Check ${checkNumber}
                </a>
            `;
        }
        return checkNumber;
    }
}
