/* eslint-disable no-use-before-define */
import { AnalyticsService } from '@treasury/core/analytics/analytics.service';
import { AnalyticsEvent } from '@treasury/core/analytics/analytics.types';
import { getChannelAPIPath } from '@treasury/core/http';

ElectronicDocumentsService.$inject = ['$resource', '$http', 'TmDi'];

/**
 * @typedef { import('@jack-henry/frontend-utils/di').DiContainer } DiContainer
 */

/**
 *
 * @param { ng.resource.IResourceService } $resource
 * @param { ng.IHttpService } $http
 * @param { DiContainer } TmDi
 * @returns
 */
export default function ElectronicDocumentsService($resource, $http, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}users/`;
    const analytics = TmDi.get(AnalyticsService);

    return {
        getElectronicDocumentSso,
        ssoFormPost,
        getAdditionalSettingsAndAccounts,
        getReportList,
        getReport,
    };

    function getElectronicDocumentSso() {
        return $resource(
            `${resourceUrl}getElectronicDocumentSso`,
            {},
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }

    function ssoFormPost(url, data) {
        return $http({
            method: 'POST',
            url,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            transformRequest(obj) {
                const str = [];
                for (const p in obj)
                    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
                return str.join('&');
            },
            data,
        }).then(response => response.data);
    }

    function getAdditionalSettingsAndAccounts(electronicDocumentTypeId) {
        return $resource(
            `${resourceUrl}electronicdocuments/` + `:id/additionalsettingsandaccounts`,
            { id: electronicDocumentTypeId },
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }

    function getReportList(productName, accountId) {
        return $resource(
            `${getChannelAPIPath()}electronicdocuments/` + `:name/reportlist/:id`,
            {
                name: productName,
                id: accountId,
            },
            { get: { method: 'GET', isArray: true } }
        ).get().$promise;
    }

    function getReport(productName, reportId, reportName) {
        const p = $http.post(
            `${getChannelAPIPath()}electronicdocuments/download`,
            {
                productName,
                reportId,
                reportName,
            },
            {
                responseType: 'arraybuffer',
            }
        );

        p.then(() => trackReportDownload(analytics, productName, reportName));
        return p;
    }
}

/**
 * @param { AnalyticsService } analytics
 * @param { string } product
 * @param { string } report
 */
function trackReportDownload(analytics, product, report) {
    analytics.track(AnalyticsEvent.GetElectronicDocs, {
        product,
        report,
    });
}
