/* eslint-disable no-use-before-define */
/* eslint-disable lit-a11y/click-events-have-key-events */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { RecordsetEvent, boolean } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-workflow.js';
import '@treasury/omega/components/progress/omega-progress';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../child-support-workflow-steps/confirm-step.ts';
import '../child-support-workflow-steps/create-step.ts';
import '../child-support-workflow-steps/manage-recipients-step.ts';
import '../child-support-workflow-steps/review-step.ts';
import ChildSupportClient from '../clients/child-support-workflow-client.ts';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration.ts';
import fillMeInChildSupport from '../data/fill-me-in-child-support.js';
import { paymentFields, paymentHeaderRecord } from '../data/payment-header-record.js';
import { defaultRecipient, recipientRecordset } from '../data/recipient-recordset.js';

class ChildSupportWorkflowContainer extends mix(LitElement).with(
    ListeningElementMixin,
    channelAlertMixin
) {
    static get properties() {
        return {
            alert: Object,
            activeStep: Number,
            client: Object,
            institution: String,
            paymentHeaderRecord: Object,
            recipientsRecordset: Object,
            fields: Array,
            paymentFile: Object,
            isUploading: Boolean,
            paymentLoading: Boolean,
            payment: Object,
            paymentCreationType: String,
            stepLabels: Array,
            downloading: Boolean,
            cutoffTimes: Object,
            workflowType: String,
            savedAsTemplate: Boolean,
            templateValidationLoading: Boolean,
        };
    }

    constructor() {
        super();
        this.client = new ChildSupportClient();
        this.loading = true;
        this.activeStep = 0;
        this.recipientsRecordset = null;
        this.recipients = [defaultRecipient()];
        this.isUploading = false;
        this.paymentsPendingProcessFeatureFlag = false;
    }

    async fillMeIn() {
        await fillMeInChildSupport(
            this.paymentHeaderRecord,
            this.recipientsRecordset,
            this.activeStep
        );
    }

    get hasTemplatePermissions() {
        if (!this.entitlements.length) return false;
        const childSupportPermission = this.entitlements.some(
            e => e.permission === 'Feature.ACH.AllowChildSupportPayments'
        );
        const templatePermission = this.entitlements.some(
            e => e.permission === 'Feature.Ach.ChildSupportPaymentTemplates'
        );
        const hasBatchEntitlement = this.entitlements.some(
            e => e.permission === 'Create Ach Template'
        );
        return childSupportPermission && templatePermission && hasBatchEntitlement;
    }

    async firstUpdated() {
        const navService = await getNavService();
        const { params } = await navService.getRouteData();
        const featureService = await FeatureFlagService.getInstance();

        this.paymentsPendingProcessFeatureFlag = await featureService.isEnabled(
            Feature.PaymentsPendingProcess
        );
        [this.workflowType, this.paymentCreationType, this.id] = window.location.pathname
            .split('/')
            .slice(-3);
        this.workflowType = this.workflowType ?? params.paymentState?.type;
        this.paymentCreationType =
            this.paymentCreationType ?? params.paymentState?.paymentCreationType;
        this.id = this.id ?? params.paymentState?.id;

        try {
            /**
             * loading achBanks behind the scene because it takes so long - won't wait for it on step one
             */
            this.client.achBanks();
            this.achConfiguration = await this.client.achConfiguration();
            this.achSettings = this.achConfiguration.achSettings;
            this.isSameDayAchEnabled = this.achConfiguration.allowSameDayPayments;
            this.holidays = this.achConfiguration.holidays;
            this.cutoffTimes = this.achConfiguration.cutoffTimes;
            this.companyMatchType = this.achConfiguration.companyMatchType;
            this.entitlements = this.achConfiguration.entitlements;
            await this.initPaymentHeaderRecord();
            this.initRecipientsRecordset();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async initPaymentHeaderRecord() {
        const navService = await getNavService();
        const { params } = await navService.getRouteData();

        this.fromPayment = !!params.paymentState?.payment;
        this.payment = params.paymentState?.payment ?? params.paymentState?.template;

        if (!this.payment && this.id) {
            if (this.workflowType === 'payment' && !this.id.includes('-')) {
                this.fromPayment = true;
                const response = await this.client.getChildSupportPayment(this.id);
                this.payment = {
                    ...response.payment,
                    companyIdentity: response.payment.achCompany.companyId,
                };
            } else {
                this.fromPayment = false;
                this.payment = await this.client.getChildSupportTemplate(this.id);
            }
        }
        this.setStepLabels();
        this.fields = paymentFields(
            { ...this.achConfiguration, type: this.workflowType, action: this.paymentCreationType },
            this.payment,
            this.client
        );
        this.paymentHeaderRecord = paymentHeaderRecord(
            { ...this.achConfiguration, type: this.workflowType, action: this.paymentCreationType },
            this.payment,
            this.client
        );
        const fromPaymentField = boolean;
        this.paymentHeaderRecord.addField('fromPayment', fromPaymentField, this.fromPayment);

        this.listenTo(this.paymentHeaderRecord, 'change', ({ detail }) => {
            const changedField = detail.field;
            if (changedField === 'achCompany') {
                const company = this.paymentHeaderRecord.getField('achCompany');
                if (!company) return;
                this.paymentHeaderRecord.setField('achCompanyName', company.companyName);
                this.paymentHeaderRecord.setField('achCompanyId', company.companyId);
                this.paymentHeaderRecord.setField('secCode', company.secCodes[0].code);
                this.paymentHeaderRecord.setField(
                    'entryDescription',
                    company.entryDescription ?? ''
                );
                this.paymentHeaderRecord.setField(
                    'discretionaryData',
                    company.discretionaryData ?? ''
                );
            }
        });
        this.listenTo(this.paymentHeaderRecord, 'blur', async ({ detail }) => {
            const { field } = detail;
            if (field === 'name' && this.workflowType === 'template') {
                await this.validateUniqueTemplateName();
            }
        });
    }

    async initRecipientsRecordset() {
        const navService = await getNavService();
        const { params } = await navService.getRouteData();
        const recipients =
            params.paymentState?.recipients ??
            this.payment?.recipients?.map(r => ({
                ...r,
                ...(r.addenda[0] ?? r.addenda),
                addendaId: r.addenda[0] ? r.addenda[0].id : r.addenda.id,
            }));

        if (recipients) {
            this.recipients = recipients;
        }

        this.recipientsRecordset = recipientRecordset(
            this.recipients,
            !!this.paymentFile,
            this.workflowType,
            { ...this.achConfiguration, type: this.workflowType, action: this.paymentCreationType }
        );
        await this.recipientsRecordset.requestUpdate();
        this.listenTo(this.recipientsRecordset, RecordsetEvent.Updated, ({ detail }) => {
            if (!detail) return;
            if (detail.field === 'prenote' && detail.record !== undefined)
                this.zeroAmountOnRecord(detail.record);
            this.recipients = this.recipientsRecordset.getData();
            if (detail.field === 'amount' || detail.field === 'hold') {
                this.calculateCreditAmount();
            }
        });
        this.listenTo(this.recipientsRecordset, RecordsetEvent.RecordDeleted, () => {
            this.recipients = this.recipientsRecordset.getData();
            this.calculateCreditAmount();
        });
    }

    async validateUniqueTemplateName() {
        const name = this.paymentHeaderRecord.getField('name');
        const initialName = this.paymentHeaderRecord.initialValues.name;
        if (this.templateValidationLoading) return; // debouncing with state
        if (name === initialName) {
            this.templateNameIsValid = true;
            return;
        }
        this.templateValidationLoading = true;
        this.loading = true;
        // button not re-rendering here
        this.templateNameIsValid = await this.client.isUniqueTemplateName(name);
        this.templateValidationLoading = false;
        this.loading = false;
        if (!this.templateNameIsValid) {
            this.alert = {
                ...this.alert,
                title: 'Template Name Error',
                posture: 'assertive',
                type: 'warning',
                visible: true,
                message: `Template name "${name}" already exists. Template names must be unique.`,
            };
        }
    }

    calculateCreditAmount() {
        const amounts = this.recipients.filter(r => !r.hold).map(r => r.amount);
        const total = amounts.reduce((acc, curr) => acc + curr, 0);
        this.paymentHeaderRecord.setField('creditAmount', total);
    }

    zeroAmountOnRecord(record) {
        record.setField('amount', 0);
    }

    async nextStep() {
        if (this.activeStep === 3) return;
        if (this.activeStep === 0 && this.workflowType === 'template') {
            await this.validateUniqueTemplateName();
            if (!this.templateNameIsValid) {
                return;
            }
        }
        this.setScrollPosition(0, 0);
        this.activeStep++;
        this.paymentHeaderRecord.setField('step', this.activeStep);
        this.recipientsRecordset.setColumnValue('step', this.activeStep);
    }

    previousStep() {
        this.activeStep--;
        this.paymentHeaderRecord.setField('step', this.activeStep);
        this.recipientsRecordset.setColumnValue('step', this.activeStep);
    }

    setScrollPosition(x, y) {
        document.querySelector('#sectionView').scrollTo(x, y);
    }

    cancel() {
        window.history.go(-1);
    }

    async goToAchPaymentActivity() {
        return (await getNavService()).navigate(`payables.ach.payment-list`);
    }

    async goToAchTemplates() {
        return (await getNavService()).navigate(`payables.ach.payments.child-support-templates`);
    }

    async uploadFile(e) {
        const file = e.detail;
        this.isUploading = true;
        try {
            this.paymentFile = await this.client.uploadNachaFile(file);
            if (this.paymentFile.errorSummary?.summaryMessageList) {
                this.alert = {
                    ...this.alert,
                    title: '',
                    message: this.paymentFile.errorSummary.summaryMessageList.map(
                        message => message
                    ),
                    type: 'error',
                    visible: true,
                };
            } else {
                this.paymentHeaderRecord = paymentHeaderRecord(
                    this.achConfiguration,
                    this.paymentFile,
                    this.client
                );
                this.recipients = this.paymentFile.recipients;
                this.recipientsRecordset = recipientRecordset(this.recipients, !!this.paymentFile);
                this.paymentHeaderRecord.setField('step', 1);
                this.activeStep = 1;
            }
        } catch (err) {
            this.setAlertFromError(e);
        } finally {
            this.isUploading = false;
        }
    }

    async updateTemplate() {
        return this.client.updateTemplate(
            this.paymentHeaderRecord.values,
            this.recipientsRecordset.getData()
        );
    }

    async submitPayment(fromTemplate) {
        const editChildSupportTemplate =
            this.paymentCreationType === 'edit' && this.workflowType === 'template';
        const applyUpdatesToTemplate =
            this.paymentCreationType === 'initiate' &&
            this.workflowType === 'payment' &&
            fromTemplate;
        try {
            this.paymentLoading = true;

            if (this.paymentFile) {
                this.payment = await this.client.submitFileAsPayment(
                    this.paymentHeaderRecord.values,
                    this.recipientsRecordset.getData()
                );
            } else if (editChildSupportTemplate || applyUpdatesToTemplate) {
                const response = await this.updateTemplate();
                this.payment = { payment: response };
            } else if (
                this.paymentHeaderRecord.getField('id') &&
                this.paymentCreationType !== 'initiate'
            ) {
                this.payment = await this.client.updatePayment(
                    this.paymentHeaderRecord.values,
                    this.recipientsRecordset.getData()
                );
            } else {
                this.payment = await this.client.submitPayment(
                    this.paymentHeaderRecord.values,
                    this.recipientsRecordset.getData()
                );
            }

            document.querySelector('#sectionView').scrollTop = 0;

            // cancel trying to make a payment - no alert
            if (this.payment.securityMessage?.errorCode) {
                this.paymentLoading = false;
                return;
            }
            // edit template error
            if (this.payment?.payment?.responseDetailCollection?.length > 0) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    title: '',
                    message: this.payment?.payment?.responseDetailCollection.map(
                        e => html`<li>Code ${e.responseCode}: ${e.responseMessage}</li>`
                    ),
                };
                this.paymentLoading = false;
                return;
            }

            const responseKey =
                this.paymentHeaderRecord.getField('id') &&
                this.paymentCreationType !== 'initiate' &&
                this.workflowType !== 'template'
                    ? 'childSupportAchPayment'
                    : 'payment';
            const paymentHeader = this.payment[responseKey];
            this.id = paymentHeader.id ?? paymentHeader.templateId;
            if (!applyUpdatesToTemplate) {
                this.paymentHeaderRecord.setField(
                    'audit',
                    paymentHeader.audit ?? paymentHeader.templateAudit
                );
                this.paymentHeaderRecord.setField('transactionId', paymentHeader.transactionId);
                this.paymentHeaderRecord.setField(
                    'status',
                    paymentHeader.status ?? paymentHeader.templateStatus
                );
            }

            this.nextStep();
            this.showSuccessAlert(applyUpdatesToTemplate);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.setAlertFromError(e);
        } finally {
            this.paymentLoading = false;
        }
    }

    showSuccessAlert(applyToTemplate) {
        if (this.workflowType === 'payment' && !applyToTemplate) {
            const paymentStatus = this.paymentHeaderRecord.getField('status');
            const alertType =
                this.paymentsPendingProcessFeatureFlag && paymentStatus === 'Pending Process'
                    ? 'info'
                    : 'success';

            const message =
                this.paymentHeaderRecord.getField('status') === 'Pending Approval'
                    ? 'ACH payment is pending approval.'
                    : 'ACH payment is processing.';
            this.alert = {
                ...this.alert,
                code: null,
                time: null,
                title: '',
                message: html`${message}
                    <span class="link" @click=${this.goToAchPaymentActivity}>
                        Please review ACH Payment Activity
                    </span>`,
                type: alertType,
                visible: true,
            };
        } else {
            this.alert = {
                ...this.alert,
                code: null,
                time: null,
                title: '',
                message: html`ACH Child Support Template saved.
                    <span class="link" @click=${this.goToAchTemplates}>
                        Please review ACH Child Support Templates
                    </span>`,
                type: 'success',
                visible: true,
            };
        }
    }

    async download({ detail }) {
        this.downloading = true;
        try {
            await this.client[CONTAINER_CONFIGURATION[this.workflowType].downloadClientService](
                this.id,
                detail.downloadType,
                CONTAINER_CONFIGURATION[this.workflowType].downloadKey
            );
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.downloading = false;
        }
    }

    async print() {
        await this.client.print('childSupportFullPage');
        window.print();
    }

    async savePaymentAsTemplate() {
        this.paymentLoading = true;
        try {
            const response = await this.client.saveAsTemplate(
                this.paymentHeaderRecord.values,
                this.recipientsRecordset.getData()
            );
            const errors = response.responseDetailCollection;
            if (errors.length) {
                const renderedErrors = errors.map(
                    e => html`<li>${e.responseCode}: ${e.responseMessage}</li>`
                );
                this.alert = {
                    ...this.alert,
                    title: 'Error',
                    visible: true,
                    type: 'error',
                    message: html`<p>Template creation failed with the following errors:</p>
                        <ul>
                            ${renderedErrors}
                        </ul>`,
                };
            } else {
                this.alert = {
                    ...this.alert,
                    title: '',
                    visible: true,
                    type: 'success',
                    message: html`Template Saved!
                        <span class="link" @click=${this.goToAchTemplates}>
                            Review ACH Child Support Templates
                        </span>`,
                };
                this.savedAsTemplate = true;
            }
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.paymentLoading = false;
            document.querySelector('#sectionView').scrollTop = 0;
        }
    }

    setStepLabels() {
        const sharedLabels = ['Review', 'Confirmation'];
        if (this.workflowType === 'payment') {
            if (this.paymentCreationType === 'file') {
                this.stepLabels = ['Upload File', 'Payment Details', ...sharedLabels];
            } else if (this.paymentCreationType === 'edit') {
                this.stepLabels = ['Edit Payment', 'Manage Recipients', ...sharedLabels];
            } else if (this.paymentCreationType === 'initiate') {
                this.stepLabels = ['Initiate Payment', 'Manage Recipients', ...sharedLabels];
            } else {
                this.stepLabels = ['Create Payment', 'Manage Recipients', ...sharedLabels];
            }
        }
        if (this.workflowType === 'template') {
            if (this.paymentCreationType === 'file') {
                this.stepLabels = ['Upload File', 'Template Details', ...sharedLabels];
            } else if (this.paymentCreationType === 'edit') {
                this.stepLabels = ['Edit Template', 'Manage Recipients', ...sharedLabels];
            } else {
                this.stepLabels = ['Create Template', 'Manage Recipients', ...sharedLabels];
            }
        }
    }

    async resetForNewPayment() {
        const navService = await getNavService();

        this.paymentHeaderRecord.reset();
        this.recipients = [defaultRecipient()];
        this.initRecipientsRecordset();
        this.savedAsTemplate = false;

        return navService.navigate('payables.ach.payments.child-support-workflow');
    }

    promptForCancellation() {
        this.alert = {
            ...this.alert,
            type: 'warning',
            posture: 'assertive',
            visible: true,
            message: `Are you sure you want to cancel? Select 'Cancel' to return to ACH Payment Activity or Continue Editing.`,
            actions: html`<omega-button type="reject" @click=${this.goToAchPaymentActivity}
                    >Cancel</omega-button
                ><omega-button
                    @click=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                    >Continue Editing</omega-button
                >`,
        };
    }

    renderLoader() {
        if (this.loading) {
            return html`<omega-progress card></omega-progress>`;
        }
        return nothing;
    }

    renderBlockingLoader() {
        if (this.paymentLoading || this.templateValidationLoading) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    renderDownloadDialog() {
        if (!this.paymentHeaderRecord) return nothing;
        const name = this.paymentHeaderRecord.getField('name');
        const downloadTitle = `Downloading ${name}`;
        if (this.downloading) {
            return html`<omega-dialog
                id="download-dialog"
                open
                .dialogTitle=${downloadTitle}
                @close=${() => {
                    this.downloading = false;
                }}
            >
                <omega-progress card></omega-progress>
            </omega-dialog>`;
        }
        return nothing;
    }

    renderStep() {
        if (!this.paymentHeaderRecord) return nothing;
        const currentStep = this.paymentHeaderRecord.getField('step');
        const headerText = `${
            CONTAINER_CONFIGURATION[this.workflowType]?.title
        } Header Information`;
        switch (currentStep) {
            case 0:
                return html`<create-step
                    .institution=${this.institution}
                    .activeStep=${this.activeStep}
                    .header=${headerText}
                    .paymentHeaderRecord=${this.paymentHeaderRecord}
                    .creationType=${this.paymentCreationType}
                    .workflowType=${this.workflowType}
                    .fields=${this.fields}
                    .isUploading=${this.isUploading}
                    @fileUploaded=${this.uploadFile}
                    @changePaymentCreationType=${({ detail }) => {
                        this.paymentCreationType = detail;
                        if (this.paymentCreationType === 'manual') this.paymentFile = null;
                        this.setStepLabels();
                    }}
                    @next=${this.nextStep}
                    @cancel=${this.promptForCancellation}
                ></create-step>`;
            case 1:
                return html` <manage-recipients-step
                    .activeStep=${this.activeStep}
                    .paymentHeaderRecord=${this.paymentHeaderRecord}
                    .recipientsRecordset=${this.recipientsRecordset}
                    .recipients=${this.recipients}
                    .entitlements=${this.entitlements}
                    .action=${this.paymentCreationType}
                    .type=${this.workflowType}
                    .holidays=${this.holidays}
                    .isFromFile=${!!this.paymentFile}
                    @next=${this.nextStep}
                    @previous=${() => {
                        this.paymentFile = null;
                        this.paymentHeaderRecord.setField('offsetAccount', 'fillerValue');
                        this.previousStep();
                    }}
                    @cancel=${this.promptForCancellation}
                ></manage-recipients-step>`;
            case 2:
                return html` <review-step
                    .activeStep=${this.activeStep}
                    .loading=${this.paymentLoading}
                    .paymentHeaderRecord=${this.paymentHeaderRecord}
                    .recipientsRecordset=${this.recipientsRecordset}
                    .isFromFile=${!!this.paymentFile}
                    @next=${() => this.submitPayment()}
                    @previous=${this.previousStep}
                    @cancel=${this.promptForCancellation}
                ></review-step>`;
            case 3:
                return html`<confirm-step
                    .hasTemplatePermission=${this.hasTemplatePermissions}
                    .savedAsTemplate=${this.savedAsTemplate}
                    .activeStep=${this.activeStep}
                    .workflowType=${this.workflowType}
                    .paymentHeaderRecord=${this.paymentHeaderRecord}
                    .recipientsRecordset=${this.recipientsRecordset}
                    .isFromFile=${!!this.paymentFile}
                    .isFromPayment=${this.fromPayment}
                    .entitlements=${this.entitlements}
                    .validateTemplateName=${this.client.isUniqueTemplateName}
                    .paymentCreationType=${this.paymentCreationType}
                    @saveAsTemplate=${() => this.savePaymentAsTemplate()}
                    @updateTemplate=${() => this.submitPayment(true)}
                    @goToStep=${e => {
                        this.alert = { ...this.alert, title: '', visible: false };
                        this.activeStep = e.detail.activeStep;
                        if (this.activeStep === 0) {
                            this.resetForNewPayment();
                        }
                    }}
                    @goToAchActivity=${this.goToAchPaymentActivity}
                    @goToChildSupportTemplates=${this.goToAchTemplates}
                    @download=${this.download}
                    @print=${this.print}
                ></confirm-step>`;
            default:
                return this.renderLoader();
        }
    }

    renderWorkflow() {
        if (!this.stepLabels) return nothing;
        return html` <omega-workflow .activeStep=${this.activeStep} .stepLabels=${this.stepLabels}>
            <div slot="step">
                <div slot="content">${this.renderStep()}</div>
            </div>
        </omega-workflow>`;
    }

    render() {
        if (!this.stepLabels) return this.renderLoader();
        return html`
            ${this.renderAlert()}${this.renderBlockingLoader()}${this.renderDownloadDialog()}
            <h2 class="workflow-header">
                Child Support ${CONTAINER_CONFIGURATION[this.workflowType].title}
            </h2>
            <div class="child-support-workflow-container">${this.renderWorkflow()}</div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .child-support-workflow-container {
                position: relative;
                min-height: 60%;
                background-color: #fff;
                box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
            }
            omega-workflow {
                height: 100%;
            }
            .workflow-header {
                font-size: 24px;
                margin-top: 0;
                font-weight: 400;
            }
            div[slot='step'] {
                height: 100%;
            }
            div[slot='content'] {
                height: 100%;
            }
            .link {
                color: var(--omega-primary);
                text-decoration: underline;
                cursor: pointer;
            }
        `;
    }
}

customElements.define('child-support-workflow-container', ChildSupportWorkflowContainer);
export default ChildSupportWorkflowContainer;

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
