import { FrequencyType } from '@treasury/domain/channel/types/frequency';
import { isValidDate } from '../../utils/frequency';

export const repeatOnIsRequired = {
    name: 'Repeat On is a required field',
    validate: (val: any) => {
        if (!val) return false;

        if (val.type === FrequencyType.OneTime) {
            return true;
        }

        if (val.type === FrequencyType.TwiceAMonth) {
            return true;
        }

        if (val.type === FrequencyType.Monthly) {
            return true;
        }

        if (val.type === FrequencyType.Quarterly) {
            return true;
        }

        if (val.type === FrequencyType.EverySixMonths) {
            return true;
        }

        if (val.type === FrequencyType.Yearly) {
            return true;
        }

        // if (logging) console.log(`valid repeat on date: ${result}`);

        return isValidDate(val.repeatOn);
    },
};
