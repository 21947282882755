/* eslint-disable @typescript-eslint/no-explicit-any */

import { Record as FdlRecord, Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import { AlertMixin } from '@treasury/omega/mixins';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../components/ach-payment-recipients-table.js';
import {
    createChildSupportPaymentColumns,
    uploadNachaFileRecipientsColumns,
} from '../data/recipient-recordset.js';
import '../parts/child-support-payment-header';
import { recipientDetails } from '../parts/child-support-payment-recipient-details';

const ListeningAlerter = AlertMixin(ListeningElementMixin(LitElement));

export const ReviewStepTagName = 'review-step';

@customElement(ReviewStepTagName)
export class ReviewStep extends ListeningAlerter {
    @property({ type: Boolean })
    public loading = false;

    @property({ type: Object })
    public paymentHeaderRecord!: FdlRecord<Record<string, any>>;

    @property({ type: Number })
    public activeStep!: number;

    @property({ type: Object })
    public recipientsRecordset!: Recordset<unknown>;

    @property({ type: Array })
    public recipients!: Array<any>;

    @property({ type: Boolean })
    public canContinue = false;

    @property({ type: Boolean })
    public isFromFile = false;

    @property({ type: Object })
    public alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: 'Are you sure you want to cancel?',
        type: 'warning',
        posture: 'assertive' as const,
        actions: html`<div slot="actions">
            <omega-button class="ok" type="primary" @click=${() => this.dialogCancel()}
                >Ok</omega-button
            >
            <omega-button class="continue-editing" @click=${() => this.dialogContinueEditing()}
                >Continue Editing</omega-button
            >
        </div>` as any,
    };

    public get columns() {
        if (this.isFromFile) return uploadNachaFileRecipientsColumns(false);
        return createChildSupportPaymentColumns(false);
    }

    public firstUpdated() {
        this.listenTo(this.paymentHeaderRecord, 'change', () => this.canContinueToNextStep());
        this.canContinueToNextStep();
    }

    public dialogCancel() {
        this.dispatchEvent(new CustomEvent('cancel'));
    }

    public dialogContinueEditing() {
        this.alert = { ...this.alert, visible: false };
    }

    public canContinueToNextStep() {
        if (this.paymentHeaderRecord)
            this.canContinue =
                this.paymentHeaderRecord.isValid() && this.paymentHeaderRecord.hasRequiredValues();
    }

    public next() {
        this.dispatchEvent(new CustomEvent('next'));
    }

    public previous() {
        this.dispatchEvent(new CustomEvent('previous'));
    }

    public render() {
        const type = this.paymentHeaderRecord.getField('type') as string;
        const workflowTypeTitle = type.charAt(0).toUpperCase() + type.slice(1);
        return html`
            ${this.renderAlert()}
            <div class="omega-form-header">
                <h2 class="step-title">Review ${workflowTypeTitle}</h2>
                <span><span class="red">*</span> indicates Required field</span>
            </div>
            <child-support-payment-header
                expanded
                .paymentHeaderRecord=${this.paymentHeaderRecord}
                .recipientsRecordset=${this.recipientsRecordset}
                .activeStep=${this.activeStep}
            ></child-support-payment-header>
            <ach-payment-recipients-table
                .columns=${this.columns}
                .recipients=${this.recipients}
                .recipientsRecordset=${this.recipientsRecordset}
                .detailFunction=${recipientDetails}
                display-toggle-all
            ></ach-payment-recipients-table>
            <omega-button-bar position="bottom" alignment="left">
                <omega-button
                    class="confirm"
                    .loading=${this.loading}
                    @click=${() => this.next()}
                    .disabled=${!this.canContinue}
                    type="primary"
                    >Confirm</omega-button
                >
                <omega-button class="back" type="secondary" @click=${() => this.previous()}
                    >Back</omega-button
                >
                <omega-button
                    class="cancel"
                    type="secondary"
                    @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                    >Cancel</omega-button
                >
            </omega-button-bar>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-form-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .omega-form-header span.red {
                color: var(--omega-text-error);
            }
            .step-title {
                margin: 0;
            }
        `;
    }
}
