import { getChannelAPIPath } from '@treasury/core/http';

ResearchTransactionsService.$inject = ['$resource', '$http'];

export default function ResearchTransactionsService($resource, $http) {
    const resourceUrl = `${getChannelAPIPath()}researchTransactions/`;
    return {
        getResearchTransactionList,
    };

    function getResearchTransactionList(researchTransactionFilter) {
        return $http({ method: 'post', url: resourceUrl, data: researchTransactionFilter }).then(
            response => response.data
        );
    }
}
