// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

/**
 * Processing cutoff times for the various payment types
 */
export class ProcessingCutoffRequests {
    static async getCutoffTimes() {
        return (await TmHttpClient.getInstance()).request(`companyaccounts/cutoffTimes`, {
            method: 'GET',
        });
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default ProcessingCutoffRequests;
