import { LitElement, html, css } from 'lit';

class TransactionReportsContainer extends LitElement {
    static get properties() {
        return {};
    }

    constructor() {
        super();
        this.type = 'transaction';
    }

    render() {
        return html``;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}

customElements.define('transaction-reports-container', TransactionReportsContainer);
