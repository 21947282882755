ArpExceptionCorrectionModalController.$inject = [
    '$scope',
    '$modalInstance',
    'arpException',
    'allowEdit',
    'correction',
];

export default function ArpExceptionCorrectionModalController(
    $scope,
    $modalInstance,
    arpException,
    allowEdit,
    correction
) {
    // variables
    $scope.arpException = arpException;
    $scope.allowEdit = allowEdit;
    $scope.correction = correction;
    $scope.arpExceptionCorrected = {};
    $scope.form = null;

    // functions
    $scope.save = save;
    $scope.close = close;
    $scope.setForm = setForm;

    // public functions
    function save() {}

    function close() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }

    // private functions
}
