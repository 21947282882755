RecurringAchPaymentListController.$inject = [
    '$scope',
    'secCodesService',
    'achPaymentsService',
    'entitlementsService',
    '$state',
    'toaster',
    '$filter',
    'achCompaniesService',
    '$uibModal',
    'dateFilterService',
    'DatePeriod',
    'securityService',
];

export default function RecurringAchPaymentListController(
    $scope,
    secCodesService,
    achPaymentsService,
    entitlementsService,
    $state,
    toaster,
    $filter,
    achCompaniesService,
    $uibModal,
    dateFilterService,
    DatePeriod,
    securityService
) {
    $scope.filterObject = {};
    $scope.allItemsAreSelected = {};
    $scope.selectionList = [];
    $scope.list = [];
    $scope.achCompanies = [];

    $scope.search = search;
    $scope.resetSearch = resetSearch;
    $scope.setForm = setForm;
    $scope.cancel = cancel;
    $scope.canCancelPayment = canCancelPayment;
    $scope.hasPermission = hasPermission;
    $scope.selectOrDeselctAllPayments = selectOrDeselectAllPayments;
    $scope.updateSelectionList = updateSelectionList;
    $scope.calculateTotals = calculateTotals;
    $scope.goToRecurringPaymentDetail = goToRecurringPaymentDetail;
    $scope.bulkInitiate = bulkInitiate;
    $scope.batchUpdate = batchUpdate;
    $scope.checkEnability = checkEnability;
    $scope.createPayment = createPayment;
    $scope.setForm = setForm;
    $scope.isDataLoaded = false;
    $scope.filteredPayments = [];
    $scope.dummy = [];
    $scope.disableDownload = true;

    $scope.filterSearch = filterSearch;
    $scope.searchObj = { text: null };
    $scope.disableFilter = disableFilter;
    $scope.goToPaymentList = goToPaymentList;
    $scope.goToACHFileActivityList = goToACHFileActivityList;
    function setApprovalAttributes() {
        $scope.paymentList.map(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'achPayment',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredPayments = $filter('filter')($scope.paymentList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.paymentList ||
            $scope.paymentList.length <= 0
        ) {
            return true;
        }

        return (
            (!!row.name &&
                row.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.achCompanyName &&
                row.achCompanyName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.transactionId &&
                row.transactionId.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.secCode &&
                row.secCode.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.frequency.nextPaymentDate &&
                $filter('date')(row.frequency.nextPaymentDate, 'MM/dd/yyyy').indexOf(
                    $scope.searchObj.text
                ) !== -1) ||
            (!!row.frequency.initiatedDate &&
                $filter('date')(row.frequency.initiatedDate, 'MM/dd/yyyy').indexOf(
                    $scope.searchObj.text
                ) !== -1) ||
            (!!row.debitAmount &&
                $filter('currency')(row.debitAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.creditAmount &&
                $filter('currency')(row.creditAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.frequency.type &&
                row.frequency.type.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1)
        );
    }

    function disableFilter() {
        return $scope.paymentList ? $scope.paymentList.length <= 0 : true;
    }

    function goToPaymentList() {
        $state.go('payables.ach.payment-list');
    }

    function goToACHFileActivityList() {
        $state.go('payables.ach.fileactivity-list');
    }

    init();

    function init() {
        $scope.approvePaymentEntitlement = 'ACH, Payment, Approve';
        getSecCodes();
        $scope.dateOptions = ['Specific Date', 'Range'];
        $scope.amountOptions = ['Specific Amount', 'Range'];
        $scope.statusOptions = [
            'All',
            'Pending Approval',
            'Approval Rejected',
            'Cancelled',
            'Scheduled',
        ];
        $scope.frequencies = [
            {
                id: '2',
                name: 'Weekly',
                key: 'Weekly',
            },
            {
                id: '3',
                name: 'Every Two Weeks',
                key: 'EveryTwoWeeks',
            },
            {
                id: '4',
                name: 'Twice a Month',
                key: 'TwiceAMonth',
            },
            {
                id: '5',
                name: 'Monthly',
                key: 'Monthly',
            },
            {
                id: '6',
                name: 'Quarterly',
                key: 'Quarterly',
            },
            {
                id: '7',
                name: 'Every Six Months',
                key: 'EverySixMonths',
            },
            {
                id: '8',
                name: 'Annually',
                key: 'Yearly',
            },
        ];
        $scope.currentDate = moment().format('MM/DD/YYYY');

        achCompaniesService.getAll().then(data => {
            $scope.filterObject.achCompanyList = $scope.achCompanies = data;
            $scope.filterObject.achCompanyList.sort((a, b) =>
                a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase())
            );
            angular.forEach($scope.achCompanies, achCompany => {
                achCompany.isChecked = true;
            });
            resetSearch();
            achPaymentsService
                .search(prepareFilter($scope.filterObject), 'Recurring')
                .then(response => {
                    $scope.paymentList = response;
                    setApprovalAttributes();
                    $scope.filterSearch($scope.searchText);
                    if ($scope.paymentList.length === 0) {
                        $scope.sidebarContainerController.setCollapsed(false);
                    } else {
                        $scope.disableDownload = false;
                    }
                    $scope.isDataLoaded = true;
                });
        });
    }

    function resetSearch() {
        if ($scope.form) {
            $scope.form.$setPristine();
        }

        $scope.filterObject = {};
        $scope.filterObject.debitAmountType = $scope.amountOptions[0];
        $scope.filterObject.creditAmountType = $scope.amountOptions[0];
        $scope.filterObject.initiatedDateType = $scope.dateOptions[0];
        $scope.filterObject.nextPaymentDateType = $scope.dateOptions[0];

        $scope.filterObject.achCompanyList = [];
        angular.forEach($scope.achCompanies, achCompany => {
            achCompany.isChecked = true;
            $scope.filterObject.achCompanyList.push(achCompany);
        });
        $scope.filterObject.batchName = '';
        $scope.filterObject.status = 'All';
        $scope.filterObject.secCode = 'All';
        angular.forEach($scope.frequencies, frequency => {
            frequency.isChecked = true;
        });
    }

    function search() {
        achPaymentsService
            .search(prepareFilter($scope.filterObject), 'Recurring')
            .then(response => {
                if (response.length === 0) {
                    $scope.sidebarContainerController.setCollapsed(false);
                } else {
                    $scope.sidebarContainerController.setCollapsed(true);
                }
                $scope.searchObj.text = null;
                $scope.paymentList = response;
                setApprovalAttributes();
                $scope.filterSearch($scope.searchObj.text);

                if ($scope.paymentList.length === 0) {
                    $scope.disableDownload = true;
                } else {
                    $scope.disableDownload = false;
                }
            });
    }

    function createPayment() {
        $state.go('payables.ach.payments.create');
    }

    function getSecCodes() {
        secCodesService.getConfigcSecCodes().then(response => {
            $scope.secCodes = response;
            $scope.secCodes.unshift('All');
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function hasPermission(permission) {
        return entitlementsService.hasEntitlement(permission);
    }

    function selectOrDeselectAllPayments() {
        angular.forEach($scope.paymentList, payment => {
            if (payment.status === 'Pending Approval') {
                payment.isSelected = $scope.allItemsAreSelected.value;
                if ($scope.allItemsAreSelected.value === true) {
                    $scope.selectionList.push(payment);
                } else {
                    $scope.selectionList = [];
                }
            }
        });
    }

    function updateSelectionList(payment) {
        const count = 0;
        let allSelected = true;

        if (payment.isSelected === true) {
            $scope.selectionList.push(payment);
        } else if (payment.isSelected === false) {
            $scope.selectionList.splice($scope.selectionList.indexOf(payment), 1);
        }

        angular.forEach($scope.paymentList, payment => {
            if (payment.status === 'Pending Approval') {
                if (!payment.isSelected) {
                    allSelected = false;
                }
            }
        });

        if (allSelected) {
            $scope.allItemsAreSelected.value = true;
        } else {
            $scope.allItemsAreSelected.value = false;
        }
    }

    function canCancelPayment(payment) {
        const cancelStatuses = [
            'Pending Approval',
            'Approval Rejected',
            'Uninitiated',
            'Expired',
            'Scheduled',
        ];
        const isInternational = payment.transactionId.slice(-2) === '-I';
        if (cancelStatuses.includes(payment.status)) {
            if (isInternational && hasPermission('Delete International ACH Payment')) {
                return true;
            }
            if (!isInternational && hasPermission('Delete Ach Payment')) {
                return true;
            }
        }
        return false;
    }

    function goToRecurringPaymentDetail(payment) {
        if (achPaymentsService.isInternationalAchPayment(payment)) {
            $state.go('payables.ach.international-ach-payment-details', { id: payment.id });
        } else {
            $state.go('payables.ach.payment-detail', {
                id: payment.id,
                type: 'view',
                list: 'recurringList',
            });
        }
    }

    function calculateTotals(data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    }

    function checkEnability() {
        if ($scope.selectionList.length > 0) {
            return false;
        }
        return true;
    }

    function bulkInitiate() {
        angular.forEach($scope.selectionList, item => {
            $scope.list.push({ key: item.id, value: '' });
        });
    }

    function batchUpdate(type) {
        const modalInstance = $uibModal.open({
            template: require('../views/bulkApproveOrRejectDialog.html'),
            size: 'md',
            controller: 'BulkApproveOrRejectDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            const batchPayload = {
                lookups: [],
            };
            angular.forEach($scope.paymentList, payment => {
                if (payment.isSelected) {
                    batchPayload.lookups.push({ key: payment.id, value: comments });
                }
            });
            achPaymentsService.batchApproveOrReject(batchPayload, type).then(
                () => {
                    search();
                    if (type === 'Approve') {
                        toaster.approve('Payments');
                    } else if (type === 'Reject') {
                        toaster.reject('Payments');
                    }
                },
                () => {
                    search();
                }
            );
        });
    }

    function cancel(payment) {
        const modalInstance = $uibModal.open({
            template: require('../views/cancelPaymentDialogView.html'),
            size: 'lg',
            controller: 'CancelPaymentController',
            backdrop: 'static',
            resolve: {
                type() {
                    return 'paymentList';
                },
            },
        });
        modalInstance.result.then(comments => {
            const cancelAchPaymentModel = {
                achPaymentId: payment.id,
                comments,
            };
            securityService
                .verifyUser('Delete ACH Payment', cancelAchPaymentModel, () =>
                    achPaymentsService.cancel(cancelAchPaymentModel)
                )
                .then(
                    response => {
                        search();
                    },
                    error => {
                        search();
                    }
                );
        });
    }

    function prepareFilter(filterObject) {
        const obj = angular.copy(filterObject);
        const fields = ['debitAmount', 'creditAmount', 'initiatedDate', 'nextPaymentDate'];
        angular.forEach(fields, field => {
            const fieldType = `${field}Type`;
            if (!obj[field] && (!obj[fieldType] || obj[fieldType].indexOf('Specific') !== -1)) {
                delete obj[field];
                delete obj[fieldType];
            }
        });
        return obj;
    }
}
