import { AmountRangeFormatters } from '../../../../shared/utilities/amount-range-formatters.js';
import { DateFormatters } from '../../../../shared/utilities/date-formatters.js';
import { EXCEPTION_REASONS } from '../../../types/arp/constants.js';

const formatDateRange = date => {
    const parsedDate = DateFormatters.parseDate(date);
    if (!parsedDate?.value) return null;
    if (typeof parsedDate.value === 'object') {
        return {
            startDate: parsedDate.value.start,
            endDate: parsedDate.value.end,
        };
    }
    return {
        startDate: parsedDate.value,
        endDate: parsedDate.value,
    };
};

const formatAmountRange = amount => {
    let startAmount = null;
    let endAmount = null;
    if (amount) {
        const rangeType = AmountRangeFormatters.getRangeType(amount);
        if (rangeType === 'AmountRange') {
            const amountRange = AmountRangeFormatters.getAmountStartAndEnd(amount);
            startAmount = amountRange.start;
            endAmount = amountRange.end;
        }
        if (rangeType === 'SpecificAmount') {
            const specificAmount = AmountRangeFormatters.getSpecificAmount(amount);
            startAmount = specificAmount;
            endAmount = specificAmount;
        }
        if (startAmount == null && endAmount == null) return null;
        return {
            startAmount,
            endAmount,
        };
    }
    return null;
};

export default class CheckExceptionMappings {
    static mapSearchParams(params) {
        const result = {
            accountUniqueIds: params?.account,
            checkNumber: params?.checkNumber || '',
            issuedAmount: formatAmountRange(params?.issuedAmount),
            postedAmount: formatAmountRange(params?.paidAmount),
            issuedDate: formatDateRange(params?.issuedDate),
            issuedPayee: params?.issuedPayee || null,
        };

        if (params?.exceptionReasons && params?.exceptionReasons !== 'all') {
            if (params.exceptionReasons.length === EXCEPTION_REASONS.length) {
                result.exceptionReasons = null;
            } else {
                result.exceptionReasons = params.exceptionReasons;
            }
        }

        if (params?.postedDate) {
            result.postedDate = formatDateRange(params.postedDate);
        }
        return result;
    }
}
