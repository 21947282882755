WireTemplateListController.$inject = [
    '$scope',
    '$state',
    '$modal',
    'searchFilterService',
    'entitlementsService',
    'companyAccountsService',
    'wireService',
    'toaster',
    'downloadPageId',
    '$filter',
    'wireCompaniesService',
    'wireIsoService'
];

export default function WireTemplateListController(
    $scope,
    $state,
    $modal,
    searchFilterService,
    entitlementsService,
    companyAccountsService,
    wireService,
    toaster,
    downloadPageId,
    $filter,
    wireCompaniesService,
    wireIsoService
) {
    const createWireInternationalEntitlement = 'Wire, International, Create Wire';
    const createWireDomesticEntitlement = 'Wire, Domestic, Create Wire';
    let hasCreateWireEntitlement = false;

    $scope.downloadPageId = downloadPageId.WireTemplateList;
    $scope.allItemsAreSelected = {};
    $scope.companyAccounts = [];
    $scope.dateOptions = null;
    $scope.filterObject = {};
    $scope.statusOptions = null;
    $scope.templates = null;
    $scope.wireCompanies = [];
    $scope.disableDownload = true;

    $scope.batchInitiate = batchInitiate;
    $scope.approveReject = approveReject;
    $scope.createWireTemplate = createWireTemplate;
    $scope.goToTemplateDetail = goToTemplateDetail;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.hasPermissionToCreate = hasPermissionToCreate;
    $scope.itemIsSelected = itemIsSelected;
    $scope.print = print;
    $scope.remove = remove;
    $scope.resetSearch = resetSearch;
    $scope.search = search;
    $scope.selectOrDeselectAllTemplates = selectOrDeselectAllTemplates;
    $scope.updateSelectionList = updateSelectionList;
    $scope.initiatePayment = initiatePayment;
    $scope.canCreateDomesticWire = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire'
    );
    $scope.canCreateInternationalWire = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire'
    );
    $scope.canCreateDomesticWireTemplate = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire Template'
    );
    $scope.canCreateInternationalWireTemplate = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire Template'
    );
    $scope.canCreateInternationalUsd = false;
    $scope.goToBeneficiaries = goToBeneficiaries;

    $scope.filteredWires = {};
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.searchObj = { text: null };
    $scope.goToRecurringWireList = goToRecurringWireList;
    $scope.canInitiate = canInitiate;
    $scope.resetCreatedDates = resetCreatedDates;
    $scope.wireIsoLabels = wireIsoService.legacyLabels
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels
    })

    function setApprovalAttributes() {
        $scope.templates.map(template => {
            template.approvalAttributes = {
                approvalEntity: 'wireTemplate',
                approvalCount: template.completedApprovalCount,
                createdBy: template.createdBy,
                updatedBy: template.updatedBy,
                productId: template.id,
            };
            return template;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredWires = $filter('filter')($scope.templates, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.templates ||
            $scope.templates.length <= 0
        ) {
            return true;
        }

        return (
            (row.debitAccount &&
                row.debitAccount.number &&
                row.debitAccount.number
                    .toLowerCase()
                    .indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (row.beneficiary &&
                row.beneficiary.name &&
                row.beneficiary.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (row.createdDate &&
                $filter('date')(row.createdDate, 'MM/dd/yyyy').indexOf($scope.searchObj.text) !==
                    -1) ||
            (row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (row.isInternational && $scope.searchObj.text.toLowerCase() === 'international') ||
            (!row.isInternational && $scope.searchObj.text.toLowerCase() === 'domestic') ||
            (row.wireCompany &&
                row.wireCompany.name &&
                row.wireCompany.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (row.name && row.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function disableFilter() {
        return $scope.templates ? $scope.templates.length <= 0 : true;
    }

    function goToRecurringWireList() {
        $state.go('payables.wire.recurring-wire-list');
    }

    init();

    function init() {
        // Reload required so that any changes to entitlements take effect immediately without re-authenticating
        entitlementsService.reload();

        $scope.dateOptions = ['Specific Date', 'Date Range'];
        $scope.statusOptions = [
            { name: 'Pending Approval' },
            { name: 'Approval Rejected' },
            { name: 'Ready' },
        ];
        $scope.currentDate = moment().format('MM/DD/YYYY');

        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;
            $scope.canCreateInternationalUsd =
                response.fiWireProductEnabled &&
                response.fiInternationalUsdEnabled &&
                response.companyWireProductEnabled &&
                response.companyInternationalUsdEnabled;

            resetSearch();
            searchFilterService.restoreFilter($scope.filterObject);
            loadCompanyAccounts();
            loadWireCompanies();
        });
    }

    function resetSearch() {
        const availableWireTypes = $scope.canCreateInternationalUsd ? 'Both' : 'Domestic';

        $scope.filterObject = { wireType: availableWireTypes };
        $scope.filterObject.statuses = [];
        $scope.filterObject.debitAccounts = [];
        $scope.filterObject.wireCompanies = [];

        angular.forEach($scope.statusOptions, statusOption => {
            statusOption.isChecked = true;
            $scope.filterObject.statuses.push(statusOption);
        });
        angular.forEach($scope.companyAccounts, companyAccount => {
            companyAccount.isChecked = true;
            $scope.filterObject.debitAccounts.push(companyAccount);
        });
        angular.forEach($scope.wireCompanies, wireCompany => {
            wireCompany.isChecked = true;
            $scope.filterObject.wireCompanies.push(wireCompany);
        });

        $scope.filterObject.createdDateType = $scope.dateOptions[0];
    }

    function search(initialLoad) {
        if (initialLoad) {
            searchFilterService.restoreFilter($scope.filterObject);
        } else {
            searchFilterService.saveFilter($scope.filterObject, [
                'payables/wire/wire-template-details',
            ]);
        }
        wireService.searchTemplates($scope.filterObject).then(response => {
            response = response.map(template => {
                template.status = template.isPendingEdit ? 'Pending Edits' : template.status;
                return template;
            });
            $scope.templates = response;
            if ($scope.templates.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
            setApprovalAttributes();
            $scope.searchObj.text = null;
            $scope.filterSearch($scope.searchObj.text);
            $scope.allItemsAreSelected.value = false;

            if ($scope.templates.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
        });
    }

    function canInitiate(template) {
        if (template == null) {
            return false;
        }

        hasCreateWireEntitlement = template.isInternational
            ? entitlementsService.hasEntitlement(createWireInternationalEntitlement)
            : entitlementsService.hasEntitlement(createWireDomesticEntitlement);

        const passInternationalUsdCheck = template.isInternational
            ? $scope.canCreateInternationalUsd
            : true;

        return hasCreateWireEntitlement && passInternationalUsdCheck && template.status === 'Ready';
    }

    function loadWireCompanies() {
        wireCompaniesService.getAll(true).then(response => {
            response.forEach(wireCompany => {
                wireCompany.isChecked = true;
                $scope.filterObject.wireCompanies.push(wireCompany);
            });
            $scope.wireCompanies = response;
            if ($scope.filterObject.debitAccounts.length > 0) {
                search(true);
            }
        });
    }

    function goToTemplateDetail(template, type) {
        $state.go('payables.wire.wire-template-details-view', { id: template.id, type });
    }

    function createWireTemplate() {
        $state.go('payables.wire.create-wire-template');
    }

    function hasPermissionToCreate() {
        return $scope.canCreateDomesticWireTemplate || $scope.canCreateInternationalWireTemplate;
    }

    function print() {
        $('#wireTemplateTable').printThis();
    }

    function hasAccessPermissions(wire, permissionType) {
        let result = false;
        angular.forEach(wire.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    function selectOrDeselectAllTemplates() {
        angular.forEach($scope.templates, template => {
            if (
                (template.isInternational
                    ? $scope.canCreateInternationalWire
                    : $scope.canCreateDomesticWire) &&
                template.status === 'Ready'
            ) {
                template.isSelected = $scope.allItemsAreSelected.value;
            }
        });
    }

    function updateSelectionList() {
        let allSelected = true;
        for (let i = 0; i < $scope.templates.length; i++) {
            if (!$scope.templates[i].isSelected && canInitiate($scope.templates[i])) {
                allSelected = false;
                break;
            }
        }
        $scope.allItemsAreSelected.value = allSelected;
    }

    function itemIsSelected() {
        let result = false;
        angular.forEach($scope.templates, wire => {
            if (wire.isSelected) {
                result = true;
            }
        });
        return result;
    }

    function batchInitiate() {
        const templates = [];
        angular.forEach($scope.templates, template => {
            if (template.isSelected) {
                templates.push(template);
            }
        });

        wireService.setSelectedTemplates(templates);
        if (templates.length > 1) {
            $state.go('payables.wire.create-multiple-wire');
        } else {
            $state.go('payables.wire.create-wire');
        }
    }

    function approveReject(template, type) {
        const modalInstance = $modal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeWireStatusDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                paymentType() {
                    return 'Template';
                },
            },
        });
        modalInstance.result.then(comments => {
            wireService.approveOrRejectWireTemplate(type, template.id, '').then(() => {
                if (type === 'Approve') {
                    toaster.approve('Wire Template');
                } else if (type === 'Reject') {
                    toaster.reject('Wire Template');
                }
                search();
            });
        });
    }

    function remove(template) {
        const modalInstance = $modal.open({
            template: require('../views/removeWireTemplateDialogView.html'),
            size: 'lg',
            controller: 'RemoveWireTemplateController',
            backdrop: 'static',
            resolve: {
                initiatedRecurringWire() {
                    return template.initiatedRecurringWire;
                },
            },
        });

        modalInstance.result.then(comments => {
            wireService.removeTemplate(template, comments).then(response => {
                toaster.delete('Wire Template');
                search();
            });
        });
    }

    function initiatePayment(template) {
        wireService.setSelectedTemplates(template);
        $state.go('payables.wire.create-wire');
    }

    function loadCompanyAccounts() {
        companyAccountsService
            .getCompanyAccountsWithProductFeature('', 'WireTransfer')
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                $scope.companyAccounts = response;

                $scope.filterObject.debitAccounts = [];
                angular.forEach($scope.companyAccounts, companyAccount => {
                    companyAccount.isChecked = true;
                    $scope.filterObject.debitAccounts.push(companyAccount);
                });
                if ($scope.filterObject.wireCompanies.length > 0) {
                    search(true);
                }
            });
    }

    function goToBeneficiaries() {
        $state.go('payables.wire.beneficiary-list');
    }

    function resetCreatedDates() {
        $scope.filterObject.createdDateStart = null;
        $scope.filterObject.createdDateEnd = null;
        $scope.filterObject.createdDate = null;
    }
}
