import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-icon.js';
import '@treasury/omega/components/progress/omega-progress.js';
import { string } from '@treasury/policy/primitives';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../mix-ins/channel-alert-mixin.js';
import { StatusCode } from '../data/login-phases.js';

class ChangePassword extends mix(LitElement).with(ListeningElementMixin, channelAlertMixin) {
    static get properties() {
        return {
            isExpired: Boolean,
            record: Object,
            userRecord: Object,
            client: Object,
            verifiedUser: Object,
            institution: String,
            requirements: Array,
            passwordsMatch: Boolean,
            formActionsDisabled: Boolean,
            loading: Boolean,
            submitting: Boolean,
            statusCode: String,
        };
    }

    constructor() {
        super();
        this.fields = {
            companyId: string.with.label('Company ID').thatIs.readOnly(),
            loginId: string.with.label('Login ID').thatIs.readOnly(),
            institution: string,
            password: string.with
                .label('New Password')
                .and.placeholder('Enter New Password')
                .thatIs.required()
                .thatHas.maxLength(250)
                .and.type('password'),
            confirmPassword: string.with
                .label('Confirm Password')
                .and.placeholder('Confirm New Password')
                .thatIs.required()
                .thatHas.maxLength(250)
                .and.validator({
                    name: 'match-password',
                    validate: (value, view, record /* options */) => {
                        if (value && record.getField('password')) {
                            return value === record.getField('password');
                        }
                        return true;
                    },
                })
                .and.type('password'),
            code: string,
        };
        this.requirements = [];
        this.formActionsDisabled = true;
        this.passwordsMatch = true;
    }

    get daysUntilPasswordExpires() {
        return this.verifiedUser.daysUntilPasswordExpires;
    }

    get passwordIsExpired() {
        return (
            this.statusCode === StatusCode.ChallengeResetExpiredPassword ||
            this.statusCode === StatusCode.ResetExpiredPassword
        );
    }

    async firstUpdated() {
        try {
            this.loading = true;
            this.requirements = await this.client.passwordRequirements(this.institution);
            this.record = new Record(this.fields, {
                /* Passing these from user verification, I imagine we'd need them from a standard login as well where the expiration prompts a change */
                companyId: this.userRecord.getField('companyId'),
                loginId: this.userRecord.getField('loginId'),
                password: '',
                confirmPassword: '',
                code: this.userRecord.getField('code') ?? '',
                institution: this.institution,
            });
            this.listenTo(this.record, 'change', () => {
                this.passwordsMatch = this.doPasswordsMatch();
                this.isFormInvalid();
            });
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    submitPassword() {
        this.dispatchEvent(
            new CustomEvent('submit', { detail: { formValues: this.record.values } })
        );
    }

    completePhase() {
        this.dispatchEvent(new CustomEvent('complete'));
    }

    reset() {
        this.record.reset();
    }

    isFormInvalid() {
        this.formActionsDisabled =
            !this.record.isValid() || !this.record.hasRequiredValues() || this.submitting;
    }

    passwordNotExpiring() {
        return !this.passwordIsExpired && this.daysUntilPasswordExpires === null;
    }

    doPasswordsMatch() {
        if (!this.record) return false;
        if (!this.record.getField('confirmPassword')) return true;
        return this.record.getField('password') === this.record.getField('confirmPassword');
    }

    renderPasswordExpiredMessage() {
        if (this.passwordIsExpired) return html`<span>Your password has expired.</span>`;
        return nothing;
    }

    renderPasswordWillExpireSoonMessage() {
        if (this.passwordNotExpiring()) return nothing;
        return html`<span
            >Your password will expire in ${this.daysUntilPasswordExpires} days.</span
        >`;
    }

    renderPasswordRequirements() {
        const requirements = this.requirements.map(req => html`<li>${req}</li>`);
        return html`<ul>
            ${requirements}
        </ul>`;
    }

    renderPasswordMismatchWarning() {
        if (this.passwordsMatch) return nothing;
        return html`<span id="mismatch-warning-message"
            ><omega-icon icon="exclamation-triangle"></omega-icon> Passwords Do Not Match</span
        >`;
    }

    renderChangePasswordLaterButton() {
        if (this.passwordNotExpiring()) return nothing;
        return html`<omega-button @click=${this.completePhase}
            >Change Password Later</omega-button
        >`;
    }

    render() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        return html`
            ${this.renderAlert()}
            <div id="change-password-container">
                <section>
                    ${this.renderPasswordExpiredMessage()}
                    ${this.renderPasswordWillExpireSoonMessage()}
                    <p>
                        Please enter a new password following the password requirements listed
                        below.
                    </p>

                    <p>Password Requirements:</p>
                    ${this.renderPasswordRequirements()}
                </section>
                <omega-field field="companyId" .record=${this.record}></omega-field>
                <omega-field field="loginId" .record=${this.record}></omega-field>
                <omega-field field="password" .record=${this.record}></omega-field>
                <omega-field field="confirmPassword" .record=${this.record}></omega-field>
                ${this.renderPasswordMismatchWarning()}
                <br />
                <omega-button
                    id="submit-password"
                    type="primary"
                    ?disabled=${this.formActionsDisabled}
                    ?loading=${this.submitting}
                    @click=${this.submitPassword}
                >
                    Submit
                </omega-button>
                <omega-button id="reset" ?disabled=${this.formActionsDisabled} @click=${this.reset}>
                    Reset
                </omega-button>
                ${this.renderChangePasswordLaterButton()}
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #change-password-container {
                height: 100%;
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
                padding: 0 10px;
            }
            #mismatch-warning-message {
                color: #ee3a3b;
            }
            omega-icon {
                --omega-icon-color: #ee3a3b;
            }
        `;
    }
}

customElements.define('change-password', ChangePassword);
