/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
/* eslint-disable lit/no-useless-template-literals */
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';

import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-textarea.js';
import '@treasury/omega/components/progress/omega-progress.js';
import '../../components/ach-payment-action-dialog.js';
import '../../components/ach-payment-recipients-table.js';
import '../../components/ach-template-action-dialog.js';
import '../parts/child-support-payment-header';

import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import { boolean } from '@treasury/policy/primitives';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import ChildSupportPaymentDetailsClient from '../clients/child-support-details-client.ts';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration.ts';
import { paymentHeaderRecord } from '../data/payment-header-record.js';
import {
    createChildSupportPaymentColumns,
    recipientRecordset,
} from '../data/recipient-recordset.js';
import { recipientDetails } from '../parts/child-support-payment-recipient-details.ts';

export const effectiveDateHasPassed = date => {
    const today = new Date();
    return new Date(date).setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
};
class ChildSupportDetailsContainer extends mix(LitElement).with(
    ListeningElementMixin,
    channelAlertMixin
) {
    static get properties() {
        return {
            CONTAINER_CONFIGURATION: Object,
            client: Object,
            institution: String,
            payment: Object,
            id: String,
            type: String,
            paymentHeaderRecord: Object,
            recipientsRecordset: Object,
            recipients: Array,
            loading: Boolean,
            downloading: Boolean,
            alert: Object,
            cutoffTimes: Object,
            approvers: Object,
            user: Object,
            showActionDialog: Boolean,
            showDeleteDialog: Boolean,
            actionTaken: String,
            pageTitle: String,
            navService: Object,
        };
    }

    constructor() {
        super();
        this.client = new ChildSupportPaymentDetailsClient();
        this.filters = [
            {
                field: 'prenote',
                fieldType: boolean.with.label('Prenote Only'),
                value: false,
            },
            {
                field: 'hold',
                fieldType: boolean.with.label('Hold Only'),
                value: false,
            },
        ];
        this.showActionDialog = false;
        this.statusChangeComments = '';
        this.submittingActionOnPayment = false;
        this.pageTitle = '';
        this.CONTAINER_CONFIGURATION = CONTAINER_CONFIGURATION;
    }

    get permissions() {
        const permissionsProperty = CONTAINER_CONFIGURATION[this.type].permissionsKey;
        return this.payment?.[permissionsProperty].map(p => p.permission || p);
    }

    get downloadOptions() {
        if (!this.type) return [];
        return CONTAINER_CONFIGURATION[this.type].downloadOptions;
    }

    firstUpdated() {
        this.init();
    }

    async init() {
        this.loading = true;
        [this.type, this.id] = window.location.pathname.split('/').slice(-2);
        const config = this.client.achConfiguration();
        this.navService = await getNavService();
        this.achConfiguration = await config;
        this.achSettings = this.achConfiguration.achSettings;
        this.isSameDayAchEnabled = this.achConfiguration.isSameDayAchEnabled;
        this.holidays = this.achConfiguration.holidays;
        this.cutoffTimes = this.achConfiguration.cutoffTimes;
        this.entitlements = this.achConfiguration.entitlements;
        if (this.type === 'template') {
            await this.loadTemplate();
        } else {
            await this.loadPayment();
        }
        this.pageTitle = `${this.CONTAINER_CONFIGURATION[this.type].title} Detail: ${
            this.payment?.transactionId ?? this.payment?.name
        }`;
    }

    async loadTemplate() {
        try {
            const template = await this.client.getPaymentTemplate(this.id);
            this.payment = { ...template, permissions: template.userPermissions };
            this.buildRecordAndRecordset();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async loadPayment() {
        try {
            const paymentResponse = this.client.getChildSupportPaymentById(this.id);
            const payment = await paymentResponse;
            this.payment = payment.payment;
            this.buildRecordAndRecordset();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    buildRecordAndRecordset() {
        this.recipients = this.payment.recipients.map(recipient => ({
            ...recipient,
            ...(recipient.addenda[0] ?? recipient.addenda),
            addendaId: recipient.addenda[0] ? recipient.addenda[0].id : recipient.addenda.id,
        }));
        this.paymentHeaderRecord = paymentHeaderRecord(
            { ...this.achConfiguration, type: this.type },
            {
                ...this.payment,
                step: 3,
            }
        );
        this.recipientsRecordset = recipientRecordset(
            this.recipients,
            false,
            this.type,
            this.achConfiguration
        );
        this.recipientsRecordset.requestUpdate();
    }

    paymentIsPending() {
        return this.paymentHeaderRecord.getField('status') === 'Pending Approval';
    }

    paymentWasRejected() {
        return this.paymentHeaderRecord.getField('status') === 'Approval Rejected';
    }

    paymentIsEditable() {
        const editableStatuses = [
            'Approval Rejected',
            'Scheduled',
            'Uninitiated',
            'Failed',
            'Expired',
            'Ready',
        ];
        const { statusField } = CONTAINER_CONFIGURATION[this.type];
        return editableStatuses.includes(this.paymentHeaderRecord.getField(statusField));
    }

    userCanTakeActionOnPayment(action) {
        return this.permissions?.includes(action);
    }

    async handleDownload({ detail }) {
        this.downloading = true;
        try {
            await this.client[this.CONTAINER_CONFIGURATION[this.type].downloadClientService](
                this.id,
                detail.downloadType,
                this.CONTAINER_CONFIGURATION[this.type].downloadKey
            );
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.downloading = false;
        }
    }

    handlePrint() {
        // TODO: need params for CSP from API
        this.client.updateUserPrintActivity('id', 'information');
        window.print();
    }

    clearFieldsForNewPayment(paymentAction) {
        if (paymentAction === 'initiate') {
            this.payment.transactionId = null;
            this.payment.id = 0;
        }
    }

    mapFrequency() {
        if (!this.payment.frequency) return {};
        return {
            ...this.payment.frequency,
            valueDate: effectiveDateHasPassed(this.payment.frequency.effectiveDate)
                ? ''
                : this.payment.frequency.effectiveDate,
        };
    }

    async routeToCreateChildSupportPayment(paymentAction) {
        this.clearFieldsForNewPayment(paymentAction);
        const navService = await getNavService();
        const paymentState = {
            [this.type]: {
                ...this.payment,
                companyIdentity: this.payment.companyIdentity ?? this.payment.achCompanyId,
                frequency: { ...this.mapFrequency() },
            },
            recipients: this.recipients,
            paymentCreationType: paymentAction,
            type: this.type,
        };
        navService.navigate(
            `payables.ach.payments.child-support-workflow-${this.type}-${paymentAction}`,
            {
                paymentState,
                id: this.id,
            }
        );
    }

    async submitActionOnPayment() {
        this.submittingActionOnPayment = true;
        try {
            await this.client.updatePaymentStatus(this.actionTaken, this.id);

            const message = this.actionTaken === 'approve' ? 'approved!' : 'rejected.';
            this.alert = {
                ...this.alert,
                message: `${this.CONTAINER_CONFIGURATION[this.type].title} successfully ${message}`,
                visible: true,
                type: 'success',
            };

            this.init();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.showActionDialog = false;
            this.submittingActionOnPayment = false;
        }
    }

    async back() {
        const { reportUrl } = CONTAINER_CONFIGURATION[this.type];
        return (await getNavService()).navigate(reportUrl);
    }

    renderLoader() {
        if (this.loading) {
            return html`<omega-progress card></omega-progress>`;
        }
        return nothing;
    }

    renderDownloadDialog() {
        if (this.downloading) {
            const downloadingTitle = `Downloading ${this.CONTAINER_CONFIGURATION[this.type].title}`;
            return html`<omega-dialog
                id="download-dialog"
                open
                .dialogTitle=${downloadingTitle}
                @close=${() => {
                    this.downloading = false;
                }}
            >
                <omega-progress card></omega-progress>
            </omega-dialog>`;
        }
        return nothing;
    }

    renderPaymentActionDialog() {
        return html`<ach-payment-action-dialog
            .paymentHeaderRecord=${this.paymentHeaderRecord}
            .submittingActionOnPayment=${this.submittingActionOnPayment}
            .actionTaken=${this.actionTaken}
            .showActionDialog=${this.showActionDialog}
            @submit=${({ detail }) => {
                this.statusChangeComments = detail;
                this.submitActionOnPayment();
            }}
            @close=${() => {
                this.showActionDialog = false;
            }}
        ></ach-payment-action-dialog>`;
    }

    renderDeleteDialog() {
        if (this.showDeleteDialog) {
            return html`
                <ach-template-action-dialog
                    .showActionDialog=${this.showDeleteDialog}
                    .dialogTitle=${'Delete Template'}
                    .message=${'Are you sure you want to delete this template?'}
                    .actionTaken=${'delete'}
                    .dialogAction=${record =>
                        this.client.deletePaymentTemplate(record.getField('id'))}
                    .record=${this.paymentHeaderRecord}
                    @close=${() => {
                        this.showDeleteDialog = false;
                    }}
                    @delete=${({ detail }) => {
                        this.showDeleteDialog = false;
                        if (detail.successLevel === 1) {
                            this.navService.navigate(
                                'payables.ach.payments.child-support-templates'
                            );
                        } else {
                            this.alert = {
                                ...this.alert,
                                type: 'error',
                                visible: true,
                                message: 'Unable to delete template',
                            };
                        }
                    }}
                ></ach-template-action-dialog>
            `;
        }
        return nothing;
    }

    renderPaymentHeader() {
        const activeStep = 3;
        if (this.paymentHeaderRecord && !this.loading) {
            return html`<child-support-payment-header
                .activeStep=${activeStep}
                isFromFile
                expanded
                .paymentHeaderRecord=${this.paymentHeaderRecord}
                .recipientsRecordset=${this.recipientsRecordset}
            ></child-support-payment-header>`;
        }
        return nothing;
    }

    renderRecipientsTable() {
        if (this.recipientsRecordset && !this.loading) {
            return html`<ach-payment-recipients-table
                .columns=${createChildSupportPaymentColumns(false)}
                .recipients=${this.recipients}
                .filters=${this.filters}
                .recipientsRecordset=${this.recipientsRecordset}
                .detailFunction=${recipientDetails}
            ></ach-payment-recipients-table>`;
        }
        return nothing;
    }

    renderEditButton() {
        if (
            this.userCanTakeActionOnPayment(`Edit${CONTAINER_CONFIGURATION[this.type].title}`) &&
            this.paymentIsEditable()
        ) {
            return html`<omega-button
                .disabled=${this.loading}
                @click=${() => this.routeToCreateChildSupportPayment('edit')}
            >
                Edit ${this.CONTAINER_CONFIGURATION[this.type].title}
            </omega-button>`;
        }
        return nothing;
    }

    renderDeleteButton() {
        if (this.type === 'payment' || !this.payment.userPermissions.includes('DeleteTemplate'))
            return nothing;
        return html`<omega-button
            .disabled=${this.loading}
            type="reject"
            @click=${() => {
                this.actionTaken = 'delete';
                this.showDeleteDialog = true;
            }}
            >Delete</omega-button
        >`;
    }

    renderApproveButton() {
        if (this.userCanTakeActionOnPayment('Approve')) {
            return html`<omega-button
                type="approve"
                .disabled=${this.loading}
                @click=${() => {
                    this.actionTaken = 'approve';
                    this.showActionDialog = true;
                }}
                >Approve</omega-button
            >`;
        }
        return nothing;
    }

    renderRejectButton() {
        if (this.userCanTakeActionOnPayment('RejectPayment')) {
            return html` <omega-button
                type="reject"
                .disabled=${this.loading}
                @click=${() => {
                    this.actionTaken = 'reject';
                    this.showActionDialog = true;
                }}
                >Reject</omega-button
            >`;
        }
        return nothing;
    }

    // We only allow users to initiate payments from the payment detail container
    renderInitiateButton() {
        if (this.type === 'template') return nothing;
        return html`<omega-button
            type="primary"
            .disabled=${this.loading}
            @click=${() => this.routeToCreateChildSupportPayment('initiate')}
        >
            Initiate ${this.CONTAINER_CONFIGURATION[this.type].title}
        </omega-button>`;
    }

    renderPaymentActions() {
        if (this.paymentHeaderRecord) {
            return html` <div class="flex-bar">
                <div class="left">
                    ${this.renderEditButton()} ${this.renderInitiateButton()}
                    ${this.renderApproveButton()}${this.renderRejectButton()}
                    <omega-button .disabled=${this.loading} @click=${this.back}>Back</omega-button>
                </div>
                <div class="right">${this.renderDeleteButton()}</div>
            </div>`;
        }
        return nothing;
    }

    renderDownloadBar() {
        if (this.paymentHeaderRecord) {
            return html` <omega-download-bar
                .pageTitle=${this.pageTitle}
                .downloadOptions=${this.downloadOptions}
                .hideActionLabels=${false}
                .actions=${['download', 'print']}
                .disableDownload=${this.loading}
                .disablePrint=${this.loading}
                @download=${this.handleDownload}
                @print=${this.handlePrint}
            ></omega-download-bar>`;
        }
        return nothing;
    }

    render() {
        return html`
            ${this.renderAlert()} ${this.renderDownloadDialog()} ${this.renderPaymentActionDialog()}
            ${this.renderDeleteDialog()}
            <div class="child-support-payment-detail-container">
                ${this.renderDownloadBar()} ${this.renderLoader()} ${this.renderPaymentHeader()}
                ${this.renderRecipientsTable()}
                <omega-button-bar position="bottom" alignment="left">
                    ${this.renderPaymentActions()}
                </omega-button-bar>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --bottom-offset: -15px;
            }
            .child-support-payment-detail-container {
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                padding: 0 5px;
                box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
            }
            ach-payment-recipients-table {
                margin-bottom: 60px;
            }
            #download-dialog {
                min-width: 350px;
            }
            #payment-action-dialog {
                padding: 0 15px;
            }
            .flex-bar {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        `;
    }
}

customElements.define('child-support-details-container', ChildSupportDetailsContainer);

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
