import { getChannelAPIPath } from '@treasury/core/http';

CentrixSsoService.$inject = ['$resource', '$q', '$http'];

export default function CentrixSsoService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}arp/`;

    return {
        getCentrixSsoLogin,
    };

    function getCentrixSsoLogin() {
        return $resource(
            `${resourceUrl}getCentrixSsoLogin`,
            {},
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }
}
