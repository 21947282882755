TaxTemplateListController.$inject = [
    '$scope',
    '$state',
    '$controller',
    'achBatchService',
    'entitlementsService',
    'achSettingsService',
];

export default function TaxTemplateListController(
    $scope,
    $state,
    $controller,
    achBatchService,
    entitlementsService,
    achSettingsService
) {
    angular.extend(this, $controller('BatchListController', { $scope }));

    const batchListState = 'payables.ach.batch-list';
    const childSupportTemplatesState = 'payables.ach.payments.child-support-templates';
    const createFromTaxTemplateState = 'payables.ach.payments.createFromTaxTemplate';
    const internationalPaymentTemplatesState = 'payables.ach.payments.international-ach-templates';

    $scope.goToBatchList = goToBatchList;
    $scope.goToChildSupportTemplates = goToChildSupportTemplates;
    $scope.bulkInitiateTaxPayments = bulkInitiateTaxPayments;
    $scope.filterSearchOnChange = filterSearchOnChange;
    $scope.hasChildSupportFeatureFlags = hasChildSupportFeatureFlags;
    $scope.goToInternationalAchTemplates = goToInternationalAchTemplates;
    $scope.hasInternationalAchTemplateFeatureFlagsAndEntitlements =
        hasInternationalAchTemplateFeatureFlagsAndEntitlements;

    init();

    async function init() {
        $scope.achSettings = await achSettingsService.get();
    }

    function filterSearchOnChange(searchText) {
        if (!(searchText && searchText.length)) {
            $scope.filterSearch(searchText);
            return;
        }

        if (searchText.length > 1) $scope.filterSearch(searchText);
    }

    function goToBatchList() {
        $state.go(batchListState);
    }

    function goToInternationalAchTemplates() {
        $state.go(internationalPaymentTemplatesState);
    }

    function hasInternationalAchTemplateFeatureFlagsAndEntitlements() {
        const hasIatFeatureFlag = entitlementsService.hasEntitlement(
            'Feature.ACH.AllowInternationalPayments'
        );
        const hasIatTemplateFeatureFlag = entitlementsService.hasEntitlement(
            'Feature.Ach.InternationalPaymentTemplates'
        );
        const hasIatTemplateEntitlement =
            entitlementsService.hasEntitlement('Full Edit International Ach Template') ||
            entitlementsService.hasEntitlement('Create International Ach Template') ||
            entitlementsService.hasEntitlement('Partial Edit International Ach Template') ||
            entitlementsService.hasEntitlement('Delete International Ach Template') ||
            entitlementsService.hasEntitlement('Approve International Ach Template') ||
            entitlementsService.hasEntitlement('File Upload Edit International ACH Template');
        return hasIatFeatureFlag && hasIatTemplateFeatureFlag && hasIatTemplateEntitlement;
    }

    function goToChildSupportTemplates() {
        $state.go(childSupportTemplatesState);
    }

    function hasChildSupportFeatureFlags() {
        const hasFeatureFlags =
            entitlementsService.hasEntitlement('Feature.ACH.AllowChildSupportPayments') &&
            entitlementsService.hasEntitlement('Feature.Ach.ChildSupportPaymentTemplates');
        const hasEntitlementsSwitchedOn = $scope.achSettings.allowChildSupportPayments;

        return hasFeatureFlags && hasEntitlementsSwitchedOn;
    }

    function bulkInitiateTaxPayments() {
        const list = $scope.selectionList.map(item => ({ key: item.id, value: '' }));

        achBatchService.bulkInitiateTaxPayments(list).then(response => {
            // $scope.setSelectedBatches is defined in ach controller
            $scope.setSelectedBatches(response);

            if ($scope.isListView) {
                $state.go(createFromTaxTemplateState);
            }
        });
    }
}
