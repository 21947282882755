/* eslint-disable no-use-before-define */
/* eslint-disable @treasury/style-includes-host-display */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { printNode } from '@treasury/utils';
import { css, html, LitElement, nothing, render } from 'lit';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-template-action-dialog.js';
import ChildSupportTemplatesClient from '../clients/child-support-templates-client.js';
import { columns, fields, filters } from '../data/template-report-fields.js';
import '../parts/child-support-template-report-header.js';

class ChildSupportTemplatesContainer extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            actions: Object,
            reportActions: Array,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            institution: String,
            reportLinks: Array,
            recordset: Object,
            client: Object,
            showDeleteDialog: Boolean,
            loading: Boolean,
            printing: Boolean,
        };
    }

    constructor() {
        super();
        this.reportLinks = [];
        this.fields = fields;
        this.filters = filters;
        this.columns = columns;
        this.actions = {
            delete: record => this.openDeleteDialog(record),
            clone: record => console.log(record),
            initiate: record => this.initiateTemplates(record),
            edit: record => this.editTemplate(record),
            loadTemplate: async record => {
                const navService = await getNavService();
                const id = record.getField('templateId');
                navService.navigate(`payables.ach.child-support-details`, {
                    id,
                    type: 'template',
                });
            },
        };
        this.pageTitle = 'ACH Child Support Templates';
        this.rowsPerPage = 25;
    }

    firstUpdated() {
        if (!this.client) {
            this.client = new ChildSupportTemplatesClient();
        }
        this.recordset = new Recordset(this.fields, this.client.fetchPaymentTemplates);
        this.recordset.setInitialPageSize(this.rowsPerPage);
        this.listenTo(this.recordset, 'loading', ({ detail }) => {
            const { loading } = detail;
            const hasInitiatePermission = this.recordset.allRecords.some(record =>
                record.getField('userPermissions').includes('CreatePayment')
            );
            if (hasInitiatePermission && !loading) {
                this.reportActions = [
                    {
                        type: 'primary',
                        label: 'Initiate Selected Template',
                        action: () => {
                            const selectedRecord = this.recordset
                                .recordsMatching('selected', true)
                                .pop();
                            this.initiateTemplates(selectedRecord);
                        },
                        isDisabled: () => this.recordset.noRecordsMatch('selected', true),
                    },
                ];
            }
        });
        this.reportLinks = [
            {
                title: 'ACH Templates',
                url: `${window.location.origin}/${this.institution}/list`,
            },
            {
                title: 'ACH Tax Templates',
                url: `${window.location.origin}/${this.institution}/ach/tax-templates`,
            },
            // add international ach entitlement service behind feature flag
        ];

        const internationalAchTemplateFeatureFlag = [
            'Feature.ACH.AllowInternationalPayments',
            'Feature.Ach.InternationalPaymentTemplates',
        ];
        const internationalAchTemplateEntitlements = [
            'Full Edit International Ach Template',
            'Create International Ach Template',
            'Partial Edit International Ach Template',
            'Delete International Ach Template',
            'Approve International Ach Template',
            'File Upload Edit International ACH Template',
        ];

        return this.client
            .hasAllEntitlements(internationalAchTemplateFeatureFlag)
            .then(
                hasFf =>
                    hasFf && this.client.hasSomeEntitlements(internationalAchTemplateEntitlements)
            )
            .then(hasFfAndEntitlements => {
                if (hasFfAndEntitlements) {
                    this.reportLinks.push({
                        title: 'International ACH Templates',
                        url: `${window.location.origin}/${this.institution}/ach/international-ach/templates`,
                    });
                }
            });
    }

    async routeToChildSupportWorkflow(paymentAction, templateRecord) {
        this.loading = true;
        const navService = await getNavService();
        const payment = await this.client.getPaymentTemplate(templateRecord.getField('templateId'));
        const typeKey = paymentAction === 'edit' ? 'template' : 'payment';
        const paymentState = {
            template: {
                ...payment,
            },
            recipients: payment.recipients.map(r => ({ ...r, ...r.addenda })),
            paymentCreationType: paymentAction,
            type: 'template',
        };
        this.loading = false;
        return navService.navigate(
            `payables.ach.payments.child-support-workflow-${typeKey}-${paymentAction}`,
            {
                paymentState,
                id: templateRecord.getField('templateId'),
            }
        );
    }

    async createNewTemplate() {
        const navService = await getNavService();

        return navService.navigate(`payables.ach.payments.child-support-workflow-template-create`, {
            paymentCreationType: 'create',
            type: 'template',
        });
    }

    initiateTemplates(templateRecord) {
        this.routeToChildSupportWorkflow('initiate', templateRecord);
    }

    editTemplate(templateRecord) {
        this.routeToChildSupportWorkflow('edit', templateRecord);
    }

    async download({ detail }) {
        this.loading = true;
        try {
            await this.client.downloadChildSupportTemplates(detail.filter, detail.type);
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async printReport() {
        this.printing = true;
        const printRecordset = new Recordset(this.fields, () => this.recordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.columns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    openDeleteDialog(record) {
        this.selectedRecord = record;
        this.showDeleteDialog = true;
    }

    renderDeleteDialog() {
        if (this.showDeleteDialog) {
            return html`
                <ach-template-action-dialog
                    .showActionDialog=${this.showDeleteDialog}
                    .dialogTitle=${'Delete Template'}
                    .message=${'Are you sure you want to delete this template?'}
                    .actionTaken=${'delete'}
                    .dialogAction=${record =>
                        this.client.deletePaymentTemplate(record.getField('templateId'))}
                    .record=${this.selectedRecord}
                    @close=${() => {
                        this.showDeleteDialog = false;
                    }}
                    @delete=${({ detail }) => {
                        this.showDeleteDialog = false;
                        if (detail.successLevel === 1) {
                            this.alert = {
                                ...this.alert,
                                visible: true,
                                type: 'success',
                                message: 'Template deleted successfully',
                            };
                            this.recordset.requestHardUpdate();
                        } else {
                            this.alert = {
                                ...this.alert,
                                type: 'error',
                                visible: true,
                                message: 'Unable to delete template',
                            };
                        }
                    }}
                ></ach-template-action-dialog>
            `;
        }
        return nothing;
    }

    renderBlockingLoader() {
        if (this.loading || this.printing) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    render() {
        if (!this.recordset) return nothing;
        return html`
            ${this.renderAlert()} ${this.renderDeleteDialog()} ${this.renderBlockingLoader()}
            <child-support-template-report-header
                .recordset=${this.recordset}
            ></child-support-template-report-header>
            <omega-report
                flyout
                autostart
                .title=${this.pageTitle}
                .actions=${this.actions}
                .recordset=${this.recordset}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .columns=${this.columns}
                .options=${['print', 'download']}
                .downloadOptions=${['PDF', 'CSV']}
                .printFunction=${() => this.printReport()}
                @callToAction=${this.createNewTemplate}
                @reportDownload=${e => this.download(e)}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                ></omega-filter-bar
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
            @media print {
                .child-support-templates-container {
                    box-shadow: none;
                    border: 1px solid #ccc;
                }
            }
        `;
    }
}

customElements.define('child-support-templates-container', ChildSupportTemplatesContainer);
export default ChildSupportTemplatesContainer;

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
