import { WireSearchCriteriaModelDto } from '@treasury/api/channel';
import { AmountRangeFormatters } from '@treasury/domain/shared/utilities/amount-range-formatters';
import { WireSearchCriteria } from '@treasury/domain/wires';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import { coerceString, extractDates } from '@treasury/omega/util';
import { Nullable, coerceEmptyStringsToNull, coerceMembersToNull } from '@treasury/utils';

interface AllSearchParams {
    beneficiaryName: string;
    createdDate: OmegaDate;
    effectiveDate: OmegaDate;
    status: WireSearchCriteriaModelDto['statuses'];
    debitAccount: WireSearchCriteriaModelDto['debitAccounts'];
    wireCompany: WireSearchCriteriaModelDto['wireCompanies'];
    type: string;
    transactionId: string;
    omad: string;
    currency: WireSearchCriteriaModelDto['currency'];
    amount: number;
    wireAmount: number;
    wireAmountMax: number;
    wireAmountMin: number;
    wireAmountType: string;
}

/**
 * Component view model of search parameters generated by the filter.
 */
export type WirePaymentActivityFilterParameters = Nullable<AllSearchParams>;

export function mapFilterParams(params: WirePaymentActivityFilterParameters): WireSearchCriteria {
    const {
        beneficiaryName,
        createdDate,
        effectiveDate,
        status,
        debitAccount,
        wireCompany,
        type,
        transactionId,
        omad,
        currency,
        amount,
    } = coerceEmptyStringsToNull(coerceMembersToNull(params));
    const { start: createdDateStart, end: createdDateEnd } = extractDates(
        coerceString(createdDate)
    );
    const { start: valueDateStart, end: valueDateEnd } = extractDates(coerceString(effectiveDate));
    const submitStatus = status?.length === 0 ? undefined : status;

    return {
        id: 0,
        currency: currency || undefined,
        wireType: type || undefined,
        wireCompanies: wireCompany || undefined,
        statuses: submitStatus || undefined,
        debitAccounts: debitAccount || undefined,
        beneficiaryName: beneficiaryName || undefined,
        transactionID: transactionId || undefined,
        omadNumber: omad || undefined,
        wireAmountMin: amount
            ? AmountRangeFormatters.getRangeStart(amount) || undefined
            : undefined,
        wireAmountMax: amount ? AmountRangeFormatters.getRangeEnd(amount) || undefined : undefined,
        wireAmount: amount
            ? AmountRangeFormatters.getSpecificAmount(amount) || undefined
            : undefined,
        wireAmountType: amount
            ? AmountRangeFormatters.getRangeType(amount, true) || undefined
            : undefined,
        valueDateType: valueDateEnd ? 'Date Range' : 'Specific Date',
        valueDateStart,
        valueDateEnd,
        createdDateStart,
        createdDateEnd,
        createdDateType: createdDateEnd ? 'Date Range' : 'Specific Date',
        isDownloadReport: false,
        isWireTotalsRequired: false,
    };
}
