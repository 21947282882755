import { AlarmClock } from '@treasury/alarm-clock';
import { dateIsTomorrow, isDateInArrayOfBankingHolidays } from '../shared/utilities';
import { FiDate } from './fi-date/fiDate';

/**
 *
 * @param holidays array of institutions holidays
 * @returns ISO date of the next valid business day: MM/DD/YYYY
 */
export function getNextBusinessDay(holidays: string[]) {
    const date = new Date();
    const fiClock = AlarmClock.getInstance();

    do {
        date.setDate(date.getDate() + 1);
        const isHoliday = isDateInArrayOfBankingHolidays(holidays, date);
        const isWeekend = [0, 6].includes(date.getDay());
        const isTomorrowAndPastCutoff = dateIsTomorrow(date) && fiClock.isAfter('achCutoff');
        if (!isHoliday && !isWeekend && !isTomorrowAndPastCutoff) {
            return new FiDate(date).toIsoDate();
        }
    } while (true);
}
