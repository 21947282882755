import { exists } from '@treasury/utils';
import {
    acceptedAdditionalInfoChars,
    additionalLocationInfoFields,
    optionalLocationFieldIds,
    setAddress,
} from '../../components/additionalLocationHelpers.js';

CreateEditBeneficiaryController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'beneficiariesService',
    'modalService',
    '$uibModal',
    'bankService',
    'entitlementsService',
    'statesService',
    'countriesService',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'regexConstants',
    'wireCompaniesService',
    'securityService',
    'wireIsoService',
];

export default function CreateEditBeneficiaryController(
    $scope,
    $state,
    $stateParams,
    beneficiariesService,
    modalService,
    $uibModal,
    bankService,
    entitlementsService,
    statesService,
    countriesService,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    regexConstants,
    wireCompaniesService,
    securityService,
    wireIsoService
) {
    $scope.wizardController = {};
    $scope.beneficiary = null;

    $scope.id = $stateParams.id;
    $scope.beneficiary = $stateParams.beneficiary;

    $scope.addIntermediaryBank = addIntermediaryBank;
    $scope.cancel = cancel;
    $scope.checkBankBeneficiaryState = checkBankBeneficiaryState;
    $scope.checkBeneficiaryAccountNumber = checkBeneficiaryAccountNumber;
    $scope.checkEnability = checkEnability;
    $scope.createAnotherBeneficiary = createAnotherBeneficiary;
    $scope.hasType = hasType;
    $scope.isInternational = isInternational;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.print = print;
    $scope.removeIntermediary = removeIntermediary;
    $scope.reset = reset;
    $scope.save = save;
    $scope.setBankIdType = setBankIdType;
    $scope.setForm = setForm;
    $scope.setToDomestic = setToDomestic;
    $scope.setToInternational = setToInternational;
    $scope.hideAudit = true;
    $scope.goToBeneficiaryList = goToBeneficiaryList;
    $scope.sendAWireToThisBeneficiary = sendAWireToThisBeneficiary;
    $scope.getCountry = getCountry;
    $scope.getCityStateAndPostalCode = getCityStateAndPostalCode;
    $scope.hasDomesticIntermediary = hasDomesticIntermediary;
    $scope.hasInternationalIntermediary = hasInternationalIntermediary;
    $scope.downloadPageId = downloadPageId.CreateBeneficiaryConfirmation;
    $scope.primaryDomesticBanks = {};
    $scope.intermediaryDomesticBanks = {};
    $scope.canSendWireToBeneficiary = canSendWireToBeneficiary;
    $scope.showSendWireButton = showSendWireButton;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    $scope.wireIsoEntitlement = false;
    $scope.hasWireIsoEntitlement = hasWireIsoEntitlement;
    $scope.applyAdditionalLocationInfo = applyAdditionalLocationInfo;
    $scope.additionalLocationLines = [];
    $scope.goToReviewStep = goToReviewStep;
    $scope.addExistingAdditionalLocationFields = addExistingAdditionalLocationFields;
    $scope.showingAdditionalLocation = false;

    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });
    wireIsoService.getEntitlementWireISO20022().then(bool => {
        $scope.wireIsoEntitlement = bool;
    });

    // Entitlements
    $scope.canCreateDomesticBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Beneficiary'
    );
    $scope.canCreateInternationalBeneficiary = entitlementsService.hasEntitlement(
        'Wire, International, Create Beneficiary'
    );
    $scope.canCreateFxBeneficiary = entitlementsService.hasEntitlement(
        'Wire, Fx, Create Beneficiary'
    );
    $scope.canCreateFxOrInternationalBeneficiary =
        $scope.canCreateFxBeneficiary || $scope.canCreateInternationalBeneficiary;
    $scope.canEditDomesticBeneficiary = entitlementsService.hasEntitlement('Edit Beneficiary');
    $scope.canEditInternationalBeneficiary = entitlementsService.hasEntitlement(
        'Wire, International, Edit Beneficiary'
    );
    $scope.canEditFxBeneficiary = entitlementsService.hasEntitlement('Wire, Fx, Edit Beneficiary');
    $scope.canCreateDomesticWire = entitlementsService.hasEntitlement(
        'Wire, Domestic, Create Wire'
    );
    $scope.canCreateFxWire = entitlementsService.hasEntitlement('Wire Transfer DLI, Create Wire');
    $scope.canCreateInternationalWire = entitlementsService.hasEntitlement(
        'Wire, International, Create Wire'
    );

    $scope.enableInternationalOption = enableInternationalOption;
    $scope.selection = {};
    $scope.previousSelection = {};
    $scope.confirmToggle = confirmToggle;
    $scope.beneficiaryNameRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.showBankDialog = showBankDialog;
    $scope.InternationalPostalCodeRegex = regexConstants.InternationalPostalCodeRegex;
    $scope.DomesticPostalCodeRegex = regexConstants.DomesticPostalCodeRegex;
    $scope.DomesticPostalCodeExtRegex = regexConstants.DomesticPostalCodeExtRegex;
    $scope.BeneficiaryGeneralRegex = regexConstants.BeneficiaryGeneralRegex;
    $scope.DomesticRoutingNumberRegex = regexConstants.DomesticRoutingNumberRegex;
    $scope.NumericRegex = regexConstants.NumericRegex;
    $scope.onSelectDomesticBank = onSelectDomesticBank;
    $scope.onSelectDomesticIntermediaryBank = onSelectDomesticIntermediaryBank;
    $scope.resetDomesticBeneficiaryBank = resetDomesticBeneficiaryBank;
    $scope.useDomesticIntermediary = useDomesticIntermediary;
    $scope.canSelectDomesticIntermediary = canSelectDomesticIntermediary;
    $scope.canSelectInternationalIntermediary = canSelectInternationalIntermediary;
    $scope.requireDomesticIntermediary = requireDomesticIntermediary;
    $scope.domesticIntermediaryValid = domesticIntermediaryValid;
    $scope.showCurrencyType = showCurrencyType;
    $scope.domesticBanksValid = domesticBanksValid;
    $scope.domesticBanksDuplicated = domesticBanksDuplicated;
    $scope.canToggleWireType = canToggleWireType;
    $scope.toggleCurrency = toggleCurrency;
    $scope.isUsdEnabled = isUsdEnabled;
    $scope.isForeignEnabled = isForeignEnabled;
    $scope.usdForeign = {};
    $scope.isValidRoutingNumber = isValidRoutingNumber;
    $scope.internationalWithUsdFxThroughSilverLakeEnabled =
        internationalWithUsdFxThroughSilverLakeEnabled;
    $scope.internationalWithUsdFxThroughSilverLakeDisabled =
        internationalWithUsdFxThroughSilverLakeDisabled;
    $scope.setBankTypeAndRegion = setBankTypeAndRegion;
    $scope.alreadyHasAnIntermediaryBank = alreadyHasAnIntermediaryBank;
    $scope.hideQuoteRestrictionInfo = entitlementsService.hasEntitlement(
        'Feature.FxWire.HideQuoteRestrictionInformation'
    );
    $scope.hasEnabledCorpayWire = entitlementsService.hasEntitlement(
        'Feature.Product.FxWire.EnableCorpayWire'
    );
    $scope.isBankAddressRequired = isBankAddressRequired;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });
    $scope.domesticCreditorCountries = ['US'];
    $scope.domesticCreditorCountry = 'US';
    let internationalUsdWireEnabled = false;
    let internationalDliEnabled = false;
    let standardWireProductEnabled = false;
    let dliWireProductEnabled = false;
    let useExternalFxWireProcessing = false;
    $scope.additionalLocationInfo = [];

    function hasWireIsoEntitlement() {
        return $scope.wireIsoEntitlement;
    }

    function canToggleWireType() {
        if (
            $scope.id ||
            !$scope.canCreateDomesticBeneficiary ||
            !$scope.canCreateFxOrInternationalBeneficiary
        ) {
            return false;
        }

        return isStandardWireAndInternationalUsdEnabled() || isStandardWireAndFxWireEnabled();
    }

    function toggleCurrency() {
        $scope.beneficiary.intermediaryBanks = [];
    }

    function beneficiaryInReadyStatus() {
        return $scope.beneficiary.status === 'Ready';
    }

    function canSendWireToBeneficiary() {
        if (!beneficiaryInReadyStatus()) return false;

        if ($scope.selection.isDomestic) {
            return $scope.canCreateDomesticWire;
        }
        if ($scope.beneficiary.isForeignCurrency) {
            return $scope.canCreateFxWire;
        }

        return $scope.canCreateInternationalWire;
    }

    function showSendWireButton() {
        return $scope.hasEnabledCorpayWire ? !$scope.beneficiary.isForeignCurrency : true;
    }

    function enableInternationalOption() {
        if ($scope.canCreateInternationalBeneficiary || $scope.canCreateFxBeneficiary) return true;
    }

    function useDomesticIntermediary() {
        let useIntermediary = false;
        if (!isInternational() && $scope.beneficiary && $scope.beneficiary.bank) {
            useIntermediary =
                !$scope.beneficiary.bank.id &&
                ($scope.beneficiary.bank.bankId ||
                    $scope.beneficiary.bank.state ||
                    $scope.beneficiary.bank.city ||
                    $scope.beneficiary.bank.name);
        }
        return useIntermediary;
    }

    function domesticBanksValid() {
        let isValid;
        // Because of how a domestic bank and an intermediary bank is conditionally displayed, this check is way more complicated then it should be
        isValid = $scope.form.bankId ? $scope.form.bankId.$valid : false;
        isValid =
            isValid &&
            $scope.form.beneficiaryBankName.$valid &&
            $scope.form.beneficiaryBankCity.$valid &&
            $scope.form.beneficiaryBankState.$valid;
        if ($scope.form.intermediaryBankId) {
            isValid = isValid && $scope.form.intermediaryBankId.$valid;
        }
        return isValid;
    }
    function domesticBanksDuplicated() {
        const domInter = getDomesticIntermediary();
        return domInter && $scope.beneficiary.bank.bankId === domInter.bankId;
    }

    function resetDomesticBeneficiaryBank() {
        $scope.beneficiary.intermediaryBanks = [];
        $scope.primaryDomesticBanks = $scope.beneficiaryBanks;
        evaluateNewRoutingNumber($scope.beneficiary.bank.bankId);
        $scope.form.$setDirty();
    }

    function onSelectDomesticBank(bank) {}

    function onSelectDomesticIntermediaryBank(bank) {}

    (async function () {
        // init
        $scope.selection.isDomestic = true;
        getStates();
        getCountries();
        getBanks();
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.wireConfig = response;

            internationalUsdWireEnabled =
                $scope.wireConfig.fiWireProductEnabled &&
                $scope.wireConfig.companyWireProductEnabled &&
                $scope.wireConfig.companyInternationalUsdEnabled;

            internationalDliEnabled =
                $scope.wireConfig.fiDliProductEnabled &&
                $scope.wireConfig.dliCompanyProductEnabled &&
                $scope.wireConfig.dliCompanyInternationalUsdEnabled;

            standardWireProductEnabled =
                $scope.wireConfig.fiWireProductEnabled &&
                $scope.wireConfig.companyWireProductEnabled;

            dliWireProductEnabled =
                $scope.wireConfig.fiDliProductEnabled && $scope.wireConfig.dliCompanyProductEnabled;

            useExternalFxWireProcessing = $scope.wireConfig.useExternalFxWireProcessing;

            if (angular.isObject($scope.beneficiary)) {
                $scope.pristineBeneficiary = angular.copy($scope.beneficiary);
                primeExistingBeneficiary();
            } else if ($scope.id && !angular.isObject($scope.beneficiary)) {
                beneficiariesService.get($scope.id).then(beneficiary => {
                    $scope.beneficiary = angular.copy(beneficiary);
                    $scope.pristineBeneficiary = angular.copy(beneficiary);
                    primeExistingBeneficiary($scope.beneficiary);
                });
            } else if (
                $scope.canCreateDomesticBeneficiary &&
                $scope.wireConfig.companyWireProductEnabled
            ) {
                setToDomestic();
            } else if (
                !internationalUsdWireEnabled &&
                !internationalDliEnabled &&
                !dliWireProductEnabled
            ) {
                setToDomestic();
            } else if (
                $scope.canCreateInternationalBeneficiary &&
                $scope.wireConfig.fiWireProductEnabled &&
                $scope.wireConfig.fiInternationalUsdEnabled &&
                $scope.wireConfig.companyWireProductEnabled &&
                $scope.wireConfig.companyInternationalUsdEnabled
            ) {
                setToInternational();
            } else if (
                $scope.canCreateFxBeneficiary &&
                $scope.wireConfig.fiDliProductEnabled &&
                $scope.wireConfig.dliCompanyProductEnabled
            ) {
                setToFx();
            } else {
                goToBeneficiaryList();
            }
            $scope.usdForeign.usdEnabled = isUsdEnabled();
            $scope.usdForeign.foreignEnabled = isForeignEnabled();
        });
        requireDomesticIntermediary();
    })();

    function addExistingAdditionalLocationFields() {
        const fields =
            additionalLocationInfoFields.filter(field => {
                const { id } = field;
                return exists($scope.beneficiary[id]);
            }) || [];

        if (fields.length < 1) {
            return;
        }

        $scope.showingAdditionalLocation = true;
        $scope.additionalLocationInfo = fields.map(field => {
            const { id } = field;
            return {
                type: field,
                value: $scope.beneficiary[id],
            };
        });
    }

    function primeExistingBeneficiary() {
        $scope.selection.isDomestic = !$scope.beneficiary.isInternational;
        $scope.beneficiary.confirmAccountNumber = $scope.beneficiary.accountNumber;
        $scope.beneficiary.isForeignCurrency = $scope.beneficiary.currencyType !== 'USD';

        if ($scope.beneficiaryBanks) {
            const domInter = getDomesticIntermediary();
            if (domInter) {
                $scope.primaryDomesticBanks = $scope.beneficiaryBanks.filter(
                    bank => bank.bankId !== domInter.bankId
                );
            }
            $scope.intermediaryDomesticBanks = $scope.beneficiaryBanks.filter(
                bank => bank.bankId !== $scope.beneficiary.bank.bankId
            );
        }
        $scope.addExistingAdditionalLocationFields();
        // In case a user goes directly to a edit url, check their entitlements
        if (!$scope.beneficiary.isInternational && !$scope.canEditDomesticBeneficiary) {
            goToBeneficiaryList();
        } else if (
            $scope.beneficiary.isInternational &&
            !$scope.beneficiary.isForeignCurrency &&
            !$scope.canEditInternationalBeneficiary &&
            !$scope.canEditFxBeneficiary
        ) {
            goToBeneficiaryList();
        } else if (
            $scope.beneficiary.isInternational &&
            $scope.beneficiary.isForeignCurrency &&
            !$scope.canEditFxBeneficiary
        ) {
            goToBeneficiaryList();
        }
    }

    function getBanks() {
        bankService.getAllBanks().then(response => {
            $scope.beneficiaryBanks = response;
            $scope.primaryDomesticBanks = response;
            $scope.intermediaryDomesticBanks = response;
        });
    }

    function confirmToggle() {
        if ($scope.form && $scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit() {
                    $modalInstance.close();
                },
            };
            const $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(
                () => {
                    toggle();
                },
                () => {
                    $scope.selection.isDomestic = $scope.previousSelection.isDomestic;
                }
            );
        } else {
            toggle();
        }
        requireDomesticIntermediary();
    }

    function toggle() {
        if ($scope.selection.isDomestic && $scope.wireConfig.companyWireProductEnabled) {
            setToDomestic();
        } else if (
            $scope.canCreateInternationalBeneficiary &&
            $scope.wireConfig.companyWireProductEnabled &&
            $scope.wireConfig.companyInternationalUsdEnabled
        ) {
            setToInternational();
        } else if (
            $scope.canCreateFxBeneficiary &&
            $scope.wireConfig.fiDliProductEnabled &&
            $scope.wireConfig.dliCompanyProductEnabled
        ) {
            setToFx();
        }
        $scope.previousSelection.isDomestic = $scope.selection.isDomestic;
        $scope.form.$setPristine(true);
    }

    function checkBeneficiaryAccountNumber(blurred) {
        if (blurred) {
            $scope.confirmFieldBlurred = true;
        }
        const isValid =
            $scope.beneficiary.accountNumber === $scope.beneficiary.confirmAccountNumber ||
            !$scope.confirmFieldBlurred;
        $scope.form.confirmAccountNumber.$setValidity('doesNotMatch', isValid);
    }

    function hasType() {
        return $scope.beneficiary && $scope.beneficiary.isInternational !== undefined;
    }

    function goToBeneficiaryList() {
        $state.go('payables.wire.beneficiary-list');
    }

    function checkEnability() {
        return $scope.form.$invalid;
    }

    function isInternational() {
        return $scope.beneficiary && $scope.beneficiary.isInternational;
    }

    function internationalWithUsdFxThroughSilverLakeEnabled() {
        return isInternational() && usdFxThroughSilverLakeEnabled();
    }

    function internationalWithUsdFxThroughSilverLakeDisabled() {
        return isInternational() && !usdFxThroughSilverLakeEnabled();
    }

    function usdFxThroughSilverLakeEnabled() {
        return internationalDliEnabled && !useExternalFxWireProcessing;
    }

    function useThreeLineAddress() {
        return beneficiariesService.canUseThreeLineAddress($scope.beneficiary);
    }

    function getStates() {
        statesService.getAll().then(response => {
            $scope.states = response;
        });
    }

    function getCountries() {
        countriesService.getAll().then(response => {
            $scope.countries = response;
        });
    }

    $scope.$watch('beneficiary.postalCode', newValue => {
        if (
            newValue !== null &&
            newValue !== undefined &&
            newValue.length === 5 &&
            isNaN(newValue) === false
        ) {
            $scope.beneficiary.postalCodeValid = true;
        }
    });

    $scope.$watch('beneficiary.bank.bankId', newValue => {
        evaluateNewRoutingNumber(newValue);
    });

    $scope.$watch('beneficiary.intermediaryBanks[0].bankId', newValue => {
        if (
            $scope.beneficiary &&
            $scope.beneficiary.intermediaryBanks &&
            $scope.beneficiary.intermediaryBanks.length > 0 &&
            $scope.beneficiary.intermediaryBanks[0] &&
            !$scope.beneficiary.intermediaryBanks[0].isInternational
        ) {
            processDomesticIntermediaryBank(0, newValue);
        }
    });

    $scope.$watch('beneficiary.intermediaryBanks[1].bankId', newValue => {
        if (
            $scope.beneficiary &&
            $scope.beneficiary.intermediaryBanks &&
            $scope.beneficiary.intermediaryBanks.length > 1 &&
            $scope.beneficiary.intermediaryBanks[1] &&
            !$scope.beneficiary.intermediaryBanks[1].isInternational
        ) {
            processDomesticIntermediaryBank(1, newValue);
        }
    });

    function evaluateNewRoutingNumber(newValue) {
        if ($scope.beneficiaryBanks && $scope.beneficiaryBanks.length > 0 && !isInternational()) {
            const results = $scope.primaryDomesticBanks.filter(bank => bank.bankId === newValue);
            if (results.length === 1) {
                $scope.beneficiary.bank.id = results[0].id;
                $scope.beneficiary.bank.city = results[0].city;
                $scope.beneficiary.bank.state = results[0].state;
                $scope.beneficiary.bank.name = results[0].name;
                $scope.intermediaryDomesticBanks = $scope.beneficiaryBanks.filter(
                    bank => bank.bankId !== $scope.beneficiary.bank.bankId
                );
            } else if (
                $scope.beneficiary &&
                $scope.beneficiary.bank &&
                $scope.beneficiary.bank.id
            ) {
                $scope.beneficiary.bank.id = '';
                $scope.beneficiary.bank.city = '';
                $scope.beneficiary.bank.state = '';
                $scope.beneficiary.bank.name = '';
            }
        }
    }
    function processDomesticIntermediaryBank(index, bankId) {
        if ($scope.beneficiaryBanks) {
            const results = $scope.intermediaryDomesticBanks.filter(bank => bank.bankId === bankId);
            if (results.length === 1) {
                $scope.beneficiary.intermediaryBanks[index].id = results[0].id;
                $scope.beneficiary.intermediaryBanks[index].city = results[0].city;
                $scope.beneficiary.intermediaryBanks[index].state = results[0].state;
                $scope.beneficiary.intermediaryBanks[index].name = results[0].name;
                $scope.beneficiary.intermediaryBanks[index].country = results[0].country;
                $scope.beneficiary.intermediaryBanks[index].isInternational = false;
                $scope.primaryDomesticBanks = $scope.beneficiaryBanks.filter(
                    bank => bank.bankId !== results[0].bankId
                );
            } else if (isInternational()) {
                $scope.beneficiary.intermediaryBanks[index].id = undefined;
                $scope.beneficiary.intermediaryBanks[index].city = undefined;
                $scope.beneficiary.intermediaryBanks[index].state = undefined;
                $scope.beneficiary.intermediaryBanks[index].name = undefined;
            } else {
                $scope.beneficiary.intermediaryBanks.splice(index, 1);
            }
        }
    }

    function setToDomestic() {
        activityService.userInteractionAudit(
            resourceType.WireResources,
            auditCode.AccessCreateDomesticBeneficiary
        );
        initBeneficiary();
        $scope.selection.isDomestic = true;
        $scope.beneficiary.isInternational = false;
        $scope.beneficiary.country = 'US';
        $scope.beneficiary.bank = { bankIdType: undefined };
    }

    function setToInternational() {
        activityService.userInteractionAudit(
            resourceType.WireResources,
            auditCode.CancelCreateDomesticBeneficiary
        );
        initBeneficiary();
        $scope.selection.isDomestic = false;
        $scope.beneficiary.isInternational = true;
        $scope.beneficiary.isForeignCurrency = false;
        $scope.beneficiary.country = '';
        $scope.beneficiary.bank = { bankIdType: 'Swift Code' };
    }

    function setToFx() {
        activityService.userInteractionAudit(
            resourceType.WireResources,
            auditCode.CancelCreateDomesticBeneficiary
        );
        initBeneficiary();
        $scope.selection.isDomestic = false;
        $scope.beneficiary.isInternational = true;
        $scope.beneficiary.isForeignCurrency = true;
        $scope.beneficiary.bank = { bankIdType: 'Swift Code' };
    }

    function showBankDialog(index) {
        const modalInstance = $uibModal.open({
            template: require('../views/bankDialogView.html'),
            size: 'md',
            controller: 'BankDialogController',
            backdrop: 'static',
            resolve: {
                banks() {
                    return $scope.beneficiaryBanks;
                },
                beneficiary() {
                    return $scope.beneficiary;
                },
            },
        });
        modalInstance.result.then(response => {
            if (index !== undefined) {
                $scope.beneficiary.intermediaryBanks[index] = response;
            } else {
                $scope.beneficiary.bank = response;
            }

            if (response) {
                $scope.form.$setDirty();
            }
        });
    }

    function createAnotherBeneficiary() {
        $state.go($state.current, {}, { reload: true });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function setBankIdType(intermediaryBank, type) {
        if (intermediaryBank !== null) {
            const index = $scope.beneficiary.intermediaryBanks.indexOf(intermediaryBank);
            $scope.beneficiary.intermediaryBanks[index].bankIdType = type;
        } else {
            $scope.beneficiary.bank.bankIdType = type;
        }
    }

    function setBankTypeAndRegion(intermediaryBank, bankIdType, isInternational) {
        if (
            intermediaryBank == null ||
            (intermediaryBank.bankIdType === bankIdType &&
                intermediaryBank.isInternational === isInternational)
        )
            return;

        intermediaryBank.bankIdType = bankIdType;
        intermediaryBank.isInternational = isInternational;

        clearAddressFields(intermediaryBank);

        if (intermediaryBank.bankIdType === 'Routing Number') intermediaryBank.country = 'US';
    }

    function clearAddressFields(intermediaryBank) {
        intermediaryBank.id = '';
        intermediaryBank.bankId = '';
        intermediaryBank.name = '';
        intermediaryBank.notes = '';

        intermediaryBank.addressLine1 = '';
        intermediaryBank.addressLine2 = '';
        intermediaryBank.city = '';
        intermediaryBank.state = '';
        intermediaryBank.country = '';
        intermediaryBank.postalCode = '';
        intermediaryBank.postalCodeExtension = '';
    }

    function checkBankBeneficiaryState() {
        let disable = false;
        if ($scope.beneficiary && (!$scope.beneficiary.bank || !$scope.beneficiary.bank.bankId)) {
            disable = true;
        } else if (
            $scope.beneficiary &&
            $scope.beneficiary.isInternational &&
            !$scope.beneficiary.isForeignCurrency &&
            $scope.wireConfig.fiInternationalUsdEnabled &&
            !hasDomesticIntermediary()
        ) {
            disable = true;
        }
        return disable;
    }

    function addIntermediaryBank(type) {
        if (type === 'international') {
            $scope.beneficiary.intermediaryBanks.push({
                isInternational: true,
                bankIdType: 'Swift Code',
            });
        } else {
            $scope.beneficiary.intermediaryBanks.push({ isInternational: false });
        }
    }

    function removeIntermediary(bank) {
        const modalOptions = {
            headerText: 'Confirm Deletion',
            bodyText: `Are you sure you want to delete this Intermediary ${$scope.wireIsoLabels.agent}?`,
            actionButtonText: `Delete Intermediary ${$scope.wireIsoLabels.agent}`,
            closeButtonText: 'Cancel',
            submit(result) {
                removeIntermediaryBank(bank);
                $modalInstance.close(result);
            },
        };
        const $modalInstance = modalService.showModal({}, modalOptions);
    }

    function removeIntermediaryBank(bank) {
        const index = $scope.beneficiary.intermediaryBanks.indexOf(bank);
        $scope.beneficiary.intermediaryBanks.splice(index, 1);
    }

    function applyAdditionalLocationInfo() {
        optionalLocationFieldIds.forEach(id => {
            const field = $scope.additionalLocationInfo.find((info) => info.type.id === id)
            $scope.beneficiary[id] = field?.value
        })
        $scope.additionalLocationLines = setAddress($scope.beneficiary, $scope.countries);
    }

    function goToReviewStep() {
        $scope.applyAdditionalLocationInfo();
        $scope.wizardController.goToNextStep();
    }

    function save(beneficiary) {
        if ($scope.beneficiary.id) {
            const actionType = buildActionType('Edit', beneficiary.isForeignCurrency);
            securityService
                .verifyUser(actionType, beneficiary, () => beneficiariesService.update(beneficiary))
                .then(response => {
                    $scope.beneficiary = response;
                    $scope.wizardController.goToNextStep();
                });
        } else {
            const actionType = buildActionType('Create', beneficiary.isForeignCurrency);

            securityService
                .verifyUser(actionType, beneficiary, () => beneficiariesService.create(beneficiary))
                .then(response => {
                    $scope.beneficiary = response;
                    $scope.wizardController.goToNextStep();
                });
        }
    }

    function buildActionType(action, isForeignCurrency) {
        let suffix = 'Beneficiary_ByName';
        if (isForeignCurrency) {
            suffix = 'FxBeneficiary_ByName';
        }
        return action + suffix;
    }

    function reset() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Reset Edits',
            headerText: 'Confirm Reset',
            bodyText: 'Are you sure you want to reset the beneficiary?',
            submit(result) {
                $modalInstance.close();
                resetForm();
            },
        };
        const $modalInstance = modalService.showModal({}, modalOptions);
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit() {
                    if ($scope.beneficiary.isInternational === true) {
                        activityService.userInteractionAudit(
                            resourceType.WireResources,
                            auditCode.CancelCreateInternationalBeneficiary
                        );
                    } else {
                        activityService.userInteractionAudit(
                            resourceType.WireResources,
                            auditCode.CancelCreateDomesticBeneficiary
                        );
                    }
                    $modalInstance.close();
                    $state.go('payables.wire.beneficiary-list');
                },
            };
            const $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            if ($scope.beneficiary.isInternational === true) {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateInternationalBeneficiary
                );
            } else {
                activityService.userInteractionAudit(
                    resourceType.WireResources,
                    auditCode.CancelCreateDomesticBeneficiary
                );
            }
            $state.go('payables.wire.beneficiary-list');
        }
    }

    function print() {
        window.print();
    }

    function sendAWireToThisBeneficiary() {
        if ($scope.beneficiary.isInternational && $scope.beneficiary.isForeignCurrency) {
            $state.go('payables.wire.create-fx-wire', { beneficiaryId: $scope.beneficiary.id });
        } else {
            $state.go('payables.wire.create-wire', { beneficiaryId: $scope.beneficiary.id });
        }
    }

    function getCountry(countryCode) {
        let result = null;
        $scope.countries.forEach(country => {
            if (country.countryCode === countryCode) {
                result = country.countryName;
            }
        });
        return result;
    }

    function getCityStateAndPostalCode(bank) {
        return beneficiariesService.getCityStateAndPostalCode(bank);
    }

    function alreadyHasAnIntermediaryBank() {
        return $scope.beneficiary.intermediaryBanks.length > 0;
    }

    function hasDomesticIntermediary() {
        let result = false;
        if (!$scope.beneficiary) {
            return result;
        }
        $scope.beneficiary.intermediaryBanks.forEach(intermediaryBank => {
            if (!intermediaryBank.isInternational) {
                result = true;
            }
        });
        return result;
    }
    function getDomesticIntermediary() {
        let result = null;
        if (!$scope.beneficiary) {
            return result;
        }
        $scope.beneficiary.intermediaryBanks.forEach(intermediaryBank => {
            if (!intermediaryBank.isInternational) {
                result = intermediaryBank;
            }
        });
        return result;
    }

    function hasInternationalIntermediary() {
        let result = false;
        if (!$scope.beneficiary) {
            return result;
        }
        $scope.beneficiary.intermediaryBanks.forEach(intermediaryBank => {
            if (intermediaryBank.isInternational) {
                result = true;
            }
        });
        return result;
    }

    function domesticIntermediaryValid() {
        return (
            $scope.beneficiary &&
            $scope.beneficiary.bank &&
            $scope.form.bankId &&
            $scope.form.bankId.$valid &&
            !$scope.beneficiary.bank.id
        );
    }

    function isValidRoutingNumber() {
        if ($scope.form.bankId && $scope.form.bankId.$viewValue) {
            if (
                !beneficiariesService.isValidRoutingNumber($scope.form.bankId.$viewValue) ||
                !$scope.beneficiaryBanks?.find(
                    bank => bank.bankId === $scope.form.bankId.$viewValue
                )
            ) {
                $scope.form.bankId.$setValidity('InvalidRoutingNumber', hasDomesticIntermediary());
                return false;
            }
            $scope.form.bankId.$setValidity('InvalidRoutingNumber', true);
        }
        return true;
    }

    //
    // Private

    function initBeneficiary() {
        if ($scope.beneficiary) {
            $scope.beneficiary.intermediaryBanks = [];
        } else {
            $scope.beneficiary = {
                intermediaryBanks: [],
            };

            if (
                $scope.wireConfig.dliCompanyInternationalUsdEnabled &&
                $scope.wireConfig.dliCompanyProductEnabled
            ) {
                $scope.beneficiary.isInternational = true;
                $scope.beneficiary.isForeignCurrency = true;
            }
        }
    }

    function canSelectDomesticIntermediary() {
        if (alreadyHasAnIntermediaryBank()) {
            return false;
        }

        return requireDomesticIntermediary();
    }

    function canSelectInternationalIntermediary() {
        if (
            alreadyHasAnIntermediaryBank() &&
            requireDomesticIntermediary() &&
            !internationalUsdWireEnabled
        ) {
            return false;
        }
        return true;
    }

    function requireDomesticIntermediary() {
        if ($scope.wireConfig && $scope.beneficiary) {
            if (
                $scope.wireConfig.fiWireProductEnabled &&
                $scope.wireConfig.companyWireProductEnabled &&
                $scope.wireConfig.companyInternationalUsdEnabled &&
                $scope.beneficiary.isForeignCurrency === false &&
                $scope.beneficiary.isInternational === true
            ) {
                return true;
            }
            if (
                $scope.fiDliProductEnabled &&
                $scope.wireConfig.dliCompanyProductEnabled &&
                $scope.wireConfig.dliCompanyInternationalUsdEnabled
            ) {
                return false;
            }
        }

        return false;
    }

    function showCurrencyType() {
        if (
            ($scope.wireConfig &&
                $scope.wireConfig.fiWireProductEnabled &&
                $scope.wireConfig.companyWireProductEnabled &&
                !$scope.beneficiary?.isInternational) ||
            isStandardWireAndInternationalUsdEnabled() ||
            isStandardWireFxWireAndDliInternationalUsdEnabled() ||
            isFxWireAndDliInternationalUsdEnabled()
        ) {
            return false;
        }

        return true;
    }

    function isUsdEnabled() {
        if ($scope.id) {
            return (
                $scope.canEditInternationalBeneficiary &&
                $scope.wireConfig &&
                $scope.wireConfig.fiWireProductEnabled &&
                $scope.wireConfig.companyWireProductEnabled &&
                $scope.wireConfig.companyInternationalUsdEnabled
            );
        }
        return (
            $scope.canCreateInternationalBeneficiary &&
            $scope.wireConfig &&
            $scope.wireConfig.fiWireProductEnabled &&
            $scope.wireConfig.companyWireProductEnabled &&
            $scope.wireConfig.companyInternationalUsdEnabled
        );
    }

    function isForeignEnabled() {
        if ($scope.id) {
            return (
                $scope.canEditFxBeneficiary &&
                $scope.wireConfig &&
                $scope.wireConfig.fiDliProductEnabled &&
                $scope.wireConfig.dliCompanyProductEnabled
            );
        }
        return (
            $scope.canCreateFxBeneficiary &&
            $scope.wireConfig &&
            $scope.wireConfig.fiDliProductEnabled &&
            $scope.wireConfig.dliCompanyProductEnabled
        );
    }

    function isStandardWireAndFxWireEnabled() {
        return standardWireProductEnabled && dliWireProductEnabled;
    }

    function isStandardWireAndInternationalUsdEnabled() {
        return standardWireProductEnabled && internationalUsdWireEnabled && !dliWireProductEnabled;
    }

    function isStandardWireFxWireAndDliInternationalUsdEnabled() {
        return standardWireProductEnabled && dliWireProductEnabled && internationalDliEnabled;
    }

    function isFxWireAndDliInternationalUsdEnabled() {
        return !standardWireProductEnabled && dliWireProductEnabled && internationalDliEnabled;
    }

    function resetAdditionalInfo () {
        $scope.additionalLocationInfo = [{ type: {
            name: 'Select type',
            id: 'blank',
            charLimit: 70,
            regex: acceptedAdditionalInfoChars,
        }, value: '' }];
        $scope.addExistingAdditionalLocationFields()
        $scope.applyAdditionalLocationInfo();
    }

    function resetForm() {
        resetAdditionalInfo();
        $scope.primaryDomesticBanks = $scope.beneficiaryBanks;
        $scope.intermediaryDomesticBanks = $scope.beneficiaryBanks;
        if ($scope.pristineBeneficiary) {
            $scope.beneficiary = angular.copy($scope.pristineBeneficiary);
            primeExistingBeneficiary();
        } else {
            $scope.beneficiary = {
                isInternational: $scope.beneficiary.isInternational,
                intermediaryBanks: [],
            };
        }
        $scope.form.$setPristine();
    }

    function isBankAddressRequired() {
        // if feature flag is set, address is not required. otherwise fall through to isInternational
        return $scope.hideQuoteRestrictionInfo ? false : $scope.beneficiary.isInternational;
    }
}
