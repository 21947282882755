const column5 = field => {
    if (!field) {
        return [
            {
                field: 'fullReturnReason',
                label: 'Return Reason',
            },
        ];
    }
    return [
        {
            field: 'fullReturnReason',
            label: 'Return Reason',
        },
        {
            field: 'dateOfDeath',
            label: 'Date Of Death',
        },
    ];
};

export const achReturnActivityDetailRowLayout = record => [
    [
        {
            field: 'originalEffectiveDate',
            label: 'Original Effective Date',
        },
        {
            field: 'receivedDate',
            label: 'Date Return Received',
        },
        {
            field: 'achCompanyName',
            label: 'ACH Company',
        },
    ],
    [
        {
            field: 'secCode',
            label: 'SEC Code',
        },
        {
            field: 'recipientName',
            label: 'Recipient Name',
        },
        {
            field: 'recipientFinancialInstitution',
            label: 'Recipient Financial Institution',
        },
    ],
    [
        {
            field: 'accountNumber',
            label: 'Recipient Account Number',
        },
        {
            field: 'accountType',
            label: 'Account Type',
        },
        {
            field: 'amount',
            label: 'Amount',
        },
    ],
    [
        {
            field: 'identificationNumber',
            label: 'Identification Number',
        },
        {
            field: 'traceNumber',
            label: 'Trace Number',
        },
    ],
    column5(record.getField('dateOfDeath')),
];
