/** this file will parse readable data from cascading logic currently powering template omega-selects */

import { InternationalAchTemplateOptions } from '@treasury/domain/channel/types/ach/international';
import { allAccountTypes } from './static-data';
import { reorderArray } from '../utils';

interface DestinationCountryCode {
    id: number;
    abbreviation: string;
    name: string;
}

export const getCascadingValueForCountryId = (
    id: number,
    iatAchOptions: InternationalAchTemplateOptions
) => iatAchOptions.values.cascadingValues.find(item => item.countryId === id);

export const getForeignExchangeIdFromName = (
    foreignExchangeIndicatorName: string,
    iatAchOptions: InternationalAchTemplateOptions
) =>
    iatAchOptions.values.foreignExchangeIndicators.find(
        indicator => indicator.name === foreignExchangeIndicatorName
    )?.id;

export const getForeignExchangeIndicators = async (
    destinationCountryCode: DestinationCountryCode,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    if (destinationCountryCode) {
        const { id } = destinationCountryCode;
        if (id) {
            const indicators =
                getCascadingValueForCountryId(id, iatAchOptions)?.foreignExchangeIndicators || [];
            const options = indicators.map(obj =>
                iatAchOptions.values.foreignExchangeIndicators.find(
                    item => item.id === obj.indicator
                )
            );
            return Promise.resolve(options);
        }
    }
    return Promise.resolve([]);
};

export const getForeignExchangeReferenceIndicators = async (
    destinationCountryCode: DestinationCountryCode,
    foreignExchangeIndicator: string,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    if (destinationCountryCode) {
        const { id } = destinationCountryCode;
        const hasId = id || id >= 0;
        if (hasId && foreignExchangeIndicator) {
            const { foreignExchangeReferenceIndicators } = iatAchOptions.values;
            const indicators =
                getCascadingValueForCountryId(id, iatAchOptions)?.foreignExchangeIndicators || [];
            const selectedIndicator = indicators.find(
                item =>
                    item.indicator ===
                    getForeignExchangeIdFromName(foreignExchangeIndicator, iatAchOptions)
            );
            const availableReferenceIndicators =
                selectedIndicator?.foreignExchangeReferenceIndicators || [];
            const options = availableReferenceIndicators.map(indicator =>
                foreignExchangeReferenceIndicators.find(item => item.id === indicator)
            );
            return Promise.resolve(options);
        }
    }
    return Promise.resolve([]);
};

export const getDestinationCurrencyCode = async (
    destinationCountryCode: DestinationCountryCode,
    foreignExchangeIndicator: string,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    if (destinationCountryCode) {
        const { id, abbreviation } = destinationCountryCode;
        const hasId = id || id >= 0;
        if (hasId && foreignExchangeIndicator) {
            const { currencies } = iatAchOptions.values;
            const selectedCountry = getCascadingValueForCountryId(id, iatAchOptions);
            const selectedIndicator = selectedCountry?.foreignExchangeIndicators.find(
                (indicator: { indicator: number }) =>
                    indicator.indicator ===
                    getForeignExchangeIdFromName(foreignExchangeIndicator, iatAchOptions)
            );
            const availableCurrencies = selectedIndicator?.destinationCurrencies || [];
            const optionsList =
                availableCurrencies.map(currencyId =>
                    currencies.find(currency => currency.id === currencyId)
                ) || [];

            const customOrderCountries: { GB: string[]; CH: string[] } = {
                GB: ['USD', 'EUR', 'GBP'],
                CH: ['CHF', 'EUR', 'USD'],
            };

            const updateOrder: string[] | undefined = (customOrderCountries as any)[abbreviation];
            if (updateOrder) {
                return Promise.resolve(reorderArray(optionsList, 'name', updateOrder));
            }

            return Promise.resolve(optionsList);
        }
    }
    return Promise.resolve([]);
};

export const foreignExchangeReferenceIndicatorDisabled = (
    destinationCountryCode: DestinationCountryCode,
    foreignExchangeIndicator: string,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    if (destinationCountryCode && foreignExchangeIndicator) {
        const countryId = destinationCountryCode.id;
        const hasId = countryId || countryId >= 0;
        if (hasId && foreignExchangeIndicator.length) {
            const indicators = getCascadingValueForCountryId(
                countryId,
                iatAchOptions
            )?.foreignExchangeIndicators;
            const availableReferenceIds = indicators?.find(
                item =>
                    item.indicator ===
                    getForeignExchangeIdFromName(foreignExchangeIndicator, iatAchOptions)
            );
            const disabledNumber = availableReferenceIds?.foreignExchangeReferenceIndicators.length;
            if (disabledNumber) {
                return false;
            }
        }
    }
    return true;
};

export const getAccountTypes = (
    destinationCountryCode: DestinationCountryCode,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    if (destinationCountryCode) {
        const countryId = destinationCountryCode.id;
        const hasId = countryId || countryId >= 0;
        if (hasId) {
            const selectedCountry = getCascadingValueForCountryId(
                destinationCountryCode.id,
                iatAchOptions
            );
            const availableTypes = selectedCountry?.internationalAccountTypes;

            const accountTypeOptions = availableTypes?.map(type =>
                allAccountTypes.find(item => item.id === type.accountType)
            );
            return new Promise(resolve => resolve(accountTypeOptions));
        }
    }
    return new Promise(resolve => resolve([]));
};

const getAccountTypeIdByName = (accountTypeName: 'Checking' | 'Savings' | 'Loan' | undefined) => {
    // to do: replace this lookup with iatAchOptions once the route has the map available
    switch (accountTypeName) {
        case 'Checking':
            return 1;
        case 'Savings':
            return 2;
        case 'Loan':
            return 3;
        default:
            return undefined;
    }
};

export const getTransactionTypes = (
    destinationCountryCode: DestinationCountryCode,
    accountType: 'Checking' | 'Savings' | 'Loan',
    // this is the legacy format
    // accountType: {accountName: "Checking", allowDebit: true, allowCredit: true}
    iatAchOptions: InternationalAchTemplateOptions
) => {
    if (destinationCountryCode) {
        const countryId = destinationCountryCode.id;
        const hasId = countryId || countryId >= 0;
        const hasAccountType =
            typeof accountType === 'string' ? !!accountType.length : !!accountType;
        if (hasId && hasAccountType) {
            // find cascading values for selected country/account type
            const selectedCountry = getCascadingValueForCountryId(
                destinationCountryCode.id,
                iatAchOptions
            );
            const availableAccountTypes = selectedCountry?.internationalAccountTypes;
            const accountTypeId = getAccountTypeIdByName(accountType);
            const selectedAccountType = availableAccountTypes?.find(
                type => type.accountType === accountTypeId
            );
            // use selected account type to lookup available transaction types
            const availableTransactionTypes = selectedAccountType?.transactionTypes;
            const allTransactionTypes = iatAchOptions.values.internationalTransactionTypes;
            const accountTypeOptions = availableTransactionTypes?.map(type =>
                allTransactionTypes.find(item => item.id === type)
            );
            return Promise.resolve(accountTypeOptions);
        }
    }
    return Promise.resolve([]);
};

export const getPaymentType = (
    destinationCountryCode: DestinationCountryCode,
    accountType: 'Checking' | 'Savings' | 'Loan' | undefined,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    const countryId = destinationCountryCode?.id;
    const hasId = typeof countryId === 'number' && countryId >= 0;
    const hasAccountType = typeof accountType === 'string' ? !!accountType.length : accountType;
    const result: string[] = [];
    if (hasId && hasAccountType) {
        // to do, update paymentTypeList once transaction type is added to api
        const paymentTypeList: string[] = ['Debit', 'Credit'];
        const countryCascadingValue = getCascadingValueForCountryId(countryId, iatAchOptions);
        const accountTypeId = getAccountTypeIdByName(accountType);
        const paymentTypeIds = countryCascadingValue?.internationalAccountTypes.find(
            item => item.accountType === accountTypeId
        )?.transactionTypes;
        paymentTypeIds?.forEach((id: number) => result.push(paymentTypeList[id - 1]));
    }
    return Promise.resolve(result);
};

export const getReceivingBankCountries = (
    destinationCountryCode: DestinationCountryCode,
    iatAchOptions: InternationalAchTemplateOptions
) => {
    const countryId = destinationCountryCode?.id;
    const hasId = typeof countryId === 'number' && countryId >= 0;
    if (hasId) {
        const cascadingValues = getCascadingValueForCountryId(countryId, iatAchOptions);
        const countryList =
            cascadingValues?.receivingBankCountries?.map(id =>
                iatAchOptions.values.countries.find(country => country.id === id)
            ) || [];
        return Promise.resolve(countryList);
    }
    return Promise.resolve([]);
};
