import DateModel from '../components/datepicker/DateModel.js';

export default function compareDates(a, b) {
    function normalizedDate(DateString) {
        const date = new Date(DateString);
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }

    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;

    const aDate = new DateModel(normalizedDate(a));
    const bDate = new DateModel(normalizedDate(b));

    if (aDate.lessThan(bDate)) {
        return -1;
    }

    if (aDate.greaterThan(bDate)) {
        return 1;
    }

    return 0;
}
