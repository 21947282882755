import { exists } from '@treasury/utils';

DayBalancesController.$inject = ['$scope', 'downloadPageId', '$filter'];

/**
 * @typedef { import('@treasury/api/channel').ReportResultBaseModelDto  & { clientId: string}} ReportResult
 */

export default function DayBalancesController($scope, downloadPageId, $filter) {
    $scope.downloadPageId = downloadPageId.DayBalanceReport;
    $scope.filterObject = {};
    $scope.balanceDayReport = {};
    $scope.hasReportBeenGenerated = false;
    $scope.filterOptions = {
        orderByField: '',
        reverseSort: false,
    };

    $scope.calculateTotals = function (data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    };

    $scope.isWidthSmall = function (widthValue) {
        return widthValue;
    };

    $scope.parse = function (value) {
        return parseFloat(value);
    };

    $scope.$on('reportUpdated', (event, response) => {
        $scope.filterOptions.orderByField = angular.copy(response.sortBy);
        if (
            $scope.filterOptions.orderByField !== null &&
            $scope.filterOptions.orderByField !== undefined
        ) {
            $scope.filterOptions.orderByField =
                $scope.filterOptions.orderByField.substring(0, 1).toLowerCase() +
                $scope.filterOptions.orderByField.substring(
                    1,
                    $scope.filterOptions.orderByField.length
                );
        }
        if (response.isAscending) {
            $scope.filterOptions.reverseSort = false;
        } else {
            $scope.filterOptions.reverseSort = true;
        }
    });

    $scope.$watch(
        'balanceDayReport',
        /**
         * @param { ReportResult | undefined} newValue
         * @param { ReportResult | undefined} oldValue
         */
        (newValue, oldValue) => {
            if (
                exists(newValue) &&
                exists(newValue.data) &&
                oldValue.clientId !== newValue.clientId
            ) {
                if (oldValue === null || newValue.data.length === 0) {
                    $scope.sidebarContainerController.setCollapsed(false);
                } else {
                    $scope.sidebarContainerController.setCollapsed(true);
                }

                $scope.hasReportBeenGenerated = true;
                $scope.balanceDayReport = newValue;
                // we must set debits/credits amount to 0
                $scope.balanceDayReport.data.forEach(item => {
                    if (item.credits === null || item.credits === undefined) {
                        item.credits = 0;
                    }
                    if (item.debits === null || item.debits === undefined) {
                        item.debits = 0;
                    }
                });
                $scope.balanceDayReport.data = $filter('orderByNumeric')(
                    $scope.balanceDayReport.data,
                    'accountNumber'
                );
            }
        },
        true
    );
}
