export const reportColumns = [
    {
        field: 'effectiveDate',
        label: 'Original Effective Date',
    },
    {
        field: 'receivedDate',
        label: 'Date Notification Received',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company',
    },
    {
        field: 'fullReturnReason',
        label: 'Change Code',
    },
];
