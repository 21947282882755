import { WorkflowTypeEntitlements, WorkflowTypes } from '@treasury/domain/channel/types/ach/';
import Entitlements, { Entitlement } from '../../../../utilities/entitlements';
import { AchDomesticBatch } from '../../containers/ach-domestic-batch';
import { AchDomesticContainer } from '../../containers/ach-domestic-container';

export class EntitlementsFactory {
    constructor(context: AchDomesticContainer | AchDomesticBatch) {
        this.context = context;
    }

    context: AchDomesticContainer | AchDomesticBatch;

    getEntitlements(type: WorkflowTypes): WorkflowTypeEntitlements {
        switch (type) {
            case WorkflowTypes.Template:
                return {
                    type: WorkflowTypes.Template,
                    edit: {
                        full: this.hasPermission(Entitlements.Ach.FullEditTemplate.permission),
                        partial: this.hasPermission(
                            Entitlements.Ach.PartialEditTemplate.permission
                        ),
                    },
                };
            case WorkflowTypes.Payment:
            default:
                return {
                    type: WorkflowTypes.Payment,
                    allowUnbalanced: this.hasPermission(
                        Entitlements.Ach.AllowUnbalancedPayments.permission
                    ),
                    restrictPayment: this.hasPermission(
                        Entitlements.Ach.RestrictPayment.permission
                    ),
                    edit: {
                        full: this.hasPermission(Entitlements.Ach.FullEditPayment.permission),
                        partial: this.hasPermission(Entitlements.Ach.PartialEditPayment.permission),
                    },
                    onUsAccessManagementFeature: this.hasPermission(
                        Entitlements.Ach.AllowOnUsAccessManagementFeature.permission
                    ),
                };
        }
    }

    get entitlements(): WorkflowTypeEntitlements {
        return this.getEntitlements(this.context.workflow.type);
    }

    private hasPermission(entitlement: string): boolean {
        const entitlements = this.context.achConfiguration?.entitlements;
        if (!entitlements) return false;
        return entitlements.some((e: Entitlement) => e.permission === entitlement);
    }
}
