/* eslint-disable import/extensions */
import { AmountRangeFormatters } from '../../../shared/utilities/amount-range-formatters';
import { DateFormatters } from '../../../shared/utilities/date-formatters';

export const mapTransferActivityParameters = params => ({
    accountOperator: params.accountOperator,
    transactionId: params.transactionId,
    status: params.status,
    amountOperator: params.amount[0] === 'range' ? 'Range' : 'Specific Amount',
    fromAmount:
        AmountRangeFormatters.getRangeStart(params.amount) ??
        AmountRangeFormatters.getSpecificAmount(params.amount),
    toAmount: AmountRangeFormatters.getRangeEnd(params.amount),
    toAccounts: params.accountOperator === 'To' ? params.activityAccounts : [],
    fromAccounts: params.accountOperator === 'From' ? params.activityAccounts : [],
    bothAccounts: params.activityAccounts,
    createdDateOperator:
        DateFormatters.getDateType(params.createdDate) === 'dateRange'
            ? 'Date Range'
            : 'Specific Date',
    createdDateFrom:
        DateFormatters.parseDate(params.createdDate).value.start ??
        DateFormatters.parseDate(params.createdDate).value,
    createdDateTo: DateFormatters.parseDate(params.createdDate).value.end ?? null,
    transferDateOperator:
        DateFormatters.getDateType(params.transferDate) === 'dateRange'
            ? 'Date Range'
            : 'Specific Date',
    transferDateFrom:
        DateFormatters.parseDate(params.transferDate).value.start ??
        DateFormatters.parseDate(params.transferDate).value,
    transferDateTo: DateFormatters.parseDate(params.transferDate).value.end ?? null,
    // pagingParameters: {
    //     orderBys: sortColumns.length
    //         ? PagingFormatters.parseSortColumns(sortColumns)
    //         : [{ Name: 'UploadDate', Descending: true }],
    //     pageSize: rowCount,
    //     pageNumber: PagingFormatters.getPageNumber(startRowNumber, rowCount),
    // },
});

function mapEditTransferParamsToRequest(params, comments) {
    return {
        ...params,
        comments,
        transferDate: DateFormatters.parseDate(params.transferDate).value,
        toAccount: {
            ...params.toAccount,
            account: {
                id: 0,
                number: params.toAccount.number,
                type: params.toAccount.type,
                coreStatus: null,
                accountDisplayLabel: null,
                name: null,
            },
        },
        fromAccount: {
            ...params.fromAccount,
            account: {
                id: 0,
                number: params.fromAccount.number,
                type: params.fromAccount.type,
                coreStatus: null,
                accountDisplayLabel: null,
                name: null,
            },
        },
    };
}

function generateTransferData(action, params, transferCount) {
    if (action === 'resubmit') {
        return mapEditTransferParamsToRequest(
            params.selectedTransferRecords[0].values,
            params.statusChangeComments
        );
    }
    return {
        comments: params.statusChangeComments,
        ...(transferCount === 1 && {
            internalTransferId: params.selectedTransferRecords[0].getField('id'),
        }),
        ...(transferCount > 1 && {
            internalTransfers: params.selectedTransferRecords.map(record => record.values),
        }),
    };
}

function generateSecurityActionType(action) {
    if (action === 'cancel') return action;
    if (action === 'resubmit') return 'Edit';
    return action[0].toUpperCase() + action.substring(1).toLowerCase();
}

function generateStatusUpdateEndpoint(action, securityActionType, transferCount, data) {
    if (action === 'resubmit') return data.id;
    if (action === 'approve' || action === 'reject') {
        if (transferCount > 1) {
            return `batch${securityActionType}`;
        }
        return `${data.internalTransferId}/${action}`;
    }
    return action;
}

export const mapUpdateTransferParamsToRequest = (action, params) => {
    const transferCount = params.selectedTransferRecords.length;
    const data = generateTransferData(action, params, transferCount);
    const securityActionType = generateSecurityActionType(action);
    const endpoint = generateStatusUpdateEndpoint(action, securityActionType, transferCount, data);

    return { securityActionType, endpoint, data };
};
