import { Injectable } from '@jack-henry/frontend-utils/di';
import { html, nothing } from 'lit';
import { OmegaAlertConfig } from './omega-alert.service.types';

const DEFAULT_CONFIG = Object.freeze<OmegaAlertConfig>({
    title: '',
    message: '',
    visible: false,
    code: '',
    time: '',
    type: 'success',
    posture: 'polite',
    actions: '',
    onClose: () => null,
});

/**
 * Duplicates functionality found in `AlertMixin` for locations
 * where mixins are not ideal.
 *
 * TODO: Refactor this into a service that displays true notifications/alerts/toasts
 * rather than just rendering a template.
 */
@Injectable()
export class OmegaAlertService {
    private alert?: OmegaAlertConfig;

    public get isVisible() {
        return this.alert ? this.alert.visible : false;
    }

    /**
     * @deprecated TODO: implement a new method to replace this one that displays
     * alerts without having to manually place them in `render()` calls a la the dialog service.
     */
    public renderAlert(config: Partial<OmegaAlertConfig>) {
        if (this.alert) {
            throw new Error('Cannot render multiple alerts.');
        }

        this.alert = this.normalizeConfig(config);
        const { code, time, message, type, title, actions, posture, visible, onClose, width } =
            this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;
        if (!visible) return nothing;
        return html`
            <omega-alert
                type=${type}
                title=${title}
                posture=${posture}
                ?isVisible=${visible}
                .width=${width}
                @close=${() => {
                    this.alert = undefined;
                    if (onClose) onClose();
                }}
            >
                ${renderedCode} ${message} ${renderedTime}
                <div slot="actions">${actions}</div>
            </omega-alert>
        `;
    }

    // eslint-disable-next-line sort-class-members/sort-class-members
    private normalizeConfig(config: Partial<OmegaAlertConfig>) {
        return {
            ...DEFAULT_CONFIG,
            ...config,
        };
    }
}
