import { LitElement } from 'lit';
import { Record as FdlRecord } from '@treasury/FDL';
import { SyncAsyncDeferred } from '@treasury/utils';
import { AlertMixin } from '../../mixins';
import { ListeningElementMixin } from '../../components/listening-element';
import { OmegaReportFilter, LocalFilterResult } from '../../types';

export interface ActionDetail<T = unknown> {
    action: string;
    rowIndex: number;
    record: FdlRecord<T>;
}
export interface LoadingEventDetail {
    loading: boolean;
}

export type ProvidedLocalFilters<T> = SyncAsyncDeferred<LocalFilterResult<T>[]>;

export type ProvidedFilters<T> = SyncAsyncDeferred<OmegaReportFilter<T>[]>;

export type ActionMap = Record<string, (record: FdlRecord<unknown>, rowIndex: number) => void>;

export const AlertingListeningElement = AlertMixin(ListeningElementMixin(LitElement));

export interface StateObject {
    datastoreKey: string;
}
