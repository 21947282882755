AchCompaniesModalController.$inject = ['$scope', '$modalInstance', 'data', '$timeout', '$filter'];

export default function AchCompaniesModalController(
    $scope,
    $modalInstance,
    data,
    $timeout,
    $filter
) {
    $scope.searchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.data = data;
    $scope.selectRow = selectRow;
    $scope.filterData = filterData;
    $scope.getSecCodesString = getSecCodesString;
    $scope.search = search;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function selectRow(row) {
        $modalInstance.close(row);
    }

    function filterData(row) {
        return row.companyName.indexOf($scope.searchText) !== -1;
    }

    function getSecCodesString(row) {
        return row.secCodes.map(elem => elem.code).join(', ');
    }

    function search() {
        $scope.filteredData = $filter('filter')($scope.data, $scope.searchText);
    }

    function init() {
        angular.forEach($scope.data, achCompany => {
            achCompany.concatSecCodes = getSecCodesString(achCompany);
        });
        search();
    }

    init();
}
