import { DiContainer, Injectable } from '@jack-henry/frontend-utils/di';
import {
    AccountClient,
    SecurityClient,
    SecurityQuestionModelDto,
    UserInformationModelDto,
    UserModelDto,
    UserPasswordModelDto,
} from '@treasury/api/channel';
import { SessionStorageService } from '@treasury/utils/services';
import AccountRequests from '../../requests/account/account.js';

const SecureTokenActionTypes = {
    RemindMeLater: 1,
    StopReminders: 2,
    Register: 3,
};

/**
 * Modern, injectable service used for interacting with accounts.
 */
@Injectable()
export class AccountService {
    constructor(
        private readonly accountClient: AccountClient,
        private readonly securityClient: SecurityClient,
        private readonly sessionStorage: SessionStorageService
        // eslint-disable-next-line no-empty-function
    ) {}

    public static async getInstance() {
        const container = await DiContainer.getInstance();
        return container.get(AccountService);
    }

    /**
     * Login to channel with credentials
     * @param credentials
     * @returns CredentialDto
     */
    public async login(credentials: {
        institution: string;
        companyId: string;
        loginId: string;
        password: string;
    }) {
        const response = await this.accountClient.accountLogin({
            institution: credentials.institution,
            companyID: credentials.companyId,
            loginID: credentials.loginId,
            password: credentials.password,
            id: 0,
            useClientBiometricsAuthentication: false,
        });

        return response.data;
    }

    /**
     * Get the currently authenticated user (cached for 16 hours)
     * @returns UserInformationModel
     */
    public async getCurrentUser() {
        const response = await this.accountClient.accountGet({ maxAgeInSeconds: 60000 });
        const companyId = this.sessionStorage.get<{ companyId: string }>('user')?.companyId || '';

        return {
            companyId,
            ...response.data,
        };
    }

    /**
     * Get the currently authenticated user (cached for 30 seconds)
     */
    public async getCurrentUserAccountSettings() {
        const response = await this.accountClient.accountGet({ maxAgeInSeconds: 30 });
        return response.data;
    }

    /**
     * Update the currently authenticated user's account settings
     */
    public async updateCurrentUserAccountSettings(user: UserInformationModelDto) {
        const response = await this.accountClient.accountPost(user);
        return response.data;
    }

    /**
     * Reset the current user account cache
     */
    public async resetCurrentUserAccountCache() {
        AccountRequests.resetCurrentUserAccountCache();
    }

    /**
     * Get settings for the user that is currently authenticated
     */
    public async getUserSettings() {
        const response = await this.accountClient.accountSettings();
        return response.data;
    }

    /**
     * Logout the current user out of the channel application
     */
    public async logout(reason: string) {
        const quoteRegex = /^"+([^"]+)"+$/gis;
        const response = await this.accountClient.accountLogOutWithUrl({ reason });
        const logoutUrl = response.data;
        const matchResults = quoteRegex.exec(logoutUrl);

        // if URL comes back quoted we should trim it
        return matchResults && matchResults.length > 1 ? matchResults[1] : logoutUrl;
    }

    /**
     * Save users's security questions
     */
    public async saveSecurityQuestions(questions: SecurityQuestionModelDto[]) {
        const response = await this.accountClient.accountSecurityQuestions(questions);
        return response.data;
    }

    /**
     * Check that the current user has successfully logged in
     */
    public async checkLoginCompletion() {
        const response = await this.accountClient.accountCheckLoginCompletion();
        return response.data;
    }

    /**
     * Verify a users's security questions
     * @param questions
     * @returns CredentialDto
     */
    public async verifySecurityQuestions(questions: SecurityQuestionModelDto[]) {
        const response = await this.accountClient.accountVerifySecurityQuestions(questions);
        return response.data;
    }

    /**
     * Register a secure token for a given user
     * @param credentialId
     * @param token
     * @param pin
     */
    public async registerSecureToken(credentialId?: string, token?: string, pin?: string) {
        // complete-secure-token-config?actionType=:actionType&credentialId=:credentialId&token=:token&pin=:pin
        const response = await this.securityClient.securityCompleteSecureTokenConfig({
            actionType: SecureTokenActionTypes.Register,
            credentialId: credentialId ? encodeURIComponent(credentialId) : null,
            token: token ? encodeURIComponent(token) : null,
            pin: pin ? encodeURIComponent(pin) : null,
        });
        return response.data;
    }

    /**
     * Submit a request to reset a given users's password
     * @param user
     * @returns
     */
    public async forgotPassword(user: UserModelDto) {
        const response = await this.accountClient.accountForgotPassword(user);
        return response.data;
    }

    /**
     * Send a one time password for authentication
     */
    public async sendOneTimePassword(phoneNumber: string, method: string, useForBoth: boolean) {
        const response = await this.securityClient.securityVerifyMethod(
            {
                useForBoth: Boolean(useForBoth).toString(),
            },
            {
                phoneNumber,
                method,
                pinNumber: '',
            }
        );
        return response.data;
    }

    /**
     *
     * Validate a user's one time password
     * @param oneTimePassword
     * @returns
     */
    public async validateOneTimePassword(oneTimePassword: string) {
        const response = await this.securityClient.securityValidateOneTimePassword({
            oneTimePassword,
            actionType: '',
            hasAlternate: false,
            challengeMethodTypeId: 0,
        });
        return response.data;
    }

    /**
     * Sets the user's preferred method of verification
     * @param method (SMS, Automated Phone Call)
     */
    public async sendOutOfBandPreferredMethod(method: string) {
        return this.securityClient.securitySetPreferredMethod({
            methodType: encodeURIComponent(method),
        });
    }

    /**
     * Set the user's oob verification to remind the user later
     * @returns
     */
    public async oobRemindMeLater() {
        const response = await this.securityClient.securityCompleteConfig({ action: 'Remind' });
        return response.data;
    }

    /**
     * Set the user's secure token verification to remind the user later
     */
    public async secureTokenRemindMeLater() {
        const response = await this.securityClient.securityCompleteSecureTokenConfig({
            actionType: SecureTokenActionTypes.RemindMeLater,
            credentialId: null,
            token: null,
            pin: null,
        });
        return response.data;
    }

    /**
     * Get the security configuration for the authenticated user
     */
    public async getSecurityConfig() {
        const response = await this.securityClient.securityConfig();
        return response.data;
    }

    /**
     * Removes an Out-Of-Band (OOB) verification contact method (SMS or automated phone call) for a user.
     * @param methodType
     * @returns
     */
    public async removeVerificationMethod(methodType: string) {
        const response = await this.securityClient.securityRemoveMethod({
            methodType: encodeURIComponent(methodType),
        });
        return response.data;
    }

    /**
     * Completes the security configuration.
     * @returns
     */
    public async completeOutOfBandConfiguration() {
        const response = await this.securityClient.securityCompleteConfig({ action: 'Done' });
        return response.data;
    }

    /**
     * Validate a users's secure token
     * @param token
     * @returns
     */
    public async validateSecureToken(token: string) {
        const response = await this.securityClient.securityValidateSecureToken({
            token,
        });
        return response.data;
    }

    /**
     * Verify the current user
     * @param user
     *  */
    public async verifyUser(user: UserModelDto) {
        const response = await this.accountClient.accountVerifyUser(user);
        return response.data;
    }

    /**
     * Get password requirements for the given institution
     * @param institution
     */
    public async getPasswordRequirements(institution: string) {
        const response = await this.accountClient.accountPasswordRequirements({
            fiId: institution,
        });
        return response.data;
    }

    /**
     * Change the current user's password
     * @param password
     */
    public async changePassword(passwords: UserPasswordModelDto) {
        const response = await this.accountClient.accountChangePassword(passwords);
        return response.data;
    }

    /**
     * Update the current user's password
     * @param password
     */
    public async updatePassword(user: UserModelDto) {
        const response = await this.accountClient.accountUpdatePassword(user);
        return response.data;
    }

    /**
     * Get the current out of band configuration
     *
     * @returns UserSecurityConfigModelDto
     */
    public async getOutOfBandConfig() {
        const response = await this.securityClient.securityConfig();
        return response.data;
    }

    /**
     * Completes the security configuration with one of three options.
     *
     * @returns boolean
     */
    public async completeOutOfBandConfig() {
        const response = await this.securityClient.securityCompleteConfig({ action: 'Done' });
        return response.data;
    }
}
