/* eslint-disable no-use-before-define */
import { RecordsetColumn } from '@treasury/FDL/recordset';
import {
    UserModelDto,
    WireCompanyModelDto,
    WireFileSearchCriteriaModelDto,
} from '@treasury/api/channel';
import { WireFileActivity } from '@treasury/domain/channel/mappings/wires/wire-file-activity.entity';
import {
    WireFileActivityQueryDto,
    WireTemplateQueryDto,
} from '@treasury/domain/channel/types/wires';
import { DateFormatters, corePagingParameters } from '@treasury/domain/shared';
import { AmountRangeFormatters } from '@treasury/domain/shared/utilities/amount-range-formatters';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import { extractDates } from '@treasury/omega/util';
import { coerceEmptyStringsToNull, coerceMembersToNull } from '@treasury/utils/functions';
import { Nullable } from '@treasury/utils/types';

interface AllSearchParams {
    name: string;
    beneficiaryName: string;
    createdDate: OmegaDate;
    status: string[];
    debitAccount: WireTemplateQueryDto['debitAccounts'];
    wireCompany: WireTemplateQueryDto['wireCompanies'];
    type: string;
    fileName: string;
    isDownloadReport: boolean;
    uploadDate: OmegaDate | '$TODAY';
    uploadDateType: string;
    wireCompanies: WireCompanyModelDto[];
    uploadedBy: UserModelDto[];
    amount: number;
    wireAmount: number;
    wireAmountMax: number;
    wireAmountMin: number;
    wireAmountType: string;
}

/**
 * Component view model of search parameters generated by the filter.
 */
export type WireTemplateSearchParams = Nullable<AllSearchParams>;

/**
 * Component view model of search parameters generated by the filter.
 */
export type WireFileActivitySearchParams = Nullable<AllSearchParams>;

/**
 * Maps the view model filter parameters to the endpoint shape the API expects.
 */
export function mapWireTemplateSearchParams(
    params: WireTemplateSearchParams
): WireTemplateQueryDto {
    const { name, beneficiaryName, wireCompany, status, debitAccount, createdDate, type } =
        coerceEmptyStringsToNull(coerceMembersToNull(params));
    const { start: createdDateStart, end: createdDateEnd } = extractDates(
        coerceString(createdDate)
    );

    return {
        templateName: name,
        beneficiaryName,
        debitAccounts: debitAccount,
        wireCompanies: wireCompany,
        wireType: type,
        statuses: (status || []).map(status => ({
            name: status,
        })),
        createdDateStart,
        createdDateEnd,
        createdDateType: createdDateEnd ? 'Date Range' : 'Specific Date',
    };
}

/**
 * Maps the view model filter parameters to the endpoint shape the API expects.
 */
export function mapWireFileActivitySearchParams(
    params: WireFileActivitySearchParams,
    pageSize: number,
    sort: RecordsetColumn<WireFileActivity>[],
    startIndex: number
): WireFileSearchCriteriaModelDto {
    const { fileName, isDownloadReport, uploadDate, uploadedBy, amount } = coerceEmptyStringsToNull(
        coerceMembersToNull(params)
    );
    let uploadDateStart = '';
    let uploadDateEnd = '';
    if (typeof uploadDate === 'string' && uploadDate.substring(0, 1) === '$') {
        uploadDateStart = DateFormatters.parseDate(uploadDate).value;
        uploadDateEnd = uploadDateStart;
    } else {
        const { start, end } = extractDates(coerceString(uploadDate));
        uploadDateStart = start;
        uploadDateEnd = end;
    }
    const uploadedByIds = (uploadedBy || []).map(user => user.id);
    const pagingParameters = corePagingParameters('UploadDate', sort || [], startIndex, pageSize);

    return {
        id: 0,
        updatedDate: '',
        wireFileUniqueId: '',
        fileName: fileName || undefined,
        isDownloadReport: isDownloadReport || false,
        uploadDateStart,
        uploadDateEnd,
        uploadDateType: uploadDate ? 'dateRange' : undefined,
        uploadedByIds,
        wireAmountMin: amount
            ? AmountRangeFormatters.getRangeStart(amount) || undefined
            : undefined,
        wireAmountMax: amount ? AmountRangeFormatters.getRangeEnd(amount) || undefined : undefined,
        wireAmount: amount
            ? AmountRangeFormatters.getSpecificAmount(amount) || undefined
            : undefined,
        wireAmountType: amount ? AmountRangeFormatters.getRangeType(amount) : undefined,
        pagingParameters,
    };
}

function coerceString<T>(value: T | string) {
    if (typeof value === 'string') {
        return null;
    }

    return value;
}
