import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import './omega-button';
import './omega-icon';
import './omega-tooltip';

export class OmegaValidationButton extends LitElement {
    @property({ type: String, reflect: true })
    type!: string;

    @property({ type: String, reflect: true })
    icon!: string;

    @property({ type: Boolean, reflect: true })
    disabled!: boolean;

    @property({ type: String, reflect: true })
    hideLabel!: string;

    @property({ type: String, reflect: true })
    posture!: string;

    @property({ type: Boolean, reflect: true })
    block!: boolean;

    @property({ type: Boolean, reflect: true })
    loading!: boolean;

    @property({ type: Boolean, reflect: true })
    submit!: boolean;

    @property({ type: String })
    width!: string;

    @property({ type: String })
    tooltipDirection!: 'top' | 'bottom' | 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left';

    @property({ type: String })
    message?: string;

    // STUB: No storybook story for this component
    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-button--button',
        };
    }

    onClick() {
        this.dispatchEvent(
            new CustomEvent('submit', {
                bubbles: true,
                composed: true,
            })
        );
    }

    render() {
        const secondaryIcon = this.icon ?? 'warning';
        const tooltipDirection = this.tooltipDirection ?? 'top-right';
        return html`
            <omega-button
                class=${classMap({ left: this.message !== undefined })}
                .type=${this.type}
                .disabled=${this.disabled}
                .hideLabel=${this.hideLabel}
                .posture=${this.posture}
                .block=${this.block}
                .loading=${this.loading}
                .submit=${this.submit}
                .width=${this.width}
                @click=${this.onClick}
            >
                <slot></slot> </omega-button
            >${this.message
                ? html`<omega-button type=${this.type} class="right">
                      <omega-tooltip .light=${true} .direction=${tooltipDirection}>
                          <omega-icon .icon=${secondaryIcon}></omega-icon>
                          <slot slot="content">${unsafeHTML(this.message)}</slot>
                      </omega-tooltip>
                  </omega-button>`
                : nothing}
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 8px;
            }
            omega-button {
                margin: 0;
            }
            omega-button.left {
                --omega-button-border-radius-right: 0;
            }
            omega-button.right {
                --omega-button-border-radius-left: 0;
                --omega-button-min-width: 0;
            }
        `;
    }
}

// eslint-disable-next-line @treasury/consistent-custom-element-name
customElements.define('omega-validation-button', OmegaValidationButton);
