// cspell:ignore uirouter
import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record, Recordset } from '@treasury/FDL';
import { RecordsetFetchArgs } from '@treasury/FDL/recordset';
import { WireCompanyModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { WireTemplate } from '@treasury/domain/channel/mappings/wires';
import { WiresService } from '@treasury/domain/channel/services';
import { DownloadEvent } from '@treasury/domain/channel/services/download/';
import { WireConfigurationDto } from '@treasury/domain/channel/types';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-tooltip';
import '@treasury/omega/layouts/omega-report';
import { AlertMixin } from '@treasury/omega/mixins/alert.mixin';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import {
    LocalFilterEvent,
    OmegaReportDownloadFormat,
    OmegaReportFilter,
    OmegaReportLink,
} from '@treasury/omega/types';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createColumns, createFields, createFilters } from './data';
import { WireTemplateSearchParams, mapWireTemplateSearchParams } from './models';
import { renderBlockingLoader, renderWiresInfo } from './templates';

type WireTemplateFilterEvent = LocalFilterEvent<WireTemplateSearchParams>;
type WireTemplateDownloadEvent = DownloadEvent<WireTemplateSearchParams>;

export const CreateFromTemplateTagName = 'create-from-template-container';

const mixin = AlertMixin(ListeningElementMixin(LitElement));
@customElement(CreateFromTemplateTagName)
export class CreateFromTemplateContainer extends mixin {
    public readonly title = 'Wire Templates';

    private readonly reportInfoElement = renderWiresInfo(this.title);

    private readonly callToAction = 'Create New Template';

    @property({
        type: String,
    })
    public institution!: string;

    @InjectProperty()
    private declare readonly wiresService: WiresService;

    @InjectProperty()
    private declare readonly dialogService: OmegaDialogService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    private readonly downloadOptions: OmegaReportDownloadFormat[] = ['PDF'];

    private readonly toolbarOptions = ['download', 'print'];

    @state()
    private filters: OmegaReportFilter<WireTemplate>[] = [];

    @state()
    columns: any = [];

    private localFilters: WireTemplateFilterEvent['detail'] = [];

    @state()
    private isLoading = false;

    @state()
    private defaultPerPage = 25;

    @state()
    private itemLabel = {
        singular: 'template',
    };

    public actions = {
        approve: (record: Record<WireTemplate>) => this.showApproveDialog(record),
        reject: (record: Record<WireTemplate>) => this.rejectTemplate(record),
        initiate: () => this.initiateTemplate(),
        view: (record: Record<WireTemplate>) => this.templateDetails(record, 'View'),
        edit: (record: Record<WireTemplate>) => this.templateDetails(record, 'Edit'),
        delete: (record: Record<WireTemplate>) => this.showDeleteDialog(record),
    };

    private reportActions = [
        {
            type: 'primary',
            label: 'Initiate Selected Templates',
            action: () => {
                this.initiatePayments();
            },
            isDisabled: () => this.recordset.noRecordsMatch('isChecked', true),
        },
    ];

    @state({
        hasChanged: (newValue: Recordset<WireTemplate>, oldValue: Recordset<WireTemplate>) => {
            if (!oldValue) {
                return true;
            }

            return newValue.allRecords !== oldValue.allRecords;
        },
    })
    private readonly recordset = new Recordset<WireTemplate, WireTemplateSearchParams>(
        this.createFields(),
        args => this.fetchTemplates(args)
    );

    private subs = [this.recordset.onChange(() => this.requestUpdate())];

    public initiateTemplate() {
        this.navService.navigate('payables.wire.create-wire');
    }

    public templateDetails(record: Record<any>, type: string) {
        this.navService.navigate('payables.wire.wire-template-details-view', {
            id: record.values.dto.id,
            type,
        });
    }

    public async showApproveDialog(record: Record<WireTemplate>) {
        const { reason } = await this.dialogService.open(
            'Are you sure you want to approve this Template?',
            'Approve this Template',
            {
                buttons: {
                    [OmegaDialogExitReason.Confirm]: {
                        label: 'Approve',
                    },
                },
            }
        ).closed;
        return this.handleApproveDialogClose(reason, record);
    }

    private handleApproveDialogClose(reason: OmegaDialogExitReason, record: Record<WireTemplate>) {
        if (reason === OmegaDialogExitReason.Confirm) {
            this.approveTemplate(record);
            return true;
        }
        return false;
    }

    /**
     *
     * @returns A promise containing `true` if the transition should occur, otherwise one containing `false`.
     */
    private async showDeleteDialog(record: Record<WireTemplate>) {
        const prompt = 'Are you sure you want to delete this Template?';
        const { reason } = await this.dialogService.open(prompt, 'Delete Template', {
            buttons: {
                [OmegaDialogExitReason.Confirm]: {
                    label: 'Delete',
                },
                [OmegaDialogExitReason.Cancel]: {
                    label: 'Cancel',
                },
            },
        }).closed;
        return this.handleDeleteDialogClose(reason, record);
    }

    /**
     *
     * @param reason The dialog exist reason.
     * @returns `true` if the transition should occur.
     */
    private handleDeleteDialogClose(reason: OmegaDialogExitReason, record: Record<WireTemplate>) {
        if (reason === OmegaDialogExitReason.Confirm) {
            this.removeTemplate(record);
            // this.initContainerData(new InternalTransferHeader(this.transferHeaderRecord.values));
            return true;
        }

        return false;
    }

    public initiatePayments() {
        const checkedTemplates = this.recordset.recordsMatching('isChecked', true);
        if (checkedTemplates.length === 1) {
            this.navService.navigate('payables.wire.create-wire');
        } else if (checkedTemplates.length > 1) {
            this.navService.navigate('payables.wire.create-multiple-wire');
        }
    }

    /**
     * This is a getter since `institution` is an element property
     * and will be `undefined` at instantiation.
     */
    private get reportLinks() {
        const links: OmegaReportLink[] = [
            {
                title: 'Beneficiaries',
                url: `/${this.institution}/payables/wire/beneficiary-list`,
            },
        ];

        return links;
    }

    private createFields() {
        return createFields(this.wiresService);
    }

    public async firstUpdated() {
        if (!this.institution) {
            throw new Error(
                `The institution attribute of <${CreateFromTemplateTagName}> is required.`
            );
        }

        try {
            const wireCompanies = (await this.wiresService.getAllWireCompanies(
                true
            )) as WireCompanyModelDto[];
            const debitAccounts = await this.wiresService.fetchTransferAccounts();
            const wireConfigurations =
                (await this.wiresService.fetchWireConfiguration()) as WireConfigurationDto;
            this.filters = createFilters(wireCompanies, debitAccounts);
            this.columns = createColumns(wireConfigurations);
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode, timestamp } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code: errorCode,
                    time: timestamp,
                };
            } else {
                const message = 'An unknown error occurred.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }

        this.recordset.setInitialPageSize(this.defaultPerPage);
    }

    public disconnectedCallback() {
        // eslint-disable-next-line wc/guard-super-call
        super.disconnectedCallback();
        this.subs.forEach(sub => sub.unsubscribe());
    }

    private goToCreateNewTemplate() {
        this.navService.navigate('payables.wire.create-wire-template');
    }

    private async fetchTemplates(
        fetchArgs: RecordsetFetchArgs<WireTemplate, WireTemplateSearchParams>
    ) {
        const { parameters } = fetchArgs;
        let templates: WireTemplate[] = [];
        let templatesLength = 0;

        try {
            const searchDto = mapWireTemplateSearchParams(parameters);
            templates = await this.wiresService.searchTemplates(searchDto);
            templatesLength = templates.length;
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code: code.toString(),
                    time,
                };
            } else {
                const message = 'An unknown error occurred.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }
        return {
            data: templates,
            totalCount: templatesLength,
        };
    }

    private async handleDownload({ detail }: WireTemplateDownloadEvent) {
        const { type, filter } = detail;
        const searchParams = filter;
        const searchDto = mapWireTemplateSearchParams(searchParams);

        try {
            await this.wiresService.downloadWiresReport(type, searchDto);
        } catch (e) {
            console.error(e);
        }
    }

    private onFilter(event: WireTemplateFilterEvent) {
        const { detail } = event;
        this.localFilters = detail;
    }

    public async approveTemplate(template: Record<any>) {
        try {
            const response = await this.wiresService.approveTemplate(template.values.dto.id);
            if (response) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: 'Approve',
                    type: 'success',
                    message: 'Wire Template successfully approved!',
                };
                this.recordset.deleteRecord(template);
            } else {
                this.alert = {
                    ...this.alert,
                    message: 'Unexpected error',
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: '',
                    type: 'error',
                };
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message: html`${message} <br />#${code}`,
                    visible: true,
                    posture: 'polite',
                    title: '',
                    actions: '',
                    type: 'error',
                };
            } else {
                const message = 'Template approval failed. Please try again.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        }
    }

    public async rejectTemplate(template: Record<any>) {
        try {
            const response = await this.wiresService.rejectTemplate(template.values.dto.id);
            if (response) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: 'Delete',
                    type: 'success',
                    message: 'Wire Template successfully rejected!',
                };
                this.recordset.deleteRecord(template);
            } else {
                this.alert = {
                    ...this.alert,
                    message: 'Unexpected error',
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: '',
                    type: 'error',
                };
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message: html`${message} <br />#${code}`,
                    visible: true,
                    posture: 'polite',
                    title: '',
                    actions: '',
                    type: 'error',
                };
            } else {
                const message = 'Template rejection failed. Please try again.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        }
    }

    public async removeTemplate(template: Record<any>) {
        try {
            const response = await this.wiresService.removeTemplate(template.values.dto.id);
            if (response) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: 'Delete',
                    type: 'success',
                    message: 'Wire Template successfully deleted!',
                };
                this.recordset.deleteRecord(template);
            } else {
                this.alert = {
                    ...this.alert,
                    message: 'Unexpected error',
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: '',
                    type: 'error',
                };
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message: html`${message} <br />#${code}`,
                    visible: true,
                    posture: 'polite',
                    title: '',
                    actions: '',
                    type: 'error',
                };
            } else {
                const message = 'Template deletion failed. Please try again.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        }
    }

    public render() {
        // don't render the UI until the request comes back and filters can be constructed
        if (!this.filters || this.filters.length < 1) {
            return nothing;
        }

        return html`${this.renderAlert()} ${renderBlockingLoader(this.isLoading)}

            <div class="report-container">
                <omega-report
                    flyout
                    autostart
                    .reportInformation=${this.reportInfoElement}
                    .callToAction=${this.callToAction}
                    .actions=${this.actions}
                    @callToAction=${() => this.goToCreateNewTemplate()}
                    .title=${this.title}
                    .columns=${this.columns}
                    .recordset=${this.recordset}
                    .itemLabel=${this.itemLabel}
                    .localFilters=${this.localFilters}
                    .options=${this.toolbarOptions}
                    .downloadOptions=${this.downloadOptions}
                    .downloadFunction=${this.handleDownload}
                    @reportDownload=${(event: WireTemplateDownloadEvent) =>
                        this.handleDownload(event)}
                    .reportLinks=${this.reportLinks}
                    .filters=${this.filters}
                    .reportActions=${this.reportActions}
                >
                    <div id="top-of-table" slot="above-table">
                        <omega-filter-bar
                            .recordset=${this.recordset}
                            .onFilter=${(e: CustomEvent) => this.onFilter(e)}
                            .itemLabel=${this.itemLabel}
                        ></omega-filter-bar>
                    </div>
                </omega-report>
            </div>`;
    }

    static styles = css`
        omega-filter-bar {
            margin-left: 10px;
        }

        .report-container {
            height: 100%;
            position: relative;
            background-color: #fff;
            border: 1px solid #eeededfd;
            box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
            overflow-x: auto;
        }

        .padded {
            padding: 10px;
        }

        .row {
            display: flex;
            flex-direction: row;
        }

        .row > * {
            flex: auto;
        }

        .full-width {
            width: 100%;
        }

        .right {
            text-align: right;
        }

        .text-center {
            text-align: center;
        }

        .text-right {
            text-align: right;
        }

        .dialog-body {
            padding: 20px;
        }
    `;
}
