import { svg } from 'lit';

export const helpIcon = svg`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
  <defs>
    <path id="normal-a" d="M14,3 C7.92529435,3 3,7.92706855 3,14 C3,20.0764798 7.92529435,25 14,25 C20.0747056,25 25,20.0764798 25,14 C25,7.92706855 20.0747056,3 14,3 Z M14,22.8709677 C9.09737097,22.8709677 5.12903226,18.9042702 5.12903226,14 C5.12903226,9.0990121 9.09754839,5.12903226 14,5.12903226 C18.9008105,5.12903226 22.8709677,9.09750403 22.8709677,14 C22.8709677,18.9025403 18.9042702,22.8709677 14,22.8709677 Z M18.7567903,11.5516129 C18.7567903,14.5256935 15.5445685,14.5714677 15.5445685,15.6705363 L15.5445685,15.9516129 C15.5445685,16.2455524 15.30625,16.483871 15.0123105,16.483871 L12.9876452,16.483871 C12.6937056,16.483871 12.4553871,16.2455524 12.4553871,15.9516129 L12.4553871,15.5675444 C12.4553871,13.9820806 13.6574032,13.3482944 14.565746,12.8390121 C15.3446613,12.4023387 15.8220524,12.1053387 15.8220524,11.5270403 C15.8220524,10.7620968 14.8462903,10.2543669 14.0574395,10.2543669 C13.0288952,10.2543669 12.5540766,10.74125 11.886625,11.5836371 C11.7066774,11.8107339 11.3783185,11.8529153 11.1474073,11.6778468 L9.91327823,10.7420484 C9.68675806,10.5703065 9.63601613,10.2512177 9.79600403,10.0162702 C10.8439758,8.47742339 12.1787903,7.61290323 14.2569919,7.61290323 C16.4335282,7.61290323 18.7567903,9.31187097 18.7567903,11.5516129 L18.7567903,11.5516129 Z M15.8629032,18.9677419 C15.8629032,19.9949556 15.0272137,20.8306452 14,20.8306452 C12.9727863,20.8306452 12.1370968,19.9949556 12.1370968,18.9677419 C12.1370968,17.9405282 12.9727863,17.1048387 14,17.1048387 C15.0272137,17.1048387 15.8629032,17.9405282 15.8629032,18.9677419 Z"/>
  </defs>
  <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
    <mask id="normal-b" fill="#fff">
      <use xlink:href="#normal-a"/>
    </mask>
    <use fill="#696969" fill-rule="nonzero" xlink:href="#normal-a"/>
    <rect width="28" height="28" fill="#8B8D92" mask="url(#normal-b)"/>
  </g>
</svg>`;
