SaveAsBatchModalController.$inject = [
    '$scope',
    '$modalInstance',
    'batch',
    'achBatchService',
    '$state',
    'securityService',
];

export default function SaveAsBatchModalController(
    $scope,
    $modalInstance,
    batch,
    achBatchService,
    $state,
    securityService
) {
    $scope.batch = batch;
    $scope.copyBatch = angular.copy($scope.batch);
    $scope.save = save;
    $scope.cancel = cancel;

    async function save() {
        if ($scope.copyBatch.resetAmounts) {
            angular.forEach($scope.copyBatch.recipients, recipient => {
                recipient.amount = 0;
            });
        }

        $scope.copyBatch.id = 0;

        await securityService.verifyUser('Create ACH Batch', $scope.copyBatch, () =>
            achBatchService.create($scope.copyBatch)
        );

        $modalInstance.close();
        $state.go('payables.ach.batch-list');
    }

    function cancel() {
        $modalInstance.dismiss();
    }
}
