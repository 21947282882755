import userHasEntitlement from '@treasury/domain/shared/utilities/user-has-entitlement.js';
import {
    amountRange,
    boolean,
    date,
    dateRange,
    money,
    number,
    string,
} from '@treasury/policy/primitives';
import { html } from 'lit';
import '../../../components/channel-status-badge.js';

const canApproveOrReject = record =>
    record.getField('status') === 'Pending Approval' &&
    record.values.permissions.find(
        permission => permission.permission === 'Approve' || permission.permission === 'Reject'
    );

export const columns = entitlements => [
    {
        type: 'checkbox',
        field: 'selected',
    },
    {
        field: 'name',
        label: 'Template Name',
        type: 'command',
        action: 'clickTemplateName',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company Name',
    },
    {
        field: 'paymentType',
        label: 'Payment Type',
    },
    {
        field: 'lastUpdated',
        label: 'Last Updated',
    },
    {
        field: 'creditAmount',
        label: 'Amount',
        summary: true,
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions: [
            {
                label: 'Delete',
                action: 'delete',
                visibleWhen: record =>
                    record.getField('status') !== 'Pending Approval' &&
                    userHasEntitlement(entitlements, 'Delete Ach Payment'),
            },
            {
                label: 'Approve/Reject',
                action: 'approveOrReject',
                visibleWhen: record => canApproveOrReject(record),
            },
            {
                label: 'Clone',
                action: 'clone',
                visibleWhen: () => userHasEntitlement(entitlements, 'Create Ach Template'),
            },
            {
                label: 'Initiate',
                action: 'initiate',
                visibleWhen: record =>
                    record.getField('status') === 'Ready' &&
                    userHasEntitlement(entitlements, 'Create Ach Template'),
            },
        ],
    },
];

export const fields = client => ({
    selected: boolean.thatIs.disabledWhen(record => record.getField('status') !== 'Ready'),
    name: string.thatIs.readOnly(),
    achCompanyName: string.thatIs.readOnly(),
    lastUpdated: date.thatIs.readOnly(),
    creditAmount: money.with.reducer('sum').thatIs.readOnly(),
    status: string.with
        .template((status, record) => {
            const component = html`<channel-status-badge
                .fetchApproversFunction=${async () =>
                    client.fetchApprovers({
                        approvalEntity: 'achBatch',
                        createdBy: record.getField('createdBy'),
                        productId: record.getField('id'),
                        updatedBy: record.getField('updatedBy'),
                    })}
                .status=${status}
                .approvalsNeeded=${record.getField('numberOfApprovalsNeeded')}
                .approvalsCount=${record.getField('completedApprovalCount')}
            ></channel-status-badge>`;
            component.toString = () => status;
            return component;
        })
        .thatIs.readOnly(),

    achCompany: string.with.template(value => value.companyName).thatIs.readOnly(),
    achPaymentTypeId: number,
    id: number,
    updatedBy: string,
    updatedDate: date.thatIs.readOnly(),
    paymentType: string.with
        .template((value, record) => {
            // ids that come back here don't match the search ids used in the filter... smh
            switch (record.getField('achPaymentTypeId')) {
                case 3:
                    return 'Federal';
                case 2:
                    return 'State';
                default:
                    return 'Both';
            }
        })
        .thatIs.readOnly(),
});

export const filters = client => [
    {
        field: 'taxTemplateType',
        fieldType: string.with
            .options({
                data: [
                    { text: 'Federal', value: 1 },
                    { text: 'State', value: 2 },
                    { text: 'Both', value: 3 },
                ],
            })
            .with.inline()
            .as.tag('omega-radio-group'),
        value: 3,
    },
    {
        field: 'status',
        fieldType: string.with
            .options({
                data: [
                    { text: 'All', value: 'All' },
                    { text: 'Pending Approval', value: 'Pending Approval' },
                    { text: 'Approval Rejected', value: 'Approval Rejected' },
                    { text: 'Ready', value: 'Ready' },
                ],
            })
            .with.label('Status')
            .as.tag('omega-select'),
        value: 'All',
    },
    {
        field: 'achCompanyList',
        fieldType: string.with
            .label('ACH Company Name')
            .and.options({
                fetch: () => client.fetchAchCompanies(),
                text: record => record.companyName,
                value: record => record,
            })
            .thatHas.placeholder('Select ACH Companies')
            .with.multipleValues()
            .with.hashFunction(item => item.id)
            .as.tag('omega-select'),
        value: [],
    },
    {
        field: 'batchName',
        fieldType: string.with.label('Template Name').as.tag('omega-input'),
    },
    {
        field: 'creditAmount',
        fieldType: amountRange.with.label('Amount').as.tag('omega-range'),
        value: ['specific', 0, 0],
    },
    {
        field: 'updatedDate',
        fieldType: dateRange.with
            .label('Last Updated')
            .and.defaultValue('')
            .as.tag('omega-datepicker'),
    },
];
