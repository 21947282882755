import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record, Recordset, RecordsetEvent } from '@treasury/FDL';
import { NavigationService } from '@treasury/core/navigation';
import { mapBatchDownloadRequest } from '@treasury/domain/channel/services/ach';
import { BatchData } from '@treasury/domain/channel/services/ach/batch-mappers';
import {
    AchBatchSummary,
    AchConfiguration,
    BatchDetails,
    BatchWorkflowSteps,
    Config,
    getDefaultValueDate,
} from '@treasury/domain/channel/types/ach';
import {
    Workflow,
    WorkflowActions,
    WorkflowTypes,
} from '@treasury/domain/channel/types/ach/constants';
import { FiDate } from '@treasury/domain/dates';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-checkbox';
import { DownloadBarOptions, Schema } from '@treasury/omega/layouts/omega-form';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { LitElement, css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { AchDomesticClient } from '../clients/ach-domestic-client';
import { getBatchRecordset, getDateDisableFunctions } from '../data/batch-recordset';
import { getBatchSummaryRecord } from '../data/batch-summary-record';
import { ContainerConfiguration } from '../data/container-configuration';
import { getSchema } from '../data/factories/multi-payment-batch/batchWorkflow';
import '../dialogs/batch-detail-modal';
import '../dialogs/batch-selected-recipients-modal';

export class AchDomesticBatch extends ListeningElementMixin(LitElement) {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @state()
    private _step: BatchWorkflowSteps = 1;

    @state()
    workflow: Workflow = {
        type: WorkflowTypes.Payment,
        action: WorkflowActions.InitiateBatchFromFile,
    };

    @state()
    batchSummary!: AchBatchSummary;

    @state()
    batchSummaryRecord!: Record<AchBatchSummary>;

    @state()
    batchDetails!: BatchDetails;

    @state()
    batchRecordset!: Recordset<BatchDetails>;

    @state()
    achConfiguration!: AchConfiguration;

    @state()
    schema!: Schema<AchBatchSummary, BatchDetails>;

    @state()
    paymentIdForDetailView: number | null = null;

    @state()
    paymentNameForDetailView: string | null = null;

    @state()
    showDetailViewModal = false;

    @state()
    showSelectedRecipientsModal = false;

    @state()
    private _oneEffectiveDate = false;

    @state()
    private _selectedCount = 0;

    @state()
    errors = false;

    @state()
    selectedPaymentValueDate = '';

    @property()
    paymentBatch!: BatchData;

    @property()
    print!: () => void;

    @property()
    private config!: Config;

    @property()
    containerConfiguration!: ContainerConfiguration;

    @property()
    client!: AchDomesticClient;

    @property({ type: Function })
    setAlert!: (alert: Partial<OmegaAlertConfig>) => void;

    @property()
    alert: OmegaAlertConfig = {
        visible: false,
        message: '',
        code: '',
        time: '',
        title: '',
        type: 'success',
        posture: 'polite',
        actions: '',
    };

    @property({ type: Boolean, reflect: true })
    loading!: boolean;

    @state()
    submitResult: any;

    get step() {
        return this._step;
    }

    set step(newStep: BatchWorkflowSteps) {
        if (newStep > 0) {
            this.batchSummaryRecord.setField('step', newStep);
            this.batchRecordset.allRecords.forEach(record => record.setField('step', newStep));
            this._step = newStep;
        }
    }

    get selectedCount() {
        return this._selectedCount;
    }

    set selectedCount(val: number) {
        this._selectedCount = val;
    }

    get oneEffectiveDate() {
        return this._oneEffectiveDate;
    }

    set oneEffectiveDate(newVal: boolean) {
        if (newVal !== this._oneEffectiveDate) {
            this.batchSummaryRecord.setField('oneEffectiveDate', newVal);
            this.batchRecordset.allRecords.forEach(record => {
                record.setField('oneEffectiveDate', newVal);
            });
            this._oneEffectiveDate = newVal;
        }
    }

    get steps() {
        return this.containerConfiguration.workflow.steps;
    }

    get formTitle() {
        return this.batchSummaryRecord?.getField('name') ?? '';
    }

    firstUpdated() {
        this.init();
        this.initListeners();
    }

    updated() {
        // validating that the steps all match
        if (this.batchSummaryRecord.getField('step') !== this.step) {
            console.error('something caused a mismatch in the batch summary record step');
            this.batchSummaryRecord.setField('step', this.step);
        }
        this.batchRecordset.allRecords.forEach(record => {
            if (record.getField('step') !== this.step) {
                console.error('something caused a mismatch in the batch recordset step');
                record.setField('step', this.step);
            }
        });

        this.selectedCount = this.batchRecordset.recordsMatching('selected', true).length;
    }

    async init() {
        this.initRecords();
        this.achConfiguration = await this.client.achConfiguration();
    }

    initListeners() {
        this.listenTo(
            this.batchRecordset,
            RecordsetEvent.Updated,
            ({ detail }: { detail: { field: string; record: Record<BatchDetails> } }) => {
                const { field, record } = detail;
                if (field === 'selected') {
                    if (record) {
                        record.setField('unselected', !record.getField('selected'));
                    } else {
                        this.batchRecordset.allRecords.forEach(record => {
                            const selected = record.getField('selected');
                            const unselected = record.getField('unselected');
                            if (selected === unselected) record.setField('unselected', !selected);
                        });
                    }
                    this.selectedCount = this.batchRecordset.recordsMatching(
                        'selected',
                        true
                    ).length;
                    this.batchSummaryRecord.setField('selectedCount', this.selectedCount);
                }
                const errorSummary = record.getField('errorSummary');
                if (errorSummary?.summaryMessageList.length) {
                    if (!this.errors) this.errors = true;
                    if (record.getField('selected')) {
                        record.setField('selected', false);
                    }
                }
                if (field === 'effectiveDate') {
                    const frequency = record.getField('effectiveDate');
                    if (
                        typeof frequency !== 'string' &&
                        frequency.dates &&
                        frequency.dates.length
                    ) {
                        record.setField(
                            'effectiveDate',
                            new FiDate(new Date(frequency.dates[0])).toString()
                        );
                    }
                }
            }
        );
    }

    initRecords() {
        const { batchSummary, batchDetails } = this.paymentBatch;
        this.batchSummaryRecord = getBatchSummaryRecord(this.config, batchSummary);
        this.batchRecordset = getBatchRecordset(this.config, batchDetails, this.client);
        this.schema = getSchema(this, this.batchSummaryRecord, this.batchRecordset, this.config);

        this.step = 1;
    }

    navigateToNachaUpload() {
        return this.navService.navigate('payables.ach.payments.file-dark');
    }

    navigateToAchList() {
        return this.navService.navigate('payables.ach.batch-list');
    }

    showReviewSelected() {
        this.showSelectedRecipientsModal = true;
    }

    printErrors() {
        let errors = '<html><body><h3>Nacha File Validation Errors</h3><hr><table>';
        this.batchRecordset.allRecords.forEach(record => {
            const batch = record.values;
            if (batch.errorSummary) {
                const errorList = batch.errorSummary.summaryMessageList;
                errors += `<tr><td><strong>Batch Name : </strong>${batch.name}</td></tr><tr><tr><td><ul>`;
                errorList.forEach(error => {
                    if (error.length > 0) errors += `<li>${error}</li>`;
                });
            }
            errors += '</ul></td></tr>';
        });

        errors += '</table></body></html>';

        const printWin = window.open('', '', 'width=1024,height=768');
        if (printWin) {
            printWin.document.write(errors);
            printWin.document.close();
            printWin.focus();
            printWin.print();
            printWin.close();
        }
    }

    setLoading(newStatus: boolean) {
        this.dispatchEvent(
            new CustomEvent('loading', {
                detail: { loading: newStatus },
            })
        );
    }

    async submit() {
        this.setLoading(true);

        try {
            this.submitResult = await this.client.createBatch(
                this.batchSummaryRecord.values,
                this.batchRecordset.getData()
            );

            this.setAlert({
                title: 'Success',
                message: `ACH payment submitted successfully`,
                visible: true,
                posture: 'polite',
                type: 'success',
            });
        } catch (e) {
            this.setAlert({
                title: 'Error',
                message:
                    e instanceof Error
                        ? e.message
                        : 'The ACH payment could not be submitted at this time.',
                type: 'error',
                visible: true,
                posture: 'polite',
            });
        } finally {
            this.setLoading(false);
        }

        this.next();
    }

    async download() {
        this.setLoading(true);

        try {
            await this.client.download(
                undefined,
                'PDF',
                'AchPaymentUploadConfirmation',
                mapBatchDownloadRequest(
                    this.batchSummaryRecord.values,
                    this.batchRecordset.getData()
                ),
                ['PDF']
            );
        } catch (err) {
            const message =
                err instanceof Error
                    ? err.message
                    : 'A problem occurred when downloading. Please try again.';
            this.alert = { ...this.alert, visible: true, type: 'error', message };
        } finally {
            this.setLoading(false);
        }
    }

    manageRecordsetDisplaySelected() {
        if (this.step >= 2) {
            this.batchRecordset.filter = (record: Record<BatchDetails>) =>
                record.getField('selected');

            this.batchRecordset.requestUpdate();
        } else {
            this.batchRecordset.filter = () => true;
        }
        this.batchRecordset.requestUpdate();
    }

    setRecordSummaryData() {
        let totalBalancedBatches = 0;
        let totalUnbalancedBatches = 0;
        let totalBalancedCreditAmount = 0;
        let totalBalancedDebitAmount = 0;
        let totalUnbalancedCreditAmount = 0;
        let totalUnbalancedDebitAmount = 0;

        this.batchRecordset.allRecords.forEach(record => {
            if (record.getField('selected')) {
                const offsetAccount = record.getField('offsetAccount');
                const creditAmt = record.getField('creditAmount');
                const debitAmt = record.getField('debitAmount');
                const balanced = creditAmt === debitAmt;
                if (balanced) {
                    totalBalancedBatches += 1;
                    totalBalancedCreditAmount += creditAmt;
                    totalBalancedDebitAmount += debitAmt;
                } else {
                    totalUnbalancedBatches += 1;
                    totalUnbalancedCreditAmount += creditAmt;
                    totalUnbalancedDebitAmount += debitAmt;
                }
            }
        });
        this.batchSummaryRecord.setField('totalBalancedBatches', totalBalancedBatches);
        this.batchSummaryRecord.setField('totalBalancedCreditAmount', totalBalancedCreditAmount);
        this.batchSummaryRecord.setField('totalBalancedDebitAmount', totalBalancedDebitAmount);
        this.batchSummaryRecord.setField('totalUnbalancedBatches', totalUnbalancedBatches);
        this.batchSummaryRecord.setField(
            'totalUnbalancedCreditAmount',
            totalUnbalancedCreditAmount
        );
        this.batchSummaryRecord.setField('totalUnbalancedDebitAmount', totalUnbalancedDebitAmount);
    }

    next() {
        if (this.step === 1) this.setRecordSummaryData();
        if (this.step < 3) {
            this.step += 1;
            this.manageRecordsetDisplaySelected();
            return;
        }
        console.error('cannot advance to next step');
    }

    back() {
        if (this.step === 1) {
            this.setAlert({
                visible: true,
                type: 'error',
                posture: 'assertive',
                title: 'Cancel create ACH payment from file?',
                message: 'Are you sure you want to go back? File data will be discarded.',
                actions: html`<omega-button
                    type="primary"
                    @click=${this.navigateToAchList}
                ></omega-button>`,
            });
        }
        if (this.step > 1) {
            this.step -= 1;
            this.manageRecordsetDisplaySelected();
            return;
        }
        console.error('cannot go back to previous step');
    }

    showBatchDetailDialog(record: Record<BatchDetails>) {
        this.selectedPaymentValueDate = record.getField('effectiveDate').toString();
        this.showDetailViewModal = true;
        this.paymentNameForDetailView = record.getField('name');
        this.paymentIdForDetailView = record.getField('achPaymentDraftId');
    }

    toggleEffectiveDateControl(event: {
        detail: { value: string; checked: boolean; indeterminate: boolean };
    }) {
        this.batchSummaryRecord.setField('oneEffectiveDate', event.detail.checked);
        this.oneEffectiveDate = event.detail.checked;
    }

    setEffectiveDate(event: { detail: { value: { dates: Array<Date | string> } } }) {
        const date = new FiDate(new Date(event.detail.value.dates[0])).toString();
        this.batchSummaryRecord.setField('effectiveDate', date);
        if (this.oneEffectiveDate) {
            this.batchRecordset.allRecords.forEach(record =>
                record.setField('effectiveDate', date)
            );
        }
    }

    renderTableHeader() {
        const batchCount = this.batchSummaryRecord.getField('batchCount');

        return html`<div class="custom-table-header">
            <h3 class="custom-table-header-title">Batches</h3>
            <small class="custom-table-header-label">
                ${this.selectedCount} of ${batchCount} Batches Selected
            </small>
        </div>`;
    }

    renderTableControls() {
        if ((this.step > 1 && this.step !== 2) || this.step === 3) return nothing;

        const handleCheckboxToggle = this.toggleEffectiveDateControl.bind(this);
        const handleDateChange = this.setEffectiveDate.bind(this);

        const iconAndLabelNoDatepicker = html`<omega-icon
                class="effective-date-icon"
                icon="calendar"
            ></omega-icon>
            <div class="effective-date-icon-label">
                Please validate the Effective Date for accuracy
            </div>`;

        const iconAndLabel = this.oneEffectiveDate
            ? nothing
            : html`<div class="effective-date-toggler">${iconAndLabelNoDatepicker}</div>`;

        if (this.achConfiguration) {
            const { sameDayAchSettings, allowSameDayPayments, cutoffTimes, holidays } =
                this.achConfiguration;

            const defaultDate = getDefaultValueDate(
                allowSameDayPayments,
                cutoffTimes,
                holidays,
                sameDayAchSettings
            );

            const datepicker = this.oneEffectiveDate
                ? html`<div class="effective-date-picker">
                      <omega-datepicker
                          .value=${defaultDate}
                          @change=${handleDateChange}
                          .dateDisabledFunction=${getDateDisableFunctions(this.config)
                              .dateDisabledFunction}
                      ></omega-datepicker>
                  </div> `
                : nothing;

            return html`<div slot="table-controls">
                ${this.renderTableHeader()}
                <div class="table-controls">
                    ${this.step === 2
                        ? html`${iconAndLabelNoDatepicker}`
                        : html`
                              ${iconAndLabel}
                              <div class="effective-date-control">
                                  <omega-checkbox
                                      .checked=${this.batchSummaryRecord.getField(
                                          'oneEffectiveDate'
                                      )}
                                      @toggle=${handleCheckboxToggle}
                                  ></omega-checkbox>
                                  <div class="effective-date-checkbox-label">
                                      One Effective Date for all Batches
                                  </div>
                                  ${datepicker}
                              </div>
                          `}
                </div>
            </div>`;
        }
        return nothing;
    }

    renderDetailModal() {
        return html`
            <batch-detail-modal
                .paymentId=${this.paymentIdForDetailView}
                .batchName=${this.paymentNameForDetailView}
                .selectedPaymentValueDate=${this.selectedPaymentValueDate}
                .visible=${this.showDetailViewModal}
                .config=${this.config}
                .setParentVisibility=${(value: boolean) => {
                    this.showDetailViewModal = value;
                }}
            ></batch-detail-modal>
        `;
    }

    renderSelectedRecipientsModal() {
        const selectedBatches: Array<Record<BatchDetails>> = this.showSelectedRecipientsModal
            ? this.batchRecordset.recordsMatching('selected', true)
            : [];
        return html`
            <batch-selected-recipients-modal
                .batchRecordset=${this.batchRecordset}
                .visible=${this.showSelectedRecipientsModal}
                .config=${this.config}
                .selectedBatches=${selectedBatches}
                .selectedCount=${this.selectedCount}
                @close=${() => {
                    this.showSelectedRecipientsModal = false;
                }}
            ></batch-selected-recipients-modal>
        `;
    }

    render() {
        if (!this.batchSummaryRecord || !this.schema) return nothing;

        const downloadBarOptions: DownloadBarOptions = {
            actions: this.step > 2 ? ['download'] : [],
            downloadOptions: ['PDF'],
            hideActionLabels: false,
        };

        return html`<omega-form
                .record=${this.batchSummaryRecord}
                .activeStep=${this.step}
                .schema=${this.schema}
                .steps=${this.steps}
                .formTitle=${this.formTitle}
                @print=${this.print}
                .downloadBarOptions=${downloadBarOptions}
                @download=${this.download}
            >
                ${this.renderTableControls()}
            </omega-form>
            ${this.renderDetailModal()} ${this.renderSelectedRecipientsModal()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            .table-controls {
                margin: 20px 5px;
            }

            .custom-table-header {
                width: 100%;
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
                padding: 15px 15px;
                border-bottom: 1px solid #cccccc;
                height: 52px;
                color: #333333;
                margin: 0;
                padding: 0;
            }

            .custom-table-header-title {
                margin: 0 5px 0 15px;
                padding: 0;
            }

            .effective-date-toggler {
                margin: 10px 0 5px 0;
            }

            .effective-date-icon {
                display: inline-block;
                margin: 3px 8px 0px 1.5px;
            }

            .effective-date-icon-label {
                display: inline-block;
                font-weight: bold;
            }

            .effective-date-control {
                max-width: 800px;
                display: flex;
                align-items: center;
                margin: 0 0 0 -5px;
            }

            .effective-date-checkbox-label {
                margin: 0 15px 0 -16px;
            }

            .effective-date-picker {
                width: 250px;
            }
        `;
    }
}

window.customElements.define('ach-domestic-batch', AchDomesticBatch);
