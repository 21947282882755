import FieldType from '@treasury/FDL/field-type.js';
import nachaAlphanumeric from '../lib/validators/nacha/nacha-alphanumeric.validator.js';
import { nachaBankName, nachaRecipientName, nachaBankIdType } from '../nacha.js';
import { string, yesNoBoolean, money, frequency } from '../primitives';
// import { internationalBankNumber } from '../../bank.js';

export const internationalAchPaymentRecipient = nachaRecipientName;

export const internationalAchPaymentRecipientStreetAddress = string.with
    .maxLength(35)
    .with.validator(nachaAlphanumeric);

export const internationalRecipientCity = new FieldType().with
    .maxLength(17)
    .with.validator(nachaAlphanumeric);

export const internationalAchPaymentRecipientState = string.with
    .maxLength(16)
    .and.validator(nachaAlphanumeric);

export const internationalRecipientZipCode = string.with
    .maxLength(31)
    .with.validator(nachaAlphanumeric);

export const internationalRecipientCountry = new FieldType().with
    .maxLength(2)
    .with.validator(nachaAlphanumeric);

export const internationalAchPaymentRecipientIdNumber = string.with
    .validator({
        name: 'length',
        validate: value => value?.length <= 15,
    })
    .with.validator(nachaAlphanumeric)
    .and.maxLength(15);

export const internationalRecipientCountryCode = new FieldType().with
    .validator({
        name: 'length',
        validate: value => value?.length <= 2,
    })
    .with.validator(nachaAlphanumeric)
    .and.defaultValue('');

export const internationalRecipientAccountNumber = new FieldType().with
    .maxLength(35)
    .with.validator(nachaAlphanumeric)
    .and.defaultValue('');

export const internationalRecipientAccountType = new FieldType().with
    .validator(nachaAlphanumeric)
    .and.defaultValue('');

export const internationalAchPaymentFrequency = frequency;

export const internationalTransactionType = new FieldType().with.defaultValue('');

export const internationalAchRecipientAddenda = new FieldType().with
    .multipleValues(1, 2)
    .as.tag('omega-additional-info');

export const internationalAchPaymentRecipientPaymentType = new FieldType();

export const internationalAchPaymentRecipientAmount = money.with.validator({
    name: 'length',
    validate: value => {
        if (!value) return true;
        return value?.toString().length <= 10;
    },
});

export const bankIdTypeOptions = [
    { id: '01', text: '01 National Clearing', value: '01 National Clearing' },
    { id: '02', text: '02 BIC Code', value: '02 BIC Code' },
    { id: '03', text: '03 IBAN', value: '03 IBAN' },
];

export const internationalAchPaymentRecipientPrenote = yesNoBoolean.and
    .minColumnWidth(100)
    .and.targetColumnWidth(100)
    .and.maxColumnWidth(100);

export const internationalAchPaymentRecipientHold = yesNoBoolean.and
    .minColumnWidth(100)
    .and.targetColumnWidth(100)
    .and.maxColumnWidth(100);

export const internationalAchPaymentRecipientBankName = nachaBankName.thatHas
    .validator(nachaAlphanumeric)
    .with.maxLength(35);

export const internationalAchPaymentRecipientBankIdType =
    nachaBankIdType.with.options(bankIdTypeOptions);

export const internationalAchPaymentRecipientBankNumber = string.thatHas
    .validator(nachaAlphanumeric)
    .with.maxLength(34);
