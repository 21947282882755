/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable lit-a11y/anchor-is-valid */
import '@treasury/omega/components/omega-checkbox';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/omega-tooltip';

import { css, html, nothing, PropertyValueMap, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { IIssuedItem, IssuedItemPendingStatus } from '@treasury/domain/channel/mappings/arp';
import { FdlFieldDefinitions, Record as FdlRecord, Recordset } from '@treasury/FDL';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';
import { localeDateString } from '@treasury/omega/formatters';
import { OmegaCheckboxToggleEvent } from '@treasury/omega/types';
import { boolean } from '@treasury/policy/primitives';
import { Action, exists, LitPropertyVerifier } from '@treasury/utils';
import { reviewStepColumns, reviewStepFields } from '../data';
import { VoidMatchesDatastore } from '../services';
import { IssuedItemMatchVm } from '../view-models';

/**
 * View model shape for selectable items.
 *
 * The interface exists only to satisfy the type constraints of
 * FDL so that Omega table can display a template for a property
 * that doesn't exist on the underlying data.
 */
interface SelectableItem extends IIssuedItem {
    /**
     * Bogus property that is not actually used at runtime.
     * Exists strictly to satisfy FDL typing for use with `template()`
     * rather than a checkbox column.
     */
    selected: boolean;
}

const columns: OmegaColumnDefinition<SelectableItem>[] = [
    {
        field: 'selected',
        label: '',
    },
    ...(reviewStepColumns.filter(
        col => col.field !== 'pendingStatus'
    ) as OmegaColumnDefinition<SelectableItem>[]),
];
interface VoidsSelectionPayload {
    original: IIssuedItem;
    voids: IIssuedItem[];
}

export type IssuedItemsVoidsSelectionEvent = CustomEvent<VoidsSelectionPayload>;

export enum VoidSelectionState {
    Unknown = -1,
    SelectVoidsPrompt,
    VoidSelect,
    TooManyMatches,
    /**
     * The current item has been previously cleared or voided.
     */
    VoidIneligible,
    /**
     * All matches are either cleared or voided.
     */
    FinalizedMatches,
    SelectionDone = Number.MAX_SAFE_INTEGER,
}

@customElement('issued-items-void-matches')
class IssuedItemsVoidMatches extends LitPropertyVerifier {
    /**
     * The issued-item-to-void.
     */
    @property({
        type: Object,
    })
    public readonly original!: IIssuedItem;

    /**
     * A list of potential check matches that could be voided based on the original void criteria.
     */
    @property({
        type: Array,
    })
    public readonly voidMatches: IIssuedItem[] = [];

    private matchViewModels: IssuedItemMatchVm[] = [];

    /**
     * Callback to invoke when this detail row collapses.
     *
     * Typically, this will be the function provided by the `<omega-table>`
     * `detailRowTemplateFunction` binding.
     */
    @property({
        type: Function,
    })
    public readonly closeRow!: Action;

    private _state = VoidSelectionState.Unknown;

    private recordset?: Recordset<SelectableItem>;

    protected readonly verifiedPropNames: (keyof this)[] = ['original', 'closeRow'];

    @state()
    private canVoid = false;

    @InjectProperty()
    private declare matchesDatastore: VoidMatchesDatastore;

    private get selected() {
        return this.matchViewModels.filter(vm => vm.selected);
    }

    @state()
    private get state() {
        return this._state;
    }

    private set state(val) {
        const oldVal = this._state;
        this._state = val;
        this.matchesDatastore.setUiState(this.original, val);

        this.requestUpdate('state', oldVal);
    }

    protected firstUpdated(changedProperties: Map<string | number | symbol, unknown>) {
        super.firstUpdated(changedProperties);
        this.state = this.determineInitialState();
    }

    protected updated(changedProperties: PropertyValueMap<any>) {
        super.updated(changedProperties);
        const stateProps: (keyof this)[] = ['original', 'voidMatches'];

        // clear recordset reference for future re-generation if matches have changed
        if (changedProperties.has('voidMatches') && exists(changedProperties.get('voidMatches'))) {
            this.recordset = undefined;
        }

        if (stateProps.some(prop => changedProperties.has(prop))) {
            this.state = this.determineInitialState();
        }
    }

    private determineInitialState() {
        // tracking UI state in a service enables detail rows to persist their state
        // when their position changes as rows are added or removed;
        // mostly useful for multi-state rows like the match selection prompt
        const previousUiState = this.matchesDatastore.getUiState(this.original);
        if (previousUiState) {
            return previousUiState;
        }

        const { pendingStatus } = this.original;
        const { voidMatches } = this;

        if (pendingStatus === IssuedItemPendingStatus.TooManyMatches) {
            return VoidSelectionState.TooManyMatches;
        }

        // if there's no matches, check the original to see if it's void eligible;
        // void eligible matches should be handled by the parent component
        if (voidMatches.length === 0 && !this.original.voidEligible) {
            return VoidSelectionState.VoidIneligible;
        }

        if (pendingStatus === IssuedItemPendingStatus.HasOnlyFinalizedMatches) {
            return VoidSelectionState.FinalizedMatches;
        }

        if (voidMatches.length > 0) {
            return VoidSelectionState.SelectVoidsPrompt;
        }

        return VoidSelectionState.Unknown;
    }

    private onCheckboxToggle(e: OmegaCheckboxToggleEvent<IssuedItemMatchVm>) {
        const { value: vm, checked } = e.detail;
        vm.selected = checked;
        this.canVoid = this.selected.length > 0;
    }

    private voidSelectedItems() {
        const detail: VoidsSelectionPayload = {
            original: this.original,
            voids: this.selected.map(vm => vm.item),
        };

        const event: IssuedItemsVoidsSelectionEvent = new CustomEvent('voids-changed', {
            detail,
        });

        this.state = VoidSelectionState.SelectionDone;
        this.closeRow();
        this.dispatchEvent(event);
    }

    private cancel() {
        this.state = VoidSelectionState.SelectVoidsPrompt;
        this.canVoid = false;
        this.matchViewModels.forEach(vm => (vm.selected = false));
    }

    private makeRecordset() {
        const fields = {
            ...reviewStepFields,
            // selectable items should not display a type to better differentiate
            // from pending items in the parent view
            type: reviewStepFields.type?.with.template(() => nothing),
            selected: boolean.with
                .readOnly()
                .and.template<boolean, IIssuedItem>((selected, record) => {
                    const item = record.values;
                    const vm = this.matchesDatastore.getViewModel(item);

                    if (!vm) {
                        return nothing;
                    }

                    return renderLeadingColumn(vm, e => {
                        this.onCheckboxToggle(e);
                    });
                })
                .thatIs.sortable(false)
                .thatHas.maxColumnWidth(30)
                .thatHas.targetColumnWidth(30),
        } as FdlFieldDefinitions<SelectableItem>;
        delete fields.pendingStatus;

        const recordset = new Recordset(fields, () => this.voidMatches as SelectableItem[]);

        recordset.update();
        this.matchViewModels = this.voidMatches
            .map(match => this.matchesDatastore.getViewModel(match))
            .filter(exists);

        return recordset;
    }

    private renderContent() {
        switch (this.state) {
            case VoidSelectionState.SelectVoidsPrompt:
                return renderSelectionPrompt(() => (this.state = VoidSelectionState.VoidSelect));
            case VoidSelectionState.VoidSelect:
                if (!this.recordset) {
                    this.recordset = this.makeRecordset();
                }
                return renderOmegaTable(this.recordset);
            case VoidSelectionState.TooManyMatches:
                return renderTooManyMatchesPrompt();
            case VoidSelectionState.VoidIneligible:
                return renderFinalizedContent(this.original);
            case VoidSelectionState.FinalizedMatches: {
                return html`<p>${getFinalizedMatchesPrompt(this.voidMatches)}</p>`;
            }
            default:
                throw new Error(`Invalid void selection state "${this.state}"`);
        }
    }

    private renderSelectionButtons() {
        return html`<omega-button
                .disabled=${!this.canVoid}
                @click=${this.voidSelectedItems}
                type="primary"
                >Void Selected</omega-button
            >
            <omega-button @click=${this.cancel} type="link">Cancel</omega-button>`;
    }

    private renderButtons() {
        let buttons: TemplateResult | undefined;

        // eslint-disable-next-line sonarjs/no-small-switch, default-case
        switch (this.state) {
            case VoidSelectionState.VoidSelect:
                buttons = this.renderSelectionButtons();
                break;
        }

        if (buttons) {
            return html` <div id="button-container">
                <omega-button-bar position="bottom" alignment="left"> ${buttons} </omega-button-bar>
            </div>`;
        }

        return nothing;
    }

    protected render() {
        if (
            this.state === VoidSelectionState.Unknown ||
            this.state === VoidSelectionState.SelectionDone
        ) {
            return nothing;
        }

        return html`
            ${this.renderContent()}
            ${this.renderButtons()}
        </div>`;
    }

    public static styles = css`
        #button-container {
            /** Use relative positioning since the button bar is absolute */
            position: relative;
            min-height: 55px;
        }

        #button-container omega-button-bar {
            display: block;
        }

        p {
            padding: 5px 10px;
        }
    `;
}

function renderOmegaTable(recordset: Recordset<SelectableItem>) {
    return html`<omega-table
        .disabledWhen=${(record: FdlRecord<IIssuedItem>) => !record.values.voidEligible}
        .columnDefinitions=${columns}
        .recordset=${recordset}
        .displayHeader=${false}
        .displayPagination=${false}
    ></omega-table>`;
}

function renderLeadingColumn(
    vm: IssuedItemMatchVm,
    onToggle: (e: OmegaCheckboxToggleEvent<IssuedItemMatchVm>) => void
) {
    const { item, selected } = vm;
    const { voidEligible } = item;

    if (!voidEligible) {
        const message = getFinalizedMessage(item);
        return html`<omega-tooltip
            light
            icon="info-circle"
            position="right"
            .message=${html`<div style="max-width: 400px;">
                <p>${message}</p>
            </div>`}
        ></omega-tooltip>`;
    }

    return html`<omega-checkbox
        hideLabel
        .checked=${selected}
        .disabled=${!voidEligible}
        .value=${vm}
        @toggle=${onToggle}
    ></omega-checkbox>`;
}

function getFinalizedMessage(item: IIssuedItem) {
    if (item.voidEligible) {
        return undefined;
    }

    const { dateFinalized, itemStatus, pendingStatus } = item;
    if (dateFinalized) {
        if (itemStatus === 'Void') {
            return `This item cannot be voided, it was previously voided on ${localeDateString(
                dateFinalized
            )}.`;
        }

        if (itemStatus === 'Cleared') {
            return `This item cannot be voided, it cleared on ${localeDateString(dateFinalized)}.`;
        }
    }

    if (pendingStatus === IssuedItemPendingStatus.PreviouslySelected) {
        return 'This item cannot be selected, it has already been selected in the list.';
    }

    return undefined;
}

function renderSelectionPrompt(onClick: Action) {
    return html`<p>
        More than one issued item matching the check information you entered has been found. Please
        <a href="javascript: void 0;" @click=${onClick}>select the item or items to void</a>.
    </p>`;
}

function renderTooManyMatchesPrompt() {
    return html`<p>
        More than one issued item matching the check information you entered has been found. Please
        provide additional information for the item you wish to void.
    </p>`;
}

function renderFinalizedContent(item: IIssuedItem) {
    const message = getFinalizedMessage(item);

    if (!message) {
        return nothing;
    }

    return html`<p>${message}</p>`;
}

export function getFinalizedMatchesPrompt(matches: IIssuedItem[]) {
    const voided = matches.filter(item => item.itemStatus === 'Void');
    const cleared = matches.filter(item => item.itemStatus === 'Cleared');

    if (voided.length < 1 && cleared.length < 1) {
        return '';
    }

    const messageBase = 'The voided item you entered cannot be voided';

    let voidedVerbiage: string | undefined;
    if (voided.length > 0) {
        const voidNoun = voided.length > 1 ? 'items' : 'item';
        const hasOrHave = voided.length > 1 ? 'have' : 'has';
        voidedVerbiage = `${voided.length} ${voidNoun} ${hasOrHave} already been voided`;
    }

    let clearedVerbiage: string | undefined;
    if (cleared.length > 0) {
        const clearedNoun = cleared.length > 1 ? 'items' : 'item';
        const hasOrHave = cleared.length > 1 ? 'have' : 'has';
        clearedVerbiage = `${cleared.length} ${clearedNoun} ${hasOrHave} already been cleared`;
    }

    const conjunction = exists(voidedVerbiage) && exists(clearedVerbiage) ? ' and ' : '';

    return `${messageBase}, ${voidedVerbiage || ''}${conjunction}${clearedVerbiage || ''}.`;
}
