/* eslint-disable @treasury/no-date */
import { number, string, dateRange } from '@treasury/policy/primitives';
import { accountNumber } from '@treasury/policy/account.js';
import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { FieldType } from '@treasury/FDL';
import { html } from 'lit';
import dateFormatter from '@treasury/policy/lib/formatters/date.formatter.js';

const periodValues = [
    {
        LastWeek: 'Last Week',
    },
    {
        LastMonth: 'Last Month',
    },
    {
        LastYear: 'Last Year',
    },
    {
        YearToDate: 'Year to Date',
    },
    {
        MonthToDate: 'Month to Date',
    },
    {
        WeekToDate: 'Week to Date',
    },
];

const formatTimePeriod = value => {
    if (value) {
        const period = periodValues.find(periodValue => periodValue[value]);
        return period[value];
    }
    return value;
};

export const fields = client => ({
    externalReportType: new FieldType().with
        .label('Report Type')
        .thatIs.readOnly()
        .and.formatter(record => record.displayName)
        .and.compareFunction(
            (a, b) => (a.displayName > b.displayName) - (a.displayName < b.displayName)
        )
        .as.tag('omega-select'),
    reportName: string.with
        .label('Report Name')
        .thatIs.readOnly()
        .thatHas.maxLength(30)
        .as.tag('omega-input'),
    accounts: accountNumber.with
        .label('Accounts')
        .thatIs.readOnly()
        .and.formatter(value => value.map(account => account.number).join(','))
        .as.tag('omega-select'),
    activityPeriod: dateRange.with
        .label('Activity Period')
        .thatIs.readOnly()
        .thatHas.template((value, record) => {
            if (record.getField('activityPeriodStart')) {
                if (
                    record.getField('activityPeriodStart') === record.getField('activityPeriodEnd')
                ) {
                    return dateFormatter(record.getField('activityPeriodStart'));
                }
                return html`${dateFormatter(
                    record.getField('activityPeriodStart')
                )}-${dateFormatter(record.getField('activityPeriodEnd'))}`;
            }
            return formatTimePeriod(record.getField('timePeriod').displayName);
        })
        .and.sortable(false)
        .as.tag('omega-datepicker'),
});

const actions = [
    {
        label: 'Run Report',
        action: 'run',
        visibleWhen: record => {
            const start = record.getField('activityPeriodStart');
            const end = record.getField('activityPeriodEnd');
            const startDate = new Date(start);
            const endDate = new Date(end);
            const todayDate = new Date();
            return startDate <= todayDate && endDate <= todayDate;
        },
    },
    {
        label: 'Edit Report',
        action: 'edit',
    },
    {
        label: 'Delete Report',
        action: 'delete',
    },
];

export const columns = [
    {
        field: 'externalReportType',
        label: 'Report Type',
    },
    {
        field: 'reportName',
        label: 'Report Name',
    },
    {
        field: 'accounts',
        label: 'Accounts',
    },
    {
        field: 'activityPeriod',
        label: 'Activity Period',
    },
    {
        label: 'Actions',
        type: 'actions',
        actions,
    },
];

export const filters = client => [
    {
        field: 'reportType',
        fieldType: number.with
            .label('Report Type')
            .and.options({
                fetch: async () => {
                    const options = await AchAccountReconciliationService.fetchReportTypes();
                    return options.sort(
                        (a, b) => (a.displayName > b.displayName) - (a.displayName < b.displayName)
                    );
                },
                text: 'displayName',
                value: 'id',
            })
            .and.placeholder('Select Report Type')
            .and.multipleValues()
            .as.tag('omega-select'),
        value: [],
    },
    {
        field: 'accountIds',
        fieldType: string.with
            .label('Accounts')
            .and.options({
                fetch: async () => AchAccountReconciliationService.fetchAccounts(),
                text: 'number',
                value: 'id',
            })
            .and.multipleValues()
            .as.tag('omega-select'),
        value: [],
    },
];
