BulkApproveOrRejectDialogController.$inject = ['$scope', '$modalInstance', 'type'];

export default function BulkApproveOrRejectDialogController($scope, $modalInstance, type) {
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.setForm = setForm;
    $scope.type = type;

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        $modalInstance.close($scope.comments);
    }

    function setForm(form) {
        $scope.form = form;
    }
}
