import localeDateString from './date.formatter.js';
import localeTimeString from './time.formatter.js';

export default function dateTimeFormatted(value) {
    if (!value) return '';
    const dateString = localeDateString(value);
    const timeString = localeTimeString(value);

    if (dateString && timeString === 'Invalid Date') {
        return dateString;
    }

    return `${localeDateString(value)}\n${localeTimeString(value)}`;
}
