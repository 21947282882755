export interface WorkAchExceptionDto {
    account: string;
    accountDisplayLabel: string;
    accountUniqueId: string;
    allowLoanPaymentPastDue: boolean;
    availableBalance: number;
    cifNumber: number;
    collectedBalance: number;
    currentBalance: number;
    dailyLimit: number;
    id: number;
    isDeleted: boolean;
    loanPaymentAmountDue: number;
    loanPaymentDueDate: string;
    name: string;
    number: string;
    perTransactionLimit: number;
    showAccountNickName: boolean;
    status: string;
    transferAmount: number;
    transferMemo: string;
    type: string;
    updatedBy: string;
    updatedDate: string;
}

export const nullWorkAchException: WorkAchExceptionDto = {
    account: '',
    accountDisplayLabel: '',
    accountUniqueId: '',
    allowLoanPaymentPastDue: false,
    availableBalance: 0,
    cifNumber: 0,
    collectedBalance: 0,
    currentBalance: 0,
    dailyLimit: 0,
    id: 0,
    isDeleted: false,
    loanPaymentAmountDue: 0,
    loanPaymentDueDate: '',
    name: '',
    number: '',
    perTransactionLimit: 0,
    showAccountNickName: false,
    status: '',
    transferAmount: 0,
    transferMemo: '',
    type: '',
    updatedBy: '',
    updatedDate: '',
};
