/* eslint-disable import/extensions */
import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';

export default class AccountRequests {
    static async logout(reason) {
        const url = `account/logout?reason=${reason.reason}`;
        const http = await TmHttpClient.getInstance();

        return http.request(url, {
            method: 'GET',
            responseType: TmHttpResponseType.Text,
        });
    }

    static async getCurrentFiDateTime() {
        const url = `account/currentFiDateTime`;
        const http = await TmHttpClient.getInstance();

        return http.request(url, {
            method: 'GET',
        });
    }
}
