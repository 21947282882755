/* eslint-disable no-use-before-define */
import { clone, deepEquals, isIntegerString } from '../../../functions';

type ArrayKeys<T = unknown> = keyof Array<T>;

const PROXY_ORIGINAL = Symbol('PROXY_ORIGINAL');
type ObjectChangeHandler<T extends object, K extends keyof T = keyof T> = (
    target: T,
    oldTarget: T,
    propName: K,
    oldPropValue: T[K],
    newPropValue: T[K]
) => void;
type ArrayChangeHandler<T extends object> = (
    target: T[],
    oldTarget: T[],
    propNameOrIndex: ArrayKeys<T>
) => void;

/**
 *
 * @param obj An object to wrap in a `Proxy`.
 * @param onPropChanged Function to execute before a property is set.
 *
 * @returns A `Proxy` wrapping the original object that invokes `onPropChange()` on mutation.
 */
export function createObjectProxy<T extends object, K extends keyof T = keyof T>(
    obj: T,
    onPropChanged: ObjectChangeHandler<T, K>
) {
    const handler: ProxyHandler<T> = {
        set: (target, propName, newValue) => {
            const oldValue = target[propName as K];

            // don't forward identical values
            if (deepEquals(oldValue, newValue)) {
                return true;
            }

            const oldTarget = clone(target);
            const success = Reflect.set(target, propName, newValue);
            onPropChanged(target, oldTarget, propName as K, oldValue, newValue);

            return success;
        },
        get: (target, prop) => {
            // provide escape hatch for accessing original value
            if (prop === PROXY_ORIGINAL) {
                return target;
            }

            return Reflect.get(target, prop);
        },
    };

    return Proxy.revocable(obj as T, handler);
}

const arrayItemProxies = new WeakSet<typeof Proxy>();

export function createArrayProxy<T extends object>(
    arr: T[],
    onArrayChanged: ArrayChangeHandler<T>,
    onElementChanged: ObjectChangeHandler<T>
) {
    const handler: ProxyHandler<T[]> = {
        set: (target, propName, newValue) => {
            propName = propName.toString();
            const oldTarget = [...target];
            const oldValue = oldTarget[propName as ArrayKeys<T>];
            const propNameIsInteger = isIntegerString(propName);
            const isProxy = arrayItemProxies.has(newValue);

            // don't forward identical values unless changing from raw to proxy
            if (!isProxy && deepEquals(oldValue, newValue)) {
                return true;
            }

            // we're setting an array index; wrap its element in a proxy if it isn't already
            if (!isProxy && propNameIsInteger) {
                // eslint-disable-next-line no-param-reassign
                const { proxy } = createObjectProxy(newValue as T, onElementChanged);
                newValue = proxy;
                arrayItemProxies.add(proxy as unknown as typeof Proxy);
            }

            Reflect.set(target, propName, newValue);
            onArrayChanged(target, oldTarget, propName as keyof T[]);

            return true;
        },
        get: (target, propName) => {
            // provide escape hatch for accessing original value
            if (propName === PROXY_ORIGINAL) {
                return target;
            }

            return Reflect.get(target, propName);
        },
    };

    // wrap any existing array items in a proxy
    const proxyTuples = arr.map(item => createObjectProxy(item, onElementChanged));
    const { proxy, revoke } = Proxy.revocable(arr, handler);

    proxyTuples.forEach(({ proxy: elementProxy }, i) => {
        arrayItemProxies.add(elementProxy as ProxyConstructor);

        // rewrite object at each index w/ proxy
        // while maintaining original reference
        proxy[i] = elementProxy;
    });

    return {
        proxy,
        revoke: () => {
            proxyTuples.forEach(r => r.revoke());
            revoke();
        },
    };
}

/**
 * Converts an object on a `LitElement` that has been decorated
 * with `@DeepReactive` back to its naked reference.
 */
export function unwrapReactive<T>(obj: T) {
    const unwrapped = Reflect.get(obj as object, PROXY_ORIGINAL) as T | undefined;

    if (!unwrapped) {
        return obj;
    }

    return unwrapped;
}
