import { css, html, LitElement } from 'lit';

class OmegaCheckbox extends LitElement {
    static get properties() {
        return {
            label: String,
            hideLabel: {
                type: Boolean,
                reflect: true,
            },
            value: Object,
            text: String,
            checked: {
                type: Boolean,
                reflect: true,
            },
            indeterminate: {
                type: Boolean,
                reflect: true,
            },
            triStateInput: {
                type: Boolean,
                reflect: true,
            },
            disabled: {
                type: Boolean,
                reflect: true,
            },
        };
    }

    constructor() {
        super();
        this.text = '';
        this.value = '';
        this.label = '';
        this.checked = false;
        this.indeterminate = false;
        this.hideLabel = false;
        this.disabled = false;
        this.triStateInput = false;
    }

    get display() {
        return this.text || this.label;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-checkbox--checkbox',
        };
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    switchCheckbox(e) {
        if (this.triStateInput) {
            // commutate between three states, in sequence of checked, not-checked, indeterminate, back to checked
            const wasChecked = this.checked;
            this.checked = this.indeterminate;
            this.indeterminate = !this.indeterminate && !wasChecked;
        } else {
            // binary toggle, getting out of indeterminate state when applicable
            this.checked = !this.checked || this.indeterminate;
            this.indeterminate = false;
        }

        this.dispatchEvent(
            new CustomEvent('toggle', {
                bubbles: true,
                composed: true,
                detail: {
                    value: this.value,
                    checked: this.checked,
                    indeterminate: this.indeterminate,
                },
            })
        );
    }

    //  Note: id must have a non-empty value so that the label can't block click event to the input
    render() {
        const stringified =
            typeof this.value === 'string' ? this.value : JSON.stringify(this.value);

        return html`
            <input
                id="checkbox"
                type="checkbox"
                .aria-label=${this.text}
                class="omega-checkbox"
                ?checked=${this.checked}
                ?indeterminate=${this.indeterminate}
                ?disabled=${this.disabled}
                .name=${this.display}
                .value=${stringified}
                @change=${this.switchCheckbox}
            />
            <label for="checkbox" class="omega-checkbox-label"
                >${this.hideLabel ? '' : this.display}</label
            >
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin-right: 12px;
            }

            .omega-checkbox {
                position: absolute;
                opacity: 0;
            }

            .omega-checkbox-label {
                position: relative;
                padding: 3px;
                cursor: pointer;
                font-weight: 400;
                border-radius: 4px;
            }

            .omega-checkbox-label::before {
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 10px;
                border: 1px solid var(--omega-secondary-lighten-300);
                background: white;
                border-radius: 4px;
                content: '';
                vertical-align: text-top;
                box-shadow: var(--omega-checkbox-box-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.08));
            }

            .omega-single-select-override .omega-checkbox-label::before,
            .omega-single-select-override .omega-checkbox-label::after {
                display: none;
            }

            .omega-checkbox:focus + .omega-checkbox-label::before {
                box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
            }

            .omega-checkbox[checked] + .omega-checkbox-label::before {
                background: var(--omega-primary);
                box-shadow: none;
            }

            .omega-checkbox[indeterminate] + .omega-checkbox-label::before {
                background: var(--omega-secondary-lighten-200);
            }

            .omega-checkbox[disabled] + .omega-checkbox-label {
                color: var(--omega-text-tertiary);
                cursor: auto;
            }

            input[type='checkbox']:disabled + label::before {
                background: var(--omega-secondary-lighten-400);
            }

            .omega-checkbox[disabled] + .omega-checkbox-label::before {
                opacity: 75%;
                cursor: not-allowed;
            }

            .omega-checkbox[checked] + .omega-checkbox-label::after {
                position: absolute;
                top: 13px;
                left: 8px;
                width: 2px;
                height: 2px;
                background: var(--omega-checkbox-checkmark-color, white);
                box-shadow: 2px 0 0 var(--omega-checkbox-checkmark-color, white),
                    4px 0 0 var(--omega-checkbox-checkmark-color, white),
                    4px -2px 0 var(--omega-checkbox-checkmark-color, white),
                    4px -4px 0 var(--omega-checkbox-checkmark-color, white),
                    4px -6px 0 var(--omega-checkbox-checkmark-color, white),
                    4px -8px 0 var(--omega-checkbox-checkmark-color, white);
                content: '';
                transform: rotate(45deg);
            }

            .omega-checkbox[indeterminate] + .omega-checkbox-label::after {
                transform: none;
                box-shadow: none;
                position: absolute;
                top: 12px;
                left: 8px;
                width: 11px;
                height: 2px;
                background: white;
                content: '';
            }

            :host([type='noBackground']) {
                --omega-checkbox-checkmark-color: var(--omega-primary);
            }

            :host([type='noBackground']) .omega-checkbox[checked] + .omega-checkbox-label::before {
                border-color: transparent;
                background: transparent;
                box-shadow: none;
            }

            :host([type='noBackground']) .omega-checkbox[checked] + .omega-checkbox-label::after {
                transform: rotate(45deg) scale(1.5);
                left: 6px;
            }
        `;
    }
}

customElements.define('omega-checkbox', OmegaCheckbox);
export default OmegaCheckbox;
