IssuedItemsActivityController.$inject = [
    'arpService',
    '$state',
    '$uibModal',
    'companyAccountsService',
    'DatePeriod',
    'downloadPageId',
];

export default function IssuedItemsActivityController(
    arpService,
    $state,
    $uibModal,
    companyAccountsService,
    DatePeriod,
    downloadPageId
) {
    const ctrl = this;
    ctrl.issuedItemActivitySearchForm = {};
    ctrl.sidebarClosed = true;
    ctrl.accountDisplayField = '';
    ctrl.companyAccounts = [];
    ctrl.exportOptions = { canPrint: true };
    ctrl.helpText =
        'Includes all issued items and the current item status. Utilize the search feature to review issued items previously submitted.';
    ctrl.filteredIssuedItems = [];
    ctrl.workingIssuedItems = [];
    ctrl.isDataLoaded = false;
    ctrl.maxDate = null;
    ctrl.searchParams = {
        amount: {},
        createdDate: {},
        issuedDate: {},
    };
    ctrl.textFilter = '';
    ctrl.downloadOptions = {
        downloadFormats: ['PDF', 'CSV'],
        pageId: downloadPageId.IssuedItemsActivity,
        filterModel: ctrl.searchParams,
    };
    ctrl.itemStatusOptions = [
        { name: 'Issued', label: 'Issued' },
        { name: 'Cleared', label: 'Cleared' },
        { name: 'Returned', label: 'Returned' },
        { name: 'Void', label: 'Void' },
        { name: 'Deleted', label: 'Deleted' },
        { name: 'StopPay', label: 'Stop Pay' },
        { name: 'StaleDate', label: 'Stale Date' },
        { name: 'Dormant', label: 'Dormant' },
    ];

    ctrl.toggleSearchPanel = toggleSearchPanel;
    ctrl.filterIssuedItems = filterIssuedItems;
    ctrl.getTitle = getTitle;
    ctrl.goToCheckExceptions = goToCheckExceptions;
    ctrl.goToDecisionActivity = goToDecisionActivity;
    ctrl.goToCreateIssuedItems = goToCreateIssuedItems;
    ctrl.search = search;
    ctrl.reset = reset;
    ctrl.textFilterDisabled = textFilterDisabled;
    ctrl.getPageTotalAmount = getPageTotalAmount;
    ctrl.getFormattedAuditText = getFormattedAuditText;

    function toggleSearchPanel() {
        ctrl.sidebarClosed = !ctrl.sidebarClosed;
    }

    function filterIssuedItems(textFilter) {
        ctrl.textFilter = textFilter || '';
        ctrl.filteredIssuedItems = ctrl.workingIssuedItems.filter(row => filterRow(row));
    }

    function filterRow(row) {
        if (ctrl.textFilter.trim() === '') {
            return true;
        }

        const searchText = ctrl.textFilter.toLowerCase();
        const createdDateText = new Date(row.createdDate).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });
        const dateIssuedText = new Date(row.dateIssued).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        return (
            (row.account &&
                row.account.number &&
                row.account.number.toLowerCase().indexOf(searchText) !== -1) ||
            (row.checkAmount && row.checkAmount.toFixed(2).indexOf(searchText) !== -1) ||
            (row.checkNumber && row.checkNumber.toLowerCase().indexOf(searchText) !== -1) ||
            (row.createdDate && createdDateText.indexOf(searchText) !== -1) ||
            (row.dateIssued && dateIssuedText.indexOf(searchText) !== -1) ||
            (row.itemEntryType && row.itemEntryType.toLowerCase().indexOf(searchText) !== -1) ||
            (row.itemStatus && row.itemStatus.toLowerCase().indexOf(searchText) !== -1) ||
            (row.payee && row.payee.toLowerCase().indexOf(searchText) !== -1) ||
            (row.type && row.type.toLowerCase().indexOf(searchText) !== -1)
        );
    }

    function getTitle() {
        return 'Issued Items Activity';
    }

    function goToCheckExceptions() {
        $state.go('payables.arp.check-exceptions');
    }

    function goToDecisionActivity() {
        $state.go('payables.arp.check-exception-decision-activity');
    }

    function goToCreateIssuedItems() {
        $state.go('payables.arp.create');
    }

    function loadAccounts() {
        arpService.getArpAccounts('UploadCreateArpFile').then(response => {
            if (response.length === 0) {
                handleNoAccounts();
                return;
            }

            ctrl.companyAccounts = response;

            if (response.length > 0 && response[0] != null) {
                ctrl.accountDisplayField = response[0].showAccountNickName ? 'name' : 'number';
            }

            // Initialize accounts.  Has an option to include a specific initial account in the URL, for the sake of linking from other pages.
            // The search filter must be pre-initialized because binding won't be complete before the initial search is done.
            if (!$state.params.accountNumber) {
                resetAccounts();
                ctrl.searchParams.accounts = [];
            } else if ($state.params.accountNumber) {
                ctrl.searchParams.accounts = [];
                ctrl.companyAccounts.forEach(account => {
                    if (
                        (account.showAccountNickName &&
                            account.name === $state.params.accountNumber) ||
                        (!account.showAccountNickName &&
                            account.number === $state.params.accountNumber)
                    ) {
                        account.isChecked = true;
                        ctrl.searchParams.accounts.push(account);
                    } else {
                        account.isChecked = false;
                    }
                });
            }
        });
    }

    function handleNoAccounts() {
        $uibModal
            .open({
                template: require('../views/missingAccountsModal.html'),
                controller: 'MissingAccountsModalController',
                backdrop: 'static',
                resolve: {
                    contextSpecificMessage() {
                        return 'There are no accounts associated with Issued Items.';
                    },
                },
            })
            .result.then(() => {
                $state.go('dashboard');
            });
    }

    function search(searchParams) {
        regularizeSearchParameters(searchParams);

        arpService.searchIssuedItems(searchParams).then(response => {
            if (response != null) {
                if (response.length === 0) {
                    ctrl.sidebarClosed = false;
                } else {
                    ctrl.sidebarClosed = true;
                    response.forEach(issuedItem => {
                        issuedItem.itemEntryType = issuedItem.isUploaded ? 'Upload' : 'Manual';
                    });
                }
            }

            ctrl.workingIssuedItems = response;

            filterIssuedItems(ctrl.textFilter);
            ctrl.isDataLoaded = true;
        });
    }

    function regularizeSearchParameters(searchParams) {
        // map to API contract
        searchParams.issuedDateEnd = searchParams.issuedDate.end;
        searchParams.issuedDateStart = searchParams.issuedDate.start;
        if (searchParams.issuedDate.searchOption === DatePeriod.SPECIFIC_DATE) {
            searchParams.issuedDateEnd = searchParams.issuedDateStart;
        }

        searchParams.createdDateEnd = searchParams.createdDate.end;
        searchParams.createdDateStart = searchParams.createdDate.start;
        if (searchParams.createdDate.searchOption === DatePeriod.SPECIFIC_DATE) {
            searchParams.createdDateEnd = searchParams.createdDateStart;
        }

        searchParams.amountEnd = searchParams.amount.end;
        searchParams.amountStart = searchParams.amount.start;
        if (searchParams.amount.searchOption === 'Specific Amount') {
            searchParams.amountEnd = searchParams.amountStart;
        }

        if (searchParams.itemStatusOptions) {
            searchParams.statusNames = [];

            searchParams.itemStatusOptions.forEach(statusOption => {
                searchParams.statusNames.push(statusOption.name);
            });
        }
    }

    function reset() {
        companyAccountsService.getCutoffTimes().then(response => {
            ctrl.maxDate = response.currentFiTime;

            ctrl.searchParams.itemEntryType = 'both';
            ctrl.searchParams.itemType = 'both';

            ctrl.itemStatusOptions.forEach(statusOption => {
                statusOption.isChecked = true;
            });

            ctrl.searchParams.issuedDate.searchOption = null;
            ctrl.searchParams.createdDate.searchOption = DatePeriod.TODAY;

            resetAccounts();

            ctrl.searchParams.amount.searchOption = 'Specific Amount';
            ctrl.searchParams.checkNumber = null;
            ctrl.searchParams.payee = null;

            ctrl.textFilter = '';
        });
    }

    function resetAccounts() {
        ctrl.companyAccounts.forEach(account => {
            account.isChecked = true;
        });
    }

    function textFilterDisabled() {
        return ctrl.filteredIssuedItems.length === 0 && ctrl.textFilter.length === 0;
    }

    function getPageTotalAmount(issuedItemsOnPage) {
        let total = 0;
        issuedItemsOnPage.forEach(issuedItem => {
            total += issuedItem.checkAmount;
        });
        return total;
    }

    function getFormattedAuditText(auditItems) {
        let formattedText = '';
        if (auditItems && auditItems.length > 0) {
            auditItems.forEach(auditItem => {
                const datetime = new Date(auditItem.createdFiDateTime);
                const formattedDate = datetime.toLocaleDateString();
                const formattedTime = datetime.toLocaleTimeString();
                formattedText += `${formattedDate} ${formattedTime} ${auditItem.createdByUserName} ${auditItem.action}<br />`;
            });
        } else {
            formattedText += 'Not available.<br />';
        }

        return formattedText;
    }

    function init() {
        Promise.all([loadAccounts()]).then(() => {
            reset();
            search(ctrl.searchParams);
        });
    }

    init();
}
