/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

/**
 * User services for TMCH
 */
export class UserRequests {
    static async getUsers() {
        return (await TmHttpClient.getInstance()).request(`users/search`, {
            method: 'POST',
            body: {
                searchUserModel: {
                    ActiveUsersOnly: true,
                    EnrolledUsersOnly: true,
                    IncludeRoles: true,
                },
            },
        });
    }

    static async getAllUsers() {
        return (await TmHttpClient.getInstance()).request(`users/search`, {
            method: 'POST',
            body: {
                searchUserModel: {
                    ActiveUsersOnly: false,
                    EnrolledUsersOnly: false,
                    IncludeRoles: true,
                },
            },
        });
    }

    static async fetchCurrentUserId() {
        const localUser = JSON.parse(sessionStorage.getItem('user'));
        const users = await this.getUsers();
        return users.find(user => user.userId === localUser.userName).id;
    }

    static async fetchCurrentUser() {
        const localUser = JSON.parse(sessionStorage.getItem('user'));
        const users = await this.getUsers();
        return users.find(user => user.userId === localUser.userName);
    }

    static async fetchApprovers(body) {
        return (await TmHttpClient.getInstance()).request(`users/getApprovers`, {
            method: 'POST',
            body,
        });
    }

    static async fetchAvailableApprovers(body) {
        const http = await TmHttpClient.getInstance();
        const result = await http.request(`users/getApprovers`, {
            method: 'POST',
            body,
        });
        return result.availableApprovers;
    }

    static async fetchAvailableAndCompletedApprovers(body) {
        const http = await TmHttpClient.getInstance();
        return http.request(`users/getApprovers`, {
            method: 'POST',
            body,
        });
    }

    static async getAccounts() {
        return (await TmHttpClient.getInstance()).request('users/accessibleaccounts', {
            maxAgeInSeconds: 600,
        });
    }

    static async getAccountUniqueIdById(id) {
        return (await TmHttpClient.getInstance()).request(`account/accountUniqueId/${id}`, {
            maxAgeInSeconds: 600,
        });
    }

    static async getCurrentUserTimeAccess() {
        return (await TmHttpClient.getInstance()).request(`users/usertimeaccess`, {
            method: 'GET',
        });
    }

    static async getUserTimeAccess(id) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/usertimeaccess`, {
            method: 'GET',
        });
    }

    static async saveUserTimeAccess(id, body) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/`, { method: 'POST', body });
    }

    static async searchUsers() {
        return (await TmHttpClient.getInstance()).request('users/search', { method: 'POST' });
    }

    static async createUser(body) {
        return (await TmHttpClient.getInstance()).request(`users/0/userinformation`, {
            method: 'POST',
            body,
        });
    }

    static async getUserHeader(id) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/header`, { method: 'GET' });
    }

    static async getUserInformation(id) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/userinformation`, {
            method: 'GET',
        });
    }

    static async getUserProductFeatures(id) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/productfeaturesummary`, {
            method: 'GET',
        });
    }

    static async getUserProductFeatureDetail(id, featureName) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/${featureName}`, {
            method: 'GET',
        });
    }

    /**
     *
     * @param { number } id
     * @param { 'approve' | 'reject'} action
     * @param { string } comments
     */
    static async approveOrRejectUser(id, action, comments) {
        return (await TmHttpClient.getInstance()).request(`users/${id}/${action}?comments=${comments}`, {
            method: 'PUT',
        });
    }
}

// eslint-disable-next-line @treasury/filename-match-export
export default UserRequests;
