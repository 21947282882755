import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-input';
import '@treasury/omega/components/omega-button.js';
import './ach-payment-addenda-dialog.js';
import { boolean } from '@treasury/policy/primitives';
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import { mix } from 'mixwith';
import { ListeningElementMixin } from '@treasury/omega/components';

class AchPaymentRecipientsTable extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            recipientsRecordset: Object,
            recipients: Array,
            editable: { type: Boolean, reflect: true },
            tableFilters: Array,
            filters: Array,
            holidays: Array,
            columns: Array,
            detailFunction: Function,
            selectedRecord: Object,
        };
    }

    constructor() {
        super();
        this.editable = false;
        this.recipients = null;
        this.filters = [
            {
                field: 'prenote',
                fieldType: boolean.with.label('Prenote Only').thatIs.inline().as.tag('omega-checkbox'),
                value: false,
            },
            {
                field: 'hold',
                fieldType: boolean.with.label('Hold Only').thatIs.inline().as.tag('omega-checkbox'),
                value: false,
            },
            {
                field: '#invalid',
                fieldType: boolean.with.label('Errors').thatIs.inline().as.tag('omega-checkbox'),
                value: false,
            },
        ];
        this.actions = {
            Addenda: (record /* rowIdx */) => {
                this.selectedRecord = record;
            },
        };
    }

    firstUpdated() {
        this.listenTo(this.recipientsRecordset, 'change', () => {
            this.dispatchEvent(
                new CustomEvent('changed', {
                    composed: true,
                    bubbles: true,
                    detail: this.recipientsRecordset,
                })
            );
        });
        this.listenTo(this.recipientsRecordset, 'updated', () => {
            this.dispatchEvent(
                new CustomEvent('updated', {
                    composed: true,
                    bubbles: true,
                    detail: this.recipientsRecordset,
                })
            );
        });

        this.listenTo(this.recipientsRecordset, 'page-changed', () => {
            this.recipientsRecordset.allRecords.forEach(record => {
                record.isExpanded = true; // eslint-disable-line no-param-reassign
            });
        });

        this.recipientsRecordset.requestUpdate();
    }

    handleAction({ action, record, rowIndex }) {
        const actionFunction = this.actions[action];
        if (actionFunction) actionFunction(record, rowIndex);
    }

    renderAddendaDialog() {
        const addenda = this.selectedRecord?.getField('addenda')[0];

        return html`<ach-payment-addenda-dialog
            .selectedRecord=${this.selectedRecord}
            .addenda=${addenda}
            .editable=${this.editable}
            @close=${() => {
                this.selectedRecord = null;
            }}
        ></ach-payment-addenda-dialog>`;
    }

    renderRecipientDetails(record, close) {
        if (this.detailFunction) return this.detailFunction(record, close, this.editable);
        return nothing;
    }

    render() {
        if (this.recipientsRecordset) {
            return html`
                ${this.renderAddendaDialog()}
                <omega-filter-bar
                    .recordset=${this.recipientsRecordset}
                    .filters=${this.filters}
                    @change=${e => {
                        this.tableFilters = e.detail;
                    }}
                ></omega-filter-bar>
                <omega-table
                    .recordset=${this.recipientsRecordset}
                    .columnDefinitions=${this.columns}
                    .filters=${this.tableFilters}
                    .detailRowTemplateFunction=${(record, close) =>
                        this.renderRecipientDetails(record, close)}
                    @action=${e => this.handleAction(e.detail)}
                >
                </omega-table>
            `;
        }
        return nothing;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                #addenda-dialog {
                    min-width: 500px;
                }
            `,
        ];
    }
}

customElements.define('ach-payment-recipients-table', AchPaymentRecipientsTable);
