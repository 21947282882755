MasterRecipientModalController.$inject = ['recipient', 'achMasterRecipientService', 'moment'];

export default function MasterRecipientModalController(
    recipient,
    achMasterRecipientService,
    moment
) {
    const vm = this;

    vm.getFormattedAudits = getFormattedAudits;

    vm.recipient = recipient;

    function init() {
        achMasterRecipientService.getMasterRecipientAudits(recipient.id).then(response => {
            recipient.audits = response.audits;
        });
    }

    function getFormattedAudits() {
        if (recipient.audits == null) return '';

        const audits = recipient.audits.map(
            audit =>
                `${moment(audit.actionDate).format('M/D/YYYY h:m:ss A')}:${audit.userAlias}:${
                    audit.action
                }`
        );
        return audits.join('\n');
    }

    init();
}
