import { accountNumber, accountType } from '@treasury/policy/account.js';
import { achCompanyName } from '@treasury/policy/ach';
import { secCode } from '@treasury/policy/company';
import { date, id, string } from '@treasury/policy/primitives.ts';

export const reportFields = {
    achNocTransactionUniqueId: id.thatIs.readOnly(),
    effectiveDate: date.thatIs.readOnly(),
    receivedDate: date.thatIs.readOnly(),
    achCompanyName: achCompanyName.thatIs.readOnly(),
    companyEntryDescription: string.thatIs.readOnly(),
    changeCode: string.thatIs.readOnly(),
    originalSecCode: secCode.thatIs.readOnly(),
    updatedSecCode: secCode.thatIs.readOnly(),
    originalRecipientName: string.thatIs.readOnly(),
    updatedRecipientName: string.thatIs.readOnly(),
    originalRecipientFi: string.thatIs.readOnly(),
    updatedRecipientFi: string.thatIs.readOnly(),
    originalAccountNumber: accountNumber.thatIs.readOnly(),
    updatedAccountNumber: accountNumber.thatIs.readOnly(),
    originalAccountType: accountType.thatIs.readOnly(),
    updatedAccountType: accountType.thatIs.readOnly(),
    originalIdNumber: string.thatIs.readOnly(),
    updatedIdNumber: string.thatIs.readOnly(),
    originalTraceNumber: string.thatIs.readOnly(),
    updatedTraceNumber: string.thatIs.readOnly(),
};
