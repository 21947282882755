import { getChannelAPIPath } from '@treasury/core/http';
import DomainSecCodesService from '@treasury/domain/channel/services/sec-codes/sec-codes-service.js';

SecCodesService.$inject = ['$resource', '$q', '$http'];

export default function SecCodesService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}secCodes/`;

    return {
        getAll,
        getConfigcSecCodes,
    };

    function getConfigcSecCodes() {
        return $resource(resourceUrl).query().$promise;
    }

    async function getAll() {
        const codes = await DomainSecCodesService.getSecCodes();
        return codes.map(code => code.secCode);
    }
}
