import EdiReportsService from '@treasury/domain/channel/services/reporting/edi-reports-service.js';

// eslint-disable-next-line @treasury/filename-match-export
export default class EdiReportsClient {
    async fetchAccounts() {
        return EdiReportsService.fetchAccounts();
    }

    async fetchTransactionCodeGroups() {
        return EdiReportsService.fetchTransactionCodeGroups();
    }

    async fetchReportData(...args) {
        return EdiReportsService.fetchReportData(...args);
    }

    async fetchReportFilters(...args) {
        return EdiReportsService.fetchReportFilters(...args);
    }
}
