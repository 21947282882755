import { AccountAccessModelDto, GetUserAccountAccessModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { getDisplayValue } from '../helpers/get-display-value';
import { isEdited } from '../helpers/is-edited';

export class UserAccountAccess extends DomainEntity<GetUserAccountAccessModelDto> {
    constructor(dto: GetUserAccountAccessModelDto) {
        super(dto);
    }

    public createDefault() {
        return new UserAccountAccess({} as GetUserAccountAccessModelDto) as this;
    }

    public get accounts() {
        return this.dto.accounts?.map((account: AccountAccessModelDto) => ({
            ...account.companyAccount,
            value: getDisplayValue(account.hasUserAccess, account.hasPendingUserAccess),
            edited: isEdited(account.hasUserAccess, account.hasPendingUserAccess),
        }));
    }
}
