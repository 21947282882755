import { BatchRecipient, PaymentHeader } from '@treasury/domain/channel/types/ach';
import { boolean, Record, Recordset } from '@treasury/FDL';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import { Schema } from '@treasury/omega/layouts/omega-form';
import { BatchDetailModal } from '../../../dialogs/batch-detail-modal';

export const getColumns = (
    context: BatchDetailModal,
    config: any,
    record: Record<PaymentHeader>
): OmegaColumnDefinition<BatchRecipient>[] => {
    const { entitlements, action, type } = config;
    return [
        { field: 'name', label: 'Recipient Name' },
        { field: 'idNumber', label: 'ID Number' },
        { field: 'accountNumber', label: 'Account Number' },
        { field: 'accountType', label: 'Account Type' },
        { field: 'routingNumber', label: 'Routing Number' },
        {
            field: 'transactionType',
            label: 'Credit/Debit',
        },
        { field: 'amount', label: 'Amount' },
        {
            field: 'prenote',
            label: 'Prenote',
            sortable: true,
        },
        {
            field: 'hold',
            label: 'Hold',
            sortable: true,
        },
        {
            label: 'Addenda',
            type: OmegaColumnType.Actions,
            actions: [
                {
                    label: 'Addenda',
                    action: (r: Record<BatchRecipient>) => context.openAddendaDialog(r),
                },
            ],
        },
    ];
};

/**
 * this schema powers the payment details modal that can be accessed during the ach domestic batch workflow
 * renders ach payment data in read only format
 */
export const getBatchDetailViewSchema = (
    context: BatchDetailModal,
    record: Record<PaymentHeader>,
    recordset: Recordset<BatchRecipient>,
    config: any
): Schema<PaymentHeader, BatchRecipient> => ({
    record,
    recordsetConfiguration: {
        recordset,
        recordsetColumns: () => getColumns(context, config, record),
        recordsetFilters: [
            {
                field: 'prenote',
                fieldType: boolean.with
                    .label('Prenote Only')
                    .thatIs.inline()
                    .as.tag('omega-checkbox'),
                value: false,
            },
            {
                field: 'hold',
                fieldType: boolean.with.label('Hold Only').thatIs.inline().as.tag('omega-checkbox'),
                value: false,
            },
        ],
    },
    sections: [
        {
            columns: [
                {
                    fields: [
                        'transactionId',
                        'name',
                        'status',
                        'achCompany',
                        'achCompanyId',
                        'secCode',
                        'entryDescription',
                        'discretionaryData',
                        'restricted',
                    ],
                },
                {
                    fields: ['debitAmount', 'creditAmount', 'frequency', 'offsetAccount', 'audit'],
                },
            ],
        },
    ],
});
