AccountGroupNameModalController.$inject = ['$scope', '$modalInstance', 'modalService', 'group'];

// eslint-disable-next-line @treasury/filename-match-export
export default function AccountGroupNameModalController(
    $scope,
    $modalInstance,
    modalService,
    group
) {
    $scope.group = angular.copy(group);
    $scope.isDirty = false;

    $scope.close = close;
    $scope.confirm = confirm;
    $scope.isNewGroup = isNewGroup;
    $scope.setDirty = setDirty;

    function isNewGroup() {
        return !$scope.group.id;
    }

    function confirm() {
        $modalInstance.close($scope.group);
    }

    function close() {
        let modalOptions;
        let modalInstance;

        if ($scope.isDirty) {
            modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit() {
                    $modalInstance.dismiss();
                    modalInstance.dismiss();
                },
            };
            modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    }

    function setDirty() {
        $scope.isDirty = true;
    }
}
