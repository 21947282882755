import { AchFilterServices } from '@treasury/domain/channel/services/ach/ach-filter-services.js';
import accountingNotation from '@treasury/omega/formatters/accountingNotation.formatter';
import usd from '@treasury/omega/formatters/usd.formatter';
import { accountNumber } from '@treasury/policy/account.js';
import {
    accountFilterSettings,
    achCompanyId,
    achCompanyName,
    expirationDate,
    filterRuleName,
    fromAmount,
    toAmount,
    transactionStatus,
    transactionType,
} from '@treasury/policy/ach';
import { secCode } from '@treasury/policy/company';
import { string } from '@treasury/policy/primitives';
import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { until } from 'lit/directives/until.js';

export const achFilterFields = async accountSettings => ({
    AchFilterUniqueId: 0,
    achFilterUniqueId: string,
    filterRuleName: filterRuleName.thatIs.readOnly(),
    accountNumber: accountNumber.thatIs
        .readOnly()
        .with.formatter((value, record) =>
            accountSettings.accountNicknames
                ? record.getField('accountNickname')
                : record.getField('accountNumber')
        ),
    accountFilterSettings: accountFilterSettings.thatIs.readOnly(),
    achCompanyName: achCompanyName.thatIs.readOnly(),
    achCompanyId: achCompanyId.thatIs.readOnly().with.template(value => {
        if (!value) return '';
        if (value.includes(' ')) {
            return unsafeHTML(value.replace(/ /g, '&nbsp;'));
        }
        return value;
    }),
    secCode: secCode.thatIs.readOnly(),
    expirationDate: expirationDate.thatIs.readOnly(),
    fromAmount: fromAmount.thatIs.readOnly(),
    toAmount: toAmount.thatIs
        .readOnly()
        .with.label('Amount')
        .and.textAlign('left')
        .and.formatter((value, record) => {
            let fromAmountValue = record.getField('fromAmount');
            const toAmountValue = record.getField('toAmount');
            // Ugly hack to get around the API returning NULL for the FromAmount when we have a valid range of 0 - something. Once Services updates the API (AB#314023)
            if (fromAmountValue == null && toAmountValue) {
                fromAmountValue = 0;
            }
            if (
                fromAmountValue != null &&
                toAmountValue != null &&
                fromAmountValue !== toAmountValue
            ) {
                return `
                    ${accountingNotation(usd(fromAmountValue))} - ${accountingNotation(
                        usd(toAmountValue)
                    )}
                `;
            }
            if (fromAmountValue) {
                return `${accountingNotation(usd(fromAmountValue))}`;
            }
            return '';
        }),
    transactionType: transactionType.thatIs.readOnly().with.template((value, record) => {
        const allowTypeValue = record.getField('allowType');
        const transactionTypeValue = record.getField('transactionType');
        const allowTypeLabel = allowTypeValue === 'Allow' ? 'Allowed' : 'Not Allowed';
        return `${transactionTypeValue} ${allowTypeLabel}`;
    }),
    detailStatus: string,
    accountMasterSettings: string,
    mode: string.thatIs.visibleWhen(() => false),
    status: transactionStatus.thatIs.readOnly().and.template((status, record) => {
        if (status === 'Active') return html`<omega-badge type="success">active</omega-badge>`;
        if (status === 'Failed') return html`<omega-badge type="error">failed</omega-badge>`;
        if (status === 'Inactive') return html`<omega-badge type="inactive">inactive</omega-badge>`;
        if (status === 'Pending') {
            const pendingID = record.getField('achFilterUniqueId');
            const result = AchFilterServices.findEligibleApprovers(pendingID).then(approvers =>
                approvers.map(approver => html`<p>${approver.name}</p>`)
            );
            return html`<omega-tooltip direction="top" clickable light
                ><omega-badge type="warning" clickable>pending<br />approval</omega-badge>
                <div slot="content">
                    <span style="margin-right: 10px; font-weight: bold">Eligible Approvers</span>
                    ${until(result, 'Loading')}
                </div></omega-tooltip
            > `;
        }
        return nothing;
    }),
});

export default achFilterFields;
