import { Address } from '../shared';

export function address2Array(obj: Address): string[] {
    let addressArray = [];

    if (obj.addressLine1) {
        addressArray.push(obj.addressLine1);
    }
    if (obj.addressLine2) {
        addressArray.push(obj.addressLine2);
    }
    if (obj.addressLine2) {
        addressArray.push(obj.addressLine2);
    }
    let city = null;
    if (obj.country === 'US') {
        if (obj.city) {
            city = obj.city;
            if (obj.state) {
                city += ', ';
            }
        }
        if (obj.state) {
            city += obj.state;
            if (obj.postalCode) {
                city += ' ';
            }
        }

        if (obj.postalCode) {
            city += obj.postalCode;
        }
    } else if (obj.country) {
        if (obj.city) {
            city = obj.city + ', ';
        }
        city += obj.country;
    }

    if (city) {
        addressArray.push(city);
    }

    return addressArray;
}
