import { createUniqueId, exists } from '../../functions';
import { ObservableTransform, Observer, SubscriptionHandle } from './observable.types';
import type { ObservationSource } from './observation-source';

/**
 * Represents a stream of values that can be observed over time.
 *
 * In general, this class should not be instantiated directly.
 * Prefer {@link ObservationSource.toObservable | `ObservationSource::toObservable()`}.
 */
export class Observable<T> {
    constructor(source?: ObservationSource<T>) {
        if (source) {
            this.source = source;
            // copy constructor
            this.observers = source.observers;
            this.errorObservers = source.errorObservers;
            this.completionObservers = source.completionObservers;
            this.lastValue = source.lastValue;

            source.subscribe(value => {
                this.lastValue = value;
            }, false);
            source.listenForCompletion(() => {
                this._completed = true;
            });
        } else {
            // hack since `ObservationSource` cannot provide
            // itself as a super() argument
            this.source = this as unknown as ObservationSource<T>;
        }
    }

    /**
     * An `ObservationSource` which drives the production of
     * values through this `Observable`.
     *
     * Since all `ObservationSource` instances are also an `Observable`,
     * in these cases, the `source` and the instance are one and the same.
     */
    protected source: ObservationSource<T>;

    protected _completed = false;

    protected observers = new Map<string, Observer<T>['onNext']>();

    protected errorObservers = new Map<string, Observer<T>['onError']>();

    protected completionObservers = new Map<string, Observer<T>['onComplete']>();

    protected lastValue?: T;

    public get completed() {
        return this._completed;
    }

    public observe(observer: Partial<Observer<T>>): SubscriptionHandle {
        this.guardCompletion();

        const { onNext, onError, onComplete } = observer;
        const subs = [
            onNext ? this.subscribe(onNext, false, true) : undefined,
            onError ? this.listenForError(onError) : undefined,
            onComplete ? this.listenForCompletion(onComplete) : undefined,
        ].filter(exists);

        this.initSource();

        return {
            unsubscribe: () => subs.forEach(s => s.unsubscribe()),
        };
    }

    /**
     * @param emitLastValue If `true`, immediately re-emit the last value seen by this observable only to the subscriber.
     * @param defer If `true`, defers invoking the underlying value source until a later time.
     */
    public subscribe(
        callback: Observer<T>['onNext'],
        emitLastValue = false,
        defer = false
    ): SubscriptionHandle {
        this.guardCompletion();

        const id = createUniqueId();
        this.observers.set(id, callback);

        if (!defer) {
            this.initSource();
        }

        if (emitLastValue && this.lastValue) {
            callback(this.lastValue);
        }

        return {
            unsubscribe: () => {
                this.observers.delete(id);
            },
        };
    }

    public listenForError(callback: Observer<T>['onError']): SubscriptionHandle {
        this.guardCompletion();

        const id = createUniqueId();
        this.errorObservers.set(id, callback);

        return {
            unsubscribe: () => {
                this.errorObservers.delete(id);
            },
        };
    }

    public listenForCompletion(callback: Observer<T>['onComplete']): SubscriptionHandle {
        this.guardCompletion();

        const id = createUniqueId();
        this.completionObservers.set(id, callback);
        this.initSource();

        return {
            unsubscribe: () => {
                this.completionObservers.delete(id);
            },
        };
    }

    public pipe<R>(transform: ObservableTransform<T, R>) {
        return this.source.pipeSource(transform, true).toObservable();
    }

    /**
     * Conditionally allow events from one event stream into another.
     *
     * @param predicate Function that receives a value of would-be value emission
     * and only allows it into the new stream if it passes the predicate test with a `true` result.
     */
    public filter(predicate: (value: T) => boolean) {
        return this.source.filterSource(predicate, true).toObservable();
    }

    /**
     * Creates a a `Promise` representation of the `Observable`.
     *
     * Since `Promise` semantics are such that they can only
     * complete once, this will only be fulfilled with
     * last emission from the source `Observable`.
     *
     * @returns A `Promise` fulfilled with the most recent value of the source observable.
     */
    public toPromise(emitLast = true) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const subs: SubscriptionHandle[] = [];

        return new Promise<T>((resolve, reject) => {
            const subscribeCallback = (value: T) => {
                subs.forEach(s => s.unsubscribe());
                resolve(value);
            };

            subs.push(
                self.subscribe(subscribeCallback, emitLast),
                self.listenForError(error => reject(error))
            );
        });
    }

    protected guardCompletion() {
        if (this._completed) {
            throw new Error('Cannot operate on a completed Observable.');
        }
    }

    protected clearSubscriptions() {
        this.observers.clear();
        this.completionObservers.clear();
        this.errorObservers.clear();
    }

    private initSource() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.source !== this) {
            this.source.init();
        }
    }
}
