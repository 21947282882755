ChangeStpStatusDialogController.$inject = [
    '$scope',
    '$modalInstance',
    'headerText',
    'message',
    'actionButtonText',
    'actionButtonClass',
    'closeButtonText',
];

export default function ChangeStpStatusDialogController(
    $scope,
    $modalInstance,
    headerText,
    message,
    actionButtonText,
    actionButtonClass,
    closeButtonText
) {
    $scope.headerText = headerText;
    $scope.message = message;
    $scope.actionButtonText = actionButtonText;
    $scope.actionButtonClass = actionButtonClass;
    $scope.closeButtonText = closeButtonText;
    $scope.cancel = cancel;
    $scope.save = save;
    $scope.setForm = setForm;

    function cancel() {
        $modalInstance.dismiss();
    }

    function save() {
        $modalInstance.close($scope.comments);
    }

    function setForm(form) {
        $scope.form = form;
    }
}
