MissingAccountsModalController.$inject = ['$scope', 'contextSpecificMessage'];

export default function MissingAccountsModalController($scope, contextSpecificMessage) {
    $scope.modalMessage = "You don't have any accounts associated with this feature.";

    function init() {
        if (contextSpecificMessage) {
            $scope.modalMessage = contextSpecificMessage;
        }
    }

    init();
}
