import { LitElement, html, css, nothing } from 'lit';
import { ListeningElementMixin } from '@treasury/omega/components';
import { downloadIcon } from '@treasury/omega/css/icons/download-icon.js';

export default class AchAccountReconciliationReportViewer extends ListeningElementMixin(
    LitElement
) {
    static get properties() {
        return {
            activeReport: Object,
            reportUrl: String,
            reportFileName: String,
            loadingFile: Boolean,
            availableFileOutputTypes: Array,
        };
    }

    downloadReport(id, name) {
        this.dispatchEvent(
            new CustomEvent('downloadReport', {
                detail: {
                    id,
                    name,
                },
            })
        );
    }

    deleteActiveFile() {
        this.dispatchEvent(new CustomEvent('deleteActiveFile'));
    }

    renderSingleDownloadLink() {
        return html`<a
            href=${this.reportUrl}
            download=${this.reportFileName}
            @click=${evt => {
                if (!this.reportUrl) {
                    evt.preventDefault();
                }
            }}
            >${downloadIcon}</a
        >`;
    }

    renderDownloadButton() {
        if (this.availableFileOutputTypes?.length === 1) {
            return this.renderSingleDownloadLink();
        }

        return html`
            <omega-context-menu>
                <div class="download-button" slot="button-contents">${downloadIcon}</div>
                ${this.availableFileOutputTypes.map(
                    item =>
                        html`<omega-context-menu-item
                            @itemClicked=${() => this.downloadReport(item.id, item.name)}
                            >${item.name?.toUpperCase()}</omega-context-menu-item
                        >`
                )}
            </omega-context-menu>
        `;
    }

    renderActiveReportTools() {
        if (!this.activeReport) return nothing;

        return html`<div class="tools">
            <omega-button
                type="icon"
                icon="trash"
                .disabled=${!this.reportUrl}
                @click=${this.deleteActiveFile}
                hideLabel="always"
            ></omega-button>
            ${this.renderDownloadButton()}
        </div>`;
    }

    renderReportContent() {
        if (!this.reportUrl) return nothing;
        return html`<iframe
            title="report-file-view"
            id="file-viewer"
            src=${this.reportUrl}
        ></iframe>`;
    }

    render() {
        if (this.loadingFile) {
            return html`<div class="loading"><omega-progress card></omega-progress></div>`;
        }

        return html`
            <div class="active-report">
                <div class="report-header">
                    <h4>${this.activeReport?.print('reportName') || 'Report Preview'}</h4>
                    ${this.renderActiveReportTools()}
                </div>
                <div class="report-content">${this.renderReportContent()}</div>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                width: 100%;
                height: 100%;
            }
            .report-header {
                display: flex;
                padding: 8px;
                box-sizing: border-box;
                min-height: 45px;
            }
            .report-header .tools {
                display: flex;
            }
            .download-options {
                display: block;
                --omega-button-padding: 0;
                --omega-button-text-align: left;
            }
            .download-button {
                color: var(--omega-primary);
            }
            omega-context-menu {
                display: flex;
                --omega-context-menu-right: 0;
                --omega-context-menu-top: 34px;
                --omega-context-menu-min-width: 120px;
            }
            .report-header a {
                color: var(--omega-primary);
            }
            .report-header a svg {
                padding: 8px 10px;
            }
            .report-header omega-button svg {
                fill: var(--omega-primary);
            }
            .active-report {
                flex: 2;
                background-color: white;
                margin-left: 5px;
                flex-direction: column;
                display: flex;
                height: 100%;
            }
            .report-header {
                display: flex;
                padding: 0 16px;
                border-bottom: 1px solid rgb(224, 224, 224);
                align-items: center;
                justify-content: space-between;
            }
            .report-header h4 {
                flex: 2;
                margin: 5px 0;
                font-size: 14px;
                color: #646464;
            }
            .report-header omega-button,
            .report-header a {
                margin: 0;
                padding: 5px;
            }
            .report-content {
                width: 100%;
                flex-grow: 2;
            }
            iframe {
                border: none;
                width: 100%;
                height: 100%;
            }
            .loading {
                display: flex;
                align-items: center;
                flex: 2;
                background: #fff;
            }
        `;
    }
}

window.customElements.define(
    'ach-account-reconciliation-report-viewer',
    AchAccountReconciliationReportViewer
);
