import { AchBank, AchBankDto, AchCompany, SecCode } from '.';
import { Frequency } from '../frequency';
import { OffsetAccount } from './offsetAccount';

export interface PaymentHeaderInputData {
    accountNumber?: string;
    accountType?: string;
    achCompany: AchCompany;
    achCompanyId: number | string;
    addenda?: string;
    addendaId?: string;
    amount?: number;
    audit: string;
    bank?: AchBankDto;
    creditAmount: number;
    debitAmount: number;
    discretionaryData: string;
    entryDescription: string;
    errors?: boolean;
    fileArchiveId?: number | null;
    frequency: Frequency;
    hold?: boolean;
    id: number;
    idNumber?: string;
    nachaFileUpload?: boolean;
    name: string;
    offsetAccount: OffsetAccount | string;
    parentId: number;
    prenote?: boolean;
    restricted: boolean;
    reversalAchPaymentId: number;
    secCode: SecCode | string;
    status?: string | null;
    step: number;
    transactionId?: string;
    transactionType?: string;
    achPaymentDraftId?: number;
    hasAccess?: boolean;
}

export class PaymentHeader {
    constructor(dto: PaymentHeaderInputData) {
        Object.assign(this, dto);
    }

    accountNumber?: string;

    accountType?: string;

    achCompany!: AchCompany;

    achCompanyId!: number | string;

    addenda?: string | null;

    addendaId?: string;

    amount?: number;

    audit!: string;

    bank?: AchBank | AchBankDto;

    creditAmount!: number;

    debitAmount!: number;

    discretionaryData!: string;

    editable?: boolean | undefined;

    entryDescription!: string;

    errors?: boolean;

    frequency!: Frequency;

    hold?: boolean;

    id!: number;

    idNumber?: string | null;

    nachaFileUpload? = false;

    name!: string;

    offsetAccount!: OffsetAccount | string;

    parentId!: number;

    prenote?: boolean;

    restricted!: boolean;

    reversalAchPaymentId!: number;

    secCode!: SecCode | string;

    status?: string | null;

    step!: number;

    transactionId?: string;

    transactionType?: string;

    testAchCompanyName?: string | undefined;

    private get isEditableStatus() {
        if (!this.status) return false;
        const editablePayments = [
            'Approval Rejected',
            'Failed',
            'Uninitiated',
            'Expired',
            'Scheduled',
        ];

        return editablePayments.indexOf(this.status) !== -1;
    }

    private get existing() {
        return !!this.id && this.id !== 0;
    }

    private get isChildPayment() {
        return !!this.parentId && this.parentId !== 0;
    }

    private get isReversal() {
        return !!this.reversalAchPaymentId && this.reversalAchPaymentId !== 0;
    }

    public get isEditable() {
        return this.existing && this.isEditableStatus && !this.isChildPayment && !this.isReversal;
    }
}
