/**
 * Create a `Promise` that resolves after a set amount of time.
 * Useful for performing an action with a delay without manually invoking `setTimeout()`.
 *
 * To maximize readability, pair with `await`.
 *
 * @example
 * const delayedValue= async value => {
 *    await delay(500);
 *    return value;
 * };
 * @param period Delay in milliseconds.
 */
export function delay(period = 0) {
    if (period < 0) {
        throw new Error('Cannot create a delay with a negative timeout value.');
    }

    return new Promise<void>(resolve => {
        const handle = setTimeout(() => {
            clearTimeout(handle);
            resolve();
        }, period);
    });
}
