/**
 * @prettier
 */

export default {
    bindings: {
        wire: '=',
        onTimeout: '&',
        isConfirmation: '=',
        isDisabled: '=',
        hideQuoteRestrictionInfo: '=',
    },
    controller: RateQuoteController,
    template: require('./rateQuote.html'),
};

RateQuoteController.$inject = ['wireService', 'timerService'];

function RateQuoteController(wireService, timerService) {
    const $ctrl = this;

    function mapDestinationCurrenciesForSelection(currencies) {
        currencies = currencies
            .map(currency => ({
                currencyLabel: `${currency.name} - ${currency.currencyCode}`,
                currencyCode: currency.currencyCode,
            }))
            .sort((a, b) =>
                a.currencyLabel < b.currencyLabel ? -1 : a.currencyLabel === b.currencyLabel ? 0 : 1
            );

        // Add USD to the beginning of dictionary per requirements.
        if ($ctrl.wire.wireConfig.dliCompanyInternationalUsdEnabled) {
            currencies.unshift({
                currencyCode: 'USD',
                currencyLabel: 'United States Dollar - USD',
            });
        }

        return currencies;
    }

    $ctrl.$onInit = function () {
        wireService.getInternationalWireCurrencies().then(response => {
            $ctrl.destinationCurrencies = mapDestinationCurrenciesForSelection(response);
        });

        if ($ctrl.wire.isAmountUSD === undefined) {
            $ctrl.wire.isAmountUSD = true;
        }
    };

    $ctrl.isGetQuoteDisabled = function () {
        window.$ctrl = $ctrl;
        return (
            $ctrl.wire.destinationCurrencyCode === 'USD' ||
            $ctrl.wire.destinationCurrencyCode === undefined ||
            !$ctrl.wire.amount ||
            $ctrl.isDisabled
        );
    };

    $ctrl.getQuote = function getQuote() {
        $ctrl.isWaitingForQuote = true;
        $ctrl.isGetQuoteCancelled = false;
        wireService.getRateQuote($ctrl.wire).then(
            response => {
                $ctrl.isWaitingForQuote = false;
                if ($ctrl.isGetQuoteCancelled) return;
                timerService.start('rateQuote', response.expirationTime);
                $ctrl.wire.rateQuote = response;
            },
            error => {
                if ($ctrl.isGetQuoteCancelled) return;
                console.error('Unable to get quote', error);
            }
        );
    };

    $ctrl.getNewQuote = function () {
        timerService.stop('rateQuote');
        $ctrl.wire.rateQuote = null;
        $ctrl.isWaitingForQuote = false;
    };

    $ctrl.cancelGetQuote = function () {
        $ctrl.isGetQuoteCancelled = true;
        $ctrl.wire.rateQuote = null;
        $ctrl.isWaitingForQuote = false;
    };

    $ctrl.isDestinationCurrencyCodeDisabled = function () {
        return $ctrl.isWaitingForQuote || $ctrl.isDisabled || $ctrl.hasNoCurrencies();
    };

    $ctrl.isWireAmountDisabled = function () {
        return $ctrl.isWaitingForQuote || $ctrl.isDisabled || $ctrl.hasNoCurrencies();
    };

    $ctrl.isCurrencyToggleDisabled = function () {
        return (
            $ctrl.isWaitingForQuote ||
            $ctrl.wire.destinationCurrencyCode === 'USD' ||
            $ctrl.wire.destinationCurrencyCode === undefined
        );
    };

    $ctrl.showRateHeader = function () {
        return $ctrl.isConfirmation;
    };

    $ctrl.showEffectiveDate = function () {
        return $ctrl.isConfirmation;
    };

    $ctrl.showTimer = function () {
        return (
            $ctrl.wire.rateQuote &&
            !$ctrl.wire.rateQuote.accepted &&
            $ctrl.wire.rateQuote.amexQuoteId &&
            !$ctrl.hideQuoteRestrictionInfo
        );
    };

    $ctrl.showNewQuoteButton = function () {
        return !$ctrl.isConfirmation && (!$ctrl.wire.rateQuote || !$ctrl.wire.rateQuote.accepted);
    };

    $ctrl.destinationCurrencyLabel = function () {
        if (
            $ctrl.wire.destinationCurrencyCode !== undefined &&
            $ctrl.wire.destinationCurrencyCode !== 'USD'
        ) {
            return $ctrl.wire.destinationCurrencyCode;
        }
        return 'destination currency';
    };

    $ctrl.isQuoteApplicable = function () {
        return !$ctrl.isQuoteNotApplicable();
    };

    $ctrl.isQuoteNotApplicable = function () {
        return $ctrl.wire.destinationCurrencyCode === 'USD';
    };

    $ctrl.hasOnlyUsdCurrency = function () {
        if (!$ctrl.destinationCurrencies) return false;
        if (
            $ctrl.destinationCurrencies.length === 1 &&
            $ctrl.destinationCurrencies[0].currencyCode === 'USD'
        ) {
            $ctrl.wire.destinationCurrencyCode = 'USD';
            return true;
        }
        return false;
    };

    $ctrl.hasNoCurrencies = function () {
        return !$ctrl.destinationCurrencies || $ctrl.destinationCurrencies.length === 0;
    };
}
