PagePushService.$inject = ['$window'];

export default function PagePushService($window) {
    let user = null;
    return {
        getUser() {
            if (!user) {
                user = JSON.parse($window.sessionStorage.getItem('user'));
            }
            return user;
        },
        setUser(newValue) {
            if (newValue) {
                $window.sessionStorage.setItem('user', JSON.stringify(newValue));
            }
            user = newValue;
        },
    };
}
