/**
 * @namespace nachaAlphanumeric
 */
const nachaAlphanumeric = {
    name: 'can only contain NACHA alpha-numeric and special characters',
    /**
     * @memberof nachaAlphanumeric
     * @method validate
     * @param {*} modelValue
     * @param {*} viewValue
     * @returns true if string only contains ASCII greater than 1F (punctuation and whitespace are allowed)
     */
    validate(modelValue, viewValue /* , record, options */) {
        if (!modelValue) {
            return true;
        }
        return /^[\x20-\x7F]*$/.test(modelValue);
    },
};

export default nachaAlphanumeric;
