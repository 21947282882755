/* eslint-disable import/no-extraneous-dependencies */
import { css, html, LitElement } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';

class OmegaFileUpload extends LitElement {
    static get properties() {
        return {
            files: Array,
            filePreviews: Array,
            label: String,
            buttonText: String,
            buttonIcon: String,
            disabled: Boolean,
            icon: Boolean,
            multiple: { type: Boolean, reflect: true },
            max: Number,
            validationMessage: String,
            accepted: Array,
        };
    }

    constructor() {
        super();
        this.max = 4;
        this.files = [];
        this.label = `omega-file-upload-${this.max}MB`;
        this.disabled = false;
        this.multiple = false;
        this._id = '';
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-file-upload--default',
        };
    }

    get id() {
        return (
            this._id ||
            this.label.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
        );
    }

    set id(value) {
        this._id = value;
    }

    get preview() {
        return this.shadowRoot.querySelector('.omega-file-upload__preview');
    }

    get fileNames() {
        return this.files?.map(file => file.name);
    }

    _hasInvalidFileSizes(files) {
        return files.some(file => file.size >= this.max * 1000000);
    }

    _addImagePreview(file) {
        const previewImg = document.createElement('img');
        previewImg.src = URL.createObjectURL(file);
        previewImg.alt = file.name;
        this.preview.appendChild(previewImg);
    }

    _fileUpload(e) {
        this.files = [...e.target.files];
        e.target.value = '';
        if (this._hasInvalidFileSizes(this.files)) {
            this.dispatchEvent(
                new CustomEvent('invalidFileSize', { bubbles: true, composed: true })
            );
            return;
        }

        this.preview.innerHTML = null;
        this.filePreviews = this.files.map(file => {
            if (file.type.includes('image')) {
                this._addImagePreview(file);
            }
            return '';
        });

        this.dispatchEvent(
            new CustomEvent('filesUploaded', {
                bubbles: true,
                composed: true,
                detail: { files: this.files },
            })
        );
    }

    renderLabel() {
        if (this.buttonText) {
            this.labelText = this.buttonText;
        } else {
            this.labelText = this.multiple ? 'Select Files' : 'Select A File';
        }
        if (this.buttonIcon) {
            this.labelIcon = html`<i class="fa fa-${this.buttonIcon}"></i>`;
        } else {
            this.labelIcon = this.icon ? html`<i class="fa fa-upload"></i>` : null;
        }
        return html`${this.labelText} ${this.labelIcon}`;
    }

    renderValidationMessage() {
        /* ? not sure how we check specific things here like the # of payments uploaded */
        if (this.validationMessage) {
            return html`<small class="omega-file-upload-hint">${this.validationMessage}</small>`;
        }
        return html`<small class="omega-file-upload-hint"
            >Maximum file size of ${this.max}MB</small
        >`;
    }

    render() {
        const accepted =
            Array.isArray(this.accepted) && this.accepted.length > 0
                ? this.accepted.join(',')
                : '*';
        return html`
            <div class="omega-file-upload-wrapper">
                <input
                    type="file"
                    id=${this.id}
                    ?disabled=${this.disabled}
                    ?multiple=${this.multiple}
                    .accept=${accepted}
                    @change=${this._fileUpload}
                />
                <label for=${this.id} class="omega-file-upload-trigger"
                    >${this.renderLabel()}</label
                >
                ${this.renderValidationMessage()}
                <p class="omega-file-upload__return">${this.fileNames}</p>
                <div class="omega-file-upload__preview"></div>
            </div>
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                .omega-file-upload-wrapper {
                    position: relative;
                    width: 100%;
                    text-align: center;
                }
                label {
                    display: block;
                    padding: 10px 40px;
                    background: var(--omega-primary);
                    border: 1px solid var(--omega-primary-darken-100);
                    color: var(--omega-white);
                    transition: all 0.25s;
                    cursor: pointer;
                    font-size: var(--omega-label);
                    border-radius: 3px;
                    text-align: center;
                }
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 225px;
                    opacity: 0;
                    cursor: pointer;
                }
                input[disabled] + label {
                    cursor: not-allowed;
                    opacity: 0.8;
                }
                input:hover + label,
                input:focus + label,
                label:hover,
                label:focus {
                    background: var(--omega-primary-darken-100);
                    box-shadow: var(--omega-primary-shadow);
                }
                img {
                    max-width: 250px;
                }
                .omega-file-upload-hint {
                    font-style: italic;
                    font-size: var(--omega-hint);
                    color: var(--omega-text-secondary);
                }
                .omega-file-upload__return {
                    margin: 0;
                }
                .omega-file-upload__return:not(:empty) {
                    margin: 1em 0;
                }
                .omega-file-upload__return {
                    font-size: var(--omega-p);
                    font-weight: var(--omega-medium);
                }
                .omega-file-upload__return:not(:empty):before {
                    display: block;
                    content: 'Selected file(s): ';
                    font-style: normal;
                    font-weight: var(--omega-regular);
                }
            `,
        ];
    }
}

customElements.define('omega-file-upload', OmegaFileUpload);
export default OmegaFileUpload;
