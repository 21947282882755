import { TmHttpClient } from '@treasury/core/http';
import { Nullable } from '@treasury/utils/types';
import { DateFormatters } from '../../../shared/utilities';
import { IIssuedItem } from '../../mappings/arp';
import { IssuedItemDto } from '../../types/arp';

interface IssuedItemsVoidsQueryDto {
    accountId: number;
    issuedAmount: number;
    checkNumber: string;
    checkDate: string;
    issuedItemCreatedDate: string;
    payeeName: string;
}

interface IssuedItemsVoidsDto {
    issuedItems: IssuedItemDto[];
    /**
     * Whether or not the server has more matches beyond `maxMatches` specified.
     */
    hasMore: boolean;
}

/**
 * Response shape to a query for all voids given a collection of issued items.
 *
 * The collection is accessed using stringified numeric keys, but it is *not* an array.
 * This is serialized as a dictionary whose string keys contain numeric values (e.g., `'3'`).
 * The value is meant to represent the table row number used in the UI making the response,
 * but this key isn't very useful to us as a unique identifier so it is largely ignored
 * in favor of making the association in other ways.
 */
type IssuedItemsVoidsCollection = { [indexId: string]: IssuedItemsVoidsDto };
/**
 * Request body shape associated with the
 * `issuedItems/searchIssuedItemsVoids` [endpoint](https://docs.banno.com/digital-is-api/treasury-api/channel-api-reference/issueditems/details/#/IssuedItems/SearchIssuedItemsVoids).
 */
interface IssuedItemsVoidsQueryRequest {
    maxMatches: number;
    issuedItemsVoids: { [index: number]: Nullable<IssuedItemsVoidsQueryDto> };
}

interface IssuedItemsVoidsResponseBase {
    success: boolean;
    searchIssuedItemsVoids: IssuedItemsVoidsCollection | null;
}

interface IssuedItemsVoidsResponseSuccess extends IssuedItemsVoidsResponseBase {
    success: true;
    searchIssuedItemsVoids: IssuedItemsVoidsCollection;
}

interface IssuedItemsVoidsResponseError extends IssuedItemsVoidsResponseBase {
    success: false;
    searchIssuedItemsVoids: null;
    baseRequest: Record<string, unknown>;
    responseDetailCollection: [
        {
            responseCode: number;
            responseMessage: string;
        }
    ];
}

type IssuedItemsVoidsResponse = IssuedItemsVoidsResponseSuccess | IssuedItemsVoidsResponseError;

/**
 * Given a list of pending, partial voids, search Silverlake for any existing checks that
 * might match the provided criteria.
 */
export async function searchIssuedItemsVoids(body: IssuedItemsVoidsQueryRequest) {
    const http = await TmHttpClient.getInstance();
    return http.request<IssuedItemsVoidsResponse>('issuedItems/searchIssuedItemsVoids', {
        method: 'POST',
        body,
    });
}

/**
 * Transforms an {@link IIssuedItem } instance to an {@link IssuedItemsVoidsQueryDto }.
 */
export function mapIssuedItemToVoidQuery(item: IIssuedItem): Nullable<IssuedItemsVoidsQueryDto> {
    const { account, checkAmount, checkNumber, dateIssued, createdDate, payee } = item;

    return {
        checkNumber: checkNumber ?? null,
        accountId: account ? account.id : null,
        issuedAmount: checkAmount,
        checkDate: dateIssued ? DateFormatters.apiFormattedDate(dateIssued) : null,
        issuedItemCreatedDate: createdDate ? DateFormatters.apiFormattedDate(createdDate) : null,
        payeeName: payee,
    };
}
