import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-field.js';
import { mix } from 'mixwith';
import { ListeningElementMixin } from '@treasury/omega/components';

class AchInternationalPaymentHeader extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            paymentHeaderRecord: Object,
            recipientsRecordset: Object,
            fields: Array,
            activeStep: Number,
            template: Boolean,
            expanded: { type: Boolean, reflect: true },
        };
    }

    constructor() {
        super();
        this.loading = false;
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.recipientsRecordset, 'page-changed', () => this.requestUpdate());
    }

    createRecipientsLabel() {
        if (!this.recipientsRecordset) return nothing;
        const totalRecipients = this.recipientsRecordset.totalCount;
        if (totalRecipients > 1) return `${totalRecipients} Recipients`;
        return `${totalRecipients} Recipient`;
    }

    renderField(field) {
        return html`<omega-field
            .field=${field.field}
            .record=${this.paymentHeaderRecord}
        ></omega-field>`;
    }

    renderStatus() {
        return this.activeStep === 2
            ? html`<omega-field
                  field="status"
                  label="Status"
                  .record=${this.paymentHeaderRecord}
              ></omega-field>`
            : nothing;
    }

    renderId() {
        return this.activeStep === 2 && !this.template
            ? html` <omega-field
                  field="transactionId"
                  label="Transaction ID"
                  .record=${this.paymentHeaderRecord}
              ></omega-field>`
            : nothing;
    }

    renderAudit() {
        return this.activeStep === 2
            ? html` <div class="form-column">
                  <omega-field field="audit" .record=${this.paymentHeaderRecord}></omega-field>
              </div>`
            : nothing;
    }

    renderFrequencyAndOffsetAccount() {
        return this.template
            ? nothing
            : html`<omega-field field="frequency" .record=${this.paymentHeaderRecord}></omega-field>
                  <omega-field
                      field="offsetAccount"
                      .record=${this.paymentHeaderRecord}
                  ></omega-field>`;
    }

    renderOffsetAccount() {
        return this.template
            ? nothing
            : html`<omega-field
                  field="frequency"
                  .record=${this.paymentHeaderRecord}
              ></omega-field>`;
    }

    renderPaymentHeaderFields() {
        return html` <div class="omega-flex-form">
            <div class="form-column">
                ${this.renderStatus()} ${this.renderId()}
                <omega-field field="achCompany" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field field="achCompanyId" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field
                    field="entryDescription"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field
                    field="discretionaryData"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field field="restricted" .record=${this.paymentHeaderRecord}></omega-field>
            </div>
            <div class="form-column">
                <omega-field
                    field="streetAddress"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field field="city" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field field="state" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field field="zipCode" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field field="country" .record=${this.paymentHeaderRecord}></omega-field>
            </div>
            <div class="form-column">
                <omega-field
                    field="destinationCountryCode"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field
                    field="foreignExchangeIndicator"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field
                    field="foreignExchangeReferenceNumber"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field
                    field="foreignExchangeReference"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                <omega-field
                    field="destinationCurrencyCode"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
            </div>
            <div class="form-column">
                <omega-field field="debitAmount" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field field="creditAmount" .record=${this.paymentHeaderRecord}></omega-field>
                <omega-field
                    field="originatorCurrencyCode"
                    .record=${this.paymentHeaderRecord}
                ></omega-field>
                ${this.renderFrequencyAndOffsetAccount()}
            </div>
            ${this.renderAudit()}
        </div>`;
    }

    render() {
        if (!this.paymentHeaderRecord) return nothing;
        return html` <omega-accordion .expanded=${this.expanded}>
            <span slot="header"
                >${this.paymentHeaderRecord.getField('name')}
                <small>${this.createRecipientsLabel()}</small></span
            >
            <div slot="content">${this.renderPaymentHeaderFields()}</div>
        </omega-accordion>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-flex-form {
                display: flex;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }
            .omega-flex-form omega-field {
                margin: 10px;
                min-height: 32px;
            }
            small {
                font-size: 14px;
                font-weight: 400;
                margin-left: 5px;
            }
        `;
    }
}

customElements.define('ach-international-payment-header', AchInternationalPaymentHeader);
