export const hasValidAmount = (amountRange: {
    rangeType: string;
    floorAmount?: number;
    ceilAmount?: number;
    specificAmount?: number;
}) => {
    if (!amountRange) {
        return false;
    }
    const { rangeType, floorAmount, ceilAmount, specificAmount } = amountRange;
    if (rangeType === 'Specific Amount') {
        return !!specificAmount;
    }
    if (rangeType === 'Range') {
        return !!floorAmount || !!ceilAmount;
    }
    return false;
};

export const mapAchExceptionFiltersToRequest = (filters: any) => ({
    ...filters,
    secCodes: filters.secCodes?.map((s: any) => ({
        code: s.secCode,
        description: s.description,
    })),
    postedDate: filters.postedDate?.startDate,
    postedDateStart: filters.postedDate?.startDate,
    postedDateEnd: filters.postedDate?.endDate,
    postedDateType: filters.postedDate?.type,
    amountType: hasValidAmount(filters.amountRange) ? filters.amountRange?.rangeType : null,
    amountStart: filters.amountRange?.floorAmount,
    amountEnd: filters.amountRange?.ceilAmount,
    amount: filters.amountRange?.specificAmount,
    amountRange: null,
    decisionTaken: 'both',
    searchType: 'OpenItems',
    type: 'OpenItems',
    page: 'AchExceptionsList',
});
