ChangeTransferController.$inject = [
    '$scope',
    '$modalInstance',
    'headerText',
    'message',
    'actionButtonText',
    'actionButtonClass',
    'closeButtonText',
];

export default function ChangeTransferController(
    $scope,
    $modalInstance,
    headerText,
    message,
    actionButtonText,
    actionButtonClass,
    closeButtonText
) {
    $scope.headerText = headerText;
    $scope.message = message;
    $scope.actionButtonText = actionButtonText;
    $scope.actionButtonClass = actionButtonClass;
    $scope.closeButtonText = closeButtonText;
    $scope.comments = '';

    // this will dismiss the window.
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    // this will update the company
    $scope.save = function () {
        $modalInstance.close($scope.comments);
    };

    // used to set the form instance.
    $scope.setForm = function (form) {
        $scope.form = form;
    };
}
