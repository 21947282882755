import OmegaDialog from '@treasury/omega/components/omega-dialog.js';
import { css, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';

class ReturnReasonCommentDialog extends OmegaDialog {
    @property()
    public comment?: string;

    renderHeader() {
        return html`
            <div class="omega-dialog-header">
                <span class="omega-dialog-header-title">Return Reason Comment</span>
                <span class="omega-dialog-header-actions">
                    <omega-button
                        class="omega-dialog-menu-button close-button"
                        type="icon"
                        icon="times"
                        hideLabel="always"
                        @click=${this.toggle}
                    >
                        Close
                    </omega-button>
                </span>
            </div>
        `;
    }

    renderBody() {
        if (!this.comment) return nothing;
        return html`<div class="dialog-content">${this.comment}</div>`;
    }

    static get styles() {
        return [
            ...OmegaDialog.styles,
            css`
                .omega-dialog-header {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: var(--omega-help-border);
                }
                .omega-dialog-header-title {
                    padding: 0 15px;
                }
                .dialog-content {
                    padding: 15px 10px 10px 20px;
                }
            `,
        ];
    }
}
window.customElements.define('return-reason-comment-dialog', ReturnReasonCommentDialog);
export default ReturnReasonCommentDialog;
