/* eslint-disable no-use-before-define */

import { DiContainer, InjectionToken } from '@jack-henry/frontend-utils/di';
import { AnalyticsEvent, AnalyticsService, NullAnalyticsService } from '@treasury/core/analytics';
import { ConfigurationService, Environment } from '@treasury/core/config';
import { TmHttpClient, TmHttpClientCached } from '@treasury/core/http';
import { LoggingService } from '@treasury/core/logging';
import { LegacyNavigationService, NavigationService } from '@treasury/core/navigation';
import { NullRealtimeService, RealtimeService } from '@treasury/core/real-time';
import {
    AccountService,
    ChannelAuthenticationService,
} from '@treasury/domain/channel/services/account';
import { AuthFlowEvent, AuthenticationService } from '@treasury/domain/services/authentication';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import {
    LogoutManager,
    OpenIdleDialogToken,
    OpenTimeAccessDialogToken,
} from '@treasury/domain/services/logout';
import { OpenIdentityDialogToken, TmHttpClientMfa } from '@treasury/domain/shared';
import { openIdentityDialog } from '@treasury/omega/domain-components/identity-verification-dialog';
import { openIdleDialog } from '@treasury/omega/domain-components/idle-logout-dialog';
import { openTimeAccessDialog } from '@treasury/omega/domain-components/time-access-logout-dialog';
import { OmegaDialogService } from '@treasury/omega/services/omega-dialog';
import { SessionStorageService, WindowService } from '@treasury/utils/services';

export const channelDiModule = angular
    .module('channel.di', [])
    .constant('TmDi', new DiContainer())
    .config([
        'TmDi',
        function (di: DiContainer) {
            initDi(di);
        },
    ])
    .factory('tmNavService', ['TmDi', (di: DiContainer) => di.get(NavigationService)]);

async function initDi(di: DiContainer) {
    di.provide(OpenIdentityDialogToken as unknown as InjectionToken<unknown>, openIdentityDialog);
    di.provide(OpenIdleDialogToken as unknown as InjectionToken<unknown>, openIdleDialog);
    di.provide(
        OpenTimeAccessDialogToken as unknown as InjectionToken<unknown>,
        openTimeAccessDialog
    );
    di.provide(TmHttpClient, TmHttpClientMfa);
    di.provide(NavigationService, LegacyNavigationService);
    di.provideFactoryAsync(
        AnalyticsService,
        [ConfigurationService, FeatureFlagService, AuthenticationService, AccountService] as const,
        async (config, ffService, auth, accountService) => {
            await auth.authenticated$.toPromise();
            await ffService.ready$.toPromise();

            try {
                const analyticsEnabled = await ffService.isEnabled(Feature.Analytics);
                const analyticsService = analyticsEnabled
                    ? new AnalyticsService(config)
                    : NullAnalyticsService;

                const user = await accountService.getCurrentUser();

                analyticsService.init();
                analyticsService.identify(user);

                return analyticsService;
            } catch (e) {
                console.error('Failed to instantiate AnalyticsService');
                throw e;
            }
        },
        NullAnalyticsService
    );

    di.provideFactoryAsync(
        RealtimeService,
        [
            LoggingService,
            ConfigurationService,
            WindowService,
            AuthenticationService,
            FeatureFlagService,
        ] as const,
        async (log, config, window, auth, ffService) => {
            await auth.authenticated$.toPromise();
            await ffService.ready$.toPromise();

            const { environment } = config;
            const realtimeEnabled =
                environment !== Environment.Local && environment !== Environment.Unknown;
            const realtimeService = realtimeEnabled
                ? new RealtimeService(config, window)
                : NullRealtimeService;

            try {
                realtimeService.connected$.subscribe(isConnected => {
                    const message = `SignalR connection ${isConnected ? 'established' : 'lost'}.`;
                    log.logInfo(message)
                });
                realtimeService.init('pushNotificationHub', environment !== Environment.Production);
            } catch (e) {
                log.logError('Failed connecting to SignalR', e as Error);
            }

            return realtimeService;
        },
        NullRealtimeService
    );

    di.provideFactoryAsync(
        FeatureFlagService,
        [
            TmHttpClient,
            SessionStorageService,
            ConfigurationService,
            AuthenticationService,
            AccountService,
        ] as const,
        async (http, storage, config, auth, accountService) => {
            await auth.authenticated$.toPromise();

            const { institutionId } = config;
            const ffService = new FeatureFlagService(http, storage, config);

            await initFfService(ffService, accountService, institutionId);

            return ffService;
        }
    );

    di.provideFactory(LoggingService, [
        WindowService, 
        ConfigurationService
    ], 
    (window, config) => {
        const logger = new LoggingService(config, window);
        logger.init();

        // set debug state out-of-band so service can be consumed synchronously
        di.getAsync(FeatureFlagService)
            .then(ffService =>  ffService.isEnabled(Feature.DebugMode))
            .then(isDebugEnabled => {
                logger.isDebugEnabled = isDebugEnabled;
            });

        return logger;
    });

    await DiContainer.init(di);

    // TODO: consolidate auth service usage
    const channelAuthService = di.get(ChannelAuthenticationService);
    const authService = di.get(AuthenticationService);
    const http = di.get(TmHttpClient);
    const logoutManager = di.get(LogoutManager);
    const accountService = di.get(AccountService);
    const navService = di.get(NavigationService);
    const loggingService = di.get(LoggingService);

    channelAuthService.authEvent$.subscribe(event => {
        switch (event) {
            case AuthFlowEvent.TermsAndConditionsAccept:
                navService.navigate('terms-and-conditions-accept');
                break;
            case AuthFlowEvent.Done:
                navService.navigate('dashboard');
                break;
            default:
                break;
        }
    });

    navService.routeChange$.subscribe(({ route }) => {
        // always fetch service fresh in case the FF has changed the instance
        di.getAsync(AnalyticsService).then(analyticsService => {
            analyticsService.trackPageView(route);
        });
    });

    authService.authenticated$.subscribe(() => {
        const config = di.get(ConfigurationService);
        onAuthenticated(logoutManager, accountService, loggingService, config);
    });
    http.sessionExpired$.subscribe(() => onSessionEnd(di));
    http.error$.subscribe(err => {
        loggingService.logError('Fetch error', err);
    });
    logoutManager.logout$.subscribe(() => onSessionEnd(di));
}

async function onAuthenticated(
    logoutManager: LogoutManager,
    accountService: AccountService,
    loggingService: LoggingService,
    config: ConfigurationService
) {
    // certain endpoint calls must be deferred until after login
    logoutManager.startLogoutTimers();

    const user = await accountService.getCurrentUser();
    loggingService.setUserSessionContext(user);

    const di = await DiContainer.getInstance();
    di.getAsync(AnalyticsService).then(analytics => {
        analytics.track(AnalyticsEvent.LogIn);
    });

    const ffService = await di.getAsync(FeatureFlagService);
    initFfService(ffService, accountService, config.institutionId);
}

function onSessionEnd(di: DiContainer) {
    const deps = [
        di.get(OmegaDialogService),
        di.get(NavigationService),
        di.get(AnalyticsService),
        di.get(RealtimeService),
        di.get(SessionStorageService),
        di.get(TmHttpClient),
    ] as const;

    return endSession(...deps);
}

async function endSession(
    dialogService: OmegaDialogService,
    navService: NavigationService,
    analyticsService: AnalyticsService,
    realtimeService: RealtimeService,
    sessionStorageService: SessionStorageService,
    httpClient: TmHttpClient
) {
    realtimeService.disconnect();
    analyticsService.track(AnalyticsEvent.LogOut);
    analyticsService.reset();
    dialogService.closeAll();
    sessionStorageService.clear();

    if (httpClient instanceof TmHttpClientCached) {
        httpClient.clearCache();
    }

    const di = await DiContainer.getInstance();
    const ffService = await di.getAsync(FeatureFlagService);
    ffService.reset();

    navService.navigate('login').then(() => {
        // TODO: remove this once the deep link login issue has been resolved;
        // this is a temporary work around to clear in-memory state from a prior user session
        window.location.reload();
    });
}

async function initFfService(
    ffService: FeatureFlagService,
    accountService: AccountService,
    institutionId: string
) {
    const { loginId, isAdmin, isSuperUser, companyId } = await accountService.getCurrentUser();

    await ffService.init(institutionId, {
        userId: loginId,
        companyId,
        isAdmin,
        isSuperUser,
    });
}
