import { HttpVerb } from '@treasury/utils';

export type TmHttpDefaultBody = object;

export enum TmHttpResponseType {
    /**
     * Deserializes the response JSON. Default response type.
     */
    Json,
    /**
     * Deserializes the response as plaintext.
     */
    Text,
    /**
     * If specified, Omega fetch will present the response as an `ArrayBuffer`.
     */
    ArrayBuffer,
    /**
     * If specified, Omega fetch will present the response as a `Blob`.
     */
    Blob,
    /**
     * If specified, Omega fetch will return the raw `Response` object.
     */
    Raw,
}

/**
 * Subset of relevant properties from the browser `fetch()` specification configuration shape.
 */
type RequestInitSubset = Pick<RequestInit, 'headers'>;
export type TmHttpConfigBase<Body extends object = TmHttpDefaultBody> = RequestInitSubset & {
    body?: Body;
    method?: HttpVerb;
    ttl?: number;
    disableContentType?: boolean;
    maxAgeInSeconds?: number;
    responseType?: TmHttpResponseType;
    /**
     * If `true`, performs the request without the configured base URL (if any).
     */
    withoutBase?: boolean;
};

interface TmHttpConfigNoBody extends TmHttpConfigBase<never> {
    method: 'GET';
}

export interface TmHttpConfigWithBody<Body extends object = TmHttpDefaultBody>
    extends TmHttpConfigBase<Body> {
    method: 'POST' | 'PUT' | 'DELETE';
}

interface ConfigArrayBuffer extends TmHttpConfigNoBody {
    responseType: TmHttpResponseType.ArrayBuffer;
}

interface ConfigArrayBufferWithBody<Body extends object> extends TmHttpConfigWithBody<Body> {
    responseType: TmHttpResponseType.ArrayBuffer;
}
export type TmHttpConfigArrayBuffer<Body extends object = TmHttpDefaultBody> =
    | ConfigArrayBuffer
    | ConfigArrayBufferWithBody<Body>;

interface ConfigBlob extends TmHttpConfigNoBody {
    responseType: TmHttpResponseType.Blob;
}

interface ConfigBlobWithBody<Body extends object> extends TmHttpConfigWithBody<Body> {
    responseType: TmHttpResponseType.Blob;
}
export type TmHttpConfigBlob<Body extends object = TmHttpDefaultBody> =
    | ConfigBlob
    | ConfigBlobWithBody<Body>;

interface ConfigText extends TmHttpConfigNoBody {
    responseType: TmHttpResponseType.Text;
}

interface ConfigTextWithBody<Body extends object> extends TmHttpConfigWithBody<Body> {
    responseType: TmHttpResponseType.Text;
}

export type TmHttpConfigText<Body extends object = TmHttpDefaultBody> =
    | ConfigText
    | ConfigTextWithBody<Body>;

interface ConfigRaw extends TmHttpConfigNoBody {
    responseType: TmHttpResponseType.Raw;
}

interface ConfigRawWithBody<Body extends object> extends TmHttpConfigWithBody<Body> {
    responseType: TmHttpResponseType.Raw;
}

export type TmHttpConfigRaw<Body extends object = TmHttpDefaultBody> =
    | ConfigRaw
    | ConfigRawWithBody<Body>;

export type TmHttpConfig<Body extends object = TmHttpDefaultBody> =
    | TmHttpConfigNoBody
    | TmHttpConfigWithBody<Body>
    | TmHttpConfigArrayBuffer<Body>
    | TmHttpConfigBlob<Body>
    | TmHttpConfigText<Body>
    | TmHttpConfigRaw<Body>;
