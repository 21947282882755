import EntitlementsClient from '@treasury/domain/channel/services/entitlements/entitlements-service';

EntitlementsService.$inject = [];

// eslint-disable-next-line @treasury/filename-match-export
export default function EntitlementsService() {
    let _entitlements = null;
    return {
        reload,
        getEntitlements,
        hasEntitlement,
    };

    function reload() {
        EntitlementsClient.reset();
        getEntitlements();
    }

    async function getEntitlements() {
        _entitlements = await EntitlementsClient.getEntitlements();
    }

    function hasEntitlement(key) {
        return _entitlements.some(entitlement => key === entitlement.permission);
    }
}
