import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { LogoutManager } from '@treasury/domain/services/logout';
import { openDialog } from '@treasury/omega';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TermsAndConditionsClient } from './terms-and-conditions-client';
import './terms-and-conditions.js';

@customElement('terms-and-conditions-accept')
export class TermsAndConditionsAccept extends LitElement {
    @property({ type: String })
    public agreeText: string = `I agree`;

    @property({ type: Boolean })
    public loading = true;

    @property({ type: Object })
    public termsAndConditions = {
        agrees: false,
    };

    @InjectProperty()
    private declare readonly logoutManager: LogoutManager;

    @InjectProperty({
        token: FeatureFlagService,
        async: true,
    })
    private declare readonly ffServicePromise: Promise<FeatureFlagService>;

    private readonly client = new TermsAndConditionsClient();

    private institution!: string;

    private isUisEnabled: boolean = false;

    public async connectedCallback() {
        super.connectedCallback();
        this.isUisEnabled = await (await this.ffServicePromise).isEnabled(Feature.UisEnabled);
        const result = await this.client.getTermsAndConditions();
        if (result && result.text) {
            this.termsAndConditions = result;
            // UX rule:
            // Regardless of the current state,
            // any time the user is sent to this view, they must Agree again to proceed.
            this.termsAndConditions.agrees = false;
            this.loading = false;
        } else {
            // Make sure that the user can get to the dashboard if there are no terms and conditions.
            window.location.href = `${this.institution}/dashboard`;
        }
    }

    private async handleAcceptClick() {
        await this.client.acceptTermsAndConditions();
        // TODO: Handle save error from the API?
        window.location.href = `${this.institution}/dashboard`;
    }

    private async handleCancelClick() {
        const response = await openDialog(document.createElement('omega-dialog'), {
            title: 'Confirm Cancel',
            content: html`<div style="margin: 20px">
                <p>
                    If you do not accept the Terms and Conditions, <br />
                    you will not be able to proceed and <br />
                    your session will be terminated.
                </p>
            </div>`,
            buttons: [
                { type: 'primary', label: `OK`, result: 'ok' },
                { type: 'secondary', label: `Return`, result: 'cancel' },
            ],
        });

        if (response === 'ok') {
            this.client.declineTermsAndConditions();
            await this.logoutManager.logOut('Forced');
            const action = this.isUisEnabled ? 'logout' : 'login';
            window.location.href = `${this.institution}/${action}`;
        }
    }

    private handleAgreeClick() {
        this.termsAndConditions = {
            ...this.termsAndConditions,
            agrees: !this.termsAndConditions.agrees,
        };
    }

    private isAccepted() {
        return this.termsAndConditions.agrees;
    }

    public render() {
        if (this.loading) {
            return ``;
        }

        return html`<form class="view">
            <div>
                <terms-and-conditions
                    .termsAndConditions="${this.termsAndConditions}"
                ></terms-and-conditions>
            </div>
            <div class="agreement-block">
                <omega-checkbox
                    class="agreement-control"
                    label="${this.agreeText}"
                    ?checked="${this.isAccepted()}"
                    @toggle="${this.handleAgreeClick}"
                ></omega-checkbox>
                <omega-button-bar>
                    <omega-button
                        type="approve"
                        @click="${this.handleAcceptClick}"
                        ?disabled="${!this.isAccepted()}"
                        >Accept
                    </omega-button>
                    <omega-button type="secondary" @click="${this.handleCancelClick}"
                        >Cancel
                    </omega-button>
                </omega-button-bar>
            </div>
        </form>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .view {
                padding: 15px;
                background-color: var(--omega-white);
            }
            .agreement-block {
                padding-left: 24px;
            }
            .agreement-control {
                margin: 8px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        `;
    }
}
