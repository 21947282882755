/* eslint-disable no-use-before-define */
import { IssuedItemsQueryDto, itemStatuses } from '@treasury/domain/channel/types/arp';
import { OmegaDate } from '@treasury/omega/components/datepicker';
import { OmegaNumericValue } from '@treasury/omega/types';
import { extractDates, extractValues } from '@treasury/omega/util';
import { coerceEmptyStringsToNull, coerceMembersToNull, exists } from '@treasury/utils/functions';
import { Nullable } from '@treasury/utils/types';
import { AccountListItem } from './account-list-item.model';

interface AllSearchParams {
    type: NonNullable<Exclude<IssuedItemsQueryDto['itemType'], 'both'>>[];
    itemEntryType: NonNullable<IssuedItemsQueryDto['entryTypes']>;
    createdDate: OmegaDate;
    dateIssued: OmegaDate;
    voidedDate: OmegaDate;
    checkAmount: OmegaNumericValue;
    itemStatus: IssuedItemsQueryDto['statusNames'];
    payee: IssuedItemsQueryDto['payee'];
    checkNumber: IssuedItemsQueryDto['checkNumber'];
    accountName: AccountListItem['value'][];
}

/**
 * Component view model of search parameters generated by the filter.
 */
export type IssuedItemsSearchParams = Nullable<AllSearchParams>;

/**
 * Maps the view model filter parameters to the endpoint shape the API expects.
 */
export function mapIssuedItemsSearchParams(params: IssuedItemsSearchParams): IssuedItemsQueryDto {
    const {
        itemStatus,
        payee,
        checkAmount,
        checkNumber,
        dateIssued,
        createdDate,
        voidedDate,
        type,
        itemEntryType,
        accountName,
    } = coerceEmptyStringsToNull(coerceMembersToNull(params));
    const { start: amountStart, end: amountEnd } = extractValues(checkAmount);
    const { start: issuedDateStart, end: issuedDateEnd } = extractDates(coerceString(dateIssued));
    const { start: createdDateStart, end: createdDateEnd } = extractDates(
        coerceString(createdDate)
    );
    const { start: voidDateStart, end: voidDateEnd } = extractDates(coerceString(voidedDate));

    return {
        accounts: accountName,
        statusNames:
            // treat all selected as options as empty array so that the API returns empty/none statuses
            Array.isArray(itemStatus) && itemStatus.length === itemStatuses.length
                ? []
                : itemStatus,
        payee,
        checkNumber,
        amountStart,
        amountEnd,
        issuedDateStart,
        issuedDateEnd,
        createdDateStart,
        createdDateEnd,
        voidDateStart,
        voidDateEnd,
        itemType: coerceDualOptions(type),
        entryTypes: itemEntryType,
    };
}

/**
 * Coerces a list of two options into a single value.
 *
 * @param options A list containing 0, 1, or 2 string options
 * @returns The selected option or `'both'` if all are specified.
 */
function coerceDualOptions<T extends string>(options: T[] | null | undefined) {
    if (!exists(options) || options.length === 0) {
        return null;
    }

    if (options.length > 2) {
        throw new Error(`Invalid option list ${JSON.stringify(options)}`);
    }

    return options.length === 2 ? 'both' : options[0];
}

function coerceString<T>(value: T | string) {
    if (typeof value === 'string') {
        return null;
    }

    return value;
}
