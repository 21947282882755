import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { SessionStorageService } from '@treasury/utils';
import { html } from 'lit';
import '../../../src/dashboard/widgets/positive-pay/positive-pay-widget.js';

const {
    FeatureFlagService,
} = require('@treasury/domain/services/feature-flags/feature-flag.service.ts');
const { Feature } = require('@treasury/domain/services/feature-flags/feature-flag.types.ts');

DashboardContainerController.$inject = [
    '$scope',
    '$element',
    '$timeout',
    '$modal',
    'dashboardService',
    'achBankService',
    'bankService',
    '$filter',
    'entitlementsService',
    'dashboardUserInteractions',
    '$q',
    'TmDi',
];

/**
 *
 * @param {* ng.IScope } $scope
 * @param { ng.ITimeoutService } $timeout
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
// eslint-disable-next-line @treasury/filename-match-export
export default function DashboardContainerController(
    $scope,
    $element,
    $timeout,
    $modal,
    dashboardService,
    achBankService,
    bankService,
    $filter,
    entitlementsService,
    dashboardUserInteractions,
    $q,
    TmDi
) {
    $scope.fullScreenWidget = null;
    $scope.gridsterController = null;
    $scope.gridsterOptions = null;
    $scope.inEditMode = false;
    $scope.widgetList = null;
    $scope.removedWidgets = [];
    $scope.widgetListBackup = [];

    $scope.$on('gridster-mobile-changed', onGridsterMobileChanged);

    $scope.cancel = cancel;
    $scope.flipWidget = flipWidget;
    $scope.getWidgetHeightStyle = getWidgetHeightStyle;
    $scope.isMobile = isMobile;
    $scope.removeWidget = removeWidget;
    $scope.save = save;
    $scope.setEditMode = setEditMode;
    $scope.setFullScreenMode = setFullScreenMode;
    $scope.setGridsterController = setGridsterController;
    $scope.showAddWidgetModal = showAddWidgetModal;
    $scope.showSettingsModal = showSettingsModal;
    $scope.isAchProductActive;
    $scope.isWireProductActive;
    $scope.isTransferProductActive;
    $scope.isFxWireProductActive;
    $scope.getProductStatus;
    $scope.areWireProductsActive = areWireProductsActive;

    const achEntitlements = [
        'Create Ach Template',
        'Full Edit Ach Template',
        'Partial Edit Ach Template',
        'Delete Ach Template',
        'Approve Ach Template',
        'ACH, Batch, CreatePayment',
        'ACH, Payment, Full Edit',
        'Delete Ach Payment',
        'Approve Ach Payment',
        'ACH, Payment, Partial Edit',
        'Create Recipient',
        'Edit Recipient',
        'Delete Recipient',
        'Approve Recipient',
    ];

    init();

    function init() {
        getProductStatus();
        dashboardService.getConfiguredWidgets().then(response => {
            $scope.widgetList = apiToPresentationWidgets(response);
            $timeout(() => {
                setEditMode(false);
                initWidgetSizeWatch();
            });
        });

        $scope.gridsterOptions = {
            columns: 3, // the width of the grid, in columns
            pushing: true, // whether to push other items out of the way on move or resize
            floating: true, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
            swapping: false, // whether or not to have items of the same size switch places instead of pushing down if they are the same size
            width: 'auto', // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
            colWidth: 'auto', // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
            rowHeight: 100, // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
            margins: [15, 15], // the pixel distance between each widget
            outerMargin: true, // whether margins apply to outer edges of the grid
            sparse: false, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
            isMobile: false, // stacks the grid items if true
            mobileBreakPoint: 960, // if the screen is not wider that this, remove the grid layout and stack the items
            mobileModeEnabled: true, // whether or not to toggle mobile mode when screen width is less than mobileBreakPoint
            minColumns: 1, // the minimum columns the grid must have
            minRows: 2, // the minimum height of the grid, in rows
            maxRows: 100,
            defaultSizeX: 2, // the default width of a gridster item, if not specifed
            defaultSizeY: 1, // the default height of a gridster item, if not specified
            minSizeX: 1, // minimum column width of an item
            maxSizeX: null, // maximum column width of an item
            minSizeY: 1, // minumum row height of an item
            maxSizeY: null, // maximum row height of an item
            resizable: {
                enabled: true,
                handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
                start(event, $element, widget) {}, // optional callback fired when resize is started,
                resize(event, $element, widget) {}, // optional callback fired when item is resized,
                stop: onWidgetGridResizeEnd, // optional callback fired when item is finished resizing
            },
            draggable: {
                enabled: true, // whether dragging items is supported
                handle: '.drag-handle', // optional selector for drag handle
                start(event, $element, widget) {}, // optional callback fired when drag is started,
                drag(event, $element, widget) {}, // optional callback fired when item is moved,
                stop(event, $element, widget) {}, // optional callback fired when item is finished dragging
            },
        };

        if (canAccessAchBanks()) {
            achBankService.getAllBanks();
        }
        bankService.getAllBanks();

        $scope.hasNewCheckExceptionEntitlement = entitlementsService.hasEntitlement(
            'Feature.PositivePay.CheckException'
        );

        handleMessageCenterItems();
    }

    $scope.toggleItem = function (bannerItem) {
        bannerItem.isCollapsed = !bannerItem.isCollapsed;
        if (!bannerItem.isCollapsed) {
            dashboardService.auditUserIteration(
                dashboardUserInteractions.viewedInformationCenterMessage
            );
        }
    };

    function handleMessageCenterItems() {
        const messageCenterPromise = FeatureFlagService.isEnabled(Feature.MessageCenterEnhancement);
        $q.resolve(messageCenterPromise).then(isEnabled => {
            if (isEnabled) {
                dashboardService.getInformationCenter().then(response => {
                    const [bannerItem] = response.filter(item => item.type === 'Banner');
                    if (bannerItem) {
                        $scope.bannerItem = bannerItem;
                    }
                    const [messageItem] = response.filter(item => item.type === 'Modal');
                    if (messageItem) {
                        messageItem.headerIcon = 'info-circle';
                        renderMessageCenterModal(messageItem);
                    }
                });
            }
        });
    }

    function getStorageService() {
        return TmDi.getAsync(SessionStorageService);
    }

    async function getStorage(key) {
        const storageService = await getStorageService();
        return storageService.get(`${key}`);
    }

    async function setStorage(key, value) {
        const storageService = await getStorageService();
        storageService.set(`${key}`, value);
    }

    async function hideModal(modalItem) {
        if (!modalItem) return true;
        const lastModalItem = await getStorage('lastDashboardModalItem');
        await setStorage('lastDashboardModalItem', modalItem);
        if (!lastModalItem) return false;
        return (
            lastModalItem.description === modalItem.description &&
            lastModalItem.title === modalItem.title
        );
    }

    function getDialogService() {
        return TmDi.getAsync(OmegaDialogService);
    }

    async function renderMessageCenterModal(modalItem) {
        if (await hideModal(modalItem)) {
            return;
        }
        const dialogService = await getDialogService();
        const dialogHandle = dialogService.open(
            html`<div style="margin-left: 18px">${modalItem.description}</div>`,
            modalItem.title,
            {
                width: 550,
                buttons: {
                    [OmegaDialogExitReason.Cancel]: null,
                    [OmegaDialogExitReason.Confirm]: { label: 'OK' },
                },
                headerIcon: modalItem.headerIcon,
            }
        );
        return $q.resolve(dialogHandle.closed);
    }

    function setGridsterController(gridsterController) {
        $scope.gridsterController = gridsterController;
    }

    function canAccessAchBanks() {
        return achEntitlements.some(entitlement => entitlementsService.hasEntitlement(entitlement));
    }

    function save() {
        const allWidgets = presentationToApiWidgets(
            $scope.widgetList.concat($scope.removedWidgets)
        );
        dashboardService.setConfiguredWidgets(allWidgets).then(response => {
            $scope.widgetListBackup = window.angular.copy($scope.widgetList);
            $scope.removedWidgets = [];
            setEditMode(false);
        });
    }

    function cancel() {
        $scope.removedWidgets = [];
        $scope.widgetList = $scope.widgetListBackup;
        $timeout(() => {
            setEditMode(false);
        });
    }

    function setEditMode(value) {
        $scope.inEditMode = value;
        window.angular.forEach($scope.widgetList, widget => {
            widget.inEditMode = value;
        });

        if ($scope.inEditMode) {
            $scope.widgetListBackup = window.angular.copy($scope.widgetList);
            $('.widget section').scrollTop(0);
        }

        setResizableHandlers();
    }

    function setResizableHandlers() {
        let widgetElements;
        let i;

        if (!$scope.inEditMode) {
            $('.gridster-item-resizable-handler').hide();
            return;
        }

        widgetElements = $('#dashboard-view').find('.widget');
        for (i = 0; i < widgetElements.length; i++) {
            if ($scope.widgetList[i].isResizable) {
                $(widgetElements[i]).find('.gridster-item-resizable-handler').show();
            } else {
                $(widgetElements[i]).find('.gridster-item-resizable-handler').hide();
            }
        }
    }

    function removeWidget(widget, gridsterItem) {
        const index = $scope.widgetList.indexOf(widget);
        if (index > -1) {
            widget.isRemoved = true;
            $scope.removedWidgets.push(widget);
            $scope.widgetList.splice(index, 1);
            gridsterItem.destroy();
        }
    }

    function setFullScreenMode(value) {
        $scope.fullScreenWidget = value;
    }

    function showAddWidgetModal() {
        const modalInstance = $modal.open({
            template: require('../views/addWidgetModal.html'),
            controller: 'AddWidgetModalController',
            size: 'md',
            windowClass: 'modal-full-height add-widget-modal',
            resolve: {
                configuredWidgets() {
                    return $scope.widgetList.filter(widget => !widget.isRemoved);
                },
            },
        });
        modalInstance.result.then(widgets => {
            angular.forEach(apiToPresentationWidgets(widgets), widget => {
                addWidget(widget);
                setResizableHandlers();
            });
        });
    }

    // TODO: Looks like this code isn't even called in the codebase. Probably remove.
    function showSettingsModal(widget) {
        const modalInstance = $modal.open({
            template: './../../../settingsModal.html', // TODO: Where is this file?
            controller: 'SettingsModalController',
            size: 'md',
            resolve: {
                widget() {
                    return widget;
                },
            },
        });
    }

    function flipWidget(widget, value) {
        if (value === widget.flipped) {
            return;
        }

        widget.flipping = true;
        if (value) {
            widget.flipped = value;
        } else {
            widget.flipped = !widget.flipped;
        }

        $timeout(() => {
            widget.flipping = false;
        }, 500);
    }

    function isMobile() {
        return $scope.gridsterController.isMobile;
    }

    function getWidgetHeightStyle(widget) {
        let sectionHeight;

        if (isMobile()) {
            sectionHeight = widget.sizeY * 100 - 50;
            return `height: ${sectionHeight}px`;
        }
        return 'height: auto';
    }

    //
    // Events

    function onWidgetGridResizeEnd(event, $element, widget) {
        let numCols;
        let colWidth;
        let marginWidth;

        // Set the width of the widget in pixels based on its new column size.
        numCols = widget.sizeX;
        colWidth = $scope.gridsterController.curColWidth;
        marginWidth = $scope.gridsterOptions.margins[0];
        widget.width = colWidth * numCols - marginWidth;

        setResizableHandlers();
    }

    function onGridsterMobileChanged(gridster) {
        setResizableHandlers();
    }

    //
    // Private

    function initWidgetSizeWatch() {
        const widgetElementList = $($element).find('.widget');

        let i = 0;
        window.angular.forEach(widgetElementList, widgetElement => {
            const widget = $scope.widgetList[i];
            $scope.widgetList[i].width = $(widgetElement).width();

            $(widgetElement).watch({
                properties: 'width',
                callback(data, propertyIndex) {
                    widget.width = parseInt(data.vals[propertyIndex], 10);
                },
            });

            i++;
        });
    }

    function addWidget(widget) {
        widget.inEditMode = true;
        $scope.widgetList.push(widget);
    }

    function apiToPresentationWidgets(widgets) {
        const presentationWidgets = [];
        angular.forEach(widgets, widget => {
            presentationWidgets.push({
                id: widget.id,
                title: widget.title,
                description: widget.description,
                type: widget.type,
                image: widget.image,
                isResizable: widget.isResizable,
                fullScreen: widget.fullScreenAllowed,
                minSizeX: widget.minWidth,
                maxSizeX: widget.maxWidth,
                minSizeY: widget.minHeight,
                maxSizeY: widget.maxHeight,
                sizeX: widget.width,
                sizeY: widget.height,
                row: widget.top,
                col: widget.left,
                isRemoved: widget.isRemoved,
                isRequired: widget.isRequired,
                hasPermission: widget.hasPermission,
                permissionMessage: widget.permissionMessage,
            });
        });
        return setTemplates(presentationWidgets);
    }

    function presentationToApiWidgets(widgets) {
        const apiWidgets = [];
        angular.forEach(widgets, widget => {
            apiWidgets.push({
                id: widget.id,
                title: widget.title,
                description: widget.description,
                type: widget.type,
                image: widget.image,
                isResizable: widget.isResizable,
                fullScreenAllowed: widget.fullScreen,
                minWidth: widget.minSizeX,
                maxWidth: widget.maxSizeX,
                minHeight: widget.minSizeY,
                maxHeight: widget.maxSizeY,
                width: widget.sizeX,
                height: widget.sizeY,
                top: widget.row,
                left: widget.col,
                isRemoved: widget.isRemoved,
                isRequired: widget.isRequired,
                hasPermission: widget.hasPermission,
                permissionMessage: widget.permissionMessage,
            });
        });
        return apiWidgets;
    }

    function setTemplates(widgets) {
        widgets = angular.copy(widgets);
        angular.forEach(widgets, widget => {
            switch (widget.type) {
                case 'accounts':
                    widget.template = 'app/dashboard/widgets/views/accountsWidget.html';
                    break;
                case 'arp':
                    if ($scope.hasNewCheckExceptionEntitlement) {
                        widget.template = 'app/dashboard/widgets/views/arpWidgetNew.html';
                    } else {
                        widget.template = 'app/dashboard/widgets/views/arpWidget.html';
                    }
                    break;
                case 'favoriteReports':
                    widget.template = 'app/dashboard/widgets/views/favoriteReportsWidget.html';
                    break;
                case 'infoCenter':
                    widget.template = 'app/dashboard/widgets/views/informationCenterWidget.html';
                    break;
                case 'paymentsPendingApproval':
                    widget.template =
                        'app/dashboard/widgets/views/paymentsPendingApprovalWidget.html';
                    break;
                case 'quickTransfer':
                    widget.template = 'app/dashboard/widgets/views/quickTransferWidget.html';
                    break;
                case 'quickLoanPayment':
                    widget.template = 'app/dashboard/widgets/views/quickLoanPaymentWidget.html';
                    break;
                case 'resources':
                    widget.template = 'app/dashboard/widgets/views/resourcesWidget.html';
                    break;
                case 'stopRequest':
                    widget.template = 'app/dashboard/widgets/views/stopRequestWidget.html';
                    break;
            }
        });
        return widgets;
    }

    function getProductStatus() {
        dashboardService.getProductStatus().then(response => {
            $scope.isAchProductActive = isProductActive(response, 'ACH');
            $scope.isTransferProductActive = isProductActive(response, 'Transfer');
            $scope.isWireProductActive = isProductActive(response, 'WireTransferProductFeature');
            $scope.isFxWireProductActive = isProductActive(
                response,
                'WireTransferDliProductFeature'
            );
        });
    }

    function isProductActive(response, productType) {
        const filterProduct = $filter('filter')(response, { type: productType })[0];
        return filterProduct && filterProduct.status === 'Active';
    }

    function areWireProductsActive() {
        return $scope.isWireProductActive || $scope.isFxWireProductActive;
    }
}
