import camelToTitleCase from '../../utilities/camel-to-title-case.js';

export const rangeStrings = {
    RANGE: 'AmountRange',
    SPECIFIC: 'SpecificAmount',
    LIMIT: 'LimitAmount',
};

const formatRangeString = (rangeString, withSpaces = false) => {
    if (!withSpaces) return rangeString;
    return camelToTitleCase(rangeString).substring(1);
};

export class AmountRangeFormatters {
    static isRangeType(array) {
        return array[0] === 'range' || array[0] === 'limit';
    }

    static getRangeType(array, withSpaces = false) {
        let result;
        const rangeType = array[0];
        switch (rangeType) {
            case 'range':
                result = formatRangeString(rangeStrings.RANGE, withSpaces);
                break;
            case 'limit':
                result = formatRangeString(rangeStrings.LIMIT, withSpaces);
                break;
            case 'specific':
                result = formatRangeString(rangeStrings.SPECIFIC, withSpaces);
                break;
            default:
                result = null;
                break;
        }
        return result;
    }

    static getRangeStart(array) {
        if (this.isRangeType(array)) return Number(array[1]) || null;
        return null;
    }

    static getRangeEnd(array) {
        if (this.isRangeType(array)) return Number(array[2]) || null;
        return null;
    }

    static getSpecificAmount(array) {
        if (!this.isRangeType(array)) return Number(array[1]) || null;
        return null;
    }

    static getAmountStartAndEnd(array) {
        if (this.isRangeType(array)) {
            const start = this.getRangeStart(array);
            const end = this.getRangeEnd(array);
            if (start === null && end === null) {
                return {
                    start,
                    end,
                };
            }
            if (start === null && end !== null) {
                return {
                    start: 0,
                    end,
                };
            }
            if (start !== null && end === null) {
                return {
                    start: 0,
                    end: null,
                };
            }
            return {
                start,
                end,
            };
        }
        return {
            start: this.getSpecificAmount(array),
            end: this.getSpecificAmount(array),
        };
    }

    static numberToSpecificAmount(amount) {
        return ['specific', amount, 0];
    }
}

export default AmountRangeFormatters;
