export enum FrequencyType {
    OneTime = 'OneTime',
    Weekly = 'Weekly',
    EveryTwoWeeks = 'EveryTwoWeeks',
    TwiceAMonth = 'TwiceAMonth',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    EverySixMonths = 'EverySixMonths',
    Yearly = 'Yearly',
}

export type FrequencyTypeKeys = keyof typeof FrequencyType;

export interface Frequency {
    type: FrequencyType | undefined | null;
    valueDate: string | null;
    initiatedDate: string | null;
    endOn: string | null;
    id?: number;
    nextPaymentDate: string | null;
    noEndDate: boolean | null;
    repeatOn: string | null;
    repeatOnDay1: number | null;
    repeatOnDay2: number | null;
    repeatOnLastBusinessDay: boolean | null;
    startOn: string | null;
    summary: string | null;
    updatedBy: string | null;
    updatedDate: string | null;
}
