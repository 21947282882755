SpinnerService.$inject = ['$q', '$rootScope'];

export default function SpinnerService($q, $rootScope) {
    let _numLoadings = 0;
    const _groups = [];
    const _ignoreList = [];
    const _permanentIgnoreList = [
        'account/settings',
        'activity/navigationaudit',
        'security/recentLogins',
        'companyaccounts/cutoffTimes',
        'notifications/recent',
        'users/getApprovers',
        'wires/getNewWireQuote',
        '/accounttransactions/accounts/balance',
        'silent=1',
        'achBatches/validateTemplateName',
        'navigations/auditUserActivity',
        'navigations/auditMenulessUserActivity',
        'dashboard/auditUserInteraction',
        'print/userActivityAudit',
        '/security/validateOneTimePassword',
        'security/validateSecureToken',
        '/ach-filters',
    ];
    return {
        request(config) {
            if (startOrIgnore(config.url)) {
                _numLoadings++;
                $rootScope.$broadcast('loader_show');
            }

            return config || $q.when(config);
        },
        response(response) {
            if (stopOrRemoveIgnore(response.config.url)) {
                if (--_numLoadings === 0) {
                    $rootScope.$broadcast('loader_hide');
                }
            }
            return response || $q.when(response);
        },
        responseError(response) {
            if (stopOrRemoveIgnore(response.config.url)) {
                if (!--_numLoadings) {
                    console.info(`hiding loader due to ${response.config.url} completing`);
                    $rootScope.$broadcast('loader_hide');
                }
            }
            return $q.reject(response);
        },

        configureGroup(groupName, urlMatchers, passiveUrlMatchers) {
            if (!getGroup(groupName)) {
                _groups.push({
                    name: groupName,
                    urlMatchers,
                    passiveUrlMatchers,
                    numPendingRequests: 0,
                });
            }
        },

        startGroupRequest(groupName) {
            const group = getGroup(groupName);
            group.numPendingRequests += 1;
            return group.numPendingRequests;
        },

        stopGroupRequest(groupName) {
            const group = getGroup(groupName);
            group.numPendingRequests -= 1;
            return group.numPendingRequests;
        },
    };

    //
    // Private

    function startOrIgnore(url) {
        if (
            hasMatchInAnyActiveGroup(url) ||
            hasPassiveMatchInAnyGroup(url) ||
            isPermanentIgnoreList(url)
        ) {
            addUrlToIgnoreList(url);
            return false;
        }
        return true;
    }

    function stopOrRemoveIgnore(url) {
        if (isOnIgnoreList(url)) {
            removeUrlFromIgnoreList(url);
            return false;
        }
        return true;
    }

    function hasMatchInAnyActiveGroup(url) {
        let result = false;
        angular.forEach(_groups, group => {
            if (group.numPendingRequests > 0) {
                angular.forEach(group.urlMatchers, urlMatcher => {
                    if (url.indexOf(urlMatcher) !== -1) {
                        result = true;
                    }
                });
            }
        });
        return result;
    }

    function hasPassiveMatchInAnyGroup(url) {
        let result = false;
        angular.forEach(_groups, group => {
            angular.forEach(group.passiveUrlMatchers, passiveUrlMatcher => {
                if (url.indexOf(passiveUrlMatcher) !== -1) {
                    result = true;
                }
            });
        });
        return result;
    }

    function addUrlToIgnoreList(url) {
        _ignoreList.push(url);
    }

    function isOnIgnoreList(url) {
        let i;
        for (i = 0; i < _ignoreList.length; i++) {
            if (_ignoreList[i] === url) {
                return true;
            }
        }
        return false;
    }

    function isPermanentIgnoreList(url) {
        let i;
        for (i = 0; i < _permanentIgnoreList.length; i++) {
            if (url.indexOf(_permanentIgnoreList[i]) !== -1) {
                return true;
            }
        }
        return false;
    }

    function removeUrlFromIgnoreList(url) {
        let i;
        for (i = 0; i < _ignoreList.length; i++) {
            if (_ignoreList[i] === url) {
                _ignoreList.splice(i, 1);
                break;
            }
        }
    }

    function getGroup(groupName) {
        let result = null;
        window.angular.forEach(_groups, group => {
            if (groupName === group.name) {
                result = group;
            }
        });
        return result;
    }
}
