import { html, css, LitElement } from 'lit';

class OmegaSection extends LitElement {
    render() {
        return html`<div class="omega-section">
            <slot></slot>
        </div>`;
    }

    static get styles() {
        return css`
            .omega-section {
                display: flex;
                background: white;
                height: 100%;
                width: 100%;
            }
        `;
    }
}

window.customElements.define('omega-section', OmegaSection);
export default OmegaSection;
