/* eslint-disable no-param-reassign */
import { FiDate } from '@treasury/domain/dates';

export default function datePicker() {
    return {
        restrict: 'A',
        require: '?ngModel',
        replace: true,
        scope: {
            ngModel: '=',
            dateGreaterThan: '@', // apparently this means greater than or equal to
            holidayDates: '<',
            kOptions: '<',
            kMin: '<',
            kMax: '<',
        },
        template:
            '<omega-datepicker style="width: 300px; display: inline-block; padding: 0"></omega-datepicker>',
        link(scope, element, attrs, ngModel) {
            if (!ngModel) return;

            const omegaDatepicker = element[0];

            omegaDatepicker.dateDisabledFunction = dateModel => {
                const nativeDate = new Date(dateModel.year, dateModel.month, dateModel.dayOfMonth);
                const date = new FiDate(nativeDate);

                if (scope.dateGreaterThan && date < new FiDate(scope.dateGreaterThan)) {
                    return true;
                }

                if (scope.kMin && date < new FiDate(scope.kMin)) {
                    return true;
                }

                if (scope.kMax && date > new FiDate(scope.kMax)) {
                    return true;
                }

                if (scope?.holidayDates?.some(holiday => holiday.startsWith(date.toIsoDate()))) {
                    return true;
                }

                if (scope?.kOptions?.disableDates?.(nativeDate)) return true;

                return false;
            };

            ngModel.$render = () => {
                omegaDatepicker.value = ngModel.$viewValue || '';
            };
            element.on('change', ({ detail }) =>
                ngModel.$setViewValue(new FiDate(detail.value.dates[0]).toString())
            );
        },
    };
}
