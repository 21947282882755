import { InjectionToken } from '@jack-henry/frontend-utils/di';

export const CHANNEL_API = `Jha.Web.Treasury.Channel.API`;
export const BACKOFFICE_API = `Jha.Web.Treasury.Backoffice.API`;
export const INSTITUTION_ID = 'institutionId';

interface AppMetaData {
    appName: string;
    version: string;
    buildType: 'production' | 'development';
    url: string;
    /**
     * Is `true` if the application is being served by Webpack dev server.
     */
    isLocal: boolean;
}

export enum AppType {
    Unknown = 'Unknown',
    Channel = 'Channel',
    BackOffice = 'BackOffice',
    Pwa = 'PWA',
}

export enum BuildType {
    Unknown = 'unknown',
    Production = 'production',
    Development = 'development',
}

export enum Environment {
    Unknown = 'unknown',
    Local = 'local',
    Dev = 'dev',
    Staging = 'staging',
    Production = 'production',
}

/**
 * Inlined value from Webpack's [`DefinePlugin`](https://webpack.js.org/plugins/define-plugin/).
 */
declare const APP_TYPE: AppType | undefined;
/**
 * Inlined value from Webpack's [`DefinePlugin`](https://webpack.js.org/plugins/define-plugin/).
 */
declare const BUILD_TYPE: BuildType | undefined;

export const AppTypeToken = new InjectionToken('AppType', {
    // specifically use typeof operator to guard against undeclared inlined value
    value: typeof APP_TYPE === 'undefined' ? AppType.Unknown : APP_TYPE,
});

export const BuildTypeToken = new InjectionToken('BuildType', {
    // specifically use typeof operator to guard against undeclared inlined value
    value: typeof BUILD_TYPE === 'undefined' ? BuildType.Unknown : BUILD_TYPE,
});

/**
 * Injected configuration data used for bootstrapping the app, if any.
 */
export interface InjectedConfig {
    /**
     * The root URL used by the channel or BO application to make API requests.
     */
    apiRoot?: string;
    /**
     * The client token used to enable the analytics SDK.
     */
    analyticsToken?: string;
    /**
     * The client token used to enable the error logging SDK.
     */
    loggingToken?: string;
}

/**
 * Global scope augmented with configuration and metadata information.
 *
 * See `index.html.ejs` amd `webpack.shared.js` for the mechanics of how this data is injected.
 */
export type InjectedWindow = Window & {
    /**
     * Configuration data manually specified or injected into `index.html.ejs`
     * using `HtmlPlugin` [`templateParameters`](https://github.com/jantimon/html-webpack-plugin#options).
     */
    config: InjectedConfig;
    /**
     * App metadata injected into `index.html.ejs` using `HtmlPlugin` [`templateParameters`](https://github.com/jantimon/html-webpack-plugin#options).
     */
    treasury: AppMetaData;
};
