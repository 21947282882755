import { ErrorDetailModelDto } from '@treasury/api/channel';
import { Wire } from '@treasury/domain/wires';
import { Record as FdlRecord } from '@treasury/FDL';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import ng from 'angular';
import { html, nothing } from 'lit';

const canCancelStatuses = ['Pending Approval', 'Scheduled'];

function isStatusCancelable(status: string) {
    return canCancelStatuses.includes(status);
}

export function hasAccessPermissions(wire: Wire, permissionType: string) {
    let result = false;
    if (!wire.permissions) return result;
    wire.permissions.forEach(permission => {
        if (permission.permission === permissionType) {
            result = true;
        }
    });
    return result;
}

function canCancel(wire: Wire) {
    if (wire.isDliWire) return false;
    if (wire.destinationCurrency !== 'USD') return false;
    if (!isStatusCancelable(wire.status ?? '')) return false;
    return hasAccessPermissions(wire, 'Delete');
}

function renderErrorDetails(details: ErrorDetailModelDto[] | undefined) {
    if (!details) return nothing;
    return html` <ul style="list-style-position: outside; text-align: left;">
        ${details.map(detail => html`<li>${detail.message}</li>`)}
    </ul>`;
}

function errorSummaryTemplate(wire: Wire) {
    return html`<div>
        ${wire.errorSummary?.summaryMessage} ${renderErrorDetails(wire.errorSummary?.details)}
    </div>`;
}

/**
 * Shared column definitions used throughout the issued item creation workflow.
 */
export const columns: OmegaColumnDefinition<Wire>[] = [
    {
        type: OmegaColumnType.CheckBox,
        field: 'isChecked',
        label: '',
    },
    {
        field: 'transactionId',
        label: 'Transaction ID',
        type: OmegaColumnType.Actions,
        actions: ['review'],
    },
    {
        field: 'transactionId',
        label: '',
        type: OmegaColumnType.Icon,
        icon: 'file-text-o',
        tooltip: 'View wire file',
        action: 'openWireFileDetails',
        conditional: record => {
            if (record.getField('isEdited'))
                return {
                    icon: 'edit',
                    label: '',
                    tooltip: 'Wire Has Been Edited',
                    type: OmegaColumnType.Icon,
                };
            if (record.getField('messageType') === 'Info')
                return {
                    icon: 'info-circle',
                    label: '',
                    tooltip: record.getField('message') ?? '',
                    type: OmegaColumnType.Icon,
                };
            if (record.getField('errorSummary'))
                return {
                    icon: 'exclamation-circle',
                    iconStyle: 'color: var(--omega-button-exclamation-triangle-danger-color, red);',
                    label: '',
                    tooltip: errorSummaryTemplate(record.values),
                    type: OmegaColumnType.Icon,
                };
            if (record.getField('isDliWire') && record.getField('status') === 'Approval Rejected')
                return {
                    icon: 'exclamation-triangle',
                    iconStyle:
                        'color: var(--omega-button-exclamation-triangle-warning-color, orange);',
                    label: '',
                    tooltip:
                        'This international wire has been rejected. For possible penalty information contact your bank.',
                    type: OmegaColumnType.Icon,
                };

            return false;
        },
        visibleWhen: (record: FdlRecord<Wire>) =>
            record.getField('source')?.toUpperCase() === 'SYSTEM' ||
            record.getField('isEdited') ||
            record.getField('messageType') === 'Info' ||
            !!record.getField('errorSummary') ||
            ((record.getField('isDliWire') ?? false) &&
                record.getField('status') === 'Approval Rejected'),
    },
    {
        field: 'wireCompany',
        label: 'Wire Company',
    },
    {
        field: 'source',
        label: 'Source',
    },
    {
        field: 'type',
        label: 'Wire Type',
    },
    {
        field: 'debitAccount',
        label: 'Debit Account',
    },
    {
        field: 'beneficiary',
        label: 'Beneficiary Name',
    },
    {
        field: 'destinationCurrency',
        label: 'Currency',
    },
    {
        field: 'amount',
        label: 'Wire Amount',
    },
    {
        field: 'frequency',
        label: 'Effective Date',
    },
    {
        field: 'createdDate',
        label: 'Created Date',
    },
    {
        field: 'confirmationNumber',
        label: 'OMAD',
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        label: 'Actions',
        type: OmegaColumnType.Actions,
        actions: [
            {
                label: 'Cancel Wire',
                action: 'cancelWirePayment',
                visibleWhen: (record: FdlRecord<Wire>) => canCancel(record.values),
            },
        ],
    },
];
