import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { DateService } from '@treasury/domain/channel/services/dates/dates-service.js';
import { WireUploadService } from '@treasury/domain/channel/services/wires/wire-upload';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/progress/omega-progress';
import { OmegaDialogExitReason, OmegaDialogService } from '@treasury/omega/services';
import { LitElement, css, html } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import { printSingleWire, printWires } from '../helpers/printRecord.js';
import { mapRecordSetToWires } from '../helpers/recordsetMapping.js';
import '../workflow/wire-upload-workflow.js';

class WireUploadContainer extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            activeStep: Number,
            institution: String,
            wireFile: Object,
            fileFormats: Array,
            wireCompanies: Array,
            wireConfiguration: Object,
            accountConfiguration: Object,
            companyId: Number,
            error: String,
            files: Array,
            confirming: Boolean,
            uploading: Boolean,
            finalUploadPayload: Object,
            holidays: Array,
            authError: String,
            // client: Object,
            alert: Object,
            wireFileUniqueId: String,
        };
    }

    constructor() {
        super();
        this.loading = true;
        this.confirming = false;
        this.uploading = false;
        this.activeStep = 0;
        this.wireFileUniqueId = '';
    }

    connectedCallback() {
        super.connectedCallback();
        this.init();
    }

    print({ detail }) {
        const record = detail.value;
        printSingleWire(record.values, this.accountConfiguration);
    }

    printAll({ detail }) {
        const { records } = detail;
        const selectedRecordValues = records
            .recordsMatching('selected', true)
            .map(record => record.values);
        printWires(selectedRecordValues, this.accountConfiguration);
    }

    async init() {
        const container = await DiContainer.getInstance();
        const navService = container.get(NavigationService);
        const { params } = await navService.getRouteData();
        this.dialogService = container.get(OmegaDialogService);
        this.wireUploadService = container.get(WireUploadService);

        const { wireFileUniqueId, fileName, fileSize } = params;
        if (wireFileUniqueId) this.wireFileUniqueId = wireFileUniqueId;
        this.files = [{ name: fileName, size: fileSize }];
        try {
            this.fileFormats = await this.wireUploadService.getFileFormats();
            this.wireCompanies = await this.wireUploadService.getCompanies();
            this.wireConfiguration = await this.wireUploadService.getWireConfiguration();
            this.accountConfiguration = await this.wireUploadService.getAccountConfiguration();
            this.holidays = await DateService.getHolidays();
            this.error = undefined;
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async updated() {
        if (this.wireFileUniqueId && !this.loading && !this.wireFile) {
            try {
                this.loading = true;
                this.uploading = true;
                this.wireFile = await this.wireUploadService.processWireFile(this.wireFileUniqueId);
                this.error = undefined;
            } catch (err) {
                this.setAlertFromError(err);
                this.wireFileUniqueId = undefined;
                this.showWireFileErrorDialog(
                    err instanceof Error ? err.message : 'An error occurred.'
                );
            } finally {
                this.uploading = false;
                this.loading = false;
            }
        }
    }

    async showWireFileErrorDialog(message) {
        await this.dialogService.open(
            html`A server error occurred processing the wire file:<br />${message}<br /><br />Returning
                to Wire File Activity.`,
            'Process Wire File Error',
            {
                maxWidth: 350,
                buttons: {
                    [OmegaDialogExitReason.Cancel]: null,
                    [OmegaDialogExitReason.Confirm]: {
                        label: 'Continue',
                    },
                },
            }
        ).closed;

        const navService = (await DiContainer.getInstance()).get(NavigationService);
        navService.navigate('payables.wire.wire-file-activity');
    }

    async parseWireFile(e) {
        try {
            this.uploading = true;
            this.files = e.detail;
            this.wireFile = await this.wireUploadService.parseWireFile(
                this.files[0],
                this.companyId,
                this.formatId
            );
            this.error = undefined;
            if (this.wireFile) this.shadowRoot.querySelector('wire-upload-workflow').next();
        } catch (err) {
            this.setAlertFromError(err);
        } finally {
            this.uploading = false;
        }
    }

    mapErrorSummary(errorSummary) {
        if (!errorSummary) return;
        const errorMessage = errorSummary.details.map(
            error =>
                html`<p>${error.message}</p>
                    <ul>
                        <li>${error.messageList.map(message => html`${message.value}`)}</li>
                    </ul>`
        );
        this.displayErrorSummary(errorMessage);
    }

    setErrorSummary(errorMessage) {
        this.errorSummary = errorMessage;
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'error',
            posture: 'assertive',
            message: this.errorMessage?.summaryMessage,
        };
    }

    handleUploadErrors(response) {
        this.error = response.cumulativeErrorSummary;
        this.wireFile = { ...this.wireFile, wires: response.wires };
        this.activeStep = 1;
    }

    handleSuccessfulUpload() {
        this.error = null;
        this.activeStep = this.wireFileUniqueId ? 2 : 3;
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'success',
            title: this.wireFileUniqueId ? 'Wire Payments Processed' : 'Wire Payments Uploaded',
            // eslint-disable-next-line lit-a11y/anchor-is-valid, lit-a11y/click-events-have-key-events
            message: html`<a @click=${this.routeToWireFileActivity} class="link"
                >Review your payments in Wire File Activity</a
            >`,
        };
    }

    async uploadFiles(uploadPayload) {
        try {
            this.confirming = true;
            const { wires } = uploadPayload;
            await this.wireUploadService.createBulkWires(
                wires,
                this.handleUploadErrors.bind(this),
                this.handleSuccessfulUpload.bind(this)
            );
        } catch (err) {
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message: 'There was a problem finalizing your wires, please try again in a moment',
            };
        } finally {
            const containerSection = document.querySelector('#sectionView');
            if (containerSection) containerSection.scrollTop = 0;
            this.confirming = false;
        }
    }

    async confirmFilesToUpload(e) {
        const error =
            'There are currently no wires selected for processing, please go back select a wire and try again.';
        this.finalUploadPayload = {
            wires: mapRecordSetToWires(e.detail.value, ['selected'], 'selected'),
        };
        if (this.finalUploadPayload?.wires?.length < 1) {
            this.error = error;
            return;
        }
        this.error = '';
        this.uploadFiles(this.finalUploadPayload);
    }

    companyChange(e) {
        this.companyId = e.detail;
    }

    formatChange(e) {
        this.formatId = e.detail;
    }

    stepChange(e) {
        this.activeStep = e.detail.value;

        if (this.activeStep === 0 && !this.wireFileUniqueId) {
            this.files = [];
            this.wireFile = {};
        }
    }

    async goToWireFileActivity() {
        const navService = (await DiContainer.getInstance()).get(NavigationService);
        return navService.navigate('payables.wire.wire-file-activity');
    }

    routeToWireFileActivity() {
        const wireFileActivityUrl = `${this.institution}/payables/wire/wire-file-activity`;
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
            { fileName: this.wireFile.fileName, fileId: this.wireFile.wireFileId }, // Did not change to wireFileUniqueId due to routing concerns.
            null,
            wireFileActivityUrl
        );
        window.location.href = wireFileActivityUrl;
    }

    processFileError(e) {
        this.alert = {
            ...this.alert,
            visible: true,
            type: 'error',
            posture: 'polite',
            message: e.detail.value,
        };
    }

    renderTitle() {
        const title = this.wireFileUniqueId ? 'Process Wire File' : 'Upload Wire File';
        return html`<h1>${title}</h1>`;
    }

    render() {
        if (this.loading)
            return html` <omega-progress card class="large-loader"></omega-progress> `;

        return html` ${this.renderAlert()}
            ${this.error
                ? html`<omega-alert id="assertive-alert" isVisible type="error"
                      >${this.error}</omega-alert
                  >`
                : null}
            <div class="wire-upload-container">
                ${this.renderTitle()}
                <wire-upload-workflow
                    .activeStep=${this.activeStep}
                    .institution=${this.institution}
                    .wireFileUniqueId=${this.wireFileUniqueId}
                    .fileFormats=${this.fileFormats}
                    .wireCompanies=${this.wireCompanies}
                    .wireFile=${this.wireFile}
                    .files=${this.files}
                    .wireConfiguration=${this.wireConfiguration}
                    .accountConfiguration=${this.accountConfiguration}
                    .holidays=${this.holidays}
                    ?canUpload=${!!this.companyId}
                    @upload=${this.parseWireFile}
                    @formatChange=${this.formatChange}
                    @companyChange=${this.companyChange}
                    @confirm=${this.confirmFilesToUpload}
                    @print=${this.print}
                    @printAll=${this.printAll}
                    @stepChange=${this.stepChange}
                    @goToWireFileActivity=${this.goToWireFileActivity}
                    @processFileError=${this.processFileError}
                    ?uploading=${this.uploading}
                    ?confirming=${this.confirming}
                >
                </wire-upload-workflow>
            </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
                .wire-upload-container {
                    position: relative;
                    background-color: #fff;
                    box-shadow: 0px 0px 6px 2px #d5d5d5;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                h1 {
                    margin-block-start: 0;
                    font-size: 24px;
                    font-weight: 400;
                    margin: 0;
                    padding: 16px;
                }
                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
                .large-loader {
                    width: 50px;
                    height: 50px;
                    border-width: 5px;
                }
                .link {
                    color: var(--omega-primary);
                    text-decoration: underline;
                    cursor: pointer;
                }
            `,
        ];
    }
}

customElements.define('wire-upload-container', WireUploadContainer);
export default WireUploadContainer;
