import { ArpAccountsService } from '@treasury/domain/channel/services';
import { AccountDto } from '@treasury/domain/channel/types';
import { FieldType } from '@treasury/FDL';

export function createSearchableAccountField(accountsService: ArpAccountsService) {
    return new FieldType<AccountDto | undefined>().with
        .placeholder('Select Account')
        .and.options({
            fetch: () => accountsService.getArpAccounts('UploadCreateArpFile'),
            text: dto => `${dto.number} (${dto.type})`,
            value: dto => dto,
        })
        .and.search({
            title: 'Search Accounts',
            columns: [
                {
                    label: 'Account Number',
                    field: 'number',
                },
                {
                    label: 'Account Name',
                    field: 'name',
                },
                {
                    label: 'Account Type',
                    field: 'type',
                },
            ],
        })
        .and.hashFunction<AccountDto>(account => {
            if (!account) {
                return '';
            }

            return account.id.toString();
        });
}
