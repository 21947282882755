import { noChange } from 'lit';
import { AsyncDirective } from 'lit/async-directive.js';
import { directive } from 'lit/directive.js';
import { exists } from '../../functions';
import { Observable } from '../../types/observable';

class ObserveDirective<T> extends AsyncDirective {
    private subscription?: ReturnType<Observable<T>['subscribe']>;

    public render(observable: Observable<T>, defaultValue?: T) {
        if (exists(defaultValue)) {
            this.setValue(defaultValue);
        }

        this.subscription = observable.subscribe(value => {
            this.setValue(value);
        }, true);

        return noChange;
    }

    // eslint-disable-next-line sort-class-members/sort-class-members
    protected disconnected(): void {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
    }
}

const observeDirective = directive(ObserveDirective<any>);

/**
 * Lit directive for interacting with {@link Observable | `Observable`} instances in templates.
 */
export function observe<T>(...args: Parameters<ObserveDirective<T>['render']>) {
    return observeDirective(...args);
}
