/* eslint-disable class-methods-use-this */

import { css, html, LitElement } from 'lit';

class OmegaTextarea extends LitElement {
    static get properties() {
        return {
            label: { type: String },
            field: { type: Object },
            disabled: { type: Boolean, defaultValue: false },
            valid: { type: Boolean, reflect: true },
            placeholder: { type: String },
            rows: { type: Number },
            maxLength: { type: Number },
            resizable: { type: Boolean, defaultValue: true },
        };
    }

    constructor() {
        super();
        this.disabled = false;
        this.valid = true;
        this.field = {
            onChange: () => null,
            allowInputChar: key => true,
        };
        this.placeholder = '';
        this.inputHasFocus = false;
        this.rows = 2;
        this.resizable = true;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-textarea--simple',
        };
    }

    firstUpdated() {
        this.field.onChange(() => {
            if (!this.inputHasFocus) {
                this.requestUpdate();
            }
        });

        this.nativeInput.addEventListener('focus', () => {
            this.inputHasFocus = true;
        });

        this.nativeInput.addEventListener('blur', () => {
            this.inputHasFocus = false;
            this.requestUpdate();
        });
    }

    get value() {
        return this.field.value;
    }

    set value(v) {
        this.field.value = v;
    }

    get nativeInput() {
        return this.shadowRoot.querySelector('textarea');
    }

    maskKey(event) {
        if (this.field.allowInputChar(event.key)) return;
        event.preventDefault();
    }

    onChange() {
        this.value = this.nativeInput.value;
        this.dispatchEvent(new CustomEvent('change', { detail: this.nativeInput.value }));
    }

    focus() {
        this.nativeInput.focus();
    }

    render() {
        return html`<textarea
            rows=${this.rows}
            placeholder=${this.placeholder}
            .value=${this.value}
            ?disabled=${this.disabled}
            aria-label=${this.label}
            aria-invalid=${!this.valid}
            maxlength=${this.maxLength}
            style=${!this.resizable && 'resize: none'}
            @change=${this.onChange}
            @input=${this.onChange}
            @focus=${this.onFocus}
            @keydown=${e => this.maskKey(e)}
        >
        </textarea> `;
    }

    static get styles() {
        return css`
            * {
                box-sizing: border-box;
            }

            :host {
                display: block;
                color: var(--omega-ux-text-color, #000);
            }

            textarea {
                width: 100%;
                line-height: 1.5em;
                border: 1px solid var(--omega-input-default-border);
                padding: 10px;
                border-radius: var(--omega-input-border-radius);
                font-size: var(--omega-input);
                font-family: var(--omega-font);
            }

            textarea[aria-invalid='true'] {
                border: 2px solid var(--omega-input-error-border);
            }

            textarea:active {
                border-color: var(--omega-input-active-border);
            }

            textarea:disabled {
                opacity: 0.6;
                background: var(--omega-white);
            }
        `;
    }
}

window.customElements.define('omega-textarea', OmegaTextarea);
export default OmegaTextarea;
