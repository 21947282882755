UserAccountsController.$inject = ['$scope', '$modalInstance', 'companyAccounts'];

export default function UserAccountsController($scope, $modalInstance, companyAccounts) {
    $scope.accountSearchText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.companyAccounts = companyAccounts;
    $scope.selectAccount = selectAccount;
    $scope.filterAccounts = filterAccounts;

    function close() {
        $modalInstance.dismiss();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function selectAccount(account) {
        $modalInstance.close(account);
    }

    function filterAccounts(account) {
        return (
            (account.number !== null &&
                account.number !== undefined &&
                account.number.toString().indexOf($scope.accountSearchText) !== -1) ||
            (account.name !== null &&
                account.name !== undefined &&
                account.name.toLowerCase().indexOf($scope.accountSearchText.toLowerCase()) !==
                    -1) ||
            (account.balance !== null &&
                account.balance !== undefined &&
                account.balance.toString().indexOf($scope.accountSearchText) !== -1) ||
            (account.availableBalance !== null &&
                account.availableBalance !== undefined &&
                account.availableBalance.toString().indexOf($scope.accountSearchText) !== -1)
        );
    }
}
