/* eslint-disable import/no-extraneous-dependencies */
import { css, html, LitElement } from 'lit';

class OmegaBadge extends LitElement {
    static get properties() {
        return {
            type: { type: String },
            clickable: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.type = 'info';
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-badge--badge',
        };
    }

    render() {
        const badgeClass = this.clickable ? 'omega-badge clickable' : 'omega-badge';
        return html`<span class=${badgeClass} type=${this.type}><slot></slot></span>`;
    }

    static get styles() {
        return css`
            .omega-badge {
                background-color: var(--omega-white);
                border: 1px solid var(--omega-primary);
                border-radius: 3px;
                color: var(--omega-primary);
                display: inline-block;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 11px;
                font-weight: 600;
                line-height: 10px;
                min-width: 90px;
                outline: none;
                padding: 6px;
                text-align: center;
                text-transform: uppercase;
            }
            .clickable {
                cursor: pointer;
            }
            span[type='error'] {
                border: 1px solid var(--omega-error);
                color: var(--omega-error);
            }
            span[type='inactive'] {
                background-color: var(--omega-info-lighten-300);
                border: 1px solid var(--omega-info-lighten-100);
                color: var(--omega-info-darken-100);
            }
            span[type='info'] {
                border: 1px solid var(--omega-primary-lighten-100);
                color: var(--omega-primary);
            }
            span[type='success'] {
                border: 1px solid var(--omega-success-darken-100);
                color: var(--omega-success-darken-100);
            }
            span[type='warning'] {
                border: 1px solid var(--omega-warning);
                color: var(--omega-warning-darken-100);
            }
            span[type='pending'] {
                border: 1px solid var(--omega-warning);
                color: var(--omega-warning);
            }
        `;
    }
}

customElements.define('omega-badge', OmegaBadge);
export default OmegaBadge;
