import { v4 as uuid } from 'uuid';

UploadPaymentController.$inject = [
    '$scope',
    'achPaymentsService',
    '$state',
    '$modal',
    'activityService',
    'resourceType',
    'auditCode',
    'modalService',
    '$sce',
];

export default function UploadPaymentController(
    $scope,
    achPaymentsService,
    $state,
    $modal,
    activityService,
    resourceType,
    auditCode,
    modalService,
    $sce
) {
    $scope.nachaData = null;
    $scope.fileUid = null;
    $scope.currentView = 'uploadFile';
    $scope.canUpload = canUpload;
    $scope.onRemoveFile = onRemoveFile;
    $scope.onSelectFile = onSelectFile;
    $scope.upload = upload;
    $scope.wizardStep = 1;
    $scope.fromTemplate = fromTemplate;
    $scope.manualEntry = manualEntry;
    $scope.entryType = { selected: 'U' };
    $scope.confirmCancel = confirmCancel;
    $scope.fileSizeExceeded = false;
    $scope.errorMessages = null;

    init();

    function init() {
        activityService.userInteractionAudit(
            resourceType.PaymentResources,
            auditCode.AccessUploadNachaFile
        );

        $scope.nachaData = {
            file: null,
            uid: null,
        };
    }

    function canUpload() {
        return $scope.nachaData.file;
    }

    function upload() {
        achPaymentsService.uploadNachaFile($scope.nachaData).then(response => {
            if (response.errorSummary) {
                $scope.errorMessages = response.errorSummary.summaryMessageList;
            } else {
                $scope.achPaymentImport = response;
                $scope.achPaymentImport.duplicatePreventionId = uuid();
                if (response.achBatchSummaries && response.achBatchSummaries.length > 1) {
                    response.achBatchSummaries = response.achBatchSummaries.map((item, index) => {
                        item.status = null;
                        item.originalIndex = index;
                        if (!item.frequency) {
                            item.frequency = {
                                type: 'One Time',
                            };
                        }
                        return item;
                    });

                    $scope.setSelectedBatches(response);
                    $state.go('payables.ach.payments.createFromNachaUpload', {
                        upload: true,
                    });
                } else {
                    response.status = null;
                    if (!response.frequency) {
                        response.frequency = {
                            type: 'One Time',
                        };
                    }
                    $scope.setSelectedBatches(response);
                    $state.go('payables.ach.payment-detail', {
                        id: 0,
                        type: 'import',
                    });
                }
            }
        });
    }

    function manualEntry() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelUploadNachaFile
                    );
                    $modalInstance.close();
                    $state.go('payables.ach.payments.create');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(null, () => {
                $scope.entryType.selected = 'U';
            });
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelUploadNachaFile
            );
            $state.go('payables.ach.payments.create');
        }
    }

    function fromTemplate() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText:
                    'You have changes on the page, are you sure you would like to leave the page without saving?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelCreateAchPayment
                    );
                    $modalInstance.close();
                    $state.go('payables.ach.payments.createFromBatch');
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
            $modalInstance.result.then(null, () => {
                $scope.entryType.selected = 'U';
            });
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelCreateAchPayment
            );
            $state.go('payables.ach.payments.createFromBatch');
        }
    }

    // Events
    function onSelectFile(e) {
        if (e.files) {
            $scope.fileSizeExceeded = false;
            $scope.errorMessages = null;
            $scope.form.$setDirty();
            $scope.nachaData.file = e.files[0].rawFile;
            $scope.fileUid = e.files[0].uid;
            $scope.fileSize = e.files[0].size;
            if (e.files[0].size / 1024 / 1024 > 15) {
                $scope.fileSizeExceeded = true;
                $scope.errorMessages = ['The number of payments exceeds the maximum of 100,000.'];
                $scope.form.$setPristine();
            }
            $scope.$apply();
        }
    }

    function onRemoveFile(e) {
        if ($scope.fileUid === e.files[0].uid) {
            $scope.nachaData.file = null;
            $scope.fileUid = null;
            $scope.errorMessages = null;
            $scope.$apply();
        }
    }

    $scope.setUploadForm = function (form) {
        $scope.form = form;
    };

    function confirmCancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel Payment Information?',
                submit(result) {
                    $modalInstance.close();
                    $state.go('payables.ach.payment-list');
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $state.go('payables.ach.payment-list');
        }
    }
}
