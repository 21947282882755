/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';

import '../../components/ach-payment-action-dialog.js';
import '../../components/ach-template-action-dialog.js';
import '../parts/ach-international-addenda-dialog.js';
import '../parts/ach-international-payment-header.js';
import '../parts/ach-international-payment-recipients-table.js';

import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-button.js';
import '@treasury/omega/components/omega-dialog.js';
import '@treasury/omega/components/omega-download-bar.js';
import '@treasury/omega/components/progress/omega-progress.js';

import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { getNextBusinessDay } from '@treasury/domain/dates';
import { ListeningElementMixin } from '@treasury/omega/components';
import { boolean } from '@treasury/policy/primitives';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import InternationalAchPaymentClient from '../clients/international-ach-payment-client.js';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration.ts';
import { setValueFromCascadingValues } from '../data/get-value-from-options.ts';
import { paymentFields, paymentHeaderRecord } from '../data/payment-header-record.js';
import { columns, recipientRecordset } from '../data/recipient-recordset.js';

class InternationalAchDetailContainer extends mix(LitElement).with(
    ListeningElementMixin,
    channelAlertMixin
) {
    static get properties() {
        return {
            client: Object,
            institution: String,
            payment: Object,
            paymentId: String,
            paymentHeaderRecord: Object,
            recipientsRecordset: Object,
            loading: Boolean,
            downloading: Boolean,
            alert: Object,
            showActionDialog: Boolean,
            actionTaken: String,
            fields: Array,
            type: String,
            deleted: Boolean,
        };
    }

    constructor() {
        super();
        this.filters = [
            {
                field: 'prenote',
                fieldType: boolean.with.label('Prenote Only'),
                value: false,
            },
            {
                field: 'hold',
                fieldType: boolean.with.label('Hold Only'),
                value: false,
            },
        ];
        this.showActionDialog = false;
        this.statusChangeComments = '';
        this.submittingActionOnPayment = false;
        this.recipientsPerPage = 25;
        this.buttonPermissions = {
            APPROVE: 'Approve',
            EDIT: 'EditPayment',
            REJECT: 'RejectPayment',
            template: {
                CREATE: 'CreateTemplate',
                EDIT: 'EditTemplate',
                PAYMENT: 'CreatePayment',
                DELETE: 'DeleteTemplate',
                APPROVE: 'ApproveTemplate',
                REJECT: 'ApproveTemplate',
            },
        };
        this.templateName = '';
        this.deleted = false;
    }

    get permissions() {
        return (this.payment?.permissions || []).map(p => p.permission);
    }

    get templatePermissions() {
        return this.payment?.userPermissions || [];
    }

    firstUpdated() {
        if (!this.client) {
            this.client = new InternationalAchPaymentClient();
        }
        this.init();
    }

    async init() {
        this.loading = true;
        const path = window.location.pathname.split('/');
        this.paymentId = path.pop();
        this.type = path.pop();
        this.template = this.type === 'template';
        this.downloadOptions = CONTAINER_CONFIGURATION[this.type].downloadOptions;

        try {
            this.achConfiguration = this.achConfiguration ?? (await this.client.achConfiguration());
            await this.getTemplateOptions();
            this.payment = await this.client[CONTAINER_CONFIGURATION[this.type].get](
                this.paymentId,
                this.achConfiguration.iatAchOptions
            );
            const { payment } = this;
            if (this.type === 'payment') this.payment = this.payment.payment;
            this.recipients = this.payment.recipients.map(recipient => ({
                ...recipient,
                ...recipient.addenda,
            }));

            this.isSameDayAchEnabled = this.achConfiguration.isSameDayAchEnabled;
            this.holidays = this.achConfiguration.holidays;
            this.cutoffTimes = this.achConfiguration.cutoffTimes;
            this.entitlements = this.achConfiguration.entitlements;

            this.fields = paymentFields(
                payment,
                this.achConfiguration,
                this.client,
                false, // edit is false on this detail container
                this.template
            );
            this.paymentHeaderRecord = paymentHeaderRecord(
                {
                    ...this.payment,
                    step: 2,
                },
                this.achConfiguration,
                this.client,
                false, // edit is false on this detail container
                this.template
            );
            this.templateName = this.paymentHeaderRecord.getField('name');
            if (this.template) {
                setValueFromCascadingValues(
                    'foreignExchangeReferenceNumber',
                    this.paymentHeaderRecord,
                    'foreignExchangeReferenceIndicators',
                    'id',
                    this.payment.foreignExchangeReferenceIndicatorId,
                    this.achConfiguration.iatAchOptions,
                    'description'
                );
            }

            this.recipientsRecordset = recipientRecordset(
                this.recipients,
                payment,
                this.achConfiguration,
                false, // edit is false on this detail container
                this.client
            );
            this.recipientsRecordset.setInitialPageSize(this.recipientsPerPage);
            this.userEntitlements = this.achConfiguration.entitlements;
            this.hasCreateIntlAchPermission = this.userEntitlements.find(
                item => item.permission === 'Create International ACH Payment'
            );
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async getTemplateOptions() {
        if (this.template) {
            const optionsAlreadyObtained = !!this.achConfiguration.iatAchOptions;
            if (!this.gettingIatAchOptions && !optionsAlreadyObtained) {
                this.gettingIatAchOptions = true;
                const iatAchOptions = this.template ? await this.client.getIatAchOptions() : null;
                this.achConfiguration.iatAchOptions = iatAchOptions;
                if (this.achConfiguration.iatAchOptions?.successLevel > 1) {
                    throw new Error('This service is currently unavailable.');
                }
                this.gettingIatAchOptions = false;
            }
        }
    }

    userCanTakeActionOnPayment(action) {
        // for edit payment, we check payment status and payment permissions
        if (this.template) return this.templatePermissions.includes(action);
        const editableStatus = action === 'EditPayment' ? this.paymentIsEditableStatus() : true;
        return this.template
            ? this.templatePermissions?.includes(action)
            : this.permissions?.includes(action) && editableStatus;
    }

    paymentIsEditableStatus() {
        const editableStatuses = new Set([
            'Approval Rejected',
            'Scheduled',
            'Uninitiated',
            'Failed',
            'Expired',
        ]);
        return editableStatuses.has(this.paymentHeaderRecord.getField('status'));
    }

    clearFieldsForNewPayment(paymentAction) {
        if (paymentAction === 'initiate') {
            this.payment.transactionId = null;
            this.payment.id = 0;
            const nextBusinessDay = getNextBusinessDay(this.holidays);
            this.payment.frequency = {
                effectiveDate: nextBusinessDay,
                repeatOn: null,
                startOn: null,
                endOn: null,
                valueDate: nextBusinessDay,
                nextPaymentDate: null,
                noEndDate: true,
                repeatOnDay1: null,
                repeatOnDay2: null,
                repeatOnLastBusinessDay: null,
                type: null,
                summary: '',
            };
        }
    }

    formatPaymentForRouter(paymentAction) {
        return {
            payment: {
                ...this.payment,
                frequency: {
                    ...this.payment.frequency,
                    valueDate: this.payment.frequency.effectiveDate,
                },
            },
            recipients: this.recipients,
            paymentCreationType: paymentAction,
        };
    }

    routeTo(paymentAction) {
        const actions = {
            edit: this.routeToEditInternationalAchPayment.bind(this),
            initiate: this.routeToCreateInternationalAchPayment.bind(this),
            delete: this.routeToTemplateList.bind(this),
        };

        actions[paymentAction]();
    }

    async routeToTemplateList() {
        (await getNavService()).navigate('payables.ach.payments.international-ach-templates');
    }

    async routeToPaymentList() {
        (await getNavService()).navigate('payables.ach.payment-list');
    }

    async routeToEditInternationalAchPayment() {
        const paymentAction = 'edit';
        const navService = await getNavService();

        if (this.template) {
            navService.navigate('payables.ach.payments.edit-international-ach-template', {
                id: this.payment.id,
            });
        } else {
            const paymentState = this.formatPaymentForRouter(paymentAction);
            navService.navigate('payables.ach.payments.edit-international-ach-payment', {
                paymentState,
                id: this.payment.id,
            });
        }
    }

    async routeToCreateInternationalAchPayment() {
        const paymentAction = 'initiate';
        const navService = await getNavService();
        const paymentState = this.formatPaymentForRouter(paymentAction);

        this.clearFieldsForNewPayment(paymentAction);
        navService.navigate('payables.ach.payments.create-international-ach-payment', {
            paymentState,
        });
    }

    async submitActionOnTemplate(record, statusChangeComments) {
        this.submittingActionOnPayment = true;
        let response;
        let successText = '';
        let clientRequest;

        const pastTenseAction = {
            approve: 'approved',
            reject: 'rejected',
            delete: 'deleted',
        };

        try {
            clientRequest = this.client[CONTAINER_CONFIGURATION.template[this.actionTaken]];
            response = await clientRequest(this.paymentId, statusChangeComments);

            if (response.successLevel === 1) {
                successText = pastTenseAction[this.actionTaken] || '';
                this.alert = {
                    ...this.alert,
                    message: html`<span>Template successfully ${successText}.</span>
                        <omega-button
                            type="link"
                            @click=${this.back}
                            @keypress=${e => console.log(e)}
                        >
                            Return to International ACH Template List
                        </omega-button>`,
                    visible: true,
                    posture: 'default',
                    type: 'success',
                };
                this.deleted = this.actionTaken === 'delete';
                this.routeToTemplateList();
            } else {
                throw new Error(response);
            }
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        } finally {
            this.showActionDialog = false;
            this.submittingActionOnPayment = false;
        }
        return clientRequest;
    }

    async submitActionOnPayment() {
        this.submittingActionOnPayment = true;
        try {
            const response = await this.client.updatePaymentStatus(
                this.actionTaken,
                this.paymentId
            );
            const { status } = response;
            if (response.message) {
                throw new Error(`${response.code} - ${response.message}`);
            } else {
                const message = this.actionTaken === 'approve' ? 'approved!' : 'rejected.';
                this.alert = {
                    ...this.alert,
                    message: `Payment successfully ${message} Returning to ach payment list.`,
                    visible: true,
                    type: 'success',
                };
                this.paymentHeaderRecord.setField('status', status);
                this.routeToPaymentList();
            }
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.submittingActionOnPayment = false;
            this.showActionDialog = false;
        }
    }

    async handleDownload({ detail }) {
        this.downloading = true;
        try {
            if (this.template) {
                await this.client.downloadTemplate(this.paymentId, detail.downloadType);
            } else {
                await this.client.downloadPayment(
                    this.paymentId,
                    detail.downloadType,
                    'ACHPaymentDetail'
                );
            }
        } catch (e) {
            this.setAlertFromError(e, 'Download failed. Please try again.');
        } finally {
            this.downloading = false;
        }
    }

    handlePrint() {
        // TODO: values from services to track this activity
        this.client.updateUserPrintActivity('id', 'information');
        window.print();
    }

    async backToPaymentList() {
        const navService = await getNavService();
        const isOneTimePayment =
            this.paymentHeaderRecord
                .getField('frequency')
                .type.toLowerCase()
                .trim()
                .replaceAll(/\s/g, '') === 'onetime';
        if (isOneTimePayment) {
            navService.navigateBack();
        } else {
            navService.navigate('payables.ach.recurring-payment-list');
        }
    }

    async back() {
        if (this.template) {
            (await getNavService()).navigate('payables.ach.payments.international-ach-templates');
        } else {
            this.backToPaymentList();
        }
    }

    renderLoader() {
        if (this.loading) {
            return html`<omega-progress card></omega-progress>`;
        }
        return nothing;
    }

    renderDownloadDialog() {
        const name = this.payment?.name ?? this.paymentId;
        const titleSubject = this.template ? name : this.payment?.transactionId;
        const downloadDialogTitle = `Downloading ${titleSubject}`;
        if (this.downloading) {
            return html`<omega-dialog
                id="download-dialog"
                open
                .dialogTitle=${downloadDialogTitle}
                @close=${() => {
                    this.downloading = false;
                }}
            >
                <omega-progress card></omega-progress>
            </omega-dialog>`;
        }
        return nothing;
    }

    renderPaymentActionDialog() {
        const titleAction = this.actionTaken
            ? this.actionTaken[0].toUpperCase() + this.actionTaken.slice(1)
            : '';

        if (this.template) {
            return html`<ach-template-action-dialog
                .actionTaken=${this.actionTaken}
                .dialogAction=${(record, statusChangeComments) =>
                    this.submitActionOnTemplate(record, statusChangeComments)}
                .closeEventHandler=${() => {
                    this.showActionDialog = false;
                }}
                dialogTitle="Confirm ${titleAction}"
                .templateName=${this.templateName}
                .showActionDialog=${this.showActionDialog}
                @close=${() => {
                    this.showActionDialog = false;
                }}
            ></ach-template-action-dialog>`;
        }
        return html`<ach-payment-action-dialog
            .paymentHeaderRecord=${this.paymentHeaderRecord}
            .submittingActionOnPayment=${this.submittingActionOnPayment}
            .actionTaken=${this.actionTaken}
            .showActionDialog=${this.showActionDialog}
            @submit=${({ detail }) => {
                this.statusChangeComments = detail;
                this.submitActionOnPayment();
            }}
            @close=${() => {
                this.showActionDialog = false;
            }}
        ></ach-payment-action-dialog>`;
    }

    renderPaymentHeader() {
        const activeStep = 2;
        if (this.paymentHeaderRecord && !this.loading) {
            return html`<ach-international-payment-header
                .activeStep=${activeStep}
                .fields=${this.fields}
                isFromFile
                expanded
                .paymentHeaderRecord=${this.paymentHeaderRecord}
                .recipientsRecordset=${this.recipientsRecordset}
                .template=${this.template}
            ></ach-international-payment-header>`;
        }
        return nothing;
    }

    renderRecipientsTable() {
        if (this.recipientsRecordset && !this.loading) {
            return html`<ach-international-payment-recipients-table
                .columns=${columns(false)}
                .editable=${false}
                .recipients=${this.recipients}
                .filters=${this.filters}
                .recipientsRecordset=${this.recipientsRecordset}
                display-toggle-all
            ></ach-international-payment-recipients-table>`;
        }
        return nothing;
    }

    renderInitiateButton() {
        if (this.hasCreateIntlAchPermission && !this.template) {
            return html`
                <omega-button
                    type="primary"
                    .disabled=${this.loading}
                    @click=${() => this.routeTo('initiate')}
                >
                    Initiate Payment
                </omega-button>
            `;
        }
        return nothing;
    }

    renderEditButton() {
        const buttonText = this.template ? 'Edit Template' : 'Edit Payment';
        if (
            this.userCanTakeActionOnPayment(this.buttonPermissions.EDIT) ||
            this.userCanTakeActionOnPayment(this.buttonPermissions.template.EDIT)
        ) {
            return html`<omega-button
                .disabled=${this.loading}
                @click=${() => this.routeTo('edit')}
            >
                ${buttonText}
            </omega-button>`;
        }
        return nothing;
    }

    renderApproveButton() {
        const canApprovePayment =
            this.userCanTakeActionOnPayment(this.buttonPermissions.APPROVE) && !this.template;
        const canApproveTemplate =
            this.userCanTakeActionOnPayment(this.buttonPermissions.template.APPROVE) &&
            this.template;
        if (canApprovePayment || canApproveTemplate) {
            return html`<omega-button
                type="approve"
                .disabled=${this.loading}
                class="button-alignment-exception-right-second"
                @click=${() => {
                    this.actionTaken = 'approve';
                    this.showActionDialog = true;
                }}
                >Approve</omega-button
            >`;
        }
        return nothing;
    }

    renderRejectButton() {
        const canRejectPayment =
            this.userCanTakeActionOnPayment(this.buttonPermissions.REJECT) && !this.template;
        const canRejectTemplate =
            this.userCanTakeActionOnPayment(this.buttonPermissions.template.REJECT) &&
            this.template;
        if (canRejectPayment || canRejectTemplate) {
            return html` <omega-button
                type="reject"
                .disabled=${this.loading}
                class="button-alignment-exception-right-last"
                @click=${() => {
                    this.actionTaken = 'reject';
                    this.showActionDialog = true;
                }}
                >Reject</omega-button
            >`;
        }
        return nothing;
    }

    renderDeleteButton() {
        if (
            this.template &&
            this.userCanTakeActionOnPayment(this.buttonPermissions.template.DELETE) &&
            !this.deleted
        ) {
            return html` <omega-button
                type="reject"
                .disabled=${this.loading}
                @click=${() => {
                    this.actionTaken = 'delete';
                    this.showActionDialog = true;
                }}
                class="button-alignment-exception-right-last"
                >Delete</omega-button
            >`;
        }
        return nothing;
    }

    renderPaymentActions() {
        if (this.paymentHeaderRecord && !this.loading) {
            return html`
                ${this.renderInitiateButton()} ${this.renderEditButton()}
                ${this.renderApproveButton()} ${this.renderRejectButton()}
                ${this.renderDeleteButton()}
                <omega-button .disabled=${this.loading} @click=${this.back}>Back</omega-button>
            `;
        }
        return nothing;
    }

    render() {
        const templateTitle = this.template ? 'Template ' : '';
        const reportTitle = `ACH ${templateTitle}Detail: ${
            this.payment?.transactionId ?? this.payment?.name ?? ''
        }`;
        return html` ${this.renderAlert()} ${this.renderDownloadDialog()}
            ${this.renderPaymentActionDialog()}
            <div id="international-ach-detail-container">
                <omega-download-bar
                    .pageTitle=${reportTitle}
                    .downloadOptions=${this.downloadOptions}
                    .actions=${['download', 'print']}
                    .disableDownload=${this.loading && this.paymentHeaderRecord}
                    .disablePrint=${this.loading && this.paymentHeaderRecord}
                    @download=${this.handleDownload}
                    @print=${this.handlePrint}
                ></omega-download-bar>
                ${this.renderLoader()} ${this.renderPaymentHeader()} ${this.renderRecipientsTable()}
                <omega-button-bar position="bottom">
                    ${this.renderPaymentActions()}
                </omega-button-bar>
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --bottom-offset: -15px;
            }
            #international-ach-detail-container {
                position: relative;
                background-color: #fff;
                border: 1px solid #eeededfd;
                padding: 0 5px;
                box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
            }
            #download-dialog {
                min-width: 350px;
            }
            #payment-action-dialog {
                padding: 0 15px;
            }
            ach-international-payment-recipients-table {
                margin-bottom: 60px;
            }
            .button-alignment-exception-right-last {
                position: absolute;
                right: 0;
            }
            .button-alignment-exception-right-second {
                position: absolute;
                right: 100px;
            }
        `;
    }
}

customElements.define('international-ach-detail-container', InternationalAchDetailContainer);

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
