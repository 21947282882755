import { Record, Recordset } from '@treasury/FDL';
import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { AchDomesticContainer } from '../containers/ach-domestic-container';

/* istanbul ignore file */
export default async (
    context: AchDomesticContainer,
    record: Record<PaymentHeader>,
    recordset: Recordset<PaymentRecipient>,
    step: number
) => {
    if (step === 0) {
        if (record.isValid() && recordset.invalidRecordCount() === 0) {
            context.next(record);
            return;
        }
        if (!record.getField('achCompany')) {
            record.setField('name', 'office supplies');
            record.setField('entryDescription', 'payroll');
            record.setField(
                'achCompany',
                (await record.fieldTypeForField('achCompany').options())[0]?.value
            );
        }

        const accountType = (await recordset.getFieldType('accountType').options())[0]?.value;

        const routingNumber = (await recordset.getFieldType('bank').options())[0]?.value;

        recordset.allRecords.forEach(recipient => {
            recipient.setField('name', 'Dwight Schrute');
            recipient.setField('idNumber', '12345123');
            recipient.setField('accountNumber', '1234123123');
            recipient.setField('accountType', accountType);
            recipient.setField('bank', routingNumber);
            recipient.setField('amount', 1);
        });
    }
};
