/* eslint-disable import/no-extraneous-dependencies */

import FieldType from '@treasury/FDL/field-type.js';
import { Record } from '@treasury/FDL/record';
import { css, html, LitElement, nothing } from 'lit';
import { fontAwesome } from '../css/icons/font-awesome.js';
import buildFilter from '../models/build-filter.js';
import { ListeningElementMixin } from './listening-element.ts';

const ListeningElement = ListeningElementMixin(LitElement);

class OmegaFilterBar extends ListeningElement {
    static get properties() {
        return {
            recordset: Object,
            filterRecord: Object,
            filters: Array,
            filterText: String,
            filterTree: Array,
            disableTypeToFilter: Boolean,
            itemLabel: Object,
            isLocal: Boolean,
        };
    }

    constructor() {
        super();
        this.filterText = '';
        this.filterHasBeenCalled = false;
        this.filters = [];
        this.debounceTimeout = 400;
        this.itemLabel = {
            singular: 'item',
            plural: 'items',
        };
        this.isLocal = true;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-filter-bar--default',
        };
    }

    isTypeToFilterDisabled() {
        if (!this.recordset) this.disableTypeToFilter = true;
        if (this.isLocal) {
            this.disableTypeToFilter = this.recordset.allRecords?.length === 0;
        }
    }

    firstUpdated() {
        this.createFilterRecord();
        this.filterTree = this.createFilters();
        if (this.filters?.length) {
            this.recordset.filter = buildFilter(this.filterTree);
        }
        this.addRecordsetListeners();
    }

    addRecordsetListeners() {
        ['updated', 'counts-changed', 'page-changed'].forEach(event => {
            this.listenTo(this.recordset, event, () => {
                if (this.filters && this.filters?.length) {
                    this.filters
                        .filter(filter => filter?.fieldType?.schema() === 'boolean')
                        .forEach(filter => {
                            const count = this.countRecordsInFilter(filter);
                            this.filterRecord.values[`${filter.field}_count`] = count;
                        });
                    this.createFilterRecord();
                }
                this.renderFilterRow();
                this.isTypeToFilterDisabled();
            });
        });
    }

    countRecordsInFilter(filter) {
        return filter.field === '#invalid'
            ? this.recordset.invalidRecordCount()
            : this.recordset.countRecordsMatching(filter.field, true);
    }

    createFilterRecord() {
        if (!this.filters) return;
        // I think we may be able to get rid of this and recreating the filter record
        // by updating the implemented label functions on the filter field definitions
        const [types, values] = this.updateFilterLabels();
        this.filterRecord = new Record(types, values);
        this.listenTo(this.filterRecord, 'change', event => {
            this.filter(this.filterText);
            if (event.detail.field.endsWith('_count')) return;
            const filterFields = Object.keys(this.filterRecord.values).filter(
                key => !key.endsWith('_count')
            );
            filterFields.forEach(field => {
                const filter = this.filters.find(f => f.field === field);
                filter.value = this.filterRecord.getField(field);
            });
            this.requestUpdate();
        });
    }

    updateFilterLabels() {
        const types = {};
        const values = {};
        this.filters.forEach(filter => {
            const countField = `${filter.field}_count`;
            types[filter.field] = filter.fieldType.with.label((record, label) => {
                if (filter.fieldType.schema() !== 'boolean') return label;
                return `${label} (${record.getField(countField)})`;
            });
            values[filter.field] = filter.value;
            types[countField] = new FieldType();
            values[countField] = this.countRecordsInFilter(filter);
        });
        return [types, values];
    }

    createTextFilterTree() {
        if (this.filterText.length === 0) return [];
        if (!this.filterRecord)
            return [
                'or',
                ...this.recordset.fieldNames.map(field => ['contains', field, this.filterText]),
            ];

        return [
            'or',
            ...this.recordset.fieldNames
                .filter(field => this.filterRecord.fieldTypeForField(field).schema() !== 'boolean')
                .map(field => ['contains', field, this.filterText]),
        ];
    }

    createFilters() {
        const textFilterTree = this.createTextFilterTree();
        const fieldFilterTree = this.createFilterTreeFromRecord();
        if (textFilterTree.length > 0 && fieldFilterTree.length > 0) {
            return ['and', textFilterTree, fieldFilterTree];
        }

        return [...textFilterTree, ...fieldFilterTree];
    }

    createFilterTreeFromRecord() {
        if (!this.filterRecord) return [];
        const trees = this.filters
            .map(filter => filter.field)
            .filter(key => this.filterRecord.values[key])
            .map(key => this.createFilterTreeForField(key));

        if (trees.length > 0) {
            return ['and', ...trees];
        }

        return [];
    }

    createFilterTreeForField(key) {
        return [this.getFilterTypeForField(key), key, this.filterRecord.getField(key)];
    }

    getFilterTypeForField(key) {
        if (key === '#invalid') {
            return 'invalid';
        }

        if (this.filterRecord.fieldTypes[key].hasMultipleValues()) {
            return 'containsAny';
        }

        if (this.filterRecord.fieldTypes[key].properties.schema === 'range') {
            return 'between';
        }
        return 'equals';
    }

    debounceFilter(text) {
        if (text === null || text === this.filterText) return;

        clearTimeout(this.typeToFilterTimeout);
        this.typeToFilterTimeout = setTimeout(() => {
            this.filter(text);
        }, this.debounceTimeout);
    }

    filter(text) {
        if (text === null) return;

        this.filterText = text.trim();
        this.filterTree = this.createFilters();
        this.recordset.filter = buildFilter(this.filterTree);
        this.filterTree.searchText = this.filterText;
        this.dispatchEvent(
            new CustomEvent('change', {
                detail: this.filterTree,
            })
        );
        this.filterHasBeenCalled = true;
    }

    renderMessage() {
        if (!this.recordset) {
            return nothing;
        }
        if (this.itemLabel.plural === undefined) {
            this.itemLabel.plural = `${this.itemLabel.singular}s`;
        }
        const { filteredCount, filter } = this.recordset;
        const isFiltered = filter.length > 0;
        if (isFiltered) {
            this.requestUpdate();
            if (filteredCount === 0) {
                return html`<div class="message"><em>No ${this.itemLabel.plural} found</em></div>`;
            }
            if (filteredCount === 1) {
                return html`<div class="message">
                    <em>1 ${this.itemLabel.singular} found</em>
                </div>`;
            }
            return html`<div class="message">
                <em>${filteredCount.toLocaleString('en-US')} ${this.itemLabel.plural} found</em>
            </div>`;
        }
        return nothing;
    }

    renderFilters() {
        if (!this.filterRecord) return nothing;

        return this.filters.map(
            filter =>
                html`<omega-field
                    .field=${filter.field}
                    .record=${this.filterRecord}
                ></omega-field>`
        );
    }

    renderFilterBarActionSlot() {
        return html`<div class="actions"><slot name="actions"></slot></div>`;
    }

    renderFilterRow() {
        return html` <div class="filter-row">
            <div id="type-to-filter">
                <input
                    placeholder="Type to filter"
                    id="type-to-filter-input"
                    aria-label="Type to filter"
                    .value=${this.filterText}
                    ?disabled=${this.disableTypeToFilter}
                    @keyup=${e => this.debounceFilter(e.target.value)}
                    @blur=${e => {
                        e.stopPropagation();
                    }}
                />
                &nbsp;<i class="fa fa-search fa-lg input-icon"></i>
            </div>
            ${this.renderMessage()} ${this.renderFilters()} ${this.renderFilterBarActionSlot()}
        </div>`;
    }

    render() {
        return this.renderFilterRow();
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                }
                .filter-row {
                    display: flex;
                    align-items: center;
                    margin: 12px 0;
                }
                .actions {
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                }
                omega-field {
                    --omega-field-control-width: 38px;
                    --omega-field-label-margin-bottom: 0;
                    --omega-field-checkbox-margin-right: 0;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    margin-right: 16px;
                }
                #type-to-filter {
                    position: relative;
                    height: 32px;
                    line-height: 34px;
                    margin: 0 15px 0 0;
                    min-width: 200px;
                }
                .input-icon {
                    position: absolute;
                    right: 3px;
                    bottom: 10px;
                    font-size: 16px;
                    color: var(--omega-text-secondary);
                }
                #type-to-filter-input {
                    height: 28px;
                    width: 100%;
                    border: 1px solid var(--omega-input-default-border);
                    border-radius: var(--omega-input-border-radius);
                    padding: 0 0 0 5px;
                }
                .message {
                    margin-right: 12px;
                }
                @media print {
                    .omega-no-print {
                        display: none;
                    }
                }
            `,
        ];
    }
}

customElements.define('omega-filter-bar', OmegaFilterBar);
export default OmegaFilterBar;
