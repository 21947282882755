export default function containsFilter(key, substring) {
    return record => {
        if (!substring) return true;
        let value = record.print(key); 

        if(typeof value !== 'string') {
            value = record.getField(key);
        }
      
        if (
            value === undefined ||
            value === null ||
            value.toString() === null ||
            value.toString() === undefined
        )
            return false;
        return value.toString().toLowerCase().includes(substring.toLowerCase());
    };
}
