/* eslint-disable no-use-before-define */
/* eslint-disable @treasury/style-includes-host-display */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services';
import { Recordset } from '@treasury/FDL';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import { printNode } from '@treasury/utils';
import { css, html, LitElement, nothing, render } from 'lit';
import { mix } from 'mixwith';
import '../../../components/blocking-loader.js';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-template-action-dialog.js';
import InternationalAchPaymentClient from '../clients/international-ach-payment-client.js';
import { convertTemplateToPayment } from '../data/convert-payment-template.ts';
import { columns, fields, filters } from '../data/international-ach-templates-data.js';

class InternationalAchTemplatesContainer extends mix(LitElement).with(
    channelAlertMixin,
    ListeningElementMixin
) {
    static get properties() {
        return {
            actions: Object,
            reportActions: Array,
            alert: Object,
            columns: Array,
            filters: Array,
            fields: Object,
            institution: String,
            reportLinks: Array,
            recordset: Object,
            client: Object,
            showDeleteDialog: Boolean,
            loading: Boolean,
            printing: Boolean,
            updateTemplateFromPayment: Function,
            hasCreateTemplateEntitlement: Boolean,
        };
    }

    constructor() {
        super();
        this.reportLinks = [];
        this.columns = columns;
        this.showDeleteDialog = false;
        this.actions = {
            delete: record => this.openDeleteDialog(record),
            clone: record => console.log(record),
            initiate: record => this.initiateTemplates(record),
            edit: record => this.editTemplate(record),
            approve: this.viewTemplateDetail.bind(this),
            reject: this.viewTemplateDetail.bind(this),
            loadTemplate: record => {
                const id = record.getField('templateId');
                console.log(id);
            },
            clickTemplateName: record => this.viewTemplateDetail(record),
        };
        this.pageTitle = 'International ACH Templates';
        this.rowsPerPage = 25;
        this.hasCreateTemplateEntitlement = false;
    }

    get callToActionText() {
        return this.hasCreateTemplateEntitlement ? 'Create New Template' : '';
    }

    async firstUpdated() {
        if (!this.client) {
            this.client = new InternationalAchPaymentClient();
        }

        this.hasCreateTemplateEntitlement = await EntitlementsService.instance.hasEntitlement(
            'Create International Ach Template'
        );
        this.achConfiguration = await this.client.achConfiguration();
        this.fields = fields(this.client);
        this.filters = filters(this.client);

        this.recordset = new Recordset(this.fields, this.client.getTemplates);
        this.recordset.setInitialPageSize(this.rowsPerPage);
        this.listenTo(this.recordset, 'error', ({ detail }) => {
            this.alert = {
                ...this.alert,
                type: 'error',
                visible: true,
                message: detail.error.message,
            };
        });
        this.listenTo(this.recordset, 'loading', ({ detail }) => {
            const { loading } = detail;
            const hasInitiatePermission = this.recordset.allRecords.some(record =>
                record.getField('userPermissions').includes('CreatePayment')
            );
            if (hasInitiatePermission && !loading) {
                this.reportActions = [
                    {
                        type: 'primary',
                        label: 'Initiate Selected Template',
                        action: () => {
                            const selectedRecord = this.recordset
                                .recordsMatching('selected', true)
                                .pop();
                            this.initiateTemplates(selectedRecord);
                        },
                        isDisabled: () => this.recordset.noRecordsMatch('selected', true),
                    },
                ];
            }
        });
        const reportLinks = [
            {
                title: 'ACH Templates',
                url: `${window.location.origin}/${this.institution}/list`,
            },
        ];
        if (this.achConfiguration.achSettings.allowTaxPayments) {
            reportLinks.push({
                title: 'ACH Tax Templates',
                url: `${window.location.origin}/${this.institution}/ach/tax-templates`,
            });
        }
        if (this.achConfiguration.achSettings.allowChildSupportPayments) {
            reportLinks.push({
                title: 'Child Support Templates',
                url: `${window.location.origin}/${this.institution}/ach/child-support-templates`,
            });
        }
        this.reportLinks = reportLinks;
    }

    async createNewTemplate() {
        return (await getNavService()).navigate(
            'payables.ach.payments.create-international-ach-template'
        );
    }

    async initiateTemplates(templateRecord) {
        this.loading = true;
        const navService = await getNavService();

        try {
            const templateData = await this.client.getTemplateById(
                templateRecord.values.templateId
            );
            const companies = await this.client.getAchCompanies();
            const options = await this.client.getIatAchOptions();
            const { payment, recipients } = convertTemplateToPayment(
                templateData,
                companies,
                options
            );
            this.loading = false;
            navService.navigate('payables.ach.payments.initiate-international-ach-payment', {
                paymentState: {
                    payment,
                    recipients,
                },
                id: templateRecord.values.templateId,
            });
        } catch (e) {
            this.loading = false;
            this.setAlertFromError(e);
            console.error(e);
        }
    }

    async editTemplate(templateRecord) {
        const navService = await getNavService();
        navService.navigate('payables.ach.payments.edit-international-ach-template', {
            id: templateRecord.getField('templateId'),
            paymentState: templateRecord,
        });
    }

    async viewTemplateDetail(templateRecord) {
        const navService = await getNavService();
        navService.navigate('payables.ach.international-ach-template-details', {
            id: templateRecord.getField('templateId'),
        });
    }

    async download({ detail }) {
        this.loading = true;
        try {
            await this.client.downloadTemplateList(detail.filter, detail.type);
        } catch (e) {
            console.error(e);
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async printReport() {
        this.printing = true;
        const printRecordset = new Recordset(this.fields, () => this.recordset.getData());
        await printRecordset.requestUpdate();
        printRecordset.setInitialPageSize(printRecordset.filteredCount);
        this.printing = false;
        const printableDiv = document.createElement('div');
        const printableTable = html`<omega-table
            .recordset=${printRecordset}
            .columnDefinitions=${this.columns}
        ></omega-table>`;
        render(printableTable, printableDiv);
        return printNode(this.pageTitle, printableDiv);
    }

    openDeleteDialog(record) {
        this.selectedRecord = record;
        this.showDeleteDialog = true;
    }

    renderDeleteDialog() {
        if (this.showDeleteDialog) {
            return html`
                <ach-template-action-dialog
                    .showDeleteDialog=${this.showDeleteDialog}
                    .dialogTitle=${'Delete Template'}
                    .message=${'Are you sure you want to delete this template?'}
                    .actionTaken=${'delete'}
                    .dialogAction=${record =>
                        this.client.deleteTemplate(record.getField('templateId'))}
                    .record=${this.selectedRecord}
                    @close=${() => {
                        this.showDeleteDialog = false;
                    }}
                    @delete=${({ detail }) => {
                        this.showDeleteDialog = false;
                        if (detail.successLevel === 1) {
                            this.alert = {
                                ...this.alert,
                                visible: true,
                                type: 'success',
                                message: 'Template deleted successfully',
                            };
                            this.recordset.requestHardUpdate();
                        } else {
                            this.alert = {
                                ...this.alert,
                                type: 'error',
                                visible: true,
                                message: 'Unable to delete template',
                            };
                        }
                    }}
                ></ach-template-action-dialog>
            `;
        }
        return nothing;
    }

    renderBlockingLoader() {
        if (this.loading || this.printing) {
            return html`<blocking-loader></blocking-loader>`;
        }
        return nothing;
    }

    renderAlert() {
        // throw new Error('Method not implemented.');
    }

    render() {
        if (!this.recordset) {
            return nothing;
        }

        return html`
            ${this.renderAlert()} ${this.renderDeleteDialog()} ${this.renderBlockingLoader()}
            <omega-report
                flyout
                autostart
                .title=${this.pageTitle}
                .actions=${this.actions}
                .recordset=${this.recordset}
                .reportActions=${this.reportActions}
                .reportLinks=${this.reportLinks}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .columns=${this.columns}
                .options=${['print', 'download']}
                .downloadOptions=${['PDF', 'CSV']}
                .callToAction=${this.callToActionText}
                .printFunction=${() => this.printReport()}
                @reportDownload=${e => this.download(e)}
                @callToAction=${this.createNewTemplate}
            >
                <omega-filter-bar
                    slot="above-table"
                    id="type-to-filter"
                    .recordset=${this.recordset}
                    @change=${({ detail }) => {
                        this.localFilters = detail;
                    }}
                ></omega-filter-bar
            ></omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                /* redefine some variables because the designs don't match */
                --omega-text-header: #494949;
                --omega-button-icon-color: var(--omega-primary);
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
            @media print {
                .child-support-templates-container {
                    box-shadow: none;
                    border: 1px solid #ccc;
                }
            }
        `;
    }
}

customElements.define('international-ach-templates-container', InternationalAchTemplatesContainer);
export default InternationalAchTemplatesContainer;

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
