// @ts-ignore
import AchChildSupportServices from '@treasury/domain/channel/services/ach/ach-child-support-services.js';
import AchConfigurationServices from '@treasury/domain/channel/services/ach/ach-configuration-services.js';
import DownloadService from '@treasury/domain/channel/services/download/download-service';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';

export default class ChildSupportWorkflowClient {
    async submitPayment(...args: any) {
        return AchChildSupportServices.createPayment(...args);
    }

    async updatePayment(...args: any) {
        return AchChildSupportServices.editPayment(...args);
    }

    async submitFileAsPayment(...args: any) {
        return AchChildSupportServices.createPaymentFromNachaUpload(...args);
    }

    async saveAsTemplate(...args: any) {
        return AchChildSupportServices.saveTemplate(...args);
    }

    async downloadChildSupportPayment(id: number, type: any, pageId: number) {
        //@ts-ignore
        return DownloadService.download(id, type, pageId);
    }

    async downloadChildSupportTemplate(guid: string, type: any) {
        return AchChildSupportServices.downloadChildSupportTemplate(guid, type);
    }

    async print(activityId: number) {
        return UserActivityService.print(activityId);
    }

    async achBanks() {
        return AchChildSupportServices.getAchBanks();
    }

    async achConfiguration() {
        const configuration: any = await AchConfigurationServices.getConfiguration();
        const entitlements = await EntitlementsService.getEntitlements();
        const companies = await AchChildSupportServices.getAchCompanies();
        return {
            companyMatchType: configuration.companyMatchType,
            cutoffTimes: configuration.cutoffTimes,
            holidays: configuration.holidays,
            allowSameDayPayments: configuration.isSameDayAchEnabled,
            entitlements,
            companies,
        };
    }

    async uploadNachaFile(...args: any) {
        return AchChildSupportServices.uploadNachaFile(...args);
    }

    async createTemplate(...args: any) {
        return AchChildSupportServices.saveTemplate(...args);
    }

    async updateTemplate(...args: any) {
        return AchChildSupportServices.updateTemplate(...args);
    }

    async isUniqueTemplateName(name: string) {
        return AchChildSupportServices.isUniqueTemplateName(name);
    }

    async getAchCompanies() {
        return AchChildSupportServices.getAchCompanies();
    }

    async getChildSupportPayment(...args: any) {
        return AchChildSupportServices.getPaymentById(...args);
    }

    async getChildSupportTemplate(guid: string) {
        return AchChildSupportServices.getPaymentTemplate(guid);
    }
}
