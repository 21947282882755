/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    BaseResponseDto,
    ChannelUserModelCollectionDto,
    ChannelUserSearchFilterDto,
    CurrentUserTimeAccessDto,
    GetUsersModelDto,
    LookupModelDto,
    ModelStateDictionaryDto,
    OkResultDto,
    SaveUserActivityRequestDto,
    SearchChannelUsersModelDto,
    SearchUsersDataPageDto,
    SearchUsersFilterDto,
    UserLoginTokenDetailModelDto,
    UserModelDto,
    UserTimeAccessDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class UsersClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Users
     * @name UsersGet
     * @summary Provides the user for the specified Id.
     * @request GET:/users/{id}
     */
    usersGet = (id: number, params: RequestParams = {}) =>
        this.http.request<UserModelDto, any>({
            path: `/users/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersDelete
     * @summary Soft Delete the selected User.
     * @request DELETE:/users/{id}
     */
    usersDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/users/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersPut
     * @summary Updated the passed FI user.
     * @request PUT:/users/{id}
     */
    usersPut = (id: number, userModel: UserModelDto, params: RequestParams = {}) =>
        this.http.request<UserModelDto, ModelStateDictionaryDto>({
            path: `/users/${id}`,
            method: 'PUT',
            body: userModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersGet2
     * @summary Retrieves the FI users.
     * @request GET:/users
     */
    usersGet2 = (params: RequestParams = {}) =>
        this.http.request<GetUsersModelDto, any>({
            path: `/users`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersPost
     * @summary Adds the new FI user
     * @request POST:/users
     */
    usersPost = (userModel: UserModelDto, params: RequestParams = {}) =>
        this.http.request<UserModelDto, ModelStateDictionaryDto>({
            path: `/users`,
            method: 'POST',
            body: userModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersGetAll
     * @summary Retrieves all FI users including deleted users.
     * @request GET:/users/allusers
     */
    usersGetAll = (params: RequestParams = {}) =>
        this.http.request<UserModelDto[], any>({
            path: `/users/allusers`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersGetActive
     * @summary Retrieves the Active FI users.
     * @request GET:/users/activeusers
     */
    usersGetActive = (params: RequestParams = {}) =>
        this.http.request<UserModelDto[], any>({
            path: `/users/activeusers`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags Users
 * @name UsersForgotPassword
 * @summary Validates User for Forgot Password Functionality.
If the provided information is correct it will send an email,
otherwise returns false
 * @request POST:/users/forgotpassword
 */
    usersForgotPassword = (userModel: UserModelDto, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/users/forgotpassword`,
            method: 'POST',
            body: userModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersResetPassword
     * @summary Resets user password.
     * @request POST:/Users/{id}/ResetPassword
     */
    usersResetPassword = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/Users/${id}/ResetPassword`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersManualResetPassword
     * @summary Posts manual password reset.
     * @request POST:/Users/{id}/ManualResetPassword
     */
    usersManualResetPassword = (
        id: number,
        password: LookupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/Users/${id}/ManualResetPassword`,
            method: 'POST',
            body: password,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersChangeStatus
     * @summary Posts user change status.
     * @request POST:/Users/{id}/ChangeStatus/{actionId}
     */
    usersChangeStatus = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<OkResultDto, any>({
            path: `/Users/${id}/ChangeStatus/${actionId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserTimeAccess
     * @summary Gets a User's Time Access
     * @request GET:/users/newusertimeaccess
     */
    usersGetUserTimeAccess = (params: RequestParams = {}) =>
        this.http.request<UserTimeAccessDto, any>({
            path: `/users/newusertimeaccess`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserLoginTokenDetail
     * @summary Gets user login  token details.
     * @request GET:/users/logintokendetail/{userId}
     */
    usersGetUserLoginTokenDetail = (userId: number, params: RequestParams = {}) =>
        this.http.request<UserLoginTokenDetailModelDto, any>({
            path: `/users/logintokendetail/${userId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersUnlockSecureToken
     * @summary Posts user unlock secure token.
     * @request POST:/users/UnlockSecureToken/{userId}/{recollectTokenCredentials}
     */
    usersUnlockSecureToken = (
        userId: number,
        recollectTokenCredentials: boolean,
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, any>({
            path: `/users/UnlockSecureToken/${userId}/${recollectTokenCredentials}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersResetSecureToken
     * @summary Resets user secure token.
     * @request POST:/users/ResetSecureToken/{userId}
     */
    usersResetSecureToken = (userId: number, params: RequestParams = {}) =>
        this.http.request<OkResultDto, any>({
            path: `/users/ResetSecureToken/${userId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersGetChannelUsers
     * @summary Gets channel users.
     * @request GET:/users/channel
     */
    usersGetChannelUsers = (
        query?: {
            /** The  array of company ids. */
            companyIds?: number[] | null;
            /** The array of company unique ids. */
            companyUniqueIds?: string[] | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<UserModelDto[], any>({
            path: `/users/channel`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersSearchChannelUsers
     * @summary Searches for channel users that match the criteria in the request.
     * @request POST:/Users/ChannelSearch
     */
    usersSearchChannelUsers = (
        searchChannelUsersModel: SearchChannelUsersModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<UserModelDto[], any>({
            path: `/Users/ChannelSearch`,
            method: 'POST',
            body: searchChannelUsersModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersSearchUsers
     * @summary Searches for users that match the criteria in the request.
     * @request POST:/users/search
     */
    usersSearchUsers = (
        searchUsersFilter: SearchUsersFilterDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SearchUsersDataPageDto, any>({
            path: `/users/search`,
            method: 'POST',
            body: searchUsersFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description This will return the current users time access details which include if the user has a time access restriction and, if they do, the details of that restriction.
     *
     * @tags Users
     * @name UsersGetCurrentUserTimeAccess
     * @summary Gets the current users time access details.
     * @request GET:/users/usertimeaccess
     */
    usersGetCurrentUserTimeAccess = (params: RequestParams = {}) =>
        this.http.request<CurrentUserTimeAccessDto, any>({
            path: `/users/usertimeaccess`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersSaveUserActivity
     * @summary Saves user activity
     * @request POST:/users/saveuseractivity
     */
    usersSaveUserActivity = (
        saveUserActivityRequest: SaveUserActivityRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaseResponseDto, any>({
            path: `/users/saveuseractivity`,
            method: 'POST',
            body: saveUserActivityRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UsersSearchAllChannelUser
     * @summary Searches for company users that match the filter criteria in the request.
     * @request POST:/users/searchchanneluser
     */
    usersSearchAllChannelUser = (
        channelUserSearchFilter: ChannelUserSearchFilterDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ChannelUserModelCollectionDto, any>({
            path: `/users/searchchanneluser`,
            method: 'POST',
            body: channelUserSearchFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
