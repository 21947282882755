CheckImageController.$inject = [
    '$scope',
    '$modalInstance',
    '$window',
    'entity',
    'accountNumber',
    'checkImageService',
    'downloadPageId',
    'printService',
];

export default function CheckImageController(
    $scope,
    $modalInstance,
    $window,
    entity,
    accountNumber,
    checkImageService,
    downloadPageId,
    printService
) {
    $scope.entity = entity;
    $scope.accountNumber = accountNumber;
    $scope.viewType = 'front';
    $scope.pagination = { currentPage: 1, itemsPerPage: 1, gotoPage: 1 };

    $scope.pageChanged = pageChanged;
    $scope.gotoPage = gotoPage;
    $scope.cancel = cancel;
    $scope.print = print;
    $scope.getCheckOrDepositText = getCheckOrDepositText;
    $scope.displayTransactionDescription = displayTransactionDescription;
    $scope.displayTransactionType = displayTransactionType;

    function init() {
        // if transaction is debit, it can be a single check
        if ($scope.entity.posPayItemId || $scope.entity.isDebit) {
            getCheckImage($scope.entity.checkImageNumber);
        } else {
            checkImageService.getDepositSet($scope.entity.checkImageNumber).then(response => {
                // TODO
                if (response.length > 0) {
                    $scope.checkImages = [response[0].frontImage, response[0].backImage];
                    $scope.viewType = 'front';
                }
                $scope.checkImageList = response;
            });
        }
    }

    function gotoPage() {
        if ($scope.pagination.gotoPage) {
            $scope.pagination.currentPage = $scope.pagination.gotoPage;
            pageChanged();
        }
    }

    function pageChanged() {
        $scope.pagination.gotoPage = $scope.pagination.currentPage;
        getCheckImage($scope.checkImageList[$scope.pagination.currentPage - 1].checkId);
    }

    function cancel() {
        $modalInstance.close();
    }

    function print() {
        $('#sectionView').addClass('no-print');
        printService.userActivityAudit(downloadPageId.CheckImageList);
        $window.print();
    }

    function getCheckImage(checkImageNumber) {
        checkImageService.getCheckImage(checkImageNumber).then(response => {
            $scope.viewType = 'front';
            $scope.checkImages = [response.frontImage, response.backImage];
        });
    }

    function getCheckOrDepositText() {
        if ($scope.entity.isDebit || $scope.entity.posPayItemId) {
            return 'Check';
        }
        return 'Deposit';
    }

    function displayTransactionDescription(entity) {
        return entity.tranCodeDescription != null || entity.description != null;
    }

    function displayTransactionType(entity) {
        return entity.tranCodeType != null;
    }

    init();
}
