import { string } from './primitives';

export const credentialId = string.with
    .label('Serial Number / Credential ID')
    .with.minLength(12)
    .and.maxLength(12)
    .and.inputMask(/[a-zA-Z0-9]/);

export const token = string.with
    .label('Token / Credential Code')
    .with.minLength(6)
    .and.maxLength(6)
    .and.inputMask(/[0-9]/);

export const pin = string.with
    .label('PIN')
    .and.minLength(4)
    .and.maxLength(4)
    .and.inputMask(/[0-9]/);
