export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                entityCaption: '@',
                selectionList: '=',
                selectionField: '@',
                ngDisabled: '=',
                includeSelectAllOption: '=?',
            },
            template: require('./genericMultiSelectDropdownTemplate.html'),
            controller: [
                '$scope',
                '$document',
                function ($scope, $document) {
                    $scope.isDisabled = $scope.ngDisabled;
                    $scope.list = $scope.selectionList;
                    $scope.isAnyChecked = false;
                    $scope.status = {
                        isOpen: false,
                        checkAll: false,
                    };

                    function changeDropdownLabel() {
                        if (
                            $scope.ngModel !== undefined &&
                            $scope.ngModel !== null &&
                            $scope.ngModel.length > 1
                        ) {
                            $scope.allChecked = true;
                            let atLeastOneVisible = false;

                            angular.forEach($scope.selectionList, item => {
                                if ($scope.isFilter) {
                                    if (item.isVisible === true) {
                                        atLeastOneVisible = true;
                                    }
                                    if (!item.isChecked && item.isVisible === true) {
                                        $scope.allChecked = false;
                                    }
                                } else if (!item.isChecked) {
                                    $scope.allChecked = false;
                                }
                            });

                            if ($scope.allChecked) {
                                $scope.selectedValue = 'All Selected';
                                $scope.status.checkAll = true;
                            } else {
                                $scope.selectedValue = 'Multiple Selected';
                                $scope.status.checkAll = false;
                            }
                        } else if (
                            $scope.ngModel !== undefined &&
                            $scope.ngModel !== null &&
                            $scope.ngModel.length === 1
                        ) {
                            $scope.selectedValue = $scope.ngModel[0][$scope.selectionField];
                            $scope.status.checkAll = false;
                        } else {
                            $scope.selectedValue = $scope.entityCaption;
                            $scope.status.checkAll = false;
                        }
                    }

                    $scope.processClickEvent = function (selectAllMode) {
                        $scope.ngModel = [];
                        let selectedCount = 0;

                        angular.forEach($scope.selectionList, item => {
                            if (selectAllMode === true) {
                                if ($scope.status.checkAll) {
                                    $scope.ngModel.push(item);
                                    item.isChecked = $scope.status.checkAll;
                                } else {
                                    item.isChecked = $scope.status.checkAll;
                                }
                            } else if (
                                item.isChecked !== null &&
                                item.isChecked !== undefined &&
                                item.isChecked === true
                            ) {
                                $scope.ngModel.push(item);
                                selectedCount++;
                            }
                        });

                        if (
                            $scope.ngModel.length === $scope.selectedCount &&
                            $scope.selectedCount > 0
                        ) {
                            $scope.status.checkAll = true;
                        } else if ($scope.ngModel.length === $scope.selectionList.length) {
                            $scope.status.checkAll = true;
                        } else {
                            $scope.status.checkAll = false;
                        }
                        if ($scope.includeSelectAllOption === false) {
                            processCustomValidation();
                        }
                        changeDropdownLabel();
                    };

                    function processCustomValidation() {
                        $scope.isAnyChecked = false;
                        angular.forEach($scope.selectionList, item => {
                            if (item.isChecked !== null) {
                                $scope.isAnyChecked = true;
                            }
                        });
                    }

                    function clearSelections() {
                        angular.forEach($scope.selectionList, item => {
                            item.isChecked = false;
                        });

                        // also clear master isChecked
                        $scope.checkAll = false;
                    }

                    (function () {
                        changeDropdownLabel();
                    });

                    $document.on('click', e => {
                        let target = e.target.parentElement;
                        let parentFound = false;
                        while (angular.isDefined(target) && target !== null && !parentFound) {
                            if (
                                (target.className.split(' ').indexOf('menuitem') > -1 ||
                                    target.className.split(' ').indexOf('dropdown') > -1) &&
                                !parentFound
                            ) {
                                parentFound = true;
                            }
                            target = target.parentElement;
                        }
                        if (!parentFound) {
                            $scope.$apply(() => {
                                $scope.status.isOpen = false;
                            });
                        }
                    });

                    $scope.$watch('ngDisabled', newValue => {
                        $scope.isDisabled = newValue;
                    });

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            $scope.list = newValue;
                            $scope.checkAll = false;
                            changeProductType();
                        },
                        true
                    );

                    $scope.$watch(
                        'ngModel',
                        newValue => {
                            changeDropdownLabel();
                            if ($scope.selectedValue == 'All Selected') {
                                $scope.checkAll = true;
                            } else {
                                $scope.checkAll = false;
                            }
                        },
                        true
                    );

                    function changeProductType() {
                        $scope.selectedValue = $scope.entityCaption;
                    }
                },
            ],
        };
    },
];
