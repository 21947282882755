import AchTaxTemplateService from '@treasury/domain/channel/services/ach/ach-tax-template-service.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';
import { UsersService } from '@treasury/domain/channel/services/users/users-service.ts';

export default class AchTaxTemplatesClient {
    async fetchAchTaxTemplates(...args) {
        return AchTaxTemplateService.fetchAchTaxTemplates(...args);
    }

    async fetchAchCompanies(...args) {
        return AchTaxTemplateService.fetchCompanies(...args);
    }

    async fetchSecCodes() {
        return AchTaxTemplateService.fetchSecCodes();
    }

    async fetchAchSettings() {
        return AchTaxTemplateService.fetchAchSettings();
    }

    async initiateTaxPaymentsByIds(recordsValues) {
        return AchTaxTemplateService.initiateTaxPaymentsByIds(recordsValues);
    }

    async downloadAchTaxTemplates(outputType, filterModel) {
        return AchTaxTemplateService.downloadAchTaxTemplates(outputType, filterModel);
    }

    async deleteTemplateById(id) {
        return AchTaxTemplateService.deleteTemplateById(id);
    }

    async updateTemplateStatus(...args) {
        return AchTaxTemplateService.updateTemplateStatus(...args);
    }

    async fetchApprovers(...args) {
        return UsersService.fetchApprovers(...args);
    }

    async entitlements() {
        return EntitlementsService.instance.getEntitlements();
    }
}
