/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { SamlLoginRequestDto, SamlLoginResponseDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class XperienceClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Xperience
     * @name XperienceSamlLogin
     * @summary Attempts to log a user into BO using an Xperience SAML token.
     * @request POST:/Xperience/SamlLogin
     */
    xperienceSamlLogin = (
        samlLoginRequest: SamlLoginRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SamlLoginResponseDto, any>({
            path: `/Xperience/SamlLogin`,
            method: 'POST',
            body: samlLoginRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
