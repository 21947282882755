AchExceptionFilterListController.$inject = ['$scope', 'arpService'];

export default function AchExceptionFilterListController($scope, arpService) {
    // Variables
    $scope.filterObject = {};
    $scope.accounts = [];
    $scope.achExceptionFilters = [];
    $scope.amountOptions = ['Specific Amount', 'Amount Range'];
    $scope.expirationDateOptions = ['Specific Date', 'Date Range'];
    $scope.isDataLoaded = false;
    $scope.disableDownload = true;

    // Functions
    $scope.search = search;
    $scope.reset = reset;

    init();

    function init() {
        reset();
        loadAccounts();
    }

    // Public Functions
    //
    function search(filteredObject) {
        if (filteredObject.accounts) {
            arpService.searchAchExceptionFilters(filteredObject).then(response => {
                $scope.achExceptionFilters = response;
                if (response.length === 0) {
                    $scope.sidebarContainerController.setCollapsed(false);
                    $scope.disableDownload = true;
                } else {
                    $scope.sidebarContainerController.setCollapsed(true);
                    $scope.disableDownload = false;
                }
                $scope.isDataLoaded = true;
            });
        }
    }

    function reset() {
        angular.forEach($scope.accounts, account => {
            account.isChecked = true;
        });
        $scope.filterObject.achCompanyName = '';
        $scope.filterObject.achCompanyId = '';
        angular.forEach($scope.secCodes, code => {
            code.isChecked = true;
        });
        $scope.filterObject.amountType = '';
        $scope.filterObject.amountStart = '';
        $scope.filterObject.amountEnd = '';
        $scope.filterObject.amount = '';
        $scope.filterObject.expirationDateType = '';
        $scope.filterObject.expirationDateStart = '';
        $scope.filterObject.expirationDateEnd = '';
        $scope.filterObject.expirationDate = '';
        $scope.filterObject.expirationFilter = '';
    }

    // Private Functions
    //
    function loadAccounts() {
        arpService.getArpAccounts('WorkAchException').then(response => {
            $scope.filterObject.accounts = $scope.accounts = response;
            angular.forEach($scope.accounts, account => {
                account.isChecked = true;
            });
            search($scope.filterObject);
        });
    }
}
