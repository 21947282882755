/* eslint-disable import/extensions */
import { createUniqueId } from '@treasury/utils';
import { ErrorType, StatusType } from '../security';

/**
 * @typedef { import('@treasury/api/channel').WireListModelDto } WireListDto
 */

export class WireResponse {
    /**
     * @param { WireListDto } dto
     */
    constructor(dto) {
        Object.assign(this, dto);

        /**
         * @type { WireListDto['wires'] }
         */
        this.wires = dto.wires?.map(w => ({
            ...w,
            additionalInformation: w.additionalInformation.map(ai => ai.value),
            duplicatePreventionId: w.duplicatePreventionId ?? createUniqueId(),
        }));
    }

    get authError() {
        const errorCode = this.securityMessage?.errorCode;
        return errorCode && errorCode !== ErrorType.Success ? this.securityMessage?.message : null;
    }

    get validationPhaseComplete() {
        const wireIds = this.wires?.filter(w => w.id).map(w => w.id);
        return wireIds?.length > 0 || this.needsAuth;
    }

    get cumulativeErrorSummary() {
        if (!this.hasErrors) return null;
        if (!this.errorSummary && !this.errorMessage)
            return 'There were some errors found in your wires, please fix the issues and resubmit';

        const errorSummaryMessage = this.errorSummary?.summaryMessage ?? '';
        const errorMessage = this.errorMessage ?? '';
        return errorSummaryMessage + errorMessage;
    }

    get hasErrors() {
        return (
            this.wires?.filter(w => w.errorSummary).length > 0 ||
            this.errorSummary?.details.length > 0
        );
    }

    get needsAuth() {
        return this.securityMessage?.status === StatusType.Verify;
    }

    get isLocked() {
        return this.securityMessage?.errorCode === ErrorType.Locked;
    }

    get isSuccessful() {
        return this.securityMessage?.status === StatusType.Success;
    }
}

export default WireResponse;
