/* eslint-disable no-param-reassign */
export default function openDialog(dialogElement, properties = {}) {
    dialogElement.open = true;

    Object.entries(properties).forEach(([key, value]) => {
        dialogElement[key] = value;
    });

    document.body.appendChild(dialogElement);

    return new Promise(resolve => {
        dialogElement.addEventListener('close', event => {
            resolve(event.detail);
            dialogElement.remove();
        });
    });
}
