import WireUploadFormatsService from '@treasury/domain/channel/services/wires/wire-upload-formats.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service.js';

export default class WireUploadFormatsClient {
    async getFormats() {
        return WireUploadFormatsService.getFormats();
    }

    async getFormat(...args) {
        return WireUploadFormatsService.getFormat(...args);
    }

    async saveFormat(...args) {
        return WireUploadFormatsService.saveFormat(...args);
    }

    async deleteFormat(...args) {
        return WireUploadFormatsService.deleteFormat(...args);
    }

    async getFormatFields() {
        return WireUploadFormatsService.getFormatFields();
    }

    async hasEntitlement(...args) {
        return EntitlementsService.hasEntitlement(...args);
    }
}
