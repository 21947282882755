import { html, LitElement } from 'lit';
import './subTypes/omega-frequency-week-recurring.js';

export class Weekly extends LitElement {
    static get properties() {
        return {
            frequency: { type: Object },
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
            isValidStartDate: { type: Function },
            isValidEndDate: { type: Function },
            isStartDateInvalid: { type: Function },
            isEndDateInvalid: { type: Function },
        };
    }

    setFrequency(e) {
        this.frequency = e.detail;
        this.dispatchEvent(new CustomEvent('change', { detail: this.frequency }));
    }

    render() {
        return html`<omega-frequency-week-recurring
            .frequency=${this.frequency}
            .isStartDateInvalid=${this.isStartDateInvalid}
            .isEndDateInvalid=${this.isEndDateInvalid}
            @change=${this.setFrequency}
            ?disabled=${this.disabled}
            ?readonly=${this.readOnly}
        ></omega-frequency-week-recurring>`;
    }
}

window.customElements.define('omega-frequency-weekly', Weekly);
export default Weekly;
