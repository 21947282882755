import { PaymentHeader, PaymentRecipient } from '@treasury/domain/channel/types/ach';
import { Record } from '@treasury/FDL';
import { RecordsetValidator } from '@treasury/FDL/recordset';

export const balancedValidator = (
    record: Record<PaymentHeader>
): RecordsetValidator<PaymentRecipient> => ({
    name: 'Debit amount total must be equal to credit amount total.',
    validate: (recipients: Array<Record<PaymentRecipient>>) => {
        const achCompany = record.getField('achCompany');
        if (achCompany.batchBalanceRequirements !== 'Balanced') return true;

        return (
            record.getField('creditAmount').toFixed(2) === record.getField('debitAmount').toFixed(2)
        );
    },
});
