/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable sonarjs/cognitive-complexity */
export default class DragAndDropView {
    constructor(containers, tiles, tileType) {
        this.containers = containers;
        this.el = undefined;
        this.tiles = tiles;
        this.tileType = tileType;
        this.init();
    }

    init() {
        const wrapper = document.createElement('div');
        wrapper.classList.add('omega-drag-and-drop-wrapper');
        this.containerElements = this.containers.map(container => {
            const containerElement = this.buildContainer(container, wrapper);
            wrapper.appendChild(containerElement);
            return containerElement;
        });

        let order = 0;
        this.tiles.forEach(tile => {
            const el = this.buildTile(tile, this.tileType);
            if (tile.active === 'true') {
                tile.order = tile.order ?? order;
                order += 1;
                this.containerElements[0].appendChild(el);
            } else {
                this.containerElements[1].appendChild(el);
            }
        });

        this.el = wrapper;
    }

    buildContainer(container, wrapper) {
        const el = document.createElement('ul');
        el.setAttribute('id', container.id);
        el.setAttribute('active-container', container.active);
        el.classList.add('omega_container_droppable', 'sortable');
        const containerHeader = document.createElement('p');
        containerHeader.innerHTML = container.title;
        containerHeader.classList.add('drop_container_header');
        wrapper.appendChild(containerHeader);
        wrapper.appendChild(el);
        return el;
    }

    buildTile(tile, type) {
        const baseTile = this.baseTile(tile);
        switch (type) {
            case 'Delimited':
                return this.delimitedTile(tile, baseTile);
            case 'FixedPosition':
                return this.fixedPositionTile(tile, baseTile);
            default:
                return baseTile;
        }
    }

    buildRemoveTileButton(tile) {
        const buildRemoveTileButton = document.createElement('button');
        buildRemoveTileButton.className = `tile_remove_button ${
            tile.dataset.optional === 'true' && tile.dataset.active === 'true' ? 'show' : ''
        }`;
        buildRemoveTileButton.setAttribute('aria-label', 'Remove tile');
        buildRemoveTileButton.innerHTML = `<i class="fa fa-times"></i>`;
        tile.appendChild(buildRemoveTileButton);
    }

    setTileRemoveState(tile) {
        const buildRemoveTileButton = tile.querySelector('.tile_remove_button');
        buildRemoveTileButton.className = `tile_remove_button ${
            tile.dataset.optional === 'true' && tile.dataset.active === 'true' ? 'show' : ''
        }`;
    }

    baseTile(tile) {
        const el = document.createElement('li');
        el.classList.add('omega_tile_draggable', 'omega_tile_default');
        el.setAttribute('id', `omega-tile-${tile.id}`);
        el.dataset.id = el.dataset.id ?? tile.id;
        el.dataset.order = tile.order;
        el.dataset.type = tile.type;
        el.dataset.text = tile.text;
        el.dataset.name = tile.name;
        el.dataset.optional = el.dataset.optional ?? tile.optional;
        el.dataset.active = tile.active ?? false;

        const title = document.createElement('h3');
        title.classList.add('tile_title');
        title.innerHTML = `${tile.name} ${
            tile.optional === 'true' ? "<span style='font-size: 13px;'>(optional)</span>" : ''
        }`;
        el.appendChild(title);
        const subtitle = document.createElement('p');
        subtitle.classList.add('tile_subtitle');
        subtitle.innerHTML = tile.type ?? '';
        el.appendChild(subtitle);
        this.buildRemoveTileButton(el);
        return el;
    }

    delimitedTile(tile, el) {
        el.classList.add('omega_tile_draggable', 'omega_tile_delimited');
        el.setAttribute('id', `omega-tile-${tile.id}`);
        el.dataset.id = el.dataset.id ?? tile.id;
        el.dataset.order = el.dataset.order ?? tile.order;
        el.dataset.width = el.dataset.width ?? tile.width;
        el.dataset.tileType = 'delimited';

        const order = document.createElement('span');
        order.classList.add('tile_order');
        order.innerHTML = tile.order;
        el.appendChild(order);
        return el;
    }

    fixedPositionTile(tile, el) {
        el.classList.add('omega_tile_draggable', 'omega_tile_fixed_position');
        el.setAttribute('id', `omega-tile-${tile.id}`);
        el.dataset.id = el.dataset.id ?? tile.id;
        el.dataset.order = el.dataset.order ?? tile.order;
        el.dataset.width = el.dataset.width ?? tile.width;
        el.dataset.tileType = 'fixed position';

        const order = document.createElement('span');
        order.classList.add('tile_order');
        order.innerHTML = tile.order;
        el.appendChild(order);
        const positionCount = document.createElement('input');
        const addToCount = document.createElement('button');
        const subtractFromCount = document.createElement('button');
        if (+el.dataset.width <= 1) {
            subtractFromCount.setAttribute('disabled', true);
        }
        positionCount.classList.add('tile_position_count_input');
        positionCount.setAttribute('type', 'number');
        positionCount.setAttribute('min', '1');
        positionCount.setAttribute('max', '99');
        positionCount.setAttribute('value', el.dataset.width);
        el.appendChild(positionCount);
        addToCount.classList.add('tile_position_button', 'add_button');
        el.appendChild(addToCount);
        subtractFromCount.classList.add('tile_position_button', 'subtract_button');
        el.appendChild(subtractFromCount);
        return el;
    }
}
