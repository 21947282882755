import { getChannelAPIPath } from '@treasury/core/http';

CompanyAccountsService.$inject = ['$resource', '$q', '$http'];

export default function CompanyAccountsService($resource, $q, $http) {
    const resourceUrl = `${getChannelAPIPath()}companyaccounts/`;
    const allCutoffTimes = null;
    return {
        getCompanyAccounts,
        getCompanyAccountsWithProductFeature,
        getCompanyAccountsForProductFeature,
        getCompanyAccountsNickNames,
        saveNickNameAccount,
        saveBulkAccountNickNames,
        getCutoffTimes,
        getCutoffTimesByProductType,
        getToAccountPermissions,
        getToAccountPermissionsFromList,
    };

    function getCompanyAccounts(type) {
        return $resource(`${resourceUrl}?type=:type`, { type }).query().$promise;
    }

    function getCompanyAccountsWithProductFeature(type, featureType) {
        return $resource(
            `${resourceUrl}GetWithProductFeature?type=:type&productfeaturetype=:productfeaturetype`,
            {
                type,
                productfeaturetype: featureType,
            }
        ).query().$promise;
    }

    function getCompanyAccountsForProductFeature(
        type,
        featureType,
        reportType,
        oppositeAccountId,
        isForLoanPayment
    ) {
        return $resource(`${resourceUrl}GetForProductFeature`, {
            type,
            productFeatureType: featureType,
            reportType,
            oppositeAccountId,
            isForLoanPayment,
        }).query().$promise;
    }

    function getCompanyAccountsNickNames() {
        return $resource(`${resourceUrl}nickNames`).query().$promise;
    }

    function saveNickNameAccount(account) {
        const resource = $resource(
            `${resourceUrl}/:id`,
            { id: account.id },
            { update: { method: 'PUT' } }
        );
        return resource.update(account).$promise;
    }

    function saveBulkAccountNickNames(accounts) {
        const resource = $resource(
            `${resourceUrl}accountNickNames`,
            {},
            { update: { method: 'PUT', isArray: true } }
        );
        return resource.update(accounts).$promise;
    }

    function getCutoffTimes() {
        return $resource(
            `${resourceUrl}cutoffTimes`,
            {},
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }

    function getCutoffTimesByProductType(productType) {
        return $resource(
            `${resourceUrl}cutoffTimesByProductType/:productType`,
            { productType },
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }

    function getToAccountPermissions(fromAccountId) {
        return $resource(`${resourceUrl}:id/toAccountPermissions`, { id: fromAccountId }).query()
            .$promise;
    }

    function getToAccountPermissionsFromList(fromAccountIdList) {
        return $http({
            method: 'post',
            url: `${resourceUrl}toAccountPermissionsFromList`,
            data: fromAccountIdList,
        }).then(response => response.data);
    }
}
