export const getFileExtension = (type, fileExtensions = ['pdf', 'csv']) => {
    const lowerCaseType = type.toLowerCase();

    if (!fileExtensions.includes(lowerCaseType)) {
        return 'txt';
    }
    return lowerCaseType;
};

export const downloadBlob = (blob, filename) => {
    // Convert blob to a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = filename;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    // Remove link from body
    document.body.removeChild(link);
};

export const toArrayBuffer = file => file.arrayBuffer();

export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // return only the base64 string https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        reader.onerror = error => reject(error);
    });

export const toBinaryString = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
