// not getting these back from the api yet
export const allAccountTypes = [
    {
        id: 1,
        accountName: 'Checking',
    },
    {
        id: 2,
        accountName: 'Savings',
    },
    {
        id: 3,
        accountName: 'Loan',
    },
];

export const allTransactionTypes = ['Debit', 'Credit'];
