/* eslint-disable no-use-before-define */
import { coerceError } from '../../functions/error.helpers';
import { Observable } from './observable';
import { ObservableTransform, ValueProducer } from './observable.types';

/**
 * Represents the source of an {@link Observable | `Observable`}.
 * There is a 1:1 relationship of sources to observables.
 *
 * Enforces a separation between emitting and listening capabilities.
 * Restricts consumers of a stream to only listening for values
 * while producers may do both.
 */
export class ObservationSource<T> extends Observable<T> {
    /**
     * @param valueProducer Function to emit values internally
     * from the `ObservationSource`. Runs deferred after the first subscription.
     *
     * @param deferred If `true`, value production will not begin on subscription.
     * An explicit call to  `init()` must be made.
     */
    constructor(
        private valueProducer?: ValueProducer<T>,
        private deferred = false
    ) {
        super();
    }

    private observable?: Observable<T>;

    private initialized = false;

    /**
     * Emit values as an external consumer of the `ObservationSource` and
     * derived `Observable`, if any.
     */
    public emit(value: T) {
        this.guardCompletion();
        this.lastValue = value;
        this.observers.forEach(callback => callback(value));

        return this;
    }

    public setError(e: Error) {
        this.errorObservers.forEach(callback => callback(e));
    }

    public complete() {
        this.guardCompletion();
        this.completionObservers.forEach(callback => callback());
        this.clearSubscriptions();
        this._completed = true;

        return this;
    }

    public pipeSource<R>(transform: ObservableTransform<T, R>, defer = false) {
        let nextStream = new ObservationSource<R>(() => {
            this.init();
        }, defer);

        let sub = this.subscribe(v => {
            try {
                const transformResult = transform(v);
                if (transformResult instanceof Promise) {
                    transformResult
                        .then(result => nextStream.emit(result))
                        .catch(e => this.setError(coerceError(e)));
                } else {
                    nextStream.emit(transformResult);
                }
            } catch (e) {
                this.setError(coerceError(e));
            }
        });

        nextStream.listenForCompletion(() => {
            sub.unsubscribe();
            (sub as any) = null;
            (nextStream as any) = null;
        });

        return nextStream;
    }

    public filterSource(predicate: (value: T) => boolean, defer = false) {
        let nextStream = new ObservationSource<T>();
        let sub = this.subscribe(v => {
            try {
                if (predicate(v)) {
                    nextStream.emit(v);
                }
            } catch (e) {
                this.setError(coerceError(e));
            }
        }, defer);

        nextStream.listenForCompletion(() => {
            sub.unsubscribe();
            (sub as any) = null;
            (nextStream as any) = null;
        });

        return nextStream;
    }

    public toObservable() {
        this.guardCompletion();

        if (!this.observable) {
            this.observable = new Observable<T>(this);
        }

        return this.observable;
    }

    public subscribe(...args: Parameters<Observable<T>['subscribe']>) {
        const subscription = super.subscribe(...args);

        if (!this.deferred) {
            this.init();
        }

        return subscription;
    }

    /**
     * @param deferred If `true`, do not begin producing values on subscription.
     */
    public listenForCompletion(...args: Parameters<Observable<T>['listenForCompletion']>) {
        const subscription = super.listenForCompletion(...args);

        if (!this.deferred) {
            this.init();
        }

        return subscription;
    }

    /**
     * Initialize the observable with the provided value producer function.
     */
    public init() {
        if (this.initialized || !this.valueProducer) {
            return;
        }

        this.initialized = true;

        this.valueProducer({
            emit: (...args) => this.emit(...args),
            complete: (...args) => this.complete(...args),
        });
    }
}
