import convertStringToPascalCase from './convert-string-to-pascal-case.js';

/**
 * We can choose to keep the exported functions instead, went with the class for utility consistency but that may be unnecessary
 */
// export const parseSortColumns = sortCols =>
//     sortCols.map(col => ({
//         descending: col.sort === 'descending',
//         name: col.field.charAt(0).toUpperCase() + col.field.slice(1),
//     }));

// export const getPageNumber = (start, count) => Math.ceil((start + 1) / count) || 1;

export class PagingFormatters {
    static parseSortColumns(sortCols) {
        return sortCols.map(col => ({
            Descending: col.sort === 'descending',
            Name: convertStringToPascalCase(col.field),
        }));
    }

    static parseLatestSortColumn(sortCols) {
        return this.parseSortColumns(sortCols).pop();
    }

    static getPageNumber(start, count) {
        return Math.ceil((start + 1) / count) || 1;
    }
}

export default PagingFormatters;
