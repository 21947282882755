import { render, html } from 'lit';

export function openDialog({ title = '', content = '', actions = '', subHeader = '' }) {
    console.warn(
        'This openDialog() function is deprecated. Use the one defined in open-dialog.js instead.'
    );

    let dialog;
    return new Promise(resolve => {
        function renderWithResolve(slot) {
            if (typeof slot === 'function') {
                return slot(resolve);
            }
            return slot;
        }

        const div = document.createElement('div');

        render(
            html`<omega-dialog .dialogTitle=${title} id="from-open-dialog-function">
                <div slot="sub-header">${subHeader}</div>
                <div slot="content">${renderWithResolve(content)}</div>
                <div slot="actions">${renderWithResolve(actions)}</div>
            </omega-dialog>`,
            div
        );

        dialog = div.querySelector('omega-dialog');
        document.body.appendChild(dialog);
        dialog.showModal();
        dialog.addEventListener('close', () => resolve(null));
    }).finally(() => {
        dialog.open = false;
        setTimeout(() => dialog.remove(), 500);
    });
}
