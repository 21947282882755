import { id, datePicker, money, string, date } from '@treasury/policy/primitives';
import { companyName, secCode } from '@treasury/policy/company.js';
import { accountNumber, accountType } from '@treasury/policy/account.js';
import { returnActivityChangeCodes } from '@treasury/policy/ach';

export const reportFields = {
    achReturnId: id.thatIs.readOnly(),
    effectiveDate: datePicker.thatIs.readOnly().with.label('Original Effective Date'),
    receivedDate: datePicker.thatIs.readOnly().with.label('Date Return Received'),
    achCompanyName: companyName.thatIs.readOnly().with.label('ACH Company'),
    amount: money.thatIs.readOnly().with.label('Amount'),
    fullReturnReason: returnActivityChangeCodes.thatIs.readOnly().with.label('Return Reason Code'),
};

export const detailFields = {
    ...reportFields,
    discretionaryData: string.thatIs.readOnly(),
    originalEffectiveDate: datePicker.thatIs.readOnly().with.label('Original Effective Date'),

    returnReasonDescription: string.thatIs.readOnly().with.label('Return Reason Code'),
    traceNumber: accountType.thatIs.readOnly().with.label('Trace Number'),
    dateOfDeath: date.thatIs.readOnly().with.label('Date of Death'),
    entryDescription: string.thatIs.readOnly(),
    secCode: secCode.thatIs.readOnly().with.label('SEC Code'),
    recipientName: string.thatIs.readOnly().with.label('Recipient Name'),
    accountNumber: accountNumber.thatIs.readOnly().with.label('Recipient Account Number'),
    accountType: accountType.thatIs.readOnly().with.label('Account Type'),
    identificationNumber: id.thatIs.readOnly().with.label('Identification Number'),
    recipientFinancialInstitution: string.thatIs
        .readOnly()
        .with.label('Recipient Financial Institution'),
};
