/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

export default class StateRequests {
    static async getStates() {
        return (await TmHttpClient.getInstance()).request(`states`, {
            maxAgeInSeconds: 3600,
        });
    }
}
