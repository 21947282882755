import { AccountService } from '@treasury/domain/channel/services/account/account.service.ts';

TransferTemplateListController.$inject = [
    '$scope',
    '$state',
    'toaster',
    '$uibModal',
    '$stateParams',
    '$location',
    'entitlementsService',
    'downloadPageId',
    '$filter',
    'companyAccountsService',
    'internalTransfersService',
];

export default function TransferTemplateListController(
    $scope,
    $state,
    toaster,
    $uibModal,
    $stateParams,
    $location,
    entitlementsService,
    downloadPageId,
    $filter,
    companyAccountsService,
    internalTransfersService
) {
    $scope.CurrentUser = null;
    $scope.downloadPageId = downloadPageId.TransferTemplateList;
    $scope.status = $stateParams.Status;
    $scope.showStatusFilter = true;
    $scope.search = search;
    $scope.reset = reset;
    $scope.goToRecurringTransferList = goToRecurringTransferList;
    $scope.goToTransferList = goToTransferList;
    $scope.amountTypeLists = ['', 'Specific Amount', 'Amount Range'];
    $scope.statusList = [];
    $scope.filter = {};
    $scope.filterSearch = filterSearch;
    $scope.viewTemplateDetail = viewTemplateDetail;
    $scope.approveOrRejectTemplate = approveOrRejectTemplate;
    $scope.initiateTransferPayment = initiateTransferPayment;
    $scope.templatesSelectedState = templatesSelectedState;
    $scope.selectOrDeselectAllTemplates = selectOrDeselectAllTemplates;
    $scope.selectTemplate = selectTemplate;
    $scope.disableCheckbox = disableCheckbox;
    $scope.initiatePayment = initiatePayment;
    $scope.accountListFrom = [];
    $scope.accountListTo = [];
    $scope.accountListBoth = [];
    $scope.canCreateInternalTransferTemplate =
        entitlementsService.hasEntitlement('Create Template');
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.canInitiate = canInitiate;
    $scope.goToEditTemplateDetail = goToEditTemplateDetail;
    $scope.deleteTemplate = deleteTemplate;
    $scope.searchObj = { text: null };
    $scope.filterObject = {
        templateName: '',
        transferType: [],
        fromAccounts: [],
        toAccounts: [],
        status: [],
        amountOperator: '',
        amountStart: 0,
        amountEnd: 0,
        amountSpecific: 0,
        accountOperator: 'Both',
    };
    $scope.disableDownload = true;

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.createTemplate = function () {
        $state.go('payables.createtransfertemplate');
    };

    function disableCheckbox(template) {
        return !canInitiate(template);
    }

    function selectTemplate(template) {
        // selection of template behaves like a radio.
        // when you check one, you have to uncheck the others.
        angular.forEach($scope.filteredTransfersTemplates, itTemplate => {
            itTemplate.isSelected = false;
        });

        template.isSelected = true;
    }

    function search() {
        getTransferTemplateList();
    }

    function getTransferTemplateList() {
        internalTransfersService.getTransferTemplateList($scope.filterObject).then(response => {
            $scope.templateList = response;

            if (response.length > 0) {
                setApprovalAttributes();
                $scope.disableDownload = false;
            } else {
                $scope.disableDownload = true;
            }

            $scope.searchObj.text = null;
            $scope.filterSearch($scope.searchObj.text);
        });
    }

    function setApprovalAttributes() {
        $scope.templateList.map(setTransferTemplateApprovalAttributes);
    }

    function setTransferTemplateApprovalAttributes(template) {
        template.approvalAttributes = {
            approvalEntity: 'template',
            approvalCount: template.completedApprovalCount,
            amount: template.amount,
            createdBy: template.createdBy,
            updatedBy: template.updatedBy,
            productId: template.id,
        };
        return template;
    }

    function initiateTransferPayment() {
        const templates = [];
        angular.forEach($scope.filteredTransfersTemplates, itTemplate => {
            if (itTemplate.isSelected) {
                templates.push(itTemplate);
            }
        });
        internalTransfersService.setSelectedTemplates(templates);

        if (templates.length > 1) {
            alert('cannot initiate batch payments');
        } else {
            $state.go('payables.transfers');
        }
    }

    function initiatePayment(template) {
        const templates = [];
        templates.push(template);
        internalTransfersService.setSelectedTemplates(templates);
        $state.go('payables.transfers');
    }

    function templatesSelectedState() {
        let result = true;
        angular.forEach($scope.filteredTransfersTemplates, itTemplate => {
            if (itTemplate.isSelected) {
                result = false;
            }
        });
        return result;
    }

    function selectOrDeselectAllTemplates() {
        angular.forEach($scope.filteredTransfersTemplates, itTemplate => {
            if (itTemplate.status === 'Ready') {
                itTemplate.isSelected = $scope.filter.selectAll;
            }
        });
    }

    function viewTemplateDetail(template, type) {
        $state.go('payables.transfertemplatedetailsview', { id: template.id, type });
    }

    function goToEditTemplateDetail(template, type) {
        $state.go('payables.edit-transfer-template', { id: template.id, type });
    }

    function deleteTemplate(template) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeTransferStatusDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return 'Delete';
                },
                paymentType() {
                    return 'Template';
                },
            },
        });
        modalInstance.result.then(comments => {
            internalTransfersService.deleteTransferTemplate(template.id).then(() => {
                toaster.delete('Transfer Template');
                search();
            });
        });
    }

    function approveOrRejectTemplate(template, type) {
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeTransferStatusDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                paymentType() {
                    return 'Template';
                },
            },
        });
        modalInstance.result.then(comments => {
            internalTransfersService.approveOrRejectTransferTemplate(type, template.id).then(() => {
                if (type === 'Approve') {
                    toaster.approve('Transfer Template');
                } else if (type === 'Reject') {
                    toaster.reject('Transfer Template');
                }
                search();
            });
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredTransfersTemplates = $filter('filter')($scope.templateList, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.templateList ||
            $scope.templateList.length <= 0
        ) {
            return true;
        }

        return (
            (!!row.templateName &&
                row.templateName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.fromAccount &&
                !!row.fromAccount.number &&
                row.fromAccount.number
                    .toLowerCase()
                    .indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.toAccount &&
                !!row.toAccount.number &&
                row.toAccount.number.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.transferType &&
                !!row.transferType &&
                row.transferType.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.amount &&
                $filter('currency')(row.amount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.status &&
                row.status.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1)
        );
    }

    function reset() {
        $scope.filterObject.templateName = '';

        angular.forEach($scope.transferTypeList, item => {
            item.isChecked = true;
        });

        angular.forEach($scope.statusList, item => {
            item.isChecked = true;
        });

        angular.forEach($scope.accountListFrom, item => {
            item.isChecked = true;
        });

        angular.forEach($scope.accountListTo, item => {
            item.isChecked = true;
        });

        angular.forEach($scope.accountListBoth, item => {
            item.isChecked = true;
        });

        $scope.filterObject.amountOperator = '';
        $scope.filterObject.amountStart = 0;
        $scope.filterObject.amountEnd = 0;
        $scope.filterObject.amountSpecific = 0;
    }

    function goToRecurringTransferList() {
        $state.go('payables.recurringTransferList');
    }

    function goToTransferList() {
        $state.go('payables.transferList');
    }

    // private functions
    function loadTransferTypeList() {
        $scope.transferTypeList = [
            { key: 'One-to-One', value: 'One-to-One', isChecked: true },
            { key: 'One-to-Many', value: 'One-to-Many', isChecked: true },
            { key: 'Many-to-One', value: 'Many-to-One', isChecked: true },
        ];
    }

    function loadAmountTypeList() {
        $scope.amountTypeList = [
            {
                name: 'Specific Amount',
            },
            {
                name: 'Amount Range',
            },
        ];
    }

    function loadStatusList() {
        $scope.statusList = [
            { key: 'PendingApproval', value: 'Pending Approval', isChecked: true },
            { key: 'Ready', value: 'Ready', isChecked: true },
            { key: 'Rejected', value: 'Rejected', isChecked: true },
        ];
    }

    $scope.resetValues = function (type) {
        if (type === 'amount') {
            if (
                $scope.filterObject.amountOperator === 'Specific Amount' ||
                $scope.filterObject.amountOperator === 'Amount Range' ||
                $scope.filterObject.amountOperator === '--Select Amount--'
            ) {
                $scope.filterObject.amountStart = null;
                $scope.filterObject.amountEnd = null;
            }
        }
    };

    function getProductFeature() {
        return !$scope.createTemplateMode ? 'InternalTransfer' : 'InternalTransferTemplate';
    }

    function loadAccounts(type) {
        companyAccountsService
            .getCompanyAccountsWithProductFeature(type, getProductFeature())
            .then(accounts => {
                if (
                    accounts.length > 0 &&
                    accounts[0] !== null &&
                    typeof accounts[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = accounts[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                if (type === 'From') {
                    $scope.accountListFrom = accounts;

                    angular.forEach($scope.accountListFrom, item => {
                        item.isChecked = true;
                    });

                    $scope.filterObject.fromAccounts = $scope.accountListFrom;
                    loadAccounts('To');
                } else {
                    $scope.accountListTo = accounts;
                    angular.forEach($scope.accountListTo, item => {
                        item.isChecked = true;

                        // mail merge accountListFrom and AccountListTo into accountListBoth
                        let toAccountExists = false;
                        angular.forEach($scope.accountListBoth, account => {
                            if (item.number === account.number) {
                                toAccountExists = true;
                            }
                        });
                        if (toAccountExists === false) {
                            $scope.accountListBoth.push(angular.copy(item));
                        }
                    });

                    if ($scope.status) {
                        $scope.showStatusFilter = false;
                        $scope.filterObject.status = [];
                        $scope.filterObject.status.push({
                            key: 'Ready',
                            value: 'Ready',
                            isChecked: true,
                        });
                        angular.forEach($scope.statusList, item => {
                            if (item.key !== 'Ready') {
                                item.isChecked = false;
                            }
                        });
                    }

                    $scope.filterObject.toAccounts = $scope.accountListTo;
                    getTransferTemplateList();
                }
            });
    }

    $scope.changeAccountType = function (value) {
        if (value === 'Both') {
            angular.forEach($scope.accountListBoth, account => {
                account.isChecked = true;
            });
        } else if (value === 'From' && $scope.filterObject.accountOperator !== 'FromTo') {
            angular.forEach($scope.accountListFrom, account => {
                account.isChecked = true;
            });
        } else if (value === 'To' && $scope.filterObject.accountOperator !== 'FromTo') {
            angular.forEach($scope.accountListTo, account => {
                account.isChecked = true;
            });
        }
        if (
            $scope.filterObject.accountOperator === null ||
            $scope.filterObject.accountOperator === undefined
        ) {
            $scope.filterObject.accountOperator = value;
        } else if (value === 'From' && $scope.filterObject.accountOperator == 'FromTo') {
            $scope.filterObject.accountOperator = 'To';
        } else if (value === 'To' && $scope.filterObject.accountOperator == 'FromTo') {
            $scope.filterObject.accountOperator = 'From';
        } else if (value === 'From' || value === 'To') {
            if (
                ($scope.filterObject.accountOperator === 'From' && value === 'To') ||
                ($scope.filterObject.accountOperator === 'To' && value === 'From')
            ) {
                $scope.filterObject.accountOperator = 'FromTo';
            } else {
                $scope.filterObject.accountOperator = value;
            }
        } else {
            $scope.filterObject.accountOperator = value;
        }
    };

    $scope.scrollLocation = function (element) {
        $location.hash(element);
    };

    function hasAccessPermissions(beneficiary, permissionType) {
        let result = false;
        angular.forEach(beneficiary.permissions, permission => {
            if (permission.permission === permissionType) result = true;
        });
        return result;
    }

    function canInitiate(template) {
        if (template == null || template.status !== 'Ready') {
            return false;
        }

        const permissionType = 'Initiate';
        return hasAccessPermissions(template, permissionType);
    }

    function loadUserProfile() {
        AccountService.getInstance().then(service => {
            service.getCurrentUserAccountSettings().then(response => {
                $scope.CurrentUser = response;
            });
        });
    }

    function init() {
        loadUserProfile();
        loadStatusList();
        loadTransferTypeList();
        loadAmountTypeList();
        loadAccounts('From');
    }

    init();
}
