RemoteDepositCaptureRedirectController.$inject = ['$stateParams'];

export default function RemoteDepositCaptureRedirectController($stateParams) {
    const vm = this;

    const { reason } = $stateParams;

    function init() {
        if (reason === 'authentication-failure') {
            vm.message = 'Your credentials are invalid. Please contact your Financial Institution.';
        } else if (reason === 'timeout') {
            vm.message =
                'Your session has timed out. Please return to Treasury Management to begin a new session.';
        } else if (reason === 'logout') {
            vm.message = 'You have successfully logged out of your session.';
        }
    }

    init();
}
