/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable lines-between-class-members */
import { WorkflowActions, WorkflowTypes } from '@treasury/domain/channel/types/ach';
import '@treasury/omega/components/omega-alert.js';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-download-bar';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import '../../components/ach-payment-recipients-table.js';
import '../../components/ach-save-as-template-dialog.js';
import {
    createChildSupportPaymentColumns,
    uploadNachaFileRecipientsColumns,
} from '../data/recipient-recordset.js';
import '../parts/child-support-payment-header';
import { recipientDetails } from '../parts/child-support-payment-recipient-details';

export class ConfirmStep extends LitElement {
    @property({ type: Boolean })
    loading = false;
    @property({ type: Object })
    paymentHeaderRecord!: any;
    @property({ type: Number })
    activeStep!: number;
    @property({ type: String })
    workflowType!: string;
    @property({ type: Object })
    recipientsRecordset: any;
    @property({ type: Array })
    recipients!: Array<any>;
    @property({ type: Boolean })
    isFromFile!: boolean;
    @property({ type: Boolean })
    isFromPayment!: boolean;
    @property({ type: Boolean })
    openSaveAsTemplate = false;
    @property({ type: Boolean })
    openApplyUpdatesToTemplate!: boolean;
    @property({ type: Boolean })
    hasTemplatePermission!: boolean;
    @property({ type: Boolean })
    savedAsTemplate!: boolean;
    @property({ type: Function })
    // eslint-disable-next-line @typescript-eslint/ban-types
    validateTemplateName!: Function;
    @property({ type: String })
    paymentCreationType!: string;
    @property({ type: Array })
    entitlements!: Array<any>;
    allDownloadOptions = ['CSV', 'NACHA', 'PDF'];
    templateDownloadOptions = ['CSV', 'PDF'];

    get columns() {
        if (this.isFromFile) return uploadNachaFileRecipientsColumns(false);
        return createChildSupportPaymentColumns(false);
    }

    goToFirstStep() {
        this.dispatchEvent(new CustomEvent('goToStep', { detail: { activeStep: 0 } }));
    }

    goToAchActivity() {
        this.dispatchEvent(new CustomEvent('goToAchActivity'));
    }

    goToChildSupportTemplates() {
        this.dispatchEvent(new CustomEvent('goToChildSupportTemplates'));
    }

    hasTemplateEntitlements() {
        return this.entitlements.some(
            e =>
                e.permission === 'Partial Edit Ach Template' ||
                e.permission === 'Full Edit Ach Template'
        );
    }

    download(e: any) {
        dispatchEvent(new CustomEvent('download', { detail: { type: e.detail.downloadType } }));
    }

    print() {
        dispatchEvent(new CustomEvent('print'));
    }

    closeTemplateDialog() {
        this.openSaveAsTemplate = false;
    }
    saveTemplateDialog() {
        this.dispatchEvent(new CustomEvent('saveAsTemplate'));
    }

    renderTemplateDialog() {
        return html`<ach-save-as-template-dialog
            .paymentHeaderRecord=${this.paymentHeaderRecord}
            .open=${this.openSaveAsTemplate}
            .validateTemplateName=${this.validateTemplateName}
            @close=${() => this.closeTemplateDialog()}
            @save=${() => this.saveTemplateDialog()}
        ></ach-save-as-template-dialog>`;
    }

    renderCreateTemplateButton() {
        if (!this.isFromPayment && this.paymentCreationType !== WorkflowActions.Create)
            return nothing;
        if (
            this.paymentCreationType === WorkflowActions.Edit ||
            this.workflowType === WorkflowTypes.Template
        )
            return nothing;
        if (!this.hasTemplatePermission || this.savedAsTemplate) return nothing;
        return html`<omega-button
            class="save-as-template"
            @click=${() => {
                this.openSaveAsTemplate = true;
            }}
            >Save as Template</omega-button
        >`;
    }

    renderApplyUpdatesToTemplateAlert() {
        return html`<omega-alert
            class="apply-updates-to-template-dialog"
            type="warning"
            posture="assertive"
            .isVisible=${this.openApplyUpdatesToTemplate}
            .closeable=${true}
            title="Are you sure?"
            @close=${() => {
                this.openApplyUpdatesToTemplate = false;
            }}
        >
            <p>Are you sure you want to apply updates to the template?</p>
            <omega-button
                class="apply-updates"
                slot="actions"
                type="primary"
                @click=${() => {
                    this.dispatchEvent(new CustomEvent('updateTemplate'));
                    this.openApplyUpdatesToTemplate = false;
                }}
                >Apply Updates</omega-button
            >
            <omega-button
                class="cancel"
                slot="actions"
                type="secondary"
                @click=${() => {
                    this.openApplyUpdatesToTemplate = false;
                }}
                >Cancel</omega-button
            >
        </omega-alert>`;
    }

    renderApplyUpdatesToTemplateButton() {
        if (
            this.paymentCreationType === WorkflowActions.InitiateFromTemplate &&
            this.hasTemplateEntitlements() &&
            !this.isFromPayment
        ) {
            return html`<omega-button
                class="apply-updates-to-template"
                @click=${() => {
                    this.openApplyUpdatesToTemplate = true;
                }}
                >Apply Updates to Template</omega-button
            >`;
        }
        return nothing;
    }

    renderCreateNewPaymentButton() {
        if (this.workflowType === WorkflowTypes.Template) return nothing;
        return html`<omega-button
            class="create-new-payment"
            @click=${() => this.goToFirstStep()}
            type="primary"
            >Create New Payment</omega-button
        >`;
    }

    renderGoToPaymentActivity() {
        if (this.workflowType === WorkflowTypes.Template) return nothing;
        return html`<omega-button
            class="ach-activity"
            type="link"
            @click=${() => this.goToAchActivity()}
            >ACH Payment Activity</omega-button
        >`;
    }

    renderCloseButton() {
        if (this.workflowType === WorkflowTypes.Payment) return nothing;
        return html`<omega-button
            class="child-support-templates"
            type="link"
            @click=${() => this.goToChildSupportTemplates()}
            >Child Support Templates</omega-button
        >`;
    }

    renderActionButtons() {
        return html`${this.renderCreateNewPaymentButton()} ${this.renderCreateTemplateButton()}
        ${this.renderApplyUpdatesToTemplateButton()}
        ${this.renderGoToPaymentActivity()}${this.renderCloseButton()}`;
    }

    render() {
        const type = this.paymentHeaderRecord.getField('type');
        const paymentCreationTypeTitle = type.charAt(0).toUpperCase() + type.slice(1);
        const downloadOptions =
            type === WorkflowTypes.Payment ? this.allDownloadOptions : this.templateDownloadOptions;
        return html` ${this.renderTemplateDialog()}${this.renderApplyUpdatesToTemplateAlert()}
            <div class="omega-form-header">
                <h2 class="step-title">${paymentCreationTypeTitle} Confirmation</h2>
                <omega-download-bar
                    pageTitle=""
                    .actions=${['download', 'print']}
                    .hideActionLabels=${false}
                    .downloadOptions=${downloadOptions}
                    @print=${() => this.print()}
                    @download=${(e: any) => this.download(e)}
                ></omega-download-bar>
            </div>
            <child-support-payment-header
                expanded
                .paymentHeaderRecord=${this.paymentHeaderRecord}
                .recipientsRecordset=${this.recipientsRecordset}
                .activeStep=${this.activeStep}
            ></child-support-payment-header>
            <ach-payment-recipients-table
                .columns=${this.columns}
                .recipients=${this.recipients}
                .recipientsRecordset=${this.recipientsRecordset}
                .detailFunction=${recipientDetails}
                display-toggle-all
            ></ach-payment-recipients-table>
            <omega-button-bar position="bottom" alignment="left">
                ${this.renderActionButtons()}
            </omega-button-bar>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            .omega-form-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .step-title {
                margin: 0;
            }
        `;
    }
}

customElements.define('confirm-step', ConfirmStep);
