/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AchSettingsModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchSettingsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchSettings
     * @name AchSettingsGet
     * @summary Returns ACH Product Configuration Settings
     * @request GET:/achSettings
     */
    achSettingsGet = (params: RequestParams = {}) =>
        this.http.request<AchSettingsModelDto, any>({
            path: `/achSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
