/* eslint-disable import/extensions */
import { Record } from '@treasury/FDL/record';
import {
    achDiscretionaryData,
    achEntryDescription,
    achPaymentAudit,
    achPaymentName,
    achPaymentStatus,
    achTransactionId,
    restrictedPayment
} from '@treasury/policy/ach';
import {
    internationalAchOffsetAccount,
    internationalAchOriginatorCompany,
    internationalAchOriginatorCompanyId,
    internationalAchOriginatorCountry,
    internationalAchOriginatorCurrencyCode,
    internationalAchOriginatorZipCode,
    internationalAchPaymentFrequency,
    internationalForeignExchangeReference,
    internationalForeignExchangeReferenceNumber
} from '@treasury/policy/ach/international-ach.js';
import { nachaCity, nachaCountryCode, nachaStreetAddress } from '@treasury/policy/nacha';
import { boolean, money, number, string } from '@treasury/policy/primitives';

import { getNextBusinessDay } from '@treasury/domain/dates';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import doesAchCompanyAllowUnbalancedPayments from '@treasury/domain/shared/utilities/does-ach-company-allow-unbalanced-payments.js';
import internationalAchEffectiveDateDisableFunctions from '@treasury/domain/shared/utilities/international-ach-effective-date-disable-functions.js';
import isAchCompanyBalanced from '@treasury/domain/shared/utilities/is-ach-company-balanced';
import { makeBankingHolidayCheck } from '@treasury/domain/shared/utilities/is-date-in-array-of-banking-holidays.js';
import isPrefundingEnabledForAchCompany from '@treasury/domain/shared/utilities/is-prefunding-enabled-for-ach-company.js';
import isValueInArrayOfObjectsFindByKey from '@treasury/domain/shared/utilities/is-value-in-array-of-objects-find-by-key.js';
import { isExistingPayment } from '../../ach-child-support/data/payment-header-record.js';
import InternationalAchPaymentClient from '../clients/international-ach-payment-client.js';
import {
    foreignExchangeReferenceIndicatorDisabled,
    getDestinationCurrencyCode,
    getForeignExchangeIndicators,
    getForeignExchangeReferenceIndicators
} from './cascading-select-logic.ts';

const isPrefundingFeatureFlagEnabled = entitlements =>
    isValueInArrayOfObjectsFindByKey(
        entitlements,
        'permission',
        'Feature.ACH.AllowUnbalancedPayments'
    );

const hasRestrictedBatchEntitlement = entitlements =>
    isValueInArrayOfObjectsFindByKey(entitlements, 'permission', 'Restricted Batch');

export const getPaymentFieldValue = (payment, field) => {
    if (!payment) return '';

    if (field === 'restricted') return !!payment[field];

    return payment[field] ?? '';
};

const notStep1 = record =>
    /* This shows as step 1 in the UI bc arrays start at position 0 */
    record.getField('step') !== 0;
const isNachaUploadStep2 = record => {
    const step2 = record.getField('step') === 1;
    return step2 && record.getField('nachaUpload');
};
const onConfirmationStep = record => record.getField('step') === 2;

const hasEntitlement = (entitlements, entitlementString) =>
    !!entitlements.find(entitlement => entitlement.permission === entitlementString);

const editingIsRestricted = (status, entitlements) => {
    const restrictedStatuses = new Set(['Expired', 'Approval Rejected', 'Initiated']);
    const restrictedStatus = restrictedStatuses.has(status);

    return restrictedStatus && !hasEntitlement(entitlements, 'Full Edit International ACH Payment');
};

export const paymentFieldsCopy = (
    payment,
    configurationDetails = { entitlements: [{ permission: '' }] },
    client = new InternationalAchPaymentClient(),
    editingMode,
    templateMode
) => {
    const { entitlements, holidays, companyMatchType, iatAchOptions } = configurationDetails;
    const staticOptions = iatAchOptions.values;

    const status = payment ? payment.status : '';
    const restrictedEditing = editingIsRestricted(status, entitlements);
    const fullEditAllowed = templateMode
        ? hasEntitlement(entitlements, 'Full Edit International Ach Template')
        : hasEntitlement(entitlements, 'Full Edit International ACH Payment');
    const partialEditAllowed = templateMode
        ? hasEntitlement(entitlements, 'Partial Edit International Ach Template')
        : hasEntitlement(entitlements, 'Partial Edit International ACH Payment');
    const editingIsNotPermitted = () =>
        templateMode ? editingMode && !fullEditAllowed : restrictedEditing;

    const nextBusinessDay = getNextBusinessDay(holidays);

    const isFlagEnabled = isPrefundingFeatureFlagEnabled(entitlements);
    const isRestrictedBatchEntitlementEnabled = () => hasRestrictedBatchEntitlement(entitlements);
    const doesMatchCriteriaContainCompanyName = () =>
        companyMatchType >= 1 && editingIsNotPermitted();
    const doesMatchCriteriaContainCompanyID = () => companyMatchType >= 2;
    const doesMatchCriteriaContainEntryDescription = () => companyMatchType >= 4;
    const doesMatchCriteriaContainDiscretionaryData = () => companyMatchType >= 5;

    const dateDisabledFunction = internationalAchEffectiveDateDisableFunctions(
        makeBankingHolidayCheck(holidays)
    );

    const companyHasPrefundingAndUnbalanced = record => {
        if (!isFlagEnabled) return false;
        const company = record.getField('achCompany');
        if (!company) return false;
        return (
            isPrefundingEnabledForAchCompany(company) &&
            doesAchCompanyAllowUnbalancedPayments(company)
        );
    };
    const destinationNotSelected = record => !record.getField('destinationCountryCode');
    const foreignExchangeIndicatorNotSelected = record =>
        !record.getField('foreignExchangeIndicator');
    const exchangeIsFV = record => record.getField('foreignExchangeIndicator') === 'FV';
    const inEditingMode = () => editingMode;
    const customValidators = {
        requiredWhenFV: {
            name: 'required when currency is fixed to fixed',
            validate: (modelValue, viewValue, record, option, field) => {
                const FV = exchangeIsFV(record);
                const value = record.getField(field);
                return FV ? !!value : true;
            },
        },
    };

    const fields = [
        {
            field: 'name',
            fieldType: achPaymentName.with
                .label(templateMode ? 'Template Name' : 'Payment Name')
                .thatIs.required()
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.readOnlyWhen(notStep1)
                .with.maxLength(50)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'name'),
        },
        {
            field: 'achCompany',
            fieldType: internationalAchOriginatorCompany.with
                .label('ACH Company Name')
                .with.options({
                    fetch: client.getAchCompanies,
                    text: record => `${record.companyName}`,
                    value: record => record,
                })
                .with.hashFunction(a => a?.id)
                .with.template(record => {
                    if (typeof record === 'string') {
                        return record;
                    }
                    return record?.companyName;
                })
                .with.filtering()
                .and.search({
                    title: 'Search ACH Companies',
                    columns: [
                        {
                            label: 'Company Name',
                            field: 'companyName',
                        },
                        {
                            label: 'Company ID',
                            field: 'companyId',
                        },
                        {
                            label: 'Entry Description',
                            field: 'entryDescription',
                        },
                        {
                            label: 'Discretionary Data',
                            field: 'discretionaryData',
                        },
                        {
                            label: 'Batch Balance Requirements',
                            field: 'batchBalanceRequirements',
                        },
                        {
                            label: 'Transaction Type',
                            field: 'notOnUsTransactionTypes',
                        },
                    ],
                })
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.readOnlyWhen(inEditingMode)
                .and.readOnlyWhen(
                    record => doesMatchCriteriaContainCompanyName() && isExistingPayment(record)
                )
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'achCompany'),
        },
        {
            field: 'achCompanyId',
            fieldType: internationalAchOriginatorCompanyId.with
                .label('ACH Company ID')
                .thatIs.required()
                .thatIs.readOnlyWhen(doesMatchCriteriaContainCompanyID)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'achCompanyId'),
        },
        {
            field: 'entryDescription',
            fieldType: achEntryDescription.with
                .label('Entry Description')
                .thatIs.required()
                .thatIs.readOnlyWhen(doesMatchCriteriaContainEntryDescription)
                .and.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.maxLength(10)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'entryDescription'),
        },
        {
            field: 'discretionaryData',
            fieldType: achDiscretionaryData.with
                .label('Discretionary Data')
                .thatIs.readOnlyWhen(doesMatchCriteriaContainDiscretionaryData)
                .and.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'discretionaryData'),
        },
        {
            field: 'restricted',
            fieldType: restrictedPayment.with
                .label('Restrict Payment')
                .thatIs.visibleWhen(isRestrictedBatchEntitlementEnabled)
                .thatIs.readOnlyWhen(record => notStep1(record) && !isNachaUploadStep2(record))
                .and.readOnlyWhen(editingIsNotPermitted)
                .as.tag('omega-checkbox'),
            value: getPaymentFieldValue(payment, 'restricted'),
        },
        {
            field: 'streetAddress',
            fieldType: nachaStreetAddress.with
                .label('Street Address')
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.maxLength(35)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'streetAddress'),
        },
        {
            field: 'city',
            fieldType: nachaCity.with
                .label('City')
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.maxLength(31)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'city'),
        },
        {
            field: 'state',
            fieldType: string.with
                .label('State')
                .with.options({
                    fetch: async () => Promise.resolve(staticOptions.states),
                    text: record => {
                        if (typeof record === 'string') {
                            return record;
                        }
                        return `${record.code} - ${record.name}`;
                    },
                    value: record => record.code,
                })
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'state'),
        },
        {
            field: 'zipCode',
            fieldType: internationalAchOriginatorZipCode.with
                .label('Zip Code')
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .thatIs.readOnlyWhen(editingIsNotPermitted)
                .and.maxLength(31)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'zipCode'),
        },
        {
            field: 'country',
            fieldType: internationalAchOriginatorCountry.with
                .label('Country')
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .with.defaultValue('US'),
            value: 'US',
        },
        {
            field: 'destinationCountryCode',
            fieldType: nachaCountryCode.with
                .label('Destination Country Code')
                .thatIs.required()
                .with.options({
                    fetch: async () => {
                        const featureService = await FeatureFlagService.getInstance();
                        const removeCountryCodesFFIsOn = await featureService.isEnabled(
                            Feature.IATInternationalACHPaymentsRemoveCountryCodes
                        );

                        const nonFilteredCountries = staticOptions.countries;
                        const filteredActiveCountries = staticOptions.countries.filter(
                            country => country.isActive
                        );

                        return Promise.resolve(
                            removeCountryCodesFFIsOn
                                ? filteredActiveCountries
                                : nonFilteredCountries
                        );
                    },
                    text: record => `${record.abbreviation} - ${record.name}`,
                    value: record => record,
                })
                .with.template(value => value?.abbreviation || value)
                .with.hashFunction(a => a?.name)
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'destinationCountryCode'),
        },
        {
            field: 'foreignExchangeIndicator',
            fieldType: string.with
                .label('Foreign Exchange Indicator')
                .with.options({
                    fetch: record =>
                        getForeignExchangeIndicators(
                            record.getField('destinationCountryCode'),
                            iatAchOptions
                        ),
                    text: record => `${record.name}`,
                    value: record => record.name,
                    fetchOnChange: true,
                })
                .thatIs.required()
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .thatIs.disabledWhen(destinationNotSelected)
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'foreignExchangeIndicator'),
        },
        {
            field: 'foreignExchangeReferenceNumber',
            fieldType: internationalForeignExchangeReferenceNumber.with
                .label('Foreign Exchange Reference Indicator')
                .with.options({
                    fetch: record =>
                        getForeignExchangeReferenceIndicators(
                            record.getField('destinationCountryCode'),
                            record.getField('foreignExchangeIndicator'),
                            iatAchOptions
                        ),
                    text: record => `${record.description}`,
                    value: record => record.name,
                })
                .thatHas.validator(customValidators.requiredWhenFV)
                .thatIs.requiredWhen(exchangeIsFV)
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.disabledWhen(destinationNotSelected)
                .and.disabledWhen(record => !record.getField('foreignExchangeIndicator'))
                .and.disabledWhen(record =>
                    foreignExchangeReferenceIndicatorDisabled(
                        record.getField('destinationCountryCode'),
                        record.getField('foreignExchangeIndicator'),
                        iatAchOptions
                    )
                )
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'foreignExchangeReferenceNumber'),
        },
        {
            field: 'foreignExchangeReference',
            fieldType: internationalForeignExchangeReference.with
                .label('Foreign Exchange Reference')
                .thatIs.requiredWhen(exchangeIsFV)
                .thatHas.validator(customValidators.requiredWhenFV)
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.disabledWhen(foreignExchangeIndicatorNotSelected)
                .as.tag('omega-input'),
            value: getPaymentFieldValue(payment, 'foreignExchangeReference'),
        },
        {
            field: 'destinationCurrencyCode',
            fieldType: string.with
                .label('Destination Currency')
                .thatIs.required()
                .with.options({
                    fetch: record =>
                        getDestinationCurrencyCode(
                            record.getField('destinationCountryCode'),
                            record.getField('foreignExchangeIndicator'),
                            iatAchOptions
                        ),
                    text: record => `${record.name}`,
                    value: record => record.name,
                    fetchOnChange: true,
                })
                .and.disabledWhen(foreignExchangeIndicatorNotSelected)
                .and.disabledWhen(
                    record => typeof record.getField('destinationCurrencyCode') === 'string'
                )
                .thatIs.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'destinationCurrencyCode'),
        },
        {
            field: 'debitAmount',
            fieldType: money.with.label('Debit Amount').thatIs.readOnly(),
            value: getPaymentFieldValue(payment, 'debitAmount'),
        },
        {
            field: 'creditAmount',
            fieldType: money.with.label('Credit Amount').thatIs.readOnly(),
            value: getPaymentFieldValue(payment, 'creditAmount'),
        },
        {
            field: 'originatorCurrencyCode',
            fieldType: internationalAchOriginatorCurrencyCode.with
                .label('Originator Currency Code')
                .thatIs.required()
                .thatIs.readOnly(),
            value: 'USD',
        },
        {
            field: 'frequency',
            fieldType: internationalAchPaymentFrequency.and
                .selectionDisabledFunctions(dateDisabledFunction)
                .and.options([
                    // values in select options need to match frequency type values
                    { text: 'One Time', value: 'OneTime' },
                    { text: 'Weekly', value: 'Weekly' },
                    { text: 'Every Two Weeks', value: 'EveryTwoWeeks' },
                    { text: 'Twice a Month', value: 'TwiceAMonth' },
                    { text: 'Monthly', value: 'Monthly' },
                    { text: 'Quarterly', value: 'Quarterly' },
                    { text: 'Every Six Months', value: 'EverySixMonths' },
                    { text: 'Yearly', value: 'Yearly' },
                ])
                .and.defaultValue({
                    effectiveDate: nextBusinessDay,
                    repeatOn: null,
                    startOn: null,
                    endOn: null,
                    valueDate: nextBusinessDay,
                    nextPaymentDate: null,
                    noEndDate: true,
                    repeatOnDay1: null,
                    repeatOnDay2: null,
                    repeatOnLastBusinessDay: null,
                    type: null,
                    summary: '',
                })
                .and.requiredWhen(() => !templateMode)
                .and.visibleWhen(() => !templateMode)
                .thatIs.readOnlyWhen(record => notStep1(record) && !isNachaUploadStep2(record))
                .and.readOnlyWhen(inEditingMode)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.readOnlyExceptionWhen(record => inEditingMode() && !notStep1(record))
                .as.tag('omega-frequency'),
            value: payment ? getPaymentFieldValue(payment, 'frequency') : nextBusinessDay,
        },
        {
            // meta data - tracks whether the user has elected nacha upload or not
            field: 'nachaUpload',
            fieldType: boolean.thatIs.visibleWhen(() => false),
            value: false,
        },
        {
            field: 'offsetAccount',
            fieldType: internationalAchOffsetAccount.with
                .label('Offset Account')
                .and.requiredWhen(record => {
                    const company = record.getField('achCompany');
                    return (
                        notStep1(record) && company && !isPrefundingEnabledForAchCompany(company)
                    );
                })
                .and.visibleWhen(notStep1)
                .thatIs.readOnlyWhen(onConfirmationStep)
                .thatIs.readOnlyWhen(record => companyHasPrefundingAndUnbalanced(record))
                .and.readOnlyWhen(record => record.getField('achCompany').offsetAccountNumber)
                .and.readOnlyWhen(editingIsNotPermitted)
                .and.template((value, record) => {
                    const achCompanyOffset = record.getField('achCompany').offsetAccountNumber;
                    if (achCompanyOffset) {
                        record.setField('offsetAccount', achCompanyOffset);
                        return achCompanyOffset;
                    }
                    return value.accountDisplayLabel || value.value;
                })
                .with.options({
                    fetch: record => {
                        const company = record.getField('achCompany');
                        if (company && isAchCompanyBalanced(company)) {
                            return client.getUserAccountsToOffsetBalancedCompany();
                        }
                        if (company && !isAchCompanyBalanced(company)) {
                            return client.getOffsetAccounts(company.id);
                        }
                        return new Promise(resolve => resolve([]));
                    },
                    text: record => record.accountDisplayLabel,
                    value: record => record,
                })
                .as.tag('omega-select'),
            value: getPaymentFieldValue(payment, 'offsetAccount'),
        },
        {
            field: 'audit',
            fieldType: achPaymentAudit.with
                .label('Audit')
                .thatIs.visibleWhen(onConfirmationStep)
                .and.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted),
            value: getPaymentFieldValue(payment, 'audit'),
        },
        {
            field: 'transactionId',
            fieldType: achTransactionId.thatIs
                .visibleWhen(() => !templateMode)
                .and.visibleWhen(onConfirmationStep)
                .and.readOnlyWhen(notStep1)
                .and.readOnlyWhen(editingIsNotPermitted)
                .with.label('Transaction ID'),
            value: getPaymentFieldValue(payment, 'transactionId'),
        },
        {
            field: 'status',
            label: 'Status',
            fieldType: achPaymentStatus.thatIs
                // we may need to update visible when for payment reintegration
                .visibleWhen((...args) => onConfirmationStep(...args))
                .and.readOnlyWhen(notStep1)
                .and.readOnlyWhen(inEditingMode)
                .and.readOnlyWhen(editingIsNotPermitted)
                // .and.template(value => value)
                .and.readOnlyExceptionWhen(inEditingMode),
            value: getPaymentFieldValue(payment, 'status'),
        },
        {
            field: 'step',
            fieldType: number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'step'),
        },
        {
            field: 'fileArchiveId',
            fieldType: number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'fileArchiveId'),
        },
        {
            field: 'achPaymentDraftId',
            fieldType: number.thatIs.visibleWhen(() => false),
            value: getPaymentFieldValue(payment, 'achPaymentDraftId'),
        },
        {
            field: 'template',
            fieldType: boolean.thatIs.visibleWhen(() => false),
            value: templateMode,
        },
    ];

    let fieldsCopy = fields;
    if (templateMode) {
        // remove unneeded fields
        const frequencyIndex = fieldsCopy.findIndex(field => field.field === 'frequency');
        if (frequencyIndex > 0) {
            fieldsCopy = fieldsCopy
                .slice(0, frequencyIndex)
                .concat(fieldsCopy.slice(frequencyIndex + 1));
        }
        const offsetAccountIndex = fieldsCopy.findIndex(field => field.field === 'offsetAccount');
        if (offsetAccountIndex > 0) {
            fieldsCopy = fieldsCopy
                .slice(0, offsetAccountIndex)
                .concat(fieldsCopy.slice(offsetAccountIndex + 1));
        }
    }
    return templateMode ? fieldsCopy : fields;
};

export const paymentHeaderRecordCopy = (
    payment,
    configurationDetails,
    client,
    editingMode,
    templateMode
) =>
    new Record(
        paymentFieldsCopy(payment, configurationDetails, client, editingMode, templateMode).reduce(
            (acc, curr) => {
                acc[curr.field] = curr.fieldType;
                return acc;
            },
            {}
        ),
        paymentFieldsCopy(payment, configurationDetails, client, editingMode, templateMode).reduce(
            (acc, curr) => {
                acc[curr.field] = curr.value || curr.fieldType.defaultValue();
                return acc;
            },
            {}
        )
    );
