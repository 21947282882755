ArpFileErrorsController.$inject = ['$scope', '$modalInstance', 'fileErrors', '$timeout'];

export default function ArpFileErrorsController($scope, $modalInstance, fileErrors, $timeout) {
    $scope.filterText = '';
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.fileErrors = fileErrors;
    $scope.filterErrors = filterErrors;

    // this will dismiss the window.
    function close() {
        $modalInstance.dismiss();
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function filterErrors(fileError) {
        if (!$scope.filterText || $scope.filterText.trim() === '') {
            return true;
        }
        return (
            fileError.lineNumber.toString().indexOf($scope.filterText) !== -1 ||
            fileError.errorText
                .toString()
                .toLowerCase()
                .indexOf($scope.filterText.toString().toLowerCase()) !== -1
        );
    }
}
