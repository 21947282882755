export default () => ({
    restrict: 'A',
    require: 'ngModel',
    link($scope, element, attrs, ngModel) {
        ngModel.$validators.treasuryMax = function (modelValue, viewValue) {
            let value = modelValue;
            if (modelValue == null) {
                value = viewValue;
            }

            if (value == null || value === '') return true;

            if (isNaN(value)) return false;

            let maximum = parseFloat($scope.$eval(attrs.trMax));
            if (isNaN(maximum)) {
                maximum = Number.MAX_VALUE;
            }

            return value <= maximum;
        };

        $scope.$watch(
            () => $scope.$eval(attrs.trMax),
            () => {
                ngModel.$validate();
            }
        );
    },
});
