import { LitElement, html, css, nothing } from 'lit';
import '@treasury/omega/layouts/omega-report';
import AchNoticeOfChangeServices from '@treasury/domain/channel/services/ach/ach-notice-of-change-services.js';
import { Record } from '@treasury/FDL/record';
import { detailFields } from './data/ach-notice-of-change-fields.js';
import '@treasury/omega/components/progress/omega-progress';

class ACHNoticeOfChangeDetailRow extends LitElement {
    static get properties() {
        return {
            parentRecord: Object,
            closeFunction: Object,
            detailRecord: Object,
            downloadOptionsOpen: Boolean,
            showBlockingLoader: Boolean,
        };
    }

    constructor() {
        super();
        this.downloadOptionsOpen = false;
        this.downloadOptions = ['PDF', 'NACHA'];
        this.showBlockingLoader = false;
    }

    firstUpdated() {
        this.fetchDetail();
    }

    async fetchDetail() {
        this.showBlockingLoader = true;
        const data = await AchNoticeOfChangeServices.getDetails(this.parentRecord);
        this.fetchRecord = new Record(detailFields, data);
        this.showBlockingLoader = false;
    }

    async _detailDownload(e) {
        if (e.type === 'click' || e.keyCode === 13) {
            this.toggleDownloadDropdown();
            this.dispatchEvent(
                new CustomEvent('saveNotice', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        guid: this.parentRecord.getField('achNotificationOfChangeUniqueId'),
                        type: e.target.innerText,
                        notificationReceivedDate: this.parentRecord.getField('receivedDate'),
                        changeCodeDescription:
                            this.parentRecord.getField('returnReasonDescription'),
                    },
                })
            );
        }
    }

    _detailPrint() {
        this.dispatchEvent(
            new CustomEvent('detailPrint', {
                bubbles: true,
                composed: true,
                detail: this.parentRecord.getField('achNotificationOfChangeUniqueId'),
            })
        );
        // window.print();
        const printWindow = window.open();
        printWindow.document.write(`
        <head>
            <style>
                body {
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; //cspell:disable-line
                    font-size: 18px;
                }
                h1 {
                    font-size: 24px;
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }
                li {
                    padding: 8px 0;
                    font-weight: 300;
                    color: #444;
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                }
                li > span {
                    font-weight: 400;
                    font-size: 16px;
                }
                .bold {
                    font-weight: 700;
                }
                .small {
                    font-size: 14px;
                }
                .italic {
                    font-style: italic;
                }
            </style>
            <body>
                <h1>ACH Notification of Change Detail</h1>
                <ul>
                    <li>Original Effective Date: <span id="effectiveDate"></span></li>
                    <li>Date Notification Received: <span id="receivedDate"></span></li>
                    <li>ACH Company: <span id="achCompanyName"></span></li>
                    <li>Company Entry Description: <span id="companyEntryDescription"></span></li>
                    <li>SEC Code: <span id="updatedSecCode"></span></li>
                    <li>Recipient Name: <span id="updatedRecipientName"></span></li>
                    <li>Recipient Financial Institution: <span id="updatedRoutingNumber"></span></li>
                    <li>Recipient Account Number: <span id="updatedAccountNumber"></span></li>
                    <li>Account Type: <span id="updatedAccountType"></span></li>
                    <li>Identification Number: <span id="updatedIdentificationNumber"></span></li>
                    <li>Trace Number: <span id="traceNumber"></span></li>
                    <li>Change Code: <span id="fullReturnReason"></span></li>
                    <li>Date of Death: <span id="dateOfDeath">Not Applicable</span></li>
                </ul>
            </body>
        </head>
        `);

        this._fetchAndPrintRecord(printWindow.document, 'effectiveDate');
        this._fetchAndPrintRecord(printWindow.document, 'receivedDate');
        this._fetchAndPrintRecord(printWindow.document, 'achCompanyName');
        this._fetchAndPrintRecord(printWindow.document, 'companyEntryDescription');
        this._fetchAndPrintChangedRecord(printWindow.document, 'updatedSecCode', 'originalSecCode');
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedRecipientName',
            'originalRecipientName'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedRoutingNumber',
            'originalRoutingNumber'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedAccountNumber',
            'originalAccountNumber'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedAccountType',
            'originalAccountType'
        );
        this._fetchAndPrintChangedRecord(
            printWindow.document,
            'updatedIdentificationNumber',
            'originalIdentificationNumber'
        );
        this._fetchAndPrintRecord(printWindow.document, 'traceNumber');
        this._fetchAndPrintRecord(printWindow.document, 'fullReturnReason');
        this._fetchAndPrintRecord(printWindow.document, 'dateOfDeath');

        printWindow.stop();
        printWindow.print();
        printWindow.close();
    }

    _fetchAndPrintRecord(document, record) {
        const field = document.getElementById(record);
        const text = this.fetchRecord.print(record);
        if (text) {
            field.textContent = this.fetchRecord.print(record);
        }
    }

    _fetchAndPrintChangedRecord(document, record, originalRecord) {
        const field = document.getElementById(record);
        const updatedText = this.fetchRecord.print(record);
        const originalText = this.fetchRecord.print(originalRecord);

        if (updatedText) {
            field.innerHTML = `<span class="bold small">Updated Entry:</span> ${updatedText} <span class="italic small">(Original Entry: ${originalText})</span>`;
        } else {
            field.textContent = originalText;
        }
    }

    toggleDownloadDropdown() {
        this.downloadOptionsOpen = !this.downloadOptionsOpen;
    }

    isDownloadDropdownOpen() {
        return this.downloadOptionsOpen === true;
    }

    renderFieldWithChangedValue(original, updated) {
        if (original && updated && this.fetchRecord.getField(updated)) {
            return html`
                <span class="updated">
                ${this.fetchRecord.print(updated)}
            </span>
                <span class="original"
             >${this.fetchRecord.print(original)}</span
          ></span>`;
        }
        return html`${this.fetchRecord.print(original)}`;
    }

    renderDayOfDeath() {
        if (this.fetchRecord.getField('dateOfDeath')) {
            return html`
                <span class="detail-row-value"> ${this.fetchRecord.print('dateOfDeath')} </span>
                <label class="detail-row-label under">Date of Death </label>
            `;
        }
        return nothing;
    }

    renderDownloadDropdown() {
        if (this.isDownloadDropdownOpen()) {
            return html`<ul
                class="download-dropdown"
                role="menu"
                aria-hidden=${this.isDownloadDropdownOpen()}
            >
                ${this.downloadOptions.map(
                    option =>
                        html`<li
                            role="menuitem"
                            class="download-dropdown-item"
                            @click=${e => this._detailDownload(e)}
                            @keyup=${e => this._detailDownload(e)}
                        >
                            ${option}
                        </li>`
                )}
            </ul>`;
        }
        return nothing;
    }

    renderDetailRow() {
        if (this.fetchRecord) {
            return html`
                <div class="detail-row-container">
                    <div class="detail-row-header">
                        <div>
                            <span class="detail-row-title">ACH Notification Of Change Detail</span>
                            <label class="detail-row-label"
                                >Updated entries in <span class="updated">Green</span>. Original
                                entries in ().</label
                            >
                        </div>
                        <div>
                            <omega-button
                                type="icon"
                                icon="download"
                                @click=${this.toggleDownloadDropdown}
                                hideLabel="always"
                            ></omega-button>
                            ${this.renderDownloadDropdown()}
                            <omega-button
                                type="icon"
                                icon="print"
                                @click=${this._detailPrint}
                                hideLabel="always"
                            ></omega-button>
                            <omega-button
                                class="close-button"
                                type="icon"
                                icon="close"
                                @click=${this.closeFunction}
                                hideLabel="always"
                            ></omega-button>
                        </div>
                    </div>
                    <div class="detail-content-container">
                        <div class="detail-content-column">
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue('effectiveDate')}
                            </span>
                            <label class="detail-row-label under">Original Effective Date</label>
                            <span class="detail-row-value"
                                >${this.fetchRecord.print('receivedDate')}</span
                            >
                            <label class="detail-row-label under">Date Notification Received</label>
                            <span class="detail-row-value"
                                >${this.fetchRecord.print('achCompanyName')}</span
                            >
                            <label class="detail-row-label under">ACH Company</label>
                        </div>
                        <div class="detail-content-column">
                            <span class="detail-row-value">
                                ${this.fetchRecord.print('companyEntryDescription')}</span
                            >
                            <label class="detail-row-label under">Company Entry Description</label>
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue(
                                    'originalSecCode',
                                    'updatedSecCode'
                                )}
                            </span>
                            <label class="detail-row-label under">SEC Code</label>
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue(
                                    'originalRecipientName',
                                    'updatedRecipientName'
                                )}
                            </span>
                            <label class="detail-row-label under">Recipient Name</label>
                        </div>
                        <div class="detail-content-column">
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue(
                                    'originalRoutingNumber',
                                    'updatedRoutingNumber'
                                )}</span
                            >
                            <label class="detail-row-label under"
                                >Recipient Financial Institution</label
                            >
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue(
                                    'originalAccountNumber',
                                    'updatedAccountNumber'
                                )}
                            </span>
                            <label class="detail-row-label under">Recipient Account Number</label>
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue(
                                    'originalAccountType',
                                    'updatedAccountType'
                                )}
                            </span>
                            <label class="detail-row-label under">Account Type</label>
                        </div>
                        <div class="detail-content-column">
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue(
                                    'originalIdentificationNumber',
                                    'updatedIdentificationNumber'
                                )}
                            </span>
                            <label class="detail-row-label under">Identification Number</label>
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue('traceNumber')}</span
                            >
                            <label class="detail-row-label under">Trace Number</label>
                        </div>

                        <div class="detail-content-column">
                            <span class="detail-row-value">
                                ${this.renderFieldWithChangedValue('fullReturnReason')}
                            </span>
                            <label class="detail-row-label under">Change Code</label>
                            ${this.renderDayOfDeath('dateOfDeath')}
                        </div>
                    </div>
                </div>
            `;
        }

        return this.renderBlockingLoader();
    }

    renderBlockingLoader() {
        if (this.showBlockingLoader) return html`<omega-progress card></omega-progress>`;
        return nothing;
    }

    render() {
        return html`${this.renderDetailRow()}`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            div.detail-row-container {
                padding: 0px;
                padding-bottom: 8px;
                margin-top: 0px;
                position: relative;
            }
            div.detail-row-header {
                height: 30px;
                padding: 4px 17px;
                padding-right: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .close-button {
                opacity: 0.6;
            }
            .detail-header {
                height: 30px;
                padding: 10px 17px 20px 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dde7ef;
            }
            .detail-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .detail-content-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
            }
            .detail-content-column {
                margin: 20px 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            .detail-row-title {
                padding-top: 0px;
                margin-top: 0px;
                margin-right: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #333333;
            }
            .detail-row-label {
                font-size: 13px;
                color: #787878;
                margin-left: 15px;
                margin-right: 5px;
                flex: 0;
                white-space: nowrap;
                min-width: 140px;
            }
            .detail-row-label.align-center {
                display: flex;
                align-items: center;
            }

            .detail-row-value {
                flex: 1;
                font-size: 14px;
            }

            .detail {
                text-align: right;
            }

            .under {
                margin-left: 0px;
                padding-bottom: 15px;
            }

            .detail-row-buttons {
                align-self: flex-end;
            }
            .updated {
                font-weight: 500;
                color: #197a00;
            }
            .original {
                font-size: 12px;
            }
            .original::before {
                content: '(';
            }
            .original::after {
                content: ')';
            }
            .download-dropdown {
                position: absolute;
                top: 25px;
                z-index: 5;
                min-width: 50px;
                padding: 0;
                border: 1px solid var(--omega-white-150);
                background: var(--omega-white);
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                list-style: none;
            }
            .download-dropdown li {
                min-width: 50px;
                padding: 10px 25px;
                text-align: left;
                color: var(--omega-primary);
                cursor: pointer;
            }

            .download-dropdown li:hover {
                background-color: var(--omega-white-100);
            }

            .download-dropdown a {
                text-decoration: none;
            }
        `;
    }
}

export default ACHNoticeOfChangeDetailRow;
window.customElements.define('ach-notice-of-change-detail-row', ACHNoticeOfChangeDetailRow);
