import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-accordion';
import '@treasury/omega/components/omega-button-bar.js';
import '@treasury/omega/components/omega-field.js';
import '@treasury/omega/components/omega-file-upload.js';
import '@treasury/omega/components/omega-radio-group.js';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import { CONTAINER_CONFIGURATION } from '../data/container-configuration.ts';
import '../parts/ach-international-payment-header.js';
import '../parts/ach-international-payment-recipients-table.js';

class CreateAchInternationalPaymentStep extends mix(LitElement).with(ListeningElementMixin) {
    static get properties() {
        return {
            institution: String,
            activeStep: Number,
            entitlements: Array,
            loading: Boolean,
            workflowType: String,
            paymentHeaderRecord: Object,
            recipientsRecordset: Object,
            recipients: Array,
            holidays: Array,
            fields: Array,
            showFileUpload: Boolean,
            file: Object,
            isUploading: Boolean,
            canContinue: Boolean,
            editingMode: Boolean,
            template: Boolean,
            continueLoading: Boolean,
            continueBlocked: Boolean,
        };
    }

    constructor() {
        super();
        this.loading = false;
        this.showFileUpload = false;
        this.isUploading = false;
        this.paymentCreationType = 'manual';
        this.recipients = [
            {
                name: '',
                streetAddress: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                idNumber: '',
                accountType: '',
                accountNumber: '',
                iatTransactionType: '',
                amount: '',
                prenote: '',
                hold: '',
                addenda: '',
                bankName: '',
                bankIdType: '',
                bankNumber: '',
                receivingBankCountry: '',
            },
        ];
        this.continueLoading = false;
        this.continueBlocked = false;
        this.canContinue = false;
    }

    updated(changedProps) {
        if (changedProps.has('paymentHeaderRecord')) {
            this.listenTo(this.paymentHeaderRecord, 'change', () => this.checkForContinuation());
        }
    }

    async changePaymentCreationType(type) {
        this.paymentCreationType = type;
        if (this.paymentCreationType === 'file') {
            this.showFileUpload = true;
        } else {
            this.showFileUpload = false;
        }

        if (type === 'template') {
            const di = await DiContainer.getInstance();
            di.get(NavigationService).navigate('payables.ach.payments.international-ach-templates');
        }

        this.paymentHeaderRecord.setField('nachaUpload', this.showFileUpload);
    }

    uploadedNachaFile() {
        this.isUploading = true;
        this.dispatchEvent(new CustomEvent('fileUploaded', { detail: this.file[0] }));
    }

    checkForContinuation() {
        if (!this.paymentHeaderRecord || !this.recipientsRecordset) {
            this.canContinue = false;
            return;
        }

        this.canContinue =
            this.paymentHeaderRecord.isValid() &&
            this.paymentHeaderRecord.hasRequiredValues() &&
            this.recipientsRecordset.isValid();
    }

    next() {
        if (this.canContinue) {
            this.dispatchEvent(
                new CustomEvent('next', {
                    detail: {
                        paymentHeader: this.paymentHeaderRecord,
                        recipients: this.recipientsRecordset,
                    },
                })
            );
        }
    }

    handleRecordsetChanges(recordset) {
        this.recipientsRecordset = recordset;
        this.recipients = recordset.allRecords.map(r => r.values);
        this.checkForContinuation();
    }

    renderPaymentCreationTypes() {
        if (this.editingMode || !this.workflowType) return nothing;
        return html`<omega-radio-group
            name="payment-creation-type"
            id="payment-creation-type-group"
            orientation="horizontal"
            label=""
            .radios=${CONTAINER_CONFIGURATION[this.workflowType].creationOptions.filter(option =>
                option.visibleWhen(this.entitlements)
            )}
            .value=${this.paymentCreationType}
            @change=${e => this.changePaymentCreationType(e.target.value)}
        ></omega-radio-group>`;
    }

    renderFileUpload() {
        if (this.showFileUpload)
            return html`<omega-file-upload
                @filesUploaded=${e => {
                    this.file = e.detail.files;
                }}
                >Upload File</omega-file-upload
            >`;
        return nothing;
    }

    renderField(field) {
        return html`<omega-field
            .field=${field.field}
            .record=${this.paymentHeaderRecord}
        ></omega-field>`;
    }

    renderPaymentHeaderFields() {
        if (!this.paymentHeaderRecord) return nothing;
        const visibleFields = this.fields.filter(field =>
            field.fieldType.visible(this.paymentHeaderRecord)
        );
        const indexOfField = fieldName => {
            for (let i = 0; i < visibleFields.length; i++) {
                if (visibleFields[i].field === fieldName) {
                    return i;
                }
            }
            return null;
        };
        const streetAddressIndex = indexOfField('streetAddress') || 6;
        const destinationCountryCodeIndex = indexOfField('destinationCountryCode') || 11;
        const debitAmountIndex = indexOfField('debitAmount') || 16;

        const firstColFields = visibleFields
            .slice(0, streetAddressIndex)
            .map(field => this.renderField(field));
        const secondColFields = visibleFields
            .slice(streetAddressIndex, destinationCountryCodeIndex)
            .map(field => this.renderField(field));
        const thirdColFields = visibleFields
            .slice(destinationCountryCodeIndex, debitAmountIndex)
            .map(field => this.renderField(field));
        const fourthColFields = visibleFields
            .slice(debitAmountIndex)
            .map(field => this.renderField(field));
        return html`<div class="omega-flex-form">
            <div class="form-column">${firstColFields}</div>
            <div class="form-column">${secondColFields}</div>
            <div class="form-column">${thirdColFields}</div>
            <div class="form-column">${fourthColFields}</div>
        </div>`;
    }

    renderRecipientsTable() {
        if (!this.paymentHeaderRecord) return nothing;
        return html`<ach-international-payment-recipients-table
            editable
            .recipients=${this.recipients}
            .recipientsRecordset=${this.recipientsRecordset}
            .holidays=${this.holidays}
            @updated=${e => this.handleRecordsetChanges(e.detail)}
            @changed=${e => this.handleRecordsetChanges(e.detail)}
        ></ach-international-payment-recipients-table>`;
    }

    renderPaymentOrTemplate() {
        return this.template ? 'Template' : 'Payment';
    }

    render() {
        if (this.activeStep !== 0) return nothing;
        if (this.paymentCreationType === 'manual') {
            return html` ${this.renderPaymentCreationTypes()}
                <div class="omega-form-header">
                    <h2 class="step-title">${this.renderPaymentOrTemplate()} Header Information</h2>
                    <span><span class="error">*</span> indicates Required field</span>
                </div>
                <hr />
                ${this.renderPaymentHeaderFields()} ${this.renderRecipientsTable()}
                <omega-button-bar position="bottom" alignment="left"
                    ><omega-button
                        @mousedown=${() => {
                            if (this.canContinue) this.next();
                        }}
                        .disabled=${!this.canContinue}
                        .loading=${this.continueLoading}
                        type="primary"
                        >Review</omega-button
                    >
                    <omega-button
                        @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                        type="secondary"
                        >Cancel</omega-button
                    ></omega-button-bar
                >`;
        }
        return html`
            ${this.renderPaymentCreationTypes()}
            <div class="omega-form-header">
                <h2 class="step-title">Upload International Nacha Formatted File</h2>
            </div>
            <hr />
            ${this.renderFileUpload()}
            <omega-button-bar position="bottom" alignment="left"
                ><omega-button
                    @click=${this.uploadedNachaFile}
                    .loading=${this.isUploading}
                    type="primary"
                    .disabled=${!this.file}
                    >Upload</omega-button
                >
                <omega-button
                    @click=${() => this.dispatchEvent(new CustomEvent('cancel'))}
                    type="secondary"
                    >Cancel</omega-button
                ></omega-button-bar
            >
        `;
    }

    static get styles() {
        return css`
            .omega-form-header {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .omega-form-header span {
                font-style: italic;
            }
            .error {
                color: var(--omega-error);
            }
            .step-title {
                margin: 0;
            }
            .omega-flex-form {
                display: flex;
                height: 100%;
            }

            .form-column {
                flex: 1 1 0;
                margin: 10px;
            }

            .form-column:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid #d3d6d9;
            }

            omega-file-upload {
                margin-top: 5px;
                width: 365px;
            }

            omega-field {
                margin: 5px;
                min-height: 32px;
                max-width: 500px;
            }
        `;
    }
}

customElements.define('create-ach-international-payment-step', CreateAchInternationalPaymentStep);
