import AmountFilter from './amountFilter/amountFilter.js';
import ApprovalStatus from './approvalStatus/approvalStatusDir.js';
import AutoCollapse from './autoCollapse/autoCollpase.js';
import Download from './download/downloadDir.js';
import MultiSelectDropdown from './dropdownMultiSelect/dropdownMultiSelectDir.js';
import GenericMultiSelectDropdown from './genericMultiSelectDropdown/genericMultiSelectDropdownDir.js';
import IncludeHeaderOptionController from './includeHeader/headerOptionCtrl.js';
import PopOver from './popOver/popOverDir.js';
import Print from './print/printDir.js';
import Switch from './switch/switchDir.js';
import ToggleSwitch from './toggle/toggleDir.js';
import TypeAheadName from './typeAhead/typeAheadNameDir.js';
import TypeAheadAccount from './typeAheadAccount/typeAheadDir.js';
import TypeAheadAchBanks from './typeAheadAchBanks/typeAheadAchBanksDir.js';
import TypeAheadAchCompanies from './typeAheadAchCompanies/typeAheadDir.js';
import TypeAheadAchOffsetAccount from './typeAheadAchOffsetAccount/typeAheadAchOffsetAccountDir.js';
import TypeAheadAchOffsetAccounts from './typeAheadAchOffsetAccounts/typeAheadAchOffsetAccountsDir.js';
import TypeAheadAchTaxCodes from './typeAheadAchTaxCodes/typeAheadAchTaxCodesDir.js';
import TypeAheadBeneficiaryBanks from './typeAheadBeneficiaryBanks/typeAheadBeneficiaryBanksDir.js';
import TypeAheadCompanyAccountDirective from './typeAheadCompanyAccount/typeAheadCompanyAccountDir.js';
import TypeAheadStates from './typeAheadStates/typeAheadStatesDir.js';
import AlphaNumericInput from './alphaNumericInputDir.js';
import Back from './backDir.js';
import Flipper from './flipperDir.js';
import Front from './frontDir.js';
import LimitTo from './limitToDir.js';
import NgCurrency from './ng-currency.js';
import NgPhone from './ng-phone.js';
import NumericInput from './numericInputDir.js';

angular
    .module('channel.directives.controls', [])
    .directive('amountFilter', AmountFilter)
    .directive('approvalStatus', ApprovalStatus)
    .directive('autoCollapse', AutoCollapse)
    .directive('download', Download)
    .directive('multiSelectDropdown', MultiSelectDropdown)
    .directive('genericMultiSelectDropdown', GenericMultiSelectDropdown)
    .controller('IncludeHeaderOptionController', IncludeHeaderOptionController)
    .directive('popOver', PopOver)
    .directive('print', Print)
    .directive('switch', Switch)
    .directive('toggleSwitch', ToggleSwitch)
    .directive('typeAheadNameDirective', TypeAheadName)
    .directive('typeAheadAccountDirective', TypeAheadAccount)
    .directive('typeAheadAchBanks', TypeAheadAchBanks)
    .directive('typeAheadAchCompanies', TypeAheadAchCompanies)
    .directive('typeAheadAchOffsetAccountDirective', TypeAheadAchOffsetAccount)
    .directive('typeAheadAchOffsetAccounts', TypeAheadAchOffsetAccounts)
    .directive('typeAheadAchTaxCodes', TypeAheadAchTaxCodes)
    .directive('typeAheadBeneficiaryBanks', TypeAheadBeneficiaryBanks)
    .directive('typeAheadCompanyAccountDirective', TypeAheadCompanyAccountDirective)
    .directive('typeAheadStates', TypeAheadStates)
    .directive('alphaNumericInput', AlphaNumericInput)
    .directive('back', Back)
    .directive('flipper', Flipper)
    .directive('front', Front)
    .directive('limitTo', LimitTo)
    .directive('ngCurrency', NgCurrency)
    .directive('ngPhone', NgPhone)
    .directive('numericInput', NumericInput);
