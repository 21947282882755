export default () => ({
    restrict: 'A',
    replace: true,
    scope: {
        label: '@',
        newValue: '=',
        currentValue: '=',
        currentDisplayValue: '=',
    },
    template: require('./differenceFlagTemplate.html'),
    controller: DifferenceFlagController,
});

DifferenceFlagController.$inject = ['$scope'];

function DifferenceFlagController($scope) {
    $scope.isDifferent = isDifferent;

    init();

    function init() {
        if (!$scope.label) {
            $scope.label = 'value';
        }
    }

    function isDifferent() {
        return $scope.newValue !== $scope.currentValue;
    }
}
