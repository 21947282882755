import { noOp } from '@treasury/utils';
import { IAnalyticsService } from './analytics.types';

export const NullAnalyticsService: IAnalyticsService = {
    init: noOp,
    identify: noOp,
    reset: noOp,
    track: noOp,
    trackPageView: noOp,
};
