const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/**
 *
 * @param {number} value Numeric value to formatted as USD.
 * @param {null} [notUsed] Deprecated. Originally used to pass a `Record`.
 * @param {'input-focus'} [context]
 * @returns A string formatted as USD currency.
 */
export default function usd(value, notUsed, context) {
    const val = value?.toString().length && !Number.isNaN(value) ? value : 0;

    if (context === 'input-focus') {
        return Number(val).toFixed(2);
    }

    return formatter.format(val, 'USD');
}
