import { ChangePasswordDto } from '@treasury/domain/channel/types/user-account';
import { TmApiError } from '@treasury/domain/shared';
import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-tooltip';
import '@treasury/omega/components/progress/omega-progress';
import { AlertMixin } from '@treasury/omega/mixins/alert.mixin';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserProfileClient } from '../clients/user-profile-client';
import { userAccountChangePasswordFields } from '../data/user-account-record';

const ListeningAlertingElement = AlertMixin(ListeningElementMixin(LitElement));
@customElement('user-change-password-dialog')
export class UserChangePasswordDialog extends ListeningAlertingElement {
    @property({
        type: String,
    })
    public institution!: string;

    @property({
        type: Object,
    })
    public client!: UserProfileClient;

    @property({
        type: Object,
    })
    public userPasswordRecord = new Record<ChangePasswordDto>(
        userAccountChangePasswordFields,
        {} as ChangePasswordDto
    );

    @state()
    private isLoading = true;

    @state()
    private isUpdating = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public open = false;

    @state()
    public passwordFormValid = false;

    private passwordRequirements: Array<string> = [];

    public async firstUpdated() {
        try {
            this.passwordRequirements = await this.client.getPasswordRequirements(this.institution);
            this.userPasswordRecord = new Record(userAccountChangePasswordFields, {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            });
            this.listenTo(this.userPasswordRecord, 'change', () => {
                this.isPasswordFormValid();
            });
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code,
                    time,
                };
            } else {
                const message = 'An unknown error occurred.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('open') && this.open) {
            this.userPasswordRecord.reset();
        }
    }

    private isPasswordFormValid() {
        this.passwordFormValid =
            this.userPasswordRecord.invalidValues.length === 0 &&
            this.userPasswordRecord.hasRequiredValues();
    }

    public async submitPasswordChange() {
        try {
            this.isUpdating = true;
            const response = await this.client.changePassword(
                this.userPasswordRecord.values as ChangePasswordDto
            );
            if (response) {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    posture: 'polite',
                    actions: '',
                    title: '',
                    type: 'success',
                    message: 'Password changed successfully!',
                };
                this.closeDialog();
            } else {
                this.alert = {
                    ...this.alert,
                    message: `An error occurred while changing your password. If the error recurs please contact your representative.`,
                    visible: true,
                    title: 'Error',
                    posture: 'assertive',
                    actions: '',
                    type: 'error',
                };
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code } = err;
                this.alert = {
                    ...this.alert,
                    message: html`${message} <br />#${code}`,
                    visible: true,
                    posture: 'assertive',
                    type: 'error',
                    title: 'Error',
                    actions: '',
                };
            } else {
                const message = 'Could not change password. Please try again.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isUpdating = false;
        }
    }

    public closeDialog() {
        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderPasswordRequirements() {
        const requirements = this.passwordRequirements.map(req => html`<li>${req}</li>`);
        return html`<ul>
            ${requirements}
        </ul>`;
    }

    renderBlockingLoader() {
        if (this.isUpdating) {
            return html`<div class="background">
                <omega-progress card class="light-loader"></omega-progress>
            </div>`;
        }
        return nothing;
    }

    render() {
        return html`${this.renderAlert()}${this.renderBlockingLoader()}<omega-dialog
                id="change-password-dialog"
                .open=${this.open}
                @close=${this.closeDialog}
                dialogTitle="Change Password"
            >
                <div slot="content" class="dialog-content">
                    <omega-field
                        field="currentPassword"
                        .record=${this.userPasswordRecord}
                    ></omega-field>
                    <omega-tooltip id="password-requirements-tooltip" direction="left-top" light>
                        <omega-field
                            field="newPassword"
                            .record=${this.userPasswordRecord}
                        ></omega-field>
                        <div slot="content" class="tooltip-content">
                            <h3>Password Policy:</h3>
                            ${this.renderPasswordRequirements()}
                        </div>
                    </omega-tooltip>
                    <omega-field
                        field="confirmPassword"
                        .record=${this.userPasswordRecord}
                    ></omega-field>
                </div>
                <div slot="actions">
                    <omega-button
                        type="primary"
                        id="change-password-submit"
                        ?disabled=${!this.passwordFormValid}
                        @click=${this.submitPasswordChange}
                        >Submit</omega-button
                    >
                    <omega-button
                        type="secondary"
                        id="change-password-cancel"
                        @click=${this.closeDialog}
                        >Cancel</omega-button
                    >
                </div>
            </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            #change-password-dialog {
                --omega-dialog-content-overflow: visible;
                --omega-dialog-min-width: 200px;
            }

            #change-password-dialog omega-field {
                --omega-field-control-width: 200px;
            }

            #change-password-dialog .dialog-content {
                padding: 0 20px 20px;
            }

            #change-password-dialog omega-field:first-of-type {
                padding-top: 0px;
            }

            #change-password-dialog omega-field,
            #change-password-dialog omega-tooltip omega-field:first-of-type {
                padding-top: 1rem;
                max-width: 200px;
            }

            #password-requirements-tooltip {
                --tooltip-span-display: auto;
                --omega-tooltip-indicator-top: 57px;
            }
            /* Firefox-specific tooltip positioning */
            @-moz-document url-prefix() {
                #password-requirements-tooltip {
                    --omega-tooltip-indicator-top: 69px;
                }
            }
            /* Safari-specific tooltip positioning */
            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance: none) {
                    #password-requirements-tooltip {
                        --omega-tooltip-left-top-top: -115px;
                    }
                }
            }

            #password-requirements-tooltip .tooltip-content ul {
                list-style-position: inside;
                margin: 0;
                padding: 0;
            }

            #password-requirements-tooltip .tooltip-content h3 {
                margin: 0 0 0.5rem;
            }
        `;
    }
}
