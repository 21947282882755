export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: '/app/directives/components/amountSearch/amountSearchTemplate.html',
            controller: TmAmountSearchController,
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                amount: '=tmModel',
                formInstance: '=',
                name: '@',
                label: '@',
            },
        };
    },
];

TmAmountSearchController.$inject = ['$scope'];

function TmAmountSearchController($scope) {
    const ctrl = this;
    ctrl.amountOptions = ['Specific Amount', 'Amount Range'];
    ctrl.endInputName = `${ctrl.name}EndInput`;

    ctrl.isAmountRange = isAmountRange;
    ctrl.isAmountSpecific = isAmountSpecific;
    ctrl.resetAmount = resetAmount;
    ctrl.validateInput = validateInput();

    $scope.$watchCollection(
        '[ctrl.amount.searchOption, ctrl.amount.end, ctrl.amount.start]',
        handleInputChange
    );

    function handleInputChange(newValues, oldValues) {
        if (newValues && oldValues && newValues[0] !== oldValues[0]) {
            // when amount.searchOption has changed
            resetAmount();
        } else {
            validateInput();
        }
    }

    function validateInput() {
        if (!ctrl?.amount?.searchOption) return;
        if (isAmountRange(ctrl.amount.searchOption)) {
            const { start } = ctrl.amount;
            const { end } = ctrl.amount;
            if (ctrl.formInstance && start != null && end != null) {
                const endInputControl = ctrl.formInstance[ctrl.endInputName];
                if (end < start || isNaN(end)) {
                    endInputControl.$setValidity('input', false);
                } else {
                    endInputControl.$setValidity('input', true);
                }
            }
        }
    }

    function resetAmount() {
        if (ctrl.amount) {
            if (ctrl.amount.searchOption === 'Amount Range') {
                ctrl.amount.end = '0';
                ctrl.amount.start = '0';
            } else {
                ctrl.amount.end = '';
                ctrl.amount.start = '';
            }
        }
    }

    function isAmountSpecific(amountSearchOption) {
        return amountSearchOption === 'Specific Amount';
    }

    function isAmountRange(amountSearchOption) {
        return amountSearchOption === 'Amount Range';
    }
}
