import EntitlementsRequests from '../../requests/entitlements/entitlements-requests.js';

export default class EntitlementsService {
    static async getEntitlements() {
        return EntitlementsRequests.getEntitlements();
    }

    static async getEntitlementGroups() {
        return EntitlementsRequests.getEntitlementGroups();
    }

    static async hasEntitlement(entitlement) {
        const entitlements = await EntitlementsRequests.getEntitlements();
        return entitlements.some(
            e => e.name.toLowerCase().trim() === entitlement.toLowerCase().trim()
        );
    }

    static async reset() {
        await Promise.all([
            EntitlementsRequests.resetEntitlementsCache(),
            EntitlementsRequests.resetEntitlementGroupsCache(),
        ]);
    }
}
