import DateOptionsModel from '../components/datepicker/date-options-model.js';
import DateModel from '../components/datepicker/DateModel.js';

/**
 *
 * @param { Date | string | null | undefined } value
 */
export default function localeDateString(value) {
    if (!value) return '';
    const dateOptionsModel = new DateOptionsModel();
    let dateArray = dateOptionsModel.parseRelativeDate(value);

    if (!dateArray) {
        // perform split in case string is a range
        dateArray = typeof value === 'string' ? value.split(' - ') : [value];
    }

    if (!dateArray) return '';

    return dateArray
        .map(date => {
            try {
                const dateValue =
                    typeof date === 'string' ? date.slice(0, 10).replace(/-/g, '/') : date;
                const DateObj = new DateModel(dateValue);
                return DateObj.displayFormat();
            } catch (error) {
                return null;
            }
        })
        .join(' - ');
}
