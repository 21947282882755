import { html } from 'lit';
import { TransferActivityRequests } from '@treasury/domain/channel/requests/transfers/transfer-activity-requests.js';
import { disablePastDates } from '../lib/utils/disable-date-functions.js';
// eslint-disable-next-line import/extensions
import { boolean, string, money, date, datePicker, number, list } from '../primitives';

export const editable = boolean;

export const transferTransactionId = string;

export const transferAccount = list.with
    .formatter(v => v.accountDisplayLabel)
    .and.options({
        fetch: TransferActivityRequests.fetchTransferAccounts,
        text: 'accountDisplayLabel',
        value: record => record,
    })
    .with.hashFunction(record => record.id);

export const transferAmount = money;

export const transferCreatedDate = date;

export const transferScheduledDate =
    datePicker.thatHas.selectionDisabledFunctions(disablePastDates);

export const transferStatus = string;

export const transferMemo = string;

export const transferAudit = string.with.template((v /* record */) =>
    v.map(
        auditObject => html`<ul>
            <li>Comments: ${auditObject.comments}</li>
            <li>Created By: ${auditObject.createdBy}</li>
            <li>Date: ${auditObject.date}</li>
            <li>Text: ${auditObject.text}</li>
        </ul>`
    )
);

export const transferId = number;

export const transferComments = string;
