import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import { ACCEPTED_ATTACHMENT_EXTENSIONS } from '@treasury/domain/channel/types/arp/constants.js';
import { Record } from '@treasury/FDL/record';
import { ListeningElementMixin } from '@treasury/omega/components';
import OmegaDialog from '@treasury/omega/components/omega-dialog.js';
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import { accountNumber } from '@treasury/policy/account';
import { datePicker, money, number, string } from '@treasury/policy/primitives';
import { css, html, nothing } from 'lit';
import {
    ACCOUNT_NUMBER,
    AMOUNT,
    CHECK_NUMBER,
    DUPLICATE_CHECK,
    PAYEE,
    POSTED_DATE,
} from '../exception-correction-items.js';

const ListeningElement = ListeningElementMixin(OmegaDialog);

class CorrectionRequestDialog extends ListeningElement {
    static get properties() {
        return {
            correctionItems: Array,
            reviewStep: Boolean,
            record: Record,
            saving: Boolean,
            _hasAttachmentEntitlement: { state: true, type: Boolean },
        };
    }

    constructor() {
        super();
        this.fields = {
            comment: string.with.rowCount(3).and.maxLength(150).as.tag('omega-textarea'),
        };
        this.reviewStep = false;
        this.correctedRecord = new Record(this.fields, { comment: '' });
        this.correctionItems = [];
        this.saving = false;
        this.addCorrectionItem();
    }

    async firstUpdated() {
        this._hasAttachmentEntitlement = await EntitlementsService.instance.hasEntitlement(
            'Feature.PositivePay.CheckException.Attachments'
        );
    }

    addCorrectionItem() {
        const correctionItemIds = this.correctionItems.map(item => item.values.itemToCorrect.id);
        const options = [
            {
                value: { id: ACCOUNT_NUMBER, field: 'accountNumber' },
                text: 'Receiving Bank - Account Number',
            },
            {
                value: { id: POSTED_DATE, field: 'postedDate' },
                text: 'Receiving Bank - Date Posted',
            },
            {
                value: { id: CHECK_NUMBER, field: 'checkNumber' },
                text: 'Receiving Bank - Check Number',
            },
            {
                value: { id: AMOUNT, field: 'issuedAmount' },
                text: 'Receiving Bank - Amount',
            },
            {
                value: { id: PAYEE, field: 'issuedPayee' },
                text: 'Receiving Bank - Payee',
            },
            {
                value: { id: DUPLICATE_CHECK, field: 'duplicateCheckNumber' },
                text: 'Duplicate Check',
            },
        ];
        function selectedItemIs(record, field) {
            return record.getField('itemToCorrect')?.field === field;
        }
        const newItem = new Record(
            {
                itemToCorrect: string.with
                    .options(options.filter(option => !correctionItemIds.includes(option.value.id)))
                    .thatIs.visibleWhen(() => !this.reviewStep)
                    .with.placeholder('Select an item to correct')
                    .with.label('Item to Correct')
                    .as.tag('omega-select'),
                accountNumber: accountNumber.thatIs
                    .visibleWhen(record => selectedItemIs(record, 'accountNumber'))
                    .thatIs.readOnlyWhen(() => this.reviewStep)
                    .with.label('Corrected Account')
                    .thatHas.maxLength(16)
                    .as.tag('omega-input'),
                postedDate: datePicker.thatIs
                    .visibleWhen(record => selectedItemIs(record, 'postedDate'))
                    .thatIs.readOnlyWhen(() => this.reviewStep)
                    .with.label('Corrected Date Posted')
                    .as.tag('omega-datepicker'),
                checkNumber: number.thatIs
                    .visibleWhen(record => selectedItemIs(record, 'checkNumber'))
                    .thatIs.readOnlyWhen(() => this.reviewStep)
                    .with.label('Corrected Check Number')
                    .thatHas.maxLength(11)
                    .as.tag('omega-input'),
                issuedAmount: money.thatIs
                    .visibleWhen(record => selectedItemIs(record, 'issuedAmount'))
                    .thatIs.readOnlyWhen(() => this.reviewStep)
                    .with.label('Corrected Amount')
                    .thatHas.maxLength(15)
                    .with.parser(v => v.replace(/\$/g, ''))
                    .as.tag('omega-input'),
                issuedPayee: string.thatIs
                    .visibleWhen(record => selectedItemIs(record, 'issuedPayee'))
                    .thatIs.readOnlyWhen(() => this.reviewStep)
                    .with.label('Corrected Payee')
                    .and.inputMask(/[A-Za-z0-9]/)
                    .thatHas.maxLength(80)
                    .as.tag('omega-input'),
                duplicateCheckNumber: string.thatIs
                    .visibleWhen(
                        record => this.reviewStep && selectedItemIs(record, 'duplicateCheckNumber')
                    )
                    .with.template(() => ``)
                    .thatIs.readOnlyWhen(() => this.reviewStep)
                    .with.label('Duplicate Check Number')
                    .as.tag('omega-input'),
            },
            {
                accountNumber: '',
                checkNumber: '',
                issuedPayee: '',
                duplicateCheckNumber: '',
            }
        );
        this.listenTo(newItem, 'change', () => {
            this.requestUpdate();
        });
        this.correctionItems = [...this.correctionItems, newItem];
    }

    setReviewStep(value) {
        this.reviewStep = value;
    }

    isValidCorrectedField(field) {
        const selectedItem = field.getField('itemToCorrect')?.field || '';
        if (selectedItem === 'duplicateCheckNumber') {
            return false;
        }
        return !selectedItem || !field.getField(selectedItem);
    }

    shouldDisableButton() {
        const lastRecord = this.correctionItems.slice(-1)[0];
        return this.isValidCorrectedField(lastRecord);
    }

    shouldDisableMoveToReviewStep() {
        return this.correctionItems.reduce(
            (prev, curr) => prev || this.isValidCorrectedField(curr),
            false
        );
    }

    removeItem() {
        this.correctionItems = this.correctionItems.slice(0, -1);
    }

    async sendRequest() {
        this.saving = true;
        const items = this.correctionItems.map(item => {
            const { field, id } = item.getField('itemToCorrect');
            return {
                ExceptionCorrectionItem: id,
                CorrectionValue: item.parse(field, item.print(field)),
            };
        });

        const correctionRequest = {
            ArpExceptionDetailUniqueId: this.record.getField('arpExceptionDetailUniqueId'),
            ExceptionCorrectionItems: items,
        };
        if (this.correctedRecord.getField('comment')) {
            correctionRequest.Comment = this.correctedRecord.getField('comment');
        }
        try {
            await CheckExceptionsServices.saveCorrectionRequest(this.file, correctionRequest);
            this.result = true;
            this.record.setField('isCorrectionRequestCreated', this.result);
            this.dispatchEvent(new CustomEvent('correctionRequestSuccess', { bubbles: true }));
        } catch (e) {
            this.dispatchEvent(
                new CustomEvent('correctionRequestError', { bubbles: true, detail: e })
            );
            this.result = false;
        }
        this.saving = false;
        this.close(this.result);
    }

    correctionItemIsDuplicateCheck(correctionItem) {
        return correctionItem.getField('itemToCorrect')?.field === 'duplicateCheckNumber';
    }

    checkForDuplicateCheckNumber() {
        return this.correctionItems.reduce(
            (prev, curr) => prev || this.correctionItemIsDuplicateCheck(curr),
            false
        );
    }

    renderHeader() {
        return html`
            <div class="omega-dialog-header">
                <span class="omega-dialog-header-title">Review Correction Request</span>
                <span class="omega-dialog-header-actions">
                    <omega-button
                        class="omega-dialog-menu-button close-button"
                        type="icon"
                        icon="times"
                        hideLabel="always"
                        @click=${this.toggle}
                    >
                        Close
                    </omega-button>
                </span>
            </div>
        `;
    }

    renderAddItemButton() {
        if (this.shouldDisableButton() || this.correctionItems.length >= 4) return nothing;

        return html`
            <omega-button @click=${() => this.addCorrectionItem()}>
                + Add Another Correction
            </omega-button>
        `;
    }

    renderRemoveButton(index, selectedItemToCorrect) {
        if (index === 0 || selectedItemToCorrect) return nothing;
        return html`
            <omega-button class="remove-button" type="icon" @click=${() => this.removeItem()}
                ><svg
                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                    xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns:svg="http://www.w3.org/2000/svg"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                    viewBox="0 -256 1792 1792"
                    id="svg3013"
                    version="1.1"
                    inkscape:version="0.48.3.1 r9886"
                    width="100%"
                    height="100%"
                    sodipodi:docname="remove_sign_font_awesome.svg"
                >
                    <metadata id="metadata3023">
                        <rdf:RDF>
                            <cc:Work rdf:about="">
                                <dc:format>image/svg+xml</dc:format>
                                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                            </cc:Work>
                        </rdf:RDF>
                    </metadata>
                    <defs id="defs3021" />
                    <sodipodi:namedview
                        pagecolor="#ffffff"
                        bordercolor="#666666"
                        borderopacity="1"
                        objecttolerance="10"
                        gridtolerance="10"
                        guidetolerance="10"
                        inkscape:pageopacity="0"
                        inkscape:pageshadow="2"
                        inkscape:window-width="640"
                        inkscape:window-height="480"
                        id="namedview3019"
                        showgrid="false"
                        inkscape:zoom="0.13169643"
                        inkscape:cx="896"
                        inkscape:cy="896"
                        inkscape:window-x="0"
                        inkscape:window-y="25"
                        inkscape:window-maximized="0"
                        inkscape:current-layer="svg3013"
                    />
                    <g transform="matrix(1,0,0,-1,136.67797,1293.0169)" id="g3015">
                        <path
                            d="m 1149,414 q 0,26 -19,45 l -181,181 181,181 q 19,19 19,45 0,27 -19,46 l -90,90 q -19,19 -46,19 -26,0 -45,-19 L 768,821 587,1002 q -19,19 -45,19 -27,0 -46,-19 l -90,-90 q -19,-19 -19,-46 0,-26 19,-45 L 587,640 406,459 q -19,-19 -19,-45 0,-27 19,-46 l 90,-90 q 19,-19 46,-19 26,0 45,19 L 768,459 949,278 q 19,-19 45,-19 27,0 46,19 l 90,90 q 19,19 19,46 z m 387,226 Q 1536,431 1433,254.5 1330,78 1153.5,-25 977,-128 768,-128 559,-128 382.5,-25 206,78 103,254.5 0,431 0,640 0,849 103,1025.5 206,1202 382.5,1305 559,1408 768,1408 977,1408 1153.5,1305 1330,1202 1433,1025.5 1536,849 1536,640 z"
                            id="path3017"
                            inkscape:connector-curvature="0"
                            style="fill:currentColor"
                        />
                    </g>
                </svg>
            </omega-button>
        `;
    }

    renderField(field, record, index) {
        if (this.correctionItemIsDuplicateCheck(record) && !this.reviewStep) {
            return html`
                <div class="duplicate-check-row">
                    <omega-field .record=${record} .field=${field}></omega-field>
                    ${this.renderRemoveButton(index, record.getField('itemToCorrect'))}
                </div>
            `;
        }
        if (this.reviewStep) {
            if (field === 'duplicateCheckNumber') {
                return html`<omega-field .record=${record} .field=${field}></omega-field>`;
            }
            return html`<omega-field .record=${this.record} .field=${field}></omega-field>
                <omega-field .record=${record} .field=${field}></omega-field>`;
        }

        return html`
            <omega-field .record=${record} .field=${field}></omega-field>
            ${this.renderRemoveButton(index, record.getField('itemToCorrect'))}
        `;
    }

    renderFields() {
        return this.correctionItems.map((record, index) =>
            Object.keys(record.fieldTypes).map(field => {
                if (record.fieldTypeForField(field).visible(record)) {
                    return this.renderField(field, record, index);
                }
                return nothing;
            })
        );
    }

    renderComment() {
        return html`<h3 class="comments-label">Comments:</h3>
            <div class="comments">${this.correctedRecord.getField('comment')}</div>`;
    }

    renderAttachmentReview() {
        if (!this.file) return nothing;
        return html`
            <i class="fa fa-paperclip"></i>
            <span>${this.file.name}</span>
        `;
    }

    renderReviewBody() {
        return html`
            <div class="dialog-content">
                <span class="account-label">Account:</span> ${this.record.getField(
                    'accountNumber'
                )}, <span class="check-number-label">Check Number:</span> ${this.record.getField(
                    'checkNumber'
                )}
                <h3>Items to Correct</h3>
                <div>${this.renderFields()}</div>
                <div class="comment-review">${this.renderComment()}</div>
                <div class="attachment-review">${this.renderAttachmentReview()}</div>
            </div>
        `;
    }

    renderAddAttachmentButton() {
        if (!this._hasAttachmentEntitlement) return nothing;
        return html`
            <omega-file-upload
                icon="true"
                buttonText="Add Attachment"
                buttonIcon="paperclip"
                .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                @filesUploaded=${e => {
                    [this.file] = e.detail.files;
                }}
            ></omega-file-upload>
        `;
    }

    renderCorrectionBody() {
        return html`
            <div class="dialog-content">
                <div class="form-rows">${this.renderFields()}</div>
                ${this.renderAddItemButton()}
                <div class="label comment-label">Comment to FI</div>
                <omega-field
                    id="comment-field"
                    .record=${this.correctedRecord}
                    field="comment"
                ></omega-field>
                ${this.renderAddAttachmentButton()}
            </div>
        `;
    }

    renderBody() {
        return this.reviewStep ? this.renderReviewBody() : this.renderCorrectionBody();
    }

    renderActions() {
        if (this.reviewStep)
            return html`
                <omega-button
                    type="primary"
                    @click=${() => this.sendRequest()}
                    .loading=${this.saving}
                >
                    Send Request
                </omega-button>

                <omega-button @click=${() => this.close('cancel')} .loading=${this.saving}
                    >Cancel</omega-button
                >
            `;
        return html`
            <omega-button
                type="primary"
                .disabled=${this.shouldDisableMoveToReviewStep()}
                @click=${() => this.setReviewStep(true)}
            >
                Review
            </omega-button>
            <omega-button @click=${() => this.close('cancel')}>Cancel</omega-button>
        `;
    }

    static get styles() {
        return [
            super.styles,
            fontAwesome,
            css`
                omega-field {
                    padding: 0px 10px 10px;
                }
                omega-field[readonly] {
                    padding: 0;
                }
                .omega-dialog-header {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: var(--omega-help-border);
                }
                .omega-dialog-header-title {
                    padding: 0 15px;
                }
                .dialog-content {
                    padding: 15px 10px 10px 20px;
                    min-height: 225px;
                    width: 530px;
                }
                .form-rows {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }

                .omega-dialog-actions {
                    justify-content: center;
                    border-top: var(--omega-help-border);
                }

                #comment-field {
                    padding-top: 0px;
                }
                .comment-label {
                    margin-left: 10px;
                }
                .remove-button {
                    width: 40px;
                    height: 40px;
                    margin-top: 40px;
                    margin-left: -10px;
                }
                .comments-label {
                    margin-left: -5px;
                }
                .comments {
                    font-size: var(--omega-label);
                    white-space: pre-wrap;
                }
                .attachment-review {
                    margin: 10px;
                    font-size: var(--omega-label);
                }
                .duplicate-check-row {
                    grid-column: 1/3;
                    width: 50%;
                }
            `,
        ];
    }
}
customElements.define('correction-request-dialog', CorrectionRequestDialog);
export default CorrectionRequestDialog;
