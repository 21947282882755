// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

export default class SecCodesRequests {
    static async getSecCodes() {
        return (await TmHttpClient.getInstance()).request(`secCodes`, {
            method: 'GET',
            maxAgeInSeconds: 300,
        });
    }

    static async getAllSecCodes() {
        return (await TmHttpClient.getInstance()).request(`arp/AllSecCodes`, {
            method: 'GET',
            maxAgeInSeconds: 300,
        });
    }
}
