export const maxAmountValidator = (amount: number) => ({
    name: 'maxAmount',
    validate(modelValue: number /*viewValue, record, options*/) {
        if (Array.isArray(modelValue)) return true; //TODO: This is due to omega-range needing to go away.
        if (!modelValue) {
            return true;
        }
        return modelValue <= amount;
    },
});
