import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';

export default class AccountReconClient {
    async fetchAccounts() {
        return AchAccountReconciliationService.fetchAccounts();
    }

    async fetchReports(parameters) {
        return AchAccountReconciliationService.fetchReports(parameters);
    }

    async fetchReportFiles(parameters) {
        return AchAccountReconciliationService.fetchReportFiles(parameters);
    }

    async deleteReport(reportId) {
        return AchAccountReconciliationService.deleteReport(reportId);
    }
}
