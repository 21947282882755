AddendaModalController.$inject = ['$scope', '$modalInstance', 'data', 'readOnly', 'regexConstants'];

// eslint-disable-next-line @treasury/filename-match-export
export default function AddendaModalController(
    $scope,
    $modalInstance,
    data,
    readOnly,
    regexConstants
) {
    $scope.addenda = {};
    $scope.close = close;
    $scope.setForm = setForm;
    $scope.readOnly = readOnly;
    $scope.save = save;
    $scope.addendaTypes = data.addendaTypes;
    $scope.batch = data.batch;
    if (data.recipient.addenda) {
        $scope.addendas = data.recipient.addenda;
    } else {
        $scope.addendas = [];
    }

    $scope.copyAddendas = angular.copy($scope.addendas);
    $scope.addAddenda = addAddenda;
    $scope.deleteAddenda = deleteAddenda;
    $scope.validation = { addendaPattern: regexConstants.AlphaNumericPlusSpecialCharsRegex };

    // this will dismiss the window.
    function close() {
        let isDirty;
        if ($scope.form.$dirty) {
            isDirty = true;
        }
        $scope.addendas = angular.copy($scope.copyAddendas);
        const data = { dirty: isDirty, addendas: $scope.addendas };
        $modalInstance.dismiss(data);
    }

    // used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function save() {
        let isDirty;
        if ($scope.form.$dirty) {
            isDirty = true;
        }
        const addendas = [];
        $scope.addendas.forEach(addenda => {
            if (addenda.value.length > 0) {
                addendas.push(addenda);
            }
        });
        const data = { dirty: isDirty, addendas };
        $modalInstance.close(data);
    }

    function addAddenda() {
        $scope.addendas.push({ key: '', value: '' });
    }

    function deleteAddenda(index) {
        $scope.addendas.splice(index, 1);
    }
}
