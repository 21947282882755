/* eslint-disable @treasury/no-date */
import AchAccountReconciliationService from '@treasury/domain/channel/services/ach/ach-account-reconciliation-service.js';
import { multipleAccounts } from '@treasury/policy/account.js';
import { dateRange, number, string } from '@treasury/policy/primitives';

export const AccountReconMessage = {
    SaveAndRunMessage:
        'Your request has been submitted and will be ready shortly. Please access your Report Library to view your report.',
    SaveMessage: 'Your report has been saved.',
};
export const fields = {
    type: number.with
        .label('Report Type')
        .and.options({
            fetch: async () => {
                const options = await AchAccountReconciliationService.fetchReportTypes();
                return options.sort(
                    (a, b) => (a.displayName > b.displayName) - (a.displayName < b.displayName)
                );
            },
            text: 'displayName',
            value: reportType => reportType,
        })
        .thatHas.hashFunction(reportType => reportType?.id)
        .and.placeholder('Select Report Type')
        .thatIs.readOnlyWhen(record => record.getField('step') > 0)
        .thatIs.requiredWhen(record => record.getField('step') === 0)
        .with.formatter(report => report.displayName)
        .as.tag('omega-select'),
    reportName: string.with
        .label('Report Name')
        .thatHas.maxLength(30)
        .thatIs.readOnlyWhen(record => record.getField('step') > 0)
        .thatIs.requiredWhen(record => record.getField('step') === 0)
        .as.tag('omega-input'),
    accountIds: multipleAccounts.with
        .label('Accounts')
        .and.options({
            fetch: async () => AchAccountReconciliationService.fetchAccounts(),
            text: item => `${item.name} - ${item.number}`,
            value: item => item,
        })
        .thatHas.hashFunction(account => account?.id)
        .thatIs.readOnlyWhen(record => record.getField('step') > 0)
        .thatIs.requiredWhen(record => record.getField('step') === 0)
        .and.multipleValues()
        .thatHas.filtering()
        .and.template((value, record) => {
            const accounts = record
                .getField('accountIds')
                .map(account => `${account.type} - ${account.number}`);
            return accounts.join(', ');
        })
        .with.defaultValue([])
        .as.tag('omega-select'),
    activityPeriod: dateRange.with
        .label('Activity Period')
        .thatIs.readOnlyWhen(record => record.getField('step') > 0)
        .thatIs.requiredWhen(record => record.getField('step') === 0)
        .and.options({
            fetch: async record => {
                const typeId = record.getField('type')?.referenceId;
                return AchAccountReconciliationService.getReportPeriodOptions(typeId);
            },
        })
        .thatHas.validator({
            name: 'valid date selection is required',
            validate: value => value.id,
        })
        .thatHas.template((value, record) => {
            if (
                [
                    'previous-week',
                    'previous-month',
                    'previous-year',
                    'week-to-date',
                    'month-to-date',
                    'year-to-date',
                ].includes(record.getField('activityPeriod').id)
            ) {
                return record.getField('activityPeriod').id.replace(/-/g, ' ');
            }
            return value;
        })
        .thatHas.defaultValue('')
        .as.tag('omega-datepicker'),
};

const mapSelectedReportActivityPeriod = selectedReportValues => ({
    dates: [selectedReportValues.activityPeriodStart, selectedReportValues.activityPeriodEnd],
    exact: !!['specific', 'as-of', 'prior-to'].includes(selectedReportValues.selectedPeriodOption),
    isRange: selectedReportValues.selectedReportPeriodOption === 'range',
    id: selectedReportValues.selectedReportPeriodOption,
});

const values = selectedReportValues => ({
    type: selectedReportValues.externalReportType || '',
    reportName: selectedReportValues.reportName || '',
    accountIds: selectedReportValues.accounts ? selectedReportValues.accounts : [],
    activityPeriod: mapSelectedReportActivityPeriod(selectedReportValues) || 'select-date',
});

export const schema = selectedReportValues => ({
    fields,
    values: values(selectedReportValues ?? {}),
});

export const steps = context => [
    {
        label: context.reportValues ? 'Edit Report' : 'Create Report',
        step: 0,
        actions: [
            {
                label: 'Review',
                type: 'primary',
                submit: true,
                action: record => record.setField('step', 1),
            },
            {
                label: 'Cancel',
                type: 'link',
                action: () => context.promptCancellation(),
            },
        ],
    },
    {
        label: 'Review',
        step: 1,
        actions: [
            {
                label: 'Back',
                type: 'secondary',
                action: record => record.setField('step', 0),
            },
            {
                label: 'Save',
                type: 'secondary',
                action: record => {
                    context.submitReport(record);
                },
            },
            {
                label: 'Save and Run',
                type: 'primary',
                action: record => {
                    context.submitReport(record, true);
                },
                visibleWhen: record => {
                    // eslint-disable-next-line prefer-const
                    let [start, end] = record.getField('activityPeriod').dates;
                    if (!end) end = start;
                    const startDate = new Date(start);
                    const endDate = new Date(end);
                    const todayDate = new Date();
                    return startDate <= todayDate && endDate <= todayDate;
                },
            },
            {
                label: 'Cancel',
                type: 'link',
                action: () => context.promptCancellation(),
            },
        ],
    },
    {
        label: 'Confirm',
        step: 2,
        actions: [
            {
                label: 'Create New Report',
                type: 'primary',
                action: record => {
                    context.alert = {
                        ...context.alert,
                        visible: false,
                    };
                    context.reportValues = {};
                    record.clear();
                    record.setField('step', 0);
                },
            },
            {
                label: 'View Report Library',
                type: 'link',
                action: () => context.viewReportLibrary(),
            },
            {
                label: 'View Report Templates',
                type: 'link',
                action: () => context.viewReportTemplates(),
            },
            {
                label: 'Run Report',
                type: 'secondary',
                action: record => context.runReport(record.values),
                visibleWhen: record => {
                    // eslint-disable-next-line prefer-const
                    let [start, end] = record.getField('activityPeriod').dates;
                    if (!end) end = start;
                    const startDate = new Date(start);
                    const endDate = new Date(end);
                    const todayDate = new Date();
                    return (
                        startDate <= todayDate &&
                        endDate <= todayDate &&
                        context.shouldShowRunButton()
                    );
                },
            },
        ],
    },
];
