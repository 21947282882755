import { getChannelAPIPath } from '@treasury/core/http';

AccountTransactionsService.$inject = ['$resource'];

export default function AccountTransactionsService($resource) {
    const resourceUrl = `${getChannelAPIPath()}accounttransactions/`;
    return {
        getAccountSummary,
        getAccountList,
        getAccountTransactions,
        getAccountTransactionsWithDates,
        getAccountsCoreData,
    };

    function getAccountTransactions(category, id) {
        return $resource(`${resourceUrl}:id/:type`, { id, type: category }).get().$promise;
    }

    function getAccountTransactionsWithDates(category, id, fromDate, toDate) {
        return $resource(
            `${resourceUrl}GetByDateRange/:id/:type?fromDate=:fromDate&toDate=:toDate`,
            {
                id,
                type: category,
                fromDate,
                toDate,
            }
        ).get().$promise;
    }

    function getAccountSummary(category) {
        return $resource(`${resourceUrl}?category=:category`, { category }).query().$promise;
    }

    function getAccountList(category) {
        return $resource(`${resourceUrl}accounts/?category=:category`, {
            category,
        }).query().$promise;
    }

    function getAccountsCoreData(accountIds, includeCollectedBalance, includePermissions) {
        return $resource(
            `${resourceUrl}accounts/coredata`,
            {},
            { get: { method: 'GET', isArray: true } }
        ).get({
            accountIds,
            includeCollectedBalance,
            includePermissions,
        }).$promise;
    }
}
