/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { IIssuedItem } from '@treasury/domain/channel/mappings/arp';
import { Recordset } from '@treasury/FDL';
import { formatUsd } from '@treasury/policy/lib/formatters';
import { html } from 'lit';
import { createAccountsSummary, IssuedItemAccountSummary } from '../shared.helpers';

export function renderAccountsSummary(
    recordset: Recordset<IIssuedItem>,
    includeExistingVoids: boolean
) {
    const items = recordset.allRecords.map(r => r.values);
    const accountSummaries = createAccountsSummary(items, includeExistingVoids);

    if (accountSummaries.length < 1) {
        return html`<div class="full-width text-center">
            <strong>No account information available.</strong>
        </div>`;
    }

    const amountSum = accountSummaries
        .map(account => account.amountTotal)
        // eslint-disable-next-line no-return-assign
        .reduce((total, amount) => total + amount, 0);

    return html`
        <table class="full-width">
            <tbody>
                <tr>
                    <td><strong>Account Number</strong></td>
                    <td class="text-center">
                        <strong>Item Count</strong>
                    </td>
                    <td class="text-right">
                        <strong>Amount Total</strong>
                    </td>
                </tr>
                ${accountSummaries.map(renderAccountRow)}
            </tbody>
        </table>
        <hr />
        <div class="text-right">Total Amount: ${formatUsd(amountSum)}</div>
    `;
}

function renderAccountRow(account: IssuedItemAccountSummary) {
    return html` <tr>
        <td>${account.accountName}</td>
        <td class="text-center">${account.itemCount}</td>
        <td class="text-right">${formatUsd(account.amountTotal)}</td>
    </tr>`;
}
