import {
    AchBankDto,
    AchCompany,
    BatchDetails,
    PaymentHeader,
    PaymentRecipient,
    PaymentRecipientDto,
} from '@treasury/domain/channel/types/ach';
import { Record, Recordset } from '@treasury/FDL';

export const lookupBank = (bankOptions: Array<AchBankDto>, routingNumber: string): AchBankDto => {
    const bank: AchBankDto | undefined = bankOptions.find(
        (bankOption: AchBankDto) => bankOption.bankId === routingNumber
    );
    return bank || {};
};

export const setNachaRecipientsData = async (
    recipientsRecordset: Recordset<PaymentRecipient>,
    recipients: Array<PaymentRecipientDto>
) => {
    const record = recipientsRecordset.allRecords[0];
    const banks = await record.fieldTypeForField('bank').options(record);
    return Promise.all(
        recipients.map(async (recipient): Promise<PaymentRecipient> => {
            const routingNumber = recipient.routingNumber ?? '';
            const bank = lookupBank(banks, routingNumber);
            const {
                prenote,
                hold,
                name,
                idNumber,
                accountNumber,
                accountType,
                amount,
                addenda,
                errors,
                transactionType,
            } = recipient;

            return {
                prenote,
                hold,
                name,
                idNumber,
                accountNumber,
                accountType,
                amount,
                addenda: addenda ? `${addenda}` : null,
                errors,
                transactionType,
                bank,
                step: 0,
                fromMasterList: false,
            };
        })
    ).then(values => recipientsRecordset.setData(values));
};

export const setAchCompany = (paymentHeaderRecord: Record<PaymentHeader>) => {
    const companyData = paymentHeaderRecord.getField('achCompany');
    return paymentHeaderRecord
        .fieldTypeForField('achCompany')
        .options(paymentHeaderRecord)
        .then((options: AchCompany[]) => {
            const selectedCompany =
                options.find(
                    option =>
                        option && companyData && option.companyName === companyData.companyName
                ) || companyData;
            paymentHeaderRecord.setField('achCompany', selectedCompany);
        });
};
