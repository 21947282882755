import { LitElement, html, css, nothing } from 'lit';

import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-input';

class AchPaymentAddendaDialog extends LitElement {
    static get properties() {
        return {
            selectedRecord: Object,
            addenda: String,
            editable: Boolean,
        };
    }

    render() {
        if (!this.selectedRecord) return nothing;
        return html`<omega-dialog
            .dialogTitle=${'Addenda'}
            id="addenda-dialog"
            open
            @close=${() => {
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <div slot="content">
                ${this.editable
                    ? html`<omega-input
                          .value=${this.addenda?.value}
                          @change=${({ detail }) => {
                              this.addenda.value = detail;
                          }}
                      ></omega-input>`
                    : this.addenda?.value}
            </div>

            ${this.editable
                ? html` <omega-button
                      slot="actions"
                      type="primary"
                      @click=${() => {
                          this.selectedRecord.setField('addenda', [this.addenda]);
                          this.dispatchEvent(new CustomEvent('close'));
                      }}
                      >Save</omega-button
                  >`
                : nothing}

            <omega-button
                slot="actions"
                @click=${() => {
                    this.dispatchEvent(new CustomEvent('close'));
                }}
                >Cancel</omega-button
            >
        </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}

customElements.define('ach-payment-addenda-dialog', AchPaymentAddendaDialog);
