import { css, html, LitElement, nothing } from 'lit';

import { Record } from '@treasury/FDL/record';
import { string } from '@treasury/policy/primitives';

import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-textarea';

class AchPaymentActionDialog extends LitElement {
    static get properties() {
        return {
            paymentHeaderRecord: Object,
            batchPaymentRecords: Array,
            submittingActionOnPayment: Boolean,
            statusChangeComments: String,
            actionTaken: String,
            showActionDialog: Boolean,
            dialogTitle: String,
        };
    }

    constructor() {
        super();
        this.rowCount = 5;
        this.submittingActionOnPayment = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this.statusCommentRecord = new Record({ comments: string }, { comments: '' });
    }

    getDialogTitle() {
        return this.dialogTitle ?? 'Comments For Status Change';
    }

    submitActionOnPayment() {
        this.submittingActionOnPayment = true;
        this.dispatchEvent(new CustomEvent('submit', { detail: this.statusChangeComments }));
        this.statusChangeComments = '';
        this.statusCommentRecord.setField('comments', '');
    }

    getTransactionIds() {
        if (this.batchPaymentRecords && this.batchPaymentRecords.length > 0) {
            return this.batchPaymentRecords
                .map(record => record.getField('transactionId'))
                .join(', ');
        }
        return this.paymentHeaderRecord.getField('transactionId');
    }

    closeEvent() {
        this.showActionDialog = false;
        this.statusChangeComments = '';
        this.statusCommentRecord.setField('comments', '');
        this.dispatchEvent(new CustomEvent('close'));
        if (this.closeEventHandler) this.closeEventHandler();
    }

    render() {
        if (this.showActionDialog) {
            const buttonDetails = {
                delete: { text: 'Delete', type: 'reject' },
                approve: { text: 'Approve', type: 'approve' },
                cancel: { text: 'Cancel Payment', type: 'reject' },
                reject: { text: 'Reject', type: 'reject' },
                uninitiate: { text: 'Uninitiate', type: 'reject' },
            };
            const actionButton = html`<omega-button
                slot="actions"
                type="${buttonDetails[this.actionTaken].type}"
                .loading=${this.submittingActionOnPayment}
                @click=${this.submitActionOnPayment}
                >${buttonDetails[this.actionTaken].text}</omega-button
            >`;
            return html`<omega-dialog
                id="payment-action-dialog"
                open
                .dialogTitle=${this.getDialogTitle()}
                @close=${this.closeEvent}
            >
                <div slot="content" id="modalContent">
                    Are you sure you want to ${this.actionTaken} ${this.getTransactionIds()}?
                    <br />
                    <br />
                    Comments:
                    <omega-textarea
                        .rows=${this.rowCount}
                        .field=${this.statusCommentRecord.field('comments')}
                        maxLength="150"
                        .resizable=${false}
                        @change=${({ detail }) => {
                            this.statusChangeComments = detail;
                        }}
                    ></omega-textarea>
                </div>
                ${actionButton}
                <omega-button slot="actions" @click=${this.closeEvent}>Close</omega-button>
            </omega-dialog>`;
        }
        return nothing;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            #modalContent {
                padding: 15px;
            }
        `;
    }
}

customElements.define('ach-payment-action-dialog', AchPaymentActionDialog);
