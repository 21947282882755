AccountGroupAccountListModalController.$inject = [
    '$scope',
    '$modalInstance',
    '$filter',
    'modalService',
    'dashboardService',
    'group',
    'maxAccountsPerGroup',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function AccountGroupAccountListModalController(
    $scope,
    $modalInstance,
    $filter,
    modalService,
    dashboardService,
    group,
    maxAccountsPerGroup
) {
    $scope.MAX_ACCOUNTS_PER_GROUP = maxAccountsPerGroup;
    $scope.accounts = null;
    $scope.allSelected = { value: false };
    $scope.filteredAccounts = null;
    $scope.group = angular.copy(group);
    $scope.isDirty = false;

    $scope.$watch('accounts', onAccountsChanged, true);
    $scope.$watch('accountSearchText', onAccountSearchTextChanged);

    $scope.close = close;
    $scope.confirm = confirm;
    $scope.isNewGroup = isNewGroup;
    $scope.setDirty = setDirty;
    $scope.toggleAll = toggleAll;
    $scope.updateSelectAllCheckbox = updateSelectAllCheckbox;
    $scope.isAddAccountsDisabled = isAddAccountsDisabled;
    $scope.isBeyondMaximumAccountCount = isBeyondMaximumAccountCount;

    init();

    function init() {
        dashboardService.getAccounts().then(response => {
            $scope.accounts = excludeExistingAccounts(response, $scope.group.accounts);
        });
    }

    function isNewGroup() {
        return !$scope.group.id;
    }

    function confirm() {
        $scope.group.accounts = $scope.group.accounts || [];
        addSelectedAccounts();
        $modalInstance.close($scope.group);
    }

    function close() {
        let modalOptions;
        let modalInstance;

        if ($scope.isDirty || isNewGroup()) {
            modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit() {
                    $modalInstance.dismiss();
                    modalInstance.dismiss();
                },
            };
            modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $modalInstance.dismiss();
        }
    }

    function setDirty() {
        $scope.isDirty = true;
    }

    function toggleAll() {
        $scope.isDirty = true;
        $scope.filteredAccounts.forEach(filteredAccount => {
            filteredAccount.isSelected = $scope.allSelected.value;
        });
    }

    function updateSelectAllCheckbox() {
        $scope.isDirty = true;
        $scope.allSelected.value = true;
        $scope.filteredAccounts.forEach(filteredAccount => {
            if (!filteredAccount.isSelected) {
                $scope.allSelected.value = false;
            }
        });
    }

    function isAddAccountsDisabled() {
        return !isAtLeastOneAccountChecked() || isBeyondMaximumAccountCount();
    }

    function isBeyondMaximumAccountCount() {
        return (
            selectedAccounts().length + $scope.group.accounts.length > $scope.MAX_ACCOUNTS_PER_GROUP
        );
    }

    function onAccountsChanged() {
        $scope.filteredAccounts = $filter('filter')($scope.accounts, $scope.accountSearchText);
    }

    function onAccountSearchTextChanged(value) {
        $scope.filteredAccounts = $filter('filter')($scope.accounts, value);
    }

    function excludeExistingAccounts(accounts, existingAccounts) {
        return accounts.filter(account =>
            existingAccounts.every(existing => existing.id !== account.id)
        );
    }

    function addSelectedAccounts() {
        Array.prototype.push.apply($scope.group.accounts, selectedAccounts());
    }

    function isAtLeastOneAccountChecked() {
        return $scope.accounts && $scope.accounts.some(account => account.isSelected);
    }

    function selectedAccounts() {
        if (!$scope.accounts) {
            return [];
        }
        return $scope.accounts.filter(account => account.isSelected);
    }
}
