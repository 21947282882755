const paymentCanBeCancelled = status =>
    [
        'PendingApproval',
        'Pending Approval',
        'ApprovalRejected',
        'Approval Rejected',
        'Scheduled',
        'Uninitiated',
    ].includes(status);

export default paymentCanBeCancelled;
