/* eslint-disable sonarjs/no-nested-template-literals */
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const omegaToastTagName = 'omega-toast';
@customElement(omegaToastTagName)
export class OmegaToast extends LitElement {
    @property({ type: String })
    type = 'success';

    @property({ type: String })
    message = '';

    @property({ type: Boolean })
    visible = false;

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-toast--toast',
        };
    }

    updated(changedProps: Map<string | number | symbol, unknown>) {
        if (changedProps.has('visible') && this.visible) {
            setTimeout(() => {
                this.closeToast(new Event('click'));
            }, 5000);
        }
    }

    closeToast(event: Event) {
        event.stopPropagation();
        const toast = this.shadowRoot?.querySelector('.toast');
        if (!toast) return;
        toast.classList.add('toast-hide');
        setTimeout(() => {
            this.visible = false;
        }, 400);
    }

    render() {
        if (!this.visible) return nothing;
        // TODO: stack will move to service that builds the toasts in a column
        return html`<div id="toast-stack">
            <div class=${`toast toast-${this.type}`}>
                <div class="toast-content">${this.message}</div>
                <button class="toast-close" @click=${this.closeToast}>x</button>
            </div>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                --primary2: rgb(0 123 255 / 95%);
                --success9: rgb(40 167 69 / 95%);
                --error9: rgb(220 53 69 / 95%);
                --primary: rgb(0 123 255);
                --success: rgb(40 167 69);
                --error: rgb(220 53 69);
                --light: rgb(248 249 250);
                --dark: rgb(52 58 64);
            }
            * {
                box-sizing: border-box;
            }
            #toast-stack {
                position: fixed;
                z-index: 10000;
                top: 3vh;
                right: 3vw;
                max-height: 94vh;
                display: grid;
                grid-template-columns: 350px;
                gap: 5px;
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            #toast-stack::-webkit-scrollbar {
                display: none;
            }
            .toast {
                flex: 1;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10px;
                padding: 10px;
                margin-inline: 10px;
                border-width: 1px;
                border-style: solid;
                border-radius: 0.25rem;
                box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;
                color: var(--light);
                font-weight: 300;
                animation: fadeIn 0.5s;
            }
            .toast:first-of-type {
                margin-block-start: 10px;
            }
            .toast:last-of-type {
                margin-block-end: 10px;
            }
            .toast-close {
                border: none;
                cursor: pointer;
                background-color: transparent;
                color: var(--light);
            }
            .toast-success {
                border-color: var(--success9);
                background-color: var(--success9);
            }
            .toast-success:hover {
                border-color: var(--success);
                background-color: var(--success);
            }
            .toast-error {
                border-color: var(--error9);
                background-color: var(--error9);
            }
            .toast-error:hover {
                border-color: var(--error);
                background-color: var(--error);
            }
            .toast-primary {
                border-color: var(--primary2);
                background-color: var(--primary2);
            }
            .toast-primary:hover {
                border-color: var(--primary);
                background-color: var(--primary);
            }
            .toast-dark {
                border-color: var(--dark);
                background-color: var(--dark);
            }
            .toast-light {
                border-color: var(--light);
                background-color: var(--light);
            }
            .toast-hide {
                animation: fadeOut 0.5s;
            }
            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            @keyframes fadeOut {
                0% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
        `;
    }
}
