import { accountNumber, accountType } from '@treasury/policy/account.js';
import { companyName, secCode } from '@treasury/policy/company.js';
import { date, datePicker, id, money, string } from '@treasury/policy/primitives.ts';

export const reportFields = {
    achReturnTransactionUniqueId: id.thatIs.readOnly(),
    effectiveDate: datePicker.thatIs.readOnly().with.label('Original Effective Date'),
    receivedDate: datePicker.thatIs.readOnly().with.label('Date Return Received'),
    achCompanyName: companyName.thatIs.readOnly().with.label('ACH Company'),
    returnReason: string.thatIs.readOnly().with.label('Return Reason').and.maxColumnWidth(500),
    recipientName: string.thatIs.readOnly().with.label('Recipient Name'),
    amount: money.thatIs.readOnly().with.label('Amount'),
    originalEffectiveDate: datePicker.thatIs.readOnly().with.label('Original Effective Date'),
    traceNumber: accountType.thatIs.readOnly().with.label('Trace Number'),
    dateOfDeath: date.thatIs.readOnly().with.label('Date of Death'),
    secCode: secCode.thatIs.readOnly().with.label('SEC Code'),
    accountNumber: accountNumber.thatIs.readOnly().with.label('Recipient Account Number'),
    accountType: accountType.thatIs.readOnly().with.label('Account Type'),
    identificationNumber: id.thatIs.readOnly().with.label('Identification Number'),
    recipientFinancialInstitution: string.thatIs
        .readOnly()
        .with.label('Recipient Financial Institution'),
};
