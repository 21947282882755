import { boolean, string } from '@treasury/FDL';

export const filters = reportFilterValues => [
    {
        field: 'postedTransactions',
        fieldType: boolean.with
            .options([
                { text: 'Posted', value: true },
                { text: 'Pending', value: false },
            ])
            .thatIs.inline(),
        value: reportFilterValues.postedTransactions,
    },
    {
        field: 'accounts',
        fieldType: string.with
            .options({
                data: reportFilterValues.accounts,
                text: record => record.account.accountDisplayLabel,
                value: record => record,
            })
            .and.label('Account:')
            .with.filtering()
            .thatIs.required()
            .thatHas.placeholder('All Accounts'),
        value: reportFilterValues.accounts,
    },
];
export const columns = [];
export const fields = {};
