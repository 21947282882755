import { html } from 'lit';
import '../../../src/terms-and-conditions/terms-and-conditions.js';
import openDialog from '@treasury/omega/open-dialog.js';

FooterController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'modalService',
    '$modal',
    '$sanitize',
];

export default function FooterController(
    $scope,
    $rootScope,
    $state,
    modalService,
    $modal,
    $sanitize
) {
    $scope.isLoginPage = true;
    $scope.state = $state;
    $scope.termsAndConditions = termsAndConditions;
    $scope.copyrightYear = new Date().getFullYear();

    $scope.$watch('state.current.name', newValue => {
        if (
            newValue === '' ||
            newValue === 'login' ||
            newValue.indexOf('login') > -1 ||
            newValue === 'change-password' ||
            newValue.indexOf('security-questions') > -1 ||
            newValue === 'forgot-password' ||
            newValue === 'forgot-password-verification' ||
            newValue === 'locked-out' ||
            newValue === 'email-sent-confirmation' ||
            newValue === 'user-verification' ||
            newValue === 'terms-and-conditions-accept' ||
            newValue === 'verify-security-methods' ||
            newValue === 'collect-secure-token' ||
            newValue === 'rdc-redirect'
        ) {
            $scope.isLoginPage = true;
        } else {
            $scope.isLoginPage = false;
        }
    });

    async function termsAndConditions() {
        const response = await openDialog(document.createElement('omega-dialog'), {
            title: 'Terms & Conditions',
            content: html`
                <div style="margin: 20px">
                    <terms-and-conditions .showAcceptanceDate=${true}></terms-and-conditions>
                </div>
            `,
            buttons: [{ type: 'primary', label: `Close`, result: 'close' }],
        });
    }
}
