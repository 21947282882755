import { getChannelAPIPath } from '@treasury/core/http';

HolidaysService.$inject = ['$resource', '$q'];

export default function HolidaysService($resource, $q) {
    const resourceUrl = `${getChannelAPIPath()}holidays/`;
    return {
        getAll,
        compareDates,
    };

    function getAll() {
        return $resource(resourceUrl).query().$promise;
    }

    function compareDates(date, dates) {
        if (typeof dates === 'undefined' || dates === null || dates.length === 0) {
            return false;
        }
        for (let i = 0; i < dates.length; i++) {
            if (
                (moment(dates[i]).date() === moment(date).date() &&
                    moment(dates[i]).month() === moment(date).month() &&
                    moment(dates[i]).year() === moment(date).year()) ||
                date.getDay() === 0 ||
                date.getDay() === 6
            ) {
                return true;
            }
        }
        return false;
    }
}
