ArpOutputFileListController.$inject = ['$scope', 'arpService', 'downloadPageId'];

export default function ArpOutputFileListController($scope, arpService, downloadPageId) {
    // Variables
    $scope.filterObject = {};
    $scope.fileReceivedDateOptions = ['Specific Date', 'Date Range'];

    // Functions
    $scope.search = search;
    $scope.reset = reset;
    $scope.downloadPageId = downloadPageId.ArpOutputFileList;
    $scope.disableDownload = true;

    init();

    function init() {
        reset();
        loadAccounts();
    }

    // Public Functions
    //
    function search(filteredObject) {
        arpService.searchArpOutputFiles(filteredObject).then(response => {
            $scope.arpOutputFiles = response;
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
                $scope.disableDownload = true;
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
                $scope.disableDownload = false;
            }
        });
    }

    function reset() {
        angular.forEach($scope.accounts, account => {
            account.isChecked = true;
        });
        $scope.filterObject.fileName = '';
        $scope.filterObject.fileReceivedDateType = '';
        $scope.filterObject.fileReceivedDateStart = '';
        $scope.filterObject.fileReceivedDateEnd = '';
        $scope.filterObject.fileReceivedDate = '';
    }

    // Private Functions
    //
    function loadAccounts() {
        // remove string and put in constant
        arpService.getArpAccounts('DownloadArpFile').then(response => {
            $scope.filterObject.accounts = $scope.accounts = response;
            angular.forEach($scope.accounts, account => {
                account.isChecked = true;
            });
            search($scope.filterObject);
        });
    }
}
