RateQuoteAcceptModalController.$inject = [
    '$scope',
    'wire',
    'cutOff',
    'wireService',
    '$modalInstance',
    '$interval',
    '$timeout',
    'companyAccountsService',
    'securityService',
    'timerService',
    'entitlementsService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function RateQuoteAcceptModalController(
    $scope,
    wire,
    cutOff,
    wireService,
    $modalInstance,
    $interval,
    $timeout,
    companyAccountsService,
    securityService,
    timerService,
    entitlementsService
) {
    $scope.hideQuoteRestrictionInfo = entitlementsService.hasEntitlement(
        'Feature.FxWire.HideQuoteRestrictionInformation'
    );
    $scope.wire = wire;
    $scope.cutOff = cutOff;
    $scope.accept = accept;
    $scope.confirm = confirm;
    $scope.confirmProcessing = false;
    $scope.cancel = cancel;
    $scope.views = {
        main: 1,
        expired: 2,
        confirm: 3,
        confirmationWithoutAcceptance: 4,
    };
    $scope.view = $scope.views.main;
    $scope.timerStarted = false;
    $scope.minutes = 0;
    $scope.seconds = 0;
    $scope.showTimer = showTimer;

    init();

    function loadCutoffTimesDli() {
        companyAccountsService.getCutoffTimesByProductType('WireTransferDli').then(response => {
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableDliToday = true;
            }, timeout);
        });
    }

    $scope.onInterval = function () {
        $scope.counter -= 1;
    };

    function init() {
        loadCutoffTimesDli();
        if ($scope.hideQuoteRestrictionInfo) {
            $scope.view = $scope.views.confirmationWithoutAcceptance;
        }
    }

    function cancel() {
        $modalInstance.dismiss();
    }

    function accept() {
        $scope.view = $scope.views.confirm;
    }

    function confirm() {
        if ($scope.confirmProcessing) {
            return;
        }
        $scope.confirmProcessing = true;
        $scope.wire.frequency = {
            type: 'One Time',
            valueDate: $scope.wire.rateQuote.valueDate,
        };
        $scope.wire.destinationCurrency = $scope.wire.destinationCurrencyCode;

        securityService
            .verifyUser('CreateFxWire_ByName', $scope.wire, () =>
                wireService.acceptRateQuote($scope.wire)
            )
            .then(response => {
                timerService.stop('rateQuote');
                $scope.wire = response;
                $scope.wire.rateQuote.accepted = true;
                $modalInstance.close($scope.wire);
                $scope.confirmProcessing = false;
            });
    }

    function showTimer() {
        return $scope.wire.rateQuote.amexQuoteId && !$scope.hideQuoteRestrictionInfo;
    }
}
