import { arrayHasElements } from '@treasury/utils';

AchUserEntitlementsController.$inject = [
    '$scope',
    '$window',
    'usersService',
    '$modal',
    'toaster',
    'securityService',
    'entitlementsService',
];

export default function AchUserEntitlementsController(
    $scope,
    $window,
    usersService,
    $modal,
    toaster,
    securityService,
    entitlementsService
) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.entitlements = null;
    $scope.entitlementsPristine = null;
    $scope.nonRecipientEntitlements = null;
    $scope.recipientEntitlements = null;

    $scope.setForm = setForm;
    $scope.getRestrictedBatchIndex = getRestrictedBatchIndex;
    $scope.reset = reset;
    $scope.save = save;
    $scope.toggleNonRecipientRow = toggleNonRecipientRow;
    $scope.toggleRecipientRow = toggleRecipientRow;
    $scope.getAchCompanies = getAchCompanies;
    $scope.manageAchCompanies = manageAchCompanies;
    $scope.toggleInternational = toggleInternational;
    $scope.toggleInternationalTemplates = toggleInternationalTemplates;
    $scope.toggleTemplates = toggleTemplates;
    $scope.toggleRecipientRow = toggleRecipientRow;
    $scope.toggleRecipients = toggleRecipients;
    $scope.toggleReview = toggleReview;
    $scope.toggleAchPayments = toggleAchPayments;
    $scope.showAchTemplateEntitlements = showAchPaymentEntitlements;
    $scope.showInternationalAchTemplateEntitlements = showInternationalAchTemplateEntitlements;
    $scope.showInternationalAchEntitlements = showInternationalAchEntitlements;
    $scope.showAchPaymentEntitlements = showAchPaymentEntitlements;
    $scope.showAchRecipientEntitlements = showAchRecipientEntitlements;
    $scope.showAchTemplateEntitlements = showAchTemplateEntitlements;
    $scope.showAchReviewEntitlements = showAchReviewEntitlements;

    function init() {
        loadEntitlements();
    }

    function setForm(form) {
        $scope.form = form;
    }

    function isAchInternationalFeatureFlagEnabled() {
        return entitlementsService.hasEntitlement('Feature.ACH.AllowInternationalPayments');
    }
    function isAchInternationalTemplatesFeatureFlagEnabled() {
        return entitlementsService.hasEntitlement('Feature.Ach.InternationalPaymentTemplates');
    }
    function isAchNoticeOfChangeFeatureFlagEnabled() {
        return (
            entitlementsService.hasEntitlement('Feature.Ach.NotificationOfChange') ||
            entitlementsService.hasEntitlement('Feature.ACH.NotificationOfChange.Nacha')
        );
    }

    function isAchReturnReviewFeatureFlagEnabled() {
        return (
            entitlementsService.hasEntitlement('Feature.Ach.ReturnReview') ||
            entitlementsService.hasEntitlement('Feature.ACH.Return.Nacha')
        );
    }

    function getRestrictedBatchIndex() {
        if (
            $scope.entitlements &&
            $scope.entitlements.userProductClaims &&
            $scope.entitlements.userProductClaims.length > 0
        ) {
            for (let index = 0; index < $scope.entitlements.userProductClaims.length; index++) {
                if ($scope.entitlements.userProductClaims[index].claimId === 113) {
                    return index;
                }
            }
        }
    }

    function save() {
        const updates = {
            achCompanies: [],
            addedEntitlements: [],
            removedEntitlements: [],
            securityMessage: null,
        };

        // user product entitlements
        if (
            $scope.entitlements.userProductClaims !== null &&
            $scope.entitlementsPristine.userProductClaims !== null
        ) {
            $window.angular.forEach($scope.entitlements.userProductClaims, working => {
                $window.angular.forEach($scope.entitlementsPristine.userProductClaims, pristine => {
                    if (pristine.claimId === working.claimId) {
                        if (working.hasPendingUserClaim && !pristine.hasPendingUserClaim) {
                            updates.addedEntitlements.push({
                                claim: working,
                            });
                        } else if (!working.hasPendingUserClaim && pristine.hasPendingUserClaim) {
                            updates.removedEntitlements.push({
                                claim: working,
                            });
                        }
                    }
                });
            });
        }

        updates.achCompanies = $scope.entitlements.userAchCompanies;

        securityService
            .verifyUser('Edit User', updates, () =>
                usersService.updateAchProductEntitlements($scope.id, updates)
            )
            .then(response => {
                if (response.success) {
                    $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
                    $scope.$parent.notifySave();
                    $scope.form.$setPristine();
                    toaster.save('ACH Entitlements');
                }
            });
    }

    function toggleNonRecipientRow() {
        toggleEntitlements($scope.nonRecipientEntitlements);
    }

    function toggleRecipientRow() {
        toggleEntitlements($scope.recipientEntitlements);
    }

    function toggleInternational() {
        toggleEntitlements($scope.achInternational);
    }

    function toggleInternationalTemplates() {
        toggleEntitlements($scope.achInternationalTemplate);
    }

    function toggleTemplates() {
        toggleEntitlements($scope.achTemplates);
    }

    function toggleRecipients() {
        toggleEntitlements($scope.achRecipients);
    }

    function toggleReview() {
        toggleEntitlements($scope.achNoticeChange);
    }

    function toggleAchPayments() {
        toggleEntitlements($scope.achPayments);
    }

    const templateEntitlementClaimIds = [95, 96, 97, 98, 99];
    const achRecipientEntitlementClaimIds = [138, 139, 140, 141];
    const achPaymentEntitlementClaimIds = [100, 101, 102, 103, 118, 133];
    const achReviewChangeEntitlementClaimIds = [194, 195];
    const achInternationalEntitlementClaimIds = [185, 186, 187, 198, 188, 189, 190];
    const achInternationalTemplateEntitlementClaimIds = [206, 205, 204, 203, 202]; // to do, add integer for approval entitlement

    const findEntitlementInUserClaim = (e, entitlementId) => e.claimId === entitlementId;
    const findClaimInArrayOfUserEntitlements = (array, entitlementClaim) =>
        array.find(entitlement => findEntitlementInUserClaim(entitlementClaim, entitlement));

    const achTemplateEntitlementArray = entitlements =>
        entitlements.filter(e =>
            findClaimInArrayOfUserEntitlements(templateEntitlementClaimIds, e)
        );

    const achRecipientEntitlementArray = entitlements =>
        entitlements.filter(e =>
            findClaimInArrayOfUserEntitlements(achRecipientEntitlementClaimIds, e)
        );

    const achReviewChangeEntitlementArray = entitlements =>
        entitlements.filter(e =>
            findClaimInArrayOfUserEntitlements(achReviewChangeEntitlementClaimIds, e)
        );

    const achPaymentEntitlementArray = entitlements =>
        entitlements.filter(e =>
            findClaimInArrayOfUserEntitlements(achPaymentEntitlementClaimIds, e)
        );

    const achInternationalEntitlementArray = entitlements =>
        entitlements.filter(e =>
            findClaimInArrayOfUserEntitlements(achInternationalEntitlementClaimIds, e)
        );

    const achInternationalTemplatesEntitlementArray = entitlements =>
        entitlements.filter(e =>
            findClaimInArrayOfUserEntitlements(achInternationalTemplateEntitlementClaimIds, e)
        );

    function getAchCompanies() {
        if ($scope.entitlements) {
            return $scope.entitlements.userAchCompanies.map(elem => elem.companyName).join(', ');
        }
    }

    function manageAchCompanies() {
        const modalInstance = $modal.open({
            template: require('../views/manageAchCompaniesModal.html'),
            size: 'lg',
            controller: 'ManageAchCompanyController',
            backdrop: 'static',
            resolve: {
                achCompanies() {
                    return $scope.entitlements.achCompanies;
                },
                userAchCompanies() {
                    return $scope.entitlements.userAchCompanies;
                },
            },
        });
        modalInstance.result.then(response => {
            $scope.form.$setDirty(true);
            $scope.entitlements.userAchCompanies = response;
        });
    }

    function reset() {
        $scope.entitlements = angular.copy($scope.entitlementsPristine);
        $scope.nonRecipientEntitlements = getNonRecipientEntitlements();
        $scope.recipientEntitlements = getRecipientEntitlements();
        $scope.form.$setPristine();
    }

    // private
    function getNonRecipientEntitlements() {
        if ($scope.entitlements) {
            return $scope.entitlements.userProductClaims.filter(
                claim => claim.claimId !== 113 && claim.claimName.indexOf('Recipient') === -1
            );
        }
    }

    // private
    function getRecipientEntitlements() {
        if ($scope.entitlements) {
            return $scope.entitlements.userProductClaims.filter(
                claim => claim.claimName.indexOf('Recipient') !== -1
            );
        }
    }

    // private
    function toggleEntitlements(userProductClaims) {
        let currentValue = true;

        userProductClaims.forEach(claim => {
            if (!claim.hasPendingUserClaim) {
                currentValue = false;
            }
        });

        userProductClaims.forEach(claim => {
            claim.hasPendingUserClaim = !currentValue;
        });

        $scope.form.$setDirty();
    }

    function showAchRecipientEntitlements() {
        if (!Array.isArray($scope.achRecipients)) return false;
        return arrayHasElements($scope.achRecipients);
    }

    function showAchPaymentEntitlements() {
        if (!Array.isArray($scope.achPayments)) return false;
        return arrayHasElements($scope.achPayments);
    }
    function showInternationalAchTemplateEntitlements() {
        if (!Array.isArray($scope.achInternationalTemplate)) return false;
        if (isAchInternationalTemplatesFeatureFlagEnabled()) {
            return arrayHasElements($scope.achInternationalTemplate);
        }
        return false;
    }
    function showInternationalAchEntitlements() {
        /* If feature flag is off, never show */
        if (!Array.isArray($scope.achInternational)) return false;
        if (isAchInternationalFeatureFlagEnabled()) {
            return arrayHasElements($scope.achInternational);
        }
        return false;
    }

    function showAchTemplateEntitlements() {
        if (!Array.isArray($scope.achTemplates)) return false;
        return arrayHasElements($scope.achTemplates);
    }

    function showAchReviewEntitlements() {
        /* If feature flag is off, never show */
        if (!Array.isArray($scope.achNoticeChange)) return false;
        if (isAchNoticeOfChangeFeatureFlagEnabled() || isAchReturnReviewFeatureFlagEnabled()) {
            return arrayHasElements($scope.achNoticeChange);
        }
        return false;
    }

    function setUserClaims(entitlements) {
        $scope.entitlements = entitlements;
        const userClaims = entitlements.userProductClaims;
        $scope.achTemplates = achTemplateEntitlementArray(userClaims);
        $scope.achPayments = achPaymentEntitlementArray(userClaims);
        $scope.achRecipients = achRecipientEntitlementArray(userClaims);
        $scope.achNoticeChange = achReviewChangeEntitlementArray(userClaims);
        $scope.achInternational = achInternationalEntitlementArray(userClaims);
        $scope.achInternationalTemplate = achInternationalTemplatesEntitlementArray(userClaims);
        $scope.entitlementsPristine = $window.angular.copy($scope.entitlements);
        $scope.nonRecipientEntitlements = getNonRecipientEntitlements();
        $scope.recipientEntitlements = getRecipientEntitlements();
    }

    // Private
    function loadEntitlements() {
        usersService.getAchEntitlements($scope.id).then(response => {
            $scope.entitlementsPristine = $window.angular.copy($scope.response);
            setUserClaims(response);
        });
    }

    init();
}
