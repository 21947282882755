StopRequestWidgetController.$inject = ['$scope', 'dashboardService', '$state', 'spinnerService'];

// eslint-disable-next-line @treasury/filename-match-export
export default function StopRequestWidgetController(
    $scope,
    dashboardService,
    $state,
    spinnerService
) {
    $scope.loadCompletionCallback = null;

    $scope.getCheckNumbers = getCheckNumbers;
    $scope.review = review;
    $scope.confirm = confirm;
    $scope.reset = reset;
    $scope.reload = reload;
    $scope.cancel = cancel;
    $scope.setForm = setForm;
    $scope.goToStpList = goToStpList;

    init();

    function init() {
        spinnerService.configureGroup(
            'stopRequestWidget',
            ['/dashboard/stpBatch', '/dashboard/stopRequests'],
            ['stopRequestWidget.html', 'stopRequestFooter.html']
        );

        $scope.widget.footerTemplate = 'app/dashboard/widgets/views/stopRequestFooter.html';

        $scope.widget.footerController = {
            getForm,
            isReviewing,
            isOnConfirmation,
            review,
            reset,
            reload,
            confirm,
            cancel,
        };

        updateSpinner(spinnerService.startGroupRequest('stopRequestWidget'));
        dashboardService.getStopRequests().then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('stopRequestWidget'));
                $scope.stopRequests = response;
                setApprovalAttributes();
                $scope.form.$setPristine();
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('stopRequestWidget'));
            }
        );
    }

    function setApprovalAttributes() {
        $scope.stopRequests.map(stp => {
            stp.approvalAttributes = {
                approvalEntity: 'stopPayment',
                approvalCount: stp.completedApprovalCount,
                amount: stp.amount,
                createdBy: stp.createdBy,
                updatedBy: stp.updatedBy,
                productId: stp.id,
            };
            return stp;
        });
    }

    function getCheckNumbers(stopPayment) {
        if (!!stopPayment.checkNumbers && stopPayment.checkNumbers.length > 0) {
            if (stopPayment.checkNumbers.length === 1) {
                return stopPayment.checkNumbers[0];
            }
            return `${stopPayment.checkNumbers[0]}-${stopPayment.checkNumbers[1]}`;
        }
    }

    function goToStpList(stp) {
        $state.go('payables.stp.list', { accountNumber: stp.account.number });
    }
    /// footer
    function reload() {
        $scope.loadCompletionCallback = function () {
            $scope.$parent.flipWidget($scope.widget, false);
            $scope.isConfirmation = false;
            $scope.isReviewing = false;
        };
        init();
    }

    function confirm() {
        const batch = [];
        angular.forEach($scope.list, item => {
            batch.push({ key: item.id, value: item.actionTaken });
        });
        $scope.isConfirmation = true;
        updateSpinner(spinnerService.startGroupRequest('stopRequestWidget'));
        dashboardService.postStpBatch(batch).then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('stopRequestWidget'));
                if (response.successMessage) {
                    $scope.successMessage = response.successMessage;
                }
                if (response.errorMessage) {
                    $scope.errorMessage = response.errorMessage;
                }
                angular.forEach($scope.list, item => {
                    angular.forEach(response.transactions, responseItem => {
                        if (responseItem.id === item.id) {
                            item.successMessage = responseItem.successMessage;
                            item.errorMessage = responseItem.errorMessage;
                            item.numberOfApprovalsNeeded = response.numberOfApprovalsNeeded;
                            item.completedApprovals = response.completedApprovals;
                            item.availableApprovers = response.availableApprovers;
                        }
                    });
                });
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('stopRequestWidget'));
            }
        );
    }

    function setForm(form) {
        $scope.form = form;
    }

    function reset() {
        angular.forEach($scope.stopRequests, item => {
            item.isApproved = false;
            item.isRejected = false;
        });

        $scope.form.$setPristine();
    }

    function cancel() {
        $scope.$parent.flipWidget($scope.widget, false);
        $scope.isReviewing = false;
    }

    function review() {
        $scope.list = [];
        angular.forEach($scope.stopRequests, item => {
            if (item.isApproved || item.isRejected) {
                if (item.isApproved) {
                    item.actionTaken = 'Approved';
                    $scope.list.push(item);
                } else if (item.isRejected) {
                    item.actionTaken = 'Rejected';
                    $scope.list.push(item);
                }
            }
        });

        $scope.$parent.flipWidget($scope.widget, true);
        $scope.isReviewing = true;
    }

    function getForm() {
        return $scope.form;
    }

    function isReviewing() {
        return $scope.isReviewing;
    }

    function isOnConfirmation() {
        return $scope.isConfirmation;
    }

    function updateSpinner(numPendingRequests) {
        const isLoading = numPendingRequests > 0;
        if ($scope.widget.isLoading && !isLoading) {
            if ($scope.loadCompletionCallback) {
                $scope.loadCompletionCallback();
                $scope.loadCompletionCallback = null;
            }
        }
        $scope.widget.isLoading = isLoading;
    }
}
