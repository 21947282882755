/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
import { LitElement, html, css, nothing } from 'lit';
import '../omega-field.js';
import '../omega-input.js';
import '../omega-datepicker.js';
import '../omega-range.js';
import '../omega-select.js';
import '../omega-button.js';
import { fontAwesome } from '../../css/icons/font-awesome.js';

class TableFilters extends LitElement {
    static get properties() {
        return {
            filters: Array,
            filtersVisible: Boolean,
            filterRecord: Object,
            filteringDisabled: Boolean,
        };
    }

    constructor() {
        super();
        this.filtersVisible = true;
        this.filteringDisabled = false;
        this.panel = undefined;
        this.filterButton = undefined;
        this.filters = [];
    }

    firstUpdated() {
        window.addEventListener('resize', () => this.resizeFilters());

        this.panel = this.shadowRoot.querySelector('.table-filters');
        this.filterButton = this.shadowRoot.querySelector('.table-filters-toggle-button');
    }

    toggleFilters() {
        if (!this.panel) {
            return;
        }

        this.filtersVisible = !this.filtersVisible;
        this.panel.classList.toggle('active');
        this.panel.classList.toggle('table-filters-hidden');
        this.panel.style.maxHeight = this.panel.style.maxHeight
            ? null
            : `${this.panel.scrollHeight}px`;
        this.setPanelHeight();
        this.dispatchEvent(new CustomEvent('toggle'));
    }

    setPanelHeight() {
        this.panel.style.maxHeight = `${this.panel.scrollHeight}px`;
    }

    resizeFilters() {
        if (!this.panel || !this.panel.children) return;
        this.panel.style.maxHeight = this.panel.style.maxHeight
            ? `${this.panel.scrollHeight}px`
            : null;

        this.dispatchEvent(new CustomEvent('resize'));
    }

    runReport() {
        this.dispatchEvent(
            new CustomEvent('runReport', {
                bubbles: true,
                composed: true,
                detail: { filterRecord: this.filterRecord },
            })
        );
    }

    resetFilters() {
        this.filterRecord.reset();
        this.dispatchEvent(
            new CustomEvent('resetFilters', {
                bubbles: true,
                composed: true,
            })
        );
    }

    renderFilterFields() {
        if (!this.filterRecord) return nothing;

        return this.filters.map(
            filter =>
                html`<omega-field
                    field=${filter.field}
                    class="table-filter"
                    label=${filter.label}
                    .inline=${!!filter.inline}
                    .required=${filter.required}
                    .record=${this.filterRecord}
                ></omega-field>`
        );
    }

    render() {
        if (!this.filters || this.filters.length === 0) return nothing;
        return html`<div class="table-filters active">
            <div class="table-filters-header">
                <span
                    role="button"
                    class="table-filters-toggle-button"
                    @click=${this.toggleFilters}
                >
                    &nbsp;<i class="fa fa-sliders fa-lg"></i>&nbsp;Filter
                </span>
            </div>
            <div class="table-filters-content">
                <div class="table-filters-wrapper">${this.renderFilterFields()}</div>
                <div class="table-filters-button-wrapper">
                    <omega-button
                        type="primary"
                        ?disabled=${this.filteringDisabled}
                        @click=${() => this.runReport()}
                        block
                        >Run Report</omega-button
                    >
                    <omega-button @click=${() => this.resetFilters()} block>Reset</omega-button>
                </div>
            </div>
        </div>`;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                    position: sticky;
                    top: 0;
                    --omega-tabs-scroll: none;
                }
                .table-filters {
                    z-index: 50;
                    margin: 0;
                    padding: 0;
                    position: sticky;
                    top: 0;
                    border-top: 1px #ccc;
                    background-color: #f1f8fd;
                    transition: all 0.25s cubic-bezier(0, 0.76, 0.35, 1.04);
                }
                .table-filters-header {
                    background-color: #f9f9f9;
                    border: 1px solid #dfe1e8;
                    height: 37px;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                }
                .table-filters-header span {
                    color: var(--omega-primary);
                    cursor: pointer;
                    line-height: 37px;
                    margin-left: 5px;
                }
                .table-filters-header i {
                    line-height: 0;
                }
                .table-filters-hidden {
                    overflow: hidden;
                    height: 39px;
                    padding: 0;
                    transition: all 0.25s cubic-bezier(0.47, 0.41, 0, 1.78);
                }

                .table-filters-content {
                    display: flex;
                    border-left: 1px solid #dfe1e8;
                    border-right: 1px solid #dfe1e8;
                    border-bottom: 1px solid #dfe1e8;
                    padding: 10px;
                }

                .table-filters-wrapper {
                    display: flex;
                    align-items: flex-start;
                    flex: 500px 20 1;
                    flex-wrap: wrap;
                }

                .table-filters-button-wrapper {
                    display: flex;
                    flex: 110px 1 1;
                    flex-flow: column wrap;
                    align-self: center;
                    padding-top: 18px;
                    margin: 10px;
                    direction: rtl;
                }

                .table-filters omega-field {
                    margin: 10px;
                    padding: 0;
                    width: 275px;
                }

                .table-filters omega-field {
                    --omega-datepicker-input-height: 32px;
                }

                @media print {
                    .table-filters {
                        display: none;
                        height: 0;
                    }
                }

                @media only screen and (max-width: 900px) {
                    .table-filters-button-wrapper {
                        padding-top: 0;
                    }
                }
            `,
        ];
    }
}

customElements.define('table-filters', TableFilters);
export default TableFilters;
