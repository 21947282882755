import { html, LitElement } from 'lit';

export class EverySixMonths extends LitElement {
    static get properties() {
        return {
            frequency: Object,
            disabled: { type: Boolean, attribute: 'disabled' },
            readOnly: { type: Boolean, attribute: 'readonly' },
            isValidStartDate: Function,
            isValidEndDate: Function,
            isStartDateInvalid: Function,
            isEndDateInvalid: Function,
        };
    }

    static get is() {
        return 'omega-frequency-every-six-months';
    }

    setFrequency(e) {
        this.frequency = e.detail;
        this.dispatchEvent(new CustomEvent('change', { detail: e.detail, bubbles: false }));
    }

    render() {
        return html`
            <omega-frequency-start-and-end
                .frequency=${this.frequency}
                @change=${this.setFrequency}
                ?disabled=${this.disabled}
                ?readonly=${this.readOnly}
                .isStartDateInvalid=${this.isStartDateInvalid}
                .isEndDateInvalid=${this.isEndDateInvalid}
            ></omega-frequency-start-and-end>
            <em>Every Six Months is defined as processing on the same day every 6 months.</em>
        `;
    }
}

window.customElements.define(EverySixMonths.is, EverySixMonths);
export default EverySixMonths;
