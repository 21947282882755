import { FeatureFlagService } from '@treasury/domain/services/feature-flags';

AchUserLimitsController.$inject = [
    '$scope',
    'usersService',
    '$filter',
    'toaster',
    'securityService',
];

export default function AchUserLimitsController(
    $scope,
    usersService,
    $filter,
    toaster,
    securityService
) {
    $scope.isSuperUser = $scope.$parent.userHeader.isSuperUser;
    $scope.searchText = '';
    $scope.filteredLimits = {};
    $scope.userLimits = {};
    $scope.userLimitsPristine = {};
    $scope.fieldLimits = { maximumDigits: '9999999999999.99' };

    $scope.reset = reset;
    $scope.search = search;
    $scope.save = save;
    $scope.setForm = setForm;

    $scope.achCompanyDailyLimitChanged = achCompanyDailyLimitChanged;
    $scope.dailyInitiationLimitChanged = dailyInitiationLimitChanged;

    function achCompanyDailyLimitChanged(frm, transLimitControlId) {
        const transLimitControl = frm[transLimitControlId];
        if (transLimitControl) {
            transLimitControl.$setDirty();
        }

        if (frm.initiationLimitInput) {
            frm.initiationLimitInput.$setDirty();
        }
    }

    function dailyInitiationLimitChanged(frm, limits) {
        if (!(limits && limits.length)) return;

        for (let i = 0; i < limits.length; i++) {
            const control = frm[`pendingDailyLimit-${i}`];
            if (control) {
                control.$setDirty();
            }
        }
    }

    async function init() {
        loadUserLimits();

        $scope.hasAchUserLimitsInfo = await FeatureFlagService.isEnabled(
            'Feature.ACH.UserLimitsInfo.TM23838'
        );
    }

    $scope.sumUserPendingCompanyDailyLimit = function () {
        let result = 0;
        if (
            !!$scope.userLimits &&
            !!$scope.userLimits.userAchCompanies &&
            $scope.userLimits.userAchCompanies.length > 0
        ) {
            result = $scope.userLimits.userAchCompanies
                .map(userCompany => userCompany.pendingUserAchCompanyLimit.dailyLimit)
                .reduce((curr, prev) => Number(curr) + Number(prev));
        }
        return result;
    };

    function save() {
        securityService
            .verifyUser('Edit User', $scope.userLimits, () =>
                usersService.updateAchLimits($scope.id, $scope.userLimits)
            )
            .then(response => {
                if (response.success) {
                    $scope.userLimitsPristine = angular.copy($scope.userLimits);
                    $scope.$parent.notifySave();
                    $scope.form.$setPristine();
                    toaster.save('ACH User Limits');
                }
            });
    }

    function reset() {
        $scope.userLimits = angular.copy($scope.userLimitsPristine);
        search($scope.searchText);
        $scope.form.$setPristine();
    }

    $scope.getMinimum = function (amounts) {
        if (!Array.isArray(amounts)) {
            return amounts;
        }
        return Math.min.apply(null, amounts);
    };

    $scope.translateACHCoreCompanyLimit = function (limit) {
        if (limit == 0) {
            return $scope.fieldLimits.maximumDigits;
        }
        return limit;
    };

    // ACH Company Core Limits can be set to zero or blank in SL
    // to indicate an unlimited core limit
    $scope.isUnlimited = function (limit) {
        if (limit) {
            return false;
        }
        return true;
    };

    function setForm(form) {
        $scope.form = form;
    }

    // Events
    function search(text) {
        $scope.searchText = text || '';
        if ($scope.userLimits !== null) {
            $scope.filteredLimits = $filter('filter')(
                $scope.userLimits.userAchCompanies,
                $scope.searchText
            );
        }
    }

    // Private
    function loadUserLimits() {
        usersService.getAchLimits($scope.id).then(response => {
            $scope.userLimits = response;
            $scope.userLimitsPristine = angular.copy($scope.userLimits);

            if (!$scope.userLimits.pendingUserProductLimit.approvalLimit) {
                $scope.userLimits.pendingUserProductLimit.approvalLimit =
                    $scope.userLimits.userProductLimit.approvalLimit;
            }

            if (!$scope.userLimits.pendingUserProductLimit.initiationLimit) {
                $scope.userLimits.pendingUserProductLimit.initiationLimit =
                    $scope.userLimits.userProductLimit.initiationLimit;
            }

            search('');
        });
    }

    init();
}
