import { CompanyAccountModelDto } from '@treasury/api/channel';
import { OmegaListItem } from '@treasury/omega/view-models';

export type DebitAccountListItem = OmegaListItem<CompanyAccountModelDto>;
export function createDebitAccountListItem(dto: CompanyAccountModelDto): DebitAccountListItem {
    return {
        value: dto,
        text: dto.accountDisplayLabel || '',
    };
}
