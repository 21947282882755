// eslint-disable-next-line import/extensions
import { filtersModule } from './index';

filtersModule.filter(
    'frequency',
    () =>
        function (input) {
            input = input || '';
            switch (input) {
                case 'OneTime':
                    return 'One Time';
                case 'EveryTwoWeeks':
                    return 'Every Two Weeks';
                case 'TwiceAMonth':
                case 'TwiceaMonth':
                    return 'Twice A Month';
                case 'EverySixMonths':
                    return 'Every Six Months';
            }
            return input;
        }
);
