export const ValidSubmit = [
    '$parse',
    function ($parse) {
        return {
            compile() {
                return {
                    post(scope, element, iAttrs) {
                        const form = element.controller('form');
                        form.$submitted = false;
                        const fn = $parse(iAttrs.validSubmit);
                        element.on('submit', event => {
                            scope.$apply(() => {
                                element.addClass('ng-submitted');
                                form.$submitted = true;
                                fn(scope, { $event: event });
                            });
                        });
                    },
                };
            },
        };
    },
];

const isValidDate = function (dateStr) {
    if (dateStr === undefined || dateStr === null) return false;
    const dateTime = Date.parse(dateStr);

    if (isNaN(dateTime)) {
        return false;
    }
    return true;
};

const getDateDifference = function (fromDate, toDate) {
    return Date.parse(toDate) - Date.parse(fromDate);
};

const isValidDateRange = function (fromDate, toDate) {
    if (fromDate === '' || toDate === '') return true;
    if (isValidDate(fromDate) === false) {
        return false;
    }
    if (isValidDate(toDate) === true) {
        const days = getDateDifference(fromDate, toDate);
        if (days < 0) {
            return false;
        }
    }
    return true;
};

// Date comparison - Checks if the date is lower than the passed model

export const DateLowerThan = [
    '$filter',
    function ($filter) {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateDateRange = function (inputValue) {
                    if (inputValue && inputValue !== '') {
                        const fromDate = $filter('date')(inputValue, 'MM/dd/yyyy');
                        const toDate = $filter('date')(attrs.dateLowerThan, 'MM/dd/yyyy');
                        const isValid = isValidDateRange(fromDate, toDate);
                        ctrl.$setValidity('dateLowerThan', isValid);
                        return inputValue;
                    }
                    ctrl.$setValidity('dateLowerThan', true);
                    return inputValue;
                };

                ctrl.$parsers.unshift(validateDateRange);
                ctrl.$formatters.push(validateDateRange);
                attrs.$observe('dateLowerThan', () => {
                    validateDateRange(ctrl.$viewValue);
                });
            },
        };
    },
];

export const MaxDaysInFuture = [
    function ($filter) {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateMaxDays = function (inputValue) {
                    if (!isNaN(Date.parse(inputValue))) {
                        const dt = new Date(inputValue);
                        const isValid = moment(dt) < moment().add({ days: attrs.maxDaysInFuture });
                        ctrl.$setValidity('maxDaysExceeded', isValid);
                        return inputValue;
                    }
                    ctrl.$setValidity('maxDaysExceeded', true);
                    return inputValue;
                };

                ctrl.$parsers.unshift(validateMaxDays);
                ctrl.$formatters.push(validateMaxDays);
                attrs.$observe('maxDaysInFuture', () => {
                    validateMaxDays(ctrl.$viewValue);
                });
            },
        };
    },
];

// Date comparison - Checks if the date is greater than the passed model
export const DateGreaterThan = [
    '$filter',
    function ($filter) {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateDateRange = function (inputValue) {
                    if (inputValue && inputValue !== '') {
                        const fromDate = $filter('date')(attrs.dateGreaterThan, 'MM/dd/yyyy');
                        const toDate = $filter('date')(inputValue, 'MM/dd/yyyy');
                        const isValid = isValidDateRange(fromDate, toDate);
                        ctrl.$setValidity('dateGreaterThan', isValid);
                        return inputValue;
                    }
                    ctrl.$setValidity('dateGreaterThan', true);
                    return inputValue;
                };

                ctrl.$parsers.unshift(validateDateRange);
                ctrl.$formatters.push(validateDateRange);
                attrs.$observe('dateGreaterThan', () => {
                    validateDateRange(ctrl.$viewValue);
                });
            },
        };
    },
];

// Date validation - Checks if the date is valid.
export const IsValidDate = [
    '$filter',
    function () {
        return {
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                const validateDate = function (inputValue) {
                    let isValid = true;
                    if (inputValue !== null && inputValue !== undefined && inputValue !== '') {
                        try {
                            let split;
                            if (inputValue.indexOf('_') > -1) {
                                isValid = false;
                            } else if (inputValue.indexOf('-') > -1) {
                                split = inputValue.split('-');
                                if (
                                    split.length < 3 ||
                                    (split.length == 3 &&
                                        (split[2].length === 0 ||
                                            split[0].length === 0 ||
                                            split[1].length === 0))
                                ) {
                                    isValid = false;
                                } else if (split[0].length == 4)
                                    isValid = moment(inputValue, 'YYYY-MM-DD').isValid();
                                else isValid = moment(inputValue, 'MM-DD-YYYY').isValid();
                            } else if (inputValue.indexOf('/') > -1) {
                                split = inputValue.split('/');
                                if (
                                    split.length < 3 ||
                                    (split.length == 3 &&
                                        (split[2].length === 0 ||
                                            split[0].length === 0 ||
                                            split[1].length === 0))
                                ) {
                                    isValid = false;
                                } else if (split[0].length == 4)
                                    isValid = moment(inputValue, 'YYYY-MM-DD').isValid();
                                else isValid = moment(inputValue, 'MM-DD-YYYY').isValid();
                            } else {
                                isValid = false;
                            }
                        } catch (err) {
                            isValid = false;
                        }
                    }
                    ctrl.$setValidity('isValidDate', isValid);
                    return inputValue;
                };
                ctrl.$parsers.unshift(validateDate);
                ctrl.$formatters.push(validateDate);
            },
        };
    },
];

export const FormatDate = () => ({
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
        element.kendoMaskedTextBox({
            mask: '00/00/0000',
        });

        element.removeClass('k-textbox');
        element.attr('placeholder', 'mm/dd/yyyy');

        element.on('blur', () => {
            const dt = ngModel.$modelValue;
            if (dt && dt.length > 0) {
                const date = moment(dt, 'MM/DD/YYYY');

                if (date.isValid()) {
                    ngModel.$setValidity('isValidDate', true);
                } else {
                    ngModel.$setValidity('isValidDate', false);
                }
            } else {
                ngModel.$setValidity('isValidDate', true);
            }
        });
    },
});

export const SyncFocus = () => ({
    restrict: 'A',
    scope: {
        focusValue: '=syncFocus',
    },
    link($scope, $element) {
        $scope.$watch('focusValue', (currentValue, previousValue) => {
            if (currentValue === true && !previousValue) {
                $element[0].focus();
            } else if (currentValue === false && previousValue) {
                $element[0].blur();
            }
        });
    },
});

export const NgEnter = () => ({
    restrict: 'A',
    scope: {
        action: '&ngEnter',
    },
    link(scope, element) {
        element.on('keydown keypress', event => {
            if (event.which === 13) {
                scope.$apply(scope.action);
                event.preventDefault();
            }
        });
    },
});

export const ignoreDirty = () => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ctrl) {
        ctrl.$setPristine = function () {};
        ctrl.$pristine = false;
    },
});

export const LessThanOrEqual = () => ({
    require: 'ngModel',
    link($scope, $element, $attrs, ctrl) {
        const validate = function (modelValue) {
            const originalModelValue = modelValue;
            if (typeof modelValue === 'string') {
                modelValue = Number(modelValue.replace(/[^0-9-\.]+/g, ''));
            }
            let comparisonModel = $attrs.lessThanOrEqual;
            if (typeof comparisonModel === 'string') {
                comparisonModel = Number(comparisonModel.replace(/[^0-9-\.]+/g, ''));
            }
            // It's valid if model is <= the model we're comparing against
            ctrl.$setValidity('lessThanOrEqual', modelValue <= comparisonModel);
            return originalModelValue;
        };
        ctrl.$parsers.unshift(validate);
        ctrl.$formatters.push(validate);

        $attrs.$observe('lessThanOrEqual', () =>
            // Whenever the comparison model changes we'll re-validate
            validate(ctrl.$modelValue)
        );
    },
});

export const DisableDate = () => ({
    scope: {
        holidayDates: '=',
    },
    require: 'ngModel',
    link(scope, element, attr, mCtrl) {
        function validateDates(value) {
            const match = scope.holidayDates.reduce(
                (acc, cur) => moment(cur, 'YYYY-MM-DD').format('MM/DD/YYYY') === value || acc,
                false
            );
            if (match || !(moment(value).day() % 6)) {
                mCtrl.$setValidity('validDate', false);
            } else {
                mCtrl.$setValidity('validDate', true);
            }
            return value;
        }
        mCtrl.$parsers.push(validateDates);
    },
});

export const AutoTabTo = [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link(scope, el, attrs, ngmodel) {
                el.bind('keyup', function (e) {
                    if (
                        !e.shiftKey &&
                        e.keyCode !== 16 &&
                        e.keyCode !== 9 &&
                        this.value.length === this.maxLength
                    ) {
                        if (ngmodel.$valid) {
                            const element = document.getElementById(attrs.autoTabTo);
                            if (element) {
                                element.setSelectionRange(0, 9999);
                                element.focus();
                            }
                        }
                        e.preventDefault();
                    }
                });
            },
        };
    },
];

export const IgnoreDirty = [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link(scope, elm, attrs, ctrl) {
                ctrl.$setPristine = function () {};
                ctrl.$pristine = false;
            },
        };
    },
];

export const LessThan = () => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, elm, attrs, ctrl) {
        if (!ctrl) {
            return;
        }
        ctrl.$validators.lessThan = function (modelValue, viewValue) {
            // default to true, other validators should catch required, isNumeric, etc...
            if (modelValue == undefined || scope.$eval(attrs.lessThan) == undefined) {
                return true;
            }
            try {
                const smallerValue = Number(modelValue);
                const largerValue = Number(scope.$eval(attrs.lessThan));
                return smallerValue < largerValue;
            } catch (ex) {
                return true;
            }
        };
    },
});

export const GreaterThan = () => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, elm, attrs, ctrl) {
        if (!ctrl) {
            return;
        }
        ctrl.$validators.greaterThan = function (modelValue, viewValue) {
            // default to true, other validators should catch required, isNumeric, etc...
            if (modelValue == undefined || scope.$eval(attrs.greaterThan) == undefined) {
                return true;
            }
            try {
                const largerValue = Number(modelValue);
                const smallerValue = Number(scope.$eval(attrs.greaterThan));
                return largerValue > smallerValue;
            } catch (ex) {
                return true;
            }
        };
    },
});
