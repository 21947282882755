import { getChannelAPIPath } from '@treasury/core/http';

CheckImageService.$inject = ['$resource'];

export default function CheckImageService($resource) {
    const resourceUrl = `${getChannelAPIPath()}checkimages/`;

    return {
        getDepositSet,
        getCheckImage,
    };

    function getDepositSet(depositImageNumber) {
        return $resource(`${resourceUrl}deposit`, {
            depositImageNumber,
        }).query().$promise;
    }

    function getCheckImage(checkImageNumber) {
        return $resource(
            `${resourceUrl}check`,
            { checkImageNumber },
            { get: { method: 'GET', isArray: false } }
        ).get().$promise;
    }
}
