/* eslint-disable no-use-before-define */
import { TmHttpClient } from '@treasury/core/http';
import { Nullable } from '@treasury/utils/types';
import {
    IssuedItemLegacyDto,
    IssuedItemSavedLegacyDto,
    IssuedItemsParsedResponse,
    IssuedItemsQueryDto,
    PositivePayFormatDto,
} from '../../types/arp';

export class ArpIssuedItemsRequests {
    static async searchIssuedItems(params: Partial<IssuedItemsQueryDto>) {
        const http = await TmHttpClient.getInstance();
        return http.request<IssuedItemLegacyDto[] | null>('arp/searchIssuedItems', {
            method: 'POST',
            body: params,
        });
    }

    static async saveIssuedItems(
        issuedItems: Nullable<IssuedItemLegacyDto>[],
        issuedFileName: string | null = null,
        account: string | null = null
    ) {
        const body = {
            account,
            issuedFileName,
            issuedItems,
        };

        const http = await TmHttpClient.getInstance();
        return http.request<Nullable<IssuedItemSavedLegacyDto>[]>('arp/saveIssuedItems', {
            body,
            method: 'POST',
        });
    }

    static async getSavedFormats() {
        const http = await TmHttpClient.getInstance();
        return http.request<PositivePayFormatDto[]>('arp/savedFormats');
    }

    static async parseIssuedItems(formatId: number, data: File) {
        const http = await TmHttpClient.getInstance();
        const formData = transformParseIssuedItemsRequest(formatId, data);
        return http.request<Nullable<IssuedItemsParsedResponse>>('arp/parseIssuedItems', {
            body: formData,
            method: 'POST',
        });
    }
}

function transformParseIssuedItemsRequest(formatId: number, data: File) {
    const fd = new FormData();
    fd.append('formatId', formatId.toString());
    fd.append('arpData', data);
    return fd;
}
