import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Recordset, string } from '@treasury/FDL';
import { Record } from '@treasury/FDL/record';
import { NewUserAccountDto } from '@treasury/domain/channel/types';
import { TmApiError } from '@treasury/domain/shared';
import { ListeningElementMixin } from '@treasury/omega/components/listening-element';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-field';
import '@treasury/omega/components/omega-filter-bar';
import '@treasury/omega/components/omega-input';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/omega-tooltip';
import '@treasury/omega/components/progress/omega-progress';
import { AlertMixin } from '@treasury/omega/mixins/alert.mixin';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserProfileClient } from '../clients/user-profile-client';
import { createUserAccountFields } from '../data/create-user-account-record';

const mixin = AlertMixin(ListeningElementMixin(LitElement));
@customElement('user-copy-user-dialog')
export class UserCopyUserDialog extends mixin {
    @property({
        type: Object,
    })
    @InjectProperty()
    public declare client: UserProfileClient;

    @property({
        type: Object,
    })
    copyUser!: (arg0: any) => unknown;

    @property({
        type: Object,
    })
    public copiedUserRecord: Record<NewUserAccountDto> = new Record(
        createUserAccountFields,
        {} as NewUserAccountDto
    );

    @state()
    private isLoading = true;

    @state()
    private isUpdating = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public open = false;

    @property({ type: Object })
    private userList!: Recordset<any>;

    columns!: (
        | { label: string; field: string; type?: undefined; actions?: undefined }
        | {
              type: string;
              label: string;
              actions: {
                  label: string;
                  action: string;
                  visibleWhen: () => boolean;
              }[];
              field?: undefined;
          }
    )[];

    actions!: { Select: (record: any) => Promise<void> };

    public async firstUpdated() {
        try {
            const fields = {
                userId: string.thatIs.readOnly(),
                userName: string.thatIs.readOnly(),
                roles: string.thatIs.readOnly().with.template((value, record) => {
                    const roles = [];
                    if (record.getField('isAdmin')) {
                        roles.push('Admin');
                    }
                    if (record.getField('isSuperUser')) {
                        roles.push('Super User');
                    }
                    return html`${roles.toString()}`;
                }),
            };
            this.actions = {
                Select: async record => {
                    // Do some stuff with your function using whatever is in the passed record argument
                    await this.copyExistingUser(record); // whatever
                },
            };
            this.columns = [
                { label: 'Login ID', field: 'userId' },
                { label: 'User Name', field: 'userName' },
                { label: 'Roles', field: 'roles' },
                {
                    type: 'actions',
                    label: '',
                    actions: [
                        {
                            label: 'Select',
                            action: 'Select',
                            visibleWhen: () => true,
                        },
                    ],
                },
            ];
            this.userList = new Recordset(fields, this.client.searchUsers);
            const copiedUser = {
                name: '',
                loginId: '',
                department: '',
                email: '',
                phone: '',
                extension: '',
                fax: '',
                isSuperUser: false,
                isAdmin: false,
            } as NewUserAccountDto;
            this.copiedUserRecord = new Record(createUserAccountFields, copiedUser);
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code: code.toString(),
                    time,
                };
            } else {
                const message = 'An unknown error occurred.';
                console.error(message, err);
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.isLoading = false;
        }
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('open') && this.open) {
            this.copiedUserRecord.reset();
        }
    }

    closeDialog() {
        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    copyExistingUser(user: any) {
        this.closeDialog();
        this.copyUser(user);
    }

    renderBlockingLoader() {
        if (this.isUpdating) {
            return html`<div class="background">
                <omega-progress card class="light-loader"></omega-progress>
            </div>`;
        }
        return nothing;
    }

    render() {
        if (!this.userList) {
            return nothing;
        }
        return html`${this.renderAlert()}${this.renderBlockingLoader()}<omega-dialog
                id="copy-user-dialog"
                .open=${this.open}
                @close=${this.closeDialog}
                dialogTitle="Copy User"
            >
                <omega-filter-bar .recordset=${this.userList}></omega-filter-bar>
                <omega-table
                    .recordset=${this.userList}
                    .columnDefinitions=${this.columns}
                    @action=${(e: { detail: any }) => this.copyExistingUser(e.detail)}
                ></omega-table>
            </omega-dialog>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            #copy-user-dialog {
                --omega-dialog-content-overflow: visible;
                --omega-dialog-min-width: 200px;
            }

            #copy-user-dialog omega-table {
                --pagination-position: static;
            }

            #copy-user-dialog omega-field {
                --omega-field-control-width: 200px;
            }

            #copy-user-dialog .dialog-content {
                padding: 0 20px 20px;
            }

            #copy-user-dialog omega-field:first-of-type {
                padding-top: 0px;
            }

            #copy-user-dialog omega-field,
            #copy-user-dialog omega-tooltip omega-field:first-of-type {
                padding-top: 1rem;
                max-width: 200px;
            }
        `;
    }
}
