interface Config {
    title: string;
    key: string;
    downloadKey: string;
    downloadClientService: string;
    get: string;
    post: string;
    put: string;
    delete: string;
    approve: string;
    reject: string;
    actions: string[];
    downloadOptions: string[];
    creationOptions: { label: string; value: string; visibleWhen: any }[];
    permissions: string[];
    reportUrl: string;
    entitlements: {
        create: string;
        fullEdit: string;
        partialEdit: string;
        delete: string;
        approve: string;
    };
}

interface ContainerConfig {
    template: Config;
    payment: Config;
}

export const CONTAINER_CONFIGURATION: ContainerConfig = {
    template: {
        title: 'Template',
        key: 'template',
        downloadKey: 'ACHTemplateDetail',
        downloadClientService: 'downloadInternationalTemplate',
        get: 'getTemplateById',
        post: 'createTemplate',
        put: 'updateTemplate',
        delete: 'deleteTemplate',
        approve: 'approveTemplate',
        reject: 'rejectTemplate',
        actions: ['edit', 'delete', 'approve', 'reject'],
        downloadOptions: ['CSV', 'PDF'],
        creationOptions: [],
        permissions: ['EditTemplate', 'DeleteTemplate', 'CreatePayment', 'CreateTemplate'],
        entitlements: {
            create: 'Create International Ach Template',
            fullEdit: 'Full Edit International Ach Template',
            partialEdit: 'Partial Edit International Ach Template',
            delete: 'Delete International Ach Template',
            approve: 'Approve International Ach Template',
        },
        reportUrl: 'payables.ach.payments.international-ach-templates',
    },
    payment: {
        title: 'Payment',
        key: 'payment',
        get: 'getPayment',
        post: 'createPayment',
        put: 'updatePayment',
        delete: '',
        downloadKey: 'ACHPaymentDetail',
        downloadClientService: 'downloadInternationalPayment',
        approve: 'approveTemplate',
        reject: 'rejectTemplate',
        actions: ['edit', 'approve', 'reject'],
        downloadOptions: ['CSV', 'NACHA', 'PDF'],
        creationOptions: [
            { label: 'Manual', value: 'manual', visibleWhen: () => true },
            {
                label: 'From Template',
                value: 'template',
                visibleWhen: (entitlements: Array<{ permission: string }>) =>
                    entitlements?.some(e => e.permission === 'Create International Ach Template') &&
                    entitlements?.some(
                        e => e.permission === 'Feature.Ach.InternationalPaymentTemplates'
                    ),
            },
            { label: 'Upload International Nacha File', value: 'file', visibleWhen: () => true },
        ],
        permissions: ['Approve', 'RejectPayment', 'EditPayment'],
        entitlements: {
            create: 'Create International Ach Payment',
            fullEdit: 'Full Edit International Ach Payment',
            partialEdit: 'Partial Edit International Ach Payment',
            delete: 'Delete International Ach Payment',
            approve: 'Approve International Ach Payment',
        },
        reportUrl: 'payables.ach.payment-list',
    },
};
