import AchExceptionsRequests from '../../requests/ach/ach-exceptions-requests.js';

export default class AchExceptionsServices {
    static async findAchExceptions() {
        // Need to figure out how to map body without filters. This is just stubbing a request from QA SMAC
        const body = {
            searchType: 'OpenItems',
            decisionTaken: 'both',
            amountType: null,
            amount: '',
            amountEnd: '',
            amountStart: '',
            postedDateType: null,
            postedDate: '',
            postedDateEnd: '',
            postedDateStart: '',
            description: '',
            accounts: [
                {
                    name: 'CK 1000',
                    number: '1000',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '1000',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: '8c3e6885-aefd-4f7a-8e8e-0ef4783b7437',
                    id: 14885,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 1020',
                    number: '1020',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '1020',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: '8c3ae625-ce9f-42c4-b43e-58756ffa5ee4',
                    id: 14886,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 121419',
                    number: '121419',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '121419',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: 'a16a18ae-90d4-4c21-bebf-8d35f28901fc',
                    id: 15927,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 191817',
                    number: '191817',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '191817',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: '04fcde7a-fff4-4be3-83d6-6417534d3f5b',
                    id: 15928,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 1984',
                    number: '1984',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '1984',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: '00f4c538-8c39-4816-b9ec-2b23837690f9',
                    id: 14887,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 2000',
                    number: '2000',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '2000',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: 'fc5e3af4-b9dd-4c55-b715-266199c43a37',
                    id: 14888,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 2018',
                    number: '2018',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '2018',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: '7c01e3ed-4870-45c7-8136-3fbf64435b11',
                    id: 14889,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 22',
                    number: '22',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '22',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: '980b3ce1-8f99-4bcc-81bd-a9d42832b9c8',
                    id: 14884,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 73005',
                    number: '73005',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '73005',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: 'df3804e0-8b5d-4a8e-8c13-945e502c6bbd',
                    id: 16033,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
                {
                    name: 'CK 9195',
                    number: '9195',
                    status: 'Active',
                    account: null,
                    currentBalance: null,
                    collectedBalance: null,
                    availableBalance: null,
                    type: 'Checking',
                    perTransactionLimit: 0,
                    dailyLimit: 0,
                    cifNumber: null,
                    isDeleted: false,
                    showAccountNickName: false,
                    accountDisplayLabel: '9195',
                    transferAmount: 0,
                    transferMemo: null,
                    loanPaymentAmountDue: null,
                    loanPaymentDueDate: null,
                    allowLoanPaymentPastDue: false,
                    accountUniqueId: 'fabb8dae-3f8c-4d63-ad5e-1abbfb3873ba',
                    id: 15926,
                    updatedBy: null,
                    updatedDate: '0001-01-01T00:00:00',
                    isChecked: true,
                },
            ],
            secCodes: [
                { code: 'ACK', isChecked: true },
                { code: 'ADV', isChecked: true },
                { code: 'ARC', isChecked: true },
                { code: 'ATX', isChecked: true },
                { code: 'BOC', isChecked: true },
                { code: 'CCD', isChecked: true },
                { code: 'CIE', isChecked: true },
                { code: 'COR', isChecked: true },
                { code: 'CTX', isChecked: true },
                { code: 'DNE', isChecked: true },
                { code: 'ENR', isChecked: true },
                { code: 'IAT', isChecked: true },
                { code: 'MTE', isChecked: true },
                { code: 'POP', isChecked: true },
                { code: 'POS', isChecked: true },
                { code: 'PPD', isChecked: true },
                { code: 'RCK', isChecked: true },
                { code: 'SHR', isChecked: true },
                { code: 'TEL', isChecked: true },
                { code: 'TRC', isChecked: true },
                { code: 'TRX', isChecked: true },
                { code: 'WEB', isChecked: true },
                { code: 'XCK', isChecked: true },
            ],
            type: 'OpenItems',
        };
        const response = await AchExceptionsRequests.findAchExceptions(body);
        return response.achExceptionDetails
            ? {
                  data: response.achExceptionDetails,
                  totalCount: response.achExceptionDetails.length,
              }
            : { data: [], totalCount: 0 };
    }

    static async findAchExceptionsDecisionsActivity() {
        const body = {
            pagingParameters: {
                pageSize: 10,
                pageNumber: 1,
                orderBys: [{ name: 'DecisionDate', descending: true }],
                currentPage: 1,
            },
            achExceptionDetailSearchModel: {
                type: 'OpenItems',
                achExceptionDecisionStatus: 'all',
                amountType: null,
                amount: '',
                amountEnd: '',
                amountStart: '',
                postedDateType: null,
                postedDate: '',
                postedDateEnd: '',
                postedDateStart: '',
                description: '',
                accounts: [
                    {
                        name: 'CK 1000',
                        number: '1000',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '1000',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: '8c3e6885-aefd-4f7a-8e8e-0ef4783b7437',
                        id: 14885,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 1020',
                        number: '1020',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '1020',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: '8c3ae625-ce9f-42c4-b43e-58756ffa5ee4',
                        id: 14886,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 121419',
                        number: '121419',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '121419',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: 'a16a18ae-90d4-4c21-bebf-8d35f28901fc',
                        id: 15927,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 191817',
                        number: '191817',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '191817',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: '04fcde7a-fff4-4be3-83d6-6417534d3f5b',
                        id: 15928,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 1984',
                        number: '1984',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '1984',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: '00f4c538-8c39-4816-b9ec-2b23837690f9',
                        id: 14887,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 2000',
                        number: '2000',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '2000',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: 'fc5e3af4-b9dd-4c55-b715-266199c43a37',
                        id: 14888,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 2018',
                        number: '2018',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '2018',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: '7c01e3ed-4870-45c7-8136-3fbf64435b11',
                        id: 14889,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 22',
                        number: '22',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '22',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: '980b3ce1-8f99-4bcc-81bd-a9d42832b9c8',
                        id: 14884,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 73005',
                        number: '73005',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '73005',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: 'df3804e0-8b5d-4a8e-8c13-945e502c6bbd',
                        id: 16033,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                    {
                        name: 'CK 9195',
                        number: '9195',
                        status: 'Active',
                        account: null,
                        currentBalance: null,
                        collectedBalance: null,
                        availableBalance: null,
                        type: 'Checking',
                        perTransactionLimit: 0,
                        dailyLimit: 0,
                        cifNumber: null,
                        isDeleted: false,
                        showAccountNickName: false,
                        accountDisplayLabel: '9195',
                        transferAmount: 0,
                        transferMemo: null,
                        loanPaymentAmountDue: null,
                        loanPaymentDueDate: null,
                        allowLoanPaymentPastDue: false,
                        accountUniqueId: 'fabb8dae-3f8c-4d63-ad5e-1abbfb3873ba',
                        id: 15926,
                        updatedBy: null,
                        updatedDate: '0001-01-01T00:00:00',
                        isChecked: true,
                    },
                ],
                secCodes: [
                    { code: 'ACK', isChecked: true },
                    { code: 'ADV', isChecked: true },
                    { code: 'ARC', isChecked: true },
                    { code: 'ATX', isChecked: true },
                    { code: 'BOC', isChecked: true },
                    { code: 'CCD', isChecked: true },
                    { code: 'CIE', isChecked: true },
                    { code: 'COR', isChecked: true },
                    { code: 'CTX', isChecked: true },
                    { code: 'DNE', isChecked: true },
                    { code: 'ENR', isChecked: true },
                    { code: 'IAT', isChecked: true },
                    { code: 'MTE', isChecked: true },
                    { code: 'POP', isChecked: true },
                    { code: 'POS', isChecked: true },
                    { code: 'PPD', isChecked: true },
                    { code: 'RCK', isChecked: true },
                    { code: 'SHR', isChecked: true },
                    { code: 'TEL', isChecked: true },
                    { code: 'TRC', isChecked: true },
                    { code: 'TRX', isChecked: true },
                    { code: 'WEB', isChecked: true },
                    { code: 'XCK', isChecked: true },
                ],
            },
            searchText: '',
            searchAccountNickName: false,
            currentBusinessDayCutoff: '2022-01-11T14:15:00',
            previousBusinessDayCutoff: '2022-01-10T14:15:00',
        };
        const response = await AchExceptionsRequests.findAchExceptionsDecisionsActivity(body);
        return {
            data: response.items,
            totalCount: response.items.length,
        };
    }

    static async findAllAchExceptions() {
        let data = [];
        await Promise.all([
            this.findAchExceptions(),
            this.findAchExceptionsDecisionsActivity(),
        ]).then(values => {
            data = values[0].data.concat(values[1].data);
        });
        return {
            data,
            totalCount: data.length,
        };
    }
}
