export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=',
                selectionList: '=',
                columnName: '@',
                id: '@',
                name: '@',
                placeholder: '@',
                ngRequired: '=',
                includeTemplate: '=',
                transaction: '=?',
                type: '@',
                index: '=?',
                ngInvalid: '=?',
                disabled: '=',
                customAccountNumberFormat: '@',
                setDirty: '&?',
            },
            template: require('./typeAheadTemplate.html'),
            controller: [
                '$scope',
                function ($scope) {
                    $scope.internalSelectionList = [];

                    $scope.createFilterFn = function (searchText) {
                        return function (value, index, array) {
                            if (value.showAccountNickName) {
                                return (
                                    value.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
                                );
                            }
                            if (!value.showAccountNickName && !$scope.customAccountNumberFormat) {
                                return (
                                    value.number.toLowerCase().indexOf(searchText.toLowerCase()) >
                                    -1
                                );
                            }
                            return (
                                value.customAccountNumberLabel
                                    .toLowerCase()
                                    .indexOf(searchText.toLowerCase()) > -1
                            );
                        };
                    };

                    $scope.$watch('columnName', newValue => {
                        if (newValue !== null && newValue !== undefined) {
                            $scope.query = `item as item.${$scope.columnName} for item in internalSelectionList`;
                        }
                    });

                    $scope.$watch(
                        'transaction',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.internalSelectionList = angular.copy($scope.selectionList);

                                if (angular.isDefined($scope.transaction.type) === false) {
                                    if ($scope.type === 'from') {
                                        if (
                                            $scope.transaction.toAccount !== null &&
                                            $scope.transaction.toAccount !== undefined &&
                                            typeof $scope.transaction.toAccount === 'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.toAccount.number ||
                                                    (item.number ===
                                                        $scope.transaction.toAccount.number &&
                                                        item.type !==
                                                            $scope.transaction.toAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    } else if ($scope.type === 'to') {
                                        if (
                                            $scope.transaction.fromAccount !== null &&
                                            $scope.transaction.fromAccount !== undefined &&
                                            typeof $scope.transaction.fromAccount === 'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.fromAccount.number ||
                                                    (item.number ===
                                                        $scope.transaction.fromAccount.number &&
                                                        item.type !==
                                                            $scope.transaction.fromAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    }
                                } else if ($scope.transaction.type === 'one') {
                                    if ($scope.type === 'from') {
                                        if (
                                            $scope.transaction.transactions[0].toAccount !== null &&
                                            $scope.transaction.transactions[0].toAccount !==
                                                undefined &&
                                            typeof $scope.transaction.transactions[0].toAccount ===
                                                'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.transactions[0].toAccount
                                                            .number ||
                                                    (item.number ===
                                                        $scope.transaction.transactions[0].toAccount
                                                            .number &&
                                                        item.type !==
                                                            $scope.transaction.transactions[0]
                                                                .toAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    } else if ($scope.type === 'to') {
                                        if (
                                            $scope.transaction.transactions[0].fromAccount !==
                                                null &&
                                            $scope.transaction.transactions[0].fromAccount !==
                                                undefined &&
                                            typeof $scope.transaction.transactions[0]
                                                .fromAccount === 'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.transactions[0]
                                                            .fromAccount.number ||
                                                    (item.number ===
                                                        $scope.transaction.transactions[0]
                                                            .fromAccount.number &&
                                                        item.type !==
                                                            $scope.transaction.transactions[0]
                                                                .fromAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    }
                                } else if ($scope.transaction.type === 'oneToMany') {
                                    if ($scope.type === 'from') {
                                        var index = 0;
                                        $scope.internalSelectionList = [];
                                        var found = false;
                                        angular.forEach(
                                            $scope.transaction.transactions,
                                            transaction => {
                                                if (index > 0) {
                                                    if (
                                                        transaction.toAccount !== null &&
                                                        transaction.toAccount !== undefined &&
                                                        typeof transaction.toAccount === 'object'
                                                    ) {
                                                        found = true;
                                                    }
                                                }
                                                index++;
                                            }
                                        );
                                        if (found === false) {
                                            $scope.internalSelectionList = angular.copy(
                                                $scope.selectionList
                                            );
                                        } else {
                                            angular.forEach($scope.selectionList, item => {
                                                let match = false;
                                                index = 0;
                                                angular.forEach(
                                                    $scope.transaction.transactions,
                                                    transaction => {
                                                        if (index > 0) {
                                                            if (
                                                                transaction.toAccount !== null &&
                                                                transaction.toAccount !==
                                                                    undefined &&
                                                                typeof transaction.toAccount ===
                                                                    'object' &&
                                                                item.number ===
                                                                    transaction.toAccount.number &&
                                                                item.type ===
                                                                    transaction.toAccount.type
                                                            ) {
                                                                match = true;
                                                            }
                                                        }
                                                        index++;
                                                    }
                                                );
                                                if (match === false) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    } else if ($scope.type === 'to') {
                                        if (
                                            $scope.transaction.transactions[0].fromAccount !==
                                                null &&
                                            $scope.transaction.transactions[0].fromAccount !==
                                                undefined &&
                                            typeof $scope.transaction.transactions[0]
                                                .fromAccount === 'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.transactions[0]
                                                            .fromAccount.number ||
                                                    (item.number ===
                                                        $scope.transaction.transactions[0]
                                                            .fromAccount.number &&
                                                        item.type !==
                                                            $scope.transaction.transactions[0]
                                                                .fromAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    }
                                } else if ($scope.transaction.type === 'manyToOne') {
                                    if ($scope.type === 'to') {
                                        var index = 0;
                                        $scope.internalSelectionList = [];
                                        var found = false;
                                        angular.forEach(
                                            $scope.transaction.transactions,
                                            transaction => {
                                                if (index > 0) {
                                                    if (
                                                        transaction.fromAccount !== null &&
                                                        transaction.fromAccount !== undefined &&
                                                        typeof transaction.fromAccount === 'object'
                                                    ) {
                                                        found = true;
                                                    }
                                                }
                                                index++;
                                            }
                                        );
                                        if (found === false) {
                                            $scope.internalSelectionList = angular.copy(
                                                $scope.selectionList
                                            );
                                        } else {
                                            angular.forEach($scope.selectionList, item => {
                                                let match = false;
                                                index = 0;
                                                angular.forEach(
                                                    $scope.transaction.transactions,
                                                    transaction => {
                                                        if (index > 0) {
                                                            if (
                                                                transaction.fromAccount !== null &&
                                                                transaction.fromAccount !==
                                                                    undefined &&
                                                                typeof transaction.fromAccount ===
                                                                    'object' &&
                                                                item.number ===
                                                                    transaction.fromAccount
                                                                        .number &&
                                                                item.type ===
                                                                    transaction.fromAccount.type
                                                            ) {
                                                                match = true;
                                                            }
                                                        }
                                                        index++;
                                                    }
                                                );
                                                if (match === false) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    } else if ($scope.type === 'from') {
                                        if (
                                            $scope.transaction.transactions[0].toAccount !== null &&
                                            $scope.transaction.transactions[0].toAccount !==
                                                undefined &&
                                            typeof $scope.transaction.transactions[0].toAccount ===
                                                'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.transactions[0].toAccount
                                                            .number ||
                                                    (item.number ===
                                                        $scope.transaction.transactions[0].toAccount
                                                            .number &&
                                                        item.type !==
                                                            $scope.transaction.transactions[0]
                                                                .toAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    }
                                } else if ($scope.transaction.type === 'many') {
                                    if ($scope.type === 'from') {
                                        if (
                                            $scope.transaction.transactions[$scope.index]
                                                .toAccount !== null &&
                                            $scope.transaction.transactions[$scope.index]
                                                .toAccount !== undefined &&
                                            typeof $scope.transaction.transactions[$scope.index]
                                                .toAccount === 'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.transactions[
                                                            $scope.index
                                                        ].toAccount.number ||
                                                    (item.number ===
                                                        $scope.transaction.transactions[
                                                            $scope.index
                                                        ].toAccount.number &&
                                                        item.type !==
                                                            $scope.transaction.transactions[
                                                                $scope.index
                                                            ].toAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    } else if ($scope.type === 'to') {
                                        if (
                                            $scope.transaction.transactions[$scope.index]
                                                .fromAccount !== null &&
                                            $scope.transaction.transactions[$scope.index]
                                                .fromAccount !== undefined &&
                                            typeof $scope.transaction.transactions[$scope.index]
                                                .fromAccount === 'object'
                                        ) {
                                            $scope.internalSelectionList = [];
                                            angular.forEach($scope.selectionList, item => {
                                                if (
                                                    item.number !==
                                                        $scope.transaction.transactions[
                                                            $scope.index
                                                        ].fromAccount.number ||
                                                    (item.number ===
                                                        $scope.transaction.transactions[
                                                            $scope.index
                                                        ].fromAccount.number &&
                                                        item.type !==
                                                            $scope.transaction.transactions[
                                                                $scope.index
                                                            ].fromAccount.type)
                                                ) {
                                                    $scope.internalSelectionList.push(item);
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        },
                        true
                    );

                    $scope.$watch(
                        'selectionList',
                        newValue => {
                            if (newValue !== null && newValue !== undefined) {
                                $scope.internalSelectionList = $scope.selectionList;
                            }
                        },
                        true
                    );

                    $scope.$watch('ngModel', newValue => {
                        if (newValue !== null && newValue !== undefined) {
                            if ($scope.setDirty) {
                                $scope.setDirty({ $controlName: $scope.name });
                            }
                            if (typeof newValue !== 'object') {
                                $scope.ngModel = newValue;
                                angular.forEach($scope.internalSelectionList, item => {
                                    if (
                                        (item.showAccountNickName && item.name === newValue) ||
                                        (!item.showAccountNickName &&
                                            !$scope.customAccountNumberFormat &&
                                            item.number === newValue) ||
                                        ($scope.customAccountNumberFormat &&
                                            item[$scope.columnName] === newValue)
                                    ) {
                                        $scope.ngModel = item;
                                    }
                                });
                            }
                        }
                    });
                },
            ],
        };
    },
];
