import '@treasury/omega/components/omega-badge.js';
import {
    string,
    date,
    amountRange,
    status,
    dateRange,
    money,
    boolean,
} from '@treasury/policy/primitives';
import { companyName } from '@treasury/policy/company.js';
import AchChildSupportServices from '@treasury/domain/channel/services/ach/ach-child-support-services';

export const fields = {
    selected: boolean.thatIs.disabledWhen(() => false),
    templateName: string.thatIs.readOnly(),
    achCompanyName: companyName.thatIs.readOnly(),
    secCode: string.thatIs.readOnly(),
    lastUpdated: date.thatIs.readOnly(),
    creditAmount: money.thatIs.readOnly().with.reducer('sum'),
    status: string.thatIs.readOnly(),
    templateId: string.thatIs.readOnly(),
};

export const filters = [
    {
        field: 'status',
        fieldType: status.with
            .options([
                // Not available at this time
                // { text: 'Pending Approval', value: 1 },
                // { text: 'Approval Rejected', value: 2 },
                { text: 'Ready', value: 2 },
            ])
            .thatHas.label('Status')
            .as.tag('omega-select'),
        value: 2,
    },
    {
        field: 'achCompany',
        fieldType: companyName.with
            .options({
                fetch: AchChildSupportServices.getAchCompanies,
                text: 'companyName',
                value: 'id',
            })
            .and.filtering()
            .thatHas.label('ACH Company Name')
            .thatHas.multipleValues()
            .and.filtering()
            .as.tag('omega-select'),
        value: [],
    },
    {
        field: 'name',
        fieldType: string.with.label('Template Name').as.tag('omega-input'),
    },
    {
        field: 'amount',
        fieldType: amountRange.with.label('Amount').as.tag('omega-range'),
        value: ['specific', '', ''],
    },
    {
        field: 'lastUpdated',
        fieldType: dateRange.with.label('Last Updated').as.tag('omega-datepicker'),
        value: 'no-date-selected',
    },
];

export const columns = [
    {
        type: 'checkbox',
        field: 'selected',
        allowOne: true,
        label: '',
    },
    {
        type: 'command',
        field: 'templateName',
        label: 'Template Name',
        action: 'loadTemplate',
    },
    {
        field: 'achCompanyName',
        label: 'ACH Company',
    },
    {
        field: 'lastUpdated',
        label: 'Last Updated',
    },
    {
        field: 'creditAmount',
        label: 'Amount',
        summary: true,
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        type: 'actions',
        label: 'Actions',
        actions: [
            {
                label: 'Delete',
                action: 'delete',
                visibleWhen: record =>
                    record.getField('userPermissions').includes('DeleteTemplate'),
            },
            // {
            //     label: 'Clone',
            //     action: 'clone',
            //     visibleWhen: record =>
            //         record.getField('userPermissions').includes('CreateTemplate'),
            // },
            // {
            //     label: 'Edit',
            //     action: 'edit',
            //     visibleWhen: record => record.getField('userPermissions').includes('EditTemplate'),
            // },
            {
                label: 'Initiate',
                action: 'initiate',
                visibleWhen: record => record.getField('userPermissions').includes('CreatePayment'),
            },
        ],
    },
];
