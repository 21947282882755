export default [
    function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                entity: '=',
                accountNumber: '@',
            },
            template:
                '<a href="javascript:void(0);" class="pointer-cursor" ng-click="showCheck();"><span ng-show="isCheckImage()">View Check {{entity.checkNumber}}</span> <span ng-show="!isCheckImage()">View Deposit {{entity.checkNumber}}</span></a>',
            controller: [
                '$scope',
                '$modal',
                function ($scope, $modal) {
                    $scope.accountNumber = $scope.accountNumber;

                    $scope.showCheck = function () {
                        const modalInstance = $modal.open({
                            template: require('./checkImageModal/checkImageModalView.html'),
                            size: 'lg',
                            controller: 'CheckImageController',
                            backdrop: 'static',
                            resolve: {
                                entity() {
                                    return $scope.entity;
                                },
                                accountNumber() {
                                    return $scope.accountNumber;
                                },
                            },
                        });
                        modalInstance.result.then(result => {
                            $('#sectionView').removeClass('no-print');
                        });
                    };

                    $scope.isCheckImage = function () {
                        return (
                            $scope.entity.checkImageNumber &&
                            parseInt($scope.entity.checkImageNumber) > 0 &&
                            ($scope.entity.isDebit || $scope.entity.posPayItemId)
                        );
                    };
                },
            ],
        };
    },
];
