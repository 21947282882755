/* eslint-disable no-use-before-define */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { NavigationService } from '@treasury/core/navigation';
import userHasEntitlement from '@treasury/domain/shared/utilities/user-has-entitlement.js';
import { Recordset } from '@treasury/FDL';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/layouts/omega-report';
import openDialog from '@treasury/omega/open-dialog.js';
import { css, html, LitElement, nothing } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../../../mix-ins/channel-alert-mixin.js';
import '../../components/ach-report-top.js';
import '../../components/ach-template-action-dialog.js';
import AchTaxTemplatesClient from '../clients/ach-tax-templates-client.js';
import { columns, fields, filters } from '../data/ach-tax-templates-data.js';

class AchTaxTemplatesContainer extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            institution: String,
            recordset: Object,
            loading: Boolean,
            client: Object,
            entitlements: Array,
            alert: Object,
        };
    }

    async firstUpdated() {
        const navService = await getNavService();

        if (!this.client) {
            this.client = new AchTaxTemplatesClient();
        }

        this.options = ['download', 'print'];
        this.entitlements = await this.client.entitlements();
        this.columns = columns(this.entitlements);
        this.filters = filters(this.client);
        this.fields = fields(this.client);
        this.recordset = new Recordset(this.fields, this.client.fetchAchTaxTemplates);
        this.itemLabel = { singular: 'template' };
        this.deleteAction = async (record, dialog) => {
            try {
                const { id, name } = record.values;
                const response = await this.client.deleteTemplateById(id);
                if (response.securityMessage.status === 'Success') {
                    this.alert = {
                        ...this.alert,
                        type: 'success',
                        message: html`ACH Tax Template <b>${name}</b> has been deleted.`,
                        visible: true,
                        code: '',
                        time: '',
                    };
                    await this.recordset.requestHardUpdate();
                }
            } catch (error) {
                this.setAlertFromError(error);
            }
            if (dialog) dialog.handleModalClose();
            return this.result;
        };
        this.actions = {
            clickTemplateName: record => {
                navService.navigate('payables.ach.batch-tax-detail', {
                    id: record.getField('id'),
                });
            },
            delete: record => {
                const deleteDialog = document.createElement('ach-template-action-dialog');
                openDialog(deleteDialog, {
                    dialogTitle: 'Delete Template',
                    record,
                    actionTaken: 'delete',
                    templateName: record.getField('name'),
                    dialogAction: () => this.deleteAction(record, deleteDialog),
                });
            },
            clone: record => {
                navService.navigate('payables.ach.batch.createClonedTaxTemplate', {
                    cloneId: record.getField('id'),
                });
            },
            initiate: async record => {
                const paymentList = await this.client.initiateTaxPaymentsByIds([record.values]);
                navService.navigate('payables.ach.payments.createFromTaxTemplate', {
                    paymentList,
                });
            },
            // eslint-disable-next-line sonarjs/no-identical-functions
            approveOrReject: record => {
                navService.navigate('payables.ach.batch-tax-detail', {
                    id: record.getField('id'),
                });
            },
        };
        this.reportActions = this.generateReportActions(this.entitlements);
        this.downloadOptions = ['PDF'];
        this.callToActionText = 'Create New Template';
        this.rowsPerPage = 25;
        this.reportTitle = 'ACH Tax Templates';
    }

    get institution() {
        return this._institution;
    }

    set institution(value) {
        this._institution = value;
        this.reportLinks = [
            {
                title: 'ACH Templates',
                url: `${window.location.origin}/${this.institution}/ach/templates`,
            },
        ];
    }

    async approveOrRejectAction(record, comments, actionTaken, dialog) {
        try {
            const id = record.getField('id');
            const action = actionTaken === 'approve' ? 'approved' : 'rejected';
            const response = await this.client.updateTemplateStatus(actionTaken, {
                templateId: id,
                comments,
            });
            if (response.securityMessage.status === 'Success') {
                this.alert = {
                    ...this.alert,
                    type: 'success',
                    message: html`Template successfully ${action}.`,
                    visible: true,
                    code: '',
                    time: '',
                };
                await this.recordset.requestHardUpdate();
            }
        } catch (error) {
            this.setAlertFromError(error);
        }
        if (dialog) dialog.handleModalClose();
        return this.result;
    }

    generateReportActions(entitlements) {
        if (!userHasEntitlement(entitlements, 'Create Ach Template')) {
            return nothing;
        }

        return [
            {
                type: 'primary',
                label: 'Initiate Selected Tax Templates',
                action: async () => {
                    const navService = await getNavService();
                    const selectedRecords = this.recordset
                        .recordsMatching('selected', true)
                        .map(record => record.values);
                    const paymentList = await this.client.initiateTaxPaymentsByIds(selectedRecords);

                    navService.navigate('payables.ach.payments.createFromTaxTemplate', {
                        paymentList,
                    });
                },
                isDisabled: () => this.recordset.noRecordsMatch('selected', true),
            },
        ];
    }

    async callToAction() {
        (await getNavService()).navigate('payables.ach.batch.createTaxTemplate');
    }

    async downloadAchTaxTemplates({ detail }) {
        await this.client.downloadAchTaxTemplates(detail.type, detail.filter);
    }

    renderReportTop() {
        return html`<ach-report-top
            .recordset=${this.recordset}
            countLabel="Total Tax Templates"
        ></ach-report-top>`;
    }

    render() {
        if (!this.recordset) return nothing;

        return html`
            ${this.renderAlert()}
            <omega-report
                flyout
                autostart
                .title=${this.reportTitle}
                .actions=${this.actions}
                .reportInformation=${this.reportInformation}
                .itemLabel=${this.itemLabel}
                .reportLinks=${this.reportLinks}
                .recordset=${this.recordset}
                .columns=${this.columns}
                .filters=${this.filters}
                .localFilters=${this.localFilters}
                .reportActions=${this.reportActions}
                .downloadOptions=${this.downloadOptions}
                .options=${this.options}
                .callToAction=${this.callToActionText}
                .rowsPerPage=${this.rowsPerPage}
                @callToAction=${this.callToAction}
                @reportDownload=${this.downloadAchTaxTemplates}
            >
                <div id="top-of-table" slot="above-table">${this.renderReportTop()}</div>
            </omega-report>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                height: 100%;
            }
            #type-to-filter {
                padding: 0 10px;
            }
        `;
    }
}

customElements.define('ach-tax-templates-container', AchTaxTemplatesContainer);

async function getNavService() {
    const di = await DiContainer.getInstance();
    return di.get(NavigationService);
}
