export default () => ({
    restrict: 'A',
    require: 'ngModel',
    link($scope, element, attrs, ngModel) {
        ngModel.$validators.treasuryMin = function (modelValue, viewValue) {
            let value = modelValue;
            if (modelValue == null) {
                value = viewValue;
            }

            if (value == null || value === '') return true;

            if (isNaN(value)) return false;

            let minimum = parseFloat($scope.$eval(attrs.trMin));
            if (isNaN(minimum)) {
                minimum = -Number.MAX_VALUE;
            }

            return value >= minimum;
        };

        $scope.$watch(
            () => $scope.$eval(attrs.trMin),
            () => {
                ngModel.$validate();
            }
        );
    },
});
