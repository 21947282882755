RegisterSecureTokenController.$inject = [
    '$scope',
    '$state',
    'securityService',
    'accountService',
    'entitlementsService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function RegisterSecureTokenController(
    $scope,
    $state,
    securityService,
    accountService
) {
    $scope.unregisteredCredential = {
        credentialId: '',
        token: '',
        pin: '',
        reEnterPin: '',
        IsUserEntryError: false,
    };
    $scope.actionTypes = {
        remindMeLater: 1,
        stopReminders: 2,
        register: 3,
    };
    $scope.pinsNotMatching = false;

    $scope.registerToken = function () {
        if ($scope.unregisteredCredential.pin !== $scope.unregisteredCredential.reEnterPin) {
            $scope.pinsNotMatching = true;
        } else {
            $scope.pinsNotMatching = false;
            $scope.unregisteredCredential.actionType = $scope.actionTypes.register;
            complete();
        }
    };

    $scope.remindMeLater = function () {
        $scope.unregisteredCredential.actionType = $scope.actionTypes.remindMeLater;
        complete();
    };

    $scope.stopReminders = function () {
        $scope.unregisteredCredential.actionType = $scope.actionTypes.stopReminders;
        complete();
    };

    $scope.isRemindMeLaterAvailable = () => $scope.allowActionBypass;

    function complete() {
        securityService
            .completeSecureTokenConfig($scope.unregisteredCredential)
            .then(async response => {
                if (response.isUserEntryError) {
                    $scope.unregisteredCredential.IsUserEntryError = true;
                } else {
                    $scope.unregisteredCredential.isUserEntryError = false;
                    await accountService.goToNextStep();
                }
            });
    }

    (function () {
        // init
        securityService.getSecureTokenConfig().then(async tokenConfig => {
            if (tokenConfig.cancelCollection) {
                await accountService.goToNextStep();
            } else {
                $scope.unregisteredCredential.credentialId = tokenConfig.credentialId;
                $scope.allowActionBypass = tokenConfig.allowActionBypass;
            }
        });
    })();
}
