/* eslint-disable no-use-before-define */
/* eslint-disable import/no-duplicates */
import '@treasury/omega/components/omega-dialog';
import '@treasury/omega/components/omega-table';
import '@treasury/omega/components/omega-filter-bar';

import { LitElement, html, css, nothing } from 'lit';
import { Action } from '@treasury/utils';
import { UploadIssuedItemsError } from '@treasury/domain/channel/types/arp';
import FieldTypes from '@treasury/policy/primitives';
import { Recordset, FieldType } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { LocalFilterEvent } from '@treasury/omega/types';
import { OmegaColumnDefinition } from '@treasury/omega/components/table';

type UploadErrorReportFilterEvent = LocalFilterEvent<UploadIssuedItemsError>;

const ReadOnlyStringField = FieldTypes.string.thatIs.readOnly();
const ReadOnlyNumberField = FieldTypes.number.thatIs.readOnly();

const columns: OmegaColumnDefinition<UploadIssuedItemsError>[] = [
    {
        field: 'lineNumber',
        label: 'Line Number',
    },
    {
        field: 'errorText',
        label: 'Error Text',
    },
];

const fieldDefinitions: FdlFieldDefinitions<UploadIssuedItemsError> = {
    lineNumber: ReadOnlyNumberField.and.maxColumnWidth(50) as FieldType<number>,
    errorText: ReadOnlyStringField.and.maxColumnWidth(400) as FieldType<string>,
};

export function renderUploadErrorDialog(errors: UploadIssuedItemsError[], onClose: Action) {
    if (errors.length < 1) {
        return nothing;
    }

    let filters: UploadErrorReportFilterEvent['detail'] = [];
    const recordset = new Recordset<UploadIssuedItemsError>(fieldDefinitions, () => errors);
    recordset.update();

    return html`<omega-dialog open dialogTitle="Positive Pay File Errors" @close=${onClose}>
        <div slot="content">
            <omega-filter-bar
                style="margin-left: 10px"
                .recordset=${recordset}
                @change=${(e: UploadErrorReportFilterEvent) => {
                    filters = e.detail;
                }}
            ></omega-filter-bar>
            <omega-table
                .columnDefinitions=${columns}
                .recordset=${recordset}
                .filters=${filters}
            ></omega-table></div
    ></omega-dialog>`;
}
