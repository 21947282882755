const isValueInArrayOfObjectsFindByKey = (array, key, value) => {
    const _key = key.toLowerCase().trim();
    const _value = value.toLowerCase().trim();
    return array.some(itemInArray => {
        const result = itemInArray[_key].toLowerCase().trim();
        return result === _value;
    });
};

export default isValueInArrayOfObjectsFindByKey;
