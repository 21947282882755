/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    BeneficiaryModelDto,
    CompanyAccountModelDto,
    ErrorHttpContentDto,
    WireCompanyModelDto,
    WireConfigurationDto,
} from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class WireCompaniesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags WireCompanies
     * @name WireCompaniesGet
     * @summary Gets all Wire Companies.
     * @request GET:/WireCompanies
     */
    wireCompaniesGet = (
        query: {
            /**
             * Indicate whether to include only non-DLI companies.
             *
             */
            nonDli: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<WireCompanyModelDto[], ErrorHttpContentDto>({
            path: `/WireCompanies`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireCompanies
     * @name WireCompaniesAccounts
     * @summary Returns the Company Accounts for the specified Wire Company Id.
     * @request GET:/WireCompanies/{id}/Accounts
     */
    wireCompaniesAccounts = (
        id: number,
        query: {
            /**
             * Wire type.
             *
             */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], ErrorHttpContentDto>({
            path: `/WireCompanies/${id}/Accounts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireCompanies
     * @name WireCompaniesBeneficiaries
     * @summary Returns the Beneficiaries for the specified Wire Company Id.
     * @request GET:/WireCompanies/{id}/Beneficiaries
     */
    wireCompaniesBeneficiaries = (id: number, params: RequestParams = {}) =>
        this.http.request<BeneficiaryModelDto[], ErrorHttpContentDto>({
            path: `/WireCompanies/${id}/Beneficiaries`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireCompanies
     * @name WireCompaniesWireConfiguration
     * @summary Returns the wire configuration for a company.
     * @request GET:/wireCompanies/wireConfiguration
     */
    wireCompaniesWireConfiguration = (params: RequestParams = {}) =>
        this.http.request<WireConfigurationDto, ErrorHttpContentDto>({
            path: `/wireCompanies/wireConfiguration`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
