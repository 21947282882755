import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { getChannelAPIPath } from '@treasury/core/http';

/**
 *
 * @param { ng.IHttpService } $http
 * @param { ng.resource.IResourceService } $resource
 * @param { ng.IQService } $q
 * @param { import('@jack-henry/frontend-utils/di').DiContainer } TmDi
 */
export function AchBatchService($resource, $http, $q, achPaymentTypes, TmDi) {
    const resourceUrl = `${getChannelAPIPath()}achBatches`;

    return {
        Batch,
        Recipient,
        search,
        searchTaxTemplates,
        getAll,
        get,
        create,
        getAccountTypes,
        getTransactionTypes,
        validateRoutingNumber,
        bulkInitiate,
        bulkInitiateTaxPayments,
        getDetails,
        updateBatch,
        deleteBatch,
        approve,
        reject,
        calculateAmounts,
        cloneBatch,
        updateFilterCounts,
        validateTemplateName,
    };

    function getAll() {
        return $resource(resourceUrl).query().$promise;
    }

    function get(batchId) {
        return $resource(`${resourceUrl}/${batchId}`).get().$promise;
    }

    function cloneBatch(cloneId) {
        return $resource(`${resourceUrl}/${cloneId}`).get().$promise;
    }

    function search(message) {
        return $http({ method: 'post', url: `${resourceUrl}/search`, data: message }).then(
            response => response.data
        );
    }

    function searchTaxTemplates(message) {
        return $http({
            method: 'post',
            url: `${resourceUrl}/searchTaxTemplates`,
            data: message,
        }).then(response => response.data);
    }

    /**
     * @param { import('@treasury/api/channel').AchStateTaxTemplateModelDto } data
     */
    function createAchStateTemplate(data) {
        return $q
            .all([
                TmDi.getAsync(AnalyticsService),
                $resource(`${resourceUrl}/stateTaxTemplate`).save(data).$promise,
            ])
            .then(([analytics, response]) => {
                const { secCode, creditAmount, debitAmount } = data;
                analytics.track(AnalyticsEvent.AchTemplateCreated, {
                    type: 'state',
                    secCode,
                    creditAmount,
                    debitAmount,
                });

                return response;
            });
    }

    /**
     * @param { import('@treasury/api/channel').AchFederalTaxTemplateModelDto } data
     */
    function createAchFederalTemplate(data) {
        return $q
            .all([
                TmDi.getAsync(AnalyticsService),
                $resource(`${resourceUrl}/federalTaxTemplate`).save(data).$promise,
            ])
            .then(([analytics, response]) => {
                const { secCode, creditAmount, debitAmount } = data;
                analytics.track(AnalyticsEvent.AchTemplateCreated, {
                    type: 'federal',
                    secCode,
                    creditAmount,
                    debitAmount,
                });

                return response;
            });
    }

    function create(data) {
        if (data.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment) {
            return createAchStateTemplate(data);
        }

        if (data.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment) {
            return createAchFederalTemplate(data);
        }

        return $resource(`${resourceUrl}/batch`).save(data).$promise;
    }

    function validateTemplateName(name) {
        return $resource(`${resourceUrl}/validateTemplateName/`, { templateName: name }).get()
            .$promise;
    }

    function bulkInitiate(list) {
        return $resource(`${resourceUrl}/initiate`).save(list).$promise;
    }

    function bulkInitiateTaxPayments(list) {
        return $resource(`${resourceUrl}/initiateTax`).save(list).$promise;
    }

    function getDetails(id) {
        return $resource(`${resourceUrl}/:id`, { id }).get().$promise;
    }

    function updateBatch(batchId, batch) {
        let url = resourceUrl;

        if (batch.achPaymentTypeId === achPaymentTypes.AchStateTaxPayment)
            url = `${resourceUrl}/stateTaxTemplate`;

        if (batch.achPaymentTypeId === achPaymentTypes.AchFederalTaxPayment)
            url = `${resourceUrl}/federalTaxTemplate`;

        const resource = $resource(`${url}/:id`, { id: batchId }, { update: { method: 'PUT' } });
        return resource.update(batch).$promise;
    }

    function validateRoutingNumber(routingNumber) {
        return $resource(
            `${getChannelAPIPath()}achPayments/verifyRoutingNumber?routingNumber=${routingNumber}`
        ).get().$promise;
    }

    function Batch(args) {
        args = args || {};
        this.name = args.name || '';
        this.achCompany = args.achCompany || new AchCompany();
        this.achCompanyName = args.achCompanyName || '';
        this.achCompanyId = args.achCompanyId || '';
        this.secCode = args.secCode || '';
        this.discretionaryData = args.discretionaryData || '';
        this.entryDescription = args.entryDescription || '';
        this.restricted =
            args.restricted && typeof args.restricted === 'boolean' ? args.restricted : false;
        this.recipients =
            args.recipients && angular.isArray(args.recipients)
                ? args.recipients
                : [
                      new Recipient({
                          accountType: 'Checking',
                          transactionType: 'CR',
                          default: true,
                      }),
                  ];
        this.lastUpdated = args.lastUpdate || new Date();
        this.debitAmount = args.debitAmount || 0;
        this.creditAmount = args.creditAmount || 0;
        this.numberOfApprovalsNeeded = args.numberOfApprovalsNeeded || 1;
        this.completedApprovals = args.completeApprovals || [];
        this.status = args.status || '';
        this.isTemplate = args.isTemplate || false;
    }

    function AchCompany(args) {
        args = args || {};

        this.companyName = args.companyName || null;
        this.companyId = args.companyId || null;
        this.secCodes = args.secCodes || [];
        this.entryDescription = args.entryDescription || null;
        this.discretionaryData = args.discretionaryData || null;
        this.totalDailyLimit = args.totalDailyLimit || 0;
        this.notOnUsTransactionTypes = args.notOnUsTransactionTypes || 0;
        this.batchBalanceRequirements = args.batchBalanceRequirements || null;
        this.onUsTransactionsRequired = args.onUsTransactionsRequired || 0;
        this.onUsAmountRequiredPerBatch = args.onUsAmountRequiredPerBatch || 0;
        this.isActive = args.isActive || false;
    }

    function Recipient(args) {
        args = args || {};

        this.name = args.name || null;
        this.idNumber = args.idNumber || null;
        this.accountNumber = args.accountNumber || null;
        this.accountType = args.accountType || 'Checking';
        this.routingNumber = args.routingNumber || null;
        this.isRoutingNumberValid = args.isRoutingNumberValid || false;
        this.isRoutingNumberOnUs = args.isRoutingNumberOnUs || false;
        this.transactionType = args.transactionType || 'CR';
        this.amount = args.amount || 0;
        this.addendaType = args.addendaType || null;
        this.addendaBody = args.addendaBody || null;
        this.prenote = args.prenote || false;
        this.hold = args.hold || false;
        this.isDeleted = false;
        this.default = args.default || false;
    }

    function getAccountTypes() {
        return ['Checking', 'Savings', 'Loans', 'GL'];
    }

    function getTransactionTypes() {
        return ['CR', 'DR'];
    }

    function approve(approveBatchModel, isTemplate = false) {
        const resource = $resource(`${resourceUrl}/:id/approve`, { id: approveBatchModel.key });
        const p = resource.save(approveBatchModel).$promise;

        if (isTemplate) {
            Promise.all([p, TmDi.getAsync(AnalyticsService)]).then(([, analytics]) => {
                analytics.track(AnalyticsEvent.AchTemplateApproved);
            });
        }

        return p;
    }

    function reject(rejectBatchModel) {
        return $resource(`${resourceUrl}/:id/reject`, { id: rejectBatchModel.key }).save(
            rejectBatchModel
        ).$promise;
    }

    function deleteBatch(deleteBatchModel) {
        return $http({
            url: `${resourceUrl}/delete`,
            method: 'DELETE',
            data: deleteBatchModel,
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
        });
    }

    function calculateAmounts(recipients) {
        const amounts = {
            debit: 0,
            credit: 0,
        };

        angular.forEach(recipients, item => {
            if (!item.hold) {
                const key = item.transactionType == 'DR' ? 'debit' : 'credit';
                amounts[key] = parseFloat((amounts[key] + parseFloat(item.amount) || 0).toFixed(2));
            }
        });

        return amounts;
    }

    function updateFilterCounts(counts, recipients, callback) {
        counts.prenote = 0;
        counts.hold = 0;
        counts.reversal = 0;
        angular.forEach(recipients, recipient => {
            counts.prenote += recipient.prenote ? 1 : 0;
            counts.hold += recipient.hold ? 1 : 0;
            counts.reversal += recipient.selectedForReversal ? 1 : 0;
        });
        if (typeof callback === 'function') {
            callback();
        }
    }
}

AchBatchService.$inject = ['$resource', '$http', '$q', 'achPaymentTypes', 'TmDi'];
