/* eslint-disable @treasury/filename-match-export */
import { exists } from '@treasury/utils';

/**
 * @param { ng.IScope } $scope
 */
export function DateRangeTransactionsController($scope, entitlementsService) {
    $scope.displayTransactionCodeDescriptionInUI = entitlementsService.hasEntitlement(
        'Feature.Report.DisplayTransactionCodeDescriptionInUI'
    );
    $scope.accountNameAccountNumberUpdates = entitlementsService.hasEntitlement(
        'Feature.Reporting.AccountNameAccountNumberUpdates.499511'
    );
    $scope.filterObject = {};
    $scope.transactionDateRangeReport = {};
    $scope.hasReportBeenGenerated = false;
    $scope.sorting = [];
    $scope.calculateTotals = calculateTotals;
    $scope.parse = parse;
    $scope.isWidthSmall = isWidthSmall;
    $scope.determineOrder = determineOrder;
    $scope.setOrder = setOrder;
    $scope.setSort = setSort;
    $scope.determineSort = determineSort;
    $scope.showCheckImageLink = showCheckImageLink;

    function calculateTotals(data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    }

    function parse(value) {
        return parseFloat(value);
    }

    function isWidthSmall(widthValue) {
        return widthValue;
    }

    function setSort(response) {
        angular.forEach($scope.sorting, item => {
            item.sortBy = angular.copy(response.sortBy);
            item.sortBy =
                item.sortBy.substring(0, 1).toLowerCase() +
                item.sortBy.substring(1, item.sortBy.length);
            item.isAscending = response.isAscending;
        });
    }

    function determineOrder(account) {
        let result = '';
        if ($scope.sorting.length > 0) {
            angular.forEach($scope.sorting, item => {
                if (item.accountNumber === account) {
                    result =
                        item.sortBy.substring(0, 1).toLowerCase() +
                        item.sortBy.substring(1, item.sortBy.length);
                }
            });
        }
        return result;
    }

    function setOrder(account, value) {
        angular.forEach($scope.sorting, item => {
            if (item.accountNumber === account) {
                item.sortBy = value;
            }
        });
    }

    function setSort(account) {
        angular.forEach($scope.sorting, item => {
            if (item.accountNumber === account) {
                item.isAscending = !item.isAscending;
            }
        });
    }

    function determineSort(account) {
        let result = false;
        if ($scope.sorting.length > 0) {
            angular.forEach($scope.sorting, item => {
                if (item.accountNumber === account) {
                    result = !item.isAscending;
                }
            });
        }
        return result;
    }

    function showCheckImageLink(row) {
        return (row.checkImageNumber && row.checkNumber) || row.checkImageNumber;
    }

    $scope.$on('reportUpdated', (event, response) => {
        setSort(response);
    });

    $scope.$watch(
        'transactionDateRangeReport',
        newValue => {
            const dataExists = exists(newValue) && exists(newValue.data);
            if (!dataExists) {
                return;
            }

            if (newValue.data.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }

            $scope.transactionDateRangeReport = newValue;
            $scope.sorting = [];
            angular.forEach($scope.transactionDateRangeReport.data, item => {
                $scope.sorting.push({
                    accountName: item.accountName,
                    accountNumber: item.accountNumber,
                    isAscending: $scope.transactionDateRangeReport.isAscending,
                    sortBy: $scope.transactionDateRangeReport.sortBy,
                });
                angular.forEach(item.data, _item => {
                    if (_item.debits === null || _item.debits === undefined) {
                        _item.debits = 0;
                    }
                    if (_item.credits === null || _item.credits === undefined) {
                        _item.credits = 0;
                    }
                });
            });

            $scope.hasReportBeenGenerated = true;
        },
        true
    );
}

DateRangeTransactionsController.$inject = ['$scope', 'entitlementsService'];
