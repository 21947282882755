import { css, html, LitElement } from 'lit';
import './omega-checkbox.js';

class OmegaCheckboxGroup extends LitElement {
    static get properties() {
        return {
            id: {
                type: String,
            },
            label: {
                type: String,
            },
            options: {
                type: Array,
            },
            values: {
                type: Array,
            },
            orientation: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.orientation = 'column';
        this.values = [];
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-checkbox-group--checkbox-group',
        };
    }

    _changed(e) {
        if (this.values.includes(e.target.value)) {
            this.values = this.values.filter(v => v !== e.target.value);
        } else {
            this.values = [...this.values, e.target.value];
        }
        this.dispatchEvent(new CustomEvent('change', { detail: { values: this.values } }));
    }

    renderCheckboxes() {
        return this.options.map(
            (checkbox, idx) => html`<omega-checkbox 
                    id=${checkbox.id || idx}
                    .value=${checkbox.value}
                    .label=${checkbox.label ? checkbox.label : checkbox.text}
                    ?checked=${this.values.includes(checkbox.value)}
                    aria-checked=${this.values.includes(checkbox.value)}
                    @toggle=${this._changed}
                ></omega-checkbox>
            </div>`
        );
    }

    render() {
        return html` <label for=${this.id}>${this.label}</label>
            <div
                class=${`omega-checkbox-group omega-checkbox-group-${this.orientation}`}
                id=${this.id}
            >
                ${this.renderCheckboxes()}
            </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
            label {
                font-size: var(--omega-label);
                color: var(--omega-text-secondary);
            }

            .omega-checkbox-group {
                display: flex;
                min-width: 225px;
                margin-top: 8px;
            }

            .omega-checkbox-group-column {
                flex-direction: var(--omega-checkbox-group-flex-direction, column);
            }

            .omega-checkbox-group-row {
                flex-direction: row;
            }
        `;
    }
}
customElements.define('omega-checkbox-group', OmegaCheckboxGroup);
export default OmegaCheckboxGroup;
