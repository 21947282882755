MessageCenterSearchModalController.$inject = [
    '$scope',
    '$modalInstance',
    'cachedSearch',
    'messagingCenterService',
];

export default function MessageCenterSearchModalController(
    $scope,
    $modalInstance,
    cachedSearch,
    messagingCenterService
) {
    $scope.searchTerms = {};
    $scope.reset = reset;

    init();

    function init() {
        if (cachedSearch.lists) {
            $scope.categories = cachedSearch.lists.categories;
        } else {
            getCategories();
        }
        if (cachedSearch.terms) {
            $scope.searchTerms = cachedSearch.terms;
        } else {
            reset();
        }
    }

    function reset() {
        $scope.searchTerms = {
            category: null,
            messageId: '',
            dateFrom: '',
            dateTo: '',
            type: 'all',
        };
    }

    function getCategories() {
        messagingCenterService.getMessageCategories().then(response => {
            $scope.categories = response;
        });
    }

    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.search = function () {
        $scope.searchTerms.categoryId = $scope.searchTerms.category
            ? $scope.searchTerms.category.fiMessageCategoryId
            : null;

        switch ($scope.searchTerms.type) {
            case 'all':
                $scope.searchTerms.messageTypeId = 1;
                break;
            case 'inbox':
                $scope.searchTerms.messageTypeId = 2;
                break;
            case 'sent':
                $scope.searchTerms.messageTypeId = 3;
                break;
            case 'archived':
                $scope.searchTerms.messageTypeId = 4;
                break;
            default:
                $scope.searchTerms.messageTypeId = 0;
                break;
        }

        $modalInstance.close({
            terms: $scope.searchTerms,
            lists: {
                categories: $scope.categories,
            },
        });
    };
}
