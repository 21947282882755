FixedHeaderDirective.$inject = ['$timeout'];

export default function FixedHeaderDirective($timeout) {
    return {
        restrict: 'A',
        link,
    };

    function link($scope, $elem, $attrs, $ctrl) {
        const elem = $elem[0];

        // wait for data to load and then transform the table
        $scope.$watch(tableDataLoaded, isTableDataLoaded => {
            if (isTableDataLoaded) {
                transformTable();
            }
        });

        function tableDataLoaded() {
            // first cell in the tbody exists when data is loaded but doesn't have a width
            // until after the table is transformed
            const firstCell = elem.querySelector('tbody tr:first-child td:first-child');
            return firstCell && !firstCell.style.width;
        }

        function transformTable() {
            // reset display styles so column widths are correct when measured below
            angular.element(elem.querySelectorAll('thead, tbody, tfoot')).css('display', '');

            // wrap in $timeout to give table a chance to finish rendering
            $timeout(() => {
                // set widths of columns
                angular.forEach(elem.querySelectorAll('tr:first-child th'), (thElem, i) => {
                    const tdElems = elem.querySelector(
                        `tbody tr:first-child td:nth-child(${i + 1})`
                    );
                    const tfElems = elem.querySelector(
                        `tfoot tr:first-child td:nth-child(${i + 1})`
                    );

                    const columnWidth = tdElems ? tdElems.offsetWidth : thElem.offsetWidth;
                    if (tdElems) {
                        tdElems.style.width = `${columnWidth}px`;
                    }
                    if (thElem) {
                        thElem.style.width = `${columnWidth}px`;
                    }
                    if (tfElems) {
                        tfElems.style.width = `${columnWidth}px`;
                    }
                });

                // set css styles on thead and tbody
                angular.element(elem.querySelectorAll('thead, tfoot')).css('display', 'block');

                angular.element(elem.querySelectorAll('tbody')).css({
                    display: 'block',
                    height: $attrs.tableHeight || 'inherit',
                    overflow: 'auto',
                });

                // reduce width of last column by width of scrollbar
                const tbody = elem.querySelector('tbody');
                let scrollBarWidth = tbody.offsetWidth - tbody.clientWidth;
                if (scrollBarWidth > 0) {
                    // for some reason trimming the width by 2px lines everything up better
                    scrollBarWidth -= 2;
                    const lastColumn = elem.querySelector('tbody tr:first-child td:last-child');
                    lastColumn.style.width = `${lastColumn.offsetWidth - scrollBarWidth}px`;
                }
            });
        }
    }
}
