/* eslint-disable max-classes-per-file */
import { LitElement } from 'lit';
import { exists } from '../../functions';

export abstract class LitPropertyVerifier extends LitElement {
    /**
     * The names of properties to verify existence of during `connectedCallback()`.
     */
    protected readonly verifiedPropNames: Readonly<(keyof typeof this)[]> = [];

    public connectedCallback(): void {
        super.connectedCallback();
        this.verifyProperties();
    }

    /**
     * Asserts the existence of properties on the current instance.
     * If any are not not found, an exception is thrown.
     */
    private verifyProperties() {
        this.verifiedPropNames.forEach(attrName => {
            if (!exists(this[attrName])) {
                throw new Error(
                    `Could not create <${this.tagName.toLowerCase()}>. The "${attrName.toString()}" attribute is required.`
                );
            }
        });
    }
}
