export default [
    '$compile',
    '$templateCache',
    function ($compile, $templateCache) {
        const getTemplate = function (contentType) {
            let template = '';
            switch (contentType) {
                case 'check':
                    template = $templateCache.get('check.html');
                    break;
                case 'pendingPaymentsApproval':
                    template = $templateCache.get('pendingPaymentsApproval.html');
                    break;
                case 'cutoffTimes':
                    template = $templateCache.get('cutoffTimes.html');
                    break;
                case 'report':
                    template = $templateCache.get('report.html');
                    break;
                case 'memo':
                    template = $templateCache.get('memo.html');
                    break;
                case 'researchTransactionCheckImage':
                    template = $templateCache.get('researchTransactionCheckImage.html');
                    break;
                default:
                    break;
            }
            return template;
        };

        return {
            restrict: 'A',
            scope: {
                entity: '=?',
                templateType: '=',
                isEditField: '=',
                isDisabled: '=?',
            },
            transclude: false,
            link(scope, element, attrs) {
                scope.currentEntity = scope.entity;

                scope.mouseOver = function () {
                    scope.currentEntity[scope.isEditField] = false;
                };

                // watch for entity or option
                scope.$watch(
                    'currentEntity',
                    (newValue, oldValue) => {
                        if (
                            newValue[scope.isEditField] !== null &&
                            newValue[scope.isEditField] === true &&
                            newValue[scope.isEditField] !== oldValue[scope.isEditField]
                        ) {
                            let popOverContent;
                            popOverContent = getTemplate(scope.templateType);
                            const options = {
                                content: $compile(popOverContent)(scope),
                                placement: attrs.placement,
                                appendToBody: true,
                                html: true,
                                body: true,
                                trigger: 'manual',
                            };
                            $(element).popover(options);
                            $(element).popover('show');
                        } else if (
                            newValue[scope.isEditField] !== null &&
                            newValue[scope.isEditField] === false &&
                            newValue[scope.isEditField] !== oldValue[scope.isEditField]
                        ) {
                            $(element).popover('destroy');
                        }
                    },
                    true
                );

                scope.memoFieldMax = function (item) {
                    if (item.length > 30) {
                        item = item.substring(0, 30);
                    }
                };

                function init() {
                    const today = new Date();
                    const hour = today.getHours();
                    let minutes = today.getMinutes();
                    if (minutes < 10) {
                        minutes = `0${minutes}`;
                    }
                    let ampm;
                    if (hour < 12) {
                        ampm = 'AM';
                    } else {
                        ampm = 'PM';
                    }
                    scope.currentTime = `${hour - 1}:${minutes}${ampm} CST`;
                }

                init();
            },
        };
    },
];
