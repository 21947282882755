export const isDateInArrayOfBankingHolidays = (holidays, date) =>
    holidays.some(h => {
        const holidayDate = new Date(h.date);
        const dateToCompare = new Date(date);
        return (
            holidayDate.getYear() === dateToCompare.getYear() &&
            holidayDate.getMonth() === dateToCompare.getMonth() &&
            holidayDate.getDate() === dateToCompare.getDate()
        );
    });

/* 
This is just a wrapper around isDateInArrayOfBankingHolidays 
so you dont have to pass holiday around 
*/

export const makeBankingHolidayCheck = holidays =>
    function (date) {
        return isDateInArrayOfBankingHolidays(holidays, date);
    };
