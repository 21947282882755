/*
 * This module includes commonly reused methods with no affinity to any set of business services.
 */
export default [
    function () {
        return {
            /* whenDefined is a convenient method allowing the user to place a watch on a not yet defined object.
             * Once the object has been added to the scope the watch is destroyed. */
            whenDefined,
        };

        function whenDefined(expression, callback, scope) {
            const value = scope.$eval(expression);

            if (value !== undefined) {
                callback(value);
            } else {
                // changing var to const will cause the minifier to break the code
                var destroy = scope.$watch(expression, newValue => {
                    if (newValue) {
                        callback(newValue);
                        destroy();
                    }
                });
            }
        }
    },
];
