import { HolidayCalendarModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { defaultHolidayCalendarModelDto } from './data/date.data';

export class Holiday extends DomainEntity<HolidayCalendarModelDto> {
    public createDefault(): this {
        return new Holiday(defaultHolidayCalendarModelDto) as this;
    }

    public get name() {
        return this.dto.name;
    }

    public get date() {
        return this.dto.date;
    }

    public get dateObj() {
        return new Date(this.dto.date);
    }

    public get isPastDue() {
        return this.dto.isPastDue;
    }
}
