import { AchBank, AchBankDto } from '.';

export interface PaymentRecipientBase {
    prenote?: boolean;
    hold?: boolean;
    name: string;
    idNumber?: string | null;
    accountNumber?: string;
    accountType?: string;
    amount?: number;
    addenda?: string | null;
    errors?: boolean;
    transactionType?: string;
    step: number;
    nachaFileUpload?: boolean;
    editable?: boolean;
    testAchCompanyName?: string | undefined;
    id?: any;
    addendaId?: any;
    fromMasterList: boolean;
}

export interface PaymentRecipient extends PaymentRecipientBase {
    bank?: AchBank | AchBankDto;
}

export const defaultRecipientValues: PaymentRecipient = {
    prenote: false,

    hold: false,

    name: '',

    idNumber: '',

    accountNumber: '',

    accountType: 'Checking',

    bank: { bankId: '0', name: '', city: '', onUs: false, isInternational: false, id: 0 },

    amount: 0,

    addenda: '',

    errors: false,

    transactionType: '',

    step: 0,

    nachaFileUpload: false,

    fromMasterList: false,
};
