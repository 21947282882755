import { LitElement, html, css } from 'lit';

class OmegaIconSuccess extends LitElement {
    render() {
        return html`
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 22 22"
            >
                <defs>
                    <path
                        id="a"
                        d="M14 3C7.925 3 3 7.925 3 14s4.925 11 11 11 11-4.925 11-11S20.075 3 14 3zm0 2.129A8.866 8.866 0 0122.871 14 8.866 8.866 0 0114 22.871 8.866 8.866 0 015.129 14 8.866 8.866 0 0114 5.129m6.219 5.778l-1-1.008a.532.532 0 00-.753-.003l-6.27 6.22-2.651-2.674a.532.532 0 00-.753-.003l-1.008 1a.532.532 0 00-.003.752l4.027 4.06c.207.208.544.21.753.002l7.655-7.593a.532.532 0 00.003-.753z"
                    />
                </defs>
                <g fill="none" fill-rule="evenodd" transform="translate(-3 -3)">
                    <mask id="b" fill="#fff">
                        <use xlink:href="#a" />
                    </mask>
                    <use fill="#02A12E" fill-rule="nonzero" xlink:href="#a" />
                    <path fill="#34AA44" d="M0 0h28v28H0z" mask="url(#b)" />
                </g>
            </svg>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }
        `;
    }
}
customElements.define('omega-icon-success', OmegaIconSuccess);
